import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { getFolderFromMetadata } from '../../components/helpers';
import ComponentBoxModal from '../../components-directory/components/ComponentBoxModal';

const NewFlowButton = (props: {
  component: Map<string, any>;
  componentMetadata: Map<string, any>;
  readOnly: boolean;
  className?: string;
}) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Button
        bsStyle="success"
        className={props.className}
        onClick={() => setShowModal(true)}
        disabled={props.readOnly}
      >
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        Create Flow
      </Button>
      <ComponentBoxModal
        show={showModal}
        allowFolder
        component={props.component}
        folders={props.componentMetadata
          .map((metadata) => getFolderFromMetadata(metadata))
          .filter(Boolean)
          .toSet()
          .toList()}
        readOnly={props.readOnly}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default NewFlowButton;
