import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { ORCHESTRATOR } from '../../../../../constants/componentIds';
import { SIDEBAR } from '../../../../../constants/external';
import { USER_DOCUMENTATION_URL } from '../../../../../constants/KbcConstants';
import Link from '../../../../../react/common/RouterLink';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import { canManageTokens } from '../../../../admin/privileges';
import ComponentDescription from '../../../../components/react/components/ComponentDescription';
import DocumentationLink from '../../../../components/react/components/DocumentationLink';
import SidebarVersions from '../../../../components/react/components/SidebarVersions';
import ComponentsStore from '../../../../components/stores/ComponentsStore';
import StorageBucketsStore from '../../../../components/stores/StorageBucketsStore';
import StorageTablesStore from '../../../../components/stores/StorageTablesStore';
import TriggersStore from '../../../../components/stores/StorageTriggersStore';
import VersionsStore from '../../../../components/stores/VersionsStore';
import CronRecord from '../../../../orchestrations-v2/components/CronRecord';
import JobsTable from '../../../../orchestrations-v2/components/JobsTable';
import JobsGraph from '../../../../queue/components/JobsGraph';
import { JOBS_LIMIT_FOR_GRAPH } from '../../../../queue/constants';
import { prepareGraphData } from '../../../../queue/helpers';
import { routeNames as projectSettingsRouteNames } from '../../../../settings/constants';
import OrchestrationJobsStore from '../../../stores/OrchestrationJobsStore';
import OrchestrationStore from '../../../stores/OrchestrationsStore';
import OrchestrationActiveButton from '../../components/OrchestrationActiveButton';
import OrchestrationDeleteButton from '../../components/OrchestrationDeleteButton';
import OrchestrationRunButton from '../../components/OrchestrationRunButton';
import ScheduleEdit from '../../modals/Schedule';
import {
  getOrchestrationJobsByType,
  ORCHESTRATION_JOB_TYPE_FAILED,
  ORCHESTRATION_JOB_TYPE_SUCCESSFUL
} from './jobsFilter';
import TasksSummary from './TasksSummary';

const OrchestrationDetail = createReactClass({
  mixins: [
    createStoreMixin(
      RoutesStore,
      ComponentsStore,
      OrchestrationStore,
      OrchestrationJobsStore,
      VersionsStore,
      StorageTablesStore,
      StorageBucketsStore,
      TriggersStore,
      ApplicationStore
    )
  ],

  getStateFromStores() {
    const orchestrationIdString = RoutesStore.getCurrentRouteParam('orchestrationId');
    const orchestrationId = parseInt(orchestrationIdString, 10);
    const jobs = OrchestrationJobsStore.getOrchestrationJobs(orchestrationId);
    const trigger = TriggersStore.get(ORCHESTRATOR, orchestrationIdString);

    return {
      component: ComponentsStore.getComponent(ORCHESTRATOR),
      orchestration: OrchestrationStore.get(orchestrationId),
      tasksToRun: OrchestrationStore.getTasksToRun(orchestrationId),
      phases: OrchestrationStore.getOrchestrationTasks(orchestrationId),
      isLoading: OrchestrationStore.getIsOrchestrationLoading(orchestrationId),
      jobs,
      pendingActions: OrchestrationStore.getPendingActionsForOrchestration(orchestrationId),
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      trigger: trigger,
      sapiTokenCanManageTokens: canManageTokens(ApplicationStore.getSapiToken()),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
      versions: VersionsStore.getVersions(ORCHESTRATOR, orchestrationIdString),
      versionsConfigs: VersionsStore.getVersionsConfigs(ORCHESTRATOR, orchestrationIdString),
      isLoadingVersions: VersionsStore.isLoadingVersions(ORCHESTRATOR, orchestrationIdString),
      isPendingVersions: VersionsStore.isPendingConfig(ORCHESTRATOR, orchestrationIdString),
      pendingMultiLoadVersions: VersionsStore.getPendingMultiLoad(
        ORCHESTRATOR,
        orchestrationIdString
      )
    };
  },

  getInitialState() {
    return {
      activeTab: 'all-runs'
    };
  },

  UNSAFE_componentWillReceiveProps() {
    return this.setState(this.getStateFromStores());
  },

  render() {
    return (
      <div className="row">
        <div className="col-sm-9">
          <ComponentDescription
            componentId={ORCHESTRATOR}
            configId={this.state.orchestration.get('id').toString()}
            placeholderEntity="orchestrator"
          />
          <div className="box info-row">
            {this.renderTasks()}
            {this.renderSchedule()}
            {this.renderNotifications()}
          </div>
          <Tabs
            animation={false}
            id="orchestration-job-type-tables"
            activeKey={this.state.activeTab}
            onSelect={this.handleSelectTab}
          >
            {this.renderTab('All Runs', 'all-runs', '', 'ml-2')}
            {this.renderTab('Successful', 'successful-runs', ORCHESTRATION_JOB_TYPE_SUCCESSFUL)}
            {this.renderTab('Failed', 'failed-runs', ORCHESTRATION_JOB_TYPE_FAILED)}
          </Tabs>
        </div>
        <div className="col-sm-3">
          <div className={`sidebar-content ${SIDEBAR}`}>
            {!this.state.readOnly && (
              <ul className="nav nav-stacked">
                <li>
                  <OrchestrationRunButton
                    buttonBlock
                    tasks={this.state.tasksToRun}
                    orchestration={this.state.orchestration}
                  />
                  <hr />
                </li>
                <li className={classnames({ disabled: !this.state.sapiTokenCanManageTokens })}>
                  <OrchestrationActiveButton
                    orchestration={this.state.orchestration}
                    isPending={this.state.pendingActions.get('active', false)}
                    buttonDisabled={!this.state.sapiTokenCanManageTokens}
                    mode="link"
                  />
                </li>
                <li>
                  <DocumentationLink href={`${USER_DOCUMENTATION_URL}/orchestrator/running/`} />
                </li>
                <li>
                  <hr />
                  <OrchestrationDeleteButton
                    buttonBlock
                    orchestration={this.state.orchestration}
                    isPending={this.state.pendingActions.get('delete', false)}
                  />
                </li>
              </ul>
            )}
            <div className="sidebar-box">
              <h4>Assigned Token</h4>
              <Link
                to={projectSettingsRouteNames.TOKEN_DETAIL}
                params={{ tokenId: this.state.orchestration.getIn(['token', 'id']) }}
                className="text-muted"
              >
                <FontAwesomeIcon icon="toolbox" className="icon-addon-right" />
                {this.state.orchestration.getIn(['token', 'description'])}
              </Link>
            </div>
            <hr />
            <SidebarVersions
              component={this.state.component}
              configId={this.state.orchestration.get('id').toString()}
              versionsLinkTo="orchestrator-versions"
              versionsLinkParams={{ orchestrationId: this.state.orchestration.get('id') }}
              versions={this.state.versions}
              versionsConfigs={this.state.versionsConfigs}
              isLoading={this.state.isLoadingVersions}
              isPending={this.state.isPendingVersions}
              pendingMultiLoad={this.state.pendingMultiLoadVersions}
              admins={this.state.admins}
            />
          </div>
        </div>
      </div>
    );
  },

  renderTab(title, tabName, jobTypeFilter, className) {
    let jobs = this.state.jobs;

    if (jobTypeFilter) {
      jobs = getOrchestrationJobsByType(jobs, jobTypeFilter);
    }

    return (
      <Tab title={title} eventKey={tabName} tabClassName={className}>
        <div className="bt pt-2">
          {this.renderGraph(tabName, jobTypeFilter)}
          <JobsTable
            orchestration={this.state.orchestration}
            jobs={jobs.toList()}
            admins={this.state.admins}
          />
        </div>
      </Tab>
    );
  },

  renderGraph(tabName, jobTypeFilter) {
    if (tabName !== this.state.activeTab) {
      return null;
    }

    let jobs = this.state.jobs;

    if (jobTypeFilter) {
      jobs = getOrchestrationJobsByType(jobs, jobTypeFilter);
    }

    if (jobs.isEmpty()) {
      return null;
    }

    return <JobsGraph data={prepareGraphData(jobs)} minEntries={JOBS_LIMIT_FOR_GRAPH} />;
  },

  renderTasks() {
    return (
      <div className="info-row-section">
        <h4 className="first-line">
          Tasks
          <Link
            className="btn-link-inline btn-link-muted icon-addon-left"
            to="orchestrationTasks"
            params={{
              orchestrationId: this.state.orchestration.get('id')
            }}
          >
            <FontAwesomeIcon icon="pen" />
          </Link>
        </h4>
        <p className="last-line text-muted">
          <TasksSummary phases={this.state.phases} />
        </p>
      </div>
    );
  },

  renderSchedule() {
    return (
      <div className="info-row-section">
        <h4 className="first-line">
          {this.state.trigger.count() ? 'Event Trigger' : 'Schedule'}
          <ScheduleEdit
            orchestrationId={this.state.orchestration.get('id')}
            crontabRecord={this.state.orchestration.get('crontabRecord')}
            crontabTimezone={this.state.orchestration.get('crontabTimezone')}
            tables={this.state.tables}
            buckets={this.state.buckets}
            trigger={this.state.trigger}
            isDisabled={!this.state.sapiTokenCanManageTokens}
          />
        </h4>
        <div className="last-line text-muted">
          {this.state.trigger.count() ? this.renderEventTrigger() : this.renderCronRecord()}
        </div>
      </div>
    );
  },

  renderNotifications() {
    if (!this.state.orchestration.get('notifications').count()) {
      return (
        <div className="info-row-section">
          <Link
            className="btn btn-primary"
            to="orchestrationNotifications"
            params={{
              orchestrationId: this.state.orchestration.get('id')
            }}
          >
            <FontAwesomeIcon icon="pen" className="icon-addon-right" />
            Configure notifications
          </Link>
        </div>
      );
    }

    return (
      <div className="info-row-section">
        <h4 className="first-line">
          Notifications
          <Link
            className="btn-link-inline btn-link-muted icon-addon-left"
            to="orchestrationNotifications"
            params={{
              orchestrationId: this.state.orchestration.get('id')
            }}
          >
            <FontAwesomeIcon icon="pen" />
          </Link>
        </h4>
        <p className="last-line text-muted">
          {this.state.orchestration.get('notifications').count()} notifications set
        </p>
      </div>
    );
  },

  renderEventTrigger() {
    const tables = this.state.trigger.get('tables', List()).count();
    const period = this.state.trigger.get('coolDownPeriodMinutes', 0);

    return (
      <div>
        <span>Tables to check: {tables}</span>
        <br />
        <span>Cooldown period: {period} minutes</span>
      </div>
    );
  },

  renderCronRecord() {
    return (
      <CronRecord
        crontabRecord={this.state.orchestration.get('crontabRecord')}
        crontabTimezone={this.state.orchestration.get('crontabTimezone')}
      />
    );
  },

  handleSelectTab(eventKey) {
    this.setState({ activeTab: eventKey });
  }
});

export default OrchestrationDetail;
