import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import CredentialsBox from '../../../../react/common/CredentialsBox';
import Loader from '../../../../react/common/Loader';

const SnowflakeCredentials = createReactClass({
  propTypes: {
    credentials: PropTypes.object,
    isCreating: PropTypes.bool
  },

  render() {
    if (this.props.isCreating) {
      return (
        <>
          <Loader className="icon-addon-right" />
          Creating sandbox
        </>
      );
    }

    if (this.props.credentials.get('id')) {
      return this._renderCredentials();
    }

    return 'Sandbox not running';
  },

  _renderCredentials() {
    const rows = Map({
      Host: this.props.credentials.get('hostname'),
      Port: '443',
      User: this.props.credentials.get('user'),
      Password: {
        protected: true,
        text: this.props.credentials.get('password')
      },
      Database: this.props.credentials.get('db'),
      Schema: this.props.credentials.get('schema')
    });

    return <CredentialsBox rows={rows} />;
  }
});

export default SnowflakeCredentials;
