import { createRequest } from '../components/StorageApi';

export default (tokenId) => {
  return {
    listEvents(params) {
      return createRequest('GET', `tokens/${tokenId}/events`)
        .query(params)
        .timeout(10000)
        .promise()
        .then((response) => response.body);
    },

    getEvent(id) {
      return createRequest('GET', `tokens/${tokenId}/events/${id}`)
        .promise()
        .then((response) => response.body);
    }
  };
};
