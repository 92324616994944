import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { JSONEditor } from '@json-editor/json-editor';

import callDockerAction from '../../../modules/components/DockerActionsApi';
import Loader from '../../../react/common/Loader';
import Markdown from '../../../react/common/Markdown';
import RoutesStore from '../../../stores/RoutesStore';
import { prepareConfigData } from '../helpers';

const SyncActionButtonApp = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState(null);

  const renderResult = () => {
    if (!result) {
      return null;
    }

    if (result.type === 'table') {
      return (
        <Markdown collapsible={false} overflow={false} className="mt-1" source={result.message} />
      );
    }

    return (
      <Alert bsStyle={result.type} onDismiss={() => setResult(null)} className="alert-no-icon mt-1">
        <Markdown collapsible={false} source={result.message} />
      </Alert>
    );
  };

  return (
    <>
      <Button
        disabled={loading}
        onClick={() => {
          const componentId = RoutesStore.getCurrentRouteComponentId();
          const configId = RoutesStore.getCurrentRouteParam('configId');
          const rowId = RoutesStore.getCurrentRouteParam('row');

          setLoading(true);
          setResult(null);
          return callDockerAction(componentId, props.action || 'action', {
            configData: prepareConfigData(componentId, configId, rowId)
          })
            .then((response) => {
              return setResult({
                type:
                  response?.status === 'error'
                    ? 'danger'
                    : ['success', 'info', 'warning', 'danger', 'table'].includes(response?.type)
                    ? response.type
                    : 'success',
                message: response.message
              });
            })
            .finally(() => setLoading(false));
        }}
      >
        {loading && <Loader className="icon-addon-right" />}
        {props.label || 'Perform action'}
      </Button>
      {renderResult()}
    </>
  );
};

export default class SyncActionButtonEditor extends JSONEditor.AbstractEditor {
  build() {
    const buttonRoot = window.document.createElement('span');

    this.container.appendChild(buttonRoot);

    this.root = createRoot(buttonRoot);

    this.root.render(
      <SyncActionButtonApp action={this.options.async?.action} label={this.options.async?.label} />
    );
  }

  destroy() {
    setTimeout(() => this.root?.unmount());

    super.destroy();
  }
}
