import { List } from 'immutable';

import {
  KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2,
  KEBOOLA_EXASOL_TRANSFORMATION,
  KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
  KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION,
  KEBOOLA_PYTHON_TRANSFORMATION_V_2,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_TERADATA_TRANSFORMATION
} from '../../constants/componentIds';

const resolveEditorMode = (componentId) => {
  switch (componentId) {
    case KEBOOLA_SNOWFLAKE_TRANSFORMATION:
      return 'text/x-sfsql';

    case KEBOOLA_PYTHON_TRANSFORMATION_V_2:
    case KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION:
    case KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2:
      return 'text/x-python';

    case KEBOOLA_EXASOL_TRANSFORMATION:
    case KEBOOLA_TERADATA_TRANSFORMATION:
    case KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION:
      return 'text/x-plsql';

    default:
      return 'application/json';
  }
};

const getConfigurations = (components, detail) => {
  return components.getIn([detail.get('type'), 'configurations'], List()).filter((config) => {
    return config
      .getIn(['configuration', 'shared_code_row_ids'], List())
      .includes(detail.get('rowId'));
  });
};

const prepareCodeString = (code) => {
  if (List.isList(code)) {
    return code.join('\n\n');
  }
  return code;
};

export { resolveEditorMode, getConfigurations, prepareCodeString };
