import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import MappingsMultiActionsHeader, {
  MappingsHeader
} from '../../../../../react/common/MappingsMultiActionsHeader';
import SortIcon from '../../../../../react/common/SortIcon';
import { getDefaultBucketName, getDefaultTableName } from '../../../../components/helpers';
import ConfigurationRowsActionCreators from '../../../../configurations/ConfigurationRowsActionCreators';
import { DBT_COMPONENTS } from '../../../../transformations-v2/constants';
import { ioType } from '../../../Constants';
import InstalledComponentsActions from '../../../InstalledComponentsActionCreators';
import CollapsibleMapping from './CollapsibleMapping';
import TableOutputMappingModal from './TableOutputMappingModal';
import TableOutputMappingRow from './TableOutputMappingRow';

const TableOutputMapping = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    configName: PropTypes.string.isRequired,
    editingValue: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    pendingActions: PropTypes.object.isRequired,
    onDeleteMappings: PropTypes.func.isRequired,
    sourceType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    addBottomRadius: PropTypes.bool,
    rowId: PropTypes.string
  },

  getInitialState() {
    return {
      selectedMappings: Map(),
      sortBy: null,
      sortDesc: false
    };
  },

  render() {
    return (
      <CollapsibleMapping
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        configId={this.props.configId}
        rowId={this.props.rowId}
        mappingKey="table-output"
      >
        {this.renderBody}
      </CollapsibleMapping>
    );
  },

  renderBody(collapse) {
    const isDbtComponent = DBT_COMPONENTS.includes(this.props.componentId);

    return (
      <div
        className={classnames('box', {
          'no-mapping': this.props.value.isEmpty(),
          'bottom-radius': this.props.addBottomRadius
        })}
      >
        <div
          className={classnames('box-header above-table-with-buttons tw-block', {
            'is-sticky bg-color-white': this.props.value.count() > 5 && !collapse.isCollapsed
          })}
        >
          <div className="tw-flex tw-justify-between tw-items-center">
            <MappingsHeader type="output" componentId={this.props.componentId} storage="tables" />
            <div className="flex-container">
              {!this.props.value.isEmpty() &&
                (!isDbtComponent || collapse.isCollapsed) &&
                collapse.renderToggleButton()}
              {!this.props.readOnly && (
                <TableOutputMappingModal
                  mode="create"
                  componentId={this.props.componentId}
                  mapping={this.props.editingValue.get('new-mapping', Map())}
                  tables={this.props.tables}
                  buckets={this.props.buckets}
                  onChange={(newMapping) => this.onChangeMapping('new-mapping', newMapping)}
                  onCancel={() => this.onCancelEditMapping('new-mapping')}
                  onSave={() => {
                    const mapping = this.props.editingValue.get('new-mapping', Map());
                    return this.onSaveMapping(
                      'new-mapping',
                      `Add output table ${mapping.get('destination')}`
                    );
                  }}
                  sourceType={this.props.sourceType}
                  defaultBucketName={getDefaultBucketName(this.props.configName)}
                  defaultTableName={getDefaultTableName(this.props.configName)}
                />
              )}
            </div>
          </div>

          {!this.props.value.isEmpty() && !collapse.isCollapsed && (
            <div className="tw-flex tw-items-center tw-py-2">
              <MappingsMultiActionsHeader
                hide={this.props.readOnly}
                disabled={this.props.value.isEmpty()}
                componentId={this.props.componentId}
                configurationId={this.props.configId}
                rowId={this.props.rowId}
                type="output"
                storage="tables"
                allMappings={this.props.value}
                selectedMappings={this.state.selectedMappings}
                updateMappingsSelection={(selectedMappings) =>
                  this.setState(() => ({ selectedMappings }))
                }
                deleteMappings={this.props.onDeleteMappings}
                isSorted={this.state.sortBy === 'source'}
                isSortedDesc={this.state.sortDesc}
                onClick={() => this.handleChangeSort('source')}
              />
              <div
                className="tw-cursor-pointer tw-font-medium"
                onClick={() => this.handleChangeSort('destination')}
              >
                Destination Table
                <SortIcon
                  className="tw-ml-2"
                  isSorted={this.state.sortBy === 'destination'}
                  isSortedDesc={this.state.sortDesc}
                />
              </div>
            </div>
          )}
        </div>

        {collapse.renderCollapseSection(this.renderContent())}
      </div>
    );
  },

  renderContent() {
    if (this.props.value.isEmpty()) {
      return null;
    }

    return (
      <div className="box-content p-0">
        <div className="table table-hover overflow-break-anywhere">
          <div className="tbody">
            {this.props.value
              .map((output, index) => [output, index])
              .sort(this.handleSort)
              .map(([output, index]) => this.renderRow(output, index))
              .toArray()}
          </div>
        </div>
      </div>
    );
  },

  renderRow(output, key) {
    return (
      <TableOutputMappingRow
        key={key}
        value={output}
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        defaultBucketName={getDefaultBucketName(this.props.configName)}
        defaultTableName={getDefaultTableName(this.props.configName)}
        editingValue={this.props.editingValue.get(key, Map())}
        tables={this.props.tables}
        buckets={this.props.buckets}
        mappingIndex={key}
        pendingActions={this.props.pendingActions}
        onEditStart={() => this.onEditStart(key)}
        onChange={(value) => this.onChangeMapping(key, value)}
        onSave={() => this.onSaveMapping(key, `Update output table ${output.get('destination')}`)}
        onCancel={() => this.onCancelEditMapping(key)}
        onDelete={() =>
          this.onDeleteMapping(key).then(() => this.setState({ selectedMappings: Map() }))
        }
        sourceType={this.props.sourceType}
        isSelected={!!this.state.selectedMappings.get(key)}
        toggleSelection={(checked) =>
          this.setState({ selectedMappings: this.state.selectedMappings.set(key, checked) })
        }
      />
    );
  },

  handleChangeSort(type) {
    if (type !== this.state.sortBy) {
      return this.setState({
        sortBy: type,
        sortDesc: false
      });
    }

    this.setState({
      sortDesc: !this.state.sortDesc
    });
  },

  handleSort([outputA], [outputB]) {
    if (!this.state.sortBy) {
      return 0;
    }

    const nameA = outputA.get(this.state.sortBy, '');
    const nameB = outputB.get(this.state.sortBy, '');

    const sort = this.state.sortDesc ? -1 : 1;

    return nameA.localeCompare(nameB) * sort;
  },

  onChangeMapping(key, value) {
    if (this.props.rowId) {
      return ConfigurationRowsActionCreators.changeEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'output',
        'tables',
        key,
        value
      );
    }

    return InstalledComponentsActions.changeEditingMapping(
      this.props.componentId,
      this.props.configId,
      'output',
      'tables',
      key,
      value
    );
  },

  onEditStart(key) {
    if (this.props.rowId) {
      return ConfigurationRowsActionCreators.startEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'output',
        'tables',
        key
      );
    }

    return InstalledComponentsActions.startEditingMapping(
      this.props.componentId,
      this.props.configId,
      'output',
      'tables',
      key
    );
  },

  onSaveMapping(key, changeDescription) {
    if (this.props.rowId) {
      return ConfigurationRowsActionCreators.saveEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'output',
        'tables',
        key,
        changeDescription
      );
    }

    return InstalledComponentsActions.saveEditingMapping(
      this.props.componentId,
      this.props.configId,
      'output',
      'tables',
      key,
      changeDescription
    );
  },

  onCancelEditMapping(key) {
    if (this.props.rowId) {
      return ConfigurationRowsActionCreators.cancelEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'output',
        'tables',
        key
      );
    }

    return InstalledComponentsActions.cancelEditingMapping(
      this.props.componentId,
      this.props.configId,
      'output',
      'tables',
      key
    );
  },

  onDeleteMapping(key) {
    const updatingTableId = this.props.value.get(key).get('destination');

    if (this.props.rowId) {
      return ConfigurationRowsActionCreators.deleteMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'output',
        'tables',
        key,
        `Delete output table mapping ${updatingTableId}`
      );
    }

    return InstalledComponentsActions.deleteMapping(
      this.props.componentId,
      this.props.configId,
      'output',
      'tables',
      key,
      `Delete output table mapping ${updatingTableId}`
    );
  }
});

export default TableOutputMapping;
