import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Confirm from './Confirm';

class OnetimePasswordWarning extends React.Component {
  componentWillUnmount() {
    if (this.props.hasPassword) {
      setTimeout(this.props.forgetPasswordFn);
    }
  }

  render() {
    if (this.props.hasPassword) {
      return (
        <Alert bsStyle="warning" className="mt-1 mb-0">
          Make sure to copy the password. You won&apos;t be able to see it again.
        </Alert>
      );
    }

    return (
      <p className="mt-1 mb-0">
        <FontAwesomeIcon icon="circle-exclamation" className="icon-addon-right" />
        Password was shown when you created the credentials.
        {this.props.resetPasswordFn && (
          <Confirm
            title="Reset Password"
            text="Are you sure you want to reset password?"
            buttonLabel="Reset"
            onConfirm={this.props.resetPasswordFn}
            isLoading={this.props.isResetting}
            closeAfterResolve
          >
            {' '}
            To reset password <button className="btn btn-link btn-link-inline">click here</button>.
          </Confirm>
        )}
      </p>
    );
  }
}

OnetimePasswordWarning.propTypes = {
  hasPassword: PropTypes.bool.isRequired,
  forgetPasswordFn: PropTypes.func.isRequired,
  resetPasswordFn: PropTypes.func,
  isResetting: PropTypes.bool
};

OnetimePasswordWarning.defaultProps = {
  isResetting: false
};

export default OnetimePasswordWarning;
