import { fromJS } from 'immutable';

const sourceInfoAction = function (configData) {
  if (!configData.hasIn(['parameters', '#token']) || !configData.hasIn(['parameters', 'url'])) {
    return false;
  }

  return fromJS({
    configData: {
      parameters: {
        '#token': configData.getIn(['parameters', '#token'], ''),
        url: configData.getIn(['parameters', 'url'], '')
      }
    }
  });
};

export default {
  sourceInfo: sourceInfoAction
};
