import { OrderedMap } from 'immutable';
import defaultsDeep from 'lodash.defaultsdeep';

import { configPoll, rowPoll } from '../../../utils/genericPolls';
import { configRequiredData } from '../../../utils/genericRequiredData';
import matchByWords from '../../../utils/matchByWords';
import InstalledComponentsStore from '../../components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '../ConfigurationRowsStore';
import IndexHeaderButton from '../react/components/IndexHeaderButton';
import RowHeaderButton from '../react/components/RowHeaderButton';
import Index from '../react/pages/Index';
import Row from '../react/pages/Row';
import rowVersionsActions from '../RowVersionsActionCreators';

// defaults
const defaults = {
  index: {},
  row: {
    hasState: false,
    detail: {
      onCreate: function () {
        return OrderedMap({});
      }
    },
    columns: [],
    searchFilter: function (row, query) {
      return matchByWords([row.get('name'), row.get('description')], query);
    }
  }
};

export default function (settings) {
  const settingsWithDefaults = defaultsDeep(settings, defaults);

  let route = {
    name: settingsWithDefaults.componentId,
    settings: settingsWithDefaults,
    path: `${settingsWithDefaults.componentId}/:config`,
    title: (routerState) => {
      const configId = routerState.getIn(['params', 'config']);
      return InstalledComponentsStore.getConfig(settingsWithDefaults.componentId, configId).get(
        'name'
      );
    },
    defaultRouteHandler: Index,
    headerButtonsHandler: IndexHeaderButton,
    poll: configPoll(settingsWithDefaults.componentId),
    requireData: [(params) => configRequiredData(settingsWithDefaults.componentId, params.config)],
    childRoutes: []
  };

  route.childRoutes.push({
    name: settingsWithDefaults.componentId + '-row',
    settings: settingsWithDefaults,
    path: 'rows/:row',
    title: (routerState) => {
      const configurationRow = ConfigurationRowsStore.get(
        settingsWithDefaults.componentId,
        routerState.getIn(['params', 'config']),
        routerState.getIn(['params', 'row'])
      );
      return `${configurationRow.get('name') || 'Untitled'} row`;
    },
    poll: rowPoll(settingsWithDefaults.componentId),
    requireData: [
      (params) =>
        rowVersionsActions.loadVersions(settingsWithDefaults.componentId, params.config, params.row)
    ],
    defaultRouteHandler: Row,
    headerButtonsHandler: RowHeaderButton
  });

  return route;
}
