import React from 'react';
import { Button } from 'react-bootstrap';
import type { List, Map } from 'immutable';
import { capitalize } from 'underscore.string';

import { KEBOOLA_ORCHESTRATOR } from '../../../../constants/componentIds';
import JobStatusIcon from '../../../../react/common/JobStatusIcon';
import JobStatusLabel from '../../../../react/common/JobStatusLabel';
import Loader from '../../../../react/common/Loader';
import Tooltip from '../../../../react/common/Tooltip';
import RoutesStore from '../../../../stores/RoutesStore';
import string from '../../../../utils/string';
import { routeNames } from '../../../queue/constants';
import ComponentConfigurationLink from './ComponentConfigurationLink';

const ConfigurationScheduleStatus = (props: {
  flows: Map<string, any>;
  lastJobs: List<any>;
  isLoading: boolean;
  hasFlows: boolean;
  hasNewQueue: boolean;
}) => {
  const [showAll, setShowAll] = React.useState(false);
  const flows = showAll ? props.flows : props.flows.slice(0, 5);
  const entity = props.hasFlows ? 'flow' : 'orchestration';

  return (
    <>
      <p className="line-height-24 mt-1">
        This component is already part of existing {string.pluralize(props.flows.count(), entity)}
      </p>
      <div className="table table-hover in-modal">
        <div className="thead">
          <div className="tr">
            <div className="th">Name</div>
            <div className="th">
              {props.isLoading && <Loader className="text-muted-light icon-addon-right" />}
              Last Use
            </div>
          </div>
        </div>
        <div className="tbody">
          {flows
            .map((flow: Map<string, any>) => {
              const lastJob = props.lastJobs.find((job) => job.get('config') === flow.get('id'));

              return (
                <ComponentConfigurationLink
                  key={flow.get('id')}
                  className="tr"
                  componentId={KEBOOLA_ORCHESTRATOR}
                  configId={flow.get('id')}
                  hasFlows={props.hasFlows}
                >
                  <div className="td color-main">{flow.get('name')}</div>
                  <div className="td">
                    {lastJob ? (
                      <Tooltip placement="top" tooltip="Open Job Detail">
                        <Button
                          bsStyle="link"
                          className="no-wrap no-underline"
                          onClick={(e: React.SyntheticEvent) => {
                            e.stopPropagation();
                            e.preventDefault();

                            RoutesStore.getRouter().transitionTo(
                              props.hasNewQueue ? routeNames.JOB_DETAIL : 'jobDetail',
                              { jobId: lastJob.get('id') }
                            );
                          }}
                        >
                          <span className="underline color-primary">Job</span>{' '}
                          <JobStatusLabel status={lastJob.get('status')} />
                          <JobStatusIcon
                            className="icon-addon-left"
                            status={lastJob.get('status')}
                          />
                        </Button>
                      </Tooltip>
                    ) : (
                      <span className="text-muted">{props.isLoading ? 'N/A' : 'No run yet'}</span>
                    )}
                  </div>
                </ComponentConfigurationLink>
              );
            })
            .toArray()}
          {props.flows.count() > flows.count() && (
            <div
              tabIndex={0}
              onClick={() => setShowAll(true)}
              className="tr no-hover text-muted clickable"
            >
              <div className="td">
                <span className="underline">Show All {capitalize(entity)}s</span>
              </div>
              <div className="td" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfigurationScheduleStatus;
