import React, { useState } from 'react';

import InlineEditTextInput from '../../../react/common/InlineEditTextInput';
import useStores from '../../../react/hooks/useStores';
import ApplicationStore from '../../../stores/ApplicationStore';
import actions from '../actions';
import store from '../store';

const InstanceNameEdit = () => {
  const { instanceDetail, readOnly } = useStores(
    () => ({
      instanceDetail: store.getStore().instanceDetail,
      readOnly: ApplicationStore.isReadOnly()
    }),
    [],
    [store, ApplicationStore]
  );
  const [currentValue, setCurrentValue] = useState(instanceDetail?.name);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  if (!instanceDetail) return null;
  if (readOnly) return <span>{instanceDetail.name}</span>;

  return (
    <InlineEditTextInput
      text={currentValue}
      placeholder="Name the template..."
      tooltipPlacement="bottom"
      isSaving={isSaving}
      isEditing={isEditing}
      isValid={!!currentValue?.trim()}
      isChanged={currentValue !== instanceDetail.name}
      onEditStart={() => setIsEditing(true)}
      onEditCancel={() => {
        setIsEditing(false);
        setCurrentValue(instanceDetail.name);
      }}
      onEditChange={(name: string) => setCurrentValue(name)}
      onEditSubmit={() => {
        setIsSaving(true);

        return actions
          .updateInstance(instanceDetail.instanceId, { name: currentValue?.trim() || '' })
          .finally(() => {
            setIsSaving(false);
            setIsEditing(false);
          });
      }}
    />
  );
};

export default InstanceNameEdit;
