import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import Switch from 'rc-switch';

import RowActionMenuItem from '../../react/common/RowActionMenuItem';
import SwitchStateLabel from './SwitchStateLabel';
import Tooltip from './Tooltip';

/** @type {any} */
const ActivateDeactivateSwitch = createReactClass({
  propTypes: {
    isActive: PropTypes.bool.isRequired,
    isPending: PropTypes.bool.isRequired,
    buttonDisabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    noTooltips: PropTypes.bool,
    activateTooltip: PropTypes.string,
    deactivateTooltip: PropTypes.string,
    mode: PropTypes.string,
    onKeyDown: PropTypes.func,
    withLabel: PropTypes.bool,
    customLabel: PropTypes.string,
    className: PropTypes.string
  },

  getDefaultProps() {
    return {
      isPending: false,
      withLabel: false,
      buttonDisabled: false,
      tooltipPlacement: 'top',
      activateTooltip: 'Enable',
      deactivateTooltip: 'Disable'
    };
  },

  render() {
    if (this.props.mode === 'menuitem') {
      return (
        <RowActionMenuItem
          onSelect={this.handleOnClick}
          disabled={this.props.buttonDisabled || this.props.isPending}
          onKeyDown={this.props.onKeyDown}
        >
          <FontAwesomeIcon
            className="bigger"
            icon={this.props.isActive ? 'toggle-large-on' : 'toggle-large-off'}
          />
          <SwitchStateLabel isActive={this.props.isActive} />
        </RowActionMenuItem>
      );
    }

    if (this.props.noTooltips || this.props.buttonDisabled) {
      return this.renderSwitch();
    }

    return (
      <Tooltip
        placement={this.props.tooltipPlacement}
        tooltip={this.props.isActive ? this.props.deactivateTooltip : this.props.activateTooltip}
      >
        {this.renderSwitch()}
      </Tooltip>
    );
  },

  renderSwitch() {
    return (
      <div
        className={classnames('switch-wrapper no-wrap', this.props.className, {
          clickable: !(this.props.buttonDisabled || this.props.isPending)
        })}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (this.props.buttonDisabled || this.props.isPending) {
            return;
          }

          this.props.onChange(!this.props.isActive);
        }}
      >
        {this.renderSwitchButton()}
        {this.props.withLabel && (
          <span className="switch-wrapper-label">
            {this.props.customLabel ?? (this.props.isActive ? 'Enabled' : 'Disabled')}
          </span>
        )}
      </div>
    );
  },

  renderSwitchButton() {
    return (
      <Switch
        prefixCls="switch"
        checked={this.props.isActive}
        disabled={this.props.buttonDisabled || this.props.isPending}
        className={classnames({ wider: this.props.mode !== 'menuitem' })}
      />
    );
  },

  handleOnClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onChange(!this.props.isActive);
  }
});

export default ActivateDeactivateSwitch;
