import React from 'react';
import classNames from 'classnames';
import createReactClass from 'create-react-class';

import Link from '../../../../react/common/RouterLink';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import DevBranchesStore from '../../../dev-branches/DevBranchesStore';
import BucketsStore from '../../stores/TransformationBucketsStore';
import NewTransformationBucketButton from './NewTransformationBucketButton';

const TransformationBucketButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, DevBranchesStore, BucketsStore)],

  getStateFromStores() {
    return {
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      hasBuckets: BucketsStore.getAll().count() > 0
    };
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <>
        {!this.state.isDevModeActive && (
          <Link
            to="sandbox"
            className={classNames('btn', 'btn-primary', { 'mr-1': this.state.hasBuckets })}
          >
            Sandbox
          </Link>
        )}
        {this.state.hasBuckets && <NewTransformationBucketButton />}
      </>
    );
  }
});

export default TransformationBucketButtons;
