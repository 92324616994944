import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';
import Select from '../../../react/common/Select';

const CreatePrimaryKeyModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    columns: PropTypes.object.isRequired,
    backend: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      primaryKey: List()
    };
  },

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState({ primaryKey: List() })}
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create primary key</Modal.Title>
            <ModalIcon.Plus />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Columns</ControlLabel>
              <Select
                multi
                autoFocus
                clearable={false}
                placeholder="Check one or more columns"
                value={this.state.primaryKey}
                onChange={(primaryKey) => this.setState({ primaryKey })}
                options={this.props.columns
                  .map((column) => ({ label: column, value: column }))
                  .toArray()}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isDisabled={this.state.primaryKey.isEmpty()}
              saveLabel="Create primary key"
              saveButtonType="submit"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  handleSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(this.state.primaryKey.toJS());
    this.props.onHide();
  }
});

export default CreatePrimaryKeyModal;
