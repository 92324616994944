import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import ComponentName from '../../../../../react/common/ComponentName';
import Link from '../../../../../react/common/RouterLink';
import { routeNames } from '../../../../queue/constants';

const jobScheduled = (component, job) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired
    },

    render() {
      return (
        <>
          <Link
            to={_.has(job, 'config') ? routeNames.JOB_DETAIL : 'jobDetail'}
            params={{ jobId: job.id }}
            onClick={this.props.onClick}
          >
            {component ? (
              <>
                <ComponentName component={component} capitalize>
                  {(name) => name}
                </ComponentName>{' '}
                job
              </>
            ) : (
              'Job'
            )}
          </Link>{' '}
          has been scheduled.
        </>
      );
    }
  });
};

export default jobScheduled;
