import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import MappingsMultiActionsHeader, {
  MappingsHeader
} from '../../../../../react/common/MappingsMultiActionsHeader';
import SortIcon from '../../../../../react/common/SortIcon';
import ConfigurationRowsActionCreators from '../../../../configurations/ConfigurationRowsActionCreators';
import InstalledComponentsActions from '../../../InstalledComponentsActionCreators';
import CollapsibleMapping from './CollapsibleMapping';
import FileOutputMappingModal from './FileOutputMappingModal';
import FileOutputMappingRow from './FileOutputMappingRow';

const FileOutputMapping = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    onDeleteMappings: PropTypes.func.isRequired,
    editingValue: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    pendingActions: PropTypes.object.isRequired,
    addBottomRadius: PropTypes.bool,
    rowId: PropTypes.string
  },

  getInitialState() {
    return {
      selectedMappings: Map(),
      sortBy: null,
      sortDesc: false
    };
  },

  render() {
    return (
      <CollapsibleMapping
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        configId={this.props.configId}
        rowId={this.props.rowId}
        mappingKey="file-output"
      >
        {this.renderBody}
      </CollapsibleMapping>
    );
  },

  renderBody(collapse) {
    return (
      <div
        className={classnames('box', {
          'no-mapping': this.props.value.isEmpty(),
          'bottom-radius': this.props.addBottomRadius
        })}
      >
        <div
          className={classnames('box-header above-table-with-buttons tw-block', {
            'is-sticky bg-color-white': this.props.value.count() > 5 && !collapse.isCollapsed
          })}
        >
          <div className="tw-flex tw-justify-between tw-items-center">
            <MappingsHeader type="output" componentId={this.props.componentId} storage="files" />
            <div className="flex-container">
              {!this.props.value.isEmpty() && collapse.renderToggleButton()}
              {!this.props.readOnly && (
                <FileOutputMappingModal
                  mode="create"
                  mapping={this.props.editingValue.get('new-mapping', Map())}
                  onChange={(newMapping) => this.onChangeMapping('new-mapping', newMapping)}
                  onCancel={() => this.onCancelEditMapping('new-mapping')}
                  onSave={() => this.onSaveMapping('new-mapping', 'Add file output')}
                  definedSources={this.props.value.map((item) => item.get('source'))}
                />
              )}
            </div>
          </div>

          {!this.props.value.isEmpty() && !collapse.isCollapsed && (
            <div className="tw-flex tw-items-center tw-py-2">
              <MappingsMultiActionsHeader
                hide={this.props.readOnly}
                disabled={this.props.value.isEmpty()}
                componentId={this.props.componentId}
                configurationId={this.props.configId}
                rowId={this.props.rowId}
                type="output"
                storage="files"
                allMappings={this.props.value}
                selectedMappings={this.state.selectedMappings}
                updateMappingsSelection={(selectedMappings) =>
                  this.setState(() => ({ selectedMappings }))
                }
                deleteMappings={this.props.onDeleteMappings}
                isSorted={this.state.sortBy === 'source'}
                isSortedDesc={this.state.sortDesc}
                onClick={() => this.handleChangeSort('source')}
              />
              <div
                className="tw-cursor-pointer tw-font-medium"
                onClick={() => this.handleChangeSort('tags')}
              >
                Assigned Tags
                <SortIcon
                  className="tw-ml-2"
                  isSorted={this.state.sortBy === 'tags'}
                  isSortedDesc={this.state.sortDesc}
                />
              </div>
            </div>
          )}
        </div>

        {collapse.renderCollapseSection(this.renderContent())}
      </div>
    );
  },

  renderContent() {
    if (this.props.value.isEmpty()) {
      return null;
    }

    return (
      <div className="box-content p-0">
        <div className="table table-hover overflow-break-anywhere">
          <div className="tbody">
            {this.props.value
              .map((output, index) => [output, index])
              .sort(this.handleSort)
              .map(([output, index]) => this.renderRow(output, index))
              .toArray()}
          </div>
        </div>
        <p className="mb-0 pl-2 pr-2 pb-1 help-block">
          All files from <code>out/files/</code> will be transferred. This allows you to configure
          the details or override the manifests.
        </p>
      </div>
    );
  },

  renderRow(output, key) {
    return (
      <FileOutputMappingRow
        key={key}
        value={output}
        readOnly={this.props.readOnly}
        editingValue={this.props.editingValue.get(key, Map())}
        mappingIndex={key}
        pendingActions={this.props.pendingActions}
        onEditStart={() => this.onEditStart(key)}
        onChange={(value) => this.onChangeMapping(key, value)}
        onSave={() => this.onSaveMapping(key, 'Update file output')}
        onCancel={() => this.onCancelEditMapping(key)}
        onDelete={() =>
          this.onDeleteMapping(key).then(() => this.setState({ selectedMappings: Map() }))
        }
        definedSources={this.props.value
          .filter((row) => row.get('source') !== output.get('source'))
          .map((item) => item.get('source'))}
        isSelected={!!this.state.selectedMappings.get(key)}
        toggleSelection={(checked) =>
          this.setState({ selectedMappings: this.state.selectedMappings.set(key, checked) })
        }
      />
    );
  },

  handleChangeSort(type) {
    if (type !== this.state.sortBy) {
      return this.setState({
        sortBy: type,
        sortDesc: false
      });
    }

    this.setState({
      sortDesc: !this.state.sortDesc
    });
  },

  handleSort([outputA], [outputB]) {
    if (!this.state.sortBy) {
      return 0;
    }

    let nameA = '';
    let nameB = '';

    if (this.state.sortBy === 'source') {
      nameA = outputA.get('source', '');
      nameB = outputB.get('source', '');
    }

    if (this.state.sortBy === 'tags') {
      nameA = outputA.getIn(['tags', 0], '');
      nameB = outputB.getIn(['tags', 0], '');
    }

    const sort = this.state.sortDesc ? -1 : 1;

    return nameA?.localeCompare(nameB) * sort;
  },

  onChangeMapping(key, value) {
    if (this.props.rowId) {
      return ConfigurationRowsActionCreators.changeEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'output',
        'files',
        key,
        value
      );
    }

    return InstalledComponentsActions.changeEditingMapping(
      this.props.componentId,
      this.props.configId,
      'output',
      'files',
      key,
      value
    );
  },

  onEditStart(key) {
    if (this.props.rowId) {
      return ConfigurationRowsActionCreators.startEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'output',
        'files',
        key
      );
    }

    return InstalledComponentsActions.startEditingMapping(
      this.props.componentId,
      this.props.configId,
      'output',
      'files',
      key
    );
  },

  onSaveMapping(key, changeDescription) {
    if (this.props.rowId) {
      return ConfigurationRowsActionCreators.saveEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'output',
        'files',
        key,
        changeDescription
      );
    }

    return InstalledComponentsActions.saveEditingMapping(
      this.props.componentId,
      this.props.configId,
      'output',
      'files',
      key,
      changeDescription
    );
  },

  onCancelEditMapping(key) {
    if (this.props.rowId) {
      return ConfigurationRowsActionCreators.cancelEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'output',
        'files',
        key
      );
    }

    return InstalledComponentsActions.cancelEditingMapping(
      this.props.componentId,
      this.props.configId,
      'output',
      'files',
      key
    );
  },

  onDeleteMapping(key) {
    if (this.props.rowId) {
      return ConfigurationRowsActionCreators.deleteMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'output',
        'files',
        key,
        'Delete file output'
      );
    }

    return InstalledComponentsActions.deleteMapping(
      this.props.componentId,
      this.props.configId,
      'output',
      'files',
      key,
      'Delete file output'
    );
  }
});

export default FileOutputMapping;
