import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const Loader = ({
  className = '',
  fixedWidth = true
}: {
  className?: string | Record<string, boolean>;
  fixedWidth?: boolean;
}) => {
  return (
    <FontAwesomeIcon
      spin
      icon="spinner"
      fixedWidth={fixedWidth}
      className={classnames(className)}
    />
  );
};

export default Loader;
