import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TabLink from '../../../../react/common/TabLink';
import TabNav from '../../../../react/common/TabNav';
import { shouldShowModelsTab } from '../../../model-services/helper';

const NAV_TABS = [
  { icon: 'gear', title: 'Transformations', routeName: 'transformations-v2' },
  { icon: 'code', title: 'Shared Code', routeName: 'shared-codes' },
  { icon: 'rotate', title: 'ML/AI Services', routeName: 'models' }
];

class TransformationTabs extends React.Component {
  render() {
    return (
      <>
        <div className="tabs-with-border-wrapper">
          <TabNav>
            {NAV_TABS.filter((tab) => {
              return tab.routeName === 'models' ? shouldShowModelsTab() : true;
            }).map((tab) => (
              <TabLink
                key={tab.routeName}
                to={tab.routeName}
                active={this.props.activeTab === tab.routeName}
              >
                <FontAwesomeIcon icon={tab.icon} className="icon-addon-right" />
                {tab.title}
              </TabLink>
            ))}
          </TabNav>
        </div>
        {this.props.children}
      </>
    );
  }
}

TransformationTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default TransformationTabs;
