import React from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import keyCodes from '../../constants/keyCodes';
import AnimationBar from './AnimationBar';
import ExternalLink from './ExternalLink';
import Loader from './Loader';
import Markdown from './Markdown';
import Tooltip from './Tooltip';

type DescriptionProps = {
  description: string;
  editing: string | null;
  onChange: (description: string) => void;
  handleSave: () => void;
  handleCancel: () => void;
  readOnly: boolean;
  isSaving: boolean;
  isEditing: boolean;
  placeholder?: string;
  inModal?: boolean;
  hasAllowedAi?: boolean;
  isGenerating?: boolean;
  handleGenerate?: () => void;
};

const GenerateButton = (props: {
  isSaving: boolean;
  handleGenerate?: () => void;
  isGenerating?: boolean;
}) => {
  return (
    <Tooltip
      placement="top"
      type="explanatory"
      tooltip="Automatically generates a description using advanced artificial intelligence algorithms. The generated description may not be perfect, but it can serve as a starting point for further editing."
      forceHide={props.isGenerating}
    >
      <Button
        bsStyle="link"
        className="btn-link-inline dark m-0"
        disabled={props.isGenerating || props.isSaving}
        onClick={props.handleGenerate}
      >
        {props.isGenerating ? (
          <Loader className="icon-addon-right" />
        ) : (
          <FontAwesomeIcon
            fixedWidth
            icon="brain-circuit"
            className="icon-addon-right color-cyan"
          />
        )}
        Generate Description with AI
      </Button>
    </Tooltip>
  );
};

const EditingArea = (props: {
  value: string;
  isSaving: boolean;
  onChange: (value: string) => void;
  handleSave: () => void;
  handleCancel: () => void;
  inModal?: boolean;
  hasAllowedAi?: boolean;
  isGenerating?: boolean;
  handleGenerate?: () => void;
}) => {
  return (
    <>
      {props.isGenerating ? (
        <AnimationBar />
      ) : (
        <Textarea
          autoFocus
          minRows={4}
          className="form-control"
          value={props.value}
          disabled={props.isSaving}
          onChange={(e) => props.onChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === keyCodes.ENTER && (e.ctrlKey || e.metaKey)) {
              props.handleSave();
            } else if (e.key === keyCodes.ESCAPE) {
              props.handleCancel();
            }
          }}
          cacheMeasurements
        />
      )}
      <div className="flex-container">
        <p className="text-muted m-0">
          <ExternalLink href="https://www.markdownguide.org/cheat-sheet">Markdown</ExternalLink> is
          supported
        </p>
        {props.hasAllowedAi && props.inModal && (
          <GenerateButton
            isSaving={props.isSaving}
            handleGenerate={props.handleGenerate}
            isGenerating={props.isGenerating}
          />
        )}
      </div>
    </>
  );
};

const StaticArea = (props: { value: string; readOnly: boolean; onClick: () => void }) => {
  return (
    <Markdown source={props.value}>
      {(markdown) => (
        <Tooltip
          placement="top"
          tooltip="Click to edit description"
          forceHide={props.readOnly || !props.value}
        >
          <div
            onClick={() => {
              if (props.readOnly || !!window.getSelection()?.toString()) {
                return null;
              }

              props.onClick();
            }}
            className={classnames({ clickable: !props.readOnly })}
          >
            {markdown}
          </div>
        </Tooltip>
      )}
    </Markdown>
  );
};

const DescriptionBox = (props: DescriptionProps) => {
  const renderSaveButtons = () => {
    return (
      <ButtonToolbar className={classnames({ 'block same-width': props.inModal })}>
        {props.hasAllowedAi && !props.inModal && (
          <GenerateButton
            isGenerating={props.isGenerating}
            isSaving={props.isSaving}
            handleGenerate={props.handleGenerate}
          />
        )}
        {(!props.inModal || !!props.description) && (
          <Button
            disabled={props.isSaving || props.isGenerating}
            onClick={props.handleCancel}
            {...(!props.inModal && { bsSize: 'small' })}
          >
            Cancel
          </Button>
        )}
        <Button
          bsStyle="success"
          disabled={props.isSaving || props.isGenerating || props.description === props.editing}
          onClick={props.handleSave}
          {...(!props.inModal && { bsSize: 'small' })}
        >
          {props.isSaving ? (
            <Loader className="icon-addon-right" />
          ) : (
            <FontAwesomeIcon icon="check-circle" className="icon-addon-right" fixedWidth />
          )}
          Save
        </Button>
      </ButtonToolbar>
    );
  };

  const renderBody = () => {
    if (props.isEditing && !props.readOnly) {
      return (
        <EditingArea
          value={props.editing || ''}
          inModal={props.inModal}
          isSaving={props.isSaving}
          isGenerating={props.isGenerating}
          onChange={props.onChange}
          hasAllowedAi={props.hasAllowedAi}
          handleSave={props.handleSave}
          handleCancel={props.handleCancel}
          handleGenerate={props.handleGenerate}
        />
      );
    }

    if (props.description) {
      return (
        <StaticArea
          value={props.description}
          readOnly={props.readOnly}
          onClick={() => props.onChange(props.description)}
        />
      );
    }

    return (
      <ButtonToolbar className="flex-container justify-center">
        <Button
          bsStyle="link"
          className="btn-link-inline dark m-0"
          onClick={() => props.onChange('')}
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right color-success" />
          Add Description
        </Button>
        {props.hasAllowedAi && (
          <>
            <span className="text-muted f-12 font-medium">or</span>
            <GenerateButton
              isGenerating={props.isGenerating}
              isSaving={props.isSaving}
              handleGenerate={props.handleGenerate}
            />
          </>
        )}
      </ButtonToolbar>
    );
  };

  if (props.readOnly && !props.description) {
    return null;
  }

  if (props.inModal) {
    return (
      <>
        <Modal.Body className="description-box">{renderBody()}</Modal.Body>
        {props.isEditing && <Modal.Footer>{renderSaveButtons()}</Modal.Footer>}
      </>
    );
  }

  return (
    <div className="box description-box">
      <div className="flex-container">
        {props.placeholder && <h3 className="f-16 line-height-24">{props.placeholder}</h3>}
        {props.isEditing && renderSaveButtons()}
      </div>
      {renderBody()}
    </div>
  );
};

export default DescriptionBox;
