import { List } from 'immutable';

export default (data) => {
  return data.updateIn(['parameters', 'tables'], List(), (tables) => {
    return tables.map((table) =>
      table
        .update((config) => {
          const updatedConfig = config.update('dbName', (name) => name.toUpperCase());
          const currentPK = config.getIn(['primaryKey', 0]);

          if (!currentPK) {
            return updatedConfig;
          }

          if (config.get('primaryKey').count() > 1) {
            return updatedConfig.set('primaryKey', List());
          }

          const forcedName = updatedConfig.get('dbName') + '_ID';
          return updatedConfig.setIn(['primaryKey', 0], forcedName).update('items', (items) => {
            return items.map((item) => {
              if (item.get('dbName') === currentPK) {
                return item.set('dbName', forcedName);
              } else if (item.get('dbName') === forcedName) {
                return item.set('dbName', item.get('name'));
              }
              return item;
            });
          });
        })
        .update('items', (items) => {
          return items.map((item) => item.update('dbName', (dbName) => dbName.toUpperCase()));
        })
    );
  });
};
