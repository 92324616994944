import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import Checkbox from '../../react/common/Checkbox';
import PasswordControl from '../../react/common/PasswordControl';
import Select from '../../react/common/Select';
import SshForm from '../../react/common/SshForm';

const SourceServer = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    imageParameters: PropTypes.instanceOf(Map).isRequired,
    value: PropTypes.shape({
      connectionType: PropTypes.oneOf(['FTP', 'FTPS', 'SFTP', '']),
      host: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      privateKey: PropTypes.string.isRequired,
      port: PropTypes.number.isRequired,
      ignorePassiveAddress: PropTypes.bool.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    const approvedHostnames = this.props.imageParameters.get('approvedHostnames', List());
    const isDisabled = this.props.disabled || this.props.readOnly;

    return (
      <Form horizontal>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Connection Type
          </Col>
          <Col sm={8}>
            <Select
              clearable={false}
              placeholder="Select type"
              value={this.props.value.connectionType}
              onChange={(value) => {
                let connectionType = { connectionType: value };

                if (approvedHostnames.isEmpty()) {
                  connectionType.port = value === 'SFTP' ? 22 : 21;
                }

                this.props.onChange(connectionType);
              }}
              options={[
                { value: 'FTP', label: 'FTP' },
                { value: 'FTPS', label: 'FTPS' },
                { value: 'SFTP', label: 'SFTP' }
              ]}
              disabled={isDisabled}
            />
          </Col>
        </FormGroup>
        {approvedHostnames.isEmpty() ? (
          <FormGroup>
            <Col componentClass={ControlLabel} sm={4}>
              Hostname/IP
            </Col>
            <Col sm={8}>
              <FormControl
                type="text"
                value={this.props.value.host}
                disabled={isDisabled}
                onChange={(e) => this.props.onChange({ host: e.target.value })}
              />
            </Col>
          </FormGroup>
        ) : (
          <FormGroup>
            <Col componentClass={ControlLabel} sm={4}>
              Hostname/Port
            </Col>
            <Col sm={8}>
              <Select
                placeholder="Select host"
                value={this.props.value.host}
                onChange={(value) => {
                  const option = approvedHostnames.find((option) => option.get('host') === value);
                  this.props.onChange({ host: option.get('host'), port: option.get('port') });
                }}
                options={approvedHostnames
                  .map((option) => ({
                    value: option.get('host'),
                    label: `${option.get('host')}:${option.get('port')}`
                  }))
                  .toArray()}
                disabled={isDisabled}
              />
            </Col>
          </FormGroup>
        )}
        {approvedHostnames.isEmpty() && (
          <FormGroup>
            <Col componentClass={ControlLabel} sm={4}>
              Port
            </Col>
            <Col sm={8}>
              <FormControl
                type="number"
                value={this.props.value.port}
                disabled={isDisabled}
                onChange={(e) => this.props.onChange({ port: e.target.value })}
              />
            </Col>
          </FormGroup>
        )}
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Username
          </Col>
          <Col sm={8}>
            <FormControl
              type="text"
              value={this.props.value.username}
              disabled={isDisabled}
              onChange={(e) => this.props.onChange({ username: e.target.value })}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Password
          </Col>
          <Col sm={8}>
            <PasswordControl
              value={this.props.value.password}
              disabled={isDisabled}
              onChange={(e) => this.props.onChange({ password: e.target.value })}
            />
          </Col>
        </FormGroup>
        <FormGroup className={this.props.value.connectionType !== 'SFTP' ? 'hidden' : ''}>
          <Col componentClass={ControlLabel} sm={4}>
            Private Key
          </Col>
          <Col sm={8}>
            <FormControl
              componentClass="textarea"
              rows="5"
              value={this.props.value.privateKey}
              disabled={isDisabled}
              onChange={(e) => this.props.onChange({ privateKey: e.target.value })}
            />
            <HelpBlock>
              Only to use with an SFTP connection type. You can paste a private key with or without{' '}
              <code>---BEGIN/END PRIVATE KEY---</code> block.
            </HelpBlock>
          </Col>
        </FormGroup>
        <FormGroup className="mb-0">
          <Col xs={8} xsOffset={4}>
            <Checkbox
              checked={this.props.value.ignorePassiveAddress}
              onChange={(checked) => this.props.onChange({ ignorePassiveAddress: checked })}
              disabled={isDisabled}
            >
              Ignore passive address
            </Checkbox>
            <HelpBlock>Ignore the IP address returned from the server on the PASV reply.</HelpBlock>
          </Col>
        </FormGroup>
        <SshForm
          isEnabled
          hasPassivePortRange
          showHelp={false}
          readOnly={isDisabled}
          data={fromJS(this.props.value.ssh)}
          onChange={(data) => this.props.onChange({ ssh: data.toJS() })}
        />
      </Form>
    );
  }
});

export default SourceServer;
