import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Map } from 'immutable';

import { isPartialJob as oldIsPartialJob } from '../../modules/jobs/helpers';

const JobPartialRunLabel = ({ job, hasNewQueue, className }) => {
  if (hasNewQueue || !oldIsPartialJob(job)) {
    return null;
  }

  return <div className={classnames('bigger-label uppercase', className)}>partial</div>;
};

JobPartialRunLabel.propTypes = {
  job: PropTypes.instanceOf(Map).isRequired,
  className: PropTypes.string,
  hasNewQueue: PropTypes.bool
};

export default JobPartialRunLabel;
