import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import { TDE_EXPORTER as componentId } from '../../../../../constants/componentIds';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import RoutesStore from '../../../../../stores/RoutesStore';
import InstalledComponentsActions from '../../../../components/InstalledComponentsActionCreators';
import FiltersDescription from '../../../../components/react/components/generic/FiltersDescription';
import FilterTableModal from '../../../../components/react/components/generic/TableFiltersOnlyModal';
import StorageApiTableLinkEx from '../../../../components/react/components/StorageApiTableLinkEx';
import InstalledComponentsStore from '../../../../components/stores/InstalledComponentsStore';
import StorageStore from '../../../../components/stores/StorageTablesStore';
import { dataPreview } from '../../../../storage/actions';
import OutputFileName from '../../../components/OutputFileName';
import * as columnsMetadata from '../../../helpers/columnsMetadata';
import * as tdeCommon from '../../../tdeCommon';
import ColumnsTable from './ColumnsTable';

const Table = createReactClass({
  mixins: [createStoreMixin(InstalledComponentsStore, StorageStore)],

  getInitialState() {
    return { loadingPreview: false, dataPreview: null };
  },

  componentDidMount() {
    this.editStart();

    if (!(this.state.columnsTypes && this.state.columnsTypes.count())) {
      this._updateLocalState(
        ['editing', this.state.tableId],
        columnsMetadata.prepareColumnsTypes(this.state.table)
      );
    }
  },

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const tableId = RoutesStore.getCurrentRouteParam('tableId');
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const localState = InstalledComponentsStore.getLocalState(componentId, configId);
    const typedefs = configData.getIn(['parameters', 'typedefs'], Map()) || Map();
    const editingData = localState.getIn(['editing', tableId], Map());

    let columnsTypes = typedefs.get(tableId, Map());

    // enforce empty Map(not List) workaround
    if (_.isEmpty(columnsTypes && columnsTypes.toJS())) {
      columnsTypes = Map();
    }

    return {
      configId,
      tableId,
      localState,
      columnsTypes,
      configData,
      editingData,
      allTables: StorageStore.getAll(),
      table: StorageStore.getTable(tableId, Map()),
      isSaving: InstalledComponentsStore.getSavingConfigData(componentId, configId),
      editingTdeFileName: tdeCommon.getEditingTdeFileName(configData, localState, tableId),
      mapping: tdeCommon.getTableMapping(configData || Map(), tableId),
      editingMapping: tdeCommon.getEditingTableMapping(configData, localState, tableId)
    };
  },

  render() {
    return (
      <>
        <div className="box info-row">
          <div className="info-row-section">
            <h4 className="first-line">Source table</h4>
            <div>
              <StorageApiTableLinkEx tableId={this.state.tableId} />
            </div>
          </div>
          <div className="info-row-section">
            <h4 className="first-line">
              Data Filter
              <Button
                className="btn-link-inline btn-link-muted icon-addon-left"
                onClick={() => {
                  let ls = this.state.localState.setIn(['filterModal'], Map({ show: true }));
                  ls = ls.set('mappingBackup', this.state.editingMapping);
                  return this._updateLocalStateDirectly(ls);
                }}
              >
                <FontAwesomeIcon icon="pen" />
              </Button>
            </h4>
            <div>
              <FiltersDescription value={this.state.editingMapping} rootClassName="" />
            </div>
          </div>
        </div>
        <div className="box info-row">
          <div className="info-row-section">
            <h4 className="first-line">Output file name</h4>
            <OutputFileName
              isSaving={!!this.state.isSaving}
              initialValue={this.state.editingTdeFileName}
              saveFn={this.saveOutputFileName}
            />
          </div>
        </div>
        <ColumnsTable
          table={this.state.table}
          columnsTypes={this.state.columnsTypes}
          dataPreview={this.state.dataPreview}
          editingData={this.state.localState.getIn(['editing', this.state.tableId])}
          onChange={this._handleEditChange}
          updateLocalStateFn={this._updateLocalState}
          isSaving={!!this.state.isSaving}
          loadingPreview={this.state.loadingPreview}
          loadPreviewFn={this.handleLoadDataPreview}
          onSave={() => this.save()}
          onReset={() => this.editStart()}
        />
        {this._renderFilterModal()}
      </>
    );
  },

  _renderFilterModal() {
    return (
      <FilterTableModal
        value={this.state.editingMapping}
        allTables={this.state.allTables}
        show={this.state.localState.getIn(['filterModal', 'show'], false)}
        onResetAndHide={() => {
          let ls = this.state.localState;
          ls = ls.setIn(['editingMappings', this.state.tableId], ls.get('mappingBackup'));
          ls = ls.set('filterModal', Map());
          this._updateLocalStateDirectly(ls);
        }}
        onOk={() => {
          this._updateLocalState(['filterModal'], Map());
          this.save();
        }}
        onSetMapping={(newMapping) => {
          this._updateLocalState(['editingMappings', this.state.tableId], newMapping);
        }}
        isSaving={!!this.state.isSaving}
        setLabel="Save"
      />
    );
  },

  _handleEditChange(data) {
    this._updateLocalState(['editing', this.state.tableId], data);
  },

  _updateLocalState(path, data) {
    const newLocalState = this.state.localState.setIn(path, data);
    InstalledComponentsActions.updateLocalState(
      componentId,
      this.state.configId,
      newLocalState,
      path
    );
  },

  _updateLocalStateDirectly(newLocalState) {
    InstalledComponentsActions.updateLocalState(componentId, this.state.configId, newLocalState);
  },

  handleLoadDataPreview() {
    if (!this.state.table || this.state.dataPreview || this.state.loadingPreview) {
      return null;
    }

    this.setState({ loadingPreview: true });
    dataPreview(this.state.tableId, { limit: 100 })
      .then((data) => this.setState({ dataPreview: fromJS(data), loadingPreview: false }))
      .catch((error) => {
        this.setState({ loadingPreview: false });
        throw error;
      });
  },

  saveOutputFileName(outputFileName) {
    return InstalledComponentsActions.saveComponentConfigData(
      componentId,
      this.state.configId,
      this.state.configData.setIn(
        ['parameters', 'tables', this.state.tableId, 'tdename'],
        outputFileName
      )
    );
  },

  editStart() {
    let prepareData = Map();

    this.state.table.get('columns', List()).forEach((column) => {
      if (this.state.columnsTypes.has(column)) {
        prepareData = prepareData.set(column, this.state.columnsTypes.get(column));
      } else {
        const emptyColumn = fromJS({ type: 'IGNORE' });
        prepareData = prepareData.set(column, emptyColumn);
      }
    });

    const path = ['editing', this.state.tableId];
    const newLocalState = this.state.localState.setIn(path, prepareData);
    return this.setLocalState(newLocalState, path);
  },

  save() {
    let { tableId, editingData } = this.state;

    editingData = editingData.filter((value) => {
      return !['IGNORE', ''].includes(value.get('type'));
    });

    let tableToSave = fromJS({
      source: tableId,
      columns: editingData.keySeq().toJS()
    });
    tableToSave = this.state.editingMapping
      .set('source', tableId)
      .set('columns', editingData.keySeq());

    let inputTables = this.state.configData.getIn(['storage', 'input', 'tables'], List());
    inputTables = inputTables.filter((table) => table.get('source') !== tableId);
    inputTables = inputTables.push(tableToSave);

    let configData = this.state.configData.setIn(['storage', 'input', 'tables'], inputTables);
    let typedefs = configData.getIn(['parameters', 'typedefs'], Map());

    if (_.isEmpty(typedefs && typedefs.toJS())) {
      typedefs = Map();
    }

    typedefs = typedefs.set(tableId, editingData);
    configData = configData.setIn(['parameters', 'typedefs'], typedefs);

    return InstalledComponentsActions.saveComponentConfigData(
      componentId,
      this.state.configId,
      configData
    );
  },

  setLocalState(newLocalState, path) {
    return InstalledComponentsActions.updateLocalState(
      componentId,
      this.state.configId,
      newLocalState,
      path
    );
  }
});

export default Table;
