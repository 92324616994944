import Promise from 'bluebird';

import { KEBOOLA_MLFLOW, KEBOOLA_SANDBOXES } from '../../constants/componentIds';
import dispatcher from '../../Dispatcher';
import ApplicationStore from '../../stores/ApplicationStore';
import SimpleError from '../../utils/errors/SimpleError';
import jobPoller from '../../utils/jobPoller';
import componentsActions from '../components/InstalledComponentsActionCreators';
import JobsApi from '../jobs/JobsApi';
import QueueApi from '../queue/api';
import { JOBS_STATUS } from '../queue/constants';
import ModelsApi from './api';
import { ActionTypes, PENDING_JOBS_QUERY } from './Constants';
import { getModelIdentifier } from './helper';
import ModelsStore from './ModelsStore';

const loadModels = () => {
  if (ModelsStore.getIsLoaded()) {
    loadModelsForce();
    return Promise.resolve();
  }

  return loadModelsForce();
};

const loadModelsForce = () => {
  dispatcher.handleViewAction({ type: ActionTypes.MODELS_LOAD });
  return ModelsApi.loadModels()
    .then((models) => {
      dispatcher.handleViewAction({ type: ActionTypes.MODELS_LOAD_SUCCESS, models });
      return null;
    })
    .catch((error) => {
      dispatcher.handleViewAction({ type: ActionTypes.MODELS_LOAD_ERROR });

      throw error;
    });
};

const updateModel = (name, version, description) => {
  return ModelsApi.updateModel(name, version, { description }).then((model) => {
    dispatcher.handleViewAction({ type: ActionTypes.MODEL_UPDATE_SUCCESS, model });
    return null;
  });
};

const linkConfiguration = (deploymentId, params) => {
  return ModelsApi.linkConfiguration(deploymentId, params).then(loadModelsForce);
};

const unlinkConfiguration = (deploymentId, componentId, configurationId) => {
  return ModelsApi.unlinkConfiguration(deploymentId, componentId, configurationId).then(
    loadModelsForce
  );
};

const startDeployment = (model) => {
  const tempId = getModelIdentifier(model);

  dispatcher.handleViewAction({ type: ActionTypes.MODEL_START_DEPLOYMENT, id: tempId });
  return componentsActions
    .runComponent({
      component: KEBOOLA_MLFLOW,
      data: {
        configData: {
          parameters: {
            task: 'start-deployment',
            modelName: model.get('name'),
            modelStage: model.get('stage'),
            modelVersion: model.get('version')
          }
        }
      }
    })
    .then((response) => {
      reloadWhenJobFinished(response).then(() => {
        dispatcher.handleViewAction({
          type: ActionTypes.MODEL_START_DEPLOYMENT_SUCCESS,
          id: tempId
        });
      });
      return null;
    });
};

const stopDeployment = (id) => {
  dispatcher.handleViewAction({ type: ActionTypes.MODEL_STOP_DEPLOYMENT, id });
  return componentsActions
    .runComponent({
      component: KEBOOLA_MLFLOW,
      data: { configData: { parameters: { task: 'stop-deployment', id } } }
    })
    .then((response) => {
      reloadWhenJobFinished(response).then(() => {
        dispatcher.handleViewAction({ type: ActionTypes.MODEL_STOP_DEPLOYMENT_SUCCESS, id });
      });
      return null;
    });
};

const loadPendingJobs = () => {
  if (ApplicationStore.hasNewQueue()) {
    return QueueApi.getJobs({
      component: KEBOOLA_SANDBOXES,
      status: [JOBS_STATUS.CREATED, JOBS_STATUS.PROCESSING, JOBS_STATUS.WAITING]
    }).then((jobs) => {
      dispatcher.handleViewAction({ type: ActionTypes.MODELS_PROCESSING_JOBS_LOADED, jobs });
      return null;
    });
  }

  return JobsApi.getJobsByQuery(PENDING_JOBS_QUERY).then((jobs) => {
    dispatcher.handleViewAction({ type: ActionTypes.MODELS_PROCESSING_JOBS_LOADED, jobs });
    return null;
  });
};

const reloadWhenJobFinished = ({ url }) => {
  Promise.delay(1000).then(loadPendingJobs);
  return jobPoller
    .poll(url)
    .catch((error) => {
      throw new SimpleError('ML/AI Services job failed', error.message);
    })
    .then(loadModelsForce)
    .then(loadPendingJobs);
};

export {
  loadModels,
  loadModelsForce,
  updateModel,
  loadPendingJobs,
  startDeployment,
  stopDeployment,
  linkConfiguration,
  unlinkConfiguration
};
