import React, { useState } from 'react';
import {
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { EXPERIMENTAL } from '../../../constants/componentFlags';
import { KEBOOLA_EX_SAMPLE_DATA, KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import * as externalClasses from '../../../constants/external';
import ComponentAuthor from '../../../react/common/ComponentAuthor';
import ComponentBadges, { hasStageFlag } from '../../../react/common/ComponentBadges';
import ComponentIcon from '../../../react/common/ComponentIcon';
import ComponentLicence from '../../../react/common/ComponentLicence';
import ComponentType from '../../../react/common/ComponentType';
import ExperimentalComponentInfoAlert from '../../../react/common/ExperimentalComponentInfoAlert';
import ExternalLink from '../../../react/common/ExternalLink';
import Loader from '../../../react/common/Loader';
import ModalIcon from '../../../react/common/ModalIcon';
import OptionalFormLabel from '../../../react/common/OptionalFormLabel';
import Select from '../../../react/common/Select';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import {
  ensureComponentWithDetails,
  getNewComponentTypeLabel,
  resolveRouterLinkParams,
  saveFolderToMetadata
} from '../../components/helpers';
import { createConfiguration, createSampleDataConfiguration } from '../actions';
import { hasSampleDataAvailable } from '../helpers';

const PAGES = {
  TYPE: 'type',
  FORM: 'form'
};

const TYPES = {
  BLANK: 'blank',
  SAMPLE_DATA: 'demo'
};

const ComponentBoxModal = ({
  show,
  allComponents,
  component,
  readOnly,
  showButtons = true,
  hasConfigurations = false,
  allowFolder = false,
  folders = List(),
  onCreate,
  onHide
}: {
  show: boolean;
  readOnly: boolean;
  allComponents?: Map<string, any>;
  component: Map<string, any>;
  onHide: () => void;
  onCreate?: (componentId: string, configId: string) => Promise<any>;
  hasConfigurations?: boolean;
  allowFolder?: boolean;
  showButtons?: boolean;
  folders?: List<string>;
}) => {
  const isFlow = component.get('id') === KEBOOLA_ORCHESTRATOR;
  const hasSampleData = !!allComponents && hasSampleDataAvailable(allComponents, component);
  const showOnlyForm = isFlow || (!readOnly && showButtons && hasConfigurations);
  const flags = component.get('flags', List());

  const [page, setPage] = useState(PAGES.TYPE);
  const [type, setType] = useState(TYPES.BLANK);
  const [name, setName] = useState('');
  const [folder, setFolder] = useState('');
  const [description, setDescription] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const renderComponentInfo = () => {
    if (!component.has('data')) {
      return (
        <Modal.Body>
          <Loader className="icon-addon-right" />
          Loading component details...
        </Modal.Body>
      );
    }

    return (
      <>
        <Modal.Body>
          {component.get('flags').includes(EXPERIMENTAL) && <ExperimentalComponentInfoAlert />}
          <div className="flex-container flex-start mb-2">
            <div className="tw-mr-10">
              <p className="text-muted">Type</p>
              <div className="f-16">
                <ComponentType
                  labelClassName="font-medium color-main"
                  type={component.get('type')}
                />
              </div>
            </div>
            <div className="tw-mr-10">
              <p className="text-muted">Author</p>
              <div className="f-16">
                <ComponentAuthor
                  is3rdParty={flags.includes('3rdParty')}
                  contact={component.getIn(['data', 'vendor', 'contact'], List())}
                />
              </div>
            </div>
            {hasStageFlag(component.get('flags')) && (
              <div>
                <p className="text-muted">Stage</p>
                <ComponentBadges flags={component.get('flags')} className="tw-mb-0.5" />
              </div>
            )}
          </div>
          <p className="text-muted">{component.get('description')}</p>
          <ComponentLicence href={component.getIn(['data', 'vendor', 'licenseUrl'])} />
          {(component.get('documentationUrl') ||
            component.getIn(['data', 'vendor', 'licenseUrl'])) && (
            <div className="mt-2">
              {component.get('documentationUrl') && (
                <p>
                  <FontAwesomeIcon icon="book-blank" className="text-muted icon-addon-right" />
                  <ExternalLink href={component.get('documentationUrl')}>
                    Documentation
                  </ExternalLink>
                </p>
              )}
              {component.getIn(['data', 'vendor', 'licenseUrl']) && (
                <p>
                  <FontAwesomeIcon icon="file-lines" className="text-muted icon-addon-right" />
                  <ExternalLink href={component.getIn(['data', 'vendor', 'licenseUrl'])}>
                    Licence
                  </ExternalLink>
                </p>
              )}
            </div>
          )}
        </Modal.Body>
        {showButtons && !readOnly && (
          <Modal.Footer>
            <ButtonToolbar className="block">
              <Button
                bsStyle="success"
                onClick={() => {
                  setType(TYPES.BLANK);
                  setPage(PAGES.FORM);
                }}
              >
                <FontAwesomeIcon icon="plus" className="icon-addon-right" fixedWidth />
                Connect to my data
              </Button>
              {hasSampleData && (
                <Button
                  bsStyle="primary"
                  onClick={() => {
                    setType(TYPES.SAMPLE_DATA);
                    setPage(PAGES.FORM);
                  }}
                >
                  <FontAwesomeIcon icon="plus" className="icon-addon-right" fixedWidth />
                  Use with demo data
                </Button>
              )}
            </ButtonToolbar>
          </Modal.Footer>
        )}
      </>
    );
  };

  const renderForm = () => {
    return (
      <Form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();

          setIsCreating(true);
          Promise.resolve()
            .then(() => {
              return type === TYPES.SAMPLE_DATA
                ? createSampleDataConfiguration(component.get('id'), name, description)
                : createConfiguration(component.get('id'), name, description);
            })
            .tap((response) => {
              if (!!folder) {
                return saveFolderToMetadata(component.get('id'), response.id, folder);
              }
            })
            .then((response) => {
              const componentId =
                type === TYPES.SAMPLE_DATA ? KEBOOLA_EX_SAMPLE_DATA : component.get('id');

              if (onCreate) {
                return onCreate(componentId, response.id).then(onHide);
              }

              const linkParams = resolveRouterLinkParams(
                componentId,
                response.id,
                null,
                ApplicationStore.hasFlows()
              );

              RoutesStore.getRouter().transitionTo(linkParams?.to, linkParams?.params);
              return null;
            })
            .catch((error) => {
              setIsCreating(false);
              throw error;
            });
        }}
      >
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              placeholder="Name your configuration"
              disabled={isCreating || readOnly}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              Description <OptionalFormLabel />
            </ControlLabel>
            <FormControl
              componentClass="textarea"
              value={description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
              placeholder="Describe your configuration"
              disabled={isCreating || readOnly}
            />
          </FormGroup>
          {allowFolder && (
            <FormGroup>
              <ControlLabel>
                Folder <OptionalFormLabel />
              </ControlLabel>
              <Select
                allowCreate
                value={folder}
                onChange={setFolder}
                placeholder="Select or create a folder"
                promptTextCreator={(label) => `Create folder "${label}"`}
                options={folders
                  .sortBy((folder) => folder?.toLowerCase())
                  .map((folder) => ({ label: folder, value: folder }))
                  .toArray()}
              />
            </FormGroup>
          )}
        </Modal.Body>
        {showButtons && !readOnly && (
          <Modal.Footer>
            <ButtonToolbar className="block">
              {!showOnlyForm && (
                <Button onClick={() => setPage(PAGES.TYPE)}>
                  <FontAwesomeIcon icon="arrow-left" fixedWidth />
                </Button>
              )}
              <Button
                type="submit"
                bsStyle="success"
                className={
                  type === TYPES.SAMPLE_DATA
                    ? `${externalClasses.useDemoDataComponentType(component.get('type'))}`
                    : `${externalClasses.connectToMyDataComponentType(component.get('type'))}`
                }
                disabled={isCreating || !name.trim().length}
              >
                {isCreating ? (
                  <Loader className="btn-icon" />
                ) : (
                  <FontAwesomeIcon icon="circle-check" className="btn-icon" fixedWidth />
                )}
                Create {isFlow ? 'Flow' : 'Configuration'}
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        )}
      </Form>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onEnter={() => {
        setPage(showOnlyForm ? PAGES.FORM : PAGES.TYPE);
        setType(TYPES.BLANK);
        setName(
          isFlow
            ? `My Flow`
            : `My ${component.get('name')} ${getNewComponentTypeLabel(component.get('type'))}`
        );
        setDescription('');
        ensureComponentWithDetails(component.get('id'));
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{component.get('name')}</Modal.Title>
        {component.get('id') === KEBOOLA_ORCHESTRATOR ? (
          <ModalIcon icon="bars-staggered" color="green" bold />
        ) : (
          <ComponentIcon className="circle-icon bigger-icon modal-icon" component={component} />
        )}
      </Modal.Header>
      {page === PAGES.TYPE ? renderComponentInfo() : renderForm()}
    </Modal>
  );
};

export default ComponentBoxModal;
