export default {
  ActionTypes: {
    CONFIGURATION_ROWS_CREATE_START: 'CONFIGURATION_ROWS_CREATE_START',
    CONFIGURATION_ROWS_CREATE_SUCCESS: 'CONFIGURATION_ROWS_CREATE_SUCCESS',
    CONFIGURATION_ROWS_CREATE_ERROR: 'CONFIGURATION_ROWS_CREATE_ERROR',

    CONFIGURATION_ROWS_UPDATE_SUCCESS: 'CONFIGURATION_ROWS_UPDATE_SUCCESS',

    CONFIGURATION_ROWS_DELETE_START: 'CONFIGURATION_ROWS_DELETE_START',
    CONFIGURATION_ROWS_DELETE_SUCCESS: 'CONFIGURATION_ROWS_DELETE_SUCCESS',
    CONFIGURATION_ROWS_DELETE_ERROR: 'CONFIGURATION_ROWS_DELETE_ERROR',

    CONFIGURATION_ROWS_DISABLE_START: 'CONFIGURATION_ROWS_DISABLE_START',
    CONFIGURATION_ROWS_DISABLE_SUCCESS: 'CONFIGURATION_ROWS_DISABLE_SUCCESS',
    CONFIGURATION_ROWS_DISABLE_ERROR: 'CONFIGURATION_ROWS_DISABLE_ERROR',

    CONFIGURATION_ROWS_ENABLE_START: 'CONFIGURATION_ROWS_ENABLE_START',
    CONFIGURATION_ROWS_ENABLE_SUCCESS: 'CONFIGURATION_ROWS_ENABLE_SUCCESS',
    CONFIGURATION_ROWS_ENABLE_ERROR: 'CONFIGURATION_ROWS_ENABLE_ERROR',

    CONFIGURATION_ROWS_UPDATE_JSON_CONFIGURATION: 'CONFIGURATION_ROWS_UPDATE_JSON_CONFIGURATION',
    CONFIGURATION_ROWS_RESET_JSON_CONFIGURATION: 'CONFIGURATION_ROWS_RESET_JSON_CONFIGURATION',

    CONFIGURATION_ROWS_SAVE_JSON_CONFIGURATION_START:
      'CONFIGURATION_ROWS_SAVE_JSON_CONFIGURATION_START',
    CONFIGURATION_ROWS_SAVE_JSON_CONFIGURATION_SUCCESS:
      'CONFIGURATION_ROWS_SAVE_JSON_CONFIGURATION_SUCCESS',
    CONFIGURATION_ROWS_SAVE_JSON_CONFIGURATION_ERROR:
      'CONFIGURATION_ROWS_SAVE_JSON_CONFIGURATION_ERROR',

    CONFIGURATION_ROWS_UPDATE_CONFIGURATION: 'CONFIGURATION_ROWS_UPDATE_CONFIGURATION',
    CONFIGURATION_ROWS_RESET_CONFIGURATION: 'CONFIGURATION_ROWS_RESET_CONFIGURATION',

    CONFIGURATION_ROWS_SAVE_CONFIGURATION_START: 'CONFIGURATION_ROWS_SAVE_CONFIGURATION_START',
    CONFIGURATION_ROWS_SAVE_CONFIGURATION_SUCCESS: 'CONFIGURATION_ROWS_SAVE_CONFIGURATION_SUCCESS',
    CONFIGURATION_ROWS_SAVE_CONFIGURATION_ERROR: 'CONFIGURATION_ROWS_SAVE_CONFIGURATION_ERROR',

    CONFIGURATION_ROWS_JSON_EDITOR_OPEN: 'CONFIGURATION_ROWS_JSON_EDITOR_OPEN',
    CONFIGURATION_ROWS_JSON_EDITOR_CLOSE: 'CONFIGURATION_ROWS_JSON_EDITOR_CLOSE',

    CONFIGURATION_ROWS_MAPPING_EDITING_START: 'CONFIGURATION_ROWS_MAPPING_EDITING_START',
    CONFIGURATION_ROWS_MAPPING_EDITING_CHANGE: 'CONFIGURATION_ROWS_MAPPING_EDITING_CHANGE',
    CONFIGURATION_ROWS_MAPPING_EDITING_CANCEL: 'CONFIGURATION_ROWS_MAPPING_EDITING_CANCEL',
    CONFIGURATION_ROWS_MAPPING_SAVE_START: 'CONFIGURATION_ROWS_MAPPING_SAVE_START',
    CONFIGURATION_ROWS_MAPPING_SAVE_SUCCESS: 'CONFIGURATION_ROWS_MAPPING_SAVE_SUCCESS',
    CONFIGURATION_ROWS_MAPPING_SAVE_ERROR: 'CONFIGURATION_ROWS_MAPPING_SAVE_ERROR',
    CONFIGURATION_ROWS_MAPPING_DELETE_START: 'CONFIGURATION_ROWS_MAPPING_DELETE_START',
    CONFIGURATION_ROWS_MAPPING_DELETE_SUCCESS: 'CONFIGURATION_ROWS_MAPPING_DELETE_SUCCESS',
    CONFIGURATION_ROWS_MAPPING_DELETE_ERROR: 'CONFIGURATION_ROWS_MAPPING_DELETE_ERROR'
  }
};
