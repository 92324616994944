import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import Duration from 'dayjs/plugin/duration';
import IsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import MinMax from 'dayjs/plugin/minMax';
import RelativeTime from 'dayjs/plugin/relativeTime';
import Timezone from 'dayjs/plugin/timezone';
import ToObject from 'dayjs/plugin/toObject';
import UTC from 'dayjs/plugin/utc';
import memoizeOne from 'memoize-one';

export const DATE_FORMAT = 'MMM D, YYYY';
export const DATE_FORMAT_WITHOUT_YEAR = 'MMM D';
export const TIME_FORMAT = 'H:mm';
export const TIME_FORMAT_WITH_SECONDS = 'H:mm:ss';

export const shouldPreferHour12 = memoizeOne(() => {
  const language = window.navigator.languages[0] || window.navigator.language;
  return new Intl.DateTimeFormat(language, { hour: 'numeric' }).resolvedOptions().hour12;
});

const defaultFormatPlugin = (option, Dayjs) => {
  const getFormat = (format) => {
    if (!shouldPreferHour12()) {
      return format;
    }

    switch (format) {
      case `${DATE_FORMAT} ${TIME_FORMAT}`:
        return 'MMM D, YYYY h:mm A';

      case `${DATE_FORMAT} ${TIME_FORMAT_WITH_SECONDS}`:
        return 'MMM D, YYYY h:mm:ss A';

      case `${DATE_FORMAT_WITHOUT_YEAR} ${TIME_FORMAT}`:
        return 'MMM D h:mm A';

      case `${DATE_FORMAT_WITHOUT_YEAR} ${TIME_FORMAT_WITH_SECONDS}`:
        return 'MMM D h:mm:ss A';

      case TIME_FORMAT_WITH_SECONDS:
        return 'h:mm:ss A';

      case TIME_FORMAT:
        return 'h:mm A';

      default:
        return format;
    }
  };

  const originalFormat = Dayjs.prototype.format;
  Dayjs.prototype.format = function (format) {
    return originalFormat.bind(this)(getFormat(format));
  };
};

[
  RelativeTime,
  Duration,
  UTC,
  Timezone,
  ToObject,
  MinMax,
  CustomParseFormat,
  IsSameOrBefore
].forEach((plugin) => dayjs.extend(plugin));

dayjs.extend(defaultFormatPlugin);

export default dayjs;
