import useStores from '../../react/hooks/useStores';
import DevBranchesStore from './DevBranchesStore';

const DevBranchTitle = () => {
  const mergeRequest = useStores(
    () => DevBranchesStore.getCurrentDevBranchMergeRequest(),
    [],
    [DevBranchesStore]
  );

  return mergeRequest.get('title', 'Branch diff');
};

export default DevBranchTitle;
