import { generateSessionToken, loadProject } from '../settings/actions';
import { sessionTokenPoller } from '../settings/routes';
import { routeNames } from './constants';
import UpgradePage from './UpgradePage';

export default {
  name: routeNames.UPGRADE_PAGE,
  defaultRouteHandler: UpgradePage,
  requireData: [() => generateSessionToken().then(() => loadProject())],
  poll: sessionTokenPoller
};
