import React from 'react';
import createReactClass from 'create-react-class';
import { List, Map, OrderedMap } from 'immutable';

import { EXCLUDE_FROM_NEW_LIST } from '../../constants/componentFlags';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '../../constants/features';
import Directory from '../../react/common/Directory';
import { SORT } from '../../react/common/SortSelect';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import ComponentBox from './components/ComponentBox';
import ComponentBoxModal from './components/ComponentBoxModal';
import { allowedTypes } from './constants';
import { getComponentsFiltered, mergeSampleDataToConfigurations, sortComponents } from './helpers';

const Index = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore, ComponentsStore)],

  getStateFromStores() {
    const allComponents = ComponentsStore.getAll();

    return {
      allComponents,
      readOnly: ApplicationStore.isReadOnly(),
      installedComponents: mergeSampleDataToConfigurations(
        InstalledComponentsStore.getAll(),
        allComponents
      ),
      components: allComponents.filter((component) => {
        return (
          !component.get('flags').includes(EXCLUDE_FROM_NEW_LIST) &&
          allowedTypes.includes(component.get('type'))
        );
      }),
      componentCategories: ComponentsStore.getComponentsCategories(),
      hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED
      )
    };
  },

  getInitialState() {
    return {
      showDetailModal: false,
      detailComponentId: null
    };
  },

  render() {
    return (
      <>
        <Directory
          entity="Component"
          getItems={(filters) => {
            return {
              items: sortComponents(
                getComponentsFiltered(
                  this.state.components,
                  filters.get('q', ''),
                  filters.get('categories', List()),
                  filters.get('types', List())
                ),
                filters.get('sortBy')
              ),
              types: getComponentsFiltered(
                this.state.components,
                filters.get('q', ''),
                filters.get('categories', List()),
                List()
              ),
              categories: getComponentsFiltered(
                this.state.components,
                filters.get('q', ''),
                List(),
                filters.get('types', List())
              )
            };
          }}
          renderItem={this.renderComponentBox}
          categories={this.state.componentCategories}
          types={allowedTypes}
          supportedSortOptions={[SORT.A_Z, SORT.Z_A, SORT.POPULAR]}
        />
        <ComponentBoxModal
          readOnly={this.state.readOnly}
          show={this.state.showDetailModal}
          allComponents={this.state.allComponents}
          component={this.state.allComponents.get(this.state.detailComponentId, Map())}
          hasConfigurations={this.state.installedComponents.has(this.state.detailComponentId)}
          onHide={() => this.setState({ showDetailModal: false })}
        />
      </>
    );
  },

  renderComponentBox(component, query) {
    return (
      <ComponentBox
        key={component.get('id')}
        component={component}
        configurations={this.state.installedComponents.getIn(
          [component.get('id'), 'configurations'],
          OrderedMap()
        )}
        readOnly={this.state.readOnly}
        showDetail={this.handleShowDetail}
        query={query}
        hasSnowflakePartnerConnectLimited={this.state.hasSnowflakePartnerConnectLimited}
      />
    );
  },

  handleShowDetail(component) {
    this.setState({ showDetailModal: true, detailComponentId: component.get('id') });
  }
});

export default Index;
