import React from 'react';
import { Button } from 'react-bootstrap';
import type { List } from 'immutable';

type Props = {
  sourcePrimaryKey: List<string> | undefined;
  onCopy: (primaryKey: List<string>) => void;
};

const CopyPrimaryKeyButton = ({ sourcePrimaryKey, onCopy }: Props) => {
  return (
    <>
      <Button
        bsStyle="link"
        className="btn-link-inline"
        onClick={() => onCopy(sourcePrimaryKey!)}
        disabled={!sourcePrimaryKey || sourcePrimaryKey.isEmpty()}
      >
        Fill in the primary key
      </Button>{' '}
      from the Storage table.
    </>
  );
};

export default CopyPrimaryKeyButton;
