import React from 'react';
import PropTypes from 'prop-types';

const TerminalWithKbcInit = ({ storageApiHost }) => {
  return (
    <div className="terminal-with-kbc-init">
      <strong>kbc init</strong>
      <br />
      Enter Keboola Storage API host.
      <br />? API host {storageApiHost}
      <br />
      Enter Keboola Storage API token. The value will be hidden.
      <br />
      ? API token ********************************
      <br />
    </div>
  );
};

TerminalWithKbcInit.propTypes = {
  storageApiHost: PropTypes.string.isRequired
};

export default TerminalWithKbcInit;
