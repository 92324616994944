import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

import CsvDelimiterInput from '../../../react/common/CsvDelimiterInput';
import Select from '../../../react/common/Select';

const columnsFromOptions = [
  {
    label: 'CSV file(s) contain(s) a header row',
    value: 'header'
  },
  {
    label: 'Set column names manually',
    value: 'manual'
  },
  {
    label: 'Generate column names as col_1, col_2, ...',
    value: 'auto'
  }
];

class CsvSettings extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">CSV Settings</h3>
        </div>
        <div className="box-content">
          <CsvDelimiterInput
            type="text"
            value={this.props.value.delimiter}
            onChange={(value) => {
              this.props.onChange({ delimiter: value });
            }}
            disabled={this.props.disabled}
            help={
              <span>
                Field delimiter used in the CSV file. Use <code>\t</code> for tabulator.
              </span>
            }
          />
          <FormGroup>
            <Col componentClass={ControlLabel} xs={4}>
              Enclosure
            </Col>
            <Col xs={8}>
              <FormControl
                type="text"
                value={this.props.value.enclosure}
                onChange={(e) => {
                  this.props.onChange({ enclosure: e.target.value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>Field enclosure used in the CSV file.</HelpBlock>
            </Col>
          </FormGroup>
          <div className="form-group">
            <label className="col-xs-4 control-label">Header</label>
            <div className="col-xs-8">
              <Select
                name="columnsFrom"
                value={this.props.value.columnsFrom}
                searchable={false}
                clearable={false}
                options={columnsFromOptions}
                disabled={this.props.disabled}
                onChange={(value) => {
                  this.props.onChange({ columnsFrom: value });
                }}
              />
            </div>
          </div>
          {this.props.value.columnsFrom === 'manual' && (
            <div className="form-group">
              <label className="col-xs-4 control-label">Column Names</label>
              <div className="col-xs-8">
                <Select
                  name="columns"
                  value={this.props.value.columns}
                  multi
                  allowCreate
                  placeholder="Add a column"
                  onChange={(value) => {
                    this.props.onChange({ columns: value });
                  }}
                  disabled={this.props.disabled || this.props.value.columnsFrom === 'header'}
                />
                <span className="help-block">Specify the columns of the headerless files.</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

CsvSettings.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default CsvSettings;
