import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Tooltip from './Tooltip';

const ModalActionButton = ({ icon, title, description, onClick, isDisabled, disabledReason }) => {
  const content = (
    <div className="modal-action-button">
      <Button
        onClick={() => !isDisabled && onClick()}
        className={classNames({ disabled: isDisabled })}
        block
      >
        <div className="modal-action-button-icon">{icon}</div>
        <div className="modal-action-button-text">
          <strong>{title}</strong>
          {description && <small>{description}</small>}
        </div>
        <div className="modal-action-button-arrow">
          <FontAwesomeIcon icon="angle-right" />
        </div>
      </Button>
    </div>
  );

  if (isDisabled) {
    return (
      <Tooltip tooltip={disabledReason} placement="top" type="explanatory">
        <div className="opacity-half">{content}</div>
      </Tooltip>
    );
  }

  return content;
};

ModalActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node,
  isDisabled: PropTypes.bool,
  disabledReason: PropTypes.string
};

export default ModalActionButton;
