import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ConfirmButtons from '../../../../../react/common/ConfirmButtons';
import ChangedSinceInput from './ChangedSinceFilterInput';
import DataFilterRow from './DataFilterRow';

const TableFiltersOnlyModal = createReactClass({
  propTypes: {
    show: PropTypes.bool,
    onOk: PropTypes.func,
    onSetMapping: PropTypes.func,
    onResetAndHide: PropTypes.func,
    value: PropTypes.object.isRequired,
    allTables: PropTypes.object,
    saveStyle: PropTypes.string,
    setLabel: PropTypes.string,
    isSaving: PropTypes.bool
  },

  getDefaultProps() {
    return {
      saveStyle: 'primary',
      isSaving: false,
      setLabel: 'Set'
    };
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onResetAndHide} bsSize="large">
        <Modal.Header closeButton>
          <Modal.Title>Table Data Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-horizontal clearfix">
            <ChangedSinceInput
              mapping={this.props.value}
              disabled={false}
              onChange={this.onChangeMapping}
            />
            <DataFilterRow
              value={this.props.value}
              allTables={this.props.allTables}
              disabled={false}
              onChange={this.onChangeMapping}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveStyle={this.props.saveStyle}
            saveLabel={this.props.setLabel}
            onSave={this.handleSave}
            isSaving={this.props.isSaving}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  handleSave() {
    this.props.onOk();
  },

  onChangeMapping(newMapping) {
    this.props.onSetMapping(newMapping);
  }
});

export default TableFiltersOnlyModal;
