import React from 'react';
import createReactClass from 'create-react-class';

import Link from '../../../../../react/common/RouterLink';
import { routeNames } from '../../../../components-directory/constants';

const configurationRowDeleted = (componentId, configurationId, row) => {
  return createReactClass({
    render() {
      return (
        <>
          Row{' '}
          <Link
            to={routeNames.GENERIC_CONFIG_VERSIONS}
            params={{ component: componentId, config: configurationId }}
          >
            {row.get('name') || 'Untitled'}
          </Link>{' '}
          has been deleted.
        </>
      );
    }
  });
};

export default configurationRowDeleted;
