import { fromJS, List } from 'immutable';

import { KDS_TEAM_WR_EXASOL, KDS_TEAM_WR_FIREBOLT } from '../../constants/componentIds';
import RowsActions, { createSimpleRow } from '../configurations/ConfigurationRowsActionCreators';
import RowsStore from '../configurations/ConfigurationRowsStore';
import ConfigurationsActions from '../configurations/ConfigurationsActionCreators';
import * as columnsMetadata from './templates/columnsMetadata';
import { getDisabledColumnFields } from './templates/dataTypes';
import { supportFullLoadOnly } from './helpers';

const orderRows = (componentId, configId, configName, order, newIndex) => {
  return ConfigurationsActions.orderRows(
    componentId,
    configId,
    order,
    order[newIndex],
    `${configName} order changed`
  );
};

const createRow = (componentId, configId, table) => {
  const columns = columnsMetadata.prepareColumnsTypes(componentId, table);
  const inputMapping = fromJS({
    source: table.get('id'),
    destination: table.get('id') + '.csv',
    columns: columns
      .filter((column) => column.get('type') !== 'IGNORE')
      .map((column) => column.get('name'))
  });
  let configuration = fromJS({
    parameters: {
      tableId: table.get('id'),
      dbName: table.get('name'),
      incremental: false,
      primaryKey: table.get('primaryKey'),
      items: columns
    }
  }).setIn(['storage', 'input', 'tables'], List([inputMapping]));

  if (supportFullLoadOnly(componentId)) {
    configuration = configuration
      .deleteIn(['parameters', 'incremental'])
      .deleteIn(['parameters', 'primaryKey']);
  }

  if (componentId === KDS_TEAM_WR_FIREBOLT) {
    configuration = configuration
      .deleteIn(['parameters', 'primaryKey'])
      .setIn(['parameters', 'table_type'], 'Dimension')
      .setIn(['parameters', 'loading_options', 'load_type'], 'Full load');
  }

  if (componentId === KDS_TEAM_WR_EXASOL) {
    configuration = configuration.setIn(['parameters', 'caseSensitive'], 'Case sensitive');
  }

  return createSimpleRow(
    componentId,
    configId,
    {
      name: table.get('name'),
      isDisabled: false,
      configuration: JSON.stringify(configuration.toJS())
    },
    `Create table ${table.get('name')}`
  );
};

const deleteRow = (componentId, configId, row, transition = false) => {
  return RowsActions.delete(
    componentId,
    configId,
    row.get('id'),
    transition,
    `Delete table ${row.get('name')}`
  );
};

const toggleRow = (componentId, configId, row) => {
  if (row.get('isDisabled', false)) {
    return RowsActions.enable(
      componentId,
      configId,
      row.get('id'),
      `Enable table ${row.get('name')}`
    );
  }

  return RowsActions.disable(
    componentId,
    configId,
    row.get('id'),
    `Disable table ${row.get('name')}`
  );
};

const resetRow = (componentId, configId, rowId) => {
  return RowsActions.resetConfiguration(componentId, configId, rowId);
};

const editRowColumn = (componentId, configId, rowId, newColumn) => {
  let configuration = RowsStore.getEditingConfiguration(componentId, configId, rowId);
  const columnIndex = configuration.getIn(['parameters', 'items'], List()).findIndex((column) => {
    return column.get('name') === newColumn.get('name');
  });

  if (columnIndex !== -1) {
    configuration = configuration.setIn(['parameters', 'items', columnIndex], newColumn);
  } else {
    configuration = configuration.updateIn(['parameters', 'items'], List(), (columns) => {
      return columns.push(newColumn);
    });
  }

  return RowsActions.updateConfiguration(componentId, configId, rowId, configuration);
};

const saveRowConfiguration = (componentId, configId, row, configuration, changeDescription) => {
  return RowsActions.saveConfigurationSimple(
    componentId,
    configId,
    row.get('id'),
    configuration,
    changeDescription
  );
};

const saveRowColumns = (componentId, configId, row, editingConfiguration, columns) => {
  const validColumns = columns.filter((column) => column.get('type') !== 'IGNORE');
  const omittedFields = getDisabledColumnFields(componentId);
  const configuration = editingConfiguration
    .setIn(
      ['parameters', 'items'],
      validColumns.map((column) => {
        let preparedColumn = column.set('nullable', column.get('null') === '1').delete('null');
        omittedFields.forEach((field) => {
          preparedColumn = preparedColumn.delete(field);
        });
        return preparedColumn;
      })
    )
    .setIn(
      ['storage', 'input', 'tables', 0, 'columns'],
      validColumns.map((column) => column.get('name'))
    );

  return saveRowConfiguration(
    componentId,
    configId,
    row,
    configuration,
    `${row.get('name')} columns edited`
  );
};

const saveLoadingSetting = (componentId, configId, row, setting) => {
  const configuration = row
    .get('configuration')
    .setIn(['parameters', 'incremental'], setting.incremental)
    .setIn(['parameters', 'primaryKey'], setting.primaryKey)
    .setIn(['storage', 'input', 'tables'], List([setting.newMapping]));
  return saveRowConfiguration(componentId, configId, row, configuration, 'Loading setting edited');
};

const saveIndexesSetting = (componentId, configId, row, indexes) => {
  const configuration = row
    .get('configuration')
    .setIn(['parameters', 'primaryIndex'], indexes.primaryIndex)
    .setIn(['parameters', 'join_indexes'], indexes.join_indexes)
    .setIn(['parameters', 'aggregation_indexes'], indexes.aggregation_indexes);
  return saveRowConfiguration(componentId, configId, row, configuration, 'Indexes setting edited');
};

const saveTableName = (componentId, configId, row, dbName) => {
  const configuration = row.get('configuration').setIn(['parameters', 'dbName'], dbName);
  return saveRowConfiguration(
    componentId,
    configId,
    row,
    configuration,
    'Database Table Name edited'
  );
};

const saveBulkSize = (componentId, configId, row, bulkSizeValue) => {
  const configuration = row
    .get('configuration')
    .setIn(['parameters', 'bulkSize'], parseInt(bulkSizeValue, 10));
  return saveRowConfiguration(componentId, configId, row, configuration, 'Bulk Size edited');
};
const saveTableType = (componentId, configId, row, tableType) => {
  const configuration = row.get('configuration').setIn(['parameters', 'table_type'], tableType);
  return saveRowConfiguration(
    componentId,
    configId,
    row,
    configuration,
    'Database Table Type edited'
  );
};

const saveCaseSensitivity = (componentId, configId, row, caseSensitive) => {
  const configuration = row
    .get('configuration')
    .setIn(['parameters', 'caseSensitive'], caseSensitive);
  return saveRowConfiguration(componentId, configId, row, configuration, 'Case Sensitivity edited');
};

const saveFireboltTableLoad = (componentId, configId, row, loadingOption) => {
  const configuration = row
    .get('configuration')
    .setIn(['parameters', 'loading_options', 'load_type'], loadingOption);
  return saveRowConfiguration(
    componentId,
    configId,
    row,
    configuration,
    'Firebolt Table Load edited'
  );
};

const savePostRunScripts = (componentId, configId, row, data) => {
  const configuration = row.get('configuration').setIn(['parameters', 'post_run_scripts'], data);
  return saveRowConfiguration(componentId, configId, row, configuration, 'Update Post Run Scripts');
};

const saveRelationship = (componentId, configId, row, relationships) => {
  const configuration = row
    .get('configuration')
    .setIn(['parameters', 'relationships'], relationships);
  return saveRowConfiguration(
    componentId,
    configId,
    row,
    configuration,
    'Update Table Relationships'
  );
};

export {
  orderRows,
  createRow,
  deleteRow,
  toggleRow,
  resetRow,
  editRowColumn,
  saveRowColumns,
  saveLoadingSetting,
  saveIndexesSetting,
  savePostRunScripts,
  saveRelationship,
  saveTableName,
  saveTableType,
  saveCaseSensitivity,
  saveFireboltTableLoad,
  saveBulkSize
};
