import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import ConfirmButtons from '../../../../../react/common/ConfirmButtons';
import ModalIcon from '../../../../../react/common/ModalIcon';
import Tooltip from '../../../../../react/common/Tooltip';
import validateStorageTableId from '../../../../../utils/validateStorageTableId';
import { ioType } from '../../../Constants';
import Editor from './TableOutputMappingEditor';

const MODE_CREATE = 'create',
  MODE_EDIT = 'edit';

const TableOutputMappingModal = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    mode: PropTypes.oneOf([MODE_CREATE, MODE_EDIT]),
    mapping: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    sourceType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    defaultBucketName: PropTypes.string.isRequired,
    defaultTableName: PropTypes.string.isRequired,
    onEditStart: PropTypes.func,
    simple: PropTypes.bool,
    renderCustomOpenButton: PropTypes.func
  },

  getDefaultProps() {
    return {
      onEditStart: () => {}
    };
  },

  isValid() {
    return (
      !!this.props.mapping.get('source') &&
      !!this.props.mapping.get('destination') &&
      validateStorageTableId(this.props.mapping.get('destination', ''))
    );
  },

  getInitialState() {
    return {
      isSaving: false,
      show: false
    };
  },

  render() {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        {this.renderOpenButton()}
        <Modal bsSize="large" show={this.state.show} onHide={this.handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.simple ? 'Save Data' : 'Output Mapping'}</Modal.Title>
            {this.props.mode === MODE_CREATE ? <ModalIcon.Plus /> : <ModalIcon.Edit />}
          </Modal.Header>
          <Modal.Body>{this.editor()}</Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={
                this.props.simple
                  ? 'Save Data'
                  : this.props.mode === MODE_CREATE
                  ? 'Add Output'
                  : 'Save Output'
              }
              isSaving={this.state.isSaving}
              onSave={this.handleSave}
              isDisabled={!this.isValid()}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  },

  editor() {
    return (
      <Editor
        value={this.props.mapping}
        tables={this.props.tables}
        buckets={this.props.buckets}
        disabled={this.state.isSaving}
        onChange={this.props.onChange}
        defaultBucketName={this.props.defaultBucketName}
        defaultTableName={this.props.defaultTableName}
        sourceType={this.props.sourceType}
        componentId={this.props.componentId}
        simple={this.props.simple}
      />
    );
  },

  handleCancel() {
    this.props.onCancel();
    this.closeModal();
  },

  handleEditButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.openModal();
    this.props.onEditStart();
  },

  openModal() {
    this.setState({ show: true });
  },

  closeModal() {
    this.setState({
      show: false,
      isSaving: false
    });
  },

  renderOpenButton() {
    if (this.props.renderCustomOpenButton) {
      return this.props.renderCustomOpenButton(this.handleEditButtonClick);
    }

    if (this.props.mode === MODE_EDIT) {
      return (
        <Tooltip placement="top" tooltip="Edit Output">
          <Button bsStyle="link" className="text-muted" onClick={this.handleEditButtonClick}>
            <FontAwesomeIcon icon="pen" fixedWidth />
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button
        bsStyle="link"
        className="header-inline-button color-success"
        onClick={this.openModal}
      >
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        New Table Output
      </Button>
    );
  },

  handleSave() {
    this.setState({
      isSaving: true
    });
    this.props
      .onSave()
      .then(() => {
        this.setState({
          isSaving: false
        });
        this.closeModal();
      })
      .catch((e) => {
        this.setState({
          isSaving: false
        });
        throw e;
      });
  }
});

export default TableOutputMappingModal;
