import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import CircleIcon from '../../../react/common/CircleIcon';
import InfoTooltip from '../../../react/common/InfoTooltip';
import Link from '../../../react/common/RouterLink';
import { convertCreditsToMinutes } from '../helpers';
import CreditsLoadingTooltip from './CreditsLoadingTooltip';

const CreditsBalanceBox = ({ creditsCount, isLoadingCredits, simple }) => {
  return (
    <div className="box box-panel box-panel-small">
      <div className="box-header">
        <h2 className="box-title">Your Minutes Balance</h2>
        <CircleIcon icon="clock" color="blue" bold />
      </div>
      <div className="box-panel-content mt-auto">
        <p className={classNames('summary-title', { 'f-32': !!simple })}>
          {convertCreditsToMinutes(creditsCount)} minutes
          {!isLoadingCredits && (
            <InfoTooltip tooltip="Current balance, including workspace run time" />
          )}
          <CreditsLoadingTooltip show={isLoadingCredits} />
        </p>
        <p className="flex-container mt-1 gap-16">
          {simple ? (
            <>
              <Link to="billing-minutes" className="btn btn-success fill-space">
                <FontAwesomeIcon icon="plus" className="icon-addon-right" />
                Top up minutes
              </Link>
              <Link to="billing-consumption" className="btn btn-default fill-space">
                Project Consumption
              </Link>
            </>
          ) : (
            <Link to="billing-overview" className="btn btn-success fill-space">
              Billing Overview
            </Link>
          )}
        </p>
      </div>
    </div>
  );
};

CreditsBalanceBox.propTypes = {
  creditsCount: PropTypes.number.isRequired,
  isLoadingCredits: PropTypes.bool.isRequired,
  simple: PropTypes.bool
};

export default CreditsBalanceBox;
