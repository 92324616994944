import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import InlineEditTextInput from '../../../react/common/InlineEditTextInput';
import Tooltip from '../../../react/common/Tooltip';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import { canWriteBucket } from '../../admin/privileges';
import TablesStore from '../../components/stores/StorageTablesStore';
import { updateTable } from '../actions';
import { validateTableName } from '../helpers';

const TableNameEdit = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, TablesStore)],

  propTypes: {
    tableName: PropTypes.string.isRequired,
    bucketId: PropTypes.string.isRequired
  },

  getInitialState() {
    const table = TablesStore.getTableByName(this.props.tableName, this.props.bucketId);

    return {
      value: table.get('displayName', this.props.tableName),
      isEditing: false,
      isSaving: false,
      error: null
    };
  },

  getStateFromStores() {
    const table = TablesStore.getTableByName(this.props.tableName, this.props.bucketId);

    return {
      table,
      readOnly: !canWriteBucket(ApplicationStore.getSapiToken(), table.get('bucket', Map())),
      originalValue: table.get('displayName', this.props.tableName),
      bucketTables: TablesStore.getAll().filter((table) => {
        return (
          table.getIn(['bucket', 'id']) === this.props.bucketId &&
          table.get('name') !== this.props.tableName
        );
      })
    };
  },

  render() {
    return (
      <>
        <FontAwesomeIcon
          icon="table"
          fixedWidth
          style={{ fontSize: '0.75em' }}
          className="text-muted icon-addon-right"
        />
        {this.renderTableName()}
      </>
    );
  },

  renderTableName() {
    if (this.state.readOnly) {
      return this.state.value;
    }

    return (
      <span className={classNames({ 'input-error': !!this.state.error })}>
        <Tooltip
          placement="bottom"
          tooltip={this.state.error}
          forceShow={!!this.state.error}
          type="explanatory"
        >
          <InlineEditTextInput
            isValid={!this.state.error}
            isChanged={this.state.value !== this.state.originalValue}
            text={this.state.value}
            isSaving={this.state.isSaving}
            isEditing={this.state.isEditing}
            onEditStart={this.handleEditStart}
            onEditCancel={this.handleEditCancel}
            onEditChange={this.handleEditChange}
            onEditSubmit={this.handleEditSubmit}
          />
        </Tooltip>
      </span>
    );
  },

  handleEditStart() {
    this.setState({ isEditing: true, error: null });
  },

  handleEditChange(newValue) {
    this.setState({
      value: newValue,
      error: validateTableName(newValue, this.state.bucketTables, { strict: false })
    });
  },

  handleEditCancel() {
    this.setState({
      error: null,
      isSaving: false,
      isEditing: false,
      value: this.state.originalValue
    });
  },

  handleEditSubmit() {
    this.setState({ error: null, isSaving: true });
    return updateTable(this.state.table.get('id'), { displayName: this.state.value })
      .catch((error) => this.setState({ error }))
      .finally(() => this.setState({ isSaving: false, isEditing: false }));
  }
});

export default TableNameEdit;
