import React from 'react';
import { Button, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { fromJS, List, Map } from 'immutable';
import Switch from 'rc-switch';

import { KEBOOLA_DATA_APPS } from '../../constants/componentIds';
import callDockerAction from '../../modules/components/DockerActionsApi';
import InstalledComponentsActionCreators from '../../modules/components/InstalledComponentsActionCreators';
import fromJSOrdered from '../../utils/fromJSOrdered';
import CollapsibleBox from './CollapsibleBox';
import CreatedDate from './CreatedDate';
import ExternalLink from './ExternalLink';
import Loader from './Loader';
import MarkedText from './MarkedText';
import PasswordControl from './PasswordControl';
import SaveButtons from './SaveButtons';
import Select from './Select';
import Tooltip from './Tooltip';
import Truncated from './Truncated';

const GitSettingBox = (props: {
  title: string;
  configId: string;
  componentId: string;
  configData: Map<string, any>;
  readOnly: boolean;
  hideEntrypoint?: boolean;
  prepareDataBeforeSave?: (configData: Map<string, any>) => Map<string, any>;
  hint?: string;
}) => {
  const isDataApp = props.componentId === KEBOOLA_DATA_APPS;
  const repositoryKey = isDataApp ? 'repository' : 'repo';
  const syncAciton = isDataApp ? 'git-repository' : 'gitRepository';
  const gitRoot = isDataApp ? ['parameters', 'dataApp'] : ['parameters'];
  const gitPath = isDataApp ? [...gitRoot, 'git'] : [...gitRoot, 'git'];

  const [formData, setFormData] = React.useState(props.configData.getIn(gitRoot, Map()));
  const [tempData, setTempData] = React.useState(Map());
  const [isPending, setIsPending] = React.useState(Map());

  const getData = () => {
    if (props.prepareDataBeforeSave) {
      return props.prepareDataBeforeSave(props.configData);
    }

    return props.configData;
  };

  const loadBranches = () => {
    setIsPending(isPending.set('gitRepository', true));
    return callDockerAction(props.componentId, syncAciton, {
      configData: Map()
        .setIn(gitPath, formData.get('git', Map()).delete('branch').delete('private'))
        .toJS()
    })
      .then((response) => {
        if (response?.status === 'error') {
          return setTempData(
            tempData.set(
              'gitRepository',
              Map({
                status: 'error',
                message: response?.message || 'Failed to load branches'
              })
            )
          );
        }

        setTempData(
          tempData.set('gitRepository', fromJS({ branches: response.repository.branches }))
        );
      })
      .finally(() => setIsPending(isPending.delete('gitRepository')));
  };

  const loadEntrypoints = (branch?: string) => {
    setIsPending(isPending.set('gitEntrypoints', true));
    return callDockerAction(props.componentId, 'data-app-entrypoints', {
      configData: Map()
        .setIn(
          gitPath,
          formData
            .get('git', Map())
            .update((git: Map<string, any>) => (branch ? git.set('branch', branch) : git))
            .delete('entrypoint')
            .delete('private')
        )
        .toJS()
    })
      .then((response) => {
        if (response?.status === 'error') {
          return setTempData(
            tempData.set(
              'gitEntrypoints',
              Map({
                status: 'error',
                message: response?.message || 'Failed to load entrypoints'
              })
            )
          );
        }

        setTempData(tempData.set('gitEntrypoints', fromJS({ entrypoints: response.entrypoints })));
      })
      .finally(() => setIsPending(isPending.delete('gitEntrypoints')));
  };

  const renderLoadingError = (key: 'gitRepository' | 'gitEntrypoints', className?: string) => {
    if (isPending.get(key, false) || tempData.getIn([key, 'status']) !== 'error') {
      return null;
    }

    return (
      <HelpBlock className={classNames('color-danger', className)}>
        <FontAwesomeIcon icon="circle-exclamation" className="f-16 btn-icon" />
        {tempData.getIn([key, 'message'])}
      </HelpBlock>
    );
  };

  return (
    <CollapsibleBox
      title={props.title}
      hint={props.hint}
      headerClassName="plp-6"
      defaultOpen={formData.get('git', Map()).isEmpty()}
      additionalActions={() => {
        if (props.readOnly) {
          return null;
        }

        return (
          <SaveButtons
            isChanged={!props.configData.getIn(gitPath, Map()).equals(formData.get('git', Map()))}
            onReset={() => {
              setFormData(formData.set('git', props.configData.getIn(gitPath, Map())));
              setTempData(tempData.remove('gitRepository'));
            }}
            onSave={() => {
              let newGitObject = Map();

              if (formData.getIn(['git', repositoryKey])) {
                newGitObject = formData.get('git', Map()).withMutations((git: Map<string, any>) => {
                  if (!git.get('private')) {
                    git.remove('#password').remove('username').remove('private');
                  }

                  if (!git.get('#password')) {
                    git.remove('#password');
                  }

                  if (!git.get('branch')) {
                    git.remove('branch');
                  }
                });
              }

              if (
                formData.getIn(['git', repositoryKey]) !==
                props.configData.getIn([...gitPath, repositoryKey])
              ) {
                setTempData(tempData.setIn(['gitRepository', 'branches'], List()));
              }

              setIsPending(isPending.set('projectRepository', true));
              return InstalledComponentsActionCreators.saveComponentConfigData(
                props.componentId,
                props.configId,
                newGitObject.isEmpty()
                  ? getData().deleteIn(gitPath)
                  : getData().setIn(gitPath, newGitObject),
                'Update project repository'
              )
                .then((response) => {
                  setFormData(formData.set('git', fromJSOrdered(response).getIn(gitPath, Map())));
                })
                .finally(() => setIsPending(isPending.set('projectRepository', false)));
            }}
            isSaving={isPending.get('projectRepository', false)}
          />
        );
      }}
    >
      <FormGroup className="input-with-addon">
        <ControlLabel>Project URL</ControlLabel>
        <FormControl
          type="text"
          placeholder="URL"
          value={formData.getIn(['git', repositoryKey], '')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFormData(formData.setIn(['git', repositoryKey], event.target.value.trim()));
          }}
          disabled={props.readOnly}
        />
        {formData.getIn(['git', repositoryKey]) && (
          <Tooltip placement="top" tooltip="Explore Project">
            <span className="addon">
              <ExternalLink
                href={formData.getIn(['git', repositoryKey])}
                className="f-16 text-muted"
              >
                <FontAwesomeIcon icon="arrow-up-right-from-square" />
              </ExternalLink>
            </span>
          </Tooltip>
        )}
      </FormGroup>
      <FormGroup>
        <ControlLabel
          className={classNames('flex-container flex-start inline-flex mb-0', {
            clickable: !props.readOnly
          })}
        >
          <Switch
            prefixCls="switch"
            className="btn-icon wider"
            checked={formData.getIn(['git', 'private'], false)}
            onClick={(checked: boolean) => {
              setFormData(formData.setIn(['git', 'private'], checked));
            }}
            disabled={props.readOnly}
          />
          Private
        </ControlLabel>
      </FormGroup>
      {formData.getIn(['git', 'private']) && (
        <div className="form-groups">
          <FormGroup>
            <ControlLabel>Username</ControlLabel>
            <FormControl
              type="text"
              value={formData.getIn(['git', 'username'], '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormData(formData.setIn(['git', 'username'], event.target.value));
              }}
              disabled={props.readOnly}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              Access Token
              <Tooltip
                placement="top"
                type="explanatory"
                tooltip="Please use GIT Personal Access Token when adding private repository"
              >
                <FontAwesomeIcon icon="circle-info" className="title-hint" />
              </Tooltip>
            </ControlLabel>
            <PasswordControl
              value={formData.getIn(['git', '#password'], '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormData(formData.setIn(['git', '#password'], event.target.value));
              }}
              disabled={props.readOnly}
            />
          </FormGroup>
        </div>
      )}
      {tempData.getIn(['gitRepository', 'branches'], List()).isEmpty() &&
      !formData.getIn(['git', 'branch']) ? (
        <FormGroup className="flex-container flex-start">
          <Button
            className="mr-1"
            onClick={loadBranches}
            disabled={
              isPending.get('gitRepository', false) || !formData.getIn(['git', repositoryKey])
            }
          >
            {isPending.get('gitRepository', false) ? (
              <>
                <Loader className="icon-addon-right" />
                Loading branches
              </>
            ) : (
              'Load branches'
            )}
          </Button>
          {renderLoadingError('gitRepository', 'm-0')}
        </FormGroup>
      ) : (
        <>
          <FormGroup>
            <ControlLabel>Branch</ControlLabel>
            <div className="flex-container flex-start">
              <span className="fill-space mr-1">
                <Select
                  allowCreate
                  clearable={false}
                  placeholder="Search branch"
                  isLoading={!!isPending.get('gitRepository', false)}
                  value={formData.getIn(['git', 'branch'], '')}
                  options={tempData
                    .getIn(['gitRepository', 'branches'], List())
                    .map((branch: Map<string, any>) => {
                      return {
                        value: branch.get('branch'),
                        label: (inputString: string) => (
                          <>
                            <span className="font-medium">{branch.get('branch')}</span>
                            <div className="flex-container line-height-20">
                              <Truncated
                                text={
                                  <MarkedText source={branch.get('comment')} mark={inputString} />
                                }
                                className="f-12 font-mono color-base option-comment"
                              />
                              <span className="ml-1 no-wrap">
                                <CreatedDate
                                  className="color-main"
                                  createdTime={branch.get('date')}
                                />
                                <MarkedText
                                  className="tw-ml-2 color-base"
                                  source={branch.getIn(['author', 'name'])}
                                  mark={inputString}
                                />
                              </span>
                            </div>
                          </>
                        )
                      };
                    })
                    .toArray()}
                  singleValueRenderer={(props: any) => {
                    return (
                      <div style={props.getStyles('singleValue', props)}>{props.data.value}</div>
                    );
                  }}
                  onChange={(branch: string) => {
                    setFormData(formData.setIn(['git', 'branch'], branch));

                    if (!props.hideEntrypoint) {
                      loadEntrypoints(branch);
                    }
                  }}
                  disabled={!!isPending.get('gitRepository', false)}
                />
              </span>
              <Button
                className="w-175"
                onClick={loadBranches}
                disabled={isPending.get('gitRepository', false)}
              >
                Reload branches
              </Button>
            </div>
          </FormGroup>
          {renderLoadingError('gitRepository')}
          {!props.hideEntrypoint && (
            <>
              <FormGroup>
                <ControlLabel>Main File Path</ControlLabel>
                <div className="flex-container flex-start">
                  <span className="fill-space mr-1">
                    <Select
                      allowCreate
                      placeholder="Default value streamlit_app.py"
                      isLoading={!!isPending.get('gitEntrypoints', false)}
                      value={formData.getIn(['git', 'entrypoint'], '')}
                      options={tempData
                        .getIn(['gitEntrypoints', 'entrypoints'], List())
                        .map((value: string) => ({ value, label: value }))
                        .toArray()}
                      onChange={(entrypoint: string) => {
                        setFormData(formData.setIn(['git', 'entrypoint'], entrypoint));
                      }}
                      disabled={!!isPending.get('gitEntrypoints', false)}
                    />
                  </span>
                  <Button
                    className="w-175"
                    onClick={() => loadEntrypoints()}
                    disabled={
                      isPending.get('gitEntrypoints', false) || !formData.getIn(['git', 'branch'])
                    }
                  >
                    {tempData.getIn(['gitEntrypoints', 'entrypoints'], List()).isEmpty()
                      ? 'Load'
                      : 'Reload'}{' '}
                    entrypoints
                  </Button>
                </div>
              </FormGroup>
              {renderLoadingError('gitEntrypoints')}
            </>
          )}
        </>
      )}
    </CollapsibleBox>
  );
};

export default GitSettingBox;
