import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { truncate } from 'underscore.string';

import CircleIcon from '../../react/common/CircleIcon';
import CreatedDate from '../../react/common/CreatedDate';
import RouterLink from '../../react/common/RouterLink';
import { routeNames as settingsRoutes } from '../settings/constants';

const CliActivityBox = ({ latestEvent }) => {
  return (
    <div className="box box-panel box-panel-small">
      <div className="box-header">
        <h2 className="box-title">Latest CLI Activity</h2>
        <CircleIcon icon="key" color="violet" bold />
      </div>
      <div className="box-panel-content mt-auto">
        <CreatedDate relative className="summary-title" createdTime={latestEvent.get('created')} />
        <p className="text-muted">
          {latestEvent.get('message') && (
            <span title={latestEvent.get('message')}>
              {truncate(latestEvent.get('message'), 65)} –{' '}
            </span>
          )}
          <RouterLink to={settingsRoutes.CLI}>Settings</RouterLink>
        </p>
      </div>
    </div>
  );
};

CliActivityBox.propTypes = {
  latestEvent: PropTypes.instanceOf(Map).isRequired
};

export default CliActivityBox;
