import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { NEW_ENTITY_BUTTON } from '../../../../constants/external';
import RoutesStore from '../../../../stores/RoutesStore';
import { routeNames } from '../../../transformations-v2/constants';
import { prepareFoldersFromMetadata } from '../../helpers';
import NewTransformationModal from './NewTransformationModal';

class NewTransformationButton extends React.Component {
  state = {
    openModal: false
  };

  render() {
    return (
      <>
        <Button
          bsStyle="success"
          className={`btn-new-transformation ${NEW_ENTITY_BUTTON}`}
          onClick={() => this.setState({ openModal: true })}
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Create Transformation
        </Button>
        <NewTransformationModal
          show={this.state.openModal}
          onHide={() => this.setState({ openModal: false })}
          onCreate={this.onCreate}
          patternComponents={this.props.patternComponents}
          allowedComponents={this.props.allowedComponents}
          folders={prepareFoldersFromMetadata(this.props.componentsMetadata)}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
          availableDatabricksClusters={this.props.availableDatabricksClusters}
          isDevModeActive={this.props.isDevModeActive}
        />
      </>
    );
  }

  onCreate = (componentId, configId) => {
    RoutesStore.getRouter().transitionTo(routeNames.GENERIC_TRANSFORMATION_CONFIG, {
      component: componentId,
      config: configId
    });
  };
}

NewTransformationButton.propTypes = {
  hasPayAsYouGo: PropTypes.bool.isRequired,
  patternComponents: PropTypes.instanceOf(Map).isRequired,
  allowedComponents: PropTypes.instanceOf(Map).isRequired,
  componentsMetadata: PropTypes.instanceOf(Map).isRequired,
  availableDatabricksClusters: PropTypes.instanceOf(List),
  isDevModeActive: PropTypes.bool.isRequired
};

export default NewTransformationButton;
