import React from 'react';
import Promise from 'bluebird';

import { KEBOOLA_DATABRICKS_TRANSFORMATION, KEBOOLA_SANDBOXES } from '../../constants/componentIds';
import { FEATURE_SANDBOXES_PYTHON_MLFLOW } from '../../constants/features';
import ApplicationStore from '../../stores/ApplicationStore';
import { configPoll } from '../../utils/genericPolls';
import ComponentsActionCreators from '../components/ComponentsActionCreators';
import InstalledComponentsActionsCreators from '../components/InstalledComponentsActionCreators';
import ComponentNameEdit from '../components/react/components/ComponentName';
import FileInputMappingHeaderButton from '../components/react/pages/file-input-mapping/HeaderButton';
import FileInputMappingPage from '../components/react/pages/file-input-mapping/Index';
import StorageActionCreators from '../components/StorageActionCreators';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import createVersionsPageRoute from '../components/utils/createVersionsPageRoute';
import VersionsActionCreators from '../components/VersionsActionCreators';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import WorkspacesBillingAlert from '../home/react/WorkspacesBillingAlert';
import WorkspacesReadOnlyStorageAlert from '../home/react/WorkspacesReadOnlyStorageAlert';
import * as NotificationActions from '../notifications/actions';
import SandboxesActions from '../sandboxes/Actions';
import SandboxDetail from '../sandboxes/components/SandboxDetail';
import SandboxesHeaderButton from '../sandboxes/components/SandboxesHeaderButton';
import SandboxesIndex from '../sandboxes/components/SandboxesIndex';
import SandboxStatusLabel from '../sandboxes/components/SandboxStatusLabel';
import { resolveComponentIdFromSandboxType } from '../sandboxes/helpers';
import SandboxesStore from '../sandboxes/SandboxesStore';
import { routeNames } from './Constants';

const routes = {
  name: routeNames.WORKSPACES,
  title: 'Workspaces',
  defaultRouteHandler: SandboxesIndex,
  infoAlertHandler: WorkspacesBillingAlert,
  headerButtonsHandler: SandboxesHeaderButton,
  requireData: [
    (params) => {
      if (params.config) {
        return Promise.resolve();
      }

      return Promise.all([
        SandboxesActions.loadSandboxes(),
        NotificationActions.loadNotifications(),
        InstalledComponentsActionsCreators.loadComponentConfigsData(KEBOOLA_SANDBOXES)
      ]);
    },
    () => {
      if (
        !DevBranchesStore.isDevModeActive() &&
        ApplicationStore.hasCurrentProjectFeature(FEATURE_SANDBOXES_PYTHON_MLFLOW)
      ) {
        return SandboxesActions.loadSandboxProjectSettings();
      }

      return Promise.resolve();
    },
    () => InstalledComponentsActionsCreators.loadComponentsMetadata()
  ],
  poll: {
    skipFirst: true,
    interval: 15,
    action: () => {
      return Promise.all([
        SandboxesActions.loadSandboxesForce(),
        InstalledComponentsActionsCreators.loadComponentConfigsDataForce(KEBOOLA_SANDBOXES)
      ]);
    }
  },
  childRoutes: [
    {
      name: routeNames.WORKSPACE,
      path: ':config',
      title(routerState) {
        const config = InstalledComponentsStore.getConfig(
          KEBOOLA_SANDBOXES,
          routerState.getIn(['params', 'config'])
        );
        return config.get('name');
      },
      nameEdit(params) {
        const config = InstalledComponentsStore.getConfig(KEBOOLA_SANDBOXES, params.config);
        const sandbox = SandboxesStore.getSandbox(
          config.getIn(['configuration', 'parameters', 'id'])
        );

        return (
          <ComponentNameEdit
            key={`${KEBOOLA_SANDBOXES}-${params.config}`}
            componentId={KEBOOLA_SANDBOXES}
            configId={params.config}
            readOnly={sandbox.get('tokenId') !== ApplicationStore.getSapiToken().get('id')}
          />
        );
      },
      defaultRouteHandler: SandboxDetail,
      infoAlertHandler: WorkspacesReadOnlyStorageAlert,
      labelHandler: SandboxStatusLabel,
      poll: configPoll(KEBOOLA_SANDBOXES, (params) => {
        const sandboxId = InstalledComponentsStore.getConfigData(
          KEBOOLA_SANDBOXES,
          params.config
        ).getIn(['parameters', 'id']);

        return SandboxesActions.loadSandbox(sandboxId);
      }),
      requireData: [
        () => StorageActionCreators.loadBucketsAndTables(),
        (params, query, routeState) => {
          return InstalledComponentsActionsCreators.loadComponentConfigData(
            KEBOOLA_SANDBOXES,
            params.config
          ).then(() => {
            const sandboxId = InstalledComponentsStore.getConfigData(
              KEBOOLA_SANDBOXES,
              params.config
            ).getIn(['parameters', 'id']);

            return SandboxesActions.loadSandbox(sandboxId).then(() => {
              const sandbox = SandboxesStore.getSandbox(sandboxId);
              const componentId = resolveComponentIdFromSandboxType(sandbox.get('type'));

              return Promise.all([
                // check if should load all rows for new file input mapping fot Databricks transformation
                componentId === KEBOOLA_DATABRICKS_TRANSFORMATION &&
                  !routeState.routes.find((route) => route.path === 'file-input-mapping') &&
                  InstalledComponentsActionsCreators.reloadInstalledComponents({
                    include: 'rows'
                  }),
                // check if component detail is not loaded, we need details to check staging storage
                !ComponentsStore.hasComponentDetails(componentId) &&
                  ComponentsActionCreators.loadComponentForce(componentId)
              ]);
            });
          });
        },
        (params) => VersionsActionCreators.loadVersions(KEBOOLA_SANDBOXES, params.config)
      ],
      childRoutes: [
        {
          name: routeNames.GENERIC_WORKSPACE_FILE_INPUT_MAPPING,
          path: 'file-input-mapping',
          title: 'File Input Mapping',
          defaultRouteHandler: FileInputMappingPage,
          headerButtonsHandler: FileInputMappingHeaderButton,
          requireData: [
            () => {
              return InstalledComponentsActionsCreators.reloadInstalledComponents({
                include: 'configuration,rows'
              });
            }
          ]
        },
        createVersionsPageRoute(KEBOOLA_SANDBOXES, 'config', routeNames.WORKSPACE_VERSIONS)
      ]
    }
  ]
};

export default routes;
