import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import ConfirmButtons from '../../../../../react/common/ConfirmButtons';
import ModalIcon from '../../../../../react/common/ModalIcon';
import Tooltip from '../../../../../react/common/Tooltip';
import Editor from './FileOutputMappingEditor';

const MODE_CREATE = 'create',
  MODE_EDIT = 'edit';

const FileOutputMappingModal = createReactClass({
  propTypes: {
    mode: PropTypes.oneOf([MODE_CREATE, MODE_EDIT]),
    mapping: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    definedSources: PropTypes.instanceOf(List).isRequired,
    onEditStart: PropTypes.func
  },

  getInitialState() {
    return {
      isSaving: false,
      show: false
    };
  },

  getDefaultProps() {
    return {
      onEditStart: () => {}
    };
  },

  isValid() {
    return (
      !!this.props.mapping.get('source') &&
      !this.props.definedSources.includes(this.props.mapping.get('source'))
    );
  },

  render() {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        {this.renderOpenButton()}
        <Modal bsSize="large" show={this.state.show} onHide={this.handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Output Mapping</Modal.Title>
            {this.props.mode === MODE_CREATE ? <ModalIcon.Plus /> : <ModalIcon.Edit />}
          </Modal.Header>
          <Modal.Body>{this.editor()}</Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={this.props.mode === MODE_CREATE ? 'Add File Output' : 'Save File Output'}
              isSaving={this.state.isSaving}
              onSave={this.handleSave}
              isDisabled={!this.isValid()}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  },

  editor() {
    return (
      <Editor
        value={this.props.mapping}
        disabled={this.state.isSaving}
        onChange={this.props.onChange}
        isSourceDuplicate={this.props.definedSources.includes(this.props.mapping.get('source'))}
      />
    );
  },

  handleCancel() {
    this.closeModal();
    this.props.onCancel();
  },

  handleEditButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.openModal();
    this.props.onEditStart();
  },

  openModal() {
    this.setState({ show: true });
  },

  closeModal() {
    this.setState({
      show: false,
      isSaving: false
    });
  },

  renderOpenButton() {
    if (this.props.mode === MODE_EDIT) {
      return (
        <Tooltip placement="top" tooltip="Edit Output">
          <Button bsStyle="link" className="text-muted" onClick={this.handleEditButtonClick}>
            <FontAwesomeIcon icon="pen" fixedWidth />
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button
        bsStyle="link"
        className="header-inline-button color-success"
        onClick={this.openModal}
      >
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        New File Output
      </Button>
    );
  },

  handleSave() {
    this.setState({
      isSaving: true
    });
    this.props
      .onSave()
      .then(() => {
        this.setState({
          isSaving: false
        });
        this.closeModal();
      })
      .catch((e) => {
        this.setState({
          isSaving: false
        });
        throw e;
      });
  }
});

export default FileOutputMappingModal;
