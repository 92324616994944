import React from 'react';
import classnames from 'classnames';

import Truncated from '../../../react/common/Truncated';
import { getComponentTypeColorClassName } from '../../components/helpers';

const TaskName = ({
  name,
  componentName,
  componentType,
  isBlank,
  isDragged,
  isDeleted,
  className
}: {
  name: string;
  componentName: string;
  componentType: string;
  isBlank: boolean;
  isDragged: boolean;
  isDeleted: boolean;
  className?: string;
}) => {
  return (
    <h4 className={classnames('user-select-none line-height-20 m-0', className)}>
      {isBlank ? (
        <div className="color-orange">No Configuration</div>
      ) : (
        <Truncated
          twoLines
          text={name}
          noTooltip={isDragged}
          className={classnames('break-all', { 'color-danger': isDeleted })}
        />
      )}
      <div className="flex-container flex-start f-12 font-normal text-muted">
        {componentType && (
          <span
            className={classnames(
              'tw-mr-1 font-medium no-wrap',
              getComponentTypeColorClassName(componentType)
            )}
          >
            {componentType}
          </span>
        )}
        <Truncated text={componentName} />
      </div>
    </h4>
  );
};

export default TaskName;
