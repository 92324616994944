import React from 'react';
import classNames from 'classnames';

import Tooltip from './Tooltip';

const Badge = (props: {
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  className?: string;
}) => {
  const body = (
    <div
      className={classNames(
        'tw-inline-flex tw-uppercase tw-text-white tw-text-[11px] tw-leading-4 tw-rounded tw-py-0.5 tw-px-1 tw-tracking-widest',
        props.className
      )}
    >
      {props.children}
    </div>
  );

  if (props.tooltip) {
    return (
      <Tooltip allowHoverTooltip tooltip={props.tooltip} placement="top" type="explanatory">
        {body}
      </Tooltip>
    );
  }

  return body;
};

const BadgeBeta = (props: { tooltip?: React.ReactNode; className?: string }) => (
  <Badge className={classNames('tw-bg-cyan-500', props.className)} tooltip={props.tooltip}>
    Beta
  </Badge>
);

const BadgeExperimental = (props: { tooltip?: React.ReactNode; className?: string }) => (
  <Badge className={classNames('tw-bg-purple-500', props.className)} tooltip={props.tooltip}>
    Experimental
  </Badge>
);

const BadgePrivate = (props: { tooltip?: React.ReactNode; className?: string }) => (
  <Badge className={classNames('tw-bg-neutral-400', props.className)} tooltip={props.tooltip}>
    Private
  </Badge>
);

export { BadgeBeta, BadgeExperimental, BadgePrivate };
