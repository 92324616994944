import Promise from 'bluebird';

import { loadAsync } from './loadAsync';

export default () => {
  return Promise.all([
    loadAsync({ id: 'google-client-snippet', src: 'https://apis.google.com/js/client.js' }),
    loadAsync({ id: 'google-auth-snippet', src: 'https://accounts.google.com/gsi/client' })
  ]);
};
