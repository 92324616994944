import { List, Map, Set } from 'immutable';

const reorderTransformationInputs = (inputs, oldIndex, newIndex) => {
  const temp = inputs.get(oldIndex);
  return inputs.splice(oldIndex, 1).splice(newIndex, 0, temp);
};

const canBeTableCloned = (table) => {
  // only table on Snowflake backend can be cloned
  if (table.getIn(['bucket', 'backend']) !== 'snowflake') {
    return false;
  }
  // non-alias table can be cloned always
  if (!table.get('isAlias', false)) {
    return true;
  }
  // alias table can be cloned only with `aliasColumnsAutoSync: true` and with empty `aliasFilter`
  return table.get('aliasFilter', Map()).count() === 0 && table.get('aliasColumnsAutoSync', false);
};

const isTableBiggerThat100MB = (table) => {
  return table.get('dataSizeBytes', 0) / 1000 / 1000 > 100;
};

const isInputMappingSourceBucket = (value) => value.has('tables');

const prepareUniqueDestination = (tableMapping, definedDestinations, suffixNumber = 2) => {
  const FILE_EXTENSION = '.csv';
  const originalDestination = tableMapping.get('destination');
  const destination =
    originalDestination.replace(FILE_EXTENSION, '') +
    '_' +
    suffixNumber +
    (originalDestination.includes(FILE_EXTENSION) ? FILE_EXTENSION : '');

  if (definedDestinations.includes(destination)) {
    return prepareUniqueDestination(tableMapping, definedDestinations, suffixNumber + 1);
  }

  return destination;
};

const updateTransformationMappings = (
  transformation,
  mappingType,
  mapping,
  mappingIndex = null
) => {
  return transformation.update(mappingType, (mappings) => {
    if (mappingIndex !== null) {
      return mappings.set(mappingIndex, mapping);
    }

    if (mapping.has('tables')) {
      const definedSources = transformation
        .get(mappingType, List())
        .map((input) => input.get('source'))
        .toList();
      const definedDestinations = transformation
        .get(mappingType, List())
        .map((input) => input.get('destination'))
        .toList();
      return mappings.concat(
        mapping
          .get('tables')
          .filter((tableMapping) => !definedSources.includes(tableMapping.get('source')))
          .map((tableMapping) => {
            if (definedDestinations.includes(tableMapping.get('destination'))) {
              return tableMapping.set(
                'destination',
                prepareUniqueDestination(tableMapping, definedDestinations)
              );
            }
            return tableMapping;
          })
          .map((tableMapping) => {
            let tableSpecificMapping = Map({
              source: tableMapping.get('source'),
              destination: tableMapping.get('destination')
            });

            if (tableMapping.has('datatypes')) {
              tableSpecificMapping = tableSpecificMapping.set(
                'datatypes',
                tableMapping.get('datatypes')
              );
            }

            return mapping.merge(tableSpecificMapping);
          })
          .map((tableMapping) => tableMapping.delete('tables'))
          .toList()
      );
    }

    return mappings.push(mapping);
  });
};

const getEnabledBucketPhasesCount = (bucket) => {
  const phases = bucket
    .get('transformations', List())
    .filter((transformation) => !transformation.get('disabled'))
    .map((transformation) => parseInt(transformation.get('phase', 1), 10));

  return new Set(phases).count();
};

export {
  reorderTransformationInputs,
  updateTransformationMappings,
  isTableBiggerThat100MB,
  canBeTableCloned,
  isInputMappingSourceBucket,
  prepareUniqueDestination,
  getEnabledBucketPhasesCount
};
