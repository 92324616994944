import React from 'react';
import createReactClass from 'create-react-class';

import Link from '../../../../../react/common/RouterLink';

const migrateTransformationNotification = (component, config) => {
  return createReactClass({
    render() {
      return (
        <>
          <Link
            to="transformations-v2"
            params={{
              component,
              config
            }}
          >
            Transformation
          </Link>{' '}
          has been successfully migrated.
        </>
      );
    }
  });
};

export default migrateTransformationNotification;
