import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Confirm from '../../../../react/common/Confirm';
import Loader from '../../../../react/common/Loader';
import useStores from '../../../../react/hooks/useStores';
import ApplicationStore from '../../../../stores/ApplicationStore';
import { canPurgeTrash } from '../../../admin/privileges';
import InstalledComponentsActions from '../../../components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';
import DevBranchesStore from '../../../dev-branches/DevBranchesStore';

const TrashHeaderButtons = () => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { isDevModeActive, deletedComponents, sapiToken } = useStores(
    () => ({
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      deletedComponents: InstalledComponentsStore.getAllDeleted(),
      sapiToken: ApplicationStore.getSapiToken()
    }),
    [],
    [DevBranchesStore, InstalledComponentsStore, ApplicationStore]
  );

  const handleDelete = () => {
    setIsDeleting(true);
    InstalledComponentsActions.deleteAllConfigurationsPermanently().finally(() => {
      setIsDeleting(false);
    });
  };

  if (!canPurgeTrash(sapiToken) || !deletedComponents.count() || isDevModeActive) {
    return null;
  }

  return (
    <Confirm
      icon="trash"
      title="Empty Trash"
      text="Are you sure you want to permanently delete all configurations in Trash?"
      buttonLabel="Empty Trash"
      onConfirm={handleDelete}
    >
      <Button bsStyle="danger" disabled={isDeleting}>
        {isDeleting ? (
          <>
            <Loader /> Deleting configurations...
          </>
        ) : (
          <>
            <FontAwesomeIcon icon="trash" className="icon-addon-right" />
            Empty Trash
          </>
        )}
      </Button>
    </Confirm>
  );
};

export default TrashHeaderButtons;
