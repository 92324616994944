import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import ExternalLink from '../../../react/common/ExternalLink';
import { parse as parseTable } from '../../../utils/tableIdParser';
import { routeNames } from '../constants';

const ExternalProjectTableLink = createReactClass({
  propTypes: {
    table: PropTypes.object.isRequired,
    urlTemplates: PropTypes.object.isRequired
  },

  render() {
    const { table, urlTemplates } = this.props;
    const tableId = table.get('id');
    const projectId = table.getIn(['project', 'id']);
    const projectName = table.getIn(['project', 'name']);
    const parsedTable = parseTable(tableId);

    return (
      <span>
        <ExternalLink href={_.template(urlTemplates.get('project'))({ projectId })}>
          {projectName}
        </ExternalLink>
        {' / '}
        <ExternalLink
          href={
            _.template(urlTemplates.get('project'))({ projectId }) +
            `/${routeNames.ROOT}/${parsedTable.parts.stage}.${parsedTable.parts.bucket}/${parsedTable.parts.table}`
          }
        >
          {tableId}
        </ExternalLink>
      </span>
    );
  }
});

export default ExternalProjectTableLink;
