import React from 'react';

import { KEBOOLA_NO_CODE_DBT_TRANSFORMATION } from './constants/componentIds';
import billingRoutes from './modules/billing/routes';
import bucketBrowser from './modules/bucket-browser/routes';
import components from './modules/components-directory/routes';
import dataAppsRoutes from './modules/data-apps/routes';
import dataCatalogSharedFromThisProject from './modules/data-catalog/routes-shared-from-project';
import dataCatalogSharedWithYou from './modules/data-catalog/routes-shared-with-you';
import DataTakeout from './modules/data-takeout/Index';
import devBranchesRoutes from './modules/dev-branches/routes';
import flowRoutes from './modules/flows/routes';
import homeRoute from './modules/home/routes';
import jobRoutes from './modules/jobs/Routes';
import orchestrationRoutes from './modules/orchestrations/Routes';
import orchestrations from './modules/orchestrations-v2/routes';
import queueRoutes from './modules/queue/routes';
import workspacesRoutes from './modules/sandboxes/routes';
import { routeNames as settingsRouteNames } from './modules/settings/constants';
import settings from './modules/settings/routes';
import snowflakePartnerConnectRoutes from './modules/snowflake-partner-connect/routes';
import storageRoutes from './modules/storage/routes';
import tableBrowser from './modules/table-browser/routes';
import templatesRoutes from './modules/templates/routes';
import transformationRoutes from './modules/transformations/Routes';
import transformations from './modules/transformations-v2/routes';
import trashRoutes from './modules/trash/routes';
import Link from './react/common/RouterLink';
import App from './react/layout/App';
import ErrorPage from './react/pages/ErrorPage';

export const routesDisabledInBranch = [
  'templates',
  'data-catalog-shared-with-you',
  'data-catalog-shared-from-this-project',
  'transformations/sandbox',
  'orchestrations',
  'data-takeout',
  'project-settings',
  'users-settings',
  'tokens-settings',
  'cli-settings',
  'models',
  `transformations-v2/${KEBOOLA_NO_CODE_DBT_TRANSFORMATION}`
];

export const routesDisabledInDemoPreview = [
  'data-catalog-shared-with-you',
  'data-catalog-shared-from-this-project',
  'transformations',
  `transformations-v2/workspaces`,
  'orchestrations',
  'orchestrations-v2',
  'data-takeout',
  'project-settings',
  'users-settings',
  'tokens-settings',
  'cli-settings',
  'models'
];

export const routesDisabledInProduction = ['development-overview'];

export const routesDisabledInProtectedDefaultBranch = ['settings-trash'];

export const routesDisabledInSPCTrial = ['templates', 'transformations-v2', 'workspaces'];

export default {
  handler: App,
  ...homeRoute,
  notFoundRouteHandler: ErrorPage,
  childRoutes: [
    components,
    transformations, // new
    orchestrations, // new
    flowRoutes, // new
    dataAppsRoutes,
    dataCatalogSharedWithYou,
    dataCatalogSharedFromThisProject,
    storageRoutes,
    orchestrationRoutes,
    transformationRoutes,
    workspacesRoutes,
    jobRoutes,
    queueRoutes,
    bucketBrowser,
    tableBrowser,
    trashRoutes,
    templatesRoutes,
    devBranchesRoutes,
    snowflakePartnerConnectRoutes,
    ...settings,
    ...billingRoutes,
    {
      name: 'data-takeout',
      title: 'Data Takeout',
      breadcrumbHandler: () => (
        <Link className="dark muted" to={settingsRouteNames.PROJECT}>
          Settings
        </Link>
      ),
      defaultRouteHandler: DataTakeout
    }
  ]
};
