import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import PureRendererMixin from 'react-immutable-render-mixin';
import InfiniteScroll from 'react-infinite-scroller';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import _ from 'underscore';

import BlockButton from '../../../react/common/BlockButton';
import Loader from '../../../react/common/Loader';
import SearchBar from '../../../react/common/SearchBar';
import RoutesStore from '../../../stores/RoutesStore';
import { factory as eventsFactory } from '../EventsService';
import { getComponent } from '../helpers';
import EventDetailModal from './EventDetailModal';
import EventsTable from './EventsTable';

const Events = createReactClass({
  mixins: [PureRendererMixin],

  propTypes: {
    link: PropTypes.object.isRequired,
    params: PropTypes.object,
    autoReload: PropTypes.bool,
    eventsApi: PropTypes.object
  },

  _handleChange() {
    const currentEventId = RoutesStore.getRouterState().getIn(['location', 'query', 'eventId']);
    const currentEvent = this._events.getEvent(currentEventId);

    this.setState({
      currentEvent,
      currentEventId,
      showDetailModal: !!currentEventId,
      searchQuery: this._events.getQuery(),
      events: this._events.getEvents(),
      isLoading: this._events.getIsLoading(),
      isLoadingOlder: this._events.getIsLoadingOlder(),
      hasMore: this._events.getHasMore(),
      errorMessage: this._events.getErrorMessage()
    });
  },

  getInitialState() {
    return {
      events: Map(),
      isLoadingOlder: false,
      isLoading: false,
      hasMore: true,
      searchQuery: '',
      searchQueryLocal: '',
      showDetailModal: false,
      currentEvent: null,
      currentEventId: RoutesStore.getRouterState().getIn(['location', 'query', 'eventId'], null)
    };
  },

  componentDidMount() {
    this._createEventsService(this.props.params, this.props.eventsApi);
    this._events.load();
    this._events.setAutoReload(this.props.autoReload);

    if (this.state.currentEventId) {
      this._events.loadEvent(this.state.currentEventId);
    }
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(nextProps.params, this.props.params) ||
      !_.isEqual(nextProps.eventsApi, this.props.eventsApi)
    ) {
      this._destroyEventsService();
      this._createEventsService(nextProps.params, nextProps.eventsApi);
      this._events.setQuery(this.state.searchQuery);
      this._events.load();
    }

    this._events.setAutoReload(nextProps.autoReload);
  },

  componentWillUnmount() {
    this._destroyEventsService();
  },

  render() {
    return (
      <>
        {this.state.errorMessage && <Alert bsStyle="danger">{this.state.errorMessage}</Alert>}
        <div className="box">
          <div className="box-header above-table">
            <h2 className="box-title">Log</h2>
            <div className="box-filters">
              <SearchBar
                bordered
                placeholder="SEARCH EVENTS"
                query={this.state.searchQueryLocal}
                onChange={this.handleQueryChange}
                onSubmit={this.handleSearchSubmit}
                className="box-search box-search-wider"
              />
            </div>
          </div>
          {this.renderEventsList()}
        </div>
        {this.renderEventDetail()}
      </>
    );
  },

  renderEventsList() {
    if (!this.state.events.count()) {
      if (this.state.isLoading) {
        return (
          <div className="box-content">
            <Loader /> Loading..
          </div>
        );
      }

      return <div className="box-content">No events found.</div>;
    }

    return (
      <>
        <InfiniteScroll
          initialLoad={false}
          hasMore={this.state.hasMore}
          loadMore={this.handleLoadMore}
        >
          <EventsTable
            isLoading={this.state.isLoading}
            events={this.state.events}
            onEventSelect={this.handleEventSelect}
          />
        </InfiniteScroll>
        {this.renderMoreButton()}
      </>
    );
  },

  renderEventDetail() {
    const event = this.state.currentEvent || Map();

    return (
      <EventDetailModal
        show={this.state.showDetailModal}
        onHide={this.handleHideDetail}
        event={event}
        component={getComponent(event)}
      />
    );
  },

  renderMoreButton() {
    if (!this.state.hasMore) {
      return null;
    }

    return <BlockButton onClick={this.handleLoadMore} isLoading={this.state.isLoadingOlder} />;
  },

  handleLoadMore() {
    if (this.state.isLoadingOlder) return;

    this._events.loadMoreMax();
  },

  handleQueryChange(query) {
    this.setState({ searchQueryLocal: query });
  },

  handleSearchSubmit() {
    this._events.setQuery(this.state.searchQueryLocal);
    this._events.load();
  },

  handleEventSelect(event) {
    this.setState({
      showDetailModal: true,
      currentEvent: this._events.getEvent(event.get('id')),
      currentEventId: event.get('id')
    });

    RoutesStore.getRouter().transitionTo(this.props.link.to, this.props.link.params, {
      eventId: event.get('id')
    });
  },

  handleHideDetail() {
    this.setState({ showDetailModal: false });

    RoutesStore.getRouter().transitionTo(this.props.link.to, this.props.link.params);
  },

  _createEventsService(params, eventsApi) {
    this._events = eventsFactory(params, eventsApi);
    this._events.addChangeListener(this._handleChange);
  },

  _destroyEventsService() {
    this._events.removeChangeListener(this._handleChange);
    this._events.reset();
  }
});

export default Events;
