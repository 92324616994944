import React from 'react';
import { Modal, Table, Well } from 'react-bootstrap';
import type { Map } from 'immutable';

import Clipboard from '../../../../react/common/Clipboard';
import ModalIcon from '../../../../react/common/ModalIcon';

const MLFlowArtifactsModal = ({
  models,
  show,
  onHide
}: {
  models: Map<string, any>;
  show: boolean;
  onHide: () => void;
}) => {
  return (
    <Modal bsSize="large" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>MLFlow models</Modal.Title>
        <ModalIcon icon="rotate" />
      </Modal.Header>
      <Modal.Body>
        <Well className="with-table">
          <Table>
            <thead>
              <tr>
                <th className="w-200">Name</th>
                <th className="w-200">Stage</th>
                <th className="w-100">Deployment URL</th>
                <th className="w-100">Artifact URL</th>
              </tr>
            </thead>
            <tbody>
              {models
                .sortBy((model) => model.get('name'))
                .map((model, index) => {
                  const deployment = model.getIn(['deployment', 'url']);
                  const artifactUrl = model.get('artifactUrl');
                  return (
                    <tr key={index}>
                      <td>{model.get('name')}</td>
                      <td>{model.get('stage')}</td>
                      <td className="text-center">
                        <span className="flex-container inline-flex flex-start">
                          {deployment ? (
                            <>
                              <Clipboard text={deployment} />
                            </>
                          ) : (
                            <span>None</span>
                          )}
                        </span>
                      </td>
                      <td>
                        <Clipboard text={artifactUrl} />
                      </td>
                    </tr>
                  );
                })
                .toArray()}
            </tbody>
          </Table>
        </Well>
      </Modal.Body>
    </Modal>
  );
};

export default MLFlowArtifactsModal;
