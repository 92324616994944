import React from 'react';
import createReactClass from 'create-react-class';

import JobPartialRunLabel from '../../../../react/common/JobPartialRunLabel';
import JobStatusLabel from '../../../../react/common/JobStatusLabel';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import RoutesStore from '../../../../stores/RoutesStore';
import JobsStore from '../../stores/JobsStore';

const JobDetailStatus = createReactClass({
  mixins: [createStoreMixin(JobsStore)],

  getStateFromStores() {
    const jobId = RoutesStore.getCurrentRouteIntParam('jobId');

    return {
      job: JobsStore.get(jobId)
    };
  },

  render() {
    if (!this.state.job) {
      return null;
    }

    return (
      <>
        <JobPartialRunLabel
          job={JobsStore.getUserRunnedParentJob(this.state.job)}
          className="ml-1"
        />
        <JobStatusLabel status={this.state.job.get('status')} />
      </>
    );
  }
});

export default JobDetailStatus;
