import React from 'react';
import classNames from 'classnames';
import type { Map } from 'immutable';

import Checkbox from '../../../../react/common/Checkbox';
import TimeAndUser from '../../../../react/common/TimeAndUser';
import Truncated from '../../../../react/common/Truncated';
import descriptionExcerpt from '../../../../utils/descriptionExcerpt';
import InstalledComponentsActionCreators from '../../../components/InstalledComponentsActionCreators';
import { getDisabledRestoreReason } from '../../utils';
import DeleteConfigurationButton from './DeleteConfigurationButton';
import RestoreConfigurationButton from './RestoreConfigurationButton';

type Props = {
  readOnly: boolean;
  config: Map<string, any>;
  component: Map<string, any>;
  admins: Map<string, any>;
  isDeleteEnabled: boolean;
  isDeleting: boolean;
  isRestoring: boolean;
  isSelected: boolean;
  isRestoreEnabled: boolean;
  onSelect: (isChecked: boolean) => void;
};

const DeletedConfigurationRow = ({
  readOnly,
  config,
  admins,
  component,
  isDeleteEnabled,
  isDeleting,
  isRestoring,
  isRestoreEnabled,
  isSelected,
  onSelect
}: Props) => {
  const handleDelete = () => {
    return InstalledComponentsActionCreators.deleteConfigurationPermanently(
      component.get('id'),
      config.get('id')
    );
  };

  const handleRestore = () => {
    return InstalledComponentsActionCreators.restoreConfiguration(component, config);
  };

  const renderActions = () => {
    if (readOnly) {
      return null;
    }

    return (
      <>
        <RestoreConfigurationButton
          text={
            <>
              Are you sure you want to restore the configuration <b>{config.get('name')}</b>?
            </>
          }
          disabledReason={getDisabledRestoreReason(component.get('id'))}
          onConfirm={handleRestore}
          isPending={isRestoring}
        />
        {isDeleteEnabled && (
          <DeleteConfigurationButton
            config={config}
            onConfirm={handleDelete}
            isPending={isDeleting}
          />
        )}
      </>
    );
  };

  return (
    <span
      className={classNames('tr hoverable-actions-with-replacement', { 'bg-selected': isSelected })}
    >
      <span className={classNames('td', isSelected && 'bg-selected')}>
        <div className="tw-flex tw-items-center tw-gap-3">
          {!readOnly && isRestoreEnabled && (
            <Checkbox
              className="tw-mb-0.5"
              tooltip={`${isSelected ? 'Deselect' : 'Select'} configuration`}
              checked={isSelected}
              onChange={onSelect}
            />
          )}
          <div>
            <Truncated text={config.get('name', '---')} />
            {config.get('description') && (
              <div>
                <span className="f-13 text-muted">
                  {descriptionExcerpt(config.get('description'))}
                </span>
              </div>
            )}
          </div>
        </div>
      </span>
      <span className={classNames('td w-200 text-right', isSelected && 'bg-selected')}>
        <div className="actions-container">
          <div className="not-actions">
            <TimeAndUser
              admin={admins.get(config.getIn(['currentVersion', 'creatorToken', 'description']))}
              time={config.getIn(['currentVersion', 'created'])}
              fallbackName={config.getIn(['currentVersion', 'creatorToken', 'description'])}
            />
          </div>
          <div className="actions">
            <span className="no-wrap">{renderActions()}</span>
          </div>
        </div>
      </span>
    </span>
  );
};

export default DeletedConfigurationRow;
