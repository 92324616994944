import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import ActivateDeactivateButton from '../../../../react/common/ActivateDeactivateButton';
import ActivateDeactivateSwitch from '../../../../react/common/ActivateDeactivateSwitch';
import OrchestrationActionCreators from '../../ActionCreators';

const OrchestrationActiveButton = createReactClass({
  propTypes: {
    orchestration: PropTypes.object.isRequired,
    isPending: PropTypes.bool.isRequired,
    mode: PropTypes.string,
    buttonDisabled: PropTypes.bool,
    onKeyDown: PropTypes.func
  },

  render() {
    if (this.props.mode === 'link') {
      return (
        <ActivateDeactivateButton
          isActive={this.props.orchestration.get('active')}
          isPending={this.props.isPending}
          onChange={this._handleActiveChange}
          buttonDisabled={this.props.buttonDisabled}
        />
      );
    }

    return (
      <ActivateDeactivateSwitch
        mode="menuitem"
        isActive={this.props.orchestration.get('active')}
        isPending={this.props.isPending}
        onKeyDown={this.props.onKeyDown}
        onChange={this._handleActiveChange}
        buttonDisabled={this.props.buttonDisabled}
      />
    );
  },

  _handleActiveChange(newValue) {
    if (newValue) {
      return OrchestrationActionCreators.activateOrchestration(this.props.orchestration.get('id'));
    }

    return OrchestrationActionCreators.disableOrchestration(this.props.orchestration.get('id'));
  }
});

export default OrchestrationActiveButton;
