import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, List, Map } from 'immutable';
import { capitalize, strLeft } from 'underscore.string';

import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '../../constants/features';
import SandboxesActions from '../../modules/sandboxes/Actions';
import AddSandboxModal, { FORM_STEPS } from '../../modules/sandboxes/components/AddSandboxModal';
import { updateExistingWorkspace } from '../../modules/sandboxes/helpers';
import { DBT_COMPONENTS } from '../../modules/transformations-v2/constants';
import ApplicationStore from '../../stores/ApplicationStore';
import ConfirmModal from './ConfirmModal';
import Loader from './Loader';
import MultiActionsHeader from './MultiActionsHeader';
import SortIcon from './SortIcon';
import Tooltip from './Tooltip';

const MODALS = {
  DELETE: 'DELETE',
  CREATE_WORKSPACE: 'CREATE_WORKSPACE',
  USE_WORKSPACE: 'USE_WORKSPACE'
};

export const MappingsHeader = ({ type, componentId, storage }) => {
  return (
    <h2 className="box-title tw-leading-10">
      {DBT_COMPONENTS.includes(componentId) && storage === 'tables' && type === 'input' ? (
        <>
          Storage Sources
          <Tooltip
            tooltip="Optionally, define sources from the storage. This will help to de-clutter lineage and documentation. Please note no data will be physically loaded during execution as dbt has read access to the storage. Platform will generate only selected tables sources.yml during execution."
            placement="top"
            type="explanatory"
          >
            <FontAwesomeIcon icon="circle-info" className="title-hint" />
          </Tooltip>
        </>
      ) : (
        `${capitalize(strLeft(storage, 's'))} ${capitalize(type)} Mapping`
      )}
    </h2>
  );
};

const MappingsMultiActionsHeader = ({
  hide,
  disabled,
  componentId,
  configurationId,
  type,
  storage,
  allMappings,
  selectedMappings,
  updateMappingsSelection,
  deleteMappings,
  sandboxComponent,
  allowedComponents,
  availableDatabricksClusters,
  sandboxes,
  hasPayAsYouGo,
  rowId,
  isSorted,
  isSortedDesc,
  onClick
}) => {
  const [activeModal, setActiveModal] = useState('');
  const [isDeletingMappings, setIsDeletingMappings] = useState(false);
  const selectedCount = selectedMappings.count((isTableSelected) => isTableSelected);
  const selectedMappingsStorage = Map().setIn(
    [type, storage],
    allMappings.filter((mapping, index) => !!selectedMappings.get(index, false))
  );
  const allowWorkspaceActions =
    allowedComponents &&
    sandboxComponent &&
    !ApplicationStore.hasCurrentProjectFeature(FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED);
  const isRowSelected = !selectedMappings.isEmpty();

  return (
    <div className="tw-w-[51%]">
      <div
        className="tw-inline-flex tw-items-center tw-cursor-pointer"
        onClick={() => !isRowSelected && onClick()}
      >
        <MultiActionsHeader
          hide={hide}
          disabled={disabled}
          totalCount={allMappings.count()}
          selectedCount={selectedCount}
          onToggleAll={(checked) =>
            updateMappingsSelection(
              !checked
                ? Map()
                : allMappings
                    .map((mapping, index) => [index, true])
                    .fromEntrySeq()
                    .toMap()
            )
          }
          placeholder={
            <div className="tw-mt-px tw-h-5 tw-leading-5 tw-font-medium">
              {type === 'input' ? 'Source' : 'Output'} {capitalize(strLeft(storage, 's'))}
            </div>
          }
          entity="mapping"
        >
          <div className="tw-mt-px tw-h-5 table-action-buttons">
            <Tooltip placement="top" tooltip="Delete Selected">
              <Button
                bsStyle="link"
                className="text-muted"
                onClick={() => setActiveModal(MODALS.DELETE)}
                disabled={isDeletingMappings}
              >
                {isDeletingMappings ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
              </Button>
            </Tooltip>
            {allowWorkspaceActions && (
              <>
                <Tooltip placement="top" tooltip="Create new Workspace">
                  <Button
                    bsStyle="link"
                    className="btn-link-inline btn-link-muted"
                    onClick={() => setActiveModal(MODALS.CREATE_WORKSPACE)}
                  >
                    <div className="add-workspace-icon">
                      <FontAwesomeIcon icon="box" fixedWidth />
                      <FontAwesomeIcon icon="circle-plus" />
                    </div>
                  </Button>
                </Tooltip>
                <Tooltip placement="top" tooltip="Copy to existing Workspace">
                  <Button
                    bsStyle="link"
                    className="btn-link-inline btn-link-muted"
                    onClick={() => setActiveModal(MODALS.USE_WORKSPACE)}
                  >
                    <div className="add-workspace-icon">
                      <FontAwesomeIcon icon="box" fixedWidth />
                      <FontAwesomeIcon icon="circle-arrow-right" />
                    </div>
                  </Button>
                </Tooltip>
              </>
            )}
            <ConfirmModal
              show={activeModal === MODALS.DELETE}
              icon="trash"
              title="Delete Selected"
              text="Are you sure you want to delete selected mappings?"
              buttonLabel="Delete"
              buttonType="danger"
              onConfirm={() => {
                setIsDeletingMappings(true);

                return deleteMappings(
                  componentId,
                  configurationId,
                  rowId,
                  type,
                  storage,
                  selectedMappings
                )
                  .then(() => updateMappingsSelection(Map()))
                  .finally(() => setIsDeletingMappings(false));
              }}
              onHide={() => setActiveModal('')}
            />
            {allowWorkspaceActions && (
              <AddSandboxModal
                hasTableInputMapping
                show={['CREATE_WORKSPACE', 'USE_WORKSPACE'].includes(activeModal)}
                forceStep={activeModal === 'USE_WORKSPACE' ? FORM_STEPS.SANDBOX_UPDATE : null}
                onHide={() => setActiveModal('')}
                sandboxComponent={sandboxComponent}
                workspaces={sandboxes.filter(
                  (sandbox) => sandbox.get('configurationId') !== configurationId
                )}
                allowedComponents={allowedComponents}
                availableDatabricksClusters={availableDatabricksClusters}
                hasPayAsYouGo={hasPayAsYouGo}
                onUpdate={(workspace, preserve) => {
                  const preparedConfig = fromJS({
                    configuration: {
                      parameters: { id: workspace.get('id') },
                      storage: selectedMappingsStorage
                    }
                  });

                  return updateExistingWorkspace(
                    preparedConfig,
                    workspace,
                    preserve,
                    'Update mapping'
                  );
                }}
                onSubmit={(name, type, options, params, description) => {
                  return SandboxesActions.createSandbox(
                    {
                      name,
                      description,
                      configuration: JSON.stringify({ storage: selectedMappingsStorage })
                    },
                    type,
                    options,
                    params.set('storage', selectedMappingsStorage)
                  );
                }}
              />
            )}
          </div>
        </MultiActionsHeader>
        {!isRowSelected && (
          <SortIcon className="tw-pl-2" isSorted={isSorted} isSortedDesc={isSortedDesc} />
        )}
      </div>
    </div>
  );
};

MappingsMultiActionsHeader.propTypes = {
  hide: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  componentId: PropTypes.string.isRequired,
  configurationId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['input', 'output']).isRequired,
  storage: PropTypes.oneOf(['tables', 'files']).isRequired,
  allMappings: PropTypes.instanceOf(List).isRequired,
  selectedMappings: PropTypes.instanceOf(Map).isRequired,
  updateMappingsSelection: PropTypes.func.isRequired,
  deleteMappings: PropTypes.func.isRequired,
  availableDatabricksClusters: PropTypes.instanceOf(List),
  sandboxComponent: PropTypes.instanceOf(Map),
  allowedComponents: PropTypes.instanceOf(Map),
  sandboxes: PropTypes.instanceOf(Map),
  hasPayAsYouGo: PropTypes.bool,
  rowId: PropTypes.string
};

export default MappingsMultiActionsHeader;
