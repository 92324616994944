import React from 'react';
import PropTypes from 'prop-types';
import { Alert, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import {
  KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
  KEBOOLA_ORACLE_TRANSFORMATION
} from '../../../../../constants/componentIds';
import { USER_DOCUMENTATION_URL } from '../../../../../constants/KbcConstants';
import Checkbox from '../../../../../react/common/Checkbox';
import ExternalLink from '../../../../../react/common/ExternalLink';
import InfoAlert from '../../../../../react/common/InfoAlert';
import DevBranchesStore from '../../../../dev-branches/DevBranchesStore';
import { backends } from '../../../../storage/constants';
import { prepareUniqueDestination } from '../../../../transformations/helpers';
import InputMappingSource from '../../../../transformations/react/components/mapping/InputMappingSource';
import { DBT_COMPONENTS } from '../../../../transformations-v2/constants';
import DataTypes from '../../../../wr-db/templates/dataTypes';
import { ioType } from '../../../Constants';
import { shouldHideExternalBuckets } from '../../../helpers';
import DatatypesEditor from './datatypes/DatatypesEditor';
import {
  getCustomColumns,
  getDatatypesComponentId,
  getDefaultDatatype,
  prepareDatatypes
} from './datatypes/helpers';
import ChangedSinceFilterInput from './ChangedSinceFilterInput';
import ColumnsSelectRow from './ColumnsSelectRow';
import DataFilterRow from './DataFilterRow';
import { canForceSourceBranch, hasInputMappingTables, hasWorkspaceViewLoad } from './helpers';

const TableInputMappingEditor = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    editingNonExistentTable: PropTypes.bool.isRequired,
    isDestinationDuplicate: PropTypes.bool.isRequired,
    destinationType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    otherMappings: PropTypes.object.isRequired,
    savedValue: PropTypes.object,
    replacementComponentId: PropTypes.string
  },

  render() {
    if (DBT_COMPONENTS.includes(this.props.componentId)) {
      return this.renderSourceSelector();
    }

    if (
      [this.props.componentId, this.props.replacementComponentId].includes(
        KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION
      )
    ) {
      return (
        <>
          {this.renderSourceSelector()}
          {this.renderDestinationInput()}
        </>
      );
    }

    const multipleTables = hasInputMappingTables(this.props.value);
    const supportsOptions = !this.isTypedTable() && !this.isBigqueryBackend();

    return (
      <>
        {this.renderSourceSelector()}
        {!multipleTables && (
          <>
            {this.renderDestinationInput()}
            {supportsOptions && (
              <>
                <ColumnsSelectRow
                  value={this.props.value}
                  disabled={this.props.disabled}
                  onChange={this.props.onChange}
                  allTables={this.props.tables}
                  componentId={this.props.componentId}
                  formType="normal"
                />
                {this.renderKeepInternalTimestampColumnCheckbox()}
              </>
            )}
          </>
        )}
        {supportsOptions && (
          <ChangedSinceFilterInput
            mapping={this.props.value}
            disabled={this.props.disabled}
            onChange={this.props.onChange}
            formType="normal"
          />
        )}
        {!multipleTables && supportsOptions && (
          <>
            <DataFilterRow
              value={this.props.value}
              disabled={this.props.disabled}
              onChange={this.props.onChange}
              allTables={this.props.tables}
              componentId={this.props.componentId}
              formType="normal"
            />
            {this.renderDatatypesEditor(this.props.disabled)}
          </>
        )}
        {this.isTypedTable() && (
          <InfoAlert className="tw-mb-4">
            Currently, our filtering option in the input mapping section is not available for tables
            with defined data types.{' '}
            <ExternalLink
              href={`${USER_DOCUMENTATION_URL}/storage/tables/data-types/#pros-and-cons`}
            >
              Learn more
            </ExternalLink>
          </InfoAlert>
        )}
      </>
    );
  },

  renderSourceSelector() {
    return (
      <>
        {this.props.editingNonExistentTable && this.props.value.has('source') && (
          <Alert bsStyle="warning">The source table does not exist</Alert>
        )}
        <div className="form-group">
          <ControlLabel>Source</ControlLabel>
          <InputMappingSource
            mode={this.props.mode}
            value={this.props.value}
            tables={this.props.tables}
            buckets={this.props.buckets}
            onChange={this.handleChangeSource}
            disabled={this.props.disabled}
            disableBuckets={this.props.componentId === KEBOOLA_ORACLE_TRANSFORMATION}
            isDestinationDuplicate={this.props.isDestinationDuplicate}
            hideExternalBuckets={shouldHideExternalBuckets(this.props.componentId)}
            otherMappings={this.props.otherMappings}
          />
        </div>
        {hasWorkspaceViewLoad(this.props.componentId, this.props.replacementComponentId) && (
          <div className="form-group">
            <Checkbox
              checked={this.props.value.get('use_view', false)}
              onChange={(checked) => this.props.onChange(this.props.value.set('use_view', checked))}
            >
              Load using view
            </Checkbox>
          </div>
        )}
        {canForceSourceBranch() && (
          <div className="form-group">
            <Checkbox
              checked={!!this.props.value.get('source_branch_id')}
              onChange={(checked) => {
                this.props.onChange(
                  checked
                    ? this.props.value.set(
                        'source_branch_id',
                        DevBranchesStore.getDefaultBranchId()
                      )
                    : this.props.value.delete('source_branch_id')
                );
              }}
            >
              Force production table
            </Checkbox>
          </div>
        )}
      </>
    );
  },

  renderDestinationInput() {
    return (
      <FormGroup validationState={this.props.isDestinationDuplicate ? 'error' : null}>
        <ControlLabel>
          {this.props.destinationType === ioType.TABLE ? 'Table name' : 'File name'}
        </ControlLabel>
        <FormControl
          type="text"
          value={this.props.value.get('destination', '')}
          disabled={this.props.disabled}
          placeholder={this.props.destinationType === ioType.TABLE ? 'Table name' : 'File name'}
          onChange={this.handleChangeDestination}
          className={classNames({
            uppercase: this.props.componentId === KEBOOLA_ORACLE_TRANSFORMATION
          })}
        />
        {this.props.isDestinationDuplicate && (
          <HelpBlock className="text-danger">
            Duplicate destination <code>{this.props.value.get('destination')}</code>
          </HelpBlock>
        )}
        {!this.props.isDestinationDuplicate && this.props.destinationType === ioType.FILE && (
          <HelpBlock>
            The file will be available at <code>{`in/tables/${this.getFileName()}`}</code>
          </HelpBlock>
        )}
      </FormGroup>
    );
  },

  renderKeepInternalTimestampColumnCheckbox() {
    const allMapings = this.props.otherMappings.push(this.props.savedValue).filter(Boolean);

    if (
      allMapings.every((mapping) => !mapping.has('keep_internal_timestamp_column')) ||
      allMapings.every((mapping) => mapping.get('keep_internal_timestamp_column') === false)
    ) {
      return null;
    }

    return (
      <FormGroup>
        <Checkbox
          checked={this.props.value.get('keep_internal_timestamp_column', false)}
          onChange={(checked) => {
            this.props.onChange(this.props.value.set('keep_internal_timestamp_column', checked));
          }}
        >
          Keep internal <code>_timestamp</code> column.
        </Checkbox>
      </FormGroup>
    );
  },

  renderDatatypesEditor(disabled) {
    const datatypesComponentId = getDatatypesComponentId(
      this.props.replacementComponentId || this.props.componentId
    );
    const datatypes = DataTypes[datatypesComponentId];

    if (!datatypes) {
      return null;
    }

    return (
      <DatatypesEditor
        value={this.props.value}
        tables={this.props.tables}
        onChange={this.props.onChange}
        datatypesComponentId={datatypesComponentId}
        datatypes={prepareDatatypes(datatypes.typesList)}
        defaultDatatype={getDefaultDatatype(this.props.componentId, datatypes)}
        customColumns={getCustomColumns(this.props.componentId)}
        componentId={this.props.componentId}
        disabled={disabled}
      />
    );
  },

  handleChangeSource(selectedTables) {
    const selected = List.isList(selectedTables)
      ? selectedTables.count((value) => this.props.tables.has(value)) === 1
        ? selectedTables.first()
        : selectedTables
      : selectedTables;
    const table = this.props.tables.get(selected);
    const isSelectedMultipleOptions = List.isList(selected);
    const isFile = this.props.destinationType === ioType.FILE;
    const otherDestinations = this.props.otherMappings.map((mapping) => mapping.get('destination'));

    if (!isSelectedMultipleOptions && table) {
      const destination = `${table.get('displayName', '')}${isFile ? '.csv' : ''}`;
      let mapping = this.props.value.withMutations((mapping) => {
        mapping.set('source', selected);
        mapping.set('destination', destination);
        mapping.set('where_column', '');
        mapping.set('where_values', List());
        mapping.set('where_operator', 'eq');
        mapping.set('columns', List());
        mapping.delete('tables');
      });

      if (otherDestinations.includes(destination)) {
        mapping = mapping.set('destination', prepareUniqueDestination(mapping, otherDestinations));
      }

      return this.props.onChange(mapping);
    }

    const mapping = this.props.value.withMutations((mapping) => {
      mapping.set('source', selected);
      mapping.set(
        'tables',
        selected
          .filter((value) => this.props.tables.has(value))
          .map((tableId) =>
            Map({
              source: this.props.tables.getIn([tableId, 'id']),
              destination: `${this.props.tables.getIn([tableId, 'displayName'])}${
                isFile ? '.csv' : ''
              }`
            })
          )
      );
      mapping.set('where_column', '');
      mapping.set('where_values', List());
      mapping.set('where_operator', 'eq');
      mapping.set('columns', List());
    });
    return this.props.onChange(mapping);
  },

  handleChangeDestination(e) {
    const value = this.props.value.set('destination', e.target.value.trim());
    return this.props.onChange(value);
  },

  getFileName() {
    if (this.props.value.get('destination') && this.props.value.get('destination') !== '') {
      return this.props.value.get('destination');
    }

    if (this.props.value.get('source') && this.props.value.get('source') !== '') {
      return this.props.value.get('source');
    }

    return '';
  },

  isTypedTable() {
    return this.props.tables.getIn([this.props.value.get('source'), 'isTyped'], false);
  },

  isBigqueryBackend() {
    return (
      this.props.tables.getIn([this.props.value.get('source'), 'bucket', 'backend']) ===
      backends.BIGQUERY
    );
  }
});

export default TableInputMappingEditor;
