import extractor from '../../images/component-extractor.png';
import other from '../../images/component-other.png';
import writer from '../../images/component-writer.png';
import { fileUrl } from './fileHelpers';

const DEFAULT_ICON_IMAGES = {
  extractor: extractor,
  writer: writer,
  other: other
};

function getComponentIconUrlByType(componentType) {
  const fileName = DEFAULT_ICON_IMAGES[componentType]
    ? DEFAULT_ICON_IMAGES[componentType]
    : DEFAULT_ICON_IMAGES.other;
  return fileUrl(fileName);
}

function getComponentIconUrl(component) {
  /*
    Use new 128px variant if is not legacy.
    Legacy format looks like 2.png (incremental), new format is like 1666588866694.png (created by Date.now())
  */
  if (!!component.get('ico128') && /\d{12}\.png$/.test(component.get('ico128'))) {
    return component.get('ico128');
  }

  // fallback to 64px variant
  if (!!component.get('ico64')) {
    return component.get('ico64');
  }

  // follback to generic icon
  return getComponentIconUrlByType(component.get('type'));
}

export { getComponentIconUrl };
