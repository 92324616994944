import React from 'react';
import { Alert } from 'react-bootstrap';
import type { Map } from 'immutable';

import { usingTwoVersionOfApi } from '../../google-utils/helpers';

const TwoVersionsApiWarning = ({ profiles }: { profiles: Map<string, any> }) => {
  if (!usingTwoVersionOfApi(profiles)) {
    return null;
  }

  return (
    <Alert bsStyle="warning">
      Using two version of API is not supported. Please choose only profiles with or without{' '}
      <strong>GA4 - Data API</strong>, or switch to an advanced mode, where you will have more
      options for how to set up your connection.
    </Alert>
  );
};

export default TwoVersionsApiWarning;
