import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Label, Panel } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ComponentIcon from '../../../../../react/common/ComponentIcon';
import ComponentName from '../../../../../react/common/ComponentName';
import CreatedDate from '../../../../../react/common/CreatedDate';
import JobDuration from '../../../../../react/common/JobDuration';
import JobStatusLabel from '../../../../../react/common/JobStatusLabel';
import Link from '../../../../../react/common/RouterLink';
import Tree from '../../../../../react/common/Tree';
import ComponentConfigurationLink from '../../../../components/react/components/ComponentConfigurationLink';

const JobTasks = createReactClass({
  propTypes: {
    tasks: PropTypes.object.isRequired,
    hasFlows: PropTypes.bool.isRequired,
    allComponents: PropTypes.object.isRequired
  },

  render() {
    return (
      <div className="box">
        <div className="box-content">
          {this.props.tasks
            .filter((task) => task.get('active'))
            .map(this._renderTask)
            .toArray()}
        </div>
      </div>
    );
  },

  _renderTask(task) {
    const component = this.props.allComponents.get(task.get('component'));

    return (
      <Panel key={task.get('id')} eventKey={task.get('id')}>
        <Panel.Heading>
          <Panel.Title toggle>
            <span className="table">
              <span className="tbody">
                <span className="tr">
                  <span className="td col-xs-6">
                    {component ? (
                      <span>
                        <ComponentIcon
                          component={component}
                          className="icon-addon-right"
                          size="32"
                        />
                        <ComponentName component={component} />
                      </span>
                    ) : (
                      task.get('componentUrl')
                    )}{' '}
                    {task.has('config') && ` - ${task.getIn(['config', 'name'])}`}
                  </span>
                  <span className="td col-xs-2 text-right">
                    <span className="labels-container">
                      <Label className="m-0">{task.get('phase')}</Label>
                    </span>
                  </span>
                  <span className="td col-xs-2 text-right">
                    <JobDuration
                      status={task.get('status')}
                      startTime={task.get('startTime')}
                      endTime={task.get('endTime')}
                    />
                  </span>
                  <span className="td col-xs-2 text-right">
                    {task.get('status', false) && <JobStatusLabel status={task.get('status')} />}
                  </span>
                </span>
              </span>
            </span>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>
          <div className="p-1">
            {task.get('startTime') && (
              <p>
                <strong>{'Start time '}</strong>
                <CreatedDate createdTime={task.get('startTime')} absolute />
              </p>
            )}
            {task.get('endTime') && (
              <p>
                <strong>{'End time '}</strong>
                <CreatedDate createdTime={task.get('endTime')} absolute />
              </p>
            )}
            {task.has('config') && (
              <p>
                <strong>{'Configuration '}</strong>
                <ComponentConfigurationLink
                  componentId={task.get('component')}
                  configId={task.getIn(['config', 'id'])}
                  hasFlows={false}
                >
                  {task.getIn(['config', 'name'])}
                </ComponentConfigurationLink>
              </p>
            )}
            {task.get('runParameters') && task.get('runParameters').size && (
              <div>
                <p>
                  <strong>Parameters</strong>
                </p>
                <Tree data={task.get('runParameters')} />
              </div>
            )}
            {task.getIn(['response', 'id']) && (
              <Link to="jobDetail" params={{ jobId: task.getIn(['response', 'id']) }}>
                Go to job detail
              </Link>
            )}
            {this.renderSpecificErrorMessage(task)}
          </div>
        </Panel.Body>
      </Panel>
    );
  },

  renderSpecificErrorMessage(task) {
    const message = task.getIn(['response', 'message'], '');
    if (message === 'Orchestrations can be started only 2 times for the current ID.') {
      return (
        <Alert bsStyle="danger">
          Maximum {this.props.hasFlows ? 'flow' : 'orchestration'} nesting level (2) was exceeded.
        </Alert>
      );
    }
    return null;
  }
});

export default JobTasks;
