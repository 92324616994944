import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';

class DescriptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      description: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState({ description: this.props.model.get('description') || '' })}
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Model {this.props.model.get('name')} description</Modal.Title>
            <ModalIcon.Edit />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Description</ControlLabel>
              <FormControl
                autoFocus
                componentClass="textarea"
                placeholder="Describe your model"
                value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={this.state.isLoading ? 'Updating description...' : 'Update description'}
              isSaving={this.state.isLoading}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });
    return this.props
      .onSubmit(
        this.props.model.get('name'),
        this.props.model.get('version'),
        this.state.description
      )
      .then(() => this.setState({ isLoading: false }))
      .then(this.props.onHide);
  }
}

DescriptionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  model: PropTypes.instanceOf(Map).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
};

export default DescriptionModal;
