import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import ChangedSinceInput from '../../../../react/common/ChangedSinceInput';
import PanelWithDetails from '../../../../react/common/PanelWithDetails';
import string from '../../../../utils/string';
import { nameWarning } from '../../../storage/constants';

const SourceTable = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      source: PropTypes.string.isRequired,
      changedSince: PropTypes.string.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    const panelExpanded = this.props.value.changedSince !== '';

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Source</h2>
        </div>
        <div className="box-content with-panel">
          <FormGroup>
            <Col componentClass={ControlLabel} sm={4}>
              Table Name
            </Col>
            <Col sm={8}>
              <FormControl
                type="text"
                value={this.props.value.source}
                onChange={(e) => {
                  this.props.onChange({ source: string.sanitizeKbcTableIdString(e.target.value) });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Table name in the source project. If the table ID is <code>in.main.mytable</code>,
                use only the table name <code>mytable</code>. {nameWarning}
              </HelpBlock>
            </Col>
          </FormGroup>
          <PanelWithDetails
            defaultExpanded={panelExpanded}
            placement="bottom"
            labelCollapse="Hide Advanced Options"
            labelOpen="Show Advanced Options"
          >
            <FormGroup>
              <Col componentClass={ControlLabel} sm={4}>
                Changed In Last
              </Col>
              <Col sm={8}>
                <ChangedSinceInput
                  value={this.props.value.changedSince}
                  onChange={(value) => {
                    this.props.onChange({ changedSince: value });
                  }}
                  disabled={this.props.disabled}
                />
              </Col>
            </FormGroup>
          </PanelWithDetails>
        </div>
      </div>
    );
  }
});

export default SourceTable;
