import React from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS } from 'immutable';

import { MAPPINGS_COLLAPSIONS } from '../../../../../constants/localStorageKeys';
import Tooltip from '../../../../../react/common/Tooltip';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import { getItem, setItem } from '../../../../../utils/localStorage';

class CollapsibleMapping extends React.Component {
  static propTypes = {
    readOnly: PropTypes.bool.isRequired,
    children: PropTypes.func.isRequired,
    mappingKey: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    rowId: PropTypes.string
  };

  static defaultProps = {
    rowId: ''
  };

  state = {
    isCollapsed: !this.props.readOnly && this.getStorageObject().get(this.getStorageKey(), false)
  };

  render() {
    return this.props.children({
      renderCollapseSection: this.renderCollapseSection,
      renderToggleButton: this.renderToggleButton,
      isCollapsed: this.state.isCollapsed
    });
  }

  renderCollapseSection = (content) => {
    if (!content) {
      return null;
    }

    return <Collapse in={!this.state.isCollapsed}>{content}</Collapse>;
  };

  renderToggleButton = () => {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <Tooltip
        placement="top"
        tooltip={this.state.isCollapsed ? 'Expand mapping' : 'Collapse mapping'}
      >
        <Button
          bsStyle="link"
          className="btn-link-inline btn-link-muted"
          onClick={() => this.toggleCollapsion()}
        >
          <FontAwesomeIcon
            fixedWidth
            icon={
              this.state.isCollapsed
                ? 'up-right-and-down-left-from-center'
                : 'down-left-and-up-right-to-center'
            }
          />
        </Button>
      </Tooltip>
    );
  };

  toggleCollapsion() {
    setItem(
      MAPPINGS_COLLAPSIONS,
      this.getStorageObject().set(this.getStorageKey(), !this.state.isCollapsed).toJS()
    );
    this.setState((state) => ({ isCollapsed: !state.isCollapsed }));
  }

  getStorageObject() {
    return fromJS(getItem(MAPPINGS_COLLAPSIONS, {}));
  }

  getStorageKey() {
    const currentProjectId = ApplicationStore.getCurrentProjectId();
    const { componentId, configId, rowId, mappingKey } = this.props;

    return `${currentProjectId}-${componentId}-${configId}-${rowId}-${mappingKey}`;
  }
}

export default CollapsibleMapping;
