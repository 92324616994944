import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import RoutesStore from '../../../../stores/RoutesStore';
import { routeNames as componentsRoutes } from '../../../components-directory/constants';
import configurationRowsActions from '../../../configurations/ConfigurationRowsActionCreators';
import configurationsActions from '../../../configurations/ConfigurationsActionCreators';
import ConfigurationRows from '../../../configurations/react/components/ConfigurationRows';
import CreateConfigurationRowButton from '../../../configurations/react/components/CreateConfigurationRowButton';

class GenericRows extends React.Component {
  state = {
    deletePending: Map(),
    enablePending: Map(),
    orderPending: Map()
  };

  render() {
    if (this.props.rows.isEmpty()) {
      return (
        <div className="box-separator">
          <h2 className="tw-text-base tw-m-0 tw-mb-4">Rows</h2>
          <div className="box">
            <div className="box-content text-center">
              <p>No rows created yet.</p>
              {!this.props.readOnly && (
                <CreateConfigurationRowButton
                  buckets={this.props.buckets}
                  tables={this.props.tables}
                  component={this.props.component}
                  configId={this.props.config.get('id')}
                  onRowCreated={this.handleOnRowCreated}
                />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <ConfigurationRows
        readOnly={this.props.readOnly}
        rows={this.props.rows.toList()}
        config={this.props.config}
        component={this.props.component}
        onOrder={this.handleOrder}
        rowDelete={this.handleRowDelete}
        rowEnableDisable={this.handleEnable}
        rowDeletePending={(rowId) => this.state.deletePending.has(rowId)}
        rowEnableDisablePending={(rowId) => this.state.enablePending.has(rowId)}
        orderPending={this.state.orderPending}
        rowLinkTo={this.getDetailLink()}
        onRowCreated={this.handleOnRowCreated}
        columns={this.props.rowsColumns}
        buckets={this.props.buckets}
        tables={this.props.tables}
      />
    );
  }

  handleRowDelete = (rowId) => {
    this.setState({ deletePending: this.state.deletePending.set(rowId, true) });
    return configurationRowsActions
      .delete(this.props.component.get('id'), this.props.config.get('id'), rowId)
      .finally(() => {
        this.setState({ deletePending: this.state.deletePending.delete(rowId) });
      });
  };

  handleEnable = (rowId) => {
    this.setState({ enablePending: this.state.enablePending.set(rowId, true) });
    const action = this.props.rows.getIn([rowId, 'isDisabled'], false) ? 'enable' : 'disable';
    return configurationRowsActions[action](
      this.props.component.get('id'),
      this.props.config.get('id'),
      rowId
    ).finally(() => {
      this.setState({ enablePending: this.state.enablePending.delete(rowId) });
    });
  };

  handleOrder = (rowIds, movedRowId) => {
    this.setState({ orderPending: this.state.orderPending.set(movedRowId, true) });
    return configurationsActions
      .orderRows(this.props.component.get('id'), this.props.config.get('id'), rowIds, movedRowId)
      .finally(() => {
        this.setState({ orderPending: this.state.orderPending.delete(movedRowId) });
      });
  };

  handleOnRowCreated = (rowId) => {
    RoutesStore.getRouter().transitionTo(this.getDetailLink(), {
      component: this.props.component.get('id'),
      config: this.props.config.get('id'),
      row: rowId
    });
  };

  getDetailLink() {
    if (this.props.rowLinkTo) {
      return this.props.rowLinkTo;
    }

    return componentsRoutes.GENERIC_CONFIG_ROW;
  }
}

GenericRows.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  rows: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  config: PropTypes.instanceOf(Map).isRequired,
  component: PropTypes.instanceOf(Map).isRequired,
  rowsColumns: PropTypes.instanceOf(List),
  rowLinkTo: PropTypes.string
};

export default GenericRows;
