import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import classNames from 'classnames';
import { List, Map } from 'immutable';

import keyCodes from '../../../constants/keyCodes';
import Checkbox from '../../../react/common/Checkbox';
import CollapseButton from '../../../react/common/CollapseButton';
import ComponentIcon from '../../../react/common/ComponentIcon';
import { getRealComponentId } from '../../../react/common/ConfigurationsTable/helpers';
import ConfirmModal from '../../../react/common/ConfirmModal';
import Loader from '../../../react/common/Loader';
import SortByName from '../../../react/common/SortByName';
import TimeAndUser from '../../../react/common/TimeAndUser';
import Tooltip from '../../../react/common/Tooltip';
import Truncated from '../../../react/common/Truncated';
import RoutesStore from '../../../stores/RoutesStore';
import descriptionExcerpt from '../../../utils/descriptionExcerpt';
import hasSelections from '../../../utils/hasSelections';
import onClickSelectionCell from '../../../utils/onClickSelectionCell';
import string from '../../../utils/string';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../../utils/windowOpen';
import { resolveRouterLinkParams } from '../../components/helpers';
import InstalledActionsCreators from '../../components/InstalledComponentsActionCreators';
import ComponentConfigurationLink from '../../components/react/components/ComponentConfigurationLink';
import { toggleConfigurationChange } from '../actions';
import {
  deletedInProduction,
  filterSelectedUpdatedComponents,
  updatedInProduction
} from '../helpers';
import DevBranchDiffModal from './DevBranchDiffModal';

class ConfigurationTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: 'asc',
      detail: Map(),
      showDiffModal: false,
      showResetModal: false,
      isDiffLoading: false,
      isCollapsed: false
    };
  }

  render() {
    if (this.props.components.isEmpty()) {
      return null;
    }

    const numberOfConfigurations = this.props.components.reduce(
      (counter, component) => counter + component.get('configurations').count(),
      0
    );

    return (
      <div className="box">
        <div
          onClick={this.toggleCollapse}
          className="flex-container collapse-above-table btn-collapse-area pb-0"
        >
          <span className="font-medium f-16 line-height-24">Changed Configurations</span>
          <CollapseButton
            entity="configurations"
            isCollapsed={this.state.isCollapsed}
            onToggle={this.toggleCollapse}
          />
        </div>
        {this.state.isCollapsed ? (
          <div className="collapsed-configurations clickable" onClick={this.toggleCollapse}>
            <span className="font-bold">{numberOfConfigurations} </span>
            <span className="text-muted">
              {string.pluralize(numberOfConfigurations, 'Configuration')}
            </span>
          </div>
        ) : (
          this.renderTable(numberOfConfigurations)
        )}
        {this.renderDiffModal()}
        <ConfirmModal
          show={this.state.showResetModal}
          onHide={() => this.setState({ showResetModal: false })}
          title="Reset configuration to production version"
          text="This will erase all your changes made to this configuration in current branch."
          buttonLabel="Reset changes"
          buttonType="danger"
          icon="window-restore"
          onConfirm={() => {
            return InstalledActionsCreators.resetConfigurationToProductionVersion(
              this.state.detail.get('componentId'),
              this.state.detail.get('configId')
            ).then(() => {
              return Promise.all([
                InstalledActionsCreators.loadInstalledComponentsForce(),
                InstalledActionsCreators.loadDeletedComponentsForce()
              ]);
            });
          }}
        />
      </div>
    );
  }

  renderDiffModal() {
    const componentId = this.state.detail.get('componentId');
    const sharedCodes = this.props.sharedCodes
      .find((config) => config.getIn(['configuration', 'componentId']) === componentId, null, Map())
      .get('rows', List());

    return (
      <DevBranchDiffModal
        show={this.state.showDiffModal}
        onClose={() => this.setState({ showDiffModal: false })}
        onDiffLoaded={() => this.setState({ isDiffLoading: false })}
        updatedMetadata={this.props.updatedMetadata}
        detail={this.state.detail}
        admins={this.props.admins}
        component={this.props.allComponents.get(componentId, Map())}
        sharedCodes={sharedCodes}
      />
    );
  }

  renderTable(numberOfConfigurations) {
    const numberOfSelectedConfigurations = filterSelectedUpdatedComponents(
      this.props.components,
      this.props.selectedConfigurationChanges
    ).reduce((counter, component) => counter + component.get('configurations').count(), 0);

    const isSomeSelected = numberOfSelectedConfigurations > 0;
    const isAllSelected = numberOfConfigurations === numberOfSelectedConfigurations;

    return (
      <Table hover>
        <thead>
          <tr>
            {!this.props.readOnly && (
              <th className="w-52 pr-0" onClick={onClickSelectionCell}>
                <Checkbox
                  tooltip="Toggle all changes"
                  checked={isAllSelected}
                  onChange={this.toggleAllConfigurations}
                  indeterminate={isSomeSelected && !isAllSelected}
                />
              </th>
            )}
            <th className={classNames({ 'pl-0': !this.props.readOnly })}>
              <SortByName sortBy={this.state.sort} onClick={(sort) => this.setState({ sort })} />
            </th>
            <th className="w-250">Last Change</th>
            <th className="w-250 text-left">Status</th>
            <th className="w-100" />
          </tr>
        </thead>
        <tbody>
          {this.props.components
            .sort(this.handleSort)
            .map((component) => {
              return component
                .get('configurations')
                .sort(this.handleSort)
                .map((config) => {
                  const detail = Map({
                    name: config.get('name'),
                    componentId: component.get('id'),
                    configId: config.get('id')
                  });
                  const linkParams = resolveRouterLinkParams(
                    component.get('id'),
                    config.get('id'),
                    null,
                    this.props.hasFlows
                  );

                  const onClick = (e) => {
                    if (!linkParams || hasSelections()) {
                      return;
                    }

                    const to = config.get('isDeleted') ? 'settings-trash' : linkParams.to;
                    const params = config.get('isDeleted') ? null : linkParams.params;

                    if (shouldUseNewWindow(e)) {
                      return windowOpen(RoutesStore.getRouter().createHref(to, params));
                    }

                    return RoutesStore.getRouter().transitionTo(to, params);
                  };

                  return (
                    <tr
                      key={config.get('id')}
                      tabIndex="0"
                      role="button"
                      onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                      onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                      onClick={onClick}
                      onKeyDown={(e) => {
                        if (e.key === keyCodes.ENTER) {
                          onClick();
                        }
                      }}
                      className="clickable hoverable-actions"
                    >
                      {!this.props.readOnly && (
                        <td className="w-52 pr-0" onClick={onClickSelectionCell}>
                          <Checkbox
                            tooltip="Add/remove change from merge."
                            checked={this.props.selectedConfigurationChanges
                              .get(component.get('id'), List())
                              .includes(config.get('id'))}
                            onChange={(checked) => {
                              toggleConfigurationChange(
                                component.get('id'),
                                config.get('id'),
                                checked
                              );
                            }}
                          />
                        </td>
                      )}
                      <td className={classNames({ 'pl-0': !this.props.readOnly })}>
                        <div className="flex-container flex-start">
                          <ComponentIcon
                            size="36"
                            className="icon-addon-right align-start"
                            component={component}
                          />
                          <div>
                            <ComponentConfigurationLink
                              className="link-inherit"
                              componentId={getRealComponentId(config, component)}
                              configId={config.get('id')}
                              hasFlows={this.props.hasFlows}
                            >
                              <Truncated className="component-name" text={config.get('name')} />
                            </ComponentConfigurationLink>
                            <span className="f-13 text-muted">
                              {descriptionExcerpt(config.get('description') || 'No description')}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <TimeAndUser
                          avatarPosition="left"
                          admin={this.props.admins.get(
                            config.getIn(['currentVersion', 'creatorToken', 'description'])
                          )}
                          time={config.getIn(['currentVersion', 'created'])}
                          fallbackName={config.getIn([
                            'currentVersion',
                            'creatorToken',
                            'description'
                          ])}
                        />
                      </td>
                      <td className="text-left">
                        {this.renderStatus(component.get('id'), config)}
                      </td>
                      <td className="td pl-0 pr-1 no-wrap">
                        {!this.props.readOnly && (
                          <Tooltip tooltip="Reset changes" placement="top">
                            <Button
                              bsStyle="link"
                              className="text-muted"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ showResetModal: true, detail });
                              }}
                            >
                              <FontAwesomeIcon icon="window-restore" fixedWidth />
                            </Button>
                          </Tooltip>
                        )}
                        <Tooltip tooltip="Compare with production" placement="top">
                          <Button
                            bsStyle="link"
                            className="text-muted"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.setState({ showDiffModal: true, isDiffLoading: true, detail });
                            }}
                          >
                            {this.state.isDiffLoading ? (
                              <Loader />
                            ) : (
                              <FontAwesomeIcon icon="right-left" fixedWidth />
                            )}
                          </Button>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })
                .toArray();
            })
            .toArray()}
        </tbody>
      </Table>
    );
  }

  renderStatus(componentId, config) {
    const productionConfig = this.props.productionComponents.getIn([
      componentId,
      'configurations',
      config.get('id')
    ]);

    if (config.get('isDeleted')) {
      return <span className="text-warning">Deleted configuration</span>;
    }

    if (
      deletedInProduction(componentId, config.get('id'), this.props.productionDeletedComponents)
    ) {
      return <span className="text-danger">Deleted in production</span>;
    }

    if (!productionConfig) {
      return <span className="text-success">New configuration</span>;
    }

    if (updatedInProduction(config, productionConfig)) {
      return <span className="text-danger">Conflicting configuration</span>;
    }

    return <span className="text-muted">No changes in production</span>;
  }

  handleSort = (entityA, entityB) => {
    const sort = this.state.sort === 'asc' ? 1 : -1;

    return entityA.get('name').localeCompare(entityB.get('name')) * sort;
  };

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  toggleAllConfigurations = (checked) => {
    this.props.components.map((component) =>
      component
        .get('configurations')
        .map((configuration) =>
          toggleConfigurationChange(component.get('id'), configuration.get('id'), checked)
        )
    );
  };
}

ConfigurationTable.propTypes = {
  admins: PropTypes.instanceOf(Map).isRequired,
  components: PropTypes.instanceOf(Map).isRequired,
  updatedMetadata: PropTypes.instanceOf(Map).isRequired,
  productionComponents: PropTypes.instanceOf(Map).isRequired,
  productionDeletedComponents: PropTypes.instanceOf(Map).isRequired,
  allComponents: PropTypes.instanceOf(Map).isRequired,
  selectedConfigurationChanges: PropTypes.instanceOf(Map).isRequired,
  sharedCodes: PropTypes.instanceOf(Map).isRequired,
  readOnly: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired
};

export default ConfigurationTable;
