import React from 'react';
import classNames from 'classnames';
import type { Map } from 'immutable';

import CreatedDate from './CreatedDate';
import Gravatar from './Gravatar';
import Truncated from './Truncated';

const TimeAndUser = ({
  admin,
  time,
  timeSuffix,
  fallbackName = '',
  showAvatar = true,
  avatarPosition = 'right'
}: {
  time?: string;
  fallbackName?: string;
  admin?: Map<string, any>;
  timeSuffix?: React.ReactNode;
  showAvatar?: boolean;
  avatarPosition?: 'left' | 'right';
}) => {
  return (
    <div
      className={classNames('flex-container inline-flex flex-start', {
        'flex-reverse': avatarPosition === 'left'
      })}
    >
      <div>
        <div className="flex-container inline-flex flex-start">
          <CreatedDate createdTime={time} />
          {timeSuffix}
        </div>
        <br />
        <Truncated
          className="text-muted"
          text={admin?.get('name') || admin?.get('email') || fallbackName}
          title={admin?.get('email') || fallbackName}
        />
      </div>
      {showAvatar && (
        <span
          className={classNames(
            'line-height-1',
            avatarPosition === 'left' ? 'icon-addon-right' : 'icon-addon-left'
          )}
        >
          <Gravatar user={admin} fallback={fallbackName} />
        </span>
      )}
    </div>
  );
};

export default TimeAndUser;
