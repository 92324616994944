import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

import Checkbox from '../../../react/common/Checkbox';
import CsvDelimiterInput from '../../../react/common/CsvDelimiterInput';
import string from '../../../utils/string';
import { nameWarning } from '../../storage/constants';

class SaveSettings extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Save Settings</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <Col xs={4} componentClass={ControlLabel}>
              Table Name
            </Col>
            <Col xs={8}>
              <FormControl
                type="text"
                value={this.props.name}
                onChange={(e) => {
                  this.props.onChange({ name: string.sanitizeKbcTableIdString(e.target.value) });
                }}
                disabled={this.props.disabled}
                placeholder="mytable"
              />
              <HelpBlock>Name of the table stored in Storage. {nameWarning}</HelpBlock>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col xs={8} xsOffset={4}>
              <Checkbox
                checked={this.props.incremental}
                onChange={(checked) => this.props.onChange({ incremental: checked })}
                disabled={this.props.disabled}
              >
                Incremental Load
              </Checkbox>
              <HelpBlock>
                If incremental load is turned on, the table will be updated instead of rewritten.
                Tables with a primary key will have rows updated, tables without a primary key will
                have rows appended.
              </HelpBlock>
            </Col>
          </FormGroup>
          <CsvDelimiterInput
            type="text"
            labelClassName="col-xs-4"
            wrapperClassName="col-xs-8"
            value={this.props.delimiter}
            disabled={this.props.disabled}
            onChange={(value) => {
              this.props.onChange({ delimiter: value });
            }}
          />
          <FormGroup>
            <Col xs={4} componentClass={ControlLabel}>
              Enclosure
            </Col>
            <Col xs={8}>
              <FormControl
                type="text"
                value={this.props.enclosure}
                disabled={this.props.disabled}
                onChange={(e) => {
                  this.props.onChange({ enclosure: e.target.value });
                }}
                placeholder={'"'}
              />
              <HelpBlock>
                Field enclosure used in the CSV file. The default value is <code>&quot;</code>.
              </HelpBlock>
            </Col>
          </FormGroup>
        </div>
      </div>
    );
  }
}

SaveSettings.propTypes = {
  name: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  incremental: PropTypes.bool.isRequired,
  delimiter: PropTypes.string.isRequired,
  enclosure: PropTypes.any.isRequired
};

export default SaveSettings;
