import { Map, OrderedMap } from 'immutable';
import _ from 'underscore';

import type {
  Config,
  Inputs,
  Instance,
  StepPayload,
  StepValidationResult,
  Template,
  VersionDetailExtended
} from '../../api/routes/templatesService';
import { type OptionValue, SORT } from '../../react/common/SortSelect';
import matchByWords from '../../utils/matchByWords';
import { defaults } from './constants';

export const getFilteredTemplates = (
  templates: Template[],
  filters: { q?: string; sortBy?: OptionValue; categories?: string[] },
  allComponents: Map<string, any>
) => {
  let filteredTemplates = [...templates];

  if (filters.categories?.length) {
    filteredTemplates = filteredTemplates.filter(({ categories }) =>
      categories.some((category) => filters.categories?.includes(category))
    );
  }

  if (filters.q) {
    filteredTemplates = filteredTemplates.filter(({ name, description, components }) => {
      const componentNames = components.map((componentId) =>
        allComponents.getIn([componentId, 'name'], componentId)
      );

      return matchByWords([name, description, ...componentNames], filters.q);
    });
  }

  return filteredTemplates;
};

export const sortTemplates = (templates: Template[], sortBy: OptionValue) => {
  return templates.sort((a, b) => a.name.localeCompare(b.name) * (sortBy === SORT.A_Z ? 1 : -1));
};

export const getFilteredInstances = (
  instances: Instance[],
  filterQuery?: string,
  allTemplates?: Template[]
) => {
  if (filterQuery) {
    return [...instances].filter(({ name, templateId }) => {
      const template = allTemplates?.find(({ id }) => id === templateId);

      return matchByWords(
        template ? [name, templateId, template.name, template.description] : [name],
        filterQuery
      );
    });
  }

  return instances;
};

export const getInstancesForTemplate = (instances: Instance[], templateId: string) => {
  return instances.filter((instance) => instance.templateId === templateId);
};

export const isTemplateVersionLoaded = (
  loaded: VersionDetailExtended,
  template: string,
  version: string
) => {
  if (loaded?.template.id !== template) return false;
  if (loaded.version === version) return true;
  if (version !== defaults.TEMPLATE_VERSION) return false;

  return loaded.version === loaded.template.defaultVersion;
};

export const getInstanceConfigurationsFromComponent = (
  component?: Map<string, any>,
  instanceConfigurations?: Config[]
) => {
  if (!component || !instanceConfigurations) {
    return Map();
  }

  return component
    .get('configurations', OrderedMap())
    .filter((configuration: Map<string, any>) =>
      instanceConfigurations.map(({ configId }) => configId).includes(configuration.get('id'))
    );
};

export const mapStepValuesToInputs = (values?: Record<string, any>) => {
  if (!values) return [];

  return Object.keys(values).map((id) => ({
    id,
    value: values[id]
  }));
};

export const getPreconfiguredValues = (inputs: Inputs) => {
  return inputs.stepGroups
    .flatMap((group) => group.steps)
    .map((step) => {
      const isStepConfigured = inputs.initialState?.stepGroups
        .flatMap((group) => group.steps)
        .find(({ id }) => id === step.id)?.configured;

      if (isStepConfigured) {
        return {
          id: step.id,
          inputs: step.inputs.map((input) => ({ id: input.id, value: input.default }))
        };
      }
    })
    .filter(Boolean) as StepPayload[];
};

// TODO: Add tests for `hasAnyConfiguratorFormChanges`
export const hasAnyConfiguratorFormChanges = (
  inputs?: Inputs,
  userValues?: StepPayload[],
  stepId?: string
) => {
  if (!inputs?.stepGroups.length || !userValues?.length) return false;

  return inputs.stepGroups.some((group) =>
    group.steps.some((step) => {
      if (stepId && stepId !== step.id) return false;

      const currentStep = userValues.find(({ id }) => id === step.id);
      const initialStepValidation = inputs.initialState.stepGroups
        ?.flatMap((group) => group.steps)
        .find(({ id }) => id === step.id);

      if (!currentStep) return initialStepValidation?.configured;
      return (
        step.inputs.some((input) => {
          const currentInput = currentStep.inputs.find(({ id }) => id === input.id);

          if (!currentInput) return false;
          if (typeof currentInput.value === 'object') {
            return !_.isEqual(input.default, currentInput.value);
          }
          return input.default !== currentInput.value;
        }) || !initialStepValidation?.configured
      );
    })
  );
};

export const prepareStepHiddenMap = (inputs?: StepValidationResult['inputs']) => {
  if (!inputs) return null;

  return inputs.reduce((inputs, input) => {
    return { ...inputs, [input.id]: !input.visible };
  }, {});
};
