import React from 'react';
import PropTypes from 'prop-types';

const CliSyncItem = ({ icon, title, content, description }) => {
  return (
    <div className="box in-box flex-container mbp-6 p-2 align-top">
      <div className="f-18 icon-addon-right">{icon}</div>
      <div>
        <h3 className="m-0">{title}</h3>
        <p className="help-block mb-0">{description}</p>
      </div>
      <div className="ml-auto">{content}</div>
    </div>
  );
};

CliSyncItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired
};

export default CliSyncItem;
