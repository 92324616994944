import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import dayjs, { DATE_FORMAT } from '../../../date';
import CollapsibleAlert from '../../../react/common/CollapsibleAlert';
import ComponentDetailLink from '../../../react/common/ComponentDetailLink';
import ComponentName from '../../../react/common/ComponentName';
import Truncated from '../../../react/common/Truncated';
import { getNewComponentTypeLabel, isComponentDeprecated } from '../../components/helpers';

const DeprecatedComponents = createReactClass({
  propTypes: {
    components: PropTypes.object.isRequired
  },

  render() {
    const deprecatedComponents = this.props.components.filter(isComponentDeprecated);

    if (deprecatedComponents.isEmpty()) {
      return null;
    }

    const grouped = deprecatedComponents.groupBy(function (component) {
      return component.get('type');
    });

    return (
      <CollapsibleAlert title="Project contains deprecated components" id="deprecated-components">
        <p>
          Please be advised that the following components within the project have been flagged as
          deprecated, indicating that they may no longer be supported or recommended for use.
        </p>
        <div className="flex-container flex-start align-top">
          {grouped.entrySeq().map(([type, components]) => {
            return (
              <div
                key={type}
                className="flex-container flex-column align-top flex-inline w-300 mlp-4 mr-2"
              >
                <h3 className="f-14 mt-0">{getNewComponentTypeLabel(type)}s</h3>
                <ul className="mlp-2 mb-0">
                  {components.entrySeq().map(([index, component]) => {
                    return (
                      <li key={index} className="color-primary">
                        <ComponentDetailLink
                          componentId={component.get('id')}
                          className="line-height-24 f-14 color-primary"
                        >
                          <ComponentName component={component}>
                            {(componentName) => (
                              <Truncated
                                text={
                                  <>
                                    {componentName.replace(/( \(Deprecated\)| - deprecated)$/, '')}
                                    {component.get('expiredOn') && (
                                      <>
                                        {' '}
                                        (Deprecated in{' '}
                                        {dayjs(component.get('expiredOn')).format(DATE_FORMAT)})
                                      </>
                                    )}
                                  </>
                                }
                              />
                            )}
                          </ComponentName>
                        </ComponentDetailLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </CollapsibleAlert>
    );
  }
});

export default DeprecatedComponents;
