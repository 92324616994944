import '../../utils/codemirror/setup';

import React from 'react';
import PropTypes from 'prop-types';
import { HelpBlock } from 'react-bootstrap';
import { Controlled as CodeMirror } from 'react-codemirror2';
import ReactDOM from 'react-dom';

import { USER_DOCUMENTATION_URL } from '../../constants/KbcConstants';
import nextTick from '../../utils/nextTick';
import KeyCode from '../common/KeyCode';
import ExternalLink from './ExternalLink';

class CodeEditor extends React.Component {
  constructor(props) {
    super(props);

    this.editorDidMount = this.editorDidMount.bind(this);
    this.onBeforeChange = this.onBeforeChange.bind(this);
  }

  render() {
    return (
      <>
        <CodeMirror
          value={this.props.value}
          options={{
            ...(this.props.withSearch
              ? { enableSearch: true }
              : {
                  extraKeys: {
                    'Ctrl-F': false,
                    'Cmd-F': false,
                    'Ctrl-G': false,
                    'Cmd-G': false,
                    'Shift-Ctrl-G': false,
                    'Shift-Cmd-G': false,
                    ...this.props.options?.extraKeys
                  }
                }),
            ...this.props.options
          }}
          editorDidMount={this.editorDidMount}
          onBeforeChange={this.onBeforeChange}
        />
        {(this.props.withAutocomplete || this.props.help) && !this.props.options?.readOnly && (
          <HelpBlock>
            {this.props.help}
            {this.props.withAutocomplete && (
              <>
                {!!this.props.help ? ' ' : ''}
                Use <KeyCode>Ctrl+Space</KeyCode> or <KeyCode>Option+Space</KeyCode> to trigger{' '}
                <ExternalLink href={`${USER_DOCUMENTATION_URL}/transformations/#autocompletion`}>
                  autocomplete
                </ExternalLink>
                .
              </>
            )}
          </HelpBlock>
        )}
      </>
    );
  }

  onBeforeChange(editor, data, value) {
    if (this.props.onChange) {
      ReactDOM.flushSync(() => this.props.onChange(value));
    }
  }

  editorDidMount(editor) {
    if (this.props.value) {
      nextTick(() => editor.refresh());
    }

    if (this.props.autoFocus) {
      editor.focus();
    }

    if (this.props.editorDidMount) {
      this.props.editorDidMount(editor);
    }

    if (this.props.withSearch) {
      editor.execCommand('find');
    }
  }
}

CodeEditor.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.object,
  onChange: PropTypes.func,
  editorDidMount: PropTypes.func,
  help: PropTypes.node,
  autoFocus: PropTypes.bool,
  withAutocomplete: PropTypes.bool,
  withSearch: PropTypes.bool
};

export default CodeEditor;
