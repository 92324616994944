import EventEmitter from 'eventemitter3';
// import monitorStore from './monitorStore';

const CHANGE_EVENT = 'change';

type GenericStoreState = Record<string, any>;
type GenericStoreGetters = Record<string, (...args: any) => any>;
type CommonStoreGetters = ReturnType<typeof StoreUtils.createStore>;

const StoreUtils = {
  createStore<StoreGetters extends GenericStoreGetters>(spec: StoreGetters) {
    const eventEmitter = new EventEmitter();

    return {
      ...spec,
      emitChange() {
        return eventEmitter.emit(CHANGE_EVENT);
      },

      addChangeListener(callback: () => void) {
        return eventEmitter.on(CHANGE_EVENT, callback);
      },

      removeChangeListener(callback: () => void) {
        return eventEmitter.removeListener(CHANGE_EVENT, callback);
      }
    };
  }
};

const initStore = <Store extends GenericStoreState>(name: string, store: Store) => {
  // return monitorStore(name, store);
  return store;
};

export { initStore, type CommonStoreGetters };

export default StoreUtils;
