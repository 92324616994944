import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS } from 'immutable';

import { KEBOOLA_SLICED_FILES_DOWNLOADER } from '../../../constants/componentIds';
import Tooltip from '../../../react/common/Tooltip';
import ApplicationStore from '../../../stores/ApplicationStore';
import componentRunner from '../../components/ComponentRunner';
import storageApi from '../../components/StorageApi';
import jobsApi from '../../jobs/JobsApi';
import QueueApi from '../../queue/api';
import Modal from './Modal';

const ModalHandler = createReactClass({
  propTypes: {
    file: PropTypes.object.isRequired,
    children: PropTypes.element,
    linkClass: PropTypes.string,
    mode: PropTypes.string,
    onKeyDown: PropTypes.func
  },

  getInitialState() {
    return {
      isModalOpen: false,
      isRunning: false,
      createdFile: null,
      jobId: null,
      progress: null,
      progressStatus: null
    };
  },

  componentWillUnmount() {
    this.cancellablePromiseRunComponent && this.cancellablePromiseRunComponent.cancel();
    this.cancellablePromiseJobDetail && this.cancellablePromiseJobDetail.cancel();
  },

  render() {
    return (
      <>
        {this.renderButton()}
        <Modal
          file={this.props.file}
          createdFile={this.state.createdFile}
          isModalOpen={this.state.isModalOpen}
          onModalHide={this.closeModal}
          isRunning={this.state.isRunning}
          onPrepareStart={this.startJob}
          progress={this.state.progress}
          progressStatus={this.state.progressStatus}
        />
      </>
    );
  },

  renderButton() {
    if (this.props.mode === 'button') {
      return (
        <Tooltip tooltip="Download file" placement="top">
          <Button bsStyle="link" className="text-muted" onClick={this.openModal}>
            <FontAwesomeIcon icon="circle-down" fixedWidth />
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button
        bsStyle="link"
        onClick={this.openModal}
        className={classnames('btn-link-inline no-underline', this.props.linkClass)}
      >
        {this.props.children}
      </Button>
    );
  },

  startJob() {
    this.setState({
      isRunning: true,
      jobId: null,
      progress: 'Waiting for start...',
      progressStatus: null
    });

    const hasNewQueue = ApplicationStore.hasNewQueue();
    this.cancellablePromiseRunComponent = componentRunner[hasNewQueue ? 'run' : 'runProduction']({
      method: 'run',
      component: KEBOOLA_SLICED_FILES_DOWNLOADER,
      data: {
        configData: {
          storage: {
            input: {
              files: [
                hasNewQueue
                  ? { file_ids: [this.props.file.get('id')] }
                  : { query: `id:${this.props.file.get('id')}` }
              ]
            }
          }
        }
      }
    })
      .then(this.handleJobReceive)
      .catch((e) => {
        this.setState({ isRunning: false });
        throw e;
      });
  },

  handleJobReceive(job) {
    if (job.isFinished) {
      if (job.status === 'success') {
        setTimeout(
          () => this.handleJobFinished(job),
          2000 // wait for file to be available in search
        );
      } else {
        this.setState({
          isRunning: false,
          jobId: job.id,
          progress: 'Package create finished with an error.',
          progressStatus: 'danger'
        });
      }
    } else {
      this.setState({
        jobId: job.id,
        progress: job.state === 'waiting' ? 'Waiting for start...' : 'Creating package...'
      });
      setTimeout(this.checkJobStatus, 5000);
    }
  },

  handleJobFinished(job) {
    storageApi
      .getFiles({ runId: job.runId })
      .then(this.handleFilesReceive)
      .catch(() => {
        this.setState({
          isRunning: false,
          progress: 'File fetch finished with an error.',
          progressStatus: 'danger'
        });
      });
  },

  handleFilesReceive(files) {
    this.setState({
      isRunning: false,
      createdFile: fromJS(files[0]),
      progress: 'Package was successfully created.',
      progressStatus: 'success'
    });
  },

  checkJobStatus() {
    if (!this.state.jobId) {
      return;
    }

    const jobPromise = ApplicationStore.hasNewQueue()
      ? QueueApi.getJobDetail(this.state.jobId)
      : jobsApi.getJobDetail(this.state.jobId);

    this.cancellablePromiseJobDetail = jobPromise.then(this.handleJobReceive).catch((error) => {
      this.setState({ isRunning: false });
      throw error;
    });
  },

  openModal() {
    this.setState({ isModalOpen: true });
  },

  closeModal() {
    this.setState({ isModalOpen: false });
  }
});

export default ModalHandler;
