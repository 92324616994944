import React from 'react';
import createReactClass from 'create-react-class';

import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import RoutesStore from '../../../../../stores/RoutesStore';
import TransformationsStore from '../../../stores/TransformationsStore';
import GraphContainer from './GraphContainer';

const TransformationGraph = createReactClass({
  mixins: [createStoreMixin(TransformationsStore)],

  getStateFromStores() {
    const bucketId = RoutesStore.getCurrentRouteParam('config');
    const transformationId = RoutesStore.getCurrentRouteParam('row');

    return {
      bucketId,
      transformationId,
      transformation: TransformationsStore.getTransformation(bucketId, transformationId)
    };
  },

  render() {
    return (
      <div className="box">
        <div className="box-content">
          <GraphContainer
            bucketId={this.state.bucketId}
            transformationId={this.state.transformationId}
            disabled={this.state.transformation.get('disabled', false)}
          />
        </div>
      </div>
    );
  }
});

export default TransformationGraph;
