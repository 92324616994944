import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import { FEATURE_TRANSFORMATIONS_MIXED_BACKENDS } from '../../../../../constants/features';
import RouterLink from '../../../../../react/common/RouterLink';
import Select from '../../../../../react/common/Select';
import Tooltip from '../../../../../react/common/Tooltip';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import { backends, transformationType } from '../../../Constants';

const allowedMixedBackends = [backends.DOCKER, backends.SNOWFLAKE];
const allowedMixedBackendTypes = [
  'simple',
  transformationType.PYTHON,
  transformationType.JULIA,
  transformationType.R
];

const Requires = createReactClass({
  propTypes: {
    transformation: PropTypes.object.isRequired,
    transformations: PropTypes.object.isRequired,
    requires: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onEditChange: PropTypes.func.isRequired,
    bucketId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  },

  getDefaultProps() {
    return {
      disabled: false
    };
  },

  render() {
    return (
      <>
        <h2 className="first-line">
          Requires
          <Tooltip
            placement="top"
            type="explanatory"
            tooltip="Transformations processed before this transformation"
          >
            <FontAwesomeIcon icon="circle-question" className="icon-addon-left" />
          </Tooltip>
        </h2>
        <Select
          multi
          value={this.props.requires.toArray()}
          options={this.getSelectOptions(this.props.transformations, this.props.transformation)}
          disabled={this.props.isSaving || this.props.disabled}
          onChange={this.handleValueChange}
          placeholder="Add required transformation..."
          noResultsText="No transformations found"
          multiValueRenderer={({ data }) => {
            return (
              <RouterLink
                to="transformationDetail"
                params={{ config: this.props.bucketId, row: data.value }}
                className="bigger-label clickable"
              >
                {data.label}
              </RouterLink>
            );
          }}
        />
      </>
    );
  },

  allowInterbackendDependencies(current, compared) {
    if (!ApplicationStore.hasCurrentProjectFeature(FEATURE_TRANSFORMATIONS_MIXED_BACKENDS)) {
      return false;
    }
    if (!allowedMixedBackends.includes(current.get('backend'))) {
      return false;
    }
    if (!allowedMixedBackends.includes(compared.get('backend'))) {
      return false;
    }
    if (!allowedMixedBackendTypes.includes(current.get('type'))) {
      return false;
    }
    if (!allowedMixedBackendTypes.includes(compared.get('type'))) {
      return false;
    }
    return true;
  },

  getSelectOptions: function (transformations, currentTransformation) {
    const component = this;
    let options = _.sortBy(
      _.map(
        _.filter(transformations.toArray(), function (transformation) {
          return (
            (parseInt(transformation.get('phase'), 10) ===
              parseInt(currentTransformation.get('phase'), 10) &&
              (transformation.get('backend') === currentTransformation.get('backend') ||
                component.allowInterbackendDependencies(currentTransformation, transformation)) &&
              transformation.get('id') !== currentTransformation.get('id')) ||
            currentTransformation.get('requires').contains(transformation.get('id'))
          );
        }),
        function (transformation) {
          if (
            parseInt(transformation.get('phase'), 10) !==
            parseInt(currentTransformation.get('phase'), 10)
          ) {
            return {
              label: transformation.get('name', transformation.get('id')) + ' (phase mismatch)',
              value: transformation.get('id')
            };
          }
          return {
            label: transformation.get('name', transformation.get('id')),
            value: transformation.get('id')
          };
        }
      ),
      function (option) {
        return option.label.toLowerCase();
      }
    );

    // identify deleted required transformations
    const missing = _.filter(
      currentTransformation.get('requires').toArray(),
      function (possiblyMissingTransformationId) {
        if (
          _.find(options, function (option) {
            return option.value === possiblyMissingTransformationId;
          })
        ) {
          return false;
        }
        return true;
      }
    );
    // add them to options
    return options.concat(
      missing.map(function (missingItem) {
        return {
          label: missingItem + ' (deleted)',
          value: missingItem
        };
      })
    );
  },

  handleValueChange(value) {
    this.props.onEditChange(value);
  }
});

export default Requires;
