import React from 'react';
import { Label } from 'react-bootstrap';
import classnames from 'classnames';

type Props = {
  className?: string;
};

const ConfigurationFromTemplateLabel = (props: Props) => {
  return (
    <Label className={classnames('label-info label-rounded uppercase', props.className)}>
      Template
    </Label>
  );
};

export default ConfigurationFromTemplateLabel;
