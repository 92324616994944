import { fromJS, List, Map } from 'immutable';

import type { VariableWithHash } from '../../api/routes/vaultService';
import Dispatcher from '../../Dispatcher';
import StoreUtils, { initStore } from '../../utils/StoreUtils';
import { ActionTypes } from './constants';

export type Store = {
  loading: boolean;
  variables: VariableWithHash[];
};

let _store = initStore('VariablesStore', Map({ loading: false, variables: List() }));

const VariablesStore = StoreUtils.createStore({ getStore: () => _store.toJS() as Store });

Dispatcher.register(
  ({
    action
  }: {
    action: {
      type: string;
      variables?: VariableWithHash[];
    };
  }) => {
    switch (action.type) {
      case ActionTypes.LOAD_VARIABLES:
        _store = _store.set('loading', true);
        return VariablesStore.emitChange();

      case ActionTypes.LOAD_VARIABLES_SUCCESS:
        _store = _store.set('variables', fromJS(action.variables)).set('loading', false);
        return VariablesStore.emitChange();

      case ActionTypes.LOAD_VARIABLES_ERROR:
        _store = _store.set('loading', false);
        return VariablesStore.emitChange();

      default:
        break;
    }
  }
);

export default VariablesStore;
