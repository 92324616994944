import React from 'react';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import ComponentWithIconAndType from '../../../react/common/ComponentWithIconAndType';
import CreatedDate from '../../../react/common/CreatedDate';
import UrlsToLinks from '../../../react/common/UrlsToLinks';
import { getComponent, shoudUseMonospaceFont } from '../helpers';

const classmap = {
  error: 'bg-danger',
  warn: 'bg-warning',
  success: 'bg-success'
};

const EventsTableRow = createReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    event: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  },

  render() {
    const status = classmap[this.props.event.get('type')];
    const component = getComponent(this.props.event);

    return (
      <div className="tr clickable" onClick={() => this.props.onClick(this.props.event)}>
        <div className={classnames('td', 'text-nowrap', status)}>
          {component.get('name') && <ComponentWithIconAndType component={component} />}
        </div>
        <div
          className={classnames('td overflow-break-anywhere', status, {
            'font-mono': shoudUseMonospaceFont(this.props.event.get('message'))
          })}
        >
          <UrlsToLinks text={this.props.event.get('message')} />
        </div>
        <div className={classnames('td text-nowrap text-right text-muted', status)}>
          <CreatedDate absolute createdTime={this.props.event.get('created')} />
        </div>
      </div>
    );
  }
});

export default EventsTableRow;
