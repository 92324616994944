import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS } from 'immutable';
import _ from 'underscore';
import { capitalize } from 'underscore.string';

import { KEBOOLA_WR_LOOKER_V2, KEBOOLA_WR_SISENSE } from '../../../../constants/componentIds';
import CatchUnsavedChanges from '../../../../react/common/CatchUnsavedChanges';
import Checkbox from '../../../../react/common/Checkbox';
import CircleIcon from '../../../../react/common/CircleIcon';
import ConfirmButtons from '../../../../react/common/ConfirmButtons';
import Select from '../../../../react/common/Select';
import { dataPreview } from '../../../storage/actions';
import { DATA_TYPES_FROM_STORAGE } from '../../constants';
import { getDataTypeOptions, getDataTypes, getSizeParam } from '../pages/table/tableHelpers';
import ColumnRow from './ColumnRow';

const ALL_COLUMNS_OPERATIONS = {
  allColumnsConvert: '',
  allColumnsDataType: '',
  allColumnsDataTypeSize: '',
  allColumnsDataTypeOptions: []
};

const ColumnsEditor = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    tableId: PropTypes.string.isRequired,
    tableExists: PropTypes.bool.isRequired,
    columns: PropTypes.object.isRequired,
    primaryKey: PropTypes.object.isRequired,
    defaultColumnsTypes: PropTypes.object.isRequired,
    disabledColumnFields: PropTypes.array.isRequired,
    editingColumns: PropTypes.object.isRequired,
    editColumnFn: PropTypes.func.isRequired,
    dataTypes: PropTypes.array.isRequired,
    isSaving: PropTypes.bool.isRequired,
    columnsValidation: PropTypes.object.isRequired,
    hasChanges: PropTypes.bool.isRequired,
    onColumnsCancel: PropTypes.func.isRequired,
    onColumnsSave: PropTypes.func.isRequired,
    otherTables: PropTypes.object,
    tablePrimaryKey: PropTypes.object
  },

  getInitialState() {
    return {
      loadingPreview: false,
      dataPreview: null,
      hideIgnored: false,
      ...ALL_COLUMNS_OPERATIONS
    };
  },

  render() {
    return (
      <>
        <CircleIcon className="box-icon" icon="line-columns" color="blue" bold />
        <div
          className={classNames('box', 'pt-1', {
            'mb-2': this.props.componentId === KEBOOLA_WR_SISENSE
          })}
        >
          <div className="box-content pt-0 pb-0 flex-container">
            <h2>Columns</h2>
            {!this.props.readOnly && this.props.columns.count() > 0 && this.renderEditButtons()}
          </div>
          <Table className="compact">
            <thead>
              <tr>
                <th>Column</th>
                <th className="no-wrap">
                  Column Name
                  {this.renderSetColumnsName()}
                </th>
                <th className="no-wrap">
                  Data Type
                  {this.renderSetColumnsType()}
                </th>
                {!this.props.disabledColumnFields.includes('nullable') && (
                  <th className="pl-0 pr-0 no-wrap text-center">
                    Nullable
                    {this.renderSetAllNullable()}
                  </th>
                )}
                {!this.props.disabledColumnFields.includes('default') && (
                  <th className="no-wrap">Default Value</th>
                )}
                <th />
              </tr>
            </thead>
            <tbody>{this.renderRows()}</tbody>
          </Table>
        </div>
      </>
    );
  },

  renderRows() {
    let columns = this.props.columns;

    if (this.state.hideIgnored) {
      columns = columns.filter((column) => {
        return this.props.editingColumns.getIn([column.get('name'), 'type']) !== 'IGNORE';
      });
    }

    if (!columns.count()) {
      return (
        <tr>
          <td colSpan="6">
            <div className="text-center">No Columns.</div>
          </td>
        </tr>
      );
    }

    return columns.map((column, index) => {
      const cname = column.get('name');
      const editingColumn = this.props.editingColumns.get(cname);
      const isValid = this.props.columnsValidation.get(cname, true);

      return (
        <ColumnRow
          key={index}
          isValid={isValid}
          column={column}
          editingColumn={editingColumn}
          primaryKey={this.props.primaryKey}
          defaultColumnsTypes={this.props.defaultColumnsTypes}
          readOnly={this.props.readOnly}
          isSaving={this.props.isSaving}
          dataTypes={this.props.dataTypes}
          editColumnFn={this.props.editColumnFn}
          dataPreview={this.state.dataPreview}
          disabledFields={this.props.disabledColumnFields}
          tablePrimaryKey={this.props.tablePrimaryKey}
          componentId={this.props.componentId}
          otherTables={this.props.otherTables}
          loadingPreview={this.state.loadingPreview}
          loadPreviewFn={this.handleLoadDataPreview}
        />
      );
    });
  },

  renderEditButtons() {
    const isValid = this.props.columnsValidation.reduce((memo, value) => memo && value, true);
    const hasColumns = this.props.editingColumns.filter(
      (column) => column.get('type') !== 'IGNORE'
    );

    return (
      <CatchUnsavedChanges
        isDirty={this.props.hasChanges}
        onSave={this.props.onColumnsSave}
        isSaveDisabled={!isValid}
        onDirtyLeave={this.handleCancel}
      >
        <ConfirmButtons
          saveStyle="primary"
          cancelLabel="Reset"
          showCancel={this.props.hasChanges}
          isSaving={this.props.isSaving}
          isDisabled={!hasColumns.count() || !isValid || !this.props.hasChanges}
          onCancel={this.handleCancel}
          onSave={this.props.onColumnsSave}
        />
      </CatchUnsavedChanges>
    );
  },

  renderSetAllNullable() {
    if (this.props.readOnly) {
      return null;
    }

    const allColumnsIgnored = this.props.editingColumns.reduce(
      (memo, column) => memo && column.get('type') === 'IGNORE',
      true
    );
    const isAllNullable = this.props.editingColumns.every((column) => column.get('null') === '1');
    const isSomeNullable = this.props.editingColumns.some((column) => column.get('null') === '1');

    return (
      <Dropdown id="column-editor-column" dropup pullRight>
        <Dropdown.Toggle noCaret bsStyle="link">
          <FontAwesomeIcon icon={['far', 'ellipsis']} className="text-muted" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-250 p-1">
          <Checkbox
            disabled={allColumnsIgnored}
            indeterminate={!isAllNullable && isSomeNullable}
            checked={isAllNullable}
            onChange={(checked) => {
              return this.props.editingColumns.map((column) => {
                this.props.editColumnFn(column.set('null', !checked ? '0' : '1'));
              });
            }}
          >
            Set nullable to all columns
          </Checkbox>
        </Dropdown.Menu>
      </Dropdown>
    );
  },

  renderSetColumnsName() {
    if (this.props.readOnly || this.props.componentId === KEBOOLA_WR_LOOKER_V2) {
      return null;
    }

    return (
      <Dropdown id="column-editor-column" dropup pullRight>
        <Dropdown.Toggle noCaret bsStyle="link">
          <FontAwesomeIcon icon={['far', 'ellipsis']} className="text-muted" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-250 p-1">
          <Select
            clearable={false}
            className="text-muted"
            placeholder="Convert All Names To"
            value={this.state.allColumnsConvert}
            options={[
              { value: 'uppercase', label: 'UPPERCASE' },
              { value: 'lowercase', label: 'lowercase' },
              { value: 'capitalize', label: 'Capitalize' }
            ]}
            onChange={(value) => {
              this.setState({ allColumnsConvert: value });
              return this.props.editingColumns.map((column) => {
                switch (value) {
                  case 'uppercase':
                    return this.props.editColumnFn(
                      column.update('dbName', (name) => name.toUpperCase())
                    );
                  case 'lowercase':
                    return this.props.editColumnFn(
                      column.update('dbName', (name) => name.toLowerCase())
                    );
                  case 'capitalize':
                    return this.props.editColumnFn(
                      column.update('dbName', (name) => capitalize(name, true))
                    );
                  default:
                    break;
                }
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  },

  renderSetColumnsType() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <Dropdown id="column-editor-datatype" dropup pullRight>
        <Dropdown.Toggle noCaret bsStyle="link">
          <FontAwesomeIcon icon={['far', 'ellipsis']} className="text-muted" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-250 p-1">
          <div className="form-horizontal">
            <Checkbox
              className="pb-1"
              checked={this.state.hideIgnored}
              onChange={() => this.setState({ hideIgnored: !this.state.hideIgnored })}
            >
              Hide Ignored
            </Checkbox>
          </div>
          <Select
            clearable={false}
            className="text-muted"
            placeholder="Set all visible columns to"
            value={this.state.allColumnsDataType}
            options={getDataTypes(this.props.componentId)
              .concat([DATA_TYPES_FROM_STORAGE, 'IGNORE'])
              .map((type) => ({ value: type, label: type }))}
            onChange={(value) => {
              const defaultSize = getSizeParam(this.props.componentId, value);

              this.setState({
                allColumnsDataType: value,
                allColumnsDataTypeSize: defaultSize,
                allColumnsDataTypeOptions: getDataTypeOptions(this.props.componentId, value)
              });

              this.props.editingColumns
                .filter((column) => !this.state.hideIgnored || column.get('type') !== 'IGNORE')
                .forEach((column) => {
                  if (value === DATA_TYPES_FROM_STORAGE) {
                    return this.props.editColumnFn(
                      this.props.defaultColumnsTypes.get(
                        column.get('name'),
                        column.merge({ type: 'IGNORE', default: '', size: '' })
                      )
                    );
                  }

                  this.props.editColumnFn(
                    column.merge({ type: value, size: _.isString(defaultSize) ? defaultSize : '' })
                  );
                });

              if (value === DATA_TYPES_FROM_STORAGE) {
                this.setState(ALL_COLUMNS_OPERATIONS);
              }
            }}
          />
          {this.state.allColumnsDataTypeOptions.length > 0 && (
            <Select
              clearable={false}
              className="text-muted"
              value={this.state.allColumnsDataTypeSize}
              options={this.state.allColumnsDataTypeOptions}
              onChange={(value) => {
                this.setState({ allColumnsDataTypeSize: value });
                this.props.editingColumns.map((column) => {
                  this.props.editColumnFn(column.set('size', value));
                });
              }}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  },

  handleCancel() {
    this.setState({ ...ALL_COLUMNS_OPERATIONS });
    return this.props.onColumnsCancel();
  },

  handleLoadDataPreview() {
    if (!this.props.tableExists || this.state.dataPreview || this.state.loadingPreview) {
      return null;
    }

    this.setState({ loadingPreview: true });
    dataPreview(this.props.tableId, { limit: 100 })
      .then((data) => this.setState({ dataPreview: fromJS(data), loadingPreview: false }))
      .catch((error) => {
        this.setState({ loadingPreview: false });
        throw error;
      });
  }
});

export default ColumnsEditor;
