import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import { isCreatedInDevBranch } from '../../modules/dev-branches/helpers';

const DevBranchLabel = ({ bucket }) => {
  if (!bucket || !isCreatedInDevBranch(bucket)) {
    return null;
  }

  return (
    <Label className="dev-branch-label icon-addon-right">
      <FontAwesomeIcon icon={['far', 'code-branch']} />
    </Label>
  );
};

DevBranchLabel.propTypes = {
  bucket: PropTypes.instanceOf(Map)
};

export default DevBranchLabel;
