import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { capitalize } from 'underscore.string';

import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '../../constants/componentIds';
import { componentTypes } from '../../constants/componentTypes';
import defaultCopyVersionName from '../../utils/defaultCopyVersionName';
import CopyVersionModal from './CopyVersionModal';
import Loader from './Loader';
import RowActionMenuItem from './RowActionMenuItem';
import Tooltip from './Tooltip';

const CopyVersionButton = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    configId: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired,
    version: PropTypes.object.isRequired,
    onCopy: PropTypes.func.isRequired,
    isPending: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLast: PropTypes.bool,
    isRow: PropTypes.bool,
    hasFlows: PropTypes.bool,
    onKeyDown: PropTypes.func,
    mode: PropTypes.string
  },

  getInitialState() {
    return {
      isSaving: false,
      showModal: false,
      newVersionName: ''
    };
  },

  render() {
    const entity = this.resolveEntity();

    return (
      <>
        {this.renderButton(entity)}
        <CopyVersionModal
          entity={entity}
          isRow={this.props.isRow}
          configId={this.props.configId}
          componentId={this.props.componentId}
          componentType={this.props.componentType}
          version={this.props.version}
          show={this.state.showModal}
          onClose={this.closeModal}
          onCopy={this.onCopy}
          onChangeName={(name) => this.setState({ newVersionName: name })}
          newVersionName={this.state.newVersionName}
          isLast={this.props.isLast}
          isSaving={this.state.isSaving}
        />
      </>
    );
  },

  renderButton(entity) {
    const isDisabled = this.props.isDisabled || this.props.isPending;

    if (this.props.mode === 'menuitem') {
      return (
        <RowActionMenuItem
          onSelect={this.openModal}
          disabled={isDisabled}
          onKeyDown={this.props.onKeyDown}
        >
          {this.props.isPending ? <Loader /> : <FontAwesomeIcon fixedWidth icon="clone" />}
          Copy {capitalize(entity)}
        </RowActionMenuItem>
      );
    }

    if (this.props.mode === 'sidebar') {
      return (
        <Button
          block
          bsStyle="link"
          className="btn-link-inline"
          onClick={this.openModal}
          disabled={isDisabled}
        >
          {this.props.isPending ? <Loader /> : <FontAwesomeIcon icon="clone" fixedWidth />}
          Copy {capitalize(entity)}
        </Button>
      );
    }

    return (
      <Tooltip tooltip={`Copy to new ${entity}`} placement="top">
        <Button
          bsStyle="link"
          className="text-muted"
          onClick={this.openModal}
          disabled={isDisabled}
        >
          {this.props.isPending ? <Loader /> : <FontAwesomeIcon icon="clone" fixedWidth />}
        </Button>
      </Tooltip>
    );
  },

  resolveEntity() {
    if (this.props.componentId === KEBOOLA_ORCHESTRATOR) {
      return this.props.hasFlows ? 'flow' : 'orchestration';
    }

    if (this.props.componentId === KEBOOLA_DATA_APPS) {
      return 'data app';
    }

    if (this.props.componentType === componentTypes.TRANSFORMATION) {
      return 'transformation';
    }

    if (this.props.isRow) {
      return 'row';
    }

    return 'configuration';
  },

  closeModal() {
    this.setState({ showModal: false, newVersionName: '' });
  },

  openModal() {
    this.setState({ showModal: true, newVersionName: defaultCopyVersionName(this.props.version) });
  },

  onCopy(excludeRows) {
    this.setState({ isSaving: true });
    this.props
      .onCopy(this.state.newVersionName.trim(), excludeRows)
      .then(this.closeModal)
      .finally(() => this.setState({ isSaving: false }));
  }
});

export default CopyVersionButton;
