import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import { backendImages } from '../../../constants/backendImages';
import { backends } from '../../storage/constants';
import { prepareBackendLabel } from '../../storage/helpers';

const getBackendImage = (backend) => {
  switch (backend) {
    case backends.SNOWFLAKE:
      return backendImages.snowflake;

    case backends.REDSHIFT:
      return backendImages.redshift;

    case backends.SYNAPSE:
      return backendImages.synapse;

    case backends.EXASOL:
      return backendImages.exasol;

    case backends.TERADATA:
      return backendImages.teradata;

    case backends.BIGQUERY:
      return backendImages.bigquery;
  }

  return null;
};

const BackendRow = ({ backend }) => {
  const backendImage = getBackendImage(backend);

  return (
    <p className="first-line font-medium flex-container">
      <span>Backend</span>
      <span className="flex-container flex-end">
        {backendImage && <Image width={24} className="icon-addon-right" src={backendImage} />}
        <span className="font-normal text-muted">{prepareBackendLabel(backend)}</span>
      </span>
    </p>
  );
};

BackendRow.propTypes = {
  backend: PropTypes.oneOf(Object.values(backends)).isRequired
};

const MemoizedBackedRow = React.memo(BackendRow);

export default MemoizedBackedRow;
