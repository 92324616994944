import React from 'react';

import CollapsibleAlert from './CollapsibleAlert';
import { EXPERIMENTAL_TEXT } from './ComponentBadges';

const ExperimentalComponentInfoAlert = () => {
  return (
    <CollapsibleAlert id="experimental-component" title="This component is experimental">
      {EXPERIMENTAL_TEXT}
    </CollapsibleAlert>
  );
};

export default ExperimentalComponentInfoAlert;
