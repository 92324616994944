import React, { useState } from 'react';
import type { List, Map } from 'immutable';

import SplitDiff, { SplitDiffTitle } from './SplitDiff/SplitDiff';
import Checkbox from './Checkbox';
import FullScreenEditor from './FullScreenEditor';
import {
  haveConfigurationQueriesChanged,
  haveConfigurationsOnlyQueriesChanged,
  prepareQueriesDiffData
} from './VersionsDiffModalHelpers';

type Props = {
  show: boolean;
  component: Map<string, any>;
  admins: Map<string, any>;
  referentialVersion: Map<string, any>;
  compareVersion: Map<string, any>;
  sharedCodes: List<any>;
  config?: Map<string, any>;
  row?: Map<string, any>;
  isBranchDiff?: boolean;
  hideRollback?: boolean;
  noChangesPlaceholder?: React.ReactNode;
  helpText?: React.ReactNode;
  title?: React.ReactNode;
  onClose: () => void;
};

const VersionsDiffModal = (props: Props) => {
  const [detailedDiff, setDetailedDiff] = useState(false);
  const [hasQueriesChanges, setQueriesChanges] = useState(false);

  if (!props.show) {
    return null;
  }

  const renderEditor = () => {
    return (
      <SplitDiff
        componentId={props.component.get('id')}
        config={props.config}
        row={props.row}
        branchDiff={props.isBranchDiff || false}
        current={props.referentialVersion}
        previous={props.compareVersion}
        noChangesPlaceholder={props.noChangesPlaceholder}
        hideRollback={props.hideRollback}
        admins={props.admins}
        {...(detailedDiff && {
          prepareData: (version) => {
            return prepareQueriesDiffData(props.component.get('id'), version, props.sharedCodes);
          }
        })}
      />
    );
  };

  const renderTitle = () => {
    return (
      <SplitDiffTitle
        component={props.component}
        name={props.title || props.referentialVersion.get('name')}
        helpText={props.helpText}
      />
    );
  };

  const renderButtons = () => {
    if (!hasQueriesChanges) {
      return null;
    }

    return (
      <>
        <Checkbox checked={detailedDiff} onChange={setDetailedDiff}>
          Detailed diff for queries or scripts
        </Checkbox>
        <span className="btn-separator" />
      </>
    );
  };

  return (
    <FullScreenEditor
      enforceFocus
      onClose={props.onClose}
      renderEditor={renderEditor}
      renderTitle={renderTitle}
      renderButtons={renderButtons}
      onEnter={() => {
        const hasQueriesChanges = haveConfigurationQueriesChanged(
          props.component.get('id'),
          props.compareVersion,
          props.referentialVersion
        );

        const hasOnlyQueriesChanges =
          hasQueriesChanges &&
          haveConfigurationsOnlyQueriesChanged(
            props.component.get('id'),
            props.compareVersion,
            props.referentialVersion
          );

        setQueriesChanges(hasQueriesChanges);
        setDetailedDiff(hasOnlyQueriesChanges);
      }}
      className="with-scroll"
    />
  );
};

export default VersionsDiffModal;
