import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { type Map } from 'immutable';

import NativeTypesLabel from '../../modules/storage/components/NativeTypesLabel';
import { tableName } from '../../modules/storage/helpers';
import BucketStageLabel from './BucketStageLabel';
import DevBranchLabel from './DevBranchLabel';
import MarkedText from './MarkedText';

const bucketLabel = (bucket: Map<string, any>, options?: { showStage: boolean }) => {
  return (inputString?: string) => {
    return (
      <div className="flex-container flex-start">
        {options?.showStage !== false && <BucketStageLabel stage={bucket.get('stage')} />}
        <DevBranchLabel bucket={bucket} />
        <MarkedText source={bucket.get('displayName')} mark={inputString} />
      </div>
    );
  };
};

const tableLabel = (table: Map<string, any>, options?: { className?: string }) => {
  return (inputString?: string) => {
    return (
      <div className={classNames('flex-container flex-start', options?.className)}>
        <BucketStageLabel stage={table.getIn(['bucket', 'stage'])} />
        <DevBranchLabel bucket={table.get('bucket')} />
        <MarkedText source={tableName(table)} mark={inputString} />
        <NativeTypesLabel isTyped={table.get('isTyped')} className="icon-addon-left" />
      </div>
    );
  };
};

const folderLabel = (name: string, folder?: string, forceInputString?: string) => {
  return (inputString?: string) => {
    return (
      <>
        <MarkedText source={name} mark={forceInputString ?? inputString} />
        {folder && (
          <div className="f-11 text-muted">
            <FontAwesomeIcon icon="folder" fixedWidth />{' '}
            <MarkedText source={folder} mark={forceInputString ?? inputString} />
          </div>
        )}
      </>
    );
  };
};

export { bucketLabel, tableLabel, folderLabel };
