import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { FEATURE_SANDBOXES_PYTHON_MLFLOW } from '../../../../constants/features';
import Clipboard from '../../../../react/common/Clipboard';
import CodeEditorModal from '../../../../react/common/CodeEditorModal';
import SaveButtons from '../../../../react/common/SaveButtons';
import ApplicationStore from '../../../../stores/ApplicationStore';
import DevBranchesStore from '../../../dev-branches/DevBranchesStore';
import { features as componentFeatures } from '../../Constants';
import { updateConfiguration } from './generic/code-blocks/actions';
import {
  getBlocksAsString,
  getBlocksDefaultValue,
  getTablesHints,
  getVariablesHints,
  hasSplittedQueries,
  prepareMultipleBlocks,
  resolveEditorMode
} from './generic/code-blocks/helpers';
import MLFlowArtifactsModal from './MLFlowArtifactsModal';

const CodeBlocksHeader = (props: {
  readOnly: boolean;
  config: Map<string, any>;
  component: Map<string, any>;
  blocks: List<any>;
  sharedCodes: List<any>;
  models: Map<string, any>;
  tables: Map<string, any>;
  variables: Map<string, any>;
}) => {
  const savedCode = React.useMemo(() => {
    return getBlocksAsString(props.component.get('id'), props.blocks, props.sharedCodes);
  }, [props.component, props.blocks, props.sharedCodes]);

  const [showMLFlowArtifactsModal, setShowMLFlowArtifactsModal] = React.useState(false);
  const [showAllCodeBlocksModal, setShowAllCodeBlocksModal] = React.useState(false);
  const [editedValue, setEditedValue] = React.useState('');
  const [isSaving, setIsSaving] = React.useState(false);

  const onOpenEditor = () => {
    setShowAllCodeBlocksModal(true);
    setEditedValue(savedCode || getBlocksDefaultValue(props.component.get('id')));
  };

  const label = hasSplittedQueries(props.component.get('id')) ? 'Queries' : 'Scripts';
  const showMLFlowArtifactsButton =
    !DevBranchesStore.isDevModeActive() &&
    ApplicationStore.hasCurrentProjectFeature(FEATURE_SANDBOXES_PYTHON_MLFLOW) &&
    props.component.get('features', List()).includes(componentFeatures.MLFLOW_ARTIFACTS_ACCESS) &&
    !props.models.isEmpty();

  const isChanged = editedValue !== savedCode;
  const onReset = () => setEditedValue(savedCode);
  const onSave = () => {
    setIsSaving(true);

    return prepareMultipleBlocks(props.component.get('id'), props.sharedCodes, editedValue)
      .then((blocks: List<any>) => {
        return updateConfiguration(
          props.component.get('id'),
          props.config.get('id'),
          props.config.get('configuration').setIn(['parameters', 'blocks'], blocks),
          `Multiple ${label} updated`
        ).then(() => {
          setEditedValue(getBlocksAsString(props.component.get('id'), blocks, props.sharedCodes));
        });
      })
      .finally(() => setIsSaving(false));
  };
  const renderAdditionalButtons = () => {
    if (props.readOnly) {
      return null;
    }

    return (
      <SaveButtons
        disabled={isSaving}
        isSaving={isSaving}
        isChanged={isChanged}
        onReset={onReset}
        onSave={onSave}
      />
    );
  };

  if (!showMLFlowArtifactsButton && !savedCode && props.readOnly) {
    return null;
  }

  return (
    <>
      <div className="box transparent-header">
        <div className="box-header big-padding smaller-height pl-0 pr-0">
          <h2 className="box-title">{label}</h2>
          <ButtonToolbar>
            {showMLFlowArtifactsButton && (
              <Button bsStyle="default" onClick={() => setShowMLFlowArtifactsModal(true)}>
                Show all MLFlow models
              </Button>
            )}
            {!!savedCode && (
              <Clipboard
                inline={false}
                tooltipPlacement="top"
                btnClassName={'btn-default'}
                label={`Copy all ${label}`}
                text={savedCode}
              />
            )}
            {(!props.readOnly || !!savedCode) && (
              <Button bsStyle="default" onClick={onOpenEditor}>
                {!savedCode ? (
                  <>
                    <FontAwesomeIcon fixedWidth icon="plus" className="btn-icon" />
                    Create multiple {label}
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon fixedWidth icon="code" className="btn-icon" />
                    {!props.readOnly ? 'Edit' : 'Show'} all {label}
                  </>
                )}
              </Button>
            )}
          </ButtonToolbar>
        </div>
        <MLFlowArtifactsModal
          models={props.models}
          show={showMLFlowArtifactsModal}
          onHide={() => setShowMLFlowArtifactsModal(false)}
        />
        {showAllCodeBlocksModal && (
          <CodeEditorModal
            withAutocomplete
            onEditorDidMount={(editor: any) => editor.focus()}
            title={`All ${label}`}
            onClose={() => setShowAllCodeBlocksModal(false)}
            value={editedValue}
            onChange={setEditedValue}
            onSave={onSave}
            onReset={onReset}
            isChanged={isChanged}
            renderAdditionalButtons={renderAdditionalButtons}
            codeMirrorOptions={{
              mode: resolveEditorMode(props.component.get('id')),
              ...(props.readOnly
                ? {
                    lineNumbers: false,
                    cursorHeight: 0,
                    readOnly: true
                  }
                : {
                    placeholder: '-- Your code goes here',
                    hintOptions: {
                      completeSingle: false,
                      container: document.querySelector('.full-screen-modal.full-screen-editor'),
                      tables: getTablesHints(
                        props.component.get('id'),
                        props.config.get('configuration', Map()),
                        props.tables
                      ),
                      variables: getVariablesHints(props.variables)
                    }
                  })
            }}
          />
        )}
      </div>
    </>
  );
};

export default CodeBlocksHeader;
