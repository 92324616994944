import { fromJS } from 'immutable';

import {
  KDS_TEAM_EX_NETSUITE,
  KDS_TEAM_EX_PROGRESS_DB,
  KDS_TEAM_PROCESSOR_CONVERT_COLUMN_CASE,
  KEBOOLA_EX_DB_DB_2,
  KEBOOLA_EX_DB_DB_2_BATA,
  KEBOOLA_EX_DB_FIREBIRD,
  KEBOOLA_EX_DB_HIVE,
  KEBOOLA_EX_DB_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE_CSAS_TEST,
  KEBOOLA_EX_DB_IMPALA,
  KEBOOLA_EX_DB_INFORMIX,
  KEBOOLA_EX_DB_MSSQL,
  KEBOOLA_EX_DB_MSSQL_CDATA,
  KEBOOLA_EX_DB_MYSQL,
  KEBOOLA_EX_DB_NETSUITE,
  KEBOOLA_EX_DB_ORACLE,
  KEBOOLA_EX_DB_PGSQL,
  KEBOOLA_EX_DB_REDSHIFT,
  KEBOOLA_EX_DB_SNOWFLAKE,
  KEBOOLA_EX_TERADATA,
  KEBOOLA_PROCESSOR_SPLIT_TABLE
} from '../../constants/componentIds';

const EX_DB_GENERIC_COMPONENTS = [
  KEBOOLA_EX_DB_PGSQL,
  KEBOOLA_EX_DB_REDSHIFT,
  KEBOOLA_EX_DB_FIREBIRD,
  KEBOOLA_EX_DB_DB_2,
  KEBOOLA_EX_DB_DB_2_BATA,
  KEBOOLA_EX_DB_MSSQL,
  KEBOOLA_EX_DB_MSSQL_CDATA,
  KEBOOLA_EX_DB_MYSQL,
  KEBOOLA_EX_DB_ORACLE,
  KEBOOLA_EX_DB_SNOWFLAKE,
  KEBOOLA_EX_DB_NETSUITE,
  KEBOOLA_EX_DB_INFORMIX,
  KEBOOLA_EX_DB_IMPALA,
  KEBOOLA_EX_DB_HIVE,
  KEBOOLA_EX_DB_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE_CSAS_TEST,
  KDS_TEAM_EX_NETSUITE,
  KDS_TEAM_EX_PROGRESS_DB,
  KEBOOLA_EX_TERADATA
];

const INCREMENTAL_FETCHING_TYPES = fromJS({
  [KEBOOLA_EX_DB_MYSQL]: [
    'timestamp',
    'datetime',
    'integer',
    'int',
    'smallint',
    'mediumint',
    'bigint',
    'decimal',
    'numeric',
    'float',
    'double'
  ],
  [KEBOOLA_EX_DB_MSSQL]: [
    'datetime',
    'datetime2',
    'smalldatetime',
    'timestamp',
    'integer',
    'int',
    'smallint',
    'bigint',
    'numeric',
    'decimal',
    'real',
    'float'
  ],
  [KEBOOLA_EX_DB_MSSQL_CDATA]: [
    'datetime',
    'datetime2',
    'smalldatetime',
    'timestamp',
    'integer',
    'int',
    'smallint',
    'bigint',
    'numeric',
    'decimal',
    'real',
    'float'
  ],
  [KEBOOLA_EX_DB_PGSQL]: [
    'timestamp',
    'timestamp without time zone',
    'timestamp with time zone',
    'smallint',
    'integer',
    'int',
    'bigint',
    'double precision',
    'serial',
    'bigserial',
    'numeric',
    'decimal',
    'real',
    'float'
  ],
  [KEBOOLA_EX_DB_SNOWFLAKE]: [
    'int',
    'integer',
    'smallint',
    'tinyint',
    'byteint',
    'number',
    'decimal',
    'numeric',
    'float',
    'float4',
    'float8',
    'double',
    'double precision',
    'real',
    'date',
    'datetime',
    'timestamp',
    'timestamp_ntz',
    'timestamp_ltz',
    'timestamp_tz'
  ],
  [KEBOOLA_EX_DB_ORACLE]: [
    'numeric',
    'number',
    'float',
    'decimal',
    'dec',
    'integer',
    'int',
    'smallint',
    'real',
    'double precision',
    'binary_float',
    'binary_double',
    'date',
    'timestamp',
    'timestamp with time zone',
    'timestamp with local time zone'
  ],
  [KEBOOLA_EX_DB_REDSHIFT]: [
    'integer',
    'smallint',
    'bigint',
    'real',
    'double precision',
    'decimal',
    'date',
    'timestamp',
    'timestamptz'
  ],
  [KEBOOLA_EX_DB_FIREBIRD]: [
    'smallint',
    'integer',
    'bigint',
    'float',
    'double precision',
    'numeric',
    'decimal',
    'date',
    'timestamp'
  ],
  [KEBOOLA_EX_DB_INFORMIX]: [
    'char',
    'smallint',
    'integer',
    'float',
    'smallfloat',
    'decimal',
    'serial',
    'date',
    'money',
    'datetime',
    'text',
    'varchar',
    'interval',
    'nchar',
    'nvarchar',
    'int8',
    'serial8',
    'lvarchar',
    'bigint',
    'bigserial'
  ],
  [KEBOOLA_EX_DB_IMPALA]: [
    'int',
    'tinyint',
    'smallint',
    'bigint',
    'real',
    'double',
    'float',
    'decimal',
    'date',
    'timestamp'
  ],
  [KEBOOLA_EX_DB_HIVE]: ['integer', 'numeric', 'float', 'timestamp', 'date'],
  [KEBOOLA_EX_DB_HIVE_CSAS]: ['integer', 'numeric', 'float', 'timestamp', 'date'],
  [KEBOOLA_EX_DB_HIVE_CSAS_TEST]: ['integer', 'numeric', 'float', 'timestamp', 'date'],
  [KEBOOLA_EX_DB_DB_2]: [
    'bigint',
    'smallint',
    'int',
    'integer',
    'numeric',
    'decimal',
    'decfloat',
    'double',
    'real',
    'timestamp',
    'date'
  ],
  [KDS_TEAM_EX_PROGRESS_DB]: ['integer', 'numeric', 'float', 'timestamp', 'date'],
  [KDS_TEAM_EX_NETSUITE]: [
    'int',
    'bigint',
    'numeric',
    'float',
    'double',
    'decimal',
    'date',
    'time',
    'time_with_timezone',
    'timestamp',
    'timestamp_with_timezone'
  ],
  [KEBOOLA_EX_DB_NETSUITE]: ['integer', 'numeric', 'float', 'timestamp', 'date']
});

const paralledUploadProcessor = fromJS({
  definition: { component: KEBOOLA_PROCESSOR_SPLIT_TABLE },
  parameters: { mode: 'slices', numberOfSlices: 60 }
});

const convertColumnCaseProcessor = fromJS({
  definition: { component: KDS_TEAM_PROCESSOR_CONVERT_COLUMN_CASE },
  parameters: { convert_to: 'lowercase' }
});

export {
  EX_DB_GENERIC_COMPONENTS,
  INCREMENTAL_FETCHING_TYPES,
  paralledUploadProcessor,
  convertColumnCaseProcessor
};
