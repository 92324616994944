import React, { useEffect, useState } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { Map } from 'immutable';

import { KEBOOLA_EX_FACEBOOK_ADS } from '../../../../constants/componentIds';
import Checkbox from '../../../../react/common/Checkbox';
import CollapsibleBox from '../../../../react/common/CollapsibleBox';
import ExternalLink from '../../../../react/common/ExternalLink';
import InfoTooltip from '../../../../react/common/InfoTooltip';
import Select from '../../../../react/common/Select';
import { QUERY_TYPE } from '../../constants';

const ASYNC_QUERY_HELP =
  "The Async Insights Query option specify the way extractor requests the data. If it is checked then data will be extracted asynchronously, the extractor will make an initial request, then by polling fb api waits for the result and once ready extracts it. This allows for bigger amount of data to be extracted especially when the synchronous extraction ends up with error Please reduce the amount of data you're asking for. Note that only data of the ads objects (Ad Account, Ad Campaign, Ad Set and Ad) can be extracted asynchronously.";

const ENDPOINT_HELP =
  'URL part of the Facebook Graph API request specifying node-id and/or edge-name, e.g. feed, me/photos etc. Can be empty. More information is available in the';

const FIELDS_HELP =
  'Parameter of the Facebook Graph API nested request specifying fields and/or additional parameters of the endpoint. More information is available in the';

const PARAMETERS_HELP =
  'Parameters of Ads insights specified in URL query string format. More information is available in the';

const TEMPLATE_HELP = 'Not sure how to set the query? Use our predefined templates.';

type Props = {
  componentId: string;
  config: Map<string, any>;
  templates: Map<string, any>;
  readOnly: boolean;
  isAsync: boolean;
  documentationUrl: string;
  resetTemplate: boolean;
  getAccountDesc: (type: string) => string;
  updateLocalState: (path: string[], newValue: Map<string, any> | boolean | string) => void;
};

const Report = ({
  componentId,
  config,
  templates,
  readOnly,
  isAsync,
  resetTemplate,
  documentationUrl,
  updateLocalState
}: Props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>();

  useEffect(() => {
    resetTemplate && setSelectedTemplate(null);
  }, [resetTemplate]);

  const type = config.get('type');
  const path = config.getIn(['query', 'path']);
  const fields = config.getIn(['query', 'fields']);
  const parameters = config.getIn(['query', 'parameters']);

  let templatesOptions = templates;
  let templatesOptionsAsync = Map<string, any>();

  if (componentId === KEBOOLA_EX_FACEBOOK_ADS) {
    templatesOptions = templates.get(QUERY_TYPE.NESTED);
    templatesOptionsAsync = templates.get(QUERY_TYPE.ASYNC_INSIGHTS);
  }

  const handleAsync = (isAsync: boolean) => {
    if (isAsync) {
      return config
        .set('type', QUERY_TYPE.ASYNC_INSIGHTS)
        .setIn(['query', 'parameters'], '')
        .deleteIn(['query', 'fields'])
        .deleteIn(['query', 'path']);
    } else {
      return config
        .set('type', QUERY_TYPE.NESTED)
        .setIn(['query', 'path'], '')
        .setIn(['query', 'fields'], '')
        .deleteIn(['query', 'parameters']);
    }
  };

  const handleSelect = (id: string) => {
    const optionValue = templatesOptions
      .concat(templatesOptionsAsync)
      .findLast((t: Map<string, any>) => t?.get('id') === id);
    const isAsync = templatesOptionsAsync.includes(optionValue);

    const templateQuery = optionValue.get('template');
    const newConfig = handleAsync(isAsync).mergeDeep(templateQuery);

    updateLocalState([], newConfig);
    setSelectedTemplate(id);
  };

  const getSelectOptions = (templates: Map<string, any>) => {
    return templates
      .map((t: Map<string, any>) => ({
        label: t?.get('name'),
        value: t?.get('id')
      }))
      .toArray();
  };

  return (
    <CollapsibleBox title="Report" defaultOpen>
      <FormGroup>
        <ControlLabel>
          Template
          <InfoTooltip tooltip={TEMPLATE_HELP} />
        </ControlLabel>
        <Select
          value={selectedTemplate || ''}
          placeholder="Select Template"
          options={[
            {
              label: 'Objects',
              options: getSelectOptions(templatesOptions)
            },
            templatesOptionsAsync.size > 0 && {
              label: 'Async Insights',
              options: getSelectOptions(templatesOptionsAsync)
            }
          ].filter(Boolean)}
          clearable={false}
          disabled={readOnly}
          onChange={handleSelect}
        />
      </FormGroup>

      <hr className="tw-mt-4 tw-mb-6" />

      {componentId === KEBOOLA_EX_FACEBOOK_ADS && (
        <FormGroup>
          <Checkbox
            disabled={readOnly}
            checked={type === QUERY_TYPE.ASYNC_INSIGHTS}
            onChange={(checked) => {
              setSelectedTemplate(null);
              updateLocalState([], handleAsync(checked));
            }}
          >
            Async Insights Query
            <InfoTooltip tooltip={ASYNC_QUERY_HELP} />
          </Checkbox>
        </FormGroup>
      )}

      {!isAsync && (
        <FormGroup>
          <ControlLabel>
            Endpoint
            <InfoTooltip
              allowHoverTooltip
              tooltip={
                <>
                  {ENDPOINT_HELP}{' '}
                  <ExternalLink href={`${documentationUrl}/#endpoint`}>documentation.</ExternalLink>
                </>
              }
            />
          </ControlLabel>
          <FormControl
            value={path || ''}
            disabled={readOnly}
            placeholder="e.g ads or empty string"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateLocalState(['query', 'path'], e.target.value);
            }}
          />
        </FormGroup>
      )}

      <FormGroup>
        <ControlLabel>
          {isAsync ? 'Parameters' : 'Fields'}
          <InfoTooltip
            allowHoverTooltip
            tooltip={
              <>
                {isAsync ? PARAMETERS_HELP : FIELDS_HELP}{' '}
                <ExternalLink href={`${documentationUrl}`}>documentation.</ExternalLink>
              </>
            }
          />
        </ControlLabel>
        <FormControl
          key={type}
          disabled={readOnly}
          componentClass="textarea"
          value={(isAsync ? parameters : fields) || ''}
          placeholder={isAsync ? 'campaign_name,actions' : 'id,name,adset_id'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateLocalState(['query', isAsync ? 'parameters' : 'fields'], e.target.value);
          }}
        />
      </FormGroup>
    </CollapsibleBox>
  );
};

export default Report;
