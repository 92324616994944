import { Map } from 'immutable';
import qs from 'qs';

import { APP_ROUTE } from '../../constants/routeNames';

const prepareLocationContext = (location: Map<string, any>) => {
  return `${location.get('pathname')}${location.get('search')}${location.get('hash')}`;
};

const parseLocationContext = (location: Map<string, any>) => {
  const { pathname, hash, search } = new URL(
    location.getIn(['query', 'context'], ''),
    window.location.origin
  );
  const query = !!search ? qs.parse(new URLSearchParams(search).toString()) : null;
  const routeState = {
    ...(location.get('state') || Map()).toJS(),
    scroll: location.getIn(['state', 'scrollY'], 0)
  };

  return { pathname: pathname !== '/' ? pathname : APP_ROUTE, query, hash, routeState };
};

export { prepareLocationContext, parseLocationContext };
