import { fromJS, Map } from 'immutable';

import dispatcher from '../../Dispatcher';
import StoreUtils, { initStore } from '../../utils/StoreUtils';
import { ActionTypes } from './Constants';
import { prepareConfigurationWorkspaces, updateWorkspaceParameters } from './helpers';

let _store = initStore('WorkspacesStore', Map({ workspaces: Map() }));

const WorkspacesStore = StoreUtils.createStore({
  getConfigurationWorkspaceByConnectionUser(componentId, configId, workspaceUser) {
    return _store
      .getIn(['workspaces', componentId, configId], Map())
      .toList()
      .find((workspace) => workspace.getIn(['connection', 'user']) === workspaceUser, null, Map());
  }
});

dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case ActionTypes.CONFIGURATION_WORKSPACE_CREATE_SUCCESS: {
      const workspace = fromJS(action.workspace);
      _store = _store.setIn(
        ['workspaces', action.componentId, action.configId, workspace.get('id')],
        updateWorkspaceParameters(workspace)
      );
      return WorkspacesStore.emitChange();
    }

    case ActionTypes.CONFIGURATION_WORKSPACES_LOAD_SUCCESS:
      _store = _store.mergeIn(
        ['workspaces', action.componentId, action.configId],
        prepareConfigurationWorkspaces(
          fromJS(action.workspaces).filter(
            (workspace) =>
              !_store.hasIn([
                'workspaces',
                action.componentId,
                action.configId,
                workspace.get('id')
              ])
          )
        )
      );
      return WorkspacesStore.emitChange();

    case ActionTypes.CONFIGURATION_WORKSPACES_DELETE_SUCCESS:
      _store = _store.deleteIn([
        'workspaces',
        action.componentId,
        action.configId,
        action.workspaceId
      ]);
      return WorkspacesStore.emitChange();

    case ActionTypes.CONFIGURATION_WORKSPACES_PASSWORD_RESET_SUCCESS:
      _store = _store.setIn(
        [
          'workspaces',
          action.componentId,
          action.configId,
          action.workspaceId,
          'connection',
          'password'
        ],
        action.password
      );
      return WorkspacesStore.emitChange();

    case ActionTypes.CONFIGURATION_WORKSPACES_HIDE_PASSWORD:
      _store = _store.deleteIn([
        'workspaces',
        action.componentId,
        action.configId,
        action.workspaceId,
        'connection',
        'password'
      ]);
      return WorkspacesStore.emitChange();

    default:
      break;
  }
});

export default WorkspacesStore;
