import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'react-bootstrap';
import { Map } from 'immutable';
import _ from 'underscore';

import CircleIcon from '../../../react/common/CircleIcon';
import NoResultsFound from '../../../react/common/NoResultsFound';
import Link from '../../../react/common/RouterLink';
import matchByWords from '../../../utils/matchByWords';
import { bucketDisplayNameWithStage } from '../../storage/helpers';
import { getDescriptionWithDefaultValue } from '../helpers';
import DisableSharingButton from './DisableSharingButton';
import EditSharingButton from './EditSharingButton';
import SharedToLabel from './SharedToLabel';

class SharedBucketsByMe extends React.Component {
  render() {
    let sharedBuckets = this.props.sharedBuckets;

    if (this.props.query) {
      sharedBuckets = sharedBuckets.filter((sharedBucket) => {
        const name = sharedBucket.get('displayName');
        const description = getDescriptionWithDefaultValue(sharedBucket, '');
        return matchByWords([name, description], this.props.query);
      });

      if (!sharedBuckets.count()) {
        return <NoResultsFound entityName="shared buckets" />;
      }
    }

    return (
      <div className="box-container two-columns">
        {sharedBuckets
          .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
          .map((bucket) => {
            return (
              <div className="box box-panel" key={bucket.get('id')}>
                <div className="box-header with-border with-big-icon">
                  <div className="flex-container mr-1">
                    <CircleIcon
                      icon="folder"
                      generatedBackground={bucketDisplayNameWithStage(bucket)}
                    />
                    <h2 className="box-title pl-1 overflow-break-anywhere">
                      <Link
                        to="shared-from-this-project-detail"
                        params={{ bucketId: bucket.get('id') }}
                      >
                        {bucket.get('displayName')}
                      </Link>
                    </h2>
                  </div>
                  <ButtonToolbar className="flex-nowrap">
                    <DisableSharingButton
                      sapiToken={this.props.sapiToken}
                      bucket={bucket}
                      urlTemplates={this.props.urlTemplates}
                    />
                    <EditSharingButton
                      bucket={bucket}
                      sapiToken={this.props.sapiToken}
                      availableUsersOptions={this.props.availableUsersOptions}
                      availableProjectsOptions={this.props.availableProjectsOptions}
                    />
                  </ButtonToolbar>
                </div>
                <div className="box-panel-content overflow-break-anywhere flex-container align-top">
                  <div>
                    <p className="box-description-header">About Data Bucket</p>
                    <p>{getDescriptionWithDefaultValue(bucket)}</p>
                  </div>
                  <div className="text-right ml-2">
                    <p className="box-description-header">
                      <span className="text-muted-light">Shared to</span>
                    </p>
                    <SharedToLabel sharing={bucket.get('sharing')} />
                  </div>
                </div>
              </div>
            );
          })
          .toArray()}
        {this.renderEmptyBoxes()}
      </div>
    );
  }

  renderEmptyBoxes() {
    const sharedBucketsCount = this.props.sharedBuckets.count();

    if ((this.props.query && !sharedBucketsCount) || sharedBucketsCount > 8) {
      return null;
    }

    return _.times(8 - sharedBucketsCount, (index) => {
      return index === 0 && sharedBucketsCount === 0 ? (
        <div className="box box-panel empty" key={`empty-${index}`}>
          <h3 className="mt-2">No shared buckets</h3>
          <p className="text-muted mb-1">
            Looks like you just started a new project or you did not share any data with your team
            members yet.
          </p>
        </div>
      ) : (
        <div className="box box-panel empty" key={`empty-${index}`} />
      );
    });
  }
}

SharedBucketsByMe.propTypes = {
  sharedBuckets: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  availableUsersOptions: PropTypes.array.isRequired,
  availableProjectsOptions: PropTypes.array.isRequired,
  urlTemplates: PropTypes.object.isRequired,
  query: PropTypes.string
};

export default SharedBucketsByMe;
