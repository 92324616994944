import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import _ from 'underscore';

import CodeEditor from '../../../../react/common/CodeEditor';
import PanelWithDetails from '../../../../react/common/PanelWithDetails';
import SaveButtons from '../../../../react/common/SaveButtons';
import fromJSOrdered from '../../../../utils/fromJSOrdered';
import ConfigurationSchemaNotValidBox from './ConfigurationSchemaNotValidBox';
import JSONSchemaEditor from './JSONSchemaEditor';

const Configuration = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    component: PropTypes.instanceOf(Map).isRequired,
    data: PropTypes.string.isRequired,
    isChanged: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onEditCancel: PropTypes.func.isRequired,
    onEditChange: PropTypes.func.isRequired,
    onEditSubmit: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    schema: PropTypes.object,
    hasRowsFlag: PropTypes.bool
  },

  getDefaultProps() {
    return {
      schema: Map(),
      hasRowsFlag: false
    };
  },

  getInitialState() {
    return {
      jsonSchemEditorResetKey: _.uniqueId('json_schema_editor_')
    };
  },

  render() {
    if (!Map.isMap(this.props.schema)) {
      return (
        <ConfigurationSchemaNotValidBox component={this.props.component} entity="configuration" />
      );
    }
    if (this.props.hasRowsFlag && this.props.schema.isEmpty()) {
      return null;
    }

    if (this.props.hasRowsFlag) {
      const title = this.props.schema.get('title', 'Configuration Parameters');

      return (
        <>
          <div className="box">
            <div className="box-header big-padding with-border">
              <h2 className="box-title">{title}</h2>
              {this.renderSaveButton()}
            </div>
            <div className="box-content only-with-panel">
              <PanelWithDetails
                placement="bottom"
                labelOpen={`Open ${title} settings`}
                labelCollapse={`Hide ${title} settings`}
              >
                {this.renderJSONSchemaEditor()}
              </PanelWithDetails>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Configuration Parameters</h2>
            {this.renderSaveButton()}
          </div>
          <div className="box-content">
            {this.props.schema.isEmpty() ? this.renderCodeMirror() : this.renderJSONSchemaEditor()}
          </div>
        </div>
      </>
    );
  },

  renderSaveButton() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <SaveButtons
        isSaving={this.props.isSaving}
        isChanged={this.props.isChanged}
        disabled={!this.props.isValid}
        onSave={this.handleSubmit}
        onReset={this.handleReset}
      />
    );
  },

  renderCodeMirror() {
    return (
      <div className="edit">
        <CodeEditor
          value={this.props.data}
          onChange={this.props.onEditChange}
          options={{
            readOnly: this.props.isSaving || this.props.readOnly,
            lint: true
          }}
        />
        <p className="help-block">
          Properties prefixed with <code>#</code> sign will be encrypted on save. Already encrypted
          strings will persist.
        </p>
      </div>
    );
  },

  renderJSONSchemaEditor() {
    return (
      <JSONSchemaEditor
        key={this.state.jsonSchemEditorResetKey}
        component={this.props.component}
        schema={this.props.schema}
        value={fromJSOrdered(JSON.parse(this.props.data))}
        onChange={this.handleParamsChange}
        readOnly={this.props.isSaving || this.props.readOnly}
      />
    );
  },

  handleParamsChange(value) {
    this.props.onEditChange(JSON.stringify(value));
  },

  handleSubmit() {
    this.props.onEditSubmit().then(this.resetJsonSchemaEditor);
  },

  handleReset() {
    this.props.onEditCancel();
    this.resetJsonSchemaEditor();
  },

  resetJsonSchemaEditor() {
    if (!this.props.schema.isEmpty()) {
      this.setState({ jsonSchemEditorResetKey: _.uniqueId('json_schema_editor_') });
    }
  }
});

export default Configuration;
