import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import Checkbox from '../../../../react/common/Checkbox';
import ChangedSinceFilterInput from '../../../components/react/components/generic/ChangedSinceFilterInput';
import ColumnsSelectRow from '../../../components/react/components/generic/ColumnsSelectRow';
import DataFilterRow from '../../../components/react/components/generic/DataFilterRow';
import SapiTableSelector from '../../../components/react/components/SapiTableSelector';
import { backends } from '../../../storage/constants';

const InputMapping = createReactClass({
  propTypes: {
    mapping: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    exclude: PropTypes.object
  },

  getInitialState() {
    return {
      showDetails: false
    };
  },

  render() {
    return (
      <div className="form-horizontal">
        {this.supportsOptions() && (
          <FormGroup>
            <Col xs={10} xsOffset={2}>
              <Checkbox checked={this.state.showDetails} onChange={this.handleToggleShowDetails}>
                Show details
              </Checkbox>
            </Col>
          </FormGroup>
        )}
        <div className="form-group">
          <label className="col-md-2 control-label">Input Table</label>
          <div className="col-md-10">
            <SapiTableSelector
              autoFocus
              placeholder="Source table"
              disabled={this.props.disabled}
              buckets={this.props.buckets}
              tables={this.props.tables}
              value={this.props.mapping.get('source', '')}
              onSelectTableFn={this.handleChangeSource}
              excludeTableFn={(tableId) =>
                this.props.exclude.some((table) => table.get('source') === tableId)
              }
            />
            <span className="help-block">Select source table from Storage</span>
          </div>
        </div>
        {this.renderColumnFilter()}
        {this.renderChangedSinceFilter()}
        {this.renderDataFilter()}
      </div>
    );
  },

  renderColumnFilter() {
    if (this.state.showDetails) {
      return (
        <ColumnsSelectRow
          value={this.props.mapping}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          allTables={this.props.tables}
        />
      );
    }
    return null;
  },

  renderChangedSinceFilter() {
    if (this.state.showDetails) {
      return (
        <ChangedSinceFilterInput
          mapping={this.props.mapping}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
        />
      );
    }
    return null;
  },

  renderDataFilter() {
    if (this.state.showDetails) {
      return (
        <DataFilterRow
          value={this.props.mapping}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          allTables={this.props.tables}
        />
      );
    }
    return null;
  },

  handleToggleShowDetails(checked) {
    this.setState({ showDetails: checked });
  },

  handleChangeSource(value) {
    const newMapping = this.props.mapping
      .set('source', value)
      .set('destination', `${value}.csv`)
      .set('where_column', '')
      .set('where_values', List())
      .set('where_operator', 'eq')
      .set('columns', List());
    return this.props.onChange(newMapping);
  },

  supportsOptions() {
    const table = this.props.tables.get(this.props.mapping.get('source'), Map());

    return !table.get('isTyped', false) && table.getIn(['bucket', 'backend']) !== backends.BIGQUERY;
  }
});

export default InputMapping;
