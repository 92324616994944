import React from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import type { Map } from 'immutable';

import { componentTypes } from '../../../constants/componentTypes';
import ComponentIcon from '../../../react/common/ComponentIcon';
import ComponentName from '../../../react/common/ComponentName';
import MarkedText from '../../../react/common/MarkedText';
import string from '../../../utils/string';
import { getNewComponentTypeLabel } from '../../components/helpers';

const VALID_TYPES = Object.values(componentTypes);

type Props = {
  component: Map<string, any>;
  configurations: number;
  query: string;
  onSelect: (component: Map<string, any>) => void;
};

class AddTaskComponentRow extends React.PureComponent<Props> {
  state = {
    showTooltip: false
  };

  render() {
    return (
      <>
        <button
          className="btn-block"
          onClick={() => {
            this.setState({ showTooltip: true });
            this.props.onSelect(this.props.component);
            setTimeout(() => this.setState({ showTooltip: false }), 1000);
          }}
        >
          <div className="flex-container flex-start align-top">
            <ComponentIcon component={this.props.component} size="30" />
            <div className="ml-1">
              <div className="font-medium line-height-18">
                <ComponentName component={this.props.component}>
                  {(name) => <MarkedText source={name} mark={this.props.query} />}
                </ComponentName>
              </div>
              <div className="f-11 line-height-1 text-muted">
                {VALID_TYPES.includes(this.props.component.get('type'))
                  ? `${getNewComponentTypeLabel(this.props.component.get('type'))} - `
                  : ''}
                {this.props.configurations}{' '}
                {string.pluralize(this.props.configurations, 'configuration')}
              </div>
            </div>
          </div>
        </button>
        <Overlay target={this} placement="bottom" show={this.state.showTooltip}>
          <Tooltip id={`component-${this.props.component.get('id')}-overlay`}>
            Component added
          </Tooltip>
        </Overlay>
      </>
    );
  }
}

export default AddTaskComponentRow;
