import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'underscore';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';
import SyncActionError from '../../../utils/errors/SyncActionError';
import { createWorkbook } from '../action';

const INITIAL_STATE = {
  showModal: false,
  isCreating: false,
  error: null,
  warning: null,
  path: ''
};

class CreateWorkbook extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Button bsStyle="success" onClick={this.openModal} disabled={this.props.disabled}>
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Create Workbook
        </Button>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Form horizontal onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create Workbook</Modal.Title>
              <ModalIcon.Plus />
            </Modal.Header>
            <Modal.Body>
              {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
              {this.state.warning && <Alert bsStyle="warning">{this.state.warning}</Alert>}
              <FormGroup>
                <Col sm={3} componentClass={ControlLabel}>
                  Path
                </Col>
                <Col sm={9}>
                  <FormControl
                    autoFocus
                    type="text"
                    disabled={this.state.isCreating}
                    value={this.state.path}
                    onChange={(e) => this.setState({ path: e.target.value })}
                  />
                  <HelpBlock>
                    Allowed formats are: <br />
                    <code>{'/path/to/file.xlsx'}</code> - the file is created on a personal
                    OneDrive, or
                    <br />
                    <code>{'https://{siteName}.sharepoint.com/sites/path/to/file.xlsx'}</code>,{' '}
                    <code>{'site://{siteName}/path/to/file.xlsx'}</code> - the file is created on
                    SharePoint site
                  </HelpBlock>
                </Col>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveLabel="Create"
                saveButtonType="submit"
                isSaving={this.state.isCreating}
                isDisabled={!this.state.path.trim()}
              />
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isCreating: true, warning: null, error: null });
    return createWorkbook(this.props.configId, this.state.path)
      .then(({ file }) => {
        const name = _.last(this.state.path.split('/'));
        this.props.onCreate({ name, driveId: file.driveId, fileId: file.fileId });
        this.closeModal();
        return null;
      })
      .catch(SyncActionError, (error) => {
        this.setState({ error: error.message });
      })
      .finally(() => {
        this.setState({ isCreating: false });
      });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState(INITIAL_STATE);
  }
}

CreateWorkbook.propTypes = {
  disabled: PropTypes.bool.isRequired,
  configId: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired
};

export default CreateWorkbook;
