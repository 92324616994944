import { KEBOOLA_STORAGE } from '../../constants/componentIds';
import ComponentsStore from '../components/stores/ComponentsStore';

const getComponent = (event) => {
  const componentId = event.get('component');
  const component =
    componentId === 'storage'
      ? ComponentsStore.getComponent(KEBOOLA_STORAGE)
      : ComponentsStore.getComponent(componentId);

  if (!component) {
    return ComponentsStore.unknownComponent(componentId);
  }

  return component;
};

const shoudUseMonospaceFont = (message) => {
  return /^\d+ of \d+ /im.test(message);
};

export { getComponent, shoudUseMonospaceFont };
