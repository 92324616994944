import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import { strRightBack } from 'underscore.string';

import BucketStageLabel from '../../../../react/common/BucketStageLabel';
import CreatedDate from '../../../../react/common/CreatedDate';
import DevBranchLabel from '../../../../react/common/DevBranchLabel';
import ExternalLink from '../../../../react/common/ExternalLink';
import FileSize from '../../../../react/common/FileSize';
import Loader from '../../../../react/common/Loader';
import RowsCount from '../../../../react/common/RowsCount';
import Tooltip from '../../../../react/common/Tooltip';
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from '../../../../utils/errors/helpers';
import tableIdParser from '../../../../utils/tableIdParser';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../../../utils/windowOpen';
import DevBranchesStore from '../../../dev-branches/DevBranchesStore';
import { prepareProductionHref } from '../../../dev-branches/helpers';
import { routeNames as storageRouteNames } from '../../../storage/constants';
import { tableName, tableNameParsed } from '../../../storage/helpers';
import { prepareLocationContext } from '../../../table-browser/helpers';
import StorageActionCreators from '../../StorageActionCreators';
import BucketsStore from '../../stores/StorageBucketsStore';
import TablesStore from '../../stores/StorageTablesStore';

/** @type {any} */
const StorageApiTableLinkEx = createReactClass({
  propTypes: {
    tableId: PropTypes.string.isRequired,
    showOnlyDisplayName: PropTypes.bool,
    forceProduction: PropTypes.bool,
    showLabels: PropTypes.bool,
    paddingless: PropTypes.bool,
    className: PropTypes.string
  },

  getDefaultProps() {
    return {
      forceProduction: false,
      showOnlyDisplayName: false,
      showLabels: true,
      paddingless: false
    };
  },

  getInitialState() {
    return this.getTableDetails();
  },

  componentDidUpdate(prevProps) {
    const tableDetails = this.getTableDetails();

    if (prevProps.tableId !== this.props.tableId || !this.state.table.equals(tableDetails.table)) {
      this.setState(tableDetails);
    }
  },

  render() {
    return (
      <Tooltip tooltip={this.renderTooltip()} placement="top" type="explanatory">
        <span>{this.renderBody()}</span>
      </Tooltip>
    );
  },

  renderBody() {
    if (this.props.forceProduction) {
      return (
        <ExternalLink
          className="btn btn-link btn-link-inline dark flex-container inline-flex flex-start"
          href={prepareProductionHref(
            ApplicationStore.getProjectBaseUrl(),
            RoutesStore.getRouter().createHref(storageRouteNames.TABLE, {
              bucketId: this.state.bucket.get('id'),
              tableName: this.state.table.get('name')
            }),
            DevBranchesStore.getCurrentId()
          )}
        >
          {this.renderTableName()}
        </ExternalLink>
      );
    }

    if (this.state.table.isEmpty()) {
      return (
        <Button
          bsStyle="link"
          onClick={this.reloadData}
          className={classNames(
            'btn-link-inline dark flex-container inline-flex flex-start',
            { 'p-0': !!this.props.paddingless },
            this.props.className
          )}
        >
          {this.renderTableName()}
        </Button>
      );
    }

    const location = RoutesStore.getRouterState().get('location', Map());

    return (
      <Button
        bsStyle="link"
        className={classNames(
          'btn-link-inline flex-container inline-flex flex-start',
          { 'p-0': !!this.props.paddingless },
          this.props.className
        )}
        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (shouldUseNewWindow(e)) {
            return windowOpen(
              RoutesStore.getRouter().createHref(storageRouteNames.TABLE, {
                bucketId: this.state.bucket.get('id'),
                tableName: this.state.table.get('name')
              })
            );
          }

          return RoutesStore.getRouter().transitionTo(
            'tablePreview',
            { tableId: this.props.tableId },
            { context: prepareLocationContext(location) },
            null,
            { ...(location.get('state') ?? Map()).toJS(), scrollY: window.scrollY }
          );
        }}
      >
        {this.renderTableName()}
      </Button>
    );
  },

  renderTooltip() {
    if (this.props.forceProduction) {
      return (
        <>
          Click to explore details in
          <br /> the production storage.
        </>
      );
    }

    if (this.state.table.isEmpty()) {
      return (
        <>
          Table does not exist.
          <br />
          {this.state.isLoading ? (
            <>
              <Loader /> Loading...{' '}
            </>
          ) : (
            'Click to reload.'
          )}
        </>
      );
    }

    if (this.props.tableId !== this.state.table.get('id')) {
      return (
        <div>
          <div className="tooltip-title">Table ID Warning</div>
          <div>
            The table has been found, but there is a discrepancy in the ID match. It is recommended
            to update the table ID reference to avoid potential issues in the future.
          </div>
        </div>
      );
    }

    if (!this.state.table.get('lastChangeDate')) {
      return 'Table exists, but was never imported.';
    }

    return (
      <>
        <div>
          Last change: <CreatedDate createdTime={this.state.table.get('lastChangeDate')} />
        </div>
        <div>
          Data size: <FileSize size={this.state.table.get('dataSizeBytes')} />
        </div>
        <div>
          Rows count: <RowsCount count={this.state.table.get('rowsCount')} />
        </div>
      </>
    );
  },

  renderTableName() {
    if (this.props.showOnlyDisplayName) {
      return this.state.table.get('displayName', strRightBack(this.props.tableId, '.'));
    }

    return (
      <>
        {this.renderLabels()}
        {this.state.table.isEmpty()
          ? tableNameParsed(this.props.tableId, this.state.bucket)
          : tableName(this.state.table)}
      </>
    );
  },

  renderLabels() {
    if (!this.props.showLabels) {
      return null;
    }

    return (
      <>
        <BucketStageLabel stage={this.state.stage} />
        {!this.props.forceProduction && <DevBranchLabel bucket={this.state.bucket} />}
      </>
    );
  },

  getTableDetails() {
    const { stage, bucket } = tableIdParser.parse(this.props.tableId).parts;

    return {
      stage,
      bucket: BucketsStore.getBucket(`${stage}.${bucket}`, Map()),
      table: TablesStore.getTable(this.props.tableId, Map(), { caseInsensitiveFallback: true }),
      isLoading: false
    };
  },

  reloadData(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isLoading: true });
    StorageActionCreators.loadTableDetailForce(this.props.tableId)
      .catch((error) => {
        if (error?.response?.status === HTTP_STATUS_CODE_NOT_FOUND) {
          return;
        }

        throw error;
      })
      .finally(() => {
        this.setState(this.getTableDetails());
      });
  }
});

export default StorageApiTableLinkEx;
