import Promise from 'bluebird';

import { TRANSFORMATION } from '../../constants/componentIds';
import { APP_ROUTE } from '../../constants/routeNames';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import * as BillingActions from '../billing/actions';
import componentsActions from '../components/InstalledComponentsActionCreators';
import metadataActionCreators from '../components/MetadataActionCreators';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import HomeHeaderButtons from './react/HomeHeaderButtons';
import Home from './react/Index';
import ActionCreators from './ActionCreators';
import { isValidTarget, mapTargetToRoute } from './helpers';

const pollableActions = () => {
  return Promise.all([
    ActionCreators.loadActivities(),
    ActionCreators.loadLatestGitEvent(),
    componentsActions.reloadInstalledComponents({ include: 'configuration' }),
    ActionCreators.loadJobs(),
    ApplicationStore.hasPayAsYouGo() && BillingActions.getCredits()
  ]);
};

export default {
  path: 'dashboard',
  name: APP_ROUTE,
  title: 'Overview',
  defaultRouteHandler: Home,
  headerButtonsHandler: !ApplicationStore.isDemoPreview() && HomeHeaderButtons,
  onEnter: ({ location }, replace, callback) => {
    if (isValidTarget(location.query.target)) {
      const { name, params } = mapTargetToRoute(location.query.target);
      RoutesStore.getRouter().replaceTo(name, params, location.query);
    }

    callback();
  },
  requireData: [
    pollableActions,
    () => {
      // metadata is not used in development branch, so we can skip loading them
      if (DevBranchesStore.isDevModeActive()) {
        return Promise.resolve();
      }

      return metadataActionCreators.loadProjectBranchMetadata(
        DevBranchesStore.getDefaultBranchId()
      );
    },
    () => {
      if (!ApplicationStore.hasNewTransformationsOnly()) {
        return componentsActions.loadComponentConfigsData(TRANSFORMATION);
      }

      return Promise.resolve();
    }
  ],
  poll: {
    interval: 30,
    skipFirst: true,
    action: pollableActions
  }
};
