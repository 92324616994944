import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA } from '../../constants/componentIds';
import { MetadataKeys } from '../../modules/components/MetadataConstants';
import ConfigurationLink from '../../modules/components/react/components/ComponentConfigurationLink';
import ComponentDetailLink from './ComponentDetailLink';
import ComponentIcon from './ComponentIcon';
import ComponentName from './ComponentName';
import Truncated from './Truncated';

class TableUpdatedByComponentInfo extends React.Component {
  render() {
    const { componentId, configId } = this.getLastUpdatedInfo();
    const component = this.props.components.get(componentId);
    const config = this.props.configurations.getIn([componentId, 'configurations', configId]);

    if (!component) {
      return 'N/A';
    }

    if (!config) {
      return (
        <div className="flex-container flex-start flex-reverse">
          {this.renderComponentIcon(component, config)}
          <div>
            <ComponentDetailLink componentId={component.get('id')} className="config-name dark">
              {configId || 'Unknown configuration'}
            </ComponentDetailLink>
            <div className="f-11 line-height-1 text-muted">
              <ComponentName component={component} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex-container flex-start flex-reverse">
        {this.renderComponentIcon(component, config)}
        <div>
          <ConfigurationLink
            componentId={componentId}
            configId={configId}
            hasFlows={this.props.hasFlows}
            className="config-name"
          >
            <Truncated text={config.get('name', configId)} />
          </ConfigurationLink>
          <div className="f-11 line-height-1 text-muted">
            <ComponentName component={component} />
          </div>
        </div>
      </div>
    );
  }

  renderComponentIcon(componentForIcon, config) {
    let component = componentForIcon;
    if (config && component.get('id') === KEBOOLA_EX_SAMPLE_DATA) {
      component = this.props.components.get(
        config.getIn(['configuration', 'parameters', 'componentId'], component)
      );
    }

    return <ComponentIcon size="24" component={component} className="icon-addon-left" />;
  }

  getLastUpdatedInfo() {
    let componentFound = this.metadataLookup(MetadataKeys.LAST_UPDATED_BY_COMPONENT_ID);
    let configFound = this.metadataLookup(MetadataKeys.LAST_UPDATED_BY_CONFIGURATION_ID);

    if (!componentFound || !configFound) {
      componentFound = this.metadataLookup(MetadataKeys.CREATED_BY_COMPONENT_ID);
      configFound = this.metadataLookup(MetadataKeys.CREATED_BY_CONFIGURATION_ID);
    }

    return {
      componentId: componentFound && componentFound.get('value'),
      configId: configFound && configFound.get('value')
    };
  }

  metadataLookup(key) {
    return this.props.table.get('metadata', List()).find((m) => m.get('key') === key);
  }
}

TableUpdatedByComponentInfo.propTypes = {
  components: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  table: PropTypes.instanceOf(Map).isRequired,
  hasFlows: PropTypes.bool.isRequired
};

export default TableUpdatedByComponentInfo;
