import later from '@breejs/later';
import cronstrue from 'cronstrue';
import { fromJS, List } from 'immutable';
import { capitalize } from 'underscore.string';

import {
  KEBOOLA_EX_SAMPLE_DATA,
  KEBOOLA_ORCHESTRATOR,
  TRANSFORMATION
} from '../../constants/componentIds';
import { componentTypes } from '../../constants/componentTypes';
import { shouldPreferHour12 } from '../../date';
import generateId from '../../utils/generateId';

/**
 * @param {string} cron
 * @returns {string}
 **/
const stringifyCron = (cron) => {
  return cronstrue.toString(cron, { verbose: true, use24HourTimeFormat: !shouldPreferHour12() });
};

const getScheduleFromCrontab = (crontabRecord) => {
  return later.parse.cron(crontabRecord).schedules[0];
};

const getPeriodForSchedule = (schedule) => {
  if (schedule.M) {
    return later.year.name;
  }

  if (schedule.D) {
    return later.month.name;
  }

  if (schedule.d) {
    return later.dayOfWeek.name;
  }

  if (schedule.h) {
    return later.day.name;
  }

  return later.hour.name;
};

const getPeriodForCrontab = (crontabRecord) => {
  return getPeriodForSchedule(getScheduleFromCrontab(crontabRecord));
};

const prepareOrchestration = (orchestration, schedulers) => {
  const scheduler = schedulers.find(
    (scheduler) =>
      scheduler.getIn(['configuration', 'target', 'componentId']) === KEBOOLA_ORCHESTRATOR &&
      scheduler.getIn(['configuration', 'target', 'configurationId']) === orchestration.get('id')
  );

  if (scheduler) {
    return orchestration.set('schedulerConfiguration', scheduler);
  }

  return orchestration;
};

const prepareOrchestrations = (orchestrations, schedulers) => {
  return orchestrations.map((orchestration) => prepareOrchestration(orchestration, schedulers));
};

const createTask = (component, configuration, phaseId, taskIds) => {
  const componentId = configuration?.get('isSample') ? KEBOOLA_EX_SAMPLE_DATA : component.get('id');

  return fromJS({
    id: generateId(taskIds),
    name: configuration ? `${componentId}-${configuration.get('id')}` : componentId,
    phase: phaseId,
    task: { componentId, configId: configuration ? configuration.get('id') : '', mode: 'run' },
    continueOnFailure: false,
    enabled: true
  });
};

const shouldAddEmptyPhase = (phases, tasks) => {
  if (phases.isEmpty()) {
    return true;
  }
  const lastPhase = phases.last();
  if (tasks.count((task) => task.get('phase') === lastPhase.get('id'))) {
    return true;
  }
  return phases.count() <= tasks.groupBy((task) => task.get('phase')).count();
};

const addEmptyPhase = (phases) => {
  return phases.push(
    fromJS({
      id: generateId(
        phases
          .map((phase) => phase.get('id'))
          .toList()
          .toJS()
      ),
      name: 'New Phase'
    })
  );
};

const preparePhasesWithTasks = (phases, tasks) => {
  return phases.map((phase) => {
    const phaseTasks = tasks.filter((task) => phase.get('id') === task.get('phase'));

    return phase.set('tasks', phaseTasks);
  });
};

const prepareConfigurationForSave = (configuration, phases, tasks) => {
  const phasesWithTasks = phases.filter((phase) =>
    tasks.count((task) => task.get('phase') === phase.get('id'))
  );

  const tasksWithPhase = tasks.filter((task) =>
    phasesWithTasks.count((phase) => phase.get('id') === task.get('phase'))
  );

  let dependsOnForNext = List();
  const phasesWithDependsOn = phasesWithTasks.map((phase) => {
    const phaseWithDependsOn = phase.set('dependsOn', dependsOnForNext);
    dependsOnForNext = fromJS([phase.get('id')]);
    return phaseWithDependsOn;
  });

  return configuration.set('phases', phasesWithDependsOn).set('tasks', tasksWithPhase);
};

const groupTasksToPhasesByComponentType = (allComponents, tasks) => {
  let phaseId = 0;
  const tasksByComponentType = tasks
    .groupBy((task) => {
      const componentId = task.getIn(['task', 'componentId']);
      if (componentId === TRANSFORMATION) {
        return componentTypes.TRANSFORMATION;
      }
      if (allComponents.has(componentId)) {
        return allComponents.getIn([componentId, 'type']);
      }
      return 'other';
    })
    .map((tasks, key) => {
      phaseId++;
      return fromJS({
        id: phaseId,
        name: capitalize(key),
        tasks
      });
    })
    .toList();

  return fromJS({
    phases: tasksByComponentType.map((phase) => phase.delete('tasks')),
    tasks: tasksByComponentType.flatMap((phase) => {
      return phase.get('tasks').map((task) => task.set('phase', phase.get('id')));
    })
  });
};

export {
  prepareOrchestration,
  prepareOrchestrations,
  preparePhasesWithTasks,
  createTask,
  addEmptyPhase,
  shouldAddEmptyPhase,
  prepareConfigurationForSave,
  groupTasksToPhasesByComponentType,
  stringifyCron,
  getScheduleFromCrontab,
  getPeriodForSchedule,
  getPeriodForCrontab
};
