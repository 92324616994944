import { fromJS } from 'immutable';

const createConfiguration = (localState) => {
  return fromJS({
    parameters: {
      query: {
        fileName: localState.get('fileName', '')
      }
    },
    storage: {
      output: {
        table_files: { tags: localState.get('fileTags', []) }
      }
    }
  });
};

const parseConfiguration = (configuration) => {
  return fromJS({
    fileName: configuration.getIn(['parameters', 'query', 'fileName'], ''),
    fileTags: configuration.getIn(['storage', 'output', 'table_files', 'tags'], [])
  });
};

const createEmptyConfiguration = (name, friendlyName) => {
  return createConfiguration(fromJS({ fileName: friendlyName }));
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  createEmptyConfiguration: createEmptyConfiguration
};
