import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Well } from 'react-bootstrap';
import classNames from 'classnames';
import { Map } from 'immutable';

import CollapsiblePanel from '../../../react/common/CollapsiblePanel';
import ComponentName from '../../../react/common/ComponentName';
import CreatedDate from '../../../react/common/CreatedDate';
import ModalIcon from '../../../react/common/ModalIcon';
import Tree from '../../../react/common/Tree';
import UrlsToLinks from '../../../react/common/UrlsToLinks';
import { shoudUseMonospaceFont } from '../helpers';
import FileLink from './FileLink';

class EventDetailModal extends React.Component {
  render() {
    const preferMono = shoudUseMonospaceFont(this.props.event.get('message'));

    return (
      <Modal bsSize="large" show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{this.renderTitle()}</Modal.Title>
          <ModalIcon icon="magnifying-glass" color="blue" />
        </Modal.Header>
        <Modal.Body>{this.renderBody(preferMono)}</Modal.Body>
      </Modal>
    );
  }

  renderTitle() {
    if (!this.props.event.count()) {
      return 'Event not found';
    }

    const component = this.props.component || Map();

    return (
      <>
        <ComponentName component={component} capitalize />: Event {this.props.event.get('id')}
      </>
    );
  }

  renderBody(preferMono) {
    if (!this.props.event.count()) {
      return null;
    }

    return (
      <>
        {this.renderMessage(preferMono)}
        {this.renderInfo()}
        {this.renderAttachments()}
        {this.renderDetail('Results', 'results')}
        {this.renderDetail('Parameters', 'params')}
        {this.renderDetail('Context', 'context')}
        {this.renderDetail('Performance', 'performance')}
      </>
    );
  }

  renderMessage(preferMono) {
    return (
      <div>
        <p className={classNames({ 'font-mono': preferMono })}>
          <UrlsToLinks text={this.props.event.get('message')} />
        </p>
        {this.props.event.get('description') && (
          <p>
            <UrlsToLinks text={this.props.event.get('description')} />
          </p>
        )}
      </div>
    );
  }

  renderInfo() {
    return (
      <Well>
        <p className="flex-container">
          <strong>Created</strong>
          <CreatedDate
            absolute
            withSeconds
            className="text-muted"
            createdTime={this.props.event.get('created')}
          />
        </p>
        <p className="flex-container">
          <strong>Component</strong>
          <span className="text-muted">{this.props.event.get('component')}</span>
        </p>
        <p className="flex-container">
          <strong>Run ID</strong>
          <span className="text-muted">{this.props.event.get('runId')}</span>
        </p>
        <p className="flex-container">
          <strong>Created by</strong>
          <span className="text-muted">{this.props.event.getIn(['token', 'name'])}</span>
        </p>
      </Well>
    );
  }

  renderAttachments() {
    if (!this.props.event.get('attachments').count()) {
      return null;
    }

    return (
      <CollapsiblePanel title="Attachments">
        <ul>
          {this.props.event
            .get('attachments')
            .map((attachment, idx) => (
              <li key={idx}>
                <FileLink file={attachment} />
              </li>
            ))
            .toArray()}
        </ul>
      </CollapsiblePanel>
    );
  }

  renderDetail(header, eventPropertyName) {
    if (!this.props.event.get(eventPropertyName, []).count()) {
      return null;
    }

    return (
      <CollapsiblePanel title={header}>
        <Tree data={this.props.event.get(eventPropertyName)} />
      </CollapsiblePanel>
    );
  }
}

EventDetailModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  component: PropTypes.object
};

export default EventDetailModal;
