import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { Map } from 'immutable';

import useStores from '../../react/hooks/useStores';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import { uiModes } from '../components/Constants';
import RunComponentButton from '../components/react/components/RunComponentButton';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import LatestJobsStore from '../jobs/stores/LatestJobsStore';
import JobsStore from '../queue/store';
import SwitchModeButton from './components/SwitchModeButton';

const HeaderButtons = () => {
  const { componentId, configId, config, readOnly, lastJob } = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('configId');
      const lastJobs = ApplicationStore.hasNewQueue()
        ? JobsStore.getLatestJobs(componentId, configId)
        : LatestJobsStore.getJobs(componentId, configId);

      return {
        configId,
        componentId,
        config: InstalledComponentsStore.getConfig(componentId, configId),
        readOnly: ApplicationStore.isReadOnly(),
        lastJob: lastJobs.getIn(['jobs', 0], Map())
      };
    },
    [],
    [ApplicationStore, RoutesStore, InstalledComponentsStore, JobsStore, LatestJobsStore]
  );

  const uiMode = config.getIn(['configuration', 'runtime', 'uiMode']);

  if (!uiMode) {
    return null;
  }

  return (
    <ButtonToolbar>
      {!readOnly && <SwitchModeButton componentId={componentId} config={config} />}
      {uiMode === uiModes.SIMPLE && !lastJob.isEmpty() && (
        <RunComponentButton
          component={componentId}
          runParams={() => ({ config: configId })}
          label="Run Component"
          runLabel="Run Component"
          noTooltip
          buttonIcon="circle-play"
          disabled={!lastJob.get('endTime')}
          buttonBsStyle="success"
        >
          You are about to run the component.
        </RunComponentButton>
      )}
    </ButtonToolbar>
  );
};

export default HeaderButtons;
