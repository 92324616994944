import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ConnectorIcon = ({ width, height, className }) => {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 10 12"
      xmlns="http://www.w3.org/2000/svg"
      className={classnames('connector-icon', className)}
    >
      <path fill="#C8CAD9" fillRule="evenodd" clipRule="evenodd" d="M2 0H0V10V12H2H10V10H2V0Z" />
    </svg>
  );
};

ConnectorIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ConnectorIcon.defaultProps = {
  width: 10,
  height: 12
};

export default ConnectorIcon;
