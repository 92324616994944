import { regressionLinear } from 'd3-regression';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import dayjs from '../../date';
import {
  allowedJobsQueryParams,
  JOB_SUCCESS_STATUSES,
  JOBS_LIMIT,
  JOBS_LIMIT_FOR_GRAPH,
  JOBS_TYPES,
  MAX_JOBS_LIMIT
} from './constants';

const isPartialJob = (job) => {
  return (
    !job.get('configRowIds', List()).isEmpty() ||
    !job.get('configData', Map()).isEmpty() ||
    !!job.get('onlyOrchestrationTaskIds')
  );
};

const isTriggeredJob = (job) => {
  const description = job.getIn(['token', 'description'], '');

  return (
    description.startsWith('[_internal] Token for triggering') ||
    description === 'Token for triggering an orchestrator' // old token description
  );
};

const getFlowIdFromTriggeredJob = (job) => {
  return job
    ?.getIn(['token', 'description'], '')
    .match(/\[_internal] Token for triggering (\d+)/)?.[1];
};

const isScheduledJob = (job) => {
  return job.getIn(['token', 'description'], '').startsWith('[_internal] Scheduler for');
};

const getFlowIdFromScheduleJob = (job) => {
  return job
    ?.getIn(['token', 'description'], '')
    .match(/\[_internal] Scheduler for (\d+) Scheduler/)?.[1];
};

const isContainerJob = (job) => {
  return [
    JOBS_TYPES.CONTAINER,
    JOBS_TYPES.ORCHESTRATION_CONTAINER,
    JOBS_TYPES.PHASE_CONTAINER
  ].includes(job.get('type'));
};

const isPhaseJob = (job) => {
  return job.get('type') === JOBS_TYPES.PHASE_CONTAINER;
};

const isRowJob = (job) => {
  return job.get('type') === JOBS_TYPES.STANDARD && !job.get('configRowIds', List()).isEmpty();
};

const getChildJobs = (allJobs, parentJob) => {
  if (!isContainerJob(parentJob)) {
    return Map();
  }

  return allJobs.filter((job) => {
    return (
      job.get('runId') !== parentJob.get('runId') &&
      job.get('runId').startsWith(parentJob.get('runId'))
    );
  });
};

const calculateAverage = (graphData) => {
  if (!graphData || graphData.isEmpty()) {
    return null;
  }

  const successJobs = graphData.get('jobs').filter((job) => {
    return JOB_SUCCESS_STATUSES.includes(job.get('status')) && !isPartialJob(job);
  });

  if (!successJobs.count()) {
    return null;
  }

  const unit = graphData.get('unit');
  const totalDuration = successJobs.reduce((sum, job) => sum + job.get('duration'), 0);
  const ratio = unit === 'Seconds' ? 1 : unit === 'Minutes' ? 60 : 3600;
  return parseFloat((totalDuration * ratio) / successJobs.count());
};

const getCurrentBackendSize = (job) =>
  job.getIn(['backend', 'type'], job.getIn(['backend', 'containerType']));

const prepareGraphData = (allJobs, options = {}, currentJobId) => {
  let scale, unit;
  let jobs = allJobs
    .filter((job) => job.get('startTime') && job.get('endTime'))
    .sortBy((job) => -1 * new Date(job.get('createdTime')).getTime())
    .slice(options.offset || 0, options.limit || JOBS_LIMIT_FOR_GRAPH)
    .reverse()
    .toList()
    .map((job, index) =>
      Map({
        index,
        status: job.get('status'),
        date: job.get('createdTime'),
        duration:
          (new Date(job.get('endTime')).getTime() - new Date(job.get('startTime')).getTime()) /
          1000,
        jobId: job.get('id'),
        backendType: getCurrentBackendSize(job) || null,
        isCurrentJob: currentJobId === job.get('id') ? 'isCurrentJob' : 'isNotCurrentJob'
      })
    );

  if (!jobs.count()) {
    return null;
  }

  const maxDuration = jobs.maxBy((job) => job.get('duration')).get('duration');
  if (maxDuration < 60) {
    scale = 1;
    unit = 'Seconds';
  } else if (maxDuration < 3600) {
    scale = 60;
    unit = 'Minutes';
  } else {
    scale = 3600;
    unit = 'Hours';
  }

  jobs = jobs.map((job) => {
    return job.withMutations((item) => {
      item.set('duration', item.get('duration') / scale);
      item.set('unit', unit);
    });
  });

  let trend = null;
  let trendData = List();

  if (!options.onlyJobs) {
    const isSuccess = (job) => JOB_SUCCESS_STATUSES.includes(job.get('status'));
    const successJobs = jobs.filter(isSuccess);
    const averageDuration = parseFloat(
      successJobs.reduce((sum, job) => sum + job.get('duration'), 0) / successJobs.count()
    );

    /*
      Trendline is calculate as basis linear regression of all (approximately 30) previous jobs
      - if a job ends up successfuly, its duration is used directly
      - if a job did not end up successfuly, avarange duration of all previous jobs is used
      We want to use all jobs (even non successful) to get smoother trend line.
    */
    const regressionData = jobs
      .map((job) => [job.get('index'), isSuccess(job) ? job.get('duration') : averageDuration])
      .toArray();
    const result = regressionLinear()(regressionData);

    trend = result.a > 0 ? 'rising' : 'no-rising';
    trendData = jobs
      .filter((job, index, all) => index === 0 || index === all.count() - 1)
      .map((job, index) => job.set('duration', result[index][1]));
  }

  return Map({ scale, unit, jobs, trendData, trend });
};

const prepareQueryParams = ({ limit = JOBS_LIMIT, ...query }) => {
  let validLimit = JOBS_LIMIT;

  if (limit > JOBS_LIMIT) {
    validLimit = limit > MAX_JOBS_LIMIT ? MAX_JOBS_LIMIT : limit;
  }

  return _.pick(
    {
      ...query,
      limit: validLimit
    },
    ...allowedJobsQueryParams
  );
};

const shouldReloadTables = (stats, oldStats) => {
  const moreExportTables =
    stats.getIn(['tables', 'export', 'tables'], List()).count() >
    oldStats.getIn(['tables', 'export', 'tables'], List()).count();

  const moreImportTables =
    stats.getIn(['tables', 'import', 'tables'], List()).count() >
    oldStats.getIn(['tables', 'import', 'tables'], List()).count();

  return moreExportTables || moreImportTables;
};

const isDateValid = (date) => {
  return !!(date && dayjs(date).isValid());
};

/**
 * @param {string} message
 * @returns {string}
 */
const extractErrorDetails = (message) => {
  const start = message.indexOf('odbc_prepare(): SQL error: ');
  const end = message.indexOf(', SQL state');
  return start !== -1 && end !== -1
    ? message.substring(start + 'odbc_prepare(): SQL error: '.length, end)
    : message;
};

const filterLatestJobs = (latestJobs, rowId = null) => {
  return latestJobs.filter((latestJob) => {
    return (
      (!rowId ||
        latestJob.get('configRowIds', List()).isEmpty() ||
        latestJob.get('configRowIds').includes(rowId)) &&
      (!latestJob.get('parentRunId') ||
        !latestJobs.some((job) => job.get('runId') === latestJob.get('parentRunId')))
    );
  });
};

const getUserRunnedParentJob = (job, allJobs) => {
  if (!job.get('config') && job.get('runId', '').includes('.')) {
    const runIdParts = job.get('runId', '').split('.');

    for (let index = 1; index <= runIdParts.length; index++) {
      let parentRunId = runIdParts.slice(0, index * -1).join('.');
      let parentJob = allJobs.find(
        (job) => job.get('runId') === parentRunId && job.get('config'),
        null,
        Map()
      );

      if (!parentJob.isEmpty()) {
        job = parentJob;
        break;
      }
    }
  }

  return job;
};

const prepareSortedChildJobs = (jobs, parent) => {
  return jobs
    .filter((job) => job.get('parentRunId') === parent.get('runId'))
    .sortBy((job) => job.get('id'));
};

const parseJobsQuery = (query) => {
  let queryParams = fromJS({
    status: query.get('status'),
    componentType: query.get('componentType'),
    component: query.get('component'),
    config: query.get('config'),
    row: query.get('row'),
    user: query.get('tokenDescription'),
    timeRange: {
      start: query.get('createdTimeFrom'),
      end: query.get('endTimeTo')
    },
    duration: {
      start: query.get('durationSecondsFrom'),
      end: query.get('durationSecondsTo')
    },
    runId: query.get('runId'),
    sortBy: query.get('sortBy', 'id'),
    sortOrder: query.get('sortOrder', 'desc')
  }).filter(Boolean);

  if (query.has('parentRunId') && !query.get('parentRunId')) {
    queryParams = queryParams.set('excludeChildJobs', true);
  }

  if (!queryParams.getIn(['timeRange', 'start']) && !queryParams.getIn(['timeRange', 'end'])) {
    queryParams = queryParams.delete('timeRange');
  }

  if (!queryParams.getIn(['duration', 'start']) && !queryParams.getIn(['duration', 'end'])) {
    queryParams = queryParams.delete('duration');
  }

  return queryParams;
};

export {
  isPartialJob,
  isTriggeredJob,
  isScheduledJob,
  getFlowIdFromScheduleJob,
  getFlowIdFromTriggeredJob,
  isContainerJob,
  isPhaseJob,
  isRowJob,
  getChildJobs,
  calculateAverage,
  getCurrentBackendSize,
  prepareGraphData,
  prepareQueryParams,
  shouldReloadTables,
  isDateValid,
  extractErrorDetails,
  filterLatestJobs,
  getUserRunnedParentJob,
  prepareSortedChildJobs,
  parseJobsQuery
};
