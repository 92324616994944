import { fromJS } from 'immutable';

const createConfiguration = (localState) => {
  return fromJS({
    parameters: {
      query: {
        tableName: localState.get('tableName', '')
      }
    }
  });
};

const parseConfiguration = (configData) => {
  return fromJS({
    tableName: configData.getIn(['parameters', 'query', 'tableName'], '')
  });
};

const createEmptyConfiguration = (name, friendlyName) => {
  return createConfiguration(fromJS({ tableName: friendlyName }));
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  createEmptyConfiguration: createEmptyConfiguration
};
