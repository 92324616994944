import React from 'react';

import { componentTypes } from '../../../constants/componentTypes';
import { USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import { HIDE_WORKSPACES_BILLING_ALERT } from '../../../constants/localStorageKeys';
import ExternalLink from '../../../react/common/ExternalLink';
import InfoAlert from '../../../react/common/InfoAlert';
import useLocalStorage from '../../../react/hooks/useLocalStorage';
import useStores from '../../../react/hooks/useStores';
import ApplicationStore from '../../../stores/ApplicationStore';
import { getAllowedTransformations } from '../../components/helpers';
import ComponentsStore from '../../components/stores/ComponentsStore';
import { BILLABLE } from '../../sandboxes/Constants';
import { resolveSandboxTypeFromComponentId } from '../../sandboxes/helpers';
import StackFeaturesStore from '../../stack-features/Store';

const WorkspacesBillingAlert = () => {
  const [isHide, hideAlert] = useLocalStorage(HIDE_WORKSPACES_BILLING_ALERT, false);
  const { allowedWorkspaces } = useStores(
    () => {
      const allowedWorkspaces = getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll()
      )
        .filter((component, componentId) =>
          Object.values(BILLABLE).includes(resolveSandboxTypeFromComponentId(componentId))
        )
        .map((component) => component.get('name'));

      return { allowedWorkspaces };
    },
    [],
    [StackFeaturesStore, ApplicationStore, ComponentsStore]
  );

  if (isHide) {
    return null;
  }

  return (
    <div className="container info-alert-section">
      <InfoAlert onClose={() => hideAlert(true)}>
        Active{' '}
        <span className="font-medium">
          {allowedWorkspaces
            ? allowedWorkspaces.join(allowedWorkspaces.count() === 2 ? ' and ' : ', ') + ' '
            : ''}
        </span>
        workspaces consume credits/minutes from your balance.{' '}
        <ExternalLink
          href={`${USER_DOCUMENTATION_URL}/management/project/limits/#project-power--time-credits`}
        >
          Learn more about billing
        </ExternalLink>
      </InfoAlert>
    </div>
  );
};

export default WorkspacesBillingAlert;
