import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExternalLink from '../../../../react/common/ExternalLink';

const DocumentationLink = ({ href }) => (
  <ExternalLink href={href}>
    <FontAwesomeIcon icon="book-blank" fixedWidth />
    Documentation
  </ExternalLink>
);

DocumentationLink.propTypes = {
  href: PropTypes.string.isRequired
};

export default DocumentationLink;
