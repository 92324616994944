import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import { componentTypes } from '../../constants/componentTypes';
import ActivateDeactivateButton from '../../react/common/ActivateDeactivateButton';
import ConfigurationInfoPanel from '../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../react/common/ConfigurationTabs';
import SaveButtons from '../../react/common/SaveButtons';
import Sidebar from '../../react/layout/Sidebar';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import ComponentDescription from '../components/react/components/ComponentDescription';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import ConfigurationRowsActionCreators from '../configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '../configurations/ConfigurationRowsStore';
import DeleteConfigurationRowButton from '../configurations/react/components/DeleteConfigurationRowButton';
import { hasNewProfiles, hasOldProfiles } from '../google-utils/helpers';
import { GapiActions, GapiStore } from '../google-utils/react/GapiFlux';
import OAuthStore from '../oauth-v2/Store';
import QueryEditor from './components/QueryEditor';
import UrlParserModal from './components/UrlParserModal';
import { loadSegments, runSampleQuery } from './actions';
import { endpointTypes } from './constants';
import { authorizeConfiguration, prepareQuery } from './helpers';

const Row = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      ConfigurationRowsStore,
      GapiStore
    )
  ],

  getStateFromStores() {
    const componentId = RoutesStore.getCurrentRouteComponentId();
    const configId = RoutesStore.getCurrentRouteParam('config');
    const rowId = RoutesStore.getCurrentRouteParam('row');
    const row = ConfigurationRowsStore.get(componentId, configId, rowId);
    const editing =
      ConfigurationRowsStore.getEditingConfiguration(componentId, configId, rowId) || Map();
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);

    return {
      componentId,
      configId,
      rowId,
      row,
      editing,
      configData,
      query: editing.get('parameters', Map()),
      component: ComponentsStore.getComponent(componentId),
      configuration: InstalledComponentsStore.getConfig(componentId, configId),
      isLoadingMetadata: GapiStore.isLoadingMetadata(),
      metadata: GapiStore.getMetadata(),
      oauthCredentials: OAuthStore.getCredentials(componentId, configData) || Map(),
      hasOldProfiles: hasOldProfiles(configData),
      hasNewProfiles: hasNewProfiles(configData),
      pendingActions: ConfigurationRowsStore.getPendingActions(componentId, configId, rowId),
      readOnly: ApplicationStore.isReadOnly()
    };
  },

  getInitialState() {
    return {
      showUrlParser: false,
      sampleData: Map(),
      accountSegments: Map()
    };
  },

  componentDidMount() {
    GapiActions.loadAnalyticsMetadata(this.state.configData);
    this.loadAccountSegments();
  },

  render() {
    return (
      <>
        <ConfigurationTabs
          componentId={this.state.componentId}
          configId={this.state.configId}
          rowId={this.state.rowId}
        />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.configuration}
        />
        <div className="row box-separator">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={this.state.componentId}
              configId={this.state.configId}
              rowId={this.state.rowId}
              placeholderEntity={componentTypes.EXTRACTOR}
            />
            {this.renderConfiguration()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={this.state.componentId}
              configId={this.state.configId}
              rowId={this.state.rowId}
              delete={
                <DeleteConfigurationRowButton
                  onClick={this.handleDeleteRow}
                  isPending={this.state.pendingActions.has('delete')}
                  mode="link"
                />
              }
              additionalButtons={
                <ActivateDeactivateButton
                  isActive={!this.state.row.get('isDisabled', false)}
                  isPending={
                    this.state.pendingActions.has('enable') ||
                    this.state.pendingActions.has('disable')
                  }
                  onChange={this.handleActivateDeactivate}
                  readOnly={this.state.readOnly}
                />
              }
              readOnly={this.state.readOnly}
            />
          </div>
          <UrlParserModal
            show={this.state.showUrlParser}
            onSave={(newQuery) => this.handleChange(this.state.query.set('query', newQuery))}
            onCancel={() => this.setState({ showUrlParser: false })}
          />
        </div>
      </>
    );
  },

  renderConfiguration() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Configuration Parameters</h2>
          {this.renderControlButtons()}
        </div>
        <div className="box-content">
          <QueryEditor
            query={this.state.query}
            endpoint={this.getEndpoint()}
            allProfiles={
              this.isNewDataApiActive()
                ? this.state.configData.getIn(['parameters', 'properties'], List())
                : this.state.configData.getIn(['parameters', 'profiles'], List())
            }
            hasOldProfiles={this.state.hasOldProfiles}
            hasNewProfiles={this.state.hasNewProfiles}
            metadata={this.state.metadata}
            sampleDataInfo={this.state.sampleData}
            accountSegments={this.state.accountSegments}
            onChange={this.handleChange}
            onRunQuery={this.handleSampleRun}
            isQueryValid={this.isQueryValid()}
            isLoadingMetadata={this.state.isLoadingMetadata}
            readOnly={this.state.readOnly}
            outputBucket={this.state.configData.getIn(['parameters', 'outputBucket'], '')}
            isNewDataApiActive={this.isNewDataApiActive()}
          />
        </div>
      </div>
    );
  },

  renderControlButtons() {
    if (this.state.readOnly) {
      return null;
    }

    const isSaving = this.state.pendingActions.has('save-configuration');
    const isChanged = this.isParametersChanged();

    return (
      <ButtonToolbar>
        {this.state.query.get('endpoint', endpointTypes.ENDPOINT_REPORT) ===
          endpointTypes.ENDPOINT_REPORT &&
          !this.isNewDataApiActive() && (
            <Button onClick={() => this.setState({ showUrlParser: true })}>Parse Query Url</Button>
          )}
        <SaveButtons
          isSaving={isSaving}
          isChanged={isChanged}
          onSave={() => this.handleSave(this.state.editing.get('parameters', Map()))}
          disabled={isSaving || !isChanged || !this.isQueryValid()}
          onReset={() =>
            ConfigurationRowsActionCreators.resetConfiguration(
              this.state.componentId,
              this.state.configId,
              this.state.rowId
            )
          }
        />
      </ButtonToolbar>
    );
  },

  handleChange(parameters) {
    return ConfigurationRowsActionCreators.updateConfiguration(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
      this.state.editing.set('parameters', parameters)
    );
  },

  handleSave(parameters) {
    return ConfigurationRowsActionCreators.saveConfigurationSimple(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
      this.state.row
        .get('configuration')
        .set(
          'parameters',
          prepareQuery(parameters, this.state.row.get('name'), this.getEndpoint())
        ),
      `Row ${this.state.row.get('name')} updated`
    );
  },

  handleSampleRun() {
    this.setState({ sampleData: this.state.sampleData.set('isLoading', true) });
    return runSampleQuery(this.state.componentId, {
      configData: this.getSyncActionParameters()
    }).then((sampleData) => this.setState({ sampleData: fromJS(sampleData) || Map() }));
  },

  handleDeleteRow() {
    ConfigurationRowsActionCreators.delete(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
      true,
      `${this.state.row.get('name')} deleted`
    );
  },

  handleActivateDeactivate() {
    if (this.state.row.get('isDisabled', false)) {
      return ConfigurationRowsActionCreators.enable(
        this.state.componentId,
        this.state.configId,
        this.state.rowId
      );
    }

    return ConfigurationRowsActionCreators.disable(
      this.state.componentId,
      this.state.configId,
      this.state.rowId
    );
  },

  loadAccountSegments() {
    if (
      !this.state.hasOldProfiles ||
      !this.state.configData.getIn(['authorization', 'oauth_api', 'id'])
    ) {
      return null;
    }

    this.setState({ accountSegments: this.state.accountSegments.set('isLoading', true) });
    return loadSegments(this.state.componentId, {
      configData: this.getSyncActionParameters()
    }).then((accountSegments) =>
      this.setState({ accountSegments: fromJS(accountSegments) || Map() })
    );
  },

  isParametersChanged() {
    return !this.state.row
      .getIn(['configuration', 'parameters'], Map())
      .equals(this.state.editing.get('parameters', Map()));
  },

  isQueryValid() {
    return (
      !!this.state.query &&
      !this.state.query.getIn(['query', 'metrics'], List()).isEmpty() &&
      !this.state.query.getIn(['query', 'dimensions'], List()).isEmpty()
    );
  },

  isNewDataApiActive() {
    return !this.state.hasOldProfiles || this.getEndpoint() === endpointTypes.DATA_API;
  },

  getEndpoint() {
    return this.state.query.get(
      'endpoint',
      this.state.hasNewProfiles ? endpointTypes.DATA_API : endpointTypes.ENDPOINT_REPORT
    );
  },

  getSyncActionParameters() {
    return authorizeConfiguration(this.state.configData, this.state.oauthCredentials)
      .set(
        'parameters',
        this.state.configData
          .get('parameters', Map())
          .merge(prepareQuery(this.state.query, this.state.row.get('name'), this.getEndpoint()))
      )
      .toJS();
  }
});

export default Row;
