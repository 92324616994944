import React from 'react';
import { Map } from 'immutable';

import ActivateDeactivateSwitch from '../../../react/common/ActivateDeactivateSwitch';
import Truncated from '../../../react/common/Truncated';
import { stringifyCron } from '../../orchestrations-v2/helpers';
import { displayTimezone } from '../../orchestrations-v2/timezones';
import { updateScheduler } from '../../scheduler/actions';
import { SCHEDULE_STATE, SOX_BRANCH_TOOLTIP_MESSAGE } from '../../scheduler/constants';

const ScheduleToggle = (props: {
  config: Map<string, any>;
  triggers: Map<string, any>;
  canSchedule: boolean;
  hasProtectedDefaultBranch: boolean;
}) => {
  const [schedulePending, setSchedulePending] = React.useState(Map());

  if (!props.config) {
    return null;
  }

  if (props.triggers.has(props.config.get('id'))) {
    return 'Event trigger';
  }

  const schedulerConfiguration = props.config.get('schedulerConfiguration', Map());
  const schedule = schedulerConfiguration.getIn(['configuration', 'schedule'], Map());

  if (!schedule.get('cronTab')) {
    return 'No Schedule';
  }

  return (
    <div className="flex-container inline-flex">
      <Truncated
        twoLines
        className="pr-1"
        text={`${stringifyCron(schedule.get('cronTab'))} (${displayTimezone(
          schedule.get('timezone')
        )})`}
      />
      <ActivateDeactivateSwitch
        className="pl-0 pr-0"
        buttonDisabled={!props.canSchedule}
        activateTooltip={
          props.hasProtectedDefaultBranch
            ? SOX_BRANCH_TOOLTIP_MESSAGE
            : 'Disabled (click to enable)'
        }
        deactivateTooltip={
          props.hasProtectedDefaultBranch
            ? SOX_BRANCH_TOOLTIP_MESSAGE
            : 'Enabled (click to disable)'
        }
        isActive={schedule.get('state') === SCHEDULE_STATE.ENABLED}
        isPending={schedulePending.has(props.config.get('id'))}
        onChange={(state: boolean) => {
          setSchedulePending(schedulePending.set(props.config.get('id'), true));
          return updateScheduler(
            schedulerConfiguration.get('id'),
            schedulerConfiguration.setIn(
              ['configuration', 'schedule', 'state'],
              state ? SCHEDULE_STATE.ENABLED : SCHEDULE_STATE.DISABLED
            )
          ).finally(() => setSchedulePending(schedulePending.delete(props.config.get('id'))));
        }}
      />
    </div>
  );
};

export default ScheduleToggle;
