import { fromJS, List, Map } from 'immutable';

import {
  KEBOOLA_SANDBOXES,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION
} from '../../../constants/componentIds';
import dayjs from '../../../date';
import Dispatcher from '../../../Dispatcher';
import ApplicationStore from '../../../stores/ApplicationStore';
import fromJSOrdered from '../../../utils/fromJSOrdered';
import StoreUtils, { initStore } from '../../../utils/StoreUtils';
import { isValidJsonConfig } from '../../../utils/validation';
import rowsConstants from '../../configurations/ConfigurationRowsConstants';
import ConfigurationsConstants from '../../configurations/ConfigurationsConstants';
import * as constants from '../Constants';
import {
  convertComponentsToByKeyStructure,
  normalizeSnowflakeTransformationColumnTypes,
  renameOrchestratorToFlow
} from '../helpers';
import { getStorageBasePath } from '../react/components/generic/helpers';
import ComponentsStore from './ComponentsStore';
import TemplatesStore from './TemplatesStore';

let _store = initStore(
  'InstalledComponentsStore',
  Map({
    configsDataLoaded: Map(), // componentId - configurations JSON
    configDataEditing: Map(), // componentId #configId - configuration
    configDataEditingObject: Map(), // componentId #configId - configuration
    rawConfigDataEditing: Map(), // componentId #configId - configuration stringified JSON
    rawConfigDataParametersEditing: Map(), // componentId #configId - configuration stringified JSON
    templatedConfigEditing: Map(), // componentId #configId
    templatedConfigValuesEditingValues: Map(), // componentId #configId
    // group (params:Map|templates:Map)
    templatedConfigValuesEditingString: Map(), // componentId #configId
    templatedConfigEditingString: Map(), // componentId #configId

    // detail JSON
    configDataSaving: Map(),
    configDataParametersIsSaving: Map(),
    localState: Map(),
    metadata: Map(),
    expandedFolders: Map(),
    components: Map(),
    deletedComponents: Map(),
    deletingConfigurations: Map(),
    restoringConfigurations: Map(),
    loadedTime: null,
    isLoading: false,
    isDeletedLoaded: false,
    isDeletedLoading: false,
    pendingActions: Map()
  })
);

var InstalledComponentsStore = StoreUtils.createStore({
  getLocalState(componentId, configId) {
    return _store.getIn(['localState', componentId, configId], Map());
  },

  getAll() {
    return _store.get('components');
  },

  getAllMetadata() {
    return _store.get('metadata', Map());
  },

  getExpandedFolders(type) {
    return _store.getIn(['expandedFolders', type], Map());
  },

  getAllDeleted() {
    return _store.get('deletedComponents');
  },

  getAllForType(type) {
    return this.getAll().filter((component) => component.get('type') === type);
  },

  getComponentConfigurations(componentId) {
    return _store.getIn(['components', componentId, 'configurations'], Map());
  },

  getIsConfigDataLoaded(componentId, configId) {
    return _store.hasIn(['components', componentId, 'configurations', configId, 'configuration']);
  },

  getIsConfigsDataLoaded(componentId) {
    return _store.getIn(['configsDataLoaded', componentId], false);
  },

  getIsMetadataLoaded(componentId, configId) {
    if (componentId && configId) {
      return !_store.getIn(['metadata', componentId, configId], Map()).isEmpty();
    }

    if (componentId) {
      return !_store.getIn(['metadata', componentId], Map()).isEmpty();
    }

    return !_store.get('metadata', Map()).isEmpty();
  },

  getEditingConfigData(componentId, configId, defaultValue) {
    return _store.getIn(['configDataEditing', componentId, configId], defaultValue);
  },

  getEditingRawConfigData(componentId, configId) {
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const path = ['rawConfigDataEditing', componentId, configId];
    return _store.getIn(path, JSON.stringify(configData, null, ' '));
  },

  getEditingRawConfigDataParameters(componentId, configId, defaultParameters) {
    const savedParams = JSON.stringify(defaultParameters.toJSON(), null, '  ');
    return _store.getIn(['rawConfigDataParametersEditing', componentId, configId], savedParams);
  },

  getSavingConfigData(componentId, configId) {
    return _store.getIn(['configDataSaving', componentId, configId]);
  },

  getConfigData(componentId, configId) {
    return _store.getIn(
      ['components', componentId, 'configurations', configId, 'configuration'],
      Map()
    );
  },

  getEditingConfigDataObject(componentId, configId) {
    return _store.getIn(['configDataEditingObject', componentId, configId], Map());
  },

  getConfig(componentId, configId) {
    return _store.getIn(['components', componentId, 'configurations', configId], Map());
  },

  getConfigurationContext: function (componentId, configurationId) {
    const config = this.getConfig(componentId, configurationId);

    return fromJS({
      componentId,
      configurationId,
      configuration: config.get('configuration'),
      rows: config.get('rows', Map()).map((row) => row.get('configuration'))
    });
  },

  getDeletedConfig(componentId, configId) {
    return _store.getIn(['deletedComponents', componentId, 'configurations', configId]);
  },

  isChangedRawConfigData(componentId, configId) {
    return _store.hasIn(['rawConfigDataEditing', componentId, configId]);
  },

  isChangedRawConfigDataParameters(componentId, configId) {
    return _store.hasIn(['rawConfigDataParametersEditing', componentId, configId]);
  },

  isEditingTemplatedConfig(componentId, configId) {
    return _store.getIn(['templatedConfigEditing', componentId, configId], false);
  },

  isChangedTemplatedConfig(componentId, configId) {
    const pathValues = [
      'templatedConfigValuesEditingValues',
      'templatedConfigValuesEditingString',
      'templatedConfigEditing'
    ];
    const isChanged = pathValues.reduce(
      (memo, path) => memo || _store.hasIn([path, componentId, configId], false),
      false
    );
    return isChanged;
  },

  isValidEditingConfigData(componentId, configId) {
    const value = this.getEditingRawConfigData(componentId, configId);
    return isValidJsonConfig(value);
  },

  getRestoringConfigurations() {
    return _store.get('restoringConfigurations');
  },

  getDeletingConfigurations() {
    return _store.get('deletingConfigurations');
  },

  isDeletingConfig(componentId, configId) {
    return _store.hasIn(['deletingConfigurations', componentId, configId]);
  },

  isSavingConfigData(componentId, configId) {
    return _store.hasIn(['configDataSaving', componentId, configId]);
  },

  isSavingConfigDataParameters(componentId, configId) {
    return _store.hasIn(['configDataParametersIsSaving', componentId, configId]);
  },

  getIsLoading() {
    return _store.get('isLoading');
  },

  getIsLoaded(options) {
    if (options?.componentType && options?.include?.includes('configuration')) {
      return _store
        .get('components')
        .filter((component) => component.get('type') === options.componentType)
        .every((component) => {
          return component
            .get('configurations', Map())
            .every((config) => config.has('configuration'));
        });
    } else if (options?.include?.includes('configuration')) {
      return _store.get('components').every((component) => {
        return component
          .get('configurations', Map())
          .every((config) => config.has('configuration'));
      });
    }

    return !!_store.get('loadedTime');
  },

  getIsJustLoaded() {
    return !!_store.get('loadedTime') && !_store.get('loadedTime').isBefore(dayjs(), 'second');
  },

  getIsDeletedLoading() {
    return _store.get('isDeletedLoading');
  },

  getIsDeletedLoaded() {
    return _store.get('isDeletedLoaded');
  },

  getPendingActions(componentId, configId) {
    return _store.getIn(['pendingActions', componentId, configId], Map());
  },

  getTemplatedConfigValueConfig(componentId, configId) {
    return _store.getIn(
      [
        'components',
        componentId,
        'configurations',
        configId,
        'configuration',
        'parameters',
        'config'
      ],
      Map()
    );
  },

  getTemplatedConfigValueUserParams(componentId, configId) {
    let config = InstalledComponentsStore.getTemplatedConfigValueConfig(componentId, configId);
    // delete keys from template if template matches
    const template = TemplatesStore.getMatchingTemplate(componentId, config);
    if (!template.isEmpty()) {
      template
        .get('data')
        .keySeq()
        .forEach((key) => (config = config.delete(key)));
    }
    return config;
  },

  getTemplatedConfigValueWithoutUserParams(componentId, configId) {
    let config = InstalledComponentsStore.getTemplatedConfigValueConfig(componentId, configId);
    // delete schema keys from config
    ComponentsStore.getComponent(componentId)
      .getIn(['configurationSchema', 'properties', 'config', 'properties'], Map())
      .keySeq()
      .forEach((key) => (config = config.delete(key)));
    return config;
  },

  getTemplatedConfigEditingValueParams(componentId, configId) {
    const params = InstalledComponentsStore.getTemplatedConfigValueUserParams(
      componentId,
      configId
    );
    return _store.getIn(
      ['templatedConfigValuesEditingValues', componentId, configId, 'params'],
      params
    );
  },

  getTemplatedConfigEditingValueTemplate(componentId, configId) {
    const config = InstalledComponentsStore.getTemplatedConfigValueConfig(componentId, configId);
    const matchingTemplate = TemplatesStore.getMatchingTemplate(componentId, config);
    return _store.getIn(
      ['templatedConfigValuesEditingValues', componentId, configId, 'template'],
      matchingTemplate
    );
  },

  getTemplatedConfigEditingValueString(componentId, configId) {
    const config = InstalledComponentsStore.getTemplatedConfigValueConfig(componentId, configId);
    return _store.getIn(
      ['templatedConfigValuesEditingString', componentId, configId],
      JSON.stringify(config.toJS(), null, 2)
    );
  },

  isTemplatedConfigEditingString(componentId, configId) {
    return _store.getIn(['templatedConfigEditingString', componentId, configId]) || false;
  }
});

Dispatcher.register(function (payload) {
  const { action } = payload;
  let path, configData, editingData;

  switch (action.type) {
    case constants.ActionTypes.INSTALLED_COMPONENTS_LOCAL_STATE_UPDATE:
      _store = _store.setIn(['localState', action.componentId, action.configId], action.data);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.CONFIGURATION_METADATA_SAVE_SUCCESS:
      _store = _store.setIn(['metadata', action.componentId, action.configId], fromJS(action.data));
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.CONFIGURATION_METADATA_DELETE_SUCCESS:
      _store = _store.updateIn(
        ['metadata', action.componentId, action.configId],
        List(),
        (metadata) => metadata.filter((row) => row.get('id') !== action.metadataId)
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_METADATA_LOAD_SUCCESS:
      _store = _store.update('metadata', Map(), (metadata) => {
        return metadata.withMutations((metadata) => {
          action.result.forEach((item) => {
            metadata.setIn([item.idComponent, item.configurationId], fromJS(item.metadata));
          });
        });
      });
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_EDIT_UPDATE:
      _store = _store.setIn(
        ['configDataEditing', action.componentId, action.configId],
        action.data
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_EDIT_CANCEL:
      _store = _store.deleteIn(['configDataEditing', action.componentId, action.configId]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATA_EDIT_UPDATE:
      _store = _store.setIn(
        ['rawConfigDataEditing', action.componentId, action.configId],
        action.data
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATA_EDIT_CANCEL:
      _store = _store.deleteIn(['rawConfigDataEditing', action.componentId, action.configId]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_EDIT_UPDATE:
      _store = _store.setIn(
        ['rawConfigDataParametersEditing', action.componentId, action.configId],
        action.data
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_EDIT_CANCEL:
      _store = _store.deleteIn([
        'rawConfigDataParametersEditing',
        action.componentId,
        action.configId
      ]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_LOAD_SUCCESS: {
      let data = fromJSOrdered(action.data);

      if ([KEBOOLA_SNOWFLAKE_TRANSFORMATION, KEBOOLA_SANDBOXES].includes(action.componentId)) {
        data = normalizeSnowflakeTransformationColumnTypes(data);
      }

      _store = _store.setIn(
        ['components', action.componentId, 'configurations', action.configId],
        data
      );
      return InstalledComponentsStore.emitChange();
    }

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGSDATA_LOAD_SUCCESS:
      _store = _store.withMutations((store) => {
        store.setIn(['configsDataLoaded', action.componentId], true);
        action.configData.forEach((configuration) => {
          store.setIn(
            ['components', action.componentId, 'configurations', configuration.id],
            fromJSOrdered(configuration)
          );
        });
      });
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATA_SAVE_START:
      var editingDataJson = JSON.parse(
        InstalledComponentsStore.getEditingRawConfigData(action.componentId, action.configId)
      );
      _store = _store.setIn(
        ['configDataSaving', action.componentId, action.configId],
        fromJSOrdered(editingDataJson)
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATA_SAVE_SUCCESS:
      _store = _store.setIn(
        ['components', action.componentId, 'configurations', action.configId],
        fromJSOrdered(action.configuration)
      );
      _store = _store.deleteIn(['configDataSaving', action.componentId, action.configId]);
      _store = _store.deleteIn(['rawConfigDataEditing', action.componentId, action.configId]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATA_SAVE_ERROR:
      _store = _store.deleteIn(['configDataSaving', action.componentId, action.configId]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_SAVE_START:
      _store = _store.setIn(
        ['configDataParametersIsSaving', action.componentId, action.configId],
        true
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_SAVE_SUCCESS:
      _store = _store.setIn(
        ['components', action.componentId, 'configurations', action.configId],
        fromJSOrdered(action.configuration)
      );
      _store = _store.deleteIn([
        'configDataParametersIsSaving',
        action.componentId,
        action.configId
      ]);
      _store = _store.deleteIn([
        'rawConfigDataParametersEditing',
        action.componentId,
        action.configId
      ]);
      return InstalledComponentsStore.emitChange();

    case rowsConstants.ActionTypes.CONFIGURATION_ROWS_UPDATE_SUCCESS:
      _store = _store.updateIn(
        ['components', action.componentId, 'configurations', action.configurationId, 'rows'],
        List(),
        (rows) => {
          return rows.map((row) => {
            return row.get('id') === action.rowId ? fromJSOrdered(action.data) : row;
          });
        }
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_SAVE_ERROR:
      _store = _store.deleteIn([
        'configDataParametersIsSaving',
        action.componentId,
        action.configId
      ]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_SAVE_START:
      if (action.forceData) {
        _store = _store.setIn(
          ['configDataSaving', action.componentId, action.configId],
          action.forceData
        );
      } else {
        const editingData = InstalledComponentsStore.getEditingConfigData(
          action.componentId,
          action.configId
        );
        _store = _store.setIn(
          ['configDataSaving', action.componentId, action.configId],
          editingData
        );
      }
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_SAVE_SUCCESS:
      _store = _store.setIn(
        ['components', action.componentId, 'configurations', action.configId],
        fromJSOrdered(action.configuration)
      );
      _store = _store.deleteIn(['configDataSaving', action.componentId, action.configId]);
      _store = _store.deleteIn(['configDataEditing', action.componentId, action.configId]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_SAVE_ERROR:
      _store = _store.deleteIn(['configDataSaving', action.componentId, action.configId]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_LOAD:
      _store = _store.set('isLoading', true);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_LOAD_ERROR:
      _store = _store.set('isLoading', false);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_DELETE_CONFIGURATION_START:
      _store = _store.setIn(
        ['deletingConfigurations', action.componentId, action.configurationId],
        true
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_DELETE_CONFIGURATION_SUCCESS:
      _store = _store.withMutations(function (store) {
        let storeResult = store
          .deleteIn(['components', action.componentId, 'configurations', action.configurationId])
          .deleteIn(['deletingConfigurations', action.componentId, action.configurationId])
          .deleteIn(['metadata', action.componentId, action.configurationId]);

        if (!storeResult.getIn(['components', action.componentId, 'configurations']).count()) {
          return (storeResult = storeResult.deleteIn(['components', action.componentId]));
        }
      });

      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.DELETED_COMPONENTS_DELETE_CONFIGURATION_START:
      _store = _store.setIn(
        ['deletingConfigurations', action.componentId, action.configurationId],
        true
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.DELETED_COMPONENTS_DELETE_CONFIGURATION_SUCCESS:
      _store = _store.withMutations((store) => {
        store.deleteIn([
          'deletedComponents',
          action.componentId,
          'configurations',
          action.configurationId
        ]);
        store.deleteIn(['deletingConfigurations', action.componentId, action.configurationId]);
        if (
          !store.getIn(['deletedComponents', action.componentId, 'configurations'], Map()).count()
        ) {
          store.deleteIn(['deletedComponents', action.componentId]);
        }
      });
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_DELETE_CONFIGURATION_ERROR:
      _store = _store.deleteIn([
        'deletingConfigurations',
        action.componentId,
        action.configurationId
      ]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.DELETED_COMPONENTS_DELETE_CONFIGURATION_ERROR:
      _store = _store.deleteIn([
        'deletingConfigurations',
        action.componentId,
        action.configurationId
      ]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.DELETED_COMPONENTS_RESTORE_CONFIGURATION_START:
      _store = _store.setIn(
        ['restoringConfigurations', action.componentId, action.configurationId],
        true
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.DELETED_COMPONENTS_RESTORE_CONFIGURATION_SUCCESS:
      _store = _store.withMutations(function (store) {
        store
          .deleteIn([
            'deletedComponents',
            action.componentId,
            'configurations',
            action.configurationId
          ])
          .deleteIn(['restoringConfigurations', action.componentId, action.configurationId]);

        if (
          !store.getIn(['deletedComponents', action.componentId, 'configurations'], Map()).count()
        ) {
          store.deleteIn(['deletedComponents', action.componentId]);
        }
      });

      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.DELETED_COMPONENTS_RESTORE_CONFIGURATION_ERROR:
      _store = _store.deleteIn([
        'restoringConfigurations',
        action.componentId,
        action.configurationId
      ]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_UPDATE_CONFIGURATION_SUCCESS:
    case ConfigurationsConstants.ActionTypes.CONFIGURATIONS_SAVE_CONFIGURATION_SUCCESS:
    case ConfigurationsConstants.ActionTypes.CONFIGURATIONS_SAVE_JSON_CONFIGURATION_SUCCESS:
      _store = _store.setIn(
        ['components', action.componentId, 'configurations', action.configurationId],
        fromJSOrdered(action.data)
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_LOAD_SUCCESS:
      _store = _store.withMutations((store) => {
        store.set('isLoading', false);
        store.set('loadedTime', dayjs());

        const newComponents = renameOrchestratorToFlow(
          convertComponentsToByKeyStructure(action.components),
          ApplicationStore.hasFlows()
        )
          .map((component) => {
            return component.update('configurations', (configurations) => {
              return configurations.sortBy((config) => config.get('name').toLowerCase());
            });
          })
          .sortBy((component) => `${component.get('name')} ${component.get('type')}`);

        const isNotLoaded = (component) => {
          return (
            !!action.options?.componentType &&
            component.get('type') !== action.options.componentType
          );
        };

        store.mergeDeepIn(['components'], newComponents).update('components', (components) => {
          return components
            .filter((component, id) => isNotLoaded(component) || newComponents.has(id))
            .map((component, id) => {
              return isNotLoaded(component)
                ? component
                : component.update('configurations', (configs) => {
                    return configs.filter((config, configId) => {
                      return newComponents.hasIn([id, 'configurations', configId]);
                    });
                  });
            });
        });
      });
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.DELETED_COMPONENTS_LOAD:
      _store = _store.set('isDeletedLoading', true);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.DELETED_COMPONENTS_LOAD_ERROR:
      _store = _store.set('isDeletedLoading', false);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.DELETED_COMPONENTS_LOAD_SUCCESS:
      _store = _store
        .set('isDeletedLoading', false)
        .set('isDeletedLoaded', true)
        .set(
          'deletedComponents',
          renameOrchestratorToFlow(
            convertComponentsToByKeyStructure(action.components),
            ApplicationStore.hasFlows()
          )
        );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_NEW_CONFIGURATION_SAVE_SUCCESS:
      _store = _store.withMutations(function (store) {
        let storeResult = store;
        if (!store.hasIn(['components', action.componentId])) {
          storeResult = store.setIn(
            ['components', action.componentId],
            action.component.set('configurations', Map())
          );
        }

        return storeResult.setIn(
          ['components', action.componentId, 'configurations', action.configuration.id],
          fromJSOrdered(action.configuration)
        );
      });

      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGURATION_MAPPING_EDITING_START:
      var currentMapping = InstalledComponentsStore.getConfigData(
        action.componentId,
        action.configId
      ).getIn(
        [
          ...getStorageBasePath(action.componentId, action.mappingType, action.storage),
          action.mappingType,
          action.storage,
          action.index
        ],
        Map()
      );
      path = [
        'configDataEditingObject',
        action.componentId,
        action.configId,
        ...getStorageBasePath(action.componentId, action.mappingType, action.storage),
        action.mappingType,
        action.storage,
        action.index
      ];
      _store = _store.setIn(path, currentMapping);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGURATION_MAPPING_EDITING_CANCEL:
      path = [
        'configDataEditingObject',
        action.componentId,
        action.configId,
        ...getStorageBasePath(action.componentId, action.mappingType, action.storage),
        action.mappingType,
        action.storage,
        action.index
      ];
      _store = _store.deleteIn(path);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGURATION_MAPPING_EDITING_CHANGE:
      path = [
        'configDataEditingObject',
        action.componentId,
        action.configId,
        ...getStorageBasePath(action.componentId, action.mappingType, action.storage),
        action.mappingType,
        action.storage,
        action.index
      ];
      _store = _store.setIn(path, action.value);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGURATION_MAPPING_SAVE_START:
      path = [
        'pendingActions',
        action.componentId,
        action.configId,
        action.mappingType,
        action.storage,
        action.index,
        'save'
      ];
      _store = _store.setIn(path, true);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGURATION_MAPPING_SAVE_SUCCESS:
      _store = _store.withMutations(function (store) {
        store.deleteIn([
          'pendingActions',
          action.componentId,
          action.configId,
          action.mappingType,
          action.storage,
          action.index,
          'save'
        ]);
        store.deleteIn([
          'configDataEditingObject',
          action.componentId,
          action.configId,
          ...getStorageBasePath(action.componentId, action.mappingType, action.storage),
          action.mappingType,
          action.storage,
          action.index
        ]);
        store.setIn(
          ['components', action.componentId, 'configurations', action.configId],
          fromJSOrdered(action.data)
        );
      });

      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGURATION_MAPPING_SAVE_ERROR:
      _store = _store.withMutations(function (store) {
        path = [
          'pendingActions',
          action.componentId,
          action.configId,
          action.mappingType,
          action.storage,
          action.index,
          'save'
        ];
        let storeResult = store.deleteIn(path);

        path = [
          'configDataEditingObject',
          action.componentId,
          action.configId,
          ...getStorageBasePath(action.componentId, action.mappingType, action.storage),
          action.mappingType,
          action.storage,
          action.index
        ];
        return storeResult.deleteIn(path);
      });

      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGURATION_MAPPING_DELETE_START:
      path = [
        'pendingActions',
        action.componentId,
        action.configId,
        action.mappingType,
        action.storage,
        action.index,
        'delete'
      ];
      _store = _store.setIn(path, true);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGURATION_MAPPING_DELETE_SUCCESS:
      _store = _store.withMutations(function (store) {
        store.deleteIn([
          'pendingActions',
          action.componentId,
          action.configId,
          action.mappingType,
          action.storage,
          action.index,
          'delete'
        ]);
        store.setIn(
          ['components', action.componentId, 'configurations', action.configId],
          fromJSOrdered(action.data)
        );
      });

      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGURATION_MAPPING_DELETE_ERROR:
      path = [
        'pendingActions',
        action.componentId,
        action.configId,
        action.mappingType,
        action.storage,
        action.index,
        'delete'
      ];
      _store = _store.deleteIn(path);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_CANCEL:
      _store = _store
        .deleteIn(['templatedConfigValuesEditingValues', action.componentId, action.configId])
        .deleteIn(['templatedConfigValuesEditingString', action.componentId, action.configId])
        .deleteIn(['templatedConfigEditingString', action.componentId, action.configId])
        .deleteIn(['templatedConfigEditing', action.componentId, action.configId]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_TEMPLATE:
      _store = _store.setIn(
        ['templatedConfigValuesEditingValues', action.componentId, action.configId, 'template'],
        action.template
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_PARAMS:
      _store = _store.setIn(
        ['templatedConfigValuesEditingValues', action.componentId, action.configId, 'params'],
        action.value
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_STRING:
      _store = _store.setIn(
        ['templatedConfigValuesEditingString', action.componentId, action.configId],
        action.value
      );
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_SAVE_START:
      configData =
        InstalledComponentsStore.getConfigData(action.componentId, action.configId) || Map();
      editingData = configData.setIn(
        ['parameters', 'api'],
        TemplatesStore.getApiTemplate(action.componentId)
      );

      if (
        _store.getIn(
          ['templatedConfigValuesEditingString', action.componentId, action.configId],
          false
        )
      ) {
        const editingConfigValueString =
          InstalledComponentsStore.getTemplatedConfigEditingValueString(
            action.componentId,
            action.configId
          );
        editingData = editingData.setIn(
          ['parameters', 'config'],
          fromJSOrdered(JSON.parse(editingConfigValueString))
        );
      } else {
        // params on the first place
        const editingParams = InstalledComponentsStore.getTemplatedConfigEditingValueParams(
          action.componentId,
          action.configId
        );
        editingData = editingData.setIn(['parameters', 'config'], editingParams);

        // merge the template
        const editingTemplate = InstalledComponentsStore.getTemplatedConfigEditingValueTemplate(
          action.componentId,
          action.configId
        );
        editingData = editingData.setIn(
          ['parameters', 'config'],
          editingData.getIn(['parameters', 'config'], Map()).merge(editingTemplate.get('data'))
        );
      }

      _store = _store.setIn(['configDataSaving', action.componentId, action.configId], editingData);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_SAVE_SUCCESS:
      _store = _store
        .setIn(
          ['components', action.componentId, 'configurations', action.configId],
          fromJSOrdered(action.configuration)
        )
        .deleteIn(['templatedConfigValuesEditingValues', action.componentId, action.configId])
        .deleteIn(['templatedConfigValuesEditingString', action.componentId, action.configId])
        .deleteIn(['templatedConfigEditing', action.componentId, action.configId])
        .deleteIn(['templatedConfigEditingString', action.componentId, action.configId])
        .deleteIn(['configDataSaving', action.componentId, action.configId]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_SAVE_ERROR:
      _store = _store
        .deleteIn(['templatedConfigValuesEditingValues', action.componentId, action.configId])
        .deleteIn(['templatedConfigValuesEditingString', action.componentId, action.configId])
        .deleteIn(['templatedConfigEditing', action.componentId, action.configId])
        .deleteIn(['templatedConfigEditingString', action.componentId, action.configId])
        .deleteIn(['configDataSaving', action.componentId, action.configId]);
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_STRING_TOGGLE:
      if (action.isStringEditingMode) {
        _store = _store.setIn(
          ['templatedConfigEditingString', action.componentId, action.configId],
          true
        );
      } else {
        _store = _store
          .deleteIn(['templatedConfigValuesEditingString', action.componentId, action.configId])
          .deleteIn(['templatedConfigEditingString', action.componentId, action.configId]);
      }
      return InstalledComponentsStore.emitChange();

    case constants.ActionTypes.TOGGLE_EXPAND_FOLDER:
      _store = _store.setIn(
        ['expandedFolders', action.componentType, action.folder],
        action.isExpanded
      );
      return;

    case constants.ActionTypes.RESET_EXPANDED_FOLDERS:
      _store = _store.set('expandedFolders', Map());
      return;

    default:
  }
});

export default InstalledComponentsStore;
