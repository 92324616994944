import React from 'react';
import { ButtonToolbar, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Map } from 'immutable';

import { KEBOOLA_DATA_APPS } from '../../constants/componentIds';
import ComponentIcon from '../../react/common/ComponentIcon';
import CopyButton from '../../react/common/ConfigurationsTable/CopyButton';
import NoEntityCreated from '../../react/common/NoEntityCreated';
import RouterLink from '../../react/common/RouterLink';
import RowActionMenuItem from '../../react/common/RowActionMenuItem';
import Truncated from '../../react/common/Truncated';
import useStores from '../../react/hooks/useStores';
import ApplicationStore from '../../stores/ApplicationStore';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import SandboxesStore from '../sandboxes/SandboxesStore';
import DeleteDataApp from './components/DeleteDataApp';
import DeployDataApp from './components/DeployDataApp';
import OpenDataApp from './components/OpenDataApp';
import TerminateDataApp from './components/TerminateDataApp';
import { routeNames } from './constants';

const Index = () => {
  const store = useStores(
    () => {
      return {
        readOnly: ApplicationStore.isReadOnly(),
        component: ComponentsStore.getComponent(KEBOOLA_DATA_APPS),
        sandboxes: SandboxesStore.getSandboxes() as Map<string, any>,
        pendingActions: SandboxesStore.getPendingActions() as Map<string, any>,
        dataApps: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_DATA_APPS),
        hasFlows: ApplicationStore.hasFlows()
      };
    },
    [],
    [SandboxesStore, InstalledComponentsStore, ComponentsStore, ApplicationStore]
  );

  if (store.dataApps.isEmpty()) {
    return <NoEntityCreated entityName="data app" />;
  }

  return (
    <div className="box-container two-columns gap-24">
      {store.dataApps
        .map((config: Map<string, any>) => {
          const sandbox = store.sandboxes.find(
            (sandbox) => sandbox.get('id') === config.getIn(['configuration', 'parameters', 'id']),
            null,
            Map()
          );

          return (
            <div className="box box-panel" key={config.get('id')}>
              <div className="box-header with-big-icon">
                <div className="flex-container">
                  <div className="icon-with-icon">
                    <ComponentIcon component={store.component} size="48" />
                    <FontAwesomeIcon
                      icon="circle"
                      className={classNames(
                        'status',
                        sandbox.isEmpty()
                          ? 'color-danger'
                          : sandbox.get('active', false)
                          ? 'color-success'
                          : 'color-purple'
                      )}
                    />
                  </div>
                  <h2 className="box-title line-height-24 pl-1">
                    <RouterLink
                      to={routeNames.DATA_APP_DETAIL}
                      params={{ config: config.get('id') }}
                    >
                      {config.get('name')}
                    </RouterLink>
                  </h2>
                </div>
                <div className="flex-container flex-end">
                  <Dropdown id={`data-app-${config.get('id')}`} className="row-dropdown" pullRight>
                    <Dropdown.Toggle noCaret bsStyle="link" className="text-muted">
                      <FontAwesomeIcon icon={['far', 'ellipsis-vertical']} className="f-20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DeployDataApp
                        mode="menuitem"
                        config={config}
                        sandbox={sandbox}
                        readOnly={store.readOnly}
                      />
                      <RowActionMenuItem divider />
                      <TerminateDataApp
                        mode="menuitem"
                        config={config}
                        sandbox={sandbox}
                        readOnly={store.readOnly}
                        isPending={store.pendingActions.hasIn(['terminate', sandbox.get('id')])}
                      />
                      <CopyButton
                        configuration={config}
                        component={store.component}
                        hasFlows={store.hasFlows}
                      />
                      <RowActionMenuItem divider />
                      <DeleteDataApp
                        mode="menuitem"
                        config={config}
                        sandbox={sandbox}
                        readOnly={store.readOnly}
                        isDeleting={store.pendingActions.hasIn(['delete', sandbox.get('id')])}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="box-panel-content flex-container stretch flex-column fill-space">
                <Truncated
                  twoLines
                  className="text-muted mb-1"
                  text={config.get('description') || 'No description'}
                />
                <ButtonToolbar className="flex-container mt-auto">
                  <RouterLink
                    to={routeNames.DATA_APP_DETAIL}
                    params={{ config: config.get('id') }}
                    className="btn btn-default fill-space mlp-1"
                  >
                    <FontAwesomeIcon icon="cog" className="icon-addon-right" />
                    Configuration
                  </RouterLink>
                  <OpenDataApp mode="box" config={config} sandbox={sandbox} />
                </ButtonToolbar>
              </div>
            </div>
          );
        })
        .toArray()}
    </div>
  );
};

export default Index;
