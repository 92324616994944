import createVersionsPage from '../react/pages/Versions';

export default function (componentId, configId, routeUniqueName, options) {
  return {
    name: routeUniqueName,
    path: 'versions',
    title: 'Versions',
    defaultRouteHandler: createVersionsPage(componentId, configId),
    breadcrumbHandler: options?.breadcrumbHandler
  };
}
