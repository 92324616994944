import { Promise } from 'bluebird';

import Api from '../../api';
import type { Variable, VariableWithHash } from '../../api/routes/vaultService';
import Dispatcher from '../../Dispatcher';
import InstalledComponentsApi from '../components/InstalledComponentsApi';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import { ActionTypes } from './constants';
import {
  filterCurrentBranchVariables,
  filterProductionVariables,
  isVariableEncrypted
} from './helpers';
import VariablesStore from './store';

const loadVariables = () => {
  if (VariablesStore.getStore().loading) {
    return Promise.resolve();
  }

  const currentBranchId = `${DevBranchesStore.getCurrentId()}`;
  const defaultBranchId = `${DevBranchesStore.getDefaultBranchId()}`;

  Dispatcher.handleViewAction({ type: ActionTypes.LOAD_VARIABLES });
  return Promise.resolve()
    .then(() => {
      if (DevBranchesStore.isDevModeActive()) {
        return Promise.props({
          branchVariables: Api.vaultService.listBranchVariables({ branchId: currentBranchId }),
          productionVariables: Api.vaultService.listBranchVariables({ branchId: defaultBranchId }),
          projectWideVariables: Api.vaultService.listBranchVariables({ branchId: 'null' })
        }).then(({ branchVariables, productionVariables, projectWideVariables }) => {
          const productionOnlyVariables = productionVariables.filter((variable) => {
            return variable.attributes?.branchId === defaultBranchId;
          });

          return filterCurrentBranchVariables(branchVariables)
            .concat(filterProductionVariables(productionOnlyVariables))
            .concat(projectWideVariables);
        });
      }

      return Api.vaultService.listBranchVariables({ branchId: defaultBranchId });
    })
    .then((variables) => {
      Dispatcher.handleViewAction({ type: ActionTypes.LOAD_VARIABLES_SUCCESS, variables });
    })
    .catch((error) => {
      Dispatcher.handleViewAction({ type: ActionTypes.LOAD_VARIABLES_ERROR });

      throw error;
    });
};

const createVariable = (variable: Variable) => {
  if (variable.group === '') {
    delete variable.group;
  }

  if (variable.attributes?.branchId === '') {
    delete variable.attributes.branchId;
  }

  return Promise.resolve().then(() => {
    if (!isVariableEncrypted(variable)) {
      return Api.vaultService.createVariable({ ...variable, value: variable.value });
    }

    return InstalledComponentsApi.encryptVariable(
      variable.value,
      variable.attributes?.branchId &&
        (variable.attributes.branchId === DevBranchesStore.getDefaultBranchId()?.toString()
          ? 'default'
          : 'dev')
    ).then((value) => Api.vaultService.createVariable({ ...variable, value }));
  });
};

const deleteVariable = (variable: VariableWithHash) => {
  return Api.vaultService.deleteVariable({ hash: variable.hash });
};

const updateVariable = (variable: VariableWithHash) => {
  return deleteVariable(variable).then(() => createVariable(variable));
};

const createFolder = (group: string, variables: VariableWithHash[]) => {
  return Promise.each(
    variables.map((variable) => ({ ...variable, group })),
    updateVariable
  ).finally(loadVariables);
};

export { loadVariables, createVariable, deleteVariable, updateVariable, createFolder };
