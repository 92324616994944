import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import keyCodes from '../../constants/keyCodes';
import { getConfigDescription } from '../../modules/openai/api';
import descriptionExcerpt from '../../utils/descriptionExcerpt';
import isMarkdown from '../../utils/isMarkdown';
import DescriptionModal from './DescriptionModal';
import InlineEditTextInput from './InlineEditTextInput';
import Tooltip from './Tooltip';

const MAX_EXCERPT_LENGTH = 60;
const MAX_ROW_EXCERPT_LENGTH = 50;

const InlineDescriptionEditInput = ({
  entity,
  description: savedDescription,
  onSave,
  readOnly,
  component,
  configuration
}) => {
  const [description, setNewDescription] = useState(savedDescription);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const excerptLenght = entity === 'Row' ? MAX_ROW_EXCERPT_LENGTH : MAX_EXCERPT_LENGTH;

  useEffect(() => {
    if (!isEditing && !showModal && savedDescription !== description) {
      setNewDescription(savedDescription);
    }
  }, [isEditing, showModal, savedDescription, description]);

  const saveHandler = (forcedDescription) => {
    const newDescription = forcedDescription || description;

    setIsSaving(true);
    return onSave(newDescription.trim())
      .then(() => {
        setIsEditing(false);
        setNewDescription(newDescription.trim());
      })
      .finally(() => setIsSaving(false));
  };

  if (!isEditing && !description) {
    return null;
  }

  if (readOnly || !onSave) {
    return (
      <span>{description ? descriptionExcerpt(description, excerptLenght) : 'No description'}</span>
    );
  }

  return (
    <div className={classnames('list-row-inline-edit-input-wrapper', { editing: isEditing })}>
      {!isEditing && (
        <>
          {descriptionExcerpt(showModal ? savedDescription : description, excerptLenght)}
          <Tooltip tooltip="Edit Description" placement="top">
            <Button
              bsStyle="link"
              className="btn-link-inline btn-link-muted"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                savedDescription.length > excerptLenght ||
                savedDescription.includes('\n') ||
                isMarkdown(savedDescription)
                  ? setShowModal(true)
                  : setIsEditing(true);
              }}
            >
              <FontAwesomeIcon fixedWidth icon="pen" />
            </Button>
          </Tooltip>
        </>
      )}
      {isEditing && (
        <div
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
          onKeyDown={(event) => {
            if (event.key === keyCodes.ENTER) {
              event.stopPropagation();
            }
          }}
        >
          <InlineEditTextInput
            isEditing
            placeholder="Description"
            isSaving={isSaving}
            isChanged={description !== savedDescription}
            text={description}
            onEditStart={() => null}
            onEditCancel={() => {
              setNewDescription(savedDescription);
              setIsEditing(false);
            }}
            onEditChange={setNewDescription}
            onEditSubmit={saveHandler}
          />
        </div>
      )}
      <DescriptionModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSave={saveHandler}
        description={description}
        entity={entity}
        {...(component &&
          configuration && {
            handleGenerate: () => getConfigDescription(component, configuration)
          })}
      />
    </div>
  );
};

InlineDescriptionEditInput.propTypes = {
  entity: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onSave: PropTypes.func
};

export default InlineDescriptionEditInput;
