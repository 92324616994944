import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import { ltrim } from 'underscore.string';

import { KEBOOLA_SANDBOXES } from '../../constants/componentIds';
import { componentTypes } from '../../constants/componentTypes';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '../../constants/features';
import Loader from '../../react/common/Loader';
import RowActionDropdown from '../../react/common/RowActionDropdown';
import RowActionMenuItem from '../../react/common/RowActionMenuItem';
import TabLink from '../../react/common/TabLink';
import TabNav from '../../react/common/TabNav';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import { canExportTable, canManageBucket, canWriteBucket } from '../admin/privileges';
import { getAllowedTransformations } from '../components/helpers';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import BucketsStore from '../components/stores/StorageBucketsStore';
import TablesStore from '../components/stores/StorageTablesStore';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import {
  filterDevBranchBuckets,
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionBuckets
} from '../dev-branches/helpers';
import { prepareSandboxes } from '../sandboxes/helpers';
import SandboxesStore from '../sandboxes/SandboxesStore';
import { factory as eventsFactory } from '../sapi-events/BucketEventsService';
import StackFeaturesStore from '../stack-features/Store';
import BucketOverview from './components/BucketOverview';
import DeleteBucketModal from './components/DeleteBucketModal';
import DeleteUnlinkBucketMenuItem from './components/DeleteUnlinkBucketMenuItem';
import BucketEvents from './components/Events';
import ExportModal from './components/ExportModal';
import RefreshExternalBucketMenuItem from './components/RefreshExternalBucketMenuItem';
import SearchContextDropdown from './components/SearchContextDropdown';
import { deleteBucket, exportTables, toggleContextFilter } from './actions';
import { bucketTabs, FILTERS, FILTERS_GROUP, routeNames } from './constants';
import StorageStore from './store';

const Bucket = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      StorageStore,
      ComponentsStore,
      InstalledComponentsStore,
      SandboxesStore,
      RoutesStore,
      BucketsStore,
      TablesStore
    )
  ],

  getStateFromStores() {
    const bucketId = RoutesStore.getCurrentRouteParam('bucketId');
    const bucket = BucketsStore.getBucket(bucketId);
    const sapiToken = ApplicationStore.getSapiToken();
    const allBuckets = BucketsStore.getAll();
    const allTables = TablesStore.getAll();
    const tables = allTables.filter((table) => table.getIn(['bucket', 'id']) === bucketId);
    const searchFilters = StorageStore.getSearchFilters();
    const contextFilter = StorageStore.getContextFilter();
    const isDevModeActive = DevBranchesStore.isDevModeActive();
    const availableBuckets = isDevModeActive
      ? filterProductionAndCurrentDevBranchBuckets(allBuckets)
      : contextFilter && searchFilters.get(FILTERS_GROUP.ENTITY) === FILTERS.DEV
      ? filterDevBranchBuckets(allBuckets)
      : filterProductionBuckets(allBuckets);

    return {
      bucket,
      sapiToken,
      tables,
      allTables,
      allBuckets,
      contextFilter,
      availableBuckets,
      isDevModeActive,
      activeTab: ltrim(window.location.hash, '#') || bucketTabs.OVERVIEW,
      indexSearchFilters: searchFilters,
      indexSearchQuery: StorageStore.getSearchQuery(),
      configurations: InstalledComponentsStore.getAll(),
      components: ComponentsStore.getAll(),
      updatingBucket: BucketsStore.isUpdatingBucket(bucketId),
      pendingActions: BucketsStore.getPendingBucketsActions(),
      deletingBucket: BucketsStore.deletingBuckets(bucketId),
      deletingTables: TablesStore.getDeletingTables(),
      exportingTables: TablesStore.getExportingTables(),
      truncatingTables: TablesStore.getTruncatingTables(),
      canWriteBucket: canWriteBucket(sapiToken, bucket),
      canExportTable: canExportTable(sapiToken),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
      availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES)
      ),
      allowedTransformationComponents: getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll()
      ),
      availableDatabricksClusters: InstalledComponentsStore.getLocalState(
        KEBOOLA_SANDBOXES,
        null
      ).get('clusters'),
      hasFlows: ApplicationStore.hasFlows(),
      hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED
      )
    };
  },

  getInitialState() {
    return {
      openExportModal: false,
      openDeleteBucketModal: false
    };
  },

  render() {
    if (!this.state.bucket) {
      return <p>Bucket not found</p>;
    }

    return (
      <>
        <div className="tabs-with-border-wrapper flex-container flex-start align-top">
          <SearchContextDropdown
            activeBucket={this.state.bucket.get('id')}
            searchQuery={this.state.indexSearchQuery}
            searchFilters={this.state.indexSearchFilters}
            contextFilter={this.state.contextFilter}
            toggleContextFilter={toggleContextFilter}
            tables={this.state.allTables}
            buckets={this.state.availableBuckets}
          />
          <TabNav>
            {this.renderTabLink(bucketTabs.OVERVIEW, 'Overview', 'ml-1')}
            {this.renderTabLink(bucketTabs.EVENTS, 'Events')}
          </TabNav>
          <div className="ml-auto flex-container flex-end">{this.renderBucketActions()}</div>
        </div>
        {this.renderTabContent()}
        <ExportModal
          show={this.state.openExportModal}
          tables={this.state.tables}
          onSubmit={(type) => exportTables(this.state.tables, type)}
          onHide={() => this.setState({ openExportModal: false })}
        />
        <DeleteBucketModal
          show={this.state.openDeleteBucketModal}
          bucket={this.state.bucket}
          pendingActions={this.state.pendingActions}
          tables={this.state.tables}
          deleting={this.state.deletingBucket}
          onConfirm={this.handleDeleteBucket}
          onHide={this.closeDeleteBucketModal}
        />
      </>
    );
  },

  renderTabLink(tab, label, className) {
    return (
      <TabLink
        active={this.state.activeTab === tab}
        to={routeNames.BUCKET}
        params={{ bucketId: this.state.bucket.get('id') }}
        hash={`#${tab}`}
        className={className}
      >
        {label}
      </TabLink>
    );
  },

  renderTabContent() {
    if (this.state.activeTab === bucketTabs.EVENTS) {
      return (
        <BucketEvents
          eventsFactory={eventsFactory(this.state.bucket.get('id'))}
          admins={this.state.admins}
        />
      );
    }

    return (
      <BucketOverview
        components={this.state.components}
        configurations={this.state.configurations}
        allowedTransformationComponents={this.state.allowedTransformationComponents}
        sandboxes={this.state.sandboxes}
        readOnly={this.state.readOnly}
        sapiToken={this.state.sapiToken}
        buckets={this.state.allBuckets}
        bucket={this.state.bucket}
        tables={this.state.tables}
        allTables={this.state.allTables}
        isDevModeActive={this.state.isDevModeActive}
        updatingBucket={this.state.updatingBucket}
        pendingActions={this.state.pendingActions}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
        canExportTable={this.state.canExportTable}
        hasFlows={this.state.hasFlows}
        canWriteBucket={this.state.canWriteBucket}
        urlTemplates={this.state.urlTemplates}
        deletingTables={this.state.deletingTables}
        truncatingTables={this.state.truncatingTables}
        exportingTables={this.state.exportingTables}
        availableUsersOptions={this.state.availableUsersOptions}
        availableProjectsOptions={this.state.availableProjectsOptions}
        availableDatabricksClusters={this.state.availableDatabricksClusters}
        hasSnowflakePartnerConnectLimited={this.state.hasSnowflakePartnerConnectLimited}
      />
    );
  },

  renderBucketActions() {
    if (
      !this.state.tables.isEmpty() &&
      this.state.tables.every((table, tableId) => this.state.exportingTables.get(tableId))
    ) {
      return (
        <span className="text-muted line-height-40">
          <Loader className="icon-addon-right" />
          Preparing export
        </span>
      );
    }

    const hasPermissionToManageBucket = canManageBucket(this.state.sapiToken, this.state.bucket);

    if (this.state.readOnly || (!hasPermissionToManageBucket && !this.state.canExportTable)) {
      return null;
    }

    return (
      <RowActionDropdown toggleClassName="in-navigation">
        {this.state.canExportTable && (
          <RowActionMenuItem
            disabled={this.state.tables.isEmpty()}
            onSelect={() => this.setState({ openExportModal: true })}
          >
            <FontAwesomeIcon fixedWidth icon="down-to-line" />
            Export bucket
          </RowActionMenuItem>
        )}
        {hasPermissionToManageBucket && (
          <>
            <RefreshExternalBucketMenuItem bucket={this.state.bucket} />
            <RowActionMenuItem divider />
            <DeleteUnlinkBucketMenuItem
              bucket={this.state.bucket}
              token={this.state.sapiToken}
              onSelect={this.openDeleteBucketModal}
              linkedBucketsCount={this.state.bucket.get('linkedBy', Map()).count()}
              isLinked={this.state.bucket.has('sourceBucket')}
            />
          </>
        )}
      </RowActionDropdown>
    );
  },

  handleDeleteBucket() {
    return deleteBucket(this.state.bucket.get('id'), {
      forceDelete: this.state.tables.count() > 0,
      transition: true
    });
  },

  openDeleteBucketModal() {
    this.setState({ openDeleteBucketModal: true });
  },

  closeDeleteBucketModal() {
    this.setState({ openDeleteBucketModal: false });
  }
});

export default Bucket;
