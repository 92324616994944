import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import ApplicationActionCreators from '../../../../actions/ApplicationActionCreators';
import Loader from '../../../../react/common/Loader';
import Tooltip, { MAX_ACTION_TOOLTIP_LENGTH } from '../../../../react/common/Tooltip';
import callDockerAction from '../../DockerActionsApi';

export const hasSingleSyncAction = (schema: Map<string, any>) => {
  return (
    !!schema &&
    schema.get('properties', Map()).count() === 1 &&
    schema.get('properties').first().get('type') === 'button' &&
    schema.get('properties').first().get('format') === 'sync-action'
  );
};

export const removeSingleSyncActionFromSchema = (schema: Map<string, any>) => {
  return hasSingleSyncAction(schema) ? Map() : schema;
};

const GenericSyncActionsButton = (props: {
  componentId: string;
  configId: string;
  schema: Map<string, any>;
}) => {
  const [isProcessing, setProcessing] = React.useState(false);

  const buttonProps = props.schema.get('properties').first().getIn(['options', 'async'], Map());

  const onClick = () => {
    setProcessing(true);
    return callDockerAction(props.componentId, buttonProps.get('action'), {
      configData: { configId: props.configId }
    })
      .then((response) => {
        if (!!response.message) {
          ApplicationActionCreators.sendNotification({
            type: response?.status === 'error' ? 'error' : 'info',
            message: response.message
          });
        }
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Tooltip
      placement="top"
      tooltip={buttonProps.get('tooltip')}
      type={
        buttonProps.get('tooltip')?.length > MAX_ACTION_TOOLTIP_LENGTH ? 'explanatory' : 'action'
      }
      forceHide={!buttonProps.has('tooltip')}
    >
      <Button
        bsStyle="link"
        className="btn-link-inline btn-block"
        onClick={onClick}
        disabled={isProcessing}
      >
        {isProcessing ? (
          <Loader />
        ) : (
          <FontAwesomeIcon icon={buttonProps.get('icon', 'message-lines')} />
        )}
        {buttonProps.get('label')}
      </Button>
    </Tooltip>
  );
};

export default GenericSyncActionsButton;
