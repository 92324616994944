import { fromJS, List, Map } from 'immutable';

import {
  KEBOOLA_EX_SAMPLE_DATA,
  KEBOOLA_SANDBOXES,
  KEBOOLA_SHARED_CODE
} from '../../../constants/componentIds';
import ComponentsStore from '../../../modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../modules/components/stores/InstalledComponentsStore';
import * as jobsResolver from '../../../modules/jobs/JobComponentResolver';
import JobsStore from '../../../modules/jobs/stores/JobsStore';
import * as queueResolver from '../../../modules/queue/jobResolver';
import QueueStore from '../../../modules/queue/store';
import { resolveComponentIdFromSandboxType } from '../../../modules/sandboxes/helpers';
import SandboxesStore from '../../../modules/sandboxes/SandboxesStore';
import TransformationsStore from '../../../modules/transformations/stores/TransformationsStore';
import RoutesStore from '../../../stores/RoutesStore';

const findProjectOrganization = (organizations, currentProject) => {
  return organizations.find(
    (organization) => {
      return organization
        .get('projects', List())
        .find((project) => project.get('id') === currentProject.get('id'));
    },
    null,
    process.env.NODE_ENV === 'production'
      ? null
      : fromJS({
          name: 'Unknown',
          projects: [{ id: 1, name: 'Mock pages', features: [] }],
          isAdmin: false,
          maintainer: { name: 'Unknown' }
        })
  );
};

const resolveComponent = () => {
  const componentId = RoutesStore.getCurrentRouteComponentId();
  const routeConfig = RoutesStore.getCurrentRouteConfig();

  if (componentId === KEBOOLA_EX_SAMPLE_DATA) {
    const configData = InstalledComponentsStore.getConfigData(
      KEBOOLA_EX_SAMPLE_DATA,
      RoutesStore.getCurrentRouteParam('config')
    );
    return ComponentsStore.getComponent(
      configData.getIn(['parameters', 'componentId'], componentId)
    );
  }

  if (ComponentsStore.hasComponent(componentId)) {
    return ComponentsStore.getComponent(componentId);
  }

  if (['workspace', 'workspace-versions'].includes(routeConfig.get('name'))) {
    const configData = InstalledComponentsStore.getConfigData(
      KEBOOLA_SANDBOXES,
      RoutesStore.getCurrentRouteParam('config')
    );
    const componentIdfromSandboxType = resolveComponentIdFromSandboxType(
      SandboxesStore.getSandboxes()
        .get(configData.getIn(['parameters', 'id']), Map())
        .get('type')
    );
    return ComponentsStore.getComponent(componentIdfromSandboxType) || Map();
  }

  if (['shared-code', 'shared-code-versions'].includes(routeConfig.get('name'))) {
    const configData = InstalledComponentsStore.getConfigData(
      KEBOOLA_SHARED_CODE,
      RoutesStore.getCurrentRouteParam('config')
    );
    return ComponentsStore.getComponent(configData.getIn(['componentId']));
  }

  if (routeConfig.get('name') === 'jobDetail') {
    return jobsResolver.getComponentByJob(
      JobsStore.get(RoutesStore.getCurrentRouteIntParam('jobId')) || Map()
    );
  }

  if (routeConfig.get('name') === 'queue-job') {
    return queueResolver.getComponentByJob(
      QueueStore.get(RoutesStore.getCurrentRouteParam('jobId'))
    );
  }

  if (routeConfig.get('name') === 'transformationDetail') {
    return TransformationsStore.getTransformation(
      RoutesStore.getCurrentRouteParam('config'),
      RoutesStore.getCurrentRouteParam('row')
    );
  }

  return Map();
};

export { findProjectOrganization, resolveComponent };
