import React from 'react';

import Checkbox from '../Checkbox';

type Props = {
  tooltip: string;
  checked: boolean;
  onChange: (checked: boolean) => boolean;
  indeterminate?: boolean;
  disabled?: boolean;
};

class CheckboxCell extends React.Component<Props> {
  render() {
    return (
      <Checkbox
        tooltip={this.props.tooltip}
        checked={this.props.checked}
        indeterminate={this.props.indeterminate}
        onChange={this.onChange}
        disabled={this.props.disabled}
      />
    );
  }

  onChange = (checked: boolean) => {
    return this.props.onChange(checked);
  };
}

export default CheckboxCell;
