import React from 'react';
import PropTypes from 'prop-types';

import AuditBox from './SourcePath/AuditBox';
import DownloadSettings from './SourcePath/DownloadSettings';
import HeaderPrimaryKey from './SourcePath/HeaderPrimaryKey';
import SaveSettings from './SourcePath/SaveSettings';

class SourcePath extends React.Component {
  render() {
    return (
      <>
        <DownloadSettings
          path={this.props.value.path}
          decompress={this.props.value.decompress}
          onChange={this.props.onChange}
          onlyNewFiles={this.props.value.onlyNewFiles}
          disabled={this.props.disabled}
        />
        <SaveSettings
          name={this.props.value.name}
          enclosure={this.props.value.enclosure}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          delimiter={this.props.value.delimiter}
          incremental={this.props.value.incremental}
        />
        <HeaderPrimaryKey
          columnsFrom={this.props.value.columnsFrom}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          columns={this.props.value.columns}
          primaryKey={this.props.value.primaryKey}
        />
        <AuditBox
          addFilenameColumn={this.props.value.addFilenameColumn}
          addRowNumberColumn={this.props.value.addRowNumberColumn}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
        />
      </>
    );
  }
}

SourcePath.propTypes = {
  value: PropTypes.shape({
    path: PropTypes.string.isRequired,
    onlyNewFiles: PropTypes.bool.isRequired,
    decompress: PropTypes.bool.isRequired,
    incremental: PropTypes.bool.isRequired,
    delimiter: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    enclosure: PropTypes.string.isRequired,
    columnsFrom: PropTypes.oneOf(['manual', 'header', 'auto']),
    columns: PropTypes.array.isRequired,
    primaryKey: PropTypes.array.isRequired,
    addRowNumberColumn: PropTypes.bool.isRequired,
    addFilenameColumn: PropTypes.bool.isRequired
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default SourcePath;
