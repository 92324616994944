import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { formatAbsolute } from '../../../react/common/CreatedDate';
import dimple from '../../../utils/dimple';
import { durationInWords } from '../../../utils/duration';

const LatestImportGraph = createReactClass({
  propTypes: {
    data: PropTypes.object.isRequired
  },

  graphRef: null,

  componentDidMount() {
    const data = this.prepareData();
    const svg = dimple.newSvg(this.graphRef, '100%', 160);
    const chart = new dimple.chart(svg, data);

    const xAxis = chart.addCategoryAxis('x', ['fakeYear', 'date']);
    xAxis.timeField = 'fakeYear';
    xAxis.hidden = true;

    const yAxis = chart.addMeasureAxis('y', 'duration');
    yAxis.hidden = true;

    const series = chart.addSeries(null, dimple.plot.area);
    series.lineWeight = 1;
    series.getTooltipText = (e) => [
      `Created: ${formatAbsolute(e.xField[1])}`,
      `Duration:  ${durationInWords(e.yValueList[0])}`
    ];
    series.afterDraw = () => {
      svg.select('path.dimple-series-0').style('clip-path', 'inset(-1px 1px 1px)');
      svg.selectAll('circle.dimple-custom-line-marker').attr('r', 5);
    };

    chart.setMargins(10, 15, 10, 15);
    chart.defaultColors = [new dimple.color('#e1f0ff', '#1F8FFF', 1)];
    chart.draw(0);

    this.chart = chart;

    window.addEventListener('resize', this.refreshGraph);
    window.addEventListener('focus', this.refreshGraph);
  },

  componentDidUpdate(prevProps) {
    if (!prevProps.data.equals(this.props.data)) {
      this.refreshGraph();
    }
  },

  componentWillUnmount() {
    window.removeEventListener('resize', this.refreshGraph);
    window.removeEventListener('focus', this.refreshGraph);
  },

  refreshGraph(e) {
    if (!e) {
      this.chart.data = this.prepareData();
    }

    this.chart.draw(0, !!e);
  },

  prepareData() {
    return this.props.data
      .toList()
      .map((event, index) => ({
        fakeYear: String(2000 + index),
        duration: event.getIn(['performance', 'importDuration']),
        date: event.get('created')
      }))
      .toArray();
  },

  render() {
    return (
      <div className="dimple-box latest-import-graph" ref={(node) => (this.graphRef = node)} />
    );
  }
});

export default LatestImportGraph;
