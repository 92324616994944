import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  ButtonToolbar,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { backendImages } from '../../../constants/backendImages';
import ConfirmButtons from '../../../react/common/ConfirmButtons';
import Markdown from '../../../react/common/Markdown';
import ModalIcon from '../../../react/common/ModalIcon';
import string from '../../../utils/string';
import StorageApi from '../../components/StorageApi';
import { registerExternalBucket } from '../actions';
import { backends, nameWarning } from '../constants';
import { parseBigQueryDatasetUrl } from '../helpers';

const FORM_STEPS = {
  FORM: 'form',
  REGISTER: 'register'
};

const INITIAL_STATE = {
  step: FORM_STEPS.FORM,
  displayName: '',
  tempData: Map(),
  error: null,
  isLoadingGuide: false,
  guide: null
};

const ICONS_MAP = Map({
  [backends.SNOWFLAKE]: backendImages.snowflake,
  [backends.TERADATA]: backendImages.teradata,
  [backends.BIGQUERY]: backendImages.bigquery
});

const ENTITY_MAP = Map({
  [backends.SNOWFLAKE]: 'Snowflake Database Schema',
  [backends.TERADATA]: 'Taradata Database',
  [backends.BIGQUERY]: 'BigQuery Dataset'
});

const HAS_GUIDE = [backends.SNOWFLAKE, backends.BIGQUERY];

const RegisterExternalBucketModal = createReactClass({
  propTypes: {
    openModal: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    backend: PropTypes.string.isRequired,
    buckets: PropTypes.object.isRequired
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    return (
      <Modal show={this.props.openModal} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Register Your {ENTITY_MAP.get(this.props.backend)}</Modal.Title>
          {ICONS_MAP.has(this.props.backend) && (
            <ModalIcon iconUrl={ICONS_MAP.get(this.props.backend)} />
          )}
        </Modal.Header>
        {this.renderBody()}
      </Modal>
    );
  },

  renderBody() {
    if (this.state.step === FORM_STEPS.FORM) {
      return this.renderForm();
    }

    return this.renderRegister();
  },

  renderForm() {
    return (
      <>
        <Modal.Body>
          {this.renderError()}
          <p>Connect your {ENTITY_MAP.get(this.props.backend)} to a bucket in Keboola.</p>
          {this.renderFormFields()}
        </Modal.Body>
        <Modal.Footer>
          {HAS_GUIDE.includes(this.props.backend)
            ? this.renderNextButton()
            : this.renderSubmitButton()}
        </Modal.Footer>
      </>
    );
  },

  renderRegister() {
    return (
      <>
        <Modal.Body>
          {this.renderError()}
          <Markdown collapsible={false} source={this.state.guide} className="tw-mb-3" />
          {this.renderAdditionalRegisterFields()}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="block">
            <Button onClick={() => this.setState({ step: FORM_STEPS.FORM })}>
              <FontAwesomeIcon icon="arrow-left" fixedWidth />
            </Button>
            {this.renderSubmitButton()}
          </ButtonToolbar>
        </Modal.Footer>
      </>
    );
  },

  renderFormFields() {
    return (
      <>
        <FormGroup>
          <ControlLabel>Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.displayName}
            onChange={(event) => {
              this.setState({ displayName: string.sanitizeKbcTableIdString(event.target.value) });
            }}
          />
          <HelpBlock>{nameWarning}</HelpBlock>
        </FormGroup>
        {this.renderAdditionalFields()}
      </>
    );
  },

  renderAdditionalFields() {
    switch (this.props.backend) {
      case backends.BIGQUERY:
        return this.renderSimpleInput('Dataset', 'dataset');

      case backends.SNOWFLAKE:
        return (
          <>
            {this.renderSimpleInput('Database', 'database')}
            {this.renderSimpleInput('Schema', 'schema')}
          </>
        );

      case backends.TERADATA:
        return this.renderSimpleInput('Database', 'database');

      default:
        return null;
    }
  },

  renderAdditionalRegisterFields() {
    switch (this.props.backend) {
      case backends.BIGQUERY:
        return this.renderSimpleInput('URL', 'url');

      default:
        return null;
    }
  },

  renderSimpleInput(label, name) {
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          type="text"
          value={this.state.tempData.get(name, '')}
          onChange={(event) =>
            this.setState({ tempData: this.state.tempData.set(name, event.target.value) })
          }
        />
      </FormGroup>
    );
  },

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <Alert bsStyle="danger">{this.state.error}</Alert>;
  },

  renderSubmitButton() {
    return (
      <ConfirmButtons
        block
        isSaving={this.props.isSaving}
        isDisabled={this.isSubmitDisabled()}
        onSave={this.onSubmit}
        saveLabel={this.props.isSaving ? 'Registering bucket...' : 'Register bucket'}
      />
    );
  },

  renderNextButton() {
    return (
      <Button
        block
        bsStyle="success"
        onClick={() => {
          this.loadGuide().then(() => {
            this.setState({ step: FORM_STEPS.REGISTER });
          });
        }}
        disabled={this.isNextStepDisabled() || this.state.isLoadingGuide}
      >
        {this.state.isLoadingGuide ? 'Loading guide...' : 'Next Step'}
      </Button>
    );
  },

  onHide() {
    this.props.onHide();
    this.setState(INITIAL_STATE);
  },

  onSubmit(event) {
    event.preventDefault();

    return registerExternalBucket(
      this.props.backend,
      this.state.displayName,
      this.prepareRegisterPath()
    ).then(this.onHide, (message) => this.setState({ error: message }));
  },

  loadGuide() {
    this.setState({ isLoadingGuide: true });
    return StorageApi.getBucketRegistrationGuide({
      backend: this.props.backend,
      path: this.prepareGuidePath()
    })
      .then(
        (response) => this.setState({ guide: response.markdown }),
        (message) => this.setState({ error: message })
      )
      .finally(() => this.setState({ isLoadingGuide: false }));
  },

  prepareRegisterPath() {
    switch (this.props.backend) {
      case backends.BIGQUERY:
        return parseBigQueryDatasetUrl(this.state.tempData.get('url', ''));

      case backends.SNOWFLAKE:
        return [this.state.tempData.get('database'), this.state.tempData.get('schema')];

      case backends.TERADATA:
        return [this.state.tempData.get('database')];

      default:
        return [];
    }
  },

  prepareGuidePath() {
    switch (this.props.backend) {
      case backends.BIGQUERY:
        return [this.state.tempData.get('dataset')];

      case backends.SNOWFLAKE:
        return [this.state.tempData.get('database'), this.state.tempData.get('schema')];

      default:
        return [];
    }
  },

  isSubmitDisabled() {
    switch (this.props.backend) {
      case backends.BIGQUERY:
        return !this.state.tempData.get('url');

      default:
        return false;
    }
  },

  isNextStepDisabled() {
    if (!this.state.displayName || this.props.isSaving) {
      return true;
    }

    switch (this.props.backend) {
      case backends.BIGQUERY:
        return !this.state.tempData.get('dataset');

      case backends.SNOWFLAKE:
        return !this.state.tempData.get('database') || !this.state.tempData.get('schema');

      case backends.TERADATA:
        return !this.state.tempData.get('database');

      default:
        return false;
    }
  }
});

export default RegisterExternalBucketModal;
