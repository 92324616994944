import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Select from '../../../../../../react/common/Select';
import { REDSHIFT_COMPRESSION_OPTIONS } from './constants';

class RedshiftCompressionHeader extends React.Component {
  render() {
    return (
      <th>
        Compression
        <Dropdown id="column-editor-datatype" dropup pullRight>
          <Dropdown.Toggle noCaret bsStyle="link">
            <FontAwesomeIcon icon={['far', 'ellipsis']} className="text-muted" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-1">
            <Select
              clearable={false}
              deleteRemoves={false}
              backspaceRemoves={false}
              className="w-200"
              placeholder="Set all columns as..."
              options={REDSHIFT_COMPRESSION_OPTIONS.map((value) => ({ label: value, value }))}
              onChange={this.props.onChange}
            />
          </Dropdown.Menu>
        </Dropdown>
      </th>
    );
  }
}

RedshiftCompressionHeader.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default RedshiftCompressionHeader;
