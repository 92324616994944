import React from 'react';
import createReactClass from 'create-react-class';

import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import BillingStore from '../store';

const Pay = React.lazy(() => import(/* webpackChunkName: "billing-credits-pay" */ './Pay'));

const Credits = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, BillingStore)],

  getStateFromStores() {
    return {
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      stripePublishableKey: ApplicationStore.getStripePublishableKey(),
      creditPrice: BillingStore.getCreditPrice(),
      billingInformation: BillingStore.getBillingInformation(),
      purchases: BillingStore.getPurchases(),
      topUpSetting: BillingStore.getTopUpSetting()
    };
  },

  render() {
    return (
      <React.Suspense fallback={null}>
        <Pay
          user={this.state.currentAdmin}
          purchases={this.state.purchases}
          creditPrice={this.state.creditPrice}
          billingInformation={this.state.billingInformation}
          stripePublishableKey={this.state.stripePublishableKey}
          topUpSetting={this.state.topUpSetting}
        />
      </React.Suspense>
    );
  }
});

export default Credits;
