import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Map } from 'immutable';

import { KEBOOLA_DATA_APPS } from '../../../constants/componentIds';
import Confirm from '../../../react/common/Confirm';
import ConfirmMenuItem from '../../../react/common/ConfirmMenuItem';
import Loader from '../../../react/common/Loader';
import SandboxesActions from '../../sandboxes/Actions';

const TerminateDataApp = (props: {
  config: Map<string, any>;
  sandbox: Map<string, any>;
  mode: 'sidebar' | 'menuitem';
  isPending: boolean;
  readOnly: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}) => {
  const handleConfirm = () => {
    return SandboxesActions.terminateSandbox(
      props.sandbox.get('id'),
      props.config.get('id'),
      KEBOOLA_DATA_APPS
    );
  };

  const renderBody = () => {
    return (
      <p>
        Are you sure you want to terminate <strong>{props.config.get('name')}</strong> the data app?
      </p>
    );
  };

  if (!props.sandbox.get('active', false)) {
    return null;
  }

  if (props.mode === 'sidebar') {
    return (
      <Confirm
        icon="circle-pause"
        buttonType="danger"
        title="Terminate Data App"
        buttonLabel="Terminate Data App"
        childrenRootElement="a"
        text={renderBody()}
        onConfirm={handleConfirm}
        isDisabled={props.isPending || props.readOnly}
      >
        {props.isPending ? <Loader /> : <FontAwesomeIcon icon="circle-pause" fixedWidth />}
        Terminate Data App
      </Confirm>
    );
  }

  return (
    <ConfirmMenuItem
      icon="circle-pause"
      buttonType="danger"
      title="Terminate Data App"
      buttonLabel="Terminate Data App"
      text={renderBody()}
      onConfirm={handleConfirm}
      isLoading={props.isPending}
      isDisabled={props.isPending || props.readOnly}
      onKeyDown={props.onKeyDown}
      closeAfterResolve
    >
      {props.isPending ? <Loader /> : <FontAwesomeIcon icon="circle-pause" fixedWidth />}
      Terminate Data App
    </ConfirmMenuItem>
  );
};

export default TerminateDataApp;
