import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import Checkbox from '../../../../react/common/Checkbox';
import CsvDelimiterInput from '../../../../react/common/CsvDelimiterInput';
import Select from '../../../../react/common/Select';
import string from '../../../../utils/string';
import { nameWarning } from '../../../storage/constants';

const columnsFromOptions = [
  {
    label: 'Set the header manually',
    value: 'manual'
  },
  {
    label: 'Read the header from the file(s) header',
    value: 'header'
  },
  {
    label: 'Create the header automatically',
    value: 'auto'
  }
];

const Configuration = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      path: PropTypes.string.isRequired,
      decompress: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      incremental: PropTypes.bool.isRequired,
      delimiter: PropTypes.string.isRequired,
      enclosure: PropTypes.string.isRequired,
      columnsFrom: PropTypes.oneOf(['manual', 'header', 'auto']),
      columns: PropTypes.array.isRequired,
      primaryKey: PropTypes.array.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">Download Settings</h3>
          </div>
          <div className="box-content">{this.renderDownloadSettings()}</div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">Save Settings</h3>
          </div>
          <div className="box-content">{this.renderSaveSettings()}</div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">Header &amp; Primary Key</h3>
          </div>
          <div className="box-content">{this.renderHeaderPrimaryKey()}</div>
        </div>
      </>
    );
  },

  renderDownloadSettings() {
    return (
      <>
        <FormGroup>
          <Col componentClass={ControlLabel} xs={4}>
            Path
          </Col>
          <Col xs={8}>
            <FormControl
              type="text"
              value={this.props.value.path}
              onChange={(e) => {
                this.props.onChange({ path: e.target.value });
              }}
              placeholder="/myfolder/myfile.csv"
              disabled={this.props.disabled}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col xs={8} xsOffset={4}>
            <Checkbox
              checked={this.props.value.decompress}
              onChange={(checked) => this.props.onChange({ decompress: checked })}
              disabled={this.props.disabled}
            >
              Decompress
            </Checkbox>
            <HelpBlock>
              Decompress the downloaded file(s). All files in all archives will be imported into a
              single Storage table.
            </HelpBlock>
          </Col>
        </FormGroup>
      </>
    );
  },

  renderSaveSettings() {
    return (
      <>
        <FormGroup>
          <Col componentClass={ControlLabel} xs={4}>
            Table Name
          </Col>
          <Col xs={8}>
            <FormControl
              type="text"
              value={this.props.value.name}
              onChange={(e) => {
                this.props.onChange({ name: string.sanitizeKbcTableIdString(e.target.value) });
              }}
              placeholder="mytable"
              disabled={this.props.disabled}
            />
            <HelpBlock>Name of the table stored in Storage. {nameWarning}</HelpBlock>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col xs={8} xsOffset={4}>
            <Checkbox
              checked={this.props.value.incremental}
              onChange={(checked) => this.props.onChange({ incremental: checked })}
              disabled={this.props.disabled}
            >
              Incremental Load
            </Checkbox>
            <HelpBlock>
              If incremental load is turned on, the table will be updated instead of rewritten.{' '}
              Tables with a primary key will have rows updated, tables without a primary key will
              have rows appended.
            </HelpBlock>
          </Col>
        </FormGroup>
        <CsvDelimiterInput
          type="text"
          value={this.props.value.delimiter}
          onChange={(value) => {
            this.props.onChange({ delimiter: value });
          }}
          disabled={this.props.disabled}
        />
        <FormGroup>
          <Col componentClass={ControlLabel} xs={4}>
            Enclosure
          </Col>
          <Col xs={8}>
            <FormControl
              type="text"
              value={this.props.value.enclosure}
              onChange={(e) => {
                this.props.onChange({ enclosure: e.target.value });
              }}
              placeholder={'"'}
              disabled={this.props.disabled}
            />
            <HelpBlock>
              Field enclosure used in the CSV file. The default value is <code>&quot;</code>.
            </HelpBlock>
          </Col>
        </FormGroup>
      </>
    );
  },

  renderHeaderPrimaryKey() {
    return (
      <>
        <div className="form-group">
          <label className="col-xs-4 control-label">Read Header</label>
          <div className="col-xs-8">
            <Select
              searchable={false}
              clearable={false}
              disabled={this.props.disabled}
              options={columnsFromOptions}
              value={this.props.value.columnsFrom}
              onChange={(selected) => {
                let diff = { columnsFrom: selected };
                if (selected !== 'manual') {
                  diff.columns = [];
                }
                this.props.onChange(diff);
              }}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4 control-label">Set Header</label>
          <div className="col-xs-8">
            <Select
              multi
              allowCreate
              placeholder="Add a column"
              disabled={this.props.value.columnsFrom !== 'manual' || this.props.disabled}
              value={this.props.value.columns}
              onChange={(values) => this.props.onChange({ columns: values })}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4 control-label">Primary Key</label>
          <div className="col-xs-8">
            <Select
              multi
              allowCreate
              placeholder="Add a column to the primary key"
              disabled={this.props.disabled}
              value={this.props.value.primaryKey}
              onChange={(values) => this.props.onChange({ primaryKey: values })}
            />
            <div className="help-block">
              If a primary key is set, updates can be done on the table by selecting{' '}
              <strong>incremental loads</strong>. The primary key can consist of multiple columns.
              The primary key of an existing table cannot be changed.
            </div>
          </div>
        </div>
      </>
    );
  }
});

export default Configuration;
