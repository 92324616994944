import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Map } from 'immutable';

import ComponentWithIconAndType from '../../../../react/common/ComponentWithIconAndType';
import CreatedDate from '../../../../react/common/CreatedDate';
import JobDuration from '../../../../react/common/JobDuration';
import JobStatusLabel from '../../../../react/common/JobStatusLabel';
import Link from '../../../../react/common/RouterLink';
import * as jobsResolver from '../../../jobs/JobComponentResolver';
import { routeNames as queueRouteNames } from '../../../queue/constants';
import { isPhaseJob } from '../../../queue/helpers';
import * as queueResolver from '../../../queue/jobResolver';

class LatestActivityJob extends React.Component {
  render() {
    const component = this.props.hasNewQueue
      ? queueResolver.getComponentByJob(this.props.job)
      : jobsResolver.getComponentByJob(this.props.job);
    const config = this.props.hasNewQueue
      ? queueResolver.getConfigurationName(this.props.job)
      : jobsResolver.getConfigurationName(this.props.job);
    const isError = this.props.job.get('status') === 'error';

    return (
      <Link
        className="tr"
        to={this.props.hasNewQueue ? queueRouteNames.JOB_DETAIL : 'jobDetail'}
        params={{ jobId: this.props.job.get('id') }}
      >
        <div className={classnames('td', { 'bg-danger': isError })}>
          <ComponentWithIconAndType
            component={component}
            isPhase={isPhaseJob(this.props.job)}
            nameSuffix={config && `: ${config}`}
          />
        </div>
        <div className={classnames('td w-200 text-right text-muted', { 'bg-danger': isError })}>
          <JobDuration
            status={this.props.job.get('status')}
            startTime={this.props.job.get('startTime')}
            endTime={this.props.job.get('endTime')}
          />
        </div>
        <div className={classnames('td w-200 text-right text-muted', { 'bg-danger': isError })}>
          <CreatedDate createdTime={this.props.job.get('createdTime')} />
        </div>
        <div className={classnames('td w-100', { 'bg-danger': isError })}>
          <JobStatusLabel status={this.props.job.get('status')} />
        </div>
      </Link>
    );
  }
}

LatestActivityJob.propTypes = {
  job: PropTypes.instanceOf(Map).isRequired,
  hasNewQueue: PropTypes.bool.isRequired
};

export default LatestActivityJob;
