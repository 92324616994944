import React from 'react';
import { Button, Panel, Table } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import gfm from 'remark-gfm';

import Clipboard from './Clipboard';
import CodeEditor from './CodeEditor';
import ExternalLink from './ExternalLink';

const Markdown = ({
  source,
  collapsible = true,
  overflow = true,
  className,
  children
}: {
  source?: string;
  collapsible?: boolean;
  className?: string;
  overflow?: boolean;
  children?: (markdown: React.ReactNode) => React.ReactNode;
}) => {
  const [ellipseContent, setEllipseContent] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const height = containerRef.current?.offsetHeight || 0;
    setEllipseContent(collapsible && !!source && height >= 300);
  }, [collapsible, source]);

  const markdownContent = (
    <ReactMarkdown
      skipHtml
      remarkPlugins={[gfm]}
      components={{
        a: (props) => (
          <ExternalLink
            href={props.href || ''}
            className={props.className}
            onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
          >
            {props.children}
          </ExternalLink>
        ),
        table: (props) => {
          return (
            <Table responsive bordered className="max-h-500">
              {props.children}
            </Table>
          );
        },
        pre: (props) => {
          if (
            props.children.length === 1 &&
            React.isValidElement(props.children[0]) &&
            props.children[0]?.props?.className === 'language-snowflake'
          ) {
            const text = String(props.children[0].props.children).trim();

            return (
              <div className="mbp-4">
                <div className="flex-container f-14 line-height-20 mbp-1 mtp-4">
                  <span className="font-medium">Code</span>
                  <Clipboard
                    text={text}
                    label="Copy code"
                    className="dark muted"
                    tooltipPlacement="top"
                  />
                </div>
                <CodeEditor
                  value={text}
                  options={{ mode: 'text/x-sfsql', readOnly: true, cursorHeight: 0 }}
                />
              </div>
            );
          }

          return <pre>{props.children}</pre>;
        }
      }}
    >
      {source || ''}
    </ReactMarkdown>
  );

  return (
    <div
      ref={containerRef}
      className={classnames('markdown', { 'overflow-break-anywhere': overflow }, className)}
    >
      {collapsible && ellipseContent ? (
        <Panel
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
          className="panel-show-details panel-show-details-bottom panel-preview"
        >
          <Panel.Heading
            className={classnames('flex-container justify-center', { collapsed: !expanded })}
          >
            <Button
              bsStyle="link"
              className="btn-link-inline dark muted"
              onClick={() => {
                setExpanded(!expanded);
                (document.activeElement as HTMLElement | null)?.blur();
              }}
            >
              <FontAwesomeIcon className="mrp-2" icon={expanded ? 'angle-up' : 'angle-down'} />
              {expanded ? 'Show less' : 'Show more'}
            </Button>
          </Panel.Heading>
          <Panel.Body collapsible>
            {children ? children(markdownContent) : markdownContent}
          </Panel.Body>
        </Panel>
      ) : children ? (
        children(markdownContent)
      ) : (
        markdownContent
      )}
    </div>
  );
};

export default Markdown;
