import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { fromJS } from 'immutable';
import _ from 'underscore';

import dayjs, { TIME_FORMAT } from '../../../../date';
import CronScheduler from '../../../../react/common/CronScheduler';
import CronSchedulerPreview from '../../../../react/common/CronSchedulerPreview';
import Loader from '../../../../react/common/Loader';
import ModalIcon from '../../../../react/common/ModalIcon';
import Select from '../../../../react/common/Select';
import StorageApi from '../../../components/StorageApi';
import VersionsActionCreators from '../../../components/VersionsActionCreators';
import { createTrigger, deleteTrigger, updateTrigger } from '../../../orchestrations-v2/actions';
import EventTrigger from '../../../orchestrations-v2/components/EventTrigger';
import SchedulePredefinedButtons from '../../../orchestrations-v2/components/SchedulePredefinedButtons';
import ScheduleTimezone from '../../../orchestrations-v2/components/ScheduleTimezone';
import { getPeriodForCrontab } from '../../../orchestrations-v2/helpers';
import actionCreators from '../../ActionCreators';
import { ORCHESTRATION_INVOKE_TYPE } from '../../Constants';
import OrchestrationsApi from '../../OrchestrationsApi';

const componentId = 'orchestrator';
const triggerDefault = { tables: [], coolDownPeriodMinutes: 5 };
const crontabDefault = () => `${_.sample([5, 15, 25, 35, 45, 55])} ${_.random(1, 23)} * * *`;

const Schedule = createReactClass({
  propTypes: {
    orchestrationId: PropTypes.number.isRequired,
    crontabRecord: PropTypes.string,
    crontabTimezone: PropTypes.string,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    trigger: PropTypes.object,
    isDisabled: PropTypes.bool.isRequired
  },

  getInitialState() {
    const crontabRecord = this.props.crontabRecord || crontabDefault();

    return {
      crontabRecord,
      cronPeriod: getPeriodForCrontab(crontabRecord),
      crontabTimezone: this.props.crontabTimezone || null,
      isSaving: false,
      showModal: false,
      invokeType: this.props.trigger.count()
        ? ORCHESTRATION_INVOKE_TYPE.EVENT
        : ORCHESTRATION_INVOKE_TYPE.TIME,
      trigger: this.props.trigger.count() ? this.props.trigger.toJS() : triggerDefault
    };
  },

  render() {
    return (
      <>
        {this.renderOpenButton()}
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header className="no-border" closeButton>
            <Modal.Title>Orchestration Schedule</Modal.Title>
            <ModalIcon icon="clock" color="green" bold />
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="schedule-form">
              {this.renderInvokeSelect()}
              {this.renderScheduleBody()}
            </div>
          </Modal.Body>
          <Modal.Footer>{this.renderButtons()}</Modal.Footer>
        </Modal>
      </>
    );
  },

  renderButtons() {
    if (this.state.invokeType === ORCHESTRATION_INVOKE_TYPE.TIME) {
      return this.renderSchedulerButtons();
    }

    return this.renderEventTriggerButtons();
  },

  renderScheduleBody() {
    if (this.state.invokeType === ORCHESTRATION_INVOKE_TYPE.TIME) {
      return (
        <>
          <SchedulePredefinedButtons
            onSelect={(crontabRecord) => {
              this.setState({
                crontabRecord,
                cronPeriod: getPeriodForCrontab(crontabRecord),
                invokeType: ORCHESTRATION_INVOKE_TYPE.TIME
              });
            }}
          />
          <CronScheduler
            period={this.state.cronPeriod}
            crontabRecord={this.state.crontabRecord}
            crontabTimezone={this.state.crontabTimezone}
            onChange={(crontabRecord, cronPeriod) => {
              this.setState({ crontabRecord, ...(cronPeriod && { cronPeriod }) });
            }}
          />
          <div className="flex-container text-muted">
            <span>Current time is {dayjs.utc().format(TIME_FORMAT)} UTC.</span>
            <ScheduleTimezone
              onChange={(crontabTimezone) => this.setState({ crontabTimezone })}
              crontabTimezone={this.state.crontabTimezone}
            />
          </div>
          <CronSchedulerPreview
            crontabRecord={this.state.crontabRecord}
            timezone={this.state.crontabTimezone || 'UTC'}
          />
        </>
      );
    }

    return (
      <EventTrigger
        tables={this.props.tables}
        buckets={this.props.buckets}
        selected={this.state.trigger.tables.map((item) => Object.values(item)).flat()}
        period={this.state.trigger.coolDownPeriodMinutes.toString()}
        onAddTable={this.handleTriggerTableAdd}
        onRemoveTable={this.handleTriggerTableRemove}
        onChangePeriod={this.handleTriggerPeriodChange}
      />
    );
  },

  renderInvokeSelect() {
    return (
      <div className="form-group">
        <Select
          searchable={false}
          clearable={false}
          options={[
            { label: 'Date & Time', value: ORCHESTRATION_INVOKE_TYPE.TIME },
            { label: 'Triggered', value: ORCHESTRATION_INVOKE_TYPE.EVENT }
          ]}
          value={this.state.invokeType}
          onChange={(selected) => this.setState({ invokeType: selected })}
          disabled={this.state.isSaving}
          className="schedule-type-select"
        />
      </div>
    );
  },

  renderSchedulerButtons() {
    return (
      <ButtonToolbar className="block">
        <Button
          bsStyle="danger"
          onClick={this.handleRemoveSchedule}
          disabled={this.state.isSaving || !this.props.crontabRecord}
        >
          Remove Schedule
        </Button>
        <Button
          type="submit"
          bsStyle="success"
          onClick={this.handleSave}
          disabled={this.state.isSaving}
        >
          {this.state.isSaving ? (
            <Loader className="icon-addon-right" />
          ) : (
            <FontAwesomeIcon icon="arrow-right" className="icon-addon-right" />
          )}
          Set Up Schedule
        </Button>
      </ButtonToolbar>
    );
  },

  renderEventTriggerButtons() {
    return (
      <ButtonToolbar className="block">
        <Button
          bsStyle="danger"
          onClick={this.handleRemoveTrigger}
          disabled={this.state.isSaving || !this.state.trigger.id}
        >
          Remove Trigger
        </Button>
        <Button
          type="submit"
          bsStyle="success"
          onClick={this.handleTriggerSave}
          disabled={
            this.state.isSaving ||
            !this.state.trigger.tables.length ||
            this.getPeriodValidation() === 'error'
          }
        >
          {this.state.isSaving ? (
            <Loader className="icon-addon-right" />
          ) : (
            <FontAwesomeIcon icon="arrow-right" className="icon-addon-right" />
          )}
          Set Up Schedule
        </Button>
      </ButtonToolbar>
    );
  },

  renderOpenButton() {
    if (this.props.isDisabled) {
      return null;
    }

    return (
      <Button
        bsStyle="link"
        className="btn-link-inline btn-link-muted icon-addon-left"
        onClick={this.openModal}
      >
        <FontAwesomeIcon icon="pen" />
      </Button>
    );
  },

  closeModal() {
    this.setState({ showModal: false });
  },

  openModal() {
    const crontabRecord = this.props.crontabRecord || crontabDefault();

    this.setState({
      crontabRecord,
      showModal: true,
      cronPeriod: getPeriodForCrontab(crontabRecord),
      crontabTimezone: this.props.crontabTimezone || null,
      invokeType: this.props.trigger.count()
        ? ORCHESTRATION_INVOKE_TYPE.EVENT
        : ORCHESTRATION_INVOKE_TYPE.TIME,
      trigger: this.props.trigger.count() ? this.props.trigger.toJS() : triggerDefault
    });
  },

  getPeriodValidation() {
    const period = parseInt(this.state.trigger.coolDownPeriodMinutes);
    return !Number.isInteger(period) || period < 5 ? 'error' : null;
  },

  handleSave() {
    const data = {
      crontabRecord: this.state.crontabRecord,
      crontabTimezone: this.state.crontabTimezone
    };

    this.setState({ isSaving: true });
    if (this.state.trigger.id) {
      return deleteTrigger(this.state.trigger.id).then(() => this.saveSchedule(data));
    }

    return this.saveSchedule(data);
  },

  saveSchedule(data) {
    this.setState({ isSaving: true });
    return OrchestrationsApi.updateOrchestration(this.props.orchestrationId, data).then(
      this.handleSaveSuccess
    );
  },

  handleSaveSuccess(response) {
    return VersionsActionCreators.loadVersionsForce(
      componentId,
      this.props.orchestrationId.toString()
    )
      .then(() => actionCreators.receiveOrchestration(response))
      .then(this.closeModal)
      .finally(() => {
        this.setState({ isSaving: false });
      });
  },

  saveTrigger(data) {
    if (this.state.trigger.id) {
      return updateTrigger(this.state.trigger.id, data);
    }

    return StorageApi.createToken({
      canManageBuckets: false,
      canReadAllFileUploads: false,
      componentAccess: [componentId],
      description: `Token for triggering an orchestrator`
    }).then((token) =>
      createTrigger({
        component: componentId,
        configurationId: this.props.orchestrationId,
        runWithTokenId: token.id,
        ...data
      })
    );
  },

  handleTriggerSave() {
    const data = {
      tableIds: this.state.trigger.tables.map((item) => item.tableId),
      coolDownPeriodMinutes: this.state.trigger.coolDownPeriodMinutes
    };

    this.setState({ isSaving: true });
    this.saveTrigger(data).then(this.handleRemoveSchedule);
  },

  handleRemoveSchedule() {
    this.saveSchedule({
      crontabRecord: null,
      cronPeriod: null,
      crontabTimezone: null
    });
  },

  handleRemoveTrigger() {
    this.setState({ isSaving: true });
    deleteTrigger(this.state.trigger.id)
      .then(this.closeModal)
      .finally(() => {
        this.setState({ isSaving: false });
      });
  },

  handleTriggerTableAdd(tableId) {
    let trigger = fromJS(this.state.trigger);

    this.setState({
      trigger: trigger.set('tables', trigger.get('tables').push({ tableId })).toJS()
    });
  },

  handleTriggerTableRemove(tableId) {
    let trigger = fromJS(this.state.trigger);
    const tables = trigger.get('tables').filter((item) => item.get('tableId') !== tableId);

    this.setState({
      trigger: trigger.set('tables', tables).toJS()
    });
  },

  handleTriggerPeriodChange(event) {
    const value = event.target.value;
    const valueInt = Number.isInteger(parseInt(value)) ? parseInt(value) : value.toString();

    this.setState({
      trigger: fromJS(this.state.trigger).set('coolDownPeriodMinutes', valueInt).toJS()
    });
  }
});

export default Schedule;
