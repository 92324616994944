import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import * as componentFlags from '../../../../constants/componentFlags';
import {
  KEBOOLA_DATABRICKS_TRANSFORMATION,
  KEBOOLA_EX_SAMPLE_DATA,
  KEBOOLA_EXASOL_TRANSFORMATION,
  KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
  KEBOOLA_ORACLE_TRANSFORMATION,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_REDSHIFT_TRANSFORMATION,
  KEBOOLA_SANDBOXES,
  KEBOOLA_SHARED_CODE,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_SYNAPSE_TRANSFORMATION,
  KEBOOLA_TERADATA_TRANSFORMATION,
  KEBOOLA_VARIABLES
} from '../../../../constants/componentIds';
import { componentTypes } from '../../../../constants/componentTypes';
import {
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_SANDBOXES_PYTHON_MLFLOW
} from '../../../../constants/features';
import CatchUnsavedChanges from '../../../../react/common/CatchUnsavedChanges';
import CatchUnsavedRunWarning from '../../../../react/common/CatchUnsavedRunWarning';
import CollapsibleBox from '../../../../react/common/CollapsibleBox';
import ConfigurationInfoPanel from '../../../../react/common/ConfigurationInfoPanel';
import { getFakeComponentId } from '../../../../react/common/ConfigurationsTable/helpers';
import ConfigurationTabs from '../../../../react/common/ConfigurationTabs';
import Markdown from '../../../../react/common/Markdown';
import Link from '../../../../react/common/RouterLink';
import Sidebar from '../../../../react/layout/Sidebar';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import contactSupport from '../../../../utils/contactSupport';
import { isValidJsonConfig } from '../../../../utils/validation';
import { canResetState } from '../../../admin/privileges';
import ConfigurationRowsStore from '../../../configurations/ConfigurationRowsStore';
import ClearStateButton from '../../../configurations/react/components/ClearStateButton';
import { isEmptyComponentState } from '../../../configurations/utils/configurationState';
import DevBranchesStore from '../../../dev-branches/DevBranchesStore';
import ModelsStore from '../../../model-services/ModelsStore';
import * as oauthUtils from '../../../oauth-v2/OauthUtils';
import AuthorizationRow from '../../../oauth-v2/react/AuthorizationRow';
import OauthStore from '../../../oauth-v2/Store';
import GenericPatternUi from '../../../pattern/react/GenericPatternUi';
import { ONLY_READONLY_STORAGE } from '../../../sandboxes/Constants';
import {
  hasSandbox,
  prepareSandboxes,
  resolveSandboxTypeFromComponentId
} from '../../../sandboxes/helpers';
import SandboxesStore from '../../../sandboxes/SandboxesStore';
import StackFeaturesStore from '../../../stack-features/Store';
import { prepareTablesMetadataMap } from '../../../storage/helpers';
import Packages from '../../../transformations/react/pages/transformation-detail/Packages';
import { routeNames as transformationRoutes } from '../../../transformations-v2/constants';
import { features as componentFeatures } from '../../Constants';
import {
  getAllowedTransformations,
  getDestinationTypeFromStagingStorage,
  getSourceTypeFromStagingStorage,
  hasDevModeProblematicMapping,
  hasGenericCodeBlocksUI,
  hasGenericDockerUI,
  hasGenericPackagesUI,
  hasGenericTemplatesUI,
  hasGenericUI,
  hasGenericVariablesUI,
  prepareCreatedFromMetadata,
  supportsDynamicBackendSize
} from '../../helpers';
import InstalledComponentsActionCreators from '../../InstalledComponentsActionCreators';
import ComponentsStore from '../../stores/ComponentsStore';
import InstalledComponentsStore from '../../stores/InstalledComponentsStore';
import StorageBucketsStore from '../../stores/StorageBucketsStore';
import StorageTablesStore from '../../stores/StorageTablesStore';
import VersionsStore from '../../stores/VersionsStore';
import BackendSizeSelect from '../components/BackendSizeSelect';
import CodeBlocksHeader from '../components/CodeBlocksHeader';
import ComponentDescription from '../components/ComponentDescription';
import Configuration from '../components/Configuration';
import CreateWorkspaceButton from '../components/CreateWorkspaceButton';
import DatabricksParameters from '../components/DatabricksParameters';
import CodeBlocksConfiguration from '../components/generic/code-blocks/CodeBlocksConfiguration';
import FileInputMapping from '../components/generic/FileInputMapping';
import FileOutputMapping from '../components/generic/FileOutputMapping';
import TableInputMapping from '../components/generic/TableInputMapping';
import TableInputMappingReadOnlyInfo from '../components/generic/TableInputMappingReadOnlyInfo';
import TableOutputMapping from '../components/generic/TableOutputMapping';
import {
  getMissingSharedCodesVariables,
  prepareVariables
} from '../components/generic/variables/helpers';
import Variables from '../components/generic/variables/Variables';
import VariablesOverridePanel from '../components/generic/variables/VariablesOverridePanel';
import GenericSyncActionsButton, {
  hasSingleSyncAction,
  removeSingleSyncActionFromSchema
} from '../components/GenericSyncActionsButton';
import LinkedModelsBox from '../components/LinkedModelsBox';
import MappingsWrapper from '../components/MappingsWrapper';
import Processors from '../components/Processors';
import QueryTimeoutModal from '../components/QueryTimeoutModal';
import SampleDataDetail from '../components/SampleDataDetail';
import TemplatedConfiguration from '../components/TemplatedConfiguration';
import FileInputMappingOverview from './file-input-mapping/Overview';
import GenericRows from './GenericRows';

const GenericDetail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      StackFeaturesStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
      OauthStore,
      VersionsStore,
      ConfigurationRowsStore,
      SandboxesStore,
      ModelsStore
    )
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config'),
      componentId = RoutesStore.getCurrentRouteParam('component'),
      localState = InstalledComponentsStore.getLocalState(componentId, configId),
      configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const sapiToken = ApplicationStore.getSapiToken();
    const allConfigurations = InstalledComponentsStore.getAll();
    const componentsMetadata = InstalledComponentsStore.getAllMetadata();
    const sharedCodes = InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SHARED_CODE);
    const componentSharedCodes = sharedCodes.find(
      (config) => config.getIn(['configuration', 'componentId']) === componentId,
      null,
      Map()
    );
    const allSharedCodes = componentSharedCodes.get('rows', List());
    const variables = InstalledComponentsStore.getComponentConfigurations(KEBOOLA_VARIABLES);
    const variablesConfigData = variables.get(configData.get('variables_id'), Map());
    const variablesConfiguration = variablesConfigData.get('configuration', Map());
    const variablesConfigurationRow = variablesConfigData.get('rows', List()).find(
      (row) => {
        return row.get('id') === configData.get('variables_values_id', null);
      },
      null,
      Map()
    );
    const preparedVariables = prepareVariables(allConfigurations, componentId, configId);
    const editingConfigDataParameters = InstalledComponentsStore.getEditingRawConfigDataParameters(
      componentId,
      configId,
      configData.hasIn(['runtime', 'codePattern', 'componentId'])
        ? configData.getIn(['runtime', 'codePattern', 'parameters'], Map())
        : configData.get('parameters', Map())
    );
    const allowedTransformationComponents = getAllowedTransformations(
      ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
      sapiToken,
      ApplicationStore.getCurrentProjectFeatures(),
      StackFeaturesStore.getAll()
    );
    const component = ComponentsStore.getComponent(componentId);

    return {
      componentId,
      configId,
      component,
      createdFrom: prepareCreatedFromMetadata(
        componentId,
        configId,
        allConfigurations,
        componentsMetadata
      ),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      hasFlows: ApplicationStore.hasFlows(),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
      versions: VersionsStore.getVersions(componentId, configId),
      configData: configData,
      rows: ConfigurationRowsStore.getRows(componentId, configId),
      editingConfigData: InstalledComponentsStore.getEditingRawConfigData(componentId, configId),
      editingConfigDataObject: InstalledComponentsStore.getEditingConfigDataObject(
        componentId,
        configId
      ),
      config: InstalledComponentsStore.getConfig(componentId, configId),
      isChanged: InstalledComponentsStore.isChangedRawConfigData(componentId, configId),
      isTemplatedChanged: InstalledComponentsStore.isChangedTemplatedConfig(componentId, configId),
      isParametersChanged: InstalledComponentsStore.isChangedRawConfigDataParameters(
        componentId,
        configId
      ),
      isSaving: InstalledComponentsStore.isSavingConfigData(componentId, configId),
      isParametersSaving: InstalledComponentsStore.isSavingConfigDataParameters(
        componentId,
        configId
      ),
      editingConfigDataParameters,
      isValidEditingConfigData: InstalledComponentsStore.isValidEditingConfigData(
        componentId,
        configId
      ),
      isValidEditingConfigDataParameters: isValidJsonConfig(editingConfigDataParameters),
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      pendingActions: InstalledComponentsStore.getPendingActions(componentId, configId),
      localState: localState,
      oauthCredentials: OauthStore.getCredentials(componentId, configData),
      componentSharedCodes,
      sharedCodes: allSharedCodes,
      variablesConfiguration: variablesConfiguration,
      variablesConfigurationRow: variablesConfigurationRow,
      preparedVariables,
      missingVariables: getMissingSharedCodesVariables(
        configData,
        preparedVariables,
        allSharedCodes,
        variables
      ),
      allComponents: ComponentsStore.getAll(),
      allConfigurations,
      componentsMetadata,
      allowedTransformationComponents,
      allowedCreateWorkspace: allowedTransformationComponents.filter((component, componentId) =>
        hasSandbox(componentId)
      ),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      isSingleTenant: StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT),
      hasModelsAvailable: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SANDBOXES_PYTHON_MLFLOW
      ),
      allModels: ModelsStore.getAll(),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES)
      ),
      sandboxComponent: ComponentsStore.getComponent(KEBOOLA_SANDBOXES),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      hasSnowflakeDynamicBackendSize: ApplicationStore.hasSnowflakeDynamicBackendSize(),
      hasJobsDynamicBackendSize: ApplicationStore.hasJobsDynamicBackendSize(),
      availableDatabricksWorkspaceClusters: InstalledComponentsStore.getLocalState(
        KEBOOLA_SANDBOXES,
        null
      ).get('clusters'),
      canResetState: canResetState(sapiToken, component)
    };
  },

  getInitialState() {
    return {
      variablesOverride: Map()
    };
  },

  render() {
    return (
      <>
        <ConfigurationTabs
          componentId={this.state.componentId}
          configId={this.state.configId}
          config={this.state.config}
          {...(this.state.component.get('type') === componentTypes.TRANSFORMATION && {
            versionsLinkTo: transformationRoutes.GENERIC_TRANSFORMATION_VERSIONS,
            notificationsLinkTo: transformationRoutes.GENERIC_TRANSFORMATION_NOTIFICATIONS
          })}
        />
        <ConfigurationInfoPanel
          component={this.state.component}
          realComponent={this.state.allComponents.get(
            getFakeComponentId(this.state.configData) || this.state.componentId
          )}
          config={this.state.config}
          hasFlows={this.state.hasFlows}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.tables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row box-separator">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={this.state.componentId}
              configId={this.state.configId}
              placeholderEntity={this.state.component.get('type')}
            />
            {this.renderBody()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={this.state.componentId}
              configId={this.state.configId}
              run={{
                forceModal:
                  this.hasPreparedVariables() ||
                  this.hasDevModeProblematicMapping() ||
                  this.state.isParametersChanged ||
                  this.state.isTemplatedChanged,
                params: this.runParams(),
                title:
                  this.state.component.get('type') === componentTypes.TRANSFORMATION
                    ? 'Run transformation'
                    : 'Run component',
                text: (
                  <>
                    {(this.state.isParametersChanged || this.state.isTemplatedChanged) && (
                      <CatchUnsavedRunWarning />
                    )}
                    <p>
                      You are about to run the{' '}
                      {this.state.component.get('type') === componentTypes.TRANSFORMATION
                        ? 'transformation'
                        : 'component'}
                      .
                    </p>
                    {this.renderDevModeWarning()}
                    {this.renderVariablesOverride()}
                  </>
                )
              }}
              additionalButtons={this.additionalButtons()}
              createdFromConfiguration={this.state.createdFrom}
            />
          </div>
        </div>
      </>
    );
  },

  additionalButtons() {
    const additionalButtons = [];

    if (
      supportsDynamicBackendSize(this.state.component) &&
      this.state.hasNewQueue &&
      !this.state.readOnly
    ) {
      additionalButtons.push(
        <BackendSizeSelect
          componentId={this.state.componentId}
          currentSize={this.state.config.getIn(['configuration', 'runtime', 'backend', 'type'])}
          changeBackendSize={(newBackendSize) =>
            InstalledComponentsActionCreators.updateComponentConfiguration(
              this.state.componentId,
              this.state.config.get('id'),
              {
                configuration: JSON.stringify(
                  this.state.config
                    .get('configuration')
                    .setIn(['runtime', 'backend', 'type'], newBackendSize)
                    .toJS()
                )
              },
              'Change transformation backend size'
            )
          }
          hasPayAsYouGo={this.state.hasPayAsYouGo}
          hasSnowflakeDynamicBackendSize={this.state.hasSnowflakeDynamicBackendSize}
          hasJobsDynamicBackendSize={this.state.hasJobsDynamicBackendSize}
          isSingleTenant={this.state.isSingleTenant}
        />
      );
    }

    if (
      [
        KEBOOLA_SNOWFLAKE_TRANSFORMATION,
        KEBOOLA_REDSHIFT_TRANSFORMATION,
        KEBOOLA_SYNAPSE_TRANSFORMATION,
        KEBOOLA_EXASOL_TRANSFORMATION,
        KEBOOLA_TERADATA_TRANSFORMATION,
        KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION
      ].includes(this.state.componentId)
    ) {
      additionalButtons.push(
        <QueryTimeoutModal
          config={this.state.config}
          componentId={this.state.componentId}
          defaultTimeout={this.state.component.getIn([
            'data',
            'image_parameters',
            'default_query_timeout'
          ])}
          readOnly={this.state.readOnly}
        />
      );
    }

    if (this.state.componentId === KEBOOLA_ORACLE_TRANSFORMATION) {
      additionalButtons.push(
        <Link
          to="transformation-credentials"
          params={{
            component: this.state.componentId,
            config: this.state.configId
          }}
        >
          <FontAwesomeIcon icon="user" fixedWidth />
          Database Credentials
        </Link>
      );
    }

    if (!this.state.readOnly && this.state.allowedCreateWorkspace.has(this.state.componentId)) {
      additionalButtons.push(
        <CreateWorkspaceButton
          key="create-workspace"
          componentId={this.state.componentId}
          sandboxComponent={this.state.sandboxComponent}
          allowedComponents={this.state.allowedCreateWorkspace}
          config={this.state.config}
          hasPayAsYouGo={this.state.hasPayAsYouGo}
          workspaces={this.state.sandboxes}
          availableDatabricksClusters={this.state.availableDatabricksWorkspaceClusters}
          metadata={this.state.componentsMetadata}
        />
      );
    }

    if (this.state.canResetState) {
      additionalButtons.push(
        <ClearStateButton
          key="clear-state"
          onClick={this.onResetState}
          disabled={isEmptyComponentState(this.state.config.get('state', Map()))}
        />
      );
    }

    if (hasSingleSyncAction(this.state.component.get('configurationSchema'))) {
      additionalButtons.push(
        <GenericSyncActionsButton
          key="sync-actions-button"
          configId={this.state.configId}
          componentId={this.state.componentId}
          schema={this.state.component.get('configurationSchema')}
        />
      );
    }

    return additionalButtons;
  },

  renderBody() {
    if (this.state.componentId === KEBOOLA_EX_SAMPLE_DATA) {
      return (
        <SampleDataDetail
          allTables={this.state.tables}
          component={this.state.allComponents.get(
            this.state.configData.getIn(['parameters', 'componentId'])
          )}
          name={this.state.config.get('name')}
          hasNewQueue={this.state.hasNewQueue}
          configurationId={this.state.configId}
        />
      );
    }

    if (this.state.configData.hasIn(['runtime', 'codePattern', 'componentId'])) {
      const patternComponent = this.state.allComponents.get(
        this.state.configData.getIn(['runtime', 'codePattern', 'componentId'])
      );

      return (
        <CatchUnsavedChanges
          isDirty={this.state.isParametersChanged}
          onSave={this.onGenericPatternSubmit}
          isSaveDisabled={!this.state.isValidEditingConfigDataParameters}
          onDirtyLeave={this.onEditParametersCancel}
        >
          <GenericPatternUi
            readOnly={this.state.readOnly}
            configId={this.state.configId}
            component={this.state.component}
            tables={this.state.tables}
            buckets={this.state.buckets}
            pendingActions={this.state.pendingActions}
            config={this.state.config}
            configData={this.state.configData}
            editingConfigData={this.state.editingConfigDataObject}
            pattern={patternComponent}
            parameters={this.state.editingConfigDataParameters}
            parametersIsChanged={this.state.isParametersChanged}
            parametersIsSaving={this.state.isParametersSaving}
            parametersOnEditCancel={this.onEditParametersCancel}
            parametersOnEditChange={this.onEditParametersChange}
            parametersOnEditSubmit={this.onGenericPatternSubmit}
            parametersIsValid={this.state.isValidEditingConfigDataParameters}
            isDevModeActive={this.state.isDevModeActive}
            allComponents={this.state.allComponents}
            allowedComponents={this.state.allowedTransformationComponents}
            sandboxes={this.state.sandboxes}
            hasPayAsYouGo={this.state.hasPayAsYouGo}
          />
        </CatchUnsavedChanges>
      );
    }

    return (
      <>
        {this.renderConfigurationDescription()}
        {this.accountAuthorization()}
        {this.renderComponentSpecificInfo()}
        {this.renderLinkedModels()}
        <MappingsWrapper>
          {this.tableInputMapping()}
          {this.fileInputMapping()}
          {this.tableOutputMapping()}
          {this.fileOutputMapping()}
        </MappingsWrapper>
        {this.renderCodeBlocksHeader()}
        {this.renderPackages()}
        {this.renderVariables()}
        {this.configuration()}
        {this.configurationRows()}
        {this.processorsConfiguration()}
      </>
    );
  },

  renderVariablesOverride() {
    if (!this.hasPreparedVariables()) {
      return null;
    }

    return (
      <VariablesOverridePanel
        allConfigurations={this.state.allConfigurations}
        componentId={this.state.componentId}
        configId={this.state.configId}
        onChangeFn={(variablesOverride) =>
          this.setState({
            variablesOverride
          })
        }
      />
    );
  },

  tableInputMapping() {
    if (ONLY_READONLY_STORAGE.includes(resolveSandboxTypeFromComponentId(this.state.componentId))) {
      return <TableInputMappingReadOnlyInfo />;
    }

    if (
      !this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_TABLE_INPUT) ||
      this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_ROWS)
    ) {
      return null;
    }

    return (
      <TableInputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        destinationType={getDestinationTypeFromStagingStorage(
          this.state.component.getIn(['data', 'staging_storage', 'input']),
          this.state.componentId
        )}
        configId={this.state.configId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        value={this.state.configData.getIn(['storage', 'input', 'tables'], List())}
        editingValue={this.state.editingConfigDataObject.getIn(
          ['storage', 'input', 'tables'],
          Map()
        )}
        tables={this.state.tables}
        buckets={this.state.buckets}
        pendingActions={this.state.pendingActions}
        allComponents={this.state.allComponents}
        allowedComponents={this.state.allowedTransformationComponents}
        availableDatabricksClusters={this.state.availableDatabricksWorkspaceClusters}
        sandboxes={this.state.sandboxes}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
      />
    );
  },

  fileInputMapping() {
    if (
      !this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_INPUT) ||
      this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_ROWS)
    ) {
      return null;
    }

    if (this.state.componentId === KEBOOLA_DATABRICKS_TRANSFORMATION) {
      return (
        <FileInputMappingOverview
          readOnly={this.state.readOnly}
          value={this.state.configData.getIn(['storage', 'input', 'files'], List())}
          componentId={this.state.componentId}
          configId={this.state.configId}
          allComponents={this.state.allComponents}
          allConfigurations={this.state.allConfigurations}
          isDevModeActive={this.state.isDevModeActive}
          onDeleteMappings={(...args) =>
            InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
          }
          pendingActions={this.state.pendingActions}
        />
      );
    }

    return (
      <FileInputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        allComponents={this.state.allComponents}
        value={this.state.configData.getIn(['storage', 'input', 'files'], List())}
        editingValue={this.state.editingConfigDataObject.getIn(
          ['storage', 'input', 'files'],
          Map()
        )}
        pendingActions={this.state.pendingActions}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        allowedComponents={this.state.allowedTransformationComponents}
        availableDatabricksClusters={this.state.availableDatabricksWorkspaceClusters}
        sandboxes={this.state.sandboxes}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
      />
    );
  },

  tableOutputMapping() {
    if (
      !this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_TABLE_OUTPUT) ||
      this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_ROWS)
    ) {
      return null;
    }

    return (
      <TableOutputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        configName={this.state.config.get('name')}
        value={this.state.configData.getIn(['storage', 'output', 'tables'], List())}
        editingValue={this.state.editingConfigDataObject.getIn(
          ['storage', 'output', 'tables'],
          Map()
        )}
        tables={this.state.tables}
        buckets={this.state.buckets}
        pendingActions={this.state.pendingActions}
        sourceType={getSourceTypeFromStagingStorage(
          this.state.component.getIn(['data', 'staging_storage', 'output'])
        )}
      />
    );
  },

  fileOutputMapping() {
    if (
      !this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_OUTPUT) ||
      this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_ROWS)
    ) {
      return null;
    }

    return (
      <FileOutputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        value={this.state.configData.getIn(['storage', 'output', 'files'], List())}
        editingValue={this.state.editingConfigDataObject.getIn(
          ['storage', 'output', 'files'],
          Map()
        )}
        pendingActions={this.state.pendingActions}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
      />
    );
  },

  renderLinkedModels() {
    if (
      this.state.isDevModeActive ||
      !this.state.hasModelsAvailable ||
      !ComponentsStore.getFeatures(this.state.componentId).includes(
        componentFeatures.MLFLOW_ARTIFACTS_ACCESS
      )
    ) {
      return null;
    }

    return (
      <LinkedModelsBox
        models={this.state.allModels}
        componentId={this.state.componentId}
        configId={this.state.configId}
      />
    );
  },

  renderCodeBlocksHeader() {
    if (!hasGenericCodeBlocksUI(this.state.component)) {
      return null;
    }

    return (
      <CodeBlocksHeader
        readOnly={this.state.readOnly}
        config={this.state.config}
        component={this.state.component}
        blocks={this.state.configData.getIn(['parameters', 'blocks'], List())}
        sharedCodes={this.state.sharedCodes}
        models={this.state.allModels}
        tables={this.state.tables}
        variables={this.state.variablesConfigurationRow}
      />
    );
  },

  renderPackages() {
    if (!hasGenericPackagesUI(this.state.component)) {
      return null;
    }

    return (
      <Packages
        disabled={this.state.readOnly}
        componentId={this.state.component.get('id')}
        isSaving={this.state.localState.getIn(['packages', 'saving'], false)}
        packages={this.state.configData.getIn(['parameters', 'packages'], List())}
        onEditChange={(packages) => {
          const configuration = this.state.configData.setIn(['parameters', 'packages'], packages);
          this.updateLocalState(['packages', 'saving'], true);
          return InstalledComponentsActionCreators.updateComponentConfiguration(
            this.state.componentId,
            this.state.configId,
            { configuration: JSON.stringify(configuration) },
            'Change packages'
          ).finally(() => this.updateLocalState(['packages', 'saving'], false));
        }}
        forGenericComponents
      />
    );
  },

  renderDevModeWarning() {
    if (!this.hasDevModeProblematicMapping()) {
      return null;
    }

    return (
      <Alert bsStyle="warning">
        Using the same table on the input mapping and the output mapping with incremental load
        turned on may cause unexpected results. Please create the bucket and the specific table in
        the development branch first or turn the incremental load off.
      </Alert>
    );
  },

  hasAuthorizeFlag() {
    return this.state.component
      .get('flags')
      .includes(componentFlags.GENERIC_DOCKER_UI_AUTHORIZATION);
  },

  renderConfigurationDescription() {
    if (!this.state.component.get('configurationDescription')) {
      return null;
    }

    return (
      <CollapsibleBox title="Configuration Description" entity="description">
        <Markdown
          collapsible={false}
          source={this.state.component.get('configurationDescription')}
        />
      </CollapsibleBox>
    );
  },

  accountAuthorization() {
    if (!this.hasAuthorizeFlag()) {
      return null;
    }

    return (
      <AuthorizationRow
        configId={this.state.configId}
        componentId={this.state.componentId}
        credentials={this.state.oauthCredentials}
        admins={this.state.admins}
        onResetCredentials={this.deleteCredentials}
        readOnly={this.state.readOnly}
      />
    );
  },

  renderComponentSpecificInfo() {
    if (this.state.componentId === KEBOOLA_DATABRICKS_TRANSFORMATION) {
      return (
        <DatabricksParameters
          component={this.state.component}
          configId={this.state.configId}
          configData={this.state.configData}
        />
      );
    }

    return null;
  },

  deleteCredentials() {
    return oauthUtils.deleteCredentialsAndConfigAuth(this.state.componentId, this.state.configId);
  },

  renderVariables() {
    if (!hasGenericVariablesUI()) {
      return null;
    }

    return (
      <Variables
        readOnly={this.state.readOnly}
        mainConfiguration={this.state.configData}
        mainComponentId={this.state.componentId}
        mainConfigurationId={this.state.configId}
        configuration={this.state.variablesConfiguration}
        row={this.state.variablesConfigurationRow}
        variables={this.state.preparedVariables}
        missingVariables={this.state.missingVariables}
        configVariablesId={this.state.configData.get('variables_id', null)}
        configVariablesValuesId={this.state.configData.get('variables_values_id', null)}
      />
    );
  },

  runParams() {
    if (this.state.variablesOverride.count() > 0) {
      return () => ({
        config: this.state.configId,
        variableValuesData: {
          values: this.state.preparedVariables.map((value) => {
            return {
              name: value.get('name'),
              value: this.state.variablesOverride.get(value.get('name'), value.get('value'))
            };
          })
        }
      });
    }

    return () => ({ config: this.state.configId });
  },

  configuration() {
    if (hasGenericCodeBlocksUI(this.state.component)) {
      return (
        <CodeBlocksConfiguration
          readOnly={this.state.readOnly}
          component={this.state.component}
          configId={this.state.configId}
          configData={this.state.configData}
          sharedCodes={this.state.sharedCodes}
          componentSharedCodes={this.state.componentSharedCodes}
          variables={this.state.variablesConfigurationRow}
          tables={this.state.tables}
        />
      );
    }

    if (hasGenericTemplatesUI(this.state.component)) {
      return <TemplatedConfiguration />;
    }

    if (hasGenericUI(this.state.component)) {
      return (
        <CatchUnsavedChanges
          isDirty={this.state.isChanged}
          onSave={this.onEditSubmit}
          isSaveDisabled={!this.state.isValidEditingConfigData}
          onDirtyLeave={this.onEditCancel}
        >
          <Configuration
            readOnly={this.state.readOnly}
            component={this.state.component}
            data={this.state.editingConfigData}
            isSaving={this.state.isSaving}
            onEditCancel={this.onEditCancel}
            onEditChange={this.onEditChange}
            isChanged={this.state.isChanged}
            onEditSubmit={this.onEditSubmit}
            isValid={this.state.isValidEditingConfigData}
          />
        </CatchUnsavedChanges>
      );
    }

    if (hasGenericDockerUI(this.state.component)) {
      return (
        <CatchUnsavedChanges
          isDirty={this.state.isParametersChanged}
          onSave={this.onEditParametersSubmit}
          isSaveDisabled={!this.state.isValidEditingConfigDataParameters}
          onDirtyLeave={this.onEditParametersCancel}
        >
          <Configuration
            readOnly={this.state.readOnly}
            component={this.state.component}
            data={this.state.editingConfigDataParameters}
            isChanged={this.state.isParametersChanged}
            isSaving={this.state.isParametersSaving}
            onEditCancel={this.onEditParametersCancel}
            onEditChange={this.onEditParametersChange}
            onEditSubmit={this.onEditParametersSubmit}
            isValid={this.state.isValidEditingConfigDataParameters}
            schema={removeSingleSyncActionFromSchema(
              this.state.component.get('configurationSchema')
            )}
            hasRowsFlag={this.state.component
              .get('flags')
              .includes(componentFlags.GENERIC_DOCKER_UI_ROWS)}
          />
        </CatchUnsavedChanges>
      );
    }

    return (
      <div className="box">
        <div className="box-content">
          <p>
            This component has to be configured manually, please contact our support for assistance.
          </p>
          <Button onClick={() => contactSupport()} bsStyle="success">
            Contact Support
          </Button>
        </div>
      </div>
    );
  },

  configurationRows() {
    if (!this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_ROWS)) {
      return null;
    }

    return (
      <GenericRows
        readOnly={this.state.readOnly}
        rows={this.state.rows}
        tables={this.state.tables}
        buckets={this.state.buckets}
        config={this.state.config}
        component={this.state.component}
      />
    );
  },

  processorsConfiguration() {
    if (
      (this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_ROWS) &&
        !this.state.configData.has('processors')) ||
      (![componentTypes.EXTRACTOR, componentTypes.WRITER].includes(
        this.state.component.get('type')
      ) &&
        !this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_PROCESSORS) &&
        !this.state.configData.has('processors'))
    ) {
      return null;
    }

    return (
      <Processors
        value={this.state.configData.get('processors', Map())}
        readOnly={this.state.readOnly}
        onSubmit={this.onProcessorsSubmit}
      />
    );
  },

  onProcessorsSubmit(processors) {
    const configData = processors
      ? this.state.configData.set('processors', processors)
      : this.state.configData.delete('processors');

    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.state.componentId,
      this.state.configId,
      configData,
      'Update processors configuration'
    );
  },

  onEditCancel() {
    InstalledComponentsActionCreators.cancelEditComponentRawConfigData(
      this.state.componentId,
      this.state.configId
    );
  },

  onEditChange(newValue) {
    InstalledComponentsActionCreators.updateEditComponentRawConfigData(
      this.state.componentId,
      this.state.configId,
      newValue
    );
  },

  onEditSubmit() {
    return InstalledComponentsActionCreators.saveComponentRawConfigData(
      this.state.componentId,
      this.state.configId
    );
  },

  onEditParametersCancel() {
    InstalledComponentsActionCreators.cancelEditComponentRawConfigDataParameters(
      this.state.componentId,
      this.state.configId
    );
  },

  onEditParametersChange(newValue) {
    InstalledComponentsActionCreators.updateEditComponentRawConfigDataParameters(
      this.state.componentId,
      this.state.configId,
      newValue
    );
  },

  onGenericPatternSubmit() {
    const dataToSave = this.state.configData
      .setIn(
        ['runtime', 'codePattern', 'parameters'],
        fromJS(JSON.parse(this.state.editingConfigDataParameters)).toOrderedMap()
      )
      .toJS();

    return InstalledComponentsActionCreators.saveComponentRawConfigDataParameters(
      this.state.componentId,
      this.state.configId,
      dataToSave
    );
  },

  onEditParametersSubmit() {
    const dataToSave = this.state.configData
      .set('parameters', fromJS(JSON.parse(this.state.editingConfigDataParameters)).toOrderedMap())
      .toJS();

    return InstalledComponentsActionCreators.saveComponentRawConfigDataParameters(
      this.state.componentId,
      this.state.configId,
      dataToSave
    );
  },

  onResetState() {
    return InstalledComponentsActionCreators.resetComponentConfigurationState(
      this.state.componentId,
      this.state.configId
    );
  },

  updateLocalState(path, data) {
    const configId = this.state.configId;
    const componentId = this.state.componentId;
    const newState = this.state.localState.setIn(path, data);
    InstalledComponentsActionCreators.updateLocalState(componentId, configId, newState, path);
  },

  resetStateEnable() {
    return (
      !this.state.readOnly &&
      this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_RESET_STATE)
    );
  },

  hasPreparedVariables() {
    return hasGenericVariablesUI() && this.state.preparedVariables.count() > 0;
  },

  hasDevModeProblematicMapping() {
    return hasDevModeProblematicMapping(
      DevBranchesStore.getCurrentId(),
      this.state.configData.get('storage', Map()),
      this.state.tables
    );
  }
});

export default GenericDetail;
