import React from 'react';
import { Map } from 'immutable';
import { truncate } from 'underscore.string';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import Truncated from '../../../react/common/Truncated';
import ComponentConfigurationLink from '../../components/react/components/ComponentConfigurationLink';
import { getFlowIdFromTriggeredJob } from '../helpers';

const TriggeredJob = (props: { job?: Map<string, any>; allConfigurations?: Map<string, any> }) => {
  const flowId = getFlowIdFromTriggeredJob(props.job);
  const flow =
    props.allConfigurations?.getIn([KEBOOLA_ORCHESTRATOR, 'configurations', flowId]) || Map();

  if (flowId) {
    return (
      <div>
        Event triggered{' '}
        <ComponentConfigurationLink
          hasFlows
          className="text-muted"
          componentId={KEBOOLA_ORCHESTRATOR}
          configId={flowId}
          isDeleted={flow.isEmpty()}
        >
          {truncate(flow.get('name', flowId), 50)}
        </ComponentConfigurationLink>{' '}
        run
      </div>
    );
  }

  return <Truncated text={`Event triggered ${flowId ? `${flowId} run` : 'run'}`} />;
};

export default TriggeredJob;
