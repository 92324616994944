import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import Variable from '../../components/react/components/generic/variables/Variable';
import VariableForm from '../../components/react/components/generic/variables/VariableForm';

const DefineVariables = ({ readOnly, variables, onChange }) => {
  const [isAddingNewVariable, setIsAddingNewVariable] = React.useState(false);
  const [newVariable, setNewVariable] = React.useState(null);
  const [editingVariables, setEditingVariables] = React.useState(Map());
  const [savingVariables, setSavingVariables] = React.useState(Map());
  const [deletingVariables, setDeletingVariables] = React.useState(Map());

  return (
    <div className="box box-genericVariablesUI pbp-2">
      <div className="box-header smaller ptp-2 prp-1 plp-6">
        <h2 className="box-title line-height-24">Variables</h2>
        {!readOnly && (
          <Button
            bsStyle="link"
            className="header-inline-button color-success"
            onClick={() => setIsAddingNewVariable(true)}
            disabled={isAddingNewVariable}
          >
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            New Variable
          </Button>
        )}
      </div>
      {(isAddingNewVariable || !variables.isEmpty()) && (
        <div className="box-content ptp-2 prp-6 pbp-2 plp-6">
          {variables.map((variable, index) => {
            const variableName = variable.get('name');
            if (variableName === newVariable) return null;
            if (editingVariables.has(variableName)) {
              return (
                <VariableForm
                  key={`edit-${variableName}-${index}`}
                  saveVariableFn={(name) => {
                    setSavingVariables(savingVariables.set(variableName, true));
                    return onChange(variables.set(index, Map({ name }))).then(() =>
                      setSavingVariables(savingVariables.delete(variableName))
                    );
                  }}
                  resetVariableFn={() => setEditingVariables(editingVariables.delete(variableName))}
                  variableName={variable.get('name')}
                  variableValue={variable.get('value')}
                  isSaving={savingVariables.has(variableName)}
                  variables={variables}
                  nameOnly={true}
                />
              );
            }
            return (
              <Variable
                key={`view-${variableName}-${index}`}
                variable={variable}
                readOnly={readOnly}
                deleteVariableFn={() => {
                  setDeletingVariables(deletingVariables.set(variableName, true));
                  return onChange(variables.delete(index)).then(() =>
                    setDeletingVariables(deletingVariables.delete(variableName))
                  );
                }}
                startEditingFn={() => setEditingVariables(editingVariables.set(variableName, true))}
                isDeleting={deletingVariables.has(variableName)}
                nameOnly={true}
              />
            );
          })}
          {isAddingNewVariable && (
            <VariableForm
              saveVariableFn={(name) => {
                setNewVariable(name);
                return onChange(variables.push(Map({ name }))).then(() => setNewVariable(null));
              }}
              resetVariableFn={() => setIsAddingNewVariable(false)}
              isSaving={!!newVariable}
              variables={variables}
              nameOnly={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

DefineVariables.propTypes = {
  readOnly: PropTypes.bool,
  variables: PropTypes.instanceOf(List).isRequired,
  onChange: PropTypes.func.isRequired
};

export default DefineVariables;
