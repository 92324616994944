import React, { useState } from 'react';
import { HelpBlock } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type Promise from 'bluebird';

import Confirm from '../../react/common/Confirm';
import { READ_ONLY_TOOLTIP_MESSAGE } from '../../react/common/ReadOnlyTooltip';
import SliderPicker from '../../react/common/SliderPicker';

const OPTIONS = [null, '2', '4', '6', '8', '10', '15', '20', '25', '50', '100'];
type optionValue = (typeof OPTIONS)[number];

const ParallelismModal = ({
  onChange,
  savedValue,
  readOnly
}: {
  onChange: (selectedValue: optionValue) => Promise<void>;
  savedValue: optionValue;
  readOnly: boolean;
}) => {
  const [selectedValue, setSelectedValue] = useState(savedValue || null);
  const [isSaving, setIsSaving] = useState(false);

  const onConfirm = () => {
    setIsSaving(true);
    return onChange(selectedValue).finally(() => setIsSaving(false));
  };

  return (
    <Confirm
      closeAfterResolve
      icon="line-height"
      title="Parallelise Configuration Jobs"
      onConfirm={onConfirm}
      onEnter={() => setSelectedValue(savedValue)}
      text={
        <>
          <p>Set maximum number of configuration jobs run in parallel.</p>
          <SliderPicker
            options={OPTIONS}
            value={selectedValue}
            onChange={setSelectedValue}
            renderLabel={renderLabel}
          />
          <HelpBlock>
            Default is <code>off</code>, to activate parallel run set up a value.
          </HelpBlock>
        </>
      }
      buttonType="success"
      buttonLabel="Save Parallelisation"
      childrenRootElement="button"
      childrenRootElementClass="btn btn-link btn-block"
      isDisabledConfirmButton={selectedValue === savedValue}
      isDisabled={readOnly}
      isLoading={isSaving}
      {...(readOnly && { disabledReason: READ_ONLY_TOOLTIP_MESSAGE })}
    >
      <FontAwesomeIcon icon="line-height" className="icon-addon-right" />
      Parallel Jobs: <span className="tw-font-medium">{renderLabel(savedValue)}</span>
    </Confirm>
  );
};

const renderLabel = (value: optionValue) => value || 'Off';

export default ParallelismModal;
