import { List, Map } from 'immutable';
import _ from 'underscore';

import { APIFY_APIFY as COMPONENT_ID } from '../../constants/componentIds';
import callDockerAction from '../components/DockerActionsApi';
import componentsActions from '../components/InstalledComponentsActionCreators';
import storeProvisioning from './storeProvisioning';

export default function (configId) {
  const store = storeProvisioning(configId);

  function updateLocalState(path, data) {
    const localState = store.getLocalState();
    const newLocalState = localState.setIn([].concat(path), data);
    componentsActions.updateLocalState(COMPONENT_ID, configId, newLocalState, path);
  }

  function prepareLocalState(path) {
    const updateLocalSubstateFn = (subPath, newData) => {
      if (_.isEmpty(subPath)) {
        return updateLocalState([].concat(path), newData);
      } else {
        return updateLocalState([].concat(path).concat(subPath), newData);
      }
    };

    return {
      localState: store.getLocalState(path),
      updateLocalState: updateLocalSubstateFn,
      prepareLocalState: (newSubPath) => prepareLocalState([].concat(path).concat(newSubPath))
    };
  }

  function saveConfigData(data, waitingPath, changeDescription) {
    // check default output bucket and save default if non set
    updateLocalState(waitingPath, true);
    return componentsActions
      .saveComponentConfigData(COMPONENT_ID, configId, data, changeDescription)
      .then(() => updateLocalState(waitingPath, false));
  }

  function saveConfig(newParams, inputTableId) {
    let data = store.configData.set('parameters', newParams);
    if (inputTableId) {
      const newTable = Map({ source: inputTableId });
      data = data.setIn(['storage', 'input', 'tables'], List().push(newTable));
    } else {
      data = data.delete('storage');
    }
    return saveConfigData(data, 'saving', 'Set up component');
  }

  return {
    updateLocalState,
    prepareLocalState,
    saveConfig,
    loadActors(settings) {
      return callDockerAction(COMPONENT_ID, 'listActors', {
        configData: {
          parameters: { '#token': settings.get('#token') }
        }
      });
    },
    loadTasks(settings) {
      return callDockerAction(COMPONENT_ID, 'listTasks', {
        configData: {
          parameters: { '#token': settings.get('#token') }
        }
      });
    }
  };
}
