import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { fromJS, List } from 'immutable';

import { SIDEBAR } from '../../../../../constants/external';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import ComponentsStore from '../../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../../components/stores/InstalledComponentsStore';
import Events from '../../../../sapi-events/react/Events';
import OrchestrationActions from '../../../ActionCreators';
import mergeTasksWithConfigurations from '../../../mergeTasksWithConfigurations';
import OrchestrationJobsStore from '../../../stores/OrchestrationJobsStore';
import OrchestrationStore from '../../../stores/OrchestrationsStore';
import JobTasks from './JobTasks';
import Overview from './Overview';
import RunHistory from './RunHistory';

const OrchestrationJobDetail = createReactClass({
  mixins: [
    createStoreMixin(
      RoutesStore,
      ComponentsStore,
      OrchestrationStore,
      OrchestrationJobsStore,
      InstalledComponentsStore,
      ApplicationStore
    )
  ],

  getStateFromStores() {
    const orchestrationId = RoutesStore.getCurrentRouteIntParam('orchestrationId');
    const jobId = RoutesStore.getCurrentRouteIntParam('jobId');
    let job = OrchestrationJobsStore.getJob(jobId);

    if (job && job.hasIn(['results', 'tasks'])) {
      const phasedTasks = OrchestrationActions.rephaseTasks(
        job.getIn(['results', 'tasks'], List()).toJS()
      );
      const merged = mergeTasksWithConfigurations(
        fromJS(phasedTasks),
        InstalledComponentsStore.getAll()
      );
      job = job.setIn(['results', 'tasks'], OrchestrationActions.dephaseTasks(merged));
    }

    return {
      job,
      orchestrationId,
      jobs: OrchestrationJobsStore.getOrchestrationJobs(orchestrationId),
      openedTab: RoutesStore.getRouterState().hasIn(['location', 'query', 'eventId'])
        ? 'log'
        : 'tasks',
      allComponents: ComponentsStore.getAll(),
      hasFlows: ApplicationStore.hasFlows()
    };
  },

  render() {
    if (!this.state.job) {
      return <p>Job not found</p>;
    }

    return (
      <>
        <Overview job={this.state.job} />
        <div className="row">
          <div className="col-sm-9">
            <Tabs
              className="mb-1"
              defaultActiveKey={this.state.openedTab}
              animation={false}
              id="job-detail-tabs"
            >
              <Tab eventKey="tasks" title="Tasks" tabClassName="ml-2">
                <JobTasks
                  tasks={this.state.job.getIn(['results', 'tasks'], List())}
                  hasFlows={this.state.hasFlows}
                  allComponents={this.state.allComponents}
                />
              </Tab>
              <Tab eventKey="log" title="Events">
                <Events
                  link={{
                    to: 'orchestrationJob',
                    params: {
                      orchestrationId: this.state.orchestrationId,
                      jobId: this.state.job.get('id')
                    }
                  }}
                  params={{
                    runId: this.state.job.get('runId')
                  }}
                  autoReload={
                    this.state.job.get('status') === 'waiting' ||
                    this.state.job.get('status') === 'processing'
                  }
                />
              </Tab>
            </Tabs>
          </div>
          <div className="col-sm-3">
            <div className={`sidebar-content ${SIDEBAR}`}>
              <RunHistory jobs={this.state.jobs} activeJobId={this.state.job.get('id')} />
            </div>
          </div>
        </div>
      </>
    );
  }
});

export default OrchestrationJobDetail;
