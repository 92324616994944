import { SERVICE_SCHEDULER } from '../../constants/serviceIds';
import ApplicationStore from '../../stores/ApplicationStore';
import request from '../../utils/request';
import ServicesStore from '../services/Store';

export const createRequest = (method, path) => {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_SCHEDULER)}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString()
  );
};

const SchedulerApi = {
  applySchedulerChanges(configurationId) {
    return createRequest('POST', 'schedules')
      .send({ configurationId })
      .promise()
      .then((response) => response.body);
  },
  removeSchedule(configurationId) {
    return createRequest('DELETE', `configurations/${configurationId}`)
      .promise()
      .then((response) => response.body);
  }
};

export default SchedulerApi;
