import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal
} from 'react-bootstrap';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS, List } from 'immutable';

import Checkbox from '../../../react/common/Checkbox';
import ConfirmButtons from '../../../react/common/ConfirmButtons';
import InfoTooltip from '../../../react/common/InfoTooltip';
import InputValidation, { isValidName } from '../../../react/common/InputValidation';
import ModalIcon from '../../../react/common/ModalIcon';
import OptionalFormLabel from '../../../react/common/OptionalFormLabel';
import Select from '../../../react/common/Select';
import whereOperatorConstants from '../../../react/common/whereOperatorConstants';
import string from '../../../utils/string';
import SapiTableSelector from '../../components/react/components/SapiTableSelector';
import { ALIAS_SUFFIX, nameWarning } from '../constants';
import { validateTableName } from '../helpers';

const initialNewTableAlias = {
  sourceTable: '',
  name: '',
  aliasFilter: {
    column: '',
    operator: whereOperatorConstants.EQ_VALUE,
    values: List()
  },
  aliasColumnsAutosync: true
};

const INITIAL_STATE = {
  newTableAlias: fromJS(initialNewTableAlias),
  tableColumns: [],
  error: null,
  warning: null
};

const CreateAliasTableModal = createReactClass({
  propTypes: {
    bucket: PropTypes.object.isRequired,
    openModal: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    buckets: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    return (
      <Modal show={this.props.openModal} onHide={this.onHide}>
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Create alias table in {this.props.bucket.get('displayName')} bucket
            </Modal.Title>
            <ModalIcon icon="table" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            {this.renderError()}
            <FormGroup>
              <ControlLabel>Source table</ControlLabel>
              <SapiTableSelector
                autoFocus
                placeholder="Source table"
                buckets={this.props.buckets}
                tables={this.props.tables}
                value={this.state.newTableAlias.get('sourceTable')}
                onSelectTableFn={this.handleSourceTable}
              />
            </FormGroup>
            <InputValidation predefined="bucketName" value={this.state.newTableAlias.get('name')}>
              {(inputState) => (
                <FormGroup validationState={this.state.warning ? 'error' : inputState}>
                  <ControlLabel>Table Name</ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.newTableAlias.get('name')}
                    onChange={this.handleName}
                  />
                  <HelpBlock
                    className={classnames({
                      'text-danger': !!this.state.warning || inputState === 'error'
                    })}
                  >
                    {this.state.warning || nameWarning}
                  </HelpBlock>
                </FormGroup>
              )}
            </InputValidation>
            {!!this.props.bucket.get('sharing') ? (
              <Alert bsStyle="warning">
                Filtering and synchronization of specific columns is not available when creating
                alias table in a shared bucket.
              </Alert>
            ) : (
              <>
                <FormGroup>
                  <ControlLabel>
                    Filtering <OptionalFormLabel />
                    <InfoTooltip
                      tooltip={
                        <>
                          <p className="tooltip-title">Filtering</p>
                          <p>
                            You can specify a column to filter by, and comma separated values
                            you&apos;re looking for. The alias table will contain only the matching
                            rows.
                          </p>
                        </>
                      }
                    />
                  </ControlLabel>
                  <div className="select-group">
                    <Select
                      clearable
                      placeholder="Column..."
                      value={this.state.newTableAlias.getIn(['aliasFilter', 'column'])}
                      onChange={this.handleAliasFilterColumn}
                      options={this.state.tableColumns}
                      disabled={this.state.tableColumns.length === 0}
                    />
                    <Select
                      searchable={false}
                      clearable={false}
                      disabled={this.state.tableColumns.length === 0}
                      value={this.state.newTableAlias.getIn(['aliasFilter', 'operator'])}
                      onChange={this.handleAliasFilterOperator}
                      options={[
                        {
                          label: whereOperatorConstants.EQ_LABEL,
                          value: whereOperatorConstants.EQ_VALUE
                        },
                        {
                          label: whereOperatorConstants.NOT_EQ_LABEL,
                          value: whereOperatorConstants.NOT_EQ_VALUE
                        }
                      ]}
                    />
                  </div>
                  <Select
                    multi
                    allowCreate
                    emptyStrings
                    placeholder="Add a value"
                    value={this.state.newTableAlias.getIn(['aliasFilter', 'values'])}
                    disabled={this.state.tableColumns.length === 0}
                    onChange={this.handleAliasFilterValues}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    Columns
                    <InfoTooltip
                      tooltip={
                        <>
                          <p className="tooltip-title">Columns</p>
                          <p>By default columns are synchronized with the source table.</p>
                          <p>
                            You can disable this behaviour and select only particular columns to be
                            included in the alias table.
                          </p>
                        </>
                      }
                    />
                  </ControlLabel>
                  <Checkbox
                    checked={this.state.newTableAlias.get('aliasColumnsAutosync')}
                    onChange={this.toggleSyncColumns}
                  >
                    Synchronize columns with the source table
                  </Checkbox>
                </FormGroup>
                {!this.state.newTableAlias.get('aliasColumnsAutosync') && (
                  <FormGroup>
                    <Select
                      multi
                      clearable
                      allowCreate
                      placeholder="Select alias table columns"
                      value={this.state.newTableAlias.get('aliasColumns', List())}
                      onChange={this.handleAliasTableColumns}
                      options={this.state.tableColumns}
                      disabled={this.state.tableColumns.length === 0}
                    />
                  </FormGroup>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isSaving={this.props.isSaving}
              isDisabled={this.isDisabled()}
              saveLabel={this.props.isSaving ? 'Creating alias table...' : 'Create alias table'}
              saveButtonType="submit"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <Alert bsStyle="danger">{this.state.error}</Alert>;
  },

  handleSourceTable(tableId, table) {
    const tableColumns = table
      .get('columns')
      .map((column) => ({ label: column, value: column }))
      .toArray();

    let newTableAlias = this.state.newTableAlias
      .set('sourceTable', tableId)
      .set('name', string.webalize(`${table.get('displayName')}${ALIAS_SUFFIX}`))
      .remove('aliasColumns');

    this.setState({ tableColumns, newTableAlias }, this.validateName);
  },

  handleAliasFilterOperator(value) {
    this.setState({
      newTableAlias: this.state.newTableAlias.setIn(['aliasFilter', 'operator'], value)
    });
  },

  handleAliasFilterColumn(option) {
    let newTableAlias = this.state.newTableAlias;

    if (option) {
      newTableAlias = newTableAlias.setIn(['aliasFilter', 'column'], option);
    } else {
      newTableAlias = newTableAlias.deleteIn(['aliasFilter', 'column']);
    }

    this.setState({ newTableAlias });
  },

  handleAliasFilterValues(value) {
    this.setState({
      newTableAlias: this.state.newTableAlias.setIn(['aliasFilter', 'values'], value)
    });
  },

  handleName(event) {
    this.setState(
      {
        newTableAlias: this.state.newTableAlias.set('name', event.target.value)
      },
      this.validateName
    );
  },

  handleAliasTableColumns(columns) {
    this.setState({ newTableAlias: this.state.newTableAlias.set('aliasColumns', columns) });
  },

  toggleSyncColumns() {
    this.setState({
      newTableAlias: this.state.newTableAlias
        .update('aliasColumnsAutosync', (aliasColumnsAutosync) => !aliasColumnsAutosync)
        .set('aliasColumns', List())
    });
  },

  onHide() {
    this.props.onHide();
    this.resetState();
  },

  onSubmit(event) {
    event.preventDefault();
    const tableAlias = this.state.newTableAlias
      .update((tableAlias) => {
        if (!tableAlias.getIn(['aliasFilter', 'column'])) {
          return tableAlias.delete('aliasFilter');
        }
        return tableAlias;
      })
      .toJS();

    this.props.onSubmit(tableAlias).then(this.onHide, (message) => {
      this.setState({
        error: message
      });
    });
  },

  resetState() {
    this.setState(INITIAL_STATE);
  },

  validateName() {
    this.setState({
      warning: validateTableName(
        this.state.newTableAlias.get('name'),
        this.props.tables.filter(
          (table) => table.getIn(['bucket', 'id']) === this.props.bucket.get('id')
        )
      )
    });
  },

  isDisabled() {
    const tableAlias = this.state.newTableAlias;
    const aliasFilter = tableAlias.get('aliasFilter');

    if (
      !tableAlias.get('name') ||
      !isValidName(tableAlias.get('name')) ||
      !tableAlias.get('sourceTable')
    ) {
      return true;
    }

    if (
      aliasFilter.get('column') &&
      (!aliasFilter.get('operator') || !aliasFilter.get('values').count())
    ) {
      return true;
    }

    if (
      !tableAlias.get('aliasColumnsAutosync', true) &&
      tableAlias.get('aliasColumns', List()).isEmpty()
    ) {
      return true;
    }

    return this.props.isSaving || !!this.state.warning;
  }
});

export default CreateAliasTableModal;
