import { KEBOOLA_GOODDATA_WRITER as componentId } from '../../constants/componentIds';
import { configPoll } from '../../utils/genericPolls';
import { configRequiredData } from '../../utils/genericRequiredData';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import Index from './react/pages/index/Index';
import Table from './react/pages/table/Table';
import TableDetailHeaderButtons from './TableDetailHeaderButtons';

export default {
  name: componentId,
  path: `${componentId}/:config`,
  defaultRouteHandler: Index,
  title: (routerState) => {
    const configId = routerState.getIn(['params', 'config']);
    return InstalledComponentsStore.getConfig(componentId, configId).get('name');
  },
  requireData: [(params) => configRequiredData(componentId, params.config)],
  poll: configPoll(componentId),
  childRoutes: [
    {
      name: componentId + '-table',
      path: 'table/:table',
      defaultRouteHandler: Table,
      title: (routerState) => routerState.getIn(['params', 'table']),
      headerButtonsHandler: TableDetailHeaderButtons
    }
  ]
};
