import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { DEVELOPERS_DOCUMENTATION_URL } from '../../../../constants/KbcConstants';
import CodeEditor from '../../../../react/common/CodeEditor';
import ExternalLink from '../../../../react/common/ExternalLink';
import TemplateSelector from './ConfigurationTemplateSelector';
import JSONSchemaEditor from './JSONSchemaEditor';

const TemplatedConfigurationEdit = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    component: PropTypes.instanceOf(Map).isRequired,
    showJsonEditor: PropTypes.bool.isRequired,
    editingTemplate: PropTypes.object.isRequired,
    editingParams: PropTypes.object.isRequired,
    editingString: PropTypes.string.isRequired,
    templates: PropTypes.object.isRequired,
    paramsSchema: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onChangeTemplate: PropTypes.func.isRequired,
    onChangeParams: PropTypes.func.isRequired,
    onChangeString: PropTypes.func.isRequired,
    jsonSchemEditorResetKey: PropTypes.string.isRequired
  },

  renderJSONSchemaEditor() {
    if (this.props.paramsSchema.get('properties', Map()).isEmpty()) {
      return null;
    }

    return (
      <JSONSchemaEditor
        key={this.props.jsonSchemEditorResetKey}
        component={this.props.component}
        schema={this.props.paramsSchema}
        value={this.props.editingParams}
        onChange={this.props.onChangeParams}
        readOnly={this.props.readOnly || this.props.isSaving}
      />
    );
  },

  render() {
    if (this.props.showJsonEditor) {
      return this.renderJsonEditor();
    }

    return this.renderTemplatesEditor();
  },

  renderJsonEditor() {
    return (
      <>
        <p>
          JSON configuration uses{' '}
          <ExternalLink href={`${DEVELOPERS_DOCUMENTATION_URL}/extend/generic-extractor/`}>
            Generic extractor
          </ExternalLink>{' '}
          format.
        </p>
        <CodeEditor
          value={this.props.editingString}
          onChange={this.props.onChangeString}
          options={{
            readOnly: this.props.readOnly || this.props.isSaving,
            lint: true,
            placeholder: '{}'
          }}
        />
      </>
    );
  },

  renderTemplatesEditor() {
    return (
      <>
        {this.renderJSONSchemaEditor()}
        <TemplateSelector
          templates={this.props.templates}
          value={this.props.editingTemplate}
          onChange={this.handleTemplateChange}
          readOnly={this.props.readOnly || this.props.isSaving}
        />
      </>
    );
  },

  handleTemplateChange(value) {
    this.props.onChangeTemplate(value);
  }
});

export default TemplatedConfigurationEdit;
