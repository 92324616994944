import React from 'react';
import { Button } from 'react-bootstrap';
import Sortable from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { KEBOOLA_EX_MONGODB as componentId } from '../../../../constants/componentIds';
import { defaultOptions } from '../../../../constants/sortable';
import CreateRowModal from '../../../../react/common/CreateRowModal';
import FilterPanel from '../../../../react/common/FilterPanel';
import NoResultsFound from '../../../../react/common/NoResultsFound';
import SortByName from '../../../../react/common/SortByName';
import RoutesStore from '../../../../stores/RoutesStore';
import matchByWords from '../../../../utils/matchByWords';
import { createSimpleRow } from '../../../configurations/ConfigurationRowsActionCreators';
import ConfigurationsActionCreators from '../../../configurations/ConfigurationsActionCreators';
import { sortRowsByName } from '../../../configurations/utils/helpers';
import ConfigRowTableRow from './ConfigRowTableRow';

type Props = {
  configId: string;
  configName: string;
  rows: Map<string, any>;
  defaultBucketId: string;
  component: Map<string, any>;
  readOnly: boolean;
};

class ConfigRowTables extends React.Component<Props> {
  state = {
    searchQuery: '',
    showNewTableModal: false,
    isChangingOrder: false,
    sort: null
  };

  render() {
    if (this.props.rows.isEmpty()) {
      return this.renderMissingTables();
    }

    return (
      <div className="box-separator">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
          <h2 className="tw-text-base tw-m-0">Exports</h2>
          {this.renderNewRowButton()}
        </div>
        <FilterPanel
          query={this.state.searchQuery}
          onChange={(searchQuery: string) => this.setState({ searchQuery })}
        />
        {this.renderTables()}
      </div>
    );
  }

  renderTables() {
    const rows = this.filteredRows();

    if (rows.isEmpty()) {
      return <NoResultsFound entityName="exports" />;
    }

    return (
      <div className="box">
        <div className="table table-hover">
          <div className="thead">
            <div className="tr">
              <span className="th">#</span>
              <span className="th">
                <SortByName
                  allowReset
                  sortBy={this.state.sort}
                  onClick={(sort) => this.setState({ sort })}
                />
              </span>
              <span className="th w-50" />
              <span className="th">Storage</span>
              <span className="th text-center">Mode</span>
              <span className="th text-center">Incremental</span>
              <span className="th" />
            </div>
          </div>
          <Sortable
            className="tbody"
            options={{ ...defaultOptions, disabled: this.props.readOnly }}
            onChange={(order: string[], sortable: any, event: any) => {
              this.setState({ isChangingOrder: true });
              return ConfigurationsActionCreators.orderRows(
                componentId,
                this.props.configId,
                order,
                order[event.newIndex],
                `${this.props.configName} order changed`
              ).finally(() => {
                this.setState({ isChangingOrder: false });
              });
            }}
          >
            {this.renderTableRows(rows)}
          </Sortable>
        </div>
      </div>
    );
  }

  renderTableRows(rows: any) {
    return rows
      .sort(sortRowsByName(this.state.sort))
      .map((row: Map<string, any>, rowIndex: number) => {
        return (
          <ConfigRowTableRow
            key={row.get('id')}
            row={row}
            rowIndex={rowIndex}
            readOnly={this.props.readOnly}
            defaultBucketId={this.props.defaultBucketId}
            configId={this.props.configId}
            isChangingOrder={this.state.isChangingOrder}
            isFiltered={!!this.state.searchQuery}
            isSorted={!!this.state.sort}
          />
        );
      })
      .toList()
      .toJS();
  }

  renderMissingTables() {
    return (
      <div className="box-separator">
        <h2 className="tw-text-base tw-m-0 tw-mb-4">Exports</h2>
        <div className="box">
          <div className="box-content text-center">
            <p>No exports assigned yet.</p>
            {this.renderNewRowButton()}
          </div>
        </div>
      </div>
    );
  }

  renderNewRowButton() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <>
        <Button
          bsStyle="success"
          bsSize="sm"
          onClick={() => this.setState({ showNewTableModal: true })}
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Add Export
        </Button>
        <CreateRowModal
          component={this.props.component}
          configId={this.props.configId}
          show={this.state.showNewTableModal}
          onHide={() => this.setState({ showNewTableModal: false })}
          onCreate={(tableId, name) => {
            return createSimpleRow(
              componentId,
              this.props.configId,
              { name },
              `Create export ${name}`
            );
          }}
          onRowRedirect={(rowId: string) => {
            RoutesStore.getRouter().transitionTo(componentId + '-row', {
              config: this.props.configId,
              row: rowId
            });
          }}
        />
      </>
    );
  }

  filteredRows() {
    if (!this.state.searchQuery) {
      return this.props.rows.toList();
    }

    return this.props.rows
      .filter((row) =>
        matchByWords(
          [row.get('name'), row.getIn(['configuration', 'parameters', 'tableName'])],
          this.state.searchQuery
        )
      )
      .toList();
  }
}

export default ConfigRowTables;
