import React from 'react';
import { Button } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import createReactClass from 'create-react-class';
import { Seq } from 'immutable';

import BlockButton from '../../react/common/BlockButton';
import RefreshIcon from '../../react/common/RefreshIcon';
import SearchPanel from '../../react/common/SearchPanel';
import Tooltip from '../../react/common/Tooltip';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import { factory as defaultEventsFactory } from '../sapi-events/EventsService';
import EventsTable from './components/EventsTable';
import StorageTabs from './components/StorageTabs';
import { eventsPredefinedSearches, routeNames } from './constants';

const Events = createReactClass({
  mixins: [createStoreMixin(ApplicationStore)],

  getStateFromStores() {
    return {
      admins: ApplicationStore.getAdmins()
    };
  },

  getInitialState() {
    return {
      events: Seq(),
      isLoadingMore: false,
      isRefreshing: false,
      isLoading: false,
      hasMore: true,
      searchQuery: ''
    };
  },

  componentDidMount() {
    this.createEventsService();
    this._events.load();
  },

  componentWillUnmount() {
    this.destroyEventsService();
  },

  render() {
    return (
      <StorageTabs activeTab={routeNames.EVENTS} hasEvents>
        <SearchPanel
          searchFieldPlaceholder="Search events"
          query={this.state.searchQuery}
          onSearch={this.handleQueryChange}
          onSubmit={this.handleSearchSubmit}
          predefinedSearches={eventsPredefinedSearches}
          additionalActions={
            <Tooltip tooltip="Refresh events" placement="top">
              <Button onClick={this.handleRefresh} bsSize="sm">
                <RefreshIcon isLoading={this.state.isLoading} />
              </Button>
            </Tooltip>
          }
        />
        {this.renderTable()}
      </StorageTabs>
    );
  },

  renderTable() {
    return (
      <div className="box">
        <InfiniteScroll
          initialLoad={false}
          hasMore={this.state.hasMore}
          loadMore={this.handleLoadMore}
        >
          <EventsTable
            events={this.state.events}
            admins={this.state.admins}
            isSearching={this.isSearching()}
          />
        </InfiniteScroll>
        {this.renderMoreButton()}
      </div>
    );
  },

  renderMoreButton() {
    if (this.state.events.isEmpty() || !this.state.hasMore || this.isSearching()) {
      return null;
    }

    return <BlockButton onClick={this.handleLoadMore} isLoading={this.state.isLoadingMore} />;
  },

  createEventsService() {
    this._events = defaultEventsFactory();
    this._events.addChangeListener(this.handleChange);
  },

  destroyEventsService() {
    this._events.removeChangeListener(this.handleChange);
    this._events.reset();
  },

  handleChange() {
    const isLoading = this._events.getIsLoading();

    this.setState({
      searchQuery: this._events.getQuery(),
      events: this._events.getEvents(),
      isRefreshing: isLoading && this.state.isRefreshing,
      isLoading,
      isLoadingMore: this._events.getIsLoadingOlder(),
      hasMore: this._events.getHasMore()
    });
  },

  handleRefresh() {
    this.setState({ isRefreshing: true }, () => {
      this._events.load();
    });
  },

  handleLoadMore() {
    if (this.state.isLoadingMore) return;

    this._events.loadMoreMax();
  },

  handleQueryChange(query) {
    this.setState({ searchQuery: query });
  },

  handleSearchSubmit(searchQuery) {
    this._events.setQuery(searchQuery);
    this._events.load();
  },

  isSearching() {
    return this.state.isLoading && !this.state.isRefreshing;
  }
});

export default Events;
