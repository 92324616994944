import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import _ from 'underscore';

import ExternalLink from '../../../react/common/ExternalLink';
import { routeNames } from '../constants';

const ExternalProjectBucketLink = createReactClass({
  propTypes: {
    bucket: PropTypes.object.isRequired,
    urlTemplates: PropTypes.instanceOf(Map).isRequired
  },

  render() {
    const { bucket, urlTemplates } = this.props;
    const projectId = bucket.getIn(['project', 'id']);
    const projectName = bucket.getIn(['project', 'name']);

    return (
      <span>
        <ExternalLink href={_.template(urlTemplates.get('project'))({ projectId })}>
          {projectName}
        </ExternalLink>
        {' / '}
        <ExternalLink
          href={`${_.template(urlTemplates.get('project'))({
            projectId
          })}/${routeNames.ROOT}/${bucket.get('id')}`}
        >
          {bucket.get('id')}
        </ExternalLink>
      </span>
    );
  }
});

export default ExternalProjectBucketLink;
