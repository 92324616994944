import React from 'react';

import { KEBOOLA_SANDBOXES } from '../../../constants/componentIds';
import InfoAlert from '../../../react/common/InfoAlert';
import useStores from '../../../react/hooks/useStores';
import RoutesStore from '../../../stores/RoutesStore';
import InstalledComponentsStore from '../../components/stores/InstalledComponentsStore';
import ReadOnlyDocumentationLink from '../../sandboxes/components/ReadOnlyDocumentationLink';
import SandboxesStore from '../../sandboxes/SandboxesStore';

const WorkspacesReadOnlyStorageAlert = () => {
  const state = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const configData = InstalledComponentsStore.getConfigData(KEBOOLA_SANDBOXES, configId);
      const sandbox = SandboxesStore.getSandbox(configData.getIn(['parameters', 'id']));

      return {
        readOnlyStorageAccess: sandbox.getIn(
          ['workspaceDetails', 'connection', 'readOnlyStorageAccess'],
          false
        )
      };
    },
    [],
    [RoutesStore, InstalledComponentsStore]
  );

  if (!state.readOnlyStorageAccess) {
    return null;
  }

  return (
    <div className="container info-alert-section">
      <InfoAlert>
        <span className="font-medium">Read-Only Access</span> to all project data is granted for
        this workspace. Be cautious when sharing it. <ReadOnlyDocumentationLink />
      </InfoAlert>
    </div>
  );
};

export default WorkspacesReadOnlyStorageAlert;
