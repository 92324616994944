import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStores from '../../../react/hooks/useStores';
import RoutesStore from '../../../stores/RoutesStore';
import TablesStore from '../../components/stores/StorageTablesStore';

const TableTitle = () => {
  const { table, tableName } = useStores(
    () => {
      const routerState = RoutesStore.getRouterState();
      const table = TablesStore.getAll().find((table) => {
        return (
          table.get('name') === routerState.getIn(['params', 'tableName']) &&
          table.getIn(['bucket', 'id']) === routerState.getIn(['params', 'bucketId'])
        );
      });

      return {
        table,
        tableName: routerState.getIn(['params', 'tableName'])
      };
    },
    [],
    [RoutesStore, TablesStore]
  );

  return (
    <>
      <FontAwesomeIcon
        icon="table"
        fixedWidth
        style={{ fontSize: '0.75em' }}
        className="text-muted icon-addon-right"
      />
      {table ? table.get('displayName') : tableName}
    </>
  );
};

export default TableTitle;
