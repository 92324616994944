import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import Link from '../../../../../react/common/RouterLink';

const configurationMovedToTrash = (configuration) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired
    },

    render() {
      return (
        <>
          Configuration{' '}
          <Link
            to="settings-trash"
            onClick={this.props.onClick}
            query={{ q: configuration.get('id') }}
          >
            {configuration.get('name')}
          </Link>{' '}
          has been moved to Trash.
        </>
      );
    }
  });
};

export default configurationMovedToTrash;
