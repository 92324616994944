import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';

import { saveFolderToMetadata } from '../../../modules/components/helpers';
import InstalledComponentsActionCreators from '../../../modules/components/InstalledComponentsActionCreators';
import TableCollapseButton from '../TableCollapseButton';
import FolderName from './FolderName';
import { getRealComponentId } from './helpers';
import type { TableRow } from './Table';

type Props = {
  name: string;
  isExpanded: boolean;
  readOnly: boolean;
  rows: TableRow[];
};

const ConfigurationsFolderName = (props: Props) => {
  return (
    <span className="with-expand flex-container flex-start overflow-break-anywhere">
      <TableCollapseButton entity="folder" isCollapsed={!props.isExpanded} />
      <FontAwesomeIcon
        fixedWidth
        icon={props.isExpanded ? 'folder-open' : 'folder'}
        className="text-muted"
      />
      <FolderName
        name={props.name}
        readOnly={props.readOnly}
        onSave={(folderName) => {
          return Promise.map(
            props.rows,
            (row) => {
              return saveFolderToMetadata(
                getRealComponentId(row.values.data.config, row.values.data.component),
                row.values.data.config.get('id'),
                folderName,
                { skipStoreUpdate: true }
              );
            },
            { concurrency: 3 }
          ).then(() => InstalledComponentsActionCreators.loadComponentsMetadataForce());
        }}
      />
    </span>
  );
};

const MemoizedConfigurationsFolderName = React.memo(ConfigurationsFolderName);

export default MemoizedConfigurationsFolderName;
