import React from 'react';

import { HIDE_BACK_TO_PRODUCTION_WARNING } from '../../../constants/localStorageKeys';
import Checkbox from '../../../react/common/Checkbox';
import ConfirmModal from '../../../react/common/ConfirmModal';
import { getItem, setItem } from '../../../utils/localStorage';
import { switchedFromBranchToProduction } from '../helpers';

const BackToProductionWarning = () => {
  const [isChecked, setIsChecked] = React.useState(false);
  const [showModal, setShowModal] = React.useState(true);

  if (getItem(HIDE_BACK_TO_PRODUCTION_WARNING, false) || !switchedFromBranchToProduction()) {
    return null;
  }

  const onHide = () => {
    if (isChecked) {
      setItem(HIDE_BACK_TO_PRODUCTION_WARNING, true);
    }

    setShowModal(false);
  };

  return (
    <ConfirmModal
      icon="code"
      buttonType="primary"
      buttonLabel="I do understand"
      title="You are back in live production project"
      text={
        <>
          <p>
            Just to let you know, any changes you are going to make now are done with live data and
            confugurations, so it can break stuff.
          </p>
          <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)}>
            Do not show this info again
          </Checkbox>
        </>
      }
      show={showModal}
      onConfirm={onHide}
      onHide={onHide}
    />
  );
};

export default BackToProductionWarning;
