import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  ControlLabel,
  Dropdown,
  FormControl,
  FormGroup,
  Label,
  Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_ORACLE_TRANSFORMATION } from '../../../../../../constants/componentIds';
import Checkbox from '../../../../../../react/common/Checkbox';
import Select from '../../../../../../react/common/Select';
import { getMetadataDataTypes } from '../../../../../wr-db/templates/columnsMetadata';
import { getTableColumnMetadata } from '../../../../utils/tableMetadataHelper';
import { getUnsynchronizedColumns } from '../helpers';
import { generateColumnTypes, getPreferableTypes, isLengthValid } from './helpers';

class DatatypesEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unsynchronizedColumns: List()
    };

    this.handleDatatypes = this.handleDatatypes.bind(this);
    this.updateAllColumns = this.updateAllColumns.bind(this);
    this.updateColumnTypes = this.updateColumnTypes.bind(this);
  }

  componentDidMount() {
    const columnTypes = this.getColumnTypes();
    const storageColumns = this.getStorageTable().get('columns', List());
    const unsynchronizedColumns = getUnsynchronizedColumns(
      this.props.value.get('columns', List()),
      columnTypes.map((column) => column.get('source')),
      storageColumns
    );

    if (!columnTypes.isEmpty() && !unsynchronizedColumns.isEmpty()) {
      this.setState({ unsynchronizedColumns });
      this.syncColumnTypes();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.getColumnTypes().count() > 0 ||
        this.props.componentId === KEBOOLA_ORACLE_TRANSFORMATION) &&
      this.props.value.get('source') &&
      this.props.value.get('source') !== prevProps.value.get('source')
    ) {
      this.setColumnTypes();
    }

    if (
      this.getColumnTypes().count() > 0 &&
      this.props.value.get('columns', List()).count() !==
        prevProps.value.get('columns', List()).count()
    ) {
      this.syncColumnTypes();
    }
  }

  render() {
    const hasColumnTypes = this.getColumnTypes().count() > 0;
    const datatypesOptions = this.props.datatypes
      .sortBy((datatype) => datatype.get('name').toLowerCase())
      .map((datatype) => {
        return {
          label: datatype.get('name'),
          value: datatype.get('name')
        };
      })
      .toArray();
    const columns = this.getColumns();

    return (
      <FormGroup>
        <ControlLabel>Data types</ControlLabel>
        <Select
          clearable={false}
          options={[
            { value: 'none', label: 'None' },
            { value: 'manual', label: 'User defined' }
          ]}
          value={hasColumnTypes ? 'manual' : 'none'}
          onChange={this.handleDatatypes}
          disabled={
            this.props.disabled ||
            !this.props.value.get('source') ||
            this.props.componentId === KEBOOLA_ORACLE_TRANSFORMATION
          }
        />
        {hasColumnTypes && !columns.isEmpty() && (
          <>
            {this.renderUnsavedColumnWarning()}
            <hr />
            <Table className="compact">
              {this.renderHeader(columns, datatypesOptions)}
              {this.renderColumns(columns, datatypesOptions)}
            </Table>
          </>
        )}
      </FormGroup>
    );
  }

  renderHeader(columns, datatypesOptions) {
    const allNullable = columns.every((column) => {
      return this.getNullableValue(this.getSelectedColumnType(column));
    });
    const someNullable =
      !allNullable &&
      columns.some((column) => this.getNullableValue(this.getSelectedColumnType(column)));

    const allEmptyValuesToNull = columns.every((column) => {
      return this.getSelectedColumnType(column).get('convert_empty_values_to_null');
    });
    const someEmptyValuesToNull =
      !allEmptyValuesToNull &&
      columns.some((column) => {
        return this.getSelectedColumnType(column).get('convert_empty_values_to_null');
      });

    return (
      <thead>
        <tr>
          <th className="pl-1">Column</th>
          <th>
            Type
            {this.renderSetColumnsType(datatypesOptions)}
          </th>
          <th>Length</th>
          {this.props.customColumns && this.props.customColumns.header(this.updateAllColumns)}
          <th>
            <Checkbox
              tooltip="Toggle columns"
              checked={allNullable}
              indeterminate={someNullable}
              disabled={allEmptyValuesToNull}
              onChange={() => {
                this.updateAllColumns(
                  'nullable',
                  allNullable || (someNullable && !someEmptyValuesToNull) ? false : true
                );
              }}
            >
              Nullable
            </Checkbox>
          </th>
          <th className="text-center">
            <Checkbox
              tooltip="Toggle columns"
              checked={allEmptyValuesToNull}
              indeterminate={someEmptyValuesToNull}
              onChange={(checked) => this.updateAllColumns('convert_empty_values_to_null', checked)}
            >
              <div className="text-left">
                Convert empty
                <br />
                values to <code>null</code>
              </div>
            </Checkbox>
          </th>
        </tr>
      </thead>
    );
  }

  renderColumns(columns, datatypesOptions) {
    const primaryKey = this.getStorageTable().get('primaryKey', List());

    return (
      <tbody>
        {columns.map((column) => {
          const columnType = this.getSelectedColumnType(column);
          const datatype = this.props.datatypes.get(columnType.get('type'));
          const hasLength = this.hasDatatypeLength(columnType.get('type'));
          const isPrimaryKey = primaryKey.includes(column);

          return (
            <tr
              key={column}
              className={classnames({
                'bg-warning': this.state.unsynchronizedColumns.includes(column)
              })}
            >
              <td className="w-175 pl-1 overflow-break-anywhere">
                <FormControl.Static>
                  {this.renderColumnLabel(column)}
                  {isPrimaryKey && (
                    <Label bsStyle="primary" className="icon-addon-left">
                      PK
                    </Label>
                  )}
                </FormControl.Static>
              </td>
              <td>
                <Select
                  className="w-175"
                  placeholder="Select type..."
                  clearable={false}
                  value={columnType.get('type')}
                  onChange={(selected) => this.updateColumnTypes(column, 'type', selected)}
                  options={datatypesOptions}
                />
              </td>
              <td>
                <FormGroup
                  className="m-0"
                  validationState={isLengthValid(columnType, datatype) ? null : 'error'}
                >
                  <FormControl
                    type="text"
                    className="w-100"
                    placeholder={
                      hasLength
                        ? `eg. ${this.getDefaultDatatypeLength(columnType.get('type'))}`
                        : ''
                    }
                    value={columnType.get('length')}
                    onChange={(e) => this.updateColumnTypes(column, 'length', e.target.value)}
                    disabled={!hasLength}
                  />
                </FormGroup>
              </td>
              {this.props.customColumns &&
                this.props.customColumns.body(column, columnType, this.updateColumnTypes)}
              <td className="text-center">
                <Checkbox
                  checked={this.getNullableValue(columnType)}
                  disabled={columnType.get('convert_empty_values_to_null')}
                  onChange={(checked) => this.updateColumnTypes(column, 'nullable', checked)}
                />
              </td>
              <td className="text-center">
                <Checkbox
                  disabled={isPrimaryKey && !columnType.get('convert_empty_values_to_null')}
                  checked={columnType.get('convert_empty_values_to_null')}
                  onChange={(checked) =>
                    this.updateColumnTypes(column, 'convert_empty_values_to_null', checked)
                  }
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  renderSetColumnsType(datatypesOptions) {
    return (
      <Dropdown id="column-editor-datatype" dropup pullRight>
        <Dropdown.Toggle noCaret bsStyle="link">
          <FontAwesomeIcon icon={['far', 'ellipsis']} className="text-muted" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-1">
          <Select
            clearable={false}
            className="w-200"
            placeholder="Set all columns as..."
            options={datatypesOptions}
            onChange={(selected) => this.updateAllColumns('type', selected)}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderUnsavedColumnWarning() {
    if (this.state.unsynchronizedColumns.isEmpty()) {
      return null;
    }

    return (
      <Alert bsStyle="warning" className="mt-1 mb-0">
        Following table columns in the configuration do not match table columns in Storage:{' '}
        {this.state.unsynchronizedColumns.map((column, index, allColumns) => (
          <React.Fragment key={column}>
            <strong>{column}</strong>
            {!this.getColumns().includes(column) && <>&nbsp;(removed)</>}
            {index + 1 < allColumns.count() && ', '}
          </React.Fragment>
        ))}
        .<br />
        Please re-save your mapping to synchronize it.
      </Alert>
    );
  }

  renderColumnLabel(column) {
    if (this.props.componentId === KEBOOLA_ORACLE_TRANSFORMATION) {
      return column.toUpperCase();
    }

    return column;
  }

  getDefaultColumnTypes(column) {
    return Map({ source: column, destination: column, ...this.props.defaultDatatype });
  }

  getColumnTypes() {
    return this.props.value.get('column_types', List());
  }

  getSelectedColumnType(column) {
    return this.getColumnTypes().find(
      (columnType) => columnType.get('source') === column,
      null,
      fromJS(this.props.defaultDatatype)
    );
  }

  getColumns() {
    if (this.props.value.get('columns', List()).count() > 0) {
      return this.props.value.get('columns');
    }

    return this.getStorageTable().get('columns', List());
  }

  getStorageTable() {
    return this.props.tables.get(this.props.value.get('source'), Map());
  }

  getDefaultDatatypeLength(type) {
    return this.props.datatypes.getIn([type, 'defaultLength'], '');
  }

  getColumnIndex(column) {
    return this.getColumnTypes().findIndex((columnType) => columnType.get('source') === column);
  }

  hasDatatypeLength(type) {
    return this.props.datatypes.getIn([type, 'length'], false);
  }

  updateColumnTypes(column, property, value) {
    const columnIndex = this.getColumnIndex(column);

    if (columnIndex !== -1) {
      return this.props.onChange(
        this.props.value.updateIn(['column_types', columnIndex], (columnType) => {
          return this.updateColumnTypeProperty(columnType, property, value);
        })
      );
    }

    const newTypes = this.getDefaultColumnTypes(column).update((columnType) => {
      return this.updateColumnTypeProperty(columnType, property, value);
    });

    return this.props.onChange(
      this.props.value.update('column_types', List(), (columnTypes) => {
        return columnTypes
          .push(newTypes)
          .sortBy((columnType) => columnType.get('source').toLowerCase());
      })
    );
  }

  updateAllColumns(property, value) {
    const primaryKey = this.getStorageTable().get('primaryKey', List());
    const columnTypes = this.getColumns().map((column) => {
      const columnIndex = this.getColumnIndex(column);

      if (columnIndex !== -1) {
        if (property === 'convert_empty_values_to_null' && primaryKey.includes(column)) {
          return this.props.value.getIn(['column_types', columnIndex]);
        }

        return this.props.value.getIn(['column_types', columnIndex]).update((columnType) => {
          if (
            property === 'nullable' &&
            columnType.get('convert_empty_values_to_null') &&
            value === false
          ) {
            return columnType;
          }

          return this.updateColumnTypeProperty(columnType, property, value);
        });
      }

      return this.getDefaultColumnTypes(column).set(property, value);
    });

    return this.props.onChange(this.props.value.set('column_types', columnTypes));
  }

  updateColumnTypeProperty(columnType, property, value) {
    if (property === 'type') {
      return columnType.set(property, value).set('length', this.getDefaultDatatypeLength(value));
    }

    if (property === 'convert_empty_values_to_null' && value === true) {
      return columnType.set(property, value).set('nullable', true);
    }

    return columnType.set(property, value);
  }

  handleDatatypes(selected) {
    if (selected === 'none') {
      return this.props.onChange(this.props.value.set('column_types', List()));
    }

    this.setColumnTypes();
  }

  setColumnTypes() {
    this.props.onChange(this.props.value.set('column_types', this.getGeneratedColumnTypes()));
  }

  getNullableValue(column) {
    return column.get('nullable', true) || column.get('nullable') === null;
  }

  getGeneratedColumnTypes() {
    return generateColumnTypes(
      this.getColumns(),
      this.props.datatypes,
      getMetadataDataTypes(
        this.props.datatypesComponentId,
        getTableColumnMetadata(this.getStorageTable())
      ),
      this.props.defaultDatatype,
      getPreferableTypes(this.props.datatypesComponentId)
    );
  }

  syncColumnTypes() {
    const generatedColumnTypes = this.getGeneratedColumnTypes();

    return this.props.onChange(
      this.props.value.set(
        'column_types',
        this.getColumns()
          .map((column) => {
            return this.props.value.get('column_types').find(
              (columnType) => columnType.get('source') === column,
              null,
              generatedColumnTypes.find((columnType) => columnType.get('source') === column)
            );
          })
          .filter(Boolean)
      )
    );
  }
}

DatatypesEditor.propTypes = {
  datatypesComponentId: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  datatypes: PropTypes.instanceOf(Map).isRequired,
  defaultDatatype: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  customColumns: PropTypes.shape({
    header: PropTypes.func.isRequired,
    body: PropTypes.func.isRequired
  })
};

export default DatatypesEditor;
