import { SERVICE_OAUTH } from '../../constants/serviceIds';
import ApplicationStore from '../../stores/ApplicationStore';
import request from '../../utils/request';
import ServicesStore from '../services/Store';

function createUrl(path) {
  return ServicesStore.getServiceUrl(SERVICE_OAUTH) + '/' + path;
}

function createRequest(method, path) {
  return request(method, createUrl(path)).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString()
  );
}

export default {
  getAllCredentialsForBranch: function (branchId = null) {
    return createRequest('GET', 'credentials')
      .query({ branchId })
      .promise()
      .then(function (response) {
        return response.body;
      });
  },

  getCredentials: function (componentId, id) {
    return createRequest('GET', 'credentials/' + componentId + '/' + id)
      .promise()
      .then(function (response) {
        return response.body;
      });
  },

  postCredentials: function (componentId, params, data) {
    return createRequest('POST', 'credentials/' + componentId)
      .send({ ...params, data })
      .promise()
      .then(function (response) {
        return response.body;
      });
  },

  deleteCredentials: function (componentId, id) {
    return createRequest('DELETE', 'credentials/' + componentId + '/' + id).promise();
  }
};
