import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import AuthorizationRow from '../../../oauth-v2/react/AuthorizationRow';
import OAuthStore from '../../../oauth-v2/Store';
import configurationsActions from '../../ConfigurationsActionCreators';

const OauthSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      oauthId: PropTypes.string.isRequired,
      componentId: PropTypes.string.isRequired,
      configurationId: PropTypes.string.isRequired
    }),
    disabled: PropTypes.bool.isRequired,
    admins: PropTypes.instanceOf(Map).isRequired
  },

  render() {
    return (
      <AuthorizationRow
        readOnly={this.props.disabled}
        configId={this.props.value.configurationId}
        componentId={this.props.value.componentId}
        credentials={OAuthStore.getCredentialsById(
          this.props.value.componentId,
          this.props.value.oauthId
        )}
        onResetCredentials={this.resetOauthCredentials}
        admins={this.props.admins}
      />
    );
  },

  resetOauthCredentials() {
    return configurationsActions.resetOauthCredentials(
      this.props.value.componentId,
      this.props.value.configurationId
    );
  }
});

export default OauthSection;
