import React from 'react';

import InfoAlert from '../../../../react/common/InfoAlert';
import useStores from '../../../../react/hooks/useStores';
import RoutesStore from '../../../../stores/RoutesStore';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';
import ReadOnlyDocumentationLink from '../../../sandboxes/components/ReadOnlyDocumentationLink';
import WorkspacesStore from '../../../workspaces/WorkspacesStore';

const ReadOnlyStorageAlert = () => {
  const state = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('config');
      const configData = InstalledComponentsStore.getConfigData(componentId, configId);
      const workspaceCredentials = WorkspacesStore.getConfigurationWorkspaceByConnectionUser(
        componentId,
        configId,
        configData.getIn(['parameters', 'db', 'user'])
      );

      return {
        readOnlyStorageAccess: workspaceCredentials.get('readOnlyStorageAccess', false)
      };
    },
    [],
    [RoutesStore, InstalledComponentsStore]
  );

  if (!state.readOnlyStorageAccess) {
    return null;
  }

  return (
    <div className="container info-alert-section">
      <InfoAlert>
        <span className="font-medium">Read-Only Access</span> to all project data is granted for the
        workspace used in this configuration. Be cautious when sharing it.{' '}
        <ReadOnlyDocumentationLink />
      </InfoAlert>
    </div>
  );
};

export default ReadOnlyStorageAlert;
