import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import CodeEditor from '../../../../react/common/CodeEditor';

const JsonConfigurationInput = createReactClass({
  propTypes: {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    return (
      <div className="edit form-group">
        <CodeEditor
          value={this.props.value}
          onChange={this.props.onChange}
          options={{
            placeholder: 'Your JSON config goes here...',
            lint: true,
            readOnly: this.props.disabled
          }}
        />
      </div>
    );
  }
});

export default JsonConfigurationInput;
