import React from 'react';
import classNames from 'classnames';

import RouterLink from './RouterLink';

const TabLink = (props: {
  to: string;
  active: boolean;
  children: React.ReactNode;
  params?: Record<string, any>;
  query?: Record<string, any>;
  hash?: string;
  className?: string;
}) => {
  return (
    <li role="presentation" className={classNames(props.className, { active: props.active })}>
      <RouterLink
        role="tab"
        to={props.to}
        params={props.params}
        query={props.query}
        hash={props.hash}
      >
        {props.children}
      </RouterLink>
    </li>
  );
};

export default TabLink;
