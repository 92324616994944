import React, { type ReactNode } from 'react';

import Tooltip from './Tooltip';

export const READ_ONLY_TOOLTIP_MESSAGE = 'You cannot make changes in read only mode.';

type Props = { children: ReactNode; readOnly: boolean };

const ReadOnlyTooltip = ({ children, readOnly }: Props) => {
  return (
    <Tooltip
      placement="top"
      tooltip={READ_ONLY_TOOLTIP_MESSAGE}
      forceHide={!readOnly}
      type="explanatory"
    >
      {children}
    </Tooltip>
  );
};

export default ReadOnlyTooltip;
