import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import Clipboard from '../../../react/common/Clipboard';

const RunIdTooltip = ({ job }: { job: Map<string, any> }) => {
  return (
    <Clipboard
      text={job.get('runId')}
      label={
        <span className="f-12 text-muted font-normal">
          Run ID
          <FontAwesomeIcon icon="copy" className="f-12" />
        </span>
      }
      tooltipText={
        <>
          <div className="tooltip-title">Run ID</div>
          <div className="overflow-break-anywhere">{job.get('runId')}</div>
          <small>Click to copy</small>
        </>
      }
      tooltipPlacement="top"
      tooltipType="explanatory"
      className="run-id-tooltip"
      showIcon={false}
    />
  );
};

export default RunIdTooltip;
