import React from 'react';
import createReactClass from 'create-react-class';

import {
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_SANDBOXES_PYTHON_MLFLOW
} from '../../constants/features';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import StackFeaturesStore from '../stack-features/Store';
import DeployModelButton from './components/DeployModelButton';
import ModelsStore from './ModelsStore';

const HeaderButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, StackFeaturesStore, ModelsStore)],

  getStateFromStores() {
    return {
      readOnly: ApplicationStore.isReadOnly(),
      showDummyPreview:
        !ApplicationStore.hasCurrentProjectFeature(FEATURE_SANDBOXES_PYTHON_MLFLOW) &&
        !StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT),
      allModels: ModelsStore.getAll(),
      isLoading: ModelsStore.getIsLoading()
    };
  },

  render() {
    if (this.state.readOnly || this.state.showDummyPreview) {
      return null;
    }

    return <DeployModelButton allModels={this.state.allModels} isLoading={this.state.isLoading} />;
  }
});

export default HeaderButtons;
