import React from 'react';
import PropTypes from 'prop-types';
import { Well } from 'react-bootstrap';
import classnames from 'classnames';
import _ from 'underscore';

import ExternalLink from '../../../react/common/ExternalLink';

const Help = ({ title, text, documentation, style, down }) => {
  return (
    <Well className={classnames('step-help', { 'is-down': down })} style={style}>
      {title && <h3>{title}</h3>}
      {_.isString(text) ? <p>{text}</p> : text}
      {documentation && (
        <ExternalLink className="display-block mt-2 f-12 font-medium" href={documentation}>
          Read more in documentation
        </ExternalLink>
      )}
    </Well>
  );
};

Help.propTypes = {
  text: PropTypes.node.isRequired,
  documentation: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  down: PropTypes.bool
};

Help.defaultProps = {
  down: false
};

export default Help;
