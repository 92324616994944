import React from 'react';
import PropTypes from 'prop-types';
import escapeStringRegexp from 'escape-string-regexp';

const MarkedText = ({ source, mark, className, isCaseSensitive }) => {
  if (!mark || !mark.trim()) {
    return <span className={className}>{source}</span>;
  }

  let parts = source.split(
    new RegExp(
      '(' + escapeStringRegexp(mark).split(' ').filter(Boolean).join('|') + ')',
      `g${isCaseSensitive ? '' : 'i'}`
    )
  );

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = <mark key={i}>{parts[i]}</mark>;
  }

  return <span className={className}>{parts}</span>;
};

MarkedText.propTypes = {
  source: PropTypes.string.isRequired,
  className: PropTypes.string,
  mark: PropTypes.string,
  isCaseSensitive: PropTypes.bool
};

export default MarkedText;
