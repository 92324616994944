import React from 'react';
import { Alert } from 'react-bootstrap';

const IncrementalResetWarning = () => {
  return (
    <Alert bsStyle="danger">
      <p>
        <span className="alert-title">
          Warning: The date of last import will be cleared for all tables in the entire
          configuration.
        </span>
        Running this part of the configuration will cause incremental processing to be reset. The
        next job of the configuration will process all source data without filter and may run
        considerably longer. To avoid this, run only the entire configuration.
      </p>
    </Alert>
  );
};

export default IncrementalResetWarning;
