import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import ChangedSinceInput from '../../../../react/common/ChangedSinceInput';
import PanelWithDetails from '../../../../react/common/PanelWithDetails';
import StorageApiTableLinkEx from '../../../components/react/components/StorageApiTableLinkEx';

const InputMapping = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      source: PropTypes.string.isRequired,
      changedSince: PropTypes.string.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    const panelExpanded = this.props.value.changedSince !== '';

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Source</h3>
        </div>
        <div className="box-content with-panel">
          <FormGroup>
            <Col componentClass={ControlLabel} sm={4}>
              Table
            </Col>
            <Col sm={8}>
              <FormControl.Static>
                <StorageApiTableLinkEx tableId={this.props.value.source} />
              </FormControl.Static>
            </Col>
          </FormGroup>
          <PanelWithDetails
            defaultExpanded={panelExpanded}
            placement="bottom"
            labelCollapse="Hide Advanced Options"
            labelOpen="Show Advanced Options"
          >
            <FormGroup>
              <Col componentClass={ControlLabel} sm={4}>
                Changed In Last
              </Col>
              <Col sm={8}>
                <ChangedSinceInput
                  value={this.props.value.changedSince}
                  onChange={(value) => this.props.onChange({ changedSince: value })}
                  disabled={this.props.disabled}
                  tableId={this.props.value.source}
                />
              </Col>
            </FormGroup>
          </PanelWithDetails>
        </div>
      </div>
    );
  }
});

export default InputMapping;
