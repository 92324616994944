import React from 'react';

import ExternalLink from './ExternalLink';

const ComponentLicence = (props: { href?: string }) => {
  if (!props.href) {
    return null;
  }

  return (
    <p className="text-muted">
      You agree to the{' '}
      <ExternalLink href={props.href}>vendor&apos;s license agreement</ExternalLink> by using this
      component.
    </p>
  );
};

export default ComponentLicence;
