import React from 'react';
import createReactClass from 'create-react-class';
import { fromJS } from 'immutable';

import { KEBOOLA_SANDBOXES } from '../../../constants/componentIds';
import { FEATURE_TRANSFORMATION_PARALLEL_UNLOADS } from '../../../constants/features';
import { HIDE_DASHBOARD_WELCOME_WIDGET } from '../../../constants/localStorageKeys';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import { getItem, setItem } from '../../../utils/localStorage';
import { canManageUsers } from '../../admin/privileges';
import CreditsBalanceBox from '../../billing/react/CreditsBalanceBox';
import ManageSubscriptionBox from '../../billing/react/ManageSubscriptionBox';
import BillingStore from '../../billing/store';
import ComponentsStore from '../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../components/stores/InstalledComponentsStore';
import DevBranchDescription from '../../dev-branches/components/DevBranchDescription';
import DevBranchesStore from '../../dev-branches/DevBranchesStore';
import CliActivityBox from '../../keboola-as-code/CliActivityBox';
import TransformationBucketsStore from '../../transformations/stores/TransformationBucketsStore';
import TransformationsStore from '../../transformations/stores/TransformationsStore';
import { ACTIVITY_TYPES, DEV_BRANCH_EVENTS } from '../constants';
import HomeStore from '../store';
import AccessPanel from './components/AccessPanel';
import ActiveUsers from './components/ActiveUsers';
import ActiveUsersSimple from './components/ActiveUsersSimple';
import AlterNatNotification from './components/AlterNatNotification';
import DemoPreviewDashboard from './components/DemoPreviewDashboard';
import DeprecatedFacebookApi from './components/DeprecatedFacebookApi';
import LastErrorsPanel from './components/LastErrorsPanel';
import LatestActivity from './components/LatestActivity';
import LatestConfigurationEdits from './components/LatestConfigurationEdits';
import NoCreditsEmptyPage from './components/NoCreditsEmptyPage';
import StoragePanel from './components/StoragePanel';
import WelcomeWidget from './components/WelcomeWidget';
import AdblockDetector from './AdblockDetector';
import DeprecatedComponents from './DeprecatedComponents';
import Expiration from './Expiration';
import ProjectDescriptionBox from './ProjectDescriptionBox';
import TransformationParallelUnloads from './TransformationParallelUnloads';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      TransformationsStore,
      TransformationBucketsStore,
      HomeStore,
      BillingStore,
      DevBranchesStore
    )
  ],

  getStateFromStores() {
    const project = ApplicationStore.getCurrentProject();
    const sizeBytes = project.getIn(['metrics', 'storage.dataSizeBytes', 'value'], 0);
    const rowsCount = project.getIn(['metrics', 'storage.rowsCount', 'value'], 0);
    const hasNewQueue = ApplicationStore.hasNewQueue();
    const latestJobs = HomeStore.getJobs();

    return {
      sizeBytes,
      rowsCount,
      hasNewQueue,
      latestJobs,
      tokens: ApplicationStore.getTokenStats(),
      admins: ApplicationStore.getAdmins(),
      sapiToken: ApplicationStore.getSapiToken(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      expires: project.get('expires'),
      allComponents: ComponentsStore.getAll(),
      installedComponents: InstalledComponentsStore.getAll(),
      transformations: TransformationsStore.getAllTransformations(),
      transformationBuckets: TransformationBucketsStore.getAll(),
      latestActivity: HomeStore.getLatestActivity({ hasNewQueue }),
      latestGitEvent: HomeStore.getLatestGitEvent(),
      lastSeen: HomeStore.getLastSeen(),
      adminsInvitedToProject: ApplicationStore.getAdminsInvitedToProject(),
      isMarketplaceProject: BillingStore.isMarketplaceProject(),
      marketplaceSubscriptionLink: BillingStore.getMarketplaceSubscriptionLink(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      hasFlows: ApplicationStore.hasFlows(),
      hasSnowflakeDynamicBackendSize: ApplicationStore.hasSnowflakeDynamicBackendSize(),
      hasJobsDynamicBackendSize: ApplicationStore.hasJobsDynamicBackendSize(),
      remainingCredits: BillingStore.getRemainingCredits(),
      isLoadingCredits: BillingStore.isLoadingCredits(),
      readOnly: ApplicationStore.isReadOnly(),
      isDemoPreview: ApplicationStore.isDemoPreview(),
      isProjectDescriptionHidden: HomeStore.isProjectDescriptionHidden(),
      projectDescription: DevBranchesStore.getProjectDescription(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      currentDevBranch: DevBranchesStore.getCurrentDevBranch()
    };
  },

  getInitialState() {
    return {
      hideWelcomeWidget: getItem(HIDE_DASHBOARD_WELCOME_WIDGET, false)
    };
  },

  render() {
    if (this.state.isDemoPreview) {
      return <DemoPreviewDashboard allComponents={this.state.allComponents} />;
    }

    if (
      this.state.hasPayAsYouGo &&
      this.state.remainingCredits <= 0 &&
      !this.state.isLoadingCredits
    ) {
      return (
        <NoCreditsEmptyPage
          credits={this.state.remainingCredits}
          marketplaceSubscriptionLink={this.state.marketplaceSubscriptionLink}
          isMarketplaceProject={this.state.isMarketplaceProject}
        />
      );
    }

    return (
      <div className="section-dashboard">
        {this.renderOverviewAlerts()}
        {this.renderProjectDescription()}
        {this.renderBoxes()}
        <LatestActivity
          activity={this.prepareActivity()}
          admins={this.state.admins}
          lastSeen={this.state.lastSeen}
          hasNewQueue={this.state.hasNewQueue}
          hasFlows={this.state.hasFlows}
          hasPayAsYouGo={this.state.hasPayAsYouGo}
          hasSnowflakeDynamicBackendSize={this.state.hasSnowflakeDynamicBackendSize}
          hasJobsDynamicBackendSize={this.state.hasJobsDynamicBackendSize}
        />
      </div>
    );
  },

  prepareActivity() {
    if (this.state.isDevModeActive && this.state.latestActivity.count() === 0) {
      return fromJS([
        {
          type: ACTIVITY_TYPES.BRANCH,
          data: {
            created: this.state.currentDevBranch.get('created'),
            token: this.state.currentDevBranch.get('creatorToken'),
            event: DEV_BRANCH_EVENTS.CREATED,
            params: {
              devBranchName: this.state.currentDevBranch.get('name')
            },
            id: this.state.currentDevBranch.get('id')
          }
        }
      ]);
    }
    return this.state.latestActivity.filter((row) => {
      if (row.get('type') !== ACTIVITY_TYPES.CONFIGURATION) {
        return true;
      }

      return (
        row.getIn(['data', 'params', 'component']) !== KEBOOLA_SANDBOXES ||
        row.getIn(['data', 'token', 'name']) === this.state.currentAdmin.get('email')
      );
    });
  },

  renderOverviewAlerts() {
    return (
      <>
        <AdblockDetector />
        <AlterNatNotification
          readOnly={this.state.readOnly}
          components={this.state.installedComponents}
        />
        <Expiration expires={this.state.expires} />
        {!ApplicationStore.hasNewQueue() &&
          !ApplicationStore.hasNewTransformationsOnly() &&
          !ApplicationStore.hasCurrentProjectFeature(FEATURE_TRANSFORMATION_PARALLEL_UNLOADS) && (
            <TransformationParallelUnloads
              transformationBuckets={this.state.transformationBuckets}
              transformations={this.state.transformations}
            />
          )}
        <DeprecatedComponents components={this.state.installedComponents} />
        <DeprecatedFacebookApi components={this.state.installedComponents} />
      </>
    );
  },

  renderProjectDescription() {
    if (
      !this.state.projectDescription ||
      (this.state.isProjectDescriptionHidden && !this.state.readOnly)
    ) {
      return null;
    }

    return (
      <ProjectDescriptionBox
        readOnly={this.state.readOnly}
        description={this.state.projectDescription}
      />
    );
  },

  renderBoxes() {
    if (this.state.hasPayAsYouGo) {
      return (
        <div className="section-boxes box-separator">
          {this.state.hideWelcomeWidget || !this.state.hasNewQueue || !this.state.hasFlows ? (
            <div className="vertical-boxes">
              <LastErrorsPanel jobs={this.state.latestJobs} hasNewQueue={this.state.hasNewQueue} />
              {!this.state.isDevModeActive && !this.state.readOnly && (
                <ActiveUsersSimple usersCount={this.state.admins.count()} />
              )}
            </div>
          ) : (
            <WelcomeWidget
              onDismiss={() => {
                this.setState({ hideWelcomeWidget: true });
                setItem(HIDE_DASHBOARD_WELCOME_WIDGET, true);
              }}
            />
          )}
          <LatestConfigurationEdits
            currentAdmin={this.state.currentAdmin}
            activities={this.state.latestActivity}
            isDevModeActive={this.state.isDevModeActive}
            hasFlows={this.state.hasFlows}
          />
          <div className="vertical-boxes">{this.renderInfoBoxes()}</div>
        </div>
      );
    }

    return (
      <div className="section-boxes box-separator">
        <div className="vertical-boxes">{this.renderInfoBoxes()}</div>
        <LatestConfigurationEdits
          currentAdmin={this.state.currentAdmin}
          activities={this.state.latestActivity}
          isDevModeActive={this.state.isDevModeActive}
          hasFlows={this.state.hasFlows}
        />
        {this.renderActiveUsers()}
      </div>
    );
  },

  renderActiveUsers() {
    return (
      <ActiveUsers
        readOnly={!canManageUsers(this.state.sapiToken) || this.state.isDevModeActive}
        activities={this.state.latestActivity}
        admins={this.state.admins}
        currentAdmin={this.state.currentAdmin}
        adminsInvitedToProject={this.state.adminsInvitedToProject}
      />
    );
  },

  renderInfoBoxes() {
    if (this.state.isDevModeActive) {
      return (
        <DevBranchDescription branch={this.state.currentDevBranch} readOnly={this.state.readOnly} />
      );
    }

    return (
      <>
        {!this.state.latestGitEvent.isEmpty() ? (
          <CliActivityBox latestEvent={this.state.latestGitEvent} />
        ) : (
          <StoragePanel rowsCount={this.state.rowsCount} sizeBytes={this.state.sizeBytes} />
        )}
        {this.renderInfoBottomBox()}
      </>
    );
  },

  renderInfoBottomBox() {
    if (this.state.isMarketplaceProject && !this.state.readOnly) {
      return (
        <ManageSubscriptionBox
          marketplaceSubscriptionLink={this.state.marketplaceSubscriptionLink}
        />
      );
    }

    if (this.state.hasPayAsYouGo) {
      return (
        <CreditsBalanceBox
          creditsCount={this.state.remainingCredits}
          isLoadingCredits={this.state.isLoadingCredits}
        />
      );
    }

    return (
      <AccessPanel
        totalTokenCount={this.state.tokens.get('totalCount')}
        adminTokenCount={this.state.tokens.get('adminCount')}
      />
    );
  }
});

export default Index;
