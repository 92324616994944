import { fromJS, Map } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_CSV_IMPORT } from '../../constants/componentIds';
import importApi from '../components/ImportApi';
import componentsActions from '../components/InstalledComponentsActionCreators';
import { MetadataKeys } from '../components/MetadataConstants';
import storageApiActions from '../components/StorageActionCreators';
import storageApi from '../components/StorageApi';
import installedComponentsStore from '../components/stores/InstalledComponentsStore';
import bucketsStore from '../components/stores/StorageBucketsStore';
import tablesStore from '../components/stores/StorageTablesStore';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import storeProvisioning from './storeProvisioning';
import { createConfiguration } from './utils';

export default function (configId) {
  const store = storeProvisioning(configId);

  function updateLocalState(path, data) {
    const ls = installedComponentsStore.getLocalState(KEBOOLA_CSV_IMPORT, configId);
    const newLocalState = ls.setIn([].concat(path), data);
    componentsActions.updateLocalState(KEBOOLA_CSV_IMPORT, configId, newLocalState, path);
  }

  function removeFromLocalState(path) {
    const ls = installedComponentsStore.getLocalState(KEBOOLA_CSV_IMPORT, configId);
    const newLocalState = ls.deleteIn([].concat(path));
    componentsActions.updateLocalState(KEBOOLA_CSV_IMPORT, configId, newLocalState, path);
  }

  function getLocalState() {
    return installedComponentsStore.getLocalState(KEBOOLA_CSV_IMPORT, configId);
  }

  function editReset() {
    removeFromLocalState(['isChanged']);
    removeFromLocalState(['settings']);
    removeFromLocalState(['isDestinationEditing']);
  }

  function setFile(file) {
    updateLocalState(['file'], file);
  }

  function resetFileInput() {
    updateLocalState(['fileInputKey'], getLocalState().get('fileInputKey', 0) + 1);
  }

  function editChange(newSettings) {
    updateLocalState(['settings'], newSettings);
    if (!getLocalState().get('isChanged', false)) {
      updateLocalState(['isChanged'], true);
    }
  }

  function destinationEdit() {
    updateLocalState(['isDestinationEditing'], true);
    if (!getLocalState().get('isChanged', false)) {
      updateLocalState(['isChanged'], true);
    }
  }

  function editSave() {
    const localState = getLocalState();
    const config = createConfiguration(
      localState.get('settings', Map()),
      configId,
      DevBranchesStore.getCurrentId()
    );
    removeFromLocalState(['isDestinationEditing']);
    updateLocalState(['isSaving'], true);
    return componentsActions
      .saveComponentConfigData(KEBOOLA_CSV_IMPORT, configId, config)
      .then(() => {
        removeFromLocalState(['settings']);
        removeFromLocalState(['isSaving']);
        removeFromLocalState(['isChanged']);
      });
  }

  function resetForm() {
    resetFileInput();
    removeFromLocalState(['file']);
  }

  function resetUploadState() {
    removeFromLocalState(['uploadingMessage']);
    updateLocalState(['isUploading'], false);
  }

  function resultSuccess(message) {
    updateLocalState(['resultState'], 'success');
    updateLocalState(['resultMessage'], message);
  }

  function resultError(message) {
    updateLocalState(['resultState'], 'error');
    updateLocalState(['resultMessage'], message);
  }

  function dismissResult() {
    removeFromLocalState(['resultState']);
    removeFromLocalState(['resultMessage']);
  }

  function startUpload() {
    updateLocalState(['isUploading'], true);
    updateLocalState(['uploadingMessage'], 'Uploading');

    const file = getLocalState().get('file');
    const reportProgress = _.throttle((percent) => {
      if (percent < 100) {
        updateLocalState(['uploadingProgress'], percent);
      }
    }, 800);

    reportProgress(1);
    importApi
      .fileUpload(file, {}, (e) => reportProgress(1 + 0.59 * e.percent))
      .then((response) => {
        updateLocalState(['uploadingMessage'], 'Uploading to S3');

        const fileId = response.id;
        const tableId = store.settings.get('destination');
        const bucketId = tableId.substring(0, tableId.lastIndexOf('.'));
        const tableName = tableId.substring(tableId.lastIndexOf('.') + 1);

        const createBucketMetadata = () => {
          const bucketMetadata = fromJS({
            [MetadataKeys.CREATED_BY_COMPONENT_ID]: KEBOOLA_CSV_IMPORT,
            [MetadataKeys.CREATED_BY_CONFIGURATION_ID]: configId
          });
          return storageApi.saveBucketMetadata(bucketId, bucketMetadata, 'system');
        };

        const createTableMetadata = () => {
          const tableMetadata = fromJS({
            [MetadataKeys.CREATED_BY_COMPONENT_ID]: KEBOOLA_CSV_IMPORT,
            [MetadataKeys.CREATED_BY_CONFIGURATION_ID]: configId
          });
          return storageApi.saveTableMetadata(tableId, tableMetadata, 'system');
        };

        const updateTableMetadata = () => {
          const tableMetadata = fromJS({
            [MetadataKeys.LAST_UPDATED_BY_COMPONENT_ID]: KEBOOLA_CSV_IMPORT,
            [MetadataKeys.LAST_UPDATED_BY_CONFIGURATION_ID]: configId
          });
          return storageApi.saveTableMetadata(tableId, tableMetadata, 'system');
        };

        const createTable = () => {
          updateLocalState(['uploadingMessage'], 'Creating table ' + tableId);
          reportProgress(75);

          return storageApiActions
            .createTable(bucketId, {
              name: tableName,
              dataFileId: fileId,
              enclosure: store.settings.get('enclosure', '"'),
              delimiter: store.settings.get('delimiter', ','),
              primaryKey: store.settings.get('primaryKey').join(',')
            })
            .then(() => {
              createTableMetadata();
              resetUploadState();
              resetForm();
              resultSuccess('CSV file successfully imported.');
              return null;
            })
            .catch((e) => {
              resetUploadState();
              resultError(e);
            });
        };

        if (!bucketsStore.hasBucket(bucketId)) {
          updateLocalState(['uploadingMessage'], 'Creating bucket ' + bucketId);
          reportProgress(60);

          storageApiActions
            .createBucket({
              name: bucketId.substring(bucketId.indexOf('-') + 1),
              displayName: bucketId.substring(bucketId.indexOf('-') + 1),
              stage: bucketId.substring(0, bucketId.lastIndexOf('.'))
            })
            .then(createBucketMetadata)
            .then(createTable)
            .catch((e) => {
              resetUploadState();
              resultError(e);
            });
        } else if (tablesStore.hasTable(tableId)) {
          updateLocalState(['uploadingMessage'], 'Loading into table ' + tableId);
          reportProgress(90);

          storageApiActions
            .loadTable(tableId, {
              dataFileId: fileId,
              enclosure: store.settings.get('enclosure', '"'),
              delimiter: store.settings.get('delimiter', ','),
              incremental: store.settings.get('incremental', false) ? 1 : 0
            })
            .then(() => {
              updateTableMetadata();
              resetUploadState();
              resetForm();
              resultSuccess('CSV file successfully imported.');
              return null;
            })
            .catch((e) => {
              resetUploadState();
              resultError(e);
            });
        } else {
          createTable();
        }
        return null;
      })
      .catch((error) => {
        resetUploadState();
        resultError(error.message ? error.message : error);
      });
  }

  return {
    startUpload,
    editReset,
    editSave,
    setFile,
    editChange,
    dismissResult,
    destinationEdit
  };
}
