import { fromJS } from 'immutable';

export default fromJS({
  INT: {
    name: 'INT',
    basetype: 'INTEGER',
    size: false
  },
  FLOAT: {
    name: 'FLOAT',
    basetype: 'FLOAT',
    size: false
  },
  BOOL: {
    name: 'BOOL',
    basetype: 'BOOLEAN',
    size: false
  },
  VARCHAR: {
    name: 'VARCHAR',
    basetype: 'STRING',
    size: true,
    maxLength: 1000000000
  },
  TIMESTAMP: {
    name: 'TIMESTAMP',
    basetype: 'TIMESTAMP',
    size: false
  },
  DATE: {
    name: 'DATE',
    basetype: 'DATE',
    size: false
  }
});
