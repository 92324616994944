import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, type Map } from 'immutable';

import ConfirmModal from '../../../react/common/ConfirmModal';
import Loader from '../../../react/common/Loader';
import RowActionMenuItem from '../../../react/common/RowActionMenuItem';
import Tooltip from '../../../react/common/Tooltip';
import { deployApp } from '../actions';

const DeployDataApp = (props: {
  mode: 'sidebar' | 'menuitem';
  config: Map<string, any>;
  sandbox: Map<string, any>;
  readOnly: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}) => {
  const [deploying, setDeploying] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const isActive = props.sandbox.get('active', false);
  const isConfigured =
    !!props.config.getIn(['configuration', 'parameters', 'dataApp', 'git', 'repository']) ||
    !!props.config.getIn(['configuration', 'parameters', 'script'], List()).join('').trim();
  const isDisabled = props.readOnly || !isConfigured || deploying;

  const handleDeploy = () => {
    setDeploying(true);
    return deployApp(props.config, props.sandbox).finally(() => setDeploying(false));
  };

  const handleClick = () => {
    isActive ? setShowConfirmModal(true) : handleDeploy();
  };

  const label = `${props.sandbox.isEmpty() ? 'Deploy' : 'Redeploy'} Data App`;

  const renderDeployAction = () => {
    if (props.mode === 'sidebar') {
      return (
        <Button block bsStyle="success" disabled={isDisabled} onClick={handleClick}>
          {deploying ? (
            <Loader className="icon-addon-right" />
          ) : (
            <FontAwesomeIcon icon="play-circle" className="icon-addon-right" />
          )}
          {label}
        </Button>
      );
    }

    return (
      <RowActionMenuItem onSelect={handleClick} disabled={isDisabled} onKeyDown={props.onKeyDown}>
        {deploying ? <Loader /> : <FontAwesomeIcon icon="play-circle" fixedWidth />}
        {label}
      </RowActionMenuItem>
    );
  };

  return (
    <>
      {isConfigured ? (
        renderDeployAction()
      ) : (
        <Tooltip tooltip="Data App is not configured" placement="top">
          <span>{renderDeployAction()}</span>
        </Tooltip>
      )}
      {isActive && (
        <ConfirmModal
          closeAfterResolve
          isLoading={deploying}
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          icon="play-circle"
          title={label}
          buttonLabel={label}
          text={<p>App is currently running. Are you sure you want to deploy new version?</p>}
          onConfirm={handleDeploy}
        />
      )}
    </>
  );
};

export default DeployDataApp;
