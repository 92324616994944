import React, { useState } from 'react';
import { Alert, Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { COLLAPSED_ALERTS } from '../../constants/localStorageKeys';
import Tooltip from '../../react/common/Tooltip';
import { getItem, setItem } from '../../utils/localStorage';

type Props = {
  title: string;
  id: string;
  bsStyle?: 'info' | 'warning';
  className?: string;
  children: React.ReactNode;
};

const CollapsibleAlert = ({
  title,
  id,
  bsStyle = 'warning',
  className = 'row-alert',
  children
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(getItem(`${COLLAPSED_ALERTS}-${id}`, false));
  const toggle = (event: React.MouseEvent) => {
    event.stopPropagation();

    setIsCollapsed(!isCollapsed);
    setItem(`${COLLAPSED_ALERTS}-${id}`, !isCollapsed);
    (document.activeElement as HTMLElement | null)?.blur();
  };

  return (
    <Alert bsStyle={bsStyle} className={classnames('alert-no-icon alert-collapsible', className)}>
      <h2 className="mb-0 flex-container clickable f-14" onClick={toggle}>
        <div className="flex-container flex-start">
          {bsStyle === 'info' ? (
            <FontAwesomeIcon icon="circle-info" className="color-primary mrp-3" />
          ) : (
            <FontAwesomeIcon icon="triangle-exclamation" className="color-warning mrp-3" />
          )}
          <span className="line-height-24 font-medium">{title}</span>
        </div>
        <Tooltip placement="top" tooltip={isCollapsed ? 'Expand alert' : 'Collapse alert'}>
          <Button bsStyle="link" className="ml-1 btn-link-muted btn-link-inline" onClick={toggle}>
            <FontAwesomeIcon icon={['far', isCollapsed ? 'angle-down' : 'angle-up']} />
          </Button>
        </Tooltip>
      </h2>
      <Collapse in={!isCollapsed}>
        <div>
          <div className="alert-collapsible-body">{children}</div>
        </div>
      </Collapse>
    </Alert>
  );
};

export default CollapsibleAlert;
