import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import AutomaticLoadTypeLastUpdated from '../../../../../react/common/AutomaticLoadTypeLastUpdated';
import changedSinceConstants from '../../../../../react/common/changedSinceConstants';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import InstalledComponentsActions from '../../../../components/InstalledComponentsActionCreators';
import FiltersDescription from '../../../../components/react/components/generic/FiltersDescription';
import InstalledComponentsStore from '../../../../components/stores/InstalledComponentsStore';
import StorageTablesStore from '../../../../components/stores/StorageTablesStore';
import columnTypeValidation from '../../../../components/utils/columnTypeValidation';
import WrDbActions from '../../../actionCreators';
import { supportConfigRows } from '../../../helpers';
import WrDbStore from '../../../store';
import * as columnsMetadata from '../../../templates/columnsMetadata';
import { getComponentDataTypes, getDisabledColumnFields } from '../../../templates/dataTypes';
import V2Actions from '../../../v2-actions';
import ColumnsEditor from '../../components/ColumnsEditor';
import IncrementalSetupModal from './IncrementalSetupModal';
import { getSizeParam, prepareColumns, prepareEditingColumns } from './tableHelpers';
import TableNameEdit from './TableNameEdit';

const Table = (componentId) => {
  return createReactClass({
    mixins: [
      createStoreMixin(ApplicationStore, WrDbStore, InstalledComponentsStore, StorageTablesStore)
    ],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const tableId = RoutesStore.getCurrentRouteParam('tableId');
      const tableConfig = WrDbStore.getTableConfig(componentId, configId, tableId);
      const storageTable = StorageTablesStore.getTable(tableId, Map());
      const storageTableColumns = storageTable.get('columns', List());
      const localState = InstalledComponentsStore.getLocalState(componentId, configId);
      const tablesExportInfo = WrDbStore.getTables(componentId, configId);
      const exportInfo = tablesExportInfo.find((tab) => tab.get('id') === tableId);
      const isUpdatingTable = WrDbStore.isUpdatingTable(componentId, configId, tableId);
      const isSavingColumns = !!WrDbStore.getUpdatingColumns(componentId, configId, tableId);
      const v2Actions = V2Actions(configId, componentId);
      const editingData = WrDbStore.getEditing(componentId, configId);
      const columnsValidation = editingData.getIn(['validation', tableId], Map());
      const columnsData = editingData.getIn(['columns', tableId]) || Map();
      const columns = prepareColumns(tableConfig.get('columns'), storageTableColumns);
      const editingColumns = prepareEditingColumns(columns, columnsData);
      const hasChanges = !prepareEditingColumns(columns, Map()).equals(editingColumns);

      return {
        storageTable,
        allTables: StorageTablesStore.getAll(),
        columnsValidation,
        editingColumns,
        hasChanges,
        editingData,
        isUpdatingTable,
        tableConfig,
        columns,
        primaryKey: tableConfig.get('primaryKey'),
        tableId,
        configId,
        localState,
        exportInfo,
        isSavingColumns,
        v2Actions,
        v2State: localState.get('v2', Map()),
        v2ConfigTable: v2Actions.configTables.find(
          (t) => t.get('tableId') === tableId,
          null,
          Map()
        ),
        otherTables: v2Actions.configTables.filter((table) => table.get('tableId') !== tableId),
        readOnly: ApplicationStore.isReadOnly() || supportConfigRows(componentId)
      };
    },

    render() {
      const primaryKey = this.state.v2ConfigTable.get('primaryKey', List());
      const dbColumns = this.state.columns.map((c) => c.get('dbName'));
      const pkMismatchList = primaryKey.reduce(
        (memo, pkColumn) =>
          !dbColumns.find((dbColumn) => dbColumn === pkColumn) ? memo.push(pkColumn) : memo,
        List()
      );

      return (
        <>
          <div className="box info-row">
            {this._renderTableEdit()}
            {componentId === 'keboola.wr-thoughtspot' && this._renderThoughSpotTypeInput()}
            {this._renderIncrementalSetup()}
            {this._renderTableFiltersRow()}
            {this._renderPrimaryKey()}
          </div>
          {pkMismatchList.count() > 0 && (
            <Alert bsStyle="warning">
              The primary Key is set to non-existing column(s). Please update the Primary Key
              settings.
            </Alert>
          )}
          <ColumnsEditor
            readOnly={this.state.readOnly}
            componentId={componentId}
            tableId={this.state.tableId}
            tableExists={this.state.allTables.has(this.state.tableId)}
            dataTypes={getComponentDataTypes(componentId)}
            disabledColumnFields={getDisabledColumnFields(componentId)}
            columns={this.state.columns}
            primaryKey={primaryKey}
            defaultColumnsTypes={columnsMetadata.prepareColumnsTypesAsMap(
              componentId,
              this.state.storageTable
            )}
            editingColumns={this.state.editingColumns}
            isSaving={this.state.isSavingColumns}
            editColumnFn={this._onEditColumn}
            columnsValidation={this.state.columnsValidation}
            hasChanges={this.state.hasChanges}
            tablePrimaryKey={this.state.primaryKey}
            otherTables={this.state.otherTables}
            onColumnsCancel={this._handleEditColumnsCancel}
            onColumnsSave={this._handleEditColumnsSave}
          />
        </>
      );
    },

    _setValidateColumn(cname, isValid) {
      const path = ['validation', this.state.tableId, cname];
      return WrDbActions.setEditingData(componentId, this.state.configId, path, isValid);
    },

    _validateColumn(column) {
      const type = column.get('type');
      const size = column.get('size');
      const shouldHaveSize = getSizeParam(componentId, type);
      const sizeValid = columnTypeValidation.validate(type, size);

      return this._setValidateColumn(column.get('name'), !shouldHaveSize || sizeValid);
    },

    _showIncrementalSetupModal() {
      return this.state.v2Actions.updateV2State(['IncrementalSetup', 'show'], true);
    },

    _renderIncrementalSetup() {
      const exportInfo = this.state.v2ConfigTable;
      const isIncremental = exportInfo.get('incremental');
      const primaryKey = exportInfo.get('primaryKey', List());
      const tableMapping = this.state.v2Actions.getTableMapping(this.state.tableId);
      const isAdaptive = tableMapping.get('changed_since') === changedSinceConstants.ADAPTIVE_VALUE;

      return (
        <div className="info-row-section">
          <h4 className="first-line">
            Load Type
            {!this.state.readOnly && (
              <Button
                bsStyle="link"
                className="btn-link-inline btn-link-muted icon-addon-left"
                disabled={this.state.hasChanges}
                onClick={this._showIncrementalSetupModal}
              >
                <FontAwesomeIcon icon="pen" />
              </Button>
            )}
          </h4>
          <div>
            {isIncremental
              ? isAdaptive
                ? 'Automatic Incremental Load'
                : 'Manual Incremental Load'
              : 'Full Load'}
            <IncrementalSetupModal
              show={this.state.v2State.getIn(['IncrementalSetup', 'show'], false)}
              onHide={() => this.state.v2Actions.updateV2State(['IncrementalSetup', 'show'], false)}
              currentPK={primaryKey}
              currentMapping={tableMapping}
              columns={this.state.columns.map((c) => c.get('dbName'))}
              isIncremental={isIncremental}
              allTables={this.state.allTables}
              onSave={(incremental, primary, newMapping, customFieldsValues) => {
                const newExportInfo = exportInfo
                  .set('primaryKey', primary)
                  .set('incremental', incremental)
                  .mergeDeep(customFieldsValues);

                return this._setV2TableInfo(newExportInfo)
                  .then(() =>
                    WrDbActions.loadTableConfigForce(
                      componentId,
                      this.state.configId,
                      this.state.tableId
                    )
                  )
                  .then(() => {
                    if (newMapping !== tableMapping) {
                      return this.state.v2Actions.setTableMapping(newMapping);
                    }
                  });
              }}
              customFieldsValues={this._getCustomFieldsValues()}
              componentId={componentId}
            />
            {isIncremental && isAdaptive && (
              <AutomaticLoadTypeLastUpdated tableId={this.state.tableId} />
            )}
          </div>
        </div>
      );
    },

    _renderPrimaryKey() {
      const exportInfo = this.state.v2ConfigTable;
      const primaryKey = exportInfo.get('primaryKey', List());

      return (
        <div className="info-row-section">
          <h4 className="first-line">
            Primary Key
            {!this.state.readOnly && (
              <Button
                bsStyle="link"
                className="btn-link-inline btn-link-muted icon-addon-left"
                disabled={this.state.hasChanges}
                onClick={this._showIncrementalSetupModal}
              >
                <FontAwesomeIcon icon="pen" />
              </Button>
            )}
          </h4>
          <div>{primaryKey.join(', ') || 'N/A'}</div>
        </div>
      );
    },

    _renderThoughSpotTypeInput() {
      return (
        <div className="info-row-section">
          <h4 className="first-line">
            Table Type
            {!this.state.readOnly && (
              <Button
                bsStyle="link"
                className="btn-link-inline btn-link-muted icon-addon-left"
                disabled={this.state.hasChanges}
                onClick={this._showIncrementalSetupModal}
              >
                <FontAwesomeIcon icon="pen" />
              </Button>
            )}
          </h4>
          <div>{this.state.v2ConfigTable.get('type', 'standard').toUpperCase()}</div>
        </div>
      );
    },

    _getCustomFieldsValues() {
      if (componentId === 'keboola.wr-thoughtspot') {
        return Map({ type: this.state.v2ConfigTable.get('type', 'standard') });
      }

      return Map();
    },

    _onEditColumn(newColumn) {
      const cname = newColumn.get('name');
      const path = ['columns', this.state.tableId, cname];
      WrDbActions.setEditingData(componentId, this.state.configId, path, newColumn);
      return this._validateColumn(newColumn);
    },

    _handleEditColumnsSave() {
      // to preserve order remap according the original columns
      const columns = this.state.columns.map((c) => {
        return this.state.editingColumns.get(c.get('name'));
      });
      return WrDbActions.saveTableColumns(
        componentId,
        this.state.configId,
        this.state.tableId,
        columns
      ).then(this._handleEditColumnsCancel);
    },

    _handleEditColumnsCancel() {
      const { configId, tableId } = this.state;
      WrDbActions.setEditingData(componentId, configId, ['columns', tableId], Map());
      WrDbActions.setEditingData(componentId, configId, ['validation', tableId], Map());
    },

    _renderTableEdit() {
      return (
        <div className="info-row-section">
          <h4 className="first-line">Database Table name</h4>
          <div>
            <TableNameEdit
              readOnly={this.state.readOnly}
              tableId={this.state.tableId}
              table={this.state.table}
              configId={this.state.configId}
              tableExportedValue={
                this.state.exportInfo && this.state.exportInfo.get('export')
                  ? this.state.exportInfo.get('export')
                  : false
              }
              currentValue={
                this.state.exportInfo && this.state.exportInfo.get('dbName')
                  ? this.state.exportInfo.get('dbName')
                  : this.state.tableId
              }
              isSaving={this.state.isUpdatingTable}
              editingValue={this.state.editingData.getIn(['editingDbNames', this.state.tableId])}
              setEditValueFn={(value) => {
                const path = ['editingDbNames', this.state.tableId];
                return WrDbActions.setEditingData(componentId, this.state.configId, path, value);
              }}
              componentId={componentId}
            />
          </div>
        </div>
      );
    },

    _renderTableFiltersRow() {
      const tableMapping = this.state.v2Actions.getTableMapping(this.state.tableId);

      return (
        <div className="info-row-section">
          <h4 className="first-line">
            Data Filter
            {!this.state.readOnly && (
              <Button
                bsStyle="link"
                className="btn-link-inline btn-link-muted icon-addon-left"
                disabled={this.state.hasChanges}
                onClick={this._showIncrementalSetupModal}
              >
                <FontAwesomeIcon icon="pen" />
              </Button>
            )}
          </h4>
          <div>
            <FiltersDescription value={tableMapping} rootClassName="" />
          </div>
        </div>
      );
    },

    _setV2TableInfo(newTableInfo) {
      return this.state.v2Actions.setTableInfo(this.state.tableId, newTableInfo);
    },

    _updateLocalState(path, data) {
      const newLocalState = this.state.localState.setIn(path, data);
      return InstalledComponentsActions.updateLocalState(
        componentId,
        this.state.configId,
        newLocalState
      );
    }
  });
};

export default Table;
