import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import Checkbox from '../../../../react/common/Checkbox';
import CollapsibleBox from '../../../../react/common/CollapsibleBox';
import InfoTooltip from '../../../../react/common/InfoTooltip';

const LIMIT_MIN = 1;
const LIMIT_DEFAULT = 25;
const LIMIT_MAX = 100;
const LIMIT_HELP = `Parameter of the Facebook Graph API nested request. Specifies the size of data returned in one page of the request. Maximum is ${LIMIT_MAX}, the default ${LIMIT_DEFAULT}.`;
const RUNBYID_HELP =
  'This is useful when query run fails with "Please reduce the amount of data you\'re asking for" error. By checking this option component will run the query for each id separately instead of all ids at once while still storing all extracted rows into one table.';

export const isResultsLimitValid = (limit: string) => {
  const parsed = parseInt(limit);
  return parsed >= LIMIT_MIN && parsed <= LIMIT_MAX;
};

type Props = {
  config: Map<string, any>;
  readOnly: boolean;
  getAccountDesc: (type: string) => string;
  updateLocalState: (path: string[], newValue: Map<string, any> | boolean | string) => void;
};

const AdvancedSettings = ({ config, readOnly, getAccountDesc, updateLocalState }: Props) => {
  const limit = config.getIn(['query', 'limit']);
  const runById = config.get('run-by-id', false);

  return (
    <CollapsibleBox title="Advanced Settings" defaultOpen>
      <FormGroup>
        <ControlLabel>
          Limit number of results per page
          <InfoTooltip tooltip={LIMIT_HELP} />
        </ControlLabel>

        <FormControl
          type="number"
          min={LIMIT_MIN}
          max={LIMIT_MAX}
          value={limit}
          disabled={readOnly}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateLocalState(['query', 'limit'], e.target.value);
          }}
        />
      </FormGroup>

      <FormGroup>
        <Checkbox
          disabled={readOnly}
          checked={runById}
          onChange={(checked) => updateLocalState(['run-by-id'], checked)}
        >
          Run query separately for each {getAccountDesc('page')}
          <InfoTooltip tooltip={RUNBYID_HELP} />
        </Checkbox>
      </FormGroup>
    </CollapsibleBox>
  );
};

export default AdvancedSettings;
