import React from 'react';
import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import InlineEditTextInput from '../../../../react/common/InlineEditTextInput';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import { canManageTokens } from '../../../admin/privileges';
import actionCreators from '../../ActionCreators';
import OrchestrationsStore from '../../stores/OrchestrationsStore';

const FIELD = 'name';

const OrchestrationNameEdit = createReactClass({
  mixins: [createStoreMixin(OrchestrationsStore, ApplicationStore), immutableMixin],

  propTypes: {
    orchestrationId: PropTypes.number.isRequired
  },

  getStateFromStores() {
    return {
      value: OrchestrationsStore.get(this.props.orchestrationId).get(FIELD),
      editValue: OrchestrationsStore.getEditingValue(this.props.orchestrationId, FIELD),
      isEditing: OrchestrationsStore.isEditing(this.props.orchestrationId, FIELD),
      isSaving: OrchestrationsStore.isSaving(this.props.orchestrationId, FIELD),
      sapiTokenCanManageTokens: canManageTokens(ApplicationStore.getSapiToken())
    };
  },

  _handleEditStart() {
    return actionCreators.startOrchestrationFieldEdit(this.props.orchestrationId, FIELD);
  },

  _handleEditCancel() {
    return actionCreators.cancelOrchestrationFieldEdit(this.props.orchestrationId, FIELD);
  },

  _handleEditChange(newValue) {
    return actionCreators.updateOrchestrationFieldEdit(this.props.orchestrationId, FIELD, newValue);
  },

  _handleEditSubmit() {
    return actionCreators.saveOrchestrationField(this.props.orchestrationId, FIELD);
  },

  render() {
    if (!this.state.sapiTokenCanManageTokens) {
      return <span>{this.state.value}</span>;
    }

    return (
      <InlineEditTextInput
        text={this.state.isEditing ? this.state.editValue : this.state.value}
        placeholder="Name the component ..."
        tooltipPlacement="bottom"
        isSaving={this.state.isSaving}
        isEditing={this.state.isEditing}
        isValid={!!(this.state.editValue && this.state.editValue.trim() !== '')}
        isChanged={this.state.editValue !== this.state.value}
        onEditStart={this._handleEditStart}
        onEditCancel={this._handleEditCancel}
        onEditChange={this._handleEditChange}
        onEditSubmit={this._handleEditSubmit}
      />
    );
  }
});

export default OrchestrationNameEdit;
