import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import BlankImage from '../../../../../../images/illustrations/blank.png';
import { KEBOOLA_ORCHESTRATOR } from '../../../../../constants/componentIds';
import { componentTypes } from '../../../../../constants/componentTypes';
import ConfigurationInfoPanel from '../../../../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../../../../react/common/ConfigurationTabs';
import FilterPanel from '../../../../../react/common/FilterPanel';
import NoResultsFound from '../../../../../react/common/NoResultsFound';
import Link from '../../../../../react/common/RouterLink';
import Sidebar from '../../../../../react/layout/Sidebar';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import { fileUrl } from '../../../../../utils/fileHelpers';
import getDefaultBucket from '../../../../../utils/getDefaultBucket';
import ComponentDescription from '../../../../components/react/components/ComponentDescription';
import ComponentsStore from '../../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../../components/stores/InstalledComponentsStore';
import StorageBucketsStore from '../../../../components/stores/StorageBucketsStore';
import StorageTablesStore from '../../../../components/stores/StorageTablesStore';
import ConfigurationRowsStore from '../../../../configurations/ConfigurationRowsStore';
import { prepareTablesMetadataMap } from '../../../../storage/helpers';
import { createActions } from '../../../actionsProvisioning';
import { migrateToRows } from '../../../helpers';
import { createStore } from '../../../storeProvisioning';
import ConfigRowTables from '../../components/ConfigRowTables';
import CreateQueryElement from '../../components/CreateQueryElement';
import MigrateToRowsButton from '../../components/MigrateToRowsButton';
import QueryTable from './QueryTable';

const Index = function (componentId) {
  const actionCreators = createActions(componentId);

  return createReactClass({
    mixins: [
      createStoreMixin(
        ApplicationStore,
        InstalledComponentsStore,
        StorageTablesStore,
        StorageBucketsStore,
        ConfigurationRowsStore
      )
    ],

    UNSAFE_componentWillReceiveProps() {
      return this.setState(this.getStateFromStores());
    },

    getStateFromStores() {
      const configId = RoutesStore.getRouterState().getIn(['params', 'config']);
      const ExDbStore = createStore(componentId, configId);
      const queries = ExDbStore.getQueries();
      const credentials = ExDbStore.getCredentials();

      // state
      return {
        queries,
        configId,
        config: ExDbStore.config,
        component: ComponentsStore.getComponent(componentId),
        configRows: ConfigurationRowsStore.getRows(componentId, configId),
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        newCredentials: ExDbStore.getNewCredentials(),
        pendingActions: ExDbStore.getQueriesPendingActions(),
        hasCredentials: ExDbStore.hasValidCredentials(credentials),
        allTables: StorageTablesStore.getAll(),
        queriesFilter: ExDbStore.getQueriesFilter(),
        queriesFiltered: ExDbStore.getQueriesFiltered(),
        hasEnabledQueries: queries.filter((query) => query.get('enabled')).count() > 0,
        readOnly: ApplicationStore.isReadOnly(),
        hasFlows: ApplicationStore.hasFlows()
      };
    },

    getInitialState() {
      return {
        isProcessing: false
      };
    },

    _handleFilterChange(query) {
      return actionCreators.setQueriesFilter(this.state.configId, query);
    },

    render() {
      return (
        <>
          <ConfigurationTabs componentId={componentId} configId={this.state.configId} />
          <ConfigurationInfoPanel
            component={this.state.component}
            config={this.state.config}
            hasFlows={this.state.hasFlows}
            flows={this.state.flows}
            tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
            metadata={this.state.componentsMetadata}
          />
          <div className="row">
            {this.renderMain()}
            {this.renderSidebar()}
          </div>
        </>
      );
    },

    renderMain() {
      return (
        <div className="col-sm-9">
          <ComponentDescription
            componentId={componentId}
            configId={this.state.configId}
            placeholderEntity={componentTypes.EXTRACTOR}
          />
          {this.renderMigrationToRowsButton()}
          {this.renderMissingDatabaseCredentials()}
          {this.renderTables()}
        </div>
      );
    },

    renderMigrationToRowsButton() {
      if (
        this.state.readOnly ||
        !this.state.config.hasIn(['configuration', 'parameters', 'exports'])
      ) {
        return null;
      }

      return (
        <div className="box">
          <div className="box-content text-center">
            {this.state.configRows.isEmpty() || this.state.isProcessing ? (
              <p>
                Please migrate the configuration to the newest format to unlock the latest features.
              </p>
            ) : (
              <Alert bsStyle="warning">
                Migration was not fully completed. Please run migration again.
              </Alert>
            )}
            <MigrateToRowsButton
              isProcessing={this.state.isProcessing}
              onConfrim={() => {
                this.setState({ isProcessing: true });
                return migrateToRows(this.state.configId).finally(() => {
                  this.setState({ isProcessing: false });
                });
              }}
            />
          </div>
        </div>
      );
    },

    renderMissingDatabaseCredentials() {
      if (this.state.hasCredentials) {
        return null;
      }

      return (
        <div className="box pt-2 pb-1">
          <div className="blank-page">
            <img
              src={fileUrl(BlankImage)}
              className="intro-image intro-image-small"
              alt="Connect database"
            />
            <h2>Connect database</h2>
            {this.state.readOnly ? (
              <p className="intro-text">No credentials provided for this component</p>
            ) : (
              <>
                <p className="intro-text">Please set up database credentials for this component.</p>
                <div className="intro-action">
                  <Button bsStyle="success" onClick={this.handleCredentialsSetup}>
                    <FontAwesomeIcon icon="user" className="icon-addon-right" />
                    Set Up Credentials
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    },

    renderTables() {
      if (!this.hasActiveConfigRows()) {
        if (this.state.queries.isEmpty() && !this.state.hasCredentials) {
          return null;
        }

        if (this.state.queries.isEmpty()) {
          return (
            <div className="box-separator">
              <h2 className="tw-text-base tw-m-0 tw-mb-4">Exports</h2>
              <div className="box">
                <div className="box-content text-center">
                  <p>No exports configured yet.</p>
                  {this.renderAdditionalActions()}
                </div>
              </div>
            </div>
          );
        }

        return (
          <div className="box-separator">
            <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
              <h2 className="tw-text-base tw-m-0">Exports</h2>
              {this.renderAdditionalActions()}
            </div>
            <FilterPanel
              query={this.state.queriesFilter}
              onChange={this._handleFilterChange}
              placeholder="Search exports"
            />
            {this.renderQueryTable()}
          </div>
        );
      }

      return (
        <ConfigRowTables
          configId={this.state.configId}
          configName={this.state.config.get('name')}
          rows={this.state.configRows}
          defaultBucketId={this.state.config.getIn(
            ['configuration', 'storage', 'output', 'default_bucket'],
            getDefaultBucket('in', componentId, this.state.configId)
          )}
          component={this.state.component}
          readOnly={this.state.readOnly}
        />
      );
    },

    renderAdditionalActions() {
      if (this.state.readOnly) {
        return null;
      }

      return (
        <CreateQueryElement
          actionCreators={actionCreators}
          componentId={componentId}
          configurationId={this.state.configId}
          isNav={false}
        />
      );
    },

    renderQueryTable() {
      if (!this.state.queriesFiltered.count()) {
        return <NoResultsFound entityName="exports" />;
      }

      return (
        <QueryTable
          readOnly={this.state.readOnly}
          queries={this.state.queriesFiltered}
          configurationId={this.state.configId}
          componentId={componentId}
          pendingActions={this.state.pendingActions}
          actionCreators={actionCreators}
        />
      );
    },

    renderSidebar() {
      return (
        <div className="col-sm-3">
          <Sidebar
            componentId={componentId}
            configId={this.state.configId}
            run={{
              disabled:
                this.state.configRows.isEmpty() && !this.state.hasEnabledQueries
                  ? 'There are no exports to be executed.'
                  : '',
              text: 'You are about to run an extraction.'
            }}
            additionalButtons={
              <Link to="ex-mongodb-credentials" params={{ config: this.state.configId }}>
                <FontAwesomeIcon icon="user" fixedWidth />
                Database Credentials
              </Link>
            }
          />
        </div>
      );
    },

    hasActiveConfigRows() {
      return (
        this.state.config.hasIn(['configuration', 'parameters']) &&
        !this.state.config.hasIn(['configuration', 'parameters', 'exports'])
      );
    },

    handleCredentialsSetup() {
      actionCreators.updateEditingCredentials(this.state.configId, this.state.newCredentials);
      RoutesStore.getRouter().transitionTo('ex-mongodb-credentials', {
        config: this.state.configId
      });
    }
  });
};

export default Index;
