import React from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { NEW_ENTITY_BUTTON } from '../../../constants/external';
import { FEATURE_SAML_ROLES } from '../../../constants/features';
import { ADMIN_ROLES, USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ExternalLink from '../../../react/common/ExternalLink';
import ModalIcon from '../../../react/common/ModalIcon';
import Select from '../../../react/common/Select';
import Tooltip from '../../../react/common/Tooltip';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import { canManageUsers } from '../../admin/privileges';
import StackFeaturesStore from '../../stack-features/Store';
import { inviteUser } from '../actions';

const InviteUserHeaderButton = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, StackFeaturesStore)],

  getStateFromStores() {
    return {
      sapiToken: ApplicationStore.getSapiToken(),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      hasSamlRoles: StackFeaturesStore.hasStackFeature(FEATURE_SAML_ROLES)
    };
  },

  getInitialState() {
    return {
      error: null,
      tempData: Map(),
      showModal: false,
      isLoading: false
    };
  },

  render() {
    if (this.state.hasSamlRoles) {
      return null;
    }

    const canInvite = canManageUsers(this.state.sapiToken);

    return (
      <>
        <Tooltip
          tooltip="You don't have permission to invite user. Please request the necessary permissions from your organization's administrator."
          placement="top"
          type="explanatory"
          forceHide={canInvite}
        >
          <Button
            bsStyle="success"
            className={classNames(NEW_ENTITY_BUTTON, { disabled: !canInvite })}
            onClick={() => canInvite && this.setState({ showModal: true })}
          >
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            Invite User
          </Button>
        </Tooltip>
        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          onEnter={() => this.setState({ tempData: Map(), error: null })}
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Invite User to Project</Modal.Title>
              <ModalIcon icon="plus" color="green" bold />
            </Modal.Header>
            <Modal.Body>
              <p>Enter the user&apos;s email address. Invitation will be send to the user email.</p>
              {this.state.error && <Alert bsStyle="danger">{this.state.error.message}</Alert>}
              <FormGroup>
                <ControlLabel>Email</ControlLabel>
                <FormControl
                  autoFocus
                  type="text"
                  value={this.state.tempData.get('email', '')}
                  onChange={(e) =>
                    this.setState({
                      tempData: this.state.tempData.set('email', e.target.value),
                      error: null
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Role</ControlLabel>
                <Select
                  clearable={false}
                  placeholder="Select role"
                  value={this.state.tempData.get('role')}
                  onChange={(role) =>
                    this.setState({ tempData: this.state.tempData.set('role', role) })
                  }
                  options={this.getOptions()}
                />
                <HelpBlock>
                  Read more about available roles in the{' '}
                  <ExternalLink
                    href={`${USER_DOCUMENTATION_URL}/management/project/users/#user-roles`}
                  >
                    documentation
                  </ExternalLink>
                  .
                </HelpBlock>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveLabel={this.state.isLoading ? 'Inviting a user...' : 'Invite user'}
                isSaving={this.state.isLoading}
                isDisabled={this.isDisabled()}
              />
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  },

  isDisabled() {
    return (
      !/\S+@\S+\.\S+/.test(this.state.tempData.get('email')) || !this.state.tempData.get('role')
    );
  },

  getOptions() {
    if (ApplicationStore.hasProtectedDefaultBranch()) {
      return [
        { value: ADMIN_ROLES.PRODUCTION_MANAGER, label: 'Production manager' },
        { value: ADMIN_ROLES.DEVELOPER, label: 'Developer' },
        { value: ADMIN_ROLES.REVIEWER, label: 'Reviewer' }
      ];
    }

    return [
      { value: ADMIN_ROLES.SHARE, label: 'Share' },
      { value: ADMIN_ROLES.ADMIN, label: 'Admin' },
      { value: ADMIN_ROLES.GUEST, label: 'Guest' },
      { value: ADMIN_ROLES.READ_ONLY, label: 'Read Only' }
    ].filter((option) => {
      if (option.value === ADMIN_ROLES.READ_ONLY && !this.state.hasNewQueue) {
        return false;
      }

      if (
        option.value === ADMIN_ROLES.SHARE &&
        !this.state.sapiToken.getIn(['admin', 'isOrganizationMember'])
      ) {
        return false;
      }

      return true;
    });
  },

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });
    inviteUser(this.state.tempData.get('email'), this.state.tempData.get('role'))
      .then(() => this.setState({ showModal: false }))
      .catch((error) => {
        if (!error?.message) {
          throw error;
        }

        return this.setState({ error });
      })
      .finally(() => this.setState({ isLoading: false }));
  }
});

export default InviteUserHeaderButton;
