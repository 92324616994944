import Promise from 'bluebird';

import {
  KDS_TEAM_EX_NETSUITE,
  KDS_TEAM_EX_PROGRESS_DB,
  KDS_TEAM_WR_EXASOL,
  KDS_TEAM_WR_FIREBOLT,
  KDS_TEAM_WR_HIVE_CSAS,
  KDS_TEAM_WR_PAIRITY,
  KEBOOLA_EX_DB_DB_2,
  KEBOOLA_EX_DB_DB_2_BATA,
  KEBOOLA_EX_DB_FIREBIRD,
  KEBOOLA_EX_DB_HIVE,
  KEBOOLA_EX_DB_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE_CSAS_TEST,
  KEBOOLA_EX_DB_IMPALA,
  KEBOOLA_EX_DB_INFORMIX,
  KEBOOLA_EX_DB_MSSQL,
  KEBOOLA_EX_DB_MSSQL_CDATA,
  KEBOOLA_EX_DB_MYSQL,
  KEBOOLA_EX_DB_NETSUITE,
  KEBOOLA_EX_DB_ORACLE,
  KEBOOLA_EX_DB_PGSQL,
  KEBOOLA_EX_DB_REDSHIFT,
  KEBOOLA_EX_DB_SNOWFLAKE,
  KEBOOLA_EX_FACEBOOK,
  KEBOOLA_EX_FACEBOOK_ADS,
  KEBOOLA_EX_FTP,
  KEBOOLA_EX_FTP_CSAS,
  KEBOOLA_EX_GOOGLE_BIGQUERY_V_2,
  KEBOOLA_EX_GOOGLE_BIGQUERY_V_2_AVRO_FILES,
  KEBOOLA_EX_INSTAGRAM,
  KEBOOLA_EX_TERADATA,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_WR_DB_HIVE,
  KEBOOLA_WR_DB_IMPALA,
  KEBOOLA_WR_DB_MSSQL_V_2,
  KEBOOLA_WR_DB_MYSQL,
  KEBOOLA_WR_DB_ORACLE,
  KEBOOLA_WR_DB_PGSQL,
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_DB_SYNAPSE,
  KEBOOLA_WR_LOOKER_V2,
  KEBOOLA_WR_REDSHIFT_V_2,
  KEBOOLA_WR_SISENSE,
  KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE,
  KEBOOLA_WR_THOUGHTSPOT
} from '../../constants/componentIds';
import DummyBreadcrumb from '../../react/common/DummyBreadcrumb';
import ApplicationStore from '../../stores/ApplicationStore';
import { configPoll, rowPoll } from '../../utils/genericPolls';
import { configRequiredData } from '../../utils/genericRequiredData';
import * as pathParser from '../../utils/pathParser';
import exApifyRoutes from '../apify/routes';
import appSnowflakeDwhManagerRoutes from '../app-snowflake-dwh-manager/routes';
import ComponentsActionCreators from '../components/ComponentsActionCreators';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import GenericComponentRaw from '../components/react/pages/GenericComponentRaw';
import GenericDetailRaw from '../components/react/pages/GenericDetailRaw';
import GenericDetailRow from '../components/react/pages/GenericDetailRow';
import GenericIndex from '../components/react/pages/GenericIndex';
import StorageActionCreators from '../components/StorageActionCreators';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import createVersionsPageRoute from '../components/utils/createVersionsPageRoute';
import ConfigurationRowsStore from '../configurations/ConfigurationRowsStore';
import RowVersions from '../configurations/react/pages/Versions';
import RowVersionsActionCreators from '../configurations/RowVersionsActionCreators';
import csvImportRoutes from '../csv-import/routes';
import exAwsS3Routes from '../ex-aws-s3/routes';
import exAzureBlobRoutes from '../ex-azure-blob/routes';
import exDbGenericRoutes from '../ex-db-generic/routes';
import dropboxExtractorRoutesV2 from '../ex-dropbox-v2/routes';
import emailAttachmentsExtractorRoutes from '../ex-email-attachments/routes';
import exFacebookRoutes from '../ex-facebook/routes.js';
import exFtpRoutes from '../ex-ftp/routes';
import exGoogleAdsRoutes from '../ex-google-ads/routes';
import exGaV4Routes from '../ex-google-analytics-v4/routes';
import exGoogleBigqueryV2Routes from '../ex-google-bigquery-v2/routes';
import exGdriveNewRoutes from '../ex-google-drive/routes';
import exHttpRoutes from '../ex-http/routes';
import exMongoDbRoutes from '../ex-mongodb/routes';
import exOnedriveRoutes from '../ex-onedrive/routes';
import exStorageRoutes from '../ex-storage/routes';
import geneeaV2Routes from '../geneea-nlp-analysis-v2/routes';
import gooddataWriterV3Routes from '../gooddata-writer-v3/routes';
import kdsTeamExAzureDataLakeGen2Routes from '../kds-team.ex-azure-data-lake-gen2/routes';
import * as NotificationActions from '../notifications/actions';
import NotificationDetail from '../notifications/Detail';
import jobsActionCreators from '../queue/actions';
import tdeRoutes from '../tde-exporter/tdeRoutes';
import wrAwsS3Routes from '../wr-aws-s3/routes';
import createDbWriterRoutes from '../wr-db/routes';
import wrGoogleBigQueryV2Routes from '../wr-google-bigquery-v2/routes';
import googleDriveWriterNewRoutes from '../wr-google-drive/routes';
import googleSheetsWriterRoutes from '../wr-google-sheets/routes';
import wrOnedriveRoutes from '../wr-onedrive/routes';
import wrStorageRoutes from '../wr-storage/routes';
import ComponentHeaderButton from './components/ComponentHeaderButton';
import ComponentsHeaderButton from './components/ComponentsHeaderButton';
import ComponentsListLink from './components/ComponentsListLink';
import Component from './Component';
import Configurations from './Configurations';
import { routeNames } from './constants';
import Index from './Index';

const routes = {
  name: routeNames.ROOT,
  title: 'Add New Component',
  defaultRouteHandler: Index,
  headerButtonsHandler: ComponentsHeaderButton,
  requireData: [
    (params) => {
      if (!params.component && !params.config) {
        return Promise.resolve();
      }

      return ComponentsActionCreators.loadComponent(
        params.component || pathParser.getComponentId(window.location.pathname)
      );
    },
    (params) => {
      if (!params.config || params.row || params.query || !ApplicationStore.hasFlows()) {
        return Promise.resolve();
      }

      return InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_ORCHESTRATOR);
    }
  ],
  childRoutes: [
    {
      name: routeNames.CONFIGURATIONS,
      title: 'Components',
      path: 'configurations',
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: Configurations,
      headerButtonsHandler: ComponentsListLink,
      requireData: [
        () => NotificationActions.loadNotifications(),
        () => StorageActionCreators.loadBucketsAndTables(),
        () =>
          ApplicationStore.hasNewQueue()
            ? jobsActionCreators.reloadLatestJobForAllConfigurations()
            : Promise.resolve(),
        () => {
          return InstalledComponentsActionCreators.reloadInstalledComponents({
            include: 'configuration'
          });
        },
        () => InstalledComponentsActionCreators.loadComponentsMetadata()
      ],
      poll: {
        skipFirst: true,
        interval: 15,
        skip: () => !ApplicationStore.hasNewQueue(),
        action: () => jobsActionCreators.reloadLatestJobForAllConfigurationsForce()
      }
    },
    {
      name: routeNames.COMPONENT_RAW,
      path: ':component/raw',
      defaultRouteHandler: GenericComponentRaw
    },
    // applications
    geneeaV2Routes,
    appSnowflakeDwhManagerRoutes,

    // extractors
    exGoogleAdsRoutes,
    exGaV4Routes,
    ...exFacebookRoutes([KEBOOLA_EX_FACEBOOK, KEBOOLA_EX_FACEBOOK_ADS, KEBOOLA_EX_INSTAGRAM]),
    exGdriveNewRoutes,
    dropboxExtractorRoutesV2,
    ...exDbGenericRoutes([
      KEBOOLA_EX_DB_PGSQL,
      KEBOOLA_EX_DB_REDSHIFT,
      KEBOOLA_EX_DB_FIREBIRD,
      KEBOOLA_EX_DB_DB_2,
      KEBOOLA_EX_DB_DB_2_BATA,
      KEBOOLA_EX_DB_MSSQL,
      KEBOOLA_EX_DB_MSSQL_CDATA,
      KEBOOLA_EX_DB_MYSQL,
      KEBOOLA_EX_DB_ORACLE,
      KEBOOLA_EX_DB_SNOWFLAKE,
      KEBOOLA_EX_DB_NETSUITE,
      KEBOOLA_EX_DB_INFORMIX,
      KEBOOLA_EX_DB_IMPALA,
      KEBOOLA_EX_DB_HIVE,
      KEBOOLA_EX_DB_HIVE_CSAS,
      KEBOOLA_EX_DB_HIVE_CSAS_TEST,
      KEBOOLA_EX_TERADATA,
      KDS_TEAM_EX_NETSUITE,
      KDS_TEAM_EX_PROGRESS_DB
    ]),
    exMongoDbRoutes,
    csvImportRoutes,
    exApifyRoutes,
    exAwsS3Routes,
    emailAttachmentsExtractorRoutes,
    exHttpRoutes,
    exStorageRoutes,
    ...exFtpRoutes([KEBOOLA_EX_FTP, KEBOOLA_EX_FTP_CSAS]),
    exAzureBlobRoutes,
    kdsTeamExAzureDataLakeGen2Routes,
    ...exGoogleBigqueryV2Routes([
      KEBOOLA_EX_GOOGLE_BIGQUERY_V_2,
      KEBOOLA_EX_GOOGLE_BIGQUERY_V_2_AVRO_FILES
    ]),
    exOnedriveRoutes,

    // writers
    tdeRoutes,
    googleSheetsWriterRoutes,
    googleDriveWriterNewRoutes,
    ...createDbWriterRoutes([
      [KEBOOLA_WR_LOOKER_V2, 'snowflake', true],
      [KEBOOLA_WR_DB_MSSQL_V_2, 'mssql'],
      [KEBOOLA_WR_REDSHIFT_V_2, 'redshift', true],
      [KEBOOLA_WR_DB_IMPALA, 'impala'],
      [KEBOOLA_WR_DB_HIVE, 'hive'],
      [KEBOOLA_WR_DB_MYSQL, 'mysql'],
      [KEBOOLA_WR_DB_ORACLE, 'oracle'],
      [KEBOOLA_WR_DB_PGSQL, 'pgsql'],
      [KEBOOLA_WR_DB_SNOWFLAKE, 'snowflake', true],
      [KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE, 'snowflake', true],
      [KDS_TEAM_WR_PAIRITY, 'pgsql'],
      [KDS_TEAM_WR_FIREBOLT, 'firebolt'],
      [KDS_TEAM_WR_HIVE_CSAS, 'hive'],
      [KEBOOLA_WR_THOUGHTSPOT, 'thoughtspot'],
      [KEBOOLA_WR_SISENSE, 'sisense'],
      [KEBOOLA_WR_DB_SYNAPSE, 'synapse'],
      [KDS_TEAM_WR_EXASOL, 'exasol', true]
    ]),
    gooddataWriterV3Routes,
    wrOnedriveRoutes,
    wrStorageRoutes,
    wrAwsS3Routes,
    wrGoogleBigQueryV2Routes,

    // generic
    {
      name: routeNames.COMPONENT,
      title(routerState) {
        return ComponentsStore.getComponent(routerState.getIn(['params', 'component'])).get('name');
      },
      defaultRouteHandler: Component,
      headerButtonsHandler: ComponentHeaderButton,
      path: ':component',
      requireData: [
        (params) => {
          if (params.config) {
            return Promise.resolve();
          }

          return Promise.all([
            NotificationActions.loadNotifications(),
            InstalledComponentsActionCreators.loadComponentConfigsData(params.component),
            InstalledComponentsActionCreators.loadComponentsMetadata(params.component),
            ApplicationStore.hasNewQueue()
              ? jobsActionCreators.reloadLatestJobForAllConfigurations()
              : Promise.resolve()
          ]);
        }
      ],
      poll: {
        skipFirst: true,
        interval: 15,
        skip: () => !ApplicationStore.hasNewQueue(),
        action: () => jobsActionCreators.reloadLatestJobForAllConfigurationsForce()
      },
      childRoutes: [
        {
          name: routeNames.GENERIC_CONFIG,
          title(routerState) {
            const configId = routerState.getIn(['params', 'config']);
            const component = routerState.getIn(['params', 'component']);
            return InstalledComponentsStore.getConfig(component, configId).get('name');
          },
          defaultRouteHandler: GenericIndex,
          path: ':config',
          requireData: [(params) => configRequiredData(params.component, params.config)],
          poll: configPoll(),
          childRoutes: [
            {
              name: routeNames.GENERIC_CONFIG_NOTIFICATIONS,
              path: 'notifications',
              title: 'Notifications',
              defaultRouteHandler: NotificationDetail,
              requireData: [() => NotificationActions.loadNotifications()]
            },
            createVersionsPageRoute(null, 'config', routeNames.GENERIC_CONFIG_VERSIONS),
            {
              name: routeNames.GENERIC_CONFIG_ROW,
              path: 'rows/:row',
              title: (routerState) => {
                const component = routerState.getIn(['params', 'component']);
                const configId = routerState.getIn(['params', 'config']);
                const rowId = routerState.getIn(['params', 'row']);
                return ConfigurationRowsStore.get(component, configId, rowId).get(
                  'name',
                  'Untitled'
                );
              },
              poll: rowPoll(),
              requireData: [
                ({ component, config, row }) => {
                  return RowVersionsActionCreators.loadVersions(component, config, row);
                }
              ],
              defaultRouteHandler: GenericDetailRow,
              childRoutes: [
                {
                  name: routeNames.GENERIC_CONFIG_ROW_VERSIONS,
                  path: 'versions',
                  title: 'Versions',
                  defaultRouteHandler: RowVersions
                },
                {
                  name: routeNames.GENERIC_CONFIG_ROW_RAW,
                  path: 'raw',
                  defaultRouteHandler: GenericDetailRaw
                }
              ]
            },
            {
              name: routeNames.GENERIC_CONFIG_RAW,
              path: 'raw',
              defaultRouteHandler: GenericDetailRaw
            }
          ].filter(Boolean)
        }
      ]
    }
  ]
};

export default routes;
