import { fromJS, List } from 'immutable';

import fromJSOrdered from '../../../utils/fromJSOrdered';
import StorageTablesStore from '../../components/stores/StorageTablesStore';
import StorageTableColumnsEditor from '../react/components/StorageTableColumnsEditor';

export default (params) => {
  const {
    onLoadColumns, // (configuration) => columnsArray. Parse columns from configuration object and return array of columns objects
    onSaveColumns, // (tableId, columnsList) => configuration_object. Return configuration object to save from given tableId and columnsList
    initColumnFn, // (columnName) => columnObject. Initial value of column given by its name
    matchColumnKey = 'id', // used for matching columns objects with storage table columns by its name (columnName, columnObject) => true/false
    columnsMappings = [], // array of object containing render and title property
    onCreateInitColumns = () => [], // array of columns, can be used for populate columns details from metadata before creating new table
    isComplete = () => true, // is representation complete?
    isColumnIgnored = (column) => column.get('type') === 'IGNORE', // if ignored then won't be saved to input mapping columns property of configuration object
    prepareColumnContext = () => null, // (sectionContext, columnsList) => result of this fn will be injected as 'context' prop to every column render component
    isColumnValidFn = () => true, // (column) => true/false
    getInitialShowAdvanced = () => false // (columns) => get initial showadvanced value used in column mappings and header
  } = params;

  const onSave = function (localState) {
    const tableId = localState.get('tableId');
    const localStateColumnsToSave = localState
      .get('columns')
      .filter((column) => !isColumnIgnored(column));
    const configParametersWithColumns = onSaveColumns(tableId, localStateColumnsToSave);
    const configStorageMapping = fromJSOrdered({
      storage: {
        input: {
          tables: [
            {
              source: tableId,
              columns: localStateColumnsToSave.map((column) => column.get(matchColumnKey))
            }
          ]
        }
      }
    });
    return configStorageMapping.mergeDeep(configParametersWithColumns);
  };

  return fromJS({
    onSave,
    onLoad(configuration, sectionContext) {
      const configColumns = onLoadColumns(configuration);
      const tableColumns = sectionContext.getIn(['table', 'columns'], List());

      const deletedColumns = configColumns.filter((column) => {
        return !tableColumns.find((tableColumn) => tableColumn === column.get(matchColumnKey));
      });

      let allColumns = tableColumns;
      deletedColumns.forEach((deletedColumn) => {
        const originalIndex = configColumns.findIndex((column) => {
          return deletedColumn.get(matchColumnKey) === column.get(matchColumnKey);
        });
        allColumns = allColumns.splice(originalIndex, 0, deletedColumn.get(matchColumnKey));
      });

      const columnsList = allColumns.map((tableColumn) => {
        return configColumns.find(
          (configColumn) => configColumn.get(matchColumnKey) === tableColumn,
          null,
          initColumnFn(tableColumn)
        );
      });

      return fromJS({
        tableExist: !!sectionContext.get('table'),
        columns: columnsList,
        tableId: sectionContext.get('tableId'),
        columnsMappings,
        context: prepareColumnContext(sectionContext, columnsList),
        matchColumnKey,
        isColumnValidFn,
        getInitialShowAdvanced
      });
    },
    onCreate(tableId) {
      const table = StorageTablesStore.getTable(tableId);
      return onSave(fromJS({ tableId, columns: onCreateInitColumns(table) }));
    },
    render: StorageTableColumnsEditor,
    isComplete
  });
};
