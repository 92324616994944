import numeral from 'numeral';

import { SERVICE_BILLING, SERVICE_TELEMETRY } from '../../constants/serviceIds';
import dayjs from '../../date';
import ServicesStore from '../services/Store';
import { CREDITS_DISCOUNTS } from './react/CreditsPicker';
import { INVOICE_STATUS, MINUTES_PER_CREDIT, TOP_UP_INVOICE_TYPE } from './constants';

// TODO: fix when telemetry will be in services
const getTelemetryUrl = () => {
  return ServicesStore.getServiceUrl(SERVICE_BILLING).replace(SERVICE_BILLING, SERVICE_TELEMETRY);
};

const convertCreditsToMinutes = (creditsCount) => {
  const minutes = numeral(creditsCount * MINUTES_PER_CREDIT);

  return minutes.value() > 1000 ? minutes.format('00') : minutes.format('0,0[.]00');
};

const convertCreditsToHours = (creditsCount) => {
  return numeral((creditsCount * MINUTES_PER_CREDIT) / 60).format();
};

const convertCreditsToPrice = (credits, creditPrice) => {
  const discount = CREDITS_DISCOUNTS[credits] ?? 0;
  const price = convertCreditsToRawPrice(credits, creditPrice);

  return formatPrice(price - (price * discount) / 100);
};

const convertCreditsToRawPrice = (credits, creditPrice) => {
  return (credits * creditPrice) / 100;
};

const convertCreditsToPriceWithoutDiscount = (credits, creditPrice) => {
  return formatPrice(convertCreditsToRawPrice(credits, creditPrice));
};

const formatPrice = (price) => {
  return numeral(price).format('$0,0.00');
};

const parseBillingInformation = (billingInformation, fallbackEmail = '') => ({
  name: billingInformation.get('name') ?? '',
  email: billingInformation.get('email') ?? fallbackEmail,
  addressCountry: billingInformation.getIn(['address', 'country']) ?? '',
  addressLine1: billingInformation.getIn(['address', 'line1']) ?? '',
  addressLine2: billingInformation.getIn(['address', 'line2']) ?? '',
  addressCity: billingInformation.getIn(['address', 'city']) ?? '',
  addressPostalCode: billingInformation.getIn(['address', 'postal_code']) ?? '',
  vatId: billingInformation.get('vat_id') ?? ''
});

const getTopUpPurchasesFromCurrentMonth = (purchases) => {
  return purchases.filter((purchase) => {
    return (
      purchase.get('invoiceTopUpType') === TOP_UP_INVOICE_TYPE &&
      purchase.get('stripeInvoiceStatus') === INVOICE_STATUS.PAID &&
      dayjs(purchase.get('created')).isSame(dayjs(), 'month')
    );
  });
};

export {
  getTelemetryUrl,
  convertCreditsToMinutes,
  convertCreditsToHours,
  convertCreditsToPrice,
  convertCreditsToRawPrice,
  convertCreditsToPriceWithoutDiscount,
  parseBillingInformation,
  getTopUpPurchasesFromCurrentMonth
};
