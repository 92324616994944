import React from 'react';
import {
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';
import { capitalize } from 'underscore.string';

import ApplicationActionCreators from '../../../actions/ApplicationActionCreators';
import { getFolderFromMetadata, saveFolderToMetadata } from '../../../modules/components/helpers';
import InstalledComponentsActionCreators from '../../../modules/components/InstalledComponentsActionCreators';
import ConfirmButtons from '../ConfirmButtons';
import ModalIcon from '../ModalIcon';
import Select from '../Select';
import { folderLabel } from '../selectLabels';
import Truncated from '../Truncated';

type Props = {
  entity: 'flow' | 'transformation';
  configurations: Map<string, any>;
  componentsMetadata: Map<string, any>;
  componentId?: string;
};

class CreateFolderButton extends React.Component<Props> {
  state = {
    openModal: false,
    isLoading: false,
    name: '',
    selected: List()
  };

  render() {
    if (this.props.configurations.isEmpty()) {
      return null;
    }

    return (
      <>
        <Button onClick={() => this.setState({ openModal: true })}>
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Create Folder
        </Button>
        <Modal
          show={this.state.openModal}
          onHide={this.onHide}
          onEnter={() => this.setState({ name: '', selected: List() })}
        >
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();

              this.setState({ isLoading: true });
              return Promise.map(
                this.state.selected.toJS(),
                (configId: string) => {
                  return saveFolderToMetadata(
                    this.props.configurations.getIn(
                      [configId, 'component', 'id'],
                      this.props.componentId
                    ),
                    configId,
                    this.state.name.trim(),
                    { skipStoreUpdate: true }
                  );
                },
                { concurrency: 3 }
              )
                .then(() => InstalledComponentsActionCreators.loadComponentsMetadataForce())
                .then(() => {
                  this.onHide();

                  ApplicationActionCreators.sendNotification({
                    type: 'success',
                    message: 'Folder created.'
                  });
                })
                .finally(() => this.setState({ isLoading: false }));
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Create Folder</Modal.Title>
              <ModalIcon icon="folder" color="green" bold />
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Enter folder name"
                  value={this.state.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ name: e.target.value });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>{capitalize(this.props.entity)}s to Add</ControlLabel>
                <Select
                  multi
                  hideSelectAllOptions
                  value={this.state.selected}
                  onChange={(selected: List<any>) => this.setState({ selected })}
                  options={this.prepareOptions()}
                  placeholder={`Select ${this.props.entity}s to add`}
                />
                <HelpBlock>
                  Select at least one {this.props.entity}. Empty folder cannot be created. Selected{' '}
                  {this.props.entity}s, curently located in other folders will be moved.
                </HelpBlock>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveLabel={this.state.isLoading ? 'Creating folder...' : 'create folder'}
                isSaving={this.state.isLoading}
                isDisabled={!this.state.name.trim() || this.state.selected.isEmpty()}
              />
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }

  onHide = () => {
    this.setState({ openModal: false });
  };

  prepareOptions = () => {
    return this.props.configurations
      .map((config) => {
        const componentId = config.getIn(['component', 'id'], this.props.componentId);
        const folder = getFolderFromMetadata(
          this.props.componentsMetadata.getIn([componentId, config.get('id')])
        );

        return {
          value: config.get('id'),
          label: folderLabel(config.get('name'), folder),
          selectedLabel: <Truncated text={config.get('name')} />,
          name: `${folder} ${config.get('name')}`,
          hasFolder: !!folder
        };
      })
      .sort((rowA, rowB) => {
        if (rowA.hasFolder && !rowB.hasFolder) return 1;
        if (!rowA.hasFolder && rowB.hasFolder) return -1;

        return rowA.name.localeCompare(rowB.name);
      })
      .groupBy((option: any) => option.hasFolder)
      .map((options, hasFolder) => {
        return {
          label: (
            <span className="f-12 font-medium uppercase">
              {hasFolder ? 'Already in folder' : `${capitalize(this.props.entity)} without folder`}
            </span>
          ),
          options: options?.toArray() ?? []
        };
      })
      .toArray();
  };
}

export default CreateFolderButton;
