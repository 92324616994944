import { Map } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_CSV_IMPORT as COMPONENT_ID } from '../../constants/componentIds';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import { createConfiguration } from './utils';

function isUploaderFileBiggerThen(localState, mbSize) {
  if (!localState.get('file')) {
    return false;
  }

  if (localState.get('file').size > mbSize * 1024 * 1024) {
    return true;
  }

  return false;
}

export default function (configId) {
  var settings;
  const defaultSettings = createConfiguration(Map(), configId);
  const config = InstalledComponentsStore.getConfig(COMPONENT_ID, configId);
  const configData =
    InstalledComponentsStore.getConfigData(COMPONENT_ID, configId) || defaultSettings;
  const localState = InstalledComponentsStore.getLocalState(COMPONENT_ID, configId);
  if (!configData.isEmpty()) {
    settings = localState.get('settings', configData);
  } else {
    settings = localState.get('settings', defaultSettings);
  }

  return {
    config,
    settings,
    isUploaderFileBig: isUploaderFileBiggerThen(localState, 100), // bigger then 100mb
    // local state stuff
    getLocalState(path) {
      if (_.isEmpty(path)) {
        return localState || Map();
      }
      return localState.getIn([].concat(path), Map());
    }
  };
}
