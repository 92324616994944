import Immutable from 'immutable';

import Dispatcher from '../../../Dispatcher';
import StoreUtils, { initStore } from '../../../utils/StoreUtils';
import Constants from '../Constants';

var _store = initStore(
  'JupyterSandboxCredentialsStore',
  Immutable.Map({
    credentials: Immutable.Map(),
    pendingActions: Immutable.Map(),
    canExtend: false,
    isLoading: false,
    isLoaded: false,
    expiration: null
  })
);

var JupyterSandboxCredentialsStore = StoreUtils.createStore({
  getCredentials: function () {
    return _store.get('credentials');
  },
  getExpirationDate: function () {
    return _store.get('expiration');
  },
  hasCredentials: function () {
    return !!_store.getIn(['credentials', 'id']);
  },
  getPendingActions: function () {
    return _store.get('pendingActions');
  },
  getCanExtend: function () {
    return _store.get('canExtend');
  },
  getIsLoading: function () {
    return _store.get('isLoading');
  },
  getIsLoaded: function () {
    return _store.get('isLoaded');
  }
});

Dispatcher.register(function (payload) {
  const action = payload.action;
  switch (action.type) {
    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_LOAD:
      _store = _store.set('isLoading', true);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_LOAD_SUCCESS:
      _store = _store.set('credentials', Immutable.fromJS(action.credentials));
      _store = _store.set('canExtend', action.canExtend);
      _store = _store.set('isLoaded', true);
      _store = _store.set('isLoading', false);
      _store = _store.set('expiration', action.expiration);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_LOAD_ERROR:
      _store = _store.set('isLoading', false);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_CREATE_JOB:
      _store = _store.setIn(['pendingActions', 'create'], true);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_CREATE_JOB_SUCCESS:
      _store = _store.set('credentials', Immutable.fromJS(action.credentials));
      _store = _store.set('canExtend', action.canExtend);
      _store = _store.set('expiration', action.expiration);
      _store = _store.deleteIn(['pendingActions', 'create']);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_CREATE_JOB_ERROR:
      _store = _store.deleteIn(['pendingActions', 'create']);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_DROP_JOB:
      _store = _store.setIn(['pendingActions', 'drop'], true);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_DROP_JOB_SUCCESS:
      _store = _store.set('credentials', Immutable.Map());
      _store = _store.set('canExtend', false);
      _store = _store.set('expiration', null);
      _store = _store.deleteIn(['pendingActions', 'drop']);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_DROP_JOB_ERROR:
      _store = _store.deleteIn(['pendingActions', 'drop']);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_EXTEND:
      _store = _store.setIn(['pendingActions', 'extend'], true);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_EXTEND_SUCCESS:
      _store = _store.set('expiration', action.expiration);
      _store = _store.deleteIn(['pendingActions', 'extend']);
      return JupyterSandboxCredentialsStore.emitChange();

    case Constants.ActionTypes.CREDENTIALS_JUPYTER_SANDBOX_EXTEND_ERROR:
      _store = _store.deleteIn(['pendingActions', 'extend']);
      return JupyterSandboxCredentialsStore.emitChange();

    default:
      break;
  }
});

export default JupyterSandboxCredentialsStore;
