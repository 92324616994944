import { List, type Map } from 'immutable';

import ApplicationStore from '../stores/ApplicationStore';
import RoutesStore from '../stores/RoutesStore';
import { MD5 } from './crypto';

export const events = {
  EVENT_USER_INVITED: 'user_invited',
  EVENT_PAYMENT_BUY_MINUTES: 'payment_buy_minutes',
  EVENT_PAYMENT_CARD_SAVE: 'payment_card_save',
  EVENT_TOP_UP_ACTIVATED: 'auto_top_up_activated',
  EVENT_TOP_UP_DEACTIVATED: 'auto_top_up_deactivated',
  EVENT_PROJECT_TOKEN_CREATED: 'project_token_created',
  EVENT_PROJECT_TOKEN_REFRESHED: 'project_token_refreshed',
  EVENT_PROJECT_DELETED: 'project_deleted',
  EVENT_PAGEVIEW: 'pageview'
} as const;

type GtmEventName = (typeof events)[keyof typeof events];

type Event = { event: string } & Record<string, any>;

const pushGtmEvent = (eventName: GtmEventName, data?: Record<string, any>) => {
  let event: Event = { event: eventName };

  if (
    (
      [
        events.EVENT_PAYMENT_BUY_MINUTES,
        events.EVENT_PROJECT_TOKEN_CREATED,
        events.EVENT_PROJECT_TOKEN_REFRESHED,
        events.EVENT_PROJECT_DELETED
      ] as GtmEventName[]
    ).includes(eventName)
  ) {
    event = {
      ...event,
      project_name: ApplicationStore.getCurrentProject().get('name'),
      project_id: ApplicationStore.getCurrentProjectId().toString(),
      ...data
    };
  }

  if (eventName === events.EVENT_PAGEVIEW) {
    event = {
      ...event,
      page: {
        section: 'platform',
        title: document.title,
        path: window.location.href,
        category: RoutesStore.getRouterState()
          .get('routes', List())
          .map((route: Map<string, any>) => route.get('name'))
          .filter(Boolean)
          .toArray(),
        project_name: ApplicationStore.getCurrentProject().get('name'),
        project_id: ApplicationStore.getCurrentProjectId().toString(),
        project_type: ApplicationStore.getCurrentProject().get('type'),
        ...data
      },
      visitor: {
        id: MD5(ApplicationStore.getCurrentAdmin().get('email')).toString()
      }
    };
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

export default pushGtmEvent;
