import React from 'react';
import { Label, Radio } from 'react-bootstrap';
import classNames from 'classnames';

import string from '../../../utils/string';
import {
  convertCreditsToHours,
  convertCreditsToMinutes,
  convertCreditsToPrice,
  convertCreditsToPriceWithoutDiscount
} from '../helpers';

export type CreditAmount = (typeof CREDIT_OPTIONS)[number];
export const CREDIT_OPTIONS = [1, 2, 4, 8, 16, 32, 64, 128] as const;
export const CREDITS_DISCOUNTS = { 32: 5, 64: 10, 128: 15 } as const;

type Props = {
  price: number;
  currentValue: CreditAmount;
  onChange: (amount: CreditAmount) => void;
};

const CreditsPicker = ({ price, currentValue, onChange }: Props) => {
  return (
    <div className="credits-picker box-container">
      {CREDIT_OPTIONS.map((creditAmount) => {
        const hours = convertCreditsToHours(creditAmount);
        const discount = CREDITS_DISCOUNTS[creditAmount as 32 | 64 | 128] || 0;

        return (
          <Radio
            key={creditAmount}
            value={creditAmount}
            checked={creditAmount === currentValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(parseInt(event.target.value, 10) as CreditAmount)
            }
          >
            <h2 className="mt-0 f-24">{convertCreditsToMinutes(creditAmount)} minutes</h2>
            <span className="text-muted">
              {hours} {string.pluralize(parseInt(hours, 10), 'hour')}
            </span>
            <p className="mt-1 mb-0 font-medium">
              <span className={classNames('f-16 mrp-2', { 'color-success-muted': !!discount })}>
                {convertCreditsToPrice(creditAmount, price)}
              </span>
              {discount > 0 && (
                <>
                  <span className="f-12 line-height-20 text-muted line-through">
                    {convertCreditsToPriceWithoutDiscount(creditAmount, price)}
                  </span>
                  <Label className="label-success-muted label-rounded mlp-2">-{discount}%</Label>
                </>
              )}
            </p>
          </Radio>
        );
      })}
    </div>
  );
};

export default CreditsPicker;
