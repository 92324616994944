import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import ActivateDeactivateSwitch from '../../../react/common/ActivateDeactivateSwitch';
import { updateScheduler } from '../../scheduler/actions';
import { SCHEDULE_STATE } from '../../scheduler/constants';
import ScheduleModal from './ScheduleModal';

const Schedule = createReactClass({
  propTypes: {
    orchestration: PropTypes.instanceOf(Map).isRequired,
    tables: PropTypes.instanceOf(Map).isRequired,
    buckets: PropTypes.instanceOf(Map).isRequired,
    trigger: PropTypes.instanceOf(Map).isRequired,
    readOnly: PropTypes.bool.isRequired
  },

  getInitialState() {
    return {
      isUpdatingActivationState: false,
      showModal: false
    };
  },

  render() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <>
        <Button
          bsStyle="link"
          className="btn-link-inline btn-link-muted icon-addon-left mr-0"
          onClick={() => this.setState({ showModal: true })}
        >
          <FontAwesomeIcon icon="pen" />
        </Button>
        {!this.props.orchestration.get('schedulerConfiguration', Map()).isEmpty() && (
          <ActivateDeactivateSwitch
            activateTooltip="Disabled (click to enable)"
            deactivateTooltip="Enabled (click to disable)"
            isActive={
              this.props.orchestration.getIn([
                'schedulerConfiguration',
                'configuration',
                'schedule',
                'state'
              ]) === SCHEDULE_STATE.ENABLED
            }
            isPending={this.state.isUpdatingActivationState}
            onChange={this.handleActivateToggle}
          />
        )}
        <ScheduleModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          orchestration={this.props.orchestration}
          tables={this.props.tables}
          buckets={this.props.buckets}
          trigger={this.props.trigger}
        />
      </>
    );
  },

  handleActivateToggle(state) {
    const schedulerConfiguration = this.props.orchestration.get('schedulerConfiguration', Map());

    this.setState({ isUpdatingActivationState: true });
    return updateScheduler(
      schedulerConfiguration.get('id'),
      schedulerConfiguration.setIn(
        ['configuration', 'schedule', 'state'],
        state ? SCHEDULE_STATE.ENABLED : SCHEDULE_STATE.DISABLED
      )
    ).finally(() => this.setState({ isUpdatingActivationState: false }));
  }
});

export default Schedule;
