import dayjs from '../../../../../date';

const versions = {
  r: [
    {
      label: '2.1.0 (R 4.0.5) with additional packages',
      version: ''
    }
  ],
  python: [
    {
      label:
        '1.6.1 - Python 3.9.5 + Pandas 1.2.4 + snowflake-python-connector 2.5.1 (updates automatically)',
      version: ''
    },
    {
      label: '1.6.0 - Python 3.9.5 + Pandas 1.2.4 (locked)',
      version: '1.6.0',
      until: '2030-01-01'
    }
  ],
  julia: [
    {
      label: 'Latest (recommended and up-to-date environment)',
      version: ''
    }
  ]
};

const dateFormat = 'YYYY-MM-DD';

const hasVersions = (backendType, date = null) => {
  if (!versions[backendType]) {
    return false;
  }
  const unixTimestamp = date ? dayjs(date, dateFormat).unix() : dayjs().unix();
  return (
    versions[backendType].filter((version) => {
      return version.version !== '' && dayjs(version.until, dateFormat).unix() > unixTimestamp;
    }).length > 0
  );
};

const getVersions = (backendType, date = null) => {
  if (!versions[backendType]) {
    return [];
  }
  const unixTimestamp = date ? dayjs(date, dateFormat).unix() : dayjs().unix();
  return versions[backendType].filter((version) => {
    return version.version === '' || dayjs(version.until, dateFormat).unix() > unixTimestamp;
  });
};

export { hasVersions, getVersions };
