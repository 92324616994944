import React from 'react';

import DescriptionBox from '../../../../react/common/DescriptionBox';
import useStores from '../../../../react/hooks/useStores';
import ApplicationStore from '../../../../stores/ApplicationStore';
import ConfigurationRowsStore from '../../../configurations/ConfigurationRowsStore';
import { getConfigDescription } from '../../../openai/api';
import { getNewComponentTypeLabel } from '../../helpers';
import InstalledComponentsActionCreators from '../../InstalledComponentsActionCreators';
import ComponentsStore from '../../stores/ComponentsStore';
import InstalledComponentsStore from '../../stores/InstalledComponentsStore';

const ComponentDescription = (props: {
  componentId: string;
  configId: string;
  rowId?: string;
  readOnly?: boolean;
  placeholderEntity?: string;
}) => {
  const state = useStores(
    () => {
      const config = InstalledComponentsStore.getConfig(props.componentId, props.configId);
      const row = ConfigurationRowsStore.get(props.componentId, props.configId, props.rowId);

      return {
        hasAllowedDescriptionAi: ApplicationStore.hasAllowedDescriptionAi(),
        readOnly: props.readOnly || ApplicationStore.isReadOnly(),
        description: props.rowId ? row.get('description', '') : config.get('description', ''),
        component: ComponentsStore.getComponent(props.componentId),
        configuration: props.rowId ? row : config
      };
    },
    [props.componentId, props.configId, props.rowId, props.readOnly],
    [ApplicationStore, ComponentsStore, InstalledComponentsStore, ConfigurationRowsStore]
  );
  const [editing, setEditing] = React.useState<string | null>(null);
  const [isGenerating, setGenerating] = React.useState(false);
  const [isSaving, setSaving] = React.useState(false);

  const handleSave = () => {
    const newDescription = editing || '';

    setSaving(true);
    Promise.resolve()
      .then(() => {
        if (props.rowId) {
          return InstalledComponentsActionCreators.saveConfigurationRowEdit(
            props.componentId,
            props.configId,
            props.rowId,
            'description',
            newDescription.trim()
          );
        }

        return InstalledComponentsActionCreators.updateComponentConfiguration(
          props.componentId,
          props.configId,
          { description: newDescription.trim() },
          'Update description'
        );
      })
      .finally(() => {
        setSaving(false);
        handleCancel();
      });
  };

  const handleCancel = () => setEditing(null);

  const handleGenerate = () => {
    setGenerating(true);
    getConfigDescription(state.component, state.configuration)
      .then((data) => setEditing(data.description.trim()))
      .finally(() => setGenerating(false));
  };

  return (
    <DescriptionBox
      editing={editing}
      description={state.description}
      onChange={setEditing}
      handleSave={handleSave}
      handleCancel={handleCancel}
      readOnly={state.readOnly}
      isSaving={isSaving}
      isEditing={editing !== null || isGenerating}
      isGenerating={isGenerating}
      hasAllowedAi={state.hasAllowedDescriptionAi}
      handleGenerate={handleGenerate}
      placeholder={
        !props.placeholderEntity || props.placeholderEntity === 'other'
          ? 'Description'
          : `${getNewComponentTypeLabel(props.placeholderEntity)} Description`
      }
    />
  );
};

export default ComponentDescription;
