import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import phaseIcon from '../../../images/phase-icon.png';
import { getComponentIconUrl } from '../../utils/componentIconFinder';

const ComponentIcon = ({ component, size, isPhase, className }) => {
  if (!component) {
    return null;
  }

  return (
    <img
      width={size}
      height={size}
      loading="lazy"
      title={component.get('name', '')}
      className={classNames('component-icon img-circle bg-color-white', className)}
      src={isPhase ? phaseIcon : getComponentIconUrl(component)}
    />
  );
};

ComponentIcon.propTypes = {
  component: PropTypes.object,
  size: PropTypes.string,
  className: PropTypes.string,
  isPhase: PropTypes.bool
};

ComponentIcon.defaultProps = {
  size: '64'
};

export default ComponentIcon;
