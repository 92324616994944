import { SERVICE_SANDBOXES } from '../../constants/serviceIds';
import ApplicationStore from '../../stores/ApplicationStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from '../../utils/errors/helpers';
import request from '../../utils/request';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import ServicesStore from '../services/Store';

export const createRequest = (method, path) => {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_SANDBOXES)}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString()
  );
};

const SandboxesApi = {
  loadProjectSettings() {
    return createRequest('GET', `projects/current`)
      .promise()
      .then((response) => response.body)
      .catch((error) => {
        if (error.response && error.response.status === HTTP_STATUS_CODE_NOT_FOUND) {
          return {};
        }

        throw error;
      });
  },

  getSandboxes() {
    return createRequest('GET', 'sandboxes')
      .query(DevBranchesStore.isDevModeActive() && { branchId: DevBranchesStore.getCurrentId() })
      .promise()
      .then((response) => response.body);
  },

  getSandbox(id) {
    return createRequest('GET', 'sandboxes/' + id)
      .promise()
      .then((response) => response.body);
  },

  updateSandboxes(id, params) {
    return createRequest('PUT', 'sandboxes/' + id)
      .send({
        ...params,
        // TODO: Remove `branchId` from here after backend will be fixed (https://keboola.atlassian.net/browse/PS-3221)
        ...(DevBranchesStore.isDevModeActive() && {
          branchId: DevBranchesStore.getCurrentId()?.toString()
        })
      })
      .promise()
      .then((response) => response.body);
  }
};

export default SandboxesApi;
