import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import InstalledComponentsActionCreators from '../../components/InstalledComponentsActionCreators';
import Secret from './Secret';
import SecretForm from './SecretForm';

type Props = {
  configId: string;
  componentId: string;
  configData: Map<string, any>;
  secrets: Map<string, string>;
  readOnly: boolean;
};

class SecretsBox extends React.Component<Props> {
  state = {
    isSavingNew: false,
    isAddingNew: false,
    saving: Map(),
    deleting: Map(),
    editing: Map()
  };

  render() {
    return (
      <div className="box box-secrets pbp-2">
        <div className="box-header smaller ptp-2 prp-1 plp-6">
          <h2 className="box-title line-height-24">Secrets</h2>
          {!this.props.readOnly && (
            <Button
              bsStyle="link"
              className="header-inline-button color-success"
              onClick={() => this.setState({ isAddingNew: true })}
              disabled={this.state.isAddingNew}
            >
              <FontAwesomeIcon icon="plus" className="icon-addon-right" />
              New Secret
            </Button>
          )}
        </div>
        {this.renderSecrets()}
      </div>
    );
  }

  renderSecrets() {
    if (this.props.secrets.count() === 0 && !this.state.isAddingNew) {
      return null;
    }

    return (
      <div className="box-content ptp-2 prp-6 pbp-2 plp-6">
        {this.props.secrets
          .map((value, name) => {
            if (!name) {
              return null;
            }

            if (this.state.editing.has(name)) {
              return (
                <SecretForm
                  key={`edit-${name}`}
                  onSave={(newName: string, value: string) => {
                    return this.handleUpdate(name, newName, value);
                  }}
                  onReset={() => {
                    this.setState({ editing: this.state.editing.delete(name) });
                  }}
                  name={name}
                  value={value}
                  isSaving={this.state.saving.has(name)}
                  secrets={this.props.secrets}
                  readOnly={this.props.readOnly}
                />
              );
            }
            return (
              <Secret
                key={`view-${name}`}
                name={name}
                value={value || ''}
                readOnly={this.props.readOnly}
                deleteVariableFn={() => this.handleDelete(name)}
                startEditingFn={() => {
                  this.setState({ editing: this.state.editing.set(name, true) });
                }}
                isDeleting={this.state.deleting.has(name)}
              />
            );
          })
          .toArray()}
        {this.state.isAddingNew && (
          <SecretForm
            onSave={this.handleAdd}
            onReset={() => this.setState({ isAddingNew: false })}
            isSaving={this.state.isSavingNew}
            secrets={this.props.secrets}
            readOnly={this.props.readOnly}
          />
        )}
      </div>
    );
  }

  handleUpdate = (oldName: string, name: string, value: string) => {
    this.setState({ saving: this.state.saving.set(oldName, true) });
    return this.saveConfigData(
      this.props.configData
        .deleteIn(['parameters', 'dataApp', 'secrets', oldName])
        .setIn(['parameters', 'dataApp', 'secrets', name], value),
      'Update secret'
    ).finally(() => this.setState({ saving: this.state.saving.delete(oldName) }));
  };

  handleAdd = (name: string, value: string) => {
    this.setState({ isSavingNew: true });
    return this.saveConfigData(
      this.props.configData.updateIn(['parameters', 'dataApp', 'secrets'], Map(), (secrets) => {
        return secrets.set(name, value);
      }),
      'Add new secret'
    ).finally(() => this.setState({ isSavingNew: false }));
  };

  handleDelete = (name: string) => {
    this.setState({ deleting: this.state.deleting.set(name, true) });
    return this.saveConfigData(
      this.props.configData
        .deleteIn(['parameters', 'dataApp', 'secrets', name])
        .updateIn(['parameters', 'dataApp'], (dataApp) => {
          if (dataApp.get('secrets', Map()).isEmpty()) {
            return dataApp.delete('secrets');
          }

          return dataApp;
        }),
      'Delete secret'
    ).finally(() => this.setState({ deleting: this.state.deleting.delete(name) }));
  };

  saveConfigData = (configData: Map<string, any>, changeDescription: string) => {
    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.props.componentId,
      this.props.configId,
      configData,
      changeDescription
    );
  };
}

export default SecretsBox;
