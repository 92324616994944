import React from 'react';
import PropTypes from 'prop-types';

import { SHARING_TYPE_LABELS } from '../constants';

const SharedToLabel = ({ sharing }) => {
  if (!SHARING_TYPE_LABELS[sharing]) {
    return null;
  }

  return <div className="shared-to-label">{SHARING_TYPE_LABELS[sharing]}</div>;
};

SharedToLabel.propTypes = {
  sharing: PropTypes.string.isRequired
};

export default SharedToLabel;
