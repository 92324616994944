import React from 'react';
import createReactClass from 'create-react-class';

import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import TransformationBucketsStore from '../../../stores/TransformationBucketsStore';
import NewTransformation from '../../modals/NewTransformation';

const TransformationBucketHeaderButton = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, RoutesStore, TransformationBucketsStore)],

  getStateFromStores() {
    const bucketId = RoutesStore.getCurrentRouteParam('config');

    return {
      bucket: TransformationBucketsStore.get(bucketId),
      readOnly: ApplicationStore.isReadOnly()
    };
  },

  render() {
    if (this.state.readOnly || !this.state.bucket) {
      return null;
    }

    return <NewTransformation bucket={this.state.bucket} />;
  }
});

export default TransformationBucketHeaderButton;
