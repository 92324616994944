import Promise from 'bluebird';
import { fromJS, List } from 'immutable';
import { strRight } from 'underscore.string';

import { KEBOOLA_SHARED_CODE, KEBOOLA_VARIABLES } from '../../constants/componentIds';
import { HTTP_STATUS_CODE_NOT_FOUND } from '../../utils/errors/helpers';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import componentsApi from '../components/InstalledComponentsApi';
import {
  createVariablesDefinition,
  deleteVariablesDefinition,
  updateVariablesDefinition
} from '../components/react/components/generic/variables/actions';
import ConfigurationRowsActionCreators from '../configurations/ConfigurationRowsActionCreators';

const findConfig = (configs, componentId) => {
  return fromJS(configs).find((config) => {
    return config.getIn(['configuration', 'componentId']) === componentId;
  });
};

const createSharedCode = (type, name, code = '', variables = List()) => {
  return componentsApi
    .getComponentConfigurations(KEBOOLA_SHARED_CODE)
    .then((configs) => {
      const config = findConfig(configs, type);

      if (config) {
        return config;
      }

      const data = {
        name: 'Shared Code',
        configuration: JSON.stringify({ componentId: type }),
        configurationId: `shared-codes.${strRight(type, '.')}`
      };

      return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_SHARED_CODE, data);
    })
    .then(fromJS)
    .then((config) => {
      if (!variables || variables.isEmpty()) {
        return Promise.props({ config });
      }

      return createVariablesDefinition(variables, KEBOOLA_SHARED_CODE, config.get('id')).then(
        (variableConfig) => {
          return Promise.props({ config, variableConfig });
        }
      );
    })
    .then(({ config, variableConfig }) => {
      let configuration = fromJS({ code_content: code });

      if (variableConfig) {
        configuration = configuration.set('variables_id', variableConfig.id);
      }

      return ConfigurationRowsActionCreators.createSimple(KEBOOLA_SHARED_CODE, config.get('id'), {
        name,
        configuration: JSON.stringify(configuration.toJS())
      }).then(({ id }) => {
        return InstalledComponentsActionCreators.loadComponentConfigsDataForce(
          KEBOOLA_SHARED_CODE
        ).then(() => ({
          sharedCodesConfigurationId: config.get('id'),
          sharedCodesConfigurationRowId: id
        }));
      });
    });
};

const editSharedCode = (configId, rowId, variableId, name, code, variables, changeDescription) => {
  return Promise.resolve()
    .then(() => {
      if (!variableId && variables.isEmpty()) {
        return null;
      } else if (!variableId) {
        return createVariablesDefinition(variables, KEBOOLA_SHARED_CODE, configId);
      } else if (variables.isEmpty()) {
        return deleteVariablesDefinition(variableId);
      } else {
        return updateVariablesDefinition(variableId, variables).catch((error) => {
          // variable config can be deleted, may occur after configuration rollback
          if (error.response?.statusCode === HTTP_STATUS_CODE_NOT_FOUND) {
            return createVariablesDefinition(variables, KEBOOLA_SHARED_CODE, configId);
          }

          throw error;
        });
      }
    })
    .then((variableConfig) => {
      let configuration = fromJS({ code_content: code });

      if (variableConfig) {
        configuration = configuration.set('variables_id', variableConfig.id);
      }

      return ConfigurationRowsActionCreators.updateSimple(
        KEBOOLA_SHARED_CODE,
        configId,
        rowId,
        {
          name,
          configuration: JSON.stringify(configuration.toJS())
        },
        changeDescription
      );
    })
    .then(() => {
      return InstalledComponentsActionCreators.loadComponentConfigsDataForce(KEBOOLA_SHARED_CODE);
    });
};

const deleteSharedCode = (configurationId, rowId, variableId) => {
  return ConfigurationRowsActionCreators.deleteSimple(KEBOOLA_SHARED_CODE, configurationId, rowId)
    .then(() => {
      if (variableId) {
        return InstalledComponentsActionCreators.deleteConfiguration(
          KEBOOLA_VARIABLES,
          variableId,
          { transition: false, notification: false }
        );
      }
      return Promise.resolve();
    })
    .then(() => {
      return InstalledComponentsActionCreators.loadComponentConfigsDataForce(KEBOOLA_SHARED_CODE);
    });
};

export default {
  createSharedCode,
  editSharedCode,
  deleteSharedCode
};
