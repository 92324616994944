import { fromJS, Map } from 'immutable';

import ApplicationActionCreators from '../../actions/ApplicationActionCreators';
import Dispatcher from '../../Dispatcher';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import fromJSOrdered from '../../utils/fromJSOrdered';
import injectDefaultsFromConfigurationSchema from '../../utils/injectDefaultsFromConfigurationSchema';
import { ensureComponentWithDetails } from '../components/helpers';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import InstalledComponentsApi from '../components/InstalledComponentsApi';
import { prepareMappingForSave } from '../components/react/components/generic/helpers';
import configurationRowDeleted from '../components/react/components/notifications/configurationRowDeleted';
import preferEncryptedAttributes from '../components/utils/preferEncryptedAttributes';
import VersionActionCreators from '../components/VersionsActionCreators';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import { prepareDataForSaveInDevBranch } from '../dev-branches/helpers';
import { emptyComponentState, removeTableFromInputTableState } from './utils/configurationState';
import Constants from './ConfigurationRowsConstants';
import ConfigurationRowsStore from './ConfigurationRowsStore';
import DockerActionsActionCreators from './DockerActionsActionCreators';
import RowVersionsActionCreators from './RowVersionsActionCreators';

const storeEncodedConfigurationRow = function (
  componentId,
  configurationId,
  rowId,
  configuration,
  changeDescription
) {
  const projectId = ApplicationStore.getCurrentProject().get('id');
  const dataToSavePrepared = JSON.stringify(
    preferEncryptedAttributes(
      prepareDataForSaveInDevBranch(componentId, configuration, DevBranchesStore.getCurrentId())
    )
  );

  return InstalledComponentsApi.encryptConfiguration(
    componentId,
    projectId,
    dataToSavePrepared
  ).then((result) => {
    const dataToSaveEncrypted = {
      configuration: JSON.stringify(result),
      changeDescription: changeDescription
    };
    return InstalledComponentsApi.updateConfigurationRow(
      componentId,
      configurationId,
      rowId,
      dataToSaveEncrypted,
      changeDescription
    );
  });
};

export const createSimpleRow = (componentId, configurationId, data, changeDescription) => {
  return ensureComponentWithDetails(componentId)
    .then((component) => {
      return injectDefaultsFromConfigurationSchema(
        data,
        component.get('configurationRowSchema', Map())
      );
    })
    .then((data) => {
      return InstalledComponentsApi.createConfigurationRow(
        componentId,
        configurationId,
        data.configuration && DevBranchesStore.isDevModeActive()
          ? {
              ...data,
              configuration: JSON.stringify(
                prepareDataForSaveInDevBranch(
                  componentId,
                  JSON.parse(data.configuration),
                  DevBranchesStore.getCurrentId()
                )
              )
            }
          : data,
        changeDescription
      );
    });
};

export default {
  createSimple: function (componentId, configurationId, data, changeDescription) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_CREATE_START,
      componentId: componentId,
      configurationId: configurationId
    });
    return createSimpleRow(componentId, configurationId, data, changeDescription)
      .then((response) => {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_CREATE_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          data: response
        });
        return response;
      })
      .catch((e) => {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_CREATE_ERROR,
          componentId: componentId,
          configurationId: configurationId,
          error: e
        });
        throw e;
      });
  },

  updateSimple: function (componentId, configurationId, rowId, data, changeDescription) {
    return InstalledComponentsApi.updateConfigurationRow(
      componentId,
      configurationId,
      rowId,
      data.configuration && DevBranchesStore.isDevModeActive()
        ? {
            ...data,
            configuration: JSON.stringify(
              prepareDataForSaveInDevBranch(
                componentId,
                JSON.parse(data.configuration),
                DevBranchesStore.getCurrentId()
              )
            )
          }
        : data,
      changeDescription
    ).then((response) => {
      VersionActionCreators.loadVersionsForce(componentId, configurationId);
      RowVersionsActionCreators.loadVersionsForce(componentId, configurationId, rowId);
      Dispatcher.handleViewAction({
        type: Constants.ActionTypes.CONFIGURATION_ROWS_UPDATE_SUCCESS,
        componentId: componentId,
        configurationId: configurationId,
        rowId: rowId,
        data: response
      });
      return response;
    });
  },

  saveConfigurationSimple: function (
    componentId,
    configurationId,
    rowId,
    configuration,
    changeDescription,
    options
  ) {
    if (!options?.skipResetEditing) {
      Dispatcher.handleViewAction({
        type: Constants.ActionTypes.CONFIGURATION_ROWS_SAVE_CONFIGURATION_START,
        componentId,
        configurationId,
        rowId
      });
    }
    return storeEncodedConfigurationRow(
      componentId,
      configurationId,
      rowId,
      configuration.toJS(),
      changeDescription
    )
      .then((response) => {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        RowVersionsActionCreators.loadVersionsForce(componentId, configurationId, rowId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_SAVE_CONFIGURATION_SUCCESS,
          componentId,
          configurationId,
          rowId,
          row: response,
          options
        });
        return response.configuration;
      })
      .catch((e) => {
        if (!options?.skipResetEditing) {
          Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CONFIGURATION_ROWS_SAVE_CONFIGURATION_ERROR,
            componentId: componentId,
            configurationId: configurationId,
            rowId: rowId,
            error: e
          });
        }
        throw e;
      });
  },

  delete: function (componentId, configurationId, rowId, transition, changeDescription) {
    if (transition) {
      RoutesStore.getRouter().transitionToForce(componentId, {
        component: componentId,
        config: configurationId
      });
    }
    const row = ConfigurationRowsStore.get(componentId, configurationId, rowId);
    return this.deleteSimple(
      componentId,
      configurationId,
      rowId,
      changeDescription
        ? changeDescription
        : 'Row ' + (row.get('name') !== '' ? row.get('name') : 'Untitled') + ' deleted'
    ).then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: configurationRowDeleted(componentId, configurationId, row)
      });
      return null;
    });
  },

  deleteSimple: function (componentId, configurationId, rowId, changeDescription) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_DELETE_START,
      componentId,
      configurationId,
      rowId
    });
    return InstalledComponentsApi.deleteConfigurationRow(
      componentId,
      configurationId,
      rowId,
      changeDescription
    )
      .then(() => {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_DELETE_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          rowId: rowId
        });
        return null;
      })
      .catch((error) => {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_DELETE_ERROR,
          componentId,
          configurationId,
          rowId
        });
        throw error;
      });
  },

  disable: function (componentId, configurationId, rowId, changeDescription) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_DISABLE_START,
      componentId: componentId,
      configurationId: configurationId,
      rowId: rowId
    });
    const row = ConfigurationRowsStore.get(componentId, configurationId, rowId);
    return InstalledComponentsApi.updateConfigurationRow(
      componentId,
      configurationId,
      rowId,
      { isDisabled: 1 },
      changeDescription
        ? changeDescription
        : 'Row ' + (row.get('name') !== '' ? row.get('name') : 'Untitled') + ' disabled'
    )
      .then(() => {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        RowVersionsActionCreators.loadVersionsForce(componentId, configurationId, rowId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_DISABLE_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          rowId: rowId
        });
        return null;
      })
      .catch((e) => {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_DISABLE_ERROR,
          componentId: componentId,
          configurationId: configurationId,
          rowId: rowId,
          error: e
        });
        throw e;
      });
  },

  enable: function (componentId, configurationId, rowId, changeDescription) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_ENABLE_START,
      componentId: componentId,
      configurationId: configurationId,
      rowId: rowId
    });
    const row = ConfigurationRowsStore.get(componentId, configurationId, rowId);
    return InstalledComponentsApi.updateConfigurationRow(
      componentId,
      configurationId,
      rowId,
      { isDisabled: 0 },
      changeDescription
        ? changeDescription
        : 'Row ' + (row.get('name') !== '' ? row.get('name') : 'Untitled') + ' enabled'
    )
      .then(() => {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        RowVersionsActionCreators.loadVersionsForce(componentId, configurationId, rowId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_ENABLE_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          rowId: rowId
        });
        return null;
      })
      .catch((e) => {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_ENABLE_ERROR,
          componentId: componentId,
          configurationId: configurationId,
          rowId: rowId,
          error: e
        });
        throw e;
      });
  },

  updateJsonConfiguration: function (componentId, configurationId, rowId, value) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_UPDATE_JSON_CONFIGURATION,
      componentId: componentId,
      configurationId: configurationId,
      rowId: rowId,
      value: value
    });
  },

  resetJsonConfiguration: function (componentId, configurationId, rowId) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_RESET_JSON_CONFIGURATION,
      componentId: componentId,
      configurationId: configurationId,
      rowId: rowId
    });
  },

  saveJsonConfiguration: function (componentId, configurationId, rowId, changeDescription) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_SAVE_JSON_CONFIGURATION_START,
      componentId: componentId,
      configurationId: configurationId,
      rowId: rowId
    });
    const row = ConfigurationRowsStore.get(componentId, configurationId, rowId, changeDescription);
    const configuration = fromJSOrdered(
      JSON.parse(
        ConfigurationRowsStore.getEditingJsonConfigurationString(
          componentId,
          configurationId,
          rowId
        )
      )
    );

    return storeEncodedConfigurationRow(
      componentId,
      configurationId,
      rowId,
      configuration.toJS(),
      changeDescription
        ? changeDescription
        : 'Row ' +
            (row.get('name') !== '' ? row.get('name') : 'Untitled') +
            ' parameters edited manually'
    )
      .then((storedConfiguration) => {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        RowVersionsActionCreators.loadVersionsForce(componentId, configurationId, rowId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_SAVE_JSON_CONFIGURATION_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          rowId: rowId,
          value: fromJSOrdered(storedConfiguration).get('configuration')
        });
        return null;
      })
      .catch((e) => {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_SAVE_JSON_CONFIGURATION_ERROR,
          componentId: componentId,
          configurationId: configurationId,
          rowId: rowId,
          error: e
        });
        throw e;
      });
  },

  updateConfiguration: function (componentId, configurationId, rowId, value) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_UPDATE_CONFIGURATION,
      componentId: componentId,
      configurationId: configurationId,
      rowId: rowId,
      value: value
    });
  },

  resetConfiguration: function (componentId, configurationId, rowId) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_RESET_CONFIGURATION,
      componentId: componentId,
      configurationId: configurationId,
      rowId: rowId
    });
  },

  saveConfiguration: function (
    componentId,
    configurationId,
    rowId,
    createFn,
    parseFn,
    changeDescription
  ) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_SAVE_CONFIGURATION_START,
      componentId: componentId,
      configurationId: configurationId,
      rowId: rowId
    });
    const row = ConfigurationRowsStore.get(componentId, configurationId, rowId);
    const configuration = createFn(
      ConfigurationRowsStore.getEditingConfiguration(componentId, configurationId, rowId, parseFn)
    );
    return storeEncodedConfigurationRow(
      componentId,
      configurationId,
      rowId,
      configuration.toJS(),
      changeDescription
        ? changeDescription
        : 'Row ' + (row.get('name') !== '' ? row.get('name') : 'Untitled') + ' edited'
    )
      .then((response) => {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        RowVersionsActionCreators.loadVersionsForce(componentId, configurationId, rowId);
        InstalledComponentsActionCreators.loadComponentConfigDataForce(
          componentId,
          configurationId
        ).then(() => {
          DockerActionsActionCreators.reloadRowSyncActions(componentId, configurationId, rowId);
        });
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_SAVE_CONFIGURATION_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          rowId: rowId,
          row: response
        });
        return null;
      })
      .catch((e) => {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_SAVE_CONFIGURATION_ERROR,
          componentId: componentId,
          configurationId: configurationId,
          rowId: rowId,
          error: e
        });
        throw e;
      });
  },

  openJsonEditor: function (componentId, configurationId, rowId) {
    this.resetConfiguration(componentId, configurationId, rowId);
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_JSON_EDITOR_OPEN,
      componentId: componentId,
      configurationId: configurationId,
      rowId: rowId
    });
  },

  closeJsonEditor: function (componentId, configurationId, rowId) {
    this.resetJsonConfiguration(componentId, configurationId, rowId);
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_JSON_EDITOR_CLOSE,
      componentId: componentId,
      configurationId: configurationId,
      rowId: rowId
    });
  },

  updateComponentState: function (componentId, configurationId, rowId, state) {
    return InstalledComponentsApi.updateConfigurationRowState(
      componentId,
      configurationId,
      rowId,
      { state: JSON.stringify(state.toJS()) },
      `Update configuration row state`
    ).then((response) => {
      Dispatcher.handleViewAction({
        type: Constants.ActionTypes.CONFIGURATION_ROWS_UPDATE_SUCCESS,
        componentId,
        configurationId,
        rowId,
        data: response
      });
      return response;
    });
  },

  clearComponentState: function (componentId, configurationId, rowId) {
    return InstalledComponentsApi.getConfigurationRow(componentId, configurationId, rowId).then(
      (response) => {
        return this.updateComponentState(
          componentId,
          configurationId,
          rowId,
          emptyComponentState(fromJS(response.state))
        );
      }
    );
  },

  clearInputMappingState: function (componentId, configurationId, rowId, tableId) {
    return InstalledComponentsApi.getConfigurationRow(componentId, configurationId, rowId).then(
      (response) => {
        return this.updateComponentState(
          componentId,
          configurationId,
          rowId,
          removeTableFromInputTableState(fromJS(response.state), tableId)
        );
      }
    );
  },

  startEditingMapping: function (componentId, configId, rowId, mappingType, storage, index) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_MAPPING_EDITING_START,
      componentId,
      configId,
      rowId,
      mappingType,
      storage,
      index
    });
  },

  changeEditingMapping: function (
    componentId,
    configId,
    rowId,
    mappingType,
    storage,
    index,
    value
  ) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_MAPPING_EDITING_CHANGE,
      componentId,
      configId,
      rowId,
      mappingType,
      storage,
      index,
      value
    });
  },

  cancelEditingMapping: function (componentId, configId, rowId, mappingType, storage, index) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_MAPPING_EDITING_CANCEL,
      componentId,
      configId,
      rowId,
      mappingType,
      storage,
      index
    });
  },

  saveEditingMapping: function (
    componentId,
    configId,
    rowId,
    mappingType,
    storage,
    index,
    changeDescription
  ) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_MAPPING_SAVE_START,
      componentId,
      configId,
      rowId,
      mappingType,
      storage,
      index
    });

    const config = prepareMappingForSave(
      componentId,
      ConfigurationRowsStore.getConfiguration(componentId, configId, rowId),
      ConfigurationRowsStore.getEditingConfiguration(componentId, configId, rowId),
      mappingType,
      storage,
      index,
      ApplicationStore.hasNewQueue()
    );

    return storeEncodedConfigurationRow(
      componentId,
      configId,
      rowId,
      config.toJS(),
      changeDescription
    )
      .then((response) => {
        VersionActionCreators.loadVersionsForce(componentId, configId);
        RowVersionsActionCreators.loadVersionsForce(componentId, configId, rowId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_MAPPING_SAVE_SUCCESS,
          componentId,
          configId,
          rowId,
          mappingType,
          storage,
          index,
          data: response
        });
        return null;
      })
      .catch((error) => {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_MAPPING_SAVE_ERROR,
          componentId,
          configId,
          rowId
        });
        throw error;
      });
  },

  deleteMapping: function (
    componentId,
    configId,
    rowId,
    mappingType,
    storage,
    index,
    changeDescription
  ) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CONFIGURATION_ROWS_MAPPING_DELETE_START,
      componentId,
      configId,
      rowId,
      mappingType,
      storage,
      index
    });
    let config = ConfigurationRowsStore.getConfiguration(componentId, configId, rowId);
    config = config.deleteIn(['storage', mappingType, storage, index]);
    return storeEncodedConfigurationRow(
      componentId,
      configId,
      rowId,
      config.toJS(),
      changeDescription
    )
      .then((response) => {
        VersionActionCreators.loadVersionsForce(componentId, configId);
        RowVersionsActionCreators.loadVersionsForce(componentId, configId, rowId);
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_MAPPING_DELETE_SUCCESS,
          componentId,
          configId,
          rowId,
          mappingType,
          storage,
          index,
          data: response
        });
        return null;
      })
      .catch((error) => {
        Dispatcher.handleViewAction({
          type: Constants.ActionTypes.CONFIGURATION_ROWS_MAPPING_DELETE_ERROR,
          componentId,
          configId,
          rowId
        });
        throw error;
      });
  }
};
