import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import Confirm from './Confirm';
import Loader from './Loader';
import Tooltip from './Tooltip';

/** @type {any} */
const DeleteButton = createReactClass({
  propTypes: {
    confirm: PropTypes.shape({
      title: PropTypes.string.isRequired,
      onConfirm: PropTypes.func.isRequired,
      text: PropTypes.any,
      buttonLabel: PropTypes.string,
      closeAfterResolve: PropTypes.bool
    }).isRequired,
    isPending: PropTypes.bool,
    tooltip: PropTypes.string,
    isEnabled: PropTypes.bool,
    label: PropTypes.string,
    pendingLabel: PropTypes.string,
    icon: PropTypes.string,
    hideIcon: PropTypes.bool,
    buttonClass: PropTypes.string,
    buttonStyle: PropTypes.string
  },

  getDefaultProps() {
    return {
      isPending: false,
      isEnabled: true,
      label: '',
      pendingLabel: '',
      icon: 'trash',
      hideIcon: false,
      buttonClass: '',
      buttonStyle: 'link'
    };
  },

  render() {
    if (this.props.isPending && !this.props.confirm.closeAfterResolve) {
      return (
        <Button bsStyle={this.props.buttonStyle} className={this.props.buttonClass} disabled>
          {this.renderLoader()}
        </Button>
      );
    }

    if (!this.props.isEnabled) {
      return (
        <Button bsStyle={this.props.buttonStyle} className={this.props.buttonClass} disabled>
          {this.renderButtonLabel()}
        </Button>
      );
    }

    const getTooltip = () => {
      if (this.props.tooltip) {
        return this.props.tooltip;
      }

      if (!this.props.label) {
        return 'Delete';
      }

      return '';
    };

    return (
      <Confirm
        icon="trash"
        buttonLabel={this.props.isPending ? 'Deleting...' : 'Delete'}
        {...this.props.confirm}
        {...(this.props.confirm.closeAfterResolve && { isLoading: this.props.isPending })}
      >
        <Tooltip tooltip={getTooltip()} placement="top" forceHide={!getTooltip()}>
          <Button
            bsStyle={this.props.buttonStyle}
            className={this.props.buttonClass}
            disabled={this.props.isPending}
          >
            {this.renderButtonLabel()}
          </Button>
        </Tooltip>
      </Confirm>
    );
  },

  renderButtonLabel() {
    if (this.props.label) {
      return (
        <>
          {!this.props.hideIcon && (
            <FontAwesomeIcon icon={this.props.icon} className="icon-addon-right" />
          )}
          {this.props.label}
        </>
      );
    }

    return <FontAwesomeIcon icon={this.props.icon} fixedWidth />;
  },

  renderLoader() {
    if (this.props.pendingLabel) {
      return (
        <>
          <Loader className="icon-addon-right" />
          {this.props.pendingLabel}
        </>
      );
    }

    return <Loader />;
  }
});

export default DeleteButton;
