import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import Link from '../../../react/common/RouterLink';
import { routeNames } from '../constants';

const tableHasBeenRestoredNotification = (table) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired
    },

    render() {
      return (
        <span>
          Table{' '}
          <Link
            to={routeNames.TABLE}
            params={{ bucketId: table.getIn(['bucket', 'id']), tableName: table.get('name') }}
            onClick={this.props.onClick}
          >
            {table.get('displayName')}
          </Link>{' '}
          has been created.
        </span>
      );
    }
  });
};

export default tableHasBeenRestoredNotification;
