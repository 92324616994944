import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import User from '../../../react/common/User';

const renderOwner = (admins, owner) => {
  const name = owner.get('name');

  if (!name) {
    return 'N/A';
  }

  if (!admins.get(name)) {
    return name;
  }

  return <User user={admins.get(name)} size={24} />;
};

const OwnerRow = ({ owner, admins }) => (
  <div className="first-line font-medium flex-container">
    <span>Owner</span>
    <span className="flex-container flex-end font-normal text-muted">
      {renderOwner(admins, owner)}
    </span>
  </div>
);

OwnerRow.propTypes = {
  owner: PropTypes.instanceOf(Map).isRequired,
  admins: PropTypes.instanceOf(Map).isRequired
};

const MemoizedOwnerRow = React.memo(OwnerRow);

export default MemoizedOwnerRow;
