import React from 'react';
import { useInView } from 'react-intersection-observer';
import { select } from 'd3-selection';
import { type List, Map } from 'immutable';
import _ from 'underscore';
import { capitalize } from 'underscore.string';

import { formatAbsolute } from '../../../react/common/CreatedDate';
import RoutesStore from '../../../stores/RoutesStore';
import dimple from '../../../utils/dimple';
import { durationInWords } from '../../../utils/duration';
import multiRef from '../../../utils/multiRef';
import { JOBS_LIMIT_FOR_RUN_RESULTS, JOBS_STATUS, routeNames } from '../../queue/constants';
import { prepareGraphData } from '../../queue/helpers';

type AGG_DATA = ['Hours' | 'Minutes' | 'Seconds', string, number, string];
type DATA = { aggField: AGG_DATA; yValueList: [number] };

const COLORS_MAP = {
  [JOBS_STATUS.SUCCESS]: '#BAF5BA',
  [JOBS_STATUS.WARNING]: '#b88d00',
  [JOBS_STATUS.TERMINATED]: '#7C8594',
  [JOBS_STATUS.ERROR]: '#EC001D'
};

const RunResults = (props: { jobs?: List<Map<string, any>> }) => {
  const graphRef = React.useRef<HTMLDivElement>(null);
  const chartRef = React.useRef<any>(null);
  const { ref, inView } = useInView({ triggerOnce: true });

  const data = React.useMemo(() => {
    if (!props.jobs) {
      return null;
    }

    return prepareGraphData(props.jobs, { onlyJobs: true, limit: JOBS_LIMIT_FOR_RUN_RESULTS });
  }, [props.jobs]);

  React.useEffect(() => {
    if (!inView || !data || data.get('jobs').isEmpty()) {
      return;
    }

    const goToJob = (jobId: number) => {
      RoutesStore.getRouter().transitionTo(routeNames.JOB_DETAIL, { jobId });
    };

    const prepareJobs = () => {
      let jobs = data.get('jobs');
      const jobsCount = jobs.count();

      if (jobsCount < JOBS_LIMIT_FOR_RUN_RESULTS) {
        _.times(JOBS_LIMIT_FOR_RUN_RESULTS - jobsCount, (index) => {
          jobs = jobs.push(Map({ duration: 0, index: index + jobsCount }));
        });
      }

      return jobs.toJS();
    };

    const preparedData = prepareJobs();

    // data refresh
    if (chartRef.current) {
      if (_.isEqual(chartRef.current.data, preparedData)) return;

      chartRef.current.svg.selectAll('.dimple-status-circle').remove();
      chartRef.current.data = preparedData;
      chartRef.current.draw(0);
      return;
    }

    const svg = dimple.newSvg(graphRef.current, 110, 36);
    const chart = new dimple.chart(svg, preparedData);

    const xAxis = chart.addCategoryAxis('x', 'index');
    xAxis.hidden = true;

    const yAxis = chart.addMeasureAxis('y', 'duration');
    yAxis.hidden = true;

    const barSeries = chart.addSeries(['unit', 'date', 'jobId', 'status'], dimple.plot.bar);

    barSeries.addEventHandler('click', (data: { seriesValue: AGG_DATA }) =>
      goToJob(data.seriesValue[2])
    );

    barSeries.getTooltipText = (e: DATA) => [
      `${capitalize(e.aggField[3])}${e.aggField[3] === JOBS_STATUS.SUCCESS ? '!' : ''}`,
      `Duration: ${durationInWords(e.yValueList[0], e.aggField[0])}`,
      formatAbsolute(e.aggField[1])
    ];

    chart.assignColor(JOBS_STATUS.ERROR, COLORS_MAP[JOBS_STATUS.ERROR]);
    chart.assignColor(JOBS_STATUS.SUCCESS, COLORS_MAP[JOBS_STATUS.SUCCESS]);
    chart.assignColor(JOBS_STATUS.WARNING, COLORS_MAP[JOBS_STATUS.WARNING]);
    chart.assignColor(JOBS_STATUS.TERMINATED, COLORS_MAP[JOBS_STATUS.TERMINATED]);
    chart.setMargins(0, 8, 0, 0);
    chart.draw(100);

    barSeries.afterDraw = (shape: Element, data: DATA) => {
      shape.classList.add('clickable');

      if (
        ![JOBS_STATUS.ERROR, JOBS_STATUS.WARNING, JOBS_STATUS.TERMINATED].includes(data.aggField[3])
      ) {
        return;
      }

      const rect = select(shape);

      select(shape.parentNode)
        .append('circle')
        .attr('class', `clickable dimple-status-circle`)
        .attr('cx', parseFloat(rect.attr('x')) + 4)
        .attr('cy', parseFloat(rect.attr('y')) - 6)
        .attr('r', 3)
        .attr('fill', COLORS_MAP[data.aggField[3]])
        .on('mouseenter', () => dimple._showBarTooltip(data, shape, chart, barSeries))
        .on('mouseleave', () => dimple._removeTooltip(data, shape, chart, barSeries))
        .on('click', () => goToJob(data.aggField[2]));
    };

    chartRef.current = chart;
  }, [inView, data]);

  React.useEffect(() => {
    const redraw = () => chartRef.current?.draw(0, true);

    window.addEventListener('focus', redraw, { passive: true });
    return () => {
      window.removeEventListener('focus', redraw);
    };
  }, []);

  if (!data || data.get('jobs').isEmpty()) {
    return <span className="text-muted">No run yet</span>;
  }

  return (
    <div
      className="run-results-graph"
      ref={multiRef(ref, graphRef)}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

export default RunResults;
