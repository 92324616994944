import React from 'react';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import ExternalLink from '../../../react/common/ExternalLink';
import Select from '../../../react/common/Select';
import Tooltip from '../../../react/common/Tooltip';
import { SANDBOX_LABELS, SANDBOX_SIZES, SUPPORT_SIZES } from '../Constants';
import { getBackendSizeNote } from '../helpers';

const SIZES_OPTIONS = Object.values(SANDBOX_SIZES).map((size) => {
  return { value: size, label: SANDBOX_LABELS[size] };
});

const WorkspaceSizeControl = (props: {
  type: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled: boolean;
  hasPayAsYouGo: boolean;
}) => {
  if (props.hasPayAsYouGo || !SUPPORT_SIZES.includes(props.type)) {
    return null;
  }

  return (
    <FormGroup>
      <ControlLabel>
        Workspace Size{' '}
        <Tooltip
          placement="top"
          type="explanatory"
          tooltip={
            <div>
              <div className="tooltip-title">
                Cost for 1 hour of active workspace (billed per second)
              </div>
              <div>
                <p>
                  <b>SMALL</b>: 1 time credit
                </p>
                <p>
                  <b>MEDIUM</b>: 2 time credits
                </p>
                <p>
                  <b>LARGE</b>: 6 time credits
                </p>
              </div>
              <p className="tw-mb-0">{getBackendSizeNote()}</p>
            </div>
          }
        >
          <FontAwesomeIcon icon="circle-info" className="text-muted" />
        </Tooltip>
      </ControlLabel>
      <Select
        clearable={false}
        options={SIZES_OPTIONS}
        value={props.value}
        onChange={props.onChange}
        disabled={props.isDisabled}
      />
      <HelpBlock>
        Read more about workspace sizes and time credits in our{' '}
        <ExternalLink
          href={`${USER_DOCUMENTATION_URL}/management/project/limits/#project-power--time-credits`}
        >
          documentation
        </ExternalLink>
        .
      </HelpBlock>
    </FormGroup>
  );
};

export default WorkspaceSizeControl;
