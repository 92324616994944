import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { KEBOOLA_EX_GMAIL } from '../../../constants/componentIds';
import { USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import ExternalLink from '../../../react/common/ExternalLink';
import OptionalFormLabel from '../../../react/common/OptionalFormLabel';
import { isGoogleService } from '../helpers';

const CustomAuthorizationFields = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    onChangeFn: PropTypes.func,
    disabled: PropTypes.bool
  },

  render() {
    let fields = { appKey: 'Client ID', appSecret: 'Client secret' };

    return (
      <div>
        {isGoogleService(this.props.componentId) && (
          <p>
            Provide your own OAuth 2.0 credentials from{' '}
            <ExternalLink href="https://console.developers.google.com">
              Google API Console
            </ExternalLink>
            .
            <br />
            Follow these <ExternalLink href={this.getInstructionsUrl()}>
              instructions
            </ExternalLink>{' '}
            to set up an application and obtain a pair of credentials.
          </p>
        )}
        <div className="form-group">
          <label className="control-label col-sm-3">
            Name <OptionalFormLabel />
          </label>
          <div className="col-sm-9">
            <FormControl
              value={this.props.values.authorizedFor || ''}
              onChange={(e) => this.props.onChangeFn('authorizedFor', e.target.value)}
              autoFocus
              disabled={this.props.disabled}
            />
            <p className="help-block">Describe this authorization, e.g. by the account name.</p>
          </div>
        </div>
        {Object.keys(fields).map((key) => this.renderInput(key, fields[key]))}
      </div>
    );
  },

  renderInput(key, name) {
    return (
      <div className="form-group" key={key}>
        <label className="control-label col-sm-3">{name}</label>
        <div className="col-sm-9">
          <FormControl
            name={key}
            value={this.props.values[key] || ''}
            onChange={(e) => this.props.onChangeFn(key, e.target.value)}
            disabled={this.props.disabled}
          />
          <p className="help-block">{name} of your app</p>
        </div>
      </div>
    );
  },

  getInstructionsUrl() {
    if (this.props.componentId === KEBOOLA_EX_GMAIL) {
      return `${USER_DOCUMENTATION_URL}/components/extractors/communication/gmail/#authorization`;
    }

    return `${USER_DOCUMENTATION_URL}/components/extractors/marketing-sales/google-analytics/#custom-oauth-credentials`;
  }
});

export default CustomAuthorizationFields;
