import React from 'react';
import PropTypes from 'prop-types';
import { Alert, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR, ORCHESTRATOR } from '../../constants/componentIds';
import { componentTypes } from '../../constants/componentTypes';
import keyCodes from '../../constants/keyCodes';
import InstalledComponentsApi from '../../modules/components/InstalledComponentsApi';
import Checkbox from './Checkbox';
import ConfirmButtons from './ConfirmButtons';
import CreatedDate from './CreatedDate';
import ModalIcon from './ModalIcon';

const CopyVersionModal = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    entity: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired,
    version: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
    newVersionName: PropTypes.string,
    onChangeName: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isRow: PropTypes.bool,
    isLast: PropTypes.bool
  },

  getInitialState() {
    return {
      configuration: Map(),
      excludeRows: false
    };
  },

  render() {
    return (
      <span
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
      >
        <Modal
          show={this.props.show}
          onHide={this.props.onClose}
          onEnter={() => {
            if (this.props.isRow) {
              return;
            }

            InstalledComponentsApi.getComponentConfigByVersion(
              this.props.componentId,
              this.props.configId,
              this.props.version.get('version')
            ).then((response) => this.setState({ configuration: fromJS(response) }));
          }}
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Version Copy</Modal.Title>
              <ModalIcon color="green" icon="clone" bold />
            </Modal.Header>
            <Modal.Body>
              {this.props.componentId === ORCHESTRATOR && !this.props.isLast && (
                <Alert bsStyle="warning">
                  <p>
                    Event Trigger scheduling cannot be automatically copied from older{' '}
                    {this.props.entity} version.
                  </p>
                  <p>
                    If {this.props.entity} had Event Trigger scheduling set, it has to be configured
                    again in the copied {this.props.entity}.
                  </p>
                </Alert>
              )}
              {this.props.componentType === componentTypes.TRANSFORMATION && !this.props.isLast && (
                <Alert bsStyle="warning">
                  <p>
                    If the {this.props.entity} has variables set then the latest versions of the
                    values for those variables will be used. This may be different from those used
                    in this version of the {this.props.entity}, so please verify them.
                  </p>
                </Alert>
              )}
              {this.props.componentId === KEBOOLA_ORCHESTRATOR && (
                <Alert bsStyle="warning">
                  <p>
                    If the {this.props.entity} has schedule set, it has to be configured again in
                    the copied {this.props.entity}.
                  </p>
                </Alert>
              )}
              <p>
                This will copy the version <strong>#{this.props.version.get('version')}</strong>{' '}
                created <CreatedDate createdTime={this.props.version.get('created')} /> by{' '}
                <strong>
                  {this.props.version.getIn(['creatorToken', 'description'], 'unknown')}
                </strong>{' '}
                to a new {this.props.entity}.
              </p>
              <FormGroup>
                <ControlLabel>New {this.props.entity} name</ControlLabel>
                <FormControl
                  autoFocus
                  type="text"
                  value={this.props.newVersionName}
                  onChange={(e) => this.props.onChangeName(e.target.value)}
                />
              </FormGroup>
              {!this.state.configuration.get('rows', List()).isEmpty() && (
                <FormGroup>
                  <Checkbox
                    checked={this.state.excludeRows}
                    onChange={(checked) => this.setState({ excludeRows: checked })}
                  >
                    Copy only empty configuration and keep credentials
                  </Checkbox>
                </FormGroup>
              )}
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveLabel={
                  this.props.isSaving
                    ? `Creating ${this.props.entity}...`
                    : `Copy ${this.props.entity}`
                }
                isSaving={this.props.isSaving}
                isDisabled={
                  !this.props.newVersionName.trim().length ||
                  (!this.props.isRow && this.state.configuration.isEmpty())
                }
              />
            </Modal.Footer>
          </Form>
        </Modal>
      </span>
    );
  },

  handleSubmit(e) {
    e.preventDefault();
    this.props.onCopy(this.state.excludeRows);
  }
});

export default CopyVersionModal;
