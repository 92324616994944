import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import { NEW_ENTITY_BUTTON } from '../../../constants/external';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import { canManageBuckets, canRegisterExternalBucket } from '../../admin/privileges';
import BucketsStore from '../../components/stores/StorageBucketsStore';
import { createBucket } from '../actions';
import CreateBucketModal from './CreateBucketModal';
import RegisterExternalBucketModal from './RegisterExternalBucketModal';

const HeaderButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, BucketsStore)],

  getStateFromStores() {
    return {
      sapiToken: ApplicationStore.getSapiToken(),
      readOnly: ApplicationStore.isReadOnly(),
      allBuckets: BucketsStore.getAll(),
      isCreatingBucket: BucketsStore.isCreatingBucket()
    };
  },

  getInitialState() {
    return {
      showCreateBucketModal: false,
      showRegisterExternalBucketModal: false
    };
  },

  render() {
    if (
      !canManageBuckets(this.state.sapiToken) &&
      !canRegisterExternalBucket(this.state.sapiToken)
    ) {
      return null;
    }

    return (
      <>
        {this.renderButtons()}
        {this.renderModals()}
      </>
    );
  },

  renderButtons() {
    return (
      <ButtonToolbar>
        {canRegisterExternalBucket(this.state.sapiToken) && (
          <Button
            bsStyle="primary"
            disabled={this.state.isCreatingBucket}
            onClick={() => this.setState({ showRegisterExternalBucketModal: true })}
            className={NEW_ENTITY_BUTTON}
          >
            Register External Data
          </Button>
        )}
        {canManageBuckets(this.state.sapiToken) && (
          <Button
            bsStyle="success"
            disabled={this.state.isCreatingBucket}
            onClick={() =>
              this.setState({
                showCreateBucketModal: true
              })
            }
            className={NEW_ENTITY_BUTTON}
          >
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            Create bucket
          </Button>
        )}
      </ButtonToolbar>
    );
  },

  renderModals() {
    return (
      <>
        <RegisterExternalBucketModal
          backend={this.state.sapiToken.getIn(['owner', 'defaultBackend'])}
          openModal={this.state.showRegisterExternalBucketModal}
          onHide={() => this.setState({ showRegisterExternalBucketModal: false })}
          isSaving={this.state.isCreatingBucket}
          buckets={this.state.allBuckets}
        />
        <CreateBucketModal
          openModal={this.state.showCreateBucketModal}
          sapiToken={this.state.sapiToken}
          onSubmit={createBucket}
          onHide={() => this.setState({ showCreateBucketModal: false })}
          isSaving={this.state.isCreatingBucket}
          buckets={this.state.allBuckets}
        />
      </>
    );
  }
});

export default HeaderButtons;
