import React from 'react';

import useStores from '../../../react/hooks/useStores';
import RoutesStore from '../../../stores/RoutesStore';
import TablesStore from '../../components/stores/StorageTablesStore';
import NativeTypesLabel from './NativeTypesLabel';

const TableLabels = () => {
  const { table } = useStores(
    () => {
      return {
        table: TablesStore.getTableByName(
          RoutesStore.getCurrentRouteParam('tableName'),
          RoutesStore.getCurrentRouteParam('bucketId')
        )
      };
    },
    [],
    [RoutesStore, TablesStore]
  );

  if (!table || table.isEmpty()) {
    return null;
  }

  return <NativeTypesLabel isTyped={table.get('isTyped', false)} className="in-title" />;
};

export default TableLabels;
