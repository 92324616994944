import React from 'react';
import { Button, Form, Label, Radio, Well } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { Map } from 'immutable';

import AnimationBar from '../../../react/common/AnimationBar';
import Markdown from '../../../react/common/Markdown';
import NewLineToBr from '../../../react/common/NewLineToBr';
import Tooltip from '../../../react/common/Tooltip';
import { FEEDBACK_STATUS } from '../../ai/constants';
import ComponentConfigurationLink from '../../components/react/components/ComponentConfigurationLink';
import { getJobComponentId } from '../../jobs/JobComponentResolver';
import { extractErrorDetails } from '../helpers';

const JobErrorResult = (props: {
  job: Map<string, any>;
  feedbackStatus: string | null;
  useAi: boolean;
  isLoading: boolean;
  hasError: boolean;
  explanationViaAI: string;
  onReload: () => void;
  onGood: () => void;
  onBad: (message?: string) => void;
}) => {
  const [showForm, setShowForm] = React.useState(false);
  const [selectedReason, setSelectedReason] = React.useState('');

  const parts = [];

  let message =
    props.job.get('error') === 'application'
      ? 'Internal Error'
      : extractErrorDetails(props.job.getIn(['result', 'message']));

  if (
    props.job.hasIn(['result', 'context', 'configurationId']) &&
    props.job.hasIn(['result', 'context', 'rowId']) &&
    props.job.hasIn(['result', 'context', 'queryNumber']) &&
    props.job.hasIn(['result', 'context', 'query'])
  ) {
    message = props.job.getIn(['result', 'message']);

    if (message.match(/Executing query #(\d*) failed:/)) {
      const matches = message.match(/Executing query #(\d*) failed:/);

      if (matches) {
        message = message.substring(matches.index || 0 + matches[0].length);
      }
    }

    parts.push(
      <p key="transformationlink">
        {'Transformation '}
        <ComponentConfigurationLink
          componentId={getJobComponentId(props.job)}
          configId={props.job.getIn(['result', 'context', 'configurationId'])}
          rowId={props.job.getIn(['result', 'context', 'rowId']).toString()}
        >
          {props.job.getIn(['result', 'context', 'rowName'])}
        </ComponentConfigurationLink>
        {' has failed'}
      </p>
    );

    parts.push(
      <div key="transformationerror">
        <strong>Error</strong>
        <div>
          <NewLineToBr text={message} />
        </div>
      </div>
    );

    parts.push(
      <div key="transformationqueryheadline">
        <strong>
          {'Query '}
          <small>
            <ComponentConfigurationLink
              componentId={getJobComponentId(props.job)}
              configId={props.job.getIn(['result', 'context', 'configurationId'])}
              rowId={props.job.getIn(['result', 'context', 'rowId']).toString()}
              query={{
                highlightQueryNumber: props.job.getIn(['result', 'context', 'queryNumber'])
              }}
            >
              <span>Open query</span>
            </ComponentConfigurationLink>
          </small>
        </strong>
        <div>
          <pre>
            <code>
              <NewLineToBr text={props.job.getIn(['result', 'context', 'query'])} />
            </code>
          </pre>
        </div>
      </div>
    );
  } else {
    parts.push(
      <p key="genericerrordesc">
        <NewLineToBr text={message} />
      </p>
    );

    if (props.useAi) {
      parts.push(
        <Well key="aierrordesc" className="bg-color no-border f-16 p-1">
          <div className="line-height-24 font-medium flex-container mbp-3">
            <div className="flex-container flex-start pre-wrap">
              <Label className="label-cyan label-rounded icon-addon-right">AI</Label>
              Explanation
            </div>
            {props.explanationViaAI && !props.hasError && (
              <div className="feedback-buttons">
                <Tooltip placement="top" tooltip="Reload Explanation">
                  <Button
                    bsStyle="link"
                    className="btn-link-inline text-muted"
                    onClick={props.onReload}
                  >
                    <FontAwesomeIcon icon="arrows-rotate" />
                  </Button>
                </Tooltip>
                <Tooltip
                  placement="top"
                  tooltip="Like Explanation"
                  forceHide={props.feedbackStatus === FEEDBACK_STATUS.GOOD}
                >
                  <Button
                    bsStyle="link"
                    className={classNames('btn-link-inline text-muted success', {
                      active: props.feedbackStatus === FEEDBACK_STATUS.GOOD
                    })}
                    onClick={() => props.feedbackStatus !== FEEDBACK_STATUS.GOOD && props.onGood()}
                  >
                    <FontAwesomeIcon icon="thumbs-up" />
                  </Button>
                </Tooltip>
                <Tooltip
                  placement="top"
                  tooltip="Dislike Explanation"
                  forceHide={props.feedbackStatus === FEEDBACK_STATUS.BAD}
                >
                  <Button
                    bsStyle="link"
                    className={classNames('btn-link-inline text-muted danger', {
                      active: props.feedbackStatus === FEEDBACK_STATUS.BAD
                    })}
                    onClick={() => {
                      if (props.feedbackStatus === FEEDBACK_STATUS.BAD) {
                        return;
                      }

                      props.onBad();
                      setShowForm(true);
                    }}
                  >
                    <FontAwesomeIcon icon="thumbs-down" />
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
          {props.explanationViaAI || props.hasError ? (
            <>
              {showForm && (
                <Form
                  inline
                  className="pbp-4"
                  onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    props.onBad(selectedReason);
                    setShowForm(false);
                    setSelectedReason('');
                  }}
                >
                  <p className="f-14 line-height-20 font-medium">
                    What was the issue with the explanation?
                  </p>
                  {['Is not relevant', 'Is not accurate', 'I don’t understand'].map(
                    (reason, index) => {
                      return (
                        <Radio
                          inline
                          key={index}
                          className="mrp-5 f-14 line-height-20"
                          name="message"
                          checked={reason === selectedReason}
                          onChange={() => setSelectedReason(reason)}
                        >
                          {reason}
                        </Radio>
                      );
                    }
                  )}
                  <Button type="submit" bsSize="sm">
                    Submit Feedback
                  </Button>
                </Form>
              )}
              <div className="m-0 f-14 line-height-20">
                {!props.hasError ? (
                  <Markdown collapsible={false} source={props.explanationViaAI.trim()} />
                ) : (
                  <div className="text-center line-height-20">
                    <p className="mb-0 font-medium">Explanation is not available.</p>
                    <p className="mb-0 text-muted">
                      Try to{' '}
                      <Button bsStyle="link" className="btn-link-inline" onClick={props.onReload}>
                        reload explanation
                      </Button>{' '}
                      or try it again later
                    </p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <AnimationBar />
          )}
        </Well>
      );
    }

    if (props.job.get('error') === 'application') {
      parts.push(
        <p key="apperror">
          Something is broken. Our developers were notified about this error. Feel free to contact
          support to request more information.
        </p>
      );
    }
  }

  return parts;
};

export default JobErrorResult;
