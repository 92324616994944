import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import { routeNames } from '../../modules/storage/constants';
import { prepareLocationContext } from '../../modules/table-browser/helpers';
import RoutesStore from '../../stores/RoutesStore';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../utils/windowOpen';
import { getRealComponentId } from './ConfigurationsTable/helpers';
import StorageDataModal from './StorageDataModal';

const StorageData = (props: {
  tablesMetadataMap: Map<string, any>;
  component: Map<string, any>;
  config: Map<string, any>;
  tableMode?: boolean;
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const realComponentId = getRealComponentId(props.config, props.component);
  const buckets = props.tablesMetadataMap
    .get(`${realComponentId}:${props.config.get('id')}`, Map())
    .groupBy((table: Map<string, any>) => table.getIn(['bucket', 'id']));

  if (buckets.isEmpty()) {
    return (
      <span className="text-muted no-wrap">
        {props.tableMode ? (
          '-'
        ) : (
          <>
            <FontAwesomeIcon icon="warehouse" className="btn-icon" />
            None
          </>
        )}
      </span>
    );
  }

  return (
    <>
      <Button
        bsStyle="link"
        className="btn-link-inline flex-container inline-flex flex-start no-wrap"
        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();

          if (buckets.count() > 1) {
            return setShowModal(true);
          }

          const bucketId = buckets.keySeq().first();

          if (shouldUseNewWindow(e)) {
            return windowOpen(RoutesStore.getRouter().createHref(routeNames.BUCKET, { bucketId }));
          }

          const location = RoutesStore.getRouterState().get('location', Map());

          return RoutesStore.getRouter().transitionTo(
            'bucketPreview',
            { bucketId },
            { context: prepareLocationContext(location) }
          );
        }}
      >
        <FontAwesomeIcon icon="warehouse" className="btn-icon text-muted" />
        Show Data
      </Button>
      <StorageDataModal
        show={showModal}
        onHide={() => setShowModal(false)}
        buckets={buckets}
        component={props.component}
        config={props.config}
      />
    </>
  );
};

const MemoizedStorageData = React.memo(StorageData);

export default MemoizedStorageData;
