import installedComponentsActions from '../components/InstalledComponentsActionCreators';
import TrashHeaderButton from './react/components/TrashHeaderButtons';
import Index from './react/pages/TrashIndex';

export default {
  name: 'settings-trash',
  title: 'Recently Deleted',
  path: 'settings-trash',
  defaultRouteHandler: Index,
  headerButtonsHandler: TrashHeaderButton,
  requireData: [() => installedComponentsActions.loadDeletedComponents()]
};
