import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Map } from 'immutable';

import Gravatar from './Gravatar';
import Truncated from './Truncated';

class User extends React.Component {
  render() {
    return (
      <div
        className={classnames(
          'flex-container inline-flex flex-start',
          { 'flex-reverse': this.props.avatarRight },
          this.props.className
        )}
      >
        {this.props.avatar && (
          <span
            className={classnames(
              'line-height-1',
              this.props.avatarRight ? 'icon-addon-left' : 'icon-addon-right'
            )}
          >
            <Gravatar user={this.props.user} size={this.props.size} />
          </span>
        )}
        <Truncated
          text={this.props.user.get('name') || this.props.user.get('email')}
          title={this.props.user.get('email')}
        />
      </div>
    );
  }
}

User.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  className: PropTypes.string,
  avatar: PropTypes.bool,
  avatarRight: PropTypes.bool,
  size: PropTypes.number
};

User.defaultProps = {
  avatar: true,
  avatarRight: false,
  size: 28
};

export default User;
