import React from 'react';

export const byteConverter = (bytes: number, precision = 2) => {
  if (bytes === 0) return '0 Bytes';

  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const exponent = Math.floor(Math.log(bytes) / Math.log(1000));

  return parseFloat((bytes / Math.pow(1000, exponent)).toFixed(precision)) + ' ' + sizes[exponent];
};

const FileSize = ({ size, precision }: { size?: number | null; precision?: number }) => {
  if (!size && size !== 0) {
    return <span>N/A</span>;
  }

  return <span className="no-wrap">{byteConverter(size, precision)}</span>;
};

export default FileSize;
