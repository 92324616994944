import { fromJS, List, Map } from 'immutable';

import { EXCLUDE_FROM_NEW_LIST } from '../../../constants/componentFlags';
import Dispatcher from '../../../Dispatcher';
import ApplicationStore from '../../../stores/ApplicationStore';
import StoreUtils, { initStore } from '../../../utils/StoreUtils';
import * as Constants from '../Constants';
import { renameOrchestratorToFlow } from '../helpers';

const prepareComponents = (components, overrides) => {
  return renameOrchestratorToFlow(
    fromJS(components)
      .toMap()
      .map((component) => component.delete('configurations'))
      .map((component) => {
        if (!overrides.has(component.get('id'))) {
          return component;
        }

        return component.merge(overrides.get(component.get('id')));
      })
      .mapKeys((key, component) => component.get('id')),
    ApplicationStore.hasFlows()
  );
};

let _store = initStore(
  'ComponentsStore',
  Map({
    componentsById: Map(),
    componentsCategories: List(),
    overrides: Map()
  })
);

const ComponentsStore = StoreUtils.createStore({
  getComponentsTypes() {
    return _store
      .get('componentsById')
      .groupBy((val) => val.get('type'))
      .keySeq()
      .toJS();
  },

  getComponentsCategories() {
    return _store.get('componentsCategories');
  },

  getAll() {
    return _store.get('componentsById');
  },

  getAllForType(type) {
    return _store.get('componentsById').filter((component) => component.get('type') === type);
  },

  getComponent(id) {
    return _store.getIn(['componentsById', id]);
  },

  getComponentFlags(id) {
    return _store.getIn(['componentsById', id, 'flags'], List());
  },

  getFeatures(id) {
    return _store.getIn(['componentsById', id, 'features'], List());
  },

  hasComponent(id) {
    return _store.hasIn(['componentsById', id]);
  },

  hasOverrides(id) {
    return _store.hasIn(['overrides', id]);
  },

  hasComponentDetails(id) {
    return _store.hasIn(['componentsById', id, 'configurationSchema']);
  },

  unknownComponent(name) {
    return Map({
      id: name,
      name,
      type: 'unknown',
      flags: List(),
      data: Map()
    });
  }
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case Constants.ActionTypes.COMPONENTS_LOAD_SUCCESS: {
      const components = prepareComponents(action.components, _store.get('overrides', Map()));
      _store = _store.set('componentsById', components).set(
        'componentsCategories',
        components
          .filter(
            (component) =>
              !component.get('flags').includes(EXCLUDE_FROM_NEW_LIST) &&
              !component.get('categories', List()).isEmpty()
          )
          .map((component) => component.get('categories'))
          .toSet()
          .flatten()
          .sort()
      );
      return ComponentsStore.emitChange();
    }

    case Constants.ActionTypes.COMPONENT_LOAD_SUCCESS:
      _store = _store.setIn(
        ['componentsById', action.component.id],
        prepareComponents([action.component], _store.get('overrides', Map())).get(
          action.component.id
        )
      );
      return ComponentsStore.emitChange();

    case Constants.ActionTypes.COMPONENT_SET_OVERRIDES:
      _store = _store
        .setIn(['overrides', action.componentId], fromJS(action.overrides))
        .mergeIn(['componentsById', action.componentId], fromJS(action.overrides));
      return ComponentsStore.emitChange();

    case Constants.ActionTypes.INSTALLED_COMPONENTS_LOAD_SUCCESS:
      _store = _store.mergeIn(
        ['componentsById'],
        prepareComponents(action.components, _store.get('overrides', Map()))
      );
      return ComponentsStore.emitChange();

    default:
      break;
  }
});

export default ComponentsStore;
