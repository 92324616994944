import React from 'react';
import { List } from 'immutable';

import columnTypes from '../configurations/utils/columnTypeConstants';
import createRoute from '../configurations/utils/createRoute';
import { CollapsibleSection } from '../configurations/utils/renderHelpers';
import { conform } from './adapters/conform';
import {
  createConfiguration as credentialsCreateConfiguration,
  isComplete as credentialsIsComplete,
  parseConfiguration as credentialsParseConfiguration
} from './adapters/credentials';
import {
  createConfiguration as rowCreateConfiguration,
  createEmptyConfiguration as rowCreateEmptyConfiguration,
  parseConfiguration as rowParseConfiguration
} from './adapters/row';
import ConfigurationForm from './Configuration';
import CredentialsForm from './Credentials';

const routeSettings = {
  componentId: 'keboola.app-snowflake-dwh-manager',
  index: {
    sections: [
      {
        render: CollapsibleSection({
          title: 'Credentials',
          contentComponent: CredentialsForm,
          options: { includeSaveButtons: true }
        }),
        onSave: credentialsCreateConfiguration,
        onLoad: credentialsParseConfiguration,
        isComplete: credentialsIsComplete
      }
    ]
  },
  row: {
    onConform: conform,
    sections: [
      {
        render: ConfigurationForm,
        onSave: rowCreateConfiguration,
        onLoad: rowParseConfiguration,
        onCreate: rowCreateEmptyConfiguration
      }
    ],
    columns: [
      {
        name: 'Entity',
        type: columnTypes.VALUE,
        value: function (row) {
          let rowEntityType = 'Unknown';
          if (row.getIn(['parameters', 'user'], false)) {
            rowEntityType = 'User';
          }
          if (row.getIn(['parameters', 'business_schema'], false)) {
            rowEntityType = 'Schema';
          }
          // let rowEntityName = 'Unknown';
          let rowEntityName = row.get('name');
          if (row.getIn(['parameters', 'user'], false)) {
            rowEntityName = row.getIn(['parameters', 'user', 'email']);
          }
          if (row.getIn(['parameters', 'business_schema'], false)) {
            rowEntityName = row.getIn(['parameters', 'business_schema', 'schema_name']);
          }
          let linkedSchemas = null;
          if (row.getIn(['parameters', 'user'], false)) {
            const schemas = row.getIn(['parameters', 'user', 'business_schemas'], List());
            if (schemas.count() > 0) {
              linkedSchemas = (
                <small>
                  <br />
                  {'Granted access to: '}
                  {schemas.join(', ')}
                </small>
              );
            }
          }

          return (
            <div>
              {rowEntityName} <small>({rowEntityType})</small> {linkedSchemas}{' '}
            </div>
          );
        }
      }
    ]
  }
};

const route = createRoute(routeSettings);

export default route;
