import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import Link from '../../../../../react/common/RouterLink';
import { routeNames as flowsRouteNames } from '../../../../flows/constants';
import { routeNames as orchestrationsRouteNames } from '../../../../orchestrations-v2/constants';

const configurationScheduled = (
  configuration,
  orchestrationId,
  hasFlows,
  options = { newOrchestration: false, linkToIndex: false }
) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired
    },

    render() {
      return (
        <>
          Configuration{' '}
          <Link to={this.getLink()} params={this.getParams()} onClick={this.props.onClick}>
            {configuration.get('name')}
          </Link>{' '}
          has been automated.
        </>
      );
    },

    getLink() {
      if (options.linkToIndex) {
        return options.newOrchestration
          ? hasFlows
            ? flowsRouteNames.ROOT
            : orchestrationsRouteNames.ROOT
          : 'orchestrations';
      }

      return options.newOrchestration
        ? hasFlows
          ? flowsRouteNames.DETAIL
          : orchestrationsRouteNames.DETAIL
        : 'orchestration';
    },

    getParams() {
      if (options.linkToIndex) {
        return null;
      }

      if (options.newOrchestration) {
        return { config: orchestrationId };
      }

      return { orchestrationId };
    }
  });
};

export default configurationScheduled;
