import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import classnames from 'classnames';
import { List } from 'immutable';

import Select from '../../../../../react/common/Select';

const FileTagsSelector = ({ tags, onChange, disabled, inline }) => {
  return (
    <FormGroup>
      {inline ? (
        <ControlLabel className="col-sm-4">File tags</ControlLabel>
      ) : (
        <h3>
          <ControlLabel>File tags</ControlLabel>
        </h3>
      )}
      <div className={classnames({ 'col-sm-8': inline })}>
        <Select
          multi
          allowCreate
          trimMultiCreatedValues
          placeholder="Add file tags"
          value={tags}
          onChange={onChange}
          disabled={disabled}
          promptTextCreator={(label) => `Add tag "${label}"`}
        />
        <HelpBlock>Add these tags to the file that was successfully processed.</HelpBlock>
      </div>
    </FormGroup>
  );
};

FileTagsSelector.propTypes = {
  tags: PropTypes.instanceOf(List).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inline: PropTypes.bool
};

const MemoizedFileTagsSelector = React.memo(FileTagsSelector);

export default MemoizedFileTagsSelector;
