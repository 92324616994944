import React from 'react';
import classnames from 'classnames';
import type { Map } from 'immutable';

import InstalledComponentsActionCreators from '../../../modules/components/InstalledComponentsActionCreators';
import TableDivideRow from '../TableDivideRow';
import { DEFAULT_FOLDER_NAME } from './constants';
import { onTableRowKeyDown } from './helpers';
import type { TableCell, TableRow } from './Table';

type Props = {
  row: TableRow;
  forceComponentType?: string;
  customClasses: Map<string, any>;
  isFirst: boolean;
};

const FolderRow = (props: Props) => {
  if (props.row.values.data === DEFAULT_FOLDER_NAME) {
    if (props.isFirst) {
      return null;
    }

    return <TableDivideRow className="text-muted">Configurations without folder</TableDivideRow>;
  }

  const rowAction = () => {
    if (props.forceComponentType) {
      InstalledComponentsActionCreators.toggleExpandedFolder(
        props.forceComponentType,
        props.row.values.data,
        !props.row.isExpanded
      );
    }
    props.row.toggleRowExpanded(!props.row.isExpanded);
  };

  const userRowProps = {
    tabIndex: '0',
    role: 'button',
    className: 'tr folder-row clickable',
    onClick: rowAction,
    onKeyDown: onTableRowKeyDown(rowAction)
  };

  return (
    <div {...props.row.getRowProps(userRowProps)}>
      {props.row.cells.map((cell: TableCell, index: number) => {
        const userCellProps = {
          className: classnames('td', props.customClasses.getIn([cell.column.id, 'td']))
        };

        return (
          <div key={`${cell.column.id}-${index}`} {...cell.getCellProps(userCellProps)}>
            {cell.render('Cell')}
          </div>
        );
      })}
    </div>
  );
};

export default FolderRow;
