import React, { Component } from 'react';
import { Overlay, Popover as BootstrapPopover } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import _ from 'underscore';

type PopoverProps = {
  title: string;
  popover: React.ReactNode;
  children: React.ReactNode;
  triggerOnClickOnly?: boolean;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
};

type PopoverState = {
  showOverlay: boolean;
  forcePlacement: null | 'top' | 'right' | 'bottom' | 'left';
};

class Popover extends Component<PopoverProps, PopoverState> {
  state = {
    showOverlay: false,
    forcePlacement: null
  };

  popoverId = _.uniqueId('popover_');

  componentWillUnmount() {
    this.hide();
  }

  render() {
    const singleChild = React.Children.only(this.props.children as React.ReactElement<any>);

    return (
      <>
        {React.cloneElement(
          singleChild,
          this.props.triggerOnClickOnly
            ? { onClick: this.toggle }
            : {
                onFocus: this.show,
                onBlur: this.hide,
                onMouseEnter: this.show,
                onMouseLeave: this.hide
              }
        )}
        <Overlay
          target={this}
          shouldUpdatePosition
          show={this.state.showOverlay}
          placement={this.state.forcePlacement || this.props.placement}
          onEntering={this.checkPlacement}
        >
          <BootstrapPopover
            id={this.popoverId}
            title={this.props.title}
            className={this.props.className}
          >
            {this.props.popover}
          </BootstrapPopover>
        </Overlay>
      </>
    );
  }

  show = () => {
    if (this.state.showOverlay) {
      return;
    }

    return ReactDOM.flushSync(() => this.setState({ showOverlay: true }));
  };

  hide = () => {
    this.setState({ showOverlay: false });
  };

  toggle = () => {
    this.setState((prevState) => ({ showOverlay: !prevState.showOverlay }));
  };

  checkPlacement = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();

    switch (this.props.placement) {
      case 'top':
        return rect.top - 50 < 0 && this.setState({ forcePlacement: 'bottom' });

      case 'bottom':
        return rect.top + 50 > window.innerHeight && this.setState({ forcePlacement: 'top' });

      case 'left':
        return rect.left - 50 < 0 && this.setState({ forcePlacement: 'right' });

      default:
        return rect.right + 50 > window.innerWidth && this.setState({ forcePlacement: 'left' });
    }
  };
}

export default Popover;
