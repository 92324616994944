import React from 'react';

import columnTypes from '../configurations/utils/columnTypeConstants';
import createRoute from '../configurations/utils/createRoute';
import { CollapsibleSection } from '../configurations/utils/renderHelpers';
import {
  createConfiguration as rowCreateConfiguration,
  createEmptyConfiguration as rowCreateEmptyConfiguration,
  parseConfiguration as rowParseConfiguration
} from './adapters/configuration';
import {
  createConfiguration as credentialsCreateConfiguration,
  isComplete as credentialsIsComplete,
  parseConfiguration as credentialsParseConfiguration
} from './adapters/credentials';
import ConfigurationForm from './react/components/Configuration';
import CredentialsForm from './react/components/Credentials';

const routeSettings = {
  componentId: 'keboola.wr-aws-s3',
  index: {
    sections: [
      {
        render: CollapsibleSection({
          title: 'AWS Credentials and S3 Bucket',
          contentComponent: CredentialsForm,
          options: { includeSaveButtons: true }
        }),
        onSave: credentialsCreateConfiguration,
        onLoad: credentialsParseConfiguration,
        isComplete: credentialsIsComplete
      }
    ]
  },
  row: {
    sections: [
      {
        render: ConfigurationForm,
        onSave: rowCreateConfiguration,
        onCreate: rowCreateEmptyConfiguration,
        onLoad: rowParseConfiguration
      }
    ],
    columns: [
      {
        name: 'Source Table',
        type: columnTypes.TABLE_LINK,
        value: function (row) {
          return row.getIn(['storage', 'input', 'tables', 0, 'source'], 'Unknown');
        }
      },
      {
        name: 'Destination',
        type: columnTypes.VALUE,
        value: function (row) {
          const prefix = row.getIn(['parameters', 'prefix'], '');
          const filename =
            row.getIn(['storage', 'input', 'tables', 0, 'destination'], '') || ' Unknown filename';
          return <code>{prefix + filename}</code>;
        }
      }
    ]
  }
};

const route = createRoute(routeSettings);

export default route;
