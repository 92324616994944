import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { List } from 'immutable';

import Tooltip from '../../../react/common/Tooltip';
import string from '../../../utils/string';

const SpecifigRowsIcon = (props: { rows: List<string> }) => {
  const rows = props.rows.count();

  if (!rows) {
    return null;
  }

  return (
    <Tooltip
      placement="bottom"
      type="explanatory"
      tooltip={`Selected only ${rows} ${string.pluralize(rows, 'row')} to run.`}
    >
      <FontAwesomeIcon icon="table-rows" className="color-orange simple-icon f-16" />
    </Tooltip>
  );
};

export default SpecifigRowsIcon;
