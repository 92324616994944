import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { KEBOOLA_EX_MONGODB } from '../../../../../constants/componentIds';
import getDefaultBucket from '../../../../../utils/getDefaultBucket';
import StorageApiBucketLinkEx from '../../../../components/react/components/StorageApiBucketLinkEx';
import QueryRow from './QueryRow';

const QueryTable = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    queries: PropTypes.object,
    configurationId: PropTypes.string,
    componentId: PropTypes.string,
    pendingActions: PropTypes.object,
    actionCreators: PropTypes.object
  },

  render() {
    const bucketId = getDefaultBucket('in', KEBOOLA_EX_MONGODB, this.props.configurationId);

    return (
      <>
        <div className="box">
          <div className="box-content">
            <Row>
              <Col xs={4}>Output bucket</Col>
              <Col xs={8}>
                <StorageApiBucketLinkEx bucketId={bucketId} />
              </Col>
            </Row>
          </div>
        </div>
        <div className="box">
          <div className="table table-hover">
            <div className="thead">
              <div className="tr">
                <span className="th">Name</span>
                <span className="th">Mode</span>
                <span className="th">Incremental</span>
                <span className="th" />
              </div>
            </div>
            <div className="tbody">
              {this.props.queries.map((query) => {
                return (
                  <QueryRow
                    readOnly={this.props.readOnly}
                    componentId={this.props.componentId}
                    configurationId={this.props.configurationId}
                    pendingActions={this.props.pendingActions.get(query.get('id'), Map())}
                    query={query}
                    key={query.get('id')}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
});

export default QueryTable;
