import * as RuntimesApi from '../../api/routes/dataScienceService';
import Dispatcher from '../../Dispatcher';
import { ActionTypes } from './constants';

const loadRuntimes = () => {
  return RuntimesApi.runtimes().then((runtimes) => {
    Dispatcher.handleViewAction({
      type: ActionTypes.LOAD_RUNTIMES_SUCCESS,
      runtimes
    });
  });
};

export { loadRuntimes };
