import React from 'react';
import PropTypes from 'prop-types';
import { Graph } from '@keboola/flow-builder';
import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import ApplicationActionCreators from '../../../actions/ApplicationActionCreators';
import StorageDataModal from '../../../react/common/StorageDataModal';
import RoutesStore from '../../../stores/RoutesStore';
import nextTick from '../../../utils/nextTick';
import { createTask } from '../../orchestrations-v2/helpers';
import { clearLocalState, updateLocalStateValue } from '../../orchestrations-v2/localState';
import { BEHAVIOR_TYPES } from '../../queue/constants';
import { routeNames as storageRoutesNames } from '../../storage/constants';
import { saveFlow } from '../actions';
import { DragZone, insertEmptyPhase, updatePhasesNames } from '../helpers';
import AddTaskModal from './AddTaskModal';
import FloatingTask from './FloatingTask';
import Phase from './Phase';
import PhaseEditModal from './PhaseEditModal';
import TaskParamsModal from './TaskParamsModal';

class Builder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddTaskModal: false,
      newTaskPosition: null,
      dragging: Map(),
      taskMoveKey: null,
      selected: null,
      phaseToEdit: Map(),
      exploreParamsTask: Map(),
      editParamsTask: Map()
    };

    this.bounds = [];

    this.autoscrollIntervalRef = null;
    this.floatingTask = React.createRef();
    this.graphContainerRef = React.createRef();

    this.updateBounds = _.throttle(this.updateBounds, 100);
  }

  componentDidMount() {
    clearLocalState(this.props.configId);
    window.addEventListener('mouseup', this.handleMouseUp, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  render() {
    return (
      <>
        <div className="flow-container grid-background">
          {this.renderGraph()}
          {this.renderAddTaskModal()}
        </div>
        <PhaseEditModal
          phase={this.state.phaseToEdit}
          show={!this.state.phaseToEdit.isEmpty()}
          onHide={this.handleHidePhaseNameModal}
          onSubmit={this.handlePhaseUpdate}
        />
        <TaskParamsModal
          readOnly={this.props.readOnly}
          params={this.state.editParamsTask.get('task', Map())}
          show={!this.state.editParamsTask.isEmpty()}
          onHide={this.handleHideTaskParamsModal}
          onSave={this.handleSetTaskParams}
        />
        <StorageDataModal
          show={!this.state.exploreParamsTask.isEmpty()}
          onHide={this.handleHideStorageDataModal}
          buckets={this.state.exploreParamsTask.get('buckets', Map())}
          component={this.state.exploreParamsTask.get('component', Map())}
          config={this.state.exploreParamsTask.get('config', Map())}
        />
      </>
    );
  }

  renderAddTaskModal() {
    return (
      <AddTaskModal
        show={this.state.showAddTaskModal}
        position={this.state.newTaskPosition}
        onHide={this.handleHideAddTaskModal}
        onSelect={this.handleAddTask}
        components={this.props.components}
        configurations={this.props.configurations}
        hasSnowflakePartnerConnectLimited={this.props.hasSnowflakePartnerConnectLimited}
        hasDataApps={this.props.hasDataApps}
      />
    );
  }

  renderGraph() {
    const phases = this.props.visualizationPhases;

    return (
      <div className="flow-graph-container" ref={this.graphContainerRef}>
        <Graph>
          {phases
            .map((phase, index) => {
              return (
                <Phase
                  configId={this.props.configId}
                  key={phase.get('key')}
                  phase={phase}
                  isFirst={index === 0}
                  isLast={!this.props.readOnly && index === phases.count() - 1}
                  isLone={phases.count() === 1}
                  toggleBehaviorOnErrorChange={this.handleToggleBehaviorOnError}
                  previousPhase={index > 0 ? phases.get(index - 1) : null}
                  dragging={this.state.dragging.get('id')}
                  selected={this.state.selected}
                  shouldMergeBefore={
                    !!this.state.taskMoveKey?.endsWith(`[[start]]/${phase.get('key')}`)
                  }
                  shouldMergeInto={this.state.taskMoveKey === phase.get('key')}
                  shouldMergeAfter={!!this.state.taskMoveKey?.startsWith(`${phase.get('key')}/`)}
                  availableDatabricksClusters={this.props.availableDatabricksClusters}
                  patternComponents={this.props.patternComponents}
                  status={this.props.flowStatus}
                  readOnly={this.props.readOnly}
                  hasPayAsYouGo={this.props.hasPayAsYouGo}
                  allComponents={this.props.components}
                  allConfigurations={this.props.configurations}
                  tablesMetadataMap={this.props.tablesMetadataMap}
                  folders={this.props.folders}
                  onSelectTaskConfig={this.onSelectTaskConfig}
                  showBackendSize={this.props.showBackendSize}
                  hasSnowflakeDynamicBackendSize={this.props.hasSnowflakeDynamicBackendSize}
                  hasJobsDynamicBackendSize={this.props.hasJobsDynamicBackendSize}
                  onEditPhase={this.onEditPhase}
                  onDelete={this.onRemovePhase}
                  onSelectTask={this.onSelectTask}
                  onDragTask={this.onDragTask}
                  onEditTask={this.onEditTask}
                  onDeleteTask={this.onRemoveTask}
                  newTaskPosition={this.state.newTaskPosition}
                  handleShowAddTaskModal={this.handleShowAddTaskModal}
                  onExploreData={this.onExploreData}
                  onSetTaskParams={this.onSetTaskParams}
                  hasTemplates={this.props.hasTemplates}
                  isDevModeActive={this.props.isDevModeActive}
                />
              );
            })
            .toArray()}
          {/* This floating task exists so that we always have a DOM node to 'move' */}
          <FloatingTask
            ref={this.floatingTask}
            name={this.state.dragging.get('name', '')}
            componentName={this.state.dragging.get('component', '')}
            componentType={this.state.dragging.get('type', '')}
            iconUrl={this.state.dragging.get('iconUrl', '')}
            isBlank={!this.state.dragging.get('configId')}
            isDragged={!this.state.dragging.isEmpty()}
            hasDeletedConfiguration={this.state.dragging.get('hasDeletedConfiguration')}
          />
        </Graph>
      </div>
    );
  }

  handleShowAddTaskModal = (position) => {
    this.setState({
      selected: null,
      showAddTaskModal: this.state.newTaskPosition !== position,
      newTaskPosition: this.state.newTaskPosition === position ? null : position
    });
  };

  handleHideAddTaskModal = () => {
    this.setState({ showAddTaskModal: false, newTaskPosition: null });
  };

  handleHidePhaseNameModal = () => {
    this.setState({ phaseToEdit: Map() });
  };

  handleHideTaskParamsModal = () => {
    this.setState({ editParamsTask: Map() });
  };

  handleHideStorageDataModal = () => {
    this.setState({ exploreParamsTask: Map() });
  };

  onEditPhase = (phase) => {
    this.setState({ phaseToEdit: phase });
  };

  onSetTaskParams = (taskId) => {
    this.setState({ editParamsTask: this.props.tasks.find((task) => task.get('id') === taskId) });
  };

  onRemoveTask = (taskId) => {
    const tasks = this.props.tasks.filter((task) => task.get('id') !== taskId);
    this.updateLocalState('tasks', tasks);
    this.fixEmptyPhases(tasks);
  };

  onRemovePhase = (removePhase) => {
    const tasks = this.props.tasks.filter((task) => task.get('phase') !== removePhase.get('id'));
    const phases = this.props.phases.filter((phase) => phase.get('id') !== removePhase.get('id'));

    this.updateLocalState('tasks', tasks);
    this.updateLocalState('phases', phases);
  };

  handleMouseUp = (event) => {
    const path = event.composedPath();

    // Deselect when user clicks outside of add new task dialog
    if (
      this.state.showAddTaskModal &&
      !path.some((node) => {
        return (
          !!node.classList?.contains('add-new-task') ||
          !!node.classList?.contains('add-task-inline')
        );
      })
    ) {
      this.setState({ showAddTaskModal: false, newTaskPosition: null });
    }

    // Deselect when user clicks outside of node, but not if normal modal is open
    if (
      this.state.selected &&
      !path.some((node) => {
        return (
          !!node.classList?.contains('flow-builder--node') ||
          !!node.classList?.contains('modal-open')
        );
      })
    ) {
      this.setState({ selected: null, dragging: Map() });
    }
  };

  handlePhaseUpdate = (phaseId, name, description) => {
    const phaseIdx = this.props.phases.findIndex((phase) => phase.get('id') === phaseId);

    this.updateLocalState(
      'phases',
      this.props.phases
        .setIn([phaseIdx, 'name'], name)
        .setIn([phaseIdx, 'description'], description)
    );

    return nextTick(() => saveFlow(this.props.config, this.props.tasks, this.props.phases));
  };

  handleToggleBehaviorOnError = (updatedPhase) => {
    const phases = this.props.phases.map((phase) => {
      if (phase.get('id') !== updatedPhase.get('id')) {
        return phase;
      }

      return phase.setIn(
        ['behavior', 'onError'],
        updatedPhase.get('behaviorOnError', BEHAVIOR_TYPES.STOP) === BEHAVIOR_TYPES.STOP
          ? BEHAVIOR_TYPES.WARNING
          : BEHAVIOR_TYPES.STOP
      );
    });
    this.updateLocalState('phases', phases);
  };

  handleAddTask = (component, configuration) => {
    let phase;
    let phases = this.props.phases;

    if (this.state.newTaskPosition === '[[end]]') {
      phases = insertEmptyPhase(this.props.phases);
      phase = phases.last();
    } else if (!this.state.newTaskPosition.includes(':')) {
      phase = this.props.phases.find((phase) => {
        return phase.get('id').toString() === this.state.newTaskPosition;
      });
    } else {
      const insertAfter = this.props.phases.findIndex((phase) => {
        return phase.get('id').toString() === this.state.newTaskPosition.split(':')[0];
      });
      phases = insertEmptyPhase(this.props.phases, insertAfter + 1);
      phase = phases.get(insertAfter + 1);
      this.setState({ newTaskPosition: phase.get('id').toString() });
    }

    const newTask = createTask(
      component,
      configuration,
      phase.get('id'),
      this.props.tasks.map((task) => task.get('id'))
    );
    this.updateLocalState('phases', phases);
    this.updateLocalState('tasks', this.props.tasks.push(newTask));
    this.setState({
      selected: newTask.get('id'),
      ...(!!this.state.newTaskPosition && {
        showAddTaskModal: false,
        newTaskPosition: null
      })
    });
  };

  onEditTask = (/** @type {string} */ taskId, /** @type {string | string[]} */ property, value) => {
    const setter = Array.isArray(property) ? 'setIn' : 'set';
    this.updateLocalState(
      'tasks',
      this.props.tasks.map((task) => {
        return task.get('id') === taskId ? task[setter](property, value) : task;
      })
    );
  };

  handleSetTaskParams = (params) => {
    this.updateLocalState(
      'tasks',
      this.props.tasks.map((task) => {
        return task.get('id') === this.state.editParamsTask.get('id')
          ? task.set('task', fromJS(params))
          : task;
      })
    );
  };

  onSelectTask = (task) => {
    this.setState({ selected: task || null, showAddTaskModal: false, newTaskPosition: null });
  };

  onExploreData = (task, buckets) => {
    if (buckets.count() > 1) {
      return this.setState({
        exploreParamsTask: fromJS({
          buckets,
          component: this.props.components.get(task.get('componentId'), Map()),
          config: this.props.configurations.getIn(
            [task.get('componentId'), 'configurations', task.get('configId')],
            Map()
          )
        })
      });
    }

    RoutesStore.getRouter().transitionTo(
      storageRoutesNames.BUCKET,
      { bucketId: buckets.keySeq().first() },
      null,
      null,
      { flowId: this.props.configId, scrollY: window.scrollY }
    );
  };

  onSelectTaskConfig = (taskId, componentId, configId, options) => {
    // autosave is used before redirect, so if know there is no change we can skip updating the flows
    if (options?.autosave && !this.props.isChanged) {
      return Promise.resolve();
    }

    this.updateLocalState(
      'tasks',
      this.props.tasks.map((task) => {
        if (task.get('id') === taskId) {
          const newName = `${componentId}-${configId}`;
          const isConfigChanged = task.get('name') !== newName;

          return task
            .set('name', newName)
            .setIn(['task', 'configId'], configId)
            .setIn(['task', 'componentId'], componentId)
            .update('task', (task) => {
              return isConfigChanged ? task.delete('configRowIds') : task;
            });
        }

        return task;
      })
    );

    if (!options?.autosave) {
      return Promise.resolve();
    }

    return nextTick(() => saveFlow(this.props.config, this.props.tasks, this.props.phases));
  };

  handleMoveTask = (taskId) => {
    let tasks = this.props.tasks;

    if (this.state.taskMoveKey) {
      if (this.state.taskMoveKey === '[[illegal]]') return;
      // task is being moved in flow
      const [keyA, keyB] = this.state.taskMoveKey.split('/');

      let toPhaseId;
      if (!keyB) {
        // task is being moved into `a`
        // if the phase we're inserting into is fake, we must create it before inserting into it.
        const phase = this.props.visualizationPhases.find((phase) => phase.get('key') === keyA);
        if (phase.get('isFake', false)) {
          const phases = insertEmptyPhase(this.props.phases);
          this.updateLocalState('phases', phases);
          toPhaseId = phases.last().get('id');
        } else {
          toPhaseId = phase.get('id');
        }
      } else {
        let insertAfter;
        if (keyA === '[[start]]') {
          // task is moved to the start
          insertAfter = 0;
        } else {
          // task is moved inbetween two phases
          // if the phase we're inserting into is fake, we should insert after the last phase.
          const phaseIndex = this.props.visualizationPhases.findIndex(
            (phase) => phase.get('key') === keyA
          );
          const phase = this.props.visualizationPhases.get(phaseIndex);
          if (phase.get('isFake', false)) {
            insertAfter = this.props.phases.count();
          } else {
            insertAfter = 1 + phaseIndex;
          }
        }
        const phases = insertEmptyPhase(this.props.phases, insertAfter);
        this.updateLocalState('phases', phases);
        toPhaseId = phases.getIn([insertAfter, 'id']);
      }

      const taskIndex = tasks.findIndex((task) => task.get('id') === taskId);
      if (taskIndex !== -1) {
        // task is already in flow, and is being transferred to a different phase
        tasks = tasks.remove(taskIndex).push(tasks.get(taskIndex).set('phase', toPhaseId));
      }
    }

    if (tasks !== this.props.tasks) {
      this.updateLocalState('tasks', tasks);
    }

    nextTick(() => this.fixEmptyPhases(tasks));
  };

  onDragTask = (
    /** @type {Map<string, any> | null} */ phase,
    /** @type {Map<string, any>} */ task,
    /** @type {'begin' | 'move' | 'end'} */ state,
    /** @type {[number, number] | undefined} */ position
  ) => {
    if (this.state.dragging.isEmpty() && state !== 'begin') {
      return;
    }

    switch (state) {
      case 'begin': {
        this.setState({
          dragging: task,
          selected: null,
          showAddTaskModal: false,
          newTaskPosition: null
        });
        this.floatingTask.current?.move([position[0], position[1]]);
        break;
      }
      case 'move': {
        this.floatingTask.current?.move([position[0], position[1]]);
        const node = document.querySelector('.drag-target');
        if (node) {
          // find the bounding box which contains the drag target's DOM node's center
          const bb = DragZone.simple(node);
          let taskMoveKey = this.bounds.find(({ aabb }, index) => {
            return aabb.match(bb.y, index === 0, index === this.bounds.length - 1);
          })?.key;
          if (
            phase &&
            // insertion is not allowed into source phase
            // OR neither after nor before it if source phase has 1 task
            // these placement changes would not actually change the phase in any way,
            // but they would trigger an unnecessary local state update
            (phase.get('tasks').size === 1
              ? taskMoveKey?.includes(phase.get('key'))
              : taskMoveKey === phase.get('key'))
          ) {
            taskMoveKey = '[[illegal]]';
          }
          if (taskMoveKey !== this.state.taskMoveKey) {
            this.setState({ taskMoveKey });
          }

          this.updateBounds();
          this.startMonitorAutoscroll();
        }
        break;
      }
      case 'end': {
        this.handleMoveTask(task.get('id'));
        this.setState({ dragging: Map(), taskMoveKey: null });
        this.stopMonitorAutoscroll();
        break;
      }
    }
  };

  fixEmptyPhases = (tasks) => {
    const phases = this.props.phases.filter((phase) => {
      return tasks.some((task) => task.get('phase') === phase.get('id'));
    });

    this.updateLocalState('phases', phases);
  };

  updateBounds = () => {
    this.bounds = [];
    const nodes = document.querySelectorAll('div.flow-builder--group');
    for (let i = 0; i < nodes.length; ++i) {
      const A = nodes[i];
      const B = nodes[i + 1];

      // bounding box at the start
      if (i === 0 && nodes.length > 1) {
        this.bounds.push({
          aabb: DragZone.before(A),
          key: `[[start]]/${A.dataset.name}`
        });
      }

      // bounding box of A
      this.bounds.push({ aabb: DragZone.simple(A), key: A.dataset.name });

      // bounding box between A and B, not between last two phases
      if (B && i !== nodes.length - 2) {
        this.bounds.push({
          aabb: DragZone.between(A, B),
          key: `${A.dataset.name}/${B.dataset.name}`
        });
      }
    }
  };

  startMonitorAutoscroll = () => {
    if (!this.autoscrollIntervalRef) {
      this.autoscrollIntervalRef = setInterval(
        () => requestAnimationFrame(this.checkAutoscroll),
        16
      );
    }
  };

  stopMonitorAutoscroll = () => {
    this.autoscrollIntervalRef && clearInterval(this.autoscrollIntervalRef);
    this.autoscrollIntervalRef = null;
  };

  checkAutoscroll = () => {
    if (!this.floatingTask.current) return;

    const [positionX, positionY] = this.floatingTask.current.getPosition();
    const container = this.graphContainerRef.current.getBoundingClientRect();

    if (positionY < container.height - 80 && positionY + container.top + 40 > window.innerHeight) {
      window.scrollBy(0, 10);
      this.floatingTask.current.move([positionX, positionY + 10]);
      return;
    }

    if (window.scrollY > 0 && positionY + container.top < 40) {
      window.scrollBy(0, -10);
      this.floatingTask.current.move([positionX, positionY - 10]);
      return;
    }
  };

  updateLocalState = (path, data) => {
    if (this.props.flowStatus?.get('isRunning')) {
      // flow was edited after clicking `run flow`
      ApplicationActionCreators.sendNotification({
        id: `notification-${this.props.flowStatus.get('jobId')}`,
        type: 'info',
        message: 'You are editing a running flow. Edits will not be reflected in the running job.'
      });
    }

    if (path === 'phases') {
      data = updatePhasesNames(data);
    }

    return updateLocalStateValue(this.props.configId, path, data);
  };
}

Builder.propTypes = {
  config: PropTypes.instanceOf(Map).isRequired,
  configId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  showBackendSize: PropTypes.bool.isRequired,
  hasJobsDynamicBackendSize: PropTypes.bool.isRequired,
  hasSnowflakeDynamicBackendSize: PropTypes.bool.isRequired,
  components: PropTypes.instanceOf(Map).isRequired,
  patternComponents: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  tablesMetadataMap: PropTypes.instanceOf(Map).isRequired,
  visualizationPhases: PropTypes.instanceOf(List).isRequired,
  availableDatabricksClusters: PropTypes.instanceOf(List).isRequired,
  isChanged: PropTypes.bool.isRequired,
  tasks: PropTypes.instanceOf(List).isRequired,
  phases: PropTypes.instanceOf(List).isRequired,
  folders: PropTypes.instanceOf(Map).isRequired,
  hasSnowflakePartnerConnectLimited: PropTypes.bool.isRequired,
  hasDataApps: PropTypes.bool.isRequired,
  hasTemplates: PropTypes.bool.isRequired,
  isDevModeActive: PropTypes.bool.isRequired,
  flowStatus: PropTypes.instanceOf(Map)
};

export default Builder;
