import React from 'react';
import PropTypes from 'prop-types';

import Clipboard from '../../../../react/common/Clipboard';

const TokenString = ({ token, sendTokenComponent }) => {
  const tokenString = token.get('token');

  return (
    <pre>
      <div>
        {tokenString} <Clipboard text={tokenString} />
      </div>
      {sendTokenComponent}
    </pre>
  );
};

TokenString.propTypes = {
  token: PropTypes.object.isRequired,
  sendTokenComponent: PropTypes.any
};

export default TokenString;
