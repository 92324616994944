import { List, Map } from 'immutable';

import * as componentFlags from '../../../../../constants/componentFlags';
import {
  KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
  KEBOOLA_ORACLE_TRANSFORMATION,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_SYNAPSE_TRANSFORMATION
} from '../../../../../constants/componentIds';
import { FEATURE_WORKSPACE_VIEW_LOAD } from '../../../../../constants/features';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import { features as componentFeatures } from '../../../../components/Constants';
import DevBranchesStore from '../../../../dev-branches/DevBranchesStore';
import { prepareUniqueDestination } from '../../../../transformations/helpers';
import { DBT_COMPONENTS } from '../../../../transformations-v2/constants';

const hasInputMappingTables = (value) => value.has('tables') && !value.get('tables').isEmpty();

const isDbtTableInputMapping = (componentId, type, storage) => {
  return type === 'input' && storage === 'tables' && DBT_COMPONENTS.includes(componentId);
};

const getStorageBasePath = (componentId, type, storage) => {
  return isDbtTableInputMapping(componentId, type, storage)
    ? ['parameters', 'storage']
    : ['storage'];
};

const prepareMappingForSave = (
  componentId,
  config,
  editingConfig,
  mappingType,
  storage,
  index,
  hasNewQueue
) => {
  const dbtTableInputMapping = isDbtTableInputMapping(componentId, mappingType, storage);
  const basePath = getStorageBasePath(componentId, mappingType, storage);
  const mapping = editingConfig
    .getIn([...basePath, mappingType, storage, index])
    .update((mapping) => {
      if (mappingType === 'input' && storage === 'files') {
        return mapping.withMutations((mapping) => {
          // remove empty query param from file input mapping
          if (!mapping.get('query')) {
            mapping.delete('query');
          }

          // remove empty source tags param from file input mapping
          if (mapping.getIn(['source', 'tags'], List()).isEmpty()) {
            mapping.deleteIn(['source', 'tags']);
          }

          // remove empty source param from file input mapping
          if (mapping.get('source', Map()).isEmpty()) {
            mapping.delete('source');
          }

          // remove empty change since param from file input mapping
          if (!mapping.get('changed_since')) {
            mapping.delete('changed_since');
          }
        });
      }

      return mapping;
    })
    .update((mapping) => {
      if (
        hasNewQueue &&
        index === 'new-mapping' &&
        mappingType === 'input' &&
        storage === 'tables' &&
        !mapping.has('keep_internal_timestamp_column')
      ) {
        return mapping.set('keep_internal_timestamp_column', false);
      }

      return mapping;
    })
    .update((mapping) => {
      if (dbtTableInputMapping && !mapping.has('tables')) {
        return Map({ source: mapping.get('source') });
      }

      return mapping;
    });

  if (!config.hasIn([...basePath, mappingType, storage])) {
    config = config.setIn([...basePath, mappingType, storage], List());
  }

  if (index === 'complete-mapping') {
    return config.setIn([...basePath, mappingType, storage], mapping);
  }

  if (index !== 'new-mapping') {
    return config.setIn([...basePath, mappingType, storage, index], mapping);
  }

  if (!mapping.has('tables')) {
    const lastIndex = config.getIn([...basePath, mappingType, storage], List()).count();
    return config.setIn([...basePath, mappingType, storage, lastIndex], mapping);
  }

  let definedDestinations = config
    .getIn([...basePath, mappingType, storage], List())
    .map((input) => input.get('destination'))
    .toList();

  return config.updateIn([...basePath, mappingType, storage], List(), (storage) => {
    return storage.concat(
      mapping
        .get('tables')
        .map((tableMapping) => {
          const destination = definedDestinations.includes(tableMapping.get('destination'))
            ? prepareUniqueDestination(tableMapping, definedDestinations)
            : tableMapping.get('destination');

          definedDestinations = definedDestinations.push(destination);
          return tableMapping.set('destination', destination);
        })
        .map((tableMapping) => {
          const newMapping = Map({
            source: tableMapping.get('source'),
            destination: tableMapping.get('destination')
          });

          if (dbtTableInputMapping) {
            return newMapping.delete('destination');
          }

          return mapping.merge(newMapping);
        })
        .map((tableMapping) => tableMapping.delete('tables'))
        .toList()
    );
  });
};

const getColumnsOptions = (columns, componentId) => {
  return columns
    .map((column) => {
      if (componentId === KEBOOLA_ORACLE_TRANSFORMATION) {
        return { label: column.toUpperCase(), value: column };
      }

      return { label: column, value: column };
    })
    .toArray();
};

const supportsFileTags = (component, configurationData) => {
  return (
    !component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_OUTPUT) &&
    component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_ROWS) &&
    component.get('features').includes(componentFeatures.ALLOW_USE_FILE_STORAGE_ONLY) &&
    configurationData.getIn(['runtime', 'use_file_storage_only'], false)
  );
};

const supportsWriteAlways = (componentId) => {
  return [KEBOOLA_SNOWFLAKE_TRANSFORMATION, KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION].includes(
    componentId
  );
};

const isDifferenceInColumns = (filterColumns, columnsFromDataTypes, columnsFromStorage) => {
  return !getUnsynchronizedColumns(
    filterColumns,
    columnsFromDataTypes,
    columnsFromStorage
  ).isEmpty();
};

const getUnsynchronizedColumns = (filterColumns, columnsFromDataTypes, columnsFromStorage) => {
  const unsynchronizedColumns = columnsFromDataTypes
    .filter((column) => !columnsFromStorage.includes(column))
    .concat(columnsFromStorage.filter((column) => !columnsFromDataTypes.includes(column)));

  if (filterColumns.isEmpty()) {
    return unsynchronizedColumns;
  }

  return unsynchronizedColumns.filter((column) => filterColumns.includes(column));
};

const hasWorkspaceViewLoad = (componentId, replacementComponentId) => {
  return (
    [componentId, replacementComponentId].includes(KEBOOLA_SYNAPSE_TRANSFORMATION) &&
    ApplicationStore.hasCurrentProjectFeature(FEATURE_WORKSPACE_VIEW_LOAD)
  );
};

const canForceSourceBranch = () => {
  return DevBranchesStore.isDevModeActive() && ApplicationStore.hasProtectedDefaultBranch();
};

export {
  hasInputMappingTables,
  getStorageBasePath,
  prepareMappingForSave,
  getColumnsOptions,
  supportsFileTags,
  supportsWriteAlways,
  isDifferenceInColumns,
  getUnsynchronizedColumns,
  hasWorkspaceViewLoad,
  canForceSourceBranch
};
