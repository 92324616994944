import React, { useState } from 'react';

import DescriptionBox from '../../../react/common/DescriptionBox';
import { saveMetadataDescription } from '../actions';

const ProjectDescriptionBox = (props: { description: string; readOnly: boolean }) => {
  const [editing, setEditing] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleCancel = () => setEditing(null);

  const handleSave = () => {
    setIsSaving(true);

    return saveMetadataDescription(editing || '')
      .then(handleCancel)
      .finally(() => setIsSaving(false));
  };

  return (
    <DescriptionBox
      placeholder="Project Description"
      editing={editing}
      description={props.description}
      onChange={setEditing}
      handleSave={handleSave}
      handleCancel={handleCancel}
      readOnly={props.readOnly}
      isSaving={isSaving}
      isEditing={editing !== null}
    />
  );
};

export default ProjectDescriptionBox;
