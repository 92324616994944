import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import InlineEditTextInput from '../../../../react/common/InlineEditTextInput';
import ComponentEditField from './ComponentEditField';

const ComponentName = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool
  },

  render() {
    return (
      <ComponentEditField
        key={`${this.props.componentId}-${this.props.configId}`}
        componentId={this.props.componentId}
        configId={this.props.configId}
        readOnly={this.props.readOnly}
        fieldName="name"
        editElement={InlineEditTextInput}
        placeholder="My Configuration"
        tooltipPlacement="bottom"
      />
    );
  }
});

export default ComponentName;
