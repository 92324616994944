import request from '../../utils/request';

const createRequest = (accessToken, path) => {
  return request('GET', `https://graph.microsoft.com/v1.0${path}`)
    .set('Authorization', `Bearer ${accessToken}`)
    .promise()
    .then((response) => response.body);
};

export default {
  getMySite(accessToken) {
    return createRequest(accessToken, '/me?$select=mySite');
  },

  getFile(accessToken, driveId, fileId) {
    return createRequest(
      accessToken,
      `/drives/${driveId}/items/${fileId}/?$select=id,name,parentReference`
    );
  }
};
