import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import CircleIcon from '../../../react/common/CircleIcon';
import CollapsiblePanel from '../../../react/common/CollapsiblePanel';
import CreatedDate from '../../../react/common/CreatedDate';
import Truncated from '../../../react/common/Truncated';
import ConfigurationLink from '../../components/react/components/ComponentConfigurationLink';

class CodeUsagesPanel extends React.Component {
  render() {
    return (
      <CollapsiblePanel
        title={
          <>
            <CircleIcon className="mrp-3" icon="gear" color="blue" smaller bold />
            List of transformations
          </>
        }
        className="mb-0"
      >
        {this.props.configurations
          .sortBy((config) => config.get('name').toLowerCase())
          .map(this.renderRow)
          .toArray()}
      </CollapsiblePanel>
    );
  }

  renderRow = (config) => {
    return (
      <div key={config.get('id')} className="flex-container mbp-1">
        <ConfigurationLink
          className="dark pr-1"
          configId={config.get('id')}
          componentId={this.props.componentId}
          hasFlows={this.props.hasFlows}
        >
          <Truncated text={config.get('name')} />
        </ConfigurationLink>
        <div className="text-muted no-wrap">
          Last changed <CreatedDate createdTime={config.getIn(['currentVersion', 'created'])} />
        </div>
      </div>
    );
  };
}

CodeUsagesPanel.propTypes = {
  configurations: PropTypes.instanceOf(Map).isRequired,
  componentId: PropTypes.string.isRequired,
  hasFlows: PropTypes.bool.isRequired
};

export default CodeUsagesPanel;
