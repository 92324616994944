import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Overlay, Tooltip } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import ApplicationStore from '../../stores/ApplicationStore';

/** @type {any} */
const PasswordControl = createReactClass({
  propTypes: {
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipPlacement: PropTypes.oneOf(['top', 'left', 'bottom', 'right'])
  },

  target: null,

  getDefaultProps() {
    return {
      value: '',
      disabled: false,
      tooltip: 'Caps Lock is ON',
      tooltipPlacement: 'top'
    };
  },

  getInitialState() {
    return {
      capsLock: false
    };
  },

  render() {
    const { onChange, value, disabled, placeholder, tooltip, tooltipPlacement, ...customProps } =
      this.props;

    return (
      <span>
        <Overlay show={this.state.capsLock} target={this.target} placement={tooltipPlacement}>
          <Tooltip id={_.uniqueId('capslock_')}>{tooltip}</Tooltip>
        </Overlay>
        <FormControl
          type={ApplicationStore.hasProtectedDefaultBranch() ? 'text' : 'password'}
          ref={(control) => (this.target = control)}
          value={value}
          onChange={onChange}
          onKeyPress={this.onKeyPress}
          onBlur={this.onBlur}
          onFocus={this.props.onFocus}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="new-password"
          {...customProps}
        />
      </span>
    );
  },

  onBlur() {
    if (this.state.capsLock) {
      this.setState({ capsLock: false });
    }
  },

  onKeyPress(event) {
    if (event.getModifierState('CapsLock') !== this.state.capsLock) {
      this.setState({ capsLock: event.getModifierState('CapsLock') });
    }
  }
});

export default PasswordControl;
