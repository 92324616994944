import React from 'react';
import PropTypes from 'prop-types';
import { capitalize as capitalizeName } from 'underscore.string';

import { getNewComponentTypeLabel } from '../../modules/components/helpers';
import { DBT_COMPONENTS } from '../../modules/transformations-v2/constants';

const ComponentName = ({ component, children, showType, capitalize }) => {
  const type = getNewComponentTypeLabel(component.get('type'));
  const name =
    capitalize && !DBT_COMPONENTS.includes(component.get('id'))
      ? capitalizeName(component.get('name'))
      : component.get('name');

  if (
    showType &&
    ['extractor', 'writer', 'application', 'transformation'].includes(component.get('type'))
  ) {
    return children ? (
      children(`${name} ${type}`)
    ) : (
      <>
        <span className="font-medium">{name}</span>{' '}
        <small className="component-type text-muted">{type}</small>
      </>
    );
  }

  return children ? children(name) : name;
};

ComponentName.propTypes = {
  component: PropTypes.object.isRequired,
  children: PropTypes.func,
  showType: PropTypes.bool,
  capitalize: PropTypes.bool
};

ComponentName.defaultProps = {
  showType: false,
  capitalize: false
};

export default ComponentName;
