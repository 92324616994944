import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from '../../../../react/common/RouterLink';
import { convertCreditsToMinutes } from '../../../billing/helpers';
import MarketplacePortalLink from '../../../billing/react/MarketplacePortalLink';

const NoCreditsEmptyPage = (props: {
  credits: number;
  isMarketplaceProject: boolean;
  marketplaceSubscriptionLink: string;
}) => {
  return (
    <div className="blank-page empty-page flex-container justify-center bg-color-white">
      <div className="max-w-350">
        <h2 className="f-32 font-medium mt-2 mb-0">
          {convertCreditsToMinutes(props.credits)} minutes {props.credits < 0 ? '' : 'left'}
        </h2>
        <p className="text-muted mb-2 mt-1 pl-2 pr-2">
          {props.credits < 0
            ? `Looks like your last jobs consumed more minutes than your current credit. We've let your jobs run to complete the process. Please top up to continue working with your project. The unpaid minutes will be auto-deducted once you top-up.`
            : `Looks like you just used all your minutes and in order to continue your project, you should top up.`}
        </p>
        {props.isMarketplaceProject ? (
          <MarketplacePortalLink href={props.marketplaceSubscriptionLink} className="mt-1" />
        ) : (
          <Link to="billing-minutes" className="btn btn-block btn-success mt-1">
            <FontAwesomeIcon icon="credit-card-blank" className="icon-addon-right" />
            Top up
          </Link>
        )}
      </div>
    </div>
  );
};

export default NoCreditsEmptyPage;
