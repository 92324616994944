import { Map } from 'immutable';

import StorageBucketsStore from '../components/stores/StorageBucketsStore';
import { loadBucketDetail } from '../storage/actions';
import Index from './Index';

export default {
  name: 'bucketPreview',
  title: (routerState) => {
    const bucketId = routerState.getIn(['params', 'bucketId']);
    const bucket = StorageBucketsStore.getBucket(bucketId, Map());
    return `Bucket ${bucket.get('displayName', bucketId)}`;
  },
  requireData: (params) => loadBucketDetail(params.bucketId),
  path: 'bucket-preview/:bucketId',
  defaultRouteHandler: Index
};
