import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import StorageApiLink from '../../../components/react/components/StorageApiTableLinkEx';

const TitleSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      title: PropTypes.string,
      identifier: PropTypes.string
    }),
    tableId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    const { value, onChange, disabled } = this.props;

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Dataset</h2>
        </div>
        <div className="box-content">
          <div className="form-horizontal">
            <FormGroup>
              <Col componentClass={ControlLabel} sm={4}>
                Storage Table
              </Col>
              <Col sm={8}>
                <FormControl.Static>
                  <StorageApiLink tableId={this.props.tableId} />
                </FormControl.Static>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={4} componentClass={ControlLabel}>
                Title
              </Col>
              <Col sm={8}>
                <FormControl
                  type="text"
                  disabled={disabled}
                  onChange={(e) => onChange({ title: e.target.value })}
                  value={value.title}
                />
                <HelpBlock>Pretty name of the dataset in GoodData</HelpBlock>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={4} componentClass={ControlLabel}>
                Identifier (optional)
              </Col>
              <Col sm={8}>
                <FormControl
                  type="text"
                  disabled={disabled}
                  onChange={(e) => onChange({ identifier: e.target.value })}
                  value={value.identifier || ''}
                />
                <HelpBlock>Custom identifier of the dataset in GoodData.</HelpBlock>
              </Col>
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }
});

export default TitleSection;
