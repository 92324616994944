import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { componentTypes } from '../../constants/componentTypes';
import {
  getComponentTypeColorClassName,
  getNewComponentTypeLabel
} from '../../modules/components/helpers';

const ComponentType = ({
  type,
  noIcon,
  className,
  labelClassName
}: {
  type: (typeof componentTypes)[keyof typeof componentTypes] | 'other';
  noIcon?: boolean;
  className?: string;
  labelClassName?: string;
}) => {
  const isTransformation = type === componentTypes.TRANSFORMATION;
  const isApplication = type === componentTypes.APPLICATION;

  return (
    <span className={classnames('type', getComponentTypeColorClassName(type), className)}>
      {!noIcon && (
        <FontAwesomeIcon
          icon={isTransformation ? 'gear' : isApplication ? 'browser' : 'database'}
          className={classnames('icon-addon-right', { 'f-16': isTransformation })}
        />
      )}
      <span className={classnames(labelClassName)}>{getNewComponentTypeLabel(type)}</span>
    </span>
  );
};

export default ComponentType;
