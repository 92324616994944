import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { Map } from 'immutable';

import ExternalLink from '../../../react/common/ExternalLink';
import CredentialsModal from '../../sandboxes/components/CredentialsModal';

const OpenDataApp = (props: {
  mode: 'sidebar' | 'box';
  sandbox: Map<string, any>;
  config: Map<string, any>;
}) => {
  const [showCredentialsModal, setShowCredentialsModal] = React.useState(false);

  const renderLabel = () => {
    return (
      <>
        {props.mode === 'sidebar' && (
          <FontAwesomeIcon icon="arrow-up-right-from-square" className="icon-addon-right" />
        )}
        Open Data App
        {props.mode === 'box' && (
          <FontAwesomeIcon icon="arrow-up-right-from-square" className="icon-addon-left" />
        )}
      </>
    );
  };

  const renderButton = () => {
    if (props.mode === 'sidebar' && !props.sandbox.get('active', false)) {
      return null;
    }

    if (
      props.config.getIn(['configuration', 'parameters', 'dataApp', 'streamlitAuthEnabled'], false)
    ) {
      return (
        <Button
          block
          className={classNames({ 'fill-space': props.mode === 'box' })}
          onClick={() => setShowCredentialsModal(true)}
        >
          {renderLabel()}
        </Button>
      );
    }

    return (
      <ExternalLink
        href={props.sandbox.get('url', '')}
        className={classNames('btn btn-default btn-block', {
          'fill-space': props.mode === 'box',
          disabled: !props.sandbox.get('active', false) || !props.sandbox.get('url', '')
        })}
      >
        {renderLabel()}
      </ExternalLink>
    );
  };

  return (
    <>
      {renderButton()}
      <CredentialsModal
        sandbox={props.sandbox}
        show={showCredentialsModal}
        onHide={() => setShowCredentialsModal(false)}
      />
    </>
  );
};

export default OpenDataApp;
