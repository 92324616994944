import dispatcher from '../../Dispatcher';
import constants from './Constants';

export default {
  receive(features) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.STACK_FEATURES_LOAD_SUCCESS,
      features
    });
  }
};
