import columnTypes from '../configurations/utils/columnTypeConstants';
import createRoute from '../configurations/utils/createRoute';
import { CollapsibleSection } from '../configurations/utils/renderHelpers';
import actions from './adapters/actions';
import { conform } from './adapters/conform';
import destinationAdapter from './adapters/destination';
import inputMappingAdapter from './adapters/inputMapping';
import targetProjectAdapter from './adapters/targetProject';
import DestinationSection from './react/components/Destination';
import InputMappingSection from './react/components/InputMapping';
import TargetProjectSection from './react/components/TargetProject';

const routeSettings = {
  componentId: 'keboola.wr-storage',
  index: {
    actions: [
      {
        name: 'info',
        cache: true,
        autoload: true,
        getPayload: actions.info
      }
    ],
    sections: [
      {
        render: CollapsibleSection({
          title: 'Target Project',
          contentComponent: TargetProjectSection,
          options: { includeSaveButtons: true }
        }),
        onSave: targetProjectAdapter.createConfiguration,
        onLoad: targetProjectAdapter.parseConfiguration,
        isComplete: targetProjectAdapter.isComplete
      }
    ]
  },
  row: {
    onConform: conform,
    hasState: false,
    sections: [
      {
        render: InputMappingSection,
        onSave: inputMappingAdapter.createConfiguration,
        onLoad: inputMappingAdapter.parseConfiguration,
        onCreate: inputMappingAdapter.createEmptyConfiguration
      },
      {
        render: DestinationSection,
        onSave: destinationAdapter.createConfiguration,
        onLoad: destinationAdapter.parseConfiguration,
        onCreate: destinationAdapter.createEmptyConfiguration
      }
    ],
    columns: [
      {
        name: 'Source Table',
        type: columnTypes.TABLE_LINK,
        value: function (row) {
          return row.getIn(['storage', 'input', 'tables', 0, 'source'], 'Unknown');
        }
      },
      {
        name: 'Destination Table',
        type: columnTypes.VALUE,
        value: function (row) {
          return row.getIn(['storage', 'input', 'tables', 0, 'destination'], 'Unknown');
        }
      }
    ]
  }
};

const result = createRoute(routeSettings);

export default result;
