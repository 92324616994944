import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import Loader from '../../../../react/common/Loader';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import CredentialsActionCreators from '../../../provisioning/ActionCreators';
import JulipyterCredentials from '../../../provisioning/react/components/JulipyterCredentials';
import JupyterCredentials from '../../../provisioning/react/components/JupyterCredentials';
import RStudioCredentials from '../../../provisioning/react/components/RStudioCredentials';
import JulipyterSandboxCredentialsStore from '../../../provisioning/stores/JulipyterSandboxCredentialsStore';
import JupyterSandboxCredentialsStore from '../../../provisioning/stores/JupyterSandboxCredentialsStore';
import RStudioSandboxCredentialsStore from '../../../provisioning/stores/RStudioSandboxCredentialsStore';
import { transformationType } from '../../Constants';
import CannotExtendSandboxWarning from './CannotExtendSandboxWarning';

const DOCKER_TYPES = [transformationType.PYTHON, transformationType.JULIA, transformationType.R];

const DockerCredentialsContainer = createReactClass({
  mixins: [
    createStoreMixin(
      JupyterSandboxCredentialsStore,
      JulipyterSandboxCredentialsStore,
      RStudioSandboxCredentialsStore
    )
  ],

  propTypes: {
    isAutoLoad: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired
  },

  componentDidMount() {
    if (!this.state.credentials.get('id') && this.props.isAutoLoad) {
      if (this.props.type === transformationType.PYTHON) {
        CredentialsActionCreators.loadJupyterSandboxCredentials();
      } else if (this.props.type === transformationType.JULIA) {
        CredentialsActionCreators.loadJulipyterSandboxCredentials();
      } else if (this.props.type === transformationType.R) {
        CredentialsActionCreators.loadRStudioSandboxCredentials();
      }
    }
  },

  getStateFromStores() {
    let dockerCredentialsStore;

    if (this.props.type === transformationType.PYTHON) {
      dockerCredentialsStore = JupyterSandboxCredentialsStore;
    } else if (this.props.type === transformationType.JULIA) {
      dockerCredentialsStore = JulipyterSandboxCredentialsStore;
    } else if (this.props.type === transformationType.R) {
      dockerCredentialsStore = RStudioSandboxCredentialsStore;
    }

    return {
      canExtend: dockerCredentialsStore.getCanExtend(),
      credentials: dockerCredentialsStore.getCredentials(),
      pendingActions: dockerCredentialsStore.getPendingActions(),
      isLoading: dockerCredentialsStore.getIsLoading(),
      expiration: dockerCredentialsStore.getExpirationDate()
    };
  },

  render() {
    if (this.state.isLoading) {
      return (
        <p>
          <Loader className="icon-addon-right" />
          Looking for an existing sandbox...
        </p>
      );
    }

    if (!this.state.credentials.get('id') && !this.state.isLoading) {
      return (
        <p>
          You will create a new sandbox and load all required data in the input mapping of the
          transformation along with the transformation script.
        </p>
      );
    }

    return (
      <div>
        <p>To create a new sandbox or load new data, you have to drop the current sandbox.</p>
        {this.renderCredentials()}
        {DOCKER_TYPES.includes(this.props.type) && !this.state.canExtend && (
          <CannotExtendSandboxWarning />
        )}
      </div>
    );
  },

  renderCredentials() {
    if (this.props.type === transformationType.PYTHON) {
      return (
        <JupyterCredentials
          expiration={this.state.expiration}
          credentials={this.state.credentials}
          isCreating={this.state.pendingActions.get('create')}
        />
      );
    }

    if (this.props.type === transformationType.JULIA) {
      return (
        <JulipyterCredentials
          expiration={this.state.expiration}
          credentials={this.state.credentials}
          isCreating={this.state.pendingActions.get('create')}
        />
      );
    }

    if (this.props.type === transformationType.R) {
      return (
        <RStudioCredentials
          credentials={this.state.credentials}
          expiration={this.state.expiration}
          isCreating={this.state.pendingActions.get('create')}
        />
      );
    }

    return null;
  }
});

export default DockerCredentialsContainer;
