import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
  ProgressBar
} from 'react-bootstrap';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import FilesDropZone from '../../../react/common/FilesDropZone';
import InputValidation, { isValidName } from '../../../react/common/InputValidation';
import ModalIcon from '../../../react/common/ModalIcon';
import OptionalFormLabel from '../../../react/common/OptionalFormLabel';
import { nameWarning } from '../constants';
import { validateTableName } from '../helpers';

const INITIAL_STATE = {
  name: '',
  file: null,
  delimiter: ',',
  enclosure: '"',
  primaryKey: '',
  error: null,
  warning: null
};

const CreateTableModal = createReactClass({
  propTypes: {
    bucket: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    openModal: PropTypes.bool.isRequired,
    onCreateFromCsv: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    isCreatingTable: PropTypes.bool.isRequired,
    progress: PropTypes.number.isRequired
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    const progress = this.props.isCreatingTable ? 100 : this.props.progress;
    const isSaving = this.props.isCreatingTable || progress > 0;

    return (
      <Modal show={this.props.openModal} onHide={this.onHide}>
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Create new table in {this.props.bucket.get('displayName')} bucket
            </Modal.Title>
            <ModalIcon icon="table" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            {this.state.error ? (
              <Alert bsStyle="danger">{this.state.error}</Alert>
            ) : (
              <div>{isSaving ? this.renderProgress(progress) : this.renderForm()}</div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.error ? (
              <ConfirmButtons
                block
                saveStyle="primary"
                saveLabel="Try again"
                onSave={this.resetState}
              />
            ) : (
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveLabel={
                  isSaving
                    ? progress < 100
                      ? 'Uploading data...'
                      : 'Creating table...'
                    : 'Create table'
                }
                isDisabled={this.isDisabled()}
                isSaving={isSaving}
              />
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  renderForm() {
    return (
      <>
        <InputValidation predefined="tableName" value={this.state.name}>
          {(inputState) => (
            <FormGroup validationState={this.state.warning ? 'error' : inputState}>
              <ControlLabel>Table name</ControlLabel>
              <FormControl
                autoFocus
                type="text"
                value={this.state.name}
                onChange={this.handleName}
              />
              <HelpBlock
                className={classnames({
                  'text-danger': !!this.state.warning || inputState === 'error'
                })}
              >
                {this.state.warning || nameWarning}
              </HelpBlock>
            </FormGroup>
          )}
        </InputValidation>
        <FormGroup>
          <FilesDropZone label="CSV file" onDrop={this.handleFile} files={this.state.file} />
          <HelpBlock>Table structure will be set up from the CSV file.</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>Delimiter</ControlLabel>
          <FormControl type="text" value={this.state.delimiter} onChange={this.handleDelimiter} />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Enclosure</ControlLabel>
          <FormControl type="text" value={this.state.enclosure} onChange={this.handleEnclosure} />
        </FormGroup>
        <FormGroup>
          <ControlLabel>
            Primary key <OptionalFormLabel />
          </ControlLabel>
          <FormControl
            type="text"
            placeholder="Primary key column name"
            value={this.state.primaryKey}
            onChange={this.handlePrimaryKey}
          />
          <HelpBlock>
            Primary key is useful for incremental imports - rows that already exist in the table are
            updated.
          </HelpBlock>
        </FormGroup>
      </>
    );
  },

  renderProgress(progress) {
    return <ProgressBar striped bsStyle="success" now={progress} active={progress < 100} />;
  },

  handleName(event) {
    this.setState({ name: event.target.value }, this.validateName);
  },

  handleFile(files) {
    this.setState({ file: files[0] });
  },

  handleDelimiter(event) {
    this.setState({ delimiter: event.target.value });
  },

  handleEnclosure(event) {
    this.setState({ enclosure: event.target.value });
  },

  handlePrimaryKey(event) {
    this.setState({ primaryKey: event.target.value });
  },

  onHide() {
    this.props.onHide();
    this.resetState();
  },

  onSubmit(event) {
    event.preventDefault();

    const params = {
      name: this.state.name,
      delimiter: this.state.delimiter,
      enclosure: this.state.enclosure
    };

    if (this.state.primaryKey) {
      params.primaryKey = this.state.primaryKey;
    }

    return this.props.onCreateFromCsv(this.state.file, params).then(this.onHide, this.handleError);
  },

  handleError(message) {
    this.setState({ error: message });
  },

  resetState() {
    this.setState(INITIAL_STATE);
  },

  validateName() {
    this.setState({ warning: validateTableName(this.state.name, this.props.tables) });
  },

  isDisabled() {
    if (
      !this.state.name ||
      !isValidName(this.state.name) ||
      this.props.isCreatingTable ||
      this.props.progress ||
      this.state.error ||
      this.state.warning
    ) {
      return true;
    }

    return !this.state.file || !this.state.delimiter || !this.state.enclosure;
  }
});

export default CreateTableModal;
