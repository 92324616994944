/**
 * kbc-ui: {
 *   'dev-credentials': {
 *     ...
 *   },
 *   'kbc-ui-dashboard-last-seen-PROJECT_ID': ...,
 *
 * }
 */

const ROOT = 'kbc-ui';
const DEV_CREDENTIALS = 'dev-credentials';
const IS_PROJECT_DESCRIPTION_HIDDEN = 'is-project-description-hidden';
const DASHBOARD_LAST_SEEN = 'kbc-ui-dashboard-last-seen';
const HIDE_WORKSPACES_BILLING_ALERT = 'hide-workspaces-billing-alert';
const HIDE_RUN_CONFIRM_MODAL = 'hide-run-confirm-modal';
const HIDE_BACK_TO_PRODUCTION_WARNING = 'back-to-production-warning';
const HIDE_DASHBOARD_WELCOME_WIDGET = 'hide-dashboard-welcome-widget';
const SHOW_CHILD_JOBS = 'show-child-jobs';
const MAPPINGS_COLLAPSIONS = 'mappings-collapsions';
const PLATFORM_NEWS_LAST_SEEN = 'platform-news-last-seen';
const CODE_EDITOR_MODE = 'code-editor-mode';
const FLOW_TRANSFORM_STYLE = 'flow-transform-style';
const COLLAPSED_ALERTS = 'collapsed-alerts';
const COLLAPSED_CONFIGURATIONS = 'collapsed-configurations';
const COLLAPSED_PULL_CONFIGURATIONS = 'collapsed-pull-configurations';
const COLLAPSED_TRASH_CONFIGURATIONS = 'collapsed-trash-configurations';
const COLLAPSED_TEMPLATES = 'collapsed-templates';
const CONFIGURATIONS_SORT_BY = 'configurations-sort-by';
const CONFIGURATIONS_COMPONENTS_SORT_BY = 'configurations-components-sort-by';
const TABLE_COLUMNS_ORDER = 'table-columns-order';
const DEMO_PROJECT_USER_ID = 'demo-project-user-id';

export {
  ROOT,
  DEV_CREDENTIALS,
  IS_PROJECT_DESCRIPTION_HIDDEN,
  DASHBOARD_LAST_SEEN,
  HIDE_WORKSPACES_BILLING_ALERT,
  HIDE_RUN_CONFIRM_MODAL,
  HIDE_BACK_TO_PRODUCTION_WARNING,
  MAPPINGS_COLLAPSIONS,
  HIDE_DASHBOARD_WELCOME_WIDGET,
  PLATFORM_NEWS_LAST_SEEN,
  CODE_EDITOR_MODE,
  FLOW_TRANSFORM_STYLE,
  SHOW_CHILD_JOBS,
  COLLAPSED_ALERTS,
  COLLAPSED_CONFIGURATIONS,
  COLLAPSED_PULL_CONFIGURATIONS,
  COLLAPSED_TRASH_CONFIGURATIONS,
  COLLAPSED_TEMPLATES,
  CONFIGURATIONS_SORT_BY,
  CONFIGURATIONS_COMPONENTS_SORT_BY,
  TABLE_COLUMNS_ORDER,
  DEMO_PROJECT_USER_ID
};
