import React from 'react';

// both stack and project features
const FEATURE_SANDBOXES_PYTHON_SPARK = 'sandboxes-python-spark';

// stack features
const FEATURE_IS_SINGLE_TENANT = 'single-tenant';
const FEATURE_DISABLE_JULIA_AND_R = 'disable-julia-and-r';
const FEATURE_SAML_ROLES = 'saml-roles';
const FEATURE_NEW_OAUTH_SERVICE = 'new-oauth-service-enabled';

// project features
const FEATURE_PAY_AS_YOU_GO = 'pay-as-you-go';
const FEATURE_NEW_TRANSFORMATIONS_ONLY = 'new-transformations-only';
const FEATURE_SHOW_TRANSFORMATION_MIGRATION = 'show-transformation-migration';
const FEATURE_TRANSFORMATION_PARALLEL_UNLOADS = 'transformation-parallel-unloads';
const FEATURE_TRANSFORMATIONS_MIXED_BACKENDS = 'transformations-mixed-backends';
const FEATURE_QUEUE_V2 = 'queuev2';
const FEATURE_ALLOW_AI = 'allow-ai';
const FEATURE_ALLOW_DESCRIPTION_AI = 'allow-description-ai';
const FEATURE_USE_AZURE_AI = 'use-azure-ai';
const FEATURE_DEPRECATED_GOOGLE_DRIVE_TRANSPOSE = 'deprecated-google-drive-transpose';
const FEATURE_SANDBOXES_PYTHON_MLFLOW = 'sandboxes-python-mlflow';
const FEATURE_SANDBOXES_DATA_APP = 'sandboxes-data-apps';
const FEATURE_SANDBOXES_PERSISTENT_VOLUME = 'sandboxes-persistent-volume';
const FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE = 'workspace-snowflake-dynamic-backend-size';
const FEATURE_DYNAMIC_BACKEND_JOBS = 'dynamic-backend-jobs';
const FEATURE_WORKSPACE_VIEW_LOAD = 'workspace-view-load';
const FEATURE_HAS_SIMPLIFIED_COMPONENTS = 'has-simplified-components';
const FEATURE_HAS_FLOWS_ONLY = 'flows-without-fallback';
const FEATURE_ORACLE_TRANSFORMATIONS = 'oracle-transformations';
const FEATURE_EXTERNAL_BUCKETS = 'external-buckets';
const FEATURE_INPUT_MAPPING_READ_ONLY_STORAGE = 'input-mapping-read-only-storage';
const FEATURE_ARTIFACTS = 'artifacts';
const FEATURE_SNOWPARK_PYTHON = 'snowpark-python';
const FEATURE_ALTERNAT_WIZARD = 'alternat-wizard';
const FEATURE_ALTERNAT = 'alternat';
const FEATURE_SNOWFLAKE_PARTNER_CONNECT = 'snowflake-partner-connect';
const FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED = 'snowflake-partner-connect-limited';
const FEATURE_DISABLE_LEGACY_BUCKET_PREFIX = 'disable-legacy-bucket-prefix';
const FEATURE_SOX_PROTECTED_DEFAULT_BRANCH = 'protected-default-branch';

// admins features
const DISABLE_AUTOSAVING_IN_FLOWS = 'disable-autosaving-in-flows';
const FEATURE_RAW_MODE = 'raw-mode';

const BETA_FEATURES = [
  FEATURE_ALLOW_AI,
  FEATURE_ALLOW_DESCRIPTION_AI,
  FEATURE_USE_AZURE_AI,
  FEATURE_SNOWPARK_PYTHON,
  FEATURE_SANDBOXES_DATA_APP
];

// List of features that user can request from UI
const REQUESTABLE_FEATURES = {
  FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE: {
    key: FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE,
    label: 'Dynamic backend size for Snowflake',
    buttonLabel: (
      <>
        Backend Size: <span className="tw-font-medium">Small</span>
      </>
    ),
    buttonIcon: 'rocket'
  },
  FEATURE_DYNAMIC_BACKEND_JOBS: {
    key: FEATURE_DYNAMIC_BACKEND_JOBS,
    label: 'Dynamic backend size for non-SQL transformations',
    buttonLabel: (
      <>
        Backend Size: <span className="tw-font-medium">Small</span>
      </>
    ),
    buttonIcon: 'rocket'
  },
  FEATURE_SANDBOXES_PYTHON_MLFLOW: {
    key: FEATURE_SANDBOXES_PYTHON_MLFLOW,
    label: 'MLflow models',
    buttonLabel: 'Unlock ML/AI feature',
    buttonIcon: 'rotate'
  }
} as const;
export type REQUESTABLE_FEATURE = (typeof REQUESTABLE_FEATURES)[keyof typeof REQUESTABLE_FEATURES];

export {
  BETA_FEATURES,
  FEATURE_ORACLE_TRANSFORMATIONS,
  FEATURE_TRANSFORMATIONS_MIXED_BACKENDS,
  FEATURE_PAY_AS_YOU_GO,
  FEATURE_NEW_TRANSFORMATIONS_ONLY,
  FEATURE_TRANSFORMATION_PARALLEL_UNLOADS,
  FEATURE_DISABLE_JULIA_AND_R,
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_SANDBOXES_PYTHON_SPARK,
  FEATURE_SANDBOXES_PYTHON_MLFLOW,
  FEATURE_SANDBOXES_DATA_APP,
  FEATURE_SANDBOXES_PERSISTENT_VOLUME,
  FEATURE_DEPRECATED_GOOGLE_DRIVE_TRANSPOSE,
  FEATURE_QUEUE_V2,
  FEATURE_ALLOW_AI,
  FEATURE_ALLOW_DESCRIPTION_AI,
  FEATURE_USE_AZURE_AI,
  FEATURE_SHOW_TRANSFORMATION_MIGRATION,
  FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE,
  FEATURE_WORKSPACE_VIEW_LOAD,
  FEATURE_SAML_ROLES,
  FEATURE_NEW_OAUTH_SERVICE,
  FEATURE_HAS_SIMPLIFIED_COMPONENTS,
  FEATURE_HAS_FLOWS_ONLY,
  FEATURE_EXTERNAL_BUCKETS,
  FEATURE_INPUT_MAPPING_READ_ONLY_STORAGE,
  FEATURE_ARTIFACTS,
  FEATURE_SNOWPARK_PYTHON,
  REQUESTABLE_FEATURES,
  FEATURE_ALTERNAT_WIZARD,
  FEATURE_ALTERNAT,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
  FEATURE_RAW_MODE,
  DISABLE_AUTOSAVING_IN_FLOWS,
  FEATURE_DISABLE_LEGACY_BUCKET_PREFIX,
  FEATURE_SOX_PROTECTED_DEFAULT_BRANCH
};
