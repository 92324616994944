import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import Select from '../../../../react/common/Select';
import { tableLabel } from '../../../../react/common/selectLabels';
import { filterProductionAndCurrentDevBranchTables } from '../../../dev-branches/helpers';
import { tableDisplayNameWithBucketAndStage, tableName } from '../../../storage/helpers';

const CreateDockerSandboxForm = createReactClass({
  propTypes: {
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
  },
  getInitialState() {
    return {
      rows: 0,
      tables: List()
    };
  },
  render: function () {
    return (
      <form className="form-horizontal">
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Tables
          </Col>
          <Col sm={9}>
            <Select
              multi
              value={this.state.tables}
              options={this.getTablesOptions()}
              onChange={this.onChangeTables}
              placeholder="Select tables to load..."
            />
            <HelpBlock>
              The required tables must be loaded into {this.props.type} when creating the sandbox.
              Data cannot be added later.
            </HelpBlock>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Sample rows
          </Col>
          <Col sm={9}>
            <FormControl
              type="number"
              placeholder="Number of rows"
              value={this.state.rows}
              onChange={this.onChangeRows}
            />
            <HelpBlock>To import all rows, use 0.</HelpBlock>
          </Col>
        </FormGroup>
      </form>
    );
  },
  onChangeRows: function (e) {
    const rows = parseInt(e.target.value, 10);
    this.setState({
      rows
    });
    this.onChange({ rows });
  },
  onChangeTables: function (tables) {
    this.setState({
      tables
    });
    this.onChange({ tables });
  },
  getTablesOptions: function () {
    return filterProductionAndCurrentDevBranchTables(this.props.tables, this.props.buckets)
      .sortBy((table) => tableDisplayNameWithBucketAndStage(table))
      .map((table) => ({
        value: table.get('id'),
        label: tableLabel(table),
        name: tableName(table)
      }))
      .toArray();
  },
  onChange: function ({ rows = this.state.rows, tables = this.state.tables }) {
    this.props.onChange({
      input: {
        tables: tables.map((table) => {
          let retVal = {
            source: table,
            destination: table + '.csv'
          };
          if (rows > 0) {
            retVal.limit = rows;
          }
          return retVal;
        })
      }
    });
  }
});

export default CreateDockerSandboxForm;
