import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';
import SyncActionError from '../../../utils/errors/SyncActionError';
import { createWorksheet } from '../action';

const INITIAL_STATE = {
  showModal: false,
  isCreating: false,
  error: null,
  warning: null,
  name: ''
};

class CreateWorksheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Button
          bsStyle="success"
          disabled={this.props.disabled || !this.props.driveId || !this.props.fileId}
          onClick={this.openModal}
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Create Worksheet
        </Button>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Form horizontal onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create Worksheet</Modal.Title>
              <ModalIcon.Plus />
            </Modal.Header>
            <Modal.Body>
              {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
              {this.state.warning && <Alert bsStyle="warning">{this.state.warning}</Alert>}
              <FormGroup>
                <Col sm={3} componentClass={ControlLabel}>
                  Name
                </Col>
                <Col sm={9}>
                  <FormControl
                    autoFocus
                    type="text"
                    disabled={this.state.isCreating}
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </Col>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveLabel="Create"
                saveButtonType="submit"
                isSaving={this.state.isCreating}
                isDisabled={!this.state.name.trim()}
              />
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isCreating: true, warning: null, error: null });
    return createWorksheet(
      this.props.configId,
      this.props.driveId,
      this.props.fileId,
      this.state.name
    )
      .then(({ worksheet }) => {
        this.props.onCreate({ name: this.state.name, id: worksheet.worksheetId });
        this.closeModal();
        return null;
      })
      .catch(SyncActionError, (error) => {
        this.setState({ error: error.message });
      })
      .finally(() => {
        this.setState({ isCreating: false });
      });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState(INITIAL_STATE);
  }
}

CreateWorksheet.propTypes = {
  disabled: PropTypes.bool.isRequired,
  configId: PropTypes.string.isRequired,
  driveId: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired
};

export default CreateWorksheet;
