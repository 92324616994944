import React from 'react';
import { Label } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

type Props = {
  className?: string;
};

const ConfigurationDisabledLabel = (props: Props) => {
  return (
    <Label className={classnames('label-purple label-rounded', props.className)}>
      <FontAwesomeIcon icon="ban" /> Disabled
    </Label>
  );
};

export default ConfigurationDisabledLabel;
