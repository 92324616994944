import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { List } from 'immutable';

import type { VariableWithHash } from '../../../api/routes/vaultService';
import Checkbox from '../../../react/common/Checkbox';
import CollapseButton from '../../../react/common/CollapseButton';
import EncryptedValue from '../../../react/common/EncryptedValue';
import SortByName, { type SortState } from '../../../react/common/SortByName';
import Truncated from '../../../react/common/Truncated';
import onClickSelectionCell from '../../../utils/onClickSelectionCell';
import string from '../../../utils/string';
import { isVariableEncrypted } from '../../vault/helpers';
import { toggleVariable } from '../actions';

type Props = {
  variables: VariableWithHash[];
  selected: List<VariableWithHash['hash']>;
  readOnly: boolean;
};

type State = {
  sort: SortState;
  isCollapsed: boolean;
};

class VariableTable extends React.Component<Props> {
  state: State = {
    sort: 'asc',
    isCollapsed: false
  };

  render() {
    if (!this.props.variables.length) {
      return null;
    }

    return (
      <div className="box">
        <div
          onClick={this.toggleCollapse}
          className="flex-container collapse-above-table btn-collapse-area pb-0"
        >
          <span className="font-medium f-16 line-height-24">Created Variables</span>
          <CollapseButton
            entity="variables"
            isCollapsed={this.state.isCollapsed}
            onToggle={this.toggleCollapse}
          />
        </div>
        {this.state.isCollapsed ? (
          <div className="collapsed-configurations clickable" onClick={this.toggleCollapse}>
            <span className="font-bold">{this.props.variables.length} </span>
            <span className="text-muted">
              {string.pluralize(this.props.variables.length, 'Variable')}
            </span>
          </div>
        ) : (
          this.renderTable()
        )}
      </div>
    );
  }

  renderTable() {
    return (
      <Table hover={!this.props.readOnly}>
        <thead>
          <tr>
            {!this.props.readOnly && (
              <th className="w-52 pr-0" onClick={onClickSelectionCell}>
                <Checkbox
                  tooltip="Toggle all changes"
                  checked={this.props.variables.length === this.props.selected.count()}
                  onChange={this.toggleAll}
                  indeterminate={
                    !this.props.selected.isEmpty() &&
                    this.props.variables.length !== this.props.selected.count()
                  }
                />
              </th>
            )}
            <th className={classNames({ 'pl-0': !this.props.readOnly })}>
              <SortByName sortBy={this.state.sort} onClick={(sort) => this.setState({ sort })} />
            </th>
            <th className="w-600 text-left">Value</th>
          </tr>
        </thead>
        <tbody>
          {this.props.variables.sort(this.handleSort).map((variable) => {
            return (
              <tr key={variable.hash}>
                {!this.props.readOnly && (
                  <td className="w-52 pr-0" onClick={onClickSelectionCell}>
                    <Checkbox
                      tooltip="Add/remove change from merge."
                      checked={this.props.selected.includes(variable.hash)}
                      onChange={(checked) => toggleVariable(variable, checked)}
                    />
                  </td>
                )}
                <td className={classNames({ 'pl-0': !this.props.readOnly })}>
                  <Truncated className="component-name" text={variable.key} />
                  {!!variable.group && (
                    <span className="flex-container flex-start text-muted">
                      <FontAwesomeIcon icon="folder" className="icon-addon-right" />
                      <span className="f-12 line-height-16">{variable.group}</span>
                    </span>
                  )}
                </td>
                <td className="text-left">
                  {isVariableEncrypted(variable) ? (
                    <EncryptedValue />
                  ) : (
                    <Truncated text={variable.value} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  handleSort = (variableA: VariableWithHash, variableB: VariableWithHash) => {
    const sort = this.state.sort === 'asc' ? 1 : -1;

    return variableA.key.localeCompare(variableB.key) * sort;
  };

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  toggleAll = (checked: boolean) => {
    this.props.variables.map((variable) => toggleVariable(variable, checked));
  };
}

export default VariableTable;
