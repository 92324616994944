import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import OptionalFormLabel from '../../../../../react/common/OptionalFormLabel';
import Select from '../../../../../react/common/Select';
import whereOperatorConstants from '../../../../../react/common/whereOperatorConstants';
import { getColumnsOptions } from './helpers';

const DataFilterRow = createReactClass({
  propTypes: {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    allTables: PropTypes.object.isRequired,
    componentId: PropTypes.string,
    groupClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    whereColumnClassName: PropTypes.string,
    formType: PropTypes.oneOf(['normal', 'horizontal'])
  },

  getDefaultProps() {
    return {
      groupClassName: 'form-group',
      labelClassName: 'col-xs-2 control-label',
      whereColumnClassName: 'col-xs-4',
      formType: 'horizontal'
    };
  },

  render() {
    const isDisabled =
      this.props.disabled ||
      !this.props.value.get('source') ||
      !this.props.value.get('where_column');

    if (this.props.formType === 'horizontal') {
      return (
        <div className={this.props.groupClassName}>
          <label className={this.props.labelClassName}>Data Filter</label>
          <div className={this.props.whereColumnClassName}>
            <Select
              name="where_column"
              value={this.props.value.get('where_column')}
              disabled={this.props.disabled || !this.props.value.get('source')}
              placeholder="Select a column"
              onChange={this._handleChangeWhereColumn}
              options={this._getColumnsOptions()}
            />
          </div>
          <div className="col-xs-2">
            <Select
              clearable={false}
              searchable={false}
              name="where_operator"
              value={this.props.value.get('where_operator') || 'eq'}
              disabled={isDisabled}
              options={[
                { label: whereOperatorConstants.EQ_LABEL, value: whereOperatorConstants.EQ_VALUE },
                {
                  label: whereOperatorConstants.NOT_EQ_LABEL,
                  value: whereOperatorConstants.NOT_EQ_VALUE
                }
              ]}
              onChange={this._handleChangeWhereOperator}
            />
          </div>
          <div className="col-xs-4">
            <Select
              name="whereValues"
              value={this.props.value.get('where_values')}
              multi
              disabled={isDisabled}
              allowCreate
              placeholder="Add a value"
              emptyStrings
              onChange={this._handleChangeWhereValues}
            />
          </div>
        </div>
      );
    }

    return (
      <FormGroup>
        <ControlLabel>
          Data Filter <OptionalFormLabel />
        </ControlLabel>
        <div className="select-group">
          <Select
            name="where_column"
            value={this.props.value.get('where_column')}
            disabled={this.props.disabled || !this.props.value.get('source')}
            placeholder="Select a column"
            onChange={this._handleChangeWhereColumn}
            options={this._getColumnsOptions()}
          />
          <Select
            clearable={false}
            searchable={false}
            name="where_operator"
            value={this.props.value.get('where_operator') || 'eq'}
            disabled={isDisabled}
            options={[
              { label: whereOperatorConstants.EQ_LABEL, value: whereOperatorConstants.EQ_VALUE },
              {
                label: whereOperatorConstants.NOT_EQ_LABEL,
                value: whereOperatorConstants.NOT_EQ_VALUE
              }
            ]}
            onChange={this._handleChangeWhereOperator}
          />
        </div>
        <Select
          multi
          allowCreate
          emptyStrings
          name="whereValues"
          placeholder="Add a value"
          value={this.props.value.get('where_values')}
          onChange={this._handleChangeWhereValues}
          disabled={isDisabled}
        />
      </FormGroup>
    );
  },

  _getColumnsOptions() {
    const columns = this.props.allTables.getIn([this.props.value.get('source'), 'columns'], List());
    return getColumnsOptions(columns, this.props.componentId);
  },

  _handleChangeWhereValues(newValue) {
    const value = this.props.value.set('where_values', newValue);
    this.props.onChange(value);
  },

  _handleChangeWhereOperator(selected) {
    const value = this.props.value.set('where_operator', selected);
    this.props.onChange(value);
  },

  _handleChangeWhereColumn(string) {
    let value = this.props.value.set('where_column', string);

    if (!string) {
      value = value.set('where_values', List());
    }

    this.props.onChange(value);
  }
});

export default DataFilterRow;
