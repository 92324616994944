import React from 'react';
import createReactClass from 'create-react-class';

import FilterPanel from '../../../react/common/FilterPanel';
import TabLink from '../../../react/common/TabLink';
import TabNav from '../../../react/common/TabNav';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import StorageBucketsStore from '../../components/stores/StorageBucketsStore';
import { prepareSharedBuckets } from '../helpers';
import SharedBuckets from './SharedBuckets';

const SharedWithYou = createReactClass({
  mixins: [createStoreMixin(StorageBucketsStore)],

  getStateFromStores() {
    return {
      allBuckets: StorageBucketsStore.getAll(),
      sapiToken: ApplicationStore.getSapiToken(),
      currentProjectId: ApplicationStore.getCurrentProjectId(),
      sharedBuckets: StorageBucketsStore.getSharedBuckets(),
      linkedBuckets: StorageBucketsStore.getLinkedBuckets()
    };
  },

  getInitialState() {
    return {
      query: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], '')
    };
  },

  render() {
    return (
      <>
        <div className="tabs-with-border-wrapper">
          <TabNav>
            <TabLink to="data-catalog-shared-with-you" active>
              Shared with you
            </TabLink>
            <TabLink to="data-catalog-shared-from-this-project">Shared from this project</TabLink>
          </TabNav>
        </div>
        {this.renderSharedBuckets()}
      </>
    );
  },

  renderSharedBuckets() {
    const sharedBuckets = prepareSharedBuckets(
      this.state.sharedBuckets,
      this.state.linkedBuckets,
      this.state.currentProjectId,
      this.state.query
    );

    return (
      <>
        <FilterPanel
          placeholder={this.getPlaceholder.bind(this, sharedBuckets)}
          query={this.state.query}
          onChange={(query) => {
            this.setState({ query });
            RoutesStore.getRouter().updateQuery({ q: query });
          }}
        />
        <SharedBuckets
          token={this.state.sapiToken}
          allBuckets={this.state.allBuckets}
          sharedBuckets={sharedBuckets}
          query={this.state.query}
        />
      </>
    );
  },

  getPlaceholder(sharedBuckets) {
    if (this.state.query) {
      return '';
    }

    return `Search data catalog (${sharedBuckets.count()})`;
  }
});

export default SharedWithYou;
