import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, HelpBlock } from 'react-bootstrap';

import Checkbox from '../../../react/common/Checkbox';

class AuditBox extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Audit</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <Col xs={8} xsOffset={4}>
              <Checkbox
                checked={this.props.addFilenameColumn}
                onChange={(checked) => this.props.onChange({ addFilenameColumn: checked })}
                disabled={this.props.disabled}
              >
                Filename
              </Checkbox>
              <HelpBlock>
                Add an <code>ftp_filename</code> column that will store the processed file name.
              </HelpBlock>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col xs={8} xsOffset={4}>
              <Checkbox
                checked={this.props.addRowNumberColumn}
                onChange={(checked) => this.props.onChange({ addRowNumberColumn: checked })}
                disabled={this.props.disabled}
              >
                Row Number
              </Checkbox>
              <HelpBlock>
                Add an <code>ftp_row_number</code> column that will store the row number from the
                processed file.
              </HelpBlock>
            </Col>
          </FormGroup>
        </div>
      </div>
    );
  }
}

AuditBox.propTypes = {
  addFilenameColumn: PropTypes.bool.isRequired,
  addRowNumberColumn: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AuditBox;
