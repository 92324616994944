import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, FormControl, FormGroup, Table } from 'react-bootstrap';
import Sortable from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import keyCodes from '../../../constants/keyCodes';
import { TABLE_COLUMNS_ORDER } from '../../../constants/localStorageKeys';
import { defaultOptions } from '../../../constants/sortable';
import { copyToClipboard } from '../../../react/common/Clipboard';
import FilterPanel from '../../../react/common/FilterPanel';
import FullScreenEditor from '../../../react/common/FullScreenEditor';
import Loader from '../../../react/common/Loader';
import MarkedText from '../../../react/common/MarkedText';
import MultiSortTooltip from '../../../react/common/MultiSortTooltip';
import RouterLink from '../../../react/common/RouterLink';
import SortIcon from '../../../react/common/SortIcon';
import Tooltip from '../../../react/common/Tooltip';
import Truncated from '../../../react/common/Truncated';
import RoutesStore from '../../../stores/RoutesStore';
import * as localStorage from '../../../utils/localStorage';
import matchByWords from '../../../utils/matchByWords';
import { getTableColumnMetadata } from '../../components/utils/tableMetadataHelper';
import { isCreatedInDevBranch } from '../../dev-branches/helpers';
import { dataPreview } from '../actions';
import { routeNames } from '../constants';
import {
  findBasetypeDatatype,
  findStorageTypeDatatype,
  prepareOrderBy,
  prepareWhereFilters
} from '../helpers';
import DataSampleColumnOrderInfo from './DataSampleColumnOrderInfo';

export const SEARCH_TYPES = { KEY: 'KEY', VALUE: 'VALUE' };
const TOOLTIP_CHARS_LIMIT = 512;

class DataSample extends React.Component {
  state = {
    rows: List(),
    sortBy: Map(),
    filters: Map(),
    activeFilters: Map(),
    error: null,
    isLoading: false,
    searchQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    searchType: RoutesStore.getRouterState().getIn(['location', 'query', 'qt'], SEARCH_TYPES.KEY)
  };

  tableRef = React.createRef();

  componentDidMount() {
    this.fetchDataPreview();
  }

  componentWillUnmount() {
    this.cancellablePromise && this.cancellablePromise.cancel();
  }

  render() {
    if (this.props.fullScreen) {
      return this.renderFullScreenDataSample();
    }

    return this.renderDataSampleTable();
  }

  renderFullScreenDataSample() {
    return (
      <FullScreenEditor
        enforceFocus
        className="full-screen-data-sample"
        renderTitle={() => {
          return (
            <div className="modal-title">
              <div className="breadcrumb">
                <RouterLink
                  className="active dark muted"
                  to={routeNames.BUCKET}
                  params={{ bucketId: this.props.bucket.get('id') }}
                >
                  <FontAwesomeIcon
                    icon="folder"
                    className={classnames('text-muted f-16 icon-addon-right', {
                      'dev-bucket': isCreatedInDevBranch(this.props.bucket)
                    })}
                  />
                  {this.props.bucket.get('displayName')}
                </RouterLink>
              </div>
              <h4 className="flex-container flex-start">
                <FontAwesomeIcon icon="table" className="text-muted icon-addon-right" />
                <Truncated text={this.props.table.get('displayName')} />
              </h4>
            </div>
          );
        }}
        renderEditor={() => this.renderDataSampleTable()}
        renderButtons={() => {
          return (
            <DataSampleColumnOrderInfo
              tableId={this.props.table.get('id')}
              onResetColumnsOrder={this.props.onChangeColumnOrder}
            />
          );
        }}
        renderCloseButton={() => {
          return (
            <Tooltip placement="left" tooltip="Close full screen">
              <Button onClick={this.props.closeFullScreen}>
                <FontAwesomeIcon icon="xmark" />
              </Button>
            </Tooltip>
          );
        }}
        onClose={this.props.closeFullScreen}
      />
    );
  }

  renderDataSampleTable() {
    const columnsMetadata = getTableColumnMetadata(this.props.table);

    return (
      <>
        <FilterPanel
          placeholder={`Search ${
            this.state.searchType === SEARCH_TYPES.KEY ? 'columns' : 'values'
          }`}
          query={this.state.searchQuery}
          onChange={(searchQuery) => {
            RoutesStore.getRouter().updateQuery({ q: searchQuery });
            this.setState({ searchQuery }, () => {
              if (this.state.searchType === SEARCH_TYPES.VALUE) {
                this.setState({ isLoading: true }, this.debouncedFetchDataPreview);
              }
            });
          }}
          additionalActions={this.renderSearchTypeToggle()}
        />
        {this.state.error && (
          <Alert bsStyle="danger" className="mt-1">
            {this.state.error}
          </Alert>
        )}
        <div
          id="data-sample-table"
          className={classnames('box', { 'full-screen': this.props.fullScreen })}
        >
          <div
            className="table-responsive sticky-header"
            ref={(tableRef) => {
              this.tableRef.current = tableRef;

              // Set table minimum height to show at least 3 rows and header
              if (
                this.props.fullScreen ||
                this.state.isLoading ||
                this.state.rows.isEmpty() ||
                this.getColumns().isEmpty() ||
                !tableRef
              )
                return;

              const minVisibleRows = Array.from(tableRef?.querySelectorAll('tbody > tr')).slice(
                0,
                4
              );
              const minHeight = minVisibleRows.reduce(
                (height, row) => height + row.offsetHeight,
                tableRef.querySelector('thead > tr').offsetHeight ?? 0
              );

              tableRef.style.minHeight = `${minHeight}px`;
            }}
          >
            <Table hover>
              <thead>
                <Sortable
                  tag="tr"
                  className="dragable-columns"
                  options={{ ...defaultOptions, filter: '.sort-icon' }}
                  onChange={this.handleColumnsReorder}
                >
                  {this.getColumns()
                    .map((column, index) => {
                      const sortBy = this.state.sortBy.get(column);
                      const metadata = columnsMetadata.get(column, List());
                      const columnType = this.props.table.get('isTyped', false)
                        ? findStorageTypeDatatype(metadata)
                        : findBasetypeDatatype(metadata);

                      return (
                        <th
                          key={index}
                          data-id={column}
                          className={classnames('text-left', {
                            'color-primary': !!this.state.activeFilters.has(column)
                          })}
                        >
                          <MultiSortTooltip active={!sortBy && !this.state.sortBy.isEmpty()}>
                            <div
                              onClick={(event) => {
                                this.setState(
                                  {
                                    sortBy:
                                      sortBy === 'desc'
                                        ? this.state.sortBy.delete(column)
                                        : event.shiftKey
                                        ? this.state.sortBy.set(column, !sortBy ? 'asc' : 'desc')
                                        : Map({ [column]: !sortBy ? 'asc' : 'desc' })
                                  },
                                  this.refetchPreviewWithFilters
                                );
                              }}
                              className="flex-container flex-start inline-flex clickable"
                            >
                              {this.state.searchType === SEARCH_TYPES.KEY &&
                              this.state.searchQuery.length ? (
                                <MarkedText source={column} mark={this.state.searchQuery} />
                              ) : (
                                column
                              )}
                              <SortIcon
                                isSorted={!!sortBy}
                                isSortedDesc={sortBy === 'desc'}
                                className="icon-addon-left"
                              />
                            </div>
                          </MultiSortTooltip>
                          {columnType && columnType.has('value') && (
                            <div className="f-12 font-normal text-muted">
                              {columnType.get('value').toUpperCase()}
                            </div>
                          )}
                          <FontAwesomeIcon
                            icon="grip-dots-vertical"
                            className="f-16 dragable drag-handle"
                          />
                        </th>
                      );
                    })
                    .toArray()}
                </Sortable>
              </thead>
              <tbody>
                {this.renderFilters()}
                {this.renderRows()}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    );
  }

  renderSearchTypeToggle() {
    return (
      <div className="predefined-search-list">
        <button
          className={classnames('btn predefined-search-link', {
            active: this.state.searchType === SEARCH_TYPES.KEY
          })}
          onClick={() => {
            this.setState({ searchType: SEARCH_TYPES.KEY }, () => {
              if (this.state.searchQuery.length) {
                this.fetchDataPreview();
              }
            });
            RoutesStore.getRouter().updateQuery({ qt: SEARCH_TYPES.KEY });
          }}
        >
          Columns
        </button>
        <button
          className={classnames('btn predefined-search-link', {
            active: this.state.searchType === SEARCH_TYPES.VALUE
          })}
          onClick={() => {
            const hasActiveFilters = !this.state.activeFilters.isEmpty();
            this.setState(
              { searchType: SEARCH_TYPES.VALUE, activeFilters: Map(), filters: Map() },
              () => {
                if (this.state.searchQuery.length || hasActiveFilters) {
                  this.fetchDataPreview();
                }
              }
            );
            RoutesStore.getRouter().updateQuery({ qt: SEARCH_TYPES.VALUE });
          }}
        >
          Values
        </button>
      </div>
    );
  }

  renderFilters() {
    if (this.state.searchType === SEARCH_TYPES.VALUE) return null;

    return (
      <tr className="filters-row">
        {this.getColumns()
          .map((header, index) => {
            const value = this.state.filters.get(header, '');
            const isActive =
              this.state.activeFilters.has(header) &&
              this.state.activeFilters.get(header) === value;

            return (
              <td key={index}>
                <FormGroup className="m-0">
                  <FormControl
                    type="text"
                    placeholder="Filter"
                    value={value}
                    onKeyDown={(e) => {
                      if (e.key === keyCodes.ENTER) {
                        this.refetchPreviewWithFilters();
                      }
                    }}
                    onChange={(e) =>
                      this.setState({ filters: this.state.filters.set(header, e.target.value) })
                    }
                    className={classnames({ active: isActive })}
                  />
                  {this.renderInputControls(header, isActive)}
                </FormGroup>
              </td>
            );
          })
          .toArray()}
      </tr>
    );
  }

  renderInputControls(header, isActive) {
    if (isActive) {
      return (
        <Tooltip placement="top" tooltip="Clear filter">
          <Button
            bsStyle="link"
            className="btn-link-inline icon-addon-left"
            onClick={() => {
              this.deactivateFilter(header);
              document.activeElement.blur();
            }}
          >
            <FontAwesomeIcon icon="circle-xmark" />
          </Button>
        </Tooltip>
      );
    }

    return (
      <Tooltip placement="top" tooltip="Apply all filters">
        <Button
          bsStyle="link"
          className="btn-link-inline btn-link-muted icon-addon-left save-filter"
          onClick={this.refetchPreviewWithFilters}
        >
          <FontAwesomeIcon icon="circle-check" />
        </Button>
      </Tooltip>
    );
  }

  renderRows() {
    if (this.state.isLoading) {
      return (
        <tr className="no-hover">
          <td colSpan={this.props.table.get('columns').count()}>
            <Loader className="icon-addon-right" />
            Loading data...
          </td>
        </tr>
      );
    }

    const columns = this.getColumns();

    if (this.state.rows.isEmpty() || columns.isEmpty()) {
      return (
        <tr className="no-hover">
          <td colSpan={this.props.table.get('columns').count()}>
            No{' '}
            {this.state.searchType === SEARCH_TYPES.KEY &&
            this.state.searchQuery.length &&
            this.state.activeFilters.isEmpty()
              ? 'columns'
              : 'data'}{' '}
            found
          </td>
        </tr>
      );
    }

    const columnOrder = this.getColumnOrder();

    return this.state.rows
      .map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row
            .sortBy((column) => columnOrder.indexOf(column.get('columnName')))
            .filter((column) => columns.includes(column.get('columnName')))
            .map((column, columnIndex) => {
              const value = String(column.get('value'));

              return (
                <td
                  key={`${rowIndex}-${columnIndex}`}
                  className={classnames('overflow-break-anywhere text-left', {
                    'active-filter-column': !!this.state.activeFilters.has(column.get('columnName'))
                  })}
                >
                  <DataSampleItem source={value}>
                    {this.state.searchType === SEARCH_TYPES.VALUE &&
                    this.state.searchQuery.length ? (
                      <MarkedText isCaseSensitive source={value} mark={this.state.searchQuery} />
                    ) : (
                      value
                    )}
                  </DataSampleItem>
                </td>
              );
            })
            .toArray()}
        </tr>
      ))
      .toArray();
  }

  refetchPreviewWithFilters = () => {
    this.setState(
      {
        activeFilters: this.state.filters.filter(Boolean),
        ...(this.state.searchType === SEARCH_TYPES.VALUE && { searchQuery: '' })
      },
      this.fetchDataPreview
    );
  };

  deactivateFilter = (name) => {
    this.setState({ filters: this.state.filters.delete(name) }, this.refetchPreviewWithFilters);
  };

  fetchDataPreview = () => {
    let options = { limit: 20 };

    if (this.state.searchType === SEARCH_TYPES.VALUE && this.state.searchQuery.length) {
      options = { limit: 100, fulltextSearch: this.state.searchQuery };
    } else if (!this.state.activeFilters.isEmpty()) {
      options = {
        limit: 100,
        whereFilters: prepareWhereFilters(this.state.activeFilters, this.props.backend)
      };
    }

    if (!this.state.sortBy.isEmpty()) {
      options = {
        ...options,
        orderBy: prepareOrderBy(
          this.state.sortBy,
          this.getColumnOrder(),
          this.props.table,
          this.props.backend
        )
      };
    }

    this.cancellablePromise?.cancel();
    this.resetScroll();
    this.setState({ isLoading: true });
    this.cancellablePromise = dataPreview(this.props.table.get('id'), options)
      .then((response) => this.setState({ rows: fromJS(response?.rows ?? []), isLoading: false }))
      .catch(this.handleError);

    return this.cancellablePromise;
  };

  debouncedFetchDataPreview = _.debounce(this.fetchDataPreview, 500);

  handleError = (error) => {
    let errorMessage = null;

    if (error.response?.body?.code === 'storage.maxNumberOfColumnsExceed') {
      errorMessage = 'Data sample cannot be displayed. Too many columns.';
    } else {
      errorMessage = error.response?.body?.message ?? error.message;
    }

    this.setState({ data: {}, activeFilters: Map(), isLoading: false, error: errorMessage });
  };

  getColumns = () => {
    const columnOrder = this.getColumnOrder();
    const allData = this.props.table.get('columns').sortBy((column) => columnOrder.indexOf(column));

    if (this.state.searchType === SEARCH_TYPES.KEY && this.state.searchQuery.length) {
      return allData.filter((column) => matchByWords(column, this.state.searchQuery));
    }

    return allData;
  };

  handleColumnsReorder = (order) => {
    if (!this.state.sortBy.isEmpty()) {
      this.refetchPreviewWithFilters();
    }

    if (_.isEqual(this.props.table.get('columns').toArray(), order)) {
      return this.removeSavedColumnOrder();
    }

    localStorage.setItem(this.getLocalStorageKey(), order);
    this.props.onChangeColumnOrder();
  };

  getColumnOrder = () => {
    return localStorage.getItem(this.getLocalStorageKey(), []);
  };

  getLocalStorageKey = () => {
    return `${TABLE_COLUMNS_ORDER}-${this.props.table.get('id')}`;
  };

  removeSavedColumnOrder = () => {
    localStorage.removeItem(this.getLocalStorageKey());
  };

  resetScroll = () => {
    if (this.tableRef.current?.scrollLeft > 0) {
      this.tableRef.current.scrollLeft = 0;
    }
  };
}

DataSample.propTypes = {
  table: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  backend: PropTypes.string.isRequired,
  fullScreen: PropTypes.bool,
  closeFullScreen: PropTypes.func,
  onChangeColumnOrder: PropTypes.func
};

export const DataSampleItem = (props) => {
  const [forceHide, setForceHide] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const isLong = props.source.length > TOOLTIP_CHARS_LIMIT;

  return (
    <Tooltip
      placement="top"
      type="explanatory"
      className="widest"
      tooltip={
        isLong ? (
          'The value is too long to be displayed in the data preview. If you want to see the full length, please download the table.'
        ) : (
          <div>
            <div className="overflow-break-anywhere">{props.source}</div>
            <hr />
            <small>{isCopied ? 'Copied' : 'Click to Copy to Clipboard'}</small>
          </div>
        )
      }
      forceHide={forceHide || !props.source}
    >
      <div
        className="tw-line-clamp-3 tw-break-all"
        onMouseOver={(e) => {
          setForceHide(e.currentTarget.scrollHeight <= e.currentTarget.clientHeight);
        }}
        onClick={() => {
          if (isLong) return;

          copyToClipboard(props.source).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 500);
          });
        }}
      >
        {props.children ?? props.source}
      </div>
    </Tooltip>
  );
};

export default DataSample;
