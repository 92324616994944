import React from 'react';
import {
  Alert,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  ProgressBar
} from 'react-bootstrap';
import classNames from 'classnames';
import type { Map } from 'immutable';

import FilesDropZone from '../../../react/common/FilesDropZone';
import InputValidation from '../../../react/common/InputValidation';
import OptionalFormLabel from '../../../react/common/OptionalFormLabel';
import { nameWarning } from '../constants';
import { validateTableName } from '../helpers';

export const INITIAL_CSV_FORM_STATE = {
  name: '',
  file: null,
  delimiter: ',',
  enclosure: '"',
  primaryKey: '',
  error: null,
  warning: null
};

type Props = {
  tables: Map<string, any>;
  values: typeof INITIAL_CSV_FORM_STATE;
  setValue: (key: string, value: string | File | null) => void;
  progress: number;
};

const CsvUploadForm = ({ tables, values, setValue, progress }: Props) => {
  if (progress && !values.error) {
    return <ProgressBar striped bsStyle="success" now={progress} active={progress < 100} />;
  }

  return (
    <div>
      {values.error && <Alert bsStyle="danger">{values.error}</Alert>}
      <InputValidation predefined="tableName" value={values.name}>
        {(inputState: string) => (
          <FormGroup validationState={values.warning ? 'error' : inputState}>
            <ControlLabel>Table name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={values.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('name', event.target.value);
                setValue('warning', validateTableName(event.target.value, tables));
              }}
            />
            <HelpBlock
              className={classNames({
                'text-danger': !!values.warning || inputState === 'error'
              })}
            >
              {values.warning || nameWarning}
            </HelpBlock>
          </FormGroup>
        )}
      </InputValidation>
      <FormGroup>
        <FilesDropZone
          label="CSV file"
          onDrop={(files) => setValue('file', files[0])}
          files={values.file}
        />
        <HelpBlock>Table structure will be set up from the CSV file.</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Delimiter</ControlLabel>
        <FormControl
          type="text"
          value={values.delimiter}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setValue('delimiter', event.target.value)
          }
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Enclosure</ControlLabel>
        <FormControl
          type="text"
          value={values.enclosure}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setValue('enclosure', event.target.value)
          }
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>
          Primary key <OptionalFormLabel />
        </ControlLabel>
        <FormControl
          type="text"
          placeholder="Primary key column name"
          value={values.primaryKey}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setValue('primaryKey', event.target.value)
          }
        />
        <HelpBlock>
          Primary key is useful for incremental imports - rows that already exist in the table are
          updated.
        </HelpBlock>
      </FormGroup>
    </div>
  );
};

export default CsvUploadForm;
