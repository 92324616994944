import { fromJS } from 'immutable';

import { USER_DOCUMENTATION_URL } from '../../constants/KbcConstants';
import dayjs from '../../date';

const ActionTypes = {
  MODELS_LOAD: 'MODELS_LOAD',
  MODELS_LOAD_SUCCESS: 'MODELS_LOAD_SUCCESS',
  MODELS_LOAD_ERROR: 'MODELS_LOAD_ERROR',

  MODEL_START_DEPLOYMENT: 'MODEL_START_DEPLOYMENT',
  MODEL_START_DEPLOYMENT_SUCCESS: 'MODEL_START_DEPLOYMENT_SUCCESS',
  MODEL_STOP_DEPLOYMENT: 'MODEL_STOP_DEPLOYMENT',
  MODEL_STOP_DEPLOYMENT_SUCCESS: 'MODEL_STOP_DEPLOYMENT_SUCCESS',

  MODEL_UPDATE_SUCCESS: 'MODEL_UPDATE_SUCCESS',

  MODELS_PROCESSING_JOBS_LOADED: 'MODELS_PROCESSING_JOBS_LOADED'
};

const PENDING_JOBS_QUERY =
  'component:docker AND params.component:keboola.mlflow AND (status:processing OR status:waiting)';

const DUMMY_PREVIEW_MODELS = fromJS([
  {
    stage: 'production',
    deployment: {
      id: '3',
      url: '',
      createdTimestamp: dayjs().subtract(2, 'minutes').format('YYYY-MM-DDThh:mm'),
      linkedConfigurations: [
        {
          componentId: 'keboola.snowflake-transformation',
          configurationId: '123456'
        },
        {
          componentId: 'keboola.python-transformation-v2',
          configurationId: '135790'
        },
        {
          componentId: 'keboola.python-transformation-v2',
          configurationId: '654321'
        }
      ]
    },
    name: '#1 Common Sets',
    description: 'Created from MySQL (ImportProdukt)',
    version: '12',
    user: 'thomas@grant.com'
  },
  {
    stage: 'staged',
    deployment: {
      id: '1',
      url: '',
      createdTimestamp: dayjs().subtract(2, 'minutes').format('YYYY-MM-DDThh:mm'),
      linkedConfigurations: []
    },
    name: '#1.1 Projects Limits',
    description: '',
    version: '9',
    user: 'thomas@grant.com'
  }
]).toMap();

const DUMMY_PREVIEW_TRANSFORMATIONS = fromJS({
  'keboola.snowflake-transformation': {
    configurations: {
      123456: {
        name: '#4 Google Analytics KBC',
        currentVersion: { created: dayjs().subtract(1, 'day').format('YYYY-MM-DDThh:mm') }
      }
    }
  },
  'keboola.python-transformation-v2': {
    configurations: {
      135790: {
        name: 'Testing Marketing',
        currentVersion: { created: dayjs().subtract(2, 'days').format('YYYY-MM-DDThh:mm') }
      },
      654321: {
        name: 'Data Validation POS',
        currentVersion: { created: dayjs().subtract(1, 'week').format('YYYY-MM-DDThh:mm') }
      }
    }
  }
});

const DUMMY_PREVIEW_ADMINS = fromJS({
  'thomas@grant.com': {
    name: 'Thomas Grant',
    email: 'thomas@grant.com'
  }
});

const HELP_LINK = `${USER_DOCUMENTATION_URL}/transformations/ml-model-deployment`;

export {
  ActionTypes,
  PENDING_JOBS_QUERY,
  DUMMY_PREVIEW_MODELS,
  DUMMY_PREVIEW_TRANSFORMATIONS,
  DUMMY_PREVIEW_ADMINS,
  HELP_LINK
};
