import { fromJS } from 'immutable';
import _ from 'underscore';

import * as constants from '../constants/KbcConstants';
import dispatcher from '../Dispatcher';

const NOTIFICATION_TIMEOUT_MS = 5000;

export default {
  receiveStack(stack) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_RECEIVE_STACK,
      stack
    });
  },

  emitApplicationChange() {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_EMIT_CHANGE
    });
  },

  showInitialLoading() {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_INITIAL_LOADING,
      loading: true
    });
  },

  hideInitialLoading() {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_INITIAL_LOADING,
      loading: false
    });
  },

  showPollLoading() {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_POLL_LOADING,
      loading: true
    });
  },

  hidePollLoading() {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_POLL_LOADING,
      loading: false
    });
  },

  receiveApplicationData(applicationData) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_DATA_RECEIVED,
      applicationData
    });
  },

  sendNotification(newNotification) {
    const notification = fromJS({
      type: 'info',
      id: newNotification.id ?? _.uniqueId('notification'),
      // error and warning notifications are not automatically dismissed
      timeout: ['error', 'warning'].includes(newNotification.type) ? null : NOTIFICATION_TIMEOUT_MS,
      ...newNotification,
      initialTimeout: newNotification.timeout ?? NOTIFICATION_TIMEOUT_MS,
      paused: false
    });

    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_SEND_NOTIFICATION,
      notification
    });
  },

  deleteNotification(id) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_DELETE_NOTIFICATION,
      notificationId: id
    });
  },

  pauseNotificationAging() {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_SET_PAUSE_NOTIFICATION,
      paused: true
    });
  },

  resetNotificationAging() {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.APPLICATION_SET_PAUSE_NOTIFICATION,
      paused: false
    });
  }
};
