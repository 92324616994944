import React, { useState } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import CollapsibleBox from '../../../../react/common/CollapsibleBox';
import InfoTooltip from '../../../../react/common/InfoTooltip';
import Select from '../../../../react/common/Select';

const DATE_HELP =
  'Parameter of the Facebook Graph API nested request. Applies only if the endpoint parameter is given and specifies the date since data of the given endpoint will be retrieved. Can by specified absolutely (yyyy-mm-dd) or relatively (e.g. 15 days ago)';

const TEMPLATES = [
  {
    name: 'today',
    until: 'today',
    since: 'now'
  },
  {
    name: 'Yesterday',
    until: 'yesterday',
    since: 'today'
  },
  {
    name: 'Last 7 days',
    until: 'today',
    since: '7 days ago'
  },
  {
    name: 'Last 30 days',
    until: 'today',
    since: '30 days ago'
  },
  {
    name: 'This Month',
    until: 'midnight first day of this month',
    since: 'midnight last day of this month'
  },
  {
    name: 'Last Month',
    until: 'midnight first day of last month',
    since: 'midnight last day of last month'
  },
  {
    name: 'This Week',
    until: 'monday this week',
    since: 'today'
  },
  {
    name: 'Last Week',
    until: 'monday last week',
    since: 'sunday last week'
  }
];

type Props = {
  config: Map<string, any>;
  readOnly: boolean;
  updateLocalState: (path: string[], newValue: Map<string, any> | boolean | string) => void;
};

const DateRangeSelector = ({ config, readOnly, updateLocalState }: Props) => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const since = config.getIn(['query', 'since'], '');
  const until = config.getIn(['query', 'until'], '');

  const handleSelectTemplate = (name: string) => {
    const template = TEMPLATES.find((t) => t.name === name);

    if (!template) {
      return;
    }

    setSelectedTemplate(template.name);

    const newQuery = config
      .setIn(['query', 'since'], template.since)
      .setIn(['query', 'until'], template.until);

    updateLocalState([], newQuery);
  };

  return (
    <CollapsibleBox title="Date Range" defaultOpen>
      <FormGroup>
        <ControlLabel>Sample Date Ranges</ControlLabel>
        <Select
          value={selectedTemplate}
          placeholder="Select Predefined Date Ranges"
          options={TEMPLATES.map((t) => ({
            label: t.name,
            value: t.name
          }))}
          clearable={false}
          disabled={readOnly}
          onChange={handleSelectTemplate}
        />
      </FormGroup>

      <div className="tw-grid tw-grid-cols-2 tw-gap-6 tw-mt-4">
        <div>
          <FormGroup>
            <ControlLabel>
              Since
              <InfoTooltip tooltip={DATE_HELP} />
            </ControlLabel>
            <FormControl
              value={since}
              placeholder="yyyy-mm-dd or 15 days ago"
              disabled={readOnly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedTemplate('');
                updateLocalState(['query', 'since'], e.target.value);
              }}
            />
          </FormGroup>
        </div>
        <div>
          <FormGroup>
            <ControlLabel>
              Until
              <InfoTooltip tooltip={DATE_HELP} />
            </ControlLabel>
            <FormControl
              value={until}
              placeholder="yyyy-mm-dd or 15 days ago"
              disabled={readOnly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedTemplate('');
                updateLocalState(['query', 'until'], e.target.value);
              }}
            />
          </FormGroup>
        </div>
      </div>
    </CollapsibleBox>
  );
};

export default DateRangeSelector;
