import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import NewTransformationBucketModal from '../modals/NewTransformationBucket';

const NewTransformationBucketButton = createReactClass({
  propTypes: {
    label: PropTypes.string
  },

  getDefaultProps() {
    return {
      label: 'New Bucket'
    };
  },

  render() {
    return <NewTransformationBucketModal label={this.props.label} />;
  }
});

export default NewTransformationBucketButton;
