import { useEffect } from 'react';

const useMatchMedia = (mediaQueryString, eventHandler) => {
  useEffect(() => {
    const mediaQuery = window.matchMedia(mediaQueryString);
    const listener = mediaQuery.addListener(eventHandler);
    eventHandler(mediaQuery);
    return () => {
      mediaQuery.removeListener(listener);
    };
  }, [mediaQueryString, eventHandler]);
};

export default useMatchMedia;
