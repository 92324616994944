import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import _ from 'underscore';

import Confirm from '../../../../../react/common/Confirm';
import CredentialsBox from '../../../../../react/common/CredentialsBox';
import TableauServerCredentialsModal from './TableauServerCredentialsModal';

const TableauServerRow = createReactClass({
  propTypes: {
    renderComponent: PropTypes.func,
    renderEnableUpload: PropTypes.func,
    resetUploadTask: PropTypes.func,
    updateLocalStateFn: PropTypes.func,
    localState: PropTypes.object,
    configId: PropTypes.string,
    account: PropTypes.object,
    setConfigDataFn: PropTypes.func
  },

  render() {
    return (
      <div className="form-horizontal">
        <FormGroup>
          <ControlLabel className="col-sm-2">Destination</ControlLabel>
          <FormControl.Static className="col-sm-10" componentClass="div">
            {this.props.renderComponent()}
          </FormControl.Static>
        </FormGroup>
        <FormGroup>
          <ControlLabel className="col-sm-2">Credentials</ControlLabel>
          <FormControl.Static className="col-sm-10" componentClass="div">
            {this.renderCredentials()}
          </FormControl.Static>
        </FormGroup>
        {this._isAuthorized() && (
          <FormGroup>
            <ControlLabel className="col-sm-2">Instant upload</ControlLabel>
            <FormControl.Static className="col-sm-10" componentClass="div">
              {this.props.renderEnableUpload()}
            </FormControl.Static>
          </FormGroup>
        )}
      </div>
    );
  },

  renderCredentials() {
    if (!this._isAuthorized()) {
      return this._renderAuthorizeButton('setup');
    }

    return (
      <>
        <div className="mb-1">
          <CredentialsBox
            rows={Map({
              'Server URL': this.props.account.get('server_url'),
              Username: this.props.account.get('username'),
              'Project name': this.props.account.get('project_name'),
              ...(this.props.account.get('site') ? { Site: this.props.account.get('site') } : {})
            })}
          />
        </div>
        {this._renderAuthorizeButton('edit')}
        <Confirm
          icon="trash"
          title="Delete Credentials"
          text={`Are you sure you want to delete the credentials for ${this.props.account.get(
            'server_url'
          )}`}
          buttonLabel="Delete"
          onConfirm={() => this.props.resetUploadTask()}
        >
          <Button className="ml-1">
            <FontAwesomeIcon icon="trash" className="icon-addon-right" />
            Delete Credentials
          </Button>
        </Confirm>
      </>
    );
  },

  _renderAuthorizeButton(caption) {
    return (
      <Button
        bsStyle="success"
        onClick={() => this.props.updateLocalStateFn(['tableauServerModal', 'show'], true)}
      >
        {caption === 'setup' ? (
          <span>
            <FontAwesomeIcon icon="user" className="icon-addon-right" />
            Set Up credentials
          </span>
        ) : (
          <span>
            <FontAwesomeIcon icon="pen" className="icon-addon-right" />
            Edit credentials
          </span>
        )}
        <TableauServerCredentialsModal
          configId={this.props.configId}
          localState={this.props.localState.get('tableauServerModal', Map())}
          updateLocalState={(data) => this.props.updateLocalStateFn(['tableauServerModal'], data)}
          credentials={this.props.account}
          saveCredentialsFn={(credentials) =>
            this.props.setConfigDataFn(['parameters', 'tableauServer'], credentials)
          }
        />
      </Button>
    );
  },

  _isAuthorized() {
    let passwordEmpty = true;

    if (this.props.account) {
      const password = this.props.account.get('password');
      const hashPassword = this.props.account.get('#password');
      passwordEmpty = _.isEmpty(password) && _.isEmpty(hashPassword);
    }

    return (
      this.props.account &&
      !_.isEmpty(this.props.account.get('server_url')) &&
      !_.isEmpty(this.props.account.get('username')) &&
      !_.isEmpty(this.props.account.get('project_name')) &&
      !passwordEmpty
    );
  }
});

export default TableauServerRow;
