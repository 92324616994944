import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import ImmutableRendererMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import BlockButton from '../../../react/common/BlockButton';
import Loader from '../../../react/common/Loader';
import RoutesStore from '../../../stores/RoutesStore';
import ActionCreators from '../../orchestrations/ActionCreators';
import { JOBS_LIMIT_FOR_GRAPH, routeNames } from '../../queue/constants';
import { orchestrationJobTypes } from '../constants';
import JobsTableRow from './JobsTableRow';

const JobsTable = createReactClass({
  mixins: [ImmutableRendererMixin],

  propTypes: {
    orchestration: PropTypes.instanceOf(Map).isRequired,
    jobs: PropTypes.instanceOf(List).isRequired,
    admins: PropTypes.instanceOf(Map).isRequired,
    status: PropTypes.oneOf(Object.values(orchestrationJobTypes)),
    hasNewQueue: PropTypes.bool,
    isLoading: PropTypes.bool
  },

  getInitialState() {
    return {
      hasMoreJobs: this.props.jobs.count() >= JOBS_LIMIT_FOR_GRAPH,
      isLoadingMoreJobs: false
    };
  },

  render() {
    return (
      <div className="box">
        <Table responsive hover>
          <thead>
            <tr>
              <th>Created</th>
              <th className="text-right">Created At</th>
              <th className="text-right">Duration</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </Table>
        {this.renderLoadMoreButton()}
      </div>
    );
  },

  renderRows() {
    if (this.props.jobs.isEmpty()) {
      return (
        <tr className="no-hover">
          <td className="text-muted" colSpan={4}>
            {this.props.isLoading ? (
              <>
                <Loader className="icon-addon-right" />
                Loading jobs...
              </>
            ) : (
              <>No orchestration jobs found</>
            )}
          </td>
        </tr>
      );
    }

    return this.props.jobs
      .map((job) => {
        const tokenDescription = job.getIn(
          ['token', 'description'],
          job.getIn(['initiatorToken', 'description'])
        );

        return (
          <JobsTableRow
            job={job}
            key={job.get('id')}
            admin={this.props.admins.get(tokenDescription)}
            hasNewQueue={this.props.hasNewQueue}
          />
        );
      })
      .toArray();
  },

  renderLoadMoreButton() {
    if (
      this.props.hasNewQueue
        ? this.props.jobs.count() < JOBS_LIMIT_FOR_GRAPH
        : !this.state.hasMoreJobs
    ) {
      return null;
    }

    if (this.props.hasNewQueue) {
      const statusInLabel = { success: ' Successful', error: ' Failed' };

      return (
        <BlockButton
          label={`Show All${statusInLabel[this.props.status] || ''} Jobs`}
          onClick={() =>
            RoutesStore.getRouter().transitionTo(
              routeNames.JOBS,
              {},
              {
                component: KEBOOLA_ORCHESTRATOR,
                config: this.props.orchestration.get('id'),
                status: this.props.status
              }
            )
          }
          isLoading={this.state.isLoadingMoreJobs}
        />
      );
    }

    return <BlockButton onClick={this.handleLoadMore} isLoading={this.state.isLoadingMoreJobs} />;
  },

  handleLoadMore() {
    const orchestrationId = this.props.orchestration.get('id');

    this.setState({ isLoadingMoreJobs: true });

    ActionCreators.loadMoreJobs(orchestrationId)
      .then((jobs) => {
        this.setState({ hasMoreJobs: jobs.length >= JOBS_LIMIT_FOR_GRAPH });
      })
      .finally(() => {
        this.setState({ isLoadingMoreJobs: false });
      });
  }
});

export default JobsTable;
