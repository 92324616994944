import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Map, OrderedMap } from 'immutable';

import { componentTypes } from '../../constants/componentTypes';
import {
  COLLAPSED_CONFIGURATIONS,
  CONFIGURATIONS_COMPONENTS_SORT_BY
} from '../../constants/localStorageKeys';
import BoxLoader from '../../react/common/BoxLoader';
import ConfigurationsTable from '../../react/common/ConfigurationsTable/Table';
import FilterPanel from '../../react/common/FilterPanel';
import LazyList from '../../react/common/LazyList';
import NoResultsFound from '../../react/common/NoResultsFound';
import Link from '../../react/common/RouterLink';
import SortSelect, { SORT } from '../../react/common/SortSelect';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import { CHANGE_EVENT_KEY, getItem, setItem } from '../../utils/localStorage';
import matchByWords from '../../utils/matchByWords';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import StorageTablesStore from '../components/stores/StorageTablesStore';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import NotificationsStore from '../notifications/store';
import JobsStore from '../queue/store';
import { prepareTablesMetadataMap } from '../storage/helpers';
import { allowedTypes, routeNames } from './constants';
import { mergeSampleDataToConfigurations, sortComponents } from './helpers';

const Configurations = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      DevBranchesStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageTablesStore,
      NotificationsStore,
      JobsStore
    )
  ],

  getStateFromStores() {
    const installedComponents = InstalledComponentsStore.getAll().filter((component) =>
      allowedTypes.includes(component.get('type'))
    );
    const allComponents = ComponentsStore.getAll();

    return {
      installedComponents,
      allComponents,
      allTables: StorageTablesStore.getAll(),
      allConfigurations: InstalledComponentsStore.getAll(),
      components: mergeSampleDataToConfigurations(installedComponents, allComponents),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      latestJobs: JobsStore.getLatestConfigJobs(),
      isLoadingConfigurations: InstalledComponentsStore.getIsLoading(),
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      notifications: NotificationsStore.getAll(),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      hasFlows: ApplicationStore.hasFlows()
    };
  },

  getInitialState() {
    return {
      filterType: RoutesStore.getRouterState().getIn(['location', 'query', 'type'], ''),
      filterQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
      sortBy: getItem(CONFIGURATIONS_COMPONENTS_SORT_BY, SORT.RECENTLY_ADDED),
      isSomeTableCollapsed: false
    };
  },

  refreshStateFromLocalStorage() {
    this.setState({
      isSomeTableCollapsed: this.getComponentsFiltered().some((component, componentId) =>
        getItem(`${COLLAPSED_CONFIGURATIONS}-${componentId}`, false)
      )
    });
  },

  componentDidMount() {
    window.addEventListener(CHANGE_EVENT_KEY, this.refreshStateFromLocalStorage);
    this.refreshStateFromLocalStorage();
  },

  componentWillUnmount() {
    window.removeEventListener(CHANGE_EVENT_KEY, this.refreshStateFromLocalStorage);
  },

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filterQuery !== this.state.filterQuery ||
      prevState.filterType !== this.state.filterType
    ) {
      RoutesStore.getRouter().updateQuery({
        q: this.state.filterQuery,
        type: this.state.filterType
      });
    }
  },

  render() {
    const components = this.getComponentsFiltered();

    return (
      <>
        <FilterPanel
          className="mb-0"
          placeholder={this.getPlaceholder}
          query={this.state.filterQuery}
          onChange={(query) => this.setState({ filterQuery: query })}
          additionalActions={this.renderAdditionalActions()}
        />
        {!this.state.components.isEmpty() && (
          <ButtonToolbar className="flex-container flex-end mtp-6 mbp-6">
            {this.renderCollapseAllToggle(components)}
            <SortSelect
              value={this.state.sortBy}
              onChange={(sortBy) => {
                this.setState({ sortBy });
                setItem(CONFIGURATIONS_COMPONENTS_SORT_BY, sortBy);
              }}
              disabled={components.isEmpty()}
              supportedOptions={[SORT.A_Z, SORT.Z_A, SORT.RECENTLY_ADDED, SORT.RECENTLY_USED]}
            />
          </ButtonToolbar>
        )}
        <LazyList limit={5} items={components} render={this.renderConfigurations} />
      </>
    );
  },

  renderAdditionalActions() {
    return (
      <div className="predefined-search-list">
        {this.renderAdditionalActionsButton('', 'All')}
        <span className="group-separator" />
        {this.renderAdditionalActionsButton(componentTypes.EXTRACTOR, 'Data Sources')}
        {this.renderAdditionalActionsButton(componentTypes.WRITER, 'Data Destinations')}
        {this.renderAdditionalActionsButton(componentTypes.APPLICATION, 'Applications')}
      </div>
    );
  },

  renderAdditionalActionsButton(type, label) {
    const active = this.state.filterType === type;

    return (
      <button
        className={classnames('btn predefined-search-link', { active })}
        onClick={() => this.setState({ filterType: active ? '' : type })}
      >
        {label}
      </button>
    );
  },

  renderCollapseAllToggle(components) {
    const shouldExpand = this.state.isSomeTableCollapsed;

    return (
      <Button
        bsStyle="link"
        onClick={() => {
          components.map((component, componentId) => {
            setItem(`${COLLAPSED_CONFIGURATIONS}-${componentId}`, !shouldExpand);
          });
        }}
        disabled={components.isEmpty()}
        className="btn-link-inline mrp-3 line-height-20"
      >
        {shouldExpand ? 'Expand' : 'Collapse'} Components
      </Button>
    );
  },

  renderConfigurations(componentsFiltered) {
    if (
      this.state.isLoadingConfigurations &&
      this.state.installedComponents.isEmpty() &&
      componentsFiltered.isEmpty()
    ) {
      return <BoxLoader entity="configurations" />;
    }

    if (this.state.installedComponents.isEmpty() && componentsFiltered.isEmpty()) {
      return (
        <div className="box">
          <div className="box-content">
            No configurations created yet. Go to{' '}
            <Link to={routeNames.ROOT}>Components Directory</Link> page to create a new one.
          </div>
        </div>
      );
    }

    if (componentsFiltered.isEmpty()) {
      return <NoResultsFound className="mtp-4" entityName="configurations" />;
    }

    const firstComponentId = componentsFiltered.first()?.get('id');

    return componentsFiltered
      .map((component) => {
        return (
          <ConfigurationsTable
            showMigrations
            showComponentDetailLink
            showUsedIn
            showData={component.get('type') !== componentTypes.WRITER}
            allowCreateConfig
            key={component.get('id')}
            component={component}
            admins={this.state.admins}
            currentAdmin={this.state.currentAdmin}
            notifications={this.state.notifications}
            tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
            componentsMetadata={this.state.componentsMetadata}
            allComponents={this.state.allComponents}
            allConfigurations={this.state.allConfigurations}
            latestJobs={this.state.latestJobs}
            readOnly={this.state.readOnly}
            hasNewQueue={this.state.hasNewQueue}
            hasFlows={this.state.hasFlows}
            configurations={component.get('configurations', OrderedMap())}
            forceShowAll={!!this.state.filterQuery}
            {...(firstComponentId === component.get('id') && { className: 'mt-0' })}
          />
        );
      })
      .toArray();
  },

  getPlaceholder() {
    const components = this.state.installedComponents.filter((component) => {
      return !this.state.filterType || component.get('type') === this.state.filterType;
    });

    return `Search${
      !this.state.filterType ? ' all' : ''
    } components (${components.count()}) and configurations (${components
      .flatMap((component) => component.get('configurations'))
      .count()})`;
  },

  getComponentsFiltered() {
    let components = this.state.components.filter(
      (component) => !this.state.filterType || component.get('type') === this.state.filterType
    );

    components = sortComponents(components, this.state.sortBy, this.state.latestJobs);

    if (!this.state.filterQuery) {
      return components;
    }

    const exactIdMatchComponent = this.state.allComponents.find(
      (component) => component.get('id') === this.state.filterQuery
    );
    const filteredComponents = components
      .map((component) => {
        if (
          component.get('id') === this.state.filterQuery ||
          matchByWords(component.get('name'), this.state.filterQuery)
        ) {
          return component;
        }

        return component.set(
          'configurations',
          component.get('configurations', Map()).filter((configuration) => {
            return (
              configuration.get('id') === this.state.filterQuery ||
              matchByWords(
                [configuration.get('name'), configuration.get('description')],
                this.state.filterQuery
              )
            );
          })
        );
      })
      .filter((component) => component.get('configurations').count() > 0);

    if (exactIdMatchComponent && !filteredComponents.has(exactIdMatchComponent.get('id'))) {
      return filteredComponents.set(exactIdMatchComponent.get('id'), exactIdMatchComponent);
    }

    return filteredComponents;
  }
});

export default Configurations;
