import { List, Map } from 'immutable';

import { KEBOOLA_SHARED_CODE } from '../../constants/componentIds';
import { hasGenericCodeBlocksUI } from '../components/helpers';
import { getBlocksAsString } from '../components/react/components/generic/code-blocks/helpers';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';

const configEnhancements = (component: Map<string, any>, config: Map<string, any>) => {
  if (hasGenericCodeBlocksUI(component)) {
    return Map().set(
      'sql',
      getBlocksAsString(
        component.get('id'),
        config.getIn(['configuration', 'parameters', 'blocks']),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SHARED_CODE)
          .find(
            (config: Map<string, any>) => {
              return config.getIn(['configuration', 'componentId']) === component.get('id');
            },
            null,
            Map()
          )
          .get('rows', List())
      )
    );
  }

  return config.get('configuration');
};

export { configEnhancements };
