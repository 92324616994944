import React from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import { componentTypes } from '../../../constants/componentTypes';
import Check from '../../../react/common/Check';
import Checkbox from '../../../react/common/Checkbox';
import CodeEditor from '../../../react/common/CodeEditor';
import ConfigurationInfoPanel from '../../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../../react/common/ConfigurationTabs';
import Select from '../../../react/common/Select';
import SourceSearchInEditor from '../../../react/common/SourceSearch/SourceSearchInEditor';
import Tooltip from '../../../react/common/Tooltip';
import Sidebar from '../../../react/layout/Sidebar';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import getDefaultBucket from '../../../utils/getDefaultBucket';
import ComponentDescription from '../../components/react/components/ComponentDescription';
import FiltersDescription from '../../components/react/components/generic/FiltersDescription';
import TablesFilterModal from '../../components/react/components/generic/TableFiltersOnlyModal';
import SapiTableSelector from '../../components/react/components/SapiTableSelector';
import SapiTableLinkEx from '../../components/react/components/StorageApiTableLinkEx';
import ComponentsStore from '../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../components/stores/InstalledComponentsStore';
import StorageBucketsStore from '../../components/stores/StorageBucketsStore';
import StorageTablesStore from '../../components/stores/StorageTablesStore';
import { STAGE } from '../../storage/constants';
import { prepareTablesMetadataMap } from '../../storage/helpers';
import {
  getEditingValue,
  getInputMapping,
  getInTable,
  params,
  resetEditingMapping,
  startEditing,
  updateEditingMapping,
  updateEditingValue,
  updateLocalState
} from '../actions';
import ResultsHelpModal from './ResultsHelpModal';
import { analysisTypes, languageOptions } from './templates';

const componentId = 'geneea.nlp-analysis-v2';

const domainOptions = [
  {
    label: 'News Articles',
    value: 'news'
  },
  {
    label: 'Voice of the Customer - Hospitality',
    value: 'voc-hospitality'
  },
  {
    label: 'Voice of the Customer - Banking',
    value: 'voc-banking'
  }
];

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      InstalledComponentsStore
    )
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const localState = InstalledComponentsStore.getLocalState(componentId, configId);
    const config = InstalledComponentsStore.getConfig(componentId, configId);
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);

    const intable = getInTable(configId);
    const parameters = configData.get('parameters', Map());

    return {
      configId,
      localState,
      config,
      configData,
      intable,
      parameters,
      componentId,
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      isSaving: InstalledComponentsStore.getSavingConfigData(componentId, configId),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      editing: !!localState.get('editing'),
      allBuckets: StorageBucketsStore.getAll(),
      allTables: StorageTablesStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      readOnly: ApplicationStore.isReadOnly(),
      hasFlows: ApplicationStore.hasFlows()
    };
  },

  componentDidMount() {
    let data = this.state.configData;
    if (data) {
      data = data.toJS();
    }

    if (_.isEmpty(data)) {
      startEditing(this.state.configId);
    }
  },

  parameterList(key, defaultValue) {
    const val = this.parameter(key, defaultValue);
    return val ? val.join(', ') : val;
  },

  parameter(key, defaultValue) {
    return this.state.parameters.getIn([].concat(key), defaultValue);
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={this.state.componentId} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.config}
          hasFlows={this.state.hasFlows}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          {this.renderResultsHelpModal()}
          {this.renderTableFiltersModal()}
          <div className="col-sm-9">
            <ComponentDescription
              componentId={componentId}
              configId={this.state.configId}
              placeholderEntity={componentTypes.APPLICATION}
            />
            <div className="box">
              <div className="box-content">
                <div className="form form-horizontal">
                  {!this.state.readOnly && this.state.editing
                    ? this.renderEditing()
                    : this.renderStatic()}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={componentId}
              configId={this.state.configId}
              run={{
                disabled: this.state.editing ? 'Configuration is not saved.' : '',
                text: 'You are about to run the analysis job of selected task(s).'
              }}
            />
          </div>
        </div>
      </>
    );
  },

  intableChange(value) {
    this.updateEditingValue('intable', value);
    resetEditingMapping(this.state.configId, value);
    const table = this.state.allTables.find((t) => t.get('id') === value);
    this.updateEditingValue(params.DATACOLUMN, List());
    this.updateEditingValue(params.LEAD, List());
    this.updateEditingValue(params.TITLE, List());
    this.updateEditingValue(params.PRIMARYKEY, table ? table.get('primaryKey') : List());
  },

  renderEditing() {
    return (
      <div>
        {this.renderSourceEditing()}
        {this.renderFormElement(
          this.renderFilterLabel(),
          this.renderDataFilter(),
          'Input table data filtered by specified rules, the filtered columns must be indexed.'
        )}
        {this.renderColumnSelect(
          'Id columns',
          params.PRIMARYKEY,
          'Column of the input table uniquely identifying a row in the table.',
          true
        )}
        {this.renderColumnSelect(
          'Text Columns',
          params.DATACOLUMN,
          'Main text of the analyzed document',
          true
        )}
        {this.renderColumnSelect(
          'Title Columns (optional)',
          params.TITLE,
          'Title of the analyzed document',
          true
        )}
        {this.renderColumnSelect(
          'Lead Columns (optional)',
          params.LEAD,
          'Lead or abstract of the analyzed document',
          true
        )}

        {this.renderDomainSelect('The source domain from which the document originates.')}
        {this.renderFormElement(
          'Language',
          <Select
            key="language"
            name="language"
            placeholder="autodetect"
            clearable={false}
            value={this.getEditingValue(params.LANGUAGE)}
            onChange={(newValue) => this.updateEditingValue(params.LANGUAGE, newValue)}
            options={languageOptions}
          />,
          'Language of the text of the data column.'
        )}

        {this.renderFormElement(
          'Correction',
          this.renderEnumSelect(params.CORRECTION, ['none', 'basic', 'aggressive']),
          'Indicates whether common typos should be corrected before analysis'
        )}
        {this.renderFormElement(
          'Diacritization',
          this.renderEnumSelect(params.DIACRITIC, ['none', 'auto', 'yes']),
          'Before analysing Czech text where diacritics are missing, add all the wedges and accents. For example, Muj ctyrnohy pritel is changed to Můj čtyřnohý přítel.'
        )}
        {this.renderEditCheckBox(
          params.BETA,
          'Use Beta Version',
          "Use Geneea's beta server (use only when instructed to do so)"
        )}
        {this.renderAnalysisTypesSelect()}
        {this.renderAdvancedSettings()}
      </div>
    );
  },

  renderSourceEditing() {
    if (this.state.configData.hasIn(['storage', 'input', 'tables', 0, 'source_search'])) {
      return this.renderSourceSearch();
    }

    return this.renderFormElement(
      'Input Table',
      <SapiTableSelector
        placeholder="Select..."
        buckets={this.state.allBuckets}
        tables={this.state.allTables}
        value={this.getEditingValue('intable')}
        onSelectTableFn={this.intableChange}
      />,
      'Table conatining documents to analyze'
    );
  },

  renderAdvancedSettings() {
    let data = this.getEditingValue(params.ADVANCED);
    if (!this.state.editing) {
      const advancedData = this.parameter(params.ADVANCED, Map()).toJS();
      if (_.isEmpty(advancedData)) {
        data = '{}';
      } else {
        data = JSON.stringify(advancedData, null, '  ');
      }
    }
    const element = (
      <CodeEditor
        value={data}
        onChange={this.updateEditingValue.bind(this, params.ADVANCED)}
        options={{
          lint: this.state.editing,
          readOnly: this.state.readOnly || !this.state.editing || !!this.state.isSaving,
          cursorHeight: this.state.readOnly || !this.state.editing || !!this.state.isSaving ? 0 : 1
        }}
      />
    );

    return this.renderFormElement(
      'Advanced',
      element,
      'Specifies arbitrary parameters as a JSON object'
    );
  },

  renderEditCheckBox(prop, name, description) {
    return (
      <div className="form-group">
        <div className="col-sm-9 col-sm-offset-3">
          <Checkbox
            checked={this.getEditingValue(prop)}
            onChange={(checked) => this.updateEditingValue(prop, checked)}
          >
            {name}
          </Checkbox>
          <p className="help-block">{description}</p>
        </div>
      </div>
    );
  },

  renderTableFiltersModal() {
    return (
      <TablesFilterModal
        show={!!this.getEditingValue('showFilterModal')}
        onOk={() => this.updateEditingValue('showFilterModal', false)}
        value={getInputMapping(this.state.configId, this.state.editing)}
        allTables={this.state.allTables}
        onSetMapping={(newMapping) => updateEditingMapping(this.state.configId, newMapping)}
        onResetAndHide={() => {
          const savedMapping = this.getEditingValue('backupedMapping');
          updateEditingMapping(this.state.configId, savedMapping);
          this.updateEditingValue('showFilterModal', false);
        }}
      />
    );
  },

  renderFilterLabel() {
    const isEditing = this.state.editing;
    const mapping = getInputMapping(this.state.configId, isEditing);
    const modalButton = (
      <button
        className="btn btn-link"
        type="button"
        onClick={() => {
          this.updateEditingValue('showFilterModal', true);
          this.updateEditingValue('backupedMapping', mapping);
        }}
      >
        <FontAwesomeIcon icon="pen" fixedWidth />
      </button>
    );
    return (
      <span>
        Input Data Filter
        {isEditing ? modalButton : null}
      </span>
    );
  },

  renderDataFilter() {
    const isEditing = this.state.editing;
    const mapping = getInputMapping(this.state.configId, isEditing);

    return (
      <span>
        <FiltersDescription value={mapping} rootClassName="" />
      </span>
    );
  },

  renderAnalysisTypesSelect() {
    const selectedTypes = this.getEditingValue(params.ANALYSIS);
    const options = _.map(_.keys(analysisTypes), (value) => {
      return (
        <>
          <Checkbox
            checked={selectedTypes.contains(value)}
            onChange={(checked) => {
              const newSelected = checked
                ? selectedTypes.push(value)
                : selectedTypes.filter((type) => type !== value);
              this.updateEditingValue(params.ANALYSIS, newSelected);
            }}
          >
            {analysisTypes[value].name}
          </Checkbox>
          {analysisTypes[value].description && (
            <HelpBlock>{analysisTypes[value].description}</HelpBlock>
          )}
        </>
      );
    });

    return this.renderFormElement('Analysis tasks', options);
  },

  renderFormElement(label, element, description = '', hasError = false) {
    let errorClass = 'form-group';
    if (hasError) {
      errorClass = 'form-group has-error';
    }

    return (
      <div className={errorClass}>
        <label className="control-label col-sm-3">{label}</label>
        <div className="col-sm-9">
          {element}
          <span className="help-block">{description}</span>
        </div>
      </div>
    );
  },

  renderEnumSelect(prop, options) {
    const selectOptions = options.map((op) => {
      return {
        label: op,
        value: op
      };
    });
    return (
      <Select
        key={prop}
        clearable={false}
        value={this.getEditingValue(prop)}
        onChange={(value) => this.updateEditingValue(prop, value)}
        options={selectOptions}
      />
    );
  },

  renderDomainSelect(description) {
    return this.renderFormElement(
      'Domain',
      <Select
        placeholder="Select or type new..."
        allowCreate
        clearable
        key="domain"
        name="domain"
        value={this.getEditingValue(params.DOMAIN)}
        onChange={(value) => this.updateEditingValue(params.DOMAIN, value)}
        options={domainOptions}
      />,
      description
    );
  },

  renderColumnSelect(label, column, description, isMulti) {
    const result = this.renderFormElement(
      label,
      <Select
        multi={isMulti}
        clearable={false}
        key={column}
        value={this.getEditingValue(column)}
        onChange={(newValue) => {
          this.updateEditingValue(column, isMulti ? newValue : List([newValue]));
        }}
        options={this.getColumns()}
      />,
      description
    );
    return result;
  },

  findDomainNameByValue(value) {
    const result = domainOptions.find((c) => c.value === value);
    return !!result ? result.label : value;
  },

  renderStatic() {
    const lang = this.parameter(params.LANGUAGE) || '';
    const langLabel = languageOptions.find((o) => o.value === lang).label;

    return (
      <div>
        {this.renderIntableStatic()}
        {this.RenderStaticInput('Input Data Filter', this.renderDataFilter())}
        {this.RenderStaticInput('Id column', this.parameterList(params.PRIMARYKEY))}
        {this.RenderStaticInput('Text Column', this.parameterList(params.DATACOLUMN))}
        {this.RenderStaticInput('Title Column (optional)', this.parameterList(params.TITLE))}
        {this.RenderStaticInput('Lead Column (optional)', this.parameterList(params.LEAD))}

        {this.RenderStaticInput(
          'Domain',
          this.findDomainNameByValue(this.parameter(params.DOMAIN))
        )}
        {this.RenderStaticInput('Language', langLabel)}

        {this.RenderStaticInput('Correction', this.parameter(params.CORRECTION))}
        {this.RenderStaticInput('Diacritization', this.parameter(params.DIACRITIC))}
        {this.RenderStaticInput('Use beta', this.parameter(params.BETA), true)}
        {this.RenderStaticInput('Analysis tasks', this.renderStaticTasks())}
        {this.renderOutput()}
        {this.renderAdvancedSettings()}
      </div>
    );
  },

  showResultsHelpModal() {
    this.updateLocalState(['showResultsHelpModal'], true);
  },

  renderResultsHelpModal() {
    return (
      <ResultsHelpModal
        show={this.state.localState.get('showResultsHelpModal', false)}
        onClose={() => this.updateLocalState(['showResultsHelpModal'], false)}
      />
    );
  },

  renderOutput() {
    const bucketId = getDefaultBucket(STAGE.OUT, componentId, this.state.configId);

    return (
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Analysis results
          <br />
          <Button bsStyle="link" className="btn-link-inline" onClick={this.showResultsHelpModal}>
            <FontAwesomeIcon icon="circle-question" /> Show explanation
          </Button>
        </Col>
        <Col sm={9}>
          <ul className="nav nav-stacked">
            <li>
              <SapiTableLinkEx tableId={`${bucketId}.analysis-result-documents`} />
            </li>
            <li>
              <SapiTableLinkEx tableId={`${bucketId}.analysis-result-entities`} />
            </li>
            <li>
              <SapiTableLinkEx tableId={`${bucketId}.analysis-result-relations`} />
            </li>
          </ul>
        </Col>
      </FormGroup>
    );
  },

  renderStaticTasks() {
    const tasks = this.parameter(params.ANALYSIS, List());
    let renderedTasks = tasks
      .map((task, idx) => {
        const info = analysisTypes[task];
        return (
          <Tooltip key={info.name} tooltip={info.description} placement="top" type="explanatory">
            <span>
              {idx === 0 ? '' : ', '}
              {info.name}
            </span>
          </Tooltip>
        );
      })
      .toArray();
    return <span>{renderedTasks}</span>;
  },

  renderIntableStatic() {
    if (this.state.configData.hasIn(['storage', 'input', 'tables', 0, 'source_search'])) {
      return this.renderSourceSearch();
    }

    const link = (
      <p className="form-control-static">
        <SapiTableLinkEx tableId={this.state.intable || ''} />
      </p>
    );
    return this.renderFormElement(<span>Input Table</span>, link);
  },

  renderSourceSearch() {
    return (
      <div className="form-group">
        <label className="control-label col-sm-3">Input Table</label>
        <div className="col-sm-9">
          <SourceSearchInEditor
            inputSearch={this.state.configData.getIn([
              'storage',
              'input',
              'tables',
              0,
              'source_search'
            ])}
          />
        </div>
      </div>
    );
  },

  RenderStaticInput(label, value, isBetaCheckobx = false) {
    return (
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          {label}
        </Col>
        <Col sm={9}>
          <FormControl.Static>
            {isBetaCheckobx ? <Check isChecked={!!value} /> : value || 'N/A'}
          </FormControl.Static>
        </Col>
      </FormGroup>
    );
  },

  getColumns() {
    const tableId = this.getEditingValue('intable');

    if (!tableId || !this.state.allTables) {
      return [];
    }

    const table = this.state.allTables.find((ptable) => ptable.get('id') === tableId);

    if (!table) {
      return [];
    }

    return table
      .get('columns')
      .map((column) => {
        return {
          key: column,
          label: column,
          value: column
        };
      })
      .toList()
      .toJS();
  },

  updateEditingValue(prop, value) {
    updateEditingValue(this.state.configId, prop, value);
  },

  getEditingValue(prop) {
    return getEditingValue(this.state.configId, prop) || '';
  },

  updateLocalState(path, data) {
    updateLocalState(this.state.configId, path, data);
  }
});

export default Index;
