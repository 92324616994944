import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '../../../constants/componentIds';
import keyCodes from '../../../constants/keyCodes';
import Checkbox from '../../../react/common/Checkbox';
import ConfirmModal from '../../../react/common/ConfirmModal';
import CreatedDate from '../../../react/common/CreatedDate';
import FileSize from '../../../react/common/FileSize';
import FilterPanel from '../../../react/common/FilterPanel';
import InlineDescriptionEditInput from '../../../react/common/InlineDescriptionEditInput';
import Loader from '../../../react/common/Loader';
import MarkedText from '../../../react/common/MarkedText';
import NoResultsFound from '../../../react/common/NoResultsFound';
import Link from '../../../react/common/RouterLink';
import RowActionDropdown from '../../../react/common/RowActionDropdown';
import RowActionMenuItem from '../../../react/common/RowActionMenuItem';
import RowsCount from '../../../react/common/RowsCount';
import SortIcon from '../../../react/common/SortIcon';
import TableUpdatedByComponentInfo from '../../../react/common/TableUpdatedByComponentInfo';
import Tooltip from '../../../react/common/Tooltip';
import Truncated from '../../../react/common/Truncated';
import RoutesStore from '../../../stores/RoutesStore';
import hasSelections from '../../../utils/hasSelections';
import onClickSelectionCell from '../../../utils/onClickSelectionCell';
import string from '../../../utils/string';
import { simulateClickIfMiddleMouseIsUsed } from '../../../utils/windowOpen';
import MetadataActionCreators from '../../components/MetadataActionCreators';
import { MetadataKeys, ObjectTypes } from '../../components/MetadataConstants';
import SandboxesActions from '../../sandboxes/Actions';
import AddSandboxModal, { FORM_STEPS } from '../../sandboxes/components/AddSandboxModal';
import { updateExistingWorkspace } from '../../sandboxes/helpers';
import {
  deleteMultipleTables,
  deleteTable,
  exportTables,
  truncateMultipleTables,
  truncateTable
} from '../actions';
import { routeNames, tableModalTabs } from '../constants';
import {
  getDescriptionValue,
  getFilteredData,
  getTableAliases,
  getTableLinks,
  prepareMappingFromSelectedBucketsAndTables,
  sortByDisplayName
} from '../helpers';
import { SEARCH_TYPES } from './DataSample';
import DeleteMultipleTablesModal from './DeleteMultipleTablesModal';
import DeleteTableModal from './DeleteTableModal';
import DescriptionButton from './DescriptionButton';
import ExportModal from './ExportModal';
import NativeTypesLabel from './NativeTypesLabel';
import TruncateTableModal from './TruncateTableModal';

const tableRowCountGetter = (table) => table.get('rowsCount');
const tableDataSizeGetter = (table) => table.get('dataSizeBytes');
const tableLastChangeGetter = (table) => {
  return new Date(table.get('lastChangeDate') ?? table.get('created')).valueOf();
};

class TablesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTables: Map(),
      selectedTable: Map(),
      activeActionModal: null,
      activeMultiActionModal: null,
      sortValueGetter: sortByDisplayName,
      sortDirection: -1,
      searchQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], '')
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDeleteTables = this.handleDeleteTables.bind(this);
    this.handleTruncateTables = this.handleTruncateTables.bind(this);
  }

  render() {
    const isFilterActive = this.state.searchQuery.length > 1;
    const filteredData = getFilteredData(
      List([this.props.bucket.set('bucketTables', this.props.tables)]),
      isFilterActive ? this.state.searchQuery : '',
      null,
      ['bucket']
    ).getIn([0, 'bucketTables'], List());

    return (
      <>
        <FilterPanel
          placeholder={this.getPlaceholder}
          query={this.state.searchQuery}
          onChange={(searchQuery) => {
            this.setState({ searchQuery });
            RoutesStore.getRouter().updateQuery({ q: searchQuery });
          }}
        />
        {isFilterActive && filteredData.isEmpty() ? (
          <NoResultsFound entityName="tables or columns" />
        ) : (
          this.renderTable(filteredData, isFilterActive)
        )}
      </>
    );
  }

  renderTable(filteredData, isFilterActive) {
    const { selectedTables, activeMultiActionModal } = this.state;
    const {
      sapiToken,
      hasFlows,
      bucket,
      components,
      configurations,
      canWriteBucket,
      urlTemplates,
      deletingTables,
      truncatingTables,
      exportingTables
    } = this.props;
    const matchedTables = filteredData.filter((table) => table.get('matches'));
    const selectedTablesCount = selectedTables.count((isTableSelected) => isTableSelected);
    const isAllSelected = !!selectedTablesCount && selectedTablesCount === matchedTables.count();
    const isSomeSelected = !!selectedTablesCount && selectedTablesCount !== matchedTables.count();

    return (
      <div className="box">
        <Table hover>
          <thead>
            <tr className="with-action-buttons is-sticky bg-color-white">
              {canWriteBucket ? (
                <>
                  <th className="w-52 pr-0" onClick={onClickSelectionCell}>
                    <Checkbox
                      tooltip={`${
                        isAllSelected || isSomeSelected ? 'Deselect' : 'Select'
                      } all tables`}
                      checked={isAllSelected}
                      disabled={matchedTables.isEmpty()}
                      indeterminate={!!selectedTablesCount && !isAllSelected}
                      onChange={(checked) => {
                        this.setState(() => ({
                          selectedTables: !checked
                            ? Map()
                            : matchedTables
                                .map((table) => [table.get('id'), true])
                                .fromEntrySeq()
                                .toMap()
                        }));
                      }}
                    />
                  </th>
                  <th>
                    <div className="flex-container flex-start">
                      {!selectedTablesCount ? (
                        isFilterActive ? (
                          'Name'
                        ) : (
                          this.renderSortHeader('Table Name', sortByDisplayName)
                        )
                      ) : (
                        <strong>
                          {selectedTablesCount} {string.pluralize(selectedTablesCount, 'table')}{' '}
                          selected
                        </strong>
                      )}
                      {selectedTablesCount > 0 && this.renderMutliActionsButtons()}
                    </div>
                  </th>
                </>
              ) : (
                <th>
                  {isFilterActive ? 'Name' : this.renderSortHeader('Table Name', sortByDisplayName)}
                </th>
              )}
              {!isFilterActive && (
                <>
                  <th className="w-150 text-right">
                    {this.renderSortHeader('Row Count', tableRowCountGetter)}
                  </th>
                  <th className="w-150 text-right">
                    {this.renderSortHeader('Data Size', tableDataSizeGetter)}
                  </th>
                  <th className="w-200 text-right">Recently Updated By</th>
                  <th className="w-200 text-right">
                    {this.renderSortHeader('Last Change', tableLastChangeGetter)}
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData.isEmpty() && (
              <tr className="no-hover">
                <td colSpan={6}>No tables created yet.</td>
              </tr>
            )}
            {filteredData
              .sort((a, b) => {
                const sorterFn = isFilterActive ? sortByDisplayName : this.state.sortValueGetter;
                const valueA = sorterFn(a);
                const valueB = sorterFn(b);
                if (valueA < valueB) return 1 * this.state.sortDirection;
                if (valueA > valueB) return -1 * this.state.sortDirection;
                return 0;
              })
              .map((table) => {
                const isDeleting = deletingTables.get(table.get('id'), false);
                const isTruncating = truncatingTables.get(table.get('id'), false);
                const isExporting = exportingTables.get(table.get('id'), false);
                const isAllSelected =
                  !!selectedTablesCount && selectedTablesCount === matchedTables.count();
                const isSomeSelected =
                  !!selectedTablesCount && selectedTablesCount !== matchedTables.count();
                const tooltipDescription = `${
                  isAllSelected || isSomeSelected ? 'Deselect' : 'Select'
                } table`;

                return (
                  <React.Fragment key={table.get('id')}>
                    {table.get('matches') && (
                      <tr
                        key={table.get('id')}
                        onClick={() => {
                          if (hasSelections()) {
                            return;
                          }

                          this.redirectToTable(table);
                        }}
                        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                        onKeyDown={(event) => {
                          if (event.key === keyCodes.ENTER) {
                            this.redirectToTable(table);
                          }
                        }}
                        className="clickable hoverable-actions-with-replacement"
                        role="button"
                        tabIndex="0"
                      >
                        {canWriteBucket && (
                          <td className="w-52 pr-0" onClick={onClickSelectionCell}>
                            <Checkbox
                              tooltip={tooltipDescription}
                              disabled={isDeleting || isTruncating || isExporting}
                              checked={!!selectedTables.get(table.get('id'))}
                              onChange={(checked) => {
                                this.setState(({ selectedTables }) => ({
                                  selectedTables: selectedTables.set(table.get('id'), checked)
                                }));
                              }}
                            />
                          </td>
                        )}
                        <td>
                          <span className="flex-container flex-start overflow-break-anywhere">
                            <FontAwesomeIcon
                              icon="table"
                              className="icon-addon-right text-muted f-18"
                            />
                            <div>
                              <div className="flex-container flex-start">
                                {this.renderTableLink(table, isFilterActive)}
                                <NativeTypesLabel
                                  isTyped={table.get('isTyped', false)}
                                  className="ml-1"
                                />
                              </div>
                              {!isFilterActive && (
                                <div className="f-13 text-muted">
                                  <InlineDescriptionEditInput
                                    entity={ObjectTypes.TABLE}
                                    description={getDescriptionValue(table.get('metadata'))}
                                    onSave={(newDescription) => {
                                      return MetadataActionCreators.saveMetadata(
                                        ObjectTypes.TABLE,
                                        table.get('id'),
                                        MetadataKeys.DESCRIPTION,
                                        newDescription.trim()
                                      );
                                    }}
                                    readOnly={!canWriteBucket}
                                  />
                                </div>
                              )}
                            </div>
                          </span>
                        </td>
                        {!isFilterActive && (
                          <>
                            <td className="text-right">
                              <RowsCount count={table.get('rowsCount')} />
                            </td>
                            <td className="text-right">
                              <FileSize size={table.get('dataSizeBytes')} />
                            </td>
                            <td className="text-right">
                              <TableUpdatedByComponentInfo
                                table={table}
                                components={components}
                                configurations={configurations}
                                hasFlows={hasFlows}
                              />
                            </td>
                            <td>
                              {!this.props.canWriteBucket && !this.props.canExportTable ? (
                                <CreatedDate
                                  createdTime={table.get('lastChangeDate') || table.get('created')}
                                />
                              ) : (
                                <div
                                  className={classnames('actions-container', {
                                    'force-actions': isDeleting || isTruncating || isExporting
                                  })}
                                >
                                  <div className="not-actions">
                                    <CreatedDate
                                      createdTime={
                                        table.get('lastChangeDate') || table.get('created')
                                      }
                                    />
                                  </div>
                                  <div className="actions">
                                    {this.renderTableActions(
                                      table,
                                      isDeleting,
                                      isTruncating,
                                      isExporting
                                    )}
                                  </div>
                                </div>
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    )}
                    {isFilterActive &&
                      table
                        .get('columns', List())
                        .sortBy((columnName) => {
                          if (columnName === this.props.query) {
                            return -1;
                          }

                          return columnName.toLowerCase();
                        })
                        .map((columnName) => (
                          <tr
                            key={columnName}
                            onClick={() => {
                              if (hasSelections()) {
                                return;
                              }

                              this.redirectToTable(table, columnName);
                            }}
                            onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                            onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                            onKeyDown={(event) => {
                              if (event.key === keyCodes.ENTER) {
                                this.redirectToTable(table, columnName);
                              }
                            }}
                            className="clickable"
                            role="button"
                            tabIndex="0"
                          >
                            {canWriteBucket && (
                              <td className="w-52 pr-0">
                                <Checkbox disabled />
                              </td>
                            )}
                            <td className="flex-container flex-start">
                              <FontAwesomeIcon
                                icon="table-columns"
                                className="icon-addon-right text-muted f-18"
                              />
                              <span className="text-muted">{table.get('displayName')}</span>
                              <FontAwesomeIcon
                                icon={['far', 'angle-right']}
                                className="text-muted"
                                fixedWidth
                              />
                              {this.renderTableLink(table, isFilterActive, columnName)}
                              <NativeTypesLabel
                                isTyped={table.get('isTyped', false)}
                                className="ml-1"
                              />
                            </td>
                          </tr>
                        ))
                        .toArray()}
                  </React.Fragment>
                );
              })
              .toArray()}
          </tbody>
        </Table>
        <DeleteMultipleTablesModal
          bucket={bucket}
          tables={matchedTables.filter((table) => selectedTables.get(table.get('id')))}
          allTables={this.props.allTables}
          show={activeMultiActionModal === tableModalTabs.TABLE}
          sapiToken={sapiToken}
          urlTemplates={urlTemplates}
          deleting={!deletingTables.isEmpty()}
          onConfirm={this.handleDeleteTables}
          onHide={this.handleCloseModal}
        />
        <ConfirmModal
          closeAfterResolve
          show={activeMultiActionModal === tableModalTabs.TRUNCATE}
          icon="xmark"
          title="Truncate table"
          text="Are you sure you want to truncate selected tables?"
          buttonLabel={!truncatingTables.isEmpty() ? 'Truncating...' : 'Truncate'}
          buttonType="danger"
          onConfirm={this.handleTruncateTables}
          onHide={this.handleCloseModal}
          isLoading={!truncatingTables.isEmpty()}
        />
        <DeleteTableModal
          show={this.state.activeActionModal === tableModalTabs.DELETE}
          table={this.state.selectedTable}
          sapiToken={this.props.sapiToken}
          urlTemplates={this.props.urlTemplates}
          tableAliases={getTableAliases(
            this.state.selectedTable,
            this.props.allTables,
            this.props.sapiToken
          )}
          tableLinks={getTableLinks(this.state.selectedTable, this.props.bucket)}
          deleting={this.props.deletingTables.get(this.state.selectedTable.get('id'), false)}
          onConfirm={() =>
            deleteTable(this.props.bucket.get('id'), this.state.selectedTable.get('id'))
          }
          onHide={this.handleCloseModal}
        />
        <TruncateTableModal
          show={this.state.activeActionModal === tableModalTabs.TRUNCATE}
          table={this.state.selectedTable}
          onConfirm={() => truncateTable(this.state.selectedTable.get('id'))}
          onHide={this.handleCloseModal}
        />
        <ExportModal
          show={
            this.state.activeActionModal === tableModalTabs.EXPORT ||
            this.state.activeMultiActionModal === tableModalTabs.EXPORT
          }
          tables={
            this.state.activeActionModal === tableModalTabs.EXPORT
              ? List([this.state.selectedTable])
              : matchedTables.filter((table) => selectedTables.get(table.get('id')))
          }
          onSubmit={(type) =>
            exportTables(
              this.state.activeActionModal === tableModalTabs.EXPORT
                ? List([this.state.selectedTable])
                : matchedTables.filter((table) => selectedTables.get(table.get('id'))),
              type
            )
          }
          onHide={this.handleCloseModal}
        />
        <AddSandboxModal
          hasTableInputMapping
          show={['CREATE_WORKSPACE', 'USE_WORKSPACE'].includes(this.state.activeActionModal)}
          forceStep={
            this.state.activeActionModal === 'USE_WORKSPACE' ? FORM_STEPS.SANDBOX_UPDATE : null
          }
          onHide={this.handleCloseModal}
          sandboxComponent={this.props.components.get(KEBOOLA_SANDBOXES)}
          workspaces={this.props.sandboxes}
          allowedComponents={this.props.allowedTransformationComponents}
          availableDatabricksClusters={this.props.availableDatabricksClusters}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
          onUpdate={(workspace, preserve) => {
            const preparedConfig = fromJS({
              configuration: {
                parameters: { id: workspace.get('id') },
                storage: prepareMappingFromSelectedBucketsAndTables(
                  this.state.selectedTables.map((table, tableId) => this.props.tables.get(tableId)),
                  this.props.tables,
                  this.props.allowedTransformationComponents,
                  workspace.get('type')
                )
              }
            });

            return updateExistingWorkspace(
              preparedConfig,
              workspace,
              preserve,
              'Use mapping from storage tables'
            );
          }}
          onSubmit={(name, type, options, params, description) => {
            const storage = prepareMappingFromSelectedBucketsAndTables(
              this.state.selectedTables.map((table, tableId) => this.props.tables.get(tableId)),
              this.props.tables,
              this.props.allowedTransformationComponents,
              type
            );

            return SandboxesActions.createSandbox(
              { name, description, configuration: JSON.stringify({ storage }) },
              type,
              options,
              params.set('storage', storage)
            );
          }}
        />
      </div>
    );
  }

  renderTableLink(table, isFilterActive, columnName) {
    const className = classnames('link-inherit', {
      'dotted-underline': table.get('isAlias', false)
    });

    if (this.props.isBucketBrowser) {
      const location = RoutesStore.getRouterState().get('location', Map());

      return (
        <Link
          to="tablePreview"
          params={{ tableId: table.get('id') }}
          query={{
            context: location.getIn(['query', 'context']),
            ...(columnName && { q: columnName, qt: SEARCH_TYPES.KEY })
          }}
          hash={columnName ? '#data-sample' : location.get('hash')}
          state={location.get('state')}
          className={className}
        >
          {this.renderTableLinkBody(table, isFilterActive)}
        </Link>
      );
    }

    return (
      <Link
        to={routeNames.TABLE}
        params={{
          bucketId: table.getIn(['bucket', 'id']),
          tableName: table.get('name')
        }}
        className={className}
        {...(columnName && {
          query: { q: columnName, qt: SEARCH_TYPES.KEY },
          hash: '#data-sample'
        })}
      >
        {this.renderTableLinkBody(table, isFilterActive)}
      </Link>
    );
  }

  renderTableLinkBody(table, isFilterActive) {
    if (isFilterActive) {
      return <MarkedText source={table.get('displayName')} mark={this.state.searchQuery} />;
    }

    return <Truncated text={table.get('displayName')} />;
  }

  renderMutliActionsButtons() {
    const anySelectedIsAlias = this.state.selectedTables
      .keySeq()
      .some((tableId) => this.props.tables.get(tableId, Map()).get('isAlias'));

    return (
      <div className="table-action-buttons no-wrap">
        <Tooltip placement="top" tooltip="Delete Selected">
          <Button
            bsStyle="link"
            className="btn-link-inline btn-link-muted"
            onClick={() => this.setState({ activeMultiActionModal: tableModalTabs.TABLE })}
            disabled={!this.props.deletingTables.isEmpty()}
          >
            <FontAwesomeIcon icon="trash" fixedWidth />
          </Button>
        </Tooltip>
        {!anySelectedIsAlias && (
          <Tooltip placement="top" tooltip="Truncate Selected">
            <Button
              bsStyle="link"
              className="btn-link-inline btn-link-muted"
              onClick={() => this.setState({ activeMultiActionModal: tableModalTabs.TRUNCATE })}
              disabled={!this.props.truncatingTables.isEmpty()}
            >
              <FontAwesomeIcon icon="xmark" fixedWidth />
            </Button>
          </Tooltip>
        )}
        <Tooltip placement="top" tooltip="Export Selected">
          <Button
            bsStyle="link"
            className="btn-link-inline btn-link-muted"
            onClick={() => this.setState({ activeMultiActionModal: tableModalTabs.EXPORT })}
            disabled={!this.props.exportingTables.isEmpty()}
          >
            <FontAwesomeIcon icon="down-to-line" fixedWidth />
          </Button>
        </Tooltip>
        {!this.props.hasSnowflakePartnerConnectLimited && (
          <>
            <Tooltip placement="top" tooltip="Create new Workspace">
              <Button
                bsStyle="link"
                className="btn-link-inline btn-link-muted"
                onClick={() => this.setState({ activeActionModal: 'CREATE_WORKSPACE' })}
              >
                <div className="add-workspace-icon">
                  <FontAwesomeIcon icon="box" fixedWidth />
                  <FontAwesomeIcon icon="circle-plus" />
                </div>
              </Button>
            </Tooltip>
            <Tooltip placement="top" tooltip="Copy to existing Workspace">
              <Button
                bsStyle="link"
                className="btn-link-inline btn-link-muted"
                onClick={() => this.setState({ activeActionModal: 'USE_WORKSPACE' })}
              >
                <div className="add-workspace-icon">
                  <FontAwesomeIcon icon="box" fixedWidth />
                  <FontAwesomeIcon icon="circle-arrow-right" />
                </div>
              </Button>
            </Tooltip>
          </>
        )}
      </div>
    );
  }

  renderSortHeader(label, sorter) {
    const isSorted = this.state.sortValueGetter === sorter;

    return (
      <span
        className="clickable"
        title={`Sort by ${label.toLowerCase()}`}
        onClick={() => {
          this.setState({
            sortValueGetter: sorter,
            sortDirection: isSorted ? this.state.sortDirection * -1 : -1
          });
        }}
      >
        <SortIcon isSorted={isSorted} isSortedDesc={this.state.sortDirection === -1} />
        {label}
      </span>
    );
  }

  renderTableActions(table, isDeleting, isTruncating, isExporting) {
    if (isDeleting) {
      return (
        <span className="text-muted">
          <Loader className="btn-icon" />
          Deleting table
        </span>
      );
    }

    if (isTruncating) {
      return (
        <span className="text-muted">
          <Loader className="btn-icon" />
          Truncating table
        </span>
      );
    }

    if (isExporting) {
      return (
        <span className="text-muted">
          <Loader className="btn-icon" />
          Preparing export
        </span>
      );
    }

    return (
      <RowActionDropdown>
        {this.props.canExportTable && (
          <RowActionMenuItem
            onSelect={() => {
              this.setState({ selectedTable: table, activeActionModal: tableModalTabs.EXPORT });
            }}
          >
            <FontAwesomeIcon fixedWidth icon="down-to-line" />
            Export table
          </RowActionMenuItem>
        )}
        <DescriptionButton
          data={table}
          entity={ObjectTypes.TABLE}
          readOnly={!this.props.canWriteBucket}
        />
        {this.props.canWriteBucket && (
          <>
            <RowActionMenuItem divider />
            {!table.get('isAlias') && (
              <RowActionMenuItem
                onSelect={() =>
                  this.setState({
                    selectedTable: table,
                    activeActionModal: tableModalTabs.TRUNCATE
                  })
                }
              >
                <FontAwesomeIcon icon="xmark" fixedWidth />
                Truncate table
              </RowActionMenuItem>
            )}
            <RowActionMenuItem
              onSelect={() =>
                this.setState({ selectedTable: table, activeActionModal: tableModalTabs.DELETE })
              }
            >
              <FontAwesomeIcon icon="trash" fixedWidth />
              Delete table
            </RowActionMenuItem>
          </>
        )}
      </RowActionDropdown>
    );
  }

  redirectToTable = (table, columName) => {
    if (this.props.isBucketBrowser) {
      const location = RoutesStore.getRouterState().get('location', Map());

      return RoutesStore.getRouter().transitionTo(
        'tablePreview',
        { tableId: table.get('id') },
        {
          context: location.getIn(['query', 'context']),
          ...(columName && { q: columName, qt: SEARCH_TYPES.KEY })
        },
        columName ? '#data-sample' : location.get('hash'),
        location.get('state')
      );
    }

    if (columName) {
      return RoutesStore.getRouter().transitionTo(
        routeNames.TABLE,
        {
          bucketId: table.getIn(['bucket', 'id']),
          tableName: table.get('name')
        },
        { q: columName, qt: SEARCH_TYPES.KEY },
        '#data-sample'
      );
    }

    return RoutesStore.getRouter().transitionTo(routeNames.TABLE, {
      bucketId: table.getIn(['bucket', 'id']),
      tableName: table.get('name')
    });
  };

  handleDeleteTables = () => {
    const selectedTables = this.state.selectedTables.filter(Boolean).keySeq();

    return deleteMultipleTables(selectedTables).then(() =>
      this.setState({ selectedTables: Map() })
    );
  };

  handleTruncateTables = () => {
    const selectedTables = this.state.selectedTables.filter(Boolean).keySeq();

    return truncateMultipleTables(selectedTables).then(() =>
      this.setState({ selectedTables: Map() })
    );
  };

  handleCloseModal = () => {
    this.setState({ activeActionModal: null, activeMultiActionModal: null, selectedTable: Map() });
  };

  getPlaceholder = () => {
    return `Search tables (${this.props.tables.count()}) and columns (${this.props.tables
      .map((table) => table.get('columns'))
      .flatten(1)
      .count()})`;
  };
}

TablesList.propTypes = {
  canExportTable: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  allowedTransformationComponents: PropTypes.instanceOf(Map).isRequired,
  sandboxes: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  allTables: PropTypes.instanceOf(Map).isRequired,
  components: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  urlTemplates: PropTypes.instanceOf(Map).isRequired,
  deletingTables: PropTypes.instanceOf(Map).isRequired,
  truncatingTables: PropTypes.instanceOf(Map).isRequired,
  exportingTables: PropTypes.instanceOf(Map).isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  canWriteBucket: PropTypes.bool.isRequired,
  availableDatabricksClusters: PropTypes.instanceOf(List),
  hasSnowflakePartnerConnectLimited: PropTypes.bool.isRequired,
  isBucketBrowser: PropTypes.bool
};

export default TablesList;
