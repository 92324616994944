import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import InputOutputTypeIcon from '../../../../../react/common/InputOutputTypeIcon';
import Loader from '../../../../../react/common/Loader';
import MultiActionsSelectCheckbox from '../../../../../react/common/MultiActionsSelectCheckbox';
import Tooltip from '../../../../../react/common/Tooltip';
import { canPullTable } from '../../../../admin/privileges';
import StorageApiTableLinkEx from '../../../../components/react/components/StorageApiTableLinkEx';
import DevBranchesStore from '../../../../dev-branches/DevBranchesStore';
import { pullTable } from '../../../../storage/actions';
import { ioType } from '../../../Constants';
import StorageTablesStore from '../../../stores/StorageTablesStore';
import TableOutputMappingModal from './TableOutputMappingModal';

const TableOutputMappingRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    componentId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    editingValue: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    mappingIndex: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    pendingActions: PropTypes.object.isRequired,
    onEditStart: PropTypes.func.isRequired,
    sourceType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    defaultBucketName: PropTypes.string.isRequired,
    defaultTableName: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelection: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      isPullingTable: false
    };
  },

  render() {
    return (
      <span className="tr hoverable-actions">
        {this.renderColumns()}
        {this.renderActionButtons()}
      </span>
    );
  },

  renderColumns() {
    const isDeleting = this.props.pendingActions.getIn(
      ['input', 'tables', this.props.mappingIndex, 'delete'],
      false
    );

    return (
      <>
        {!this.props.readOnly && (
          <span className="td pr-0">
            <MultiActionsSelectCheckbox
              isChecked={this.props.isSelected}
              isDisabled={isDeleting}
              onToggle={this.props.toggleSelection}
              entity="mapping"
            />
          </span>
        )}
        <span className="td col-xs-5">{this.renderSource()}</span>
        <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
        <span className="td col-xs-5">{this.renderDestination()}</span>
      </>
    );
  },

  renderSource() {
    return (
      <>
        <InputOutputTypeIcon type={this.props.sourceType} />
        {this.props.sourceType === ioType.FILE && 'out/tables/'}
        {this.props.value.get('source')}
      </>
    );
  },

  renderActionButtons() {
    if (this.props.readOnly) {
      return <span className="td pl-0 pr-1 no-wrap" />;
    }

    const isDeleting = this.props.pendingActions.getIn(
      ['output', 'tables', this.props.mappingIndex, 'delete'],
      false
    );

    return (
      <span className="td pl-0 pr-1 no-wrap">
        {this.shouldShowCloneButton() && (
          <Tooltip placement="top" tooltip="Clone to Current Branch">
            <Button
              bsStyle="link"
              className="text-muted"
              onClick={() => {
                this.setState({ isPullingTable: true });

                pullTable(this.props.value.get('destination')).finally(() =>
                  this.setState({ isPullingTable: false })
                );
              }}
              disabled={this.state.isPullingTable}
            >
              {this.state.isPullingTable ? <Loader /> : <FontAwesomeIcon icon="clone" fixedWidth />}
            </Button>
          </Tooltip>
        )}
        <TableOutputMappingModal
          mode="edit"
          tables={this.props.tables}
          buckets={this.props.buckets}
          mapping={this.props.editingValue}
          onChange={this.props.onChange}
          onCancel={this.props.onCancel}
          onSave={this.props.onSave}
          onEditStart={this.props.onEditStart}
          sourceType={this.props.sourceType}
          componentId={this.props.componentId}
          defaultBucketName={this.props.defaultBucketName}
          defaultTableName={this.props.defaultTableName}
        />
        {this.props.value.get('destination') !== '' && (
          <Tooltip placement="top" tooltip="Delete Output">
            <Button
              bsStyle="link"
              className="text-muted"
              onClick={this.props.onDelete}
              disabled={isDeleting}
            >
              {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
            </Button>
          </Tooltip>
        )}
      </span>
    );
  },

  renderDestination() {
    if (!this.props.value.get('destination')) {
      return 'Not set';
    }

    return (
      <div className="flex-container flex-start">
        <StorageApiTableLinkEx tableId={this.props.value.get('destination')} />
        {this.shouldShowCloneButton() && (
          <Tooltip
            placement="top"
            type="explanatory"
            tooltip="Consider cloning this table from production to current branch to sustain data consistency."
          >
            <FontAwesomeIcon
              icon="triangle-exclamation"
              className="icon-addon-left f-16 text-warning"
            />
          </Tooltip>
        )}
      </div>
    );
  },

  renderRightArrow() {
    const isIncremental = this.props.value.get('incremental');

    if (!isIncremental) {
      return <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />;
    }

    return (
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={
          <div>
            <div>Incremental</div>
            {this.props.value.get('delete_where_column') &&
              this.props.value.get('delete_where_values') && <div>Delete rows</div>}
          </div>
        }
      >
        <FontAwesomeIcon icon={['far', 'chevrons-right']} className="text-muted" />
      </Tooltip>
    );
  },

  shouldShowCloneButton() {
    return (
      canPullTable() &&
      this.props.value.get('incremental') &&
      StorageTablesStore.getTable(this.props.value.get('destination'), Map()).getIn([
        'bucket',
        'idBranch'
      ]) === DevBranchesStore.getDefaultBranchId()
    );
  }
});

export default TableOutputMappingRow;
