import React from 'react';
import { Alert, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

const NameControl = (props: {
  value: string;
  onChange: (newValue: string) => void;
  error?: string;
  warning?: string;
}) => {
  return (
    <>
      {props.error && <Alert bsStyle="danger">{props.error}</Alert>}
      <FormGroup validationState={props.warning ? 'error' : null}>
        <ControlLabel>Name</ControlLabel>
        <FormControl
          autoFocus
          type="text"
          placeholder="Type a name"
          value={props.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
        />
        {!!props.warning && <HelpBlock className="text-danger">{props.warning}</HelpBlock>}
      </FormGroup>
    </>
  );
};

export default NameControl;
