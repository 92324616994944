import React from 'react';
import type { List, Map } from 'immutable';

import {
  FEATURE_ALLOW_AI,
  FEATURE_ALLOW_DESCRIPTION_AI,
  FEATURE_HAS_FLOWS_ONLY,
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_SANDBOXES_DATA_APP,
  FEATURE_SNOWPARK_PYTHON,
  FEATURE_USE_AZURE_AI
} from '../../../constants/features';
import FeatureList from '../../../react/common/FeatureList';
import ApplicationStore from '../../../stores/ApplicationStore';
import StackFeaturesStore from '../../stack-features/Store';
import { addProjectFeature, removeProjectFeature } from '../actions';
import { FEATURE_TYPES } from '../constants';

const isApprovedFeature = (feature: Map<string, any>) => {
  const name = feature.get('name');

  if (
    // No features for legacy queue
    !ApplicationStore.hasNewQueue() ||
    // Some features require a read only storage
    ([FEATURE_SNOWPARK_PYTHON].includes(name) && !ApplicationStore.hasReadOnlyStorage()) ||
    // AI features are not available for single tenants
    ([FEATURE_ALLOW_AI, FEATURE_ALLOW_DESCRIPTION_AI, FEATURE_USE_AZURE_AI].includes(name) &&
      StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT))
  ) {
    return false;
  }

  return [
    FEATURE_ALLOW_AI,
    FEATURE_ALLOW_DESCRIPTION_AI,
    FEATURE_USE_AZURE_AI,
    FEATURE_HAS_FLOWS_ONLY,
    FEATURE_SNOWPARK_PYTHON,
    FEATURE_SANDBOXES_DATA_APP
  ].includes(name);
};

const EditableFeatures = (props: {
  readOnly: boolean;
  activatedFeatures: List<string>;
  availableFeatures: List<any>;
}) => {
  const availableFeatures = props.availableFeatures
    .filter((feature) => feature.get('type') === FEATURE_TYPES.PROJECT)
    .filter(isApprovedFeature) as List<any>;

  if (availableFeatures.isEmpty()) {
    return null;
  }

  return (
    <div className="well pp-6">
      <h3 className="tw-mt-0 tw-mb-6">Features</h3>
      <FeatureList
        readOnly={props.readOnly}
        availableFeatures={availableFeatures}
        activatedFeatures={props.activatedFeatures}
        onAddFeature={addProjectFeature}
        onRemoveFeature={removeProjectFeature}
      />
    </div>
  );
};

export default EditableFeatures;
