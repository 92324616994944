import React from 'react';

import InfoAlert from '../../../../../react/common/InfoAlert';
import ReadOnlyDocumentationLink from '../../../../sandboxes/components/ReadOnlyDocumentationLink';

const TableInputMappingReadOnlyInfo = () => {
  return (
    <InfoAlert header="Table Input Mapping" className="box-separator">
      All tables are already included in your transformations - no manual addition required. You
      just need to reference them in the code. <ReadOnlyDocumentationLink />
    </InfoAlert>
  );
};

export default TableInputMappingReadOnlyInfo;
