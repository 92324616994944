import React from 'react';
import { Table } from 'react-bootstrap';
import { useExpanded, useSortBy, useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { List, type Map } from 'immutable';

import Checkbox from '../../../../react/common/Checkbox';
import Loader from '../../../../react/common/Loader';
import MarkedText from '../../../../react/common/MarkedText';
import SortIcon from '../../../../react/common/SortIcon';
import Truncated from '../../../../react/common/Truncated';
import hasSelections from '../../../../utils/hasSelections';

export type SelectedSheets = { file: Map<string, any>; sheet: Map<string, any> }[];

const SheetsSelector = ({
  isLoading,
  files,
  searchQuery,
  savedSheets,
  selectSheets
}: {
  isLoading: boolean;
  files: List<any>;
  searchQuery: string;
  savedSheets: Map<string, any>;
  selectSheets: (sheets: SelectedSheets, selected: boolean) => void;
}) => {
  const isSaved = React.useCallback(
    (row: any) => {
      return savedSheets.some((sheet) => {
        return (
          sheet.get('fileId') === row.values.data.file.get('id') &&
          sheet.get('sheetId') === row.values.data.sheet.get('sheetId')
        );
      });
    },
    [savedSheets]
  );

  const columns = React.useMemo(() => {
    return [
      {
        accessor: 'data',
        sortType: (rowA: any, rowB: any) => {
          const [entity, key] = rowA.depth === 0 ? ['file', 'name'] : ['sheet', 'sheetTitle'];

          return rowA.values.data[entity]
            .get(key)
            .toLowerCase()
            .localeCompare(rowB.values.data[entity].get(key).toLowerCase());
        },
        Header: ({ rows }: any) => {
          const isSelectable = rows.some((row: any) =>
            row.subRows.some((subRow: any) => !isSaved(subRow))
          );
          const isAllSelected =
            isSelectable &&
            rows.every((row: any) => {
              return row.subRows.every((subRow: any) => {
                return subRow.values.data.sheet.get('selected', false) || isSaved(subRow);
              });
            });
          const isSomeSelected =
            !isAllSelected &&
            isSelectable &&
            rows.some((row: any) => {
              return row.subRows.some((subRow: any) => {
                return subRow.values.data.sheet.get('selected', false);
              });
            });

          return (
            <>
              {isSelectable && (
                <Checkbox
                  className="tw-mr-3"
                  checked={isAllSelected}
                  indeterminate={isSomeSelected}
                  onChange={() => {
                    selectSheets(
                      rows
                        .map((row: any) => {
                          return row.subRows
                            .filter((subRow: any) => !isSaved(subRow))
                            .map((subRow: any) => subRow.values.data);
                        })
                        .flat(),
                      isSomeSelected || isAllSelected ? false : true
                    );
                  }}
                />
              )}
              Sheets
            </>
          );
        },
        Cell: ({ row }: any) => {
          if (row.depth === 1) {
            const isSheetAdded = isSaved(row);

            return (
              <div className="flex-container flex-start tw-ml-4">
                {isSheetAdded ? (
                  <Checkbox disabled tooltip="Sheet is already added" />
                ) : (
                  <Checkbox
                    checked={row.values.data.sheet.get('selected', false)}
                    onChange={(checked) => selectSheets([row.values.data], checked)}
                  />
                )}
                <FontAwesomeIcon icon="table-cells" className="text-muted tw-mr-3 tw-ml-3" />
                <Truncated
                  text={
                    <MarkedText
                      source={row.values.data.sheet.get('sheetTitle')}
                      mark={searchQuery}
                    />
                  }
                />
                {isSheetAdded && (
                  <span className="tw-ml-auto tw-text-xs tw-font-medium tw-text-neutral-400">
                    Added
                  </span>
                )}
              </div>
            );
          }

          const isSelectable = row.subRows.some((subRow: any) => !isSaved(subRow));
          const isAllSelected =
            isSelectable &&
            row.subRows.every((subRow: any) => {
              return subRow.values.data.sheet.get('selected', false) || isSaved(subRow);
            });
          const isSomeSelected =
            !isAllSelected &&
            isSelectable &&
            row.subRows.some((subRow: any) => {
              return subRow.values.data.sheet.get('selected', false);
            });

          return (
            <div className="flex-container flex-start">
              {!isSelectable ? (
                <Checkbox disabled tooltip="All sheets already added" />
              ) : (
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={isSomeSelected}
                  onChange={() => {
                    selectSheets(
                      row.subRows
                        .filter((subRow: any) => !isSaved(subRow))
                        .map((subRow: any) => subRow.values.data),
                      isSomeSelected || isAllSelected ? false : true
                    );
                  }}
                />
              )}
              <FontAwesomeIcon icon="table" className="text-muted tw-mr-3 tw-ml-3" />
              <Truncated
                text={<MarkedText source={row.values.data.file.get('name')} mark={searchQuery} />}
              />
            </div>
          );
        }
      }
    ];
  }, [selectSheets, searchQuery, isSaved]);

  const data = React.useMemo(() => {
    return files.map((file) => {
      return {
        data: { file },
        subRows: file
          .get('sheets', List())
          .map((sheet: any) => ({ data: { file, sheet } }))
          .toArray()
      };
    });
  }, [files]);

  const initialState = React.useMemo(() => {
    let expanded = {};

    if (!!searchQuery) {
      expanded = files.toArray().reduce((acc, file, index) => ({ ...acc, [index]: true }), {});
    }

    return { sortBy: [{ id: 'data', desc: false }], expanded };
  }, [searchQuery, files]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
      disableSortRemove: true,
      autoResetExpanded: !!searchQuery
    } as any,
    useSortBy,
    useExpanded
  );

  return (
    <Table hover {...tableInstance.getTableProps({ className: 'react-table stretch-modal' })}>
      <thead>
        {tableInstance.headerGroups.map((headerGroup) => {
          const { key, ...headerProps } = headerGroup.getHeaderGroupProps();

          return (
            <tr key={key} {...headerProps}>
              {headerGroup.headers.map((column: any) => {
                const sortByProps = column.getSortByToggleProps({ title: '' });
                const { key, ...columnProps } = column.getHeaderProps(sortByProps);

                return (
                  <th key={key} {...columnProps}>
                    {column.render('Header')}
                    <SortIcon
                      className="icon-addon-left"
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                    />
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...tableInstance.getTableBodyProps()}>
        {!tableInstance.rows.length && (
          <tr className="no-hover">
            {tableInstance.columns.map((column, index) => {
              return (
                <td key={index}>
                  {index === 0 ? (
                    isLoading ? (
                      <>
                        <Loader className="icon-addon-right" />
                        Loading files sheets...
                      </>
                    ) : (
                      'No files selected'
                    )
                  ) : (
                    ''
                  )}
                </td>
              );
            })}
          </tr>
        )}
        {tableInstance.rows
          .map((row: any) => {
            tableInstance.prepareRow(row);
            return row;
          })
          .map((row: any) => {
            const isSheetAdded = row.values.data.sheet && isSaved(row);

            const { key, ...rowProps } = row.getRowProps({
              className: classNames({ clickable: !isSheetAdded }),
              onClick: () => {
                if (isSheetAdded || hasSelections()) {
                  return;
                }

                return row.depth === 0
                  ? row.toggleRowExpanded()
                  : selectSheets([row.values.data], !row.values.data.sheet.get('selected'));
              }
            });

            return (
              <tr key={key} {...rowProps}>
                {row.cells.map((cell: any) => {
                  const { key, ...cellProps } = cell.getCellProps();

                  return (
                    <td key={key} {...cellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default SheetsSelector;
