import { fromJS, List, Map } from 'immutable';

import { ActionTypes as ApplicationActionTypes } from '../../constants/KbcConstants';
import dispatcher from '../../Dispatcher';
import StoreUtils, { initStore } from '../../utils/StoreUtils';
import { ActionTypes } from './constants';

let _store = initStore(
  'SettingsStore',
  Map({
    project: Map(),
    token: Map(),
    organizationsViaMaintainer: Map(),
    organizations: List(),
    users: List(),
    joinRequests: List(),
    invitations: List(),
    features: List()
  })
);

const SettingsStore = StoreUtils.createStore({
  getProject() {
    return _store.get('project', Map());
  },

  getToken() {
    return _store.getIn(['token'], Map());
  },

  getTokenString() {
    return _store.getIn(['token', 'token'], Map());
  },

  getUser() {
    return _store.getIn(['token', 'user'], Map());
  },

  getOrganizationsViaMaintainer() {
    return _store.get('organizationsViaMaintainer', Map()).toList();
  },

  getOrganizations() {
    return _store.get('organizations', List());
  },

  getUsers() {
    return _store.get('users', List());
  },

  getJoinRequests() {
    return _store.get('joinRequests', List());
  },

  getInvitations() {
    return _store.get('invitations', List());
  },

  getFeatures(type) {
    if (!type) return _store.get('features', List());

    return _store.get('features', List()).filter((feature) => {
      return feature.get('type') === type;
    });
  },

  isProjectLoaded() {
    return !_store.get('project', Map()).isEmpty();
  },

  isTokenLoaded() {
    return !_store.get('token', Map()).isEmpty();
  },

  areOrganizationsViaMaintainerLoaded() {
    return !_store.get('organizationsViaMaintainer', Map()).isEmpty();
  },

  areOrganizationsLoaded() {
    return !_store.get('organizations', List()).isEmpty();
  },

  areUsersLoaded() {
    return !_store.get('users', List()).isEmpty();
  }
});

dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case ActionTypes.LOAD_PROJECT_SUCCESS:
      _store = _store.set('project', fromJS(action.project));
      return SettingsStore.emitChange();

    case ApplicationActionTypes.APPLICATION_ADD_PROJECT_FEATURE:
      _store = _store.updateIn(['project', 'features'], List(), (features) => {
        return features.push(action.feature);
      });
      return SettingsStore.emitChange();

    case ApplicationActionTypes.APPLICATION_REMOVE_PROJECT_FEATURE:
      _store = _store.updateIn(['project', 'features'], List(), (features) => {
        return features.filter((feature) => feature !== action.feature);
      });
      return SettingsStore.emitChange();

    case ActionTypes.GENERATE_SESSION_TOKEN_SUCCESS:
      _store = _store.set('token', fromJS(action.token));
      return SettingsStore.emitChange();

    case ActionTypes.LOAD_MAINTAINER_ORGANIZATIONS_SUCCESS:
      _store = _store.mergeIn(
        ['organizationsViaMaintainer'],
        fromJS(action.organizations)
          .map((organization) => organization.set('maintainer', fromJS(action.maintainer)))
          .toMap()
          .mapKeys((key, val) => val.get('id'))
      );
      return SettingsStore.emitChange();

    case ActionTypes.LOAD_ORGANIZATIONS_SUCCESS:
      _store = _store.set('organizations', fromJS(action.organizations));
      return SettingsStore.emitChange();

    case ActionTypes.LOAD_USERS_SUCCESS:
      _store = _store
        .set('users', fromJS(action.data.users))
        .set('joinRequests', fromJS(action.data.joinRequests))
        .set('invitations', fromJS(action.data.invitations));
      return SettingsStore.emitChange();

    case ActionTypes.LOAD_FEATURES_SUCCESS:
      _store = _store.set(
        'features',
        fromJS(action.features).filter((feature) => feature.get('canBeManageByAdmin', false))
      );
      return SettingsStore.emitChange();

    default:
      break;
  }
});

export default SettingsStore;
