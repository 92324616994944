import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import Checkbox from '../../../../react/common/Checkbox';
import CredentialsBox from '../../../../react/common/CredentialsBox';
import ExternalLink from '../../../../react/common/ExternalLink';
import Loader from '../../../../react/common/Loader';

const RedshiftCredentials = createReactClass({
  propTypes: {
    credentials: PropTypes.object,
    isCreating: PropTypes.bool
  },

  getInitialState() {
    return { showDetails: false };
  },

  render() {
    if (this.props.isCreating) {
      return (
        <>
          <Loader className="icon-addon-right" />
          Creating sandbox
        </>
      );
    }

    if (this.props.credentials.get('id')) {
      return this._renderCredentials();
    }

    return 'Sandbox not running';
  },

  _renderCredentials() {
    const { credentials } = this.props;
    const jdbcRedshift = `jdbc:redshift://${credentials.get('hostname')}:5439/${credentials.get(
      'db'
    )}`;
    const jdbcPgSql = `jdbc:postgresql://${credentials.get('hostname')}:5439/${credentials.get(
      'db'
    )}`;

    let rows = Map({
      Host: credentials.get('hostname'),
      Port: '5439',
      User: credentials.get('user'),
      Password: {
        protected: true,
        text: credentials.get('password')
      },
      Database: credentials.get('db'),
      Schema: credentials.get('schema')
    });

    if (this.state.showDetails) {
      rows = rows.set('Redshift driver', jdbcRedshift).set('PostgreSQL driver', jdbcPgSql);
    }

    return (
      <>
        <p>
          Use these credentials to connect to the sandbox with your favourite Redshift client (we
          like <ExternalLink href="https://dbeaver.io/download/">DBeaver</ExternalLink>).
        </p>
        <FormGroup>
          <Checkbox checked={this.state.showDetails} onChange={this._handleToggleShowDetails}>
            Show JDBC strings
          </Checkbox>
        </FormGroup>
        <CredentialsBox rows={rows} />
      </>
    );
  },

  _handleToggleShowDetails(checked) {
    return this.setState({ showDetails: checked });
  }
});

export default RedshiftCredentials;
