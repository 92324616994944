import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SearchBar from './SearchBar';

class SearchPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: this.props.query
    };

    this.searchInput = null;

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.initInputRef = this.initInputRef.bind(this);
  }

  render() {
    return (
      <div className="search-panel box">
        <SearchBar
          autoFocus={this.props.autoFocus}
          query={this.state.query}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          inputRef={this.initInputRef}
          placeholder={this.props.searchFieldPlaceholder}
          additionalActions={this.renderPredefinedSearchList()}
        />
      </div>
    );
  }

  renderPredefinedSearchList() {
    if (!this.props.predefinedSearches && !this.props.additionalActions) {
      return null;
    }

    return (
      <div className="predefined-search-list">
        {this.props.predefinedSearches.map((link, index) => (
          <button
            key={index}
            type="button"
            className={classnames('btn predefined-search-link', {
              active: this.state.query === link.query
            })}
            onClick={() => this.handlePredefinedSearchLink(link)}
          >
            {link.name}
          </button>
        ))}
        {this.props.additionalActions}
      </div>
    );
  }

  onChange(query) {
    this.setState({ query });
  }

  onSubmit() {
    this.props.onSearch(this.state.query);

    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.query);
    }
  }

  initInputRef(input) {
    this.searchInput = input;
  }

  handlePredefinedSearchLink(link) {
    this.setState({ query: link.query });
    this.props.onSearch(link.query);
    this.searchInput.focus();

    if (this.props.onSubmit) {
      this.props.onSubmit(link.query);
    }
  }
}

SearchPanel.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  query: PropTypes.string,
  autoFocus: PropTypes.bool,
  searchFieldPlaceholder: PropTypes.string,
  predefinedSearches: PropTypes.array,
  additionalActions: PropTypes.any
};

SearchPanel.defaultProps = {
  query: '',
  autoFocus: false
};

export default SearchPanel;
