import { strRight } from 'underscore.string';

import type { Variable, VariableWithHash } from '../../api/routes/vaultService';
import string from '../../utils/string';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import { type FilterType, VARIABLE_TYPE } from './constants';

const filterVariablesByBranch = (variables: VariableWithHash[], branchId?: string) => {
  return variables.filter((variable) => variable.attributes?.branchId === branchId);
};

const filterProjectWideVariables = (variables: VariableWithHash[]) => {
  return variables.filter((variable) => !variable.attributes?.branchId);
};

const filterProductionVariables = (variables: VariableWithHash[]) => {
  return filterVariablesByBranch(variables, DevBranchesStore.getDefaultBranchId()?.toString());
};

const filterCurrentBranchVariables = (variables: VariableWithHash[]) => {
  if (DevBranchesStore.isDevModeActive()) {
    return filterVariablesByBranch(variables, DevBranchesStore.getCurrentId()?.toString());
  }

  return filterProductionVariables(variables);
};

const filterVariablesAvailableInCurrentScope = (variables: VariableWithHash[]) => {
  return filterProjectWideVariables(variables).concat(filterCurrentBranchVariables(variables));
};

const groupVariables = (variables: VariableWithHash[]): Record<string, VariableWithHash[]> => {
  return variables.reduce(
    (folders, variable) => {
      return {
        ...folders,
        [variable.group ?? '']: [...(folders[variable.group ?? ''] || []), variable]
      };
    },
    { '': [] } as Record<string, VariableWithHash[]>
  );
};

const getUniqueGroups = (
  variablesByGroups: Record<string, VariableWithHash[]>,
  productionVariablesByGroups: Record<string, VariableWithHash[]>
) => {
  return [
    ...new Set([...Object.keys(variablesByGroups), ...Object.keys(productionVariablesByGroups)])
  ]
    .filter(Boolean)
    .sort();
};

const prepareVariableToCopy = (variable: VariableWithHash) => {
  return `{{ vault.${variable.key} }}`;
};

const getVariablesByType = (variables: VariableWithHash[], type: FilterType) => {
  switch (type) {
    case VARIABLE_TYPE.PRODUCTION:
      return filterProductionVariables(variables);

    case VARIABLE_TYPE.SCOPED:
      return filterCurrentBranchVariables(variables);

    case VARIABLE_TYPE.WIDE:
      return filterProjectWideVariables(variables);

    default:
      return variables;
  }
};

const findExistingVariable = (variable: Variable, allVariables: VariableWithHash[]) => {
  return allVariables.find((existingVariable) => {
    if (existingVariable.key !== variable.key) return false;
    if (existingVariable.attributes?.branchId && variable.attributes?.branchId) return true;
    return !existingVariable.attributes?.branchId && !variable.attributes?.branchId;
  });
};

const isVariableEncrypted = (variable: VariableWithHash | Variable) => {
  return !!variable.flags?.includes('encrypted');
};

const isVariableOauth = (variable: VariableWithHash | Variable) => {
  return !!variable.flags?.includes('oauthCredentialsId');
};

const separateOauthVariables = <T extends Variable | VariableWithHash>(variables: T[]) => {
  return variables.reduce(
    (allVariables, variable) => {
      if (isVariableOauth(variable)) {
        return {
          ...allVariables,
          oauthVariables: [...allVariables.oauthVariables, variable]
        };
      }

      return { ...allVariables, variables: [...allVariables.variables, variable] };
    },
    { variables: [] as T[], oauthVariables: [] as T[] }
  );
};

const separateSelectedOauthVariables = (
  oauthVariables: VariableWithHash[],
  selectedVariables: VariableWithHash['hash'][]
) => {
  return selectedVariables.reduce(
    (allSelectedVariables, variableHash) => {
      if (oauthVariables.some((variable) => variable.hash === variableHash)) {
        return {
          ...allSelectedVariables,
          selectedOauthVariables: [...allSelectedVariables.selectedOauthVariables, variableHash]
        };
      }

      return {
        ...allSelectedVariables,
        selectedVariables: [...allSelectedVariables.selectedVariables, variableHash]
      };
    },
    {
      selectedVariables: [] as VariableWithHash['hash'][],
      selectedOauthVariables: [] as VariableWithHash['hash'][]
    }
  );
};

const generateOauthVariableKey = (
  variables: VariableWithHash[],
  componentId: string,
  configurationId: string
) => {
  const key = `oauth-${string.webalize(componentId)}-${configurationId}`;
  const duplicitVariables = variables.filter((variable) => variable.key.startsWith(key));

  if (!duplicitVariables?.length) {
    return key;
  }

  const lastIndex = Math.max(
    ...duplicitVariables.map((variable) => parseInt(strRight(variable.key, `${key}-`), 10) || 1)
  );

  return `${key}-${lastIndex + 1}`;
};

const isVariableName = (variableName: string) => {
  return variableName.startsWith('{{ ') && variableName.endsWith(' }}');
};

const parseVariableName = (variableName: string) => {
  return variableName.replace('{{ vault.', '').replace('{{ ', '').replace(' }}', '');
};

export {
  groupVariables,
  filterVariablesByBranch,
  filterProjectWideVariables,
  filterProductionVariables,
  filterCurrentBranchVariables,
  filterVariablesAvailableInCurrentScope,
  getUniqueGroups,
  prepareVariableToCopy,
  getVariablesByType,
  isVariableEncrypted,
  isVariableOauth,
  separateOauthVariables,
  separateSelectedOauthVariables,
  generateOauthVariableKey,
  isVariableName,
  parseVariableName,
  findExistingVariable
};
