const ActionTypes = {
  TRANSFORMATION_BUCKET_CREATE: 'TRANSFORMATION_BUCKET_CREATE',
  TRANSFORMATION_BUCKET_CREATE_SUCCESS: 'TRANSFORMATION_BUCKET_CREATE_SUCCESS',
  TRANSFORMATION_BUCKET_CREATE_ERROR: 'TRANSFORMATION_BUCKET_CREATE_ERROR',

  TRANSFORMATION_CREATE_SUCCESS: 'TRANSFORMATION_CREATE_SUCCESS',

  TRANSFORMATION_BUCKET_DELETE: 'TRANSFORMATION_BUCKET_DELETE',
  TRANSFORMATION_BUCKET_DELETE_SUCCESS: 'TRANSFORMATION_BUCKET_DELETE_SUCCESS',
  TRANSFORMATION_BUCKET_DELETE_ERROR: 'TRANSFORMATION_BUCKET_DELETE_ERROR',

  TRANSFORMATION_DELETE: 'TRANSFORMATION_DELETE',
  TRANSFORMATION_DELETE_SUCCESS: 'TRANSFORMATION_DELETE_SUCCESS',
  TRANSFORMATION_DELETE_ERROR: 'TRANSFORMATION_DELETE_ERROR',

  TRANSFORMATION_OVERVIEW_LOAD: 'TRANSFORMATION_OVERVIEW_LOAD',
  TRANSFORMATION_OVERVIEW_LOAD_SUCCESS: 'TRANSFORMATION_OVERVIEW_LOAD_SUCCESS',
  TRANSFORMATION_OVERVIEW_LOAD_ERROR: 'TRANSFORMATION_OVERVIEW_LOAD_ERROR',
  TRANSFORMATION_OVERVIEW_SHOW_DISABLED: 'TRANSFORMATION_OVERVIEW_SHOW_DISABLED',

  TRANSFORMATION_EDIT_START: 'TRANSFORMATION_EDIT_START',
  TRANSFORMATION_EDIT_CANCEL: 'TRANSFORMATION_EDIT_CANCEL',
  TRANSFORMATION_EDIT_SAVE_START: 'TRANSFORMATION_EDIT_SAVE_START',
  TRANSFORMATION_EDIT_SAVE_SUCCESS: 'TRANSFORMATION_EDIT_SAVE_SUCCESS',
  TRANSFORMATION_EDIT_SAVE_ERROR: 'TRANSFORMATION_EDIT_SAVE_ERROR',
  TRANSFORMATION_EDIT_CHANGE: 'TRANSFORMATION_EDIT_CHANGE',

  TRANSFORMATION_EDIT_INPUT_MAPPING_OPEN_TOGGLE: 'TRANSFORMATION_EDIT_INPUT_MAPPING_OPEN_TOGGLE',
  TRANSFORMATION_EDIT_INPUT_MAPPING_ADD: 'TRANSFORMATION_EDIT_INPUT_MAPPING_ADD',
  TRANSFORMATION_EDIT_INPUT_MAPPING_DELETE: 'TRANSFORMATION_EDIT_INPUT_MAPPING_DELETE',

  TRANSFORMATION_EDIT_OUTPUT_MAPPING_OPEN_TOGGLE: 'TRANSFORMATION_EDIT_OUTPUT_MAPPING_OPEN_TOGGLE',
  TRANSFORMATION_EDIT_OUTPUT_MAPPING_ADD: 'TRANSFORMATION_EDIT_OUTPUT_MAPPING_ADD',
  TRANSFORMATION_EDIT_OUTPUT_MAPPING_DELETE: 'TRANSFORMATION_EDIT_OUTPUT_MAPPING_DELETE',

  TRANSFORMATION_START_EDIT_FIELD: 'TRANSFORMATION_START_EDIT_FIELD',
  TRANSFORMATION_UPDATE_EDITING_FIELD: 'TRANSFORMATION_UPDATE_EDITING_FIELD',
  TRANSFORMATION_CANCEL_EDITING_FIELD: 'TRANSFORMATION_CANCEL_EDITING_FIELD',

  DELETED_TRANSFORMATION_BUCKET_RESTORE: 'DELETED_TRANSFORMATION_BUCKET_RESTORE',
  DELETED_TRANSFORMATION_BUCKET_RESTORE_SUCCESS: 'DELETED_TRANSFORMATION_BUCKET_RESTORE_SUCCESS',
  DELETED_TRANSFORMATION_BUCKET_RESTORE_ERROR: 'DELETED_TRANSFORMATION_BUCKET_RESTORE_ERROR',

  TRANSFORMATION_UPDATE_PARSE_QUERIES: 'TRANSFORMATION_UPDATE_PARSE_QUERIES',
  TRANSFORMATION_UPDATE_PARSE_QUERIES_START: 'TRANSFORMATION_UPDATE_PARSE_QUERIES_START',
  TRANSFORMATION_UPDATE_PARSE_QUERIES_SUCCESS: 'TRANSFORMATION_UPDATE_PARSE_QUERIES_SUCCESS',
  TRANSFORMATION_UPDATE_PARSE_QUERIES_ERROR: 'TRANSFORMATION_UPDATE_PARSE_QUERIES_ERROR'
};

const backends = {
  REDSHIFT: 'redshift',
  SNOWFLAKE: 'snowflake',
  DOCKER: 'docker'
};

const transformationType = {
  PYTHON: 'python',
  R: 'r',
  OPENREFINE: 'openrefine',
  JULIA: 'julia'
};

const transformationLabels = {
  redshift: 'Redshift',
  snowflake: 'Snowflake',
  docker: 'Docker',
  python: 'Python',
  r: 'R',
  openrefine: 'OpenRefine (beta)',
  julia: 'Julia'
};

export { ActionTypes, backends, transformationType, transformationLabels };
