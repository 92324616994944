import { fromJS, List, Map, Set } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_EX_SAMPLE_DATA } from '../../constants/componentIds';
import { componentTypes } from '../../constants/componentTypes';
import dayjs from '../../date';
import { READ_ONLY_TOOLTIP_MESSAGE } from '../../react/common/ReadOnlyTooltip';
import { getComponentIconUrl } from '../../utils/componentIconFinder';
import generateId from '../../utils/generateId';
import { defaultTransformationBackendSize as defaultBackendSize } from '../components/Constants';
import { getNewComponentTypeLabel, hasDynamicBackendSizeEnabled } from '../components/helpers';
import { prepareVariables } from '../components/react/components/generic/variables/helpers';
import { BEHAVIOR_TYPES, JOB_RUNNING_STATUSES } from '../queue/constants';
import { getChildJobs } from '../queue/helpers';
import { BRANCH_TOOLTIP_MESSAGE, SOX_BRANCH_TOOLTIP_MESSAGE } from '../scheduler/constants';

class DragZone {
  constructor(/** @type {number} */ cy, /** @type {number} */ hh) {
    /** center Y */
    this.y = cy;
    /** top edge */
    this.top = cy - hh;
    /** bottom edge */
    this.bottom = cy + hh;
  }

  /** Test if this drag zone match `py` */
  match(py, isFirst, isLast) {
    if (isFirst) return py <= this.bottom; // any point above bottom edge of first drag zone match
    if (isLast) return py >= this.top; // any point under top edge of first drag zone match
    return this.top <= py && py <= this.bottom;
  }

  /** Construct a drag zone around `el` */
  static simple(/** @type {Element} */ el) {
    const bb = el.getBoundingClientRect();
    return new DragZone(bb.y + bb.height / 2, bb.height / 2);
  }

  /** Construct a drag zone positioned before `el`, with size matching `el` */
  static before(/** @type {Element} */ el) {
    const bb = el.getBoundingClientRect();
    return new DragZone(bb.y - bb.height * 0.5, bb.height / 2);
  }

  /**
   * Construct a drag zone taking up the space between `a` and `b`,
   * with width being the minimum of the two elements' widths.
   */
  static between(/** @type {Element} */ a, /** @type {Element} */ b) {
    const a_b = a.getBoundingClientRect();
    const b_b = b.getBoundingClientRect();
    return new DragZone(
      (a_b.bottom + b_b.top) / 2, // point on Y axis between bottom edge of `A` and top edge of `B`
      (b_b.top - a_b.bottom) / 2 // half of distance between bottom edge of `A` and top edge of `B`
    );
  }
}

const phaseKey = (phase) => `${phase.get('id')}-${phase.get('name')}`;

/**
 * Use more user friendly phases names if user do not provide own name
 */
const updatePhasesNames = (phases) => {
  return phases.map((phase, index) => {
    if (/^(New Step|Step \d+)$/i.test(phase.get('name', ''))) {
      return phase.set('name', `Step ${index + 1}`);
    }

    return phase;
  });
};

/**
 * Merges all tasks into their phases + calls `prepareTask` on each task
 */
const prepareVisualizationPhases = (
  phases,
  tasks,
  allComponents,
  allInstalledComponents,
  deletedComponents
) => {
  return phases
    .map((phase) => {
      return phase.set(
        'tasks',
        tasks.filter((task) => task.get('phase') === phase.get('id'))
      );
    })
    .map((phase) => {
      const data = {
        id: phase.get('id'),
        name: phase.get('name'),
        description: phase.get('description', ''),
        behaviorOnError: phase.getIn(['behavior', 'onError'], BEHAVIOR_TYPES.STOP),
        key: phaseKey(phase),
        tasks: phase.get('tasks').map((task) => {
          return prepareTask(task, allComponents, allInstalledComponents, deletedComponents);
        })
      };
      if (phase.get('isFake', false)) data.isFake = true;
      return Map(data);
    });
};

/**
 * Merges the config name, component type, and iconUrl of the task into itself
 */
const prepareTask = (task, allComponents, allInstalledComponents, deletedComponents) => {
  const componentId = task.getIn(['task', 'componentId']);
  const configId = task.getIn(['task', 'configId']);
  const config = allInstalledComponents.getIn([componentId, 'configurations', configId], Map());
  const component = allComponents.get(
    componentId === KEBOOLA_EX_SAMPLE_DATA && !config.isEmpty()
      ? config.getIn(['configuration', 'parameters', 'componentId'])
      : componentId,
    Map()
  );

  const componentName = component.get('name');
  const isDeleted = !!configId && config.isEmpty();
  const inTrash = deletedComponents.hasIn([componentId, 'configurations', configId, 'name']);
  const specificRows = task.getIn(['task', 'configRowIds'], List());
  const availableRows = config
    .get('rows', List())
    .map((row) => ({ value: row.get('id'), label: row.get('name') }));

  let out = Map({
    id: task.get('id'),
    name: isDeleted
      ? inTrash
        ? `Deleted (${deletedComponents.getIn([componentId, 'configurations', configId, 'name'])})`
        : 'Deleted Configuration'
      : config.get('name', configId),
    specificRows,
    availableRows,
    componentId: component.get('id'),
    type: Object.values(componentTypes).includes(component.get('type'))
      ? getNewComponentTypeLabel(component.get('type'))
      : '',
    configId,
    component: componentName ?? 'Invalid component',
    iconUrl: getComponentIconUrl(component),
    enabled: task.get('enabled', true),
    continueOnFailure: task.get('continueOnFailure', false),
    invalid: !componentName,
    hasDeletedConfiguration: isDeleted,
    hasConfigurationInTrash: inTrash
  });

  if (
    task.hasIn(['task', 'backend', 'type']) ||
    config.hasIn(['configuration', 'runtime', 'backend', 'type'])
  ) {
    out = out.set(
      'backend',
      task.getIn(
        ['task', 'backend', 'type'],
        config.getIn(['configuration', 'runtime', 'backend', 'type'])
      )
    );
  }

  if (task.hasIn(['task', 'variableValuesId'])) {
    out = out.set('variableValuesId', task.getIn(['task', 'variableValuesId']));
  }

  if (task.hasIn(['task', 'variableValuesData'])) {
    out = out.set('variableValuesData', task.getIn(['task', 'variableValuesData']));
  }

  return out;
};

const insertEmptyPhase = (phases, insertAfter, { isFake = false } = {}) => {
  const index = _.isUndefined(insertAfter) ? phases.count() : insertAfter;
  const id = generateId(phases.map((phase) => phase.get('id')).toArray());

  const data = { id, name: 'New Step', dependsOn: [phases.getIn([index, 'id'])] };
  if (isFake) data.isFake = true;

  return phases.splice(index, 0, fromJS(data));
};

const resolveComponentId = (allConfigurations, task, configId) => {
  return allConfigurations.getIn(
    [task.get('componentId'), 'configurations', configId, 'isSample'],
    false
  )
    ? KEBOOLA_EX_SAMPLE_DATA
    : task.get('componentId');
};

/** @returns {'small' | 'medium' | 'large' | null} */
const getBackendSize = (
  component,
  task,
  hasSnowflakeDynamicBackendSize,
  hasJobsDynamicBackendSize
) => {
  return hasDynamicBackendSizeEnabled(
    component,
    hasSnowflakeDynamicBackendSize,
    hasJobsDynamicBackendSize
  )
    ? task.get('backend', defaultBackendSize)
    : null;
};

/** Ensures that `tasks` contains at least some tasks are configured and enabled */
const shouldAllowRunFlow = (tasks) => {
  return tasks.some((task) => !!task.getIn(['task', 'configId']) && task.get('enabled', true));
};

/**
 * Gets all currently running phases + their running tasks.
 */
const getRunningFlowStatus = (allJobs, flowJob) => {
  // phaseInfos format:
  // {
  //   jobId: string
  //   isRunning: boolean
  //   [phase: number]: {
  //     jobId: string
  //     status: Status
  //     [task: number]: {
  //       jobId: string
  //       status: Status
  //     }
  //   }
  // }
  let phaseInfos = Map({
    jobId: flowJob.get('id'),
    isRunning: JOB_RUNNING_STATUSES.includes(flowJob.get('status'))
  });
  getChildJobs(allJobs, flowJob)
    .filter((phaseJob) => phaseJob.hasIn(['configData', 'phaseId']))
    .forEach((phaseJob) => {
      // task jobs are created in the same order as the tasks themselves are in the phase,
      // so sorting by `runId` is the same as sorting by task id
      // this is to make up for the fact that we don't have access to the task id in the child jobs.
      const taskJobs = getChildJobs(allJobs, phaseJob)
        .toIndexedSeq()
        .sortBy((v) => v.get('runId'));
      // because two or more tasks in a phase may have the same configId, we use a set to
      // keep track of which specific task _jobs_ we have already visited, to avoid having
      // two tasks with the same configId sharing the same status
      let usedTaskJobs = Set();

      let phaseInfo = Map({ jobId: phaseJob.get('id'), status: phaseJob.get('status') });
      phaseJob.getIn(['configData', 'tasks'], List()).forEach((task) => {
        const matchedJob = taskJobs.find(
          (taskJob) =>
            !usedTaskJobs.has(taskJob.get('runId')) &&
            taskJob.get('config') === task.getIn(['task', 'configId'])
        );
        if (matchedJob) {
          usedTaskJobs = usedTaskJobs.add(matchedJob.get('runId'));
          phaseInfo = phaseInfo.set(
            task.get('id'),
            Map({ jobId: matchedJob.get('id'), status: matchedJob.get('status') })
          );
        }
      });
      phaseInfos = phaseInfos.set(phaseJob.getIn(['configData', 'phaseId']), phaseInfo);
    });

  return phaseInfos;
};

/** Returns true if `job` was run with the current version of `config` */
const jobVersionMatch = (config, job) => {
  const jobStartTime = dayjs(job.getIn(['startTime']));
  const currentVersionCreatedTime = dayjs(config.getIn(['currentVersion', 'created']));
  return currentVersionCreatedTime.isBefore(jobStartTime);
};

const filterDisabledTasks = (phases) => {
  return phases.map((phase) => {
    return phase.update('tasks', List(), (tasks) => {
      return tasks.filter((task) => task.get('enabled'));
    });
  });
};

const prepareSelectedTasks = (configData, selected, allConfigs = Map(), variables = Map()) => {
  const setVariablesOverride = (task) => {
    if (!variables.hasIn([task.get('phase'), task.get('id')])) {
      return task;
    }

    return task.setIn(
      ['task', 'variableValuesData', 'values'],
      prepareVariables(
        allConfigs,
        task.getIn(['task', 'componentId']),
        task.getIn(['task', 'configId'])
      ).map((value) => ({
        name: value.get('name'),
        value: variables.getIn(
          [task.get('phase'), task.get('id'), value.get('name')],
          value.get('value')
        )
      }))
    );
  };

  const allPhases = configData.get('phases');
  const firstPhase = allPhases.find((phase) => phase.get('dependsOn', List()).isEmpty());
  const tasks = configData
    .get('tasks')
    .filter((task) => selected[task.get('id')])
    .map((task) => setVariablesOverride(task.set('enabled', true)));

  let dependencyMap = List([firstPhase.get('id')]);
  const buildDependencyMap = (phaseId) => {
    const nextPhase = allPhases.find((phase) => phase.getIn(['dependsOn', 0]) === phaseId);
    if (nextPhase) {
      dependencyMap = dependencyMap.push(nextPhase.get('id'));
      buildDependencyMap(nextPhase.get('id'));
    }
  };
  buildDependencyMap(firstPhase.get('id'));

  dependencyMap = dependencyMap.filter((phaseId) => {
    return tasks.some((task) => task.get('phase') === phaseId);
  });

  const phases = allPhases
    .filter((phase) => dependencyMap.includes(phase.get('id')))
    .map((phase) => {
      const dependsOnIndex = dependencyMap.findIndex((phaseId) => phaseId === phase.get('id'));

      if (dependsOnIndex === 0) {
        return phase.set('dependsOn', List());
      }

      return phase.setIn(['dependsOn', 0], dependencyMap.get(dependsOnIndex - 1));
    });

  return { tasks: tasks.toJS(), phases: phases.toJS() };
};

const getScheduleTooltipMessage = (
  hasProtectedDefaultBranch,
  isDevModeActive,
  isButtonDisabled
) => {
  if (hasProtectedDefaultBranch) {
    return isButtonDisabled ? READ_ONLY_TOOLTIP_MESSAGE : SOX_BRANCH_TOOLTIP_MESSAGE;
  }

  if (isDevModeActive) {
    return BRANCH_TOOLTIP_MESSAGE;
  }

  return isButtonDisabled ? READ_ONLY_TOOLTIP_MESSAGE : '';
};

export {
  DragZone,
  phaseKey,
  updatePhasesNames,
  prepareVisualizationPhases,
  prepareTask,
  insertEmptyPhase,
  resolveComponentId,
  getBackendSize,
  shouldAllowRunFlow,
  getRunningFlowStatus,
  jobVersionMatch,
  filterDisabledTasks,
  prepareSelectedTasks,
  getScheduleTooltipMessage
};
