import React, { useState } from 'react';
import { Button, Collapse, Label } from 'react-bootstrap';
import { useProductFruitsApi } from 'react-product-fruits';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { type Map } from 'immutable';

import ComponentIcon from '../../../../react/common/ComponentIcon';
import ComponentName from '../../../../react/common/ComponentName';
import Tooltip from '../../../../react/common/Tooltip';

type Checklist = {
  id: number;
  name: string;
  title: string;
  description: string;
  items: Task[];
  state: 'not_touched' | 'in_progress' | 'finished';
};

type Task = {
  id: string;
  internalId: string | undefined;
  title: string;
  state: 'tbd' | 'open' | 'done';
};

const ProductFruitsChecklists = (props: { allComponents: Map<string, any> }) => {
  const [checklists, setChecklists] = useState<Checklist[]>();

  useProductFruitsApi((api) => {
    setChecklists(
      (api?.checklists?.getChecklists() as Checklist[]).sort((a, b) =>
        a.name === 'Beginner' || (a.name === 'Intermediate' && b.name === 'Expert') ? -1 : 1
      )
    );
  }, []);

  if (!checklists?.length) return null;

  return (
    <div className="product-fruits-checklists">
      {checklists.map((checklist) => (
        <Checklist
          key={checklist.id}
          showInitially={checklist.state !== 'finished'}
          allComponents={props.allComponents}
          {...checklist}
        />
      ))}
    </div>
  );
};

const Checklist = ({
  id,
  name: level,
  title,
  description,
  items,
  showInitially,
  allComponents
}: Checklist & { showInitially: boolean; allComponents: Map<string, any> }) => {
  const [show, setShow] = useState(showInitially);
  const totalTasks = items?.length ?? 0;
  const finishedTasks = items?.filter(({ state }) => state === 'done')?.length ?? 0;
  const componentsMatch = description.match(/\[components=(.*)\]/);
  let components: string[] = [];

  if (componentsMatch) {
    description = description.replace(componentsMatch[0], '').trim();
    components = componentsMatch[1].split(',');
  }

  return (
    <div className="box box-collapsible">
      <div
        className="box-header big-padding with-collapse-button clickable"
        onClick={() => setShow(!show)}
      >
        <h3 className="box-title flex-container flex-start">
          {title}
          <Label className="mlp-3 label-rounded label-primary uppercase">{level}</Label>
        </h3>
        <div className="ml-auto text-muted f-12">
          {totalTasks === finishedTasks ? (
            <span className="color-success">
              <FontAwesomeIcon icon="circle-check" className="icon-addon-right f-16" fixedWidth />
              All Done
            </span>
          ) : (
            <>
              <span className={classNames({ 'color-orange': finishedTasks > 0 })}>
                {finishedTasks} of {totalTasks}
              </span>
              <span> tasks completed</span>
            </>
          )}
          <Tooltip placement="top" tooltip={show ? 'Collapse checklist' : 'Expand checklist'}>
            <Button
              bsStyle="link"
              className="btn-collapse btn-link-inline mlp-4 text-muted f-16"
              onClick={() => setShow(!show)}
            >
              <FontAwesomeIcon icon={['far', show ? 'angle-up' : 'angle-down']} />
            </Button>
          </Tooltip>
        </div>
      </div>
      <Collapse in={show}>
        <div>
          <div className="box-content box-container two-columns pt-0">
            <div>
              <p className="text-muted mb-0">{description}</p>
              {!!components.length && (
                <div className="box-container three-columns mtp-8">
                  {components.map((componentId) => {
                    const component = allComponents.find(
                      (component) => component.get('id') === componentId
                    );

                    if (!component) return null;
                    return <ComponentBox key={componentId} component={component} />;
                  })}
                </div>
              )}
            </div>
            <div
              ref={(el) => {
                if (!el || el.children.length) return;

                window.productFruits?.api?.checklists?.injectToElement(id, el);
              }}
            ></div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const ComponentBox = (props: { component: Map<string, any> }) => {
  return (
    <div className="box no-shadow p-0 flex-container flex-start">
      <ComponentIcon component={props.component} size="40" className="mrp-3" />
      <div className="flex-container flex-column align-top">
        <ComponentName component={props.component} showType />
      </div>
    </div>
  );
};
export default ProductFruitsChecklists;
