import React from 'react';
import PropTypes from 'prop-types';

import { nameWarning } from '../../modules/storage/constants';

export const isValidName = (value) => /^[a-zA-Z0-9_-]*$/.test(value);
export const isValid = (predefinedType, value) => !getValidationMessage(predefinedType, value);
const getValidationMessage = (predefinedType, value) => {
  switch (predefinedType) {
    case 'bucketName':
    case 'tableName':
    case 'columnName':
      return isValidName(value) ? null : nameWarning;

    default:
      return null;
  }
};

class InputValidation extends React.Component {
  render() {
    const validationMessage =
      this.props.customValidation?.(this.props.value) ??
      getValidationMessage(this.props.predefined, this.props.value);

    return this.props.children(!!validationMessage ? 'error' : null, validationMessage);
  }
}

InputValidation.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  predefined: PropTypes.oneOf(['bucketName', 'tableName', 'columnName']),
  customValidation: PropTypes.func
};

export default InputValidation;
