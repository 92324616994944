import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { capitalize } from 'underscore.string';

import { USER_DOCUMENTATION_URL } from '../../constants/KbcConstants';
import ExternalLink from '../../react/common/ExternalLink';
import PasswordControl from '../../react/common/PasswordControl';
import Select from '../../react/common/Select';
import SyncActionSimpleValue from '../configurations/react/components/SyncActionSimpleValue';
import { LOGIN_TYPES } from './constants';

const Credentials = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      loginType: PropTypes.oneOf(Object.values(LOGIN_TYPES)).isRequired,
      awsAccessKeyId: PropTypes.string,
      awsSecretAccessKey: PropTypes.string,
      accountId: PropTypes.string,
      roleName: PropTypes.string
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    return (
      <div className="form-horizontal">
        <FormGroup>
          <Col componentClass={ControlLabel} xs={4}>
            Login Type
          </Col>
          <Col xs={8}>
            <Select
              clearable={false}
              searchable={false}
              disabled={this.props.disabled}
              value={this.props.value.loginType}
              onChange={(selected) => this.props.onChange({ loginType: selected })}
              options={Object.values(LOGIN_TYPES).map((value) => ({
                value,
                label: capitalize(value)
              }))}
            />
          </Col>
        </FormGroup>
        {this.props.value.loginType === LOGIN_TYPES.ROLE ? (
          <>
            <FormGroup>
              <Col componentClass={ControlLabel} xs={4}>
                Account Id
              </Col>
              <Col xs={8}>
                <FormControl
                  type="text"
                  value={this.props.value.accountId}
                  onChange={(e) => {
                    this.props.onChange({ accountId: e.target.value });
                  }}
                  disabled={this.props.disabled}
                  placeholder="123456789012"
                />
                <HelpBlock>ID of your AWS account</HelpBlock>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col componentClass={ControlLabel} xs={4}>
                Role Name
              </Col>
              <Col xs={8}>
                <FormControl
                  type="text"
                  value={this.props.value.roleName}
                  onChange={(e) => {
                    this.props.onChange({ roleName: e.target.value });
                  }}
                  disabled={this.props.disabled}
                />
                <HelpBlock>
                  Fill up with the name of a role you created in your AWS account. More information
                  is available in the{' '}
                  <ExternalLink
                    href={`${USER_DOCUMENTATION_URL}/components/extractors/storage/aws-s3/`}
                  >
                    documentation
                  </ExternalLink>
                  .
                </HelpBlock>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={4}>
                External Id
              </Col>
              <Col sm={8}>
                <FormControl.Static>
                  <SyncActionSimpleValue
                    action={this.props.actions.get('getExternalId')}
                    valueKey={['external-id']}
                    withClipboard
                  />
                </FormControl.Static>
                <HelpBlock>
                  Use this identifier as External Id when creating the new role.
                </HelpBlock>
              </Col>
            </FormGroup>
          </>
        ) : (
          <>
            <FormGroup>
              <Col componentClass={ControlLabel} xs={4}>
                Access Key Id
              </Col>
              <Col xs={8}>
                <FormControl
                  type="text"
                  value={this.props.value.awsAccessKeyId}
                  onChange={(e) => {
                    this.props.onChange({ awsAccessKeyId: e.target.value });
                  }}
                  placeholder="MYAWSACCESSKEYID123"
                  disabled={this.props.disabled}
                />
                <HelpBlock>
                  Make sure that this AWS Access Key ID has correct permissions. Required
                  permissions are
                  <ul>
                    <li>
                      <code>s3:GetObject</code> for the given key/wildcard
                    </li>
                    <li>
                      <code>s3:ListBucket</code> to access all wildcard files
                    </li>
                    <li>
                      <code>s3:GetBucketLocation</code> to determine the bucket region
                    </li>
                  </ul>
                  More information is available in the{' '}
                  <ExternalLink
                    href={`${USER_DOCUMENTATION_URL}/components/extractors/storage/aws-s3/`}
                  >
                    documentation
                  </ExternalLink>
                  .
                </HelpBlock>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col componentClass={ControlLabel} xs={4}>
                Secret Access Key
              </Col>
              <Col xs={8}>
                <PasswordControl
                  value={this.props.value.awsSecretAccessKey}
                  onChange={(e) => this.props.onChange({ awsSecretAccessKey: e.target.value })}
                  disabled={this.props.disabled}
                />
                <HelpBlock>The AWS Secret Access Key will be encrypted.</HelpBlock>
              </Col>
            </FormGroup>
          </>
        )}
      </div>
    );
  }
});

export default Credentials;
