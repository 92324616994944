import React from 'react';

import InfoAlert from '../../../react/common/InfoAlert';
import ReadOnlyDocumentationLink from './ReadOnlyDocumentationLink';

const ReadOnlyInfoAlert = (props: { className?: string }) => {
  return (
    <InfoAlert className={props.className} header="Read-Only Access">
      Enabling this option grants read-only access to all project data without a need to define
      tables in input mapping. <ReadOnlyDocumentationLink />
    </InfoAlert>
  );
};

export default ReadOnlyInfoAlert;
