import React from 'react';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { componentTypes } from '../../constants/componentTypes';
import { findTemplateInstanceIdFromMetadata } from '../../modules/components/helpers';
import { routeNames as templatesRouteNames } from '../../modules/templates/constants';
import CollapseButton from './CollapseButton';
import ComponentAuthor from './ComponentAuthor';
import ComponentBadges, { hasStageFlag } from './ComponentBadges';
import ComponentLicence from './ComponentLicence';
import ComponentType from './ComponentType';
import ExternalLink from './ExternalLink';
import Markdown from './Markdown';
import RouterLink from './RouterLink';
import StorageData from './StorageData';
import UsedInFlowsModal from './UsedInFlowsModal';

const ConfigurationInfoPanel = (props: {
  component: Map<string, any>;
  config: Map<string, any>;
  hasFlows?: boolean;
  flows?: Map<string, any>;
  metadata?: Map<string, any>;
  realComponent?: Map<string, any>;
  tablesMetadataMap?: Map<string, any>;
  className?: string;
}) => {
  const [expand, setExpand] = React.useState(false);
  const component = props.realComponent || props.component;
  const description = component.get('longDescription') || component.get('description');
  const licenceUrl = component.getIn(['data', 'vendor', 'licenseUrl']);
  const templateInstanceId = findTemplateInstanceIdFromMetadata(
    component.get('id'),
    props.config.get('id'),
    props.metadata
  );

  const renderBoxItem = (
    label: React.ReactNode,
    body: React.ReactNode,
    options?: { aside?: boolean }
  ) => {
    return (
      <div className={classNames('panel-info-item no-wrap', { aside: options?.aside })}>
        <span className="text-muted">{label}:</span>
        <span className="flex-container flex-start" onClick={(event) => event.stopPropagation()}>
          {body}
        </span>
      </div>
    );
  };

  return (
    <div className={classNames('box box-separator panel-info', props.className)}>
      <div className="box-content">
        <div className="flex-container btn-collapse-area" onClick={() => setExpand(!expand)}>
          <div className="flex-container align-top line-height-24">
            {renderBoxItem(
              'Type',
              <ComponentType labelClassName="font-medium color-main" type={component.get('type')} />
            )}
            {component.get('type') !== componentTypes.TRANSFORMATION &&
              renderBoxItem(
                'Author',
                <>
                  <FontAwesomeIcon icon="user" className="icon-addon-right text-muted" />
                  <ComponentAuthor
                    is3rdParty={component.get('flags', List()).includes('3rdParty')}
                    contact={component.getIn(['data', 'vendor', 'contact'], List())}
                  />
                </>
              )}
            {hasStageFlag(component.get('flags')) &&
              renderBoxItem('Stage', <ComponentBadges flags={component.get('flags')} />)}
            {props.flows &&
              props.hasFlows &&
              renderBoxItem(
                'Used in',
                <>
                  <UsedInFlowsModal
                    config={props.config}
                    component={props.component}
                    flows={props.flows}
                  />
                  {templateInstanceId && (
                    <span className="text-muted pre">
                      {'  '}and{'  '}
                      <RouterLink
                        to={templatesRouteNames.INSTANCE_DETAIL}
                        params={{ instanceId: templateInstanceId }}
                      >
                        <FontAwesomeIcon icon="book-open" className="f-16 btn-icon text-muted" />
                        Template
                      </RouterLink>
                    </span>
                  )}
                </>
              )}
            {props.config &&
              props.tablesMetadataMap &&
              component.get('type') !== componentTypes.WRITER &&
              renderBoxItem(
                'Data',
                <StorageData
                  config={props.config}
                  component={props.component}
                  tablesMetadataMap={props.tablesMetadataMap}
                />
              )}
            {(component.get('documentationUrl') ||
              component.getIn(['data', 'vendor', 'licenseUrl'])) &&
              renderBoxItem(
                'Additional Links',
                <>
                  {component.get('documentationUrl') && (
                    <span className="mr-1 no-wrap">
                      <FontAwesomeIcon icon="book-blank" className="text-muted icon-addon-right" />
                      <ExternalLink href={component.get('documentationUrl')}>
                        Documentation
                      </ExternalLink>
                    </span>
                  )}
                  {component.getIn(['data', 'vendor', 'licenseUrl']) && (
                    <span className="no-wrap">
                      <FontAwesomeIcon icon="file-lines" className="text-muted icon-addon-right" />
                      <ExternalLink href={component.getIn(['data', 'vendor', 'licenseUrl'])}>
                        Licence
                      </ExternalLink>
                    </span>
                  )}
                </>,
                { aside: true }
              )}
          </div>
          {(description || licenceUrl) && (
            <CollapseButton
              entity="component info"
              isCollapsed={!expand}
              onToggle={() => setExpand(!expand)}
            />
          )}
        </div>
        {(description || licenceUrl) && (
          <Collapse in={expand}>
            <div className="text-muted">
              <div className="panel-info-content">
                <Markdown source={description} collapsible={false} />
                <ComponentLicence href={licenceUrl} />
              </div>
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
};

export default ConfigurationInfoPanel;
