import React from 'react';
import { Modal } from 'react-bootstrap';
import { Promise } from 'bluebird';
import type { Map } from 'immutable';
import { fromJS, List } from 'immutable';

import ConfirmButtons from '../../../../react/common/ConfirmButtons';
import ModalIcon from '../../../../react/common/ModalIcon';
import SearchBar from '../../../../react/common/SearchBar';
import matchByWords from '../../../../utils/matchByWords';
import GooglePicker from '../../../google-utils/react/GooglePicker';
import ViewTemplates from '../../../google-utils/react/PickerViewTemplates';
import { listSheets } from '../../../google-utils/react/SheetsApi';
import type { SelectedSheets } from './SheetsSelector';
import SheetsSelector from './SheetsSelector';

type Props = {
  show: boolean;
  isSaving: boolean;
  authorizedEmail: string;
  savedSheets: Map<string, any>;
  onFilesChange: (files: List<any>) => void;
  onSaveSheets: (files: List<any>) => Promise<any>;
  onHide: () => void;
  files?: List<any>;
};

class SheetsManagerModal extends React.Component<Props> {
  state = {
    query: '',
    isLoading: false
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sheets</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>
          <p>1. select documents from {this.props.authorizedEmail}</p>
          <GooglePicker
            dialogTitle="Select spreadsheet document"
            buttonLabel="Select spreadsheet document"
            onPickedFn={this.onPickSpreadsheet}
            requireSheetsApi
            views={[
              ViewTemplates.sheets,
              ViewTemplates.teamDriveSheets,
              ViewTemplates.sharedSheets,
              ViewTemplates.starredSheets,
              ViewTemplates.recentSheets
            ]}
          />
          <p className="tw-mt-6">2. select sheets from the selected documents</p>
          <SearchBar
            className="as-input condensed"
            query={this.state.query}
            onChange={(query) => this.setState({ query })}
          />
          <SheetsSelector
            files={this.getFilteredFiles()}
            selectSheets={this.selectSheets}
            savedSheets={this.props.savedSheets}
            isLoading={this.state.isLoading}
            searchQuery={this.state.query}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={this.props.isSaving}
            onSave={this.handleSave}
            saveLabel="Add Sheets"
            isDisabled={!this.hasSheetsToSave()}
          />
        </Modal.Footer>
      </Modal>
    );
  }

  getFilteredFiles = () => {
    if (!this.state.query || !this.props.files) {
      return this.props.files ?? List();
    }

    return this.props.files
      .map((file: Map<string, any>) => {
        if (matchByWords(file?.get('name'), this.state.query)) {
          return file;
        }

        return file.update('sheets', List(), (sheets) => {
          return sheets.filter((sheet: any) => {
            return matchByWords(sheet.get('sheetTitle'), this.state.query);
          });
        });
      })
      .filter((file) => {
        if (!file) return false;

        return (
          matchByWords(file.get('name'), this.state.query) || !file.get('sheets', List()).isEmpty()
        );
      })
      .toList();
  };

  onPickSpreadsheet = (data: Record<string, any>) => {
    let files = this.props.files ?? List();

    data
      .filter((dataFile: Record<string, any>) => dataFile.type === 'document')
      .forEach((dataFile: Record<string, any>) => {
        if (!files.find((file: Map<string, any>) => file.get('id') === dataFile.id)) {
          files = files.push(fromJS(dataFile));
        }
      });

    this.setState({ isLoading: true });
    return Promise.map(
      files.filter((file) => !file.has('sheets')).toArray(),
      (file: Map<string, any>) => {
        return listSheets(file.get('id')).then((data: Record<string, any>) => {
          const sheets = data.result.sheets.map((sheet: Record<string, any>) => {
            return { sheetId: sheet.properties.sheetId, sheetTitle: sheet.properties.title };
          });
          const updatedFile = file.set('sheets', fromJS(sheets));

          files = files.map((file: Map<string, any>) => {
            return file.get('id') === updatedFile.get('id') ? updatedFile : file;
          }) as List<any>;
        });
      },
      { concurrency: 3 }
    )
      .then(() => this.props.onFilesChange(files))
      .finally(() => this.setState({ isLoading: false }));
  };

  hasSheetsToSave = () => {
    return !!this.props.files?.some((file: Map<string, any>) => {
      return file.get('sheets', List()).some((sheet: Map<string, any>) => sheet.get('selected'));
    });
  };

  selectSheets = (sheets: SelectedSheets, selected: boolean) => {
    let files = this.props.files ?? List();

    // go through selected sheets and update selected state
    sheets.forEach((data) => {
      files = files.map((file) => {
        if (file.get('id') !== data.file.get('id')) {
          return file;
        }

        return file.update('sheets', List(), (fileSheets: Map<string, any>) => {
          return fileSheets.map((sheet) => {
            if (sheet.get('sheetId') !== data.sheet.get('sheetId')) {
              return sheet;
            }

            return sheet.set('selected', selected);
          });
        });
      }) as List<any>;
    });

    this.props.onFilesChange(files);
  };

  handleSave = () => {
    const files = this.props.files ?? List();

    const itemsToSave = files
      .map((file: Map<string, any>) => {
        return file
          .get('sheets', List())
          .filter((sheet: Map<string, any>) => !!sheet.get('selected'))
          .map((sheet: Map<string, any>) => {
            return fromJS({
              fileId: file.get('id'),
              fileTitle: file.get('name'),
              sheetId: sheet.get('sheetId'),
              sheetTitle: sheet.get('sheetTitle')
            });
          });
      })
      .flatten(true) as List<any>;

    this.props.onSaveSheets(itemsToSave).then(() => {
      this.props.onFilesChange(
        files.map((file: Map<string, any>) => {
          return file.set(
            'sheets',
            file
              .get('sheets', List())
              .map((sheet: Map<string, any>) => sheet.set('selected', false))
          );
        }) as List<any>
      );
      this.props.onHide();
    });
  };
}

export default SheetsManagerModal;
