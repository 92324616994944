import React, { useState } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import useStores from '../../../react/hooks/useStores';
import RoutesStore from '../../../stores/RoutesStore';
import InstalledComponentsStore from '../../components/stores/InstalledComponentsStore';
import { routeNames } from '../constants';
import TemplatesStore from '../store';
import NewInstanceModal from './NewInstanceModal';
import VersionSelect from './VersionSelect';

const UseTemplateHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const { templateDetail, sourceFlowName } = useStores(
    () => {
      return {
        templateDetail: TemplatesStore.getStore().templateVersionDetail,
        sourceFlowName: InstalledComponentsStore.getConfig(
          KEBOOLA_ORCHESTRATOR,
          RoutesStore.getRouterState().getIn(['location', 'query', 'flowId'])
        ).get('name')
      };
    },
    [],
    [TemplatesStore, InstalledComponentsStore]
  );

  if (!templateDetail) return null;

  const handleUseTemplate = () => {
    if (!sourceFlowName) {
      return setShowModal(true);
    }

    return RoutesStore.getRouter().transitionTo(
      routeNames.INSTANCE_NEW,
      {
        templateId: templateDetail.template.id
      },
      {
        n: sourceFlowName,
        flowId: RoutesStore.getRouterState().getIn(['location', 'query', 'flowId'])
      }
    );
  };

  return (
    <>
      <ButtonToolbar>
        <VersionSelect
          versions={templateDetail.template.versions}
          current={templateDetail.version}
        />
        <Button bsStyle="success" onClick={() => handleUseTemplate()}>
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Setup Template
        </Button>
      </ButtonToolbar>
      <NewInstanceModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        templateDetail={templateDetail.template}
        templateVersion={templateDetail.version}
      />
    </>
  );
};

export default UseTemplateHeader;
