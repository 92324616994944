import React from 'react';
import { ControlLabel, Form, FormControl, FormGroup, HelpBlock, Modal } from 'react-bootstrap';
import Promise from 'bluebird';
import { List, Map } from 'immutable';

import ApplicationActionCreators from '../../actions/ApplicationActionCreators';
import { GENERIC_DOCKER_UI_ROWS } from '../../constants/componentFlags';
import { componentTypes } from '../../constants/componentTypes';
import { hasWriterSimpleTableInput } from '../../modules/components/helpers';
import InstalledComponentsActions from '../../modules/components/InstalledComponentsActionCreators';
import InputMappingSource from '../../modules/transformations/react/components/mapping/InputMappingSource';
import ConfirmButtons from './ConfirmButtons';
import ModalIcon from './ModalIcon';

const isWriterWithoutRows = (component: Map<string, any>) => {
  return (
    component.get('type') === componentTypes.WRITER &&
    !component.get('flags').includes(GENERIC_DOCKER_UI_ROWS)
  );
};

const CreateRowModal = (props: {
  component: Map<string, any>;
  configId: string;
  buckets?: Map<string, any>;
  tables?: Map<string, any>;
  entity?: string;
  show?: boolean;
  onCreate: (tableId: string, name: string, options?: { addStorageTable: boolean }) => Promise<any>;
  onRowRedirect: (rowId: string) => void;
  onHide: () => void;
}) => {
  const [value, setValue] = React.useState('');
  const [isSaving, setIsSaving] = React.useState(false);

  const addStorageTable = hasWriterSimpleTableInput(props.component);
  const showTableSelector = isWriterWithoutRows(props.component) || addStorageTable;

  const entity = props.entity || 'Table';

  const createRows = () => {
    let data = [[value, value]];

    if (showTableSelector && props.buckets && props.tables) {
      const tables = props.buckets.has(value)
        ? props.tables.filter((table) => table.getIn(['bucket', 'id']) === value).toList()
        : List([props.tables.get(value)]);

      data = tables.map((table) => [table.get('id'), table.get('displayName')]).toArray();
    }

    return Promise.mapSeries(data, ([tableId, name]) => {
      return props.onCreate(tableId, name, { addStorageTable });
    })
      .then((result) => {
        if (result.length === 1) {
          return props.onRowRedirect(result[0]['id']);
        }

        return InstalledComponentsActions.loadComponentConfigDataForce(
          props.component.get('id'),
          props.configId
        ).then(() => {
          ApplicationActionCreators.sendNotification({
            type: 'success',
            message: `All ${entity.toLowerCase()}s have been created.`
          });
        });
      })
      .then(props.onHide);
  };

  return (
    <Modal onHide={props.onHide} show={props.show} onEnter={() => setValue('')}>
      <Form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          setIsSaving(true);
          return createRows().finally(() => setIsSaving(false));
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add {entity.toLowerCase()}</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            {showTableSelector ? (
              <>
                <ControlLabel>Select a table or a bucket</ControlLabel>
                <InputMappingSource
                  disableMultiSelect // TODO: Add support here?
                  value={Map({ source: value })}
                  tables={props.tables}
                  buckets={props.buckets}
                  onChange={setValue}
                />
                <HelpBlock>
                  When a bucket is selected a configuration for each table from a bucket will be
                  created.
                </HelpBlock>
              </>
            ) : (
              <>
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  autoFocus
                  type="text"
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setValue(event.target.value)
                  }
                />
              </>
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveButtonType="submit"
            saveLabel="Create"
            isSaving={isSaving}
            isDisabled={!value}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateRowModal;
