import React from 'react';
import type { List } from 'immutable';

import dayjs from '../../../../date';
import ComponentIcon from '../../../../react/common/ComponentIcon';
import CreatedDate from '../../../../react/common/CreatedDate';
import JobPartialRunLabel from '../../../../react/common/JobPartialRunLabel';
import JobStatusLabel from '../../../../react/common/JobStatusLabel';
import RouterLink from '../../../../react/common/RouterLink';
import Truncated from '../../../../react/common/Truncated';
import * as jobsResolver from '../../../jobs/JobComponentResolver';
import { JOBS_STATUS, routeNames as queueRouteNames } from '../../../queue/constants';
import * as queueResolver from '../../../queue/jobResolver';

const LastErrorsPanel = ({ jobs, hasNewQueue }: { jobs: List<any>; hasNewQueue: boolean }) => {
  const lastMonthData = dayjs().subtract(1, 'month');
  const filteredJobs = jobs
    .filter((job) => {
      return (
        !!job.get('config') &&
        [JOBS_STATUS.ERROR, JOBS_STATUS.WARNING].includes(job.get('status')) &&
        dayjs(job.get('createdTime')).isAfter(lastMonthData)
      );
    })
    .take(3);

  return (
    <div className="box box-panel box-panel-small">
      <div className="box-header">
        <h2 className="box-title">Last Errors &amp; Warnings</h2>
      </div>
      {filteredJobs.count() > 0 ? (
        <div className="box-panel-content p-0">
          {filteredJobs
            .map((job) => {
              const resolver = hasNewQueue ? queueResolver : jobsResolver;

              return (
                <RouterLink
                  key={job.get('id')}
                  className="flex-container last-errors-item no-underline"
                  to={hasNewQueue ? queueRouteNames.JOB_DETAIL : 'jobDetail'}
                  params={{ jobId: job.get('id') }}
                >
                  <ComponentIcon
                    component={resolver.getComponentByJob(job)}
                    size="36"
                    className="tw-mr-4"
                  />
                  <div className="component-meta tw-pr-3">
                    <div className="flex-container flex-start">
                      <JobPartialRunLabel job={job} hasNewQueue={hasNewQueue} />
                      <Truncated text={resolver.getConfigurationName(job) || job.get('config')} />
                    </div>
                    <div className="text-muted">
                      <CreatedDate createdTime={job.get('createdTime')} />
                    </div>
                  </div>
                  <JobStatusLabel status={job.get('status')} />
                </RouterLink>
              );
            })
            .toArray()}
        </div>
      ) : (
        <div className="box-panel-content mt-auto">
          <p className="f-16 font-medium text-muted">No recent errors found</p>
        </div>
      )}
    </div>
  );
};

export default LastErrorsPanel;
