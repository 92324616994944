import React from 'react';

import RedshiftCompressionBody from './RedshiftCompressionBody';
import RedshiftCompressionHeader from './RedshiftCompressionHeader';

const RedshiftCompressionColumn = {
  header(updateAllColumnFn) {
    return (
      <RedshiftCompressionHeader
        onChange={(selected) => updateAllColumnFn('compression', selected)}
      />
    );
  },
  body(column, columnType, updateColumnTypesFn) {
    return (
      <RedshiftCompressionBody
        value={columnType.get('compression')}
        onChange={(selected) => updateColumnTypesFn(column, 'compression', selected)}
      />
    );
  }
};

export default RedshiftCompressionColumn;
