import type { Map } from 'immutable';

import { SERVICE_AI } from '../../constants/serviceIds';
import ApplicationStore from '../../stores/ApplicationStore';
import request from '../../utils/request';
import ServicesStore from '../services/Store';

const createRequest = (path: string) => {
  return request('POST', `${ServicesStore.getServiceUrl(SERVICE_AI)}/${path}`)
    .set('X-StorageApi-Token', ApplicationStore.getSapiTokenString())
    .set('Content-Type', 'application/json');
};

const getErrorExplanation = (job: Map<string, any>) => {
  return createRequest('explain')
    .send({ jobId: job.get('id'), error: job.getIn(['result', 'message']) })
    .promise()
    .then((response) => response.body);
};

const sendExplanationFeedback = (data: Record<string, any>) => {
  return createRequest('feedback')
    .send({ type: 'explain', ...data })
    .promise()
    .then((response) => response.body);
};

export { getErrorExplanation, sendExplanationFeedback };
