import React from 'react';
import classNames from 'classnames';
import type { Map } from 'immutable';

import { CONFIGURATIONS_SORT_BY } from '../../../constants/localStorageKeys';
import { setItem } from '../../../utils/localStorage';
import ColumnHeader from './ColumnHeader';

const TableHead = (props: {
  tableInstance: any;
  allowCreateConfig: boolean;
  sortByKey: any;
  customClasses: Map<string, any>;
  readOnly: boolean;
}) => {
  return (
    <div
      {...props.tableInstance.getTableProps({
        className: classNames('table table-hover react-table', {
          'is-sticky bg-color-white': props.tableInstance.rows.length > 10,
          'pb-0': !props.allowCreateConfig
        })
      })}
    >
      <div className="thead">
        {props.tableInstance.headerGroups.map((headerGroup: any, index: number) => (
          <div
            key={index}
            {...headerGroup.getHeaderGroupProps({ className: 'tr with-action-buttons' })}
          >
            {headerGroup.headers.map((column: any) => {
              const selectedRows = Object.keys(props.tableInstance.state.selectedRowIds).length;
              const disableSort = column.id === 'data' && selectedRows > 0;
              const sortByProps = column.getSortByToggleProps({ title: '' });
              const userCellProps = {
                ...(!disableSort && sortByProps.onClick
                  ? {
                      ...sortByProps,
                      onClick: (e: any) => {
                        setItem(
                          `${CONFIGURATIONS_SORT_BY}-${props.sortByKey}`,
                          `${column.id}:${
                            !column.isSorted ? 'asc' : column.isSortedDesc ? 'asc' : 'desc'
                          }`
                        );
                        sortByProps.onClick(e);
                      }
                    }
                  : {}),
                className: classNames('th', props.customClasses.getIn([column.id, 'th']), {
                  'w-125 text-right': ['storage_data', 'used_in'].includes(column.id),
                  'w-175 text-right': column.id === 'last_change',
                  'w-200 text-right': column.id === 'job'
                }),
                title: ''
              };

              return (
                <div key={column.id} {...column.getHeaderProps(userCellProps)}>
                  <ColumnHeader column={column} canSort={column.canSort && !disableSort} />
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableHead;
