import { Map } from 'immutable';

import { KEBOOLA_GOODDATA_WRITER } from '../../constants/componentIds';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';

const updateTableConfiguration = (config, tableId, parameters, inputMapping) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_GOODDATA_WRITER,
    config.get('id'),
    {
      configuration: JSON.stringify(
        config
          .setIn(['configuration', 'parameters', 'tables', tableId], parameters)
          .updateIn(['configuration', 'storage', 'input', 'tables'], (tables) => {
            return tables.map((table) => (table.get('source') === tableId ? inputMapping : table));
          })
          .get('configuration')
          .toJS()
      )
    },
    'Update input mapping and parameters'
  );
};

const resetEditingState = (configId) => {
  InstalledComponentsActionCreators.updateLocalState(KEBOOLA_GOODDATA_WRITER, configId, Map());
};

export { updateTableConfiguration, resetEditingState };
