import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import dayjs from '../../date';
import { formatAbsolute } from './CreatedDate';
import ValidUntil from './ValidUntil';

const ValidUntilWithIcon = ({ validUntil }) => {
  if (!dayjs(validUntil).isValid()) {
    return null;
  }

  return (
    <span title={formatAbsolute(validUntil)}>
      <FontAwesomeIcon icon={['far', 'calendar-days']} /> <ValidUntil validUntil={validUntil} />
    </span>
  );
};

ValidUntilWithIcon.propTypes = {
  validUntil: PropTypes.string
};

export default ValidUntilWithIcon;
