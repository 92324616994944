import React from 'react';
import type { Map } from 'immutable';

import { isComponentDeprecated } from '../../../modules/components/helpers';
import AddNewConfigBlockButton from '../../../modules/components/react/components/AddNewConfigBlockButton';
import MigrationRow from '../../../modules/components/react/components/MigrationRow';

type Props = {
  allComponents: Map<string, any>;
  component: Map<string, any>;
  readOnly: boolean;
  showMigrations: boolean;
  hasConfigurations: boolean;
  hasNewQueue: boolean;
  hasFlows: boolean;
};

const AddNewConfigButton = (props: Props) => {
  if (props.readOnly || (isComponentDeprecated(props.component) && !props.showMigrations)) {
    return null;
  }

  if (isComponentDeprecated(props.component)) {
    return (
      <MigrationRow
        inTable
        componentId={props.component.get('id')}
        replacementAppId={props.component.getIn(['uiOptions', 'replacementApp'])}
        hasNewQueue={props.hasNewQueue}
        hasFlows={props.hasFlows}
      />
    );
  }

  return (
    <AddNewConfigBlockButton
      component={props.component}
      allComponents={props.allComponents}
      hasConfigurations={props.hasConfigurations}
      readOnly={props.readOnly}
    />
  );
};

export default AddNewConfigButton;
