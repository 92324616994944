import React from 'react';
import { Button, HelpBlock } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '../../constants/componentIds';
import { SIDEBAR } from '../../constants/external';
import { FEATURE_RAW_MODE } from '../../constants/features';
import { DEMO_PROJECT_URL, USER_DOCUMENTATION_URL } from '../../constants/KbcConstants';
import SwitchStateLink from '../../react/common/ActionControls/SwitchStateLink';
import CodeEditorModal from '../../react/common/CodeEditorModal';
import CodeEditorPreview from '../../react/common/CodeEditorPreview';
import CopyButton from '../../react/common/ConfigurationsTable/CopyButton';
import ConfigurationTabs from '../../react/common/ConfigurationTabs';
import ExternalLink from '../../react/common/ExternalLink';
import GitSettingBox from '../../react/common/GitSettingBox';
import RouterLink from '../../react/common/RouterLink';
import SaveButtons from '../../react/common/SaveButtons';
import Select from '../../react/common/Select';
import UsedInFlowsModal from '../../react/common/UsedInFlowsModal';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import { ioType } from '../components/Constants';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import ComponentDescription from '../components/react/components/ComponentDescription';
import FileInputMapping from '../components/react/components/generic/FileInputMapping';
import TableInputMapping from '../components/react/components/generic/TableInputMapping';
import MappingWrapper from '../components/react/components/MappingsWrapper';
import ScheduleConfigurationButton from '../components/react/components/ScheduleConfigurationButton';
import SidebarJobs from '../components/react/components/SidebarJobs';
import SidebarVersions from '../components/react/components/SidebarVersions';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import StorageBucketsStore from '../components/stores/StorageBucketsStore';
import StorageTablesStore from '../components/stores/StorageTablesStore';
import VersionsStore from '../components/stores/VersionsStore';
import { routeNames as componentsRoutes } from '../components-directory/constants';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import NotificationsStore from '../notifications/store';
import JobsStore from '../queue/store';
import { prepareSandboxes } from '../sandboxes/helpers';
import SandboxesStore from '../sandboxes/SandboxesStore';
import DeleteDataApp from './components/DeleteDataApp';
import DeployDataApp from './components/DeployDataApp';
import OpenDataApp from './components/OpenDataApp';
import SecretsBox from './components/SecretsBox';
import TerminateDataApp from './components/TerminateDataApp';
import { routeNames } from './constants';

const UI_FORM = {
  GIT: 'git',
  CODE: 'code'
};

const SandboxDetail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      SandboxesStore,
      InstalledComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
      NotificationsStore,
      DevBranchesStore,
      ComponentsStore,
      JobsStore,
      VersionsStore
    )
  ],

  getStateFromStores() {
    const componentId = KEBOOLA_DATA_APPS;
    const configId = RoutesStore.getCurrentRouteParam('config');
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const sandbox = SandboxesStore.getSandbox(configData.getIn(['parameters', 'id']));

    return {
      componentId,
      configId,
      sandbox,
      configData,
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      allComponents: ComponentsStore.getAll(),
      allConfigurations: InstalledComponentsStore.getAll(),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(componentId)
      ),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      latestJobs: JobsStore.getLatestJobs(componentId, configId),
      config: InstalledComponentsStore.getConfig(componentId, configId),
      editingConfigData: InstalledComponentsStore.getEditingConfigDataObject(componentId, configId),
      componentPendingActions: InstalledComponentsStore.getPendingActions(componentId, configId),
      pendingActions: SandboxesStore.getPendingActions(),
      admins: ApplicationStore.getAdmins(),
      versions: VersionsStore.getVersions(componentId, configId),
      notifications: NotificationsStore.getAll(),
      versionsConfigs: VersionsStore.getVersionsConfigs(componentId, configId),
      isLoadingVersions: VersionsStore.isLoadingVersions(componentId, configId),
      isPendingVersions: VersionsStore.isPendingConfig(componentId, configId),
      pendingMultiLoadVersions: VersionsStore.getPendingMultiLoad(componentId, configId),
      hasRawMode: ApplicationStore.hasCurrentAdminFeature(FEATURE_RAW_MODE),
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      isDemoPreview: ApplicationStore.isDemoPreview(),
      hasFlows: ApplicationStore.hasFlows()
    };
  },

  getInitialState() {
    return {
      forceType: null,
      formData: Map(),
      editingCode: '',
      savingCode: false,
      savingPackage: false,
      isTogglingAuthorization: false,
      showCodeEditorModal: false
    };
  },

  render() {
    return (
      <>
        <ConfigurationTabs
          componentId={this.state.componentId}
          configId={this.state.configId}
          versionsLinkTo="data-apps-versions"
        />
        {this.renderInfoPanel()}
        <div className="row box-separator">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={this.state.componentId}
              configId={this.state.configId}
              readOnly={this.state.readOnly}
              placeholderEntity="Data App"
            />
            {this.renderTypeSelector()}
            {this.renderGitRepository()}
            {this.renderPackages()}
            {this.renderSecrets()}
            <MappingWrapper>
              {this.tableInputMapping()}
              {this.fileInputMapping()}
            </MappingWrapper>
            {this.renderCode()}
          </div>
          <div className="col-sm-3">
            <div className={`sidebar-content ${SIDEBAR}`}>
              <div className="nav nav-stacked">
                <li>
                  <DeployDataApp
                    mode="sidebar"
                    config={this.state.config}
                    sandbox={this.state.sandbox}
                    readOnly={this.state.readOnly}
                  />
                  {!this.state.sandbox.get('active', false) && <hr />}
                </li>
                {this.state.sandbox.get('active', false) && (
                  <li className="!tw-mt-2">
                    <OpenDataApp
                      mode="sidebar"
                      config={this.state.config}
                      sandbox={this.state.sandbox}
                    />
                    <hr />
                  </li>
                )}
                <li>
                  <SwitchStateLink
                    label="Authorization Enabled"
                    readOnly={this.state.readOnly}
                    isActive={this.isAuthorizationEnabled()}
                    onChange={this.handleAuthorizationSwitchChange}
                    isPending={this.state.isTogglingAuthorization}
                  />
                </li>
                <li>
                  <TerminateDataApp
                    mode="sidebar"
                    readOnly={this.state.readOnly}
                    config={this.state.config}
                    sandbox={this.state.sandbox}
                    isPending={this.state.pendingActions.hasIn([
                      'terminate',
                      this.state.sandbox.get('id')
                    ])}
                  />
                </li>
                {this.renderCopyButton()}
                {this.renderScheduleConfigurationButton()}
                {this.renderRawConfigurationButton()}
                <hr />
                <DeleteDataApp
                  mode="sidebar"
                  readOnly={this.state.readOnly}
                  config={this.state.config}
                  sandbox={this.state.sandbox}
                  isDeleting={this.state.pendingActions.hasIn([
                    'delete',
                    this.state.sandbox.get('id')
                  ])}
                />
              </div>
              <SidebarJobs
                hasNewQueue
                jobs={this.state.latestJobs}
                componentId={this.state.componentId}
                configId={this.state.configId}
                allConfigurations={this.state.allConfigurations}
                admins={this.state.admins}
                currentAdmin={this.state.currentAdmin}
                notifications={this.state.notifications}
              />
              <hr />
              <SidebarVersions
                configId={this.state.configId}
                component={this.state.component}
                config={this.state.config}
                versionsLinkTo={routeNames.DATA_APP_VERSIONS}
                versions={this.state.versions}
                versionsConfigs={this.state.versionsConfigs}
                isLoading={this.state.isLoadingVersions}
                isPending={this.state.isPendingVersions}
                pendingMultiLoad={this.state.pendingMultiLoadVersions}
                admins={this.state.admins}
              />
            </div>
          </div>
          {this.renderCodeEditorModal()}
        </div>
      </>
    );
  },

  renderInfoPanel() {
    return (
      <div className="box box-separator panel-info">
        <div className="box-content">
          <div className="flex-container">
            <div className="flex-container align-top line-height-24">
              <div className="panel-info-item no-wrap">
                <span className="text-muted">Type:</span>
                <span className="flex-container flex-start font-medium">
                  <FontAwesomeIcon icon="browser" className="f-16 icon-addon-right text-muted" />
                  Data App
                </span>
              </div>
              <div className="panel-info-item no-wrap">
                <span className="text-muted">Used in:</span>
                <span className="flex-container flex-start">
                  <UsedInFlowsModal
                    config={this.state.config}
                    component={this.state.component}
                    flows={this.state.flows}
                  />
                </span>
              </div>
              <div className="panel-info-item no-wrap aside">
                <span className="text-muted">Additional Links:</span>
                <span className="flex-container flex-start">
                  <span className="mr-1 no-wrap">
                    <FontAwesomeIcon icon="book-blank" className="text-muted icon-addon-right" />
                    <ExternalLink href={`${USER_DOCUMENTATION_URL}/components/data-apps`}>
                      Documentation
                    </ExternalLink>
                  </span>
                  {!this.state.isDemoPreview && (
                    <span className="no-wrap">
                      <FontAwesomeIcon icon="laptop" className="text-muted icon-addon-right" />
                      <ExternalLink href={`${DEMO_PROJECT_URL}/app/data-apps`}>
                        Demo Project
                      </ExternalLink>
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },

  renderCopyButton() {
    if (this.state.hasBackflow || this.state.readOnly) {
      return null;
    }

    return (
      <li>
        <CopyButton
          mode="sidebar"
          configuration={this.state.config}
          component={this.state.component}
          hasFlows={this.state.hasFlows}
        />
      </li>
    );
  },

  renderScheduleConfigurationButton() {
    if (this.state.hasBackflow || this.state.readOnly || this.state.isDevModeActive) {
      return null;
    }

    return (
      <li>
        <ScheduleConfigurationButton
          hasNewQueue
          flows={this.state.flows}
          component={this.state.component}
          config={this.state.config}
          hasFlows={this.state.hasFlows}
        />
      </li>
    );
  },

  renderRawConfigurationButton() {
    if (!this.state.hasRawMode) {
      return null;
    }

    return (
      <li>
        <RouterLink
          to={componentsRoutes.GENERIC_CONFIG_RAW}
          params={{ component: this.state.componentId, config: this.state.configId }}
          className="btn btn-link btn-block btn-link-inline"
        >
          <FontAwesomeIcon icon="bug" fixedWidth />
          Debug Mode
        </RouterLink>
      </li>
    );
  },

  tableInputMapping() {
    return (
      <TableInputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        destinationType={ioType.FILE}
        configId={this.state.configId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        value={this.state.configData.getIn(['storage', 'input', 'tables'], List())}
        editingValue={this.state.editingConfigData.getIn(['storage', 'input', 'tables'], Map())}
        tables={this.state.tables}
        buckets={this.state.buckets}
        pendingActions={this.state.componentPendingActions}
        allComponents={this.state.allComponents}
        sandboxes={this.state.sandboxes}
      />
    );
  },

  fileInputMapping() {
    return (
      <FileInputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        value={this.state.configData.getIn(['storage', 'input', 'files'], List())}
        editingValue={this.state.editingConfigData.getIn(['storage', 'input', 'files'], Map())}
        pendingActions={this.state.componentPendingActions}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        allComponents={this.state.allComponents}
        sandboxes={this.state.sandboxes}
      />
    );
  },

  renderTypeSelector() {
    return (
      <div className="box">
        <div className="box-header smaller ptp-5 prp-6 plp-6">
          <h2 className="box-title line-height-24">Deployment Type</h2>
        </div>
        <div className="box-content ptp-5 prp-6 pbp-6 plp-6">
          <Select
            clearable={false}
            searchable={false}
            value={this.resolveType()}
            onChange={(selected) => this.setState({ forceType: selected })}
            options={[
              { label: 'Git Repository', value: UI_FORM.GIT },
              { label: 'Code', value: UI_FORM.CODE }
            ]}
            disabled={this.state.readOnly}
          />
        </div>
      </div>
    );
  },

  renderGitRepository() {
    if (this.resolveType() !== UI_FORM.GIT) {
      return null;
    }

    return (
      <GitSettingBox
        title="Data App Repository"
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        configData={this.state.configData}
        prepareDataBeforeSave={(configData) => {
          return configData.deleteIn(['parameters', 'script']).deleteIn(['parameters', 'packages']);
        }}
      />
    );
  },

  renderPackages() {
    if (this.resolveType() !== UI_FORM.CODE) {
      return null;
    }

    return (
      <div className="box">
        <div className="box-header smaller ptp-5 prp-6 plp-6">
          <h2 className="box-title line-height-24">Packages</h2>
        </div>
        <div className="box-content ptp-5 prp-6 pbp-6 plp-6">
          <Select
            multi
            allowCreate
            trimMultiCreatedValues
            value={this.state.configData.getIn(['parameters', 'packages'], List())}
            placeholder="Add packages"
            onChange={(packages) => {
              this.setState({ savingPackage: true });
              return InstalledComponentsActionCreators.updateComponentConfiguration(
                this.state.componentId,
                this.state.configId,
                {
                  configuration: JSON.stringify(
                    this.state.configData
                      .deleteIn(['parameters', 'dataApp', 'git'])
                      .setIn(['parameters', 'packages'], packages)
                  )
                },
                'Change packages'
              ).finally(() => this.setState({ savingPackage: false }));
            }}
            promptTextCreator={(label) => `Add package "${label}"`}
            disabled={this.state.savingPackage || this.state.readOnly}
          />
          <HelpBlock className="mb-0">
            Learn more about package installation and usage, and about the list of pre-installed
            packages in our{' '}
            <ExternalLink href={`${USER_DOCUMENTATION_URL}/components/data-apps/#packages`}>
              documentation
            </ExternalLink>
            .
          </HelpBlock>
        </div>
      </div>
    );
  },

  renderSecrets() {
    return (
      <SecretsBox
        readOnly={this.state.readOnly}
        configId={this.state.configId}
        componentId={this.state.componentId}
        configData={this.state.configData}
        secrets={this.state.configData.getIn(['parameters', 'dataApp', 'secrets'], Map())}
      />
    );
  },

  renderCode() {
    if (this.resolveType() !== UI_FORM.CODE) {
      return null;
    }

    const code = this.getScriptCode();

    if (!code && !this.state.readOnly) {
      return (
        <div className="box">
          <div className="box-header big-padding with-border prp-6 plp-6">
            <h2 className="box-title">Code</h2>
          </div>
          <div className="box-content text-center">
            <Button bsStyle="success" onClick={() => this.setState({ showCodeEditorModal: true })}>
              Add Code
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="box">
        <div className="box-header big-padding with-border prp-6 plp-6">
          <h2 className="box-title">Code</h2>
          <Button bsStyle="success" onClick={this.handleOpenEditor}>
            {this.state.readOnly ? 'Show Code' : 'Edit Code'}
          </Button>
        </div>
        <div className="box-content">
          <CodeEditorPreview
            readOnly={this.state.readOnly}
            code={code}
            mode="text/x-python"
            onClick={this.handleOpenEditor}
          />
        </div>
      </div>
    );
  },

  renderCodeEditorModal() {
    if (!this.state.showCodeEditorModal) {
      return null;
    }

    return (
      <CodeEditorModal
        autoFocus
        withAutocomplete
        editorKey={`data-app-${this.state.configId}}`}
        title="Code"
        value={this.state.editingCode}
        isChanged={this.isCodeChanged()}
        onChange={this.handleCodeChange}
        onSave={this.handleCodeSave}
        onReset={this.handleCodeReset}
        onClose={() => this.setState({ showCodeEditorModal: false })}
        renderAdditionalButtons={() => (
          <SaveButtons
            disabled={this.state.readOnly}
            isSaving={this.state.savingCode}
            isChanged={this.isCodeChanged()}
            onSave={this.handleCodeSave}
            onReset={this.handleCodeReset}
          />
        )}
        codeMirrorOptions={this.getCodeMirrorOptions()}
      />
    );
  },

  handleCodeChange(code) {
    this.setState({ editingCode: code });
  },

  handleCodeReset() {
    this.setState({ editingCode: this.getScriptCode() });
  },

  handleOpenEditor() {
    this.handleCodeReset();
    this.setState({ showCodeEditorModal: true });
  },

  handleCodeSave() {
    this.setState({ savingCode: true });
    return InstalledComponentsActionCreators.updateComponentConfiguration(
      this.state.componentId,
      this.state.configId,
      {
        configuration: JSON.stringify(
          this.state.configData
            .deleteIn(['parameters', 'dataApp', 'git'])
            .update('parameters', Map(), (parameters) => {
              if (
                parameters.getIn(['dataApp', 'git'], Map()).isEmpty() &&
                parameters.getIn(['dataApp', 'secrets'], Map()).isEmpty()
              ) {
                return parameters.delete('dataApp');
              }

              return parameters;
            })
            .setIn(['parameters', 'script'], List([this.state.editingCode]))
        )
      },
      'Change code'
    ).finally(() => this.setState({ savingCode: false }));
  },

  handleAuthorizationSwitchChange() {
    const isEnabled = this.isAuthorizationEnabled();

    this.setState({ isTogglingAuthorization: true });
    return InstalledComponentsActionCreators.updateComponentConfiguration(
      this.state.componentId,
      this.state.configId,
      {
        configuration: JSON.stringify(
          this.state.configData.setIn(['parameters', 'dataApp', 'streamlitAuthEnabled'], !isEnabled)
        )
      },
      `Authorization ${isEnabled} 'disabled' : 'enabled'`
    ).finally(() => this.setState({ isTogglingAuthorization: false }));
  },

  isCodeChanged() {
    return this.getScriptCode() !== this.state.editingCode;
  },

  getScriptCode() {
    return this.state.configData.getIn(['parameters', 'script', 0], '');
  },

  getCodeMirrorOptions() {
    const commonOptions = { mode: 'text/x-python', placeholder: '-- Your code goes here' };

    if (this.state.readOnly) {
      return { ...commonOptions, cursorHeight: 0, readOnly: true };
    }

    return {
      ...commonOptions,
      hintOptions: {
        completeSingle: false,
        container: document.querySelector('.full-screen-modal.full-screen-editor')
      }
    };
  },

  isAuthorizationEnabled() {
    return this.state.configData.getIn(['parameters', 'dataApp', 'streamlitAuthEnabled'], false);
  },

  resolveType() {
    if (!this.state.forceType) {
      return !!this.getScriptCode() ? 'code' : 'git';
    }

    return this.state.forceType;
  }
});

export default SandboxDetail;
