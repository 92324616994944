import { JSONEditor } from '@json-editor/json-editor';
import CodeMirror from 'codemirror';
import _ from 'underscore';

import { isValidJsonConfig } from '../../validation';

export default class CodeMirrorPlugin extends JSONEditor.defaults.editors.string {
  preBuild() {
    this.options.format = 'textarea';

    // default options for editor
    this.codeMirrorOptions = {
      mode: 'application/json',
      viewportMargin: 1000,
      lineNumbers: true,
      lint: true
    };

    this.editableOptions = ['mode', 'placeholder', 'autofocus', 'lineNumbers', 'lint', 'readOnly'];
  }

  postBuild() {
    // for same CSS ass classic react codemirror editor
    this.input.parentNode.classList.add('react-codemirror2');

    // options can be overwritten for all properties or for individual property
    this.codeMirrorOptions = this.expandCallbacks('editor', {
      ...this.codeMirrorOptions,
      ...this.defaults.options.editor,
      ...this.options.editor
    });

    // if mode is json add info about encrypt, same as original codemirror editor
    if (this.isJsonMode() && !this.options.editor?.readOnly) {
      const helpText = document.createElement('span');
      helpText.classList.add('help-block');
      helpText.innerHTML = `Properties prefixed with <code>#</code> sign will be encrypted on save. Already encrypted strings will persist.`;
      this.input.parentNode.appendChild(helpText);
    }

    this.codemirror_instance = new CodeMirror.fromTextArea(
      this.input,
      _.pick(this.codeMirrorOptions, ...this.editableOptions)
    );

    // get value and set it to codemirror, always as string
    this.codemirror_instance.setValue(this.sanitize(this.getValue()));

    this.codemirror_instance.on('change', (instance) => {
      // get value from codemirror and save to json-editor instance
      this.input.value = instance.getValue();
      this.is_dirty = true;
      this.refreshValue();
      this.onChange(true);
    });
  }

  setValue(value, initial) {
    const sanitized = this.sanitize(value);

    if (this.input.value === sanitized) {
      return;
    } else if (initial) {
      this.is_dirty = false;
    }

    // set value to textarea, always as string
    this.setValueToInputField(sanitized);
    this.refreshValue();
    this.onChange(true);

    if (this.codemirror_instance) {
      // set value to coremirror, always as string
      this.codemirror_instance.setValue(sanitized);
    }
  }

  isJsonMode() {
    return this.codeMirrorOptions.mode === 'application/json';
  }

  getValue() {
    if (!this.codemirror_instance) {
      return '';
    }

    // return object if mode is json and if is string is valid json
    if (this.isJsonMode() && isValidJsonConfig(this.input.value)) {
      return JSON.parse(this.input.value);
    }

    return this.input.value;
  }

  sanitize(value) {
    // convert object to json string
    if (typeof value === 'object') {
      return JSON.stringify(value, null, '  ');
    }

    return value;
  }

  enable() {
    // turn off readonly after save
    if (
      !this.always_disabled &&
      this.codemirror_instance?.isReadOnly() &&
      !this.codeMirrorOptions?.readOnly
    ) {
      this.codemirror_instance.setOption('readOnly', false);
    }

    super.enable();
  }

  disable(alwaysDisabled) {
    // turn on readonly during save
    if (!this.codemirror_instance?.isReadOnly()) {
      this.codemirror_instance.setOption('readOnly', true);
    }

    super.disable(alwaysDisabled);
  }

  showValidationErrors() {
    // we use codemirorr lint to show errors
    return null;
  }
}
