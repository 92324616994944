import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { EXCLUDE_FROM_NEW_LIST } from '../../../../../constants/componentFlags';
import {
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_SHARED_CODE,
  ORCHESTRATOR
} from '../../../../../constants/componentIds';
import ComponentName from '../../../../../react/common/ComponentName';
import MarkedText from '../../../../../react/common/MarkedText';
import Select from '../../../../../react/common/Select';
import matchByWords from '../../../../../utils/matchByWords';

const ComponentsSelector = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedComponents: PropTypes.object.isRequired,
    allComponents: PropTypes.object.isRequired,
    hasNewQueue: PropTypes.bool.isRequired
  },

  render() {
    return (
      <Select
        multi
        allowCreate
        placeholder="Select component..."
        disabled={this.props.disabled}
        value={this.props.selectedComponents}
        onChange={this.props.onChange}
        options={this.getOptions()}
        filterOption={(option, filter) =>
          matchByWords([option.value, option.componentName], filter)
        }
      />
    );
  },

  getOptions() {
    return this.props.allComponents
      .filter((component) => {
        if (
          [
            this.props.hasNewQueue ? KEBOOLA_ORCHESTRATOR : ORCHESTRATOR,
            KEBOOLA_SHARED_CODE
          ].includes(component.get('id'))
        ) {
          return true;
        }

        return !component.get('flags').includes(EXCLUDE_FROM_NEW_LIST);
      })
      .sortBy((component) => component.get('name'))
      .map((component, componentId) => ({
        label: (inputString) => (
          <ComponentName component={component} capitalize showType>
            {(componentName) => <MarkedText source={componentName} mark={inputString} />}
          </ComponentName>
        ),
        value: componentId,
        componentName: component.get('name')
      }))
      .toArray();
  }
});

export default ComponentsSelector;
