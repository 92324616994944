import React from 'react';
import { Button } from 'react-bootstrap';
import Switch from 'rc-switch';

import { USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import ExternalLink from '../../../react/common/ExternalLink';
import Tooltip from '../../../react/common/Tooltip';
import contactSupport from '../../../utils/contactSupport';

const DataPersistency = (props: { isActive: boolean }) => {
  return (
    <div className="well pp-6">
      <h3 className="tw-mt-0 tw-mb-6">
        Workspace Data Persistency
        <Tooltip
          placement="top"
          tooltip={`Contact support to ${props.isActive ? 'disable' : 'enable'}`}
        >
          <span>
            <Switch
              disabled
              prefixCls="switch"
              className="icon-addon-left wider"
              checked={props.isActive}
            />
          </span>
        </Tooltip>
      </h3>
      <div className="value-list mb-0">
        <p className="tw-mb-6">
          When enabled newly created workspaces will have all the changes saved with Auto Sleep.
          Additional charges are applied, see{' '}
          <ExternalLink
            href={`${USER_DOCUMENTATION_URL}/transformations/workspace/#data-persistency-beta`}
          >
            documentation
          </ExternalLink>
          .
        </p>
        <Button bsSize="sm" onClick={() => contactSupport()}>
          Contact Support
        </Button>
      </div>
    </div>
  );
};

export default DataPersistency;
