import { fromJS } from 'immutable';

import dispatcher from '../../Dispatcher';
import { HTTP_STATUS_CODE_NOT_FOUND } from '../../utils/errors/helpers';
import { ActionTypes } from './Constants';
import api from './WorkspacesApi';

const getConfigurationWorkspaces = (componentId, configId) => {
  return api
    .loadConfigurationWorkspaces(componentId, configId)
    .catch((error) => {
      if (error.response && error.response.status === HTTP_STATUS_CODE_NOT_FOUND) {
        return [];
      }

      throw error;
    })
    .then((workspaces) => {
      dispatcher.handleViewAction({
        type: ActionTypes.CONFIGURATION_WORKSPACES_LOAD_SUCCESS,
        workspaces,
        componentId,
        configId
      });
      return null;
    });
};

const createConfigurationWorkspace = (componentId, configId, params) => {
  return api.createConfigurationWorkspace(componentId, configId, params).then((workspace) => {
    dispatcher.handleViewAction({
      type: ActionTypes.CONFIGURATION_WORKSPACE_CREATE_SUCCESS,
      componentId,
      configId,
      workspace
    });
    return fromJS(workspace);
  });
};

const deleteConfigurationWorkspace = (componentId, configId, workspaceId) => {
  return api.deleteWorkspace(workspaceId).then(() => {
    dispatcher.handleViewAction({
      type: ActionTypes.CONFIGURATION_WORKSPACES_DELETE_SUCCESS,
      componentId,
      configId,
      workspaceId
    });
    return null;
  });
};

const resetWorkspacePassword = (componentId, configId, workspaceId) => {
  return api.resetPassword(workspaceId).then((workspace) => {
    dispatcher.handleViewAction({
      type: ActionTypes.CONFIGURATION_WORKSPACES_PASSWORD_RESET_SUCCESS,
      componentId,
      configId,
      workspaceId,
      password: workspace.password
    });
    return fromJS(workspace);
  });
};

const hideWorkspacePassword = (componentId, configId, workspaceId) => {
  return dispatcher.handleViewAction({
    type: ActionTypes.CONFIGURATION_WORKSPACES_HIDE_PASSWORD,
    componentId,
    configId,
    workspaceId
  });
};

export default {
  createConfigurationWorkspace,
  deleteConfigurationWorkspace,
  getConfigurationWorkspaces,
  resetWorkspacePassword,
  hideWorkspacePassword
};
