export const routeNames = {
  ROOT: 'flows',
  DETAIL: 'flow'
};

export const tabs = {
  BUILDER: 'builder',
  ALL_RUNS: 'all-runs',
  NOTIFICATIONS: 'notifications',
  VERSIONS: 'versions'
};
