import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { USER_DOCUMENTATION_URL } from '../../../../../constants/KbcConstants';
import ExternalLink from '../../../../../react/common/ExternalLink';
import Popover from '../../../../../react/common/Popover';
import SearchPanel from '../../../../../react/common/SearchPanel';
import ApplicationStore from '../../../../../stores/ApplicationStore';

class QueryRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: this.props.query,
      currentUserEmail: ApplicationStore.getCurrentAdmin().get('email')
    };
  }

  render() {
    return (
      <SearchPanel
        autoFocus
        onSearch={this.props.onSearch}
        query={this.state.query}
        predefinedSearches={this.getPredefinedSearches()}
        additionalActions={this.additionalActions()}
        searchFieldPlaceholder="Search jobs"
      />
    );
  }

  getPredefinedSearches() {
    return [
      {
        name: 'Long running',
        query: 'durationSeconds:>7200'
      },
      {
        name: 'My jobs',
        query: 'token.description:' + this.state.currentUserEmail
      },
      {
        name: 'My failed jobs',
        query: 'status:error AND token.description:' + this.state.currentUserEmail
      },
      {
        name: 'Failed in last 7 days',
        query: 'status:error AND startTime:>now-7d'
      }
    ];
  }

  additionalActions() {
    return (
      <Popover
        triggerOnClickOnly
        title="Quick help"
        className="popover-wide"
        placement="bottom"
        popover={
          <>
            <h3>Search attributes</h3>
            <dl>
              <dt>Job status</dt>
              <dd>
                <code>status:success</code>
              </dd>
              <dt>User who created the job</dt>
              <dd>
                <code>token.description:john.doe@company.com</code>
              </dd>
              <dt>Component name</dt>
              <dd>
                <code>params.component:keboola.ex-http</code>
              </dd>
              <dt>Config ID</dt>
              <dd>
                <code>params.config:351711187</code>
              </dd>
              <dt>Duration</dt>
              <dd>
                <code>durationSeconds:&gt;120</code>
              </dd>
              <dt>Time started</dt>
              <dd>
                <code>startTime:[2018-06-21 TO 2018-07-01]</code>
              </dd>
              <dt>Time finished</dt>
              <dd>
                <code>endTime:[2018-06-21 TO 2018-07-01]</code>
              </dd>
              <h3>Modifiers and combining queries</h3>

              <dt>Exclude some results</dt>
              <dd>
                <code>-status:success</code>
                <br /> Note the minus sign before the query
              </dd>

              <dt>Combine queries</dt>
              <dd>
                <code>+params.component:keboola.ex-http +status:error</code>
              </dd>
              <dt>Combine queries with more possible values</dt>
              <dd>
                <code>+params.component:(keboola.ex-http OR keboola.wr-google-sheets)</code>
                <br />
                Jobs from either HTTP or Google Sheets component
              </dd>
              <dt>Complex query</dt>
              <dd>
                <code>
                  +params.component:(keboola.ex-http OR keboola.wr-google-sheets) AND
                  -status:success
                </code>
              </dd>
              <dt>Open ended time query</dt>
              <dd>
                <code>endTime:[2018-06-21 TO *]</code>
              </dd>
            </dl>
            <p>
              You can{' '}
              <ExternalLink
                href={`${USER_DOCUMENTATION_URL}/management/jobs/#searching-the-jobs-log`}
              >
                find more complex examples in documentation
              </ExternalLink>
            </p>
          </>
        }
      >
        <Button bsStyle="link" title="Show Quick help" className="quick-help-button">
          <FontAwesomeIcon icon="circle-question" />
        </Button>
      </Popover>
    );
  }
}

QueryRow.propTypes = {
  onSearch: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired
};

export default QueryRow;
