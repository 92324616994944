import React from 'react';

import {
  KEBOOLA_EX_DB_MSSQL,
  KEBOOLA_EX_DB_MYSQL,
  KEBOOLA_EX_DB_ORACLE,
  KEBOOLA_EX_DB_PGSQL,
  KEBOOLA_EX_DB_REDSHIFT,
  KEBOOLA_EX_DB_SNOWFLAKE,
  KEBOOLA_EX_TERADATA
} from '../../constants/componentIds';
import { USER_DOCUMENTATION_URL } from '../../constants/KbcConstants';
import ExternalLink from '../../react/common/ExternalLink';
import { hasSshTunnel } from '../ex-db-generic/helpers';
import defaultPorts from '../ex-db-generic/templates/defaultPorts';

const defaultFields = {
  user: {
    name: 'user',
    label: 'Username',
    help: 'User with at least read rights to objects you want to load from your database to Keboola Connection.'
  },
  password: {
    name: '#password',
    label: 'Password',
    type: 'password',
    help: 'Type or paste in your password. Keep in mind that passwords are case sensitive.'
  },
  database: {
    name: 'database',
    label: 'Database',
    help: 'Fill in the name of your database. Be careful, database names are case sensitive, for example: my_awesome_db is not the same as my_awesome_DB.'
  }
};

export const HostnameHelp = ({ url, componentId }) => (
  <p>
    Name or address of your database without protocol part (https); <br />
    <span className="break-all">
      e.g. <code>{url}</code>
    </span>{' '}
    or <code>203.100.16.10</code>. <br />
    Your server must be accessible from{' '}
    <ExternalLink
      href={`${USER_DOCUMENTATION_URL}/components/ip-addresses/#connectionnorth-europeazurekeboolacom`}
    >
      Keboola Connection
    </ExternalLink>
    {hasSshTunnel(componentId) && (
      <>
        {' '}
        or you must set up SSH tunneling in advanced configuration mode as described in the{' '}
        <ExternalLink
          href={`${USER_DOCUMENTATION_URL}/components/extractors/database/#connecting-to-database`}
        >
          documentation
        </ExternalLink>
      </>
    )}
    .
  </p>
);

const mysqlFields = [
  {
    name: 'host',
    label: 'Hostname / Server address',
    help: <HostnameHelp url="mysql.mydomain.com" componentId={KEBOOLA_EX_DB_MYSQL} />
  },
  {
    name: 'port',
    value: defaultPorts(KEBOOLA_EX_DB_MYSQL)
  },
  defaultFields.user,
  {
    ...defaultFields.password,
    help: 'Type or paste in your password. Keep in mind that passwords in MySQL are case sensitive.'
  },
  defaultFields.database,
  {
    name: 'networkCompression',
    value: true
  }
];

const snowflakeFields = [
  {
    name: 'host',
    label: 'Hostname / Server address',
    help: (
      <HostnameHelp
        url="xx1234.west-europe.azure.snowflakecomputing.com"
        componentId={KEBOOLA_EX_DB_SNOWFLAKE}
      />
    )
  },
  {
    name: 'port',
    value: defaultPorts(KEBOOLA_EX_DB_SNOWFLAKE)
  },
  defaultFields.user,
  defaultFields.password,
  {
    ...defaultFields.database,
    help: 'Fill in the name of your database. Be careful—database names are case sensitive, for example: my_awesome_db is not the same as MY_AWESOME_DB. Snowflake uses uppercase letters for all objects by default.'
  },
  {
    name: 'schema',
    label: 'Schema',
    help: 'Enter your schema in Snowflake. Schemas are used to organize objects in the db in a folder-like structure. PUBLIC is the default schema for objects created without a specific schema. If you do not know the schemas in your db, use the default schema PUBLIC.',
    defaultValue: 'PUBLIC'
  },
  {
    name: 'warehouse',
    label: 'Warehouse',
    help: 'Enter your warehouse name in Snowflake. The warehouse serves as independent computing power for your database. COMPUTE_WH is the default warehouse name created for your Snowflake account.',
    defaultValue: 'COMPUTE_WH'
  }
];

const mssqlFields = [
  {
    name: 'host',
    label: 'Hostname / Server address',
    help: <HostnameHelp url="myserver.database.windows.net" componentId={KEBOOLA_EX_DB_MSSQL} />
  },
  {
    name: 'port',
    value: defaultPorts(KEBOOLA_EX_DB_MSSQL)
  },
  defaultFields.user,
  defaultFields.password,
  {
    ...defaultFields.database,
    help: 'Fill in the name of your database.'
  }
];

const pgsqlFields = [
  {
    name: 'host',
    label: 'Hostname / Server address',
    help: <HostnameHelp url="mydb.postgres.database.azure.com" componentId={KEBOOLA_EX_DB_PGSQL} />
  },
  {
    name: 'port',
    value: defaultPorts(KEBOOLA_EX_DB_PGSQL)
  },
  defaultFields.user,
  defaultFields.password,
  defaultFields.database
];

const redshiftFields = [
  {
    name: 'host',
    label: 'Hostname / Server address',
    help: (
      <HostnameHelp
        url="mydb.123456789.us-west-2.redshift.amazonaws.com"
        componentId={KEBOOLA_EX_DB_REDSHIFT}
      />
    )
  },
  {
    name: 'port',
    value: defaultPorts(KEBOOLA_EX_DB_REDSHIFT)
  },
  defaultFields.user,
  defaultFields.password,
  defaultFields.database
];

const teradataFields = [
  {
    name: 'host',
    label: 'Hostname / Server address',
    help: <HostnameHelp url="mydb.td.teradata.com" componentId={KEBOOLA_EX_TERADATA} />
  },
  {
    name: 'port',
    value: defaultPorts(KEBOOLA_EX_TERADATA)
  },
  defaultFields.user,
  defaultFields.password,
  defaultFields.database
];

const oracleFields = [
  {
    name: 'host',
    label: 'Hostname / Server address',
    help: <HostnameHelp url="mydb.oracle.com" componentId={KEBOOLA_EX_DB_ORACLE} />
  },
  {
    name: 'port',
    label: 'Port',
    type: 'number',
    defaultValue: defaultPorts(KEBOOLA_EX_DB_ORACLE),
    help: 'The port used to connect to your database.'
  },
  {
    name: 'database',
    label: 'Service Name/SID',
    help: 'Service name, SID, or database name identify your database within your server cluster. Keep in mind that these are case sensitive so myORAdb is not the same as myoradb.'
  },
  defaultFields.user,
  defaultFields.password
];

const COMPONENTS_FIELDS = {
  [KEBOOLA_EX_DB_MYSQL]: mysqlFields,
  [KEBOOLA_EX_DB_SNOWFLAKE]: snowflakeFields,
  [KEBOOLA_EX_DB_MSSQL]: mssqlFields,
  [KEBOOLA_EX_DB_PGSQL]: pgsqlFields,
  [KEBOOLA_EX_DB_REDSHIFT]: redshiftFields,
  [KEBOOLA_EX_TERADATA]: teradataFields,
  [KEBOOLA_EX_DB_ORACLE]: oracleFields
};

export function getFields(componentId) {
  if (!COMPONENTS_FIELDS[componentId]) {
    return Object.values(defaultFields);
  }

  return COMPONENTS_FIELDS[componentId];
}
