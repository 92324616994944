import React from 'react';
import PropTypes from 'prop-types';
import { Button, HelpBlock } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StorageApiTableLinkEx from '../../modules/components/react/components/StorageApiTableLinkEx';
import { STAGE, tableIdWarning } from '../../modules/storage/constants';
import { getBucketDisplayNameFromName } from '../../modules/storage/helpers';
import tableIdParser, { parse as parseTable } from '../../utils/tableIdParser';
import validateStorageTableId from '../../utils/validateStorageTableId';
import DestinationTableSelector from './DestinationTableSelector';
import Tooltip from './Tooltip';

class TableSelectorForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.parseDestination = this.parseDestination.bind(this);
    this.updateDestinationPart = this.updateDestinationPart.bind(this);
  }

  render() {
    if (this.props.editing) {
      return (
        <>
          <DestinationTableSelector
            buckets={this.props.buckets}
            tables={this.props.tables}
            parts={this.parseDestination().parts}
            updatePart={this.updateDestinationPart}
            disabled={this.props.disabled}
          />
          {this.renderHelp()}
        </>
      );
    }

    return (
      <>
        <StorageApiTableLinkEx tableId={this.props.value} />
        <Button bsStyle="link" onClick={this.props.onEdit} disabled={this.props.disabled}>
          <Tooltip tooltip="Edit table name" placement="top">
            <FontAwesomeIcon icon="pen" />
          </Tooltip>
        </Button>
        {this.renderHelp()}
      </>
    );
  }

  parseDestination() {
    return tableIdParser.parse(this.props.value, { defaultStage: STAGE.OUT });
  }

  updateDestinationPart(partName, value) {
    return this.handleChange(this.parseDestination().setPart(partName, value).tableId);
  }

  renderHelp() {
    if (this.props.value && !validateStorageTableId(this.props.value)) {
      return <HelpBlock className="text-danger">{tableIdWarning}</HelpBlock>;
    }

    const parsedTable = parseTable(this.props.value).parts;
    const alreadyExists = this.props.tables
      .groupBy((table) => table.getIn(['bucket', 'id']))
      .map((tables) => tables.first().get('bucket'))
      .filter((bucket) => bucket.get('stage') === parsedTable.stage)
      .find((bucket) => {
        return (
          bucket.get('name') !== parsedTable.bucket &&
          bucket.get('displayName') === getBucketDisplayNameFromName(parsedTable.bucket)
        );
      });

    if (alreadyExists) {
      return (
        <HelpBlock className="text-danger">
          The bucket name {getBucketDisplayNameFromName(parsedTable.bucket)} already exists in the
          selected stage.
        </HelpBlock>
      );
    }

    return (
      <HelpBlock>
        Table in Storage where the CSV file will be imported. If the table does not exist, it will
        be created.
      </HelpBlock>
    );
  }

  handleChange(selectedValue) {
    const value = selectedValue.trim();

    return this.props.onChange(value);
  }
}

TableSelectorForm.propTypes = {
  tables: PropTypes.object.isRequired,
  buckets: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
};

export default TableSelectorForm;
