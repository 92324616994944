import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import { List, Map } from 'immutable';

import { BETA, EXCLUDE_FROM_NEW_LIST } from '../../../../constants/componentFlags';
import {
  KEBOOLA_DATABRICKS_TRANSFORMATION,
  KEBOOLA_DBT_TRANSFORMATION,
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
  KEBOOLA_PYTHON_MLFLOW_TRANSFORMATION
} from '../../../../constants/componentIds';
import { features as componentFeatures } from '../../../../modules/components/Constants';
import ComponentBadges from '../../../../react/common/ComponentBadges';
import ComponentIcon from '../../../../react/common/ComponentIcon';
import ConfirmButtons from '../../../../react/common/ConfirmButtons';
import ModalActionButton from '../../../../react/common/ModalActionButton';
import ModalIcon from '../../../../react/common/ModalIcon';
import OptionalFormLabel from '../../../../react/common/OptionalFormLabel';
import Select from '../../../../react/common/Select';
import Tooltip from '../../../../react/common/Tooltip';
import ConfigurationsActionCreators from '../../../configurations/ConfigurationsActionCreators';
import { prepareRuntimesForTransformation } from '../../../runtimes/helpers';
import RuntimesStore from '../../../runtimes/store';
import { DBT_REMOTE_TRANSFORMATIONS } from '../../../transformations-v2/constants';
import { prepareTransformationData } from '../../../transformations-v2/helpers';
import ComponentsActionCreators from '../../ComponentsActionCreators';
import {
  ensureComponentWithDetails,
  getDatabricksParametersFromCluster,
  getPredefinedCluster,
  saveFolderToMetadata
} from '../../helpers';
import InstalledComponentsActionCreators from '../../InstalledComponentsActionCreators';
import DatabricksParametersForm from './DatabricksParametersFormContent';

const STEP_TRANSFORMATIONS_LIST = 'list';
const STEP_CREATE_CONFIGURATION = 'create';

const INITIAL_STATE = {
  step: STEP_TRANSFORMATIONS_LIST,
  component: null,
  isLoading: false,
  tempData: Map(),
  isLoadingDatabricksClusters: false
};

/*
  Some components as python mlflow or remote dbt components
  are not offered in the list of transformations even they can be public
*/
const SPECIFIC_COMPONENTS = [KEBOOLA_PYTHON_MLFLOW_TRANSFORMATION, ...DBT_REMOTE_TRANSFORMATIONS];

class NewTransformationModal extends React.Component {
  state = INITIAL_STATE;

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => {
          this.setState({
            ...INITIAL_STATE,
            ...(this.props.forceName && { tempData: Map({ name: this.props.forceName }) })
          });

          if (this.props.forceComponent) {
            ensureComponentWithDetails(this.props.forceComponent.get('id')).then((component) => {
              this.setState({ step: STEP_CREATE_CONFIGURATION, component });
            });
          }
        }}
      >
        {this.renderBody()}
      </Modal>
    );
  }

  renderBody() {
    if (this.state.step === STEP_CREATE_CONFIGURATION) {
      return this.renderCreateConfigurationModal();
    }

    return this.renderTransformationsListModal();
  }

  renderTransformationsListModal() {
    if (this.props.forceComponent) {
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title>New {this.props.forceComponent.get('name')} Transformation</Modal.Title>
            <ModalIcon icon="gear" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <p>Loading component details...</p>
          </Modal.Body>
        </>
      );
    }

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>New Transformation</Modal.Title>
          <ModalIcon icon="gear" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          {this.props.allowedComponents
            .filter((component) => !SPECIFIC_COMPONENTS.includes(component.get('id')))
            .sortBy((item) => item.get('name'))
            .sortBy((item) => item.get('flags').includes(BETA))
            .map((component) => {
              const isNocodeInDevMode =
                this.props.isDevModeActive &&
                component.get('id') === KEBOOLA_NO_CODE_DBT_TRANSFORMATION;

              return (
                <ModalActionButton
                  key={component.get('id')}
                  icon={<ComponentIcon component={component} size="48" />}
                  title={
                    <>
                      {component.get('name')} Transformation
                      <ComponentBadges flags={component.get('flags')} className="tw-ml-2" />
                    </>
                  }
                  description={component.get('description')}
                  onClick={() => this.handleSelectComponent(component)}
                  {...(isNocodeInDevMode && {
                    isDisabled: true,
                    disabledReason:
                      'We are sorry no-code manipulation are not available in development branch at the moment'
                  })}
                />
              );
            })
            .toArray()}
        </Modal.Body>
      </>
    );
  }

  renderCreateConfigurationModal() {
    const codePatterns = this.props.patternComponents.filter((component) => {
      return component
        .getIn(['configurationSchema', 'supported_components'], List())
        .includes(this.state.component.get('id'));
    });
    const databricksPredefinedCluster = getPredefinedCluster(
      this.props.availableDatabricksClusters,
      this.state.tempData.get('clusterId')
    );
    const componentId = this.state.component.get('id');
    const runtimes = RuntimesStore.getRuntimes(
      DBT_REMOTE_TRANSFORMATIONS.includes(componentId) ? KEBOOLA_DBT_TRANSFORMATION : componentId
    );

    return (
      <Form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>New {this.state.component.get('name')} Transformation</Modal.Title>
          <ModalIcon icon="gear" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              placeholder="Name your transformation"
              value={this.state.tempData.get('name', '')}
              onChange={(e) =>
                this.setState({ tempData: this.state.tempData.set('name', e.target.value) })
              }
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              Description <OptionalFormLabel />
            </ControlLabel>
            <FormControl
              type="text"
              placeholder="Describe your transformation"
              value={this.state.tempData.get('description', '')}
              onChange={(e) =>
                this.setState({ tempData: this.state.tempData.set('description', e.target.value) })
              }
            />
          </FormGroup>
          {this.renderWarehouseInput()}
          {this.isDatabricksTransformation(this.state.component) && (
            <DatabricksParametersForm
              savedValues={
                !this.state.tempData.get('clusterId')
                  ? this.state.tempData
                  : Map({
                      clusterId: this.state.tempData.get('clusterId')
                    }).merge(getDatabricksParametersFromCluster(databricksPredefinedCluster))
              }
              defaultValues={this.state.component.getIn(['data', 'image_parameters'], Map())}
              availableClusters={this.props.availableDatabricksClusters}
              isLoading={this.state.isLoadingDatabricksClusters}
              loadClustersHandler={this.loadDatabricksClusters}
              onChange={(newValues) => {
                const { clusterId, nodeType, numberOfNodes, sparkVersion } = newValues.toJS();

                this.setState({
                  tempData: this.state.tempData.merge({
                    clusterId,
                    nodeType,
                    numberOfNodes,
                    sparkVersion
                  })
                });
              }}
            />
          )}
          {this.state.component
            .get('features', List())
            .includes(componentFeatures.ALLOW_TAG_OVERRIDE) &&
            !runtimes.isEmpty() && (
              <FormGroup>
                <ControlLabel>Backend Version</ControlLabel>
                <Select
                  clearable={false}
                  options={prepareRuntimesForTransformation(runtimes)}
                  value={this.state.tempData.get('image_tag', '')}
                  onChange={(selected) => {
                    this.setState({ tempData: this.state.tempData.set('image_tag', selected) });
                  }}
                />
              </FormGroup>
            )}
          <FormGroup>
            <ControlLabel>
              Folder <OptionalFormLabel />
            </ControlLabel>
            <Select
              allowCreate
              value={this.state.tempData.get('folder', '')}
              onChange={(folder) => {
                this.setState({ tempData: this.state.tempData.set('folder', folder) });
              }}
              placeholder="Select or create a folder"
              promptTextCreator={(label) => `Create folder "${label}"`}
              options={this.props.folders
                .filter((configs, componentId) => this.props.allowedComponents.has(componentId))
                .toList()
                .map((configs) => configs.toList())
                .flatten()
                .toSet()
                .sortBy((folder) => folder.toLowerCase())
                .map((folder) => ({ label: folder, value: folder }))
                .toArray()}
            />
          </FormGroup>
          {!this.props.hasPayAsYouGo && codePatterns.count() > 0 && (
            <FormGroup>
              <ControlLabel>
                Use predefined code pattern <OptionalFormLabel />
              </ControlLabel>
              <Select
                placeholder="Select pattern"
                value={this.state.tempData.get('pattern', '')}
                onChange={(selected) => {
                  this.setState({ tempData: this.state.tempData.set('pattern', selected) });
                }}
                options={codePatterns
                  .map((pattern) => ({ value: pattern.get('id'), label: pattern.get('name') }))
                  .toArray()}
              />
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="block">
            {!this.props.forceComponent && (
              <Button onClick={() => this.setState(INITIAL_STATE)}>
                <FontAwesomeIcon icon="arrow-left" fixedWidth />
              </Button>
            )}
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={
                this.state.isLoading ? 'Creating transformation...' : 'Create transformation'
              }
              isSaving={this.state.isLoading}
              isDisabled={this.isDisabled()}
            />
          </ButtonToolbar>
        </Modal.Footer>
      </Form>
    );
  }

  renderWarehouseInput() {
    if (!this.isDbtTransformation(this.state.component)) {
      return null;
    }

    const canUseKeboolaStorage = this.isDbtTransformationUsable();

    return (
      <FormGroup>
        <ControlLabel>
          Warehouse
          <Tooltip
            tooltip={`Choose when the transformation code will be executed.${
              canUseKeboolaStorage
                ? ' Default - Keboola storage. You can also choose various external DWH.'
                : ' You can choose various external DWH.'
            }`}
            placement="top"
            type="explanatory"
          >
            <FontAwesomeIcon icon="circle-info" className="title-hint" />
          </Tooltip>
        </ControlLabel>
        <Select
          clearable={false}
          placeholder="Select warehouse"
          options={[
            ...(canUseKeboolaStorage
              ? [
                  {
                    value: KEBOOLA_DBT_TRANSFORMATION,
                    label: 'Keboola Storage'
                  }
                ]
              : []),
            ...this.props.allowedComponents
              .filter((component) => DBT_REMOTE_TRANSFORMATIONS.includes(component.get('id')))
              .sortBy((component) => component.get('name'))
              .map((component) => ({
                value: component.get('id'),
                label: component.get('name')
              }))
              .toArray()
          ]}
          value={this.state.tempData.get('dbt_component', '')}
          onChange={(selected) => {
            this.setState({ tempData: this.state.tempData.set('dbt_component', selected) });
          }}
        />
      </FormGroup>
    );
  }

  handleSelectComponent(component) {
    /*
      Workaround to properly load all data for pattern compoennt.
      We have only one pattern and probably no more will be puplished, so it is save
    */
    return Promise.each(this.props.patternComponents.toArray(), (component) => {
      return ComponentsActionCreators.loadComponent(component.get('id'));
    })
      .then(() => ensureComponentWithDetails(component.get('id')))
      .then((component) => {
        let data = Map({
          name: '',
          description: '',
          pattern: ''
        });

        if (this.isDbtTransformation(component)) {
          data = data.set(
            'dbt_component',
            this.isDbtTransformationUsable() ? component.get('id') : ''
          );
        }

        if (this.isDatabricksTransformation(component)) {
          data = data
            .set('nodeType', component.getIn(['data', 'image_parameters', 'defaultNodeType']))
            .set(
              'numberOfNodes',
              component.getIn(['data', 'image_parameters', 'defaultNumberOfNodes'])
            )
            .set(
              'sparkVersion',
              component.getIn(['data', 'image_parameters', 'defaultSparkVersion'])
            );
        }

        this.setState({ step: STEP_CREATE_CONFIGURATION, component, tempData: data });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    Promise.resolve()
      .then(() => {
        if (!this.state.tempData.has('dbt_component')) {
          return this.state.component;
        }

        return ensureComponentWithDetails(this.state.tempData.get('dbt_component'));
      })
      .then((component) => prepareTransformationData(component, this.state.tempData))
      .then(({ componentId, data }) => {
        return InstalledComponentsActionCreators.createConfiguration(componentId, data)
          .tap((response) => {
            if (!!this.state.tempData.get('folder')) {
              return saveFolderToMetadata(
                componentId,
                response.id,
                this.state.tempData.get('folder')
              );
            }
          })
          .then((response) => this.props.onCreate(componentId, response.id));
      })
      .then(() => {
        this.setState(INITIAL_STATE);
        this.props.onHide();
      });
  };

  isDbtTransformation(component) {
    return component.get('id') === KEBOOLA_DBT_TRANSFORMATION;
  }

  isDatabricksTransformation(component) {
    return component.get('id') === KEBOOLA_DATABRICKS_TRANSFORMATION;
  }

  isDisabled() {
    if (!this.state.tempData.get('name', '').trim()) {
      return true;
    }

    if (this.isDbtTransformation(this.state.component)) {
      return !this.state.tempData.get('dbt_component') && !this.isDbtTransformationUsable();
    }

    return false;
  }

  isDbtTransformationUsable() {
    return !this.props.allowedComponents
      .getIn([KEBOOLA_DBT_TRANSFORMATION, 'flags'], List())
      .includes(EXCLUDE_FROM_NEW_LIST);
  }

  loadDatabricksClusters = () => {
    this.setState({ isLoadingDatabricksClusters: true });

    return ConfigurationsActionCreators.loadDatabricksClusters(
      this.state.component.get('id')
    ).finally(() => this.setState({ isLoadingDatabricksClusters: false }));
  };
}

NewTransformationModal.propTypes = {
  patternComponents: PropTypes.instanceOf(Map).isRequired,
  allowedComponents: PropTypes.instanceOf(Map).isRequired,
  folders: PropTypes.instanceOf(Map).isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  availableDatabricksClusters: PropTypes.instanceOf(List),
  forceComponent: PropTypes.instanceOf(Map),
  forceName: PropTypes.string
};

export default NewTransformationModal;
