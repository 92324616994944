import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import Loader from '../../../react/common/Loader';
import { factory as eventsFactory } from '../../sapi-events/TableEventsService';
import LatestImportGraph from './LatestImportGraph';

const LatestImports = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    table: PropTypes.object.isRequired
  },

  getInitialState() {
    return {
      events: List(),
      isLoading: false
    };
  },

  componentDidMount() {
    this.createEventsService();
    this._events.load();
  },

  componentWillUnmount() {
    this.destroyEventsService();
  },

  render() {
    return (
      <>
        <p className="first-line f-14 font-medium flex-container">Latest import time</p>
        {this.renderImportsTableGraph()}
      </>
    );
  },

  renderImportsTableGraph() {
    if (this.state.isLoading) {
      return (
        <p>
          <Loader /> Loading graph...
        </p>
      );
    }

    if (this.state.events.count() < 2) {
      return <p>Graph cannot be rendered, there aren&apos;t enough import events.</p>;
    }

    return <LatestImportGraph data={this.state.events} />;
  },

  createEventsService() {
    this._events = eventsFactory(this.props.table.get('id'), { component: 'storage' });
    this._events.setQuery('event:storage.tableImportDone');
    this._events.addChangeListener(this.handleChange);
  },

  destroyEventsService() {
    this._events.removeChangeListener(this.handleChange);
    this._events.reset();
  },

  handleChange() {
    this.setState({
      events: this._events.getEvents(),
      isLoading: this._events.getIsLoading()
    });
  }
});

export default LatestImports;
