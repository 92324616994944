import React from 'react';

import CircleIcon from '../../../../react/common/CircleIcon';
import FileSize from '../../../../react/common/FileSize';
import RouterLink from '../../../../react/common/RouterLink';
import RowsCount from '../../../../react/common/RowsCount';

const StoragePanel = ({ sizeBytes, rowsCount }: { sizeBytes?: number; rowsCount: number }) => (
  <div className="box box-panel box-panel-small">
    <div className="box-header">
      <h2 className="box-title">Storage Overview</h2>
      <CircleIcon icon="warehouse" color="blue" bold />
    </div>
    <div className="box-panel-content mt-auto">
      <p className="summary-title">
        <FileSize size={sizeBytes} />
      </p>
      <p className="summary-text text-muted font-medium">
        (<RowsCount count={rowsCount} /> Rows){' '}
        <RouterLink to="storage">Storage overview</RouterLink>
      </p>
    </div>
  </div>
);

export default StoragePanel;
