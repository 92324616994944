import { List, Map } from 'immutable';

import * as columnsMetadata from './helpers/columnsMetadata';
import configProvisioning from './configProvisioning';
import localStateProvisioning from './localStateProvisioning';

const EDITING_PATH = ['editing'];

export default function (configId) {
  const { parameters, inputMapping, saveInputMappingAndParameters, configData } =
    configProvisioning(configId);
  const { getLocalStateValue, updateLocalState } = localStateProvisioning(configId);
  const tables = parameters.get('tables', Map());
  const initialEditingTables = Map({
    parameters: Map(),
    inputMapping: List()
  });

  const editingTables = getLocalStateValue(EDITING_PATH, initialEditingTables);

  function getEditingTable(tableId) {
    const storedTableParameters = tables.get(tableId);
    const storedInputMapping = inputMapping.find((table) => table.get('source') === tableId);
    const tableParameters = editingTables.getIn(['parameters', tableId]) || storedTableParameters;
    const tableInputMapping =
      editingTables.get('inputMapping').find((table) => table.get('source') === tableId) ||
      storedInputMapping;
    return {
      tableParameters,
      tableInputMapping
    };
  }

  function isEditingTableChanged(tableId) {
    const { tableParameters, tableInputMapping } = getEditingTable(tableId);
    const storedTableParameters = tables.get(tableId);
    const storedInputMapping = inputMapping.find((table) => table.get('source') === tableId);
    return tableParameters !== storedTableParameters || tableInputMapping !== storedInputMapping;
  }

  function setEditingTable(tableId, tableParams, tableInputMapping) {
    const tableMappingIndex = editingTables
      .get('inputMapping')
      .findIndex((tableIm) => tableIm.get('source') === tableId);
    let newEditingTables = editingTables.setIn(['parameters', tableId], tableParams);
    if (tableMappingIndex > -1) {
      newEditingTables = newEditingTables.setIn(
        ['inputMapping', tableMappingIndex],
        tableInputMapping
      );
    } else {
      newEditingTables = newEditingTables.update('inputMapping', (editingInputMapping) =>
        editingInputMapping.push(tableInputMapping)
      );
    }
    updateLocalState(EDITING_PATH, newEditingTables);
  }

  function updateEditingTable(tableId, tableParams, tableInputMapping) {
    const editingTable = getEditingTable(tableId);
    const newTableParams = editingTable.tableParameters.mergeDeep(tableParams);
    const newTableInputMapping = editingTable.tableInputMapping.mergeDeep(tableInputMapping);
    setEditingTable(tableId, newTableParams, newTableInputMapping);
  }

  function updateTableMapping(tableId, updateFn) {
    return inputMapping.map((table) => (table.get('source') === tableId ? updateFn(table) : table));
  }

  function createNewTable(table, title) {
    const tableId = table.get('id');
    const columns = columnsMetadata.prepareColumnsTypes(table);
    const newParameters = parameters.setIn(
      ['tables', tableId],
      Map({ title, columns: columns.toJS() })
    );
    const newMapping = inputMapping.push(Map({ source: tableId, columns: [...columns.keys()] }));
    return saveInputMappingAndParameters(newMapping, newParameters, `Add table ${tableId}`);
  }

  function toggleTableExport(tableId, isEnabled) {
    const newMapping = updateTableMapping(tableId, (table) =>
      isEnabled ? table.delete('limit') : table.set('limit', 1)
    );
    const newParameters = parameters.updateIn(['tables', tableId], (table) =>
      table.set('disabled', !isEnabled)
    );
    const changeDescription = `${isEnabled ? 'Enable' : 'Disabled'} ${tableId} export`;
    return saveInputMappingAndParameters(newMapping, newParameters, changeDescription, [
      tableId,
      'activate'
    ]).then(() => {
      if (isEditingTableChanged(tableId)) {
        const mappingObjectToMerge = isEnabled
          ? { source: tableId }
          : { source: tableId, limit: 1 };
        updateEditingTable(tableId, Map({ disabled: !isEnabled }), Map(mappingObjectToMerge));
      }
    });
  }

  function deleteTable(tableId) {
    const newMapping = inputMapping.filter((mapping) => mapping.get('source') !== tableId);
    const newParameters = parameters.deleteIn(['tables', tableId]);
    return saveInputMappingAndParameters(newMapping, newParameters, `delete table ${tableId}`, [
      tableId,
      'delete'
    ]);
  }

  function getSingleRunParams(tableId, loadDataOnly) {
    const newConfigData = configData
      .updateIn(['storage', 'input', 'tables'], (tables) =>
        tables.filter((table) => table.get('source') === tableId)
      )
      .deleteIn(['storage', 'input', 'tables', 0, 'limit'])
      .setIn(['parameters', 'tables', tableId, 'disabled'], false)
      .setIn(['parameters', 'loadOnly'], !!loadDataOnly);
    return {
      config: configId,
      configData: newConfigData.toJS()
    };
  }

  return {
    getSingleRunParams,
    createNewTable,
    deleteTable,
    tables,
    toggleTableExport,
    updateEditingTable,
    setEditingTable,
    getEditingTable,
    isEditingTableChanged
  };
}
