import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import BlockButton from '../../react/common/BlockButton';
import Loader from '../../react/common/Loader';
import NoResultsFound from '../../react/common/NoResultsFound';
import SearchBar from '../../react/common/SearchBar';
import Tooltip from '../../react/common/Tooltip';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import FilesStore from '../components/stores/StorageFilesStore';
import ExamplesModal from './components/FilesSearchExamplesModal';
import FilesTable from './components/FilesTable';
import StorageTabs from './components/StorageTabs';
import { deleteFile, filterFiles, loadMoreFiles, updateFilesSearchQuery } from './actions';
import { routeNames } from './constants';
import FilesLocalStore from './FilesLocalStore';

const Files = createReactClass({
  mixins: [
    ImmutableRenderMixin,
    createStoreMixin(ApplicationStore, RoutesStore, FilesStore, FilesLocalStore)
  ],

  getStateFromStores() {
    return {
      files: FilesStore.getAll(),
      hasMore: FilesStore.hasMore(),
      searchQuery: FilesLocalStore.getSearchQuery(),
      isLoading: FilesStore.getIsLoading(),
      isLoadingMore: FilesStore.getIsLoadingMore(),
      isDeleting: FilesStore.getIsDeleting(),
      isReadOnly: ApplicationStore.isReadOnly(),
      canManageApps: ApplicationStore.getKbcVars().get('canManageApps'),
      admins: ApplicationStore.getAdmins()
    };
  },

  componentWillUnmount() {
    updateFilesSearchQuery('');
  },

  getInitialState() {
    return {
      openExamplesModal: false
    };
  },

  render() {
    return (
      <StorageTabs activeTab={routeNames.FILES} hasEvents={this.state.canManageApps}>
        <div className="search-panel box">
          <SearchBar
            placeholder="Search: tags:tag"
            query={this.state.searchQuery}
            onChange={updateFilesSearchQuery}
            onSubmit={() => {
              filterFiles(this.state.searchQuery);
            }}
            additionalActions={
              <ButtonToolbar>
                <Tooltip tooltip="Search syntax &amp; Examples" placement="top">
                  <Button bsSize="sm" onClick={this.openExamplesModal}>
                    <FontAwesomeIcon icon="circle-question" />
                  </Button>
                </Tooltip>
              </ButtonToolbar>
            }
          />
        </div>
        {this.renderFiles()}
        <ExamplesModal
          show={this.state.openExamplesModal}
          onHide={this.closeExamplesModal}
          onSelectExample={filterFiles}
        />
      </StorageTabs>
    );
  },

  renderFiles() {
    if (!this.state.files.count() && this.state.isLoading) {
      return (
        <div className="box">
          <div className="box-content">
            <Loader /> Loading files...
          </div>
        </div>
      );
    }

    if (!this.state.files.count()) {
      return <NoResultsFound entityName="files" />;
    }

    return (
      <div className="box">
        <InfiniteScroll
          initialLoad={false}
          loadMore={this.fetchMoreFiles}
          hasMore={this.state.hasMore}
        >
          <FilesTable
            readOnly={this.state.isReadOnly}
            files={this.state.files}
            admins={this.state.admins}
            onSearchQuery={filterFiles}
            onDeleteFile={deleteFile}
            isDeleting={this.state.isDeleting}
          />
        </InfiniteScroll>
        {this.renderMoreButton()}
      </div>
    );
  },

  renderMoreButton() {
    if (!this.state.files || !this.state.hasMore) {
      return null;
    }

    return <BlockButton onClick={this.fetchMoreFiles} isLoading={this.state.isLoadingMore} />;
  },

  openExamplesModal() {
    this.setState({
      openExamplesModal: true
    });
  },

  closeExamplesModal() {
    this.setState({
      openExamplesModal: false
    });
  },

  fetchMoreFiles() {
    if (this.state.isLoadingMore) return;

    return loadMoreFiles({
      offset: this.state.files.count(),
      ...(this.state.searchQuery && { q: this.state.searchQuery })
    });
  }
});

export default Files;
