import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';
import { truncate } from 'underscore.string';

import { TRANSFORMATION } from '../../../constants/componentIds';
import { USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import CircleIcon from '../../../react/common/CircleIcon';
import Confirm from '../../../react/common/Confirm';
import ExternalLink from '../../../react/common/ExternalLink';
import JobDuration from '../../../react/common/JobDuration';
import contactSupport from '../../../utils/contactSupport';
import { canRunConfigDataJob } from '../../admin/privileges';
import {
  getLargerBackendSize,
  hasDynamicBackendSizeEnabled,
  supportsDynamicBackendSize
} from '../../components/helpers';
import actions from '../actions';
import { extractErrorDetails, getCurrentBackendSize } from '../helpers';
import JobErrorModal from './JobErrorModal';

class JobErrorPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrorModal: false,
      isRetryingJob: false
    };
  }

  render() {
    const errorMessage = this.props.job.getIn(['result', 'message']);
    const [title, body] = this.renderDetails(errorMessage);

    return (
      <div className="job-error-panel box box-panel">
        <div className="box-header">
          <h3 className="box-title text-danger">{title}</h3>
          <CircleIcon icon="circle-exclamation" color="red" />
        </div>
        <div className="box-panel-content mt-auto">
          <p className="summary-title">
            <JobDuration
              status={this.props.job.get('status')}
              startTime={this.props.job.get('startTime')}
              endTime={this.props.job.get('endTime')}
            />
          </p>
          {body}
        </div>
      </div>
    );
  }

  /** @returns {[string, JSX.Element]} */
  renderDetails(message) {
    if (message.indexOf('Component out of memory') !== -1) {
      return ['Component out of memory', this.renderInsufficientResourcesPanel('memory')];
    } else if (message.indexOf('container exceeded the timeout') !== -1) {
      return ['Job Timeout', this.renderInsufficientResourcesPanel('time')];
    } else if (
      this.props.job.get('component') === TRANSFORMATION &&
      message.indexOf('TTL exceeded') !== -1
    ) {
      return [
        'Circular dependencies',
        <p key="Circular Dependencies">
          Please check your transformations and prevent any circular{' '}
          <ExternalLink href={`${USER_DOCUMENTATION_URL}/transformations/#dependencies`}>
            dependencies
          </ExternalLink>
          .
        </p>
      ];
    }

    const useAi =
      this.props.hasAllowedAi &&
      this.props.job.get('error') !== 'application' &&
      this.props.job.hasIn(['result', 'message']) &&
      this.props.job.getIn(['result', 'context'], List()).isEmpty();
    const errorMessage = extractErrorDetails(this.props.job.getIn(['result', 'message'], ''));

    return [
      'Job Error',
      <>
        <div className="overflow-break-anywhere">
          <p>
            {this.props.job.get('error') === 'application'
              ? 'Internal Error'
              : truncate(errorMessage, 300)}
          </p>
        </div>
        <Button bsStyle="success" onClick={() => this.setState({ showErrorModal: true })}>
          {useAi ? (
            <>
              <FontAwesomeIcon icon="brain-circuit" className="icon-addon-right" />
              {errorMessage.length > 300 ? 'Show More & Explain' : 'Explain'}
            </>
          ) : (
            'Show details'
          )}
        </Button>
        <JobErrorModal
          useAi={useAi}
          job={this.props.job}
          show={this.state.showErrorModal}
          onHide={() => this.setState({ showErrorModal: false })}
        />
      </>
    ];
  }

  renderInsufficientResourcesPanel(entity) {
    const supportsDynamicBackendSizes =
      this.props.allComponents &&
      supportsDynamicBackendSize(this.props.allComponents.get(this.props.job.get('component'))) &&
      hasDynamicBackendSizeEnabled(
        this.props.allComponents.get(this.props.job.get('component'), Map()),
        this.props.hasSnowflakeDynamicBackendSize,
        this.props.hasJobsDynamicBackendSize
      );
    const hasLargerBackendSize = !!getLargerBackendSize(
      this.props.job.get('component'),
      getCurrentBackendSize(this.props.job)
    );

    return (
      <>
        <p>
          Looks like{' '}
          {entity === 'time' ? (
            <>
              <strong>this job timed out</strong>
            </>
          ) : (
            <>
              you are <strong>running out of {entity}</strong> with this job
            </>
          )}
          . Would you like to run it with increased {entity === 'time' ? 'timeout' : entity}?
        </p>
        {supportsDynamicBackendSizes &&
        hasLargerBackendSize &&
        canRunConfigDataJob(this.props.sapiTokens) ? (
          <Confirm
            closeAfterResolve
            title="Run with More Resources"
            text={
              <>
                <p className="text-muted">
                  You can try to run a job with more resources to see if it helps. Running it on
                  stronger backend is more expensive. Please check the pricing{' '}
                  <ExternalLink
                    href={`${USER_DOCUMENTATION_URL}/management/project/limits/#project-power--time-credits`}
                  >
                    here
                  </ExternalLink>
                  .
                </p>
                <p className="text-muted">Do you want to continue?</p>
              </>
            }
            buttonLabel="Retry job with More Resources"
            buttonType="success"
            childrenRootElement="button"
            childrenRootElementClass="btn btn-primary"
            onConfirm={() => {
              this.setState({ isRetryingJob: true });

              return actions
                .retryJobWithLargerBackend(this.props.job, this.props.configData)
                .catch(() => this.setState({ isRetryingJob: false }));
            }}
            isLoading={this.state.isRetryingJob}
          >
            <FontAwesomeIcon icon="rocket" className="icon-addon-right" />
            Retry job with more resources
          </Confirm>
        ) : (
          <Button bsStyle="danger" onClick={() => contactSupport()}>
            Contact Support
          </Button>
        )}
      </>
    );
  }
}

JobErrorPanel.propTypes = {
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  job: PropTypes.instanceOf(Map).isRequired,
  configData: PropTypes.instanceOf(Map),
  allComponents: PropTypes.instanceOf(Map),
  hasAllowedAi: PropTypes.bool,
  hasSnowflakeDynamicBackendSize: PropTypes.bool,
  hasJobsDynamicBackendSize: PropTypes.bool
};

export default JobErrorPanel;
