import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import LinkifyIt from 'linkify-it';

import ExternalLink from './ExternalLink';

const linkifyIt = new LinkifyIt(
  {},
  {
    fuzzyLink: false,
    fuzzyEmail: false,
    fuzzyIP: false
  }
);

const checkText = (text) =>
  linkifyIt.add('ftp:', null).add('//', null).add('mailto:', null).match(text);

const splitNewLines = (text) => {
  const lines = text.split('\n');
  const result = [];

  lines.forEach((value, index) => {
    result.push(<React.Fragment key={index}>{value}</React.Fragment>);
    if (index < lines.length - 1) {
      result.push(<br key={`${index}-br`} />);
    }
  });

  return result;
};

const renderExternalLink = (href, text, key) => {
  return (
    <ExternalLink href={href} key={key} onClick={(e) => e.stopPropagation()}>
      {text}
    </ExternalLink>
  );
};

class UrlsToLinks extends React.Component {
  render() {
    if (!this.props.text || !this.props.text.length) {
      return null;
    }

    return (
      <Linkify componentDecorator={renderExternalLink} matchDecorator={checkText}>
        {splitNewLines(this.props.text)}
      </Linkify>
    );
  }
}

UrlsToLinks.propTypes = {
  text: PropTypes.string
};

export default UrlsToLinks;
