import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'rc-switch';

import ReadOnlyTooltip from '../ReadOnlyTooltip';
import { SwitchStateLinkStatus } from './SwitchStateLinkStatus';

class SwitchStateLink extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  render() {
    return (
      <ReadOnlyTooltip readOnly={this.props.readOnly}>
        <span className={this.getLinkClass()} onClick={this.handleOnClick}>
          <Switch
            defaultChecked={this.isSwitchChecked()}
            checked={this.isSwitchChecked()}
            disabled={this.isDisabled()}
            prefixCls="switch"
            className={this.props.wider ? 'wider' : ''}
          />
          {this.props.label}
        </span>
      </ReadOnlyTooltip>
    );
  }

  getLinkClass() {
    let linkClass = 'switch-state-link btn-link ' + this.getStatus();
    if (this.props.disabled === true) {
      linkClass += ' disabled';
    }
    return linkClass;
  }

  getStatus() {
    if (this.props.isPending) {
      return SwitchStateLinkStatus.PENDING;
    }

    return this.props.isActive ? SwitchStateLinkStatus.ON : SwitchStateLinkStatus.OFF;
  }

  isDisabled() {
    return (
      this.getStatus() === SwitchStateLinkStatus.PENDING ||
      this.props.readOnly ||
      this.props.disabled === true
    );
  }

  isSwitchChecked() {
    return this.getStatus() === SwitchStateLinkStatus.ON;
  }

  handleOnClick(e) {
    e.stopPropagation();
    e.preventDefault();
    if (this.isDisabled()) {
      return;
    }
    this.props.onChange(e);
  }
}

SwitchStateLink.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  disabled: PropTypes.bool,
  wider: PropTypes.bool
};

SwitchStateLink.defaultProps = {
  disabled: false,
  wider: false
};

export default SwitchStateLink;
