import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import Link from '../../../../react/common/RouterLink';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import { canCreateToken } from '../../../admin/privileges';
import { routeNames } from '../../../settings/constants';

const NewTokenHeaderButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore)],

  getStateFromStores() {
    return {
      sapiToken: ApplicationStore.getSapiToken()
    };
  },

  render() {
    if (!canCreateToken(this.state.sapiToken)) {
      return null;
    }

    return (
      <Link className="btn btn-success" to={routeNames.NEW_TOKEN}>
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        New Token
      </Link>
    );
  }
});

export default NewTokenHeaderButtons;
