import React from 'react';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_VARIABLES } from '../../../../constants/componentIds';
import CatchUnsavedRunWarning from '../../../../react/common/CatchUnsavedRunWarning';
import ConfigurationInfoPanel from '../../../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../../../react/common/ConfigurationTabs';
import Sidebar from '../../../../react/layout/Sidebar';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
// stores
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import { hasGenericVariablesUI, isComponentDeprecated } from '../../../components/helpers';
import ComponentDescription from '../../../components/react/components/ComponentDescription';
import { prepareVariables } from '../../../components/react/components/generic/variables/helpers';
import Variables from '../../../components/react/components/generic/variables/Variables';
import MigrationRow from '../../../components/react/components/MigrationRow';
import ComponentsStore from '../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';
import StorageBucketsStore from '../../../components/stores/StorageBucketsStore';
import StorageTablesStore from '../../../components/stores/StorageTablesStore';
import VersionsStore from '../../../components/stores/VersionsStore';
import OAuthStore from '../../../oauth-v2/Store';
import { shouldShowSimplifiedUi } from '../../../simplified-ui/helpers';
import SimplifiedUi from '../../../simplified-ui/Index';
import { prepareTablesMetadataMap } from '../../../storage/helpers';
import configurationRowsActions from '../../ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '../../ConfigurationRowsStore';
import configurationsActions from '../../ConfigurationsActionCreators';
import ConfigurationsStore from '../../ConfigurationsStore';
import sections from '../../utils/sections';
import ConfigurationRows from '../components/ConfigurationRows';
import CreateConfigurationRowButton from '../components/CreateConfigurationRowButton';
import IndexSections from '../components/IndexSections';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      ConfigurationsStore,
      ConfigurationRowsStore,
      VersionsStore,
      OAuthStore
    )
  ],

  getStateFromStores() {
    const configurationId = RoutesStore.getCurrentRouteParam('config');
    const settings = RoutesStore.getRouteSettings();
    const componentId = settings.get('componentId');
    const component = ComponentsStore.getComponent(componentId);
    const configuration = ConfigurationsStore.get(componentId, configurationId);
    const configData = ConfigurationsStore.getConfiguration(componentId, configurationId);
    const allConfigurations = InstalledComponentsStore.getAll();
    const conformFn = settings.getIn(['index', 'onConform'], (config) => config);
    const parseFn = sections.makeParseFn(
      settings.getIn(['index', 'sections']),
      conformFn,
      InstalledComponentsStore.getConfigurationContext(componentId, configurationId)
    );
    const configurationBySections = ConfigurationsStore.getEditingConfiguration(
      componentId,
      configurationId,
      parseFn
    );
    const variables = InstalledComponentsStore.getComponentConfigurations(KEBOOLA_VARIABLES);
    const variablesConfigData = variables.get(configData.get('variables_id'), Map());

    return {
      settings,
      component,
      componentId,
      configuration,
      configurationId,
      configData,
      variablesConfiguration: variablesConfigData.get('configuration', Map()),
      variablesConfigurationRow: variablesConfigData
        .get('rows', List())
        .find((row) => row.get('id') === configData.get('variables_values_id', null), null, Map()),
      preparedVariables: prepareVariables(allConfigurations, componentId, configurationId),
      readOnly: ApplicationStore.isReadOnly(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      rows: ConfigurationRowsStore.getRows(componentId, configurationId),
      isChanged: !configurationBySections.equals(parseFn(configData)),
      isJsonChanged: ConfigurationsStore.isEditingJsonConfiguration(componentId, configurationId),
      isDeprecated: isComponentDeprecated(component),
      deprecationReplacementComponentId: component.getIn(['uiOptions', 'replacementApp']),
      hasAuthCredentials: !!OAuthStore.getCredentials(componentId, configData),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      allBuckets: StorageBucketsStore.getAll(),
      allTables: StorageTablesStore.getAll(),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      hasFlows: ApplicationStore.hasFlows()
    };
  },

  onRowCreated(rowId) {
    const transitionParams = {
      config: this.state.configurationId,
      row: rowId
    };
    RoutesStore.getRouter().transitionTo(this.state.componentId + '-row', transitionParams);
  },

  renderNewConfigRowButton() {
    return (
      <CreateConfigurationRowButton
        component={this.state.component}
        configId={this.state.configurationId}
        emptyConfig={sections.makeCreateEmptyFn(
          this.state.settings.getIn(['row', 'sections'], List()),
          this.state.settings.getIn(['row', 'onSave'])
        )}
        onRowCreated={this.onRowCreated}
        buckets={this.state.allBuckets}
        tables={this.state.allTables}
      />
    );
  },

  renderRowsTable() {
    const state = this.state;
    const settings = this.state.settings;

    if (this.state.rows.count() === 0) {
      if (settings.getIn(['index', 'authRequired']) && !this.state.hasAuthCredentials) {
        return null;
      }

      return (
        <div className="box-separator">
          <h2 className="tw-text-base tw-m-0 tw-mb-4">Rows</h2>
          <div className="box">
            <div className="box-content text-center">
              <p>No rows created yet.</p>
              {!this.state.readOnly && this.renderNewConfigRowButton()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <ConfigurationRows
        readOnly={this.state.readOnly}
        rows={this.state.rows.toList()}
        config={this.state.configuration}
        tables={this.state.allTables}
        buckets={this.state.allBuckets}
        component={this.state.component}
        rowDelete={(rowId) => {
          return configurationRowsActions.delete(
            this.state.componentId,
            this.state.configurationId,
            rowId,
            false
          );
        }}
        rowDeletePending={(rowId) => {
          return ConfigurationRowsStore.getPendingActions(
            this.state.componentId,
            this.state.configurationId,
            rowId
          ).has('delete');
        }}
        rowEnableDisable={(rowId) => {
          const isDisabled = this.state.rows.getIn([rowId, 'isDisabled'], false);
          return configurationRowsActions[isDisabled ? 'enable' : 'disable'](
            this.state.componentId,
            this.state.configurationId,
            rowId
          );
        }}
        rowEnableDisablePending={(rowId) => {
          const rowPendingActions = ConfigurationRowsStore.getPendingActions(
            this.state.componentId,
            this.state.configurationId,
            rowId
          );

          return rowPendingActions.has('disable') || rowPendingActions.has('enable');
        }}
        rowLinkTo={this.state.componentId + '-row'}
        onOrder={(rowIds, movedRowId) => {
          return configurationsActions.orderRows(
            this.state.componentId,
            this.state.configurationId,
            rowIds,
            movedRowId
          );
        }}
        orderPending={ConfigurationsStore.getPendingActions(
          state.componentId,
          state.configurationId
        ).get('order-rows', Map())}
        columns={settings.getIn(['row', 'columns'])}
        filter={settings.getIn(['row', 'searchFilter'])}
        onRowCreated={this.onRowCreated}
        rowCreateEmptyConfig={sections.makeCreateEmptyFn(
          settings.getIn(['row', 'sections'], List()),
          settings.getIn(['row', 'onSave'])
        )}
      />
    );
  },

  renderRunModalContent() {
    return (
      <>
        {(this.state.isChanged || this.state.isJsonChanged) && <CatchUnsavedRunWarning />}
        <p>You are about to run {this.state.configuration.get('name', 'Untitled')}.</p>
      </>
    );
  },

  renderVariables() {
    if (!hasGenericVariablesUI()) {
      return null;
    }

    return (
      <Variables
        readOnly={this.state.readOnly}
        mainConfiguration={this.state.configData}
        mainComponentId={this.state.componentId}
        mainConfigurationId={this.state.configurationId}
        configuration={this.state.variablesConfiguration}
        row={this.state.variablesConfigurationRow}
        variables={this.state.preparedVariables}
        configVariablesId={this.state.configData.get('variables_id', null)}
        configVariablesValuesId={this.state.configData.get('variables_values_id', null)}
      />
    );
  },

  render() {
    if (
      shouldShowSimplifiedUi(this.state.component, this.state.configuration.get('configuration'))
    ) {
      return <SimplifiedUi />;
    }

    return (
      <>
        <ConfigurationTabs
          componentId={this.state.componentId}
          configId={this.state.configurationId}
        />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.configuration}
          hasFlows={this.state.hasFlows}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">
            {this.state.isDeprecated && !this.state.readOnly && (
              <MigrationRow
                componentId={this.state.componentId}
                replacementAppId={this.state.deprecationReplacementComponentId}
                hasNewQueue={this.state.hasNewQueue}
                hasFlows={this.state.hasFlows}
              />
            )}
            <ComponentDescription
              componentId={this.state.componentId}
              configId={this.state.configurationId}
              placeholderEntity={this.state.component.get('type')}
            />
            {this.renderVariables()}
            <IndexSections />
            {this.renderRowsTable()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={this.state.componentId}
              configId={this.state.configurationId}
              run={{
                forceModal: this.state.isChanged || this.state.isJsonChanged,
                text: this.renderRunModalContent()
              }}
            />
          </div>
        </div>
      </>
    );
  }
});

export default Index;
