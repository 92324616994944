import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import _ from 'underscore';

import nextTick from '../../utils/nextTick';
import CatchUnsavedChangesModal from './CatchUnsavedChangesModal';

class CatchUnsavedChanges extends React.Component {
  state = {
    isConfirmed: false,
    showConfirm: false,
    nextLocation: null
  };

  routeLeaveHookRef = null;

  componentDidMount() {
    this.routeLeaveHookRef = this.props.router.setRouteLeaveHook(
      _.last(this.props.routes),
      this.checkIsDirty
    );
  }

  componentWillUnmount() {
    if (this.props.isDirty && this.props.onDirtyLeave) {
      nextTick(this.props.onDirtyLeave);
    }

    this.routeLeaveHookRef?.();
    this.routeLeaveHookRef = null;
  }

  render() {
    return (
      <>
        {this.props.children}
        <CatchUnsavedChangesModal
          text={this.props.text}
          show={this.state.showConfirm}
          onHide={this.onHide}
          onLeave={() => this.handleLeave(this.state.nextLocation)}
          onSave={() => this.handleSave(this.state.nextLocation)}
          isSaveDisabled={this.props.isSaveDisabled}
        />
      </>
    );
  }

  handleSave = (nextLocation) => {
    return this.props.onSave().then(() => this.handleLeave(nextLocation));
  };

  handleLeave = (nextLocation) => {
    this.setState({ isConfirmed: true }, () => {
      this.props.router.push(nextLocation);
      this.onHide();
    });
  };

  onHide = () => {
    this.setState({ showConfirm: false, isConfirmed: false, nextLocation: null });
  };

  checkIsDirty = (nextLocation) => {
    if (this.props.isDirty && !this.state.isConfirmed && !nextLocation?.state?.force) {
      this.setState({ showConfirm: true, nextLocation });
      return false;
    }

    return true;
  };
}

CatchUnsavedChanges.propTypes = {
  router: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  isDirty: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool,
  onDirtyLeave: PropTypes.func,
  text: PropTypes.node
};

// eslint-disable-next-line import/no-anonymous-default-export
export default withRouter(CatchUnsavedChanges);
