import React from 'react';

import { USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import ExternalLink from '../../../react/common/ExternalLink';

const ReadOnlyDocumentationLink = () => {
  return (
    <ExternalLink
      href={`${USER_DOCUMENTATION_URL}/transformations/mappings/#read-only-input-mapping`}
    >
      Learn More
    </ExternalLink>
  );
};

export default ReadOnlyDocumentationLink;
