import BluebirdPromise from 'bluebird';

import ApplicationActionCreators from '../../actions/ApplicationActionCreators';
import Api from '../../api';
import type {
  GenericError,
  StepPayload,
  Task,
  UpdateInstanceRequestBody,
  ValidationError,
  VersionDetailExtended
} from '../../api/routes/templatesService';
import Dispatcher from '../../Dispatcher';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import { actionTypes, defaults } from './constants';
import { getPreconfiguredValues, isTemplateVersionLoaded } from './helpers';
import store from './store';

const loadTemplatesOnce = () => {
  if (store.getStore().templates.length) return;

  return Api.templatesService
    .TemplatesIndex({ repository: defaults.REPOSITORY })
    .then((templates) =>
      Dispatcher.handleViewAction({
        type: actionTypes.LOAD_TEMPLATES_SUCCESS,
        templates
      })
    );
};

const loadTemplateDetail = (
  template: string,
  version = defaults.TEMPLATE_VERSION
): BluebirdPromise<VersionDetailExtended> => {
  const loadedTemplateVersionDetail = store.getStore().templateVersionDetail;
  const promise = Api.templatesService
    .VersionIndex({ repository: defaults.REPOSITORY, template, version })
    .then((templateVersionDetail) => {
      Dispatcher.handleViewAction({
        type: actionTypes.LOAD_TEMPLATE_SUCCESS,
        templateVersionDetail
      });

      return templateVersionDetail;
    });

  if (
    loadedTemplateVersionDetail &&
    isTemplateVersionLoaded(loadedTemplateVersionDetail, template, version)
  ) {
    return BluebirdPromise.resolve(loadedTemplateVersionDetail);
  }

  return promise;
};

const loadTemplateInputs = (template: string, version = defaults.TEMPLATE_VERSION) => {
  return new BluebirdPromise<{ version: string; repository: string }>((resolve) => {
    const loadedTemplateVersionDetail = store.getStore().templateVersionDetail;

    if (
      loadedTemplateVersionDetail &&
      isTemplateVersionLoaded(loadedTemplateVersionDetail, template, version)
    ) {
      return resolve({
        version,
        repository: loadedTemplateVersionDetail.repository.name
      });
    }

    return resolve(
      loadTemplateDetail(template, version).then((templateVersionDetail) => ({
        version,
        repository: templateVersionDetail?.repository.name
      }))
    );
  }).then(({ version, repository }) =>
    Api.templatesService.InputsIndex({ repository, template, version }).then((inputs) =>
      Dispatcher.handleViewAction({
        type: actionTypes.CHANGE_INSTANCE_CONFIGURATOR_FORM,
        instanceConfiguratorForm: {
          inputs,
          userValues: getPreconfiguredValues(inputs),
          validation: inputs.initialState
        }
      })
    )
  );
};

const loadInstanceInputs = (instanceId: string, version = defaults.TEMPLATE_VERSION) =>
  Api.templatesService
    .UpgradeInstanceInputsIndex({ branch: defaults.BRANCH, instanceId, version })
    .then((inputs) =>
      Dispatcher.handleViewAction({
        type: actionTypes.CHANGE_INSTANCE_CONFIGURATOR_FORM,
        instanceConfiguratorForm: {
          inputs,
          userValues: getPreconfiguredValues(inputs),
          validation: inputs.initialState
        }
      })
    );

const loadInstances = () =>
  Api.templatesService.InstancesIndex({ branch: defaults.BRANCH }).then(({ instances }) => {
    const { templates } = store.getStore();
    const availableInstances = instances.filter(({ templateId }) =>
      templates.some((template) => template.id === templateId)
    );

    Dispatcher.handleViewAction({
      type: actionTypes.LOAD_INSTANCES_SUCCESS,
      instances: availableInstances
    });

    return availableInstances;
  });

const loadInstanceDetail = (instanceId: string) =>
  Api.templatesService.InstanceIndex({ branch: defaults.BRANCH, instanceId }).then((instance) => {
    Dispatcher.handleViewAction({
      type: actionTypes.LOAD_INSTANCE_DETAIL_SUCCESS,
      instanceDetail: instance
    });

    return instance;
  });

const validateAllSteps = (
  repository: string,
  template: string,
  version: string,
  stepsValues?: StepPayload[]
) => {
  if (!stepsValues) {
    return;
  }

  return Api.templatesService.ValidateInputs(
    {
      repository,
      template,
      version
    },
    { steps: stepsValues }
  );
};

const validateStep = BluebirdPromise.method(
  (repository: string, template: string, version: string, stepValues: StepPayload) => {
    return Api.templatesService
      .ValidateInputs(
        {
          repository,
          template,
          version
        },
        { steps: [stepValues] }
      )
      .then((validationResult) =>
        validationResult.stepGroups
          .flatMap((group) => group.steps)
          .find(({ id }) => id === stepValues.id)
      );
  }
);

const changeInstanceConfiguratorForm = (
  repository: string,
  template: string,
  version: string,
  userValues: StepPayload[]
) => {
  const filteredValues = userValues.filter(Boolean);

  return BluebirdPromise.resolve(
    filteredValues.length ? validateAllSteps(repository, template, version, filteredValues) : null
  ).then((validation) => {
    Dispatcher.handleViewAction({
      type: actionTypes.CHANGE_INSTANCE_CONFIGURATOR_FORM,
      instanceConfiguratorForm: { userValues: filteredValues, validation }
    });
  });
};

const resetInstanceConfiguratorForm = () => {
  Dispatcher.handleViewAction({
    type: actionTypes.RESET_INSTANCE_CONFIGURATOR_FORM
  });
};

const createInstance = (
  repository: string,
  template: string,
  version: string,
  instanceName: string,
  userValues: StepPayload[]
) => {
  return Api.templatesService
    .UseTemplateVersion(
      {
        repository,
        template,
        version
      },
      { branch: defaults.BRANCH, name: instanceName, steps: userValues }
    )
    .then(resolveWhenTaskFinishes)
    .then(throwOnError)
    .then(handleInstanceChange);
};

const updateInstance = (instanceId: string, params: UpdateInstanceRequestBody) => {
  return Api.templatesService
    .UpdateInstance({ instanceId, branch: defaults.BRANCH }, params)
    .then((instanceDetail) => {
      Dispatcher.handleViewAction({
        type: actionTypes.LOAD_INSTANCE_DETAIL_SUCCESS,
        instanceDetail
      });
      loadInstances();
    });
};

const upgradeInstance = (
  instanceId: string,
  userValues: StepPayload[],
  version = defaults.TEMPLATE_VERSION
) => {
  return Api.templatesService
    .UpgradeInstance({ instanceId, version, branch: defaults.BRANCH }, { steps: userValues })
    .then(resolveWhenTaskFinishes)
    .then(throwOnError)
    .then(handleInstanceChange);
};

const deleteInstance = (instanceId: string) => {
  return Api.templatesService
    .DeleteInstance({
      instanceId,
      branch: defaults.BRANCH
    })
    .then(() => {
      InstalledComponentsActionCreators.loadInstalledComponentsForce({ include: 'configuration' });

      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: 'Selected template has been removed.'
      });

      return loadInstances();
    });
};

const deleteInstances = (instanceIds: string[]) => {
  return BluebirdPromise.each(instanceIds, (instanceId) =>
    Api.templatesService.DeleteInstance({
      instanceId,
      branch: defaults.BRANCH
    })
  ).then(() => {
    InstalledComponentsActionCreators.loadInstalledComponentsForce({ include: 'configuration' });

    ApplicationActionCreators.sendNotification({
      type: 'info',
      message: `Selected ${
        instanceIds.length > 1 ? 'templates have' : 'template has'
      } been removed.`
    });

    return loadInstances();
  });
};

const resolveWhenTaskFinishes = async (
  response: GenericError | ValidationError | Task
): Promise<GenericError | ValidationError | Task> => {
  if ('isFinished' in response && !response.isFinished) {
    return BluebirdPromise.delay(500)
      .then(() => Api.templatesService.GetTask({ taskId: response.id }))
      .then(resolveWhenTaskFinishes);
  }

  return BluebirdPromise.resolve(response);
};

const throwOnError = (
  response: GenericError | ValidationError | Task
): undefined | ValidationError | Task => {
  if ('error' in response) {
    throw 'message' in response ? response.message : response.error;
  }

  return response;
};

const handleInstanceChange = (result: undefined | ValidationError | Task) => {
  InstalledComponentsActionCreators.loadInstalledComponentsForce({
    include: 'configuration'
  });
  loadInstances();

  if (result && 'outputs' in result && result.outputs?.instanceId) {
    return loadInstanceDetail(result.outputs.instanceId);
  }

  Dispatcher.handleViewAction({
    type: actionTypes.CHANGE_INSTANCE_CONFIGURATOR_FORM,
    instanceConfiguratorForm: {
      validation: result && 'ValidationResult' in result ? result.ValidationResult : null
    }
  });
};

export default {
  loadTemplatesOnce,
  loadTemplateDetail,
  loadTemplateInputs,
  loadInstanceInputs,
  loadInstances,
  loadInstanceDetail,
  changeInstanceConfiguratorForm,
  resetInstanceConfiguratorForm,
  validateAllSteps,
  validateStep,
  createInstance,
  updateInstance,
  upgradeInstance,
  deleteInstance,
  deleteInstances
};
