import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal
} from 'react-bootstrap';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import dayjs from '../../../date';
import ConfirmButtons from '../../../react/common/ConfirmButtons';
import InputValidation from '../../../react/common/InputValidation';
import ModalIcon from '../../../react/common/ModalIcon';
import Select from '../../../react/common/Select';
import { bucketLabel } from '../../../react/common/selectLabels';
import { canWriteBucket } from '../../admin/privileges';
import { filterProductionBuckets } from '../../dev-branches/helpers';
import { backends, nameWarning } from '../constants';
import { bucketDisplayNameWithStage, validateTableName } from '../helpers';

const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
const DATETIME_COMPACT_FORMAT = 'YYYYMMDDHHmmss';

const TimeTravelModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    buckets: PropTypes.object.isRequired,
    tables: PropTypes.instanceOf(Map).isRequired,
    table: PropTypes.object.isRequired,
    sapiToken: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired
  },

  getInitialState() {
    return this.defaultInitialState();
  },

  defaultInitialState() {
    const restoreData = dayjs(this.minRestoreDate()).add(5, 'minute');

    return {
      timestamp: restoreData.format(DATETIME_FORMAT),
      tableName: this.props.table.get('name') + '_' + restoreData.format(DATETIME_COMPACT_FORMAT),
      destinationBucket: this.props.table.getIn(['bucket', 'id']),
      warning: null,
      isSaving: false
    };
  },

  render() {
    const retentionLimit = this.props.sapiToken.getIn(['owner', 'dataRetentionTimeInDays']);
    const bucketsOptions = filterProductionBuckets(this.props.buckets)
      .filter((bucket) => canWriteBucket(this.props.sapiToken, bucket))
      .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
      .map((bucket) => {
        return {
          value: bucket.get('id'),
          label: bucketLabel(bucket),
          name: bucket.get('displayName')
        };
      })
      .toArray();

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState(this.defaultInitialState())}
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Restore table using time travel</Modal.Title>
            <ModalIcon color="green" icon="camera" bold />
          </Modal.Header>
          <Modal.Body>
            <p>
              This will create a new table which will be a replica of the data as it existed at the
              time of your choice. If the table has defined data types, they must be re-created
              after the table has been created. This method can not replicate data older than your
              project limit of <strong>{retentionLimit} days</strong>.
            </p>
            <FormGroup>
              <ControlLabel>Replication Date</ControlLabel>
              <FormControl
                type="datetime-local"
                placeholder={DATETIME_FORMAT}
                min={this.minRestoreDate().format(DATETIME_FORMAT)}
                max={dayjs().format(DATETIME_FORMAT)}
                value={this.state.timestamp}
                onChange={this.handleTimestamp}
                disabled={this.state.isSaving}
              />
              <HelpBlock>
                Date in <code>{DATETIME_FORMAT}</code> format.
              </HelpBlock>
            </FormGroup>
            <InputValidation predefined="tableName" value={this.state.tableName}>
              {(inputState) => (
                <FormGroup validationState={this.state.warning ? 'error' : inputState}>
                  <ControlLabel>Table Name</ControlLabel>
                  <FormControl
                    type="text"
                    disabled={this.state.isSaving}
                    value={this.state.tableName}
                    onChange={this.handleTableName}
                  />
                  <HelpBlock
                    className={classnames({
                      'text-danger': !!this.state.warning || inputState === 'error'
                    })}
                  >
                    {this.state.warning || nameWarning}
                  </HelpBlock>
                </FormGroup>
              )}
            </InputValidation>
            <FormGroup>
              <ControlLabel>Destination Bucket</ControlLabel>
              <Select
                clearable={false}
                disabled={this.state.isSaving}
                value={this.state.destinationBucket}
                onChange={this.handleDestinationBucket}
                options={bucketsOptions}
              />
            </FormGroup>
            {this.props.table.getIn(['bucket', 'backend']) === backends.BIGQUERY && (
              <Alert bsStyle="warning">
                Take note that primary keys are not preserved / restored. It must be set up manually
                after table creation.
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isSaving={this.state.isSaving}
              isDisabled={this.isDisabled()}
              saveLabel={this.state.isSaving ? 'Creating table...' : 'Create table'}
              saveButtonType="submit"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  handleTimestamp(e) {
    let tableName = this.state.tableName;

    if (this.state.tableName.match(/_\d{14}$/) && dayjs(e.target.value).isValid()) {
      tableName =
        this.props.table.get('name') + '_' + dayjs(e.target.value).format(DATETIME_COMPACT_FORMAT);
    }

    this.setState({ timestamp: e.target.value, tableName });
  },

  handleDestinationBucket(selected) {
    this.setState({ destinationBucket: selected }, this.validateName);
  },

  handleTableName(event) {
    this.setState({ tableName: event.target.value }, this.validateName);
  },

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ isSaving: true });
    this.props
      .onConfirm(this.state.destinationBucket, this.state.tableName, this.state.timestamp)
      .then(this.props.onHide)
      .catch((error) => {
        this.setState({ isSaving: false });
        throw error;
      });
  },

  validateName() {
    this.setState(({ tableName }) => ({
      warning: validateTableName(
        tableName,
        this.props.tables.filter(
          (table) => table.getIn(['bucket', 'id']) === this.state.destinationBucket
        )
      )
    }));
  },

  minRestoreDate() {
    const dataRetentionTimeInDays = this.props.sapiToken.getIn([
      'owner',
      'dataRetentionTimeInDays'
    ]);
    const projectRetentionMinDate = dayjs().subtract(dataRetentionTimeInDays, 'days');
    const tableCreatedDate = dayjs(this.props.table.get('created'));

    return dayjs.max(projectRetentionMinDate, tableCreatedDate);
  },

  isDisabled() {
    return (
      !this.isValidDate() ||
      !this.state.destinationBucket ||
      !this.state.tableName ||
      !!this.state.warning
    );
  },

  isValidDate() {
    const current = dayjs(this.state.timestamp);
    const min = this.minRestoreDate();
    const max = dayjs();

    return current.isValid() && current.isAfter(min) && current.isBefore(max);
  }
});

export default TimeTravelModal;
