import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';

import { DEVELOPERS_DOCUMENTATION_URL } from '../../constants/KbcConstants';
import ExternalLink from '../../react/common/ExternalLink';
import StorageActionCreators from '../components/StorageActionCreators';
import tokensActions from '../tokens/actionCreators';
import NewTokenHeaderButton from '../tokens/react/components/NewTokenHeaderButton';
import TokenHeaderButtons from '../tokens/react/components/TokenHeaderButtons';
import DetailPage from '../tokens/react/pages/Detail/Detail';
import NewPage from '../tokens/react/pages/New/New';
import TokensStore from '../tokens/StorageTokensStore';
import InviteUserHeaderButton from './components/InviteUserHeaderButton';
import {
  generateSessionToken,
  loadFeatures,
  loadOrganizations,
  loadOrganizationsViaMaintainer,
  loadProject,
  loadUsers
} from './actions';
import CliSync from './CliSync';
import { routeNames } from './constants';
import Project from './Project';
import Tokens from './Tokens';
import Users from './Users';

export const sessionTokenPoller = {
  interval: 10,
  action: () => generateSessionToken(),
  skipFirst: true
};

const PRODUCT_SETTING_TITLE = 'Project Settings';

export default [
  {
    name: routeNames.PROJECT,
    title: PRODUCT_SETTING_TITLE,
    defaultRouteHandler: Project,
    requireData: [
      () =>
        generateSessionToken().then(() =>
          Promise.all([
            loadProject(),
            loadOrganizationsViaMaintainer(),
            loadOrganizations(),
            loadFeatures()
          ])
        )
    ],
    poll: sessionTokenPoller
  },
  {
    name: routeNames.USERS,
    title: PRODUCT_SETTING_TITLE,
    defaultRouteHandler: Users,
    headerButtonsHandler: InviteUserHeaderButton,
    requireData: [() => generateSessionToken().then(loadUsers)],
    poll: sessionTokenPoller
  },
  {
    name: routeNames.TOKENS,
    title: PRODUCT_SETTING_TITLE,
    defaultRouteHandler: Tokens,
    headerButtonsHandler: NewTokenHeaderButton,
    requireData: [tokensActions.loadTokens],
    childRoutes: [
      {
        name: routeNames.NEW_TOKEN,
        path: 'new-token',
        title: 'New Token',
        defaultRouteHandler: NewPage,
        requireData: [() => StorageActionCreators.loadBucketsAndTables()]
      },
      {
        name: routeNames.TOKEN_DETAIL,
        path: ':tokenId',
        defaultRouteHandler: DetailPage,
        headerButtonsHandler: TokenHeaderButtons,
        title: (routerState) => {
          const tokenId = routerState.getIn(['params', 'tokenId']);
          const token = TokensStore.getAll().find((token) => token.get('id') === tokenId);

          if (!token) {
            return `Unknown token ${tokenId}`;
          }

          return `${token.get('description')} (${token.get('id')})`;
        },
        requireData: [() => StorageActionCreators.loadBucketsAndTables()]
      }
    ]
  },
  {
    name: routeNames.CLI,
    title: PRODUCT_SETTING_TITLE,
    defaultRouteHandler: CliSync,
    headerButtonsHandler: () => (
      <ExternalLink className="btn btn-default" href={`${DEVELOPERS_DOCUMENTATION_URL}/cli`}>
        <FontAwesomeIcon icon="book-blank" className="icon-addon-right" />
        Documentation
      </ExternalLink>
    )
  }
];
