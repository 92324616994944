let _baseUrl = '';
let _authHeaders: Record<string, string> = {};
let _fetch: typeof window.fetch = window.fetch;
export function init(baseUrl: string, storageApiToken: string, fetch: typeof window.fetch) {
  _baseUrl = baseUrl;
  _authHeaders = { 'X-StorageApi-Token': storageApiToken };
  _fetch = fetch;
}
export type Template = { content: string; language: 'jsonnet' };
export type Column = {
  name: string;
  primaryKey?: boolean | undefined;
  template?: { content: string; language: 'jsonnet' } | undefined;
  type: 'id' | 'datetime' | 'ip' | 'body' | 'headers' | 'template';
};
export type Conditions = {
  count?: number | undefined;
  size?: string | undefined;
  time?: string | undefined;
};
export type Mapping = { columns: Column[]; incremental?: boolean | undefined; tableId: string };
export type CreateExportData = {
  conditions?: Conditions | undefined;
  id?: string | undefined;
  mapping: { columns: Column[]; incremental?: boolean | undefined; tableId: string };
  name: string;
};
export type CreateReceiverRequestBody = {
  exports?: CreateExportData[] | undefined;
  id?: string | undefined;
  name: string;
};
export type Export = {
  conditions: Conditions;
  id: string;
  mapping: Mapping;
  name: string;
  receiverId: string;
};
export type ExportsList = { exports: Export[] };
export type GenericError = { error: string; message: string; statusCode: number };
export type Receiver = { exports: Export[]; id: string; name: string; url: string };
export type ReceiversList = { receivers: Receiver[] };
export type ServiceDetail = { api: string; documentation: string };
export type Task = {
  createdAt: string;
  duration?: number | undefined;
  error?: string | undefined;
  finishedAt?: string | undefined;
  id: string;
  isFinished: boolean;
  result?: string | undefined;
  status: 'processing' | 'success' | 'error';
  url: string;
};
export type UpdateExportRequestBody = {
  conditions?: Conditions | undefined;
  mapping?: Mapping | undefined;
  name?: string | undefined;
};
export type UpdateReceiverRequestBody = { name?: string | undefined };
/**
 * Redirect to /v1.
 */
export async function ApiRootIndex(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/';
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
/**
 * List API name and link to documentation.
 */
export async function ApiVersionIndex(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1';
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
export async function OpenapiJson(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi.json';
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
export async function OpenapiYaml(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi.yaml';
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
export async function Openapi3Json(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi3.json';
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
export async function Openapi3Yaml(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi3.yaml';
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
/**
 * Upload data into the receiver.
 */
export async function Import(params: {
  projectId: number;
  receiverId: string;
  secret: string;
  'Content-Type': string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/import/{projectId}/{receiverId}/{secret}'
    .replace('{projectId}', `${params['projectId']}`)
    .replace('{receiverId}', params['receiverId'])
    .replace('{secret}', params['secret']);
  const response = await _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': params['Content-Type'] }
  });
  return response;
}
/**
 * List all receivers for a given project.
 */
export async function ListReceivers(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers';
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
/**
 * Create a new receiver for a given project
 */
export async function CreateReceiver(body: CreateReceiverRequestBody): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers';
  const response = await _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });
  return response;
}
/**
 * Get the configuration of a receiver.
 */
export async function GetReceiver(params: { receiverId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers/{receiverId}'.replace('{receiverId}', params['receiverId']);
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
/**
 * Delete a receiver.
 */
export async function DeleteReceiver(params: { receiverId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers/{receiverId}'.replace('{receiverId}', params['receiverId']);
  const response = await _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
  return response;
}
/**
 * Update a receiver export.
 */
export async function UpdateReceiver(
  params: { receiverId: string },
  body: UpdateReceiverRequestBody
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers/{receiverId}'.replace('{receiverId}', params['receiverId']);
  const response = await _fetch(url.toString(), {
    method: 'patch',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });
  return response;
}
/**
 * List all exports for a given receiver.
 */
export async function ListExports(params: { receiverId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers/{receiverId}/exports'.replace('{receiverId}', params['receiverId']);
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
/**
 * Create a new export for an existing receiver.
 */
export async function CreateExport(
  params: { receiverId: string },
  body: CreateExportData
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers/{receiverId}/exports'.replace('{receiverId}', params['receiverId']);
  const response = await _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });
  return response;
}
/**
 * Get the configuration of an export.
 */
export async function GetExport(params: {
  receiverId: string;
  exportId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers/{receiverId}/exports/{exportId}'
    .replace('{receiverId}', params['receiverId'])
    .replace('{exportId}', params['exportId']);
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
/**
 * Delete a receiver export.
 */
export async function DeleteExport(params: {
  receiverId: string;
  exportId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers/{receiverId}/exports/{exportId}'
    .replace('{receiverId}', params['receiverId'])
    .replace('{exportId}', params['exportId']);
  const response = await _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
  return response;
}
/**
 * Update a receiver export.
 */
export async function UpdateExport(
  params: { receiverId: string; exportId: string },
  body: UpdateExportRequestBody
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers/{receiverId}/exports/{exportId}'
    .replace('{receiverId}', params['receiverId'])
    .replace('{exportId}', params['exportId']);
  const response = await _fetch(url.toString(), {
    method: 'patch',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });
  return response;
}
/**
 * Each export uses its own token scoped to the target bucket, this endpoint refreshes all of those tokens.
 */
export async function RefreshReceiverTokens(params: { receiverId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/receivers/{receiverId}/tokens/refresh'.replace(
    '{receiverId}',
    params['receiverId']
  );
  const response = await _fetch(url.toString(), { method: 'post', headers: { ..._authHeaders } });
  return response;
}
/**
 * Get details of a task.
 */
export async function GetTask(params: { taskId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/tasks/{taskId}'.replace('{taskId}', params['taskId']);
  const response = await _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
  return response;
}
