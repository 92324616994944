import React from 'react';

import JobStatusIcon from '../../../react/common/JobStatusIcon';
import { JOB_RUNNING_STATUSES } from '../../queue/constants';

type Props = {
  status?: string | null;
  children?: React.ReactNode;
};

const Status = ({ status, children }: Props) => (
  <div className="task-icon-wrapper">
    {children}
    {status && !JOB_RUNNING_STATUSES.includes(status) && (
      <JobStatusIcon className="task-sub-icon" status={status} />
    )}
  </div>
);

export default Status;
