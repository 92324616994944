import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import CreatedDate from '../../../../../react/common/CreatedDate';
import FileSize from '../../../../../react/common/FileSize';
import InputOutputTypeIcon from '../../../../../react/common/InputOutputTypeIcon';
import Link from '../../../../../react/common/RouterLink';
import RowActionDropdown from '../../../../../react/common/RowActionDropdown';
import RowActionMenuItem from '../../../../../react/common/RowActionMenuItem';
import Tooltip from '../../../../../react/common/Tooltip';
import RoutesStore from '../../../../../stores/RoutesStore';
import { ioType } from '../../../../components/Constants';
import RunComponentButton from '../../../../components/react/components/RunComponentButton';
import SapiTableLinkEx from '../../../../components/react/components/StorageApiTableLinkEx';
import { routeNames as storageRoutes } from '../../../../storage/constants';
import { tableName } from '../../../../storage/helpers';

const TableRow = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    table: PropTypes.object.isRequired,
    configId: PropTypes.string.isRequired,
    tdeFile: PropTypes.object,
    deleteRowFn: PropTypes.func,
    prepareRunDataFn: PropTypes.func,
    configData: PropTypes.object,
    uploadComponentId: PropTypes.string,
    tdeFileName: PropTypes.string,
    uploadComponentIdSetFn: PropTypes.func
  },

  render() {
    if (!this.props.tableExists) {
      return (
        <div
          className={classnames('tr hoverable-actions', {
            'text-muted': !this.props.tableExists
          })}
        >
          {this.renderBody()}
        </div>
      );
    }

    return (
      <Link
        className="tr hoverable-actions"
        to="tde-exporter-table"
        params={{
          config: this.props.configId,
          tableId: this.props.table.get('id')
        }}
      >
        {this.renderBody()}
      </Link>
    );
  },

  renderBody() {
    return (
      <>
        <span className="td col-xs-5">
          <SapiTableLinkEx tableId={this.props.table.get('id')} showOnlyDisplayName />
        </span>
        <span className="td">
          <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" fixedWidth />
        </span>
        <span className="td col-xs-5">
          {this.props.tdeFile ? (
            <>
              <InputOutputTypeIcon type={ioType.FILE} />
              <Tooltip
                placement="top"
                type="explanatory"
                tooltip={
                  <>
                    <div>{this.props.tdeFile.getIn(['creatorToken', 'description'])}</div>
                    <div>
                      <CreatedDate createdTime={this.props.tdeFile.get('created')} />
                    </div>
                    <div>
                      <FileSize size={this.props.tdeFile.get('sizeBytes')} />
                    </div>
                  </>
                }
              >
                <button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    RoutesStore.getRouter().transitionTo(storageRoutes.FILES, null, {
                      q: `id:${this.props.tdeFile.get('id')}`
                    });
                  }}
                  className="btn btn-link btn-link-inline"
                >
                  {this.props.tdeFile.get('name')}
                </button>
              </Tooltip>
            </>
          ) : (
            <>
              <InputOutputTypeIcon type={ioType.FILE} />
              {this.props.tdeFileName}
            </>
          )}
        </span>
        <span className="td pr-1">
          {!this.props.readOnly && (
            <RowActionDropdown>
              {this.props.tableExists && (
                <>
                  <RunComponentButton
                    mode="menuitem"
                    title="Export to TDE"
                    component="tde-exporter"
                    runParams={() => this.props.prepareRunDataFn()}
                  >
                    {`You are about export ${tableName(this.props.table)} to TDE.`}
                  </RunComponentButton>
                  <RowActionMenuItem divider />
                </>
              )}
              <RowActionMenuItem onSelect={() => this.props.deleteRowFn()}>
                <FontAwesomeIcon icon="trash" fixedWidth />
                Delete
              </RowActionMenuItem>
            </RowActionDropdown>
          )}
        </span>
      </>
    );
  }
});

export default TableRow;
