import React from 'react';
import createReactClass from 'create-react-class';

import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import StorageBucketsStore from '../../components/stores/StorageBucketsStore';
import LinkButton from './LinkButton';
import UnlinkButton from './UnlinkButton';

const SharedBucketHeaderButton = createReactClass({
  mixins: [createStoreMixin(StorageBucketsStore)],

  getStateFromStores() {
    const sharedBucketId = RoutesStore.getCurrentRouteParam('bucketId');
    const currentProjectId = ApplicationStore.getCurrentProjectId();
    const sharedProjectId = parseInt(RoutesStore.getCurrentRouteParam('projectId'), 10);

    return {
      allBuckets: StorageBucketsStore.getAll(),
      sapiToken: ApplicationStore.getSapiToken(),
      sharedBucket: StorageBucketsStore.getSharedBucket(
        sharedBucketId,
        currentProjectId,
        sharedProjectId
      )
    };
  },

  render() {
    if (!this.state.sharedBucket) {
      return null;
    }

    if (this.state.sharedBucket.get('linkedBucket')) {
      return (
        <UnlinkButton
          bucket={this.state.sharedBucket.get('linkedBucket')}
          token={this.state.sapiToken}
        />
      );
    }

    return (
      <LinkButton
        buckets={this.state.allBuckets}
        bucket={this.state.sharedBucket}
        token={this.state.sapiToken}
        withIcon
      />
    );
  }
});

export default SharedBucketHeaderButton;
