import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import ActivateDeactivateSwitch from '../../../../../react/common/ActivateDeactivateSwitch';
import Check from '../../../../../react/common/Check';
import Link from '../../../../../react/common/RouterLink';
import RowActionDropdown from '../../../../../react/common/RowActionDropdown';
import RowActionMenuItem from '../../../../../react/common/RowActionMenuItem';
import RunComponentButton from '../../../../components/react/components/RunComponentButton';
import * as actionsProvisioning from '../../../actionsProvisioning';
import QueryDeleteButton from '../../components/QueryDeleteButton';

const QueryRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    query: PropTypes.object.isRequired,
    pendingActions: PropTypes.object.isRequired,
    configurationId: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired
  },

  handleActiveChange: function (newValue) {
    const actionCreators = actionsProvisioning.createActions(this.props.componentId);
    return actionCreators.changeQueryEnabledState(
      this.props.configurationId,
      this.props.query.get('id'),
      newValue
    );
  },

  render: function () {
    const actionCreators = actionsProvisioning.createActions(this.props.componentId);
    const { configurationId, query, pendingActions, componentId } = this.props;

    return (
      <Link
        className={classnames('tr hoverable-actions', { 'row-disabled': !query.get('enabled') })}
        to="ex-mongodb-query"
        params={{
          config: configurationId,
          query: query.get('id')
        }}
      >
        <span className="td overflow-break-anywhere">
          {query.get('name') ? query.get('name') : <span className="text-muted">Untitled</span>}
        </span>
        <span className="td">
          <Label>{query.get('mode')}</Label>
        </span>
        <span className="td">
          <Check isChecked={query.get('incremental', false)} />
        </span>
        <span className="td pr-1">
          {!this.props.readOnly && (
            <RowActionDropdown
              showLoading={!!pendingActions.get('deleteQuery') || !!pendingActions.get('enabled')}
            >
              <RunComponentButton
                mode="menuitem"
                title="Run Export"
                component={componentId}
                runParams={() => {
                  return {
                    config: configurationId,
                    configData: actionCreators.prepareSingleQueryRunData(configurationId, query)
                  };
                }}
              >
                You are about to run an extraction.
              </RunComponentButton>
              <RowActionMenuItem divider />
              <ActivateDeactivateSwitch
                mode="menuitem"
                isActive={query.get('enabled')}
                isPending={pendingActions.get('enabled')}
                onChange={this.handleActiveChange}
              />
              <RowActionMenuItem divider />
              <QueryDeleteButton
                mode="menuitem"
                query={query}
                componentId={componentId}
                configurationId={configurationId}
                isPending={pendingActions.get('deleteQuery') || false}
                actionsProvisioning={actionsProvisioning}
              />
            </RowActionDropdown>
          )}
        </span>
      </Link>
    );
  }
});

export default QueryRow;
