import Promise from 'bluebird';

import { KEBOOLA_ORCHESTRATOR } from '../../constants/componentIds';
import InstalledComponentsActionsCreators from '../components/InstalledComponentsActionCreators';
import StorageActionCreators from '../components/StorageActionCreators';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import createVersionsPageRoute from '../components/utils/createVersionsPageRoute';
import VersionsActionCreators from '../components/VersionsActionCreators';
import * as NotoficationsActions from '../notifications/actions';
import NotificationsDetail from '../notifications/Detail';
import JobsActionCreators from '../queue/actions';
import { loadAll, loadOrchestration, loadTriggers } from './actions';
import { routeNames } from './constants';
import Index from './Index';
import IndexHeader from './IndexHeader';
import OrchestrationDetail from './Orchestration';
import Tasks from './Tasks';
import TasksHeader from './TasksHeader';

const routes = {
  name: routeNames.ROOT,
  title: 'Orchestrations',
  defaultRouteHandler: Index,
  headerButtonsHandler: IndexHeader,
  requireData: [
    (params) => {
      if (params.config) {
        return Promise.resolve();
      }

      return Promise.all([
        NotoficationsActions.loadNotifications(),
        loadAll().then(() => {
          // Can be lazy loaded because it's used only for graph on listing page
          JobsActionCreators.loadLatestJobsForConfigurations(
            KEBOOLA_ORCHESTRATOR,
            InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR)
              .keySeq()
              .toArray()
          );
        })
      ]);
    },
    loadTriggers
  ],
  poll: {
    skipFirst: true,
    interval: 15,
    action() {
      return JobsActionCreators.loadLatestJobsForConfigurationsForce(
        KEBOOLA_ORCHESTRATOR,
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR).keySeq().toArray()
      );
    }
  },
  childRoutes: [
    {
      name: routeNames.DETAIL,
      path: ':config',
      title(routerState) {
        return InstalledComponentsStore.getConfig(
          KEBOOLA_ORCHESTRATOR,
          routerState.getIn(['params', 'config'])
        ).get('name');
      },
      defaultRouteHandler: OrchestrationDetail,
      requireData: [
        (params) => {
          return Promise.all([
            loadOrchestration(params.config),
            VersionsActionCreators.loadVersions(KEBOOLA_ORCHESTRATOR, params.config),
            // Wait maximum 300ms for the request or skip it and show loading indicator in the UI.
            Promise.any([
              Promise.delay(300),
              JobsActionCreators.loadComponentConfigurationLatestJobs(
                KEBOOLA_ORCHESTRATOR,
                params.config
              )
            ])
          ]);
        },
        NotoficationsActions.loadNotifications,
        StorageActionCreators.loadBucketsAndTables
      ],
      poll: {
        skipFirst: true,
        interval: 15,
        action(params) {
          return Promise.all([
            JobsActionCreators.loadComponentConfigurationLatestJobs(
              KEBOOLA_ORCHESTRATOR,
              params.config
            ),
            VersionsActionCreators.reloadVersionsAndNotifyIfDoNotMatch(
              KEBOOLA_ORCHESTRATOR,
              params.config
            )
          ]);
        }
      },
      childRoutes: [
        {
          name: routeNames.TASKS,
          title: 'Tasks',
          path: 'tasks',
          defaultRouteHandler: Tasks,
          headerButtonsHandler: TasksHeader,
          requireData: [
            () =>
              InstalledComponentsActionsCreators.reloadInstalledComponents({
                include: 'configuration'
              }),
            () => InstalledComponentsActionsCreators.loadComponentsMetadata()
          ]
        },
        {
          name: routeNames.NOTIFICATIONS,
          title: 'Notifications',
          path: 'notifications',
          defaultRouteHandler: NotificationsDetail
        },
        createVersionsPageRoute(KEBOOLA_ORCHESTRATOR, 'config', routeNames.VERSIONS)
      ]
    }
  ]
};

export default routes;
