import React from 'react';
import createReactClass from 'create-react-class';

import TerminateButton from '../../../../react/common/JobTerminateButton';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import ActionCreators from '../../ActionCreators';
import JobsStore from '../../stores/JobsStore';

const JobDetailButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, JobsStore)],

  getStateFromStores() {
    const jobId = RoutesStore.getCurrentRouteIntParam('jobId');

    return {
      job: JobsStore.get(jobId),
      sapiToken: ApplicationStore.getSapiToken(),
      isTerminating: JobsStore.getIsJobTerminating(jobId)
    };
  },

  render() {
    return (
      <TerminateButton
        job={this.state.job}
        sapiToken={this.state.sapiToken}
        isTerminating={this.state.isTerminating}
        onTerminate={this.handleTerminate}
      />
    );
  },

  handleTerminate() {
    return ActionCreators.terminateJob(this.state.job.get('id'));
  }
});

export default JobDetailButtons;
