import _ from 'underscore';

import {
  DEV_CREDENTIALS,
  HIDE_DASHBOARD_WELCOME_WIDGET,
  PLATFORM_NEWS_LAST_SEEN,
  ROOT,
  SHOW_CHILD_JOBS
} from '../constants/localStorageKeys';
import ApplicationStore from '../stores/ApplicationStore';

const KEYS_WITHOUT_FALLBACK = [HIDE_DASHBOARD_WELCOME_WIDGET];
const PROJECT_AGNOSTIC_KEYS = [DEV_CREDENTIALS, PLATFORM_NEWS_LAST_SEEN, SHOW_CHILD_JOBS];
const CHANGE_EVENT_KEY = 'local-storage-changed';

const getStore = () => {
  const store = window.localStorage.getItem(ROOT);
  return store ? JSON.parse(store) : {};
};

const getKey = (key) => {
  return PROJECT_AGNOSTIC_KEYS.includes(key)
    ? key
    : `${ApplicationStore.getCurrentProjectId()}-${key}`;
};

const getItem = (key, defaultValue) => {
  const store = getStore();
  const projectSpecificKey = `${ApplicationStore.getCurrentProjectId()}-${key}`;
  const value =
    (projectSpecificKey in store || KEYS_WITHOUT_FALLBACK.includes(key)) &&
    !PROJECT_AGNOSTIC_KEYS.includes(key)
      ? store[projectSpecificKey]
      : store[key];

  return typeof value !== 'undefined' ? value : defaultValue;
};

const setItem = (key, value) => {
  window.localStorage.setItem(ROOT, JSON.stringify({ ...getStore(), [getKey(key)]: value }));
  dispatchChange();
};

const removeItem = (key) => {
  const store = { ...getStore() };
  delete store[getKey(key)];
  window.localStorage.setItem(ROOT, JSON.stringify(store));
  dispatchChange();
};

const dispatchChange = _.debounce(() => window.dispatchEvent(new Event(CHANGE_EVENT_KEY)), 100);

export { getItem, setItem, removeItem, CHANGE_EVENT_KEY };
