import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import Loader from './Loader';

type Props = {
  isSaving?: boolean;
  isDisabled?: boolean;
  cancelLabel?: string;
  saveLabel?: string;
  saveStyle?: string;
  onCancel?: (event: React.SyntheticEvent) => void;
  onSave?: (event: React.FormEvent<HTMLFormElement> | React.SyntheticEvent) => void;
  saveButtonType?: 'button' | 'submit';
  showCancel?: boolean;
  showSave?: boolean;
  block?: boolean;
  className?: string | Record<string, boolean>;
  saveButtonClass?: string | Record<string, boolean>;
  children?: React.ReactNode;
};

class ConfirmButtons extends React.Component<Props> {
  static defaultProps = {
    onSave: () => {},
    onCancel: () => {},
    saveLabel: 'Save',
    saveStyle: 'success',
    cancelLabel: 'Cancel',
    saveButtonType: 'button',
    isSaving: false,
    isDisabled: false,
    showSave: true,
    showCancel: true,
    block: false
  };

  render() {
    if (this.props.block) {
      return this.renderSaveButton();
    }

    return (
      <ButtonToolbar className={classnames('btn-toolbar-confirm', this.props.className)}>
        {this.props.isSaving && <Loader />}
        {this.props.children}
        {!this.props.showCancel && ' '}
        {this.renderCancelButton()}
        {this.renderSaveButton()}
      </ButtonToolbar>
    );
  }

  renderSaveButton() {
    if (!this.props.showSave) {
      return null;
    }

    return (
      <Button
        className={classnames('btn-confirm', this.props.saveButtonClass)}
        block={this.props.block}
        type={this.props.saveButtonType}
        bsStyle={this.props.saveStyle}
        disabled={this.props.isSaving || this.props.isDisabled}
        onClick={this.props.onSave}
      >
        {this.props.block && (
          <>
            {this.props.isSaving ? (
              <Loader className="icon-addon-right" />
            ) : (
              this.props.saveStyle &&
              ['success', 'primary'].includes(this.props.saveStyle) && (
                <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
              )
            )}
          </>
        )}
        {this.props.saveLabel}
      </Button>
    );
  }

  renderCancelButton() {
    if (!this.props.showCancel) {
      return null;
    }

    return (
      <Button
        className="btn-confirm"
        bsStyle="link"
        disabled={this.props.isSaving}
        onClick={this.props.onCancel}
      >
        {this.props.cancelLabel}
      </Button>
    );
  }
}

export default ConfirmButtons;
