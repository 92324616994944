import React from 'react';
import PropTypes from 'prop-types';

import blankComponentsImage from '../../../../../images/illustrations/blank-components.png';
import { componentTypes } from '../../../../constants/componentTypes';
import { fileUrl } from '../../../../utils/fileHelpers';

const HEADER = {
  [componentTypes.TRANSFORMATION]: 'Your data source extracts data. Let’s transform it.'
};

const TEXTS = {
  [componentTypes.TRANSFORMATION]:
    'Data gets transformed all the time. You can use SQL, Python, or R.'
};

const BlankComponentsPage = ({ type }) => (
  <div className="blank-page">
    <img
      src={fileUrl(blankComponentsImage)}
      className="intro-image"
      alt="Components introduction"
    />
    <h2>{HEADER[type]}</h2>
    <p className="intro-text">{TEXTS[type] || 'Get started with your first component!'}</p>
  </div>
);

BlankComponentsPage.propTypes = {
  type: PropTypes.oneOf(Object.values(componentTypes)).isRequired
};

export default BlankComponentsPage;
