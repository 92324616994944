import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import ChangeOrganizationModal from './ChangeOrganizationModal';

class ChangeOrganizationButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  render() {
    return (
      <>
        <Button
          bsStyle="link"
          className="btn-link-muted btn-link-inline icon-addon-left"
          onClick={() => this.setState({ showModal: true })}
        >
          <FontAwesomeIcon icon="pen" />
        </Button>
        <ChangeOrganizationModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          project={this.props.project}
          organizations={this.props.organizations}
          onSubmit={this.props.onSubmit}
        />
      </>
    );
  }
}

ChangeOrganizationButton.propTypes = {
  project: PropTypes.instanceOf(Map).isRequired,
  organizations: PropTypes.instanceOf(List).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ChangeOrganizationButton;
