import Promise from 'bluebird';

import * as serviceIds from '../constants/serviceIds';
import HttpError from '../utils/errors/HttpError';
import SimpleError from '../utils/errors/SimpleError';
import * as _Api from './routes/index';
type _Api = typeof _Api;

// When re-generating the API routes, this object should be modified
// to include any new API routes, remove old ones, or to update
// existing ones with new response types.
const Api = {
  templatesService: {
    TemplatesIndex: def(
      _Api.templatesService.TemplatesIndex
    ).body<_Api.templatesService.Templates>(),
    VersionIndex: def(
      _Api.templatesService.VersionIndex
    ).body<_Api.templatesService.VersionDetailExtended>(),
    InputsIndex: def(_Api.templatesService.InputsIndex).body<_Api.templatesService.Inputs>(),
    ValidateInputs: def(
      _Api.templatesService.ValidateInputs
    ).body<_Api.templatesService.ValidationResult>(),
    UseTemplateVersion: def(_Api.templatesService.UseTemplateVersion).body<
      _Api.templatesService.ValidationError | _Api.templatesService.Task
    >(),
    InstancesIndex: def(
      _Api.templatesService.InstancesIndex
    ).body<_Api.templatesService.Instances>(),
    InstanceIndex: def(
      _Api.templatesService.InstanceIndex
    ).body<_Api.templatesService.InstanceDetail>(),
    UpdateInstance: def(
      _Api.templatesService.UpdateInstance
    ).body<_Api.templatesService.InstanceDetail>(),
    UpgradeInstanceInputsIndex: def(
      _Api.templatesService.UpgradeInstanceInputsIndex
    ).body<_Api.templatesService.Inputs>(),
    UpgradeInstanceValidateInputs: def(
      _Api.templatesService.UpgradeInstanceValidateInputs
    ).body<_Api.templatesService.ValidationResult>(),
    UpgradeInstance: def(_Api.templatesService.UpgradeInstance).body<
      _Api.templatesService.ValidationError | _Api.templatesService.Task
    >(),
    DeleteInstance: def(_Api.templatesService.DeleteInstance).body(),
    GetTask: def(_Api.templatesService.GetTask).body<_Api.templatesService.Task>()
  },
  bufferService: {
    ListReceivers: def(_Api.bufferService.ListReceivers).body<_Api.bufferService.ReceiversList>(),
    CreateReceiver: def(_Api.bufferService.CreateReceiver).body<
      _Api.bufferService.Task | _Api.bufferService.GenericError
    >(),
    DeleteReceiver: def(_Api.bufferService.DeleteReceiver).body<
      never | _Api.bufferService.GenericError
    >(),
    GetReceiver: def(_Api.bufferService.GetReceiver).body<
      _Api.bufferService.Receiver | _Api.bufferService.GenericError
    >(),
    UpdateReceiver: def(_Api.bufferService.UpdateReceiver).body<
      _Api.bufferService.Receiver | _Api.bufferService.GenericError
    >(),
    ListExports: def(_Api.bufferService.ListExports).body<_Api.bufferService.ExportsList>(),
    CreateExport: def(_Api.bufferService.CreateExport).body<
      _Api.bufferService.Task | _Api.bufferService.GenericError
    >(),
    DeleteExport: def(_Api.bufferService.DeleteExport).body<
      never | _Api.bufferService.GenericError
    >(),
    GetExport: def(_Api.bufferService.GetExport).body<
      _Api.bufferService.Export | _Api.bufferService.GenericError
    >(),
    UpdateExport: def(_Api.bufferService.UpdateExport).body<
      _Api.bufferService.Task | _Api.bufferService.GenericError
    >(),
    GetTask: def(_Api.bufferService.GetTask).body<
      _Api.bufferService.Task | _Api.bufferService.GenericError
    >(),
    RefreshReceiverTokens: def(_Api.bufferService.RefreshReceiverTokens).body<
      _Api.bufferService.Receiver | _Api.bufferService.GenericError
    >()
  },
  dataScienceService: {
    runtimes: def(_Api.dataScienceService.runtimes).body<_Api.dataScienceService.Runtime>()
  },
  vaultService: {
    listVariables: def(_Api.vaultService.listVariables).body<
      _Api.vaultService.VariableWithHash[]
    >(),
    listBranchVariables: def(_Api.vaultService.listBranchVariables).body<
      _Api.vaultService.VariableWithHash[]
    >(),
    createVariable: def(
      _Api.vaultService.createVariable
    ).body<_Api.vaultService.VariableWithHash>(),
    deleteVariable: def(_Api.vaultService.deleteVariable).body()
  }
};

// ensure that all generated api routes are wrapped
// eslint-disable-next-line
const _check: {
  [M in keyof _Api]: {
    [R in keyof _Api[M] as R extends 'init' ? never : R]?: (...args: any[]) => any;
  };
} = Api;

const MAXIMUM_RETRIES = 5;

// wraps `fetch` to return bluebird `Promise` instead of native one,
// and handles error response by wrapping it in `HttpError`
function fetchWrapper<Body>(
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  retry = 1
): Promise<Body> {
  return Promise.resolve().then(() => {
    return fetch(input, init).then((response) => {
      if (response.status === 503) {
        if (retry > MAXIMUM_RETRIES) {
          throw new SimpleError(
            'Maximum retries exceeded',
            'The project is locked, another operation is in progress, please try again later.'
          );
        }

        return Promise.delay(Math.pow(3, retry) * 100).then(() => {
          return fetchWrapper(input, init, retry + 1);
        });
      }

      return response
        .json()
        .then((body) => {
          if (response.ok || 'ValidationResult' in body) {
            return body;
          }

          throw new HttpError(body);
        })
        .catch((error) => {
          if (!response.ok) {
            throw error?.response ? error : new HttpError(error || response);
          }
        });
    });
  });
}

type ServiceId = (typeof serviceIds)[keyof typeof serviceIds];
type Services = { id: ServiceId; url: string }[];

function getServiceUrl(services: Services, serviceId: ServiceId) {
  return services.find(({ id }) => id === serviceId)?.url || '';
}

export function init(services: Services, sapiToken: string) {
  _Api.templatesService.init(
    getServiceUrl(services, serviceIds.SERVICE_TEMPLATES),
    sapiToken,
    fetchWrapper
  );
  _Api.bufferService.init(
    getServiceUrl(services, serviceIds.SERVICE_BUFFER),
    sapiToken,
    fetchWrapper
  );
  _Api.dataScienceService.init(
    getServiceUrl(services, serviceIds.SERVICE_DATA_SCIENCE),
    fetchWrapper
  );
  _Api.vaultService.init(
    getServiceUrl(services, serviceIds.SERVICE_VAULT),
    sapiToken,
    fetchWrapper
  );
}

// definition is done in two steps, because TypeScript currently
// does not support partial type parameter inference
function def<Args extends any[]>(
  route: (...args: Args) => globalThis.Promise<Response>
): { body<Body>(): (...args: Args) => Promise<Body> } {
  return {
    body() {
      return route as any;
    }
  };
}

export default Api;
