import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'underscore';

import { CODE_EDITOR_MODE } from '../../constants/localStorageKeys';
import { getItem, setItem } from '../../utils/localStorage';
import nextTick from '../../utils/nextTick';
import Clipboard from '../common/Clipboard';
import CatchUnsavedChanges from './CatchUnsavedChanges';
import CatchUnsavedChangesModal from './CatchUnsavedChangesModal';
import CodeEditor from './CodeEditor';
import FullScreenModal from './FullScreenModal';
import Tooltip from './Tooltip';

const MODES = {
  FULL_SCREEN: 'full-screen',
  HALF_SCREEN: 'half-screen'
};

class CodeEditorModal extends React.Component {
  state = {
    show: true,
    showConfirm: false,
    mode: getItem(CODE_EDITOR_MODE, MODES.HALF_SCREEN)
  };

  componentDidMount() {
    document.dispatchEvent(
      new CustomEvent('new-editor-open', { detail: { closeNewEditor: this.props.onClose } })
    );
    document.addEventListener('new-editor-open', this.onHide);
  }

  componentWillUnmount() {
    document.removeEventListener('new-editor-open', this.onHide);
  }

  render() {
    const isHalfScreen = this.state.mode === MODES.HALF_SCREEN;

    return (
      <>
        <CSSTransition appear timeout={300} in={this.state.show} classNames="slide-up">
          <FullScreenModal
            onHide={this.onHide}
            hideOverflow={!isHalfScreen}
            className={classNames('full-screen-editor CodeMirror-search-dialog-root', {
              'half-screen': this.state.mode === MODES.HALF_SCREEN,
              'with-warning': this.props.warning
            })}
          >
            <Modal.Header>
              <div className="CodeMirror-search-dialog-wrapper flex-container">
                <Modal.Title className="flex-container flex-start mr-2">
                  {this.props.title}
                </Modal.Title>
                <ButtonToolbar>
                  <div className="tw-flex tw-items-center tw-h-11">
                    <Clipboard
                      tooltipText="Copy code to clipboard"
                      tooltipPlacement="bottom"
                      text={this.props.value}
                      btnClassName="ml-0 !tw-py-3"
                    />
                  </div>

                  <Tooltip
                    key="full-screen"
                    placement="bottom"
                    tooltip={`Switch to ${isHalfScreen ? 'full' : 'half'} screen view`}
                  >
                    <Button
                      bsStyle="link"
                      className="btn-link-muted ml-0"
                      onClick={() =>
                        this.setMode(isHalfScreen ? MODES.FULL_SCREEN : MODES.HALF_SCREEN)
                      }
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={
                          isHalfScreen
                            ? 'up-right-and-down-left-from-center'
                            : 'down-left-and-up-right-to-center'
                        }
                      />
                    </Button>
                  </Tooltip>
                  {this.props.renderAdditionalButtons?.()}
                  <Button onClick={() => this.handleCloseModal()}>
                    <FontAwesomeIcon icon="xmark" />
                  </Button>
                </ButtonToolbar>
              </div>
              {this.props.warning && (
                <Alert bsStyle="warning" className="mt-1">
                  {this.props.warning}
                </Alert>
              )}
            </Modal.Header>
            <Modal.Body>
              <CatchUnsavedChanges
                isDirty={!!this.props.isChanged}
                onSave={this.props.onSave ?? _.noop}
                onDirtyLeave={this.props.onReset}
              >
                <CodeEditor
                  withSearch
                  withAutocomplete={!!this.props.withAutocomplete}
                  key={this.props.editorKey}
                  editorDidMount={this.editorDidMount}
                  value={this.props.value}
                  onChange={this.props.onChange}
                  options={this.getCodemirrorOptions()}
                />
              </CatchUnsavedChanges>
            </Modal.Body>
          </FullScreenModal>
        </CSSTransition>
        <CatchUnsavedChangesModal
          show={this.state.showConfirm}
          onHide={() => this.setState({ showConfirm: false })}
          onLeave={this.handleCloseModal}
          onSave={() => this.props.onSave?.().then(this.handleCloseModal)}
          text="You have unsaved changes! If you close editor, your unsaved changes will be discarded and your work will be lost."
          leaveLabel="Close without saving"
        />
      </>
    );
  }

  getCodemirrorOptions = () => {
    const options = {
      extraKeys: {
        ...(this.props.onSave && {
          'Ctrl-Enter': this.props.onSave,
          'Cmd-Enter': this.props.onSave,
          'Shift-Ctrl-Enter': () => this.props.onSave().then(this.handleCloseModal),
          'Shift-Cmd-Enter': () => this.props.onSave().then(this.handleCloseModal)
        }),
        'Ctrl-Space': 'autocomplete',
        'Alt-Space': 'autocomplete'
      },
      ...(this.props.codeMirrorOptions || {})
    };

    return options;
  };

  editorDidMount = (editor) => {
    if (this.props.value) {
      nextTick(() => editor.refresh());
    }

    if (this.props.autoFocus) {
      editor.focus();
    }

    if (this.props.onEditorDidMount) {
      this.props.onEditorDidMount(editor);
    }
  };

  onHide = (event) => {
    if (
      !this.state.showConfirm &&
      this.props.isChanged &&
      !document.body.classList.contains('modal-open')
    ) {
      event?.detail?.closeNewEditor();
      this.setState({ showConfirm: true });
    } else if (!this.props.isChanged) {
      this.handleCloseModal();
    }
  };

  setMode = (mode) => {
    this.setState({ mode });
    setItem(CODE_EDITOR_MODE, mode);
  };

  handleCloseModal = () => {
    if (this.state.mode === MODES.FULL_SCREEN) {
      return this.props.onClose();
    }

    this.setState({ show: false }, () => {
      setTimeout(this.props.onClose, 300);
    });
  };
}

CodeEditorModal.propTypes = {
  title: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onReset: PropTypes.func,
  isChanged: PropTypes.bool,
  renderAdditionalButtons: PropTypes.func,
  autoFocus: PropTypes.bool,
  withAutocomplete: PropTypes.bool,
  value: PropTypes.string,
  editorKey: PropTypes.string,
  onEditorDidMount: PropTypes.func,
  codeMirrorOptions: PropTypes.object,
  warning: PropTypes.string
};

export default CodeEditorModal;
