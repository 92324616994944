import Immutable from 'immutable';

import {
  KEBOOLA_PROCESSOR_CREATE_MANIFEST,
  KEBOOLA_PROCESSOR_DECOMPRESS,
  KEBOOLA_PROCESSOR_FLATTEN_FOLDERS,
  KEBOOLA_PROCESSOR_MOVE_FILES,
  KEBOOLA_PROCESSOR_SKIP_LINES
} from '../../../constants/componentIds';

export function createConfiguration(localState) {
  let skipLinesProcessor;
  let decompressProcessor;
  let flattenFoldersProcessor;
  let createManifestProcessor = Immutable.fromJS({
    definition: {
      component: KEBOOLA_PROCESSOR_CREATE_MANIFEST
    },
    parameters: {
      delimiter: localState.get('delimiter', ','),
      enclosure: localState.get('enclosure', '"'),
      incremental: localState.get('incremental', false),
      primary_key: localState.get('primaryKey', Immutable.List())
    }
  });

  if (localState.get('columnsFrom', 'header') === 'header') {
    createManifestProcessor = createManifestProcessor.setIn(
      ['parameters', 'columns_from'],
      'header'
    );
    skipLinesProcessor = Immutable.fromJS({
      definition: {
        component: KEBOOLA_PROCESSOR_SKIP_LINES
      },
      parameters: {
        lines: 1
      }
    });
  } else if (localState.get('columnsFrom') === 'auto') {
    createManifestProcessor = createManifestProcessor.setIn(['parameters', 'columns_from'], 'auto');
  } else if (localState.get('columnsFrom', 'manual') === 'manual') {
    createManifestProcessor = createManifestProcessor.setIn(
      ['parameters', 'columns'],
      localState.get('columns', Immutable.List())
    );
  }

  if (localState.get('decompress', false) === true) {
    decompressProcessor = Immutable.fromJS({
      definition: {
        component: KEBOOLA_PROCESSOR_DECOMPRESS
      }
    });
    flattenFoldersProcessor = Immutable.fromJS({
      definition: {
        component: KEBOOLA_PROCESSOR_FLATTEN_FOLDERS
      },
      parameters: {
        starting_depth: 1
      }
    });
  }

  let config = Immutable.fromJS({
    parameters: {
      path: localState.get('path', '')
    },
    processors: {
      after: []
    }
  });

  let processors = Immutable.List([]);

  if (decompressProcessor) {
    processors = processors.push(decompressProcessor);
  }
  processors = processors.push(
    Immutable.fromJS({
      definition: {
        component: KEBOOLA_PROCESSOR_MOVE_FILES
      },
      parameters: {
        direction: 'tables',
        folder: localState.get('name', '')
      }
    })
  );
  if (flattenFoldersProcessor) {
    processors = processors.push(flattenFoldersProcessor);
  }
  processors = processors.push(createManifestProcessor);

  if (skipLinesProcessor) {
    processors = processors.push(skipLinesProcessor);
  }

  config = config.setIn(['processors', 'after'], processors);

  return config;
}

export function parseConfiguration(configuration) {
  const processorCreateManifest = configuration
    .getIn(['processors', 'after'], Immutable.List())
    .find(
      function (processor) {
        return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_CREATE_MANIFEST;
      },
      null,
      Immutable.Map()
    );
  const processorDecompress = configuration
    .getIn(['processors', 'after'], Immutable.List())
    .find(function (processor) {
      return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_DECOMPRESS;
    });
  const moveFilesProcessor = configuration.getIn(['processors', 'after'], Immutable.List()).find(
    function (processor) {
      return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_MOVE_FILES;
    },
    null,
    Immutable.Map()
  );

  let columnsFrom = processorCreateManifest.getIn(['parameters', 'columns_from'], 'header');
  if (processorCreateManifest.hasIn(['parameters', 'columns'])) {
    columnsFrom = 'manual';
  }

  return Immutable.fromJS({
    path: configuration.getIn(['parameters', 'path'], ''),
    name: moveFilesProcessor.getIn(['parameters', 'folder'], ''),
    incremental: processorCreateManifest.getIn(['parameters', 'incremental'], false),
    primaryKey: processorCreateManifest
      .getIn(['parameters', 'primary_key'], Immutable.List())
      .toJS(),
    delimiter: processorCreateManifest.getIn(['parameters', 'delimiter'], ','),
    enclosure: processorCreateManifest.getIn(['parameters', 'enclosure'], '"'),
    columns: processorCreateManifest.getIn(['parameters', 'columns'], Immutable.List()).toJS(),
    columnsFrom: columnsFrom,
    decompress: processorDecompress ? true : false
  });
}

export function createEmptyConfiguration(name, webalizedName) {
  return createConfiguration(Immutable.fromJS({ name: webalizedName }));
}
