/*
  Edit buttons
  When editing Save and Cancel buttons are shown. These buttons are disabled and loader is shown when saving.
  Edit butotn is shown when editing mode is disabled.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ConfirmButtons from './ConfirmButtons';

const EditButtons = createReactClass({
  propTypes: {
    isEditing: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onEditStart: PropTypes.func,
    isDisabled: PropTypes.bool,
    bsStyle: PropTypes.string,
    editLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    saveLabel: PropTypes.string
  },

  getDefaultProps() {
    return {
      bsStyle: 'success',
      editLabel: 'Edit',
      saveLabel: 'Save',
      cancelLabel: 'Cancel',
      isDisabled: false
    };
  },

  render() {
    if (this.props.isEditing) {
      return (
        <ConfirmButtons
          saveStyle={this.props.bsStyle}
          isSaving={this.props.isSaving}
          isDisabled={this.props.isDisabled}
          cancelLabel={this.props.cancelLabel}
          saveLabel={this.props.saveLabel}
          onCancel={this.props.onCancel}
          onSave={this.props.onSave}
        />
      );
    }

    return (
      <Button bsStyle={this.props.bsStyle} onClick={this.props.onEditStart}>
        {this.props.editLabel}
      </Button>
    );
  }
});

export default EditButtons;
