import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import InlineEditTextInput from '../../../../react/common/InlineEditTextInput';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import ConfigurationRowEditField from '../../../components/react/components/ConfigurationRowEditField';
import TransformationsStore from '../../stores/TransformationsStore';

const TransformationNameEditField = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, TransformationsStore)],

  propTypes: {
    configId: PropTypes.string.isRequired,
    rowId: PropTypes.string.isRequired
  },

  getStateFromStores() {
    return {
      transformation: TransformationsStore.getTransformation(this.props.configId, this.props.rowId),
      readOnly: ApplicationStore.isReadOnly()
    };
  },

  render() {
    if (this.state.readOnly) {
      return this.state.transformation.get('name');
    }

    if (!this.state.transformation) {
      return this.props.rowId;
    }

    return (
      <ConfigurationRowEditField
        key={`transformation-${this.props.configId}-${this.props.rowId}`}
        componentId="transformation"
        configId={this.props.configId}
        rowId={this.props.rowId}
        fieldName="name"
        editElement={InlineEditTextInput}
        placeholder="Choose a name..."
        tooltipPlacement="bottom"
        fallbackValue={this.state.transformation.get('name')}
      />
    );
  }
});

export default TransformationNameEditField;
