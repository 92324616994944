import React from 'react';
import { Button } from 'react-bootstrap';

import LoadingBlock from '../../react/common/LoadingBlock';
import contactSupport from '../../utils/contactSupport';
import TelemetryApi from './TelemetryApi';

const GoodDataDashboard = React.lazy(
  () => import(/* webpackChunkName: "gooddata-dashboard" */ './GoodDataDashboard')
);

type WorkspaceData = { userToken: string; workspaceId: string; dashboardId: string };

const BillingConsumptionInfo = () => {
  const [credentials, setCredentials] = React.useState<WorkspaceData | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    TelemetryApi.createCredentials()
      .then(setCredentials)
      .finally(() => setIsLoading(false));
  }, []);

  if (!credentials) {
    return <LoadingData isLoading={isLoading} />;
  }

  return (
    <React.Suspense fallback={<LoadingDashboard />}>
      <GoodDataDashboard
        userToken={credentials.userToken}
        workspaceId={credentials.workspaceId}
        dashboardId={credentials.dashboardId}
      />
    </React.Suspense>
  );
};

const LoadingData = (props: { isLoading: boolean; usageBoardIsNotAvailable?: boolean }) => {
  if (props.isLoading) {
    return <LoadingBlock label="Preparing data..." style={{ height: 48 }} />;
  }

  if (props.usageBoardIsNotAvailable) {
    return (
      <div className="box">
        <div className="tw-text-center tw-py-8 tw-px-4">
          <h3 className="m-0 tw-text-base tw-font-medium">
            Organization usage dashboard is not available
          </h3>
          <p className="tw-mx-auto tw-my-4 tw-text-neutral-400 tw-max-w-[550px]">
            Organization Usage is available only to our customers with a contract. Your organization
            is not linked to any customer account. Please contact your Keboola account manager or
            our support.
          </p>
          <Button bsStyle="success" onClick={() => contactSupport()}>
            Contact support
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="box">
      <div className="box-content">
        <p className="m-0">Dashboard is not available.</p>
      </div>
    </div>
  );
};

const LoadingDashboard = () => {
  return <LoadingBlock label="Loading dashboard..." style={{ height: 48 }} />;
};

export { LoadingData, LoadingDashboard, WorkspaceData };

export default BillingConsumptionInfo;
