import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import BlockButton from '../../react/common/BlockButton';
import FileSize from '../../react/common/FileSize';
import Link from '../../react/common/RouterLink';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import tableIdParser from '../../utils/tableIdParser';
import JobsStore from '../components/stores/StorageJobsStore';
import JobDetailModal from './components/JobDetailModal';
import JobsTable from './components/JobsTable';
import StorageTabs from './components/StorageTabs';
import { loadMoreJobs } from './actions';
import { routeNames } from './constants';

const Jobs = createReactClass({
  mixins: [createStoreMixin(RoutesStore, JobsStore)],

  getStateFromStores() {
    return {
      hasMore: JobsStore.hasMore(),
      isLoading: JobsStore.getIsLoading(),
      isLoadingMore: JobsStore.getIsLoadingMore(),
      jobs: JobsStore.getAll(),
      jobDetail: JobsStore.getJob(RoutesStore.getCurrentRouteParam('jobId'), Map()),
      canManageApps: ApplicationStore.getKbcVars().get('canManageApps'),
      admins: ApplicationStore.getAdmins()
    };
  },

  render() {
    return (
      <StorageTabs activeTab={routeNames.JOBS} hasEvents={this.state.canManageApps}>
        <div className="box">{this.renderJobs()}</div>
      </StorageTabs>
    );
  },

  renderJobs() {
    if (this.state.jobs.isEmpty() && !this.state.isLoading) {
      return <div className="box-content">No jobs have been uploaded yet.</div>;
    }

    return (
      <>
        <InfiniteScroll
          initialLoad={false}
          hasMore={this.state.hasMore}
          loadMore={this.fetchMoreJobs}
        >
          <JobsTable
            jobs={this.state.jobs}
            admins={this.state.admins}
            renderTableLink={this.renderTableLink}
            renderDataTransfer={this.renderDataTransfer}
            showJobDetailModal={this.showJobDetailModal}
          />
        </InfiniteScroll>
        {this.state.hasMore && (
          <BlockButton onClick={this.fetchMoreJobs} isLoading={this.state.isLoadingMore} />
        )}
        <JobDetailModal
          show={!this.state.jobDetail.isEmpty()}
          job={this.state.jobDetail}
          onHide={this.hideJobDetailModal}
          renderTableLink={this.renderTableLink}
          renderDataTransfer={this.renderDataTransfer}
        />
      </>
    );
  },

  fetchMoreJobs() {
    if (this.state.isLoadingMore) return;

    return loadMoreJobs(this.state.jobs.count());
  },

  showJobDetailModal(jobId) {
    RoutesStore.getRouter().replaceTo(routeNames.JOBS, {
      jobId
    });
  },

  hideJobDetailModal() {
    RoutesStore.getRouter().replaceTo(routeNames.JOBS);
  },

  renderTableLink(job) {
    const tableCreate = job.get('operationName') === 'tableCreate';
    const tableId = tableCreate
      ? job.getIn(['results', 'id'], job.get('tableId'))
      : job.get('tableId');

    if (!tableId) {
      return null;
    }

    const { stage, bucket, table } = tableIdParser.parse(tableId).parts;

    return (
      <Link
        to={routeNames.TABLE}
        params={{
          bucketId: `${stage}.${bucket}`,
          tableName: table
        }}
        onClick={(event) => event.stopPropagation()}
      >
        {tableId}
      </Link>
    );
  },

  renderDataTransfer(job) {
    const metric = job.get('metrics');
    const inData = metric.get('inCompressed', false)
      ? metric.get('inBytes')
      : metric.get('inBytesUncompressed');
    const outData = metric.get('outCompressed', false)
      ? metric.get('outBytes')
      : metric.get('outBytesUncompressed');

    return <FileSize size={inData + outData} />;
  }
});

export default Jobs;
