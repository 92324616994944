import { SERVICE_NOTIFICATION } from '../../constants/serviceIds';
import ApplicationStore from '../../stores/ApplicationStore';
import request from '../../utils/request';
import ServicesStore from '../services/Store';

export const createRequest = (method, path) => {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_NOTIFICATION)}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString()
  );
};

const NotificationsApi = {
  loadAll() {
    return createRequest('GET', 'project-subscriptions')
      .promise()
      .then((response) => response.body);
  },

  addNotification(params) {
    return createRequest('POST', 'project-subscriptions')
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  removeNotification(id) {
    return createRequest('DELETE', `project-subscriptions/${id}`)
      .promise()
      .then((response) => response.body);
  }
};

export default NotificationsApi;
