import { fromJS, List, Map } from 'immutable';
import { rtrim } from 'underscore.string';

import {
  FEATURE_ALLOW_AI,
  FEATURE_ALLOW_DESCRIPTION_AI,
  FEATURE_DISABLE_LEGACY_BUCKET_PREFIX,
  FEATURE_EXTERNAL_BUCKETS,
  FEATURE_HAS_FLOWS_ONLY,
  FEATURE_INPUT_MAPPING_READ_ONLY_STORAGE,
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_NEW_OAUTH_SERVICE,
  FEATURE_NEW_TRANSFORMATIONS_ONLY,
  FEATURE_PAY_AS_YOU_GO,
  FEATURE_QUEUE_V2,
  FEATURE_SHOW_TRANSFORMATION_MIGRATION,
  FEATURE_SOX_PROTECTED_DEFAULT_BRANCH,
  FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE
} from '../constants/features';
import { ActionTypes, ADMIN_ROLES } from '../constants/KbcConstants';
import Dispatcher from '../Dispatcher';
import DevBranchesStore from '../modules/dev-branches/DevBranchesStore';
import { isMergeRequestApproved, isMergeRequestInReview } from '../modules/dev-branches/helpers';
import StackFeaturesStore from '../modules/stack-features/Store';
import StorageTokenStoreConstants from '../modules/tokens/constants';
import StoreUtils, { initStore } from '../utils/StoreUtils';

let _store = initStore(
  'ApplicationStore',
  Map({
    stack: '',
    sapiUrl: '',
    initialLoading: false,
    sapiToken: Map(),
    organizations: List(),
    kbc: Map(), // contains - projectBaseUrl, admin (object)
    isDemoPreview: false
  })
);

const ApplicationStore = StoreUtils.createStore({
  isDemoPreview() {
    return _store.get('isDemoPreview', false);
  },

  isReadOnly() {
    const role = this.getSapiToken().getIn(['admin', 'role']);
    const mergeRequest = DevBranchesStore.getCurrentDevBranchMergeRequest();

    return (
      this.isDemoPreview() ||
      [ADMIN_ROLES.PRODUCTION_MANAGER, ADMIN_ROLES.READ_ONLY].includes(role) ||
      (DevBranchesStore.isDevModeActive()
        ? isMergeRequestInReview(mergeRequest) || isMergeRequestApproved(mergeRequest)
        : [ADMIN_ROLES.DEVELOPER, ADMIN_ROLES.REVIEWER].includes(role))
    );
  },

  getInitialLoading() {
    return _store.get('initialLoading');
  },

  getSapiToken() {
    return _store.getIn(['sapiToken']);
  },

  getSapiTokenString() {
    return _store.getIn(['sapiToken', 'token']);
  },

  getSapiUrl() {
    return _store.get('sapiUrl');
  },

  getOrganizations() {
    return _store.get('organizations');
  },

  getProjectTemplates() {
    return _store.get('projectTemplates');
  },

  getTokenStats() {
    return _store.get('tokenStats');
  },

  getCurrentStack() {
    return _store.get('stack', '');
  },

  getCurrentProjectId() {
    return _store.getIn(['sapiToken', 'owner', 'id']);
  },

  getCurrentProject() {
    return _store.getIn(['sapiToken', 'owner'], Map());
  },

  getCurrentProjectFeatures() {
    return this.getCurrentProject().get('features', List());
  },

  hasCurrentProjectFeature(feature) {
    return this.getCurrentProjectFeatures().includes(feature);
  },

  hasCurrentAdminFeature(feature) {
    return _store.getIn(['kbc', 'admin', 'features'], List()).includes(feature);
  },

  getCurrentAdmin() {
    return _store.getIn(['kbc', 'admin']);
  },

  getAdmins() {
    return _store.getIn(['kbc', 'admins']);
  },

  getSharingToAdminsData() {
    return _store
      .getIn(['kbc', 'sharingToAdminsData'], List())
      .filter((user) => user.get('id') !== _store.getIn(['kbc', 'admin', 'id']))
      .map((user) => ({
        value: user.get('id'),
        label: user.get('name', '').trim() || user.get('email')
      }))
      .toArray();
  },

  getSharingToProjectsData() {
    return _store
      .getIn(['kbc', 'sharingToProjectsData'], List())
      .map((project) => ({
        value: project.get('id'),
        label: project.get('name')
      }))
      .toArray();
  },

  getAdminsInvitedToProject() {
    return _store
      .getIn(['kbc', 'projectInvitationsData'], List())
      .map((invitation) => {
        return invitation.get('user');
      })
      .toMap()
      .mapKeys((key, user) => {
        return user.get('email');
      });
  },

  getProjectBaseUrl() {
    return _store.getIn(['kbc', 'projectBaseUrl']);
  },

  getProjectPageUrl(path) {
    return rtrim(this.getProjectBaseUrl(), '/') + '/' + path;
  },

  getUrlTemplates() {
    return _store.getIn(['kbc', 'urlTemplates']);
  },

  getXsrfToken() {
    return _store.getIn(['kbc', 'xsrfToken']);
  },

  getCanCreateProject() {
    return _store.getIn(['kbc', 'canCreateProject']);
  },

  getStripePublishableKey() {
    return _store.getIn(['kbc', 'stripePublishableKey']);
  },

  getKbcVars() {
    return _store.getIn(['kbc']);
  },

  getProjectUrlTemplate() {
    return _store.getIn(['kbc', 'urlTemplates', 'project']);
  },

  hasPayAsYouGo() {
    return this.hasCurrentProjectFeature(FEATURE_PAY_AS_YOU_GO);
  },

  hasNewTransformationsOnly() {
    return this.hasCurrentProjectFeature(FEATURE_NEW_TRANSFORMATIONS_ONLY);
  },

  hasShowTransformationMigration() {
    return this.hasCurrentProjectFeature(FEATURE_SHOW_TRANSFORMATION_MIGRATION);
  },

  hasNewQueue() {
    return this.hasCurrentProjectFeature(FEATURE_QUEUE_V2);
  },

  hasAllowedAi() {
    return this.hasCurrentProjectFeature(FEATURE_ALLOW_AI);
  },

  hasAllowedDescriptionAi() {
    return this.hasCurrentProjectFeature(FEATURE_ALLOW_DESCRIPTION_AI);
  },

  hasSnowflakeDynamicBackendSize() {
    return this.hasCurrentProjectFeature(FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE);
  },

  hasDisableLegacyBucketPrefix() {
    return this.hasCurrentProjectFeature(FEATURE_DISABLE_LEGACY_BUCKET_PREFIX);
  },

  hasJobsDynamicBackendSize() {
    return this.hasNewQueue() && !this.hasPayAsYouGo();
  },

  hasFlows() {
    return this.hasNewQueue();
  },

  hasFlowsOnly() {
    if (!this.hasNewQueue()) {
      return false;
    }

    return this.hasCurrentProjectFeature(FEATURE_HAS_FLOWS_ONLY);
  },

  hasTemplates() {
    return (
      this.hasNewQueue() &&
      this.getCurrentProject().get('hasSnowflake', false) &&
      !StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT)
    );
  },

  hasExternalBuckets() {
    return this.hasCurrentProjectFeature(FEATURE_EXTERNAL_BUCKETS);
  },

  hasReadOnlyStorage() {
    return (
      this.getCurrentProject().get('hasBigquery', false) ||
      this.hasCurrentProjectFeature(FEATURE_INPUT_MAPPING_READ_ONLY_STORAGE)
    );
  },

  hasProtectedDefaultBranch() {
    return this.hasCurrentProjectFeature(FEATURE_SOX_PROTECTED_DEFAULT_BRANCH);
  },

  hasCredentialsInVariables() {
    return (
      StackFeaturesStore.hasStackFeature(FEATURE_NEW_OAUTH_SERVICE) &&
      this.hasProtectedDefaultBranch()
    );
  }
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case ActionTypes.APPLICATION_EMIT_CHANGE:
      return ApplicationStore.emitChange();

    case ActionTypes.APPLICATION_RECEIVE_STACK:
      _store = _store.set('stack', action.stack);
      return ApplicationStore.emitChange();

    case ActionTypes.APPLICATION_INITIAL_LOADING:
      _store = _store.set('initialLoading', action.loading);
      return ApplicationStore.emitChange();

    case ActionTypes.APPLICATION_ADD_PROJECT_FEATURE:
      _store = _store.updateIn(['sapiToken', 'owner', 'features'], List(), (features) => {
        return features.push(action.feature);
      });
      return ApplicationStore.emitChange();

    case ActionTypes.APPLICATION_REMOVE_PROJECT_FEATURE:
      _store = _store.updateIn(['sapiToken', 'owner', 'features'], List(), (features) => {
        return features.filter((feature) => feature !== action.feature);
      });
      return ApplicationStore.emitChange();

    case ActionTypes.APPLICATION_ADD_USER_FEATURE:
      _store = _store.updateIn(['kbc', 'admin', 'features'], List(), (features) => {
        return features.push(action.feature);
      });
      return ApplicationStore.emitChange();

    case ActionTypes.APPLICATION_REMOVE_USER_FEATURE:
      _store = _store.updateIn(['kbc', 'admin', 'features'], List(), (features) => {
        return features.filter((feature) => feature !== action.feature);
      });
      return ApplicationStore.emitChange();

    case ActionTypes.APPLICATION_DATA_RECEIVED:
      return (_store = _store.withMutations((store) =>
        store
          .set('sapiToken', fromJS(action.applicationData.sapiToken))
          .set('sapiUrl', action.applicationData.sapiUrl)
          .set(
            'kbc',
            fromJS(action.applicationData.kbc).update('admins', (admins) => {
              return admins.toMap().mapKeys((key, item) => item.get('email'));
            })
          )
          .set('organizations', fromJS(action.applicationData.organizations))
          .set('tokenStats', fromJS(action.applicationData.tokenStats))
          .set('projectTemplates', fromJS(action.applicationData.projectTemplates))
          .set('isDemoPreview', !!action.applicationData.isDemoPreview)
      ));

    case ActionTypes.SAPI_TOKEN_RECEIVED:
      _store = _store.mergeIn(['sapiToken'], fromJS(action.sapiToken));
      return ApplicationStore.emitChange();

    case StorageTokenStoreConstants.ActionTypes.STORAGE_TOKEN_REFRESH_SUCCESS:
      if (action.tokenId === _store.getIn(['sapiToken', 'id'])) {
        let token = _store.get('sapiToken');
        token = token.set('token', action.newToken.get('token'));
        token = token.set('refreshed', action.newToken.get('refreshed'));
        _store = _store.set('sapiToken', token);
        return ApplicationStore.emitChange();
      }
      break;

    default:
      break;
  }
});

export default ApplicationStore;
