import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_ORACLE_TRANSFORMATION } from '../../constants/componentIds';
import RoutesStore from '../../stores/RoutesStore';
import callDockerAction from '../components/DockerActionsApi';
import componentsActions from '../components/InstalledComponentsActionCreators';
import InstalledComponentsApi from '../components/InstalledComponentsApi';
import ComponentsStore from '../components/stores/ComponentsStore';
import rowsActions from '../configurations/ConfigurationRowsActionCreators';
import { routeNames as transformationRoutes } from '../transformations-v2/constants';
import { getProtectedProperties } from './templates/credentials';
import getDefaultPort from './templates/defaultPorts';
import { INCREMENTAL_FETCHING_TYPES } from './constants';
import { supportSimpleSetup, supportSplitLoading } from './helpers';
import * as storeProvisioning from './storeProvisioning';

export function loadSourceTables(componentId, configId) {
  const store = storeProvisioning.createStore(componentId, configId);

  if (!supportSimpleSetup(componentId) || !!store.getSourceTables()) {
    return Promise.resolve();
  }

  return createActions(componentId).getSourceTables(configId);
}

export function reloadSourceTables(componentId, configId, queryId) {
  if (!supportSimpleSetup(componentId)) {
    return Promise.resolve();
  }

  createActions(componentId).updateLocalState(
    configId,
    storeProvisioning.LOADING_SOURCE_TABLES_PATH,
    true
  );
  return createActions(componentId).getSourceTables(configId, queryId);
}

export function createActions(componentId) {
  function resetProtectedProperties(credentials) {
    const props = List(getProtectedProperties(componentId));
    return props.reduce((memo, prop) => memo.set(prop, ''), credentials);
  }

  function updateProtectedProperties(newCredentials, oldCredentials) {
    const props = getProtectedProperties(componentId);
    const propsList = List(props);
    const result = propsList.reduce((memo, prop) => {
      const newValue = newCredentials.get(prop);
      const oldValue = oldCredentials.get(prop);
      if (!newValue) {
        return memo.set(prop, oldValue);
      }
      return memo;
    }, newCredentials);
    return result;
  }

  function getStore(configId) {
    return storeProvisioning.createStore(componentId, configId);
  }

  function saveConfigData(configId, data, waitingPath, changeDescription) {
    updateLocalState(configId, waitingPath, true);
    return componentsActions
      .saveComponentConfigData(componentId, configId, data, changeDescription)
      .then(() => updateLocalState(configId, waitingPath, false));
  }

  function createConfigRow(configId, data, waitingPath, changeDescription) {
    updateLocalState(configId, waitingPath, true);
    return rowsActions.createSimple(componentId, configId, data, changeDescription).then(() => {
      updateLocalState(configId, waitingPath, false);
    });
  }

  function updateConfigRow(configId, rowId, data, waitingPath, changeDescription) {
    updateLocalState(configId, waitingPath, true);
    return rowsActions
      .updateSimple(componentId, configId, rowId.toString(), data, changeDescription)
      .then(() => updateLocalState(configId, waitingPath, false));
  }

  function deleteConfigRow(configId, rowId, waitingPath, changeDescription) {
    updateLocalState(configId, waitingPath, true);
    return rowsActions
      .delete(componentId, configId, rowId.toString(), false, changeDescription)
      .then(() => updateLocalState(configId, waitingPath, false));
  }

  function getLocalState(configId) {
    return getStore(configId).getLocalState();
  }

  function updateLocalState(configId, path, data) {
    const ls = getStore(configId).getLocalState();
    const newLocalState = ls.setIn([].concat(path), data);
    componentsActions.updateLocalState(componentId, configId, newLocalState, path);
  }

  function removeFromLocalState(configId, path) {
    const ls = getStore(configId).getLocalState();
    const newLocalState = ls.deleteIn([].concat(path));
    componentsActions.updateLocalState(componentId, configId, newLocalState, path);
  }

  function getPKColumsFromSourceTable(targetTable, sourceTables) {
    const matchedTable = sourceTables.find(
      (table) =>
        table.get('schema') === targetTable.get('schema') &&
        table.get('name') === targetTable.get('tableName')
    );
    if (!matchedTable) {
      return Map();
    }
    if (matchedTable.get('columns')) {
      return matchedTable.get('columns').filter((column) => column.get('primaryKey') === true);
    } else {
      return Map();
    }
  }

  function getIncrementalCandidates(sourceTables) {
    const allowedTypes = INCREMENTAL_FETCHING_TYPES.get(componentId, List());
    return sourceTables.reduce((memo, table) => {
      const qualifyingColumns = table.get('columns', List()).filter((column) => {
        return column.get('type') && allowedTypes.includes(column.get('type').toLowerCase());
      });
      if (qualifyingColumns.count() > 0) {
        return memo.push(
          Map({
            tableName: table.get('name'),
            schema: table.get('schema'),
            candidates: qualifyingColumns
          })
        );
      } else {
        return memo;
      }
    }, List());
  }

  function rowDataFromQuery(query, configData = Map()) {
    const paramsQuery = query.delete('state').delete('name').delete('id').delete('enabled');

    return {
      rowId: query.get('id'),
      name: query.get('name'),
      isDisabled: !query.get('enabled'),
      configuration: JSON.stringify({
        ...configData.toJS(),
        parameters: paramsQuery.toJS()
      })
    };
  }

  function getDefaultProcessors() {
    return ComponentsStore.getComponent(componentId).getIn(
      ['emptyConfigurationRow', 'processors'],
      Map()
    );
  }

  return {
    // Credentials Actions start
    editCredentials(configId) {
      const store = getStore(configId);
      let credentials = store.getCredentials();
      if (!credentials.get('port') && getDefaultPort(componentId)) {
        credentials = credentials.set('port', getDefaultPort(componentId));
      }
      credentials = resetProtectedProperties(credentials);
      updateLocalState(configId, 'editingCredentials', credentials);
    },

    cancelCredentialsEdit(configId) {
      removeFromLocalState(configId, ['isChangedCredentials']);
      removeFromLocalState(configId, ['editingCredentials']);
    },

    updateEditingCredentials(configId, newCredentials) {
      updateLocalState(configId, 'editingCredentials', newCredentials);
      if (!getLocalState(configId).get('isChangedCredentials', false)) {
        updateLocalState(configId, ['isChangedCredentials'], true);
      }
    },

    saveCredentialsEdit(configId) {
      const store = getStore(configId);
      let credentials = store
        .getEditingCredentials()
        .filter((value) => _.isBoolean(value) || !!value);
      credentials = updateProtectedProperties(credentials, store.getCredentials());
      let newConfigData = store.configData.setIn(['parameters', 'db'], credentials);
      if (store.isRowConfiguration()) {
        newConfigData = newConfigData.deleteIn(['parameters', 'tables']);
      }
      const diffMsg = 'Update credentials';
      return saveConfigData(configId, newConfigData, ['isSavingCredentials'], diffMsg).then(() => {
        reloadSourceTables(componentId, configId);
        this.cancelCredentialsEdit(configId);
        if (componentId === KEBOOLA_ORACLE_TRANSFORMATION) {
          RoutesStore.getRouter().transitionTo(transformationRoutes.GENERIC_TRANSFORMATION_CONFIG, {
            config: configId,
            component: componentId
          });
        } else {
          RoutesStore.getRouter().transitionTo(componentId, { config: configId });
        }
      });
    },

    testCredentials(configId, credentials, forceAlterNat) {
      const store = getStore(configId);
      const testingCredentials = updateProtectedProperties(
        credentials.filter((value) => _.isBoolean(value) || !!value),
        store.getCredentials()
      );
      let runData = store.configData.setIn(['parameters', 'db'], testingCredentials);
      if (!store.isRowConfiguration()) {
        runData = runData.setIn(['parameters', 'tables'], List());
      }
      if (componentId === KEBOOLA_ORACLE_TRANSFORMATION) {
        runData = runData.deleteIn(['parameters', 'tables']).deleteIn(['parameters', 'blocks']);
      }
      return callDockerAction(componentId, 'testConnection', {
        configData: runData.toJS(),
        forceAlterNat
      });
    },
    // Credentials actions end

    setQueriesFilter(configId, query) {
      updateLocalState(configId, 'queriesFilter', query);
    },

    changeQueryEnabledState(configId, qid, newValue) {
      const store = getStore(configId);
      const newQueries = store.getQueries().map((q) => {
        if (q.get('id') === qid) {
          return q.set('enabled', newValue).delete('advancedMode');
        } else {
          return q.delete('advancedMode');
        }
      });
      const prefixMsg = !!newValue ? 'Enable' : 'Disable';
      const diffMsg = prefixMsg + ' query ' + store.getQueryName(qid);
      if (store.isRowConfiguration()) {
        const query = newQueries.find((q) => q.get('id') === qid);
        const rowData = rowDataFromQuery(query, store.getSavedRowConfigData(qid));
        return updateConfigRow(configId, qid, rowData, ['pending', qid, 'enabled'], diffMsg);
      }
      const newData = store.configData.setIn(['parameters', 'tables'], newQueries);
      return saveConfigData(configId, newData, ['pending', qid, 'enabled'], diffMsg);
    },

    checkTableName(query, store) {
      const defaultTableName = store.getDefaultOutputTableId(query.get('name', ''));
      if (query.get('outputTable', '').trim().length > 0) {
        return query;
      } else {
        return query.set('outputTable', defaultTableName);
      }
    },

    createQuery(configId) {
      const store = getStore(configId);
      const newQuery = this.checkTableName(
        store.generateNewQuery(null, supportSimpleSetup(componentId)),
        store
      );
      updateLocalState(configId, ['newQueries', newQuery.get('id')], newQuery);
      if (store.isRowConfiguration()) {
        updateLocalState(
          configId,
          ['editingProcessors', newQuery.get('id')],
          getDefaultProcessors()
        );
      }
      updateLocalState(
        configId,
        ['newQueriesIdsList'],
        store.getNewQueriesIdsList().unshift(newQuery.get('id'))
      );
      this.changeQueryEdit(configId, newQuery);
      return newQuery;
    },

    deleteQuery(configId, qid) {
      const store = getStore(configId);
      removeFromLocalState(configId, ['newQueries', qid]);
      removeFromLocalState(configId, ['editingQueries', qid]);
      removeFromLocalState(configId, ['editingProcessors', qid]);
      const newQueries = store.getQueries().filter((q) => q.get('id') !== qid);
      const newData = store.configData.setIn(['parameters', 'tables'], newQueries);
      const diffMsg = 'Delete query ' + store.getQueryName(qid);
      if (store.isRowConfiguration()) {
        if (store.isNewQuery(qid)) {
          return;
        }
        return deleteConfigRow(configId, qid.toString(), ['pending', qid, 'deleteQuery'], diffMsg);
      }
      return saveConfigData(configId, newData, ['pending', qid, 'deleteQuery'], diffMsg);
    },

    clearState(configId, queryId) {
      updateLocalState(configId, ['isSaving', queryId], true);
      return rowsActions
        .clearComponentState(componentId, configId, queryId.toString())
        .then(() => removeFromLocalState(configId, ['isSaving', queryId]));
    },

    prepareSingleQueryRunData(configId, query, source) {
      const store = getStore(configId);
      let runQuery = query;
      if (source === 'detail') {
        if (runQuery.get('advancedMode')) {
          runQuery = runQuery.delete('table');
          runQuery = runQuery.delete('columns');
          runQuery = runQuery.delete('incrementalFetchingColumn');
          runQuery = runQuery.delete('incrementalFetchingLimit');
        } else {
          if (runQuery.get('incrementalFetchingColumn') === '') {
            runQuery = runQuery.delete('incrementalFetchingColumn');
          }
          runQuery = runQuery.delete('query');
        }
        runQuery = runQuery.delete('advancedMode');
      }
      if (store.isRowConfiguration()) {
        return {
          config: configId,
          row: runQuery.get('id').toString()
        };
      } else {
        runQuery = runQuery.delete('incrementalFetchingColumn').delete('incrementalFetchingLimit');
        return {
          config: configId,
          configData: store.configData.setIn(['parameters', 'tables'], List().push(runQuery))
        };
      }
    },

    resetQueryEdit(configId, queryId) {
      removeFromLocalState(configId, ['editingProcessors', queryId]);
      removeFromLocalState(configId, ['isDestinationEditing', queryId]);
      const store = getStore(configId);
      if (store.isNewQuery(queryId)) {
        const newQuery = store.generateNewQuery(queryId, supportSimpleSetup(componentId));
        updateLocalState(configId, ['newQueries', queryId], newQuery);
        updateLocalState(configId, ['editingQueries', queryId], newQuery);
      } else {
        removeFromLocalState(configId, ['editingQueries', queryId]);
      }
    },

    destinationEdit(configId, queryId) {
      updateLocalState(configId, ['isDestinationEditing', queryId], true);
    },

    changeQueryEdit(configId, newQuery) {
      updateLocalState(configId, ['editingQueries', newQuery.get('id')], newQuery);
    },

    changeProcessorsEdit(configId, queryId, processors) {
      updateLocalState(configId, ['editingProcessors', queryId], processors);
    },

    saveQueryEdit(configId, queryId) {
      const store = getStore(configId);
      let newQuery = store.getConfigQuery(queryId);
      if (newQuery.get('advancedMode')) {
        newQuery = newQuery.delete('table');
        newQuery = newQuery.delete('columns');
        newQuery = newQuery.delete('incrementalFetchingColumn');
        newQuery = newQuery.delete('incrementalFetchingLimit');
      } else {
        if (newQuery.get('incrementalFetchingColumn') === '') {
          newQuery = newQuery.delete('incrementalFetchingColumn');
        }
        newQuery = newQuery.delete('query');
      }
      newQuery = newQuery.delete('advancedMode');
      if (!store.isRowConfiguration()) {
        // if a table was made while this bug was alive https://github.com/keboola/kbc-ui/issues/1731,
        // need to remove the invalid parameters
        newQuery = newQuery.delete('incrementalFetchingColumn').delete('incrementalFetchingLimit');
      }
      newQuery = this.checkTableName(newQuery, store);

      var newQueries, diffMsg;
      if (store.getQueries().find((q) => q.get('id') === newQuery.get('id'))) {
        newQueries = store.getQueries().map((q) => (q.get('id') === queryId ? newQuery : q));
        diffMsg = 'Edit query ' + newQuery.get('name');
      } else {
        newQueries = store.getQueries().push(newQuery);
        diffMsg = 'Create query ' + newQuery.get('name');
      }
      removeFromLocalState(configId, ['isDestinationEditing', queryId]);

      if (store.isRowConfiguration()) {
        const isNewQuery = store.isNewQuery(queryId);
        const rowData = rowDataFromQuery(
          newQuery,
          store.getSavedRowConfigData(queryId).set('processors', store.getRowProcessors(queryId))
        );
        if (isNewQuery) {
          return createConfigRow(configId, rowData, ['isSaving', queryId], diffMsg).then(() => {
            removeFromLocalState(configId, ['editingQueries', queryId]);
            removeFromLocalState(configId, ['editingProcessors', queryId]);
            removeFromLocalState(configId, ['newQueries', queryId]);
            removeFromLocalState(configId, ['isSaving', queryId]);
          });
        } else {
          return updateConfigRow(configId, queryId, rowData, ['isSaving', queryId], diffMsg).then(
            () => {
              removeFromLocalState(configId, ['editingQueries', queryId]);
              removeFromLocalState(configId, ['editingProcessors', queryId]);
              removeFromLocalState(configId, ['isSaving', queryId]);
            }
          );
        }
      } else {
        const newData = store.configData.setIn(['parameters', 'tables'], newQueries);
        return saveConfigData(configId, newData, ['isSaving', queryId], diffMsg).then(() => {
          removeFromLocalState(configId, ['editingQueries', queryId]);
          removeFromLocalState(configId, ['isSaving', queryId]);
          if (store.isNewQuery(queryId)) {
            removeFromLocalState(configId, ['newQueries', queryId]);
          }
        });
      }
    },

    quickstart(configId, tableList) {
      const store = getStore(configId);
      const queries = tableList.map(function (table) {
        let query = store.generateNewQuery(null, supportSimpleSetup(componentId));
        query = query.set('table', table);
        query = query.set('name', table.get('tableName'));
        const pkCols = getPKColumsFromSourceTable(table, store.getSourceTables(configId));
        if (pkCols.count() > 0) {
          query = query.set(
            'primaryKey',
            pkCols
              .map((column) => {
                return column.get('name');
              })
              .toJS()
          );
        }
        query = query.set('outputTable', store.getDefaultOutputTableId(table.get('tableName')));
        return query;
      });

      const diffMsg = 'Quickstart config creation';

      if (store.isRowConfiguration()) {
        return Promise.each(queries.toList(), (query) => {
          return createConfigRow(
            configId,
            rowDataFromQuery(query, Map({ processors: getDefaultProcessors() })),
            ['quickstartSaving'],
            diffMsg
          );
        }).then(() => {
          removeFromLocalState(configId, ['quickstartSaving']);
        });
      }

      const newData = store.configData.setIn(['parameters', 'tables'], queries);
      return saveConfigData(configId, newData, ['quickstartSaving'], diffMsg).then(() => {
        removeFromLocalState(configId, ['quickstartSaving']);
      });
    },

    getDefaultOutputTableId(configId, name) {
      const store = getStore(configId);
      return store.getDefaultOutputTableId(name);
    },

    getPKColumnsFromSourceTable(table, sourceTable) {
      return getPKColumsFromSourceTable(table, sourceTable);
    },

    quickstartSelected(configId, selected) {
      updateLocalState(configId, ['quickstart', 'tables'], selected);
    },

    updateLocalState(configId, path, data) {
      return updateLocalState(configId, path, data);
    },

    getSourceTables(configId, queryId) {
      const store = getStore(configId);

      updateLocalState(configId, storeProvisioning.LOADING_SOURCE_TABLES_PATH, true);
      let runData = store.configData.setIn(['parameters', 'db'], store.getCredentials());
      if (!store.isRowConfiguration()) {
        runData = runData.setIn(['parameters', 'tables'], List());
      }
      if (supportSplitLoading(componentId)) {
        runData = runData.setIn(['parameters', 'tableListFilter', 'listColumns'], false);

        if (queryId) {
          const query = store.getConfigQuery(queryId);
          updateLocalState(configId, storeProvisioning.LOADING_SOURCE_TABLES_PATH, false);
          updateLocalState(configId, storeProvisioning.LOADING_COLUMNS_PATH, true);
          runData = runData.setIn(
            ['parameters', 'tableListFilter'],
            fromJS({
              listColumns: true,
              tablesToList: [
                {
                  schema: query.getIn(['table', 'schema']),
                  tableName: query.getIn(['table', 'tableName'])
                }
              ]
            })
          );
        }
      }
      return callDockerAction(componentId, 'getTables', { configData: runData.toJS() })
        .then((data) => {
          if (data.status === 'error') {
            updateLocalState(
              configId,
              storeProvisioning.SOURCE_TABLES_ERROR_PATH,
              fromJS(data.message)
            );
          } else if (data.status === 'success') {
            updateLocalState(configId, storeProvisioning.SOURCE_TABLES_ERROR_PATH, null);
          }
          if (queryId && data.tables && data.tables.length > 0 && store.getSourceTables()) {
            const tables = store.getSourceTables().map((table) => {
              if (
                table.get('name') === data.tables[0].name &&
                table.get('schema') === data.tables[0].schema
              ) {
                return fromJS(data.tables[0]);
              }
              return table;
            });
            updateLocalState(configId, storeProvisioning.SOURCE_TABLES_PATH, tables);
          } else if (!queryId) {
            updateLocalState(configId, storeProvisioning.SOURCE_TABLES_PATH, fromJS(data.tables));
          }
          if (
            data.tables &&
            store.isRowConfiguration() &&
            (!supportSplitLoading(componentId) ||
              runData.getIn(['parameters', 'tableListFilter', 'listColumns']))
          ) {
            updateLocalState(
              configId,
              storeProvisioning.INCREMENTAL_CANDIDATES_PATH,
              getIncrementalCandidates(fromJS(data.tables))
            );
          }
          return data;
        })
        .finally(() => {
          updateLocalState(configId, storeProvisioning.LOADING_SOURCE_TABLES_PATH, false);
          updateLocalState(configId, storeProvisioning.LOADING_COLUMNS_PATH, false);
        });
    },

    migrateConfig(configId) {
      const store = getStore(configId);
      return Promise.each(store.rows.toList(), (row) => {
        return InstalledComponentsApi.deleteConfigurationRow(
          componentId,
          configId,
          row.get('id'),
          `Row ${row.get('name')} deleted during migration`
        );
      })
        .then(() => {
          return Promise.each(store.getQueries(), (query) => {
            return rowsActions.createSimple(
              componentId,
              configId,
              rowDataFromQuery(query),
              `Migrating query ${query.get('name')} to configuration row`
            );
          });
        })
        .then(() => {
          return componentsActions.saveComponentConfigData(
            componentId,
            configId,
            store.configData.deleteIn(['parameters', 'tables']),
            'Migrating configuration to rows'
          );
        })
        .then(() => {
          updateLocalState(configId, ['migration', 'completed'], true);
          if (store.getSourceTables(configId)) {
            updateLocalState(
              configId,
              storeProvisioning.INCREMENTAL_CANDIDATES_PATH,
              getIncrementalCandidates(store.getSourceTables(configId))
            );
          }
        });
    },

    dismissMigrationAlert(configId) {
      removeFromLocalState(configId, ['migration']);
    }
  };
}
