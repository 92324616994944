import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import BlockButton from '../../../../react/common/BlockButton';
import ComponentBoxModal from '../../../components-directory/components/ComponentBoxModal';

class AddNewConfigBlockButton extends React.Component {
  state = {
    showModalWithSampleData: false
  };

  render() {
    return (
      <>
        <BlockButton
          style="primary"
          className="add-button"
          label={
            <>
              <FontAwesomeIcon icon="plus" fixedWidth />
              Add New Configuration
            </>
          }
          onClick={() => this.setState({ showModalWithSampleData: true })}
        />
        <ComponentBoxModal
          show={this.state.showModalWithSampleData}
          allComponents={this.props.allComponents}
          hasConfigurations={this.props.hasConfigurations}
          component={this.props.component}
          onHide={() => this.setState({ showModalWithSampleData: false })}
          readOnly={this.props.readOnly}
        />
      </>
    );
  }
}

AddNewConfigBlockButton.propTypes = {
  allComponents: PropTypes.instanceOf(Map).isRequired,
  component: PropTypes.instanceOf(Map).isRequired,
  hasConfigurations: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export default AddNewConfigBlockButton;
