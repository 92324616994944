import React from 'react';
import PropTypes from 'prop-types';
import { Button, Label } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import InputOutputTypeIcon from '../../../../../react/common/InputOutputTypeIcon';
import Loader from '../../../../../react/common/Loader';
import MultiActionsSelectCheckbox from '../../../../../react/common/MultiActionsSelectCheckbox';
import Tooltip from '../../../../../react/common/Tooltip';
import { ioType } from '../../../Constants';
import FileOutputMappingModal from './FileOutputMappingModal';

const FileOutputMappingRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    editingValue: PropTypes.object.isRequired,
    mappingIndex: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    pendingActions: PropTypes.object.isRequired,
    onEditStart: PropTypes.func.isRequired,
    definedSources: PropTypes.instanceOf(List).isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelection: PropTypes.func.isRequired
  },

  render() {
    return (
      <span className="tr hoverable-actions">
        {this.renderColumns()}
        {this.renderActionButtons()}
      </span>
    );
  },

  renderColumns() {
    const isDeleting = this.props.pendingActions.getIn(
      ['input', 'tables', this.props.mappingIndex, 'delete'],
      false
    );

    return (
      <>
        {!this.props.readOnly && (
          <span className="td pr-0">
            <MultiActionsSelectCheckbox
              isChecked={this.props.isSelected}
              isDisabled={isDeleting}
              onToggle={this.props.toggleSelection}
              entity="mapping"
            />
          </span>
        )}
        <span className="td col-xs-5">
          <InputOutputTypeIcon type={ioType.FILE} />
          out/files/
          {this.props.value.get('source')}
        </span>
        <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
        <span className="td col-xs-5">{this.renderTags()}</span>
      </>
    );
  },

  renderActionButtons() {
    if (this.props.readOnly) {
      return <span className="td pl-0 pr-1 no-wrap" />;
    }

    const isDeleting = this.props.pendingActions.getIn(
      ['output', 'files', this.props.mappingIndex, 'delete'],
      false
    );

    return (
      <span className="td pl-0 pr-1 no-wrap">
        <FileOutputMappingModal
          mode="edit"
          onEditStart={this.props.onEditStart}
          mapping={this.props.editingValue}
          onChange={this.props.onChange}
          onCancel={this.props.onCancel}
          onSave={this.props.onSave}
          definedSources={this.props.definedSources}
        />
        <Tooltip placement="top" tooltip="Delete Output">
          <Button
            bsStyle="link"
            className="text-muted"
            onClick={this.props.onDelete}
            disabled={isDeleting}
          >
            {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
          </Button>
        </Tooltip>
      </span>
    );
  },

  renderTags() {
    const tags = this.props.value.get('tags', List());

    if (tags.isEmpty()) {
      return 'No tags will be assigned.';
    }

    return (
      <div className="labels-container">
        {tags.map((tag) => <Label key={tag}>{tag}</Label>).toArray()}
      </div>
    );
  },

  renderRightArrow() {
    if (!this.props.value.get('is_public') && !this.props.value.get('is_permanent')) {
      return <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />;
    }

    return (
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={
          <div>
            {this.props.value.get('is_public') && <div>Public</div>}
            {this.props.value.get('is_permanent') && <div>Permanent</div>}
          </div>
        }
      >
        <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />
      </Tooltip>
    );
  }
});

export default FileOutputMappingRow;
