import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  ControlLabel,
  Form as BootstrapForm,
  FormControl,
  FormGroup,
  HelpBlock
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import Checkbox from '../../react/common/Checkbox';
import Loader from '../../react/common/Loader';
import PasswordControl from '../../react/common/PasswordControl';
import Select from '../../react/common/Select';
import { s3Regions } from './constants';
import { fields } from './fields';

const Form = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired
  },

  getInitialState() {
    return {
      backend: 's3',
      s3Data: Map({
        storageBackendType: 's3',
        region: s3Regions[0].value,
        access_key_id: '',
        '#secret_access_key': '',
        '#bucket': '',
        backupPath: '',
        exportStructureOnly: false
      }),
      absData: Map({
        storageBackendType: 'abs',
        accountName: '',
        '#accountKey': '',
        backupPath: '',
        exportStructureOnly: false
      })
    };
  },

  render() {
    return (
      <BootstrapForm horizontal onSubmit={this.submit}>
        <FormGroup>
          <Col xs={4} componentClass={ControlLabel}>
            Backend
          </Col>
          <Col xs={8}>
            <Select
              clearable={false}
              value={this.state.backend}
              onChange={(value) => this.setState({ backend: value })}
              options={[
                { value: 's3', label: 'Amazon S3' },
                { value: 'abs', label: 'Azure Blob Storage' }
              ]}
            />
          </Col>
        </FormGroup>
        {fields[this.state.backend].map((field) => {
          const error =
            ['text', 'password'].includes(field.type) &&
            /\s/.test(this.getValue(field.key)) &&
            `${field.label} cannot contain spaces`;
          return (
            <FormGroup key={field.label}>
              {field.type !== 'checkbox' && (
                <Col xs={4} componentClass={ControlLabel}>
                  {field.label}
                </Col>
              )}
              <Col xs={8} xsOffset={field.type === 'checkbox' ? 4 : 0}>
                {this.renderInput(field.type, field.label, field.key, field.info)}
                {error ? (
                  <HelpBlock className="text-danger">{error}</HelpBlock>
                ) : (
                  field.help && <HelpBlock>{field.help}</HelpBlock>
                )}
              </Col>
            </FormGroup>
          );
        })}
        <FormGroup>
          {this.renderS3Path()}
          <Col xs={8} xsOffset={4}>
            <Button
              bsStyle="success"
              type="submit"
              disabled={this.props.readOnly || !this.isValid() || this.props.isSaving}
            >
              {this.props.isSaving ? (
                <Loader className="icon-addon-right" />
              ) : (
                <FontAwesomeIcon icon="play" className="icon-addon-right" />
              )}
              Run Export
            </Button>
          </Col>
        </FormGroup>
      </BootstrapForm>
    );
  },

  renderInput(type, label, key, info = {}) {
    switch (type) {
      case 'text':
        return (
          <FormControl
            type="text"
            placeholder={info.placeholder ?? ''}
            value={this.getValue(key)}
            onChange={(evt) => this.setValue(key, evt.target.value)}
          />
        );
      case 'password':
        return (
          <PasswordControl
            placeholder={info.placeholder ?? ''}
            value={this.getValue(key)}
            onChange={(evt) => this.setValue(key, evt.target.value)}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            checked={this.getValue(key)}
            onChange={(checked) => this.setValue(key, checked)}
          >
            {label}
          </Checkbox>
        );
      case 'select':
        return (
          <Select
            searchable
            clearable={false}
            value={this.getValue(key)}
            onChange={(value) => this.setValue(key, value)}
            options={info.options ?? []}
          />
        );
    }
  },

  renderS3Path() {
    if (this.state.backend !== 's3') return null;

    let fullPath = 's3://';
    const bucket = this.getValue('#bucket').trim();
    const backupPath = this.getValue('backupPath').trim();
    if (bucket) fullPath += bucket + '/';
    if (backupPath) fullPath += backupPath;

    return (
      <Col xs={8} xsOffset={4}>
        <HelpBlock>
          {'This project will be exported to '}
          <strong>{fullPath}</strong>
        </HelpBlock>
      </Col>
    );
  },

  setValue(key, value) {
    const name = `${this.state.backend}Data`;
    this.setState({ [name]: this.state[name].set(key, value) });
  },

  getValue(key) {
    return this.state[`${this.state.backend}Data`].get(key);
  },

  getData() {
    return this.state[`${this.state.backend}Data`];
  },

  isValid() {
    for (const field of fields[this.state.backend]) {
      const value = this.getValue(field.key);
      if (['text', 'password'].includes(field.type) && (value.length === 0 || /\s/.test(value))) {
        return false;
      }
    }
    return true;
  },

  submit(event) {
    event.preventDefault();
    this.props.onSubmit(this.getData().toJS());
  }
});

export default Form;
