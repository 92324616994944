import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const InfoAlert = (props: {
  children: React.ReactNode;
  className?: string;
  header?: string;
  bsStyle?: 'info' | 'warning';
  onClose?: () => void;
}) => {
  const renderIcon = () => {
    if (props.bsStyle === 'warning') {
      return (
        <FontAwesomeIcon icon="triangle-exclamation" className="color-warning icon-addon-right" />
      );
    }

    return <FontAwesomeIcon icon="circle-info" className="color-primary icon-addon-right" />;
  };

  return (
    <Alert
      bsStyle={props.bsStyle || 'info'}
      className={classnames(
        'alert-no-icon f-14 line-height-20',
        { 'alert-dismissable': !!props.onClose },
        props.className
      )}
    >
      {!props.header ? (
        <div className="match-header color-dark-muted">
          {renderIcon()}
          {props.children}
        </div>
      ) : (
        <>
          <span className="alert-title">
            {renderIcon()}
            {props.header}
          </span>
          <div className="match-header color-dark-muted">{props.children}</div>
        </>
      )}
      {props.onClose && (
        <Button
          type="button"
          bsStyle="link"
          className="btn-link-inline close"
          onClick={props.onClose}
        >
          <FontAwesomeIcon icon="xmark" fixedWidth />
        </Button>
      )}
    </Alert>
  );
};

export default InfoAlert;
