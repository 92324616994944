import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import Loader from '../../../react/common/Loader';

const PhaseOutput = (props: { continueOnFailure: boolean; isLoading: boolean }) => {
  return (
    <div className="phase-output-label no-wrap">
      {props.isLoading ? (
        <Loader className="color-main" />
      ) : (
        <FontAwesomeIcon
          icon={props.continueOnFailure ? 'rotate-exclamation' : 'down-from-dotted-line'}
          className={classnames('f-16', props.continueOnFailure ? 'color-danger' : 'color-muted')}
        />
      )}
      {props.continueOnFailure ? 'Continue on Failure' : 'All to Finish'}
    </div>
  );
};

export default PhaseOutput;
