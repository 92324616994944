import Promise from 'bluebird';
import { OrderedMap } from 'immutable';

import {
  KEBOOLA_EX_SAMPLE_DATA,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_VARIABLES
} from '../../constants/componentIds';
import ConfigurationTitleLabel from '../../react/common/ConfigurationTitleLabel';
import folderBreadcrumbHandler from '../../utils/folderBreadcrumbHandler';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import StorageActionCreators from '../components/StorageActionCreators';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import VersionsActionCreators from '../components/VersionsActionCreators';
import * as NotificationActions from '../notifications/actions';
import JobsActionCreators from '../queue/actions';
import JobsStore from '../queue/store';
import { loadAll, loadFlow, loadTriggers } from './actions';
import { routeNames, tabs } from './constants';
import Detail from './Detail';
import DetailHeader from './DetailHeader';
import Index from './Index';
import IndexHeader from './IndexHeader';

const routes = {
  name: routeNames.ROOT,
  title: 'Flows',
  defaultRouteHandler: Index,
  headerButtonsHandler: IndexHeader,
  requireData: [
    (params) => {
      if (params.config) {
        return Promise.resolve();
      }

      return Promise.all([
        NotificationActions.loadNotifications(),
        InstalledComponentsActionCreators.loadComponentsMetadata(KEBOOLA_ORCHESTRATOR),
        loadAll().then(() => {
          // Can be lazy loaded because it's used only for graph on listing page
          JobsActionCreators.loadLatestJobsForConfigurations(
            KEBOOLA_ORCHESTRATOR,
            InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR)
              .keySeq()
              .toArray()
          );
        })
      ]);
    },
    loadTriggers
  ],
  poll: {
    skipFirst: true,
    interval: 15,
    action() {
      return JobsActionCreators.loadLatestJobsForConfigurationsForce(
        KEBOOLA_ORCHESTRATOR,
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR).keySeq().toArray()
      );
    }
  },
  childRoutes: [
    {
      name: routeNames.DETAIL,
      path: ':config(/:tab)',
      title(routerState) {
        return InstalledComponentsStore.getConfig(
          KEBOOLA_ORCHESTRATOR,
          routerState.getIn(['params', 'config'])
        ).get('name');
      },
      breadcrumbHandler: ({ breadcrumbs, params }) =>
        folderBreadcrumbHandler(breadcrumbs, KEBOOLA_ORCHESTRATOR, params.config, routeNames.ROOT),
      labelHandler: ConfigurationTitleLabel,
      defaultRouteHandler: Detail,
      headerButtonsHandler: DetailHeader,
      requireData: [
        (params) => {
          if (params.tab === tabs.NOTIFICATIONS) {
            return NotificationActions.loadNotifications();
          }

          if (params.tab === tabs.ALL_RUNS) {
            // Wait maximum 300ms for the request or skip it and show loading indicator in the UI.
            return Promise.any([
              Promise.delay(300),
              JobsActionCreators.loadComponentConfigurationLatestJobs(
                KEBOOLA_ORCHESTRATOR,
                params.config
              )
            ]);
          }

          if (params.tab === tabs.VERSIONS) {
            return VersionsActionCreators.loadVersions(KEBOOLA_ORCHESTRATOR, params.config);
          }

          // just preload data for last run status visualization, this data is not required for detail page transition
          JobsActionCreators.loadComponentConfigurationLatestJobs(
            KEBOOLA_ORCHESTRATOR,
            params.config
          ).then(() => {
            const latestJob = JobsStore.getLatestJobs(KEBOOLA_ORCHESTRATOR, params.config)
              .get('jobs', OrderedMap())
              .first();

            if (latestJob) {
              JobsActionCreators.loadChildJobsForce(latestJob);
            }
          });

          InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_VARIABLES);

          return Promise.all([
            loadFlow(params.config),
            InstalledComponentsActionCreators.loadComponentsMetadata(),
            NotificationActions.loadNotifications(),
            StorageActionCreators.loadBucketsAndTables(),
            ComponentsStore.hasComponent(KEBOOLA_EX_SAMPLE_DATA)
              ? InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_EX_SAMPLE_DATA)
              : Promise.resolve(),
            InstalledComponentsActionCreators.loadDeletedComponents()
          ]);
        }
      ],
      poll: {
        skipFirst: true,
        interval: 15,
        action(params) {
          return VersionsActionCreators.reloadVersionsAndNotifyIfDoNotMatch(
            KEBOOLA_ORCHESTRATOR,
            params.config
          );
        }
      }
    }
  ]
};

export default routes;
