import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, HelpBlock } from 'react-bootstrap';

import Checkbox from '../../../react/common/Checkbox';
import PanelWithDetails from '../../../react/common/PanelWithDetails';

class ProcessingSettings extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Processing Settings</h3>
        </div>
        <div className="box-content with-panel">
          <FormGroup>
            <Col xs={8} xsOffset={4}>
              <Checkbox
                checked={this.props.value.decompress}
                onChange={(checked) => this.props.onChange({ decompress: checked })}
                disabled={this.props.disabled}
              >
                Decompress
              </Checkbox>
              <HelpBlock>
                Decompress downloaded file(s). All files in all archives will be imported into a
                single Storage table.
              </HelpBlock>
            </Col>
          </FormGroup>
          <PanelWithDetails
            defaultExpanded={
              this.props.value.addFilenameColumn || this.props.value.addRowNumberColumn
            }
            placement="bottom"
            labelOpen="Show additional processing settings"
            labelCollapse="Hide additional processing settings"
          >
            <FormGroup>
              <Col xs={8} xsOffset={4}>
                <Checkbox
                  checked={this.props.value.addFilenameColumn}
                  onChange={(checked) => this.props.onChange({ addFilenameColumn: checked })}
                  disabled={this.props.disabled}
                >
                  Add Filename Column
                </Checkbox>
                <HelpBlock>
                  Add an <code>s3_filename</code> column that will store the processed file name.
                </HelpBlock>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col xs={8} xsOffset={4}>
                <Checkbox
                  checked={this.props.value.addRowNumberColumn}
                  onChange={(checked) => this.props.onChange({ addRowNumberColumn: checked })}
                  disabled={this.props.disabled}
                >
                  Add Row Number Column
                </Checkbox>
                <HelpBlock>
                  Add an <code>s3_row_number</code> column that will store the row number from the
                  processed file.
                </HelpBlock>
              </Col>
            </FormGroup>
          </PanelWithDetails>
        </div>
      </div>
    );
  }
}

ProcessingSettings.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default ProcessingSettings;
