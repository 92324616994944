import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import ChangedSinceInput from '../../../../../react/common/ChangedSinceInput';
import Checkbox from '../../../../../react/common/Checkbox';
import PanelWithDetails from '../../../../../react/common/PanelWithDetails';
import Select from '../../../../../react/common/Select';
import whereOperatorConstants from '../../../../../react/common/whereOperatorConstants';
import { isInputMappingSourceBucket } from '../../../helpers';
import RedshiftDataTypesContainer from './input/RedshiftDataTypesContainer';
import { prepareSourceMapping } from './InputMappingRowRedshiftEditorHelper';
import InputMappingSource from './InputMappingSource';

const distStyleOptions = [
  { label: 'EVEN', value: 'EVEN' },
  { label: 'KEY', value: 'KEY' },
  { label: 'ALL', value: 'ALL' }
];

const InputMappingRowRedshiftEditor = createReactClass({
  propTypes: {
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    initialShowDetails: PropTypes.bool.isRequired,
    isDestinationDuplicate: PropTypes.bool.isRequired
  },

  _handleChangeSource(selected) {
    return this.props.onChange(prepareSourceMapping(selected, this.props.value, this.props.tables));
  },

  _handleChangeDestination(e) {
    const value = this.props.value.set('destination', e.target.value.trim());
    return this.props.onChange(value);
  },

  _handleChangeOptional(checked) {
    return this.props.onChange(this.props.value.set('optional', checked));
  },

  _handleChangeChangedSince(changedSince) {
    let { value } = this.props;
    if (this.props.value.has('days')) {
      value = value.delete('days');
    }
    value = value.set('changedSince', changedSince);
    return this.props.onChange(value);
  },

  _handleChangeColumns(newValue) {
    const component = this;
    const immutable = this.props.value.withMutations((mapping) => {
      mapping.set('columns', newValue);
      if (newValue.count()) {
        const columns = mapping.get('columns').toJS();

        const datatypes = _.pick(mapping.get('datatypes').toJS(), columns);
        mapping.set('datatypes', fromJS(datatypes || Map()));

        if (!_.contains(columns, mapping.get('distKey'))) {
          mapping.set('distKey', '').set('distStyle', '');
        }

        mapping.set(
          'sortKey',
          _.intersection(columns, component.props.value.get('sortKey').split(',')).join(',')
        );
      }
    });

    return this.props.onChange(immutable);
  },

  _handleChangeWhereColumn(string) {
    const value = this.props.value.set('whereColumn', string);
    return this.props.onChange(value);
  },

  _handleChangeWhereOperator(newValue) {
    const value = this.props.value.set('whereOperator', newValue);
    return this.props.onChange(value);
  },

  _handleChangeWhereValues(newValue) {
    const value = this.props.value.set('whereValues', newValue);
    return this.props.onChange(value);
  },

  _handleChangeDataTypes(datatypes) {
    const value = this.props.value.set('datatypes', datatypes);
    return this.props.onChange(value);
  },

  _handleChangeSortKey(immutable) {
    const value = this.props.value.set('sortKey', immutable.join());
    return this.props.onChange(value);
  },

  _handleChangeDistKey(string) {
    const value = this.props.value.set('distKey', string);
    return this.props.onChange(value);
  },

  _handleChangeDistStyle(string) {
    let value = this.props.value.set('distStyle', string);
    if (string !== 'KEY') {
      value = value.set('distKey', '');
    }
    return this.props.onChange(value);
  },

  _getColumns() {
    if (!this.props.value.get('source')) {
      return [];
    }
    const { props } = this;
    const table = this.props.tables.find((t) => t.get('id') === props.value.get('source'));
    if (!table) {
      return [];
    }
    return table.get('columns').toJS();
  },

  _getColumnsOptions() {
    const columns = this._getColumns();
    return _.map(columns, (column) => ({
      label: column,
      value: column
    }));
  },

  _getFilteredColumnsOptions() {
    let columns;
    if (this.props.value.get('columns', List()).count()) {
      columns = this.props.value.get('columns').toJS();
    } else {
      columns = this._getColumns();
    }
    return _.map(columns, (column) => ({
      label: column,
      value: column
    }));
  },

  _getSortKeyImmutable() {
    if (this.props.value.get('sortKey')) {
      return fromJS(this.props.value.get('sortKey').split(','));
    } else {
      return List();
    }
  },

  render() {
    const isSelectedBucket = isInputMappingSourceBucket(this.props.value);
    const isDisabled = this.props.disabled || !this.props.value.get('source');

    return (
      <Form horizontal>
        <FormGroup>
          <Col sm={2} componentClass={ControlLabel}>
            Source
          </Col>
          <Col sm={10}>
            <InputMappingSource
              disableMultiSelect
              mode={this.props.mode}
              tables={this.props.tables}
              buckets={this.props.buckets}
              value={this.props.value}
              onChange={this._handleChangeSource}
              disabled={this.props.disabled}
              isDestinationDuplicate={this.props.isDestinationDuplicate}
            />
          </Col>
        </FormGroup>
        {isSelectedBucket && (
          <>
            {this.renderChangeInLastInput(isDisabled)}
            {this.renderOptionalInput()}
          </>
        )}
        {!isSelectedBucket && (
          <FormGroup validationState={this.props.isDestinationDuplicate ? 'error' : null}>
            <Col sm={2} componentClass={ControlLabel}>
              Destination
            </Col>
            <Col sm={10}>
              <FormControl
                type="text"
                value={this.props.value.get('destination', '')}
                disabled={this.props.disabled}
                placeholder="Destination table name in transformation DB"
                onChange={this._handleChangeDestination}
              />
              {this.props.isDestinationDuplicate && (
                <HelpBlock className="text-danger">
                  {'Duplicate destination '}
                  <code>{this.props.value.get('destination')}</code>.
                </HelpBlock>
              )}
            </Col>
          </FormGroup>
        )}
        {!isSelectedBucket && (
          <PanelWithDetails defaultExpanded={this.props.initialShowDetails}>
            {this.renderOptionalInput()}
            <FormGroup>
              <Col sm={2} componentClass={ControlLabel}>
                Columns
              </Col>
              <Col sm={10}>
                <Select
                  multi
                  value={this.props.value.get('columns', List()).toJS()}
                  disabled={isDisabled}
                  placeholder="All columns will be imported"
                  onChange={this._handleChangeColumns}
                  options={this._getColumnsOptions()}
                />
                <HelpBlock>Import only the specified columns</HelpBlock>
              </Col>
            </FormGroup>
            {this.renderChangeInLastInput(isDisabled)}
            <FormGroup>
              <Col sm={2} componentClass={ControlLabel}>
                Data filter
              </Col>
              <Col sm={3} className="pr-0">
                <Select
                  value={this.props.value.get('whereColumn')}
                  disabled={isDisabled}
                  placeholder="Select a column"
                  onChange={this._handleChangeWhereColumn}
                  options={this._getColumnsOptions()}
                />
              </Col>
              <Col sm={2}>
                <Select
                  clearable={false}
                  disabled={isDisabled}
                  value={this.props.value.get('whereOperator', '')}
                  onChange={this._handleChangeWhereOperator}
                  options={[
                    {
                      label: whereOperatorConstants.EQ_LABEL,
                      value: whereOperatorConstants.EQ_VALUE
                    },
                    {
                      label: whereOperatorConstants.NOT_EQ_LABEL,
                      value: whereOperatorConstants.NOT_EQ_VALUE
                    }
                  ]}
                />
              </Col>
              <Col sm={5} className="pl-0">
                <Select
                  value={this.props.value.get('whereValues')}
                  multi
                  disabled={isDisabled}
                  allowCreate
                  placeholder="Add a value"
                  emptyStrings
                  onChange={this._handleChangeWhereValues}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={2} componentClass={ControlLabel}>
                Sort key
              </Col>
              <Col sm={10}>
                <Select
                  multi
                  value={this._getSortKeyImmutable()}
                  disabled={isDisabled}
                  placeholder="No sort key"
                  onChange={this._handleChangeSortKey}
                  options={this._getFilteredColumnsOptions()}
                />
                <HelpBlock>
                  SORTKEY option for creating a table in the Redshift DB. You can create a compound
                  sort key.
                </HelpBlock>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={2} componentClass={ControlLabel}>
                Distribution
              </Col>
              <Col sm={5}>
                <Select
                  value={this.props.value.get('distStyle')}
                  disabled={isDisabled}
                  placeholder="Style"
                  onChange={this._handleChangeDistStyle}
                  options={distStyleOptions}
                />
              </Col>
              <Col sm={5}>
                <Select
                  value={this.props.value.get('distKey')}
                  disabled={isDisabled || this.props.value.get('distStyle') !== 'KEY'}
                  placeholder={
                    this.props.value.get('distStyle') === 'KEY'
                      ? 'Select a column'
                      : 'Column selection not available'
                  }
                  onChange={this._handleChangeDistKey}
                  options={this._getFilteredColumnsOptions()}
                />
              </Col>
              <Col sm={10} smOffset={2}>
                <HelpBlock>
                  DISTKEY and DISTSTYLE options used for CREATE TABLE query in Redshift
                </HelpBlock>
              </Col>
            </FormGroup>
            <RedshiftDataTypesContainer
              value={this.props.value.get('datatypes', Map())}
              disabled={isDisabled}
              onChange={this._handleChangeDataTypes}
              columnsOptions={this._getFilteredColumnsOptions()}
            />
          </PanelWithDetails>
        )}
      </Form>
    );
  },

  renderChangeInLastInput(isDisabled) {
    return (
      <FormGroup>
        <Col sm={2} componentClass={ControlLabel}>
          Changed in Last
        </Col>
        <Col sm={10}>
          <ChangedSinceInput
            value={this.props.value.get(
              'changedSince',
              this.props.value.get('days') > 0 ? `-${this.props.value.get('days')} days` : null
            )}
            disabled={isDisabled}
            onChange={this._handleChangeChangedSince}
          />
        </Col>
      </FormGroup>
    );
  },

  renderOptionalInput() {
    return (
      <FormGroup>
        <Col sm={10} smOffset={2}>
          <Checkbox
            checked={this.props.value.get('optional', false)}
            disabled={this.props.disabled}
            onChange={this._handleChangeOptional}
          >
            Optional
          </Checkbox>
          <HelpBlock>
            If the source table doesn&apos;t exist in Storage, the transformation won&apos;t show an
            error.
          </HelpBlock>
        </Col>
      </FormGroup>
    );
  }
});

export default InputMappingRowRedshiftEditor;
