import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ConfirmButtons from '../../../../react/common/ConfirmButtons';

const ResetProjectModal = createReactClass({
  propTypes: {
    pid: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    return (
      <Modal onHide={this.props.onHide} show={this.props.show}>
        <Modal.Header closeButton>
          <Modal.Title>Disconnect GoodData Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to disconnect GoodData Project <strong>{this.props.pid}</strong> from the
          configuration.
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel="Disconnect"
            saveStyle="danger"
            isSaving={this.props.disabled}
            onSave={this.props.onConfirm}
          />
        </Modal.Footer>
      </Modal>
    );
  }
});

export default ResetProjectModal;
