import React from 'react';
import PropTypes from 'prop-types';
import { resolve } from 'react-router-named-routes';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import RouterActionCreators from '../../actions/RouterActionCreators';
import { EXTERNAL_CLASS_PREFIX } from '../../constants/external';
import {
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED
} from '../../constants/features';
import BackToProductionWarning from '../../modules/dev-branches/components/BackToProductionWarning';
import DevBranchesStore from '../../modules/dev-branches/DevBranchesStore';
import { getCampaignTarget, isValidCampaignTarget } from '../../modules/home/helpers';
import Expiration from '../../modules/home/react/Expiration';
import WelcomeModal from '../../modules/snowflake-partner-connect/components/WelcomeModal';
import { routeNames as snowflakePartnerConnectRouteNames } from '../../modules/snowflake-partner-connect/constants';
import UpgradeSPCBar from '../../modules/snowflake-partner-connect/UpgradeBar';
import StackFeaturesStore from '../../modules/stack-features/Store';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import Sidemenu from '../admin/project/Sidemenu';
import TopBarNav from '../admin/project/TopBarNav';
import Confetti from '../common/Confetti';
import ExternalLink from '../common/ExternalLink';
import createStoreMixin from '../mixins/createStoreMixin';
import ErrorPage from '../pages/ErrorPage';
import LoadingPage from '../pages/LoadingPage';
import CreateProjectBar from './CreateProjectBar';
import DevModeBar from './DevModeBar';
import FloatingNotifications from './FloatingNotifications';
import Hotkeys from './Hotkeys';
import PageTitle from './PageTitle';
import ProductFruits from './ProductFruits';
import ScrollAndFocusManager from './ScrollAndFocusManager';

const APP_HEADER_OFFSET = 20;

const App = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, DevBranchesStore, StackFeaturesStore)],

  getStateFromStores() {
    const currentProject = ApplicationStore.getCurrentProject();

    return {
      currentProject,
      currentDevBranch: DevBranchesStore.getCurrentDevBranch(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      isInitialLoading: ApplicationStore.getInitialLoading(),
      projectFeatures: ApplicationStore.getCurrentProjectFeatures(),
      projectExpiration: currentProject.get('expires'),
      isDemoPreview: ApplicationStore.isDemoPreview(),
      hasSnowflakePartnerConnect: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT
      ),
      hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED
      ),
      currentDevBranchMergeRequest: DevBranchesStore.getCurrentDevBranchMergeRequest(),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      sapiToken: ApplicationStore.getSapiToken(),
      readOnly: ApplicationStore.isReadOnly()
    };
  },

  getInitialState() {
    RouterActionCreators.routerCreated({
      isActive: (name, params, index) =>
        this.props.router.isActive({ pathname: resolve(name, params) }, index)
    });

    return {
      backFlow: Map(),
      isCollapsed: false,
      organizations: ApplicationStore.getOrganizations(),
      isSingleTenant: StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      projectTemplates: ApplicationStore.getProjectTemplates(),
      xsrf: ApplicationStore.getXsrfToken(),
      canManageApps: ApplicationStore.getKbcVars().get('canManageApps')
    };
  },

  componentDidMount() {
    this.identifyUser();

    window.addEventListener(
      'scroll',
      () => document.body.classList.toggle('sticky', window.scrollY > APP_HEADER_OFFSET),
      { passive: true }
    );
  },

  componentDidUpdate(prevProps) {
    if (!this.state.backFlow.has('pathname') && !!this.props.location?.state?.flowId) {
      this.setState({
        backFlow: Map({
          id: this.props.location.state.flowId,
          scroll: this.props.location.state.scrollY,
          pathname: this.props.location.pathname
        })
      });
    } else if (
      !this.state.backFlow.isEmpty() &&
      !this.props.location.pathname.startsWith(this.state.backFlow.get('pathname'))
    ) {
      this.setState({ backFlow: Map() });
    }

    if (
      !this.state.isCollapsed &&
      this.props.location.pathname.includes('/flows/') &&
      !prevProps.location.pathname.includes('/flows/')
    ) {
      this.setState({ isCollapsed: true });
    }
  },

  render() {
    return (
      <div
        className={classNames(this.getExternalClassName(), {
          'demo-preview': this.state.isDemoPreview
        })}
      >
        <CreateProjectBar />
        {this.renderUpgradeSPCBar()}
        {this.renderProjectExpirationBar()}
        <DevModeBar
          sapiToken={this.state.sapiToken}
          currentDevBranch={this.state.currentDevBranch}
          hasProtectedDefaultBranch={this.state.hasProtectedDefaultBranch}
          mergeRequest={this.state.currentDevBranchMergeRequest}
        />
        {this.renderBody()}
      </div>
    );
  },

  renderBody() {
    return (
      <>
        <PageTitle />
        <div id="main-app" className={classNames({ 'sidemenu-collapsed': this.state.isCollapsed })}>
          <CSSTransition timeout={120} classNames="sidemenu-slide" in={!this.state.isCollapsed}>
            <Sidemenu
              user={this.state.currentAdmin}
              urlTemplates={this.state.urlTemplates}
              currentProject={this.state.currentProject}
              projectTemplates={this.state.projectTemplates}
              canManageApps={this.state.canManageApps}
              isDemoPreview={this.state.isDemoPreview}
              organizations={this.state.organizations}
              xsrf={this.state.xsrf}
              isCollapsed={this.state.isCollapsed}
              toggleSidemenu={this.handleToggleSidemenu}
            />
          </CSSTransition>
          <main id="main-content">
            <TopBarNav backFlow={this.state.backFlow} />
            <div className="main-content-body">{this.renderMain()}</div>
          </main>
        </div>
        <FloatingNotifications />
        <Hotkeys />
        <ScrollAndFocusManager location={this.props.location} />
        {!this.state.hasProtectedDefaultBranch && <BackToProductionWarning />}
        {this.renderProductFruits()}
        {this.renderConfetti()}
        {this.renderSPCWelcomeModal()}
        {this.renderDevButtons()}
      </>
    );
  },

  renderMain() {
    if (RoutesStore.isError()) {
      return <ErrorPage />;
    }

    if (this.state.isInitialLoading) {
      return <LoadingPage />;
    }

    return this.props.children;
  },

  renderProductFruits() {
    return (
      <ProductFruits
        user={this.state.currentAdmin}
        sapiToken={this.state.sapiToken}
        location={this.props.location}
        isDemoPreview={this.state.isDemoPreview}
      />
    );
  },

  renderConfetti() {
    if (!this.props.location.query || typeof this.props.location.query.celebrate === 'undefined') {
      return null;
    }

    return <Confetti router={this.props.router} location={this.props.location} />;
  },

  renderProjectExpirationBar() {
    if (!this.state.hasSnowflakePartnerConnect || this.state.hasSnowflakePartnerConnectLimited) {
      return null;
    }

    return <Expiration expires={this.state.projectExpiration} showInStickyPanel />;
  },

  renderUpgradeSPCBar() {
    if (
      !this.state.hasSnowflakePartnerConnectLimited ||
      this.props.router.isActive(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) ||
      this.state.readOnly
    ) {
      return null;
    }

    return <UpgradeSPCBar />;
  },

  renderSPCWelcomeModal() {
    if (!this.state.hasSnowflakePartnerConnect) {
      return null;
    }

    return (
      <React.Suspense fallback={null}>
        <WelcomeModal isUpgraded={!this.state.hasSnowflakePartnerConnectLimited} />
      </React.Suspense>
    );
  },

  renderDevButtons() {
    if (process.env.NODE_ENV !== 'development') {
      return null;
    }

    return (
      <div
        style={{
          position: 'fixed',
          display: 'inline-block',
          right: '1em',
          bottom: '1em',
          backgroundColor: 'white',
          borderRadius: '.33em',
          border: 'thin solid #e3e9f3',
          fontFamily: 'monospace',
          lineHeight: '1.8em',
          padding: '0 .6em',
          zIndex: 2
        }}
      >
        <a href="/dev.html" title="Open local development settings">
          DEV
        </a>
        {' | '}
        <ExternalLink
          href={ApplicationStore.getSapiUrl() + window.location.pathname}
          title="Open in production"
        >
          PROD
        </ExternalLink>
      </div>
    );
  },

  handleToggleSidemenu() {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  },

  getExternalClassName() {
    return this.props.router.routes
      .filter((route) => !!route.name)
      .map((route) => `${EXTERNAL_CLASS_PREFIX}-${route.name}`.replace('.', '-'))
      .join(' ');
  },

  identifyUser() {
    if (this.state.isSingleTenant) {
      return;
    }

    if (window.heap?.loaded) {
      window.heap.identify(this.state.currentAdmin.get('email'));

      if (isValidCampaignTarget(this.props.location.query.target)) {
        window.heap.addUserProperties({
          landing_page: getCampaignTarget(this.props.location.query.target),
          campaign: this.props.location.query.campaign
        });
      }
    }

    if (window.hj) {
      window.hj('identify', this.state.currentAdmin.get('id'), {
        email: this.state.currentAdmin.get('email')
      });
    }
  }
});

App.propTypes = {
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default App;
