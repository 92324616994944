import dispatcher from '../../../Dispatcher';
import ApplicationStore from '../../../stores/ApplicationStore';
import SimpleError from '../../../utils/errors/SimpleError';
import jobPoller from '../../../utils/jobPoller';
import InstalledComponentsActionCreators from '../../components/InstalledComponentsActionCreators';
import InstalledComponentsApi from '../../components/InstalledComponentsApi';
import VersionsActions from '../../components/VersionsActionCreators';
import { ProvisioningActionTypes } from '../constants';

export default {
  readModel(parameters) {
    dispatcher.handleViewAction({
      type: ProvisioningActionTypes.GD_PROVISIONING_READ_MODEL_START
    });
    const componentId = 'keboola.gooddata-writer';
    const projectId = ApplicationStore.getCurrentProjectId();
    const plainJobParams = {
      component: componentId,
      method: 'run',
      data: {
        config: parameters.configurationId,
        configData: {
          parameters: {
            action: 'readModel',
            configurationId: parameters.configurationId,
            user: parameters.user,
            project: parameters.project,
            bucket: parameters.bucket
          }
        }
      }
    };
    return InstalledComponentsApi.encryptConfiguration(componentId, projectId, plainJobParams)
      .then(InstalledComponentsActionCreators.runComponent)
      .then(({ url }) => jobPoller.poll(url))
      .catch((error) => {
        throw new SimpleError('Loading LDM model failed', error.message);
      })
      .then(() =>
        InstalledComponentsActionCreators.loadComponentConfigDataForce(
          componentId,
          parameters.configurationId
        )
      )
      .then(() => {
        VersionsActions.loadVersionsForce(componentId, parameters.configurationId);
        dispatcher.handleViewAction({
          type: ProvisioningActionTypes.GD_PROVISIONING_READ_MODEL_SUCCESS
        });
        return null;
      })
      .catch((error) => {
        dispatcher.handleViewAction({
          type: ProvisioningActionTypes.GD_PROVISIONING_READ_MODEL_ERROR
        });
        throw error;
      });
  }
};
