import { Map } from 'immutable';

import { MetadataKeys } from '../MetadataConstants';

const getTableColumnMetadata = (table) => {
  if (table.get('isAlias')) {
    return table.getIn(['sourceTable', 'columnMetadata'], Map());
  }

  return table.get('columnMetadata', Map());
};

const hasTableColumnMetadataDatatypes = (table) => {
  return (
    getTableColumnMetadata(table)
      .filter((metadata) => {
        const provider = getLastActiveDatatypeProvider(metadata);

        return (
          metadata
            .filter((metadata) => {
              return (
                metadata.get('provider') === provider &&
                metadata.get('key') === MetadataKeys.BASE_TYPE
              );
            })
            .count() > 0
        );
      })
      .count() > 0
  );
};

const getColumnMetadataByProvider = (table, forceProvider) => {
  return getTableColumnMetadata(table).map((metadata) => {
    const provider =
      forceProvider || getLastActiveDatatypeProvider(metadata, { exclude: ['user'] });
    return metadata.filter((data) => data.get('provider') === provider);
  });
};

const getMachineColumnMetadata = (table) => {
  return getColumnMetadataByProvider(table);
};

const getUserColumnMetadata = (table) => {
  return getColumnMetadataByProvider(table, 'user');
};

const getLastActiveDatatypeProvider = (metadata, options = {}) => {
  const exclude = options.exclude || [];
  const latestUpdatedMetadata = metadata
    .filter((metadata) => metadata.get('key').startsWith('KBC.datatype'))
    .filter((metadata) => !exclude.includes(metadata.get('provider')))
    .sortBy((metadata) => -1 * new Date(metadata.get('timestamp')).getTime())
    .first();

  return latestUpdatedMetadata ? latestUpdatedMetadata.get('provider') : null;
};

export {
  getTableColumnMetadata,
  hasTableColumnMetadataDatatypes,
  getColumnMetadataByProvider,
  getMachineColumnMetadata,
  getUserColumnMetadata,
  getLastActiveDatatypeProvider
};
