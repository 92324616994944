import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import _ from 'underscore';
import { strRight } from 'underscore.string';

import { EXCLUDE_FROM_NEW_LIST } from '../../../../constants/componentFlags';
import { KEBOOLA_WR_AZURE_EVENT_HUB } from '../../../../constants/componentIds';
import ExternalLink from '../../../../react/common/ExternalLink';
import Markdown from '../../../../react/common/Markdown';
import Tooltip, { MAX_ACTION_TOOLTIP_LENGTH } from '../../../../react/common/Tooltip';
import contactSupport from '../../../../utils/contactSupport';
import fromJSOrdered from '../../../../utils/fromJSOrdered';
import JSONEditor from '../../../../utils/json-editor/json-editor';
import nextTick from '../../../../utils/nextTick';

const JSONSchemaEditor = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    component: PropTypes.instanceOf(Map).isRequired,
    schema: PropTypes.instanceOf(Map).isRequired,
    value: PropTypes.instanceOf(Map).isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired
  },

  jsoneditor: null,
  tooltipsRoots: Map(),
  jsoneditorRef: React.createRef(),

  getInitialState() {
    return {
      error: null
    };
  },

  componentDidMount() {
    this.initJsonEditor(this.props.value, this.props.readOnly);
  },

  componentDidUpdate(prevProps) {
    if (!this.jsoneditor || !this.props.schema.equals(prevProps.schema)) {
      return this.initJsonEditor(this.props.value, this.props.readOnly);
    }

    if (this.jsoneditor.isEnabled() && this.props.readOnly) {
      this.jsoneditor.disable();
    } else if (!this.jsoneditor.isEnabled() && !this.props.readOnly) {
      this.jsoneditor.enable();
    }
  },

  componentWillUnmount() {
    this.destroyEditor();
  },

  initJsonEditor(nextValue, nextReadOnly) {
    this.destroyEditor();

    if (!this.isSchemaValid()) {
      return this.setState({ error: true });
    }

    let options = {
      schema: this.props.schema.toJS(),
      show_errors: 'always',
      disable_array_delete_last_row: true,
      disable_array_reorder: true,
      disable_collapse: true,
      disable_edit_json: true,
      disable_properties: true,
      prompt_before_delete: false
    };

    if (nextReadOnly) {
      options.disable_array_add = true;
      options.disable_array_delete = true;
    }

    try {
      this.jsoneditor = new JSONEditor(this.jsoneditorRef.current, options);

      if (!nextValue.isEmpty()) {
        this.jsoneditor.promise.then(() => {
          this.jsoneditor.editors.root.setValue(nextValue.toJS(), true);
        });
      }
    } catch (error) {
      this.setState({ error: true });
      return;
    }

    this.jsoneditor.promise.then(() => {
      this.jsoneditor.on(
        'change',
        _.debounce(() => {
          if (!this.jsoneditor?.ready) {
            return;
          }

          const data = this.getPreparedData();

          if (!data.equals(this.props.value)) {
            this.props.onChange(data);
          }
        }, 100)
      );

      this.jsoneditor.on('addRow', this.patchEditorInput);

      this.patchEditors();
      this.setGlobalValues();

      if (nextReadOnly) {
        this.jsoneditor.disable();
      }
    });
  },

  destroyEditor() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }

    this.unmountTooltipRoots();
  },

  isSchemaValid() {
    return (
      this.props.schema.get('type') === 'object' &&
      (this.props.schema.has('properties') ||
        this.props.schema.has('allOf') ||
        this.props.schema.has('anyOf') ||
        this.props.schema.has('oneOf'))
    );
  },

  render() {
    if (this.state.error) {
      if (this.props.component.get('flags').includes(EXCLUDE_FROM_NEW_LIST)) {
        return <p>The configuration does not match the component&apos;s configuration schema.</p>;
      }

      return (
        <>
          <p>
            The configuration does not match the component&apos;s configuration schema, please
            contact support for assistance.
          </p>
          <Button onClick={() => contactSupport()} bsStyle="success">
            Contact Support
          </Button>
        </>
      );
    }

    return (
      <form autoComplete="off" className="json-editor">
        <div ref={this.jsoneditorRef} />
      </form>
    );
  },

  getPreparedData() {
    const global = this.props.component.getIn(['data', 'image_parameters', 'global_config'], Map());
    let config = fromJSOrdered(JSON.parse(JSON.stringify(this.jsoneditor.getValue())));

    if (this.props.component.get('id') !== KEBOOLA_WR_AZURE_EVENT_HUB) {
      return config;
    }

    if (!global.isEmpty()) {
      for (let key in this.jsoneditor.editors) {
        const propertyPath = strRight(key, 'root.').split('.');
        if (!!this.jsoneditor.editors[key]?.input && global.hasIn(propertyPath)) {
          config = config.deleteIn(propertyPath);
        }
      }
    }

    return config;
  },

  setGlobalValues() {
    if (this.props.component.get('id') !== KEBOOLA_WR_AZURE_EVENT_HUB) {
      return;
    }

    const global = this.props.component.getIn(['data', 'image_parameters', 'global_config'], Map());

    for (let key in this.jsoneditor.editors) {
      if (_.has(this.jsoneditor.editors, key) && key !== 'root') {
        const el = this.jsoneditor.getEditor(key);

        if (el && el.input) {
          const propertyPath = strRight(key, 'root.').split('.');
          if (global.hasIn(propertyPath)) {
            el.setValue(global.getIn(propertyPath));
            el.disable();
          }
        }
      }
    }
  },

  patchEditors() {
    for (let key in this.jsoneditor.editors) {
      if (_.has(this.jsoneditor.editors, key) && key !== 'root') {
        const editor = this.jsoneditor.getEditor(key);

        this.patchEditorInput(editor);
        this.addPropertyHint(editor);
      }
    }
  },

  patchEditorInput(editor) {
    if (!editor?.input || editor?.input_type === 'select') {
      return;
    }

    if (editor.input_type === 'password') {
      editor.input.autocomplete = 'new-password';
    }

    editor.input.addEventListener('input', () => {
      editor.refreshValue();
      editor.onChange(true);
    });
  },

  addPropertyHint(editor) {
    const label = editor?.label || editor?.header;

    if (
      !label ||
      (!_.isString(editor?.options?.tooltip) && !_.isObject(editor?.options?.documentation))
    ) {
      return;
    }

    const tooltipRoot = createRoot(label);

    tooltipRoot.render(
      <>
        {editor.schema.title}
        {editor?.options?.documentation ? (
          <ExternalLink className="text-muted" href={editor.options.documentation.link}>
            <Tooltip
              placement="top"
              type={
                editor.options.documentation?.tooltip?.length > MAX_ACTION_TOOLTIP_LENGTH
                  ? 'explanatory'
                  : 'action'
              }
              tooltip={editor.options.documentation.tooltip || 'Open documentation'}
            >
              <FontAwesomeIcon icon="book-blank" className="title-hint" />
            </Tooltip>
          </ExternalLink>
        ) : (
          <Tooltip
            placement="top"
            type="explanatory"
            tooltip={<Markdown source={editor.options.tooltip} collapsible={false} />}
          >
            <FontAwesomeIcon icon="circle-info" className="title-hint" />
          </Tooltip>
        )}
      </>
    );

    this.tooltipsRoots = this.tooltipsRoots.set(editor.path, tooltipRoot);
  },

  unmountTooltipRoots() {
    this.tooltipsRoots.map((tooltipRoot) => nextTick(() => tooltipRoot?.unmount()));
    this.tooltipsRoots = Map();
  }
});

export default JSONSchemaEditor;
