import React from 'react';
import PropTypes from 'prop-types';
import { flushSync } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import Tooltip from './Tooltip';

export const copyToClipboard = (text) => {
  return navigator.clipboard.writeText(text);
};

class Clipboard extends React.Component {
  static defaultProps = {
    tooltipText: 'Copy to Clipboard',
    tooltipPlacement: 'right',
    tooltipType: 'action',
    showIcon: true,
    inline: true
  };

  state = {
    isCopied: false,
    error: null
  };

  overlayRef = React.createRef();

  render() {
    const tooltipText = this.getTooltipText();

    return (
      <Tooltip
        tooltip={tooltipText}
        type={this.props.tooltipType}
        forceHide={!tooltipText}
        placement={this.props.tooltipPlacement}
        ref={this.overlayRef}
      >
        {this.props?.text !== '' ? (
          <span className={classnames('display-inline-block', this.props.className)}>
            <button
              className={classnames(
                'btn',
                { 'btn-link btn-link-inline dark muted': this.props.inline },
                { 'no-underline': this.props.inline && !this.props.label },
                this.props.btnClassName
              )}
              onClick={(event) => {
                event.preventDefault();

                copyToClipboard(this.props.text).then(this.handleAfterCopy, this.handleError);
              }}
            >
              {this.renderLabel()}
            </button>
          </span>
        ) : (
          this.renderLabel()
        )}
      </Tooltip>
    );
  }

  renderLabel() {
    if (!this.props.label) {
      return <span className={this.props.className}>{this.renderCopyIcon()}</span>;
    }

    return (
      <span className={this.props.className}>
        {this.props.showIcon && this.renderCopyIcon()}
        {this.props.label}
      </span>
    );
  }

  renderCopyIcon() {
    return (
      <FontAwesomeIcon
        icon="copy"
        className={classnames({
          'tw-text-base': true,
          'text-muted-light': !this.props.text,
          'icon-addon-left icon-addon-right': this.props.inline,
          'btn-icon': !this.props.inline
        })}
      />
    );
  }

  getTooltipText = () => {
    if (this.state.error) {
      return this.state.error;
    }

    if (!this.props.text) {
      return 'Nothing to copy';
    }

    return this.state.isCopied ? 'Copied to Clipboard' : this.props.tooltipText;
  };

  handleAfterCopy = () => {
    flushSync(() => this.setState({ isCopied: true }));
    this.overlayRef.current?.show();
    setTimeout(this.hideOverlay, 500);
  };

  handleError = (error) => {
    flushSync(() => this.setState({ isCopied: false, error: error.message || 'Failed to copy' }));
    this.overlayRef.current?.show();
    setTimeout(this.hideOverlay, 2000);
  };

  hideOverlay = () => {
    this.overlayRef.current?.hide();
    setTimeout(() => this.setState({ isCopied: false, error: null }), 300);
  };
}

Clipboard.propTypes = {
  text: PropTypes.string,
  label: PropTypes.node,
  tooltipText: PropTypes.node,
  tooltipPlacement: PropTypes.string,
  showIcon: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
  btnClassName: PropTypes.string
};

export default Clipboard;
