import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import { Map } from 'immutable';

import { DEVELOPERS_DOCUMENTATION_URL } from '../../../../constants/KbcConstants';
import Clipboard from '../../../../react/common/Clipboard';
import CodeEditor from '../../../../react/common/CodeEditor';
import CollapseButton from '../../../../react/common/CollapseButton';
import ExternalLink from '../../../../react/common/ExternalLink';
import SaveButtons from '../../../../react/common/SaveButtons';
import nextTick from '../../../../utils/nextTick';
import { isValidJsonConfig } from '../../../../utils/validation';

class Processors extends React.Component {
  constructor(props) {
    super(props);

    const initialValue = this.getInitialValue();

    this.state = {
      isSaving: false,
      expanded: !this.isEmpty(),
      initialValue,
      value: initialValue
    };
  }

  render() {
    return (
      <div className="box box-separator">
        <div className="box-header big-padding btn-collapse-area" onClick={this.handleCollapse}>
          <h2 className="box-title flex-container flex-start">
            <span className="color-main">Processors</span>
            {this.state.expanded && !this.isEmpty() && (
              <Clipboard text={this.state.value} btnClassName="text-muted-light" />
            )}
          </h2>
          <div className="flex-container" onClick={(event) => event.stopPropagation()}>
            {this.renderButtons()}
            <CollapseButton
              entity="processors"
              isCollapsed={!this.state.expanded}
              onToggle={this.handleCollapse}
            />
          </div>
        </div>
        <Collapse in={this.state.expanded} mountOnEnter>
          <div>
            <div className="box-content pt-0">
              <div className="edit">
                <CodeEditor
                  value={this.state.value}
                  onChange={this.handleChange}
                  options={{
                    lint: !!this.state.value,
                    readOnly: this.props.readOnly || this.state.isSaving
                  }}
                />
                <div className="help-block mb-0">
                  Learn more about{' '}
                  <ExternalLink
                    href={`${DEVELOPERS_DOCUMENTATION_URL}/extend/component/processors/`}
                  >
                    Processors
                  </ExternalLink>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }

  renderButtons() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <SaveButtons
        isSaving={this.state.isSaving}
        disabled={!this.isValid()}
        isChanged={this.state.value !== this.state.initialValue}
        onSave={this.handleSubmmit}
        onReset={this.handleReset}
      />
    );
  }

  getInitialValue() {
    const value = this.isEmpty() ? { before: [], after: [] } : this.props.value.toJS();

    return JSON.stringify(value, null, 2);
  }

  isEmpty() {
    return !Map.isMap(this.props.value) || this.props.value.isEmpty();
  }

  isValid() {
    return this.state.value === '' || isValidJsonConfig(this.state.value);
  }

  isChanged() {
    return this.state.value !== this.state.initialValue;
  }

  handleCollapse = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  handleReset = () => {
    this.setState({ value: this.state.initialValue });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  handleSubmmit = () => {
    const processors =
      this.state.value === '' || this.state.value === '{}' ? null : JSON.parse(this.state.value);

    this.setState({ isSaving: true });
    return this.props
      .onSubmit(processors)
      .then(() => {
        // wait for update props
        nextTick(() => {
          const newInitialValue = this.getInitialValue();
          this.setState({ initialValue: newInitialValue, value: newInitialValue });
        });
      })
      .finally(() => this.setState({ isSaving: false }));
  };
}

Processors.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
  onSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export default Processors;
