import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import { componentTypes } from '../../constants/componentTypes';
import { NEW_ENTITY_BUTTON } from '../../constants/external';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import { getAllowedTransformations } from '../components/helpers';
import ComponentsStore from '../components/stores/ComponentsStore';
import StackFeaturesStore from '../stack-features/Store';
import CreateSharedCodeModal from './components/CreateSharedCodeModal';
import SharedCodesActions from './Actions';

const HeaderButton = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, StackFeaturesStore, ComponentsStore)],

  getInitialState() {
    return { showCreateModal: false };
  },

  getStateFromStores() {
    return {
      readOnly: ApplicationStore.isReadOnly(),
      allowedTransformationComponents: getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll()
      )
    };
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <>
        <Button
          onClick={() => this.setState({ showCreateModal: true })}
          bsStyle="success"
          className={NEW_ENTITY_BUTTON}
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Create Shared Code
        </Button>
        <CreateSharedCodeModal
          show={this.state.showCreateModal}
          onSubmit={(type, name) => {
            return SharedCodesActions.createSharedCode(type, name).then((newConfig) => {
              RoutesStore.getRouter().transitionTo('shared-code', {
                config: newConfig.sharedCodesConfigurationId,
                row: newConfig.sharedCodesConfigurationRowId
              });
              return newConfig;
            });
          }}
          onHide={() => this.setState({ showCreateModal: false })}
          allowedTransformationComponents={this.state.allowedTransformationComponents}
        />
      </>
    );
  }
});

export default HeaderButton;
