import React from 'react';
import PropTypes from 'prop-types';
import PureRendererMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import Loader from '../../../react/common/Loader';
import TableRow from './EventsTableRow';

const EventsTable = createReactClass({
  mixins: [PureRendererMixin],

  propTypes: {
    events: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onEventSelect: PropTypes.func.isRequired
  },

  render() {
    return (
      <div className="table table-hover">
        <div className="thead">
          <div className="tr">
            <div className="th">Component</div>
            <div className="th">Event {this.props.isLoading && <Loader />}</div>
            <div className="th text-right">Created</div>
          </div>
        </div>
        <div className="tbody">{this._body()}</div>
      </div>
    );
  },

  _body() {
    return this.props.events
      .map((event) => {
        return <TableRow key={event.get('id')} onClick={this.props.onEventSelect} event={event} />;
      })
      .toArray();
  }
});

export default EventsTable;
