import Immutable, { List } from 'immutable';

const createConfiguration = function (localState) {
  return Immutable.fromJS({
    parameters: {
      customerId: localState.get('customerId', []),
      onlyEnabledCustomers: localState.get('onlyEnabledCustomers', true)
    }
  });
};

const parseConfiguration = function (configuration) {
  return Immutable.fromJS({
    customerId: configuration.getIn(['parameters', 'customerId'], []),
    onlyEnabledCustomers: configuration.getIn(['parameters', 'onlyEnabledCustomers'], true)
  });
};

const isComplete = function (configuration) {
  return !configuration.getIn(['parameters', 'customerId'], List()).isEmpty();
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  isComplete: isComplete
};
