import React from 'react';
import { Modal } from 'react-bootstrap';
import type { List } from 'immutable';

import { DISABLE_AUTOSAVING_IN_FLOWS, FEATURE_RAW_MODE } from '../../../constants/features';
import {
  addUserFeature,
  generateSessionToken,
  loadFeatures,
  removeUserFeature
} from '../../../modules/settings/actions';
import { FEATURE_TYPES } from '../../../modules/settings/constants';
import ModalIcon from '../../../react/common/ModalIcon';
import FeatureList from '../../common/FeatureList';
import InfoAlert from '../../common/InfoAlert';
import Loader from '../../common/Loader';

const isApprovedFeature = (feature: Map<string, any>) => {
  return [FEATURE_RAW_MODE, DISABLE_AUTOSAVING_IN_FLOWS].includes(feature.get('name'));
};

const UserFeaturesModal = (props: {
  show: boolean;
  onHide: () => void;
  activatedFeatures: List<string>;
  availableFeatures: List<any>;
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const availableFeatures = props.availableFeatures
    .filter((feature) => feature.get('type') === FEATURE_TYPES.ADMIN)
    .filter(isApprovedFeature) as List<any>;

  const onEnter = () => {
    if (!props.availableFeatures.isEmpty()) {
      return;
    }

    setLoading(true);
    generateSessionToken()
      .then(() => loadFeatures())
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={props.show} onHide={props.onHide} onEnter={onEnter}>
      <Modal.Header closeButton>
        <Modal.Title>User Features</Modal.Title>
        <ModalIcon icon="star" color="blue" bold />
      </Modal.Header>
      <Modal.Body className="pbp-2">
        {isLoading ? (
          <p>
            <Loader className="icon-addon-right" />
            Loading available features...
          </p>
        ) : availableFeatures.isEmpty() ? (
          <p>No user features available yet.</p>
        ) : (
          <>
            <InfoAlert className="mbp-6">
              These features are connected to your account, so they may change the behavior of every
              project you are a member of in the stack.
            </InfoAlert>
            <FeatureList
              availableFeatures={availableFeatures}
              activatedFeatures={props.activatedFeatures}
              onAddFeature={addUserFeature}
              onRemoveFeature={removeUserFeature}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UserFeaturesModal;
