/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useRowSelect, useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { List, Map } from 'immutable';

import BlockButton from '../../../react/common/BlockButton';
import BucketStageLabel from '../../../react/common/BucketStageLabel';
import { onTableRowKeyDown } from '../../../react/common/ConfigurationsTable/helpers';
import CreatedDate from '../../../react/common/CreatedDate';
import MarkedText from '../../../react/common/MarkedText';
import Link from '../../../react/common/RouterLink';
import RoutesStore from '../../../stores/RoutesStore';
import onClickSelectionCell from '../../../utils/onClickSelectionCell';
import { canManageBucket, canWriteBucket } from '../../admin/privileges';
import { isCreatedInDevBranch } from '../../dev-branches/helpers';
import { routeNames, sortEntities, storageInitialLimit } from '../constants';
import { getFilteredData, sortByDisplayName, sortByExactMatch } from '../helpers';
import ExternalBucketLabel from './ExternalBucketLabel';
import {
  ActionDropdown,
  BucketActions,
  CellSelection,
  HeaderSelection,
  LastChangeColumn,
  TableActions,
  useSorter
} from './IndexTable';
import NativeTypesLabel from './NativeTypesLabel';

const Table = ({ columns, data, sapiToken }) => {
  const [showAll, setShowAll] = React.useState(false);
  const tableInstance = useTable({ columns, data }, useRowSelect, (hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: 'selection',
        Header: (props) => <HeaderSelection sapiToken={sapiToken} {...props} />,
        Cell: ({ row }) => <CellSelection sapiToken={sapiToken} row={row} />
      },
      ...columns
    ]);
  });

  let rows = tableInstance.rows.map((row) => {
    tableInstance.prepareRow(row);

    return row;
  });

  if (!showAll) {
    rows = rows.slice(0, storageInitialLimit);
  }

  return (
    <>
      <div {...tableInstance.getTableProps({ className: 'table table-hover react-table' })}>
        <div className="thead">
          {tableInstance.headerGroups.map((headerGroup, index) => (
            <div
              key={index}
              {...headerGroup.getHeaderGroupProps({
                className: 'tr with-action-buttons is-sticky bg-color-white'
              })}
            >
              {headerGroup.headers.map((column) => {
                const userCellProps = {
                  className: classnames('th', {
                    'w-52': column.id === 'selection',
                    'w-200 text-right': column.id === 'last_modified'
                  })
                };

                if (column.id === 'selection') {
                  userCellProps.onClick = onClickSelectionCell;
                }

                return (
                  <div key={column.id} {...column.getHeaderProps(userCellProps)}>
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...tableInstance.getTableBodyProps({ className: 'tbody' })}>
          {rows.map((row) => {
            const rowAction = () =>
              row.values.item.has('bucketTables')
                ? RoutesStore.getRouter().transitionTo(routeNames.BUCKET, {
                    bucketId: row.values.item.get('id')
                  })
                : RoutesStore.getRouter().transitionTo(routeNames.TABLE, {
                    bucketId: row.values.item.getIn(['bucket', 'id']),
                    tableName: row.values.item.get('name')
                  });

            return (
              <div
                key={row.id}
                {...row.getRowProps({
                  tabIndex: '0',
                  role: 'button',
                  className: 'tr clickable hoverable-actions-with-replacement',
                  onClick: rowAction,
                  onKeyDown: onTableRowKeyDown(rowAction)
                })}
              >
                {row.cells.map((cell, index) => {
                  const userCellProps = {
                    className: classnames('td', {
                      'bg-selected': row.isSelected,
                      'text-right': cell.column.id === 'last_modified',
                      'dev-bucket': isCreatedInDevBranch(
                        row.values.item.has('stage')
                          ? row.values.item
                          : row.values.item.get('bucket')
                      )
                    })
                  };

                  if (cell.column.id === 'selection') {
                    userCellProps.onClick = onClickSelectionCell;
                  }

                  return (
                    <div key={index} {...cell.getCellProps(userCellProps)}>
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {tableInstance.rows.length > storageInitialLimit && !showAll && (
        <BlockButton label="Show All" onClick={() => setShowAll(true)} />
      )}
    </>
  );
};

const IndexTableResults = ({
  sapiToken,
  buckets,
  query,
  searchFilters,
  bucketsSort,
  openDeleteModal,
  openDeleteBucketModal,
  openDeleteTableModal,
  openExportTablesModal,
  openCreateWorkpaceModal,
  deletingTables,
  exportingTables,
  deletingBuckets,
  canExportTable,
  hasSnowflakePartnerConnectLimited
}) => {
  const { sort, setSort, sorter } = useSorter(bucketsSort);

  const columns = React.useMemo(
    () => [
      {
        Header: ({ state, rowsById }) => (
          <ActionDropdown
            openDeleteModal={openDeleteModal}
            openCreateWorkpaceModal={openCreateWorkpaceModal}
            canExportTable={canExportTable}
            openExportTablesModal={openExportTablesModal}
            state={state}
            rowsById={rowsById}
            sort={sort}
            setSort={setSort}
            hasSnowflakePartnerConnectLimited={hasSnowflakePartnerConnectLimited}
          />
        ),
        Cell: ({ row }) => {
          if (row.values.item.has('stage')) {
            const bucketToExport = buckets.get(row.values.item.get('id'));

            return (
              <span className="flex-container flex-start overflow-break-anywhere">
                <FontAwesomeIcon
                  fixedWidth
                  icon={
                    bucketToExport.get('bucketTables', List()).isEmpty()
                      ? ['far', 'folder']
                      : 'folder'
                  }
                  className="text-muted"
                />
                <Link
                  to={routeNames.BUCKET}
                  params={{ bucketId: row.values.item.get('id') }}
                  className="link-inherit"
                >
                  <MarkedText mark={query} source={row.values.item.get('displayName')} />
                </Link>
                <BucketStageLabel stage={row.values.item.get('stage')} round />
                <ExternalBucketLabel
                  hasExternalSchema={row.values.item.get('hasExternalSchema', false)}
                />
              </span>
            );
          }

          return (
            <span className="flex-container flex-start overflow-break-anywhere">
              <FontAwesomeIcon fixedWidth icon="table" className="text-muted" />
              <Link
                to={routeNames.TABLE}
                params={{
                  bucketId: row.values.item.getIn(['bucket', 'id']),
                  tableName: row.values.item.get('name')
                }}
                className={classnames('link-inherit', {
                  'dotted-underline': row.values.item.get('isAlias', false)
                })}
              >
                {row.values.item.getIn(['bucket', 'displayName'])}
                <BucketStageLabel
                  round
                  className="mr-0"
                  stage={row.values.item.getIn(['bucket', 'stage'])}
                />
                <FontAwesomeIcon
                  icon={['far', 'angle-right']}
                  className="text-muted icon-addon-left icon-addon-right"
                />
                <MarkedText mark={query} source={row.values.item.get('displayName')} />
              </Link>
              <NativeTypesLabel isTyped={row.values.item.get('isTyped', false)} className="ml-1" />
            </span>
          );
        },
        accessor: 'item'
      },
      {
        Header: <LastChangeColumn sort={sort} setSort={setSort} />,
        accessor: 'last_modified'
      }
    ],
    [
      query,
      buckets,
      sort,
      setSort,
      openCreateWorkpaceModal,
      openDeleteModal,
      canExportTable,
      hasSnowflakePartnerConnectLimited,
      openExportTablesModal
    ]
  );

  const memoizedRows = React.useMemo(() => {
    let data = getFilteredData(buckets, query, searchFilters.toJS());

    if (sort.get('entity') === sortEntities.NAME && !!query) {
      data = data.sortBy(sortByDisplayName).sortBy(sortByExactMatch);
    } else {
      data = data.sort(sorter);
    }

    return data
      .map((bucket) => {
        const isDeleting = deletingBuckets.get(bucket.get('id'), false);
        const bucketToExport = buckets.get(bucket.get('id'));
        const isExportingBucket =
          !bucketToExport.get('bucketTables').isEmpty() &&
          bucketToExport
            .get('bucketTables')
            .every((table, tableId) => exportingTables.get(tableId, false));
        let tables = bucket.get('bucketTables');

        if (sort.get('entity') === sortEntities.NAME && !!query) {
          tables = tables.sortBy(sortByDisplayName).sortBy(sortByExactMatch);
        } else {
          tables = tables.sort(sorter);
        }

        return [
          bucket.get('matches') && {
            item: bucket,
            last_modified: (
              <div
                className={classnames('actions-container', {
                  'force-actions': isDeleting || isExportingBucket
                })}
              >
                <div className="not-actions">
                  <CreatedDate
                    createdTime={bucket.get('lastChangeDate') || bucket.get('created')}
                  />
                </div>
                <div className="actions">
                  <BucketActions
                    bucket={bucketToExport}
                    isDeleting={isDeleting}
                    isExporting={isExportingBucket}
                    openModal={openDeleteBucketModal}
                    canExportTable={canExportTable}
                    openExportTablesModal={openExportTablesModal}
                    canManageBucket={canManageBucket(sapiToken, bucket)}
                  />
                </div>
              </div>
            )
          },
          ...tables
            .map((table) => {
              const isDeleting = deletingTables.get(table.get('id'), false);

              return {
                item: table,
                last_modified: (
                  <div
                    className={classnames('actions-container', {
                      'force-actions': isDeleting || exportingTables.get(table.get('id'), false)
                    })}
                  >
                    <div className="not-actions">
                      <CreatedDate
                        createdTime={table.get('lastChangeDate') || table.get('created')}
                      />
                    </div>
                    <div className="actions">
                      <TableActions
                        table={table}
                        isDeleting={isDeleting}
                        isExporting={exportingTables.get(table.get('id'), false)}
                        openDeleteTableModal={openDeleteTableModal}
                        openExportTablesModal={openExportTablesModal}
                        canExportTable={canExportTable}
                        canWriteTable={canWriteBucket(sapiToken, table.get('bucket'))}
                      />
                    </div>
                  </div>
                )
              };
            })
            .toArray()
        ].filter(Boolean);
      })
      .toArray()
      .flat();
  }, [
    query,
    sapiToken,
    searchFilters,
    buckets,
    sorter,
    sort,
    deletingBuckets,
    deletingTables,
    exportingTables,
    canExportTable,
    openDeleteBucketModal,
    openDeleteTableModal,
    openExportTablesModal
  ]);

  if (memoizedRows.length === 0) {
    return (
      <div className="box searchbar-results-box">
        <div className="box-content">No buckets or tables are matching your criteria</div>
      </div>
    );
  }

  return (
    <div className="box">
      <Table columns={columns} data={memoizedRows} query={query} sapiToken={sapiToken} />
    </div>
  );
};

IndexTableResults.propTypes = {
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  deletingTables: PropTypes.instanceOf(Map).isRequired,
  deletingBuckets: PropTypes.instanceOf(Map).isRequired,
  exportingTables: PropTypes.instanceOf(Map).isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  openDeleteBucketModal: PropTypes.func.isRequired,
  openDeleteTableModal: PropTypes.func.isRequired,
  openExportTablesModal: PropTypes.func.isRequired,
  openCreateWorkpaceModal: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  searchFilters: PropTypes.instanceOf(Map).isRequired,
  bucketsSort: PropTypes.instanceOf(Map).isRequired,
  canExportTable: PropTypes.bool.isRequired,
  hasSnowflakePartnerConnectLimited: PropTypes.bool.isRequired
};

export default IndexTableResults;
