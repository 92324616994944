import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import StorageApiTableLinkEx from '../../../components/react/components/StorageApiTableLinkEx';

const TargetTableSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      source: PropTypes.string.isRequired,
      destination: PropTypes.string.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    const { value, onChange, disabled } = this.props;

    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Source</h2>
          </div>
          <div className="box-content">
            <FormGroup>
              <Col componentClass={ControlLabel} sm={4}>
                Storage Table
              </Col>
              <Col sm={8}>
                <FormControl.Static>
                  <StorageApiTableLinkEx tableId={this.props.value.source} />
                </FormControl.Static>
              </Col>
            </FormGroup>
          </div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Destination</h2>
          </div>
          <div className="box-content">
            <FormGroup>
              <Col componentClass={ControlLabel} sm={4}>
                BigQuery Table Name
              </Col>
              <Col sm={8}>
                <FormControl
                  type="text"
                  disabled={disabled}
                  onChange={(e) => onChange({ destination: e.target.value })}
                  value={value.destination}
                />
              </Col>
            </FormGroup>
          </div>
        </div>
      </>
    );
  }
});

export default TargetTableSection;
