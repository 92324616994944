import { Promise } from 'bluebird';
import { Map } from 'immutable';

import { KEBOOLA_EX_GOOGLE_ANALYTICS_V_4 } from '../../constants/componentIds';
import request from '../../utils/request';
import callDockerAction from '../components/DockerActionsApi';
import { hasNewProfiles, hasOldProfiles } from './helpers';

const PROFILES_METADATA_URL =
  'https://content.googleapis.com/analytics/v3/metadata/ga/columns?reportType=ga';

const prepareProfilesData = (metadata, type) => {
  return metadata?.items?.filter((item) => item.attributes.type === type) ?? [];
};

const preparePropertiesData = (metadata, type) => {
  return (
    metadata?.[type]?.map(({ apiName, uiName, description }) => {
      return { id: apiName, attributes: { uiName, description } };
    }) ?? []
  );
};

const loadMetadata = (configData) => {
  return Promise.props({
    propertiesMetadata: hasNewProfiles(configData)
      ? callDockerAction(KEBOOLA_EX_GOOGLE_ANALYTICS_V_4, 'getPropertiesMetadata', {
          configData: {
            authorization: configData.get('authorization', Map()).toJS(),
            parameters: {
              properties: configData.getIn(['parameters', 'properties'], Map()).toJS()
            }
          }
        })
      : Promise.resolve(),
    profilesMetadata: hasOldProfiles(configData)
      ? request('GET', PROFILES_METADATA_URL)
          .promise()
          .then((response) => response.body)
      : Promise.resolve()
  }).then(({ propertiesMetadata, profilesMetadata }) => {
    return {
      metrics: prepareProfilesData(profilesMetadata, 'METRIC'),
      dimensions: prepareProfilesData(profilesMetadata, 'DIMENSION'),
      dataApiMetrics: preparePropertiesData(propertiesMetadata, 'metrics'),
      dataApiDimensions: preparePropertiesData(propertiesMetadata, 'dimensions')
    };
  });
};

export { loadMetadata };
