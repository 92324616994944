import React from 'react';

import {
  KDS_TEAM_WR_EXASOL,
  KDS_TEAM_WR_FIREBOLT,
  KDS_TEAM_WR_HIVE_CSAS,
  KDS_TEAM_WR_PAIRITY,
  KEBOOLA_WR_DB_HIVE,
  KEBOOLA_WR_DB_IMPALA,
  KEBOOLA_WR_DB_MSSQL_V_2,
  KEBOOLA_WR_DB_MYSQL,
  KEBOOLA_WR_DB_ORACLE,
  KEBOOLA_WR_DB_PGSQL,
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_DB_SYNAPSE,
  KEBOOLA_WR_LOOKER_V2,
  KEBOOLA_WR_REDSHIFT_V_2,
  KEBOOLA_WR_SISENSE,
  KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE,
  KEBOOLA_WR_THOUGHTSPOT
} from '../../../constants/componentIds';
import { USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import ExternalLink from '../../../react/common/ExternalLink';

const defaultFields = {
  host: {
    label: 'Hostname',
    name: 'host',
    type: 'text',
    required: true,
    protected: false,
    defaultValue: ''
  },
  port: {
    label: 'Port',
    name: 'port',
    type: 'number',
    required: true,
    protected: false,
    defaultValue: '3306'
  },
  user: {
    label: 'Username',
    name: 'user',
    type: 'text',
    required: true,
    protected: false,
    defaultValue: ''
  },
  password: {
    label: 'Password',
    name: 'password',
    type: 'password',
    required: true,
    protected: true,
    defaultValue: ''
  },
  database: {
    label: 'Database Name',
    name: 'database',
    type: 'text',
    required: true,
    protected: false,
    defaultValue: ''
  }
};

const snowflakeFields = {
  host: defaultFields.host,
  port: { ...defaultFields.port, defaultValue: '443' },
  user: defaultFields.user,
  '#password': { ...defaultFields.password, name: '#password' },
  database: defaultFields.database,
  schema: {
    label: 'Schema',
    name: 'schema',
    type: 'text',
    required: true,
    protected: false,
    defaultValue: ''
  },
  warehouse: {
    label: 'Warehouse',
    name: 'warehouse',
    type: 'text',
    required: false,
    protected: false,
    defaultValue: ''
  }
};

const fields = {
  [KEBOOLA_WR_DB_MSSQL_V_2]: {
    host: defaultFields.host,
    instance: {
      label: 'Instance Name',
      name: 'instance',
      type: 'text',
      required: false,
      protected: false,
      defaultValue: '',
      help: 'Optional instance name'
    },
    port: { ...defaultFields.port, defaultValue: '1433' },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    database: defaultFields.database,
    tdsVersion: {
      label: 'Server Version',
      name: 'tdsVersion',
      type: 'select',
      required: true,
      protected: false,
      defaultValue: '7.1',
      options: [
        { value: '7.0', label: 'Microsoft SQL Server 7.0' },
        { value: '7.1', label: 'Microsoft SQL Server 2000' },
        { value: '7.2', label: 'Microsoft SQL Server 2005' },
        { value: '7.3', label: 'Microsoft SQL Server 2008' },
        { value: '7.4', label: 'Microsoft SQL Server 2012 or newer' }
      ]
    }
  },

  [KEBOOLA_WR_DB_MYSQL]: {
    host: defaultFields.host,
    port: defaultFields.port,
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    database: defaultFields.database
  },

  [KEBOOLA_WR_DB_IMPALA]: {
    host: defaultFields.host,
    port: { ...defaultFields.port, defaultValue: '21050' },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    database: defaultFields.database,
    auth_mech: {
      label: 'Authentication mechanism',
      name: 'auth_mech',
      type: 'number',
      required: true,
      protected: false,
      defaultValue: '3'
    }
  },

  [KEBOOLA_WR_DB_HIVE]: {
    host: defaultFields.host,
    port: { ...defaultFields.port, defaultValue: '10000' },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    database: defaultFields.database
  },

  [KDS_TEAM_WR_HIVE_CSAS]: {
    host: defaultFields.host,
    port: { ...defaultFields.port, defaultValue: '10000' },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    kinitPrincipal: {
      label: 'Kinit Principal',
      name: 'kinitPrincipal',
      type: 'text',
      required: true,
      protected: false
    },
    servicePrincipal: {
      label: 'Service Principal',
      name: 'servicePrincipal',
      type: 'text',
      required: true,
      protected: false
    },
    config: {
      label: 'Config',
      name: 'config',
      type: 'text',
      required: true,
      protected: false
    },
    '#keytab': {
      label: 'Keytab',
      name: '#keytab',
      type: 'text',
      required: true,
      protected: true
    },
    database: defaultFields.database
  },

  [KEBOOLA_WR_DB_SYNAPSE]: {
    host: defaultFields.host,
    port: { ...defaultFields.port, defaultValue: '1433' },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    database: defaultFields.database,
    schema: {
      label: 'Schema',
      name: 'schema',
      type: 'text',
      required: false,
      protected: false,
      defaultValue: 'dbo'
    }
  },

  [KEBOOLA_WR_REDSHIFT_V_2]: {
    host: defaultFields.host,
    port: { ...defaultFields.port, defaultValue: '5439' },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    database: defaultFields.database,
    schema: {
      label: 'Schema',
      name: 'schema',
      type: 'text',
      required: true,
      protected: false,
      defaultValue: ''
    }
  },

  [KEBOOLA_WR_DB_ORACLE]: {
    host: { ...defaultFields.host, required: false },
    port: { ...defaultFields.port, defaultValue: '1521', required: false },
    database: { ...defaultFields.database, label: 'Service Name/SID', required: false },
    tnsnames: {
      label: 'Tnsnames',
      name: 'tnsnames',
      type: 'textarea',
      protected: false,
      required: false,
      defaultValue: '',
      help: (
        <>
          Fill <code>Tnsnames</code> only if you want to connect without specifying the{' '}
          <code>Hostname</code>, <code>Port</code> and <code>Service Name/SID</code> parameters.
          Read more in the{' '}
          <ExternalLink
            href={`${USER_DOCUMENTATION_URL}/components/extractors/database/sqldb/#oracle-tnsnamesora-file`}
          >
            documentation
          </ExternalLink>
          .
        </>
      )
    },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    schema: {
      label: 'Schema',
      name: 'schema',
      type: 'text',
      required: false,
      protected: false,
      defaultValue: ''
    }
  },

  [KEBOOLA_WR_DB_SNOWFLAKE]: snowflakeFields,

  [KEBOOLA_WR_LOOKER_V2]: snowflakeFields,

  [KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE]: snowflakeFields,

  [KEBOOLA_WR_DB_PGSQL]: {
    host: defaultFields.host,
    port: { ...defaultFields.port, defaultValue: '5432' },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    database: defaultFields.database,
    schema: {
      label: 'Schema',
      name: 'schema',
      type: 'text',
      required: true,
      protected: false,
      defaultValue: 'public'
    }
  },

  [KDS_TEAM_WR_PAIRITY]: {
    host: defaultFields.host,
    port: { ...defaultFields.port, defaultValue: '5432' },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    database: defaultFields.database,
    schema: {
      label: 'Schema',
      name: 'schema',
      type: 'text',
      required: true,
      protected: false,
      defaultValue: 'public'
    }
  },

  [KDS_TEAM_WR_FIREBOLT]: {
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    database: defaultFields.database,
    aws_api_key_id: {
      label: 'AWS API Key Id',
      name: 'aws_api_key_id',
      type: 'text',
      required: true,
      protected: false
    },
    '#aws_api_key_secret': {
      label: 'AWS API Key Secret',
      name: '#aws_api_key_secret',
      type: 'text',
      required: true,
      protected: true
    },
    staging_bucket: {
      label: 'AWS Staging Bucket',
      name: 'staging_bucket',
      type: 'text',
      required: true,
      protected: false
    },
    engine_name: {
      label: 'Engine Name',
      name: 'engine_name',
      type: 'text',
      required: false,
      protected: false
    }
  },

  [KEBOOLA_WR_THOUGHTSPOT]: {
    host: defaultFields.host,
    port: { ...defaultFields.port, defaultValue: '12345' },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    database: defaultFields.database,
    schema: {
      label: 'Schema',
      name: 'schema',
      type: 'text',
      required: false,
      protected: false,
      defaultValue: ''
    },
    sshUser: {
      label: 'SSH User',
      name: 'sshUser',
      type: 'text',
      required: true,
      protected: false,
      defaultValue: ''
    },
    '#sshPassword': {
      label: 'SSH Password',
      name: '#sshPassword',
      type: 'password',
      required: true,
      protected: true,
      defaultValue: ''
    }
  },

  [KEBOOLA_WR_SISENSE]: {
    host: defaultFields.host,
    port: { ...defaultFields.port, defaultValue: '30845' },
    username: { ...defaultFields.user, name: 'username' },
    '#password': { ...defaultFields.password, name: '#password' },
    database: { ...defaultFields.database, label: 'Datamodel' }
  },

  [KDS_TEAM_WR_EXASOL]: {
    host: defaultFields.host,
    port: { ...defaultFields.port, defaultValue: '8563' },
    user: defaultFields.user,
    '#password': { ...defaultFields.password, name: '#password' },
    '#refresh_token': {
      label: 'Personal Access Token',
      name: '#refresh_token',
      type: 'text',
      required: true,
      protected: true,
      defaultValue: ''
    },
    schema: {
      label: 'Schema',
      name: 'schema',
      type: 'text',
      required: true,
      protected: false,
      defaultValue: ''
    }
  }
};

const getRequiredFields = (componentId) => {
  return getComponentFields(componentId).filter((field) => field.required);
};

const getWithDefaultValue = (componentId) => {
  return getComponentFields(componentId).filter((field) => !!field.defaultValue);
};

const getComponentFields = (componentId) => {
  return Object.values(fields[componentId] || defaultFields);
};

export { fields, defaultFields, getComponentFields, getRequiredFields, getWithDefaultValue };
