import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import {
  KEBOOLA_CSV_IMPORT as COMPONENT_ID,
  KEBOOLA_ORCHESTRATOR
} from '../../../../constants/componentIds';
import { componentTypes } from '../../../../constants/componentTypes';
import ConfigurationInfoPanel from '../../../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../../../react/common/ConfigurationTabs';
import RouterLink from '../../../../react/common/RouterLink';
import SaveButtons from '../../../../react/common/SaveButtons';
import Sidebar from '../../../../react/layout/Sidebar';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import ComponentDescription from '../../../components/react/components/ComponentDescription';
import ComponentsStore from '../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';
import StorageBucketsStore from '../../../components/stores/StorageBucketsStore';
import StorageTablesStore from '../../../components/stores/StorageTablesStore';
import DevBranchesStore from '../../../dev-branches/DevBranchesStore';
import { routeNames as storageRouteNames } from '../../../storage/constants';
import { prepareTablesMetadataMap } from '../../../storage/helpers';
import actionsProvisioning from '../../actionsProvisioning';
import storeProvisioning from '../../storeProvisioning';
import { getDefaultTable } from '../../utils';
import Settings from '../components/Settings';
import Upload from '../components/Upload';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      DevBranchesStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageTablesStore,
      StorageBucketsStore
    )
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const store = storeProvisioning(configId);
    const actions = actionsProvisioning(configId);

    return {
      configId: configId,
      actions: actions,
      config: store.config,
      settings: store.settings,
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      isUploaderFileBig: store.isUploaderFileBig,
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      localState: store.getLocalState(),
      component: ComponentsStore.getComponent(COMPONENT_ID),
      hasFlows: ApplicationStore.hasFlows(),
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive()
    };
  },

  renderUploader() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">CSV File</h3>
        </div>
        <div className="box-content">
          {this.state.isDevModeActive ? (
            <div className="text-center">
              <p>
                Currently, it is not possible to upload files via CSV import in a development
                branch.
              </p>
              <p>
                If you want to upload data in a development branch, please do so via{' '}
                <RouterLink to={storageRouteNames.ROOT}>Storage</RouterLink>.
              </p>
              <p>
                However, this configuration can be used to upload data after the development branch
                is merged.
              </p>
            </div>
          ) : (
            <Upload
              disabled={this.state.readOnly}
              key={this.state.localState.get('fileInputKey', 0)}
              onChange={this.state.actions.setFile}
              file={this.state.localState.get('file')}
              isFileBig={this.state.isUploaderFileBig}
              isUploading={this.state.localState.get('isUploading', false)}
              uploadingMessage={this.state.localState.get('uploadingMessage', '')}
              uploadingProgress={this.state.localState.get('uploadingProgress', 0)}
              resultMessage={this.state.localState.get('resultMessage', '')}
              resultState={this.state.localState.get('resultState', '')}
              dismissResult={this.state.actions.dismissResult}
            />
          )}
        </div>
      </div>
    );
  },

  renderSettings() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Upload Settings</h3>
          {!this.state.readOnly && (
            <SaveButtons
              isSaving={this.state.localState.get('isSaving', false)}
              isChanged={this.state.localState.get('isChanged', false)}
              onReset={this.state.actions.editReset}
              onSave={this.state.actions.editSave}
            />
          )}
        </div>
        <div className="box-content">
          <Settings
            settings={this.state.settings}
            onChange={this.state.actions.editChange}
            tables={this.state.tables}
            buckets={this.state.buckets}
            defaultTable={getDefaultTable(this.state.configId)}
            disabled={this.state.readOnly || this.state.localState.get('isSaving', false)}
            destinationEditing={this.state.localState.get('isDestinationEditing', false)}
            onDestinationEdit={this.state.actions.destinationEdit}
          />
        </div>
      </div>
    );
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={COMPONENT_ID} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.config}
          hasFlows={this.state.hasFlows}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.tables)}
        />
        <div className="row">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={COMPONENT_ID}
              configId={this.state.configId}
              placeholderEntity={componentTypes.EXTRACTOR}
            />
            {this.renderUploader()}
            {this.renderSettings()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              hideJobs
              componentId={COMPONENT_ID}
              configId={this.state.configId}
              run={
                <Button
                  block
                  bsStyle="success"
                  disabled={this.isUploadDisabled()}
                  onClick={this.state.actions.startUpload}
                >
                  <FontAwesomeIcon icon="circle-play" className="icon-addon-right" fixedWidth />
                  Upload
                </Button>
              }
            />
          </div>
        </div>
      </>
    );
  },

  isUploadDisabled() {
    return (
      this.state.isDevModeActive ||
      !this.state.localState.get('file') ||
      this.state.localState.get('isChanged', false) ||
      this.state.localState.get('isUploading', false)
    );
  }
});

export default Index;
