import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import Checkbox from '../../../../../react/common/Checkbox';
import OptionalFormLabel from '../../../../../react/common/OptionalFormLabel';
import Select from '../../../../../react/common/Select';

const FileOutputMappingEditor = createReactClass({
  propTypes: {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    isSourceDuplicate: PropTypes.bool.isRequired
  },

  render() {
    return (
      <>
        <FormGroup validationState={this.props.isSourceDuplicate ? 'error' : null}>
          <ControlLabel>File name</ControlLabel>
          <FormControl
            type="text"
            autoFocus
            placeholder="File name"
            value={this.props.value.get('source', '')}
            onChange={this.handleChangeSource}
            disabled={this.props.disabled}
          />
          {this.renderSourceHelpText()}
        </FormGroup>
        <FormGroup>
          <ControlLabel>
            Tags <OptionalFormLabel />
          </ControlLabel>
          <Select
            multi
            allowCreate
            trimMultiCreatedValues
            placeholder="Add tags"
            promptTextCreator={(label) => `Add tag "${label}"`}
            disabled={this.props.disabled}
            value={this.props.value.get('tags', List())}
            onChange={this.handleChangeTags}
          />
          <HelpBlock>The file will be assigned these tags</HelpBlock>
        </FormGroup>
        <FormGroup>
          <Checkbox
            checked={this.props.value.get('is_permanent', false)}
            onChange={this.handleChangeIsPermanent}
            disabled={this.props.disabled}
          >
            Permanent
          </Checkbox>
          <HelpBlock>
            The file will be stored permanently (otherwise it will be deleted after 15 days)
          </HelpBlock>
        </FormGroup>
      </>
    );
  },

  renderSourceHelpText() {
    if (this.props.isSourceDuplicate) {
      return <HelpBlock>The output mapping for this file is already defined</HelpBlock>;
    }

    return (
      <HelpBlock>
        The file will be uploaded from{' '}
        <code>{`out/files/${this.props.value.get('source', '')}`}</code>
      </HelpBlock>
    );
  },

  handleChangeSource(e) {
    return this.props.onChange(this.props.value.set('source', e.target.value.trim()));
  },

  handleChangeTags(tags) {
    return this.props.onChange(this.props.value.set('tags', tags));
  },

  handleChangeIsPermanent(checked) {
    return this.props.onChange(this.props.value.set('is_permanent', checked));
  }
});

export default FileOutputMappingEditor;
