import React from 'react';
import classnames from 'classnames';

const ExternalLink = ({
  href,
  title,
  children,
  className,
  onClick
}: {
  href: string;
  title?: string;
  children: React.ReactNode;
  className?: string | Record<string, boolean>;
  onClick?: (event: React.SyntheticEvent) => void;
}) => (
  <a
    href={href}
    title={title}
    target="_blank"
    rel="noopener noreferrer"
    className={classnames(className)}
    onClick={onClick}
  >
    {children}
  </a>
);

export default ExternalLink;
