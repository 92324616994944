import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';
import _ from 'underscore';

import * as componentFlags from '../../constants/componentFlags';
import { GENERIC_DOCKER_UI_FILE_OUTPUT } from '../../constants/componentFlags';
import {
  KEBOOLA_DBT_TRANSFORMATION,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_SHARED_CODE
} from '../../constants/componentIds';
import { componentTypes } from '../../constants/componentTypes';
import { SIDEBAR } from '../../constants/external';
import { FEATURE_RAW_MODE, FEATURE_SANDBOXES_PYTHON_SPARK } from '../../constants/features';
import dayjs from '../../date';
import {
  features as componentFeatures,
  HIDE_PARALLELISM_BUTTON
} from '../../modules/components/Constants';
import InstalledComponentsActionCreators from '../../modules/components/InstalledComponentsActionCreators';
import ParallelismModal from '../../modules/components/ParallelismModal';
import ComponentConfigurationLink from '../../modules/components/react/components/ComponentConfigurationLink';
import DocumentationLink from '../../modules/components/react/components/DocumentationLink';
import RunComponentButton from '../../modules/components/react/components/RunComponentButton';
import ScheduleConfigurationButton from '../../modules/components/react/components/ScheduleConfigurationButton';
import SidebarJobs from '../../modules/components/react/components/SidebarJobs';
import SidebarVersions from '../../modules/components/react/components/SidebarVersions';
import ComponentsStore from '../../modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '../../modules/components/stores/InstalledComponentsStore';
import VersionsStore from '../../modules/components/stores/VersionsStore';
import { routeNames as componentsRoutes } from '../../modules/components-directory/constants';
import ConfigurationRowsStore from '../../modules/configurations/ConfigurationRowsStore';
import RowVersionsStore from '../../modules/configurations/RowVersionsStore';
import DevBranchesStore from '../../modules/dev-branches/DevBranchesStore';
import LatestJobsStore from '../../modules/jobs/stores/LatestJobsStore';
import NotificationsStore from '../../modules/notifications/store';
import JobsStore from '../../modules/queue/store';
import {
  getRuntimeShortLabel,
  prepareRuntimeLabel,
  prepareRuntimesForTransformation
} from '../../modules/runtimes/helpers';
import RuntimesStore from '../../modules/runtimes/store';
import StackFeaturesStore from '../../modules/stack-features/Store';
import BackendVersionModal from '../../modules/transformations/react/components/backend-version/Modal';
import {
  DBT_REMOTE_TRANSFORMATIONS,
  routeNames as transformationRoutes
} from '../../modules/transformations-v2/constants';
import DeleteConfigurationButton from '../../react/common/DeleteConfigurationButton';
import Gravatar from '../../react/common/Gravatar';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import SwitchStateLink from '../common/ActionControls/SwitchStateLink';
import CopyButton from '../common/ConfigurationsTable/CopyButton';
import ConfirmModal from '../common/ConfirmModal';
import ReadOnlyTooltip, { READ_ONLY_TOOLTIP_MESSAGE } from '../common/ReadOnlyTooltip';
import RouterLink from '../common/RouterLink';
import Tooltip from '../common/Tooltip';
import createStoreMixin from '../mixins/createStoreMixin';

/** @type {any} */
const Sidebar = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      ConfigurationRowsStore,
      DevBranchesStore,
      JobsStore,
      LatestJobsStore,
      VersionsStore,
      NotificationsStore,
      RowVersionsStore,
      RoutesStore
    )
  ],

  propTypes: {
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    rowId: PropTypes.string,
    run: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.shape({
        params: PropTypes.func,
        text: PropTypes.node,
        title: PropTypes.string,
        disabled: PropTypes.string,
        forceModal: PropTypes.bool
      })
    ]),
    additionalPrimaryButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    additionalButtons: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node)
    ]),
    delete: PropTypes.node,
    versionsLinkTo: PropTypes.string,
    hideSchedule: PropTypes.bool,
    hideJobs: PropTypes.bool,
    hideRun: PropTypes.bool,
    hideCopy: PropTypes.bool,
    createdFromConfiguration: PropTypes.instanceOf(Map),
    createdByUser: PropTypes.instanceOf(Map)
  },

  componentDidUpdate(prevProps) {
    if (
      this.props.rowId !== prevProps.rowId ||
      this.props.configId !== prevProps.configId ||
      this.props.componentId !== prevProps.componentId
    ) {
      this.setState(this.getStateFromStores());
    }
  },

  getStateFromStores() {
    const { componentId, configId, rowId } = this.props;
    const component = ComponentsStore.getComponent(this.props.componentId);
    const hasNewQueue = ApplicationStore.hasNewQueue();
    const configuration = InstalledComponentsStore.getConfig(componentId, configId);

    let latestJobs;

    if (hasNewQueue) {
      latestJobs = JobsStore.getLatestJobs(componentId, configId);
    } else {
      latestJobs = rowId
        ? LatestJobsStore.getRowJobs(componentId, configId, rowId)
        : LatestJobsStore.getJobs(componentId, configId);
    }

    const sharedCodes = InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SHARED_CODE)
      .find((config) => config.getIn(['configuration', 'componentId']) === componentId, null, Map())
      .get('rows', List());

    const hasBackflow = !!RoutesStore.getRouterState().getIn(['location', 'state', 'flowId']);
    const allConfigurations = InstalledComponentsStore.getAll();

    return {
      component,
      hasNewQueue,
      configuration,
      latestJobs,
      sharedCodes,
      hasBackflow,
      allConfigurations,
      row: ConfigurationRowsStore.get(componentId, configId, rowId),
      flows: allConfigurations.getIn([KEBOOLA_ORCHESTRATOR, 'configurations'], Map()),
      runtimes: RuntimesStore.getRuntimes(
        DBT_REMOTE_TRANSFORMATIONS.includes(componentId) ? KEBOOLA_DBT_TRANSFORMATION : componentId
      ),
      componentFeatures: component.get('features', List()),
      configurationData: configuration.get('configuration', Map()),
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      notifications: NotificationsStore.getAll(),
      versions: VersionsStore.getVersions(componentId, configId),
      versionsConfigs: VersionsStore.getVersionsConfigs(componentId, configId),
      isLoadingVersions: VersionsStore.isLoadingVersions(componentId, configId),
      isPendingVersions: VersionsStore.isPendingConfig(componentId, configId),
      pendingMultiLoadVersions: VersionsStore.getPendingMultiLoad(componentId, configId),
      rowVersions: RowVersionsStore.getVersions(componentId, configId, rowId),
      rowVersionsConfigs: RowVersionsStore.getVersionsConfigs(componentId, configId, rowId),
      isLoadingRowVersions: RowVersionsStore.isLoadingVersions(componentId, configId, rowId),
      isPendingRowVersions: RowVersionsStore.isPendingConfig(componentId, configId, rowId),
      pendingMultiLoadRowVersions: RowVersionsStore.getPendingMultiLoad(
        componentId,
        configId,
        rowId
      ),
      hasSparkSandboxes:
        StackFeaturesStore.hasStackFeature(FEATURE_SANDBOXES_PYTHON_SPARK) ||
        ApplicationStore.hasCurrentProjectFeature(FEATURE_SANDBOXES_PYTHON_SPARK),
      hasRawMode: ApplicationStore.hasCurrentAdminFeature(FEATURE_RAW_MODE),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      hasFlows: ApplicationStore.hasFlows()
    };
  },

  getInitialState() {
    return {
      isSavingConfigurationRuntimeSafeParam: false,
      isSavingConfigurationRuntimeUseFileStorageOnlyParam: false,
      showFileStorageOnlyConfirmModal: false,
      showDevBranchUnsafeConfirmModal: false,
      showTagOverrideModal: false
    };
  },

  render() {
    return (
      <div className={`sidebar-content ${SIDEBAR}`}>
        <ul className="nav nav-stacked">
          {this.renderRunButton()}
          {this.renderParallelismButton()}
          {this.renderTagOverride()}
          {this.renderDevBranchUnsafeSwitch()}
          {this.renderFileStorageOnlySwitch()}
          {this.renderAdditionalButtons()}
          {this.renderCopyButton()}
          {this.renderScheduleConfigurationButton()}
          {this.renderRawConfigurationButton()}
          {this.renderDeleteConfigurationButton()}
          {this.renderDocumentationButton()}
        </ul>
        {this.renderCreatedByUser()}
        {this.renderCreatedFromConfiguration()}
        {this.renderJobs()}
        {this.renderVersions()}
      </div>
    );
  },

  renderRunButton() {
    if (
      this.props.hideRun ||
      (this.state.component.get('flags', List()).includes(componentFlags.EXCLUDE_RUN) &&
        !this.props.run)
    ) {
      return null;
    }

    if (!this.props.run) {
      return (
        <li>
          <RunComponentButton
            mode="sidebar"
            component={this.props.componentId}
            runParams={this.getRunParams()}
          >
            You are about to run the component.
          </RunComponentButton>
        </li>
      );
    }

    if (React.isValidElement(this.props.run)) {
      return (
        <li>
          <ReadOnlyTooltip readOnly={this.state.readOnly}>{this.props.run}</ReadOnlyTooltip>
          <hr />
        </li>
      );
    }

    return (
      <>
        <li
          className={classnames({
            disabled: !!this.props.run.disabled || this.state.readOnly,
            removeDivider: !!this.props.additionalPrimaryButtons
          })}
        >
          <RunComponentButton
            mode="sidebar"
            component={this.props.componentId}
            title={this.props.run.title}
            runParams={this.getRunParams()}
            disabled={!!this.props.run.disabled}
            disabledReason={this.props.run.disabled}
            forceModal={this.props.run.forceModal}
          >
            {this.props.run.text || 'You are about to run the component.'}
          </RunComponentButton>
        </li>
        {!!this.props.additionalPrimaryButtons && this.renderAdditionalPrimaryButtons()}
      </>
    );
  },

  renderDevBranchUnsafeSwitch() {
    if (
      this.props.rowId ||
      !this.state.isDevModeActive ||
      this.state.hasProtectedDefaultBranch ||
      !this.state.componentFeatures.includes(componentFeatures.DEV_BRANCH_CONFIGURATION_UNSAFE)
    ) {
      return null;
    }

    const isEnabled = this.state.configurationData.getIn(['runtime', 'safe'], false);

    return (
      <li>
        <SwitchStateLink
          label="Safe for run in branch"
          onChange={() =>
            isEnabled
              ? this.toggleDevBranchSafeRun()
              : this.setState({ showDevBranchUnsafeConfirmModal: true })
          }
          isActive={isEnabled}
          isPending={this.state.isSavingConfigurationRuntimeSafeParam}
          readOnly={this.state.readOnly}
        />
        <ConfirmModal
          buttonType="success"
          buttonLabel="Confirm"
          icon={['far', 'code-branch']}
          title="Mark configuration as safe"
          show={this.state.showDevBranchUnsafeConfirmModal}
          onHide={() => this.setState({ showDevBranchUnsafeConfirmModal: false })}
          text={
            <p>
              Are you sure you want to mark this configuration as safe for running in development
              branch?
            </p>
          }
          onConfirm={this.toggleDevBranchSafeRun}
        />
      </li>
    );
  },

  renderTagOverride() {
    const imageTag = this.state.configurationData.getIn(['runtime', 'image_tag'], '');
    const hasRuntimes =
      this.state.componentFeatures.includes(componentFeatures.ALLOW_TAG_OVERRIDE) &&
      !this.state.runtimes.isEmpty();

    if (!imageTag && !hasRuntimes) {
      return null;
    }

    if (!hasRuntimes) {
      return (
        <li>
          <Tooltip
            placement="top"
            tooltip="Configuration has setup custom image tag."
            type="explanatory"
          >
            <Button
              block
              bsStyle="link"
              className="btn-link-inline"
              onClick={() => {
                RoutesStore.getRouter().transitionTo(componentsRoutes.GENERIC_CONFIG_RAW, {
                  component: this.props.componentId,
                  config: this.props.configId
                });
              }}
            >
              <FontAwesomeIcon icon="right-left" fixedWidth />
              Backend Version: <span className="tw-font-medium color-warning">{imageTag}</span>
            </Button>
          </Tooltip>
        </li>
      );
    }

    const runtime = this.state.runtimes.find(
      (runtime) => runtime.get('transformationComponentTag') === imageTag,
      null,
      this.state.runtimes.find((runtime) => runtime.get('isTypeDefault'), null, Map())
    );

    return (
      <li>
        <Tooltip
          placement="top"
          tooltip={
            this.state.readOnly ? READ_ONLY_TOOLTIP_MESSAGE : `${prepareRuntimeLabel(runtime)}`
          }
          type="explanatory"
          forceHide={!this.state.readOnly && runtime.isEmpty()}
        >
          <Button
            block
            bsStyle="link"
            className={classnames({ disabled: this.state.readOnly })}
            onClick={() => !this.state.readOnly && this.setState({ showTagOverrideModal: true })}
          >
            <FontAwesomeIcon icon="right-left" fixedWidth />
            Backend Version:{' '}
            <span className="tw-font-medium">{getRuntimeShortLabel(runtime, imageTag)}</span>
          </Button>
        </Tooltip>
        <BackendVersionModal
          show={this.state.showTagOverrideModal}
          availableVersions={prepareRuntimesForTransformation(this.state.runtimes)}
          onClose={() => this.setState({ showTagOverrideModal: false })}
          onSave={this.overrideImageTag}
          imageTag={imageTag}
        />
      </li>
    );
  },

  renderFileStorageOnlySwitch() {
    if (
      this.props.rowId ||
      !this.state.hasSparkSandboxes ||
      !!this.state.component.getIn(['emptyConfiguration', 'runtime', 'use_file_storage_only']) ||
      !this.state.componentFeatures.includes(componentFeatures.ALLOW_USE_FILE_STORAGE_ONLY) ||
      this.state.component.get('flags', List()).includes(GENERIC_DOCKER_UI_FILE_OUTPUT)
    ) {
      return null;
    }

    const isEnabled = this.state.configurationData.getIn(
      ['runtime', 'use_file_storage_only'],
      false
    );

    return (
      <li>
        <SwitchStateLink
          label="File storage only"
          onChange={() =>
            isEnabled
              ? this.toggleFileStorageOnly()
              : this.setState({ showFileStorageOnlyConfirmModal: true })
          }
          isActive={isEnabled}
          isPending={this.state.isSavingConfigurationRuntimeUseFileStorageOnlyParam}
          readOnly={this.state.readOnly}
        />
        <ConfirmModal
          buttonType="success"
          buttonLabel="Confirm"
          icon="file-lines"
          title="Set file storage only mode"
          show={this.state.showFileStorageOnlyConfirmModal}
          onHide={() => this.setState({ showFileStorageOnlyConfirmModal: false })}
          text={<p>Are you sure you want to turn on file storage mode only?</p>}
          onConfirm={this.toggleFileStorageOnly}
        />
      </li>
    );
  },

  renderAdditionalPrimaryButtons() {
    return (
      <>
        <li className="additionalPrimary">{this.props.additionalPrimaryButtons}</li>
        <hr />
      </>
    );
  },

  renderAdditionalButtons() {
    if (!this.props.additionalButtons) {
      return null;
    }

    if (_.isArray(this.props.additionalButtons)) {
      return this.props.additionalButtons.map((button, index) => {
        return <li key={index}>{button}</li>;
      });
    }

    return <li>{this.props.additionalButtons}</li>;
  },

  renderCopyButton() {
    if (this.state.hasBackflow || this.props.hideCopy || this.props.rowId || this.state.readOnly) {
      return null;
    }

    return (
      <li>
        <CopyButton
          mode="sidebar"
          configuration={this.state.configuration}
          component={this.state.component}
          hasFlows={this.state.hasFlows}
        />
      </li>
    );
  },

  renderScheduleConfigurationButton() {
    if (
      this.state.hasBackflow ||
      this.props.hideSchedule ||
      this.props.rowId ||
      this.state.component.get('flags', List()).includes(componentFlags.EXCLUDE_RUN) ||
      this.state.readOnly ||
      this.state.isDevModeActive
    ) {
      return null;
    }

    return (
      <li>
        <ScheduleConfigurationButton
          flows={this.state.flows}
          component={this.state.component}
          config={this.state.configuration}
          hasNewQueue={this.state.hasNewQueue}
          hasFlows={this.state.hasFlows}
        />
      </li>
    );
  },

  renderRawConfigurationButton() {
    if (!this.state.hasRawMode) {
      return null;
    }

    return (
      <li>
        <RouterLink
          to={
            this.props.rowId
              ? componentsRoutes.GENERIC_CONFIG_ROW_RAW
              : componentsRoutes.GENERIC_CONFIG_RAW
          }
          params={{
            component: this.props.componentId,
            config: this.props.configId,
            row: this.props.rowId
          }}
          className="btn btn-link btn-block btn-link-inline"
        >
          <FontAwesomeIcon icon="bug" fixedWidth />
          Debug Mode
        </RouterLink>
      </li>
    );
  },

  renderParallelismButton() {
    if (
      this.state.configuration.get('rows', List()).isEmpty() ||
      this.props.rowId ||
      !this.state.hasNewQueue ||
      HIDE_PARALLELISM_BUTTON.includes(this.props.componentId)
    ) {
      return null;
    }

    return (
      <li>
        <ParallelismModal
          onChange={this.setParallelism}
          savedValue={this.state.configurationData.getIn(['runtime', 'parallelism'], null)}
          readOnly={this.state.readOnly}
        />
      </li>
    );
  },

  renderDeleteConfigurationButton() {
    if (this.state.readOnly) {
      return null;
    }

    if (this.props.delete) {
      return (
        <li>
          <hr />
          {this.props.delete}
        </li>
      );
    }

    return (
      <li>
        <hr />
        <DeleteConfigurationButton
          config={this.state.configuration}
          componentId={this.props.componentId}
          flows={this.state.flows}
        />
      </li>
    );
  },

  renderDocumentationButton() {
    if (
      !this.state.component.get('documentationUrl') ||
      (this.props.rowId && this.state.component.get('id') !== KEBOOLA_SHARED_CODE) ||
      [
        componentTypes.EXTRACTOR,
        componentTypes.WRITER,
        componentTypes.APPLICATION,
        componentTypes.TRANSFORMATION
      ].includes(this.state.component.get('type'))
    ) {
      return null;
    }

    return (
      <li>
        <DocumentationLink href={this.state.component.get('documentationUrl')} />
      </li>
    );
  },

  renderCreatedByUser() {
    if (!this.props.createdByUser) {
      return null;
    }

    const user = this.props.createdByUser.get('user');
    const admin = this.state.admins.get(user);
    const createdAt = dayjs(this.props.createdByUser.get('createdAt'));

    return (
      <>
        <div className="sidebar-box created-by">
          <h4 className="mb-1">Created by</h4>
          <div className="overflow-break-anywhere">
            {admin ? (
              <>
                <Gravatar className="icon-addon-right" size={14} user={admin} />{' '}
                {admin.get('name') || admin.get('email')}
              </>
            ) : (
              <>
                <FontAwesomeIcon icon="circle-user" className="icon-addon-right" /> {user}
              </>
            )}
          </div>
          <div className="text-muted overflow-break-anywhere">
            <FontAwesomeIcon icon="clock" className="icon-addon-right" /> {createdAt.fromNow()}
          </div>
        </div>
        <hr />
      </>
    );
  },

  renderCreatedFromConfiguration() {
    if (!this.props.createdFromConfiguration || this.props.createdFromConfiguration.isEmpty()) {
      return null;
    }

    return (
      <>
        <div className="sidebar-box created-by">
          <h4 className="mb-1">Created from</h4>
          <ComponentConfigurationLink
            className="overflow-break-anywhere link-inherit no-underline"
            componentId={this.props.createdFromConfiguration.get('componentId')}
            configId={this.props.createdFromConfiguration.get('configId')}
            isDeleted={this.props.createdFromConfiguration.get('isDeleted')}
          >
            {this.props.createdFromConfiguration.get('name')}
          </ComponentConfigurationLink>
        </div>
        <hr />
      </>
    );
  },

  renderJobs() {
    if (this.props.hideJobs) {
      return null;
    }

    return (
      <>
        <SidebarJobs
          jobs={this.state.latestJobs}
          componentId={this.props.componentId}
          configId={this.props.configId}
          rowId={this.props.rowId}
          hasNewQueue={this.state.hasNewQueue}
          allConfigurations={this.state.allConfigurations}
          admins={this.state.admins}
          notifications={this.state.notifications}
          currentAdmin={this.state.currentAdmin}
        />
        <hr />
      </>
    );
  },

  renderVersions() {
    const { configId, rowId, versionsLinkTo } = this.props;

    if (this.props.hideVersions) {
      return null;
    }

    if (rowId) {
      return (
        <SidebarVersions
          configId={configId}
          component={this.state.component}
          config={this.state.configuration}
          row={this.state.row}
          versionsLinkTo={versionsLinkTo || componentsRoutes.GENERIC_CONFIG_ROW_VERSIONS}
          admins={this.state.admins}
          versions={this.state.rowVersions}
          versionsConfigs={this.state.rowVersionsConfigs}
          isLoading={this.state.isLoadingRowVersions}
          isPending={this.state.isPendingRowVersions}
          pendingMultiLoad={this.state.pendingMultiLoadRowVersions}
          rowId={rowId}
          limit={5}
        />
      );
    }

    return (
      <SidebarVersions
        configId={configId}
        component={this.state.component}
        config={this.state.configuration}
        versionsLinkTo={
          versionsLinkTo ||
          (this.state.component.get('type') === componentTypes.TRANSFORMATION
            ? transformationRoutes.GENERIC_TRANSFORMATION_VERSIONS
            : componentsRoutes.GENERIC_CONFIG_VERSIONS)
        }
        admins={this.state.admins}
        versions={this.state.versions}
        versionsConfigs={this.state.versionsConfigs}
        isLoading={this.state.isLoadingVersions}
        isPending={this.state.isPendingVersions}
        pendingMultiLoad={this.state.pendingMultiLoadVersions}
        sharedCodes={this.state.sharedCodes}
      />
    );
  },

  getRunParams() {
    if (this.props.run && this.props.run.params) {
      return this.props.run.params;
    }

    if (this.props.rowId) {
      return () => ({
        config: this.props.configId,
        row: this.props.rowId
      });
    }

    return () => ({ config: this.props.configId });
  },

  overrideImageTag(imageTag) {
    return InstalledComponentsActionCreators.updateComponentConfiguration(
      this.props.componentId,
      this.props.configId,
      {
        configuration: JSON.stringify(
          imageTag
            ? this.state.configurationData.setIn(['runtime', 'image_tag'], imageTag)
            : this.state.configurationData.deleteIn(['runtime', 'image_tag'])
        )
      },
      `${imageTag ? 'Set' : 'Remove'} image override`
    );
  },

  setParallelism(parallelItemsCount) {
    return InstalledComponentsActionCreators.updateComponentConfiguration(
      this.props.componentId,
      this.props.configId,
      {
        configuration: JSON.stringify(
          this.state.configurationData.setIn(['runtime', 'parallelism'], parallelItemsCount)
        )
      },
      `Change number of configuration jobs in parallelisation`
    );
  },

  toggleDevBranchSafeRun() {
    const isEnabled = this.state.configurationData.getIn(['runtime', 'safe'], false);

    this.setState({ isSavingConfigurationRuntimeSafeParam: true });
    InstalledComponentsActionCreators.updateComponentConfiguration(
      this.props.componentId,
      this.props.configId,
      {
        configuration: JSON.stringify(
          isEnabled
            ? this.state.configurationData.deleteIn(['runtime', 'safe'])
            : this.state.configurationData.setIn(['runtime', 'safe'], true)
        )
      },
      `Mark configuration as "${isEnabled ? 'unsafe' : 'safe'}" for run in dev branch.`
    ).finally(() => this.setState({ isSavingConfigurationRuntimeSafeParam: false }));
  },

  toggleFileStorageOnly() {
    const isEnabled = this.state.configurationData.getIn(
      ['runtime', 'use_file_storage_only'],
      false
    );

    this.setState({ isSavingConfigurationRuntimeUseFileStorageOnlyParam: true });
    InstalledComponentsActionCreators.updateComponentConfiguration(
      this.props.componentId,
      this.props.configId,
      {
        configuration: JSON.stringify(
          isEnabled
            ? this.state.configurationData.deleteIn(['runtime', 'use_file_storage_only'])
            : this.state.configurationData.setIn(['runtime', 'use_file_storage_only'], true)
        )
      },
      `"${isEnabled ? 'Disable' : 'Enable'}" file storage only mode.`
    ).finally(() => this.setState({ isSavingConfigurationRuntimeUseFileStorageOnlyParam: false }));
  }
});

export default Sidebar;
