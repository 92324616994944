import ApplicationStore from '../../stores/ApplicationStore';
import SimpleError from '../../utils/errors/SimpleError';
import request from '../../utils/request';

const loadPurchases = () => {
  return request('POST', '/pay-as-you-go/billing/credits')
    .send({
      idProject: ApplicationStore.getCurrentProjectId()
    })
    .promise()
    .then((response) => {
      return response.body;
    });
};

const getPaygBillingConfiguration = () => {
  return request('GET', '/pay-as-you-go/billing/configuration')
    .promise()
    .then((response) => {
      return response.body;
    });
};

const getPaygBillingStripeCustomer = () => {
  return request('GET', '/pay-as-you-go/billing/stripe-customer')
    .query({
      idProject: ApplicationStore.getCurrentProjectId()
    })
    .promise()
    .then((response) => {
      return response.body;
    });
};

const getBillingTopUpSetting = () => {
  return request('GET', `/pay-as-you-go/billing/top-up/${ApplicationStore.getCurrentProjectId()}`)
    .promise()
    .then((response) => {
      return response.body;
    });
};

const updateBillingStripeCustomer = (data) => {
  return request('PUT', '/pay-as-you-go/billing/stripe-customer')
    .send({
      idProject: ApplicationStore.getCurrentProjectId(),
      ...data
    })
    .promise()
    .then((response) => {
      return response.body;
    });
};

const updateBillingTopUp = (data) => {
  return request('PUT', '/pay-as-you-go/billing/top-up')
    .send({ idProject: ApplicationStore.getCurrentProjectId(), ...data })
    .promise()
    .then((response) => {
      return response.body;
    });
};

const paygBillingCreatePaymentRequest = (data) => {
  return request('POST', '/pay-as-you-go/billing/create-payment-request')
    .send({
      idProject: ApplicationStore.getCurrentProjectId(),
      ...data
    })
    .promise()
    .then((response) => {
      return response.body;
    });
};

const createAdminAccountSessionToken = () => {
  return request('POST', '/admin/account/session-token')
    .send()
    .promise()
    .then((response) => {
      // If the response is "302 Found", then response type is "text/html", because backend detected
      // that session expired and redirected user to login screen
      if (response.type === 'text/html') {
        window.location.reload();
        throw new SimpleError('Your session expired', 'You will be logged out automatically.');
      }
      return response.body;
    });
};

export {
  loadPurchases,
  getPaygBillingConfiguration,
  getPaygBillingStripeCustomer,
  getBillingTopUpSetting,
  updateBillingStripeCustomer,
  updateBillingTopUp,
  paygBillingCreatePaymentRequest,
  createAdminAccountSessionToken
};
