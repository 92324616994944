import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import classnames from 'classnames';
import _ from 'underscore';

import CollapseButton from '../../../react/common/CollapseButton';

class Step extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    isConfigured: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLast: PropTypes.bool,
    status: PropTypes.node,
    headerButton: PropTypes.node,
    headerClassName: PropTypes.string,
    paddingless: PropTypes.bool,
    keepOpenAfterConfigured: PropTypes.bool,
    onStartProcessing: PropTypes.func
  };

  static defaultProps = {
    isConfigured: false,
    isProcessing: false,
    isDisabled: false,
    isLast: false,
    paddingless: false,
    keepOpenAfterConfigured: false,
    onStartProcessing: _.noop
  };

  state = {
    show: !this.props.isConfigured || this.props.keepOpenAfterConfigured
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isConfigured && !this.props.isConfigured && !this.state.show) {
      this.setState({ show: true });
    }

    if (!prevProps.isProcessing && this.props.isProcessing) {
      this.props.onStartProcessing();
    }
  }

  render() {
    return (
      <div
        className={classnames('box', {
          disabled: this.props.isDisabled,
          'box-collapsible': this.props.isConfigured
        })}
      >
        <div
          className={classnames(
            'box-header big-padding btn-collapse-area',
            this.props.headerClassName
          )}
          onClick={this.handleToggle}
        >
          <h2 className="box-title flex-container flex-start color-main">{this.props.title}</h2>
          <div className="flex-container" onClick={(event) => event.stopPropagation()}>
            {this.props.status}
            {this.props.headerButton && (
              <div className="ml-auto">
                {React.cloneElement(this.props.headerButton, {
                  isConfigured: this.props.isConfigured
                })}
              </div>
            )}
            <CollapseButton
              entity="step"
              isCollapsed={this.props.isDisabled || !this.state.show}
              isDisabled={this.props.isDisabled}
              onToggle={this.handleToggle}
            />
          </div>
        </div>
        {this.renderDetails()}
      </div>
    );
  }

  renderDetails() {
    if (this.props.isDisabled) {
      return null;
    }

    return (
      <Collapse in={this.state.show}>
        <div>
          <div
            className={classnames({
              'box-content pt-0': !this.props.isLast,
              'p-0': this.props.paddingless
            })}
          >
            {React.cloneElement(this.props.children, {
              isConfigured: this.props.isConfigured
            })}
          </div>
        </div>
      </Collapse>
    );
  }

  handleToggle = () => {
    if (!this.props.isDisabled) {
      this.setState({ show: !this.state.show });
    }
  };
}

export default Step;
