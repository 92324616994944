import React from 'react';

import { REGISTRATION_WIZARD_URL } from '../../constants/KbcConstants';
import ApplicationStore from '../../stores/ApplicationStore';

const CreateProjectBar = () => {
  if (!ApplicationStore.isDemoPreview()) {
    return null;
  }

  return (
    <div className="top-sticky-bar">
      Ready to take control? Start creating your own project!
      <a href={REGISTRATION_WIZARD_URL} className="btn btn-success">
        Create My Project
      </a>
    </div>
  );
};

export default CreateProjectBar;
