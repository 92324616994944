import { fromJS, List, Map } from 'immutable';

import type { Runtime } from '../../api/routes/dataScienceService';
import Dispatcher from '../../Dispatcher';
import StoreUtils, { initStore } from '../../utils/StoreUtils';
import { ActionTypes } from './constants';

export type Store = {
  runtimes: Record<string, Runtime[]>;
};

let _store = initStore('RuntimesStore', Map({ runtimes: Map<string, Runtime>() }));

const RuntimesStore = StoreUtils.createStore({
  getRuntimes(componentId: string) {
    return _store.getIn(['runtimes', componentId], List());
  }
});

Dispatcher.register(
  ({
    action
  }: {
    action: {
      type: string;
      runtimes: Runtime[];
    };
  }) => {
    switch (action.type) {
      case ActionTypes.LOAD_RUNTIMES_SUCCESS:
        _store = _store.set(
          'runtimes',
          fromJS(action.runtimes)
            .filter((runtime: Map<string, any>) => !!runtime.get('transformationComponentId'))
            .groupBy((runtime: Map<string, any>) => runtime.get('transformationComponentId'))
        );
        return RuntimesStore.emitChange();

      default:
        break;
    }
  }
);

export default RuntimesStore;
