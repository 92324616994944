import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import Loader from './Loader';
import Tooltip from './Tooltip';
import VersionsDiffModal from './VersionsDiffModal';

class DiffVersionButton extends React.Component {
  state = {
    showModal: false
  };

  render() {
    return (
      <>
        <Tooltip tooltip="Compare with previous" placement="top">
          <Button
            bsStyle="link"
            className={classnames('text-muted', {
              disabled: this.props.isDisabled || this.props.isPending
            })}
            onClick={this.onHandleClick}
          >
            {this.props.isPending ? <Loader /> : <FontAwesomeIcon icon="right-left" fixedWidth />}
          </Button>
        </Tooltip>
        <VersionsDiffModal
          show={this.state.showModal}
          admins={this.props.admins}
          onClose={() => this.setState({ showModal: false })}
          component={this.props.component}
          config={this.props.config}
          row={this.props.row}
          referentialVersion={this.props.versionConfig}
          compareVersion={this.props.previousVersionConfig}
          sharedCodes={this.props.sharedCodes}
          hideRollback={this.props.hideRollback}
        />
      </>
    );
  }

  onHandleClick = () => {
    if (this.props.isPending || this.props.isDisabled) {
      return;
    }

    this.props.onLoadVersionConfig().then(() => this.setState({ showModal: true }));
  };
}

DiffVersionButton.propTypes = {
  config: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  versionConfig: PropTypes.object.isRequired,
  previousVersionConfig: PropTypes.object.isRequired,
  onLoadVersionConfig: PropTypes.func.isRequired,
  sharedCodes: PropTypes.object.isRequired,
  admins: PropTypes.object.isRequired,
  row: PropTypes.object,
  hideRollback: PropTypes.bool,
  isPending: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default DiffVersionButton;
