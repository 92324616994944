import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Link from '../../../../react/common/RouterLink';
import { parse as parseTable } from '../../../../utils/tableIdParser';
import { routeNames as storageRoutes } from '../../../storage/constants';

const StorageApiTableLink = ({ tableId, query, hash, children, className }) => {
  const parsedTable = parseTable(tableId);

  return (
    <Link
      to={storageRoutes.TABLE}
      className={classnames(className)}
      params={{
        bucketId: `${parsedTable.parts.stage}.${parsedTable.parts.bucket}`,
        tableName: parsedTable.parts.table
      }}
      query={query}
      hash={hash}
    >
      {children}
    </Link>
  );
};

StorageApiTableLink.propTypes = {
  tableId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  query: PropTypes.object,
  hash: PropTypes.string,
  className: PropTypes.string
};

export default StorageApiTableLink;
