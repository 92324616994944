import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import getDatatypeLabel from './getDatatypeLabel';

const DatatypeLabel = createReactClass({
  propTypes: {
    column: PropTypes.string.isRequired,
    datatype: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  },

  renderConvertEmptyValuesToNull() {
    if (!Map.isMap(this.props.datatype)) {
      return null;
    }

    if (this.props.datatype.get('convertEmptyValuesToNull') !== true) {
      return null;
    }

    return (
      <span>
        , convert empty values to <code>null</code>
      </span>
    );
  },

  render() {
    return (
      <span>
        <strong>{this.props.column}</strong> <code>{getDatatypeLabel(this.props.datatype)}</code>
        {this.renderConvertEmptyValuesToNull()}
      </span>
    );
  }
});

export default DatatypeLabel;
