import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import string from '../../../utils/string';
import Tooltip from '../Tooltip';
import MoveActionButton from './MoveActionButton';
import type { SelectedRows } from './Table';

type Props = {
  openDeleteModal: () => void;
  openMoveModal: () => void;
  supportFolders: boolean;
  selectedConfigurations: SelectedRows;
  renderAdditionalMultiActions?: (selectedConfigurations: SelectedRows) => React.ReactNode;
  forceComponentType?: string;
  disableDeleteReason?: string;
};

const ActionButtons = (props: Props) => {
  const selectedConfigurationsCount = props.selectedConfigurations.length;

  return (
    <div className="flex-container flex-start inline-flex">
      {selectedConfigurationsCount === 0 ? (
        'Name'
      ) : (
        <strong>
          {selectedConfigurationsCount}{' '}
          {string.pluralize(
            selectedConfigurationsCount,
            props.forceComponentType || 'configuration'
          )}{' '}
          selected
        </strong>
      )}
      {selectedConfigurationsCount > 0 && (
        <div className="table-action-buttons no-wrap">
          <Tooltip
            placement="top"
            tooltip={props.disableDeleteReason || 'Delete Selected'}
            type={props.disableDeleteReason ? 'explanatory' : 'action'}
          >
            <Button
              bsStyle="link"
              className={classNames('btn-link-inline btn-link-muted', {
                disabled: !!props.disableDeleteReason
              })}
              onClick={() => !props.disableDeleteReason && props.openDeleteModal()}
            >
              <FontAwesomeIcon icon="trash" fixedWidth />
            </Button>
          </Tooltip>
          {props.supportFolders && (
            <MoveActionButton mode="sidebar" onClick={props.openMoveModal} />
          )}
          {props.renderAdditionalMultiActions?.(props.selectedConfigurations)}
        </div>
      )}
    </div>
  );
};

export default ActionButtons;
