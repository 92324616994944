import React from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { JSONEditor } from '@json-editor/json-editor';
import _ from 'underscore';

import ApplicationActionCreators from '../../../actions/ApplicationActionCreators';
import callDockerAction from '../../../modules/components/DockerActionsApi';
import Select from '../../../react/common/Select';
import RoutesStore from '../../../stores/RoutesStore';
import { prepareConfigData } from '../helpers';

const SelectApp = (props) => {
  const [loading, setLoading] = React.useState(false);

  const renderSelect = () => {
    return (
      <Select
        multi
        value={props.value}
        options={props.options}
        isLoading={loading}
        allowCreate={props.tags}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onChange={(selected) => props.onChange(selected.toJS())}
      />
    );
  };

  if (props.async && props.async.action) {
    return (
      <InputGroup>
        {renderSelect()}
        <InputGroup.Button>
          <Button
            className="ml-1"
            disabled={loading}
            onClick={() => {
              const componentId = RoutesStore.getCurrentRouteComponentId();
              const configId = RoutesStore.getCurrentRouteParam('configId');
              const rowId = RoutesStore.getCurrentRouteParam('row');

              setLoading(true);
              return callDockerAction(componentId, props.async.action, {
                configData: prepareConfigData(componentId, configId, rowId)
              })
                .then((response) => {
                  if (response?.status === 'error') {
                    return ApplicationActionCreators.sendNotification({
                      type: 'error',
                      message: response.message ?? 'Data cannot be loaded.'
                    });
                  }

                  if (!Array.isArray(response)) {
                    return;
                  }

                  props.onAsyncOptionsLoaded(response);
                })
                .finally(() => setLoading(false));
            }}
          >
            {props.async.label ?? 'Load'}
          </Button>
        </InputGroup.Button>
      </InputGroup>
    );
  }

  return renderSelect();
};

export default class ReactMultiSelectPlugin extends JSONEditor.defaults.editors.multiselect {
  renderReactSelect() {
    if (this.root) {
      this.root.render(
        <SelectApp
          disabled={this.disabled}
          tags={!!this.options.tags}
          placeholder={this.options?.inputAttributes?.placeholder}
          value={this.value}
          options={this.option_keys.map((value, index) => {
            return { value, label: this.option_enum[index]?.title ?? value };
          })}
          onChange={(selected) => {
            this.updateValue(selected);
            this.onChange(true);
            this.renderReactSelect();
          }}
          onAsyncOptionsLoaded={(options) => {
            this.option_keys = [];
            this.option_enum = [];

            options.forEach((option, index) => {
              this.option_keys[index] = option.value;
              this.option_enum[index] = { title: option.label };
            });

            this.renderReactSelect();
            this.jsoneditor?.notifyWatchers(this.path);
          }}
          async={this.options.async}
        />
      );
    }
  }

  preBuild() {
    if (this.hasWatchedEnumSource()) {
      this.enumSource = this.schema.items.enumSource;
      this.schema.watch = this.schema.items.watch;
    }

    super.preBuild();

    if (this.option_keys.length === 0 && this.schema?.default?.length > 0) {
      this.option_keys = this.schema.default;
    }
  }

  afterInputReady() {
    // create new react-select root
    const selectRoot = window.document.createElement('span');

    // add new react-select root before existing select
    this.selectNode = this.input.parentNode.insertBefore(selectRoot, this.input);

    // hide old select
    this.input.style.display = 'none';

    // create react root
    this.root = createRoot(selectRoot);

    // render react-select
    this.renderReactSelect();

    // remove original event listener, not needed
    this.control.removeEventListener('change', this.multiselectChangeHandler);

    super.afterInputReady();
  }

  onWatchedFieldChange() {
    if (this.hasWatchedEnumSource()) {
      this.setupWatchedOptions();
      this.renderReactSelect();
    }

    super.onWatchedFieldChange();
  }

  updateValue(value) {
    if (_.isEqual(this.value, value)) {
      return false;
    }

    this.value = value;
    return true;
  }

  enable() {
    // turn off readonly after save
    if (!this.always_disabled) {
      this.selectNode
        ?.querySelector('.react-select__control')
        ?.classList.remove('react-select__control--is-disabled');
    }

    super.enable();
  }

  disable(alwaysDisabled) {
    // turn on readonly during save
    this.selectNode
      ?.querySelector('.react-select__control')
      ?.classList.add('react-select__control--is-disabled');

    super.disable(alwaysDisabled);
  }

  destroy() {
    setTimeout(() => {
      this.root?.unmount();
      this.root = null;
      this.input.previousSibling?.remove();
    });

    super.destroy();
  }

  setupWatchedOptions() {
    this.option_keys = [];
    this.option_enum = [];

    // loop watched editors and set values as select options
    Object.entries(this.watched).map(([name, path]) => {
      const editor = this.jsoneditor.getEditor(path);

      if (editor && this.watched_values[name]) {
        this.watched_values[name].forEach((value, index) => {
          const titleIndex = editor.option_keys?.findIndex((option) => option === value) ?? -1;
          this.option_keys[index] = value;
          this.option_enum[index] = titleIndex !== -1 ? editor.option_enum[titleIndex] : value;
        });
      }
    });
  }

  hasWatchedEnumSource() {
    return !!(this.schema.items.enumSource && this.schema.items.watch);
  }

  showValidationErrors(errors) {
    if (!!this.options?.async?.action || this.options?.tags) {
      return null;
    }

    super.showValidationErrors(errors);
  }
}
