import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { DEVELOPERS_DOCUMENTATION_URL } from '../../../../../constants/KbcConstants';
import Checkbox from '../../../../../react/common/Checkbox';
import CopyPrimaryKeyButton from '../../../../../react/common/CopyPrimaryKeyButton';
import DestinationTableSelector from '../../../../../react/common/DestinationTableSelector';
import ExternalLink from '../../../../../react/common/ExternalLink';
import OptionalFormLabel from '../../../../../react/common/OptionalFormLabel';
import Select from '../../../../../react/common/Select';
import whereOperatorConstants from '../../../../../react/common/whereOperatorConstants';
import string from '../../../../../utils/string';
import tableIdParser from '../../../../../utils/tableIdParser';
import { STAGE } from '../../../../storage/constants';
import { ioType } from '../../../Constants';
import { supportsWriteAlways } from './helpers';

const TableOutputMappingEditor = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    componentId: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    sourceType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    defaultBucketName: PropTypes.string.isRequired,
    defaultTableName: PropTypes.string.isRequired,
    simple: PropTypes.bool
  },

  getInitialState() {
    return { overwriteDestination: false };
  },

  _parseDestination() {
    return tableIdParser.parse(this.props.value.get('destination'), {
      defaultStage: STAGE.OUT,
      defaultBucket: this.props.defaultBucketName
    });
  },

  _handleFocusSource() {
    if (!this._parseDestination().parts.table) {
      return this.setState({ overwriteDestination: true });
    }
  },

  prepareDestinationFromSource(value) {
    let sourceValue = value;
    if (!this.state.overwriteDestination) {
      return null;
    }
    const isFileMapping = true; // generic components always use file system
    const lastDotIdx = sourceValue.lastIndexOf('.');
    if (isFileMapping && lastDotIdx > 0) {
      sourceValue = sourceValue.substring(0, lastDotIdx);
    }
    const dstParser = this._parseDestination();
    const webalizedSourceValue = string.webalize(sourceValue, { caseSensitive: true });
    const newDestination = dstParser.setPart('table', webalizedSourceValue);
    return newDestination.tableId;
  },

  _handleChangeSource(e) {
    const newSource = e.target.value.trim();
    const newDestination = this.prepareDestinationFromSource(newSource);
    let newMapping = this.props.value;
    if (newDestination) {
      newMapping = this.updateMappingWithDestination(newDestination);
    }
    newMapping = newMapping.set('source', newSource);
    return this.props.onChange(newMapping);
  },

  updateMappingWithDestination(newDestination) {
    let value = this.props.value.set('destination', newDestination.trim());
    if (this.props.tables.get(value.get('destination'))) {
      value = value.set(
        'primary_key',
        this.props.tables.getIn([value.get('destination'), 'primaryKey'], List())
      );
    }
    return value;
  },

  _handleChangeDestination(newValue) {
    return this.props.onChange(this.updateMappingWithDestination(newValue));
  },

  _updateDestinationPart(partName, value) {
    return this._handleChangeDestination(this._parseDestination().setPart(partName, value).tableId);
  },

  _handleChangeIncremental(checked) {
    let value;
    if (checked) {
      value = this.props.value
        .set('incremental', checked)
        .set('delete_where_column', '')
        .set('delete_where_operator', 'eq')
        .set('delete_where_values', List());
    } else {
      value = this.props.value
        .delete('incremental')
        .delete('delete_where_column')
        .delete('delete_where_operator')
        .delete('delete_where_values');
    }
    return this.props.onChange(value);
  },

  _handleChangePrimaryKey(newValue) {
    const value = this.props.value.set('primary_key', newValue);
    return this.props.onChange(value);
  },

  _handleChangeDeleteWhereValues(newValue) {
    const value = this.props.value.set('delete_where_values', newValue);
    return this.props.onChange(value);
  },

  _getTablesAndBuckets() {
    const tablesAndBuckets = this.props.tables.merge(this.props.buckets);

    const inOut = tablesAndBuckets.filter(
      (item) => item.get('id').substr(0, 3) === 'in.' || item.get('id').substr(0, 4) === 'out.'
    );

    const map = inOut.sortBy((item) => item.get('id')).map((item) => item.get('id'));

    return map.toList();
  },

  _getColumnsOptions() {
    const columns = this.props.tables
      .find((table) => table.get('id') === this.props.value.get('destination'), null, Map())
      .get('columns', List())
      .toArray();

    const currentValue = this.props.value.get('delete_where_column', '');

    if (currentValue && !columns.includes(currentValue)) {
      columns.push(currentValue);
    }

    return columns.map((option) => {
      return {
        label: option,
        value: option
      };
    });
  },

  render() {
    if (this.props.simple) {
      return this.renderDestinationInput();
    }

    return (
      <>
        <FormGroup>
          <ControlLabel>
            {this.props.sourceType === ioType.TABLE ? 'Table name' : 'File name'}
          </ControlLabel>
          <FormControl
            type="text"
            name="source"
            autoFocus
            value={this.props.value.get('source', '')}
            disabled={this.props.disabled}
            placeholder={this.props.sourceType === ioType.TABLE ? 'Table name' : 'File name'}
            onFocus={this._handleFocusSource}
            onBlur={() => this.setState({ overwriteDestination: false })}
            onChange={this._handleChangeSource}
          />
          {this.renderSourceHelpText()}
        </FormGroup>
        {this.renderAllInputs()}
      </>
    );
  },

  renderSourceHelpText() {
    if (this.props.sourceType === ioType.FILE) {
      return (
        <HelpBlock>
          The file will be uploaded from{' '}
          <code>{`out/tables/${this.props.value.get('source', '')}`}</code>
        </HelpBlock>
      );
    }

    return (
      <HelpBlock>
        Please note Keboola is case sensitive, you most likely should use UPPERCASE name of the
        table in the output mapping.
      </HelpBlock>
    );
  },

  renderPrimaryKeyHelpText() {
    const sourcePrimaryKey = this.props.tables.getIn(
      [this.props.value.get('destination'), 'primaryKey'],
      List()
    );

    if (this.props.value.get('primary_key', List()).equals(sourcePrimaryKey)) {
      return null;
    }

    return (
      <>
        The primary key must match the primary key in the existing table.{' '}
        <CopyPrimaryKeyButton
          sourcePrimaryKey={sourcePrimaryKey}
          onCopy={this._handleChangePrimaryKey}
        />
      </>
    );
  },

  renderDestinationInput() {
    return (
      <FormGroup>
        <ControlLabel>Destination</ControlLabel>
        <DestinationTableSelector
          currentSource={this.props.value.get('source')}
          updatePart={this._updateDestinationPart}
          defaultBucketName={this.props.defaultBucketName}
          defaultTableName={this.props.defaultTableName}
          parts={this._parseDestination().parts}
          tables={this.props.tables}
          buckets={this.props.buckets}
          disabled={this.props.disabled}
        />
        <HelpBlock>
          The Storage table where the source-file data will be loaded. You can create a new table or
          use an existing one.
        </HelpBlock>
      </FormGroup>
    );
  },

  renderWriteAlways() {
    if (this.props.sourceType !== ioType.TABLE || !supportsWriteAlways(this.props.componentId)) {
      return null;
    }

    return (
      <FormGroup>
        <Checkbox
          disabled={this.props.disabled}
          checked={this.props.value.get('write_always', false)}
          onChange={(checked) => this.props.onChange(this.props.value.set('write_always', checked))}
        >
          Write Always
        </Checkbox>
        <HelpBlock>
          The table will be uploaded to Storage, even if the job fails. Read more in the{' '}
          <ExternalLink
            href={`${DEVELOPERS_DOCUMENTATION_URL}/extend/common-interface/config-file/#output-mapping--write-even-if-the-job-fails`}
          >
            documentation
          </ExternalLink>
          .
        </HelpBlock>
      </FormGroup>
    );
  },

  renderAllInputs() {
    const columnsOptions = this._getColumnsOptions();

    return (
      <>
        {this.renderDestinationInput()}
        <FormGroup>
          <Checkbox
            checked={this.props.value.get('incremental', false)}
            disabled={this.props.disabled}
            onChange={this._handleChangeIncremental}
          >
            Incremental
          </Checkbox>
          <HelpBlock>
            If the destination table exists in Storage, output mapping does not overwrite the table,
            it only appends the data to it. It uses an incremental write to Storage.
          </HelpBlock>
        </FormGroup>
        {this.renderWriteAlways()}
        <hr />
        <FormGroup>
          <ControlLabel>
            Primary Key{' '}
            {this.props.tables
              .getIn([this.props.value.get('destination'), 'primaryKey'], List())
              .isEmpty() && <OptionalFormLabel />}
          </ControlLabel>
          <Select
            name="primary_key"
            value={this.props.value.get('primary_key')}
            multi
            disabled={this.props.disabled}
            allowCreate={columnsOptions.length === 0}
            placeholder="Add a column to the primary key"
            noResultsText="No matching column found"
            onChange={this._handleChangePrimaryKey}
            options={columnsOptions}
          />
          <HelpBlock>{this.renderPrimaryKeyHelpText()}</HelpBlock>
        </FormGroup>
        {(this.props.value.get('incremental') ||
          this.props.value.get('delete_where_column', '') !== '') && (
          <FormGroup>
            <ControlLabel>
              Delete Rows <OptionalFormLabel />
            </ControlLabel>
            <div className="select-group">
              <Select
                allowCreate
                options={columnsOptions}
                placeholder="Select a column"
                value={this.props.value.get('delete_where_column', '')}
                onChange={(value) => {
                  this.props.onChange(this.props.value.set('delete_where_column', value));
                }}
                promptTextCreator={(label) => (label ? 'Select the "' + label + '" column' : '')}
                disabled={this.props.disabled}
              />
              <Select
                clearable={false}
                searchable={false}
                value={this.props.value.get('delete_where_operator')}
                disabled={this.props.disabled}
                onChange={(value) => {
                  this.props.onChange(this.props.value.set('delete_where_operator', value));
                }}
                options={[
                  {
                    label: whereOperatorConstants.EQ_LABEL,
                    value: whereOperatorConstants.EQ_VALUE
                  },
                  {
                    label: whereOperatorConstants.NOT_EQ_LABEL,
                    value: whereOperatorConstants.NOT_EQ_VALUE
                  }
                ]}
              />
            </div>
            <Select
              name="deleteWhereValues"
              value={this.props.value.get('delete_where_values')}
              multi
              disabled={this.props.disabled}
              allowCreate
              placeholder="Add a value"
              emptyStrings
              onChange={this._handleChangeDeleteWhereValues}
            />
            <HelpBlock className="bottom-margin">
              Delete matching rows in the destination table before importing the result
            </HelpBlock>
          </FormGroup>
        )}
      </>
    );
  }
});

export default TableOutputMappingEditor;
