import React from 'react';
import { Label } from 'react-bootstrap';
import classnames from 'classnames';

const ExternalBucketLabel = (props: { hasExternalSchema: boolean; className?: string }) => {
  if (!props.hasExternalSchema) {
    return null;
  }

  return (
    <Label bsStyle="primary" className={classnames('label-rounded uppercase', props.className)}>
      External
    </Label>
  );
};

export default ExternalBucketLabel;
