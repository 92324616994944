import {
  KDS_TEAM_EX_NETSUITE,
  KDS_TEAM_EX_PROGRESS_DB,
  KEBOOLA_EX_DB_MSSQL,
  KEBOOLA_EX_DB_MSSQL_CDATA,
  KEBOOLA_EX_DB_MYSQL,
  KEBOOLA_EX_DB_NETSUITE,
  KEBOOLA_EX_DB_ORACLE,
  KEBOOLA_EX_DB_PGSQL,
  KEBOOLA_EX_DB_REDSHIFT
} from '../../../constants/componentIds';

// templates by componentId for codemirror editor mode, default is mysql
export default (componentId) => {
  switch (componentId) {
    case KEBOOLA_EX_DB_PGSQL:
      return 'text/x-pgsql';

    case KEBOOLA_EX_DB_REDSHIFT:
    case KDS_TEAM_EX_PROGRESS_DB:
      return 'text/x-sql';

    case KEBOOLA_EX_DB_MYSQL:
      return 'text/x-mysql';

    case KEBOOLA_EX_DB_MSSQL:
    case KEBOOLA_EX_DB_MSSQL_CDATA:
      return 'text/x-mssql';

    case KEBOOLA_EX_DB_ORACLE:
    case KEBOOLA_EX_DB_NETSUITE:
    case KDS_TEAM_EX_NETSUITE:
      return 'text/x-plsql';

    default:
      return 'text/x-mysql';
  }
};
