import React from 'react';

import dayjs from '../../date';
import { calculateDuration, timeInWords } from '../../utils/duration';
import Timer from '../../utils/Timer';

const DurationUntillNow = (props: { startTime: string }) => {
  const [endTime, setEndTime] = React.useState(dayjs().format());

  React.useEffect(() => {
    const increaseEndTime = () => setEndTime(dayjs().format());

    Timer.poll(increaseEndTime, { interval: 1 });
    return () => {
      Timer.stop(increaseEndTime);
    };
  }, []);

  return <>{timeInWords(calculateDuration(props.startTime, endTime))}</>;
};

export default DurationUntillNow;
