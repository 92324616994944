import { Map } from 'immutable';

import { KEBOOLA_EX_ONEDRIVE } from '../../constants/componentIds';
import SyncActionError from '../../utils/errors/SyncActionError';
import callDockerAction from '../components/DockerActionsApi';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import AuthStore from '../oauth-v2/Store';

const getCredentials = (configId) => {
  const configData = InstalledComponentsStore.getConfigData(KEBOOLA_EX_ONEDRIVE, configId);
  const credentials = AuthStore.getCredentials(KEBOOLA_EX_ONEDRIVE, configData) || Map();

  return Map({
    appKey: credentials.get('appKey'),
    '#data': credentials.get('#data'),
    '#appSecret': credentials.get('#appSecret')
  });
};

const searchFile = (configId, search) => {
  const params = {
    configData: Map()
      .setIn(['parameters', 'workbook', 'search'], search)
      .setIn(['authorization', 'oauth_api', 'credentials'], getCredentials(configId))
      .toJS()
  };

  return callDockerAction(KEBOOLA_EX_ONEDRIVE, 'search', params).then((result) => {
    if (result.status && result.status === 'error') {
      throw new SyncActionError(
        result.message || 'An error occurred while searching files',
        result.exceptionId
      );
    }
    return result;
  });
};

const searchWorkspace = (configId, driveId, fileId) => {
  const params = {
    configData: Map()
      .setIn(['parameters', 'workbook', 'fileId'], fileId)
      .setIn(['parameters', 'workbook', 'driveId'], driveId)
      .setIn(['authorization', 'oauth_api', 'credentials'], getCredentials(configId))
      .toJS()
  };

  return callDockerAction(KEBOOLA_EX_ONEDRIVE, 'getWorksheets', params).then((result) => {
    if (result.status && result.status === 'error') {
      throw new SyncActionError(
        result.message || 'An error occurred while loading worksheets',
        result.exceptionId
      );
    }
    return result;
  });
};

export { searchFile, searchWorkspace };
