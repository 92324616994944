const REDSHIFT_COMPRESSION_OPTIONS = [
  'RAW',
  'RUNLENGTH',
  'BYTEDICT',
  'LZO',
  'ZSTD',
  'DELTA',
  'MOSTLY8'
];

export { REDSHIFT_COMPRESSION_OPTIONS };
