import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';

class PhaseNameModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        onEnter={() => {
          this.setState({ name: this.props.phase.get('name') });
        }}
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Rename</Modal.Title>
            <ModalIcon.Edit />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                autoFocus
                type="text"
                placeholder="Type a name"
                value={this.state.name}
                onChange={(event) => this.setState({ name: event.target.value })}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel="Rename"
              isDisabled={this.state.name.trim() === ''}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  onHide() {
    this.props.onHide();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onChangeName(this.props.phase.get('id'), this.state.name);
    this.onHide();
  }
}

PhaseNameModal.propTypes = {
  phase: PropTypes.instanceOf(Map).isRequired,
  onHide: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default PhaseNameModal;
