import { KEBOOLA_VARIABLES } from '../constants/componentIds';
import { hasGenericTemplatesUI, hasGenericVariablesUI } from '../modules/components/helpers';
import InstalledComponentsActionCreators from '../modules/components/InstalledComponentsActionCreators';
import StorageActionCreators from '../modules/components/StorageActionCreators';
import ComponentsStore from '../modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '../modules/components/stores/InstalledComponentsStore';
import SchemasActionsCreators from '../modules/components/TemplatesActionCreators';
import VersionsActionCreators from '../modules/components/VersionsActionCreators';
import { loadNotifications } from '../modules/notifications/actions';
import * as OauthUtils from '../modules/oauth-v2/OauthUtils';

const configRequiredData = (
  componentId: string,
  configId: string,
  onConfigDataLoad?: (configData: Map<string, any>) => Promise<any>
) => {
  const component = ComponentsStore.getComponent(componentId);

  return Promise.all([
    loadNotifications(),
    StorageActionCreators.loadBucketsAndTables(),
    InstalledComponentsActionCreators.loadComponentConfigData(componentId, configId)
      .tap(() => OauthUtils.loadCredentialsFromConfig(componentId, configId))
      .tap(() => onConfigDataLoad?.(InstalledComponentsStore.getConfigData(componentId, configId))),
    InstalledComponentsActionCreators.loadComponentsMetadata(componentId, configId),
    VersionsActionCreators.loadVersions(componentId, configId),
    hasGenericTemplatesUI(component) && SchemasActionsCreators.loadSchema(componentId),
    hasGenericVariablesUI() &&
      InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_VARIABLES)
  ]);
};

export { configRequiredData };
