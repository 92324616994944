import {
  KDS_TEAM_EX_AWS_COST_AND_USAGE_REPORTS,
  KEBOOLA_DBT_TRANSFORMATION,
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
  KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_WR_DB_SNOWFLAKE
} from '../../constants/componentIds';

export const routeNames = { UPGRADE_PAGE: 'spc-upgrade' };

export const componentsUnavailableInTrial = [
  KEBOOLA_WR_DB_SNOWFLAKE,
  KDS_TEAM_EX_AWS_COST_AND_USAGE_REPORTS,
  KEBOOLA_DBT_TRANSFORMATION,
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
  KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION
];
