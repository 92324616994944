import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import memoizeOne from 'memoize-one';

import Select from '../../../../react/common/Select';
import { tableLabel } from '../../../../react/common/selectLabels';
import { filterProductionAndCurrentDevBranchTables } from '../../../dev-branches/helpers';
import { STAGE } from '../../../storage/constants';
import {
  isLoadingColumnMetadata,
  tableDisplayNameWithBucketAndStage,
  tableName
} from '../../../storage/helpers';

const prepareOptions = memoizeOne((buckets, tables, allowedBuckets, excludeTableFn) => {
  return filterProductionAndCurrentDevBranchTables(tables, buckets)
    .filter((table) => {
      return (
        allowedBuckets.includes(table.getIn(['bucket', 'stage'])) &&
        !excludeTableFn(table.get('id'), table)
      );
    })
    .sortBy((table) => tableDisplayNameWithBucketAndStage(table))
    .map((table) => ({
      value: table.get('id'),
      label: tableLabel(table, {
        className: { 'text-muted': !tables.has(table.get('id')) }
      }),
      name: tableName(table)
    }))
    .toArray();
});

class SapiTableSelector extends React.PureComponent {
  render() {
    const isLoading = isLoadingColumnMetadata(this.props.tables);

    return (
      <Select
        autoFocus={this.props.autoFocus}
        disabled={this.props.disabled || isLoading}
        isLoading={isLoading}
        clearable={this.props.clearable}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.handleChange}
        options={prepareOptions(
          this.props.buckets,
          this.props.tables,
          this.props.allowedBuckets,
          this.props.excludeTableFn
        )}
      />
    );
  }

  handleChange = (tableId) => {
    this.props.onSelectTableFn(tableId, this.props.tables.get(tableId));
  };
}

SapiTableSelector.propTypes = {
  tables: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  onSelectTableFn: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  excludeTableFn: PropTypes.func,
  allowedBuckets: PropTypes.array,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  autoFocus: PropTypes.bool
};

SapiTableSelector.defaultProps = {
  excludeTableFn: () => false,
  allowedBuckets: Object.values(STAGE),
  disabled: false,
  autoFocus: false,
  clearable: false
};

export default SapiTableSelector;
