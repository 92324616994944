import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ConfirmModal from './ConfirmModal';
import Loader from './Loader';
import Tooltip from './Tooltip';

const ResetAutomaticLoadTypeButton = createReactClass({
  propTypes: {
    onClick: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      isPending: false,
      showModal: false
    };
  },

  render() {
    return (
      <>
        {this.renderButton()}
        <ConfirmModal
          closeAfterResolve
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          icon="arrow-rotate-left"
          title="Reset Automatic Incremental Load"
          text={
            <p>
              This will clear the information about automatic incremental load. Next execution will
              load all data in the table.
            </p>
          }
          buttonLabel="Reset Automatic Incremental Load"
          buttonType="success"
          onConfirm={this.clearState}
          isLoading={this.state.isPending}
        />
      </>
    );
  },

  renderButton() {
    if (this.state.isPending) {
      return (
        <Tooltip
          tooltip="Clears information about automatic incremental load."
          placement="top"
          type="explanatory"
        >
          <Button bsStyle="link" className="btn-link-inline disabled">
            Reset <Loader />
          </Button>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        tooltip="Clears information about automatic incremental load."
        placement="top"
        type="explanatory"
      >
        <Button
          bsStyle="link"
          className="btn-link-inline"
          onClick={() => this.setState({ showModal: true })}
        >
          Reset
        </Button>
      </Tooltip>
    );
  },

  clearState() {
    this.setState({ isPending: true });
    return this.props.onClick().catch((error) => {
      this.setState({ isPending: false });
      throw error;
    });
  }
});

export default ResetAutomaticLoadTypeButton;
