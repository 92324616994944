import Promise from 'bluebird';

import dispatcher from '../../Dispatcher';
import { HTTP_STATUS_CODE_NOT_FOUND } from '../../utils/errors/helpers';
import {
  getBillingTopUpSetting,
  getPaygBillingConfiguration,
  getPaygBillingStripeCustomer,
  loadPurchases,
  paygBillingCreatePaymentRequest,
  updateBillingStripeCustomer,
  updateBillingTopUp
} from '../admin/api';
import { ActionTypes } from '../admin/constants';
import BillingApi from './BillingApi';
import { ActionTypes as BillingActionTypes } from './constants';
import { parseBillingInformation } from './helpers';
import store from './store';

const getPurchases = () => {
  if (store.getPurchases().isEmpty()) {
    getPurchasesForce();
    return Promise.resolve();
  }

  return getPurchasesForce();
};

const getPurchasesForce = () => {
  return loadPurchases().then((data) => {
    dispatcher.handleViewAction({
      type: ActionTypes.PAYG_BILLING_GET_PURCHASES_SUCCESS,
      purchases: data
    });
  });
};

const getBillingConfiguration = () => {
  getPaygBillingConfiguration().then((data) => {
    dispatcher.handleViewAction({
      type: ActionTypes.PAYG_BILLING_GET_CONFIGURATION_SUCCESS,
      configuration: data
    });
  });
};

const getCreditsForce = () => {
  dispatcher.handleViewAction({ type: BillingActionTypes.BILLING_CREDITS_LOAD_START });
  return BillingApi.getCredits()
    .then((data) => {
      dispatcher.handleViewAction({
        type: BillingActionTypes.BILLING_CREDITS_LOAD_SUCCESS,
        credits: data
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({ type: BillingActionTypes.BILLING_CREDITS_LOAD_ERROR });
      throw error;
    });
};

const getCredits = () => {
  if (store.getRemainingCredits() > 0) {
    getCreditsForce();
    return Promise.resolve();
  }

  return getCreditsForce();
};

const getBillingInformationForce = () => {
  return getPaygBillingStripeCustomer().then((data) => {
    dispatcher.handleViewAction({
      type: ActionTypes.PAYG_BILLING_GET_BILLING_INFORMATION_SUCCESS,
      billingInformation: data
    });
  });
};

const getBillingInformation = () => {
  if (!store.getBillingInformation().isEmpty()) {
    getBillingInformationForce();
    return Promise.resolve();
  }

  return getBillingInformationForce();
};

const updateBillingInformation = (data) => {
  return updateBillingStripeCustomer(data).then((data) => {
    dispatcher.handleViewAction({
      type: ActionTypes.PAYG_BILLING_GET_BILLING_INFORMATION_SUCCESS,
      billingInformation: data
    });
  });
};

const getTopUpSetting = () => {
  if (store.isTopUpSettingLoaded()) {
    getTopUpSettingForce();
    return Promise.resolve();
  }

  return getTopUpSettingForce();
};

const getTopUpSettingForce = () => {
  return getBillingTopUpSetting()
    .then((data) => {
      dispatcher.handleViewAction({
        type: ActionTypes.PAYG_BILLING_GET_TOP_UP_SETTING_SUCCESS,
        setting: data
      });
    })
    .catch((error) => {
      // api returns 404 if no top-up was ever configured
      if (error?.response?.status === HTTP_STATUS_CODE_NOT_FOUND) {
        dispatcher.handleViewAction({
          type: ActionTypes.PAYG_BILLING_GET_TOP_UP_SETTING_SUCCESS,
          setting: null
        });

        return;
      }

      throw error;
    });
};

const updateTopUp = (data) => {
  return updateBillingTopUp(data).then(getTopUpSetting);
};

const removeCard = (cardId) => {
  return updateBillingStripeCustomer({
    ...parseBillingInformation(store.getBillingInformation()),
    dropCardId: cardId
  }).then((data) => {
    dispatcher.handleViewAction({
      type: ActionTypes.PAYG_BILLING_GET_BILLING_INFORMATION_SUCCESS,
      billingInformation: data
    });
  });
};

const createPaymentRequest = (data) => {
  return paygBillingCreatePaymentRequest(data).then((response) => response.clientSecret);
};

export {
  getPurchases,
  getCredits,
  getCreditsForce,
  getBillingConfiguration,
  getBillingInformation,
  updateBillingInformation,
  getTopUpSetting,
  updateTopUp,
  createPaymentRequest,
  removeCard
};
