import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

import Checkbox from '../../../react/common/Checkbox';
import Select from '../../../react/common/Select';
import string from '../../../utils/string';
import { nameWarning } from '../../storage/constants';

class Destination extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Destination</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <Col componentClass={ControlLabel} xs={4}>
              Storage Table Name
            </Col>
            <Col xs={8}>
              <FormControl
                type="text"
                value={this.props.value.storage}
                onChange={(e) => {
                  this.props.onChange({ storage: string.sanitizeKbcTableIdString(e.target.value) });
                }}
                placeholder="mytable"
                disabled={this.props.disabled}
              />
              <HelpBlock>Name of the table stored in Storage. {nameWarning}</HelpBlock>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col xs={8} xsOffset={4}>
              <Checkbox
                checked={this.props.value.incremental}
                onChange={(checked) => this.props.onChange({ incremental: checked })}
                disabled={this.props.disabled}
              >
                Incremental Load
              </Checkbox>
              <HelpBlock>
                If incremental load is turned on, the table will be updated instead of rewritten.{' '}
                Tables with a primary key will have rows updated, tables without a primary key will
                have rows appended.
              </HelpBlock>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col xs={4} componentClass={ControlLabel}>
              Primary Key
            </Col>
            <Col xs={8}>
              <Select
                value={this.props.value.primary_key}
                multi
                allowCreate
                placeholder="Add a column to the primary key"
                onChange={(value) => {
                  this.props.onChange({ primary_key: value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                If a primary key is set, updates can be done on the table by selecting{' '}
                <strong>incremental loads</strong>. The primary key can consist of multiple columns.
                The primary key of an existing table cannot be changed.
              </HelpBlock>
            </Col>
          </FormGroup>
        </div>
      </div>
    );
  }
}

Destination.propTypes = {
  value: PropTypes.shape({
    storage: PropTypes.string.isRequired,
    incremental: PropTypes.bool.isRequired,
    primary_key: PropTypes.array.isRequired
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default Destination;
