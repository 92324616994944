import CodeMirror from 'codemirror';

function dialogDiv(cm, template, wrapperId = '') {
  var cmWrap = cm.getWrapperElement();
  var dialogWrap = cmWrap
    .closest('.CodeMirror-search-dialog-root')
    .querySelector('.CodeMirror-search-dialog-wrapper');
  var dialog;

  // Remove existing search
  var wrapperEl = document.getElementById(wrapperId);
  if (wrapperEl && dialogWrap.contains(wrapperEl)) {
    dialogWrap.removeChild(wrapperEl);
  }

  dialog = dialogWrap.insertBefore(
    document.createElement('div'),
    dialogWrap.querySelector('div.btn-toolbar')
  );
  dialog.className = 'CodeMirror-dialog';
  dialog.id = wrapperId;

  if (typeof template == 'string') {
    dialog.innerHTML = template;
  } else {
    // Assuming it's a detached DOM element.
    dialog.appendChild(template);
  }
  CodeMirror.addClass(cmWrap, 'dialog-opened');
  return dialog;
}

CodeMirror.defineExtension('openDialog', function (template, callback, options = {}) {
  var dialog = dialogDiv(this, template, options.wrapperId);
  var me = this;

  var inp = dialog.getElementsByTagName('input')[0],
    button;
  if (inp) {
    if (options.value) {
      inp.value = options.value;
      if (options.selectValueOnOpen !== false) {
        inp.select();
      }
    }

    if (options.onInput)
      CodeMirror.on(inp, 'input', function (e) {
        options.onInput(e, inp.value);
      });

    if (options.onKeyUp)
      CodeMirror.on(inp, 'keyup', function (e) {
        options.onKeyUp(e, inp.value);
      });

    CodeMirror.on(inp, 'keydown', function (e) {
      if (options && options.onKeyDown && options.onKeyDown(e, inp.value)) {
        return;
      }

      if (e.keyCode == 13) callback(inp.value, e);
    });
  } else if ((button = dialog.getElementsByTagName('button')[0])) {
    CodeMirror.on(button, 'click', function () {
      close();
      me.focus();
    });

    if (options.closeOnBlur !== false) CodeMirror.on(button, 'blur', close);

    button.focus();
  }
  return close;
});
