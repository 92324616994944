import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import CredentialsActionCreators from '../../../provisioning/ActionCreators';
import SnowflakeCredentials from '../../../provisioning/react/components/SnowflakeCredentials';
import SnowflakeSandboxCredentialsStore from '../../../provisioning/stores/SnowflakeSandboxCredentialsStore';

const SnowflakeCredentialsContainer = createReactClass({
  mixins: [createStoreMixin(SnowflakeSandboxCredentialsStore)],

  propTypes: {
    isAutoLoad: PropTypes.bool.isRequired
  },

  componentDidMount() {
    if (!this.state.credentials.get('id') && this.props.isAutoLoad) {
      CredentialsActionCreators.createSnowflakeSandboxCredentials();
    }
  },

  getStateFromStores() {
    return {
      credentials: SnowflakeSandboxCredentialsStore.getCredentials(),
      pendingActions: SnowflakeSandboxCredentialsStore.getPendingActions()
    };
  },

  render() {
    return (
      <SnowflakeCredentials
        credentials={this.state.credentials}
        isCreating={this.state.pendingActions.get('create')}
      />
    );
  }
});

export default SnowflakeCredentialsContainer;
