import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfirmModal from '../../../../react/common/ConfirmModal';
import Loader from '../../../../react/common/Loader';

class SaveButton extends React.Component {
  state = {
    modalOpen: false
  };

  render() {
    return (
      <>
        {this.props.isChanged && (
          <ButtonToolbar>
            <Button onClick={this.props.onReset} disabled={this.props.isSaving}>
              <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
              Reset
            </Button>
            <Button
              bsStyle="success"
              disabled={this.props.disabled || this.props.isSaving}
              onClick={this.onSaveButtonClick}
            >
              {this.props.isSaving ? <Loader /> : 'Save'}
            </Button>
          </ButtonToolbar>
        )}
        {this.renderModal()}
      </>
    );
  }

  renderModal() {
    return (
      <ConfirmModal
        buttonLabel="Save"
        icon="floppy-disk"
        title="Save Parameters"
        text={
          <p>
            The changes in the configuration are not compatible with the original visual editor.
            Saving this configuration will disable the visual representation of the whole
            configuration and you will be able to edit the configuration in code editor only.
          </p>
        }
        onHide={this.onHide}
        onConfirm={this.onSave}
        show={this.state.modalOpen}
      />
    );
  }

  onSaveButtonClick = () => {
    if (!this.props.showModal) {
      return this.props.onSave();
    }

    this.setState({ modalOpen: true });
  };

  onSave = () => {
    this.onHide();
    this.props.onSave();
  };

  onHide = () => {
    this.setState({ modalOpen: false });
  };
}

SaveButton.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  isChanged: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showModal: PropTypes.bool
};

SaveButton.defaultProps = {
  disabled: false,
  showModal: false
};

export default SaveButton;
