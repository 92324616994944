import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TABLE_COLUMNS_ORDER } from '../../../constants/localStorageKeys';
import Tooltip from '../../../react/common/Tooltip';
import * as localStorage from '../../../utils/localStorage';

const DataSampleColumnOrderInfo = (props: { tableId: string; onResetColumnsOrder: () => void }) => {
  const localStorageKey = `${TABLE_COLUMNS_ORDER}-${props.tableId}`;
  const columnOrder = localStorage.getItem(localStorageKey);

  if (!columnOrder) {
    return null;
  }

  return (
    <div className="flex-container flex-start inline-flex">
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip="Columns are reordered only in the Data Sample preview. They are not reordered in the original table."
      >
        <span className="f-12 cursor-help dashed-underline border-color-inherit icon-addon-right">
          Columns are reordered
        </span>
      </Tooltip>
      <Tooltip placement="top" tooltip="Reset column order">
        <Button
          bsStyle="link"
          className="text-muted btn-link-inline icon-addon-right icon-addon-left"
          onClick={() => {
            localStorage.removeItem(localStorageKey);
            props.onResetColumnsOrder();
          }}
        >
          <FontAwesomeIcon icon="arrow-rotate-left" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default DataSampleColumnOrderInfo;
