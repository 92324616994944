import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import Checkbox from '../../../../react/common/Checkbox';

const PreferencesHeader = createReactClass({
  propTypes: {
    showAdvanced: PropTypes.bool.isRequired,
    onChangeShowAdvanced: PropTypes.func.isRequired
  },

  render() {
    return (
      <Checkbox
        checked={this.props.showAdvanced}
        onChange={() => this.props.onChangeShowAdvanced(!this.props.showAdvanced)}
      >
        Show Identifiers
      </Checkbox>
    );
  }
});

export default PreferencesHeader;
