import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import Tooltip from '../../../react/common/Tooltip';
import { canManageBucket, canUnlinkBucket } from '../../admin/privileges';

class DeleteUnlinkBucketMenuItem extends React.Component {
  render() {
    return (
      <MenuItem
        eventKey="delete"
        onSelect={() => this.props.onSelect()}
        disabled={this.isDisabled()}
      >
        {this.renderLabel()}
      </MenuItem>
    );
  }

  hasPermissions() {
    if (this.props.isLinked) {
      return canUnlinkBucket(this.props.token, this.props.bucket);
    }

    return canManageBucket(this.props.token, this.props.bucket);
  }

  isDisabled() {
    return !this.hasPermissions() || (this.props.linkedBucketsCount > 0 && !this.props.isLinked);
  }

  renderLabel() {
    if (this.props.linkedBucketsCount > 0) {
      return (
        <Tooltip tooltip="Please unlink linked buckets first." placement="top" type="explanatory">
          <span>
            <FontAwesomeIcon icon="trash" fixedWidth className="icon-addon-right" />
            Delete bucket
          </span>
        </Tooltip>
      );
    }

    if (!this.hasPermissions()) {
      if (this.props.isLinked) {
        return (
          <Tooltip
            tooltip="You don't have permission to unlink bucket."
            placement="top"
            type="explanatory"
          >
            <span>
              <FontAwesomeIcon icon="link-slash" fixedWidth className="icon-addon-right" />
              Unlink bucket
            </span>
          </Tooltip>
        );
      }

      return (
        <Tooltip
          tooltip="You don't have permission to delete this bucket."
          placement="top"
          type="explanatory"
        >
          <span>
            <FontAwesomeIcon icon="trash" fixedWidth className="icon-addon-right" />
            Delete bucket
          </span>
        </Tooltip>
      );
    }

    if (this.props.isLinked) {
      return (
        <span>
          <FontAwesomeIcon icon="link-slash" fixedWidth className="icon-addon-right" />
          Unlink bucket
        </span>
      );
    }

    return (
      <span>
        <FontAwesomeIcon icon="trash" fixedWidth className="icon-addon-right" />
        Delete bucket
      </span>
    );
  }
}

DeleteUnlinkBucketMenuItem.propTypes = {
  bucket: PropTypes.instanceOf(Map).isRequired,
  token: PropTypes.instanceOf(Map).isRequired,
  linkedBucketsCount: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  isLinked: PropTypes.bool.isRequired
};

export default DeleteUnlinkBucketMenuItem;
