import React from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type Promise from 'bluebird';

import Loader from './Loader';
import ModalIcon from './ModalIcon';

type Props = {
  show: boolean;
  onSave: () => Promise<void>;
  onLeave: () => void;
  onHide: () => void;
  text?: React.ReactNode;
  leaveLabel?: string;
  isSaveDisabled?: boolean;
};

const CatchUnsavedChangesModal = ({
  show,
  onLeave,
  onSave,
  onHide,
  text,
  isSaveDisabled,
  leaveLabel
}: Props) => {
  const [isSaving, setIsSaving] = React.useState(false);

  return (
    <Modal bsSize="sm" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-md">Unsaved changes</Modal.Title>
        <ModalIcon icon="square-exclamation" color="orange" bold />
      </Modal.Header>
      <Modal.Body>
        <div className="max-w-350">
          {text || (
            <p>
              You have unsaved changes! If you leave, your unsaved changes will be discarded and
              your work will be lost.
            </p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar className="block">
          <Button onClick={onLeave}>{leaveLabel || 'Leave without saving'}</Button>
          {isSaveDisabled ? (
            <Button bsStyle="success" className="disabled">
              <FontAwesomeIcon icon="circle-check" className="icon-addon-right" fixedWidth />
              Save changes
            </Button>
          ) : (
            <Button
              disabled={isSaving}
              bsStyle="success"
              onClick={() => {
                setIsSaving(true);
                onSave().finally(() => setIsSaving(false));
              }}
            >
              {isSaving ? (
                <Loader className="icon-addon-right" />
              ) : (
                <FontAwesomeIcon icon="circle-check" className="icon-addon-right" fixedWidth />
              )}
              Save changes
            </Button>
          )}
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
};

export default CatchUnsavedChangesModal;
