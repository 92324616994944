import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import { componentTypes } from '../../../constants/componentTypes';
import RowActionMenuItem from '../RowActionMenuItem';
import Tooltip from '../Tooltip';

type Props = {
  onClick: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
} & ({ mode: 'sidebar' } | { mode: 'menuitem'; component: Map<string, any>; hasFlows: boolean });

const MoveActionButton = (props: Props) => {
  if (props.mode === 'menuitem') {
    return (
      <RowActionMenuItem onSelect={props.onClick} onKeyDown={props.onKeyDown}>
        <FontAwesomeIcon fixedWidth icon="folder-arrow-up" />
        Move{' '}
        {props.component.get('type') === componentTypes.TRANSFORMATION
          ? 'Transformation'
          : props.component.get('id') === KEBOOLA_ORCHESTRATOR
          ? props.hasFlows
            ? 'Flow'
            : 'Orchestration'
          : 'Configuration'}
      </RowActionMenuItem>
    );
  }

  return (
    <Tooltip placement="top" tooltip="Move Selected">
      <Button bsStyle="link" className="btn-link-inline btn-link-muted" onClick={props.onClick}>
        <FontAwesomeIcon icon="folder-arrow-up" />
      </Button>
    </Tooltip>
  );
};

export default MoveActionButton;
