import React from 'react';
import type { Map } from 'immutable';

import LastUseCell from './LastUseCell';
import type { TableRow } from './Table';

const LastUseActionCell = (props: {
  admins: Map<string, any>;
  row: TableRow;
  renderAction: (row: TableRow, body: React.ReactNode) => React.ReactNode;
}) => {
  return props.renderAction(
    props.row,
    <LastUseCell
      admins={props.admins}
      job={props.row.values.job}
      component={props.row.values.data.component}
    />
  );
};

const MemoizedLastUseActionCell = React.memo(LastUseActionCell);

export default MemoizedLastUseActionCell;
