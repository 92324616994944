import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const DescriptionButton = (props: {
  onClick: () => void;
  isFilled: boolean;
  readOnly?: boolean;
  showsInModalOnly?: boolean;
}) => {
  if (props.readOnly && !props.isFilled) return null;

  return (
    <Button onClick={props.onClick}>
      <FontAwesomeIcon
        icon={props.isFilled ? 'note-sticky' : ['far', 'note-sticky']}
        className={classnames('btn-icon', { 'color-primary': props.isFilled })}
      />
      {props.isFilled ? (props.showsInModalOnly || props.readOnly ? 'Show' : 'Edit') : 'Add'}{' '}
      Description
    </Button>
  );
};

export default DescriptionButton;
