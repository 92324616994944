import React from 'react';
import PropTypes from 'prop-types';

import dayjs from '../../date';

const ValidUntil = ({ validUntil }) => {
  const validUntilDate = dayjs(validUntil);

  if (!validUntilDate.isValid()) {
    return null;
  }

  if (validUntilDate < dayjs()) {
    return <span>any time now</span>;
  }

  return <span>{validUntilDate.fromNow()}</span>;
};

ValidUntil.propTypes = {
  validUntil: PropTypes.string
};

export default ValidUntil;
