import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Label, Well } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { startsWith } from 'underscore.string';

import Loader from '../../../react/common/CustomLoader';
import GraphCanvas from '../../../react/common/GraphCanvas';
import Select from '../../../react/common/Select';
import graphUtils from '../../../utils/graphUtils';
import StorageExplorerApi from '../api';

const TableGraph = createReactClass({
  propTypes: {
    table: PropTypes.object.isRequired
  },

  graphRef: null,

  getInitialState() {
    return {
      data: null,
      error: null,
      direction: this.getDefaultDirection(),
      loading: false
    };
  },

  componentDidMount() {
    this.loadData();
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.direction !== prevState.direction) {
      this.loadData();
    }

    this.initGraph();
  },

  componentWillUnmount() {
    this.cancellablePromise && this.cancellablePromise.cancel();
  },

  render() {
    return (
      <div className="box">
        <div className="box-content clearfix">
          {this.state.error ? (
            this.renderError()
          ) : (
            <>
              {this.renderControls()}
              {this.renderGraph()}
            </>
          )}
        </div>
      </div>
    );
  },

  renderGraph() {
    if (this.state.loading) {
      return <Loader show={this.state.loading} text="Loading data..." loaderSize="2x" />;
    }

    if (!this.hasValidData()) {
      return <p>There are no connections for the table {this.props.table.get('displayName')}.</p>;
    }

    return (
      <>
        <div ref={(node) => (this.graphRef = node)} />
        {this.renderLegend()}
        {this.renderNote()}
      </>
    );
  },

  renderLegend() {
    return (
      <Well>
        <Label bsStyle="success">Input</Label>
        <Label className="label-black">Transformation</Label>
        <Label bsStyle="primary">Output</Label>
      </Well>
    );
  },

  renderControls() {
    if (this.state.loading || !this.state.data) {
      return null;
    }

    return (
      <div className="pull-right ml-2">
        <Select
          clearable={false}
          className="w-200"
          value={this.state.direction}
          options={[
            { value: 'forward', label: 'Forward' },
            { value: 'backward', label: 'Backward' }
          ]}
          onChange={this.handleChangeDirection}
        />
      </div>
    );
  },

  renderError() {
    return (
      <>
        <Alert bsStyle="warning">{this.state.error}</Alert>
        <Button bsStyle="link" className="btn-link-inline" onClick={this.loadData}>
          Try again.
        </Button>
      </>
    );
  },

  renderNote() {
    return (
      <Alert bsStyle="info">
        Please note that the graph shows a maximum of 7 levels of nesting.
      </Alert>
    );
  },

  loadData() {
    this.setState({ error: null, loading: true });
    this.cancellablePromise = StorageExplorerApi.loadGraph(
      this.props.table.get('id'),
      this.state.direction
    )
      .then((data) => {
        data.nodes = graphUtils.addLinksToNodes(data.nodes);
        this.setState({ data });
        return null;
      })
      .catch((error) => {
        if (error.response && error.response.body && error.response.body.message) {
          this.setState({ error: error.response.body.message });
        } else {
          this.setState({ error: 'An error occurred while fetching data.' });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  },

  handleChangeDirection(value) {
    this.setState({ direction: value });
  },

  getDefaultDirection() {
    return startsWith(this.props.table.get('id'), 'in.') ? 'forward' : 'backward';
  },

  hasValidData() {
    return !!(this.state.data && this.state.data.transitions.length > 0);
  },

  initGraph() {
    if (this.state.loading || !this.hasValidData()) {
      return;
    }

    if (this.graphRef) {
      this.graph = new GraphCanvas({}, this.graphRef);
    }

    if (this.graph) {
      this.graph.data = this.state.data;
      this.graph.spacing = 1;
      this.graph.styles = graphUtils.styles();
      this.graph.render();
    }
  }
});

export default TableGraph;
