import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, HelpBlock } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import Loader from '../../../../react/common/Loader';
import { supportSplitLoading } from '../../helpers';

const ColumnLoaderQueryEditor = createReactClass({
  propTypes: {
    query: PropTypes.object.isRequired,
    componentId: PropTypes.string.isRequired,
    isLoadingColumns: PropTypes.bool.isRequired,
    isLoadingSourceTables: PropTypes.bool.isRequired,
    columnSelector: PropTypes.object.isRequired,
    refreshMethod: PropTypes.func.isRequired
  },

  render() {
    if (this.props.isLoadingColumns) {
      return (
        <FormControl.Static>
          <Loader /> Fetching list of columns...
        </FormControl.Static>
      );
    }

    return (
      <div>
        {this.props.columnSelector}
        {this.showReloader() && (
          <HelpBlock>
            Not seeing all columns?{' '}
            <Button bsStyle="link" className="btn-link-inline" onClick={this.props.refreshMethod}>
              Reload
            </Button>{' '}
            the list of columns.
          </HelpBlock>
        )}
      </div>
    );
  },

  showReloader() {
    return (
      this.props.query.hasIn(['table', 'tableName']) &&
      supportSplitLoading(this.props.componentId) &&
      !this.props.isLoadingSourceTables
    );
  }
});

export default ColumnLoaderQueryEditor;
