import React from 'react';

const BucketSharedLabel = (props: { isSharing: boolean }) => {
  if (!props.isSharing) {
    return null;
  }

  return <span className="label label-rounded label-purple reverse font-semi-bold">Shared</span>;
};

export default BucketSharedLabel;
