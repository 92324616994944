import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import _ from 'underscore';

import CircleIcon from '../../../react/common/CircleIcon';
import NoResultsFound from '../../../react/common/NoResultsFound';
import Link from '../../../react/common/RouterLink';
import Truncated from '../../../react/common/Truncated';
import StorageApiBucketLinkEx from '../../components/react/components/StorageApiBucketLinkEx';
import { bucketDisplayNameWithStage } from '../../storage/helpers';
import { getDescriptionWithDefaultValue } from '../helpers';
import LinkButton from './LinkButton';
import UnlinkButton from './UnlinkButton';

class SharedBuckets extends React.Component {
  render() {
    if (this.props.query && !this.props.sharedBuckets.count()) {
      return <NoResultsFound entityName="shared buckets" />;
    }

    return (
      <div className="box-container two-columns">
        {this.props.sharedBuckets
          .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
          .map((bucket) => {
            const linkedBucket = bucket.get('linkedBucket');
            return (
              <div
                className="box box-panel"
                key={`${bucket.getIn(['project', 'id'])}-${bucket.get('id')}`}
              >
                <div className="box-header with-border with-big-icon">
                  <div className="flex-container mr-1">
                    <CircleIcon
                      icon="folder"
                      generatedBackground={bucketDisplayNameWithStage(bucket)}
                    />
                    <h2 className="box-title pl-1">
                      <div className="flex-container flex-start">
                        <Link
                          to="shared-with-you-detail"
                          params={{
                            projectId: bucket.getIn(['project', 'id']),
                            bucketId: bucket.get('id')
                          }}
                        >
                          <Truncated text={bucket.get('displayName')} />
                        </Link>
                        {linkedBucket && (
                          <span className="no-shrink text-muted plp-2"> used as </span>
                        )}
                      </div>
                      {linkedBucket && (
                        <>
                          <StorageApiBucketLinkEx
                            bucketId={linkedBucket.get('id')}
                            truncateBucketName
                          />
                          <br />
                        </>
                      )}
                      <small className="text-muted">by {bucket.getIn(['project', 'name'])}</small>
                    </h2>
                  </div>
                  {linkedBucket ? (
                    <UnlinkButton size="sm" token={this.props.token} bucket={linkedBucket} />
                  ) : (
                    <LinkButton
                      size="sm"
                      buckets={this.props.allBuckets}
                      token={this.props.token}
                      bucket={bucket}
                    />
                  )}
                </div>
                <div className="box-panel-content overflow-break-anywhere">
                  <p className="box-description-header">About Data Bucket</p>
                  <p>{getDescriptionWithDefaultValue(bucket)}</p>
                </div>
              </div>
            );
          })
          .toArray()}
        {this.renderEmptyBoxes()}
      </div>
    );
  }

  renderEmptyBoxes() {
    const sharedBucketsCount = this.props.sharedBuckets.count();

    if ((this.props.query && !sharedBucketsCount) || sharedBucketsCount > 8) {
      return null;
    }

    return _.times(8 - sharedBucketsCount, (index) => {
      return index === 0 && sharedBucketsCount === 0 ? (
        <div className="box box-panel empty" key={`empty-${index}`}>
          <h3 className="mt-2">Why is this place empty?</h3>
          <p className="text-muted mb-1">
            Looks like you just started a new project or your team members did not share any data
            with you.
          </p>
        </div>
      ) : (
        <div className="box box-panel empty" key={`empty-${index}`} />
      );
    });
  }
}

SharedBuckets.propTypes = {
  token: PropTypes.instanceOf(Map).isRequired,
  allBuckets: PropTypes.instanceOf(Map).isRequired,
  sharedBuckets: PropTypes.instanceOf(Map).isRequired,
  query: PropTypes.string
};

export default SharedBuckets;
