import React from 'react';

import { KEBOOLA_EX_MONGODB as componentId } from '../../constants/componentIds';
import { configPoll, rowPoll } from '../../utils/genericPolls';
import { configRequiredData } from '../../utils/genericRequiredData';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '../configurations/ConfigurationRowsStore';
import RowVersionsActionCreators from '../configurations/RowVersionsActionCreators';
import ExDbQueryHeaderButtons from './react/components/QueryActionButtons';
import ExDbQueryName from './react/components/QueryName';
import ExDbCredentialsPage from './react/pages/credentials/CredentialsPage';
import ExDbIndex from './react/pages/index/Index';
import ExDbQueryDetail from './react/pages/query-detail/QueryDetail';
import RowDetail from './react/pages/row/Detail';
import RowDetailHeader from './react/pages/row/RowDetailHeader';
import * as actionsProvisioning from './actionsProvisioning';
import * as storeProvisioning from './storeProvisioning';

const routes = {
  name: componentId,
  path: `${componentId}/:config`,
  requireData: [(params) => configRequiredData(componentId, params.config)],
  title: (routerState) => {
    const configId = routerState.getIn(['params', 'config']);
    return InstalledComponentsStore.getConfig(componentId, configId).get('name');
  },
  poll: configPoll(componentId),
  defaultRouteHandler: ExDbIndex(componentId),
  childRoutes: [
    {
      name: componentId + '-row',
      path: 'rows/:row',
      title: (routerState) => {
        const configId = routerState.getIn(['params', 'config']);
        const rowId = routerState.getIn(['params', 'row']);
        return ConfigurationRowsStore.get(componentId, configId, rowId).get('name');
      },
      poll: rowPoll(componentId),
      requireData: [
        (params) => RowVersionsActionCreators.loadVersions(componentId, params.config, params.row)
      ],
      defaultRouteHandler: RowDetail,
      headerButtonsHandler: RowDetailHeader
    },
    {
      name: 'ex-mongodb-query',
      path: 'query/:query',
      title: (routerState) => {
        const configId = routerState.getIn(['params', 'config']);
        const queryId = routerState.getIn(['params', 'query']);
        const ExDbStore = storeProvisioning.createStore(componentId, configId);
        const query = ExDbStore.getConfigQuery(queryId);
        return query ? `Export ${query.get('name')}` : 'Untitled Export';
      },
      nameEdit: (params) => {
        const EditComponent = ExDbQueryName(storeProvisioning);

        return (
          <EditComponent
            key={`${componentId}-${params.config}-${params.query}`}
            configId={params.config}
            queryId={params.query}
          />
        );
      },
      defaultRouteHandler: ExDbQueryDetail(componentId, actionsProvisioning, storeProvisioning),
      headerButtonsHandler: ExDbQueryHeaderButtons(
        componentId,
        actionsProvisioning,
        storeProvisioning
      )
    },
    {
      name: 'ex-mongodb-credentials',
      path: 'credentials',
      title: () => {
        return 'Credentials';
      },
      defaultRouteHandler: ExDbCredentialsPage(componentId, actionsProvisioning, storeProvisioning)
    }
  ]
};

export default routes;
