import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import Checkbox from '../../../../../react/common/Checkbox';
import GraphCanvas from '../../../../../react/common/GraphCanvas';
import graphUtils from '../../../../../utils/graphUtils';

const Graph = createReactClass({
  propTypes: {
    model: PropTypes.object.isRequired,
    centerNodeId: PropTypes.string,
    showDisabled: PropTypes.bool.isRequired,
    disabledTransformation: PropTypes.bool.isRequired,
    showDisabledHandler: PropTypes.func.isRequired
  },

  graphRef: null,

  _modelData() {
    const model = this.props.model.toJS();
    model.nodes = graphUtils.addLinksToNodes(model.nodes);
    return model;
  },

  _renderGraph() {
    this.graph.data = this._modelData();
    this.graph.direction = 'regular';
    this.graph.spacing = 1;
    this.graph.styles = graphUtils.styles();
    return this.graph.render(this.props.centerNodeId);
  },

  componentDidMount() {
    this.graph = new GraphCanvas({}, this.graphRef);
    this._renderGraph();
  },

  componentDidUpdate() {
    this._renderGraph();
  },

  render() {
    return (
      <div>
        <div>
          <div className="graph-options">
            <span className="pull-right checkbox">
              {this.props.disabledTransformation ? (
                <small>Showing all disabled transformations</small>
              ) : (
                <Checkbox
                  onChange={this.props.showDisabledHandler}
                  checked={this.props.showDisabled}
                >
                  Show disabled transformations
                </Checkbox>
              )}
            </span>
          </div>
        </div>
        <div>
          <div className="svg" />
          <div id="canGraph" ref={(node) => (this.graphRef = node)} />
        </div>
      </div>
    );
  }
});

export default Graph;
