import React from 'react';
import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '../../../configurations/ConfigurationRowsStore';

const QueryName = function (componentId, storeProvisioning) {
  return createReactClass({
    mixins: [createStoreMixin(InstalledComponentsStore, ConfigurationRowsStore), immutableMixin],

    propTypes: {
      configId: PropTypes.string.isRequired,
      queryId: PropTypes.string.isRequired
    },

    getStateFromStores() {
      const ExDbStore = storeProvisioning.createStore(componentId, this.props.configId);
      const query = ExDbStore.isEditingQuery(this.props.queryId)
        ? ExDbStore.getEditingQuery(this.props.queryId)
        : ExDbStore.getConfigQuery(this.props.queryId);

      return {
        name: query ? query.get('name') : null
      };
    },

    render() {
      if (this.state.name) {
        return <span>{this.state.name}</span>;
      } else {
        return <span className="text-muted">Untitled Query</span>;
      }
    }
  });
};

export default QueryName;
