const COMPONENT_ID = 'keboola.ex-email-attachments';
import { Map } from 'immutable';
import _ from 'underscore';

import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
export const storeMixins = [InstalledComponentsStore];

export default function (configId) {
  const config = InstalledComponentsStore.getConfig(COMPONENT_ID, configId);
  const localState = InstalledComponentsStore.getLocalState(COMPONENT_ID, configId);
  const configData = InstalledComponentsStore.getConfigData(COMPONENT_ID, configId);
  const settings = localState.get('settings', configData.get('parameters', Map()));

  return {
    settings,
    config,
    configData,
    processors: configData.get('processors', Map()),
    error: localState.get('error'),
    requestedEmail: settings.get('email'),
    getLocalState(path) {
      if (_.isEmpty(path)) {
        return localState || Map();
      }
      return localState.getIn([].concat(path), Map());
    }
  };
}
