import React from 'react';
import { Table } from 'react-bootstrap';
import { useExpanded, useSortBy, useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { List, Map } from 'immutable';

import Checkbox from '../../../react/common/Checkbox';
import Loader from '../../../react/common/Loader';
import MarkedText from '../../../react/common/MarkedText';
import SortIcon from '../../../react/common/SortIcon';
import Truncated from '../../../react/common/Truncated';
import hasSelections from '../../../utils/hasSelections';
import { getProfileName } from '../helpers';

const every = (rows: any[], predicate: (row: any) => boolean): boolean => {
  return rows.every((row: any) => {
    return row.subRows.length > 0 ? every(row.subRows, predicate) : predicate(row);
  });
};

const some = (rows: any[], predicate: (row: any) => boolean): boolean => {
  return rows.some((row: any) => {
    return row.subRows.length > 0 ? some(row.subRows, predicate) : predicate(row);
  });
};

const ProfilesSelector = ({
  isLoading,
  profiles,
  savedProfiles,
  selectedProfiles,
  searchQuery,
  selectProfiles
}: {
  isLoading: boolean;
  profiles: Map<string, any>;
  savedProfiles: Map<string, any>;
  selectedProfiles: List<any>;
  searchQuery: string;
  selectProfiles: (profiles: any, selected: boolean) => void;
}) => {
  const isSaved = React.useCallback(
    (row: any) => savedProfiles.some((profile) => row.values.data === getProfileName(profile)),
    [savedProfiles]
  );
  const isSelected = React.useCallback(
    (row: any) => selectedProfiles.includes(row.values.data),
    [selectedProfiles]
  );

  const prepareRowProps = React.useCallback(
    (rows: any[]) => {
      const prepareProfiles = (rows: any[]): any[] => {
        return rows.reduce((acc, row) => {
          return row.subRows.length > 0
            ? [...acc, ...prepareProfiles(row.subRows)]
            : isSaved(row)
            ? acc
            : [...acc, row.values.data];
        }, []);
      };

      const isSelectable = some(rows, (row: any) => !isSaved(row));
      const isAllSelected =
        isSelectable && every(rows, (row: any) => isSelected(row) || isSaved(row));
      const isSomeSelected = !isAllSelected && isSelectable && some(rows, isSelected);

      return {
        isSelectable,
        checkboxProps: {
          checked: isAllSelected,
          indeterminate: isSomeSelected,
          onChange: () => selectProfiles(prepareProfiles(rows), !isAllSelected && !isSomeSelected)
        }
      };
    },
    [isSaved, isSelected, selectProfiles]
  );

  const columns = React.useMemo(() => {
    return [
      {
        accessor: 'data',
        Header: ({ rows }: any) => {
          const { isSelectable, checkboxProps } = prepareRowProps(rows);

          return (
            <>
              {isSelectable && <Checkbox className="tw-mr-3" {...checkboxProps} />}
              Profiles
            </>
          );
        },
        Cell: ({ row }: any) => {
          const isProfile = row.depth === 2;
          const { isSelectable, checkboxProps } = prepareRowProps(isProfile ? [row] : row.subRows);

          return (
            <div
              className={classNames('flex-container flex-start', {
                'tw-ml-4': row.depth === 1,
                'tw-ml-8': isProfile
              })}
            >
              {!isSelectable ? (
                <Checkbox
                  disabled
                  tooltip={isProfile ? 'Profile is already added' : 'All profiles already added'}
                />
              ) : (
                <Checkbox {...checkboxProps} />
              )}
              <FontAwesomeIcon
                icon={isProfile ? 'user' : row.isExpanded ? 'folder-open' : 'folder'}
                className="text-muted tw-mr-3 tw-ml-3"
              />
              <Truncated text={<MarkedText source={row.values.data} mark={searchQuery} />} />
              {isProfile && !isSelectable && (
                <span className="tw-ml-auto tw-text-xs tw-font-medium tw-text-neutral-400">
                  Added
                </span>
              )}
            </div>
          );
        }
      }
    ];
  }, [searchQuery, prepareRowProps]);

  const data = React.useMemo(() => {
    return profiles
      .map((group, groupName) => {
        return {
          data: groupName,
          subRows: group
            .map((account: Map<string, any>, accountName: string) => {
              return {
                data: accountName,
                subRows: account.map((profile: Map<string, any>) => {
                  return { data: getProfileName(profile) };
                })
              };
            })
            .toArray()
        };
      })
      .toArray();
  }, [profiles]);

  const initialState = React.useMemo(() => {
    let expanded = {};

    if (!!searchQuery) {
      expanded = data
        .map((group, index) => {
          return [
            `${index}`,
            ...group.subRows.map((account: any, accountIndex: number) => {
              return `${index}.${accountIndex}`;
            })
          ];
        })
        .flat()
        .reduce((all, key) => ({ ...all, [key]: true }), {});
    }

    return { sortBy: [{ id: 'data', desc: false }], expanded };
  }, [searchQuery, data]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
      disableSortRemove: true,
      autoResetExpanded: !!searchQuery
    } as any,
    useSortBy,
    useExpanded
  );

  return (
    <Table hover {...tableInstance.getTableProps({ className: 'react-table stretch-modal' })}>
      <thead>
        {tableInstance.headerGroups.map((headerGroup) => {
          const { key, ...headerProps } = headerGroup.getHeaderGroupProps();

          return (
            <tr key={key} {...headerProps}>
              {headerGroup.headers.map((column: any) => {
                const sortByProps = column.getSortByToggleProps({ title: '' });
                const { key, ...columnProps } = column.getHeaderProps(sortByProps);

                return (
                  <th key={key} {...columnProps}>
                    {column.render('Header')}
                    <SortIcon
                      className="icon-addon-left"
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                    />
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...tableInstance.getTableBodyProps()}>
        {!tableInstance.rows.length && (
          <tr className="no-hover">
            {tableInstance.columns.map((column, index) => {
              return (
                <td key={index}>
                  {index === 0 ? (
                    isLoading ? (
                      <>
                        <Loader className="icon-addon-right" />
                        Loading profiles...
                      </>
                    ) : (
                      `No profiles ${!!searchQuery ? 'found' : 'selected'}`
                    )
                  ) : (
                    ''
                  )}
                </td>
              );
            })}
          </tr>
        )}
        {tableInstance.rows
          .map((row: any) => {
            tableInstance.prepareRow(row);
            return row;
          })
          .map((row: any) => {
            const isProfileAdded = row.depth === 2 && isSaved(row);

            const { key, ...rowProps } = row.getRowProps({
              className: classNames({ clickable: !isProfileAdded }),
              onClick: () => {
                if (isProfileAdded || hasSelections()) {
                  return;
                }

                return row.depth < 2
                  ? row.toggleRowExpanded()
                  : selectProfiles([row.values.data], !isSelected(row));
              }
            });

            return (
              <tr key={key} {...rowProps}>
                {row.cells.map((cell: any) => {
                  const { key, ...cellProps } = cell.getCellProps();

                  return (
                    <td key={key} {...cellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default ProfilesSelector;
