import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import { USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import ConfirmButtons from '../../../react/common/ConfirmButtons';
import PasswordControl from '../../../react/common/PasswordControl';
import Tooltip from '../../../react/common/Tooltip';
import nextTick from '../../../utils/nextTick';
import InstalledComponentsActionCreators from '../../components/InstalledComponentsActionCreators';
import Help from './Help';

class AwsLogin extends React.Component {
  static propTypes = {
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    configData: PropTypes.instanceOf(Map).isRequired,
    readOnly: PropTypes.bool.isRequired
  };

  state = {
    data: this.prepareData(),
    isSaving: false
  };

  render() {
    return (
      <div className="flex-container align-top">
        <div className="fill-space pr-2">
          <Form onSubmit={this.handleSubmit} horizontal>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Access Key Id
              </Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  value={this.state.data.get('accessKeyId')}
                  onChange={(e) =>
                    this.setState({ data: this.state.data.set('accessKeyId', e.target.value) })
                  }
                  placeholder="MYAWSACCESSKEYID123"
                  disabled={this.isDisabled()}
                />
                <HelpBlock>Make sure that this Access Key ID has correct permissions.</HelpBlock>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Secret Access Key{' '}
                <Tooltip
                  tooltip="The Secret Access Key will be securely encrypted."
                  type="explanatory"
                >
                  <FontAwesomeIcon icon="circle-question" fixedWidth />
                </Tooltip>
              </Col>
              <Col sm={9}>
                <PasswordControl
                  value={this.state.data.get('secretAccessKey')}
                  onChange={(e) =>
                    this.setState({
                      data: this.state.data.set('secretAccessKey', e.target.value)
                    })
                  }
                  disabled={this.isDisabled()}
                />
              </Col>
            </FormGroup>
            {this.renderConfirmButton()}
          </Form>
        </div>
        {this.renderHelp()}
      </div>
    );
  }

  renderConfirmButton() {
    return (
      <ConfirmButtons
        block
        saveButtonType="submit"
        saveLabel="Save AWS Login"
        isSaving={this.state.isSaving}
        isDisabled={this.isSubmitDisabled()}
      />
    );
  }

  renderHelp() {
    if (!this.state.isSaving && this.isSubmitDisabled()) {
      return (
        <Help
          title="Enter your AWS Login"
          text="If you need more login options (login via ROLE), switch to an advanced mode, where you will have more options for how to set up your login."
          documentation={`${USER_DOCUMENTATION_URL}/components/extractors/storage/aws-s3#authentication-with-aws-credentials`}
        />
      );
    }

    return (
      <Help
        down
        title="What will happen now?"
        text="Your AWS login will be saved and in the next step you will set up your source settings. You will then be ready to download your data."
      />
    );
  }

  isDisabled() {
    return this.state.isSaving || this.props.readOnly;
  }

  isSubmitDisabled() {
    return (
      this.isDisabled() ||
      !this.state.data.get('accessKeyId').trim() ||
      !this.state.data.get('secretAccessKey').trim() ||
      this.state.data.equals(this.prepareData())
    );
  }

  prepareData() {
    return Map({
      accessKeyId: this.props.configData.getIn(['parameters', 'accessKeyId'], ''),
      secretAccessKey: this.props.configData.getIn(['parameters', '#secretAccessKey'], '')
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ isSaving: true });
    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.props.componentId,
      this.props.configId,
      this.props.configData
        .setIn(['parameters', 'loginType'], 'credentials')
        .setIn(['parameters', 'accessKeyId'], this.state.data.get('accessKeyId').trim())
        .setIn(['parameters', '#secretAccessKey'], this.state.data.get('secretAccessKey').trim()),
      'Set up AWS login'
    )
      .then(() => nextTick(() => this.setState({ data: this.prepareData() })))
      .finally(() => this.setState({ isSaving: false }));
  };
}

export default AwsLogin;
