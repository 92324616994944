import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { KEBOOLA_STORAGE } from '../../../constants/componentIds';
import ComponentIcon from '../../../react/common/ComponentIcon';
import Gravatar from '../../../react/common/Gravatar';
import Tooltip from '../../../react/common/Tooltip';
import { isNullable } from '../../components/utils/datatypeHelpers';
import {
  findBasetypeDatatype,
  findLengthDatatypeValue,
  findNullableDatatypeValue,
  findStorageTypeDatatype
} from '../helpers';
import ColumnDatatypesModal from './ColumnDatatypesModal';

class ColumnDatatypes extends React.Component {
  state = {
    openModal: false
  };

  render() {
    return (
      <>
        {this.renderDatatypes()}
        {this.props.canEdit && (
          <>
            <span className="f-13 text-muted">
              <Tooltip tooltip="Update data type" placement="top">
                <Button
                  bsStyle="link"
                  className="btn-link-inline pl-1 pr-1"
                  onClick={() => {
                    this.setState({ openModal: true });

                    if (!this.props.sampleData) {
                      this.props.loadSampleData();
                    }
                  }}
                  disabled={this.props.disabled}
                >
                  <FontAwesomeIcon icon="pen" className="font-size-inherit" />
                </Button>
              </Tooltip>
            </span>
            <ColumnDatatypesModal
              show={this.state.openModal}
              columnName={this.props.columnName}
              sampleData={this.props.sampleData}
              metadata={this.props.columnMetadata}
              onHide={() => this.setState({ openModal: false })}
              saveUserType={this.props.saveUserType}
              deleteUserType={this.props.deleteUserType}
              components={this.props.components}
            />
          </>
        )}
      </>
    );
  }

  renderDatatypes() {
    const metadata = this.props.isTypedTable
      ? findStorageTypeDatatype(this.props.columnMetadata)
      : findBasetypeDatatype(this.props.columnMetadata);

    if (!metadata) {
      return <span className="text-muted">N/A</span>;
    }

    const type = metadata.get('value');
    const provider = metadata.get('provider');
    const length = findLengthDatatypeValue(this.props.columnMetadata, provider);
    const nullable = findNullableDatatypeValue(this.props.columnMetadata, provider);

    return (
      <>
        {this.renderIcon(provider)}
        {`${type}${length ? ` (${length})` : ''}${isNullable(nullable) ? `, Nullable` : ''}`}
      </>
    );
  }

  renderIcon(provider) {
    if (provider === 'user') {
      return (
        <Tooltip tooltip="Provided by user" placement="top">
          <span className="icon-addon-right">
            <Gravatar size={16} />
          </span>
        </Tooltip>
      );
    }

    const component = this.props.components.get(
      provider === 'storage' ? KEBOOLA_STORAGE : provider
    );

    if (!component) {
      return null;
    }

    return (
      <Tooltip tooltip={`Provided by ${component.get('name')}`} placement="top" type="explanatory">
        <span className="icon-addon-right">
          <ComponentIcon component={component} size="16" />
        </span>
      </Tooltip>
    );
  }
}

ColumnDatatypes.propTypes = {
  components: PropTypes.instanceOf(Map).isRequired,
  columnMetadata: PropTypes.instanceOf(List).isRequired,
  columnName: PropTypes.string.isRequired,
  saveUserType: PropTypes.func.isRequired,
  deleteUserType: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isTypedTable: PropTypes.bool.isRequired,
  loadSampleData: PropTypes.func.isRequired,
  sampleData: PropTypes.instanceOf(Map)
};

export default ColumnDatatypes;
