import React from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Modal, Well } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ExternalLink from '../../../react/common/ExternalLink';
import ModalIcon from '../../../react/common/ModalIcon';

const FilesSearchExamplesModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSelectExample: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      examples: [
        {
          query: 'token.name:john.doe@company.com',
          description: 'Files uploaded by John Doe'
        },
        {
          query: 'name:devel',
          description: 'Files with a name that contains "devel"'
        },
        {
          query: 'isPublic:1',
          description: 'Public files only'
        },
        {
          query: '-isPublic:1',
          description: 'Everything except public files'
        },
        {
          query: 'created:>2018-01-31',
          description: 'Files created after 2018-01-31'
        },
        {
          query: 'sizeBytes:>10000',
          description: 'Files bigger than 10kB'
        },
        {
          query: 'tags:table-export',
          description: 'Files tagged "table-export"'
        }
      ]
    };
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Search syntax &amp; Examples</Modal.Title>
          <ModalIcon icon="circle-question" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          {this.state.examples.map((example, index) => {
            return (
              <Well bsSize="sm" key={index}>
                <Label bsStyle="primary" className="icon-addon-right">
                  Search
                </Label>
                <Button
                  bsStyle="link"
                  className="btn-link-inline"
                  onClick={() => this.selectExample(example)}
                >
                  {example.query}
                </Button>
                <br />
                <Label bsStyle="warning" className="icon-addon-right">
                  Shows
                </Label>
                <span>{example.description}</span>
              </Well>
            );
          })}
          <p>
            <ExternalLink href="https://www.elastic.co/guide/en/elasticsearch/reference/5.0/query-dsl-query-string-query.html#query-string-syntax">
              Read full syntax guide
            </ExternalLink>
          </p>
        </Modal.Body>
      </Modal>
    );
  },

  selectExample(example) {
    this.props.onSelectExample(example.query);
    this.props.onHide();
  }
});

export default FilesSearchExamplesModal;
