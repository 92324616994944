import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from './Tooltip';

const Protected = (props: { children: JSX.Element }) => {
  const [isProtected, setIsProtected] = React.useState(true);

  if (!isProtected) {
    return props.children;
  }

  return (
    <Tooltip placement="top" tooltip="Protected content, click to reveal." type="explanatory">
      <FontAwesomeIcon
        icon="lock"
        fixedWidth
        className="clickable"
        onClick={() => setIsProtected(false)}
      />
    </Tooltip>
  );
};

export default Protected;
