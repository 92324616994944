import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import ConfirmModal from '../../../../react/common/ConfirmModal';
import Loader from '../../../../react/common/Loader';
import Tooltip from '../../../../react/common/Tooltip';

const ClearStateButton = createReactClass({
  propTypes: {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  getInitialState() {
    return {
      isPending: false,
      showModal: false
    };
  },

  render() {
    return (
      <>
        {this.renderButton()}
        <ConfirmModal
          closeAfterResolve
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          icon="arrow-rotate-left"
          title="Clear state"
          text={
            <p>
              Delete the current stored state of the configuration, e.g. progress of an incremental
              processes.
            </p>
          }
          buttonLabel="Clear state"
          buttonType="success"
          onConfirm={this.clearState}
          isLoading={this.state.isPending}
        />
      </>
    );
  },

  renderButton() {
    if (this.state.isPending) {
      return (
        <Button bsStyle="link" className="btn-block disabled">
          <Loader /> Clear State
        </Button>
      );
    }

    if (this.props.disabled) {
      return (
        <Tooltip tooltip="No stored state" placement="top">
          <Button bsStyle="link" className="btn-block disabled">
            <FontAwesomeIcon icon="arrow-rotate-left" fixedWidth />
            Clear State
          </Button>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        tooltip="State stores information from the previous run(s) and allows e.g. incremental loads."
        placement="top"
        type="explanatory"
      >
        <Button
          bsStyle="link"
          className="btn-block"
          onClick={() => this.setState({ showModal: true })}
        >
          <FontAwesomeIcon icon="arrow-rotate-left" fixedWidth />
          Clear State
        </Button>
      </Tooltip>
    );
  },

  clearState() {
    this.setState({ isPending: true });
    return this.props.onClick().finally(() => this.setState({ isPending: false }));
  }
});

export default ClearStateButton;
