import keyCodes from '../constants/keyCodes';

const shouldUseNewWindow = (event) => event?.ctrlKey || event?.metaKey;

const simulateClickIfMiddleMouseIsUsed = {
  mousedown: (event) => {
    if (event.button === keyCodes.MIDDLE_MOUSE) {
      event.preventDefault();
      event.stopPropagation();
    }
  },
  mouseup: (event) => {
    if (event.button === keyCodes.MIDDLE_MOUSE) {
      event.preventDefault();
      event.stopPropagation();
      event.target.dispatchEvent(new MouseEvent('click', { bubbles: true, ctrlKey: true }));
    }
  }
};

const windowOpen = (url, features = '', target = '_blank', { width, height } = {}) => {
  if (features.includes('popup')) {
    features += `,${getPopUpDimensionsString(width, height)}`;
  }

  return window.open(url, target, features);
};

// Inspiration from https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
const getPopUpDimensionsString = (width = 700, height = 800) => {
  const screenWidth = window.innerWidth ?? document.documentElement.clientWidth ?? screen.width;
  const screenHeight = window.innerHeight ?? document.documentElement.clientHeight ?? screen.height;

  return `width=${width},height=${height},top=${(screenHeight - height) / 2},left=${
    (screenWidth - width) / 2
  }`;
};

export { shouldUseNewWindow, simulateClickIfMiddleMouseIsUsed, windowOpen };
