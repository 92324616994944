/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useExpanded, useRowSelect, useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { List, Map } from 'immutable';
import { strLeftBack } from 'underscore.string';

import BlockButton from '../../../react/common/BlockButton';
import BucketSharedLabel from '../../../react/common/BucketSharedLabel';
import BucketStageLabel from '../../../react/common/BucketStageLabel';
import Checkbox from '../../../react/common/Checkbox';
import { onTableRowKeyDown } from '../../../react/common/ConfigurationsTable/helpers';
import CreatedDate from '../../../react/common/CreatedDate';
import InlineDescriptionEditInput from '../../../react/common/InlineDescriptionEditInput';
import Loader from '../../../react/common/Loader';
import Link from '../../../react/common/RouterLink';
import RowActionDropdown from '../../../react/common/RowActionDropdown';
import RowActionMenuItem from '../../../react/common/RowActionMenuItem';
import SortIcon from '../../../react/common/SortIcon';
import TableCollapseButton from '../../../react/common/TableCollapseButton';
import TableUpdatedByComponentInfo from '../../../react/common/TableUpdatedByComponentInfo';
import Tooltip from '../../../react/common/Tooltip';
import Truncated from '../../../react/common/Truncated';
import useMatchMedia from '../../../react/hooks/useMatchMedia';
import RoutesStore from '../../../stores/RoutesStore';
import hasSelections from '../../../utils/hasSelections';
import onClickSelectionCell from '../../../utils/onClickSelectionCell';
import string from '../../../utils/string';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../../utils/windowOpen';
import { canManageBucket, canUnlinkBucket, canWriteBucket } from '../../admin/privileges';
import MetadataActionCreators from '../../components/MetadataActionCreators';
import { MetadataKeys, ObjectTypes } from '../../components/MetadataConstants';
import { isCreatedInDevBranch } from '../../dev-branches/helpers';
import { FORM_STEPS } from '../../sandboxes/components/AddSandboxModal';
import { toggleExpandedBucket, toggleShowAllBuckets, updateBucketsSort } from '../actions';
import { routeNames, sortEntities, storageInitialLimit } from '../constants';
import { getDescriptionValue, getFilteredData } from '../helpers';
import DescriptionButton from './DescriptionButton';
import ExternalBucketLabel from './ExternalBucketLabel';
import NativeTypesLabel from './NativeTypesLabel';
import RefreshExternalBucketMenuItem from './RefreshExternalBucketMenuItem';

const HIDDEN_COLUMNS_WIDTH_LIMIT = 1281;
const HIDDEN_COLUMNS_FOR_SMALL_SCREEN = ['last_updated_by'];

const getSortValue = (row, sortEntity) => {
  if (sortEntity === sortEntities.LAST_CHANGE) {
    return new Date(row.get('lastChangeDate') ?? row.get('created')).valueOf();
  }

  return row.get('displayName').toLowerCase();
};

export const useSorter = (initialState) => {
  const [sort, setSort] = React.useState(initialState);

  const sorter = React.useCallback(
    (a, b) => {
      const valueA = getSortValue(a, sort.get('entity'));
      const valueB = getSortValue(b, sort.get('entity'));

      if (sort.get('entity') === sortEntities.NAME) {
        return valueA.localeCompare(valueB) * sort.get('order');
      }

      return (valueB - valueA) * sort.get('order');
    },
    [sort]
  );

  const setSortFn = React.useCallback((sort) => {
    setSort(Map(sort));
    updateBucketsSort(sort);
  }, []);

  return { sorter, sort, setSort: setSortFn };
};

const Description = ({ row, entity, readOnly }) => {
  return (
    <div className="f-13 text-muted">
      <InlineDescriptionEditInput
        entity={entity}
        description={getDescriptionValue(row.values.item.get('metadata'))}
        onSave={(newDescription) => {
          return MetadataActionCreators.saveMetadata(
            entity,
            row.values.item.get('id'),
            MetadataKeys.DESCRIPTION,
            newDescription.trim()
          );
        }}
        readOnly={readOnly}
      />
    </div>
  );
};

const BucketCell = ({ row, canWrite }) => {
  return (
    <span className="with-expand flex-container flex-start overflow-break-anywhere">
      {row.canExpand && <TableCollapseButton entity="bucket" isCollapsed={!row.isExpanded} />}
      <FontAwesomeIcon
        fixedWidth
        icon={row.canExpand ? (row.isExpanded ? 'folder-open' : 'folder') : ['far', 'folder']}
        className="text-muted"
      />
      <div>
        <div className="flex-container flex-start">
          <Link
            to={routeNames.BUCKET}
            params={{ bucketId: row.values.item.get('id') }}
            className="link-inherit"
          >
            <Truncated text={row.values.item.get('displayName')} />
          </Link>
          <BucketStageLabel stage={row.values.item.get('stage')} round />
          <ExternalBucketLabel
            hasExternalSchema={row.values.item.get('hasExternalSchema', false)}
          />
          <BucketSharedLabel isSharing={!!row.values.item.get('sharing')} />
        </div>
        <Description row={row} entity={ObjectTypes.BUCKET} readOnly={!canWrite} />
      </div>
    </span>
  );
};

const TableCell = ({ row, canWrite }) => {
  return (
    <span className="flex-container flex-start overflow-break-anywhere">
      <FontAwesomeIcon icon="table" className="text-muted" />
      <div>
        <div className="flex-container flex-start">
          <Link
            to={routeNames.TABLE}
            params={{
              bucketId: row.values.item.getIn(['bucket', 'id']),
              tableName: row.values.item.get('name')
            }}
            className={classnames('link-inherit', {
              'dotted-underline': row.values.item.get('isAlias', false)
            })}
          >
            <Truncated text={row.values.item.get('displayName')} />
          </Link>
          <NativeTypesLabel isTyped={row.values.item.get('isTyped', false)} className="ml-1" />
        </div>
        <Description row={row} entity={ObjectTypes.TABLE} readOnly={!canWrite} />
      </div>
    </span>
  );
};

export const HeaderSelection = ({ getToggleAllRowsSelectedProps, rows, sapiToken }) => {
  const allAllowedRows = rows.filter((row) => {
    if (!row.values.item.has('stage')) {
      return canWriteBucket(sapiToken, row.values.item.get('bucket'));
    }
    if (row.values.item.get('linkedBy', List()).count() !== 0) {
      return false;
    }
    if (row.values.item.has('sourceBucket')) {
      return canUnlinkBucket(sapiToken, row.values.item);
    }
    return canManageBucket(sapiToken, row.values.item);
  });

  if (allAllowedRows.length === 0) {
    return null;
  }

  const isAllSelected = allAllowedRows.every((row) => row.isSelected);
  const isSomeSelected = allAllowedRows.some((row) => row.isSelected);

  return (
    <Checkbox
      {...getToggleAllRowsSelectedProps({
        tooltip: isAllSelected || isSomeSelected ? 'Deselect all' : 'Select all',
        checked: isAllSelected,
        indeterminate: !isAllSelected && isSomeSelected,
        onChange: () => {
          const toggleState = !(isAllSelected || isSomeSelected);
          allAllowedRows.forEach((row) => row.toggleRowSelected(toggleState));
        }
      })}
    />
  );
};

export const CellSelection = ({ row, state, sapiToken, prepareRow, rowsById }) => {
  if (!row.values.item.has('stage')) {
    if (!canWriteBucket(sapiToken, row.values.item.get('bucket'))) {
      return <Checkbox disabled tooltip="You don't have permission to delete this table" />;
    }

    return (
      <Checkbox
        {...row.getToggleRowSelectedProps({
          tooltip: `${row.isSelected ? 'Deselect' : 'Select'} table`,
          onChange: () => {
            // when searching, tables are rendered without bucket, there is no parent
            if (row.depth === 0) {
              return row.toggleRowSelected(!row.isSelected);
            }

            const parent = rowsById[strLeftBack(row.id, '.')];

            if (parent?.isSelected && row.isSelected) {
              parent.toggleRowSelected(false);
              return parent.subRows.forEach((subRow) => {
                return subRow.toggleRowSelected(row.id !== subRow.id);
              });
            }

            return row.toggleRowSelected(!row.isSelected);
          }
        })}
      />
    );
  }

  if (row.values.item.get('linkedBy', List()).count() !== 0) {
    return <Checkbox disabled tooltip="Please unlink linked buckets first" />;
  }

  if (row.values.item.has('sourceBucket') && !canUnlinkBucket(sapiToken, row.values.item)) {
    return <Checkbox disabled tooltip="You don't have permission to unlink bucket" />;
  }

  if (!row.values.item.has('sourceBucket') && !canManageBucket(sapiToken, row.values.item)) {
    return <Checkbox disabled tooltip="You don't have permission to delete this bucket" />;
  }

  const isChecked = state ? !!state.selectedRowIds[row.id] : row.isSelected;

  return (
    <Checkbox
      {...row.getToggleRowSelectedProps({
        tooltip: `${isChecked || row.isSomeSelected ? 'Deselect' : 'Select'} ${
          row.isSomeSelected ? 'tables in bucket' : 'bucket'
        }`,
        checked: isChecked,
        onChange: () => {
          row.toggleRowSelected(!(isChecked || row.isSomeSelected));
          row.subRows.forEach((subRow) => {
            if (!subRow.toggleRowSelected) {
              prepareRow(subRow);
            }
            subRow.toggleRowSelected(!(isChecked || row.isSomeSelected));
          });
        }
      })}
    />
  );
};

export const ActionDropdown = ({
  sort,
  setSort,
  state,
  rowsById,
  canExportTable,
  openDeleteModal,
  openCreateWorkpaceModal,
  openExportTablesModal,
  hasSnowflakePartnerConnectLimited
}) => {
  const selected = Object.keys(state.selectedRowIds);
  const selectedRows = Object.values(rowsById)
    .filter((row) => selected.includes(row.id))
    .map((row) => row.values.item);
  const { buckets, tables } = selectedRows.reduce(
    (bucketsAndTables, row) => {
      const key = row.has('stage') ? 'buckets' : 'tables';
      return { ...bucketsAndTables, [key]: bucketsAndTables[key] + 1 };
    },
    {
      buckets: 0,
      tables: 0
    }
  );
  const areWorkspacesDisabled = selectedRows.some((row) => row.get('hasExternalSchema'));
  const workspacesDisabledReason = 'Unavailable for external tables';
  const isSortedByName = sort.get('entity') === sortEntities.NAME;

  return (
    <div className="flex-container flex-start">
      {buckets + tables === 0 ? (
        <span
          className="clickable"
          title="Sort by name"
          onClick={() => {
            setSort({
              entity: sortEntities.NAME,
              order: isSortedByName ? sort.get('order') * -1 : 1
            });
          }}
        >
          Name
          <SortIcon
            className="icon-addon-left"
            isSorted={isSortedByName}
            isSortedDesc={sort.get('order') === -1}
          />
        </span>
      ) : (
        <strong>
          {!!buckets && `${buckets} ${string.pluralize(buckets, 'bucket')} `}
          {buckets && tables ? 'and ' : ''}
          {!!tables && `${tables} ${string.pluralize(tables, 'table')} `}
          selected
        </strong>
      )}
      {buckets + tables > 0 && (
        <div className="table-action-buttons no-wrap">
          <Tooltip placement="top" tooltip="Delete selected">
            <Button
              bsStyle="link"
              className="btn-link-inline btn-link-muted"
              onClick={() => openDeleteModal(selectedRows)}
            >
              <FontAwesomeIcon icon="trash" fixedWidth />
            </Button>
          </Tooltip>
          {canExportTable && (
            <Tooltip placement="top" tooltip="Export selected">
              <Button
                bsStyle="link"
                className="btn-link-inline btn-link-muted"
                onClick={() => {
                  openExportTablesModal(selectedRows.filter((row) => !row.has('stage')));
                }}
              >
                <FontAwesomeIcon icon="down-to-line" fixedWidth />
              </Button>
            </Tooltip>
          )}
          {!hasSnowflakePartnerConnectLimited && (
            <>
              <Tooltip
                placement="top"
                tooltip={areWorkspacesDisabled ? workspacesDisabledReason : 'Create new Workspace'}
              >
                <Button
                  bsStyle="link"
                  className={classnames('btn-link-inline btn-link-muted', {
                    disabled: areWorkspacesDisabled
                  })}
                  onClick={() => !areWorkspacesDisabled && openCreateWorkpaceModal(selectedRows)}
                >
                  <div className="add-workspace-icon">
                    <FontAwesomeIcon icon="box" fixedWidth />
                    <FontAwesomeIcon icon="circle-plus" />
                  </div>
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                tooltip={
                  areWorkspacesDisabled ? workspacesDisabledReason : 'Copy to existing Workspace'
                }
              >
                <Button
                  bsStyle="link"
                  className={classnames('btn-link-inline btn-link-muted', {
                    disabled: areWorkspacesDisabled
                  })}
                  onClick={() => {
                    !areWorkspacesDisabled &&
                      openCreateWorkpaceModal(selectedRows, FORM_STEPS.SANDBOX_UPDATE);
                  }}
                >
                  <div className="add-workspace-icon">
                    <FontAwesomeIcon icon="box" fixedWidth />
                    <FontAwesomeIcon icon="circle-arrow-right" />
                  </div>
                </Button>
              </Tooltip>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const BucketActions = ({
  bucket,
  isDeleting,
  isExporting,
  openModal,
  openExportTablesModal,
  canExportTable,
  canManageBucket
}) => {
  if (isExporting) {
    return (
      <span className="text-muted">
        <Loader className="btn-icon" />
        Preparing export
      </span>
    );
  }

  return (
    <RowActionDropdown showLoading={isDeleting}>
      {canExportTable && (
        <RowActionMenuItem
          disabled={bucket.get('bucketTables', List()).isEmpty()}
          onSelect={() => openExportTablesModal(bucket.get('bucketTables', List()).toArray())}
        >
          <FontAwesomeIcon fixedWidth icon="down-to-line" />
          Export bucket
        </RowActionMenuItem>
      )}
      <DescriptionButton data={bucket} entity={ObjectTypes.BUCKET} readOnly={!canManageBucket} />
      {canManageBucket && (
        <>
          <RefreshExternalBucketMenuItem bucket={bucket} />
          <RowActionMenuItem divider />
          <RowActionMenuItem disabled={isDeleting} onSelect={() => openModal(bucket)}>
            {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
            Delete bucket
          </RowActionMenuItem>
        </>
      )}
    </RowActionDropdown>
  );
};

export const TableActions = ({
  table,
  isDeleting,
  isExporting,
  openDeleteTableModal,
  openExportTablesModal,
  canExportTable,
  canWriteTable
}) => {
  if (isExporting) {
    return (
      <span className="text-muted">
        <Loader className="btn-icon" />
        Preparing export
      </span>
    );
  }

  if (isDeleting) {
    return (
      <span className="text-muted">
        <Loader className="btn-icon" />
        Deleting table
      </span>
    );
  }

  return (
    <RowActionDropdown>
      {canExportTable && (
        <RowActionMenuItem onSelect={() => openExportTablesModal([table])}>
          <FontAwesomeIcon fixedWidth icon="down-to-line" />
          Export table
        </RowActionMenuItem>
      )}
      <DescriptionButton data={table} entity={ObjectTypes.TABLE} readOnly={!canWriteTable} />
      {canWriteTable && (
        <>
          <RowActionMenuItem divider />
          <RowActionMenuItem onSelect={() => openDeleteTableModal(table)}>
            <FontAwesomeIcon icon="trash" fixedWidth />
            Delete table
          </RowActionMenuItem>
        </>
      )}
    </RowActionDropdown>
  );
};

export const LastChangeColumn = ({ sort, setSort }) => {
  const isSorted = sort.get('entity') === sortEntities.LAST_CHANGE;

  return (
    <span
      className="clickable"
      title="Sort by last change"
      onClick={() => {
        setSort({
          entity: sortEntities.LAST_CHANGE,
          order: isSorted ? sort.get('order') * -1 : 1
        });
      }}
    >
      <SortIcon isSorted={isSorted} isSortedDesc={sort.get('order') === -1} />
      Last change
    </span>
  );
};

const getTableInitialState = (data, expandedBuckets) => {
  let expanded = {};

  if (!expandedBuckets.isEmpty()) {
    expanded = data.reduce((all, bucket, bucketIndex) => {
      return { ...all, [bucketIndex]: expandedBuckets.has(bucket.item.get('id')) };
    }, {});
  }

  return {
    expanded,
    ...(window.innerWidth < HIDDEN_COLUMNS_WIDTH_LIMIT && {
      hiddenColumns: HIDDEN_COLUMNS_FOR_SMALL_SCREEN
    })
  };
};

const Table = ({ columns, data, sapiToken, expandedBuckets, showAll }) => {
  const initialState = React.useMemo(
    () => getTableInitialState(data, expandedBuckets),
    [data, expandedBuckets]
  );
  const tableInstance = useTable(
    { columns, data, initialState },
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return columns.map((column) => {
          if (column.id !== 'item') {
            return column;
          }

          return {
            ...column,
            Header: (props) => {
              return (
                <div className="flex-container inline-flex lex-start">
                  <div
                    onClick={onClickSelectionCell}
                    className="tw-p-1 tw-pr-3 tw-pl-6 -tw-ml-6 tw-inline-flex tw-items-center"
                  >
                    <HeaderSelection sapiToken={sapiToken} {...props} />
                  </div>
                  {column.Header(props)}
                </div>
              );
            },
            Cell: (props) => {
              const inFolder = props.row.depth === 1;

              return (
                <div
                  className={classnames('flex-container inline-flex flex-start', {
                    'tw-pl-7': inFolder
                  })}
                >
                  <div
                    onClick={onClickSelectionCell}
                    className={classnames(
                      'tw-p-3 tw-inline-flex tw-items-center',
                      inFolder ? 'tw-pl-12 -tw-ml-12' : 'tw-pl-6 -tw-ml-6'
                    )}
                  >
                    <CellSelection sapiToken={sapiToken} {...props} />
                  </div>
                  {column.Cell(props)}
                </div>
              );
            }
          };
        });
      });
    }
  );

  const matchMediaHandler = React.useCallback(
    ({ matches }) => {
      return tableInstance.columns
        .filter((column) => HIDDEN_COLUMNS_FOR_SMALL_SCREEN.includes(column.id))
        .forEach((column) => column.toggleHidden(!matches));
    },
    [tableInstance.columns]
  );

  useMatchMedia(`(min-width: ${HIDDEN_COLUMNS_WIDTH_LIMIT}px)`, matchMediaHandler);

  let rows = tableInstance.rows.map((row) => {
    tableInstance.prepareRow(row);

    return row;
  });

  if (!showAll) {
    rows = rows.slice(0, storageInitialLimit);
  }

  return (
    <>
      <div {...tableInstance.getTableProps({ className: 'table table-hover react-table' })}>
        <div className="thead">
          {tableInstance.headerGroups.map((headerGroup, index) => (
            <div
              key={index}
              {...headerGroup.getHeaderGroupProps({
                className: classnames('tr with-action-button is-sticky bg-color-white')
              })}
            >
              {headerGroup.headers.map((column) => {
                const userCellProps = {
                  className: classnames('th', {
                    'w-280 text-right': column.id === 'last_updated_by',
                    'w-200 text-right': column.id === 'last_modified'
                  })
                };

                return (
                  <div key={column.id} {...column.getHeaderProps(userCellProps)}>
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...tableInstance.getTableBodyProps({ className: 'tbody' })}>
          {rows.map((row) => {
            const rowAction = (e) => {
              if (hasSelections()) {
                return;
              }

              if (row.depth === 0) {
                if (shouldUseNewWindow(e)) {
                  return windowOpen(
                    RoutesStore.getRouter().createHref(routeNames.BUCKET, {
                      bucketId: row.values.item.get('id')
                    })
                  );
                }

                toggleExpandedBucket(row.values.item.get('id'), !row.isExpanded);
                return row.toggleRowExpanded(!row.isExpanded);
              }

              if (shouldUseNewWindow(e)) {
                return windowOpen(
                  RoutesStore.getRouter().createHref(routeNames.TABLE, {
                    bucketId: row.values.item.getIn(['bucket', 'id']),
                    tableName: row.values.item.get('name')
                  })
                );
              }

              return RoutesStore.getRouter().transitionTo(routeNames.TABLE, {
                bucketId: row.values.item.getIn(['bucket', 'id']),
                tableName: row.values.item.get('name')
              });
            };

            const userRowProps =
              row.depth === 0 && row.subRows.length === 0
                ? {
                    className: 'tr hoverable-actions-with-replacement',
                    title: 'No tables in bucket'
                  }
                : {
                    tabIndex: '0',
                    role: 'button',
                    className: 'tr clickable hoverable-actions-with-replacement',
                    onMouseDown: simulateClickIfMiddleMouseIsUsed.mousedown,
                    onMouseUp: simulateClickIfMiddleMouseIsUsed.mouseup,
                    onClick: rowAction,
                    onKeyDown: onTableRowKeyDown(rowAction)
                  };

            return (
              <div key={row.id} {...row.getRowProps(userRowProps)}>
                {row.cells.map((cell, index) => {
                  const userCellProps = {
                    className: classnames('td', {
                      'text-right': ['last_updated_by', 'last_modified'].includes(cell.column.id),
                      'bg-selected': row.isSelected,
                      'dev-bucket': isCreatedInDevBranch(
                        row.values.item.has('stage')
                          ? row.values.item
                          : row.values.item.get('bucket')
                      )
                    })
                  };

                  return (
                    <div key={index} {...cell.getCellProps(userCellProps)}>
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {tableInstance.rows.length > storageInitialLimit && !showAll && (
        <BlockButton label="Show All" onClick={() => toggleShowAllBuckets(true)} />
      )}
    </>
  );
};

const IndexTable = ({
  buckets,
  expandedBuckets,
  showAllBuckets,
  bucketsSort,
  searchFilters,
  components,
  configurations,
  sapiToken,
  openDeleteModal,
  openDeleteBucketModal,
  openDeleteTableModal,
  openExportTablesModal,
  openCreateWorkpaceModal,
  deletingTables,
  exportingTables,
  deletingBuckets,
  canExportTable,
  hasFlows,
  hasSnowflakePartnerConnectLimited
}) => {
  const { sort, sorter, setSort } = useSorter(bucketsSort);

  const columns = React.useMemo(
    () => [
      {
        Header: ({ state, rowsById }) => (
          <ActionDropdown
            openDeleteModal={openDeleteModal}
            openCreateWorkpaceModal={openCreateWorkpaceModal}
            canExportTable={canExportTable}
            openExportTablesModal={openExportTablesModal}
            state={state}
            rowsById={rowsById}
            sort={sort}
            setSort={setSort}
            hasSnowflakePartnerConnectLimited={hasSnowflakePartnerConnectLimited}
          />
        ),
        Cell: ({ row }) =>
          row.depth === 0 ? (
            <BucketCell row={row} canWrite={canWriteBucket(sapiToken, row.values.item)} />
          ) : (
            <TableCell
              row={row}
              canWrite={canWriteBucket(sapiToken, row.values.item.get('bucket'))}
            />
          ),
        accessor: 'item'
      },
      {
        Header: 'Recently updated by',
        accessor: 'last_updated_by'
      },
      {
        Header: <LastChangeColumn sort={sort} setSort={setSort} />,
        accessor: 'last_modified'
      }
    ],
    [
      sapiToken,
      sort,
      setSort,
      openCreateWorkpaceModal,
      openDeleteModal,
      canExportTable,
      openExportTablesModal,
      hasSnowflakePartnerConnectLimited
    ]
  );

  const memoizedRows = React.useMemo(
    () =>
      getFilteredData(buckets, '', searchFilters.toJS())
        .sort(sorter)
        .map((bucket) => {
          const isDeleting = deletingBuckets.get(bucket.get('id'), false);
          const isExportingBucket =
            !bucket.get('bucketTables').isEmpty() &&
            bucket.get('bucketTables').every((table, tableId) => {
              return exportingTables.get(tableId, false);
            });

          return {
            item: bucket,
            last_updated_by: (
              <span className="text-muted">
                {bucket.get('bucketTables').isEmpty() ? (
                  'N/A'
                ) : (
                  <TableUpdatedByComponentInfo
                    table={bucket
                      .get('bucketTables')
                      .sortBy((table) => {
                        const lastChange = table.get('lastChangeDate', table.get('created'));
                        return -1 * new Date(lastChange).getTime();
                      })
                      .first()}
                    components={components}
                    configurations={configurations}
                    hasFlows={hasFlows}
                  />
                )}
              </span>
            ),
            last_modified: (
              <div
                className={classnames('actions-container', {
                  'force-actions': isDeleting || isExportingBucket
                })}
              >
                <div className="not-actions">
                  <CreatedDate
                    createdTime={bucket.get('lastChangeDate') || bucket.get('created')}
                  />
                </div>
                <div className="actions">
                  <BucketActions
                    bucket={bucket}
                    isDeleting={isDeleting}
                    isExporting={isExportingBucket}
                    openModal={openDeleteBucketModal}
                    canExportTable={canExportTable}
                    openExportTablesModal={openExportTablesModal}
                    canManageBucket={canManageBucket(sapiToken, bucket)}
                  />
                </div>
              </div>
            ),
            subRows: bucket
              .get('bucketTables')
              .sort(sorter)
              .map((table) => {
                const isDeleting = deletingTables.get(table.get('id'), false);

                return {
                  item: table,
                  last_updated_by: (
                    <TableUpdatedByComponentInfo
                      table={table}
                      components={components}
                      configurations={configurations}
                      hasFlows={hasFlows}
                    />
                  ),
                  last_modified: (
                    <div
                      className={classnames('actions-container', {
                        'force-actions': isDeleting || exportingTables.get(table.get('id'), false)
                      })}
                    >
                      <div className="not-actions">
                        <CreatedDate
                          createdTime={table.get('lastChangeDate') || table.get('created')}
                        />
                      </div>
                      <div className="actions">
                        <TableActions
                          table={table}
                          isDeleting={isDeleting}
                          isExporting={exportingTables.get(table.get('id'), false)}
                          openDeleteTableModal={openDeleteTableModal}
                          openExportTablesModal={openExportTablesModal}
                          canExportTable={canExportTable}
                          canWriteTable={canWriteBucket(sapiToken, table.get('bucket'))}
                        />
                      </div>
                    </div>
                  )
                };
              })
              .toArray()
          };
        })
        .toArray(),
    [
      components,
      configurations,
      buckets,
      deletingBuckets,
      deletingTables,
      exportingTables,
      sorter,
      searchFilters,
      openDeleteBucketModal,
      openDeleteTableModal,
      openExportTablesModal,
      sapiToken,
      hasFlows,
      canExportTable
    ]
  );

  if (memoizedRows.length === 0) {
    return (
      <div className="box searchbar-results-box">
        <div className="box-content">No buckets created yet.</div>
      </div>
    );
  }

  return (
    <div className="box">
      <Table
        columns={columns}
        data={memoizedRows}
        sapiToken={sapiToken}
        expandedBuckets={expandedBuckets}
        showAll={showAllBuckets}
      />
    </div>
  );
};

IndexTable.propTypes = {
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  expandedBuckets: PropTypes.instanceOf(Map).isRequired,
  bucketsSort: PropTypes.instanceOf(Map).isRequired,
  components: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  deletingTables: PropTypes.instanceOf(Map).isRequired,
  deletingBuckets: PropTypes.instanceOf(Map).isRequired,
  exportingTables: PropTypes.instanceOf(Map).isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  openDeleteBucketModal: PropTypes.func.isRequired,
  openDeleteTableModal: PropTypes.func.isRequired,
  openExportTablesModal: PropTypes.func.isRequired,
  openCreateWorkpaceModal: PropTypes.func.isRequired,
  canExportTable: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  showAllBuckets: PropTypes.bool.isRequired,
  hasSnowflakePartnerConnectLimited: PropTypes.bool.isRequired
};

export default IndexTable;
