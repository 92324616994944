const SCHEDULE_STATE = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
};

const BRANCH_TOOLTIP_MESSAGE = 'Schedule can be set up only in production.';

const SOX_BRANCH_TOOLTIP_MESSAGE =
  'Changes to the schedule will only be materialized when the branch is merged into production';

export { SCHEDULE_STATE, BRANCH_TOOLTIP_MESSAGE, SOX_BRANCH_TOOLTIP_MESSAGE };
