import React from 'react';
import { fromJS, Map } from 'immutable';

import Confirm from '../../../react/common/Confirm';
import SwitchButton from '../../../react/common/SwitchButton';
import { uiModes } from '../../components/Constants';
import InstalledComponentsApi from '../../components/InstalledComponentsApi';
import { disableSimplifiedUi, enableSimplifiedUi } from '../actions';

const SwitchModeButton = (props: { componentId: string; config: Map<string, any> }) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [previousVersion, setPreviousVersion] = React.useState(Map());

  const uiMode = props.config.getIn(['configuration', 'runtime', 'uiMode']);
  const isSimpleModeActive = uiMode === uiModes.SIMPLE;
  const isDisabled =
    !isSimpleModeActive &&
    previousVersion.getIn(['configuration', 'runtime', 'uiMode']) !== uiModes.SIMPLE;

  React.useEffect(() => {
    if (
      uiMode &&
      !isSimpleModeActive &&
      props.config.get('version') > 1 &&
      previousVersion.get('version') !== props.config.get('version') - 1
    ) {
      setIsLoading(true);
      InstalledComponentsApi.getComponentConfigByVersion(
        props.componentId,
        props.config.get('id'),
        props.config.get('version') - 1
      )
        .then((config) => setPreviousVersion(fromJS(config)))
        .finally(() => setIsLoading(false));
    }
  }, [uiMode, isSimpleModeActive, previousVersion, props.componentId, props.config]);

  return (
    <Confirm
      buttonType="success"
      icon="right-left"
      title={`${isSimpleModeActive ? 'Advanced' : 'Simplified'} mode`}
      text={`Are you sure you want to switch to the ${
        isSimpleModeActive ? 'Advanced' : 'Simplified'
      } Mode?`}
      buttonLabel={`Switch to ${isSimpleModeActive ? 'advanced' : 'simplified'} mode`}
      onConfirm={() => {
        setIsUpdating(true);
        const action = isSimpleModeActive ? disableSimplifiedUi : enableSimplifiedUi;
        return action(
          props.componentId,
          props.config.get('id'),
          props.config.get('configuration')
        ).finally(() => setIsUpdating(false));
      }}
      isLoading={isUpdating}
      isDisabled={isLoading || isDisabled}
      disabledReason="You have made changes that are not compatible in this view. To enable Simplified Mode, restore the previous version."
      closeAfterResolve
    >
      <SwitchButton
        isDisabled={isDisabled}
        label={`${isSimpleModeActive ? 'Advanced' : 'Simplified'} mode`}
      />
    </Confirm>
  );
};

export default SwitchModeButton;
