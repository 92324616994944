import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Radio } from 'react-bootstrap';

const SliderPicker = ({ onChange, options, value, renderLabel, disabled }) => {
  return (
    <FormGroup className="slider-picker">
      <div className="picker-values">
        {options.map((option) => (
          <Radio
            inline
            key={option}
            name="credits"
            onChange={() => onChange(option)}
            checked={value === option}
            disabled={disabled}
          >
            <span className="picker-label text-muted">{renderLabel?.(option) || option}</span>
          </Radio>
        ))}
      </div>
    </FormGroup>
  );
};

SliderPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  renderLabel: PropTypes.func,
  disabled: PropTypes.bool
};

export default SliderPicker;
