import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { Map } from 'immutable';

class PhasesAndTasksActions extends React.Component {
  render() {
    return (
      <DropdownButton
        bsStyle="link"
        title="Actions"
        id="orchestration-tasks-phases-and-tasks-actions"
      >
        <MenuItem
          disabled={!this.props.selectedPhases.toList().count((selected) => selected)}
          onSelect={this.props.showMergePhasesModalFn}
        >
          Merge selected phases
        </MenuItem>
        <MenuItem
          disabled={!this.props.selectedTasks.toList().count((selected) => selected)}
          onSelect={this.props.showMoveTasksToPhaseModalFn}
        >
          Move selected tasks between phases
        </MenuItem>
        <MenuItem disabled={!this.props.hasTasks} onSelect={this.props.groupTasksByComponentTypeFn}>
          Group tasks into phases by component type
        </MenuItem>
      </DropdownButton>
    );
  }
}

PhasesAndTasksActions.propTypes = {
  selectedPhases: PropTypes.instanceOf(Map).isRequired,
  showMergePhasesModalFn: PropTypes.func.isRequired,
  selectedTasks: PropTypes.instanceOf(Map).isRequired,
  showMoveTasksToPhaseModalFn: PropTypes.func.isRequired,
  groupTasksByComponentTypeFn: PropTypes.func.isRequired,
  hasTasks: PropTypes.bool.isRequired
};

export default PhasesAndTasksActions;
