import React, { useEffect, useRef } from 'react';
import Litepicker from 'litepicker';
import type { DateTime } from 'litepicker/dist/types/datetime';

type DateObject = { start: string; end: string };
type Props = {
  onSelect: (newDate: DateObject) => void;
  initialValue?: DateObject;
};

const DatePicker = ({ onSelect, initialValue }: Props) => {
  const pickerTriggerRef = useRef<HTMLInputElement>(null);
  const pickerRef = useRef<Litepicker | null>(null);
  const initialValueRef = useRef<DateObject | undefined>(initialValue);

  useEffect(() => {
    if (pickerTriggerRef.current && !pickerRef.current) {
      pickerRef.current = new Litepicker({
        element: pickerTriggerRef.current,
        parentEl: pickerTriggerRef.current.parentElement,
        zIndex: 11,
        numberOfColumns: 2,
        numberOfMonths: 2,
        showTooltip: false,
        singleMode: false,
        maxDate: new Date(),
        autoApply: false,
        setup(picker: Litepicker) {
          picker.on('show', () => {
            picker.gotoDate(picker.getDate());
          });

          picker.on('render', (ui) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const disableTimeSelects = picker.datePicked.length < 2 && !picker.getDate();
            const timeWrapper = document.createElement('div');
            const startBlock = document.createElement('div');
            const endBlock = document.createElement('div');
            timeWrapper.className = 'time-plugin-container';
            startBlock.className = 'time-plugin-custom-block';
            endBlock.className = 'time-plugin-custom-block';

            for (const name of ['start-h', 'start-m', 'end-h', 'end-m']) {
              const [position, unit] = name.split('-');
              const isStart = position === 'start';
              const isHours = unit === 'h';
              const { start, end } = initialValueRef.current || {};
              const dateString = isStart ? start : end;
              const timeInput = document.createElement('select');
              timeInput.name = name;
              timeInput.disabled = disableTimeSelects;

              for (let option = 0; option < (isHours ? 24 : 60); option++) {
                timeInput.options.add(new Option(`${option}`, `${option}`));
              }

              if (dateString) {
                const date = new Date(dateString);
                const initialValue = isHours ? date?.getHours() : date?.getMinutes();
                timeInput.value = isNaN(initialValue) ? '0' : `${initialValue}`;
              }

              timeInput.addEventListener('change', (event) => {
                const target = event.target as HTMLInputElement;
                const newTimeValue = target.value ? parseInt(target.value, 10) : 0;

                if (!newTimeValue && newTimeValue !== 0) return;

                const initialDate = isStart ? start : end;
                const newDate =
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  picker.datePicked[isStart ? 0 : 1]?.toJSDate() ||
                  (isStart ? picker.getStartDate() : picker.getEndDate())?.toJSDate() ||
                  (initialDate && new Date(initialDate));

                if (!newDate) return;
                if (isHours) {
                  newDate.setHours(newTimeValue);
                } else {
                  newDate.setMinutes(newTimeValue);
                }

                if (isStart) {
                  picker.setStartDate(newDate);
                } else {
                  picker.setEndDate(newDate);
                }
              });

              if (isStart) {
                startBlock.appendChild(timeInput);
              } else {
                endBlock.appendChild(timeInput);
              }
            }

            timeWrapper.appendChild(startBlock);
            timeWrapper.appendChild(endBlock);
            ui.querySelector('.container__footer').prepend(timeWrapper);
          });

          picker.on('button:apply', (start?: DateTime, end?: DateTime) => {
            const startJSDate = start?.toJSDate();
            const endJSDate = end?.toJSDate();

            onSelect({
              start: startJSDate?.toISOString() || '',
              end: endJSDate?.toISOString() || ''
            });
          });
        }
      });

      pickerRef.current?.show();
    }
  }, [onSelect]);

  useEffect(() => {
    if (initialValue?.end) {
      const startDate = new Date(initialValue.start);
      const endDate = new Date(initialValue.end);

      pickerRef.current?.setDateRange(startDate, endDate);
    } else {
      pickerRef.current?.clearSelection();
    }

    initialValueRef.current = { start: initialValue?.start ?? '', end: initialValue?.end ?? '' };
  }, [initialValue?.start, initialValue?.end]);

  return <input className="hidden" ref={pickerTriggerRef} />;
};

export default DatePicker;
