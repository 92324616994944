import React from 'react';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';
import qs from 'qs';

import flowsEmptyMp4 from '../../../media/flows-empty.mp4';
import flowsEmptyImage from '../../../media/flows-empty.png';
import flowsEmptyWebM from '../../../media/flows-empty.webm';
import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '../../constants/componentIds';
import FilterPanel from '../../react/common/FilterPanel';
import NoResultsFound from '../../react/common/NoResultsFound';
import RouterLink from '../../react/common/RouterLink';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import { fileUrl } from '../../utils/fileHelpers';
import matchByWords from '../../utils/matchByWords';
import { getFolderFromMetadata } from '../components/helpers';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import TriggersStore from '../components/stores/StorageTriggersStore';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import NotificationsStore from '../notifications/store';
import { prepareOrchestrations } from '../orchestrations-v2/helpers';
import { JOB_FAILED_STATUSES } from '../queue/constants';
import JobsStore from '../queue/store';
import { routeNames as templateRouteNames } from '../templates/constants';
import FlowConfigs from './components/FlowConfigs';
import NewFlowButton from './components/NewFlowButton';

const FILTERS_GROUP = {
  SCHEDULE: 'schedule',
  STATUS: 'status',
  CONFIGURATION: 'configuration'
};

const FILTERS = {
  ALL: '',
  SCHEDULED: 'scheduled',
  NO_SCHEDULE: 'no-schedule',
  FAILED: 'failed',
  NO_CONFIGURATION: 'no-configration'
};

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      TriggersStore,
      NotificationsStore,
      JobsStore,
      DevBranchesStore
    )
  ],

  getStateFromStores() {
    const flows = prepareOrchestrations(
      InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SCHEDULER)
    );

    return {
      flows,
      allConfigurations: InstalledComponentsStore.getAll(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      latestJobs: JobsStore.getLatestConfigJobs(),
      component: ComponentsStore.getComponent(KEBOOLA_ORCHESTRATOR),
      triggers: TriggersStore.getForComponent(KEBOOLA_ORCHESTRATOR),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      sapiToken: ApplicationStore.getSapiToken(),
      notifications: NotificationsStore.getAll(),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
      allComponents: ComponentsStore.getAll(),
      hasTemplates: ApplicationStore.hasTemplates(),
      isDevModeActive: DevBranchesStore.isDevModeActive()
    };
  },

  componentDidMount() {
    const filters = RoutesStore.getRouterState().getIn(['location', 'query', 'filter']);

    if (filters) {
      this.setState({ filters: fromJS(qs.parse(filters)) });
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.filters.equals(this.state.filters)) {
      RoutesStore.getRouter().updateQuery({ filter: qs.stringify(this.state.filters.toJS()) });
    }
  },

  getInitialState() {
    return {
      filters: Map(),
      filterQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], '')
    };
  },

  render() {
    if (this.state.flows.isEmpty()) {
      return (
        <div className="blank-page pt-2">
          <h2 className="pt-2">
            Create your first data pipeline with a drag and drop flow builder
          </h2>
          <p className="intro-text">
            Flow builder is a visual representation of steps within your data pipeline.
          </p>
          <div className="intro-action">
            <div className="flex-container justify-center gap-24">
              <NewFlowButton
                className="btn-big"
                component={this.state.component}
                readOnly={this.state.readOnly}
                componentMetadata={this.state.componentsMetadata.get(KEBOOLA_ORCHESTRATOR, Map())}
              />
              {!this.state.isDevModeActive && this.state.hasTemplates && (
                <>
                  <span className="f-12 font-medium letter-spacing-wider text-muted">OR</span>
                  <RouterLink
                    className="btn btn-default btn-big"
                    to={templateRouteNames.TEMPLATES}
                    query={{ flowId: 'new' }}
                    disabled={this.state.readOnly}
                  >
                    Use Template
                  </RouterLink>
                </>
              )}
            </div>
          </div>
          <video
            loop
            muted
            autoPlay
            playsInline
            className="intro-video"
            poster={fileUrl(flowsEmptyImage)}
          >
            <source src={fileUrl(flowsEmptyMp4)} type="video/mp4;codecs=hvc1" />
            <source src={fileUrl(flowsEmptyWebM)} type="video/webm" />
          </video>
        </div>
      );
    }

    const filteredFlows = this.getFilteredFlows();

    return (
      <>
        <FilterPanel
          query={this.state.filterQuery}
          onChange={(query) => {
            this.setState({ filterQuery: query });
            RoutesStore.getRouter().updateQuery({ q: query });
          }}
          additionalActions={this.renderAdditionalActions()}
          placeholder={() => this.getPlaceholder(filteredFlows)}
        />
        {this.renderFlows(filteredFlows)}
      </>
    );
  },

  renderAdditionalActions() {
    return (
      <div className="predefined-search-list">
        {this.renderAdditionalActionsButton(FILTERS.ALL, null, 'ALL')}
        <span className="group-separator" />
        {this.renderAdditionalActionsButton(FILTERS.SCHEDULED, FILTERS_GROUP.SCHEDULE, 'Scheduled')}
        {this.renderAdditionalActionsButton(
          FILTERS.NO_SCHEDULE,
          FILTERS_GROUP.SCHEDULE,
          'Not scheduled'
        )}
        <span className="group-separator" />
        {this.renderAdditionalActionsButton(FILTERS.FAILED, FILTERS_GROUP.STATUS, 'Failed')}
        <span className="group-separator" />
        {this.renderAdditionalActionsButton(
          FILTERS.NO_CONFIGURATION,
          FILTERS_GROUP.CONFIGURATION,
          'No configuration'
        )}
      </div>
    );
  },

  renderAdditionalActionsButton(type, group, label) {
    const active = group ? this.state.filters.get(group) === type : this.state.filters.isEmpty();

    return (
      <button
        type="button"
        className={classnames('btn predefined-search-link', { active })}
        onClick={() => {
          this.setState({
            filters: group
              ? active
                ? this.state.filters.remove(group)
                : this.state.filters.set(group, type)
              : Map()
          });
        }}
      >
        {label}
      </button>
    );
  },

  renderFlows(filteredFlows) {
    if (filteredFlows.isEmpty()) {
      return <NoResultsFound entityName="flows" />;
    }

    return (
      <FlowConfigs
        hasFlows
        configurations={filteredFlows}
        allConfigurations={this.state.allConfigurations}
        admins={this.state.admins}
        currentAdmin={this.state.currentAdmin}
        triggers={this.state.triggers}
        readOnly={this.state.readOnly}
        component={this.state.component}
        latestJobs={this.state.latestJobs}
        isSearching={!!this.state.filterQuery}
        allComponents={this.state.allComponents}
        componentsMetadata={this.state.componentsMetadata}
        notifications={this.state.notifications}
        sapiToken={this.state.sapiToken}
        hasProtectedDefaultBranch={this.state.hasProtectedDefaultBranch}
      />
    );
  },

  getFilteredFlows() {
    let filteredFlows = this.state.flows;

    if (this.state.filterQuery) {
      filteredFlows = filteredFlows.filter((flow) => {
        const folder = getFolderFromMetadata(
          this.state.componentsMetadata.getIn([KEBOOLA_ORCHESTRATOR, flow.get('id')], List())
        );

        return matchByWords(
          [flow.get('name'), flow.get('description'), folder],
          this.state.filterQuery
        );
      });
    }

    this.state.filters.forEach((filter) => {
      switch (filter) {
        case FILTERS.SCHEDULED:
          filteredFlows = filteredFlows.filter((flow) => {
            return (
              !!flow.getIn(['schedulerConfiguration', 'configuration', 'schedule', 'cronTab']) ||
              this.state.triggers.has(flow.get('id'))
            );
          });
          break;

        case FILTERS.NO_SCHEDULE:
          filteredFlows = filteredFlows.filter((flow) => {
            return !(
              !!flow.getIn(['schedulerConfiguration', 'configuration', 'schedule', 'cronTab']) ||
              this.state.triggers.has(flow.get('id'))
            );
          });
          break;

        case FILTERS.FAILED:
          filteredFlows = filteredFlows.filter((flow) => {
            return JOB_FAILED_STATUSES.includes(
              this.state.latestJobs.getIn([KEBOOLA_ORCHESTRATOR, flow.get('id'), 0, 'status'])
            );
          });
          break;

        case FILTERS.NO_CONFIGURATION:
          filteredFlows = filteredFlows.filter((flow) => {
            return flow
              .getIn(['configuration', 'tasks'], List())
              .every((task) => !task.getIn(['task', 'configId']));
          });
          break;
      }
    });

    return filteredFlows;
  },

  getPlaceholder(filteredFlows) {
    const folders = filteredFlows
      .map((flow) => {
        return getFolderFromMetadata(
          this.state.componentsMetadata.getIn([KEBOOLA_ORCHESTRATOR, flow.get('id')])
        );
      })
      .filter(Boolean)
      .toSet();

    if (!folders.isEmpty()) {
      return `Search${
        this.state.filters.isEmpty() ? ' all' : ''
      } folders (${folders.count()}) and flows (${filteredFlows.count()})`;
    }

    return `Search${this.state.filters.isEmpty() ? ' all' : ''} flows (${filteredFlows.count()})`;
  }
});

export default Index;
