import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import createReactClass from 'create-react-class';

const TargetDatasetSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      dataset: PropTypes.string.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    const { value, onChange, disabled } = this.props;

    return (
      <div className="form-horizontal">
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Dataset
          </Col>
          <Col sm={8}>
            <FormControl
              type="text"
              disabled={disabled}
              onChange={(e) => onChange({ dataset: e.target.value })}
              value={value.dataset}
              placeholder="my-dataset"
            />
            <HelpBlock>Google BigQuery dataset name.</HelpBlock>
          </Col>
        </FormGroup>
      </div>
    );
  }
});

export default TargetDatasetSection;
