import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import Confirm from '../../../../react/common/Confirm';
import SwitchButton from '../../../../react/common/SwitchButton';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import fromJSOrdered from '../../../../utils/fromJSOrdered';
import ComponentsStore from '../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';
import SimplifiedUiHeaderButtons from '../../../simplified-ui/HeaderButtons';
import { shouldShowSimplifiedUi } from '../../../simplified-ui/helpers';
import Actions from '../../ConfigurationsActionCreators';
import Store from '../../ConfigurationsStore';
import isParsableConfiguration from '../../utils/isParsableConfiguration';
import sectionsUtils from '../../utils/sections';
import SaveButton from './SaveButton';

const IndexHeaderButton = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore, ComponentsStore, Store)],

  getStateFromStores() {
    const settings = RoutesStore.getRouteSettings();
    const componentId = settings.get('componentId');
    const configId = RoutesStore.getCurrentRouteParam('config');
    const conformFn = settings.getIn(['index', 'onConform'], (config) => config);
    const context = InstalledComponentsStore.getConfigurationContext(componentId, configId);
    const configuration = Store.getConfiguration(componentId, configId);
    const editingConfiguration = Store.getEditingJsonConfiguration(componentId, configId);
    const sections = settings.getIn(['index', 'sections'], List());
    const createFn = sectionsUtils.makeCreateFn(sections);
    const parseFn = sectionsUtils.makeParseFn(sections, conformFn, context);
    const isJsonValid = Store.isEditingJsonConfigurationValid(componentId, configId);
    const configurationBySections = Store.getEditingConfiguration(componentId, configId, parseFn);
    const storedConfigurationSections = parseFn(configuration);

    return {
      componentId,
      configId,
      configuration,
      component: ComponentsStore.getComponent(componentId),
      isChanged: !configurationBySections.equals(storedConfigurationSections),
      isJsonChanged: Store.isEditingJsonConfiguration(componentId, configId),
      isParsable: isParsableConfiguration(conformFn(configuration), parseFn, createFn),
      isJsonParsable:
        isJsonValid &&
        isParsableConfiguration(conformFn(fromJSOrdered(editingConfiguration)), parseFn, createFn),
      hasJsonEditor: Store.hasJsonEditor(componentId, configId, parseFn, createFn, conformFn),
      isJsonValid: Store.isEditingJsonConfigurationValid(componentId, configId),
      isJsonSaving: Store.getPendingActions(componentId, configId).has('save-json'),
      readOnly: ApplicationStore.isReadOnly()
    };
  },

  render() {
    if (shouldShowSimplifiedUi(this.state.component, this.state.configuration)) {
      return <SimplifiedUiHeaderButtons />;
    }

    if (this.state.hasJsonEditor) {
      return (
        <ButtonToolbar className="no-wrap">
          {this.renderSimplifiedHeaderButton()}
          {this.renderCloseJsonButton()}
          {this.renderSaveJsonButton()}
        </ButtonToolbar>
      );
    }

    return (
      <ButtonToolbar className="no-wrap">
        {this.renderSimplifiedHeaderButton()}
        {this.renderOpenJsonButton()}
      </ButtonToolbar>
    );
  },

  renderCloseJsonButton() {
    if (!this.state.isParsable) {
      return (
        <SwitchButton
          isDisabled
          tooltip="Can't close code editor, configuration is not compatible. Revert your changes to allow switching back to the visual editor."
          label="Visual editor"
        />
      );
    }

    if (this.state.isJsonChanged) {
      return (
        <Confirm
          title="Close code editor"
          text="All unsaved changes will be discard."
          buttonLabel="Confirm"
          onConfirm={this.closeJsonEditor}
        >
          <SwitchButton label="Visual editor" />
        </Confirm>
      );
    }

    return (
      <SwitchButton
        label="Visual editor"
        tooltip="Close code editor"
        onClick={this.closeJsonEditor}
      />
    );
  },

  renderOpenJsonButton() {
    if (this.state.isChanged) {
      return (
        <Confirm
          title="Open code editor"
          text="All unsaved changes will be discard."
          buttonLabel="Confirm"
          onConfirm={this.openJsonEditor}
        >
          <SwitchButton label="Code editor" />
        </Confirm>
      );
    }

    return (
      <SwitchButton tooltip="Open code editor" onClick={this.openJsonEditor} label="Code editor" />
    );
  },

  renderSimplifiedHeaderButton() {
    if (!shouldShowSimplifiedUi(this.state.component, this.state.configuration)) {
      return null;
    }

    return <SimplifiedUiHeaderButtons />;
  },

  renderSaveJsonButton() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <SaveButton
        isSaving={this.state.isJsonSaving}
        isChanged={this.state.isJsonChanged}
        onReset={this.resetJsonConfiguration}
        onSave={this.saveJsonConfiguration}
        disabled={!this.state.isJsonValid}
        showModal={this.state.isParsable && !this.state.isJsonParsable}
      />
    );
  },

  saveJsonConfiguration() {
    Actions.saveJsonConfiguration(
      this.state.componentId,
      this.state.configId,
      'Configuration edited manually'
    );
  },

  resetJsonConfiguration() {
    Actions.resetJsonConfiguration(this.state.componentId, this.state.configId);
  },

  closeJsonEditor() {
    Actions.closeJsonEditor(this.state.componentId, this.state.configId);
  },

  openJsonEditor() {
    Actions.openJsonEditor(this.state.componentId, this.state.configId);
  }
});

export default IndexHeaderButton;
