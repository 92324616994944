import type { SortState } from '../../../react/common/SortByName';

const sortRowsByName = (sort: SortState) => {
  return (rowA: Map<string, any>, rowB: Map<string, any>) => {
    if (!sort) {
      return 0;
    }

    return rowA.get('name').localeCompare(rowB.get('name')) * (sort === 'asc' ? 1 : -1);
  };
};

export { sortRowsByName };
