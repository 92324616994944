import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import RunActionLink from '../../../../react/common/ActionControls/RunActionLink';
import { RunActionLinkStatus } from '../../../../react/common/ActionControls/RunActionLinkStatus';
import ConfirmButtons from '../../../../react/common/ConfirmButtons';
import Loader from '../../../../react/common/Loader';
import ModalIcon from '../../../../react/common/ModalIcon';
import PanelWithDetails from '../../../../react/common/PanelWithDetails';
import Link from '../../../../react/common/RouterLink';
import RowActionMenuItem from '../../../../react/common/RowActionMenuItem';
import OrchestrationActionCreators from '../../ActionCreators';
import TaskSelectTable from '../components/TaskSelectTable';

const RunOrchestration = createReactClass({
  propTypes: {
    orchestration: PropTypes.object.isRequired,
    onRequestRun: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    tasks: PropTypes.object,
    onRequestCancel: PropTypes.func,
    onOpen: PropTypes.func,
    buttonBlock: PropTypes.bool,
    loadTasksFn: PropTypes.func,
    onKeyDown: PropTypes.func
  },

  getInitialState() {
    return { showModal: false };
  },

  getDefaultProps() {
    return {
      buttonBlock: false
    };
  },

  close() {
    return this.setState({
      showModal: false
    });
  },

  open() {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
    if (!this.props.tasks && this.props.loadTasksFn) {
      this.props.loadTasksFn();
    }
    return this.setState({
      showModal: true
    });
  },

  render() {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        {this.renderOpenButton()}
        <Modal show={this.state.showModal} bsSize="large" onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>{`Run orchestration ${this.props.orchestration.get('name')}`}</Modal.Title>
            <ModalIcon.Play />
          </Modal.Header>
          <Modal.Body>{this.renderTasksTable()}</Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isDisabled={!this._isValid() || this.props.isLoading}
              saveLabel="Run"
              saveStyle="primary"
              onSave={this._handleRun}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  },

  renderTasksTable() {
    if (!this.state.showModal) {
      return null;
    }

    if (this.props.isLoading && !this.hasTasks()) {
      return (
        <p>
          <Loader /> Loading tasks...
        </p>
      );
    }

    if (!this.hasTasks()) {
      return (
        <Alert bsStyle="warning">
          Orchestration cannot be run without tasks. Please{' '}
          <Link
            to="orchestrationTasks"
            params={{
              orchestrationId: this.props.orchestration.get('id')
            }}
          >
            add tasks
          </Link>{' '}
          and run the orchestration again.
        </Alert>
      );
    }

    return (
      <div>
        <p>
          {'You are about to run the orchestration '}
          <strong>
            {this.props.orchestration.get('name')}
            {' manually and the notifications will be sent only to you.'}
          </strong>
        </p>
        {this.props.tasks && (
          <PanelWithDetails labelCollapse="Hide Tasks" labelOpen="Show Tasks">
            <TaskSelectTable
              tasks={this.props.tasks}
              onTaskUpdate={this._handleTaskUpdate}
              onTasksUpdate={this._handleTasksUpdate}
            />
          </PanelWithDetails>
        )}
      </div>
    );
  },

  renderOpenButton() {
    if (this.props.buttonBlock) {
      return (
        <RunActionLink
          label="Run Orchestration"
          onClick={this._handleOpenClick}
          status={this.getActionLinkStatus()}
        />
      );
    }

    return (
      <RowActionMenuItem
        onSelect={this._handleOpenClick}
        disabled={this.props.isLoading || !this.props.orchestration.get('active')}
        onKeyDown={this.props.onKeyDown}
      >
        {this.props.isLoading ? <Loader /> : <FontAwesomeIcon icon="circle-play" fixedWidth />}
        Run Orchestration
      </RowActionMenuItem>
    );
  },

  getActionLinkStatus() {
    if (this.props.isLoading) {
      return RunActionLinkStatus.RUNNING;
    }
    if (!this.props.orchestration.get('active')) {
      return RunActionLinkStatus.DISABLED;
    }
    return RunActionLinkStatus.READY;
  },

  hasTasks() {
    return this.props.tasks && this.props.tasks.count() > 0;
  },

  _handleOpenClick(e) {
    e.preventDefault();
    e.stopPropagation();
    return this.open();
  },

  _handleRun() {
    this.close();
    return this.props.onRequestRun();
  },

  _handleCancel() {
    if (this.props.onRequestCancel) {
      this.close();
      return this.props.onRequestCancel();
    } else {
      return this.close();
    }
  },

  _handleTasksUpdate(updatedTasks) {
    return OrchestrationActionCreators.updateOrchestrationRunTasksEdit(
      this.props.orchestration.get('id'),
      updatedTasks
    );
  },

  _handleTaskUpdate(updatedTask) {
    let tasks = this.props.tasks.map((phase) => {
      const newTasks = phase.get('tasks').map((t) => {
        if (t.get('id') === updatedTask.get('id')) {
          return updatedTask;
        } else {
          return t;
        }
      });
      return phase.set('tasks', newTasks);
    });

    return OrchestrationActionCreators.updateOrchestrationRunTasksEdit(
      this.props.orchestration.get('id'),
      tasks
    );
  },

  _isValid() {
    if (!this.props.tasks) {
      return true;
    }

    const allTasks = this.props.tasks;

    if (allTasks) {
      return (
        allTasks
          .filter(
            (tasks) =>
              tasks
                .get('tasks')
                .filter((task) => task.get('active'))
                .count() > 0
          )
          .count() > 0
      );
    }
  }
});

export default RunOrchestration;
