import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import Sortable from 'react-sortablejs';
import { List, Map } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA } from '../../../constants/componentIds';
import { defaultOptions } from '../../../constants/sortable';
import { hasDynamicBackendSizeEnabled } from '../../components/helpers';
import AddTaskModal from '../../orchestrations/react/modals/add-task/AddTaskModal';
import PhaseNameModal from './PhaseNameModal';
import PhaseRow from './PhaseRow';
import PhasesAndTasksActions from './PhasesAndTasksActions';
import SelectTargetPhaseModal from './SelectTargetPhaseModal';
import TaskRow from './TaskRow';

class TasksTable extends React.Component {
  constructor(props) {
    super(props);

    this.renderPhaseRow = this.renderPhaseRow.bind(this);
    this.renderTasksRows = this.renderTasksRows.bind(this);
    this.showAddTaskToPhaseModal = this.showAddTaskToPhaseModal.bind(this);

    this.state = {
      // phase name change
      showPhaseNameModal: false,
      phaseToEdit: Map(),
      // task add
      addTaskToPhase: Map(),
      addTaskSearchQuery: '',
      selectedPhases: Map(),
      showMergePhasesModal: false,
      selectedTasks: Map(),
      showMoveTasksToPhaseModal: false
    };
  }

  render() {
    const showBackendSize =
      this.props.hasSnowflakeDynamicBackendSize || this.props.hasJobsDynamicBackendSize;

    if (!this.props.phasesWithTasks.count()) {
      return (
        <Table>
          <thead>
            <tr>
              <th className="w-50" />
              <th className="w-150" />
              <th>Component</th>
              <th className="w-400">Configuration</th>
              <th>Enabled</th>
              <th>Continue on Failure</th>
              <th className="w-150" />
            </tr>
          </thead>
        </Table>
      );
    }

    return (
      <>
        <Sortable
          tag="table"
          className="table"
          options={{ ...defaultOptions, filter: 'thead' }}
          onChange={(order, sortable, event) => {
            const oldIndex = Math.max(1, event.oldIndex) - 1;
            const newIndex = Math.max(1, event.newIndex) - 1;
            this.props.movePhaseFn(oldIndex, newIndex);
          }}
        >
          <thead>
            <tr>
              <th className="w-50" />
              <th className="w-150">
                <PhasesAndTasksActions
                  selectedPhases={this.state.selectedPhases}
                  showMergePhasesModalFn={() => {
                    this.setState({
                      showMergePhasesModal: true
                    });
                  }}
                  selectedTasks={this.state.selectedTasks}
                  showMoveTasksToPhaseModalFn={() => {
                    this.setState({
                      showMoveTasksToPhaseModal: true
                    });
                  }}
                  groupTasksByComponentTypeFn={this.props.groupTasksByComponentTypeFn}
                  hasTasks={
                    !!this.props.phasesWithTasks.flatMap((phases) => phases.get('tasks')).count()
                  }
                />
              </th>
              <th>Component</th>
              <th className="w-400">Configuration</th>
              <th>Enabled</th>
              <th>Continue on Failure</th>
              {showBackendSize && <th className="w-200">Backend Size</th>}
              <th className="w-150" />
            </tr>
          </thead>
          {this.props.phasesWithTasks
            .map((phase) => this.renderPhaseRow(phase, showBackendSize))
            .toArray()}
          <tfoot>
            <tr>
              <td colSpan={showBackendSize ? 8 : 7}>
                <div className="pb-2" />
              </td>
            </tr>
          </tfoot>
        </Sortable>
        <PhaseNameModal
          phase={this.state.phaseToEdit}
          show={this.state.showPhaseNameModal}
          onHide={() => {
            this.setState({
              showPhaseNameModal: false,
              phaseToEdit: Map()
            });
          }}
          onChangeName={this.props.phaseNameUpdateFn}
        />
        <AddTaskModal
          onConfigurationSelect={this.props.addTaskFn}
          phaseId={this.state.addTaskToPhase.get('id')}
          phaseName={this.state.addTaskToPhase.get('name')}
          show={!this.state.addTaskToPhase.isEmpty()}
          onHide={() => {
            this.setState({
              addTaskToPhase: Map()
            });
          }}
          onChangeSearchQuery={(query) => {
            return this.setState({
              addTaskSearchQuery: query
            });
          }}
          searchQuery={this.state.addTaskSearchQuery}
        />
        <SelectTargetPhaseModal
          title="Merge Selected Phases"
          buttonLabel="Merge"
          show={this.state.showMergePhasesModal}
          phases={this.props.phasesWithTasks}
          onSelectPhase={(selectedPhaseId) => {
            this.props.mergePhasesFn(
              this.state.selectedPhases
                .filter((phase) => phase)
                .keySeq()
                .toJS(),
              selectedPhaseId
            );
          }}
          onHide={() => {
            this.setState({
              showMergePhasesModal: false,
              selectedPhases: Map()
            });
          }}
        />
        <SelectTargetPhaseModal
          title="Move Selected Tasks to Phase"
          buttonLabel="Move"
          show={this.state.showMoveTasksToPhaseModal}
          phases={this.props.phasesWithTasks}
          onSelectPhase={(selectedPhaseId) => {
            this.props.moveTasksToPhaseFn(
              this.state.selectedTasks
                .filter((task) => task)
                .keySeq()
                .toJS(),
              selectedPhaseId
            );
          }}
          onHide={() => {
            this.setState({
              showMoveTasksToPhaseModal: false,
              selectedTasks: Map()
            });
          }}
        />
      </>
    );
  }

  renderPhaseRow(phase, showBackendSize) {
    return (
      <tbody key={phase.get('id')}>
        <PhaseRow
          phase={phase}
          readOnly={this.props.readOnly}
          showPhaseNameModalFn={() => {
            this.setState({
              showPhaseNameModal: true,
              phaseToEdit: phase
            });
          }}
          addTaskToPhaseFn={() => this.showAddTaskToPhaseModal(phase)}
          allowDragAndDrop={this.props.phasesWithTasks.count() > 1}
          isSelected={this.state.selectedPhases.get(phase.get('id'), false)}
          onPhaseToggleFn={(selected) => {
            this.setState({
              selectedPhases: this.state.selectedPhases.set(phase.get('id'), selected)
            });
          }}
          showBackendSize={showBackendSize}
        />
        {this.renderTasksRows(phase, showBackendSize)}
      </tbody>
    );
  }

  showAddTaskToPhaseModal(phase) {
    this.setState({
      addTaskToPhase: phase
    });
  }

  renderTasksRows(phase, showBackendSize) {
    if (phase.get('tasks').isEmpty()) {
      return (
        <tr key={`empty-phase-row-${phase.get('id')}`} className="bg-color-white">
          <td colSpan={showBackendSize ? 8 : 7}>
            <div className="text-center">
              <p>No tasks assigned to this phase yet. Empty phases will not be saved.</p>
            </div>
          </td>
        </tr>
      );
    }

    return phase.get('tasks').map((task) => {
      const configuration = this.props.configurations.getIn(
        [task.getIn(['task', 'componentId']), 'configurations', task.getIn(['task', 'configId'])],
        Map()
      );
      const component = this.props.components.get(
        task.getIn(['task', 'componentId']) === KEBOOLA_EX_SAMPLE_DATA
          ? configuration.getIn(
              ['configuration', 'parameters', 'componentId'],
              KEBOOLA_EX_SAMPLE_DATA
            )
          : task.getIn(['task', 'componentId']),
        Map()
      );

      return (
        <TaskRow
          key={`phase${phase.get('id')}-task${task.get('id')}`}
          task={task}
          readOnly={this.props.readOnly}
          component={component}
          configuration={configuration}
          onDeleteFn={() => this.props.removeTaskFn(task.get('id'))}
          isSelected={this.state.selectedTasks.get(task.get('id'), false)}
          onTaskToggleFn={(selected) => {
            this.setState({
              selectedTasks: this.state.selectedTasks.set(task.get('id'), selected)
            });
          }}
          onTaskUpdateFn={(path, value) => this.props.changeTaskFn(task.get('id'), path, value)}
          hasFlows={this.props.hasFlows}
          hasSnowflakeDynamicBackendSize={this.props.hasSnowflakeDynamicBackendSize}
          hasJobsDynamicBackendSize={this.props.hasJobsDynamicBackendSize}
          showBackendSize={showBackendSize}
          hasBackendSize={hasDynamicBackendSizeEnabled(
            component,
            this.props.hasSnowflakeDynamicBackendSize,
            this.props.hasJobsDynamicBackendSize
          )}
          isSingleTenant={this.props.isSingleTenant}
        />
      );
    });
  }
}

TasksTable.propTypes = {
  configurations: PropTypes.instanceOf(Map).isRequired,
  components: PropTypes.instanceOf(Map).isRequired,
  phasesWithTasks: PropTypes.instanceOf(List).isRequired,
  phaseNameUpdateFn: PropTypes.func.isRequired,
  movePhaseFn: PropTypes.func.isRequired,
  addTaskFn: PropTypes.func.isRequired,
  removeTaskFn: PropTypes.func.isRequired,
  changeTaskFn: PropTypes.func.isRequired,
  mergePhasesFn: PropTypes.func.isRequired,
  moveTasksToPhaseFn: PropTypes.func.isRequired,
  groupTasksByComponentTypeFn: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  hasSnowflakeDynamicBackendSize: PropTypes.bool.isRequired,
  hasJobsDynamicBackendSize: PropTypes.bool.isRequired,
  isSingleTenant: PropTypes.bool.isRequired
};

export default TasksTable;
