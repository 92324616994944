import React from 'react';
import Promise from 'bluebird';

import DummyBreadcrumb from '../../react/common/DummyBreadcrumb';
import nextTick from '../../utils/nextTick';
import StorageActionCreators from '../components/StorageActionCreators';
import StorageJobsStore from '../components/stores/StorageJobsStore';
import BucketLabels from './components/BucketLabels';
import BucketNameEdit from './components/BucketNameEdit';
import BucketTitle from './components/BucketTitle';
import CreateTableButton from './components/CreateTableButton';
import HeaderButtons from './components/HeaderButtons';
import TableLabels from './components/TableLabels';
import TableNameEdit from './components/TableNameEdit';
import TableTitle from './components/TableTitle';
import UploadFileButton from './components/UploadFileButton';
import {
  loadBucketDetail,
  loadFiles,
  loadJob,
  loadJobs,
  loadTableDetail,
  resetStorageStore,
  tokenVerify,
  updateFilesSearchQuery
} from './actions';
import Bucket from './Bucket';
import { routeNames } from './constants';
import Events from './Events';
import Files from './Files';
import Index from './Index';
import Jobs from './Jobs';
import Table from './Table';

const STORAGE_TITLE = 'Storage';

const routes = {
  name: routeNames.ROOT,
  title: STORAGE_TITLE,
  headerButtonsHandler: HeaderButtons,
  defaultRouteHandler: Index,
  onLeave: () => nextTick(resetStorageStore),
  requireData: [() => tokenVerify(), () => StorageActionCreators.loadBucketsAndTables()],
  childRoutes: [
    {
      name: routeNames.JOBS,
      path: 'jobs(/:jobId)',
      title: STORAGE_TITLE,
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: Jobs,
      poll: {
        interval: 20,
        action: () => loadJobs(),
        skipFirst: true
      },
      requireData: [
        ({ jobId }) => {
          if (jobId) {
            if (StorageJobsStore.getAll().isEmpty()) {
              loadJobs();
            }

            if (!StorageJobsStore.getJob(jobId).isEmpty()) {
              return Promise.resolve();
            }

            return loadJob(jobId);
          }

          // init loading without waiting, we will show loading on the index page
          loadJobs();
        }
      ]
    },
    {
      name: routeNames.EVENTS,
      path: 'events',
      title: STORAGE_TITLE,
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: Events
    },
    {
      name: routeNames.FILES,
      path: 'files',
      title: STORAGE_TITLE,
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: Files,
      headerButtonsHandler: UploadFileButton,
      poll: {
        interval: 20,
        skipFirst: true,
        action: (params, query) => loadFiles({ ...(query.q && { q: query.q }) })
      },
      requireData: [
        (params, query) => {
          if (query.q || query.q === '') {
            updateFilesSearchQuery(query.q);

            return loadFiles({ q: query.q });
          }

          return loadFiles();
        }
      ]
    },
    {
      name: routeNames.BUCKET,
      path: ':bucketId',
      titleHandler: BucketTitle,
      nameEdit(params) {
        return <BucketNameEdit key={params.bucketId} bucketId={params.bucketId} />;
      },
      requireData: (params) => loadBucketDetail(params.bucketId),
      defaultRouteHandler: Bucket,
      headerButtonsHandler: CreateTableButton,
      labelHandler: BucketLabels,
      childRoutes: [
        {
          name: routeNames.TABLE,
          path: ':tableName',
          titleHandler: TableTitle,
          labelHandler: TableLabels,
          nameEdit(params) {
            return (
              <TableNameEdit
                key={`${params.bucketId}.${params.tableName}`}
                bucketId={params.bucketId}
                tableName={params.tableName}
              />
            );
          },
          requireData: (params) => loadTableDetail(`${params.bucketId}.${params.tableName}`),
          defaultRouteHandler: Table
        }
      ]
    }
  ]
};

export default routes;
