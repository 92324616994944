import React from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Map } from 'immutable';
import { ltrim } from 'underscore.string';

import FullScreenModal from '../../react/common/FullScreenModal';
import Loader from '../../react/common/Loader';
import RouterLink from '../../react/common/RouterLink';
import TabLink from '../../react/common/TabLink';
import TabNav from '../../react/common/TabNav';
import Truncated from '../../react/common/Truncated';
import useForceUpdate from '../../react/hooks/useForceUpdate';
import useStores from '../../react/hooks/useStores';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import { canWriteBucket } from '../admin/privileges';
import SapiTableLink from '../components/react/components/StorageApiTableLink';
import StorageActionCreators from '../components/StorageActionCreators';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import StorageBucketsStore from '../components/stores/StorageBucketsStore';
import StorageTablesStore from '../components/stores/StorageTablesStore';
import { isCreatedInDevBranch } from '../dev-branches/helpers';
import { factory as eventsFactory } from '../sapi-events/TableEventsService';
import ColumnsPreview from '../storage/components/ColumnsPreview';
import DataSample from '../storage/components/DataSample';
import DataSampleColumnOrderInfo from '../storage/components/DataSampleColumnOrderInfo';
import DataSampleHelp from '../storage/components/DataSampleHelp';
import TableEvents from '../storage/components/Events';
import NativeTypesLabel from '../storage/components/NativeTypesLabel';
import TableGraph from '../storage/components/TableGraph';
import TableOverview from '../storage/components/TableOverview';
import TableSchema from '../storage/components/TableSchema';
import TableUsage from '../storage/components/TableUsage';
import { getTableAliases, getTableLinks } from '../storage/helpers';
import { parseLocationContext } from './helpers';

const tabs = {
  OVERVIEW: 'overview',
  SCHEMA: 'schema',
  EVENTS: 'events',
  DATA_SAMPLE: 'data-sample',
  GRAPH: 'graph',
  USAGE: 'usage'
};

const Index = () => {
  const forceUpdate = useForceUpdate();
  const [isReloadingData, setIsReloadingData] = React.useState(false);
  const state = useStores(
    () => {
      const routerState = RoutesStore.getRouterState();
      const tableId = RoutesStore.getCurrentRouteParam('tableId');
      const table = StorageTablesStore.getTable(tableId, Map(), { caseInsensitiveFallback: true });
      const bucket = table.get('bucket', Map());
      const sapiToken = ApplicationStore.getSapiToken();
      const hasShowTransformationMigration = ApplicationStore.hasShowTransformationMigration();

      return {
        table,
        bucket,
        tableId,
        routerState,
        sapiToken,
        hasShowTransformationMigration,
        activeTab: ltrim(window.location.hash, '#') || tabs.OVERVIEW,
        tables: StorageTablesStore.getAll(),
        buckets: StorageBucketsStore.getAll(),
        canWriteTable: canWriteBucket(sapiToken, bucket),
        addingColumn: StorageTablesStore.getAddingColumn(),
        deletingColumn: StorageTablesStore.getDeletingColumn().get(table.get('id'), Map()),
        creatingPrimaryKey: StorageTablesStore.getIsCreatingPrimaryKey(table.get('id')),
        deletingPrimaryKey: StorageTablesStore.getIsDeletingPrimaryKey(table.get('id')),
        settingAliasFilter: StorageTablesStore.getIsSettingAliasFilter(table.get('id')),
        removingAliasFilter: StorageTablesStore.getIsRemovingAliasFilter(table.get('id')),
        updatingTable: StorageTablesStore.getIsUpdatingTable(table.get('id')),
        configurations: InstalledComponentsStore.getAll(),
        components: ComponentsStore.getAll(),
        hasGraph: !ApplicationStore.hasNewTransformationsOnly(),
        urlTemplates: ApplicationStore.getUrlTemplates(),
        readOnly: ApplicationStore.isReadOnly(),
        hasUsage: ApplicationStore.hasNewTransformationsOnly() || hasShowTransformationMigration,
        admins: ApplicationStore.getAdmins(),
        hasFlows: ApplicationStore.hasFlows()
      };
    },
    [],
    [
      StorageTablesStore,
      StorageBucketsStore,
      InstalledComponentsStore,
      ComponentsStore,
      ApplicationStore,
      RoutesStore
    ]
  );

  const onHide = () => {
    const { pathname, query, hash, routeState } = parseLocationContext(
      state.routerState.get('location', Map())
    );

    RoutesStore.getRouter().transitionTo(pathname, null, query, hash, routeState);
  };

  const renderTabLink = (tab: string, label: string) => {
    return (
      <TabLink
        active={state.activeTab === tab}
        to="tablePreview"
        params={{ tableId: state.tableId }}
        query={{ context: state.routerState.getIn(['location', 'query', 'context']) }}
        hash={`#${tab}`}
      >
        {label}
      </TabLink>
    );
  };

  const renderTabContent = () => {
    const tableAliases = getTableAliases(state.table, state.tables, state.sapiToken);
    const tableLinks = getTableLinks(
      state.table,
      state.buckets.get(state.table.getIn(['bucket', 'id']), Map())
    );

    if (state.activeTab === tabs.SCHEMA) {
      return (
        <TableSchema
          sapiToken={state.sapiToken}
          tables={state.tables}
          table={state.table}
          canWriteTable={state.canWriteTable}
          addingColumn={state.addingColumn}
          deletingColumn={state.deletingColumn}
          tableAliases={tableAliases}
          tableLinks={tableLinks}
          urlTemplates={state.urlTemplates}
          components={state.components}
        />
      );
    }

    if (state.activeTab === tabs.EVENTS) {
      return <TableEvents eventsFactory={eventsFactory(state.tableId)} admins={state.admins} />;
    }

    if (state.activeTab === tabs.DATA_SAMPLE) {
      return (
        <DataSample
          backend={state.bucket.get('backend')}
          table={state.table}
          bucket={state.bucket}
          onChangeColumnOrder={forceUpdate}
        />
      );
    }

    if (state.hasGraph && state.activeTab === tabs.GRAPH) {
      return (
        <TableGraph key={state.table.get('lastImportDate') || tabs.GRAPH} table={state.table} />
      );
    }

    if (state.hasUsage && state.activeTab === tabs.USAGE) {
      return (
        <TableUsage
          admins={state.admins}
          tableId={state.table.get('id')}
          configurations={state.configurations}
          hasShowTransformationMigration={state.hasShowTransformationMigration}
          hasFlows={state.hasFlows}
        />
      );
    }

    return (
      <>
        <TableOverview
          readOnly={state.readOnly}
          table={state.table}
          tables={state.tables}
          components={state.components}
          configurations={state.configurations}
          tableAliases={tableAliases}
          tableLinks={tableLinks}
          sapiToken={state.sapiToken}
          urlTemplates={state.urlTemplates}
          creatingPrimaryKey={state.creatingPrimaryKey}
          deletingPrimaryKey={state.deletingPrimaryKey}
          settingAliasFilter={state.settingAliasFilter}
          removingAliasFilter={state.removingAliasFilter}
          updatingTable={state.updatingTable}
          canWriteTable={state.canWriteTable}
          hasFlows={state.hasFlows}
        />
        <ColumnsPreview tableId={state.tableId} />
      </>
    );
  };

  const renderTabs = () => {
    if (state.table.isEmpty()) {
      return <p>Table not found</p>;
    }

    return (
      <>
        <div className="tabs-with-border-wrapper flex-container flex-nowrap">
          <TabNav className="no-shrink pr-2">
            {renderTabLink(tabs.OVERVIEW, 'Overview')}
            {renderTabLink(tabs.SCHEMA, 'Schema')}
            {renderTabLink(tabs.EVENTS, 'Events')}
            {renderTabLink(tabs.DATA_SAMPLE, 'Data Sample')}
            {state.hasGraph && renderTabLink(tabs.GRAPH, 'Graph')}
            {state.hasUsage && renderTabLink(tabs.USAGE, 'Usage')}
          </TabNav>
          {ltrim(window.location.hash, '#') === tabs.DATA_SAMPLE && (
            <div className="flex-container flex-end">
              <DataSampleColumnOrderInfo
                tableId={state.table.get('id')}
                onResetColumnsOrder={forceUpdate}
              />
              <DataSampleHelp backend={state.bucket.get('backend')} />
            </div>
          )}
        </div>
        {renderTabContent()}
      </>
    );
  };

  const renderTopButton = () => {
    return (
      <ButtonToolbar>
        {!state.table.isEmpty() && (
          <>
            <SapiTableLink tableId={state.tableId} className="btn btn-default">
              <FontAwesomeIcon icon="warehouse" className="icon-addon-right" />
              Explore in Storage
            </SapiTableLink>
            <Button
              onClick={() => {
                setIsReloadingData(true);
                StorageActionCreators.loadTableDetailForce(state.tableId).finally(() => {
                  setIsReloadingData(false);
                });
              }}
              disabled={isReloadingData}
            >
              {isReloadingData ? (
                <Loader className="icon-addon-right" />
              ) : (
                <FontAwesomeIcon icon="rotate" className="icon-addon-right" fixedWidth />
              )}
              Reload Data
            </Button>
            <span className="btn-separator" />
          </>
        )}
        <button className="btn btn-default" onClick={onHide}>
          <FontAwesomeIcon icon="xmark" />
        </button>
      </ButtonToolbar>
    );
  };

  const renderBreadcrumb = () => {
    const location = state.routerState.get('location', Map());

    return (
      <div className="breadcrumb">
        <RouterLink
          className="active dark muted"
          to="bucketPreview"
          state={location.get('state')}
          query={{ context: location.getIn(['query', 'context']) }}
          params={{ bucketId: state.bucket.get('id') }}
        >
          <FontAwesomeIcon
            icon="folder"
            className={classNames('text-muted f-16 icon-addon-right', {
              'dev-bucket': isCreatedInDevBranch(state.bucket)
            })}
          />
          {state.bucket.get('displayName')}
        </RouterLink>
      </div>
    );
  };

  return (
    <FullScreenModal autoFocus className="full-screen-generic-overview" onHide={onHide}>
      <Modal.Header>
        <div className="flex-container">
          {state.table.isEmpty() ? (
            <h4 className="modal-title">{state.tableId}</h4>
          ) : (
            <div className="modal-title">
              {renderBreadcrumb()}
              <h4 className="flex-container flex-start">
                <FontAwesomeIcon icon="table" className="text-muted icon-addon-right" />
                <Truncated text={state.table.get('displayName')} />
                <NativeTypesLabel
                  className="icon-addon-left"
                  isTyped={state.table.get('isTyped', false)}
                />
              </h4>
            </div>
          )}
          {renderTopButton()}
        </div>
      </Modal.Header>
      <Modal.Body>{renderTabs()}</Modal.Body>
    </FullScreenModal>
  );
};

export default Index;
