import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import { HEADER_BUTTONS } from '../../../constants/external';
import { APP_ROUTE } from '../../../constants/routeNames';
import ComponentNameEdit from '../../../modules/components/react/components/ComponentName';
import InstalledComponentsStore from '../../../modules/components/stores/InstalledComponentsStore';
import ComponentTypeIcon from '../../../modules/components-directory/components/ComponentTypeIcon';
import { routeNames as componentsRoutes } from '../../../modules/components-directory/constants';
import ConfigurationRowName from '../../../modules/configurations/react/components/ConfigurationRowName';
import BackToFlowButton from '../../../modules/flows/components/BackToFlowButton';
import { routeNames as flowsRouteNames } from '../../../modules/flows/constants';
import { routeNames as orchestrationsRouteNames } from '../../../modules/orchestrations-v2/constants';
import { isPhaseJob } from '../../../modules/queue/helpers';
import QueueJobs from '../../../modules/queue/store';
import TransformationType from '../../../modules/transformations/react/components/TransformationType';
import { routeNames as transformationsRouteNames } from '../../../modules/transformations-v2/constants';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import ComponentIcon from '../../common/ComponentIcon';
import Link from '../../common/RouterLink';
import RoutePendingIndicator from '../../layout/RoutePendingIndicator';
import createStoreMixin from '../../mixins/createStoreMixin';
import { resolveComponent } from './helpers';

const TopBarNav = createReactClass({
  mixins: [
    immutableMixin,
    createStoreMixin(RoutesStore, ApplicationStore, InstalledComponentsStore)
  ],

  propTypes: {
    backFlow: PropTypes.instanceOf(Map).isRequired
  },

  getStateFromStores() {
    const realComponentId = RoutesStore.getCurrentRouteComponentId();
    const currentRouteConfig = RoutesStore.getCurrentRouteConfig();
    const routerState = RoutesStore.getRouterState();

    return {
      routerState,
      realComponentId,
      currentRouteConfig,
      component: resolveComponent(),
      breadcrumbs: RoutesStore.getBreadcrumbs(),
      routerError: RoutesStore.getError(),
      currentRouteParams: routerState.get('params', Map()),
      currentRouteLocation: routerState.get('location', Map()),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      allFlows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR)
    };
  },

  render() {
    const pageName = this.state.breadcrumbs.last()?.get('name');

    if (
      (this.state.hasPayAsYouGo &&
        this.state.remainingCredits <= 0 &&
        !this.state.isLoadingCredits &&
        pageName === APP_ROUTE) ||
      (this.state.allFlows.isEmpty() && pageName === flowsRouteNames.ROOT) ||
      (this.state.isDemoPreview && pageName === APP_ROUTE)
    ) {
      return null;
    }

    return (
      <div id="container-header" className="flex-container breadcrumb-container">
        {this.renderTitle()}
        {this.renderButtons()}
      </div>
    );
  },

  renderTitle() {
    if (this.state.routerError) {
      return null;
    }

    return (
      <div className="title">
        {this.renderIcon()}
        <div className="flex-container flex-column align-top justify-center">
          {this.renderBreadcrumbs()}
          <div className="flex-container flex-start pr-2">
            {this.renderName()}
            {this.renderLabels()}
          </div>
        </div>
      </div>
    );
  },

  renderIcon() {
    if (this.state.component.has('backend')) {
      return (
        <span className="breadcrumb-component-icon">
          <TransformationType
            showLabel={false}
            transformation={this.state.component}
            imageSize="64"
          />
        </span>
      );
    }

    if (!this.state.component.isEmpty()) {
      return (
        <div className="breadcrumb-component-icon component-icon-with-type">
          <ComponentIcon
            component={this.state.component}
            isPhase={
              this.state.hasNewQueue &&
              this.state.currentRouteParams.has('jobId') &&
              isPhaseJob(QueueJobs.get(this.state.currentRouteParams.get('jobId')))
            }
          />
          <ComponentTypeIcon type={this.state.component.get('type')} size="18" />
        </div>
      );
    }

    return null;
  },

  renderName() {
    if (!this.state.breadcrumbs.count()) {
      return null;
    }

    const page = this.state.breadcrumbs.last();
    const linkTo = page.getIn(['link', 'to']);

    if (
      [
        this.state.component.get('id'),
        flowsRouteNames.DETAIL,
        orchestrationsRouteNames.DETAIL,
        transformationsRouteNames.GENERIC_TRANSFORMATION_CONFIG,
        componentsRoutes.GENERIC_CONFIG
      ].includes(linkTo)
    ) {
      return (
        <h1>
          <ComponentNameEdit
            componentId={this.state.realComponentId || this.state.component.get('id')}
            configId={this.state.currentRouteParams.get('config')}
          />
        </h1>
      );
    }

    if (
      [`${this.state.component.get('id')}-row`, componentsRoutes.GENERIC_CONFIG_ROW].includes(
        linkTo
      )
    ) {
      return (
        <h1>
          <ConfigurationRowName
            componentId={this.state.realComponentId || this.state.component.get('id')}
            configId={this.state.currentRouteParams.get('config')}
            rowId={this.state.currentRouteParams.get('row')}
          />
        </h1>
      );
    }

    if (this.state.currentRouteConfig.get('nameEdit')) {
      return (
        <h1>
          {this.state.currentRouteConfig.get('nameEdit')(this.state.currentRouteParams.toJS())}
        </h1>
      );
    }

    return <h1>{page.get('title')}</h1>;
  },

  renderLabels() {
    if (!this.state.currentRouteConfig.get('labelHandler')) {
      return null;
    }

    return React.createElement(this.state.currentRouteConfig.get('labelHandler'));
  },

  renderBreadcrumbs() {
    const breadcrumbs = [];
    let filteredBreadcrumbs = this.props.backFlow.isEmpty()
      ? this.state.breadcrumbs
      : this.getBackFlowBreadcrumbs();

    filteredBreadcrumbs.forEach((part, i) => {
      if (i !== filteredBreadcrumbs.count() - 1) {
        breadcrumbs.push(
          <Link
            className="dark muted"
            key={`${i}-${part.get('name')}`}
            to={part.getIn(['link', 'to'])}
            params={part.getIn(['link', 'params'], Map()).toJS()}
            query={part.getIn(['link', 'query'], this.getCurrentRouteQueryParams()).toJS()}
          >
            {part.get('title')}
          </Link>
        );

        if (i !== filteredBreadcrumbs.count() - 2) {
          breadcrumbs.push(
            <FontAwesomeIcon icon={['far', 'angle-right']} key={`arrow-${i}-${part.get('name')}`} />
          );
        }
      }
    });

    if (this.state.currentRouteConfig.get('breadcrumbHandler')) {
      return (
        <div className="breadcrumb">
          {React.createElement(this.state.currentRouteConfig.get('breadcrumbHandler'), {
            breadcrumbs,
            params: this.state.currentRouteParams.toJS()
          })}
        </div>
      );
    }

    const subtitle = filteredBreadcrumbs.last()?.get('subtitle');

    if (this.state.routerError || (!breadcrumbs.length && !subtitle)) {
      return null;
    }

    return (
      <div className="breadcrumb">
        {breadcrumbs.length < 2 && subtitle ? (
          <span className="active">{subtitle}</span>
        ) : (
          breadcrumbs
        )}
        <RoutePendingIndicator />
      </div>
    );
  },

  renderButtons() {
    const showCustomHeaderButtons =
      !this.state.routerError && this.state.currentRouteConfig.get('headerButtonsHandler');
    const showBackToFlowButton = !this.props.backFlow.isEmpty();

    if (!showCustomHeaderButtons && !showBackToFlowButton) {
      return null;
    }

    return (
      <div className={`top-buttons ${HEADER_BUTTONS}`}>
        {showCustomHeaderButtons &&
          React.createElement(this.state.currentRouteConfig.get('headerButtonsHandler'))}
        {showCustomHeaderButtons && showBackToFlowButton && <span className="btn-separator" />}
        {showBackToFlowButton && this.renderFlowsButtons()}
      </div>
    );
  },

  renderFlowsButtons() {
    const backButton = this.getBackFlowBreadcrumbs().get(-2); // get route before last route

    return (
      <ButtonToolbar>
        {!!backButton && (
          <Link
            onlyActiveOnIndex
            className="btn btn-default"
            to={backButton.getIn(['link', 'to'])}
            params={backButton.getIn(['link', 'params'], Map()).toJS()}
          >
            <FontAwesomeIcon icon="chevron-left" className="icon-addon-right" />
            Back
          </Link>
        )}
        <BackToFlowButton flow={this.props.backFlow} />
      </ButtonToolbar>
    );
  },

  getBackFlowBreadcrumbs() {
    const configIndex = this.state.breadcrumbs.findIndex((part) => {
      return part.hasIn(['link', 'params', 'config']);
    });

    return this.state.breadcrumbs.slice(configIndex);
  },

  getCurrentRouteQueryParams() {
    const queryParams = this.state.currentRouteLocation.get('query', Map());

    return this.state.currentRouteConfig
      .get('persistQueryParams', List())
      .reduce((result, item) => result.set(item, queryParams.get(item)), Map())
      .filter(Boolean);
  }
});

export default TopBarNav;
