import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Map, Seq } from 'immutable';

import CircleIcon from '../../../react/common/CircleIcon';
import ComponentIcon from '../../../react/common/ComponentIcon';
import ComponentName from '../../../react/common/ComponentName';
import Loader from '../../../react/common/Loader';
import TimeAndUser from '../../../react/common/TimeAndUser';
import UrlsToLinks from '../../../react/common/UrlsToLinks';
import { getComponent } from '../../sapi-events/helpers';
import EventDetailModal from './EventDetailModal';

const CLASSES_MAP = {
  info: 'text-muted',
  success: 'text-success',
  warn: 'text-warning',
  error: 'text-danger'
};

const STATUSES = {
  info: 'Info',
  success: 'Success',
  warn: 'Warning',
  error: 'Error'
};

const EventsTable = createReactClass({
  propTypes: {
    events: PropTypes.instanceOf(Seq).isRequired,
    admins: PropTypes.instanceOf(Map).isRequired,
    isSearching: PropTypes.bool.isRequired
  },

  getInitialState() {
    return {
      showDetailModal: false,
      eventDetail: Map()
    };
  },

  render() {
    return (
      <>
        <Table className="events-table" responsive hover>
          <thead>
            <tr>
              <th className="w-200">Component</th>
              <th>Event</th>
              <th className="w-280 text-right">Created</th>
              <th className="w-100">Status</th>
            </tr>
          </thead>
          <tbody>{this.renderEvents()}</tbody>
        </Table>
        <EventDetailModal
          show={this.state.showDetailModal}
          event={this.state.eventDetail}
          onHide={this.hideDetailModal}
        />
      </>
    );
  },

  renderEvents() {
    if (this.props.isSearching || this.props.events.isEmpty()) {
      return (
        <tr>
          <td colSpan={5}>
            {this.props.isSearching ? (
              <>
                <Loader /> Loading events...
              </>
            ) : (
              'No events found.'
            )}
          </td>
        </tr>
      );
    }

    return this.props.events.map(this.renderRow).toArray();
  },

  renderRow(event) {
    const component = getComponent(event);
    const hasFailed = event.get('type') === 'error';

    return (
      <tr key={event.get('id')} onClick={() => this.showDetailModal(event)} className="clickable">
        <td className={classnames('no-wrap', { [CLASSES_MAP.error]: hasFailed })}>
          {hasFailed ? (
            <CircleIcon
              icon="exclamation"
              className="icon-addon-right inline vertical-middle"
              color="red"
              bold
              smaller
            />
          ) : (
            <ComponentIcon component={component} size="28" className="icon-addon-right" />
          )}
          <ComponentName component={component} capitalize />
        </td>
        <td className="overflow-break-anywhere">
          <span className="event-message">
            {/* TODO: Add match marking here */}
            <UrlsToLinks text={event.get('message')} />
          </span>
        </td>
        <td className="text-right">
          <TimeAndUser
            admin={this.props.admins.get(event.getIn(['token', 'name']))}
            time={event.get('created')}
            fallbackName={event.getIn(['token', 'name'])}
          />
        </td>
        <td className={CLASSES_MAP[event.get('type')]}>{STATUSES[event.get('type')]}</td>
      </tr>
    );
  },

  showDetailModal(event) {
    this.setState({ showDetailModal: true, eventDetail: event });
  },

  hideDetailModal() {
    this.setState({ showDetailModal: false });
  }
});

export default EventsTable;
