import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import InfoAlert from '../../../../../react/common/InfoAlert';
import SaveButtons from '../../../../../react/common/SaveButtons';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import { canManageTokens } from '../../../../admin/privileges';
import ComponentsStore from '../../../../components/stores/ComponentsStore';
import BucketsStore from '../../../../components/stores/StorageBucketsStore';
import Events from '../../../../sapi-events/react/Events';
import { routeNames } from '../../../../settings/constants';
import TokensActions from '../../../actionCreators';
import { isInternalToken } from '../../../helpers';
import TokensStore from '../../../StorageTokensStore';
import createTokenEventsApi from '../../../TokenEventsApi';
import TokenEditor from '../../components/tokenEditor/TokenEditor';

const Detail = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, TokensStore, BucketsStore, ComponentsStore)],

  getStateFromStores() {
    const localState = TokensStore.localState();
    const tokenId = RoutesStore.getCurrentRouteParam('tokenId');
    const token = TokensStore.getAll().find((t) => t.get('id') === tokenId);

    return {
      localState,
      tokenId,
      token,
      editedToken: localState.getIn(['editedToken', tokenId], token),
      allComponents: ComponentsStore.getAll(),
      allBuckets: BucketsStore.getAll(),
      sapiToken: ApplicationStore.getSapiToken(),
      hasNewQueue: ApplicationStore.hasNewQueue()
    };
  },

  getInitialState() {
    return {
      isSaving: false,
      eventsApi: createTokenEventsApi(this.props.params.tokenId)
    };
  },

  render() {
    return this.state.token ? this.renderDetail() : this.renderNotFound();
  },

  renderDetail() {
    return (
      <Tabs id="token-detail-tabs" animation={false}>
        <Tab title="Overview" eventKey="overview" tabClassName="ml-1">
          <div className="box p-1 form form-horizontal">
            {isInternalToken(this.state.token) && (
              <InfoAlert header="Internal token">
                Please note that internal tokens cannot be modified by users in the UI, as doing so
                may cause issues with the functionality of the product. However, it may be updated
                via API if necessary.
              </InfoAlert>
            )}
            <div className="save-buttons">
              <SaveButtons
                isSaving={this.state.isSaving}
                disabled={!this.isValid() || !canManageTokens(this.state.sapiToken)}
                isChanged={!this.state.token.equals(this.state.editedToken)}
                onSave={this.handleSave}
                onReset={this.resetEditedToken}
              />
            </div>
            <TokenEditor
              isEditing
              disabled={
                !!this.state.isSaving ||
                !canManageTokens(this.state.sapiToken) ||
                isInternalToken(this.state.token)
              }
              token={this.state.editedToken}
              sapiToken={this.state.sapiToken}
              allBuckets={this.state.allBuckets}
              allComponents={this.state.allComponents}
              updateToken={this.updateToken}
              hasNewQueue={this.state.hasNewQueue}
            />
          </div>
        </Tab>
        <Tab title="Events" eventKey="events">
          <Events
            eventsApi={this.state.eventsApi}
            autoReload
            link={{ to: routeNames.TOKEN_DETAIL, params: { tokenId: this.state.tokenId } }}
          />
        </Tab>
      </Tabs>
    );
  },

  renderNotFound() {
    return <p>Token {this.state.tokenId} does not exist or has been removed.</p>;
  },

  isValid() {
    return !!(
      this.state.editedToken.get('description') && this.state.editedToken.get('expiresIn') !== 0
    );
  },

  resetEditedToken() {
    const updatedLocalState = this.state.localState.deleteIn(['editedToken', this.state.tokenId]);
    TokensActions.updateLocalState(updatedLocalState);
  },

  updateToken(name, value) {
    const updatedToken = this.state.editedToken.set(name, value);
    const updatedLocalState = this.state.localState.setIn(
      ['editedToken', this.state.tokenId],
      updatedToken
    );
    TokensActions.updateLocalState(updatedLocalState);
  },

  handleSave() {
    this.saving(true);
    return TokensActions.updateToken(this.state.tokenId, this.state.editedToken.toJS()).finally(
      () => {
        this.saving(false);
        this.resetEditedToken();
      }
    );
  },

  saving(value) {
    this.setState({
      isSaving: value
    });
  }
});

export default Detail;
