import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';

import Select from '../../../react/common/Select';

const LoadOptions = ({ preserve, onChange }) => {
  return (
    <FormGroup>
      <ControlLabel>Operation</ControlLabel>
      <Select
        clearable={false}
        value={preserve}
        onChange={onChange}
        options={[
          { value: false, label: 'Clean workspace before loading' },
          { value: true, label: 'Preserve non-conflicting tables when loading' }
        ]}
      />
      <HelpBlock>
        {preserve
          ? 'New tables will be loaded, existing tables with the same name will be replaced, other tables will be preserved.'
          : 'All existing data will be deleted before loading the new tables.'}
      </HelpBlock>
    </FormGroup>
  );
};

LoadOptions.propTypes = {
  preserve: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default LoadOptions;
