import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ReactModal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ExternalLink from '../../../react/common/ExternalLink';
import FileSize from '../../../react/common/FileSize';
import ModalIcon from '../../../react/common/ModalIcon';

const Modal = createReactClass({
  propTypes: {
    isModalOpen: PropTypes.bool.isRequired,
    onModalHide: PropTypes.func.isRequired,
    onPrepareStart: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
    createdFile: PropTypes.object,
    isRunning: PropTypes.bool.isRequired,
    progress: PropTypes.string,
    progressStatus: PropTypes.string
  },

  render() {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        <ReactModal show={this.props.isModalOpen} onHide={this.props.onModalHide}>
          <ReactModal.Header closeButton>
            <ReactModal.Title>Sliced File Download</ReactModal.Title>
            <ModalIcon icon="circle-down" color="blue" bold />
          </ReactModal.Header>
          <ReactModal.Body>
            <p>
              File{' '}
              <strong>
                {this.props.file.get('name')} (<FileSize size={this.props.file.get('sizeBytes')} />)
              </strong>{' '}
              is sliced into multiple chunks.
            </p>
            <p>
              All chunks will be packed into a <code>ZIP</code> file, you will be given link to
              download the file.
            </p>
            {this.props.progressStatus === 'danger' && (
              <p className="text-danger">{this.props.progress}</p>
            )}
          </ReactModal.Body>
          <ReactModal.Footer>{this.renderButton()}</ReactModal.Footer>
        </ReactModal>
      </span>
    );
  },

  renderButton() {
    if (this.props.progressStatus === 'danger') {
      return (
        <ConfirmButtons
          block
          saveStyle="primary"
          saveLabel="Try again"
          onSave={this.props.onPrepareStart}
        />
      );
    }

    if (this.props.createdFile) {
      return (
        <ExternalLink
          href={this.props.createdFile.get('url')}
          className="btn btn-primary btn-block"
        >
          <FontAwesomeIcon icon="circle-down" className="icon-addon-right" />
          Download file
        </ExternalLink>
      );
    }

    return (
      <ConfirmButtons
        block
        saveStyle="primary"
        onSave={this.props.onPrepareStart}
        saveLabel={this.props.progress || 'Prepare package'}
        isSaving={this.props.isRunning}
      />
    );
  }
});

export default Modal;
