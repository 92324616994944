import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, HelpBlock, Modal } from 'react-bootstrap';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import InputValidation from '../../../react/common/InputValidation';
import ModalIcon from '../../../react/common/ModalIcon';
import Select from '../../../react/common/Select';
import { bucketLabel } from '../../../react/common/selectLabels';
import { canWriteBucket } from '../../admin/privileges';
import { filterProductionBuckets } from '../../dev-branches/helpers';
import { nameWarning } from '../constants';
import { bucketDisplayNameWithStage, validateTableName } from '../helpers';

const INITIAL_STATE = {
  bucketId: '',
  name: '',
  warning: null,
  isSaving: false
};

const CreateTableFromSnapshotModal = createReactClass({
  propTypes: {
    sapiToken: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    snapshot: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    activeBucketId: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      ...INITIAL_STATE
    };
  },

  render() {
    const bucketsOptions = filterProductionBuckets(this.props.buckets)
      .filter((bucket) => canWriteBucket(this.props.sapiToken, bucket))
      .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
      .map((bucket) => {
        return {
          value: bucket.get('id'),
          label: bucketLabel(bucket),
          name: bucket.get('displayName')
        };
      })
      .toArray();

    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        onEnter={() =>
          this.setState({
            bucketId: this.props.activeBucketId,
            name: `${this.props.tableName}_${this.props.snapshot.get('id')}`
          })
        }
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create table from snapshot {this.props.snapshot.get('id')}</Modal.Title>
            <ModalIcon icon="table" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Destination Bucket</ControlLabel>
              <Select
                autoFocus
                clearable={false}
                value={this.state.bucketId}
                onChange={this.handleDestinationBucket}
                options={bucketsOptions}
              />
            </FormGroup>
            <InputValidation predefined="tableName" value={this.state.name}>
              {(inputState) => (
                <FormGroup validationState={this.state.warning ? 'error' : inputState}>
                  <ControlLabel>Table Name</ControlLabel>
                  <FormControl type="text" value={this.state.name} onChange={this.handleName} />
                  <HelpBlock
                    className={classnames({
                      'text-danger': !!this.state.warning || inputState === 'error'
                    })}
                  >
                    {this.state.warning || nameWarning}
                  </HelpBlock>
                </FormGroup>
              )}
            </InputValidation>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isDisabled={this.isDisabled()}
              saveLabel="Create table"
              saveButtonType="submit"
              isSaving={this.state.isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  handleDestinationBucket(selected) {
    this.setState({ bucketId: selected }, this.validateName);
  },

  handleName({ target }) {
    this.setState({ name: target.value }, this.validateName);
  },

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ isSaving: true });
    return this.props
      .onConfirm(this.props.snapshot.get('id'), this.state.bucketId, this.state.name)
      .then(this.onHide)
      .finally(() => this.setState({ isSaving: false }));
  },

  validateName() {
    this.setState(({ name }) => ({
      warning: validateTableName(
        name,
        this.props.tables.filter((table) => table.getIn(['bucket', 'id']) === this.state.bucketId)
      )
    }));
  },

  onHide() {
    this.setState(INITIAL_STATE);
    this.props.onHide();
  },

  isDisabled() {
    return !this.state.bucketId || !this.state.name || !!this.state.warning;
  }
});

export default CreateTableFromSnapshotModal;
