import { SERVICE_BILLING } from '../../constants/serviceIds';
import ApplicationStore from '../../stores/ApplicationStore';
import request from '../../utils/request';
import ServicesStore from '../services/Store';

const createRequest = (method, path) => {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_BILLING)}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString()
  );
};

const BillingApi = {
  getCredits() {
    return createRequest('GET', 'credits')
      .promise()
      .then((response) => response.body);
  }
};

export default BillingApi;
