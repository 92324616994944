import { List, Map } from 'immutable';
import _ from 'underscore';

import getDefaultBucket from '../../utils/getDefaultBucket';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import OauthStore from '../oauth-v2/Store';

const COMPONENT_ID = 'keboola.ex-google-drive';

export const storeMixins = [InstalledComponentsStore, OauthStore];

export default function (configId) {
  const localState = () => InstalledComponentsStore.getLocalState(COMPONENT_ID, configId) || Map();
  const config = InstalledComponentsStore.getConfig(COMPONENT_ID, configId);
  const configData = InstalledComponentsStore.getConfigData(COMPONENT_ID, configId);

  const parameters = configData.get('parameters', Map());
  const sheets = parameters
    .getIn(['sheets'], List())
    .sortBy((s) => s.get('fileTitle').toLowerCase());
  const processors = configData.getIn(['processors', 'after'], List());

  const tempPath = ['_'];
  const savingPath = tempPath.concat('saving');
  const pendingPath = tempPath.concat('pending');
  const defaultOutputBucket = getDefaultBucket('in', COMPONENT_ID, configId);
  const outputBucket = parameters.get('outputBucket') || defaultOutputBucket;

  function getConfigSheet(sheetId) {
    return sheets.find((q) => q.get('id').toString() === sheetId.toString());
  }

  return {
    oauthCredentials: OauthStore.getCredentials(COMPONENT_ID, configData) || Map(),

    // local state stuff
    getLocalState(path) {
      if (_.isEmpty(path)) {
        return localState() || Map();
      }
      return localState().getIn([].concat(path), Map());
    },

    // config data stuff
    sheets,
    processors,
    config,
    configData,
    outputBucket,

    isSaving(what) {
      return localState().getIn(savingPath.concat(what), false);
    },

    getSavingPath(what) {
      return savingPath.concat(what);
    },

    getConfigSheet: getConfigSheet,

    getPendingPath(what) {
      return pendingPath.concat(what);
    },

    isPending(what) {
      return localState().getIn(pendingPath.concat(what), null);
    },

    getRunSingleSheetData(sheetId) {
      const sheet = getConfigSheet(sheetId).set('enabled', true);
      return configData.setIn(['parameters', 'sheets'], List().push(sheet)).toJS();
    },

    isAuthorized() {
      const creds = this.oauthCredentials;
      return creds && creds.has('id');
    }
  };
}
