import { ASSETS_URL } from './KbcConstants';

const backendImages = {
  r: ASSETS_URL + '/developer-portal/icons/keboola.r-transformation/64/1578661578812.png',
  python: ASSETS_URL + '/developer-portal/icons/keboola.python-transformation/64/1578661535474.png',
  openrefine:
    ASSETS_URL + '/developer-portal/icons/keboola.openrefine-transformation/64/1578661268692.png',
  snowflake: ASSETS_URL + '/developer-portal/icons/keboola.ex-db-snowflake/64/1576250365598.png',
  redshift: ASSETS_URL + '/developer-portal/icons/keboola.ex-db-redshift/64/1576252341013.png',
  synapse:
    ASSETS_URL + '/developer-portal/icons/keboola.synapse-transformation/64/1583317931583.png',
  julia: ASSETS_URL + '/developer-portal/icons/keboola.julia-transformation/64/1578661628294.png',
  bigquery:
    ASSETS_URL + '/developer-portal/icons/keboola.wr-google-bigquery-v2/64/1576512221746.png',
  exasol: ASSETS_URL + '/developer-portal/icons/keboola.exasol-transformation/64/1629713819829.png',
  teradata:
    ASSETS_URL + '/developer-portal/icons/keboola.teradata-transformation/64/1652873644841.png'
};

export { backendImages };
