import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'react-bootstrap';
import { Map } from 'immutable';

import ComponentIcon from '../../../../../react/common/ComponentIcon';

class Mapping extends React.Component {
  render() {
    return (
      <>
        {this.props.component.count() !== 0 && (
          <ComponentIcon
            component={this.props.component}
            size={this.props.iconSize}
            className="icon-addon-right"
          />
        )}
        <span>
          {this.props.component.count() !== 0 &&
            this.props.showComponentName &&
            `${this.props.component.get('name')}: `}
          {this.props.configuration.count() !== 0 && `${this.props.configuration.get('name')}`}
          {this.props.configurationRow.count() !== 0 &&
            ` - Row ${this.props.configurationRow.get('name')}`}
          {this.props.fileTag !== '' && (
            <Label bsStyle="success" className="label-rounded color-inverse icon-addon-left">
              {this.props.fileTag}
            </Label>
          )}
        </span>
      </>
    );
  }
}

Mapping.propTypes = {
  iconSize: PropTypes.string.isRequired,
  component: PropTypes.instanceOf(Map),
  configuration: PropTypes.instanceOf(Map),
  configurationRow: PropTypes.instanceOf(Map),
  fileTag: PropTypes.string,
  showComponentName: PropTypes.bool
};

Mapping.defaultProps = {
  component: Map(),
  configuration: Map(),
  configurationRow: Map(),
  fileTag: '',
  showComponentName: false
};

export default Mapping;
