import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { List } from 'immutable';
import { startsWith } from 'underscore.string';

import { USER_DOCUMENTATION_URL } from '../../../../constants/KbcConstants';
import Checkbox from '../../../../react/common/Checkbox';
import ExternalLink from '../../../../react/common/ExternalLink';
import Select from '../../../../react/common/Select';
import { bucketLabel, tableLabel } from '../../../../react/common/selectLabels';
import {
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionAndCurrentDevBranchTables
} from '../../../dev-branches/helpers';
import { tableName } from '../../../storage/helpers';
import { backends } from '../../Constants';

class ConfigureSandbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preserve: false,
      backend: this.props.backend,
      include: List(),
      rows: 0
    };

    this.setRows = this.setRows.bind(this);
    this.setInclude = this.setInclude.bind(this);
    this.setPreserve = this.setPreserve.bind(this);
  }

  render() {
    return (
      <div className="form-horizontal">
        {this.renderSnowflakeSandboxInfo()}
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Backend
          </Col>
          <Col sm={9}>
            <FormControl.Static>{this.state.backend}</FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Data
          </Col>
          <Col sm={9}>
            <Select
              multi
              value={this.state.include}
              options={this.bucketsAndTables()}
              onChange={this.setInclude}
              placeholder="Select buckets and tables..."
            />
          </Col>
        </FormGroup>
        {this.renderRowsInput()}
        <FormGroup>
          <Col sm={9} smOffset={3}>
            <Checkbox checked={this.state.preserve} onChange={this.setPreserve}>
              Preserve existing data
            </Checkbox>
          </Col>
        </FormGroup>
      </div>
    );
  }

  renderSnowflakeSandboxInfo() {
    if (this.props.backend !== backends.SNOWFLAKE) {
      return null;
    }

    return (
      <Alert bsStyle="warning">
        Tables are loaded into the Snowflake sandbox using{' '}
        <ExternalLink href={`${USER_DOCUMENTATION_URL}/transformations/snowflake/#clone-table`}>
          <strong>CLONE TABLE</strong> load type
        </ExternalLink>
        . Alias Tables with filter cannot be loaded to Sandbox using this method.
      </Alert>
    );
  }

  renderRowsInput() {
    if (this.props.backend === backends.SNOWFLAKE) {
      return null;
    }

    return (
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Sample rows
        </Col>
        <Col sm={9}>
          <FormControl
            type="number"
            value={this.state.rows}
            onChange={this.setRows}
            placeholder="Number of rows"
          />
        </Col>
      </FormGroup>
    );
  }

  setInclude(selected) {
    return this.setState({ include: selected }, () => this.props.onChange(this.state));
  }

  setRows(e) {
    const rows = e.target.value.trim();
    return this.setState({ rows }, () => this.props.onChange(this.state));
  }

  setPreserve(checked) {
    return this.setState({ preserve: checked }, () => this.props.onChange(this.state));
  }

  bucketsAndTables() {
    const buckets = filterProductionAndCurrentDevBranchBuckets(this.props.buckets)
      .filter(
        (bucket) => startsWith(bucket.get('id'), 'in.') || startsWith(bucket.get('id'), 'out.')
      )
      .map((bucket) => ({
        value: bucket.get('id'),
        label: bucketLabel(bucket),
        name: bucket.get('displayName')
      }))
      .toSet();

    const tables = filterProductionAndCurrentDevBranchTables(this.props.tables, this.props.buckets)
      .filter((table) => startsWith(table.get('id'), 'in.') || startsWith(table.get('id'), 'out.'))
      .filter((table) => {
        return (
          (this.props.backend === backends.SNOWFLAKE && !table.has('aliasFilter')) ||
          this.props.backend !== backends.SNOWFLAKE
        );
      })
      .map((table) => ({
        value: table.get('id'),
        label: tableLabel(table),
        name: tableName(table)
      }))
      .toSet();

    return buckets
      .union(tables)
      .sortBy((option) => option.name)
      .toArray();
  }
}

ConfigureSandbox.propTypes = {
  backend: PropTypes.string.isRequired,
  tables: PropTypes.object.isRequired,
  buckets: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ConfigureSandbox;
