import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import RunHistoryRow from './RunHistoryRow';

const RunHistory = createReactClass({
  propTypes: {
    activeJobId: PropTypes.number.isRequired,
    jobs: PropTypes.instanceOf(Map).isRequired
  },

  render() {
    return (
      <div className="sidebar-box">
        <h3 className="flex-container mb-1">Run History</h3>
        {this.props.jobs
          .map((job) => (
            <RunHistoryRow
              key={job.get('id')}
              job={job}
              isActive={this.props.activeJobId === job.get('id')}
            />
          ))
          .toArray()}
      </div>
    );
  }
});

export default RunHistory;
