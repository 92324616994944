import { fromJS } from 'immutable';
import _ from 'underscore';

import { getComponentDataTypes } from '../../../templates/dataTypes';

const prepareColumns = (configColumns, storageColumns) => {
  const configColumnsNamesSet = configColumns.map((c) => c.get('name')).toSet();
  const deletedColumns = configColumnsNamesSet.subtract(storageColumns);
  const allColumns = storageColumns.concat(deletedColumns);
  return allColumns.map((storageColumn) => {
    const configColumnFound = configColumns.find((cc) => cc.get('name') === storageColumn);
    if (configColumnFound) {
      return configColumnFound;
    } else {
      return fromJS({
        name: storageColumn,
        dbName: storageColumn,
        type: 'IGNORE',
        null: false,
        default: '',
        size: ''
      });
    }
  });
};

const prepareEditingColumns = (columns, editingColumns) => {
  return columns
    .toMap()
    .mapKeys((key, column) => column.get('name'))
    .map((column, key) => editingColumns.get(key, column));
};

const prepareNullable = (column) => {
  if (column.has('null')) {
    return column;
  }

  return column.set('null', column.get('nullable', false) ? '1' : '0');
};

const getSizeParam = (componentId, dataType) => {
  const dtypes = getComponentDataTypes(componentId);
  const dt = _.find(dtypes, (d) => _.isObject(d) && _.keys(d)[0] === dataType);
  return dt && dt[dataType] && dt[dataType].defaultSize;
};

const getDataTypeOptions = (componentId, dataType) => {
  const dtypes = getComponentDataTypes(componentId);
  const dt = _.find(dtypes, (d) => _.isObject(d) && _.keys(d)[0] === dataType);
  return (dt && dt[dataType] && dt[dataType].sizeOptions) || [];
};

const getDataTypes = (componentId) => {
  return _.map(getComponentDataTypes(componentId), (dataType) => {
    return _.isObject(dataType) ? _.keys(dataType)[0] : dataType;
  });
};

export {
  prepareColumns,
  prepareEditingColumns,
  prepareNullable,
  getSizeParam,
  getDataTypeOptions,
  getDataTypes
};
