import React from 'react';
import { Alert } from 'react-bootstrap';

const CatchUnsavedRunWarning = () => {
  return (
    <Alert bsStyle="warning">
      You have unsaved changes! Running the component will use last saved version.
    </Alert>
  );
};

export default CatchUnsavedRunWarning;
