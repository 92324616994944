import { List, Map } from 'immutable';

import { KEBOOLA_PROCESSOR_MOVE_FILES } from '../../constants/componentIds';
import columnTypes from '../configurations/utils/columnTypeConstants';
import createRoute from '../configurations/utils/createRoute';
import { CollapsibleSection } from '../configurations/utils/renderHelpers';
import conformSource from './adapters/conform';
import SourcePathAdapter from './adapters/SourcePath';
import SourceServerAdapter from './adapters/SourceServer';
import SourcePathSection from './SourcePath';
import SourceServerSection from './SourceServer';

export default function routes(componentsIds) {
  return componentsIds.map((componentId) => {
    return createRoute({
      componentId,
      index: {
        onConform: conformSource,
        sections: [
          {
            render: CollapsibleSection({
              title: 'Connection configuration',
              contentComponent: SourceServerSection,
              options: { includeSaveButtons: true }
            }),
            onLoad: SourceServerAdapter.parseConfiguration,
            onSave: SourceServerAdapter.createConfiguration,
            isComplete: SourceServerAdapter.isComplete
          }
        ]
      },
      row: {
        hasState: false,
        onConform: SourcePathAdapter.onConform,
        sections: [
          {
            render: SourcePathSection,
            onSave: SourcePathAdapter.createConfiguration,
            onLoad: SourcePathAdapter.parseConfiguration,
            onCreate: SourcePathAdapter.createEmptyConfiguration
          }
        ],
        columns: [
          {
            name: 'Storage',
            type: columnTypes.TABLE_LINK_DEFAULT_BUCKET,
            value: (row) => {
              return row
                .getIn(['processors', 'after'], List())
                .find(
                  (processor) =>
                    processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_MOVE_FILES,
                  null,
                  Map()
                )
                .getIn(['parameters', 'folder']);
            }
          }
        ]
      }
    });
  });
}
