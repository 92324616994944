import { KEBOOLA_ORACLE_TRANSFORMATION } from '../../constants/componentIds';
import { componentTypes } from '../../constants/componentTypes';
import * as actionsProvisioning from '../ex-db-generic/actionsProvisioning';
import ExDbCredentialsPage from '../ex-db-generic/react/pages/credentials/CredentialsPage';
import * as storeProvisioning from '../ex-db-generic/storeProvisioning';
import * as credentialsTemplate from '../ex-db-generic/templates/credentials';

export default {
  name: 'transformation-credentials',
  path: 'credentials',
  defaultRouteHandler: ExDbCredentialsPage(
    KEBOOLA_ORACLE_TRANSFORMATION,
    actionsProvisioning,
    storeProvisioning,
    credentialsTemplate,
    componentTypes.TRANSFORMATION
  ),
  title: 'Credentials'
};
