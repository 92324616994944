import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { componentTypes } from '../../../constants/componentTypes';
import { getComponentTypeColorClassName } from '../../components/helpers';

const ComponentTypeIcon = ({
  type,
  size = '32',
  className
}: {
  type:
    | typeof componentTypes.EXTRACTOR
    | typeof componentTypes.WRITER
    | typeof componentTypes.APPLICATION;
  size?: string;
  className?: string;
}) => {
  if (
    ![componentTypes.EXTRACTOR, componentTypes.WRITER, componentTypes.APPLICATION].includes(type)
  ) {
    return null;
  }

  return (
    <span className={classnames('type-icon', className)}>
      <span className="fa-layers" style={{ fontSize: `${size}px` }}>
        <FontAwesomeIcon icon="circle" className={getComponentTypeColorClassName(type)} />
        <FontAwesomeIcon
          icon={type === componentTypes.APPLICATION ? 'browser' : 'database'}
          transform="shrink-8"
          color="white"
        />
      </span>
    </span>
  );
};

export default ComponentTypeIcon;
