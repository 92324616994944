import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_VARIABLES } from '../../../../../../constants/componentIds';
import ConfigurationRowsActionCreators from '../../../../../configurations/ConfigurationRowsActionCreators';
import InstalledComponentsActionCreators from '../../../../InstalledComponentsActionCreators';
import InstalledComponentsApi from '../../../../InstalledComponentsApi';

const updateVariableDefinition = (oldName, newName, configId, config) => {
  return InstalledComponentsActionCreators.saveComponentConfigData(
    KEBOOLA_VARIABLES,
    configId,
    config.update('variables', List(), (variables) => {
      return variables
        .filter((variable) => variable.get('name') !== oldName)
        .push(
          fromJS({
            name: newName,
            type: 'string'
          })
        );
    }),
    `Update variable name "${oldName}" to "${newName}"`
  );
};

const createConfigRowWithFirstVariableValue = (configId, name, value) => {
  return ConfigurationRowsActionCreators.createSimple(
    KEBOOLA_VARIABLES,
    configId,
    {
      name: 'Default values',
      configuration: JSON.stringify({
        values: [
          {
            name: name,
            value: value
          }
        ]
      })
    },
    `Create value for "${name}" variable`
  );
};

const createVariableDefinition = (name, configId, config) => {
  return InstalledComponentsActionCreators.saveComponentConfigData(
    KEBOOLA_VARIABLES,
    configId,
    config.update('variables', List(), (variables) => {
      return variables.push(
        fromJS({
          name: name,
          type: 'string'
        })
      );
    }),
    `Create variable "${name}"`
  );
};

const updateVariableValue = (
  oldName,
  oldValue,
  newName,
  newValue,
  configId,
  configRowId,
  configRow
) => {
  let description = `Update variable "${newName}" value`;
  if (oldName !== newName) {
    description = `Update variable name "${oldName}" to "${newName}". ${description}`;
  }

  return ConfigurationRowsActionCreators.updateSimple(
    KEBOOLA_VARIABLES,
    configId,
    configRowId,
    {
      configuration: JSON.stringify(
        configRow
          .get('configuration', Map())
          .update('values', List(), (values) => {
            return values
              .filter((value) => value.get('name') !== oldName)
              .push(
                fromJS({
                  name: newName,
                  value: newValue
                })
              );
          })
          .toJS()
      )
    },
    description
  );
};

const createVariableDefinitionAndValue = (
  name,
  value,
  configId,
  config,
  configRowId,
  configRow
) => {
  return createVariableDefinition(name, configId, config).then(() => {
    return ConfigurationRowsActionCreators.updateSimple(
      KEBOOLA_VARIABLES,
      configId,
      configRowId,
      {
        configuration: JSON.stringify(
          configRow
            .get('configuration', Map())
            .update('values', List(), (values) => {
              return values.push(
                fromJS({
                  name: name,
                  value: value
                })
              );
            })
            .toJS()
        )
      },
      `Create variable "${name}" value`
    );
  });
};

const createVariablesDefinition = (variables, componentId, configId) => {
  return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_VARIABLES, {
    name: `Variables definition for ${componentId}/${configId}`,
    changeDescription: `Create variables definition`,
    configuration: JSON.stringify({
      variables: variables.map((variable) => ({ name: variable, type: 'string' }))
    })
  });
};

const updateVariablesDefinition = (configId, variables) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_VARIABLES,
    configId,
    {
      changeDescription: `Update variables definition`,
      configuration: JSON.stringify({
        variables: variables.map((variable) => ({ name: variable, type: 'string' }))
      })
    }
  );
};

const deleteVariablesDefinition = (configId) => {
  return InstalledComponentsActionCreators.deleteConfiguration(KEBOOLA_VARIABLES, configId, {
    transition: false,
    notification: false
  });
};

const createConfigWithVariableDefinitionAndRowWithFirstValue = (
  name,
  value,
  mainComponentId,
  mainConfigId,
  mainConfig
) => {
  return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_VARIABLES, {
    name: `Variables definition for ${mainComponentId}/${mainConfigId}`,
    changeDescription: `Create variable "${name}"`,
    configuration: JSON.stringify({
      variables: [
        {
          name: name,
          type: 'string'
        }
      ]
    })
  })
    .then((config) => {
      return Promise.props({
        config,
        row: createConfigRowWithFirstVariableValue(config.id, name, value)
      });
    })
    .then(({ config, row }) => {
      return InstalledComponentsActionCreators.saveComponentConfigData(
        mainComponentId,
        mainConfigId,
        mainConfig.set('variables_id', config.id).set('variables_values_id', row.id),
        `Link variables ${config.id} and values ${row.id}`
      ).then(() => {
        return InstalledComponentsActionCreators.loadComponentConfigDataForce(
          KEBOOLA_VARIABLES,
          config.id
        );
      });
    });
};

const deleteVariableReferenceAndVariableDefinition = (
  mainComponentId,
  mainConfigId,
  variableConfigId,
  mainConfig
) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    mainComponentId,
    mainConfigId,
    {
      changeDescription: `Unlink variables ${variableConfigId}`,
      configuration: JSON.stringify(
        mainConfig.delete('variables_id').delete('variables_values_id').toJS()
      )
    }
  ).then(() => deleteVariablesDefinition(variableConfigId));
};

const updateVariableDefinitionAndValue = (
  oldName,
  oldValue,
  newName,
  newValue,
  configId,
  config,
  configRowId,
  configRow
) => {
  if (oldName === newName && oldValue === newValue) {
    return Promise.resolve();
  }
  if (oldName === newName) {
    return updateVariableValue(
      oldName,
      oldValue,
      newName,
      newValue,
      configId,
      configRowId,
      configRow
    );
  }
  return updateVariableDefinition(oldName, newName, configId, config).then(() => {
    return updateVariableValue(
      oldName,
      oldValue,
      newName,
      newValue,
      configId,
      configRowId,
      configRow
    );
  });
};

const deleteVariableDefinition = (name, configId, config) => {
  return InstalledComponentsActionCreators.saveComponentConfigData(
    KEBOOLA_VARIABLES,
    configId,
    config.update('variables', List(), (variables) => {
      return variables.filter((variable) => variable.get('name') !== name);
    }),
    `Delete variable "${name}"`
  );
};

const deleteVariableValues = (name, configId, config, configRowId, configRow) => {
  return ConfigurationRowsActionCreators.updateSimple(
    KEBOOLA_VARIABLES,
    configId,
    configRowId,
    {
      configuration: JSON.stringify(
        configRow
          .get('configuration', Map())
          .update('values', List(), (values) => {
            return values.filter((value) => value.get('name') !== name);
          })
          .toJS()
      )
    },
    `Delete variable "${name}" value`
  );
};

const deleteVariableDefinitionAndValue = (name, configId, config, configRowId, configRow) => {
  return deleteVariableDefinition(name, configId, config).then(() => {
    return deleteVariableValues(name, configId, config, configRowId, configRow);
  });
};

const initMissingVariables = (
  componentId,
  configId,
  variableId,
  variableValueId,
  currentVariables,
  missingVariables
) => {
  if (!variableId) {
    return createVariablesDefinition(missingVariables, componentId, configId).then((varibles) => {
      return ConfigurationRowsActionCreators.createSimple(
        KEBOOLA_VARIABLES,
        varibles.id,
        {
          name: 'Default values',
          configuration: JSON.stringify({
            values: missingVariables.map((variable) => ({ name: variable, value: '' }))
          })
        },
        'Init default values'
      ).then((values) => {
        return InstalledComponentsApi.getComponentConfiguration(componentId, configId)
          .then(fromJS)
          .then((config) => {
            return InstalledComponentsActionCreators.saveComponentConfigData(
              componentId,
              configId,
              config
                .get('configuration', Map())
                .set('variables_id', varibles.id)
                .set('variables_values_id', values.id),
              `Link variables ${varibles.id} and values ${values.id}`
            );
          });
      });
    });
  }

  return updateVariablesDefinition(variableId, currentVariables.concat(missingVariables)).then(
    () => {
      return InstalledComponentsApi.getConfigurationRow(
        KEBOOLA_VARIABLES,
        variableId,
        variableValueId
      )
        .then(fromJS)
        .then((configRow) => {
          return ConfigurationRowsActionCreators.updateSimple(
            KEBOOLA_VARIABLES,
            variableId,
            variableValueId,
            {
              configuration: JSON.stringify(
                configRow
                  .get('configuration', Map())
                  .update('values', List(), (values) => {
                    return values.concat(
                      fromJS(missingVariables).map((variable) => ({ name: variable, value: '' }))
                    );
                  })
                  .toJS()
              )
            },
            'Add default values for new variables'
          );
        });
    }
  );
};

export {
  deleteVariableDefinitionAndValue,
  createVariableDefinitionAndValue,
  createVariablesDefinition,
  updateVariablesDefinition,
  deleteVariablesDefinition,
  createConfigWithVariableDefinitionAndRowWithFirstValue,
  deleteVariableReferenceAndVariableDefinition,
  updateVariableDefinitionAndValue,
  initMissingVariables
};
