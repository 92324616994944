import React from 'react';
import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import ActivateDeactivateSwitch from '../../../../react/common/ActivateDeactivateSwitch';
import ConfigurationDisabledLabel from '../../../../react/common/ConfigurationDisabledLabel';
import { getRealComponentId } from '../../../../react/common/ConfigurationsTable/helpers';
import CopyVersionButton from '../../../../react/common/CopyVersionButton';
import InlineDescriptionEditInput from '../../../../react/common/InlineDescriptionEditInput';
import MultiActionsSelectCheckbox from '../../../../react/common/MultiActionsSelectCheckbox';
import RowActionDropdown from '../../../../react/common/RowActionDropdown';
import RowActionMenuItem from '../../../../react/common/RowActionMenuItem';
import Truncated from '../../../../react/common/Truncated';
import RoutesStore from '../../../../stores/RoutesStore';
import hasSelections from '../../../../utils/hasSelections';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../../../utils/windowOpen';
import RunComponentButton from '../../../components/react/components/RunComponentButton';
import ConfigurationRowsActionCreators from '../../ConfigurationRowsActionCreators';
import RowVersionsActionCreators from '../../RowVersionsActionCreators';
import ChangeOrderHandle from './ChangeOrderHandle';
import ConfigurationRowsTableCell from './ConfigurationRowsTableCell';
import DeleteConfigurationRowButton from './DeleteConfigurationRowButton';

const ConfigurationRowsTableRow = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    component: PropTypes.instanceOf(Map).isRequired,
    config: PropTypes.instanceOf(Map).isRequired,
    row: PropTypes.instanceOf(Map).isRequired,
    columns: PropTypes.object.isRequired,
    linkTo: PropTypes.string.isRequired,
    isDeletePending: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired,
    isEnableDisablePending: PropTypes.bool.isRequired,
    onEnableDisable: PropTypes.func.isRequired,
    disabledMove: PropTypes.bool.isRequired,
    orderPending: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelected: PropTypes.func.isRequired,
    isRowConfiguration: PropTypes.bool.isRequired,
    runParams: PropTypes.object
  },

  render() {
    const routerLinkParams = {
      component: this.props.component.get('id'),
      config: this.props.config.get('id'),
      row: this.props.row.get('id'),
      query: this.props.row.get('id')
    };

    return (
      <div
        className={classnames('tr clickable hoverable-actions', {
          'row-sort': !this.props.readOnly && this.props.isRowConfiguration
        })}
        data-id={this.props.row.get('id')}
        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
        onClick={(e) => {
          if (hasSelections()) {
            return;
          }

          if (shouldUseNewWindow(e)) {
            return windowOpen(
              RoutesStore.getRouter().createHref(this.props.linkTo, routerLinkParams)
            );
          }

          RoutesStore.getRouter().transitionTo(this.props.linkTo, routerLinkParams);
        }}
      >
        {!this.props.readOnly && this.props.isRowConfiguration ? (
          <>
            <div
              className="td row-sort-handle with-checkbox no-wrap"
              onClick={(e) => e.stopPropagation()}
            >
              <ChangeOrderHandle
                isPending={this.props.orderPending}
                disabled={this.props.disabledMove}
              />
              <MultiActionsSelectCheckbox
                isChecked={this.props.isSelected}
                isDisabled={this.props.isDeletePending}
                onToggle={this.props.toggleSelected}
                entity="row"
              />
            </div>
            <div className="td pl-0">{this.renderName()}</div>
          </>
        ) : (
          <div className="td">{this.renderName()}</div>
        )}
        {this.props.columns.map((columnDefinition, index) => {
          return (
            <div className="td overflow-break-anywhere" key={index}>
              <ConfigurationRowsTableCell
                type={columnDefinition.get('type', 'value')}
                valueFn={columnDefinition.get('value')}
                tablePrefix={columnDefinition.get('tablePrefix')}
                row={this.props.row}
                component={this.props.component}
                componentId={this.props.component.get('id')}
                configData={this.props.config.get('configuration', Map())}
                configurationId={this.props.config.get('id')}
              />
            </div>
          );
        })}
        <div className="td pr-1">{this.renderRowActionButtons()}</div>
      </div>
    );
  },

  renderName() {
    const isDisabled = this.props.row.get('isDisabled');

    return (
      <div>
        <div className="flex-container flex-start">
          <Truncated
            text={this.props.row.get('name') || 'Untitled'}
            className={classnames('config-name', { 'opacity-half': isDisabled })}
          />
          {isDisabled && <ConfigurationDisabledLabel className="no-shrink icon-addon-left" />}
        </div>
        <div className={classnames('f-13 text-muted', { 'opacity-half': isDisabled })}>
          <InlineDescriptionEditInput
            entity="Row"
            description={this.props.row.get('description', '')}
            onSave={(newDescription) => {
              return ConfigurationRowsActionCreators.updateSimple(
                getRealComponentId(this.props.config, this.props.component),
                this.props.config.get('id'),
                this.props.row.get('id'),
                { description: newDescription },
                'Change description'
              );
            }}
            readOnly={this.props.readOnly || isDisabled}
            component={this.props.component}
            configuration={this.props.row}
          />
        </div>
      </div>
    );
  },

  renderRowActionButtons() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <RowActionDropdown
        showLoading={this.props.isDeletePending || this.props.isEnableDisablePending}
      >
        <RunComponentButton
          mode="menuitem"
          title="Run"
          component={this.props.component.get('id')}
          runParams={() => {
            if (this.props.runParams) {
              return this.props.runParams;
            }

            return {
              config: this.props.config.get('id'),
              row: this.props.row.get('id')
            };
          }}
        >
          {this.renderRunModalContent()}
        </RunComponentButton>
        <RowActionMenuItem divider />
        {this.props.isRowConfiguration && (
          <>
            <ActivateDeactivateSwitch
              mode="menuitem"
              isActive={!this.props.row.get('isDisabled', false)}
              isPending={this.props.isEnableDisablePending}
              onChange={this.props.onEnableDisable}
            />
            <CopyVersionButton
              isRow
              isLast
              mode="menuitem"
              configId={this.props.config.get('id')}
              componentId={getRealComponentId(this.props.config, this.props.component)}
              componentType={this.props.component.get('type')}
              version={this.props.row}
              onCopy={(name) => {
                return RowVersionsActionCreators.copyVersion(
                  getRealComponentId(this.props.config, this.props.component),
                  this.props.config.get('id'),
                  this.props.row.get('id'),
                  this.props.row.get('version'),
                  name
                );
              }}
            />
            <RowActionMenuItem divider />
          </>
        )}
        <DeleteConfigurationRowButton
          mode="menuitem"
          isPending={this.props.isDeletePending}
          onClick={this.props.onDelete}
        />
      </RowActionDropdown>
    );
  },

  renderRunModalContent() {
    const rowName = this.props.row.get('name', 'Untitled');

    if (this.props.row.get('isDisabled')) {
      return `You are about to run ${rowName}. Configuration ${rowName} is disabled and will be forced to run.`;
    }

    return `You are about to run ${rowName}.`;
  }
});

export default ConfigurationRowsTableRow;
