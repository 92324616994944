import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

import Checkbox from '../../../react/common/Checkbox';

class Schema extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header with-border big-padding">
          <h3 className="box-title">Schema</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <Col componentClass={ControlLabel} xs={4}>
              Name
            </Col>
            <Col xs={8}>
              <FormControl
                type="text"
                value={this.props.schema_name}
                onChange={(e) => this.props.onChange({ schema_name: e.target.value })}
                disabled={this.props.disabled}
              />
              <HelpBlock>Name of the schema to be created.</HelpBlock>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col xs={8} xsOffset={4}>
              <Checkbox
                checked={this.props.reset_password}
                onChange={(checked) => this.props.onChange({ reset_password: checked })}
                disabled={this.props.disabled}
              >
                Reset password
              </Checkbox>
            </Col>
          </FormGroup>
        </div>
      </div>
    );
  }
}

Schema.propTypes = {
  schema_name: PropTypes.string.isRequired,
  reset_password: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default Schema;
