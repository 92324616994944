import { KEBOOLA_EX_ONEDRIVE } from '../../constants/componentIds';
import columnTypes from '../configurations/utils/columnTypeConstants';
import createOauthSection from '../configurations/utils/createOauthSection';
import createRoute from '../configurations/utils/createRoute';
import conform from './adapters/conform';
import * as rowAdapter from './adapters/row';
import Row from './react/Row';

export default createRoute({
  componentId: KEBOOLA_EX_ONEDRIVE,
  index: {
    authRequired: true,
    sections: [createOauthSection()]
  },
  row: {
    hasState: false,
    onConform: conform,
    sections: [
      {
        render: Row,
        onSave: rowAdapter.createConfiguration,
        onCreate: rowAdapter.createEmptyConfiguration,
        onLoad: rowAdapter.parseConfiguration
      }
    ],
    columns: [
      {
        name: 'Output Table',
        type: columnTypes.TABLE_LINK_DEFAULT_BUCKET,
        value: (row) => row.getIn(['parameters', 'worksheet', 'name'], '')
      }
    ]
  }
});
