import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DescriptionModal from '../../../react/common/DescriptionModal';
import RowActionMenuItem from '../../../react/common/RowActionMenuItem';
import MetadataActionCreators from '../../components/MetadataActionCreators';
import { MetadataKeys } from '../../components/MetadataConstants';
import { getDescriptionValue } from '../helpers';

const DescriptionButton = (props: {
  entity: string;
  data: Map<string, any>;
  readOnly: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}) => {
  const [show, setShow] = React.useState(false);
  const description = getDescriptionValue(props.data.get('metadata'));

  return (
    <>
      <RowActionMenuItem onSelect={() => setShow(true)} onKeyDown={props.onKeyDown}>
        <FontAwesomeIcon fixedWidth icon={!!description ? 'note-sticky' : ['far', 'note-sticky']} />
        {!!description || props.readOnly ? 'Show' : 'Add'} Description
      </RowActionMenuItem>
      <DescriptionModal
        show={show}
        onHide={() => setShow(false)}
        entity={props.entity}
        description={description}
        onSave={(newDescription) => {
          return MetadataActionCreators.saveMetadata(
            props.entity,
            props.data.get('id'),
            MetadataKeys.DESCRIPTION,
            newDescription.trim()
          );
        }}
        readOnly={props.readOnly}
      />
    </>
  );
};

export default DescriptionButton;
