import React from 'react';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import {
  KEBOOLA_EX_SAMPLE_DATA,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_PROJECT_BACKUP
} from '../constants/componentIds';
import * as Constants from '../constants/KbcConstants';
import Dispatcher from '../Dispatcher';
import ComponentsStore from '../modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '../modules/components/stores/InstalledComponentsStore';
import { routeNames as ComponentsRouteNames } from '../modules/components-directory/constants';
import { routeNames as FlowsRouteNames } from '../modules/flows/constants';
import { ActionTypes as JobsActionTypes } from '../modules/jobs/Constants';
import JobsStore from '../modules/jobs/stores/JobsStore';
import { routeNames as OrchestrationsRouteNames } from '../modules/orchestrations-v2/constants';
import { ActionTypes as QueueActionTypes } from '../modules/queue/constants';
import queueStore from '../modules/queue/store';
import { routeNames as WorkspacesRouteNames } from '../modules/sandboxes/Constants';
import { routeNames as TransformationsRouteNames } from '../modules/transformations-v2/constants';
import Error from '../utils/errors/Error';
import { createPresentationalError } from '../utils/errors/helpers';
import { notifyProductFruitsWhenRouteChanges } from '../utils/external/productFruits';
import * as pathParser from '../utils/pathParser';
import StoreUtils, { initStore } from '../utils/StoreUtils';
import RoutePendingStore from './RoutePendingStore';

let _store = initStore(
  'RoutesStore',
  Map({
    router: {},
    routerState: Map(),
    routesByName: Map(),
    breadcrumbs: List()
  })
);

const routeNameToComponentMapping = {
  'data-takeout': KEBOOLA_PROJECT_BACKUP
};

/*
  Converts nested routes structure to flat Map indexed by route name
*/
const nestedRoutesToByNameMap = (route) => {
  const map = {};
  const traverse = (r) => {
    if (r.name) {
      map[r.name] = r;
    }

    if (r.childRoutes) {
      return r.childRoutes.forEach(traverse);
    }
  };

  traverse(route);
  return fromJS(map);
};

const getRoute = (store, routeName) => {
  return store.getIn(['routesByName', routeName], Map());
};

/*
 Returns title for route
*/
const getRouteTitle = (store, routeName) => {
  const route = getRoute(store, routeName);

  if (route.isEmpty()) {
    return null;
  }

  if (route.has('titleHandler')) {
    return React.createElement(route.get('titleHandler'));
  }

  if (_.isFunction(route.get('title'))) {
    return route.get('title')(store.get('routerState'));
  }

  return route.get('title', '');
};

const getRouteSettings = (store, routeName) => {
  const route = getRoute(store, routeName);

  return route.get('settings', Map());
};

const getRouteIsRunning = (store, routeName) => {
  const route = getRoute(store, routeName);
  const isRunning = route.get('isRunning', false);

  if (_.isFunction(isRunning)) {
    return isRunning(store.get('routerState'));
  }

  return isRunning;
};

const getCurrentRouteName = (store) => {
  const route = store.getIn(['routerState', 'routes'], List()).findLast((route) => {
    return (
      !!route.get('name') &&
      /*
        if last route is shared codes versions or workspaces versions
        use previous route instead, to keep editing (name edit) or workspace active label
      */
      ![
        TransformationsRouteNames.SHARED_CODE_VERSIONS,
        WorkspacesRouteNames.WORKSPACE_VERSIONS
      ].includes(route.get('name'))
    );
  });

  if (!route) {
    return null;
  }

  return route.get('name');
};

const generateBreadcrumbs = (store) => {
  let routes = store.getIn(['routerState', 'routes'], List()).filter((route) => {
    /*
      Versions, notifications or discovery should not modify page title and breadcrumb
      It does not apply for versions of legacy transformations, orchestrations and orchestration-v2
    */
    if (
      ['discovery', 'notifications', 'versions'].includes(route.get('path')) &&
      ![
        'transformation-bucket-versions',
        'transformation-bucket-row-versions',
        'orchestrator-versions',
        OrchestrationsRouteNames.VERSIONS
      ].includes(route.get('name'))
    ) {
      return false;
    }

    return !!route.get('name');
  });

  if (
    routes.count() > 1 &&
    routes.first().get('name') === ComponentsRouteNames.ROOT &&
    routes.last().get('name') === ComponentsRouteNames.COMPONENT
  ) {
    routes = routes.splice(
      0,
      1,
      Map({
        title: 'Components',
        name: ComponentsRouteNames.CONFIGURATIONS,
        link: Map({ to: ComponentsRouteNames.CONFIGURATIONS })
      })
    );
  } else if (
    routes.count() > 1 &&
    routes.first().get('name') === ComponentsRouteNames.ROOT &&
    ![ComponentsRouteNames.COMPONENT, ComponentsRouteNames.CONFIGURATIONS].includes(
      routes.last().get('name')
    )
  ) {
    let componentId = RoutesStore.getCurrentRouteComponentId();

    if (componentId === KEBOOLA_EX_SAMPLE_DATA) {
      componentId = InstalledComponentsStore.getConfigData(
        componentId,
        RoutesStore.getConfigId()
      ).getIn(['parameters', 'componentId'], componentId);
    }

    routes = routes
      .filter((route) => route.get('name') !== ComponentsRouteNames.COMPONENT)
      .splice(
        0,
        1,
        Map({
          title: 'Components',
          name: ComponentsRouteNames.CONFIGURATIONS,
          link: Map({ to: ComponentsRouteNames.CONFIGURATIONS })
        }),
        Map({
          title: ComponentsStore.hasComponent(componentId)
            ? ComponentsStore.getComponent(componentId).get('name')
            : componentId,
          name: ComponentsRouteNames.COMPONENT,
          link: fromJS({ to: ComponentsRouteNames.COMPONENT, params: { component: componentId } })
        })
      );
  }

  return routes.map((route) =>
    Map({
      title: route.get('title') || getRouteTitle(store, route.get('name')),
      subtitle: getRoute(store, route.get('name')).get('subtitle'),
      name: route.get('name'),
      link: route.get(
        'link',
        Map({
          to: route.get('name'),
          params: route.get('path', '').includes(':')
            ? store.getIn(['routerState', 'params'], Map())
            : Map()
        })
      )
    })
  );
};

const RoutesStore = StoreUtils.createStore({
  isError() {
    return _store.has('error');
  },

  getRouter() {
    return _store.get('router');
  },

  getBreadcrumbs() {
    return _store.get('breadcrumbs');
  },

  getCurrentRouteConfig() {
    return getRoute(_store, getCurrentRouteName(_store));
  },

  getRouterState() {
    return _store.get('routerState');
  },

  getComponentId(defaultValue) {
    if (this.getRouterState().hasIn(['params', 'component'])) {
      return this.getRouterState().getIn(['params', 'component']);
    }
    if (this.getRouterState().hasIn(['params', 'componentId'])) {
      return this.getRouterState().getIn(['params', 'componentId']);
    }
    return this.getRouteSettings().get('componentId', defaultValue);
  },

  getConfigId(defaultValue) {
    if (this.getRouterState().hasIn(['params', 'config'])) {
      return this.getRouterState().getIn(['params', 'config']);
    }
    if (this.getRouterState().hasIn(['params', 'configId'])) {
      return this.getRouterState().getIn(['params', 'configId']);
    }
    return defaultValue;
  },

  getRowId(defaultValue) {
    if (this.getRouterState().hasIn(['params', 'row'])) {
      return this.getRouterState().getIn(['params', 'row']);
    }
    return defaultValue;
  },

  getOrchestrationId(defaultValue) {
    if (this.getRouterState().hasIn(['params', 'orchestrationId'])) {
      return this.getRouterState().getIn(['params', 'orchestrationId']);
    }
    return defaultValue;
  },

  getCurrentRouteParam(paramName, defaultValue = null) {
    if (paramName === 'config' || paramName === 'configId') {
      return this.getConfigId(defaultValue);
    }

    if (paramName === 'component' || paramName === 'componentId') {
      return this.getComponentId(defaultValue);
    }

    if (paramName === 'orchestrationId') {
      return this.getOrchestrationId(defaultValue);
    }

    return this.getRouterState().getIn(['params', paramName], defaultValue);
  },

  getCurrentRouteIntParam(paramName) {
    return parseInt(this.getCurrentRouteParam(paramName), 10);
  },

  getCurrentRouteIsRunning() {
    return getRouteIsRunning(_store, getCurrentRouteName(_store));
  },

  getRouteSettings() {
    return getRouteSettings(_store, getCurrentRouteName(_store));
  },

  /*
    If it'is a component route, component id is returned
    componet is some writer or extractor like wr-db or ex-db
  */
  getCurrentRouteComponentId() {
    const routeName = getCurrentRouteName(_store);

    if (routeNameToComponentMapping[routeName]) {
      return routeNameToComponentMapping[routeName];
    }

    const componentId = this.getComponentId();

    if (!componentId) {
      const isOrchestrator = RoutesStore.getRouterState()
        .get('routes', List())
        .some((route) =>
          [FlowsRouteNames.DETAIL, OrchestrationsRouteNames.DETAIL].includes(route.get('name'))
        );

      if (isOrchestrator) {
        return KEBOOLA_ORCHESTRATOR;
      }

      return pathParser.getComponentId(
        RoutesStore.getRouterState().getIn(['location', 'pathname'])
      );
    }

    return componentId;
  },

  getError() {
    return _store.get('error');
  },

  hasRoute(routeName) {
    return !getRoute(_store, routeName).isEmpty();
  },

  getRequireDataFunctionsForRouterState(routes) {
    return fromJS(routes)
      .map((route) => _store.getIn(['routesByName', route.get('name'), 'requireData']))
      .flatten()
      .filter(_.isFunction);
  },

  getPollerForLastRoute(routes) {
    const route = fromJS(routes)
      .filter((r) => !!r.get('name'))
      .last(); // use poller only from last route in hierarchy

    if (!route) {
      return null;
    }

    return _store.getIn(['routesByName', route.get('name'), 'poll'], null);
  }
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case Constants.ActionTypes.ROUTER_ROUTE_CHANGE_SUCCESS: {
      Dispatcher.waitFor([
        // wait for updating pending state
        RoutePendingStore.dispatchToken,

        // wait for finished jobs
        queueStore.dispatchToken,
        JobsStore.dispatchToken
      ]);

      const newState = fromJS(action.routerState);

      _store = _store.set('routerState', newState).remove('error');

      if (newState.get('routes').last().get('name') !== 'notFound') {
        _store = _store.set('breadcrumbs', generateBreadcrumbs(_store));
      } else {
        // No need to generate breadcrumb if have an error, it is not rendered
        _store = _store.set('error', new Error('Page not found'));
      }

      notifyProductFruitsWhenRouteChanges();

      return RoutesStore.emitChange();
    }

    case Constants.ActionTypes.ROUTER_ROUTE_CHANGE_ERROR:
      _store = _store
        .set('routerState', fromJS(action.routerState))
        .set('error', createPresentationalError(action.error));
      return RoutesStore.emitChange();

    case Constants.ActionTypes.ROUTER_ROUTES_CONFIGURATION_RECEIVE:
      _store = _store.set('routesByName', nestedRoutesToByNameMap(action.routes));
      return RoutesStore.emitChange();

    case Constants.ActionTypes.ROUTER_HISTORY_CREATED:
    case Constants.ActionTypes.ROUTER_ROUTER_CREATED:
      _store = _store.update('router', (router) => ({ ...router, ...action.router }));
      return RoutesStore.emitChange();

    case JobsActionTypes.JOB_LOAD_SUCCESS:
    case QueueActionTypes.JOB_LOAD_SUCCESS:
      return RoutesStore.emitChange();

    default:
  }
});

export default RoutesStore;
