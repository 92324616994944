import React from 'react';
import { Promise } from 'bluebird';
import { Map } from 'immutable';

import ApplicationActionCreators from '../../actions/ApplicationActionCreators';
import ApplicationStore from '../../stores/ApplicationStore';
import SimpleError from '../../utils/errors/SimpleError';
import installedComponentsActions from '../components/InstalledComponentsActionCreators';
import StorageApi from '../components/StorageApi';
import installedComponentsStore from '../components/stores/InstalledComponentsStore';
import * as vaultActions from '../vault/actions';
import {
  filterVariablesAvailableInCurrentScope,
  filterVariablesByBranch,
  generateOauthVariableKey,
  isVariableName,
  parseVariableName,
  prepareVariableToCopy
} from '../vault/helpers';
import VariablesStore from '../vault/store';
import OauthActions from './ActionCreators';
import { Constants } from './Constants';
import OauthStore from './Store';

function createConfiguration(componentId, configId, credentialsId, wrapperComponentId) {
  const configuration =
    installedComponentsStore.getConfigData(wrapperComponentId ?? componentId, configId) || Map();
  const credentials = Map({ id: credentialsId, version: Constants.OAUTH_VERSION_3 });

  return configuration.setIn(
    wrapperComponentId ? ['parameters', componentId] : ['authorization', 'oauth_api'],
    credentials
  );
}

export function processRedirectData(
  componentId,
  configId,
  credentialsId,
  branchId,
  wrapperComponentId,
  skipSave
) {
  // config component configuration
  return OauthActions.loadCredentials(componentId, credentialsId).then(() => {
    if (skipSave) return Promise.resolve(credentialsId);
    if (!configId) {
      return saveCredentialsIntoVariable(componentId, configId, branchId, credentialsId);
    }

    return installedComponentsActions
      .loadComponentConfigData(wrapperComponentId ?? componentId, configId)
      .then(() => {
        const credentials = OauthStore.getCredentialsById(componentId, credentialsId);

        if (!credentials || !credentials.has('authorizedFor')) {
          throw new SimpleError('Auth Error.', 'Credentials was not found.');
        }

        return saveCredentialsIntoVariable(componentId, configId, branchId, credentialsId).then(
          (finalCredentialsId) => {
            // save configuration with authorization id
            return installedComponentsActions
              .saveComponentConfigData(
                wrapperComponentId ?? componentId,
                configId,
                createConfiguration(componentId, configId, finalCredentialsId, wrapperComponentId),
                `Save authorization for ${credentials.get('authorizedFor')}`
              )
              .then(() => {
                ApplicationActionCreators.sendNotification({
                  type: 'success',
                  message: () => {
                    return (
                      <>
                        Account successfully authorized for{' '}
                        <b>{credentials.get('authorizedFor')}</b>.
                      </>
                    );
                  }
                });

                return installedComponentsActions.loadComponentConfigData(
                  wrapperComponentId ?? componentId,
                  configId
                );
              });
          }
        );
      });
  });
}

export function saveCredentialsIntoVariable(componentId, configId, branchId, credentialsId) {
  if (!ApplicationStore.hasCredentialsInVariables() || isVariableName(credentialsId)) {
    return Promise.resolve(credentialsId);
  }

  const variableKey = generateOauthVariableKey(
    filterVariablesByBranch(VariablesStore.getStore().variables, branchId),
    componentId,
    configId
  );

  return vaultActions
    .createVariable({
      key: variableKey,
      value: credentialsId,
      attributes: {
        branchId,
        componentId,
        configId
      },
      flags: ['oauthCredentialsId']
    })
    .then((variable) => vaultActions.loadVariables().then(() => prepareVariableToCopy(variable)));
}

// get credentials id from configData and load credentials
export function loadCredentialsFromConfig(componentId, configId) {
  return Promise.resolve()
    .then(() => {
      if (!ApplicationStore.hasProtectedDefaultBranch()) return;
      return vaultActions.loadVariables();
    })
    .then(() => {
      const id = parseCredentialsId(
        installedComponentsStore
          .getConfigData(componentId, configId)
          .getIn(['authorization', 'oauth_api', 'id'], '')
      );

      if (!id) {
        return Promise.resolve();
      }

      return OauthActions.loadCredentials(componentId, id);
    });
}

// delete credentials and docker configuration object part
export function deleteCredentialsAndConfigAuth(componentId, configId) {
  const configData = installedComponentsStore.getConfigData(componentId, configId);
  const credentialsId = configData.getIn(['authorization', 'oauth_api', 'id'], '');
  const authorizedFor = OauthStore.getCredentialsById(componentId, credentialsId).get(
    'authorizedFor'
  );

  // delete the whole authorization object part of the configuration
  const newConfigData = configData.delete('authorization');
  const description = `Reset authorization of ${authorizedFor}`;

  return installedComponentsActions
    .saveComponentConfigData(componentId, configId, newConfigData, description)
    .then(() => {
      if (ApplicationStore.hasProtectedDefaultBranch() && isVariableName(credentialsId)) {
        const variables = filterVariablesAvailableInCurrentScope(
          VariablesStore.getStore().variables
        );
        const variable = variables?.find(
          (variable) => variable.key === parseVariableName(credentialsId)
        );

        if (variable) vaultActions.deleteVariable(variable);
      }

      return installedComponentsActions.loadComponentConfigDataForce(componentId, configId);
    });
}

export function parseCredentialsId(credentialsId) {
  if (
    ApplicationStore.hasProtectedDefaultBranch() &&
    credentialsId &&
    isVariableName(credentialsId)
  ) {
    const variables = filterVariablesAvailableInCurrentScope(VariablesStore.getStore().variables);

    return (
      variables?.find((variable) => variable.key === parseVariableName(credentialsId))?.value ??
      credentialsId
    );
  }

  return credentialsId;
}

export function generateLink(componentId, configId) {
  const description = ApplicationStore.getSapiToken().get('description');
  const tokenParams = {
    canManageBuckets: false,
    canReadAllFileUploads: false,
    componentAccess: [componentId],
    description: `${description} external oauth link`,
    expiresIn: 48 * 3600 // 48 hours in seconds
  };
  return StorageApi.createToken(tokenParams).then((token) => {
    return `${Constants.EXTERNAL_OAUTH_URL}?token=${
      token.token
    }&sapiUrl=${ApplicationStore.getSapiUrl()}#/${componentId}/${configId}`;
  });
}

export function saveDirectData(componentId, configId, authorizedFor, branchId, data) {
  return StorageApi.generateUniqueId()
    .then((credentialsId) =>
      OauthActions.postCredentials(
        componentId,
        {
          id: credentialsId,
          authorizedFor,
          ...(branchId !== null && branchId !== 'null' && { branchId })
        },
        data
      ).then(() => saveCredentialsIntoVariable(componentId, configId, branchId, credentialsId))
    )
    .then((credentialsId) => {
      // save configuration with authorization id
      return installedComponentsActions
        .saveComponentConfigData(
          componentId,
          configId,
          createConfiguration(componentId, configId, credentialsId),
          `Save direct token authorization for ${authorizedFor}`
        )
        .then(() => authorizedFor);
    });
}
