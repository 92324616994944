import React from 'react';
import createReactClass from 'create-react-class';

import FilterPanel from '../../../react/common/FilterPanel';
import TabLink from '../../../react/common/TabLink';
import TabNav from '../../../react/common/TabNav';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import { canLoadSharedBuckets } from '../../admin/privileges';
import StorageBucketsStore from '../../components/stores/StorageBucketsStore';
import MySharedBuckets from './MySharedBuckets';

const SharedFromThisProject = createReactClass({
  mixins: [createStoreMixin(StorageBucketsStore)],

  getStateFromStores() {
    return {
      sapiToken: ApplicationStore.getSapiToken(),
      mySharedBuckets: StorageBucketsStore.getAll().filter((bucket) => bucket.get('sharing')),
      availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
      availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
      urlTemplates: ApplicationStore.getUrlTemplates()
    };
  },

  getInitialState() {
    return {
      query: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], '')
    };
  },

  render() {
    if (!canLoadSharedBuckets(this.state.sapiToken)) {
      return this.renderSharedBuckets();
    }

    return (
      <>
        <div className="tabs-with-border-wrapper">
          <TabNav>
            <TabLink to="data-catalog-shared-with-you">Shared with you</TabLink>
            <TabLink to="data-catalog-shared-from-this-project" active>
              Shared from this project
            </TabLink>
          </TabNav>
        </div>
        {this.renderSharedBuckets()}
      </>
    );
  },

  renderSharedBuckets() {
    return (
      <>
        <FilterPanel
          placeholder={this.getPlaceholder}
          query={this.state.query}
          onChange={(query) => {
            this.setState({ query });
            RoutesStore.getRouter().updateQuery({ q: query });
          }}
        />
        <MySharedBuckets
          sharedBuckets={this.state.mySharedBuckets}
          sapiToken={this.state.sapiToken}
          availableUsersOptions={this.state.availableUsersOptions}
          availableProjectsOptions={this.state.availableProjectsOptions}
          query={this.state.query}
          urlTemplates={this.state.urlTemplates}
        />
      </>
    );
  },

  getPlaceholder() {
    return `Search data catalog (${this.state.mySharedBuckets.count()})`;
  }
});

export default SharedFromThisProject;
