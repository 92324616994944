import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import {
  KEBOOLA_EX_DB_MSSQL,
  KEBOOLA_EX_DB_MYSQL,
  KEBOOLA_EX_DB_PGSQL
} from '../../constants/componentIds';
import { USER_DOCUMENTATION_URL } from '../../constants/KbcConstants';
import Checkbox from './Checkbox';
import ExternalLink from './ExternalLink';

const SSLForm = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    globalData: PropTypes.instanceOf(Map),
    isEditing: PropTypes.bool.isRequired
  },

  getDefaultProps() {
    return {
      globalData: Map()
    };
  },

  render() {
    return (
      <>
        <FormGroup>
          <Col sm={8} smOffset={4}>
            <Checkbox
              disabled={this.isDisabled('enabled')}
              checked={this.isEnabled()}
              onChange={this.handleToggle.bind(this, 'enabled')}
            >
              Encrypted (SSL) connection {this.renderHelp()}
            </Checkbox>
          </Col>
        </FormGroup>
        {this.renderForm()}
      </>
    );
  },

  renderForm() {
    if (!this.isEnabled()) {
      return null;
    }

    if (this.props.componentId === KEBOOLA_EX_DB_MSSQL) {
      return (
        <>
          {this.createInput('SSL CA Certificate (ca-cert.pem)', 'ca')}
          {this.renderVerifyCheckbox()}
        </>
      );
    }

    return (
      <>
        {this.createInput('SSL Client Certificate (client-cert.pem)', 'cert')}
        {this.createInput('SSL Client Key (client-key.pem)', '#key')}
        {this.createInput('SSL CA Certificate (ca-cert.pem)', 'ca')}
        {this.createInput(
          'SSL Cipher',
          'cipher',
          'You can optionally provide a list of permissible ciphers to use for the SSL encryption.'
        )}
        {this.renderVerifyCheckbox()}
      </>
    );
  },

  createInput(labelValue, propName, help = null) {
    return (
      <FormGroup>
        <Col sm={4} componentClass={ControlLabel}>
          {labelValue}
        </Col>
        <Col sm={8}>
          <Textarea
            label={labelValue}
            disabled={this.isDisabled(propName)}
            value={
              propName === '#key'
                ? this.props.data.get('#key', this.props.data.get('key', ''))
                : this.props.data.get(propName, '')
            }
            onChange={this.handleChange.bind(this, propName)}
            className="form-control"
            minRows={4}
          />
          {help && <HelpBlock>{help}</HelpBlock>}
        </Col>
      </FormGroup>
    );
  },

  renderVerifyCheckbox() {
    return (
      <FormGroup>
        <Col sm={8} smOffset={4}>
          <Checkbox
            disabled={this.isDisabled('verifyServerCert')}
            checked={this.props.data.get('verifyServerCert', true)}
            onChange={this.handleToggle.bind(this, 'verifyServerCert')}
          >
            Verify server certificate
          </Checkbox>
        </Col>
      </FormGroup>
    );
  },

  renderHelp() {
    if (this.props.componentId === KEBOOLA_EX_DB_MYSQL) {
      return (
        <ExternalLink
          href={`${USER_DOCUMENTATION_URL}/components/extractors/database/sqldb/#mysql-encryption`}
        >
          Help
        </ExternalLink>
      );
    }

    if (this.props.componentId === KEBOOLA_EX_DB_MSSQL) {
      return (
        <ExternalLink
          href={`${USER_DOCUMENTATION_URL}/components/extractors/database/sqldb/#ms-sql-encryption`}
        >
          Help
        </ExternalLink>
      );
    }

    if (this.props.componentId === KEBOOLA_EX_DB_PGSQL) {
      return (
        <ExternalLink
          href={`${USER_DOCUMENTATION_URL}/components/extractors/database/sqldb/#postgresql-encryption`}
        >
          Help
        </ExternalLink>
      );
    }

    return null;
  },

  handleChange(propName, event) {
    return this.props.onChange(this.props.data.set(propName, event.target.value).filter(Boolean));
  },

  handleToggle(propName, checked) {
    return this.props.onChange(this.props.data.set(propName, checked));
  },

  isEnabled() {
    return this.props.data.get('enabled', false);
  },

  isDisabled(prop) {
    return !this.props.isEditing || this.props.readOnly || this.props.globalData.has(prop);
  }
});

export default SSLForm;
