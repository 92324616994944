import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { FEATURE_IS_SINGLE_TENANT } from '../../../../constants/features';
import PasswordControl from '../../../../react/common/PasswordControl';
import ExternalBucketLink from '../../../components/react/components/ExternalBucketLink';
import ExternalProjectLink from '../../../components/react/components/ExternalProjectLink';
import SyncActionSimpleValue from '../../../configurations/react/components/SyncActionSimpleValue';
import ProjectStackSelector from './ProjectStackSelector';

const SourceProject = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    stackFeatures: PropTypes.instanceOf(List).isRequired,
    value: PropTypes.shape({
      url: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    actions: PropTypes.object
  },

  render() {
    let sourceInfo = this.props.actions.get('sourceInfo');

    return (
      <Form horizontal>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Project stack
          </Col>
          <Col sm={8}>
            <ProjectStackSelector
              value={this.props.value.url}
              onChange={(url) => this.props.onChange({ url })}
              isDisabled={this.props.disabled}
              isSingleTenantStack={this.props.stackFeatures.includes(FEATURE_IS_SINGLE_TENANT)}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Storage API Token
          </Col>
          <Col sm={8}>
            <PasswordControl
              value={this.props.value.token}
              onChange={(e) => this.props.onChange({ token: e.target.value })}
              disabled={this.props.disabled}
            />
            <HelpBlock>
              Use a token with permissions limited only to read from a single bucket in the source
              project.
            </HelpBlock>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Project
          </Col>
          <Col sm={8}>
            <FormControl.Static>
              <ExternalProjectLink
                stackUrl={sourceInfo.getIn(['request', 'configData', 'parameters', 'url'])}
                projectId={sourceInfo.getIn(['data', 'project', 'projectId'])}
              >
                <SyncActionSimpleValue action={sourceInfo} valueKey={['project', 'projectName']} />
              </ExternalProjectLink>
            </FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Bucket
          </Col>
          <Col sm={8}>
            <FormControl.Static>
              <ExternalBucketLink
                stackUrl={sourceInfo.getIn(['request', 'configData', 'parameters', 'url'])}
                projectId={sourceInfo.getIn(['data', 'project', 'projectId'])}
                bucketId={sourceInfo.getIn(['data', 'project', 'bucket'])}
              >
                <SyncActionSimpleValue action={sourceInfo} valueKey={['project', 'bucket']} />
              </ExternalBucketLink>
            </FormControl.Static>
          </Col>
        </FormGroup>
      </Form>
    );
  }
});

export default SourceProject;
