import React from 'react';
import createReactClass from 'create-react-class';

import { KEBOOLA_ORCHESTRATOR } from '../../../../../constants/componentIds';
import { componentTypes } from '../../../../../constants/componentTypes';
import ConfigurationInfoPanel from '../../../../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../../../../react/common/ConfigurationTabs';
// components
import Sidebar from '../../../../../react/layout/Sidebar';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import ComponentDescription from '../../../../components/react/components/ComponentDescription';
import ComponentsStore from '../../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../../components/stores/InstalledComponentsStore';
import StorageBucketsStore from '../../../../components/stores/StorageBucketsStore';
import StorageTablesStore from '../../../../components/stores/StorageTablesStore';
import VersionsStore from '../../../../components/stores/VersionsStore';
import { CollapsibleSection } from '../../../../configurations/utils/renderHelpers';
import credentialsAdapter from '../../../adapters/credentialsAdapter';
import dimensionsAdapter from '../../../adapters/dimensionsAdapter';
import tablesLoadSettingsAdapter from '../../../adapters/tablesLoadSettingsAdapter';
import makeConfigProvisioning from '../../../configProvisioning';
import hasAnyRowWithChangedSinceSet from '../../../helpers/hasAnyRowWithChangedSinceSet';
import makeLocalStateProvisioning from '../../../localStateProvisioning';
import tablesProvisioning from '../../../tablesProvisioning';
import CredentialsContainer from '../../components/CredentialsContainer';
import DimensionsSection from '../../components/DimensionsSection';
import TablesLoadSettings from '../../components/TablesLoadSettings';
import ConfiguredTables from './ConfiguredTables';
import NewTableButton from './NewTableButton';

const COMPONENT_ID = 'keboola.gooddata-writer';

const DimensionsCollapsibleComponent = CollapsibleSection({
  title: 'Date Dimensions',
  contentComponent: DimensionsSection
});

const CredentialsCollapsibleComponent = CollapsibleSection({
  title: 'GoodData Project',
  contentComponent: CredentialsContainer
});

const LoadSettingsCollapsibleComponent = CollapsibleSection({
  title: 'Tables load settings',
  contentComponent: TablesLoadSettings
});

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      VersionsStore
    )
  ],

  getStateFromStores() {
    const configurationId = RoutesStore.getCurrentRouteParam('config');
    const { tables, deleteTable, createNewTable, toggleTableExport, getSingleRunParams } =
      tablesProvisioning(configurationId);
    const configProvisioning = makeConfigProvisioning(configurationId);
    const { isSaving, isPendingFn } = configProvisioning;
    const localStateProvisioning = makeLocalStateProvisioning(configurationId);

    // section props
    const dimensionsSectionProps = dimensionsAdapter(configProvisioning, localStateProvisioning);

    const tableLoadSettingsSectionProps = tablesLoadSettingsAdapter(configProvisioning);
    const credentialsSectionProps = credentialsAdapter(configProvisioning, localStateProvisioning);

    const isDeletingConfig = InstalledComponentsStore.isDeletingConfig(
      COMPONENT_ID,
      configurationId
    );

    return {
      allBuckets: StorageBucketsStore.getAll(),
      allTables: StorageTablesStore.getAll(),
      component: ComponentsStore.getComponent(COMPONENT_ID),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      readOnly: ApplicationStore.isReadOnly(),
      hasFlows: ApplicationStore.hasFlows(),
      isDeletingConfig,
      dimensionsSectionProps,
      tableLoadSettingsSectionProps,
      credentialsSectionProps,
      getSingleRunParams,
      configurationId,
      isPendingFn,
      isSaving,
      tables,
      deleteTable,
      createNewTable,
      toggleTableExport,
      configProvisioning
    };
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={COMPONENT_ID} configId={this.state.configurationId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.configProvisioning.config}
          hasFlows={this.state.hasFlows}
          flows={this.state.flows}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={COMPONENT_ID}
              configId={this.state.configurationId}
              placeholderEntity={componentTypes.WRITER}
            />
            <br />
            {this.renderCredentials()}
            {this.renderDimensions()}
            {this.renderLoadSettings()}
            {this.renderTables()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={COMPONENT_ID}
              configId={this.state.configurationId}
              run={{ text: this.renderRunModalContent() }}
            />
          </div>
        </div>
      </>
    );
  },

  renderDimensions() {
    return (
      <DimensionsCollapsibleComponent
        {...this.state.dimensionsSectionProps}
        disabled={this.state.readOnly || this.state.isSaving}
        readOnly={this.state.readOnly}
      />
    );
  },

  renderLoadSettings() {
    return (
      <LoadSettingsCollapsibleComponent
        {...this.state.tableLoadSettingsSectionProps}
        disabled={this.state.readOnly || this.state.isSaving}
        isComplete
      />
    );
  },

  renderCredentials() {
    return (
      <CredentialsCollapsibleComponent
        {...this.state.credentialsSectionProps}
        disabled={this.state.isSaving}
        readOnly={this.state.readOnly}
      />
    );
  },

  renderTables() {
    if (this.state.tables.count() === 0) {
      return (
        <div className="box-separator">
          <h2 className="tw-text-base tw-m-0 tw-mb-4">Tables</h2>
          <div className="box">
            <div className="box-content text-center">
              <p>No tables created yet.</p>
              {this.renderNewTableButton()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <ConfiguredTables
        readOnly={this.state.readOnly}
        loadOnly={this.state.tableLoadSettingsSectionProps.value.loadOnly}
        configurationId={this.state.configurationId}
        tables={this.state.tables}
        isSaving={this.state.isSaving}
        isTablePending={this.state.isPendingFn}
        toggleTableExport={this.state.toggleTableExport}
        newTableButton={this.renderNewTableButton()}
        deleteTable={this.state.deleteTable}
        getSingleRunParams={this.state.getSingleRunParams}
        showIncrementalResetWarning={hasAnyRowWithChangedSinceSet(
          this.state.configProvisioning.configData
        )}
      />
    );
  },

  renderNewTableButton() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <NewTableButton
        createdTables={this.state.tables}
        allBuckets={this.state.allBuckets}
        allTables={this.state.allTables}
        onCreateTable={this.createNewTableAndRedirect}
      />
    );
  },

  createNewTableAndRedirect(tableId, title) {
    const table = this.state.allTables.get(tableId);
    return this.state.createNewTable(table, title).then(() =>
      RoutesStore.getRouter().transitionTo('keboola.gooddata-writer-table', {
        config: this.state.configurationId,
        table: tableId
      })
    );
  },

  renderRunModalContent() {
    return (
      <span>
        <p>You are about to run a load of all tables.</p>
      </span>
    );
  }
});

export default Index;
