import React from 'react';
import { Button, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import { KEBOOLA_DATA_APPS } from '../../constants/componentIds';
import ConfirmButtons from '../../react/common/ConfirmButtons';
import ModalIcon from '../../react/common/ModalIcon';
import OptionalFormLabel from '../../react/common/OptionalFormLabel';
import useStores from '../../react/hooks/useStores';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import { routeNames } from './constants';

const Header = () => {
  const [show, setShow] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const [formData, setFormData] = React.useState(Map());
  const state = useStores(
    () => {
      return { readOnly: ApplicationStore.isReadOnly() };
    },
    [],
    [ApplicationStore]
  );

  const onHide = () => setShow(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsCreating(true);
    InstalledComponentsActionCreators.createConfiguration(KEBOOLA_DATA_APPS, {
      name: formData.get('name', ''),
      description: formData.get('description', '')
    })
      .then((response) => {
        RoutesStore.getRouter().transitionTo(routeNames.DATA_APP_DETAIL, { config: response.id });
        return null;
      })
      .finally(() => setIsCreating(false));
  };

  return (
    <>
      <Button bsStyle="success" onClick={() => setShow(true)} disabled={state.readOnly}>
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        Add Data App
      </Button>
      <Modal
        show={show}
        onHide={onHide}
        onEnter={() => {
          setFormData(formData.set('name', 'My Data App').set('description', ''));
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Data App</Modal.Title>
          <ModalIcon icon="plus" color="green" bold />
        </Modal.Header>
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                autoFocus
                type="text"
                placeholder="Name your data app"
                disabled={isCreating}
                value={formData.get('name', '')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData(formData.set('name', e.target.value));
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>
                Description <OptionalFormLabel />
              </ControlLabel>
              <FormControl
                componentClass="textarea"
                placeholder="Describe your data app"
                disabled={isCreating}
                value={formData.get('description', '')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData(formData.set('description', e.target.value))
                }
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              showCancel={false}
              saveButtonType="submit"
              saveLabel="Create Data App"
              isSaving={isCreating}
              isDisabled={!formData.get('name', '')}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Header;
