import React from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { Map } from 'immutable';
import { strLeft } from 'underscore.string';

import BucketStageLabel from '../../../../react/common/BucketStageLabel';
import CreatedDate from '../../../../react/common/CreatedDate';
import DevBranchLabel from '../../../../react/common/DevBranchLabel';
import FileSize from '../../../../react/common/FileSize';
import Loader from '../../../../react/common/Loader';
import RowsCount from '../../../../react/common/RowsCount';
import Tooltip from '../../../../react/common/Tooltip';
import Truncated from '../../../../react/common/Truncated';
import RoutesStore from '../../../../stores/RoutesStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from '../../../../utils/errors/helpers';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../../../utils/windowOpen';
import { routeNames as storageRouteNames } from '../../../storage/constants';
import { prepareLocationContext } from '../../../table-browser/helpers';
import StorageActionCreators from '../../StorageActionCreators';
import BucketsStore from '../../stores/StorageBucketsStore';

type Props = {
  bucketId: string;
  truncateBucketName?: boolean;
};

type State = {
  stage: string;
  bucket: Map<string, any>;
  isLoading: boolean;
};

class StorageApiBucketLinkEx extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getBucketDetails();
  }

  componentDidUpdate(prevProps: Props) {
    const bucketDetails = this.getBucketDetails();

    if (
      prevProps.bucketId !== this.props.bucketId ||
      !this.state.bucket.equals(bucketDetails.bucket)
    ) {
      this.setState(bucketDetails);
    }
  }

  render() {
    return (
      <Tooltip
        tooltip={this.renderTooltip()}
        placement="top"
        type="explanatory"
        className={classNames({ 'widest break-all': !!this.props.truncateBucketName })}
      >
        <span>{this.renderBody()}</span>
      </Tooltip>
    );
  }

  renderBody() {
    if (this.state.bucket.isEmpty()) {
      return (
        <Button
          bsStyle="link"
          onClick={this.reloadData}
          className="btn-link-inline dark flex-container inline-flex flex-start"
        >
          {this.renderBucket()}
        </Button>
      );
    }

    const location = RoutesStore.getRouterState().get('location', Map());

    return (
      <Button
        bsStyle="link"
        className="btn-link-inline flex-container inline-flex flex-start"
        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();

          if (shouldUseNewWindow(e)) {
            return windowOpen(
              RoutesStore.getRouter().createHref(storageRouteNames.BUCKET, {
                bucketId: this.state.bucket.get('id')
              })
            );
          }

          return RoutesStore.getRouter().transitionTo(
            'bucketPreview',
            { bucketId: this.props.bucketId },
            { context: prepareLocationContext(location) },
            null,
            { ...(location.get('state') ?? Map()).toJS(), scrollY: window.scrollY }
          );
        }}
      >
        {this.renderBucket()}
      </Button>
    );
  }

  renderTooltip() {
    if (this.state.bucket.isEmpty()) {
      return (
        <div>
          Bucket does not exist.
          <br />
          {this.state.isLoading ? (
            <>
              <Loader /> Loading...{' '}
            </>
          ) : (
            'Click to reload.'
          )}
        </div>
      );
    }

    return (
      <div className={classNames({ 'text-left': !!this.props.truncateBucketName })}>
        {this.props.truncateBucketName && (
          <div className="mbp-4">{this.state.bucket.get('displayName', this.props.bucketId)}</div>
        )}
        <div>
          Last change: <CreatedDate createdTime={this.state.bucket.get('lastChangeDate')} />
        </div>
        <div>
          Data size: <FileSize size={this.state.bucket.get('dataSizeBytes')} />
        </div>
        <div>
          Rows count: <RowsCount count={this.state.bucket.get('rowsCount')} />
        </div>
      </div>
    );
  }

  renderBucket() {
    return (
      <>
        {!!this.state.stage && (
          <BucketStageLabel stage={this.state.stage} className="mtp-1 mbp-1" />
        )}
        {!this.state.bucket.isEmpty() && <DevBranchLabel bucket={this.state.bucket} />}
        {this.props.truncateBucketName ? (
          <Truncated text={this.state.bucket.get('displayName', this.props.bucketId)} noTooltip />
        ) : (
          this.state.bucket.get('displayName', this.props.bucketId)
        )}
      </>
    );
  }

  getBucketDetails = () => {
    return {
      stage: strLeft(this.props.bucketId, '.'),
      bucket: BucketsStore.getBucket(this.props.bucketId, Map()),
      isLoading: false
    };
  };

  reloadData = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isLoading: true });
    StorageActionCreators.loadBucketDetailForce(this.props.bucketId)
      .catch((error: any) => {
        if (error?.response?.status === HTTP_STATUS_CODE_NOT_FOUND) {
          return;
        }

        throw error;
      })
      .finally(() => {
        this.setState(this.getBucketDetails());
      });
  };
}

export default StorageApiBucketLinkEx;
