import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';
import { startsWith } from 'underscore.string';

import { KDS_TEAM_WR_EXASOL, KDS_TEAM_WR_FIREBOLT } from '../../../../../constants/componentIds';
import PasswordControl from '../../../../../react/common/PasswordControl';
import SaveButtons from '../../../../../react/common/SaveButtons';
import Select from '../../../../../react/common/Select';
import SshForm from '../../../../../react/common/SshForm';
import SSLForm from '../../../../../react/common/SSLForm';
import TestCredentialsButtonGroup from '../../../../../react/common/TestCredentialsButtonGroup';
import Tooltip from '../../../../../react/common/Tooltip';
import { hasSshTunnel, hasSsl } from '../../../helpers';
import { getComponentFields } from '../../../templates/credentialsFields';
import hasValidCredentials from '../../../templates/hasValidCredentials';
import ProvisionedCredentials from './ProvisionedCredentials';

const CredentialsForm = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    credentials: PropTypes.instanceOf(Map).isRequired,
    globalCredentails: PropTypes.instanceOf(Map).isRequired,
    savedCredentials: PropTypes.instanceOf(Map).isRequired,
    onChangeFn: PropTypes.func.isRequired,
    changeCredentialsFn: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isProvisioningCredentials: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    testCredentialsFn: PropTypes.func.isRequired,
    cancelEditingFn: PropTypes.func.isRequired,
    saveCredentialsFn: PropTypes.func.isRequired,
    componentSupportsProvisionedCredentials: PropTypes.bool.isRequired,
    isWorkspaceCredentials: PropTypes.bool.isRequired,
    forgetPasswordFn: PropTypes.func.isRequired,
    approvedHostnames: PropTypes.instanceOf(List),
    credentialsType: PropTypes.string
  },

  render() {
    return (
      <div className="form-horizontal">
        <div className="box">
          {this.props.componentSupportsProvisionedCredentials && (
            <div className="box-header big-padding with-border">
              <h2 className="box-title">
                {this.hasCredentialsProvidedByKeboola()
                  ? 'Keboola provided database credentials'
                  : 'User specified database credentials'}
              </h2>
            </div>
          )}
          <div className="box-content">
            {this.renderSaveButtons()}
            {this.renderCredentials()}
            {this.renderSshTunnelRow()}
            {this.renderSSLForm()}
            {this.renderTestCredentialsButton()}
          </div>
        </div>
      </div>
    );
  },

  hasCredentialsProvidedByKeboola() {
    return this.props.isProvisioningCredentials || this.props.isWorkspaceCredentials;
  },

  renderSaveButtons() {
    if (this.props.readOnly || this.hasCredentialsProvidedByKeboola()) {
      return null;
    }

    return (
      <div className="save-buttons">
        <SaveButtons
          isSaving={this.props.isSaving}
          isChanged={!this.props.savedCredentials.toMap().equals(this.props.credentials.toMap())}
          disabled={
            this.props.isSaving ||
            !hasValidCredentials(this.props.componentId, this.props.credentials)
          }
          onReset={this.props.cancelEditingFn}
          onSave={this.props.saveCredentialsFn}
        />
      </div>
    );
  },

  renderCredentials() {
    if (this.hasCredentialsProvidedByKeboola()) {
      return (
        <ProvisionedCredentials
          readOnly={this.props.readOnly}
          credentials={this.props.credentials}
          componentId={this.props.componentId}
          isWorkspaceCredentials={this.props.isWorkspaceCredentials}
          resetWorkspacePasswordFn={this.props.resetWorkspacePasswordFn}
          forgetPasswordFn={this.props.forgetPasswordFn}
        />
      );
    }

    if (!this.props.isEditing) {
      return null;
    }

    let componentFields = getComponentFields(this.props.componentId);

    if (this.props.componentId === KDS_TEAM_WR_EXASOL) {
      componentFields = componentFields.filter(({ name }) =>
        this.props.credentialsType === 'saas' || this.props.credentials.has('#refresh_token')
          ? name !== '#password'
          : name !== '#refresh_token'
      );
    }

    return componentFields.map(this.createInput);
  },

  renderSshTunnelRow() {
    if (!hasSshTunnel(this.props.componentId) || this.hasCredentialsProvidedByKeboola()) {
      return null;
    }

    return (
      <SshForm
        readOnly={this.props.readOnly}
        onChange={(newSshData) => {
          return this.props.changeCredentialsFn(this.props.credentials.set('ssh', newSshData));
        }}
        data={this.props.credentials.get('ssh') || Map()}
        globalData={this.props.globalCredentails.get('ssh', Map())}
        isEnabled={this.props.isEditing}
      />
    );
  },

  renderSSLForm() {
    if (!hasSsl(this.props.componentId) || this.hasCredentialsProvidedByKeboola()) {
      return null;
    }

    return (
      <SSLForm
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        isEditing={this.props.isEditing}
        data={this.props.credentials.get('ssl', Map())}
        globalData={this.props.globalCredentails.get('ssl', Map())}
        onChange={(sslObject) => {
          return this.props.changeCredentialsFn(this.props.credentials.set('ssl', sslObject));
        }}
      />
    );
  },

  renderTestCredentialsButton() {
    if (
      this.props.readOnly ||
      this.hasCredentialsProvidedByKeboola() ||
      this.props.componentId === KDS_TEAM_WR_FIREBOLT ||
      this.props.componentId === KDS_TEAM_WR_EXASOL
    ) {
      return null;
    }

    return (
      <TestCredentialsButtonGroup
        testCredentialsFn={(forceAlterNat) => {
          return this.props.testCredentialsFn(this.props.credentials, forceAlterNat);
        }}
        componentId={this.props.componentId}
        configId={this.props.configId}
        isEditing={this.props.isEditing}
        disabled={!hasValidCredentials(this.props.componentId, this.props.credentials)}
      />
    );
  },

  createInput(field) {
    let fieldName = field.name;
    let isHashed = startsWith(fieldName, '#');

    if (this.props.isProvisioningCredentials && isHashed) {
      fieldName = fieldName.slice(1, fieldName.length);
      isHashed = false;
    }

    const value = this.props.credentials.get(fieldName);
    const defaultValue = this.props.credentials.get(fieldName, field.defaultValue);

    if (this.props.approvedHostnames && field.name === 'host') {
      const selected = this.props.approvedHostnames.find((option) => {
        return (
          `${option.get('host')}:${option.get('port')}` ===
          `${this.props.credentials.get('host')}:${this.props.credentials.get('port')}`
        );
      });

      return (
        <FormGroup key={fieldName}>
          <Col xs={4} componentClass={ControlLabel}>
            Hostname/Port
          </Col>
          <Col xs={8}>
            <Select
              clearable={false}
              placeholder="Select host"
              value={selected ? `${selected.get('host')}:${selected.get('port')}` : ''}
              options={this.props.approvedHostnames
                .map((option) => {
                  const value = `${option.get('host')}:${option.get('port')}`;
                  return { value, label: value };
                })
                .toArray()}
              onChange={(value) => {
                const option = this.props.approvedHostnames.find((option) => {
                  return `${option.get('host')}:${option.get('port')}` === value;
                });

                this.props.changeCredentialsFn(
                  this.props.credentials
                    .set('host', option.get('host'))
                    .set('port', option.get('port'))
                );
              }}
              disabled={this.isDisabled(fieldName)}
            />
          </Col>
        </FormGroup>
      );
    } else if (this.props.approvedHostnames && field.name === 'port') {
      return null;
    }

    if (isHashed) {
      return (
        <FormGroup key={fieldName}>
          <Col xs={4} componentClass={ControlLabel}>
            {field.label}{' '}
            <small>
              <Tooltip
                placement="top"
                type="explanatory"
                tooltip={`${field.label} will be stored securely encrypted.`}
              >
                <FontAwesomeIcon icon="circle-question" fixedWidth />
              </Tooltip>
            </small>
          </Col>
          <Col xs={8}>
            <PasswordControl
              value={value}
              disabled={this.isDisabled(fieldName)}
              onChange={(event) => this.props.onChangeFn(fieldName, event.target.value)}
            />
            {field.help && <HelpBlock>{field.help}</HelpBlock>}
          </Col>
        </FormGroup>
      );
    }

    switch (field.type) {
      case 'select':
        return (
          <FormGroup key={fieldName}>
            <Col xs={4} componentClass={ControlLabel}>
              {field.label}
            </Col>
            <Col xs={8}>
              <Select
                clearable={false}
                value={value || defaultValue}
                options={field.options}
                onChange={(value) => this.props.onChangeFn(fieldName, value)}
                disabled={this.isDisabled(fieldName)}
              />
              {field.help && <HelpBlock>{field.help}</HelpBlock>}
            </Col>
          </FormGroup>
        );

      case 'textarea':
        return (
          <FormGroup key={fieldName}>
            <Col xs={4} componentClass={ControlLabel}>
              {field.label}
            </Col>
            <Col xs={8}>
              <Textarea
                minRows={4}
                className="form-control"
                disabled={this.isDisabled(fieldName)}
                value={value || defaultValue}
                onChange={(event) => this.props.onChangeFn(fieldName, event.target.value)}
              />
              {field.help && <HelpBlock>{field.help}</HelpBlock>}
            </Col>
          </FormGroup>
        );

      default:
        return (
          <FormGroup key={fieldName}>
            <Col xs={4} componentClass={ControlLabel}>
              {field.label}
            </Col>
            <Col xs={8}>
              <FormControl
                type={field.type}
                disabled={this.isDisabled(fieldName)}
                value={value || defaultValue}
                onChange={(event) => {
                  const value =
                    !!event.target.value.length && field.type === 'number'
                      ? parseInt(event.target.value, 10)
                      : event.target.value;

                  this.props.onChangeFn(fieldName, value);
                }}
              />
              {field.help && <HelpBlock>{field.help}</HelpBlock>}
            </Col>
          </FormGroup>
        );
    }
  },

  isDisabled(fildName) {
    if (this.props.isDisabled || this.props.readOnly) {
      return true;
    }

    return this.props.globalCredentails.has(fildName);
  }
});

export default CredentialsForm;
