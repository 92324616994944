import { Promise } from 'bluebird';
import { List, Map } from 'immutable';

import { KEBOOLA_EX_MONGODB as componentId } from '../../constants/componentIds';
import fromJSOrdered from '../../utils/fromJSOrdered';
import string from '../../utils/string';
import { isValidJsonConfig } from '../../utils/validation';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import installedComponentsApi from '../components/InstalledComponentsApi';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import RowsActions from '../configurations/ConfigurationRowsActionCreators';

const isMappingValid = (mapping) => {
  if (Map.isMap(mapping)) {
    return isValidJsonConfig(JSON.stringify(mapping.toJS()));
  } else {
    return isValidJsonConfig(mapping);
  }
};

const isValidQuery = (query) => {
  const mode = query.get('mode', 'mapping');
  const mapping = query.get('mapping', '');
  return (
    query.get('name', '').trim().length > 0 &&
    query.get('collection', '').trim().length > 0 &&
    (query.get('query', '').toString().trim().length === 0 ||
      isValidJsonConfig(query.get('query', '').toString())) &&
    (query.get('sort', '').toString().trim().length === 0 ||
      isValidJsonConfig(query.get('sort', '').toString())) &&
    (mode === 'raw' || (mode === 'mapping' && isMappingValid(mapping)))
  );
};

const updateProtectedProperties = (newCredentials, oldCredentials) => {
  if (!newCredentials.get('#password')) {
    return newCredentials.set('#password', oldCredentials.get('#password'));
  }

  return newCredentials;
};

const migrateToRows = (configId) => {
  const config = InstalledComponentsStore.getConfig(componentId, configId);

  return Promise.each(config.get('rows', List()), (row) => {
    return installedComponentsApi.deleteConfigurationRow(
      componentId,
      configId,
      row.get('id'),
      `Row ${row.get('name')} deleted during migration`
    );
  })
    .then(() => {
      return Promise.each(
        config.getIn(['configuration', 'parameters', 'exports'], List()).toList(),
        (row) => {
          return RowsActions.createSimple(
            componentId,
            configId,
            {
              rowId: row.get('id'),
              name: row.get('name'),
              isDisabled: !row.get('enabled'),
              configuration: JSON.stringify({
                parameters: row
                  .set('tableName', string.webalize(row.get('name')))
                  .delete('id')
                  .delete('name')
                  .delete('enabled')
                  .toJS()
              }),
              ...(config.hasIn(['state', 'component', 'lastFetchedRow', row.get('id')]) && {
                state: JSON.stringify({
                  component: {
                    lastFetchedRow: config.getIn([
                      'state',
                      'component',
                      'lastFetchedRow',
                      row.get('id')
                    ])
                  }
                })
              })
            },
            `Migrating table ${row.get('name')} to configuration row`
          );
        }
      );
    })
    .then(() => {
      return InstalledComponentsActionCreators.resetComponentConfigurationState(
        componentId,
        configId
      );
    })
    .then(() => {
      return InstalledComponentsActionCreators.saveComponentConfigData(
        componentId,
        configId,
        config.get('configuration').deleteIn(['parameters', 'exports']),
        'Migrating configuration to rows.'
      );
    });
};

const prepareQueryToSave = (query) => {
  let newQuery = query;

  const mode = newQuery.get('mode', 'mapping');

  if (mode === 'mapping') {
    const mapping = newQuery.get('mapping', Map());
    if (Map.isMap(mapping)) {
      newQuery = newQuery.set('mapping', mapping.toJS());
    } else {
      newQuery = newQuery.set('mapping', fromJSOrdered(JSON.parse(mapping)));
    }
  } else {
    newQuery = newQuery.delete('mapping').delete('includeParentInPK');
  }

  if (newQuery.get('query') || newQuery.get('sort')) {
    newQuery = newQuery.delete('incrementalFetchingColumn');
  } else if (newQuery.get('incrementalFetchingColumn')) {
    newQuery = newQuery.delete('query').delete('sort');
  }

  return newQuery;
};

export {
  isMappingValid,
  isValidQuery,
  updateProtectedProperties,
  migrateToRows,
  prepareQueryToSave
};
