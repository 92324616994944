import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Map } from 'immutable';

import ConfirmModal from '../../../react/common/ConfirmModal';

class DeleteProject extends React.Component {
  state = {
    showModal: false,
    isDeleting: false
  };

  render() {
    return (
      <div className="well pp-6">
        <h3 className="tw-mt-0 tw-mb-6">Delete this project</h3>
        <div className="value-list mb-0">
          <p className="tw-mb-6">
            <strong>We&apos;ll keep a backup for 60 days.</strong> After 60 days, the project will
            be permanently deleted.
          </p>
          <Button bsSize="sm" bsStyle="danger" onClick={() => this.setState({ showModal: true })}>
            Delete project
          </Button>
        </div>
        <ConfirmModal
          closeAfterResolve
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          icon="trash"
          title="Delete Project"
          text={
            <p>
              Are you sure you want to delete the project{' '}
              <strong>{this.props.project.get('name')}</strong>?
            </p>
          }
          buttonType="danger"
          buttonLabel="Delete Project"
          onConfirm={this.handleDelete}
          isLoading={this.state.isDeleting}
        />
      </div>
    );
  }

  handleDelete = () => {
    this.setState({ isDeleting: true });
    return this.props.onDelete().finally(() => this.setState({ isDeleting: false }));
  };
}

DeleteProject.propTypes = {
  project: PropTypes.instanceOf(Map).isRequired,
  onDelete: PropTypes.func.isRequired
};

export default DeleteProject;
