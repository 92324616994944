import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from '../../../react/common/Tooltip';
import ApplicationStore from '../../../stores/ApplicationStore';
import { canDownloadSlicedFile } from '../../admin/privileges';
import ModalHandler from '../../sapi-events/sliced-files-downloader/ModalHandler';

const FileLinkButton = (props) => {
  if (props.file.get('isSliced') && !canDownloadSlicedFile(ApplicationStore.getSapiToken())) {
    return null;
  }

  if (props.file.get('isSliced')) {
    return <ModalHandler mode="button" file={props.file} onKeyDown={props.onKeyDown} />;
  }

  return (
    <Tooltip tooltip="Download file" placement="top">
      <a className="btn btn-link text-muted" href={props.file.get('url')}>
        <FontAwesomeIcon icon="circle-down" fixedWidth />
      </a>
    </Tooltip>
  );
};

FileLinkButton.propTypes = {
  file: PropTypes.object,
  onKeyDown: PropTypes.func
};

export default FileLinkButton;
