import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ConfirmButtons from '../../../react/common/ConfirmButtons';

const AntiSamplingModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    hasAdaptive: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
  },

  getInitialState() {
    return {
      value: ''
    };
  },

  render() {
    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState({ value: this.props.value })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Up Anti-sampling</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <p>
              Sampling in Analytics is the practice of selecting a subset of data from your traffic
              and reporting on the trends available in that sample set. If your API call covers a
              date range greater than the set session limits, it will return a sampled call. To
              avoid this and get more precise results, you can use one of the following algorithms.
            </p>
            {this.renderBody()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons block onSave={this.handleSave} saveLabel="Set" saveStyle="primary" />
        </Modal.Footer>
      </Modal>
    );
  },

  handleSave() {
    this.props.onSave(this.state.value);
    this.props.onHide();
  },

  renderBody() {
    return (
      <>
        {this.createRadioInput(
          'Daily Walk algorithm',
          'dailyWalk',
          'Will make one request per date in the date range. You will get the most precise results, but it takes a lot of API calls.'
        )}
        {this.props.hasAdaptive &&
          this.createRadioInput(
            'Adaptive algorithm',
            'adaptive',
            'Will divide the date range into multiple smaller date ranges. This is way faster, but might not be that precise.'
          )}
        {this.createRadioInput('None', '', 'No anti-sampling algorithm used.')}
      </>
    );
  },

  createRadioInput(name, value, description) {
    return (
      <div className="radio">
        <label>
          <input
            type="radio"
            name="antisampling"
            id={name}
            value={value}
            checked={this.state.value === value}
            onChange={() => this.setState({ value })}
          />
          {name}
          <small className="help-block">{description}</small>
        </label>
      </div>
    );
  }
});

export default AntiSamplingModal;
