import React from 'react';
import type { Map } from 'immutable';

import LastChange from './LastChange';
import type { TableRow } from './Table';

const LastChangeActionCell = (props: {
  admins: Map<string, any>;
  row: TableRow;
  renderAction: (row: TableRow, body: React.ReactNode) => React.ReactNode;
}) => {
  return props.renderAction(
    props.row,
    <LastChange configuration={props.row.values.data.config} admins={props.admins} />
  );
};

const MemoizedLastChangeActionCell = React.memo(LastChangeActionCell);

export default MemoizedLastChangeActionCell;
