import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { SERVICE_OAUTH } from '../../../constants/serviceIds';
import ApplicationStore from '../../../stores/ApplicationStore';
import { windowOpen } from '../../../utils/windowOpen';
import StorageApi from '../../components/StorageApi';
import ServicesStore from '../../services/Store';
import { Constants } from '../Constants';
import { getReturnUrl } from '../helpers';
import { processRedirectData } from '../OauthUtils';

const AuthorizationForm = createReactClass({
  propTypes: {
    authorizedFor: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string,
    wrapperComponentId: PropTypes.string,
    idPrefix: PropTypes.string,
    branchId: PropTypes.string,
    children: PropTypes.any,
    onComplete: PropTypes.func,
    skipSave: PropTypes.bool
  },

  windowRef: null,

  windowEventHandler(event) {
    if (event.data?.isAuthorized) {
      processRedirectData(
        this.props.componentId,
        this.props.configId,
        event.data.credentialsId,
        this.props.branchId,
        this.props.wrapperComponentId,
        this.props.skipSave
      ).then((credentialsId) => {
        this.windowRef?.close();
        this.props.onComplete?.({
          id: credentialsId,
          version: Constants.OAUTH_VERSION_3
        });
      });

      window.removeEventListener('message', this.windowEventHandler);
    }
  },

  componentDidMount() {
    window.addEventListener('message', this.windowEventHandler, false);
  },

  componentWillUnmount() {
    window.removeEventListener('message', this.windowEventHandler);
  },

  render() {
    const oauthUrl = ServicesStore.getServiceUrl(SERVICE_OAUTH);
    const actionUrl = `${oauthUrl}/authorize/${this.props.componentId}`;

    return (
      <form
        method="POST"
        action={actionUrl}
        target="OAuthPopup"
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          StorageApi.generateUniqueId().then((uniqueId) => {
            this.appendHiddenInputs(
              this.props.idPrefix ? `${this.props.idPrefix}-${uniqueId}` : uniqueId
            );
            this.windowRef = windowOpen('', 'popup,toolbar=no,menubar=no', 'OAuthPopup');
            this.formElementRef.submit();
          });
        }}
        className="form form-horizontal"
        ref={(formElement) => (this.formElementRef = formElement)}
      >
        {this.props.children}
      </form>
    );
  },

  appendHiddenInputs(credentialsId) {
    this.appendHiddenInput('token', ApplicationStore.getSapiTokenString());
    this.appendHiddenInput('id', credentialsId);
    this.appendHiddenInput('returnUrl', getReturnUrl(credentialsId));
    this.appendHiddenInput('authorizedFor', this.props.authorizedFor);

    if (this.props.branchId !== null && this.props.branchId !== 'null') {
      this.appendHiddenInput('branchId', this.props.branchId);
    }
  },

  appendHiddenInput(name, value) {
    const existingInput = this.formElementRef.querySelector(`input[name=${name}]`);

    if (existingInput) {
      existingInput.value = value;
    } else {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = value;
      this.formElementRef.appendChild(input);
    }
  }
});

export default AuthorizationForm;
