import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import InlineEditTextInput from '../../../react/common/InlineEditTextInput';
import Tooltip from '../../../react/common/Tooltip';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import { canManageBucket } from '../../admin/privileges';
import StorageBucketsStore from '../../components/stores/StorageBucketsStore';
import StorageTablesStore from '../../components/stores/StorageTablesStore';
import DevBranchesStore from '../../dev-branches/DevBranchesStore';
import { isCreatedInDevBranch } from '../../dev-branches/helpers';
import { updateBucket } from '../actions';
import { validateBucketName } from '../helpers';

const BucketNameEdit = createReactClass({
  mixins: [
    createStoreMixin(ApplicationStore, DevBranchesStore, StorageBucketsStore, StorageTablesStore)
  ],

  propTypes: {
    bucketId: PropTypes.string.isRequired
  },

  getInitialState() {
    const bucket = StorageBucketsStore.getBucket(this.props.bucketId, Map());

    return {
      value: bucket.get('displayName', this.props.bucketId),
      isEditing: false,
      isSaving: false,
      error: null
    };
  },

  getStateFromStores() {
    const bucket = StorageBucketsStore.getBucket(this.props.bucketId, Map());

    return {
      originalValue: bucket.get('displayName', this.props.bucketId),
      bucket,
      readOnly: !canManageBucket(ApplicationStore.getSapiToken(), bucket),
      allOtherBuckets: StorageBucketsStore.getAll().filter(
        (bucket) => this.props.bucketId !== bucket.get('id')
      ),
      hasAnyTable: StorageTablesStore.getAll().some(
        (table) => table.getIn(['bucket', 'id']) === this.props.bucketId
      )
    };
  },

  render() {
    return (
      <>
        <FontAwesomeIcon
          icon={this.state.hasAnyTable ? 'folder' : ['far', 'folder']}
          style={{ fontSize: '0.9em' }}
          className={classNames('text-muted', 'icon-addon-right', {
            'dev-bucket': isCreatedInDevBranch(this.state.bucket)
          })}
        />
        {this.renderTableName()}
      </>
    );
  },

  renderTableName() {
    if (this.state.readOnly) {
      return this.state.value;
    }

    return (
      <span className={classNames({ 'input-error': !!this.state.error })}>
        <Tooltip
          placement="bottom"
          tooltip={this.state.error}
          forceShow={!!this.state.error}
          type="explanatory"
        >
          <InlineEditTextInput
            isValid={!this.state.error}
            isChanged={this.state.value !== this.state.originalValue}
            text={this.state.value}
            isSaving={this.state.isSaving}
            isEditing={this.state.isEditing}
            onEditStart={this.handleEditStart}
            onEditCancel={this.handleEditCancel}
            onEditChange={this.handleEditChange}
            onEditSubmit={this.handleEditSubmit}
          />
        </Tooltip>
      </span>
    );
  },

  handleEditStart() {
    this.setState({ isEditing: true, error: null });
  },

  handleEditChange(newValue) {
    this.setState({
      value: newValue,
      error: validateBucketName(
        newValue,
        this.state.bucket.get('stage'),
        this.state.allOtherBuckets,
        DevBranchesStore.getCurrentId(),
        { strict: false }
      )
    });
  },

  handleEditCancel() {
    this.setState({
      error: null,
      isSaving: false,
      isEditing: false,
      value: this.state.originalValue
    });
  },

  handleEditSubmit() {
    this.setState({ error: null, isSaving: true });
    return updateBucket(this.props.bucketId, { displayName: this.state.value })
      .catch((error) => this.setState({ error }))
      .finally(() => this.setState({ isSaving: false, isEditing: false }));
  }
});

export default BucketNameEdit;
