import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import Select from '../../../../react/common/Select';
import { Types } from '../../helpers/constants';
import makeColumnDefinition from '../../helpers/makeColumnDefinition';

const PreferencesColumn = createReactClass({
  propTypes: {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    column: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    showAdvanced: PropTypes.bool
  },

  renderDbName() {
    return (
      <FormGroup className="col-sm-12">
        <Col sm={4} componentClass={ControlLabel}>
          BigQuery Column
        </Col>
        <Col sm={8}>
          <FormControl
            type="text"
            disabled={this.props.disabled}
            onChange={(e) => this.onChangeColumn('dbName', e.target.value)}
            value={this.props.column.dbName}
          />
        </Col>
      </FormGroup>
    );
  },

  render() {
    const { fields } = makeColumnDefinition(this.props.column);
    return (
      <>
        <FormGroup className="col-sm-12">
          <Col sm={4} componentClass={ControlLabel}>
            Data Type
          </Col>
          <Col sm={8}>
            <Select
              clearable={false}
              value={this.props.column.type}
              options={Object.keys(Types).map((value) => ({ value, label: value }))}
              onChange={(value) => this.onChangeColumn('type', value)}
              disabled={this.props.disabled}
            />
          </Col>
        </FormGroup>
        {fields.dbName.show && this.renderDbName()}
      </>
    );
  },

  onChangeColumn(property, value) {
    const newColumn = makeColumnDefinition(this.props.column).updateColumn(property, value).column;
    this.props.onChange(newColumn);
  }
});

export default PreferencesColumn;
