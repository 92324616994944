import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { fromJS, List, Map } from 'immutable';
import { strRightBack } from 'underscore.string';

import keyCodes from '../../../constants/keyCodes';
import BucketStageLabel from '../../../react/common/BucketStageLabel';
import DevBranchLabel from '../../../react/common/DevBranchLabel';
import Duration from '../../../react/common/Duration';
import FileSize from '../../../react/common/FileSize';
import Loader from '../../../react/common/Loader';
import RowsCount from '../../../react/common/RowsCount';
import Tooltip from '../../../react/common/Tooltip';
import RoutesStore from '../../../stores/RoutesStore';
import { parse } from '../../../utils/tableIdParser';
import StorageTableLink from '../../components/react/components/StorageApiTableLinkEx';
import StorageActionCreators from '../../components/StorageActionCreators';
import StorageApi from '../../components/StorageApi';
import { exportTables } from '../../storage/actions';
import ExportModal from '../../storage/components/ExportModal';
import { getBucketDisplayNameFromName } from '../../storage/helpers';

class LoadingData extends React.Component {
  static propTypes = {
    allTables: PropTypes.instanceOf(Map).isRequired,
    getExpectedTables: PropTypes.func.isRequired,
    job: PropTypes.instanceOf(Map).isRequired,
    isProcessing: PropTypes.bool.isRequired,
    exportingTables: PropTypes.instanceOf(Map).isRequired,
    allBuckets: PropTypes.instanceOf(Map),
    allProcessingFinished: PropTypes.func,
    simplifiedList: PropTypes.bool,
    canExportTable: PropTypes.bool,
    hasNewQueue: PropTypes.bool.isRequired
  };

  static defaultProps = {
    canExportTable: true
  };

  state = {
    stats: Map(),
    tableToExport: ''
  };

  componentDidMount() {
    this.collectStats(this.props.job);

    if (!this.props.job.get('endTime')) {
      setTimeout(this.startPolling, 5000);
    }
  }

  componentDidUpdate() {
    if (this.props.job.get('endTime')) {
      setTimeout(this.stopPolling, 10000);
    }
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  render() {
    if (this.props.job.isEmpty() && this.props.isProcessing) {
      return (
        <div
          className={classNames('box-content', { 'simplified-list': this.props.simplifiedList })}
        >
          <Loader /> Loading job detail...
        </div>
      );
    }

    return (
      <>
        <Table className={classNames({ 'table-hover simplified-list': this.props.simplifiedList })}>
          {!this.props.simplifiedList && (
            <thead>
              <tr>
                <th className="pt-0">Table</th>
                <th className="w-100 text-right pt-0">Size</th>
                <th className="w-150 text-right pt-0">Rows</th>
                <th className="w-150 text-right pt-0">Duration</th>
                <th className="w-150 text-right pt-0">Status</th>
              </tr>
            </thead>
          )}
          <tbody>{this.renderTables()}</tbody>
        </Table>
        {this.props.canExportTable && (
          <ExportModal
            show={!!this.state.tableToExport}
            tables={List([this.props.allTables.get(this.state.tableToExport, Map())])}
            onSubmit={(type) => {
              exportTables(List([this.props.allTables.get(this.state.tableToExport, Map())]), type);
            }}
            onHide={() => this.setState({ tableToExport: '' })}
          />
        )}
      </>
    );
  }

  renderTables() {
    return this.props
      .getExpectedTables()
      .sortBy((tableId) => tableId.toLowerCase())
      .map((tableId) => {
        return {
          tableId,
          storageTable: this.props.allTables.get(tableId, Map()),
          statsTable: this.state.stats
            .getIn(['tables', 'import', 'tables'], List())
            .find((table) => table.get('id') === tableId, null, Map())
        };
      })
      .groupBy(({ tableId }) => {
        const { stage, bucket } = parse(tableId).parts;

        return `${stage}.${bucket}`;
      })
      .map((tables, bucketId) => {
        const [stage, displayName] = bucketId.split('.');
        const bucket =
          this.props.allBuckets?.get(bucketId) ||
          Map({ stage, displayName: getBucketDisplayNameFromName(displayName) });

        return (
          <React.Fragment key={bucketId}>
            {this.props.simplifiedList && (
              <tr className="no-hover bucket-row">
                <td className="pl-2 flex-container flex-start font-medium">
                  <FontAwesomeIcon icon="folder" className="color-base icon-addon-right" />
                  <BucketStageLabel stage={bucket.get('stage')} />
                  <DevBranchLabel bucket={bucket} />
                  {bucket.get('displayName')}
                </td>
              </tr>
            )}
            {tables
              .map(({ tableId, storageTable, statsTable }) => {
                const isExporting = this.props.exportingTables.get(tableId, false);

                return (
                  <tr
                    key={tableId}
                    className={classNames({
                      'table-row': this.props.simplifiedList,
                      'hoverable-actions': this.props.canExportTable,
                      'no-hover': this.props.isProcessing
                    })}
                    {...(!this.props.isProcessing && {
                      onClick: () => this.redirectToTable(tableId),
                      onKeyDown: (event) => {
                        if (event.key === keyCodes.ENTER) {
                          this.redirectToTable(tableId);
                        }
                      },
                      role: 'button',
                      tabIndex: '0'
                    })}
                  >
                    <td
                      className={classNames('no-wrap', {
                        'pl-3': this.props.simplifiedList,
                        'text-muted': this.props.isProcessing
                      })}
                    >
                      <FontAwesomeIcon
                        icon="table"
                        fixedWidth
                        className="text-muted icon-addon-right"
                      />
                      {this.props.isProcessing ? (
                        strRightBack(tableId, '.')
                      ) : (
                        <StorageTableLink
                          tableId={tableId}
                          showLabels={false}
                          {...(this.props.simplifiedList && {
                            paddingless: true,
                            showOnlyDisplayName: true,
                            className: 'link-inherit'
                          })}
                        />
                      )}
                    </td>
                    {this.props.canExportTable ? (
                      !this.props.isProcessing && (
                        <td className="pt-0 pb-0 pl-0 pr-2 no-wrap">
                          {isExporting ? (
                            <Tooltip placement="top" tooltip="Preparing Export">
                              <Loader />
                            </Tooltip>
                          ) : (
                            <Tooltip placement="top" tooltip="Export Table">
                              <Button
                                bsStyle="link"
                                className="text-muted"
                                onClick={(e) => {
                                  e.stopPropagation();

                                  this.setState({ tableToExport: tableId });
                                }}
                                disabled={storageTable.isEmpty()}
                              >
                                <FontAwesomeIcon icon="down-to-line" />
                              </Button>
                            </Tooltip>
                          )}
                        </td>
                      )
                    ) : (
                      <>
                        <td className="text-right">
                          <FileSize size={storageTable.get('dataSizeBytes')} />
                        </td>
                        <td className="text-right">
                          <RowsCount count={storageTable.get('rowsCount')} />
                        </td>
                        <td className="text-right">
                          <Duration duration={statsTable.get('durationTotalSeconds')} />
                        </td>
                        <td className="text-right font-medium">
                          {this.renderStatus(storageTable, statsTable)}
                        </td>
                      </>
                    )}
                  </tr>
                );
              })
              .toArray()}
          </React.Fragment>
        );
      })
      .toArray();
  }

  renderStatus(storageTable, statsTable) {
    if (!this.props.isProcessing && statsTable.isEmpty()) {
      return <span className="text-muted">Skipped</span>;
    }

    if (storageTable.isEmpty() && statsTable.isEmpty()) {
      return <span className="text-muted">Waiting</span>;
    }

    if (!storageTable.isEmpty() && statsTable.isEmpty()) {
      return <span className="text-success">Loading</span>;
    }

    if (!statsTable.isEmpty()) {
      return <span className="text-success">Finished</span>;
    }

    return <span className="text-muted">N/A</span>;
  }

  collectStats = (job) => {
    const runId =
      this.props.hasNewQueue && !job.get('useLegacyRunId') ? job.get('id') : job.get('runId');

    if (!runId) {
      return;
    }

    this.cancellablePromise = StorageApi.getRunIdStats(runId)
      .then((data) => {
        const stats = fromJS(data);
        const statsImportTables = stats.getIn(['tables', 'import', 'tables'], List());
        const allProcessingFinished = this.props
          .getExpectedTables()
          .map((tableId) => statsImportTables.find((table) => table.get('id') === tableId))
          .every(Boolean);

        if (allProcessingFinished) {
          this.props.allProcessingFinished?.();
        }

        this.setState({ stats });
      })
      .then(() => StorageActionCreators.loadBucketsAndTablesForce());
  };

  startPolling = () => {
    this.timeout = setInterval(() => this.collectStats(this.props.job), 5000);
  };

  stopPolling = () => {
    this.timeout && clearInterval(this.timeout);
    this.cancellablePromise && this.cancellablePromise.cancel();
  };

  redirectToTable = (tableId) => {
    RoutesStore.getRouter().transitionTo(
      'tablePreview',
      { tableId },
      { context: window.location.pathname }
    );
  };
}

export default LoadingData;
