/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import classnames from 'classnames';
import _ from 'underscore';

import SearchBar from './SearchBar';

const FilterPanel = (props: {
  query: string;
  onChange: (query: string) => void;
  className?: string;
  additionalActions?: React.ReactNode;
  placeholder?: string | (() => string);
  autoFocus?: boolean;
}) => {
  const [query, setQuery] = React.useState(props.query);

  React.useEffect(() => {
    if (query !== props.query) {
      setQuery(props.query);
    }
  }, [props.query]);

  const debouncedOnChange = React.useMemo(() => {
    return _.debounce((newQuery: string) => {
      if (newQuery.length > 1 || !newQuery || newQuery.length < props.query.length) {
        props.onChange(newQuery);
      }
    }, 200);
  }, [props.onChange]);

  return (
    <div className={classnames('search-panel box', props.className)}>
      <SearchBar
        autoFocus={props.autoFocus}
        placeholder={props.placeholder}
        query={query}
        onChange={(newQuery) => {
          setQuery(newQuery);
          debouncedOnChange(newQuery);
        }}
        additionalActions={props.additionalActions}
      />
    </div>
  );
};

export default FilterPanel;
