import React, { type ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { routeNames } from '../../modules/flows/constants';
import string from '../../utils/string';
import CreatedDate from './CreatedDate';
import ModalIcon from './ModalIcon';
import Link from './RouterLink';
import Truncated from './Truncated';

const UsedInFlowsModal = (props: {
  component: Map<string, any>;
  config: Map<string, any>;
  flows: Map<string, any>;
  placeholder?: ReactNode;
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const inFlows = props.flows.filter((flow) => {
    return flow.getIn(['configuration', 'tasks'], List()).some((task: Map<string, any>) => {
      return (
        task.getIn(['task', 'componentId']) === props.component.get('id') &&
        task.getIn(['task', 'configId']) === props.config.get('id')
      );
    });
  });

  if (inFlows.isEmpty()) {
    return (
      <span className="text-muted no-wrap">
        {props.placeholder ?? (
          <>
            <FontAwesomeIcon icon="bars-staggered" className="f-16 btn-icon" />0 flows
          </>
        )}
      </span>
    );
  }

  return (
    <>
      <Button
        bsStyle="link"
        className="btn-link-inline flex-container inline-flex flex-start no-wrap"
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon="bars-staggered" className="f-16 btn-icon text-muted" />
        {inFlows.count()} {string.pluralize(inFlows.count(), 'flow')}
      </Button>
      <Modal
        className="simple-modal-with-table"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="no-border" closeButton>
          <Modal.Title>
            {props.config.get('name')} - Used in flows
            <ModalIcon icon="bars-staggered" color="blue" bold />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table table-hover">
            <div className="thead">
              <div className="tr">
                <div className="th">Name</div>
                <div className="th">Last Change</div>
              </div>
            </div>
            <div className="tbody">
              {inFlows
                .map((flow: Map<string, any>) => {
                  return (
                    <Link
                      key={flow.get('id')}
                      to={routeNames.DETAIL}
                      params={{ config: flow.get('id') }}
                      className="tr"
                    >
                      <div className="td color-main">
                        <div className="flex-container flex-start">
                          <Truncated text={flow.get('name')} />
                        </div>
                      </div>
                      <div className="td text-muted">
                        <CreatedDate createdTime={flow.getIn(['currentVersion', 'created'])} />
                      </div>
                    </Link>
                  );
                })
                .toArray()}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UsedInFlowsModal;
