import React from 'react';

import { KEBOOLA_NO_CODE_DBT_TRANSFORMATION } from '../../../constants/componentIds';
import useStores from '../../../react/hooks/useStores';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import RunComponentButton from '../../components/react/components/RunComponentButton';

const NoCodeHeaderButtons = () => {
  const { componentId, configId } = useStores(
    () => {
      return {
        componentId: RoutesStore.getCurrentRouteComponentId(),
        configId: RoutesStore.getCurrentRouteParam('configId')
      };
    },
    [],
    [ApplicationStore, RoutesStore]
  );

  if (componentId !== KEBOOLA_NO_CODE_DBT_TRANSFORMATION) return null;

  return (
    <RunComponentButton
      noTooltip
      component={KEBOOLA_NO_CODE_DBT_TRANSFORMATION}
      runParams={() => ({ config: configId })}
      label="Run Transformation"
      runLabel="Run Transformation"
      buttonIcon="circle-play"
      buttonBsStyle="success"
    >
      You are about to run the component.
    </RunComponentButton>
  );
};

export default NoCodeHeaderButtons;
