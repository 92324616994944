import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup } from 'react-bootstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import CircleIcon from '../../../react/common/CircleIcon';
import Gravatar from '../../../react/common/Gravatar';
import MarkedText from '../../../react/common/MarkedText';
import Select from '../../../react/common/Select';
import Tooltip from '../../../react/common/Tooltip';
import isEmailValid from '../../../utils/isEmailValid';
import string from '../../../utils/string';
import { addNotification, loadNotificationsForce, removeNotification } from '../actions';
import {
  CHANNEL_EMAIL,
  EVENT_JOB_FAILED,
  EVENT_JOB_PROCESSING,
  EVENT_JOB_SUCCESS,
  EVENT_JOB_WARNING
} from '../constants';
import {
  getNotificationTolerance,
  prepareNotification,
  updateNotificationTolerance
} from '../helpers';

class Notifications extends React.Component {
  state = {
    isSaving: null
  };

  render() {
    const isFlow = this.props.componentId === KEBOOLA_ORCHESTRATOR;
    const entity = isFlow ? 'flow' : 'configuration';

    return (
      <>
        <CircleIcon className="box-icon smaller" icon="check" color="green" bold />
        <div className="box">
          <div className="box-content">
            <h2 className="font-medium f-16 mt-1">Success</h2>
            <p className="text-muted">Get notified when the {entity} finishes with a success.</p>
            <FormGroup className="mb-0">{this.renderSelect(EVENT_JOB_SUCCESS)}</FormGroup>
            {this.renderDetails(EVENT_JOB_SUCCESS)}
          </div>
        </div>
        {isFlow && (
          <>
            <CircleIcon className="box-icon smaller" icon="exclamation" color="orange" bold />
            <div className="box">
              <div className="box-content">
                <h2 className="font-medium f-16 mt-1">Warnings</h2>
                <p className="text-muted">
                  Get notified when the {entity} finishes with a warning.
                </p>
                <FormGroup className="mb-0">{this.renderSelect(EVENT_JOB_WARNING)}</FormGroup>
                {this.renderDetails(EVENT_JOB_WARNING)}
              </div>
            </div>
          </>
        )}
        <CircleIcon className="box-icon smaller" icon="exclamation" color="red" bold />
        <div className="box">
          <div className="box-content">
            <h2 className="font-medium f-16 mt-1">Errors</h2>
            <p className="text-muted">Get notified when the {entity} finishes with an error.</p>
            <FormGroup className="mb-0">{this.renderSelect(EVENT_JOB_FAILED)}</FormGroup>
            {this.renderDetails(EVENT_JOB_FAILED)}
          </div>
        </div>
        <CircleIcon className="box-icon smaller" icon="spinner" color="blue" bold />
        <div className="box">
          <div className="box-content">
            <h2 className="font-medium f-16 mt-1">Processing</h2>
            <p className="text-muted">Get notified when job is processing longer than usual (%).</p>
            <FormGroup className="mb-0">{this.renderSelect(EVENT_JOB_PROCESSING)}</FormGroup>
            {this.renderDetails(EVENT_JOB_PROCESSING)}
          </div>
        </div>
      </>
    );
  }

  renderDetails(type) {
    return (
      <TransitionGroup>
        {this.getValue(type)
          .sortBy((notification) => notification.getIn(['recipient', 'address']))
          .map((notification) => {
            const email = notification.getIn(['recipient', 'address']);
            const admin = this.props.admins.get(email);

            return (
              <CSSTransition key={email} timeout={300} exit={false} classNames="fade">
                <div className="notification-row">
                  <div className="flex-container">
                    <div className="flex-container flex-start">
                      <Gravatar user={admin} className="mr-1" />
                      <div className="flex-container align-top flex-column">
                        <strong className="f-16 font-medium">
                          {admin ? admin.get('name', '') : 'Guest'}
                        </strong>
                        <span className="text-muted">{email}</span>
                      </div>
                    </div>
                    <div className="flex-container flex-end">
                      {this.renderToleranceInput(email, notification)}
                      {this.renderDeleteNotificationButton(type, notification)}
                    </div>
                  </div>
                </div>
              </CSSTransition>
            );
          })
          .toArray()}
      </TransitionGroup>
    );
  }

  renderSelect(type) {
    return (
      <Select
        allowCreate
        noResultsText="Enter valid email"
        placeholder="Choose from your colleagues or enter email"
        promptTextCreator={(label) => `Add ${string.pluralize(label?.split(',').length, 'email')}`}
        isLoading={this.state.isSaving === type}
        disabled={this.props.readOnly || this.state.isSaving === type}
        options={this.getOptions(type)}
        onChange={(email) => this.handleAddNotification(type, email)}
        isValidNewOption={(inputValue) => inputValue.split(',').every(isEmailValid)}
      />
    );
  }

  renderToleranceInput(email, notification) {
    const type = `tolerance-${email}`;

    if (notification.get('event') !== EVENT_JOB_PROCESSING) {
      return null;
    }

    return (
      <>
        <Select
          allowCreate
          clearable={false}
          className="w-175"
          menuPlacement="top"
          isLoading={this.state.isSaving === type}
          disabled={this.props.readOnly || this.state.isSaving === type}
          promptTextCreator={(label) => `Set to ${label}%`}
          isValidNewOption={(inputValue) => /^\d+$/.test(inputValue.trim())}
          options={_.range(100, 0, -10).map((value) => ({
            value: value.toString(),
            label: value.toString()
          }))}
          value={getNotificationTolerance(notification) || ''}
          onChange={(tolerance) => this.handleToleranceChange(type, notification, tolerance)}
        />
        <span className="no-wrap ml-1 mr-2">% longer</span>
      </>
    );
  }

  renderDeleteNotificationButton(type, notification) {
    return (
      <Tooltip placement="top" tooltip="Remove notification">
        <Button
          bsStyle="link"
          className="btn-link-inline text-muted-light"
          onClick={() => this.handleDeleteNotification(type, notification)}
          disabled={this.props.readOnly || this.state.isSaving === type}
        >
          <FontAwesomeIcon icon="circle-xmark" />
        </Button>
      </Tooltip>
    );
  }

  handleToleranceChange(type, notification, tolerance) {
    const updatedNotification = updateNotificationTolerance(notification, tolerance)
      .delete('id')
      .toJS();

    this.onChange(type, () => {
      return addNotification(updatedNotification).then(() => {
        return removeNotification(notification.get('id'));
      });
    });
  }

  handleAddNotification(type, email) {
    const currentEmails = this.getCurrentEmails(type);
    const newNotifications = email
      .split(',')
      .filter((email) => !!email && !currentEmails.includes(email.toLowerCase()))
      .map((email) => {
        return prepareNotification(
          this.props.componentId,
          this.props.configId,
          this.props.notifications,
          type,
          email
        );
      });

    this.onChange(type, () => {
      return Promise.map(newNotifications, addNotification, { concurrency: 3 });
    });
  }

  handleDeleteNotification(type, notification) {
    this.onChange(type, () => removeNotification(notification.get('id')));
  }

  getValue = (type) => {
    return this.props.notifications.filter((notification) => notification.get('event') === type);
  };

  getCurrentEmails = (type) => {
    return this.getValue(type).map((notification) => notification.getIn(['recipient', 'address']));
  };

  getOptions = (type) => {
    const currentEmails = this.getCurrentEmails(type);

    return this.props.allNotifications
      .filter((notification) => notification.getIn(['recipient', 'channel']) === CHANNEL_EMAIL)
      .groupBy((notification) => notification.getIn(['recipient', 'address']))
      .keySeq()
      .concat(this.props.admins.map((admin) => admin.get('email')))
      .map((email) => email.toLowerCase())
      .toSet()
      .filter((email) => !currentEmails.includes(email))
      .map((email) => {
        const admin = this.props.admins.get(email);

        return {
          value: email,
          label: (inputString) => (
            <div className="flex-container flex-start">
              <Gravatar user={admin} className="mr-1" />
              <div className="flex-container align-top flex-column">
                <strong className="font-medium">
                  <MarkedText source={admin ? admin.get('name', '') : 'Guest'} mark={inputString} />
                </strong>
                <small className="text-muted">
                  <MarkedText source={email} mark={inputString} />
                </small>
              </div>
            </div>
          ),
          name: admin ? admin.get('name', '') : 'Guest'
        };
      })
      .sortBy((option) => option.name.toLowerCase())
      .toArray();
  };

  onChange = (type, action) => {
    this.setState({ isSaving: type });
    action()
      .finally(loadNotificationsForce)
      .finally(() => this.setState({ isSaving: null }));
  };
}

Notifications.propTypes = {
  admins: PropTypes.instanceOf(Map).isRequired,
  allNotifications: PropTypes.instanceOf(List).isRequired,
  notifications: PropTypes.instanceOf(List).isRequired,
  componentId: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export default Notifications;
