import { AUTH_TYPES } from '../../../../../react/common/HiveCredentials';

const hasHiveValidCredentials = (credentials) => {
  if (!credentials.get('host') || !credentials.get('port') || !credentials.get('database')) {
    return false;
  }

  if (
    credentials.get('authType', AUTH_TYPES.PASSWORD) === AUTH_TYPES.PASSWORD &&
    (!credentials.get('user') || !credentials.get('#password'))
  ) {
    return false;
  }

  if (
    credentials.get('authType', AUTH_TYPES.PASSWORD) === AUTH_TYPES.KERBEROS &&
    (!credentials.getIn(['kerberos', 'kinitPrincipal']) ||
      !credentials.getIn(['kerberos', 'servicePrincipal']) ||
      !credentials.getIn(['kerberos', 'config']) ||
      !credentials.getIn(['kerberos', '#keytab']))
  ) {
    return false;
  }

  if (credentials.getIn(['ssh', 'enabled']) && !credentials.getIn(['ssh', 'sshHost'])) {
    return false;
  }

  return true;
};

export { hasHiveValidCredentials };
