import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import { type Map } from 'immutable';

import ApplicationActionCreators from '../../../actions/ApplicationActionCreators';
import { KEBOOLA_DATA_APPS } from '../../../constants/componentIds';
import Confirm from '../../../react/common/Confirm';
import ConfirmMenuItem from '../../../react/common/ConfirmMenuItem';
import Loader from '../../../react/common/Loader';
import RoutesStore from '../../../stores/RoutesStore';
import InstalledComponentsActionCreators from '../../components/InstalledComponentsActionCreators';
import SandboxesActions from '../../sandboxes/Actions';
import { routeNames } from '../constants';

const DeleteDataApp = (props: {
  config: Map<string, any>;
  sandbox: Map<string, any>;
  mode: 'sidebar' | 'menuitem';
  isDeleting: boolean;
  readOnly: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}) => {
  const [isConfigDeleting, setConfigDeleting] = React.useState(false);
  const isDeleting = props.isDeleting || isConfigDeleting;

  const handleConfirm = () => {
    if (props.mode === 'sidebar') {
      RoutesStore.getRouter().transitionTo(routeNames.ROOT);
    }

    return Promise.resolve().then(() => {
      if (props.sandbox.isEmpty()) {
        setConfigDeleting(true);
        return InstalledComponentsActionCreators.deleteConfiguration(
          KEBOOLA_DATA_APPS,
          props.config.get('id'),
          { transition: false, notification: false }
        ).then(() => {
          ApplicationActionCreators.sendNotification({
            type: 'info',
            message: () => (
              <>
                Data app <b>{props.config.get('name')}</b> has been deleted.
              </>
            )
          });
          setConfigDeleting(false);
        });
      }

      return SandboxesActions.deleteSandbox(
        props.sandbox.get('id'),
        props.config.get('id'),
        KEBOOLA_DATA_APPS
      );
    });
  };

  const renderBody = () => {
    return (
      <p>
        Are you sure you want to delete <strong>{props.config.get('name')}</strong> the data app?
      </p>
    );
  };

  if (props.mode === 'sidebar') {
    return (
      <Confirm
        icon="trash"
        buttonType="danger"
        title="Delete Data App"
        buttonLabel="Delete Data App"
        text={renderBody()}
        onConfirm={handleConfirm}
        isDisabled={isDeleting || props.readOnly}
        childrenRootElement="a"
      >
        {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
        Delete Data App
      </Confirm>
    );
  }

  return (
    <ConfirmMenuItem
      icon="trash"
      title="Delete Data App"
      buttonLabel="Delete Data App"
      text={renderBody()}
      onConfirm={handleConfirm}
      isDisabled={isDeleting || props.readOnly}
      onKeyDown={props.onKeyDown}
      closeAfterResolve
    >
      {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
      Delete Data App
    </ConfirmMenuItem>
  );
};

export default DeleteDataApp;
