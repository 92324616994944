import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import CreatedDate from '../../../react/common/CreatedDate';
import CredentialsBox from '../../../react/common/CredentialsBox';
import ChangeOrganizationButton from './ChangeOrganizationButton';
import UpdateProjectButton from './UpdateProjectButton';

class ProjectDetails extends React.Component {
  render() {
    let rows = Map({
      Name: {
        noCopy: true,
        text: this.props.project.get('name')
      },
      Organization: {
        noCopy: true,
        component: (
          <>
            {this.props.hasAccessToProjectOrganization ? (
              <a href={`/admin/organizations/${this.props.project.getIn(['organization', 'id'])}`}>
                {this.props.project.getIn(['organization', 'name'])}
              </a>
            ) : (
              this.props.project.getIn(['organization', 'name'])
            )}
            {this.props.canChangeProjectOrganization && (
              <ChangeOrganizationButton
                project={this.props.project}
                organizations={this.props.organizations}
                onSubmit={this.props.handleChangeOrganization}
              />
            )}
          </>
        )
      },
      Created: {
        noCopy: true,
        component: <CreatedDate createdTime={this.props.project.get('created')} />
      },
      Type: {
        noCopy: true,
        text: this.props.projectTemplates
          .find((template) => template.get('id') === this.props.project.get('type'), null, Map())
          .get('name', this.props.project.get('type'))
      },
      Expires: {
        noCopy: true,
        component: (
          <CreatedDate absolute createdTime={this.props.project.get('expires')} fallback="Never" />
        )
      },
      'Monthly Fee': {
        noCopy: true,
        text: this.props.project.get('billedMonthlyPrice') || 'N/A'
      },
      Region: {
        noCopy: true,
        text: this.props.project.get('region')
      }
    });

    if (this.props.user.get('isSuperAdmin', false)) {
      rows = rows.set('Features', {
        noCopy: true,
        component: (
          <div style={{ maxWidth: '300px' }}>
            {this.props.project
              .get('features', List())
              .sortBy((feature) => feature.toLowerCase())
              .map((feature) => (
                <code key={feature} style={{ margin: '0 0 4px 6px', display: 'inline-block' }}>
                  {feature}
                </code>
              ))
              .toArray()}
          </div>
        )
      });
    }

    return (
      <>
        <div className="well pp-6">
          <h3 className="tw-mt-0 tw-mb-6">
            Settings
            {(this.props.hasAccessToProjectOrganization || this.props.hasAdminRoleInProject) && (
              <UpdateProjectButton
                project={this.props.project}
                projectTemplates={this.props.projectTemplates}
                isSuperAdmin={this.props.user.get('isSuperAdmin', false)}
                onSubmit={this.props.handleUpdateProject}
              />
            )}
          </h3>
          <CredentialsBox rows={rows} noBorder />
        </div>
      </>
    );
  }
}

ProjectDetails.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
  organizations: PropTypes.instanceOf(List).isRequired,
  projectTemplates: PropTypes.instanceOf(List).isRequired,
  handleChangeOrganization: PropTypes.func.isRequired,
  handleUpdateProject: PropTypes.func.isRequired,
  hasAdminRoleInProject: PropTypes.bool.isRequired,
  hasAccessToProjectOrganization: PropTypes.bool.isRequired,
  canChangeProjectOrganization: PropTypes.bool.isRequired
};

export default ProjectDetails;
