import { List, Map } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA } from '../../constants/componentIds';
import string from '../../utils/string';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '../configurations/ConfigurationRowsStore';
import { isPhaseJob, isRowJob } from './helpers';

const getComponentByJob = (job) => {
  let componentId = job.get('component');
  const configId = job.get('config');

  if (componentId === KEBOOLA_EX_SAMPLE_DATA) {
    const configData = InstalledComponentsStore.getConfigData(KEBOOLA_EX_SAMPLE_DATA, configId);
    componentId = configData.getIn(['parameters', 'componentId'], KEBOOLA_EX_SAMPLE_DATA);
  }

  return ComponentsStore.getComponent(componentId) || ComponentsStore.unknownComponent(componentId);
};

const getConfigurationName = (job) => {
  const config = InstalledComponentsStore.getConfig(job.get('component'), job.get('config'));

  if (config?.get('name') && isPhaseJob(job)) {
    return config.get('name') + ' - ' + getOrchestratorPhaseName(job);
  }

  if (config?.get('name') && isRowJob(job)) {
    const rowsIds = job.get('configRowIds', List());
    const rows = rowsIds
      .map((rowId) => {
        return config
          .get('rows', List())
          .find((row) => row.get('id') === rowId, null, Map())
          .get('name', false);
      })
      .filter(Boolean);

    return `${config.get('name')} - ${
      rowsIds.count() === rows.count()
        ? rows.join(', ')
        : `${string.pluralize(rows.count(), 'row')}`
    }`;
  }

  if (config?.get('name')) {
    return config.get('name');
  }

  return job.get('config') || '';
};

const getOrchestratorPhaseName = (job) => {
  const phase = InstalledComponentsStore.getConfigData(job.get('component'), job.get('config'))
    .get('phases', List())
    .find((phase) => {
      return phase.get('id') === job.getIn(['configData', 'phaseId']);
    });

  return phase ? phase.get('name') : `Phase ${job.getIn(['configData', 'phaseId'], '')}`;
};

const getConfigurationRowName = (job) => {
  const configRowId = job.get('configRowIds', List()).first();

  return ConfigurationRowsStore.get(job.get('component'), job.get('config'), configRowId).get(
    'name',
    configRowId
  );
};

export {
  getComponentByJob,
  getConfigurationName,
  getOrchestratorPhaseName,
  getConfigurationRowName
};
