export const ActionTypes = {
  JOBS_LOAD: 'JOBS_LOAD',
  JOBS_LOAD_SUCCESS: 'JOBS_LOAD_SUCCESS',
  JOBS_LOAD_ERROR: 'JOBS_LOAD_ERROR',
  JOBS_SET_QUERY: 'JOBS_SET_QUERY',
  JOBS_SEARCH: 'JOBS_SEARCH',

  JOB_LOAD: 'JOB_LOAD',
  JOB_LOAD_SUCCESS: 'JOB_LOAD_SUCCESS',
  JOB_LOAD_ERROR: 'JOB_LOAD_ERROR',

  JOB_TERMINATE_START: 'JOB_TERMINATE_START',
  JOB_TERMINATE_ERROR: 'JOB_TERMINATE_ERROR',
  JOB_TERMINATE_SUCCESS: 'JOB_TERMINATE_SUCCESS',

  JOBS_LATEST_LOAD_START: 'JOBS_LATEST_LOAD_START',
  JOBS_LATEST_LOAD_SUCCESS: 'JOBS_LATEST_LOAD_SUCCESS',
  JOBS_LATEST_LOAD_ERROR: 'JOBS_LATEST_LOAD_ERROR',

  JOB_ERROR_NOTE_UPDATED: 'JOB_ERROR_NOTE_UPDATED'
};

export const JOBS_LIMIT_FOR_PREVIOUS_JOBS = 50;
