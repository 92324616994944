import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'react-bootstrap';

const SidebarLabel = ({ version }) => {
  if (!version) {
    return 'Change Backend';
  }
  return (
    <>
      Backend Version
      <Label className="label-backend icon-addon-left">{version}</Label>
    </>
  );
};

SidebarLabel.propTypes = {
  version: PropTypes.string
};

export default SidebarLabel;
