import React, { useEffect, useState } from 'react';
import Promise from 'bluebird';
import { fromJS, type List, type Map } from 'immutable';

import VersionsDiffModal from '../../../react/common/VersionsDiffModal';
import InstalledApi from '../../components/InstalledComponentsApi';

const defaultState = {
  branchConfig: null,
  productionConfig: null
};

type Props = {
  show: boolean;
  admins: Map<string, any>;
  detail: Map<string, any>;
  updatedMetadata: Map<string, any>;
  component: Map<string, any>;
  sharedCodes: List<any>;
  onClose: () => void;
  onDiffLoaded: () => void;
};

type State = {
  branchConfig: Map<string, any> | null;
  productionConfig: Map<string, any> | null;
};

const DevBranchDiffModal = ({
  show,
  admins,
  detail,
  updatedMetadata,
  component,
  sharedCodes,
  onClose,
  onDiffLoaded
}: Props) => {
  const [state, setState] = useState<State>(defaultState);

  const componentId = detail.get('componentId');
  const configId = detail.get('configId');

  useEffect(() => {
    if (!show || !componentId || !configId) {
      setState(defaultState);
      return;
    }

    if (!!state.branchConfig && !!state.productionConfig) {
      return;
    }

    Promise.props({
      branchConfig: InstalledApi.getComponentConfiguration(componentId, configId).catch(
        (error: any) => {
          if (error.response.status === 404) {
            return {};
          }
          throw error;
        }
      ),
      productionConfig: InstalledApi.getProductionComponentConfiguration(
        componentId,
        configId
      ).catch((error: any) => {
        if (error.response.status === 404) {
          return {};
        }
        throw error;
      })
    }).then(({ branchConfig, productionConfig }) => {
      setState({
        branchConfig: fromJS(branchConfig),
        productionConfig: fromJS(productionConfig)
      });
      onDiffLoaded();
    });
  }, [show, onDiffLoaded, componentId, configId, state.branchConfig, state.productionConfig]);

  const renderNoChangesPlaceholder = () => {
    if (updatedMetadata.hasIn([detail.get('componentId'), detail.get('configId')])) {
      return <p>Only the configuration metadata has been updated.</p>;
    }

    return (
      <p>
        The configuration was changed in this branch but it matches the production one. Merging this
        configuration will not create new version of production configuration.
      </p>
    );
  };

  const prepareHelpText = () => {
    if (state.productionConfig?.isEmpty()) {
      return (
        <>
          The configuration was created in current Development branch — and therefore has no
          reference in production.
        </>
      );
    }

    if (state.branchConfig?.isEmpty() && !state.productionConfig?.isEmpty()) {
      return (
        <p>
          The configuration was deleted in current Development branch and later updated in
          production.
        </p>
      );
    }

    return null;
  };

  if (!state.branchConfig || !state.productionConfig) {
    return null;
  }

  return (
    <VersionsDiffModal
      isBranchDiff
      show={show}
      component={component}
      admins={admins}
      referentialVersion={state.branchConfig}
      compareVersion={state.productionConfig}
      sharedCodes={sharedCodes}
      noChangesPlaceholder={renderNoChangesPlaceholder()}
      helpText={prepareHelpText()}
      title={detail.get('name')}
      onClose={onClose}
    />
  );
};

export default DevBranchDiffModal;
