import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal, Well } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, List } from 'immutable';

import Loader from '../../../react/common/Loader';
import ModalIcon from '../../../react/common/ModalIcon';
import SyncActionError from '../../../utils/errors/SyncActionError';
import { searchWorkspace } from '../action';

const INITIAL_STATE = {
  showModal: false,
  isSearching: false,
  error: null,
  warning: null,
  worksheets: List()
};

class SearchWorksheets extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.startSearch = this.startSearch.bind(this);
  }

  render() {
    return (
      <>
        <Button
          bsStyle="success"
          disabled={this.props.disabled || !this.props.driveId || !this.props.fileId}
          onClick={this.openModal}
        >
          Load Worksheets
        </Button>
        <Modal show={this.state.showModal} onHide={this.closeModal} onEnter={this.startSearch}>
          <Modal.Header closeButton>
            <Modal.Title>Load Worksheets</Modal.Title>
            <ModalIcon icon="file-spreadsheet" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            {this.state.isSearching && (
              <p>
                <Loader className="icon-addon-right" />
                Loading worksheets...
              </p>
            )}
            {this.state.error && (
              <Alert bsStyle="danger">
                <p>{this.state.error.message}</p>
                {this.state.error.exceptionId && (
                  <small>Exception ID: {this.state.error.exceptionId}</small>
                )}
              </Alert>
            )}
            {this.state.warning && <Alert bsStyle="warning">{this.state.warning}</Alert>}
            {this.state.worksheets.count() > 0 && (
              <Well className="m-0 overflow-auto">
                <p className="text-muted f-14">Select worksheet</p>
                {this.state.worksheets
                  .sortBy((worksheet) => worksheet.get('name').toLowerCase())
                  .map((worksheet) => (
                    <Button
                      block
                      bsStyle="link"
                      className="btn-link-inline"
                      key={worksheet.get('worksheetId')}
                      onClick={() => {
                        this.props.onSelect({
                          name: worksheet.get('name'),
                          id: worksheet.get('worksheetId')
                        });
                        this.closeModal();
                      }}
                    >
                      <FontAwesomeIcon
                        icon="file-spreadsheet"
                        className="icon-addon-right text-muted"
                      />
                      {worksheet.get('name')}
                    </Button>
                  ))
                  .toArray()}
              </Well>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }

  startSearch() {
    this.setState({ isSearching: true, warning: null, error: null });
    return searchWorkspace(this.props.configId, this.props.driveId, this.props.fileId)
      .then((response) => {
        const worksheets = fromJS(response.worksheets);

        if (worksheets.count() > 0) {
          this.setState({ worksheets });
        } else {
          this.setState({ warning: 'No worksheets was found.' });
        }
      })
      .catch(SyncActionError, (error) => {
        this.setState({ error });
      })
      .finally(() => {
        this.setState({ isSearching: false });
      });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState(INITIAL_STATE);
  }
}

SearchWorksheets.propTypes = {
  disabled: PropTypes.bool.isRequired,
  configId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  driveId: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired
};

export default SearchWorksheets;
