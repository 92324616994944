import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ComponentIcon from '../../../../react/common/ComponentIcon';
import ConfirmButtons from '../../../../react/common/ConfirmButtons';
import ExternalLink from '../../../../react/common/ExternalLink';
import { isValidName } from '../../../../react/common/InputValidation';
import ModalIcon from '../../../../react/common/ModalIcon';
import PasswordControl from '../../../../react/common/PasswordControl';
import SyncActionError from '../../../../utils/errors/SyncActionError';
import { nameWarning } from '../../../storage/constants';

const INITIAL_STATE = {
  connectionName: '',
  credentialsId: '',
  token: '',
  host: '',
  isLoading: false,
  warning: null,
  error: null
};

const LOOKER_HELP_LINK =
  'https://docs.looker.com/reference/api-and-integration/api-auth#authentication_with_a_sdk';

class LookerCredentialsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.onHide = this.onHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <Form onSubmit={this.handleSubmit} className="form-horizontal">
          <Modal.Header closeButton>
            <Modal.Title>Connect your Looker account</Modal.Title>
            <ModalIcon icon="database" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <ExternalLink
              href={LOOKER_HELP_LINK}
              className="color-main flex-container no-underline"
            >
              <ComponentIcon
                size="24"
                component={this.props.lookerComponent}
                className="icon-addon-right"
              />
              See how to get your Looker API key
              <FontAwesomeIcon
                icon="chevron-right"
                fixedWidth
                className="text-muted-light ml-auto"
              />
            </ExternalLink>
            <hr className="inside-modal-body" />
            {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
            <FormGroup validationState={this.state.warning ? 'error' : null}>
              <Col sm={3} componentClass={ControlLabel}>
                Connection Name
              </Col>
              <Col sm={9}>
                <FormControl
                  autoFocus
                  type="text"
                  value={this.state.connectionName}
                  onChange={({ target }) =>
                    this.setState({
                      connectionName: target.value,
                      warning: isValidName(target.value) ? null : nameWarning
                    })
                  }
                />
                {this.state.warning && (
                  <HelpBlock className="text-danger">{this.state.warning}</HelpBlock>
                )}
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3} componentClass={ControlLabel}>
                Client ID
              </Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  value={this.state.credentialsId}
                  onChange={(e) => this.setState({ credentialsId: e.target.value })}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3} componentClass={ControlLabel}>
                Client Secret
              </Col>
              <Col sm={9}>
                <PasswordControl
                  value={this.state.token}
                  onChange={(e) => this.setState({ token: e.target.value })}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3} componentClass={ControlLabel}>
                Host
              </Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  value={this.state.host}
                  onChange={(e) => this.setState({ host: e.target.value })}
                />
                <HelpBlock>
                  E.g. <code>https://MY_COMPANY.api.looker.com/api/3.1</code>
                </HelpBlock>
              </Col>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={
                this.state.isLoading ? 'Testing connection...' : 'Test connection and save'
              }
              saveButtonType="submit"
              isSaving={this.state.isLoading}
              isDisabled={this.isSubmitDisabled()}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  isSubmitDisabled() {
    return (
      !this.state.credentialsId ||
      !this.state.token ||
      !this.state.host ||
      this.state.isLoading ||
      this.state.warning
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props
      .registerToLookerFn(this.state.credentialsId, this.state.token, this.state.host)
      .then(() => {
        return this.props.saveCredentialsFn(
          this.props.configId,
          this.state.credentialsId,
          this.state.token,
          this.state.host,
          this.state.connectionName
        );
      })
      .then(this.onHide)
      .catch(SyncActionError, (error) => {
        this.setState({
          error: error.message
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  onHide() {
    this.setState(INITIAL_STATE);
    this.props.onHide();
  }
}

LookerCredentialsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  registerToLookerFn: PropTypes.func.isRequired,
  saveCredentialsFn: PropTypes.func.isRequired,
  lookerComponent: PropTypes.object.isRequired,
  configId: PropTypes.string.isRequired
};

export default LookerCredentialsModal;
