import Promise from 'bluebird';

import { uiModes } from '../components/Constants';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import VersionActionCreators from '../components/VersionsActionCreators';
import oldJobsActionCreators from '../jobs/ActionCreators';
import { deleteCredentialsAndConfigAuth } from '../oauth-v2/OauthUtils';
import jobsActionCreators from '../queue/actions';

const enableSimplifiedUi = (componentId, configId, configData) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    componentId,
    configId,
    {
      configuration: JSON.stringify(configData.setIn(['runtime', 'uiMode'], uiModes.SIMPLE).toJS())
    },
    'Enable simplified mode'
  );
};

const disableSimplifiedUi = (componentId, configId, configData) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    componentId,
    configId,
    {
      configuration: JSON.stringify(configData.setIn(['runtime', 'uiMode'], uiModes.DEFAULT).toJS())
    },
    'Disable simplified mode'
  );
};

const resetAuthorization = (componentId, configId) => {
  return deleteCredentialsAndConfigAuth(componentId, configId);
};

const runComponent = (componentId, configId, hasNewQueue) => {
  return InstalledComponentsActionCreators.runComponent({
    method: 'run',
    component: componentId,
    data: { config: configId }
  })
    .delay(2000)
    .then(() => {
      return Promise.all([
        hasNewQueue
          ? jobsActionCreators.loadComponentConfigurationLatestJobs(componentId, configId)
          : oldJobsActionCreators.loadComponentConfigurationLatestJobs(componentId, configId),
        VersionActionCreators.loadVersionsForce(componentId, configId),
        InstalledComponentsActionCreators.loadComponentConfigDataForce(componentId, configId)
      ]);
    });
};

export { enableSimplifiedUi, disableSimplifiedUi, resetAuthorization, runComponent };
