import React from 'react';
import { Button, ControlLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '../../../../constants/componentIds';
import { componentTypes } from '../../../../constants/componentTypes';
import CollapsibleBox from '../../../../react/common/CollapsibleBox';
import ConfigurationInfoPanel from '../../../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../../../react/common/ConfigurationTabs';
import ExternalLink from '../../../../react/common/ExternalLink';
import SaveButtons from '../../../../react/common/SaveButtons';
import Select from '../../../../react/common/Select';
import Sidebar from '../../../../react/layout/Sidebar';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import getDefaultBucket from '../../../../utils/getDefaultBucket';
import ComponentDescription from '../../../components/react/components/ComponentDescription';
import EmptyState from '../../../components/react/components/ComponentEmptyState';
import ComponentsStore from '../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';
import storageTablesStore from '../../../components/stores/StorageTablesStore';
import { deleteCredentialsAndConfigAuth } from '../../../oauth-v2/OauthUtils';
import AuthorizationRow from '../../../oauth-v2/react/AuthorizationRow';
import { prepareTablesMetadataMap } from '../../../storage/helpers';
import actionsProvisioning from '../../actionsProvisioning';
import storeProvisioning, { DEFAULT_API_VERSION, storeMixins } from '../../storeProvisioning';
import accountDescriptionTemplate from '../../templates/accountDescription';
import AccountLink from './AccountLink';
import AccountsManagerModal from './AccountsManagerModal';
import AddQueryModal from './AddQueryModal';
import QueriesTable from './QueriesTable';

// https://developers.facebook.com/docs/graph-api/changelog
const FB_API_VERSIONS = ['v18.0', 'v17.0', 'v16.0'];

const Index = function (COMPONENT_ID) {
  const getAccountDesc = accountDescriptionTemplate(COMPONENT_ID);
  return createReactClass({
    mixins: [
      createStoreMixin(...storeMixins, ApplicationStore, ComponentsStore, storageTablesStore)
    ],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const store = storeProvisioning(COMPONENT_ID, configId);
      const actions = actionsProvisioning(COMPONENT_ID, configId);
      const component = ComponentsStore.getComponent(COMPONENT_ID);

      return {
        allTables: storageTablesStore.getAll(),
        store: store,
        actions: actions,
        component: component,
        configId: configId,
        oauthCredentials: store.oauthCredentials,
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        localState: store.getLocalState(),
        hasFlows: ApplicationStore.hasFlows(),
        readOnly: ApplicationStore.isReadOnly(),
        admins: ApplicationStore.getAdmins()
      };
    },

    render() {
      return (
        <>
          <ConfigurationTabs componentId={COMPONENT_ID} configId={this.state.configId} />
          <ConfigurationInfoPanel
            component={this.state.component}
            config={this.state.store.config}
            hasFlows={this.state.hasFlows}
            flows={this.state.flows}
            tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
            metadata={this.state.componentsMetadata}
          />
          <div className="row">
            {this.renderAccountsManagerModal()}
            {this.renderNewQueryModal()}
            <div className="col-sm-9">
              <ComponentDescription
                componentId={COMPONENT_ID}
                configId={this.state.configId}
                placeholderEntity={componentTypes.EXTRACTOR}
              />
              {this.renderAuthorizedInfo()}
              {this.isAuthorized() && this.renderApiVersionEdit()}
              {(this.isAuthorized() || this.state.store.hasAccounts) && (
                <div className="box">
                  <div className="box-content">{this.renderAccountsInfo()}</div>
                </div>
              )}
              {this.renderQueries()}
            </div>
            <div className="col-sm-3">
              <Sidebar
                componentId={COMPONENT_ID}
                configId={this.state.configId}
                run={{
                  disabled: !this.isAuthorized() ? 'No Facebook account authorized' : '',
                  text: 'You are about to run an extraction.'
                }}
              />
            </div>
          </div>
        </>
      );
    },

    renderQueries() {
      if (!this.isAuthorized() || !this.state.store.hasAccounts) {
        return null;
      }

      if (!this.state.store.hasQueries) {
        return this.renderEmptyQueries();
      }

      return (
        <div className="box">
          <QueriesTable
            readOnly={this.state.readOnly}
            componentId={COMPONENT_ID}
            bucketId={getDefaultBucket('in', COMPONENT_ID, this.state.configId)}
            allTables={this.state.allTables}
            queries={this.state.store.queries}
            configId={this.state.configId}
            accounts={this.state.store.accounts}
            deleteQueryFn={this.state.actions.deleteQuery}
            isPendingFn={this.state.store.isPending}
            toggleQueryEnabledFn={this.state.actions.toggleQueryEnabledFn}
            getRunSingleQueryDataFn={this.state.store.getRunSingleQueryData}
            accountDescFn={getAccountDesc}
            addQueryButton={this.renderAddQueryLink()}
          />
        </div>
      );
    },

    handleApiVersionEdit(version) {
      this.state.actions.updateLocalState('version', version);
    },

    renderApiVersionEdit() {
      const version = this.state.store.getLocalState().get('version', this.state.store.version);
      const isChanged = version !== this.state.store.version;

      return (
        <CollapsibleBox
          title="Facebook API Version"
          collapsePrefix={
            <span className={'tw-font-medium tw-text-xs tw-mr-2 tw-text-neutral-400'}>
              {this.state.store.version}
            </span>
          }
          additionalActions={() => {
            if (this.state.readOnly) {
              return null;
            }

            return (
              <SaveButtons
                isChanged={!!isChanged}
                onReset={() =>
                  this.state.actions.updateLocalState('version', this.state.store.version)
                }
                onSave={() => this.state.actions.saveApiVersion(version)}
                isSaving={!!this.state.store.isPending('version')}
              />
            );
          }}
        >
          <ControlLabel>API Version</ControlLabel>
          <Select
            value={version}
            options={FB_API_VERSIONS.map((v) => ({ value: v, label: v }))}
            allowCreate
            promptTextCreator={(input) => `Add version "${input}"`}
            isValidNewOption={(input) => input.match(/^v\d+(?:.\d+)?$/)}
            clearable={false}
            isDisabled={this.state.readOnly}
            onChange={this.handleApiVersionEdit}
          />
          <span className="tw-block tw-text-xs tw-mt-3 tw-text-neutral-400">
            Facebook has its own specific platform{' '}
            <ExternalLink href="https://developers.facebook.com/docs/apps/versions">
              versioning
            </ExternalLink>
            . If you change the API version, some API calls specified in queries may not work,
            producing an error, or no data or data with different columns might be retrieved. To
            review the API changes, see{' '}
            <ExternalLink href="https://developers.facebook.com/docs/apps/changelog">
              changelog
            </ExternalLink>
            . The most recent API version is {DEFAULT_API_VERSION}.
          </span>
        </CollapsibleBox>
      );
    },

    isAuthorized() {
      return this.state.store.isAuthorized();
    },

    renderAccountsInfo() {
      const { accounts } = this.state.store;
      const showTreshold = 10;
      const showMorecount = accounts.count() - showTreshold;

      if (this.state.store.hasAccounts) {
        return (
          <>
            <span>Selected {getAccountDesc('Facebook Pages')}</span>
            {!this.state.readOnly && (
              <a className="btn btn-link btn-sm" onClick={this.showAccountsManagerModal}>
                Modify
              </a>
            )}
            <div>
              {accounts
                .take(showTreshold)
                .map((a, index) => {
                  return (
                    <p key={index}>
                      <AccountLink account={a} componentId={COMPONENT_ID} />{' '}
                    </p>
                  );
                })
                .toArray()}
              {!this.state.readOnly && showMorecount > 0 && (
                <a className="btn btn-link" onClick={this.showAccountsManagerModal}>
                  {' '}
                  and {showMorecount} more
                </a>
              )}
            </div>
          </>
        );
      }

      return (
        <EmptyState>
          <p>No {getAccountDesc('Facebook Pages')} Selected</p>
          {!this.state.readOnly && (
            <button className="btn btn-success" onClick={this.showAccountsManagerModal}>
              Select {getAccountDesc('Pages')}
            </button>
          )}
        </EmptyState>
      );
    },

    showAccountsManagerModal() {
      this.state.actions.loadAccounts();
      this.state.actions.updateLocalState(
        ['AccountsManagerModal', 'selected'],
        this.state.store.accounts
      );
      this.state.actions.updateLocalState('ShowAccountsManagerModal', true);
    },

    renderAccountsManagerModal() {
      return (
        <AccountsManagerModal
          show={this.state.localState.get('ShowAccountsManagerModal', false)}
          onHideFn={() => {
            this.state.actions.updateLocalState(['AccountsManagerModal'], Map());
            this.state.actions.updateLocalState(['ShowAccountsManagerModal'], false);
          }}
          accounts={this.state.store.accounts}
          authorizedDescription={this.state.oauthCredentials.get('authorizedFor')}
          syncAccounts={this.state.store.syncAccounts}
          {...this.state.actions.prepareLocalState('AccountsManagerModal')}
          onSaveAccounts={this.state.actions.saveAccounts}
          isSaving={!!this.state.store.isSavingAccounts()}
          accountDescFn={getAccountDesc}
        />
      );
    },

    renderAuthorizedInfo() {
      return (
        <AuthorizationRow
          readOnly={this.state.readOnly}
          configId={this.state.configId}
          componentId={COMPONENT_ID}
          credentials={this.state.oauthCredentials}
          onResetCredentials={this.deleteCredentials}
          admins={this.state.admins}
        />
      );
    },

    renderAddQueryLink() {
      if (this.state.readOnly) {
        return null;
      }

      return (
        <Button
          bsStyle="success"
          onClick={() => {
            this.state.actions.updateLocalState('showQueryModal', true);
          }}
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          New Query
        </Button>
      );
    },

    renderNewQueryModal() {
      return (
        <AddQueryModal
          component={this.state.component}
          configId={this.state.configId}
          show={this.state.localState.get('showQueryModal', false)}
          onHide={() => this.state.actions.updateLocalState('showQueryModal', false)}
        />
      );
    },

    renderEmptyQueries() {
      return (
        <div className="box">
          <div className="box-content">
            <EmptyState>
              <p>No Queries Configured</p>
              {this.renderAddQueryLink()}
            </EmptyState>
          </div>
        </div>
      );
    },

    deleteCredentials() {
      return deleteCredentialsAndConfigAuth(COMPONENT_ID, this.state.configId);
    }
  });
};

export default Index;
