import React from 'react';
import classnames from 'classnames';
import { Map } from 'immutable';
import { truncate } from 'underscore.string';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import CircleIcon from '../../../react/common/CircleIcon';
import CreatedDate from '../../../react/common/CreatedDate';
import Truncated from '../../../react/common/Truncated';
import ComponentConfigurationLink from '../../components/react/components/ComponentConfigurationLink';
import { getFlowIdFromScheduleJob } from '../helpers';

const ScheduledJob = (props: {
  time?: string;
  job?: Map<string, any>;
  allConfigurations?: Map<string, any>;
  timeSuffix?: React.ReactNode;
  noIcon?: boolean;
  className?: string;
  iconLeft?: boolean;
}) => {
  const flowId = getFlowIdFromScheduleJob(props.job);
  const flow =
    props.allConfigurations?.getIn([KEBOOLA_ORCHESTRATOR, 'configurations', flowId]) || Map();

  return (
    <div
      className={classnames(
        'flex-container inline-flex flex-start',
        { 'flex-reverse': props.iconLeft },
        props.className
      )}
    >
      <div>
        {props.time && (
          <>
            <CreatedDate createdTime={props.time} />
            {props.timeSuffix}
            <br />
          </>
        )}

        {flowId ? (
          <div className={classnames({ 'text-muted': props.time })}>
            Scheduled{' '}
            <ComponentConfigurationLink
              hasFlows
              className="text-muted"
              componentId={KEBOOLA_ORCHESTRATOR}
              configId={flowId}
              isDeleted={flow.isEmpty()}
            >
              {truncate(flow.get('name', flowId), 50)}
            </ComponentConfigurationLink>{' '}
            run
          </div>
        ) : (
          <Truncated className={classnames({ 'text-muted': props.time })} text="Scheduled run" />
        )}
      </div>
      {!props.noIcon && (
        <span
          className={classnames(
            'line-height-1',
            props.iconLeft ? 'icon-addon-right' : 'icon-addon-left'
          )}
        >
          <CircleIcon
            bold
            icon="clock"
            color="blue"
            className={classnames('job-icon', { bigger: !props.iconLeft })}
          />
        </span>
      )}
    </div>
  );
};

export default ScheduledJob;
