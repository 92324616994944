import React from 'react';
import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import getDefaultBucket from '../../../../utils/getDefaultBucket';
import StorageApiTableLinkEx from '../../../components/react/components/StorageApiTableLinkEx';
import columnTypes from '../../../configurations/utils/columnTypeConstants';

const TableCell = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    type: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired,
    configData: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    componentId: PropTypes.string.isRequired,
    configurationId: PropTypes.string.isRequired,
    valueFn: PropTypes.func,
    tablePrefix: PropTypes.string
  },

  getDefaultProps() {
    return {
      valueFn: (rowData) => JSON.stringify(rowData.get('configuration', Map())),
      tablePrefix: ''
    };
  },

  render() {
    switch (this.props.type) {
      case columnTypes.TABLE_LINK_DEFAULT_BUCKET: {
        const bucketId =
          this.props.row.getIn(['configuration', 'storage', 'output', 'default_bucket']) ||
          this.props.configData.getIn(['storage', 'output', 'default_bucket']) ||
          getDefaultBucket(
            this.props.component.getIn(['data', 'default_bucket_stage']),
            this.props.component.get('id'),
            this.props.configurationId
          );
        const table = this.props.valueFn(this.props.row.get('configuration', Map()));
        const tableName = `${this.props.tablePrefix}${table}`;

        if (!table) {
          return (
            <>
              Unable to determine table name.
              <br />
              Please check configuration.
            </>
          );
        }

        return <StorageApiTableLinkEx tableId={bucketId + '.' + tableName} showOnlyDisplayName />;
      }

      case columnTypes.TABLE_LINK: {
        const tableId = this.props.valueFn(this.props.row.get('configuration', Map()));

        if (!tableId) {
          return (
            <>
              Unable to determine table name.
              <br />
              Please check configuration.
            </>
          );
        }

        return <StorageApiTableLinkEx tableId={tableId} showOnlyDisplayName />;
      }

      default:
        return <span>{this.props.valueFn(this.props.row.get('configuration', Map()))}</span>;
    }
  }
});

export default TableCell;
