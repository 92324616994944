import React from 'react';
import { Image } from 'react-bootstrap';

import FakeBarGraph from '../../../../../images/fake-bar-graph.png';
import { fileUrl } from '../../../../utils/fileHelpers';
import { MINIMUM_JOBS_FOR_GRAPH } from '../pages/job-detail/JobDetail';

class EmptyJobsGraph extends React.Component {
  render() {
    return (
      <div className="empty-jobs-graph">
        <div className="empty-jobs-graph-text">
          <strong className="mt-1">Why is there no bar chart visible?</strong>
          <p>
            This graph is only available for non-partial jobs with at least {MINIMUM_JOBS_FOR_GRAPH}{' '}
            log entries, as we are unable to produce a useful visualisation without sufficient data.
          </p>
        </div>
        <Image responsive src={fileUrl(FakeBarGraph)} className="empty-jobs-graph-image" />
      </div>
    );
  }
}

export default EmptyJobsGraph;
