import type { Map, Set } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA } from '../../../constants/componentIds';
import keyCodes from '../../../constants/keyCodes';
import { getFolderFromMetadata } from '../../../modules/components/helpers';

const getRealComponentId = (
  configuration: Map<string, any>,
  component: Map<string, any>
): string => {
  return configuration.get('isSample') ? KEBOOLA_EX_SAMPLE_DATA : component.get('id');
};

const getFakeComponentId = (configuration: Map<string, any>): string => {
  return configuration.getIn(['parameters', 'componentId']);
};

const getUsedFolders = (
  availableConfigurations: Map<string, any>,
  componentsMetadata: Map<string, any>,
  component: Map<string, any>
): Set<any> => {
  return availableConfigurations
    .flatMap((component): Map<string, any> => {
      return component.get('configurations').map((config: Map<string, any>) => {
        return config.set('component', component);
      });
    })
    .map((configuration) => {
      return getFolderFromMetadata(
        componentsMetadata.getIn([
          configuration.get('component', component).get('id'),
          configuration.get('id')
        ])
      );
    })
    .filter(Boolean)
    .toSet();
};

const onTableRowKeyDown = (rowAction: (e: React.KeyboardEvent) => void) => {
  return (e: React.KeyboardEvent) => {
    if (e.key !== keyCodes.ENTER) {
      return;
    }

    const target = e.target as HTMLElement;

    // we call action only when enter is pressed on table row itself or on collapse button if is not disabled
    if (
      (target.classList.contains('tr') && target.classList.contains('clickable')) ||
      (target instanceof HTMLButtonElement &&
        target.classList.contains('table-collapse-button') &&
        !target.disabled)
    ) {
      rowAction(e);
    }
  };
};

export { getRealComponentId, getFakeComponentId, getUsedFolders, onTableRowKeyDown };
