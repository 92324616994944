import { fromJS, Map } from 'immutable';

import InstalledComponentsStore from '../../modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '../../modules/configurations/ConfigurationRowsStore';

const prepareConfigData = (componentId, configId, rowId) => {
  const configData = InstalledComponentsStore.getConfigData(componentId, configId);
  const editingParameters = InstalledComponentsStore.getEditingRawConfigDataParameters(
    componentId,
    configId,
    configData.get('parameters', Map())
  );

  const rowConfigData = ConfigurationRowsStore.getConfiguration(componentId, configId, rowId);
  const editingRow = ConfigurationRowsStore.getEditingConfiguration(componentId, configId, rowId);

  return configData
    .set('parameters', fromJS(JSON.parse(editingParameters)))
    .mergeDeep(rowConfigData.set('parameters', editingRow.get('parameters', Map())))
    .toJS();
};

export { prepareConfigData };
