import { fromJS, Map, OrderedMap } from 'immutable';

import Dispatcher from '../../Dispatcher';
import StoreUtils, { initStore } from '../../utils/StoreUtils';
import { ActionTypes, JOBS_LIMIT } from './constants';

let _store = initStore(
  'QueueJobsStore',
  Map({
    jobs: OrderedMap(),
    params: Map(),
    latestJobs: Map(),
    latestConfigJobs: Map(),
    pendingActions: Map(),
    isLoading: false,
    isLoadMore: false
  })
);

const JobsStore = StoreUtils.createStore({
  getAll() {
    return _store.get('jobs');
  },

  getIsLoading() {
    return _store.get('isLoading', false);
  },

  getIsLoaded() {
    return _store.get('jobs').count() > 0;
  },

  getIsJobLoaded(id) {
    return _store.hasIn(['jobs', id], false);
  },

  getIsLoadMore() {
    return _store.get('isLoadMore');
  },

  get(id) {
    return _store.getIn(['jobs', id], Map());
  },

  getLatestJobs(componentId, configurationId) {
    return _store.getIn(['latestJobs', componentId, configurationId], Map());
  },

  getLatestConfigJobs(componentId) {
    if (!componentId) {
      return _store.get('latestConfigJobs', Map());
    }

    return _store.getIn(['latestConfigJobs', componentId], Map());
  },

  getPendingActions() {
    return _store.get('pendingActions');
  },

  getParams() {
    return _store.get('params');
  }
});

JobsStore.dispatchToken = Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case ActionTypes.JOBS_LOAD_START: {
      const newParams = fromJS(action.params);
      if (action.options?.resetPreviousJobs && !_store.get('params', Map()).equals(newParams)) {
        _store = _store.set('jobs', OrderedMap());
      }
      _store = _store.set('params', newParams);
      _store = _store.set('isLoading', true);
      return JobsStore.emitChange();
    }

    case ActionTypes.JOBS_LOAD_SUCCESS:
      _store = _store.withMutations((store) => {
        if (action.options?.resetPreviousJobs) {
          store.set('jobs', OrderedMap());
        }

        store.mergeIn(
          ['jobs'],
          fromJS(action.jobs)
            .toOrderedMap()
            .mapKeys((key, job) => job.get('id'))
        );

        if (!action.withoutLoading) {
          store
            .set('isLoading', false)
            .set(
              'isLoadMore',
              action.jobs.length === _store.getIn(['params', 'limit'], JOBS_LIMIT)
            );
        }
      });
      return JobsStore.emitChange();

    case ActionTypes.JOBS_LOAD_ERROR:
      _store = _store.set('isLoading', false);
      return JobsStore.emitChange();

    case ActionTypes.JOB_LOAD:
      _store = _store.setIn(['pendingActions', 'loading', action.id], true);
      return JobsStore.emitChange();

    case ActionTypes.JOB_LOAD_SUCCESS:
      _store = _store.setIn(['jobs', action.job.id], fromJS(action.job));
      _store = _store.deleteIn(['pendingActions', 'loading', action.job.id]);
      return JobsStore.emitChange();

    case ActionTypes.JOB_TERMINATE:
      _store = _store.setIn(['pendingActions', 'terminating', action.id], true);
      return JobsStore.emitChange();

    case ActionTypes.JOB_TERMINATE_SUCCESS:
    case ActionTypes.JOB_TERMINATE_ERROR:
      _store = _store.deleteIn(['pendingActions', 'terminating', action.id], true);
      return JobsStore.emitChange();

    case ActionTypes.JOBS_LATEST_LOAD_START:
      _store = _store.updateIn(
        ['latestJobs', action.componentId, action.configurationId],
        Map({ isLoaded: false, jobs: Map() }),
        (jobs) => jobs.set('isLoading', true)
      );
      return JobsStore.emitChange();

    case ActionTypes.JOBS_LATEST_LOAD_ERROR:
      _store = _store.setIn(
        ['latestJobs', action.componentId, action.configurationId, 'isLoading'],
        false
      );
      return JobsStore.emitChange();

    case ActionTypes.JOBS_LATEST_LOAD_SUCCESS:
      _store = _store.setIn(
        ['latestJobs', action.componentId, action.configurationId],
        Map({
          isLoading: false,
          isLoaded: true,
          jobs: fromJS(action.jobs)
        })
      );
      return JobsStore.emitChange();

    case ActionTypes.CONFIG_LATEST_JOB_LOAD_SUCCESS: {
      const latestJobs = fromJS(action.jobs).groupBy((job) => job.get('config'));

      if (latestJobs.equals(_store.getIn(['latestConfigJobs', action.componentId], Map()))) {
        return;
      }

      _store = _store.setIn(['latestConfigJobs', action.componentId], latestJobs);
      return JobsStore.emitChange();
    }

    case ActionTypes.ALL_CONFIG_LATEST_JOB_LOAD_SUCCESS: {
      const latestJobs = fromJS(action.jobs)
        .groupBy((job) => job.get('component'))
        .map((jobs) => jobs.groupBy((job) => job.get('config')));

      if (latestJobs.equals(_store.get('latestConfigJobs'))) {
        return;
      }

      _store = _store.set('latestConfigJobs', latestJobs);
      return JobsStore.emitChange();
    }

    default:
      break;
  }
});

export default JobsStore;
