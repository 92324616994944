import React from 'react';
import type { Map } from 'immutable';

import { BETA, EXCLUDE_FROM_NEW_LIST, EXPERIMENTAL } from '../../constants/componentFlags';
import { BadgeBeta, BadgeExperimental, BadgePrivate } from './Badge';

const hasStageFlag = (flags: Map<string, any>) => {
  return (
    flags.includes(BETA) || flags.includes(EXPERIMENTAL) || flags.includes(EXCLUDE_FROM_NEW_LIST)
  );
};

const PRIVATE_TEXT = 'This component is not publicly accessible.';
const BETA_TEXT =
  'Available to all users. In active development with potential for feature additions. Minor bugs fixed under standard SLA. Some limitations may be present, and documentation is evolving. Publicly accessible code.';
const EXPERIMENTAL_TEXT =
  'Functional but experimental component, excluded from the standard SLA. Usually, developed for specific scenarios and not widely tested. May contain unhandled bugs or have various limitations.';

const ComponentBadges = ({ className, flags }: { className?: string; flags: Map<string, any> }) => {
  if (flags.includes(EXCLUDE_FROM_NEW_LIST)) {
    return <BadgePrivate className={className} tooltip={PRIVATE_TEXT} />;
  }

  if (flags.includes(BETA)) {
    return <BadgeBeta className={className} tooltip={BETA_TEXT} />;
  }

  if (flags.includes(EXPERIMENTAL)) {
    return <BadgeExperimental className={className} tooltip={EXPERIMENTAL_TEXT} />;
  }

  return null;
};

export { hasStageFlag, EXPERIMENTAL_TEXT };

export default ComponentBadges;
