import React from 'react';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import ComponentWithIconAndType from '../../../../../react/common/ComponentWithIconAndType';
import JobDuration from '../../../../../react/common/JobDuration';
import JobPartialRunLabel from '../../../../../react/common/JobPartialRunLabel';
import JobStatusLabel from '../../../../../react/common/JobStatusLabel';
import Link from '../../../../../react/common/RouterLink';
import TimeAndUser from '../../../../../react/common/TimeAndUser';
import Truncated from '../../../../../react/common/Truncated';
import { getComponentByJob, getConfigurationName } from '../../../JobComponentResolver';

const JobRow = createReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    job: PropTypes.object.isRequired,
    userRunnedJob: PropTypes.object.isRequired,
    query: PropTypes.string.isRequired,
    admin: PropTypes.instanceOf(Map)
  },

  render() {
    const component = getComponentByJob(this.props.job);

    return (
      <Link className="tr" to="jobDetail" params={this.linkParams()} query={this.linkQuery()}>
        <div className="td">
          <ComponentWithIconAndType component={component} />
        </div>
        <div className="td">
          <div className="flex-container flex-start">
            <JobPartialRunLabel job={this.props.userRunnedJob} />
            <Truncated text={getConfigurationName(this.props.userRunnedJob)} />
          </div>
        </div>
        <div className="td text-right">
          <JobDuration
            status={this.props.job.get('status')}
            startTime={this.props.job.get('startTime')}
            endTime={this.props.job.get('endTime')}
          />
        </div>
        <div className="td text-right">
          <TimeAndUser
            admin={this.props.admin}
            time={this.props.job.get('createdTime')}
            fallbackName={this.props.job.getIn(['token', 'description'])}
          />
        </div>
        <div className="td">
          <JobStatusLabel status={this.props.job.get('status')} />
        </div>
      </Link>
    );
  },

  linkParams() {
    return {
      jobId: this.props.job.get('id')
    };
  },

  linkQuery() {
    return {
      q: this.props.query
    };
  }
});

export default JobRow;
