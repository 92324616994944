import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';
import removeMarkdown from 'remove-markdown';

import { copyToClipboard } from '../../../react/common/Clipboard';
import ModalIcon from '../../../react/common/ModalIcon';
import contactSupport from '../../../utils/contactSupport';
import { getErrorExplanation, sendExplanationFeedback } from '../../ai/api';
import { FEEDBACK_STATUS } from '../../ai/constants';
import { extractErrorDetails } from '../helpers';
import JobErrorResult from './JobErrorResult';

class JobErrorModal extends React.Component {
  state = {
    message: '',
    hasError: false,
    correlationId: null,
    usedSupportButton: false,
    savedInClipboard: false,
    feedbackStatus: null
  };

  render() {
    const exceptionId = this.props.job.getIn(
      ['result', 'error', 'exceptionId'], // new queue
      this.props.job.getIn(['result', 'exceptionId']) // old jobs
    );

    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this.onHide}
        onEnter={() => this.props.useAi && !this.state.message && this.handleExplain()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Job Error
            {exceptionId && <small className="text-muted ml-2">ExceptionId {exceptionId}</small>}
          </Modal.Title>
          <ModalIcon icon="circle-exclamation" color="red" bold />
        </Modal.Header>
        <Modal.Body className="overflow-break-anywhere">
          <JobErrorResult
            job={this.props.job}
            useAi={this.props.useAi}
            hasError={this.state.hasError}
            explanationViaAI={this.state.message}
            feedbackStatus={this.state.feedbackStatus}
            onReload={this.handleExplain}
            onGood={this.sendGoodFeedback}
            onBad={this.sendBadFeedback}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="block same-width">
            <Button
              disabled={this.state.savedInClipboard || (this.props.useAi && !this.state.message)}
              onClick={() => {
                return copyToClipboard(this.prepareClipboardText(exceptionId)).then(() => {
                  this.setState({ savedInClipboard: true });
                  setTimeout(() => this.setState({ savedInClipboard: false }), 1000);
                });
              }}
            >
              <FontAwesomeIcon icon="copy" className="icon-addon-right" />
              {this.state.savedInClipboard ? 'Copied' : 'Copy details to clipboard'}
            </Button>
            <Button
              bsStyle="success"
              onClick={() => {
                contactSupport();
                this.setState({ usedSupportButton: true });
              }}
            >
              <FontAwesomeIcon icon="message-question" className="icon-addon-right" />
              Contact Support
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }

  sendGoodFeedback = () => {
    this.sendFeedback(FEEDBACK_STATUS.GOOD);
  };

  sendBadFeedback = (comment) => {
    this.sendFeedback(FEEDBACK_STATUS.BAD, { comment });
  };

  onHide = () => {
    this.props.onHide();

    if (!this.state.feedbackStatus && !!this.state.message) {
      this.sendFeedback(FEEDBACK_STATUS.CANCEL, {
        subject: { jobId: this.props.job.get('id') },
        ...(this.state.usedSupportButton && { comment: 'Contact support clicked!' })
      });
    }
  };

  sendFeedback = (status, data) => {
    this.setState({ feedbackStatus: status });
    sendExplanationFeedback({
      value: status,
      ...(this.state.correlationId && { correlationId: this.state.correlationId }),
      ...data
    });
  };

  handleExplain = () => {
    this.setState({ message: '', feedbackStatus: null, hasError: false });
    getErrorExplanation(this.props.job)
      .then((data) => {
        this.setState({ message: data.answer, correlationId: data.correlationId });
      })
      .catch(() => this.setState({ hasError: true }));
  };

  prepareClipboardText = (exceptionId) => {
    const jobId = this.props.job.get('id');
    const exception = exceptionId ? `\nExceptionId ${exceptionId}` : '';
    const errorMessage =
      this.props.job.get('error') === 'application'
        ? 'Internal Error'
        : extractErrorDetails(this.props.job.getIn(['result', 'message'])).trim();

    const aiMessage = this.state.message
      ? `\nAI Explanation: ${removeMarkdown(this.state.message).trim()}`
      : '';

    return `Job Error

Job ${jobId}${exception}
${window.location.href}

${errorMessage}
${aiMessage}`;
  };
}

JobErrorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  job: PropTypes.instanceOf(Map).isRequired,
  useAi: PropTypes.bool.isRequired
};

export default JobErrorModal;
