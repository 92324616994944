import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { KEBOOLA_ORCHESTRATOR } from '../../constants/componentIds';
import ConfirmButtons from './ConfirmButtons';
import CreatedDate from './CreatedDate';
import ModalIcon from './ModalIcon';

const RollbackVersionModal = createReactClass({
  propTypes: {
    version: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onRollback: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Version Rollback</Modal.Title>
          <ModalIcon color="green" icon="arrow-rotate-left" bold />
        </Modal.Header>
        <Modal.Body>
          {this.props.componentId === KEBOOLA_ORCHESTRATOR && (
            <Alert bsStyle="warning">
              <p>If the orchestration has schedule set, the rollback will not affect it.</p>
            </Alert>
          )}
          <p>
            Are you sure you want to roll back the version #{this.props.version.get('version')}{' '}
            created <CreatedDate createdTime={this.props.version.get('created')} /> by{' '}
            {this.props.version.getIn(['creatorToken', 'description'], 'unknown')}?
          </p>
          <p>Rollback copies the older version into a new version, so no versions will be lost.</p>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons block saveLabel="Restore version" onSave={this.props.onRollback} />
        </Modal.Footer>
      </Modal>
    );
  }
});

export default RollbackVersionModal;
