import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import {
  KDS_TEAM_WR_EXASOL,
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_LOOKER_V2,
  KEBOOLA_WR_THOUGHTSPOT
} from '../../../../../constants/componentIds';
import changedSinceConstants from '../../../../../react/common/changedSinceConstants';
import ConfirmButtons from '../../../../../react/common/ConfirmButtons';
import ModalIcon from '../../../../../react/common/ModalIcon';
import Select from '../../../../../react/common/Select';
import ChangedSinceInput from '../../../../components/react/components/generic/ChangedSinceFilterInput';
import DataFilterRow from '../../../../components/react/components/generic/DataFilterRow';
import { backends } from '../../../../storage/constants';
import ThoughtSpotTypeInput from './ThoughtSpotTypeInput';

const IncrementalSetupModal = createReactClass({
  propTypes: {
    columns: PropTypes.object.isRequired,
    allTables: PropTypes.object.isRequired,
    currentPK: PropTypes.instanceOf(List).isRequired,
    currentMapping: PropTypes.object,
    isIncremental: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    customFieldsValues: PropTypes.object,
    supportsPrimaryKey: PropTypes.bool
  },

  getDefaultProps() {
    return {
      supportsPrimaryKey: true
    };
  },

  getInitialState() {
    return {
      primaryKey: this.props.currentPK,
      mapping: this.props.currentMapping,
      isIncremental: this.props.isIncremental,
      customFieldsValues: this.props.customFieldsValues,
      isSaving: false
    };
  },

  render() {
    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState(this.getInitialState())}
      >
        <Modal.Header closeButton>
          <Modal.Title>Load Settings</Modal.Title>
          <ModalIcon icon="circle-arrow-up" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <div className="form form-horizontal">
            {this.renderCustomFields()}
            <div className="form-group">
              <label className="control-label col-sm-3">Load Type</label>
              <div className="col-sm-9">
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      label="Full Load"
                      checked={!this.state.isIncremental}
                      onChange={() =>
                        this.setState({
                          isIncremental: false,
                          mapping: this.state.mapping.set('changed_since', '')
                        })
                      }
                    />
                    <span>Full Load</span>
                  </label>
                </div>
                <p className="help-block">Replace all existing rows in the destination table.</p>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      label="Automatic Incremental Load"
                      checked={
                        this.state.isIncremental &&
                        this.state.mapping.get('changed_since') ===
                          changedSinceConstants.ADAPTIVE_VALUE
                      }
                      onChange={() =>
                        this.setState({
                          isIncremental: true,
                          mapping: this.state.mapping.set(
                            'changed_since',
                            changedSinceConstants.ADAPTIVE_VALUE
                          )
                        })
                      }
                    />
                    <span>Automatic Incremental Load</span>
                  </label>
                </div>
                <p className="help-block">
                  Append all data that has been added or changed since the last successful run.{' '}
                  {this.props.supportsPrimaryKey && (
                    <>
                      If a primary key is specified, updates will be applied to rows with matching
                      primary key values.
                    </>
                  )}
                </p>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      label="Manual Incremental Load"
                      checked={
                        this.state.isIncremental &&
                        this.state.mapping.get('changed_since') !==
                          changedSinceConstants.ADAPTIVE_VALUE
                      }
                      onChange={() =>
                        this.setState({
                          isIncremental: true,
                          mapping: this.state.mapping.set('changed_since', '')
                        })
                      }
                    />
                    <span>Manual Incremental Load</span>
                  </label>
                </div>
                <p className="help-block">
                  Append all selected data.{' '}
                  {this.props.supportsPrimaryKey && (
                    <>
                      If a primary key is specified, updates will be applied to rows with matching
                      primary key values.
                    </>
                  )}
                </p>
              </div>
            </div>
            {this.supportsOptions() &&
              this.state.mapping.get('changed_since') !== changedSinceConstants.ADAPTIVE_VALUE && (
                <ChangedSinceInput
                  disabled={false}
                  label="Data changed in last"
                  labelClassName="col-sm-3"
                  wrapperClassName="col-sm-9"
                  groupClassName="form-group"
                  onChange={(value) => this.setState({ mapping: value })}
                  mapping={this.state.mapping}
                  helpBlock="When specified, only rows changed or created within the selected time period will be loaded."
                />
              )}
            {this.supportsOptions() && (
              <DataFilterRow
                value={this.state.mapping}
                disabled={false}
                allTables={this.props.allTables}
                onChange={(value) => this.setState({ mapping: value })}
                groupClassName="form-group"
                labelClassName="col-xs-3 control-label"
                whereColumnClassName="col-xs-3"
              />
            )}
            {this.renderPKSelector()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons block isSaving={this.state.isSaving} onSave={this.handleSave} />
        </Modal.Footer>
      </Modal>
    );
  },

  renderCustomFields() {
    if (this.props.componentId === KEBOOLA_WR_THOUGHTSPOT) {
      return this.renderThoughtSpotTypeInput();
    }
    return null;
  },

  renderPKSelector() {
    if (!this.props.supportsPrimaryKey) {
      return null;
    }

    const isMulti = ![KEBOOLA_WR_LOOKER_V2, KDS_TEAM_WR_EXASOL].includes(this.props.componentId);

    return (
      <div className="form-group">
        <label htmlFor="title" className="col-sm-3 control-label">
          Destination Table
          <br />
          Primary Key
        </label>
        <div className="col-sm-9">
          <Select
            placeholder="Select from database column names"
            clearable={!isMulti}
            name="pkelector"
            multi={isMulti}
            value={this.state.primaryKey}
            onChange={(newValue) => {
              if (isMulti) {
                this.setState({ primaryKey: newValue });
              } else {
                this.setState({ primaryKey: newValue ? newValue : null });
              }
            }}
            options={this.getColumns()}
          />
          <span className="help-block">
            Used to determine matching rows for updates in incremental loads.
            {this.props.componentId === KEBOOLA_WR_DB_SNOWFLAKE && (
              <>
                {' '}
                Only non <strong>nullable</strong> columns can be selected.
              </>
            )}
            {!isMulti && (
              <strong> This components does not support a multi-column Primary Key.</strong>
            )}
          </span>
        </div>
      </div>
    );
  },

  renderThoughtSpotTypeInput() {
    return (
      <ThoughtSpotTypeInput
        value={this.state.customFieldsValues.get('type', 'standard')}
        onChange={(value) =>
          this.setState({
            customFieldsValues: this.state.customFieldsValues.set('type', value)
          })
        }
      />
    );
  },

  supportsOptions() {
    const table = this.props.allTables.get(this.state.mapping.get('source'), Map());

    return !table.get('isTyped', false) && table.getIn(['bucket', 'backend']) !== backends.BIGQUERY;
  },

  getColumns() {
    return this.props.columns.map((column) => ({ label: column, value: column })).toArray();
  },

  closeModal() {
    this.setState({ isSaving: false });
    this.props.onHide();
  },

  handleSave() {
    this.setState({ isSaving: true });
    return this.props
      .onSave(
        this.state.isIncremental,
        List.isList(this.state.primaryKey) ? this.state.primaryKey : List([this.state.primaryKey]),
        this.state.mapping,
        this.state.customFieldsValues
      )
      .then(() => this.closeModal());
  }
});

export default IncrementalSetupModal;
