import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { capitalize } from 'underscore.string';

import { defaultTransformationBackendSize } from '../../Constants';
import { getTransformationBackendSizes } from '../../helpers';

export const getBackendSizeIcons = (allSizes, size) => {
  const rockets = [];

  if (!allSizes || !size) {
    return null;
  }

  for (let rocketsCount = allSizes.indexOf(size); rocketsCount >= 0; rocketsCount--) {
    rockets.push(<FontAwesomeIcon key={rocketsCount} icon="rocket" />);
  }

  return rockets;
};

const BackendSizeLabel = ({
  componentId,
  className,
  showText = false,
  size = defaultTransformationBackendSize,
  emphasized = false,
  pullRight = false
}) => {
  return (
    <div
      className={classnames(className, 'backend-size-label', {
        'pull-right': pullRight,
        emphasized
      })}
      title={capitalize(size)}
    >
      {getBackendSizeIcons(getTransformationBackendSizes(componentId), size)}
      {showText && <span>{capitalize(size)}</span>}
    </div>
  );
};

BackendSizeLabel.propTypes = {
  componentId: PropTypes.string.isRequired,
  className: PropTypes.string,
  showText: PropTypes.bool,
  size: PropTypes.string,
  emphasized: PropTypes.bool,
  pullRight: PropTypes.bool
};

const MemoizedBackendSizeLabel = React.memo(BackendSizeLabel);

export default MemoizedBackendSizeLabel;
