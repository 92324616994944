const REQUEST_ABORTED_ERROR = 'ABORTED';
const REQUEST_TIMEOUT_ERROR = 'ECONNABORTED';

// There's a chance that some services have 60s on application gateway. So we
// want higher limit to be able to say why the request timed out. Adding
// 2 seconds.
const REQUEST_TIMEOUT_MS = 62000;

// Maximum Time of transfer is 45 minutes
// https://developers.keboola.com/integrate/storage/api/importer/
// The number bellow is 45 minutes + 10 seconds (in milliseconds)
const IMPORT_SERVICE_REQUEST_TIMEOUT_MS = 2710000;

export {
  REQUEST_ABORTED_ERROR,
  REQUEST_TIMEOUT_ERROR,
  REQUEST_TIMEOUT_MS,
  IMPORT_SERVICE_REQUEST_TIMEOUT_MS
};
