import React from 'react';

import { USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import CollapsibleAlert from '../../../react/common/CollapsibleAlert';
import ExternalLink from '../../../react/common/ExternalLink';

const BillingAlert = () => {
  return (
    <div className="container info-alert-section">
      <CollapsibleAlert id="data-apps-beta" title="Data Apps Are in Beta Version">
        <p>
          In the Beta version, active apps consume credits/minutes from your balance. Please note
          that data apps are automatically stopped every night. If you need to access the data apps,
          you will have to redeploy them manually. Pricing and the sleep/restore functions will
          change upon the final release. You can{' '}
          <ExternalLink
            href={`${USER_DOCUMENTATION_URL}/management/project/limits/#project-power--time-credits`}
          >
            learn more
          </ExternalLink>{' '}
          about billing in our documentation.
        </p>
      </CollapsibleAlert>
    </div>
  );
};

export default BillingAlert;
