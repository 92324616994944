import { KEBOOLA_WR_GOOGLE_DRIVE as COMPONENT_ID } from '../../constants/componentIds';
import { configPoll } from '../../utils/genericPolls';
import { configRequiredData } from '../../utils/genericRequiredData';
import Index from './react/pages/Index/Index';

export default {
  name: COMPONENT_ID,
  path: `${COMPONENT_ID}/:config`,
  defaultRouteHandler: Index(COMPONENT_ID),
  requireData: [(params) => configRequiredData(COMPONENT_ID, params.config)],
  poll: configPoll(COMPONENT_ID)
};
