import { Map } from 'immutable';

import { KEBOOLA_WR_ONEDRIVE } from '../../constants/componentIds';
import SyncActionError from '../../utils/errors/SyncActionError';
import callDockerAction from '../components/DockerActionsApi';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import AuthStore from '../oauth-v2/Store';

const getCredentials = (configId) => {
  const configData = InstalledComponentsStore.getConfigData(KEBOOLA_WR_ONEDRIVE, configId);
  const credentials = AuthStore.getCredentials(KEBOOLA_WR_ONEDRIVE, configData) || Map();

  return Map({
    appKey: credentials.get('appKey'),
    '#data': credentials.get('#data'),
    '#appSecret': credentials.get('#appSecret')
  });
};

const createWorkbook = (configId, path) => {
  const params = {
    configData: Map()
      .setIn(['parameters', 'workbook', 'path'], path)
      .setIn(['authorization', 'oauth_api', 'credentials'], getCredentials(configId))
      .toJS()
  };

  return callDockerAction(KEBOOLA_WR_ONEDRIVE, 'createWorkbook', params).then((result) => {
    if (result.status && result.status === 'error') {
      throw new SyncActionError(
        result.message || 'An error occurred while creating workbook',
        result.exceptionId
      );
    }
    return result;
  });
};

const createWorksheet = (configId, driveId, fileId, name) => {
  const params = {
    configData: Map()
      .setIn(['parameters', 'workbook', 'fileId'], fileId)
      .setIn(['parameters', 'workbook', 'driveId'], driveId)
      .setIn(['parameters', 'worksheet', 'name'], name)
      .setIn(['authorization', 'oauth_api', 'credentials'], getCredentials(configId))
      .toJS()
  };

  return callDockerAction(KEBOOLA_WR_ONEDRIVE, 'createWorksheet', params).then((result) => {
    if (result.status && result.status === 'error') {
      throw new SyncActionError(
        result.message || 'An error occurred while creating worksheet',
        result.exceptionId
      );
    }
    return result;
  });
};

const searchWorkspace = (configId, driveId, fileId) => {
  const params = {
    configData: Map()
      .setIn(['parameters', 'workbook', 'fileId'], fileId)
      .setIn(['parameters', 'workbook', 'driveId'], driveId)
      .setIn(['authorization', 'oauth_api', 'credentials'], getCredentials(configId))
      .toJS()
  };

  return callDockerAction(KEBOOLA_WR_ONEDRIVE, 'getWorksheets', params).then((result) => {
    if (result.status && result.status === 'error') {
      throw new SyncActionError(
        result.message || 'An error occurred while loading worksheets',
        result.exceptionId
      );
    }
    return result;
  });
};

export { createWorkbook, createWorksheet, searchWorkspace };
