import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import createReactClass from 'create-react-class';

import { FEATURE_ALTERNAT, FEATURE_ALTERNAT_WIZARD } from '../../constants/features';
import { ANNOUNCEMENT_URL } from '../../modules/home/react/components/AlterNatNotification';
import ApplicationStore from '../../stores/ApplicationStore';
import Checkbox from './Checkbox';
import ExternalLink from './ExternalLink';
import Loader from './Loader';

const TestCredentialsButtonGroup = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    testCredentialsFn: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  },

  getDefaultProps() {
    return {
      disabled: false
    };
  },

  getInitialState() {
    const showAlterNat =
      ApplicationStore.hasCurrentProjectFeature(FEATURE_ALTERNAT_WIZARD) &&
      !ApplicationStore.hasCurrentProjectFeature(FEATURE_ALTERNAT);

    return {
      showAlterNat,
      forceAlterNat: showAlterNat,
      isTesting: false,
      isError: false,
      result: null
    };
  },

  render() {
    return (
      <FormGroup>
        <Col xs={8} xsOffset={4}>
          {this.state.showAlterNat && this.renderAlterNatAlert()}
          {this.renderButton()}
          {this.renderResult()}
        </Col>
      </FormGroup>
    );
  },

  renderAlterNatAlert() {
    return (
      <Alert bsStyle="info" className="alert-no-icon f-14 line-height-20">
        <Checkbox
          checked={this.state.forceAlterNat}
          onChange={(checked) => this.setState({ forceAlterNat: checked })}
        >
          <span className="font-medium">Test with new outbound IPs</span>
        </Checkbox>
        <p className="match-header color-dark-muted">
          Read more in <ExternalLink href={ANNOUNCEMENT_URL}>announcement</ExternalLink>.
        </p>
      </Alert>
    );
  },

  renderButton() {
    return (
      <Button disabled={this.state.isTesting || this.props.disabled} onClick={this._startTesting}>
        {this.state.isTesting ? (
          <>
            <Loader className="icon-addon-right" />
            Testing Connection
          </>
        ) : (
          <>
            <FontAwesomeIcon icon="key" className="icon-addon-right" />
            Test Connection
          </>
        )}
      </Button>
    );
  },

  renderResult() {
    if (!this.state.result && !this.state.isError) {
      return null;
    }

    if (this.state.isError || !['success', 'ok'].includes(this.state.result.status)) {
      return this.renderError();
    }

    return this.renderSuccess();
  },

  renderSuccess() {
    return (
      <Alert bsStyle="success" className="alert-no-icon mt-1 mb-0">
        <span className={classNames('alert-title', { 'mb-0': !this.props.isEditing })}>
          <FontAwesomeIcon icon="check" /> Connected!
        </span>
        {this.props.isEditing && <p className="mt-1">Don&apos;t forget to save the credentials.</p>}
      </Alert>
    );
  },

  renderError() {
    return (
      <Alert bsStyle="danger" className="alert-no-icon mt-1 mb-0">
        <span className="alert-title">Failed to connect!</span>
        {this.state.result && (
          <div className="mt-1">
            <p>{this.state.result.message}</p>
            {this.state.result.exceptionId && (
              <small>ExceptionId: {this.state.result.exceptionId}</small>
            )}
          </div>
        )}
      </Alert>
    );
  },

  _startTesting() {
    this.setState({ isTesting: true, isError: false, result: null });
    return this.props
      .testCredentialsFn(this.state.forceAlterNat)
      .then(this._onTestingDone, this._onTestingError);
  },

  _onTestingDone(result) {
    return this.setState({ isTesting: false, isError: false, result });
  },

  _onTestingError(result) {
    return this.setState({ isTesting: false, isError: true, result });
  }
});

export default TestCredentialsButtonGroup;
