import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
  Well
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { List, Map } from 'immutable';

import InputValidation, { isValidName } from '../../../react/common/InputValidation';
import Loader from '../../../react/common/Loader';
import ModalIcon from '../../../react/common/ModalIcon';
import Tooltip from '../../../react/common/Tooltip';
import { canLinkBucket } from '../../admin/privileges';
import StorageActionCreators from '../../components/StorageActionCreators';
import DevBranchesStore from '../../dev-branches/DevBranchesStore';
import { nameWarning } from '../../storage/constants';
import { bucketDisplayNameWithStage, validateBucketName } from '../../storage/helpers';

class LinkButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayName: '',
      showModal: false,
      creatingBucket: false,
      error: null,
      warning: null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDisplayName = this.handleDisplayName.bind(this);
  }

  render() {
    if (!canLinkBucket(this.props.token)) {
      return (
        <Tooltip
          tooltip="You don't have permission to link this bucket."
          placement="top"
          type="explanatory"
        >
          <button
            className={classnames('btn btn-default disabled', {
              'btn-sm': this.props.size === 'sm'
            })}
          >
            {this.props.withIcon && <FontAwesomeIcon icon="plus" className="icon-addon-right" />}
            Use this
          </button>
        </Tooltip>
      );
    }

    return (
      <>
        {this.renderButton()}
        {this.renderModal()}
      </>
    );
  }

  renderButton() {
    return (
      <Button bsSize={this.props.size} bsStyle="primary" onClick={this.openModal}>
        {this.props.withIcon && <FontAwesomeIcon icon="plus" className="icon-addon-right" />}
        Use this
      </Button>
    );
  }

  renderModal() {
    return (
      <Modal show={this.state.showModal} onHide={this.closeModal}>
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <ModalIcon
              icon="folder"
              generatedBackground={bucketDisplayNameWithStage(this.props.bucket)}
            />
            <Modal.Title>
              {this.props.bucket.get('displayName')}{' '}
              <span className="text-muted font-normal">
                by {this.props.bucket.getIn(['project', 'name'])}
              </span>
            </Modal.Title>
            {this.props.bucket.get('description') && (
              <div className="text-muted mt-1">{this.props.bucket.get('description')}</div>
            )}
          </Modal.Header>
          <Modal.Body>
            {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
            <InputValidation predefined="bucketName" value={this.state.displayName}>
              {(inputState) => (
                <FormGroup validationState={this.state.warning ? 'error' : inputState}>
                  <ControlLabel>
                    Select a name how the bucket will appear in your project
                  </ControlLabel>
                  <div className="flex-container">
                    <FormControl
                      autoFocus
                      type="text"
                      value={this.state.displayName}
                      onChange={this.handleDisplayName}
                    />
                    <Button
                      type="submit"
                      className="ml-1"
                      bsStyle="success"
                      onClick={this.onSubmit}
                      disabled={this.isDisabled()}
                    >
                      {this.state.creatingBucket ? (
                        <Loader className="icon-addon-right" />
                      ) : (
                        <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
                      )}
                      Link
                    </Button>
                  </div>
                  <HelpBlock
                    className={classnames({
                      'text-danger': !!this.state.warning || inputState === 'error'
                    })}
                  >
                    {this.state.warning ||
                      `The bucket will be linked to an IN stage. ${nameWarning}`}
                  </HelpBlock>
                </FormGroup>
              )}
            </InputValidation>
            {this.props.bucket.get('tables', List()).count() > 0 && (
              <Well>
                <div className="well-title">Tables</div>
                {this.props.bucket.get('tables').map((table, index) => (
                  <div className="flex-container flex-start" key={index}>
                    <FontAwesomeIcon icon="table" className="icon-addon-right text-muted" />
                    {table.get('displayName')}
                  </div>
                ))}
              </Well>
            )}
          </Modal.Body>
        </Form>
      </Modal>
    );
  }

  openModal() {
    this.setState({ showModal: true, displayName: this.props.bucket.get('displayName') });
  }

  closeModal() {
    this.setState({
      showModal: false,
      error: null,
      displayName: ''
    });
  }

  handleDisplayName(e) {
    const displayName = e.target.value;

    this.setState({
      displayName,
      warning: validateBucketName(
        displayName,
        'in',
        this.props.buckets,
        DevBranchesStore.getCurrentId()
      )
    });
  }

  isDisabled() {
    if (
      this.state.creatingBucket ||
      !this.state.displayName ||
      !isValidName(this.state.displayName) ||
      this.state.warning
    ) {
      return true;
    }

    return false;
  }

  onSubmit(e) {
    e.preventDefault();

    const newBucket = {
      stage: 'in',
      displayName: this.state.displayName,
      name: this.state.displayName,
      sourceProjectId: this.props.bucket.getIn(['project', 'id']),
      sourceBucketId: this.props.bucket.get('id')
    };

    this.setState({ creatingBucket: true });
    StorageActionCreators.createBucket(newBucket, { async: true })
      .then(this.closeModal)
      .catch((error) => {
        this.setState({ error, creatingBucket: false });
      });
  }
}

LinkButton.propTypes = {
  buckets: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  token: PropTypes.instanceOf(Map).isRequired,
  size: PropTypes.string,
  withIcon: PropTypes.bool
};

LinkButton.defaultProps = {
  size: 'lg',
  withIcon: false
};

export default LinkButton;
