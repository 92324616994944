import { SERVICE_IMPORT } from '../../constants/serviceIds';
import { IMPORT_SERVICE_REQUEST_TIMEOUT_MS } from '../../constants/superagent';
import ApplicationStore from '../../stores/ApplicationStore';
import request from '../../utils/request';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import ServicesStore from '../services/Store';

const createRequest = function (method, path) {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_IMPORT)}/${path}`)
    .timeout(IMPORT_SERVICE_REQUEST_TIMEOUT_MS)
    .set('X-StorageApi-Token', ApplicationStore.getSapiTokenString());
};

export default {
  fileUpload: function (file, params, onProgress) {
    return createRequest('POST', 'upload-file')
      .on('progress', onProgress)
      .attach('data', file)
      .field({
        ...params,
        branchId: ApplicationStore.hasProtectedDefaultBranch()
          ? DevBranchesStore.getCurrentId()
          : DevBranchesStore.getDefaultBranchId()
      })
      .promise()
      .then((response) => response.body);
  }
};
