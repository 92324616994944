import React from 'react';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '../../constants/componentIds';
import { componentTypes } from '../../constants/componentTypes';
import ConfigurationInfoPanel from '../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../react/common/ConfigurationTabs';
import InfoAlert from '../../react/common/InfoAlert';
import Sidebar from '../../react/layout/Sidebar';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import ComponentDescription from '../components/react/components/ComponentDescription';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import StorageTablesStore from '../components/stores/StorageTablesStore';
import OauthStore from '../oauth-v2/Store';
import { prepareTablesMetadataMap } from '../storage/helpers';

const LegacyIndex = createReactClass({
  mixins: [
    createStoreMixin(
      InstalledComponentsStore,
      OauthStore,
      ApplicationStore,
      ComponentsStore,
      StorageTablesStore
    )
  ],

  getStateFromStores() {
    const componentId = RoutesStore.getCurrentRouteComponentId();
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(componentId, configId);
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);

    return {
      config,
      configId,
      componentId,
      allTables: StorageTablesStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      oauthCredentials: OauthStore.getCredentials(componentId, configData) || Map(),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
      hasFlows: ApplicationStore.hasFlows()
    };
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={this.state.componentId} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.config}
          hasFlows={this.state.hasFlows}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={this.state.componentId}
              configId={this.state.configId}
              placeholderEntity={componentTypes.EXTRACTOR}
            />
            <InfoAlert
              className="tw-mt-6"
              bsStyle="warning"
              header="The configuration has legacy format"
            >
              It cannot be further edited. To automatically migrate to the new format, please run
              your configuration.
            </InfoAlert>
          </div>
          <div className="col-sm-3">
            <Sidebar
              hideCopy
              hideSchedule
              componentId={this.state.componentId}
              configId={this.state.configId}
            />
          </div>
        </div>
      </>
    );
  }
});

export default LegacyIndex;
