import { Map } from 'immutable';

import StorageTablesStore from '../components/stores/StorageTablesStore';
import { loadTableDetail } from '../storage/actions';
import Index from './Index';

export default {
  name: 'tablePreview',
  title: (routerState) => {
    const tableId = routerState.getIn(['params', 'tableId']);
    const table = StorageTablesStore.getTable(tableId, Map());
    return `Table ${table.get('displayName', tableId)}`;
  },
  path: 'table-preview/:tableId',
  requireData: (params) => loadTableDetail(params.tableId),
  defaultRouteHandler: Index
};
