import React from 'react';
import createReactClass from 'create-react-class';

import {
  FEATURE_NEW_TRANSFORMATIONS_ONLY,
  FEATURE_SANDBOXES_PERSISTENT_VOLUME,
  FEATURE_SHOW_TRANSFORMATION_MIGRATION
} from '../../constants/features';
import { ADMIN_ROLES } from '../../constants/KbcConstants';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import {
  canChangeProjectOrganization,
  canDeleteProject,
  canManageProjectFeatures,
  hasAccessToProjectOrganization
} from '../admin/privileges';
import StackFeaturesStore from '../stack-features/Store';
import DataPersistency from './components/DataPersistency';
import DataTakeout from './components/DataTakeout';
import DeleteProject from './components/DeleteProject';
import EditableFeatures from './components/EditableFeatures';
import ProjectDetails from './components/ProjectDetails';
import SettingsTabs from './components/SettingsTabs';
import StorageBackend from './components/StorageBackend';
import { changeOrganization, removeProject, updateProject } from './actions';
import { FEATURE_TYPES, routeNames } from './constants';
import SettingsStore from './SettingsStore';

const Project = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, SettingsStore, StackFeaturesStore)],

  getStateFromStores() {
    return {
      user: SettingsStore.getUser(),
      project: SettingsStore.getProject(),
      availableFeatures: SettingsStore.getFeatures(FEATURE_TYPES.PROJECT),
      activatedFeatures: ApplicationStore.getCurrentProjectFeatures(),
      organizationsViaMaintainer: SettingsStore.getOrganizationsViaMaintainer(),
      organizations: SettingsStore.getOrganizations(),
      projectTemplates: ApplicationStore.getProjectTemplates(),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      hasStackEnabledPersistentStorage: StackFeaturesStore.hasStackFeature(
        FEATURE_SANDBOXES_PERSISTENT_VOLUME
      ),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      sapiToken: ApplicationStore.getSapiToken(),
      isReadOnly: ApplicationStore.isReadOnly()
    };
  },

  render() {
    return (
      <SettingsTabs activeTab={routeNames.PROJECT}>
        <div className="box project-settings">
          {this.state.hasNewQueue ? (
            <>
              <div>
                {this.renderProjectDetails()}
                {this.renderStorageInfo()}
                {this.renderDataPersistency()}
                {this.renderDataTakeout()}
                {this.renderDeleteProject()}
              </div>
              <div>{this.renderFeatures()}</div>
            </>
          ) : (
            <>
              <div>
                {this.renderProjectDetails()}
                {this.renderFeatures()}
              </div>
              <div>
                {this.renderStorageInfo()}
                {this.renderDataPersistency()}
                {this.renderDataTakeout()}
                {this.renderDeleteProject()}
              </div>
            </>
          )}
        </div>
      </SettingsTabs>
    );
  },

  renderProjectDetails() {
    return (
      <ProjectDetails
        user={this.state.user}
        project={this.state.project}
        organizations={this.state.organizationsViaMaintainer}
        projectTemplates={this.state.projectTemplates}
        handleChangeOrganization={changeOrganization}
        handleUpdateProject={updateProject}
        hasAdminRoleInProject={this.state.sapiToken.getIn(['admin', 'role']) === ADMIN_ROLES.ADMIN}
        hasAccessToProjectOrganization={hasAccessToProjectOrganization(
          this.state.user,
          this.state.organizations,
          this.state.organizationsViaMaintainer,
          this.state.project
        )}
        canChangeProjectOrganization={canChangeProjectOrganization(
          this.state.user,
          this.state.organizationsViaMaintainer,
          this.state.project
        )}
      />
    );
  },

  renderFeatures() {
    return (
      <EditableFeatures
        readOnly={!canManageProjectFeatures(this.state.sapiToken)}
        activatedFeatures={this.state.activatedFeatures}
        availableFeatures={this.state.availableFeatures}
      />
    );
  },

  renderStorageInfo() {
    return (
      <StorageBackend
        user={this.state.user}
        project={this.state.project}
        handleUpdateStorage={updateProject}
      />
    );
  },

  renderDataPersistency() {
    if (
      !this.state.project.get('features').includes(FEATURE_SHOW_TRANSFORMATION_MIGRATION) &&
      !this.state.project.get('features').includes(FEATURE_NEW_TRANSFORMATIONS_ONLY)
    ) {
      return null;
    }

    return (
      <DataPersistency
        isActive={
          this.state.hasStackEnabledPersistentStorage ||
          this.state.project.get('features').includes(FEATURE_SANDBOXES_PERSISTENT_VOLUME)
        }
      />
    );
  },

  renderDeleteProject() {
    if (
      !canDeleteProject(
        this.state.user,
        this.state.sapiToken,
        this.state.organizationsViaMaintainer,
        this.state.project
      )
    ) {
      return null;
    }

    return <DeleteProject project={this.state.sapiToken.get('owner')} onDelete={removeProject} />;
  },

  renderDataTakeout() {
    if (this.state.isReadOnly || this.state.hasProtectedDefaultBranch) {
      return null;
    }

    return <DataTakeout />;
  }
});

export default Project;
