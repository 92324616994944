import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';

const TruncateTableModal = createReactClass({
  propTypes: {
    onConfirm: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    table: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired
  },

  getInitialState() {
    return {
      isPending: false
    };
  },

  render() {
    return (
      <Modal onHide={this.props.onHide} show={this.props.show}>
        <Modal.Header closeButton>
          <Modal.Title>Truncate table</Modal.Title>
          <ModalIcon color="red" icon="xmark" bold />
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to truncate the table <b>{this.props.table.get('displayName')}</b>
            ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={this.state.isPending}
            saveLabel={this.state.isPending ? 'Truncating table...' : 'Truncate table'}
            saveStyle="danger"
            onSave={this.handleConfirm}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  handleConfirm() {
    this.setState({
      isPending: true
    });
    this.props.onConfirm().finally(() => {
      this.setState({ isPending: false }, () => {
        this.props.onHide();
      });
    });
  }
});

export default TruncateTableModal;
