const defaultPorts = {
  SSH_PORT: 22,
  DATABASE_PORT: 27017
};

const protocols = {
  MONGO_DB: 'mongodb',
  MONGO_DB_SRV: 'mongodb+srv',
  CUSTOM_URI: 'custom_uri'
};

const exportModes = {
  MODE_RAW: 'raw',
  MODE_MAPPING: 'mapping'
};

export { defaultPorts, protocols, exportModes };
