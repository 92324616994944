import React from 'react';
import createReactClass from 'create-react-class';

import { KEBOOLA_SANDBOXES } from '../../../constants/componentIds';
import { componentTypes } from '../../../constants/componentTypes';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import { getAllowedTransformations } from '../../components/helpers';
import ComponentsStore from '../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../components/stores/InstalledComponentsStore';
import StackFeaturesStore from '../../stack-features/Store';
import AddSandboxButton from './AddSandboxButton';

const SandboxesHeaderButton = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      StackFeaturesStore,
      ComponentsStore,
      InstalledComponentsStore
    )
  ],

  getStateFromStores() {
    return {
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      readOnly: ApplicationStore.isReadOnly(),
      sandboxComponent: ComponentsStore.getComponent(KEBOOLA_SANDBOXES),
      allowedTransformationComponents: getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll()
      ),
      availableDatabricksClusters: InstalledComponentsStore.getLocalState(
        KEBOOLA_SANDBOXES,
        null
      ).get('clusters')
    };
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <AddSandboxButton
        sandboxComponent={this.state.sandboxComponent}
        allowedComponents={this.state.allowedTransformationComponents}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
        availableDatabricksClusters={this.state.availableDatabricksClusters}
      />
    );
  }
});

export default SandboxesHeaderButton;
