import React from 'react';
import PropTypes from 'prop-types';
import { Alert, ControlLabel, Form, FormGroup, Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';
import Select from '../../../react/common/Select';
import { canManageSharedBucket } from '../../admin/privileges';
import DataCatalogActions from '../actions';
import { SHARED_TYPES } from '../constants';
import { couldBeProjectRemovedFromBucketSharingSettings } from '../helpers';
import ShareWithSelect from './ShareWithSelect';

class EditSharingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      sharedType: '',
      targetUsers: List(),
      targetProjects: List(),
      current: {
        sharedType: '',
        targetUsers: List(),
        targetProjects: List()
      }
    };

    this.onHide = this.onHide.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    if (!canManageSharedBucket(this.props.sapiToken)) {
      return null;
    }

    return (
      <Modal show={this.props.show} onHide={this.onHide} onEnter={this.onEnter}>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit sharing {this.props.bucket.get('displayName')}</Modal.Title>
            <ModalIcon.Edit />
          </Modal.Header>
          <Modal.Body>
            {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
            {this.isUsed() && (
              <Alert bsStyle="warning">
                There are projects which have linked the bucket. You cannot change the group with
                which the bucket is shared.
                {[SHARED_TYPES.SELECTED_PEOPLE, SHARED_TYPES.SELECTED_PROJECT].includes(
                  this.props.bucket.get('sharing')
                ) && (
                  <>
                    {' '}
                    But you can change the current sharing settings (e.g. add more projects or
                    users).
                  </>
                )}
              </Alert>
            )}
            <ShareWithSelect
              value={this.state.sharedType}
              onChange={(type) => this.setState({ sharedType: type })}
              disabled={this.isUsed()}
              hasUsersOptions={this.props.availableUsersOptions.length > 0}
              hasProjectsOptions={this.props.availableProjectsOptions.length > 0}
            />
            {this.renderAdditionalControls(this.isUsed())}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={this.state.isLoading ? 'Saving changes...' : 'Save changes'}
              isSaving={this.state.isLoading}
              isDisabled={this.isDisabled()}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  isUsed() {
    return this.props.bucket.get('linkedBy', List()).count() > 0;
  }

  onEnter() {
    const sharedType = this.props.bucket.get('sharing');
    const targetUsers = this.props.bucket
      .getIn(['sharingParameters', 'users'], List())
      .map((option) => option.get('id'));
    const targetProjects = this.props.bucket
      .getIn(['sharingParameters', 'projects'], List())
      .map((option) => option.get('id'));

    this.setState({
      showModal: true,
      sharedType,
      targetUsers,
      targetProjects,
      current: { sharedType, targetUsers, targetProjects }
    });
  }

  renderAdditionalControls(isUsed) {
    if (this.state.sharedType === SHARED_TYPES.SELECTED_PEOPLE) {
      return (
        <FormGroup controlId="form-shared-with-selected-people">
          <ControlLabel>Users</ControlLabel>
          <Select
            multi
            clearable={!isUsed}
            placeholder="Select users"
            options={this.props.availableUsersOptions.map((option) => {
              return {
                clearableValue: !isUsed,
                ...option
              };
            })}
            value={this.state.targetUsers}
            onChange={(targetUsers) => this.setState({ targetUsers })}
          />
        </FormGroup>
      );
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PROJECT) {
      return (
        <FormGroup controlId="form-shared-with-selected-projects">
          <ControlLabel>Projects</ControlLabel>
          <Select
            multi
            clearable={!isUsed}
            placeholder="Select projects"
            options={this.props.availableProjectsOptions.map((option) => {
              return {
                clearableValue: couldBeProjectRemovedFromBucketSharingSettings(
                  this.props.bucket,
                  option.value
                ),
                ...option
              };
            })}
            value={this.state.targetProjects}
            onChange={(targetProjects) => this.setState({ targetProjects })}
          />
        </FormGroup>
      );
    }

    return null;
  }

  handleSubmit(e) {
    e.preventDefault();

    const params = {
      targetProjectIds: this.state.targetProjects.toJS(),
      targetUsers: this.state.targetUsers.toJS()
    };

    this.setState({ isLoading: true });
    DataCatalogActions.shareBucketSimple(this.props.bucket.get('id'), this.state.sharedType, params)
      .then(this.onHide)
      .catch((error) => {
        this.setState({ error, isLoading: false });
        return null;
      });
  }

  isDisabled() {
    if (
      (this.state.sharedType === SHARED_TYPES.ORGANIZATION_MEMBER &&
        this.props.bucket.get('sharing') === SHARED_TYPES.ORGANIZATION_MEMBER) ||
      (this.state.sharedType === SHARED_TYPES.PROJECT_MEMBERS &&
        this.props.bucket.get('sharing') === SHARED_TYPES.PROJECT_MEMBERS)
    ) {
      return true;
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PEOPLE) {
      return (
        !this.state.targetUsers.count() ||
        this.state.current.targetUsers.equals(this.state.targetUsers)
      );
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PROJECT) {
      return (
        !this.state.targetProjects.count() ||
        this.state.current.targetProjects.equals(this.state.targetProjects)
      );
    }

    return false;
  }

  onHide() {
    this.setState({ isLoading: false, error: null }, () => {
      this.props.closeModalFn();
    });
  }
}

EditSharingModal.propTypes = {
  bucket: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  availableUsersOptions: PropTypes.array.isRequired,
  availableProjectsOptions: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired
};

export default EditSharingModal;
