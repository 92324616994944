import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { List } from 'immutable';
import { Map } from 'immutable';

import Checkbox from '../../../react/common/Checkbox';
import JobStatusIcon from '../../../react/common/JobStatusIcon';
import Loader from '../../../react/common/Loader';
import ModalIcon from '../../../react/common/ModalIcon';
import VariablesOverridePanel from '../../components/react/components/generic/variables/VariablesOverridePanel';
import { JOB_FAILED_STATUSES } from '../../queue/constants';
import TaskIcon from './TaskIcon';
import TaskName from './TaskName';

const isSelectable = (task: Map<string, any>) => {
  return !!task.get('configId') && !task.get('invalid') && !task.get('hasDeletedConfiguration');
};

const RunSelectedModal = ({
  allConfigurations,
  phases,
  status,
  hasProtectedDefaultBranch,
  isRetry,
  show,
  onHide,
  onRun
}: {
  allConfigurations: Map<string, any>;
  phases: List<any>;
  status?: Map<string, any>;
  hasProtectedDefaultBranch: boolean;
  isRetry: boolean;
  show: boolean;
  onHide: () => void;
  onRun: (selected: SelectedTasks, variablesOverride: Map<string, any>) => Promise<void>;
}) => {
  const [isRunning, setIsRunning] = useState(false);
  const [selected, setSelected] = useState<SelectedTasks>({});
  const [variablesOverride, setVariablesOverride] = useState<Map<string, any>>(Map());
  const isAllSelected = phases.every((phase) =>
    phase.get('tasks').every((task: Map<string, any>) => selected[task.get('id')] ?? false)
  );
  const isAnySelected = phases.some((phase) =>
    phase.get('tasks').some((task: Map<string, any>) => selected[task.get('id')] ?? false)
  );

  const run = () => {
    setIsRunning(true);
    onRun(selected, variablesOverride).finally(() => {
      setIsRunning(false);
      onHide();
    });
  };

  const preselectFailedTasks = () => {
    if (!status) {
      return;
    }

    const selectedTasks = {} as SelectedTasks;
    phases.forEach((phase) =>
      phase.get('tasks').forEach((task: Map<string, any>) => {
        const taskStatus = status.getIn(
          [phase.get('id'), task.get('id'), 'status'],
          status.getIn([task.get('id').toString(), 'status'])
        );
        selectedTasks[task.get('id') as string] =
          JOB_FAILED_STATUSES.includes(taskStatus) || !taskStatus;
      })
    );
    setSelected(selectedTasks);
  };

  const getVariableOverrideHanlder = () => {
    if (hasProtectedDefaultBranch) {
      return;
    }

    return (phaseId: string, taskId: string, variables: Map<string, any>) => {
      setVariablesOverride(variablesOverride.setIn([phaseId, taskId], variables));
    };
  };

  const body = (
    <div className="retry-table">
      <div className="header-row">
        <div className="checkbox-cell">
          <Checkbox
            tooltip={isAllSelected || isAnySelected ? 'Deselect all' : 'Select all'}
            disabled={isRunning}
            checked={isAllSelected}
            indeterminate={!isAllSelected && isAnySelected}
            onChange={(checked) => {
              if (checked) {
                const selectedTasks = {} as SelectedTasks;
                phases.forEach((phase) =>
                  phase
                    .get('tasks')
                    .filter(isSelectable)
                    .forEach((task: Map<string, any>) => {
                      selectedTasks[task.get('id') as string] = true;
                    })
                );
                setSelected(selectedTasks);
              } else {
                setSelected({});
              }
            }}
          />
        </div>
        <div>Name</div>
        <div className="text-right">Last Run</div>
      </div>
      {phases
        .filter((phase) => !phase.get('tasks').isEmpty())
        .map((phase) => (
          <PhaseRows
            key={phase.get('id')}
            allConfigurations={allConfigurations}
            phase={phase}
            status={status}
            setSelected={setSelected}
            onVariablesOverrideChange={getVariableOverrideHanlder()}
            selected={selected}
            isRunning={isRunning}
          />
        ))
        .toArray()}
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      onEnter={() => {
        setVariablesOverride(Map());

        if (isRetry) {
          preselectFailedTasks();
        }
      }}
      className="retry-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isRetry ? 'Re-run failed tasks' : 'Select tasks you would like to run'}
        </Modal.Title>
        <ModalIcon icon="rotate" color="blue" bold />
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-confirm"
          bsStyle="primary"
          disabled={
            isRunning ||
            Object.keys(selected).length === 0 ||
            Object.values(selected).every((v) => !v)
          }
          onClick={run}
          block
        >
          {isRunning ? (
            <Loader className="icon-addon-right" />
          ) : (
            <FontAwesomeIcon
              icon={isRetry ? 'rotate' : 'circle-play'}
              className="icon-addon-right"
            />
          )}
          {isRetry ? 'Retry' : 'Run Selected Tasks'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

type SelectedTasks = { [taskId: string]: boolean };

const PhaseRows = ({
  allConfigurations,
  phase,
  status,
  selected,
  setSelected,
  onVariablesOverrideChange,
  isRunning
}: {
  allConfigurations: Map<string, any>;
  phase: Map<string, any>;
  status?: Map<string, any>;
  selected: SelectedTasks;
  setSelected: (selected: SelectedTasks) => void;
  onVariablesOverrideChange?: (
    phaseId: string,
    taskId: string,
    variablesOverride: Map<string, any>
  ) => void;
  isRunning: boolean;
}) => {
  const selectable = phase.get('tasks').filter(isSelectable);
  const isAllSelected =
    selectable.count() > 0 &&
    selectable.every((task: Map<string, any>) => !!selected[task.get('id')]);
  const isAnySelected =
    selectable.count() > 0 &&
    selectable.some((task: Map<string, any>) => !!selected[task.get('id')]);

  const togglePhase = (checked: boolean) => {
    const phaseSelected = {} as SelectedTasks;
    selectable.forEach((task: Map<string, any>) => {
      phaseSelected[task.get('id') as string] = checked;
    });
    setSelected({ ...selected, ...phaseSelected });
  };

  return (
    <React.Fragment>
      <div
        className={classNames('phase-row', { clickable: selectable.count() > 0 })}
        onClick={() => togglePhase(!isAllSelected && !isAnySelected)}
      >
        <div className="checkbox-cell">
          <Checkbox
            tooltip={`${isAllSelected || isAnySelected ? 'Deselect' : 'Select'} phase`}
            disabled={selectable.isEmpty() || isRunning}
            checked={isAllSelected}
            indeterminate={!isAllSelected && isAnySelected}
            onChange={togglePhase}
          />
        </div>
        <div className="label-row">
          <div className="label text-muted">{phase.get('name')}</div>
        </div>
      </div>
      {phase
        .get('tasks')
        .map((task: Map<string, any>) => (
          <TaskRow
            key={task.get('id')}
            task={task}
            phase={phase}
            status={status?.getIn(
              [phase.get('id'), task.get('id'), 'status'],
              status.getIn([task.get('id').toString(), 'status'])
            )}
            selected={selected[task.get('id')] ?? false}
            onSelect={(id) => setSelected({ ...selected, [id]: !selected[id] })}
            onVariablesOverrideChange={onVariablesOverrideChange}
            allConfigurations={allConfigurations}
            isRunning={isRunning}
          />
        ))
        .toArray()}
    </React.Fragment>
  );
};

const TaskRow = ({
  allConfigurations,
  task,
  phase,
  status,
  selected,
  onSelect,
  onVariablesOverrideChange,
  isRunning
}: {
  allConfigurations: Map<string, any>;
  task: Map<string, any>;
  phase: Map<string, any>;
  status?: string;
  selected: boolean;
  onSelect: (taskId: string) => void;
  onVariablesOverrideChange?: (
    phaseId: string,
    taskId: string,
    variablesOverride: Map<string, any>
  ) => void;
  isRunning: boolean;
}) => {
  const selectable = isSelectable(task);
  const toggleTask = () => selectable && onSelect(task.get('id'));

  return (
    <div className={classNames('task-row', { clickable: selectable })} onClick={toggleTask}>
      <div className="checkbox-cell">
        <Checkbox disabled={!selectable || isRunning} checked={selected} onChange={toggleTask} />
      </div>
      <div className="task-info">
        <div className="flex-container flex-start">
          <TaskIcon src={task.get('iconUrl')} />
          <TaskName
            isDragged={false}
            name={task.get('name')}
            componentName={task.get('component')}
            componentType={task.get('type')}
            isBlank={!task.get('configId')}
            isDeleted={task.get('hasDeletedConfiguration')}
            className="icon-addon-left"
          />
        </div>
      </div>
      <div className="text-right">
        {selectable &&
          (status ? <JobStatusIcon status={status} /> : <span className="text-muted">N/A</span>)}
      </div>
      {onVariablesOverrideChange && (
        <VariablesOverridePanel
          allConfigurations={allConfigurations}
          componentId={task.get('componentId')}
          configId={task.get('configId')}
          variableValuesId={task.get('variableValuesId')}
          variableValuesData={task.get('variableValuesData')}
          onChangeFn={(varibles: Map<string, any>) => {
            return onVariablesOverrideChange(phase.get('id'), task.get('id'), varibles);
          }}
          isDisabled={!selected}
        />
      )}
    </div>
  );
};

export default RunSelectedModal;
