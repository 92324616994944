import React from 'react';
import PropTypes from 'prop-types';
import { Image, Label } from 'react-bootstrap';
import classnames from 'classnames';

import { backendImages } from '../../../../constants/backendImages';
import { transformationLabels } from '../../Constants';
import { isKnownTransformationType, resolveBackendName } from '../../utils/transformationTypes';

class TransformationType extends React.Component {
  render() {
    if (!this.props.transformation || !isKnownTransformationType(this.props.transformation)) {
      return null;
    }

    const backendName = resolveBackendName(this.props.transformation);

    return (
      <>
        <Image
          src={backendImages[backendName]}
          width={this.props.imageSize}
          alt={transformationLabels[backendName]}
          title={transformationLabels[backendName]}
          className={classnames(this.props.imageClass)}
        />
        {this.props.showLabel && (
          <Label className="label-backend">{transformationLabels[backendName]}</Label>
        )}
      </>
    );
  }
}

TransformationType.propTypes = {
  transformation: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
  imageClass: PropTypes.string,
  imageSize: PropTypes.string
};

TransformationType.defaultProps = {
  showLabel: true,
  imageSize: '19'
};

export default TransformationType;
