import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

import Checkbox from '../../../react/common/Checkbox';

class DownloadSettings extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Download settings</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <Col xs={4} componentClass={ControlLabel}>
              Path
            </Col>
            <Col xs={8}>
              <FormControl
                type="text"
                value={this.props.path}
                onChange={(e) => {
                  this.props.onChange({ path: e.target.value });
                }}
                placeholder="folder/*.csv"
                disabled={this.props.disabled}
              />
              <HelpBlock>
                <span>
                  Exact path to a file or glob syntax. Use an absolute path for FTP(s) connections
                  and a relative path for SFTP connections.
                  <ul>
                    <li>
                      <code>{'**/*.csv'}</code> will download all CSV files in all subdirectories
                    </li>
                    <li>
                      <code>{'files/*.csv'}</code> will download all CSV files in files/ directory
                    </li>
                    <li>
                      <code>{'files/directory/file.txt'}</code> will download a particular text file
                    </li>
                  </ul>
                </span>
              </HelpBlock>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col xs={8} xsOffset={4}>
              <Checkbox
                checked={this.props.onlyNewFiles}
                onChange={(checked) => this.props.onChange({ onlyNewFiles: checked })}
                disabled={this.props.disabled}
              >
                Only New Files
              </Checkbox>
              <HelpBlock>
                Every job stores the timestamp of the last downloaded file and a subsequent job can
                pick up from there.
              </HelpBlock>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col xs={8} xsOffset={4}>
              <Checkbox
                checked={this.props.decompress}
                onChange={(checked) => this.props.onChange({ decompress: checked })}
                disabled={this.props.disabled}
              >
                Decompress
              </Checkbox>
              <HelpBlock>
                Decompress downloaded file(s). All files in all archives will be imported into a
                single Storage table.
              </HelpBlock>
            </Col>
          </FormGroup>
        </div>
      </div>
    );
  }
}

DownloadSettings.propTypes = {
  decompress: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onlyNewFiles: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired
};

export default DownloadSettings;
