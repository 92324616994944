import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { FEATURE_SNOWFLAKE_PARTNER_CONNECT } from '../../../constants/features';
import { APP_ROUTE } from '../../../constants/routeNames';
import BillingStore from '../../../modules/billing/store';
import PlatformNews from '../../../modules/components/react/components/PlatformNews';
import InstalledComponentsStore from '../../../modules/components/stores/InstalledComponentsStore';
import { allowedTypes } from '../../../modules/components-directory/constants';
import DevBranchesStore from '../../../modules/dev-branches/DevBranchesStore';
import { FEATURE_TYPES } from '../../../modules/settings/constants';
import SettingsStore from '../../../modules/settings/SettingsStore';
import ApplicationStore from '../../../stores/ApplicationStore';
import Link from '../../common/RouterLink';
import Tooltip from '../../common/Tooltip';
import useStores from '../../hooks/useStores';
import BranchSelector from './BranchSelector';
import CurrentUser from './CurrentUser';
import Logo from './Logo';
import Navigation from './Navigation';
import ProjectSelector from './ProjectSelector';

const GlobalSearch = React.lazy(
  () => import(/* webpackChunkName: "global-search" */ '../../layout/search/GlobalSearch')
);

type Props = {
  user: Map<string, any>;
  backFlow: Map<string, any>;
  urlTemplates: Map<string, any>;
  currentProject: Map<string, any>;
  projectTemplates: List<any>;
  organizations: List<any>;
  xsrf: string;
  canManageApps: boolean;
  isCollapsed: boolean;
  isDemoPreview: boolean;
  toggleSidemenu: () => void;
};

const Sidemenu = (props: Props) => {
  const state = useStores(
    () => {
      return {
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        isMarketplaceProject: BillingStore.isMarketplaceProject(),
        hasNewTransformationsOnly: ApplicationStore.hasNewTransformationsOnly(),
        hasShowTransformationMigration: ApplicationStore.hasShowTransformationMigration(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        hasFlows: ApplicationStore.hasFlows(),
        hasFlowsOnly: ApplicationStore.hasFlowsOnly(),
        hasTemplates: ApplicationStore.hasTemplates(),
        devBranches: DevBranchesStore.getAll() as Map<string, any>,
        currentDevBranchId: DevBranchesStore.getCurrentId() as number | null,
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        readOnly: ApplicationStore.isReadOnly(),
        sapiToken: ApplicationStore.getSapiToken(),
        projectBaseUrl: ApplicationStore.getProjectBaseUrl(),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        availableFeatures: SettingsStore.getFeatures(FEATURE_TYPES.ADMIN),
        activatedFeatures: ApplicationStore.getCurrentAdmin().get('features', List()),
        hasConfigurations: !InstalledComponentsStore.getAll()
          .filter((component: Map<string, any>) => allowedTypes.includes(component.get('type')))
          .isEmpty(),
        hasSnowflakePartnerConnect: ApplicationStore.hasCurrentProjectFeature(
          FEATURE_SNOWFLAKE_PARTNER_CONNECT
        )
      };
    },
    [],
    [ApplicationStore, SettingsStore, BillingStore, DevBranchesStore, InstalledComponentsStore]
  );

  return (
    <aside id="sidemenu">
      <div className="sidemenu-container">
        <div className="flex-container sidemenu-header">
          <Link to={APP_ROUTE} className="header-logo">
            <Logo />
          </Link>
          <span className="flex-container flex-end">
            {!props.isCollapsed && state.hasNewQueue && (
              <React.Suspense fallback={null}>
                <GlobalSearch />
              </React.Suspense>
            )}
            <Tooltip tooltip={`${props.isCollapsed ? 'Open' : 'Close'} Sidemenu`} placement="right">
              <Button
                id="sidemenu-collapse-button"
                tabIndex={0}
                bsStyle="link"
                className="btn-link-inline btn-link-muted btn-header"
                onClick={() => {
                  props.toggleSidemenu();
                  (document.activeElement as HTMLElement)?.blur();
                }}
              >
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {props.isCollapsed ? (
                    <>
                      <path d="M6 0H0V2H6V0Z" />
                      <path d="M6 12H0V14H6V12Z" />
                      <path d="M13.636 6H0V8H13.636V6Z" />
                      <path d="M9.63604 0.6L8.22183 2.01421L14.5858 8.37817L16 6.96396L9.63604 0.6Z" />
                      <path d="M16 6.96396L14.5858 5.54975L8.22183 11.9137L9.63604 13.3279L16 6.96396Z" />
                    </>
                  ) : (
                    <>
                      <path d="M10 0H16V2H10V0Z" />
                      <path d="M10 12H16V14H10V12Z" />
                      <path d="M2.36396 6H16V8H2.36396V6Z" />
                      <path d="M6.36396 0.6L7.77817 2.01421L1.41421 8.37817L0 6.96396L6.36396 0.6Z" />
                      <path d="M0 6.96396L1.41421 5.54975L7.77817 11.9137L6.36396 13.3279L0 6.96396Z" />
                    </>
                  )}
                </svg>
              </Button>
            </Tooltip>
          </span>
        </div>
        <ProjectSelector
          isCollapsed={props.isCollapsed}
          organizations={props.organizations}
          currentProject={props.currentProject}
          urlTemplates={props.urlTemplates}
          isDemoPreview={props.isDemoPreview}
        />
        <BranchSelector
          xsrf={props.xsrf}
          readOnly={state.readOnly}
          isCollapsed={props.isCollapsed}
          isDevModeActive={state.isDevModeActive}
          projectBaseUrl={state.projectBaseUrl}
          organizations={props.organizations}
          currentProject={props.currentProject}
          devBranches={state.devBranches}
          sapiToken={state.sapiToken}
          currentDevBranchId={state.currentDevBranchId}
          isDemoPreview={props.isDemoPreview}
        />
        <hr />
        <Navigation
          xsrf={props.xsrf}
          showNewTransformations={
            state.hasNewTransformationsOnly || state.hasShowTransformationMigration
          }
          hideOldTransformations={state.hasNewTransformationsOnly}
          hasSnowflakePartnerConnect={state.hasSnowflakePartnerConnect}
          isDemoPreview={props.isDemoPreview}
          hasNewQueue={state.hasNewQueue}
          readOnly={state.readOnly}
          hasPayAsYouGo={state.hasPayAsYouGo}
          isDevModeActive={state.isDevModeActive}
          hasConfigurations={state.hasConfigurations}
          hasFlowsOnly={state.hasFlowsOnly}
          hasFlows={state.hasFlows}
          hasTemplates={state.hasTemplates}
          sapiToken={state.sapiToken}
          isCollapsed={props.isCollapsed}
        />
        <div className="bottom">
          <hr />
          {!(state.hasProtectedDefaultBranch && !state.isDevModeActive) &&
            (props.isCollapsed ? (
              <Tooltip tooltip="Recently Deleted" placement="right" delay="fast">
                <span>
                  <Link to="settings-trash" className="nav-link">
                    <FontAwesomeIcon icon={['far', 'trash']} fixedWidth />
                  </Link>
                </span>
              </Tooltip>
            ) : (
              <Link to="settings-trash" className="nav-link light">
                Recently Deleted
              </Link>
            ))}
          <PlatformNews isCollapsed={props.isCollapsed} />
          <CurrentUser
            user={props.user}
            isCollapsed={props.isCollapsed}
            urlTemplates={props.urlTemplates}
            canManageApps={props.canManageApps}
            isDevModeActive={state.isDevModeActive}
            hasPayAsYouGo={state.hasPayAsYouGo}
            isMarketplaceProject={state.isMarketplaceProject}
            availableFeatures={state.availableFeatures}
            activatedFeatures={state.activatedFeatures}
          />
        </div>
      </div>
    </aside>
  );
};

export default Sidemenu;
