import { fromJS, List, Map } from 'immutable';

import { TRANSFORMATION } from '../../../constants/componentIds';
import dispatcher from '../../../Dispatcher';
import StoreUtils, { initStore } from '../../../utils/StoreUtils';
import * as TransformationsConstants from '../../transformations/Constants';
import * as ComponentsConstatns from '../Constants';
import Constants from '../VersionsConstants';

let _store = initStore(
  'VersionsStore',
  Map({
    loadingVersions: Map(),
    versions: Map(),
    versionsConfigs: Map(),
    pending: Map(),
    multiLoadPending: Map()
  })
);

const VersionsStore = StoreUtils.createStore({
  hasVersions: function (componentId, configId) {
    return _store.hasIn(['versions', componentId, configId]);
  },

  hasVersion: function (componentId, configId, versionId) {
    return _store.hasIn(['versions', componentId, configId, versionId]);
  },

  hasConfigByVersion: function (componentId, configId, versionId) {
    return _store.hasIn(['versionsConfigs', componentId, configId, versionId]);
  },

  isLoadingVersions: function (componentId, configId) {
    return _store.getIn(['loadingVersions', componentId, configId], false);
  },

  getVersions: function (componentId, configId) {
    return _store.getIn(['versions', componentId, configId], List());
  },

  getVersionsConfigs: function (componentId, configId) {
    return _store.getIn(['versionsConfigs', componentId, configId], Map());
  },

  getConfigByVersion: function (componentId, configId, versionId) {
    return _store.getIn(['versionsConfigs', componentId, configId, versionId], Map());
  },

  getVersion: function (componentId, configId, versionId) {
    return _store.getIn(['versions', componentId, configId, versionId], Map());
  },

  isPendingConfig: function (componentId, configId) {
    return (
      this.getPendingVersions(componentId, configId)
        .filter((version) => version.count() > 0)
        .count() > 0
    );
  },

  getPendingVersions: function (componentId, configId) {
    return _store.getIn(['pending', componentId, configId], Map());
  },

  getPendingMultiLoad(componentId, configId) {
    return _store.getIn(['multiLoadPending', componentId, configId], Map());
  }
});

dispatcher.register(({ action }) => {
  switch (action.type) {
    case Constants.ActionTypes.VERSIONS_LOAD_START:
      _store = _store.setIn(['loadingVersions', action.componentId, action.configId], true);
      return VersionsStore.emitChange();

    case Constants.ActionTypes.VERSIONS_LOAD_SUCCESS:
      _store = _store.setIn(
        ['versions', action.componentId, action.configId],
        fromJS(action.versions)
      );
      _store = _store.setIn(['rollbackVersions', action.componentId, action.configId], false);
      _store = _store.setIn(['loadingVersions', action.componentId, action.configId], false);
      return VersionsStore.emitChange();

    case Constants.ActionTypes.VERSIONS_LOAD_ERROR:
      _store = _store.setIn(['loadingVersions', action.componentId, action.configId], false);
      return VersionsStore.emitChange();

    case Constants.ActionTypes.VERSIONS_CONFIG_LOAD_SUCCESS:
      _store = _store.setIn(
        ['versionsConfigs', action.componentId, action.configId, action.version],
        fromJS(action.data)
      );
      return VersionsStore.emitChange();

    case Constants.ActionTypes.VERSIONS_PENDING_START:
      _store = _store.setIn(
        ['pending', action.componentId, action.configId, action.version, action.action],
        true
      );
      return VersionsStore.emitChange();

    case Constants.ActionTypes.VERSIONS_PENDING_STOP:
      _store = _store.deleteIn([
        'pending',
        action.componentId,
        action.configId,
        action.version,
        action.action
      ]);
      return VersionsStore.emitChange();

    case Constants.ActionTypes.VERSIONS_MULTI_PENDING_START:
      _store = _store.setIn(
        ['multiLoadPending', action.componentId, action.configId, action.pivotVersion],
        true
      );
      return VersionsStore.emitChange();

    case Constants.ActionTypes.VERSIONS_MULTI_PENDING_STOP:
      _store = _store.deleteIn([
        'multiLoadPending',
        action.componentId,
        action.configId,
        action.pivotVersion
      ]);
      return VersionsStore.emitChange();

    case ComponentsConstatns.ActionTypes.INSTALLED_COMPONENTS_DELETE_CONFIGURATION_SUCCESS:
      _store = _store.deleteIn(['versions', action.componentId, action.configurationId]);
      return VersionsStore.emitChange();

    case TransformationsConstants.ActionTypes.TRANSFORMATION_BUCKET_DELETE_SUCCESS:
      _store = _store.deleteIn(['versions', TRANSFORMATION, action.bucketId]);
      return VersionsStore.emitChange();

    case Constants.ActionTypes.VERSIONS_RELOAD_SUCCESS:
      _store = _store.setIn(['versions', action.componentId, action.configId], action.versions);
      return VersionsStore.emitChange();

    default:
  }
});

export default VersionsStore;
