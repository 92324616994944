import React from 'react';
import createReactClass from 'create-react-class';

import EditButtons from '../../../react/common/EditButtons';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import InstalledComponentsStore from '../../components/stores/InstalledComponentsStore';
import * as actions from '../actions';

const componentId = 'geneea.nlp-analysis-v2';

const HeaderButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, RoutesStore, InstalledComponentsStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const localState = InstalledComponentsStore.getLocalState(componentId, configId);

    return {
      localState: localState,
      editing: localState.get('editing'),
      configId: configId,
      isSaving: InstalledComponentsStore.getSavingConfigData(componentId, configId),
      readOnly: ApplicationStore.isReadOnly()
    };
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <EditButtons
        editLabel="Set Up"
        isEditing={!!this.state.editing}
        isSaving={!!this.state.isSaving}
        isDisabled={!actions.isValid(this.state.configId)}
        onCancel={() => actions.cancel(this.state.configId)}
        onSave={() => actions.save(this.state.configId)}
        onEditStart={() => actions.startEditing(this.state.configId)}
      />
    );
  }
});

export default HeaderButtons;
