import {
  KDS_TEAM_EX_NETSUITE,
  KDS_TEAM_EX_PROGRESS_DB,
  KEBOOLA_EX_DB_DB_2,
  KEBOOLA_EX_DB_FIREBIRD,
  KEBOOLA_EX_DB_HIVE,
  KEBOOLA_EX_DB_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE_CSAS_TEST,
  KEBOOLA_EX_DB_IMPALA,
  KEBOOLA_EX_DB_INFORMIX,
  KEBOOLA_EX_DB_MSSQL,
  KEBOOLA_EX_DB_MSSQL_CDATA,
  KEBOOLA_EX_DB_MYSQL,
  KEBOOLA_EX_DB_NETSUITE,
  KEBOOLA_EX_DB_ORACLE,
  KEBOOLA_EX_DB_PGSQL,
  KEBOOLA_EX_DB_REDSHIFT,
  KEBOOLA_EX_DB_SNOWFLAKE,
  KEBOOLA_EX_TERADATA,
  KEBOOLA_ORACLE_TRANSFORMATION
} from '../../constants/componentIds';
import { FEATURE_IS_SINGLE_TENANT } from '../../constants/features';
import StackFeaturesStore from '../stack-features/Store';

const supportSplitLoading = (componentId: string): boolean => {
  return [
    KEBOOLA_EX_DB_SNOWFLAKE,
    KEBOOLA_EX_DB_MYSQL,
    KEBOOLA_EX_DB_MSSQL,
    KEBOOLA_EX_DB_PGSQL,
    KEBOOLA_EX_DB_ORACLE,
    KEBOOLA_EX_DB_REDSHIFT,
    KEBOOLA_EX_DB_NETSUITE,
    KEBOOLA_EX_DB_INFORMIX,
    KEBOOLA_EX_DB_HIVE,
    KEBOOLA_EX_DB_HIVE_CSAS,
    KEBOOLA_EX_DB_HIVE_CSAS_TEST,
    KDS_TEAM_EX_NETSUITE,
    KDS_TEAM_EX_PROGRESS_DB
  ].includes(componentId);
};

const supportSimpleSetup = (componentId: string): boolean => {
  return [
    KEBOOLA_EX_DB_MYSQL,
    KEBOOLA_EX_DB_REDSHIFT,
    KEBOOLA_EX_DB_SNOWFLAKE,
    KEBOOLA_EX_DB_NETSUITE,
    KEBOOLA_EX_DB_MSSQL,
    KEBOOLA_EX_DB_MSSQL_CDATA,
    KEBOOLA_EX_DB_ORACLE,
    KEBOOLA_EX_DB_DB_2,
    KEBOOLA_EX_DB_PGSQL,
    KEBOOLA_EX_TERADATA,
    KEBOOLA_EX_DB_INFORMIX,
    KDS_TEAM_EX_NETSUITE,
    KDS_TEAM_EX_PROGRESS_DB,
    KEBOOLA_EX_DB_HIVE
  ].includes(componentId);
};

const supportConfigRows = (componentId: string): boolean => {
  return [
    KEBOOLA_EX_DB_FIREBIRD,
    KEBOOLA_EX_DB_MYSQL,
    KEBOOLA_EX_DB_MSSQL,
    KEBOOLA_EX_DB_MSSQL_CDATA,
    KEBOOLA_EX_DB_ORACLE,
    KEBOOLA_EX_DB_PGSQL,
    KEBOOLA_EX_DB_SNOWFLAKE,
    KEBOOLA_EX_DB_NETSUITE,
    KEBOOLA_EX_TERADATA,
    KEBOOLA_EX_DB_REDSHIFT,
    KEBOOLA_EX_DB_INFORMIX,
    KEBOOLA_EX_DB_IMPALA,
    KEBOOLA_EX_DB_HIVE,
    KEBOOLA_EX_DB_HIVE_CSAS,
    KEBOOLA_EX_DB_HIVE_CSAS_TEST,
    KDS_TEAM_EX_NETSUITE,
    KDS_TEAM_EX_PROGRESS_DB,
    KEBOOLA_EX_DB_DB_2
  ].includes(componentId);
};

const supportConvertColumnCase = (componentId: string): boolean => {
  return (
    StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT) &&
    [KEBOOLA_EX_DB_MSSQL, KEBOOLA_EX_DB_HIVE_CSAS, KEBOOLA_EX_DB_HIVE_CSAS_TEST].includes(
      componentId
    )
  );
};

const hasSshTunnel = (componentId: string): boolean => {
  return ![
    KEBOOLA_EX_DB_SNOWFLAKE,
    KEBOOLA_EX_TERADATA,
    KEBOOLA_EX_DB_NETSUITE,
    KDS_TEAM_EX_NETSUITE,
    KDS_TEAM_EX_PROGRESS_DB,
    KEBOOLA_ORACLE_TRANSFORMATION
  ].includes(componentId);
};

const hasSsl = (componentId: string): boolean => {
  return [KEBOOLA_EX_DB_PGSQL, KEBOOLA_EX_DB_MYSQL, KEBOOLA_EX_DB_MSSQL].includes(componentId);
};

const hasCdc = (componentId: string): boolean => {
  return [KEBOOLA_EX_DB_MSSQL].includes(componentId);
};

const hasInitQueries = (componentId: string): boolean => {
  return [KEBOOLA_EX_DB_ORACLE, KEBOOLA_EX_DB_MYSQL].includes(componentId);
};

export {
  supportSplitLoading,
  supportSimpleSetup,
  supportConfigRows,
  supportConvertColumnCase,
  hasSshTunnel,
  hasSsl,
  hasCdc,
  hasInitQueries
};
