import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import Checkbox from '../../../../react/common/Checkbox';
import ComponentIcon from '../../../../react/common/ComponentIcon';
import ComponentName from '../../../../react/common/ComponentName';
import Popover from '../../../../react/common/Popover';
import Tree from '../../../../react/common/Tree';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';

const TaskSelectTableRow = createReactClass({
  propTypes: {
    task: PropTypes.object.isRequired,
    component: PropTypes.object,
    onTaskUpdate: PropTypes.func.isRequired
  },

  render() {
    return (
      <tr onClick={this._handleActiveChange} className="clickable">
        <td>
          <span>
            {this.props.component && (
              <ComponentIcon
                component={this.props.component}
                className="icon-addon-right"
                size="32"
              />
            )}
            {this.props.component ? (
              <ComponentName component={this.props.component} />
            ) : (
              this.props.task.get('componentUrl')
            )}
          </span>
        </td>
        <td>
          <span className="label label-primary">{this.props.task.get('action')}</span>
        </td>
        <td className="overflow-break-anywhere">{this._renderConfiguration()}</td>
        <td>
          <Checkbox checked={this.props.task.get('active')} onChange={this._handleActiveChange} />
        </td>
      </tr>
    );
  },

  _renderConfiguration() {
    const parameters = this.props.task.get('actionParameters');

    if (parameters.count() === 1 && parameters.has('config') && this.props.component) {
      const config = InstalledComponentsStore.getConfig(
        this.props.component.get('id'),
        parameters.get('config').toString()
      );
      return config.get('name', parameters.get('config'));
    }

    return (
      <Popover
        title="Parameters"
        placement="bottom"
        popover={<Tree data={this.props.task.get('actionParameters')} />}
      >
        <Button
          bsStyle="link"
          onClick={(event) => {
            event.stopPropagation();
          }}
          className="btn-link-inline"
        >
          Show parameters
        </Button>
      </Popover>
    );
  },

  _handleActiveChange() {
    return this.props.onTaskUpdate(this.props.task.set('active', !this.props.task.get('active')));
  }
});

export default TaskSelectTableRow;
