import React from 'react';
import classnames from 'classnames';
import type { Map } from 'immutable';

import { resolveRouterLinkParams } from '../../../modules/components/helpers';
import RoutesStore from '../../../stores/RoutesStore';
import hasSelections from '../../../utils/hasSelections';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../../utils/windowOpen';
import { getRealComponentId, onTableRowKeyDown } from './helpers';
import type { TableCell, TableRow } from './Table';

const ConfigurationRow = (props: {
  row: TableRow;
  customClasses: Map<string, any>;
  readOnly: boolean;
  hasFlows: boolean;
}) => {
  const componentId = getRealComponentId(
    props.row.values.data.config,
    props.row.values.data.component
  );
  const configId = props.row.values.data.config.get('id');
  const isCreating = props.row.values.data.config.get('isCreating');
  const isDummyItem = props.row.values.data.config.get('isDummyItem');
  let actionProps = {};

  if (!isCreating && !isDummyItem) {
    const linkParams = resolveRouterLinkParams(componentId, configId, null, props.hasFlows);
    const rowAction = (e?: React.MouseEvent | React.KeyboardEvent) => {
      if (!linkParams || hasSelections()) {
        return;
      }

      if (shouldUseNewWindow(e)) {
        return windowOpen(RoutesStore.getRouter().createHref(linkParams.to, linkParams.params));
      }

      return RoutesStore.getRouter().transitionTo(linkParams.to, linkParams.params);
    };

    actionProps = {
      onClick: rowAction,
      onMouseDown: simulateClickIfMiddleMouseIsUsed.mousedown,
      onMouseUp: simulateClickIfMiddleMouseIsUsed.mouseup,
      onKeyDown: onTableRowKeyDown(rowAction)
    };
  }

  const userRowProps = {
    className: classnames('tr hoverable-actions-with-replacement', {
      clickable: !isCreating && !isDummyItem
    }),
    ...(!isCreating && !isDummyItem && { tabIndex: '0', role: 'button', ...actionProps })
  };

  return (
    <div {...props.row.getRowProps(userRowProps)}>
      {props.row.cells.map((cell: TableCell, index: number) => {
        const userCellProps = {
          className: classnames('td', props.customClasses.getIn([cell.column.id, 'td']), {
            'w-125 text-right': cell.column.id === 'used_in',
            'w-125 text-right text-muted': cell.column.id === 'storage_data',
            'w-175 text-right': cell.column.id === 'last_change',
            'w-200 text-right': cell.column.id === 'job',
            'bg-selected': props.row.isSelected
          })
        };

        return (
          <div key={`${cell.column.id}-${index}`} {...cell.getCellProps(userCellProps)}>
            {cell.render('Cell')}
          </div>
        );
      })}
    </div>
  );
};

export default ConfigurationRow;
