import React, { useState } from 'react';
import type { List, Map } from 'immutable';

import type { Instance } from '../../api/routes/templatesService';
import FilterPanel from '../../react/common/FilterPanel';
import NoResultsFound from '../../react/common/NoResultsFound';
import useStores from '../../react/hooks/useStores';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import ComponentsStore from '../components/stores/ComponentsStore';
import StorageTokensStore from '../tokens/StorageTokensStore';
import InstancesTableWithTemplateBox from './components/InstancesTable';
import { getFilteredInstances } from './helpers';
import TemplatesStore from './store';

const InstancesIndex = () => {
  const [filterQuery, setFilterQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], '')
  );

  const { templates, instances, allComponents, admins, tokens, readOnly } = useStores(
    () => {
      const { templates, instances } = TemplatesStore.getStore();

      return {
        templates,
        instances,
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        tokens: StorageTokensStore.getAll() as List<any>,
        admins: ApplicationStore.getAdmins(),
        readOnly: ApplicationStore.isReadOnly()
      };
    },
    [],
    [TemplatesStore, ApplicationStore, StorageTokensStore, ComponentsStore]
  );

  if (!instances?.length) return null;

  const filteredInstances = getFilteredInstances(instances, filterQuery, templates);
  const instancesByTemplate = filteredInstances.reduce<Record<string, Instance[]>>(
    (allInstances, instance) => ({
      ...allInstances,
      [instance.templateId]: [...(allInstances[instance.templateId] || []), instance]
    }),
    {}
  );

  const getPlaceholder = () => {
    const templatesCount = Object.keys(instancesByTemplate).filter((templateId) => {
      return templates.some((template) => template.id === templateId);
    }).length;

    return `Search templates (${templatesCount}) and template configurations (${instances.length})`;
  };

  return (
    <>
      <FilterPanel
        placeholder={getPlaceholder}
        query={filterQuery}
        onChange={(query: string) => {
          setFilterQuery(query);
          RoutesStore.getRouter().updateQuery({ q: query });
        }}
      />
      {filteredInstances.length ? (
        Object.keys(instancesByTemplate).map((templateId) => {
          const template = templates.find((template) => template.id === templateId);
          const instances = instancesByTemplate[templateId];

          if (!template) return null;

          return (
            <InstancesTableWithTemplateBox
              key={templateId}
              template={template}
              instances={instances}
              allComponents={allComponents}
              admins={admins}
              tokens={tokens}
              readOnly={readOnly}
              forceShowAll={!!filterQuery}
            />
          );
        })
      ) : (
        <NoResultsFound entityName="templates" />
      )}
    </>
  );
};

export default InstancesIndex;
