import {
  KDS_EX_DB360,
  KEBOOLA_EX_FACEBOOK,
  KEBOOLA_EX_FACEBOOK_ADS,
  KEBOOLA_EX_INSTAGRAM
} from '../../constants/componentIds';
import ApplicationStore from '../../stores/ApplicationStore';
import { Constants } from './Constants';

export const isGoogleService = (componentId) => {
  return (
    [KDS_EX_DB360].includes(componentId) ||
    ['google', 'gmail', 'gsuite', 'gcalendar'].some((key) => componentId.includes(key))
  );
};

export const isFacebookService = (componentId) =>
  [KEBOOLA_EX_FACEBOOK, KEBOOLA_EX_FACEBOOK_ADS, KEBOOLA_EX_INSTAGRAM].includes(componentId);

export const getReturnUrl = (credentialsId) => {
  const returnUrl = new URL(
    `${window.location.origin}${ApplicationStore.getProjectPageUrl(Constants.ROUTE_NAME)}`
  );
  returnUrl.searchParams.append('credentialsId', credentialsId);

  return returnUrl.toString();
};
