import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { KEBOOLA_DATABRICKS_TRANSFORMATION } from '../../constants/componentIds';
import { componentTypes } from '../../constants/componentTypes';
import CreateFolderButton from '../../react/common/ConfigurationsTable/CreateFolderButton';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import { getAllowedPatternComponents, getAllowedTransformations } from '../components/helpers';
import NewTransformationButton from '../components/react/components/NewTransformationButton';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import StackFeaturesStore from '../stack-features/Store';

const HeaderButtons = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      StackFeaturesStore,
      DevBranchesStore,
      ComponentsStore,
      InstalledComponentsStore
    )
  ],

  getStateFromStores() {
    const isDevModeActive = DevBranchesStore.isDevModeActive();

    return {
      isDevModeActive,
      readOnly: ApplicationStore.isReadOnly(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      allowedPatternComponents: getAllowedPatternComponents(
        ComponentsStore.getAllForType(componentTypes.CODE_PATTERN),
        isDevModeActive
      ),
      allConfigurations: InstalledComponentsStore.getAllForType(
        componentTypes.TRANSFORMATION
      ).flatMap((component) => {
        return component.get('configurations').map((config) => config.set('component', component));
      }),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      allowedTransformationComponents: getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll()
      ),
      availableDatabricksClusters: InstalledComponentsStore.getLocalState(
        KEBOOLA_DATABRICKS_TRANSFORMATION,
        null
      ).get('clusters')
    };
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <ButtonToolbar>
        <CreateFolderButton
          entity="transformation"
          configurations={this.state.allConfigurations}
          componentsMetadata={this.state.componentsMetadata}
        />
        <NewTransformationButton
          hasPayAsYouGo={this.state.hasPayAsYouGo}
          patternComponents={this.state.allowedPatternComponents}
          allowedComponents={this.state.allowedTransformationComponents}
          availableDatabricksClusters={this.state.availableDatabricksClusters}
          componentsMetadata={this.state.componentsMetadata}
          isDevModeActive={this.state.isDevModeActive}
        />
      </ButtonToolbar>
    );
  }
});

export default HeaderButtons;
