import { KEBOOLA_WR_LOOKER_V2 } from '../../constants/componentIds';
import dispatcher from '../../Dispatcher';
import SyncActionError from '../../utils/errors/SyncActionError';
import callDockerAction from '../components/DockerActionsApi';
import Constants from './constants';
import WritersApi from './WritersApi';

const TEST_LOOKER_CREDENTIALS_SYNC_ACTION = 'testLookerCredentials';

const registerToLooker = (credentialsId, token, host) => {
  dispatcher.handleViewAction({
    type: Constants.ActionTypes.LOOKER_WRITER_REGISTER_TO_LOOKER
  });

  const requestBody = {
    configData: {
      parameters: {
        looker: {
          credentialsId,
          '#token': token,
          host
        }
      }
    }
  };

  return callDockerAction(
    KEBOOLA_WR_LOOKER_V2,
    TEST_LOOKER_CREDENTIALS_SYNC_ACTION,
    requestBody
  ).then((result) => {
    if (result.status && result.status === 'error') {
      dispatcher.handleViewAction({
        type: Constants.ActionTypes.LOOKER_WRITER_REGISTER_TO_LOOKER_ERROR
      });
      throw new SyncActionError(
        result.message || 'There was an error while registering credentials'
      );
    }

    dispatcher.handleViewAction({
      type: Constants.ActionTypes.LOOKER_WRITER_REGISTER_TO_LOOKER_SUCCESS
    });

    return result;
  });
};

const saveCredentials = (configId, credentialsId, token, host, connectionName) => {
  const credentials = {
    credentialsId,
    '#token': token,
    host,
    ...(connectionName && { connectionName })
  };
  dispatcher.handleViewAction({
    type: Constants.ActionTypes.LOOKER_WRITER_SAVE_CREDENTIALS
  });
  return WritersApi(KEBOOLA_WR_LOOKER_V2)
    .postLookerWriterCredentials(configId, credentials)
    .then(() =>
      dispatcher.handleViewAction({
        type: Constants.ActionTypes.LOOKER_WRITER_SAVE_CREDENTIALS_SUCCESS,
        componentId: KEBOOLA_WR_LOOKER_V2,
        configId,
        lookerCredentials: credentials
      })
    )
    .catch((err) => {
      dispatcher.handleViewAction({
        type: Constants.ActionTypes.LOOKER_WRITER_SAVE_CREDENTIALS_ERROR
      });
      throw err;
    });
};

export { registerToLooker, saveCredentials };
