import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import { BadgeBeta } from '../../../../react/common/Badge';
import RowActionMenuItem from '../../../../react/common/RowActionMenuItem';
import { transformationType } from '../../Constants';
import CreateSandboxModal from '../modals/ConfigureSandbox';

const CreateSandboxButton = createReactClass({
  propTypes: {
    backend: PropTypes.string.isRequired,
    transformationType: PropTypes.string.isRequired,
    runParams: PropTypes.object.isRequired,
    mode: PropTypes.oneOf(['link', 'menuitem']),
    onKeyDown: PropTypes.func
  },

  getDefaultProps() {
    return {
      mode: 'link'
    };
  },

  getInitialState() {
    return {
      isModalOpen: false
    };
  },

  openModal(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      isModalOpen: true
    });
  },

  render() {
    if (this.props.mode === 'menuitem') {
      return (
        <>
          <RowActionMenuItem onSelect={this.openModal} onKeyDown={this.props.onKeyDown}>
            <FontAwesomeIcon icon="wrench" fixedWidth />
            Sandbox
            {this.betaWarning()}
          </RowActionMenuItem>
          {this.renderModal()}
        </>
      );
    }

    return (
      <>
        <a onClick={this.openModal}>
          <FontAwesomeIcon icon="wrench" fixedWidth />
          Sandbox
          {this.betaWarning()}
        </a>
        {this.renderModal()}
      </>
    );
  },

  betaWarning() {
    if (
      [transformationType.PYTHON, transformationType.JULIA, transformationType.R].includes(
        this.props.transformationType
      )
    ) {
      return <BadgeBeta className="tw-ml-2" />;
    }
  },

  renderModal() {
    return (
      <CreateSandboxModal
        defaultMode="prepare"
        show={this.state.isModalOpen}
        onHide={() => this.setState({ isModalOpen: false })}
        backend={this.props.backend}
        transformationType={this.props.transformationType}
        runParams={this.props.runParams}
      />
    );
  }
});

export default CreateSandboxButton;
