import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';
import { ltrim } from 'underscore.string';

import { KEBOOLA_SANDBOXES } from '../../constants/componentIds';
import { componentTypes } from '../../constants/componentTypes';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '../../constants/features';
import dayjs from '../../date';
import FilterPanel from '../../react/common/FilterPanel';
import Loader from '../../react/common/Loader';
import RowActionDropdown from '../../react/common/RowActionDropdown';
import RowActionMenuItem from '../../react/common/RowActionMenuItem';
import TabLink from '../../react/common/TabLink';
import TabNav from '../../react/common/TabNav';
import Tooltip from '../../react/common/Tooltip';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import { canExportTable, canPullTable, canWriteBucket } from '../admin/privileges';
import { getAllowedTransformations } from '../components/helpers';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import BucketsStore from '../components/stores/StorageBucketsStore';
import FilesStore from '../components/stores/StorageFilesStore';
import TablesStore from '../components/stores/StorageTablesStore';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import {
  filterDevBranchBuckets,
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionBuckets,
  isCreatedInDevBranch
} from '../dev-branches/helpers';
import SandboxesActions from '../sandboxes/Actions';
import AddSandboxModal, { FORM_STEPS } from '../sandboxes/components/AddSandboxModal';
import { prepareSandboxes, updateExistingWorkspace } from '../sandboxes/helpers';
import SandboxesStore from '../sandboxes/SandboxesStore';
import { factory as eventsFactory } from '../sapi-events/TableEventsService';
import StackFeaturesStore from '../stack-features/Store';
import CloneToCurrentBranch from './components/CloneToCurrentBranch';
import ColumnsPreview from './components/ColumnsPreview';
import CreateAliasTableAlternativeModal from './components/CreateAliasTableAlternativeModal';
import CreateSnapshotModal from './components/CreateSnapshotModal';
import DataSample from './components/DataSample';
import DataSampleColumnOrderInfo from './components/DataSampleColumnOrderInfo';
import DataSampleHelp from './components/DataSampleHelp';
import DeleteTableModal from './components/DeleteTableModal';
import TableEvents from './components/Events';
import ExportModal from './components/ExportModal';
import LoadTableFromCsvModal from './components/LoadTableFromCsvModal';
import SearchContextDropdown from './components/SearchContextDropdown';
import Snapshots from './components/Snapshots';
import TableGraph from './components/TableGraph';
import TableOverview from './components/TableOverview';
import TableSchema from './components/TableSchema';
import TableUsage from './components/TableUsage';
import TimeTravelModal from './components/TimeTravelModal';
import TruncateTableModal from './components/TruncateTableModal';
import {
  createAliasTable,
  createSnapshot,
  deleteTable,
  exportTables,
  loadSnapshots,
  loadTable,
  restoreUsingTimeTravel,
  toggleContextFilter,
  truncateTable,
  uploadFile
} from './actions';
import {
  backends,
  FILTERS,
  FILTERS_GROUP,
  routeNames,
  tableModalTabs,
  tableTabs
} from './constants';
import {
  getTableAliases,
  getTableLinks,
  prepareMappingFromSelectedBucketsAndTables
} from './helpers';
import StorageStore from './store';

const Table = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      StorageStore,
      ComponentsStore,
      InstalledComponentsStore,
      BucketsStore,
      TablesStore,
      FilesStore,
      SandboxesStore,
      StackFeaturesStore,
      DevBranchesStore
    )
  ],

  getStateFromStores() {
    const sapiToken = ApplicationStore.getSapiToken();
    const bucketId = RoutesStore.getCurrentRouteParam('bucketId');
    const tableName = RoutesStore.getCurrentRouteParam('tableName');
    const table = TablesStore.getTableByName(tableName, bucketId);
    const bucket = table.get('bucket', Map());
    const allBuckets = BucketsStore.getAll();
    const searchFilters = StorageStore.getSearchFilters();
    const contextFilter = StorageStore.getContextFilter();
    const isDevModeActive = DevBranchesStore.isDevModeActive();
    const availableBuckets = isDevModeActive
      ? filterProductionAndCurrentDevBranchBuckets(allBuckets)
      : contextFilter && searchFilters.get(FILTERS_GROUP.ENTITY) === FILTERS.DEV
      ? filterDevBranchBuckets(allBuckets)
      : filterProductionBuckets(allBuckets);
    const hasNewTransformationsOnly = ApplicationStore.hasNewTransformationsOnly();
    const hasShowTransformationMigration = ApplicationStore.hasShowTransformationMigration();
    const hasSnowflakePartnerConnectLimited = ApplicationStore.hasCurrentProjectFeature(
      FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED
    );

    return {
      sapiToken,
      bucket,
      table,
      bucketId,
      allBuckets,
      contextFilter,
      isDevModeActive,
      availableBuckets,
      activeTab: ltrim(window.location.hash, '#') || tableTabs.OVERVIEW,
      indexSearchFilters: searchFilters,
      allTables: TablesStore.getAll(),
      indexSearchQuery: StorageStore.getSearchQuery(),
      canWriteTable: canWriteBucket(sapiToken, bucket),
      canExportTable: canExportTable(sapiToken),
      canPullTable: canPullTable(),
      canLoadToWorkspace:
        !bucket.get('hasExternalSchema', false) && !hasSnowflakePartnerConnectLimited,
      readOnly: ApplicationStore.isReadOnly(),
      components: ComponentsStore.getAll(),
      configurations: InstalledComponentsStore.getAll(),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      admins: ApplicationStore.getAdmins(),
      currentDevBranch: DevBranchesStore.getCurrentDevBranch(),
      snapshots: TablesStore.getTableSnapshots(table.get('id')),
      updatingTable: TablesStore.getIsUpdatingTable(table.get('id')),
      creatingPrimaryKey: TablesStore.getIsCreatingPrimaryKey(table.get('id')),
      deletingPrimaryKey: TablesStore.getIsDeletingPrimaryKey(table.get('id')),
      settingAliasFilter: TablesStore.getIsSettingAliasFilter(table.get('id')),
      removingAliasFilter: TablesStore.getIsRemovingAliasFilter(table.get('id')),
      uploadingProgress: FilesStore.getUploadingProgress(bucketId),
      creatingAliasTable: TablesStore.getIsCreatingAliasTable(),
      truncatingTable: TablesStore.getTruncatingTables().get(table.get('id'), false),
      deletingTable: TablesStore.getDeletingTables().get(table.get('id'), false),
      loadingIntoTable: TablesStore.getIsLoadingTable(),
      exportingTable: TablesStore.getIsExportingTable(table.get('id')),
      restoringTable: TablesStore.getIsRestoringTable(table.get('id')),
      creatingSnapshot: TablesStore.getIsCreatingSnapshot(table.get('id')),
      creatingFromSnapshot: TablesStore.getIsCreatingFromSnapshot(),
      deletingSnapshot: TablesStore.getIsDeletingSnapshot(),
      addingColumn: TablesStore.getAddingColumn(),
      deletingColumn: TablesStore.getDeletingColumn().get(table.get('id'), Map()),
      pullingTable: TablesStore.getIsPullingTable(table.get('id')),
      hasGraph: !hasNewTransformationsOnly,
      hasUsage: hasNewTransformationsOnly || hasShowTransformationMigration,
      hasShowTransformationMigration,
      hasFlows: ApplicationStore.hasFlows(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      sandboxComponent: ComponentsStore.getComponent(KEBOOLA_SANDBOXES),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES)
      ),
      allowedTransformationComponents: getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll()
      ),
      availableDatabricksClusters: InstalledComponentsStore.getLocalState(
        KEBOOLA_SANDBOXES,
        null
      ).get('clusters'),
      hasSnowflakePartnerConnectLimited
    };
  },

  getInitialState() {
    return {
      activeActionModal: null,
      showFullScreenDataSample: false,
      showCreateWorkspaceModal: false,
      createWorkspaceForceStep: null,
      searchQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], '')
    };
  },

  render() {
    if (this.state.bucket.isEmpty()) {
      return <p>Bucket not found</p>;
    }

    if (this.state.table.isEmpty()) {
      return <p>Table not found</p>;
    }

    const tableAliases = getTableAliases(
      this.state.table,
      this.state.allTables,
      this.state.sapiToken
    );
    const tableLinks = getTableLinks(
      this.state.table,
      this.state.allBuckets.get(this.state.bucketId, Map())
    );

    return (
      <>
        <div className="tabs-with-border-wrapper flex-container flex-start align-top">
          <SearchContextDropdown
            activeBucket={this.state.bucket.get('id')}
            activeTable={this.state.table.get('id')}
            searchQuery={this.state.indexSearchQuery}
            searchFilters={this.state.indexSearchFilters}
            contextFilter={this.state.contextFilter}
            toggleContextFilter={toggleContextFilter}
            tables={this.state.allTables}
            buckets={this.state.availableBuckets}
          />
          <TabNav>
            {this.renderTabLink(tableTabs.OVERVIEW, 'Overview', 'ml-1')}
            {this.renderTabLink(tableTabs.SCHEMA, 'Schema')}
            {this.renderTabLink(tableTabs.EVENTS, 'Events')}
            {this.renderTabLink(tableTabs.DATA_SAMPLE, 'Data Sample')}
            {!this.state.table.get('isAlias') &&
              this.renderTabLink(tableTabs.SNAPSHOT_AND_RESTORE, 'Snapshots')}
            {this.state.hasGraph && this.renderTabLink(tableTabs.GRAPH, 'Graph')}
            {this.state.hasUsage && this.renderTabLink(tableTabs.USAGE, 'Usage')}
          </TabNav>
          <div className="ml-auto flex-container flex-end">
            {!this.state.exportingTable &&
              ltrim(window.location.hash, '#') === tableTabs.DATA_SAMPLE && (
                <>
                  <DataSampleColumnOrderInfo
                    tableId={this.state.table.get('id')}
                    onResetColumnsOrder={() => this.forceUpdate()}
                  />
                  <DataSampleHelp backend={this.state.bucket.get('backend')} />
                  <Tooltip placement="top" tooltip={'Expand data sample to full screen'}>
                    <Button
                      bsStyle="link"
                      className="text-muted btn-link-inline icon-addon-right icon-addon-left"
                      onClick={() => this.setState({ showFullScreenDataSample: true })}
                    >
                      <FontAwesomeIcon icon={['far', 'maximize']} />
                    </Button>
                  </Tooltip>
                </>
              )}
            {this.renderTableActions()}
          </div>
        </div>
        {this.renderTabContent(tableAliases, tableLinks)}
        <CreateAliasTableAlternativeModal
          show={this.state.activeActionModal === tableModalTabs.ALIAS}
          buckets={this.state.allBuckets}
          tables={this.state.allTables}
          table={this.state.table}
          sapiToken={this.state.sapiToken}
          onSubmit={createAliasTable}
          onHide={this.closeActionModal}
          isSaving={this.state.creatingAliasTable}
        />
        <DeleteTableModal
          show={this.state.activeActionModal === tableModalTabs.DELETE}
          table={this.state.table}
          sapiToken={this.state.sapiToken}
          urlTemplates={this.state.urlTemplates}
          tableAliases={tableAliases}
          tableLinks={tableLinks}
          deleting={this.state.deletingTable}
          onConfirm={() => {
            return deleteTable(this.state.bucket.get('id'), this.state.table.get('id')).then(() => {
              RoutesStore.getRouter().transitionTo(routeNames.BUCKET, {
                bucketId: this.state.bucket.get('id')
              });
            });
          }}
          onHide={this.closeActionModal}
        />
        <TruncateTableModal
          show={this.state.activeActionModal === tableModalTabs.TRUNCATE}
          onConfirm={() => truncateTable(this.state.table.get('id'))}
          onHide={this.closeActionModal}
          table={this.state.table}
        />
        <LoadTableFromCsvModal
          show={this.state.activeActionModal === tableModalTabs.LOAD}
          table={this.state.table}
          onSubmit={this.handleLoadTable}
          onHide={this.closeActionModal}
          isLoading={this.state.loadingIntoTable}
          progress={this.state.uploadingProgress}
        />
        <ExportModal
          show={this.state.activeActionModal === tableModalTabs.EXPORT}
          tables={List([this.state.table])}
          onSubmit={this.handleExportTable}
          onHide={this.closeActionModal}
        />
        <TimeTravelModal
          show={this.state.activeActionModal === tableModalTabs.RESTORE}
          buckets={this.state.allBuckets}
          tables={this.state.allTables}
          table={this.state.table}
          sapiToken={this.state.sapiToken}
          onConfirm={this.handleTimeTravel}
          onHide={this.closeActionModal}
        />
        <CreateSnapshotModal
          show={this.state.activeActionModal === tableModalTabs.SNAPSHOT}
          table={this.state.table}
          onConfirm={this.handleCreateSnapshot}
          onHide={this.closeActionModal}
        />
        <AddSandboxModal
          hasTableInputMapping
          show={this.state.showCreateWorkspaceModal}
          forceStep={this.state.createWorkspaceForceStep}
          onHide={() => this.setState({ showCreateWorkspaceModal: false })}
          sandboxComponent={this.state.sandboxComponent}
          workspaces={this.state.sandboxes}
          allowedComponents={this.state.allowedTransformationComponents}
          availableDatabricksClusters={this.state.availableDatabricksClusters}
          hasPayAsYouGo={this.state.hasPayAsYouGo}
          onUpdate={(workspace, preserve) => {
            const preparedConfig = fromJS({
              configuration: {
                parameters: { id: workspace.get('id') },
                storage: prepareMappingFromSelectedBucketsAndTables(
                  List([this.state.table]),
                  this.state.allTables,
                  this.state.allowedTransformationComponents,
                  workspace.get('type')
                )
              }
            });

            return updateExistingWorkspace(
              preparedConfig,
              workspace,
              preserve,
              'Use mapping from storage tables'
            );
          }}
          onSubmit={(name, type, options, params, description) => {
            const storage = prepareMappingFromSelectedBucketsAndTables(
              List([this.state.table]),
              this.state.allTables,
              this.state.allowedTransformationComponents,
              type
            );

            return SandboxesActions.createSandbox(
              { name, description, configuration: JSON.stringify({ storage }) },
              type,
              options,
              params.set('storage', storage)
            );
          }}
        />
      </>
    );
  },

  renderTabLink(tab, label, className) {
    return (
      <TabLink
        active={this.state.activeTab === tab}
        to={routeNames.TABLE}
        params={{ bucketId: this.state.bucketId, tableName: this.state.table.get('name') }}
        hash={`#${tab}`}
        className={className}
      >
        {label}
      </TabLink>
    );
  },

  renderTabContent(tableAliases, tableLinks) {
    if (this.state.activeTab === tableTabs.SCHEMA) {
      return (
        <>
          <FilterPanel
            placeholder={this.getPlaceholder}
            query={this.state.searchQuery}
            onChange={(searchQuery) => {
              this.setState({ searchQuery });
              RoutesStore.getRouter().updateQuery({ q: searchQuery });
            }}
          />
          <TableSchema
            sapiToken={this.state.sapiToken}
            tables={this.state.allTables}
            table={this.state.table}
            canWriteTable={this.state.canWriteTable}
            addingColumn={this.state.addingColumn}
            deletingColumn={this.state.deletingColumn}
            tableAliases={tableAliases}
            tableLinks={tableLinks}
            urlTemplates={this.state.urlTemplates}
            components={this.state.components}
            searchQuery={this.state.searchQuery}
          />
        </>
      );
    }

    if (this.state.activeTab === tableTabs.EVENTS) {
      return (
        <TableEvents
          eventsFactory={eventsFactory(this.state.table.get('id'))}
          admins={this.state.admins}
        />
      );
    }

    if (this.state.activeTab === tableTabs.DATA_SAMPLE) {
      return (
        <DataSample
          backend={this.state.bucket.get('backend')}
          table={this.state.table}
          bucket={this.state.bucket}
          fullScreen={this.state.showFullScreenDataSample}
          closeFullScreen={() => this.setState({ showFullScreenDataSample: false })}
          onChangeColumnOrder={() => this.forceUpdate()}
        />
      );
    }

    if (this.state.activeTab === tableTabs.SNAPSHOT_AND_RESTORE) {
      return (
        <Snapshots
          snapshots={this.state.snapshots}
          sapiToken={this.state.sapiToken}
          admins={this.state.admins}
          buckets={this.state.allBuckets}
          tables={this.state.allTables}
          table={this.state.table}
          creatingFromSnapshot={this.state.creatingFromSnapshot}
          deletingSnapshot={this.state.deletingSnapshot}
        />
      );
    }

    if (this.state.hasGraph && this.state.activeTab === tableTabs.GRAPH) {
      return (
        <TableGraph
          key={this.state.table.get('lastImportDate') || tableTabs.GRAPH}
          table={this.state.table}
        />
      );
    }

    if (this.state.hasUsage && this.state.activeTab === tableTabs.USAGE) {
      return (
        <TableUsage
          admins={this.state.admins}
          tableId={this.state.table.get('id')}
          configurations={this.state.configurations}
          hasShowTransformationMigration={this.state.hasShowTransformationMigration}
          hasFlows={this.state.hasFlows}
        />
      );
    }

    return (
      <>
        <TableOverview
          readOnly={this.state.readOnly}
          table={this.state.table}
          tables={this.state.allTables}
          components={this.state.components}
          configurations={this.state.configurations}
          tableAliases={tableAliases}
          tableLinks={tableLinks}
          sapiToken={this.state.sapiToken}
          urlTemplates={this.state.urlTemplates}
          creatingPrimaryKey={this.state.creatingPrimaryKey}
          deletingPrimaryKey={this.state.deletingPrimaryKey}
          settingAliasFilter={this.state.settingAliasFilter}
          removingAliasFilter={this.state.removingAliasFilter}
          updatingTable={this.state.updatingTable}
          canWriteTable={this.state.canWriteTable}
          hasFlows={this.state.hasFlows}
        />
        <ColumnsPreview tableId={this.state.table.get('id')} />
      </>
    );
  },

  renderTableActions() {
    if (this.state.exportingTable) {
      return (
        <span className="text-muted line-height-40">
          <Loader className="icon-addon-right" />
          Preparing export
        </span>
      );
    }

    if (
      this.state.readOnly ||
      (!this.state.canWriteTable &&
        !this.state.canExportTable &&
        !this.state.canPullTable &&
        !this.state.canLoadToWorkspace)
    ) {
      return null;
    }

    return (
      <RowActionDropdown
        toggleClassName="in-navigation"
        showLoading={
          this.state.exportingTable ||
          this.state.loadingIntoTable ||
          this.state.uploadingProgress > 0 ||
          this.state.restoringTable ||
          this.state.creatingSnapshot ||
          this.state.creatingAliasTable ||
          this.state.truncatingTable ||
          this.state.deletingTable ||
          this.state.pullingTable
        }
      >
        {this.state.canExportTable && (
          <RowActionMenuItem eventKey={tableModalTabs.EXPORT} onSelect={this.openActionModal}>
            <FontAwesomeIcon fixedWidth icon="down-to-line" />
            Export table
          </RowActionMenuItem>
        )}
        {this.state.canWriteTable && (
          <RowActionMenuItem
            eventKey={tableModalTabs.LOAD}
            onSelect={this.openActionModal}
            disabled={this.state.table.get('isAlias')}
          >
            {this.state.loadingIntoTable || this.state.uploadingProgress > 0 ? (
              <>
                <Loader />
                Loading into table...
              </>
            ) : (
              <>
                <FontAwesomeIcon fixedWidth icon="arrow-up" />
                Load
              </>
            )}
          </RowActionMenuItem>
        )}
        {this.state.canPullTable && (
          <CloneToCurrentBranch
            table={this.state.table}
            pullingTable={this.state.pullingTable}
            currentDevBranch={this.state.currentDevBranch}
          />
        )}
        {this.state.canWriteTable &&
          !!this.state.sapiToken.getIn(['owner', 'dataRetentionTimeInDays']) &&
          !this.state.table.get('isAlias') &&
          [backends.SNOWFLAKE, backends.BIGQUERY].includes(this.state.bucket.get('backend')) && (
            <RowActionMenuItem
              eventKey={tableModalTabs.RESTORE}
              onSelect={this.openActionModal}
              disabled={this.state.restoringTable}
            >
              {this.state.restoringTable ? (
                <>
                  <Loader />
                  Restoring table...
                </>
              ) : (
                <>
                  <FontAwesomeIcon fixedWidth icon="camera" />
                  Restore
                </>
              )}
            </RowActionMenuItem>
          )}
        {this.state.canWriteTable && !this.state.table.get('isAlias') && (
          <RowActionMenuItem
            eventKey={tableModalTabs.SNAPSHOT}
            onSelect={this.openActionModal}
            disabled={this.state.creatingSnapshot}
          >
            {this.state.creatingSnapshot ? (
              <>
                <Loader />
                Creating snapshot...
              </>
            ) : (
              <>
                <FontAwesomeIcon fixedWidth icon="plus" />
                Create snapshot
              </>
            )}
          </RowActionMenuItem>
        )}
        {this.state.table.get('isAliasable') &&
          this.state.canWriteTable &&
          !isCreatedInDevBranch(this.state.bucket) && (
            <RowActionMenuItem
              eventKey={tableModalTabs.ALIAS}
              onSelect={this.openActionModal}
              disabled={this.state.creatingAliasTable}
            >
              <FontAwesomeIcon fixedWidth icon="plus" />
              Create alias table
            </RowActionMenuItem>
          )}
        {this.state.canLoadToWorkspace && (
          <>
            <RowActionMenuItem
              onSelect={() => {
                this.setState({
                  showCreateWorkspaceModal: true,
                  createWorkspaceForceStep: null
                });
              }}
            >
              <>
                <FontAwesomeIcon icon="box" fixedWidth />
                Create new Workspace
              </>
            </RowActionMenuItem>
            <RowActionMenuItem
              onSelect={() => {
                this.setState({
                  showCreateWorkspaceModal: true,
                  createWorkspaceForceStep: FORM_STEPS.SANDBOX_UPDATE
                });
              }}
            >
              <>
                <FontAwesomeIcon icon="box" fixedWidth />
                Copy to existing Workspace
              </>
            </RowActionMenuItem>
          </>
        )}
        {this.state.canWriteTable && (
          <>
            <RowActionMenuItem divider />
            {!this.state.table.get('isAlias') && (
              <RowActionMenuItem
                eventKey={tableModalTabs.TRUNCATE}
                onSelect={this.openActionModal}
                disabled={this.state.truncatingTable}
              >
                <FontAwesomeIcon fixedWidth icon="xmark" />
                Truncate table
              </RowActionMenuItem>
            )}
            <RowActionMenuItem
              eventKey={tableModalTabs.DELETE}
              onSelect={this.openActionModal}
              disabled={this.state.deletingTable}
            >
              {this.state.deletingTable ? (
                <>
                  <Loader />
                  Deleting table...
                </>
              ) : (
                <>
                  <FontAwesomeIcon fixedWidth icon="trash" />
                  Delete table
                </>
              )}
            </RowActionMenuItem>
          </>
        )}
      </RowActionDropdown>
    );
  },

  openActionModal(event, action) {
    if (Object.values(tableModalTabs).includes(action)) {
      this.setState({
        activeActionModal: action
      });
    }
  },

  closeActionModal() {
    this.setState({
      activeActionModal: null
    });
  },

  handleLoadTable(file, params) {
    return uploadFile(this.state.bucket.get('id'), file).then((dataFileId) =>
      loadTable(this.state.table.get('id'), { ...params, dataFileId })
    );
  },

  handleExportTable(type) {
    return exportTables(List([this.state.table]), type);
  },

  handleTimeTravel(bucketId, tableName, timestamp) {
    const params = {
      sourceTableId: this.state.table.get('id'),
      timestamp: dayjs(timestamp).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss Z'),
      name: tableName
    };

    return restoreUsingTimeTravel(bucketId, params);
  },

  handleCreateSnapshot(description) {
    return createSnapshot(this.state.table.get('id'), { description }).then(() => {
      return loadSnapshots(this.state.table.get('id'));
    });
  },

  getPlaceholder() {
    return `Search columns (${this.state.table.get('columns').count()})`;
  }
});

export default Table;
