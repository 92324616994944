import { Map } from 'immutable';

import { KEBOOLA_DATA_APPS } from '../../constants/componentIds';
import SandboxesActions from '../sandboxes/Actions';
import { SANDBOX_TYPE } from '../sandboxes/Constants';

const deployApp = (config: Map<string, any>, sandbox: Map<string, any>) => {
  return SandboxesActions.createSandboxSimple(
    config.toJS(),
    SANDBOX_TYPE.STREAMLIT,
    Map({ shared: true }),
    config.getIn(['configuration', 'parameters'], Map()),
    config.getIn(['configuration', 'storage'], Map()),
    KEBOOLA_DATA_APPS,
    sandbox.isEmpty() ? 'create' : 're-create'
  );
};

export { deployApp };
