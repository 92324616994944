import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from './Checkbox';

const MultiActionsSelectCheckbox = ({
  isChecked,
  isIndeterminate,
  isDisabled,
  onToggle,
  entity
}) => {
  return (
    <Checkbox
      tooltip={`${isChecked || isIndeterminate ? 'Deselect' : 'Select'} ${entity}`}
      disabled={isDisabled}
      checked={isChecked}
      indeterminate={isIndeterminate}
      onChange={onToggle}
    />
  );
};

MultiActionsSelectCheckbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  entity: PropTypes.string.isRequired,
  isIndeterminate: PropTypes.bool
};

export default MultiActionsSelectCheckbox;
