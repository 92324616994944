import React from 'react';
import { Overlay, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import classNames from 'classnames';
import _ from 'underscore';

const DELAY_SHOW = 300;
const DELAY_SHOW_FAST = 100;
const DELAY_HOVER_TOOLTIP = 150;
export const MAX_ACTION_TOOLTIP_LENGTH = 20;

export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';
export type TooltipType = 'action' | 'explanatory';
type TooltipDelay = 'default' | 'fast';

type TooltipProps = {
  tooltip: React.ReactNode | ((position: TooltipPosition | undefined) => React.ReactNode);
  children?: React.ReactNode;
  placement?: TooltipPosition;
  type?: TooltipType;
  className?: string;
  forceHide?: boolean;
  forceShow?: boolean;
  delay?: TooltipDelay;
  allowHoverTooltip?: boolean;
};

class Tooltip extends React.Component<TooltipProps> {
  state = {
    showOverlay: false,
    forcePlacement: null
  };

  tooltipId = _.uniqueId('tooltip_');
  showTimeout: number | null = null;
  hideTimeout: number | null = null;

  componentWillUnmount() {
    this.hide();
    this.hideTimeout && window.clearTimeout(this.hideTimeout);
  }

  getDelay() {
    switch (this.props.delay) {
      case 'fast':
        return DELAY_SHOW_FAST;
      default:
        return DELAY_SHOW;
    }
  }

  render() {
    const singleChild = React.Children.only(this.props.children as React.ReactElement<any>);
    const placement = this.state.forcePlacement || this.props.placement;
    const type = this.props.type || 'action';

    return (
      <>
        {React.cloneElement(singleChild, {
          onFocus: this.show,
          onBlur: this.hide,
          onMouseEnter: this.show,
          onMouseLeave: this.hide
        })}
        <Overlay
          shouldUpdatePosition
          target={this}
          show={this.props.forceShow || (!this.props.forceHide && this.state.showOverlay)}
          placement={placement}
          onEntering={this.checkPlacement}
          onExited={this.resetPosition}
        >
          <BootstrapTooltip
            id={this.tooltipId}
            className={classNames(
              'overflow-break-anywhere',
              type === 'explanatory' && 'explanatory',
              type === 'action' && 'action',
              this.props.className
            )}
            onClick={(event: React.SyntheticEvent) => event.stopPropagation()}
            {...(this.props.allowHoverTooltip && {
              onMouseEnter: this.show,
              onMouseLeave: this.hide
            })}
          >
            {typeof this.props.tooltip === 'function'
              ? this.props.tooltip(placement)
              : this.props.tooltip}
          </BootstrapTooltip>
        </Overlay>
      </>
    );
  }

  show = () => {
    if (this.hideTimeout) {
      window.clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
    }

    if (this.state.showOverlay || this.showTimeout) {
      return;
    }

    this.showTimeout = window.setTimeout(() => {
      this.setState({ showOverlay: true });
    }, this.getDelay());
  };

  hide = (event?: React.MouseEvent<HTMLElement>) => {
    if (this.showTimeout) {
      window.clearTimeout(this.showTimeout);
      this.showTimeout = null;
    }

    if (!this.state.showOverlay || this.hideTimeout) {
      return;
    }

    if (
      !event ||
      !this.props.allowHoverTooltip ||
      (event?.target as HTMLElement).closest(`#${this.tooltipId}`)
    ) {
      this.setState({ showOverlay: false });
      return;
    }

    this.hideTimeout = window.setTimeout(() => {
      this.setState({ showOverlay: false });
    }, DELAY_HOVER_TOOLTIP);
  };

  resetPosition = () => {
    this.setState({ forcePlacement: null });
  };

  checkPlacement = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();

    switch (this.props.placement) {
      case 'top':
        return rect.top < 0 && this.setState({ forcePlacement: 'bottom' });

      case 'bottom':
        return rect.top > window.innerHeight && this.setState({ forcePlacement: 'top' });

      case 'left':
        return rect.left < 0 && this.setState({ forcePlacement: 'right' });

      default:
        return rect.right > window.innerWidth && this.setState({ forcePlacement: 'left' });
    }
  };
}

export default Tooltip;
