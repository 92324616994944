import { Promise } from 'bluebird';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '../../constants/componentIds';
import Dispatcher from '../../Dispatcher';
import ApplicationStore from '../../stores/ApplicationStore';
import { canActivateSchedule } from '../admin/privileges';
import { ActionTypes } from '../components/Constants';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import schedulerApi from './api';
import { SCHEDULE_STATE } from './constants';

const applySchedulerChanges = (configurationId) => {
  if (!canActivateSchedule(ApplicationStore.getSapiToken())) {
    return null;
  }

  return schedulerApi.applySchedulerChanges(configurationId);
};

const createAndActivateScheduler = (configurationId, { cronTab, timezone = 'UTC' }) => {
  return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_SCHEDULER, {
    name: `Scheduler for ${configurationId}`,
    configuration: JSON.stringify({
      schedule: { cronTab, timezone, state: SCHEDULE_STATE.ENABLED },
      target: { componentId: KEBOOLA_ORCHESTRATOR, configurationId, mode: 'run' }
    })
  }).then((response) => applySchedulerChanges(response.id));
};

const updateScheduler = (configurationId, configuration) => {
  // update configuration early to see changed schedule state immediately
  Dispatcher.handleViewAction({
    type: ActionTypes.INSTALLED_COMPONENTS_UPDATE_CONFIGURATION_SUCCESS,
    componentId: KEBOOLA_SCHEDULER,
    configurationId,
    data: configuration
  });
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_SCHEDULER,
    configurationId,
    { configuration: JSON.stringify(configuration.get('configuration')) },
    'Update scheduler'
  )
    .catch((error) => {
      // reset original configuration if update fail
      InstalledComponentsActionCreators.loadComponentConfigDataForce(
        KEBOOLA_SCHEDULER,
        configurationId
      );

      throw error;
    })
    .then((response) => applySchedulerChanges(response.id));
};

const removeScheduler = (configurationId) => {
  return Promise.resolve()
    .then(() => {
      return canActivateSchedule(ApplicationStore.getSapiToken())
        ? schedulerApi.removeSchedule(configurationId)
        : Promise.resolve();
    })
    .then(() => {
      return InstalledComponentsActionCreators.deleteConfiguration(
        KEBOOLA_SCHEDULER,
        configurationId,
        { transition: false, notification: false }
      );
    });
};

export { createAndActivateScheduler, updateScheduler, removeScheduler, applySchedulerChanges };
