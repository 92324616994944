import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type List, Map } from 'immutable';

import Checkbox from '../../../react/common/Checkbox';
import ConfirmModal from '../../../react/common/ConfirmModal';
import FilterPanel from '../../../react/common/FilterPanel';
import Loader from '../../../react/common/Loader';
import MarkedText from '../../../react/common/MarkedText';
import Tooltip from '../../../react/common/Tooltip';
import Truncated from '../../../react/common/Truncated';
import matchByWords from '../../../utils/matchByWords';
import string from '../../../utils/string';
import { getProfileFullName } from '../helpers';

const ProfilesTable = (props: {
  profiles: List<any>;
  onSave: (profiles: List<any>) => Promise<any>;
  readOnly: boolean;
}) => {
  const [query, setQuery] = React.useState('');
  const [selected, setSelected] = React.useState(Map());
  const [isDeleting, setDeleting] = React.useState(Map());
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const filteredProfiles = props.profiles.filter((profile: Map<string, any>) => {
    return matchByWords(getProfileFullName(profile), query);
  });

  const getProfileId = (profile: Map<string, any>) => {
    return profile.get('id', profile.get('propertyKey'));
  };

  const renderProfiles = () => {
    if (!query && props.profiles.isEmpty()) {
      return (
        <tr className="no-hover">
          <td>No profiles selected</td>
          <td />
        </tr>
      );
    }

    if (!!query && filteredProfiles.isEmpty()) {
      return (
        <tr className="no-hover">
          <td>No profiles found</td>
          <td />
        </tr>
      );
    }

    return filteredProfiles
      .map((profile: Map<string, any>) => {
        const id = getProfileId(profile);
        const isSelected = selected.has(id);

        return (
          <tr key={id} className="hoverable-actions">
            <td>
              <div className="tw-flex tw-items-center tw-justify-items-start">
                {!props.readOnly && (
                  <Checkbox
                    checked={isSelected}
                    onChange={() => {
                      setSelected(isSelected ? selected.delete(id) : selected.set(id, true));
                    }}
                    className="tw-mr-3"
                  />
                )}
                <Truncated
                  text={<MarkedText source={getProfileFullName(profile)} mark={query} />}
                />
              </div>
            </td>
            <td className="pr-1">
              {!props.readOnly && (
                <Tooltip placement="top" tooltip="Delete selected">
                  <Button
                    bsStyle="link"
                    className="text-muted"
                    onClick={() => {
                      const profiles = props.profiles
                        .filter((savedProfile) => !savedProfile.equals(profile))
                        .toList();

                      setDeleting(isDeleting.set(id, true));
                      props.onSave(profiles).finally(() => {
                        setDeleting(isDeleting.delete(id));
                      });
                    }}
                    disabled={isDeleting.has(id)}
                  >
                    {isDeleting.has(id) ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
                  </Button>
                </Tooltip>
              )}
            </td>
          </tr>
        );
      })
      .toArray();
  };

  const isAllSelected =
    !filteredProfiles.isEmpty() && selected.count() === filteredProfiles.count();
  const isSomeSelected = !selected.isEmpty() && selected.count() < filteredProfiles.count();

  return (
    <>
      <FilterPanel query={query} onChange={setQuery} className="tw-mb-4" />
      <div className="box tw-mb-6">
        <Table hover className="condensed no-spacing">
          <thead>
            <tr>
              <th>
                <div className="tw-flex tw-items-center tw-justify-items-start">
                  {!props.readOnly && (
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={isSomeSelected}
                      disabled={filteredProfiles.isEmpty()}
                      onChange={() => {
                        setSelected(
                          isAllSelected || isSomeSelected
                            ? Map()
                            : (filteredProfiles
                                .toMap()
                                .mapKeys((key, profile) => getProfileId(profile))
                                .map(() => true) as Map<string, boolean>)
                        );
                      }}
                      className="tw-mr-3"
                    />
                  )}
                  Profiles
                  {!selected.isEmpty() && (
                    <div className="table-action-buttons">
                      <Tooltip placement="top" tooltip="Delete selected">
                        <Button
                          bsStyle="link"
                          className="btn-link-inline btn-link-muted"
                          onClick={() => setShowDeleteModal(true)}
                        >
                          <FontAwesomeIcon icon="trash" fixedWidth />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>{renderProfiles()}</tbody>
        </Table>
        <ConfirmModal
          closeAfterResolve
          icon="trash"
          buttonType="danger"
          title="Delete selected profiles"
          buttonLabel="Delete"
          show={showDeleteModal}
          isLoading={!!isDeleting.get('selected')}
          onHide={() => setShowDeleteModal(false)}
          text={
            <>
              Are you sure you want to delete <b>{selected.count()}</b> selected{' '}
              {string.pluralize(selected.count(), 'profile')}?
            </>
          }
          onConfirm={() => {
            const profiles = filteredProfiles
              .filter((profile) => !selected.has(getProfileId(profile)))
              .toList();

            setDeleting(isDeleting.set('selected', true));
            return props
              .onSave(profiles)
              .then(() => {
                setSelected(Map());
                setShowDeleteModal(false);
              })
              .finally(() => setDeleting(isDeleting.delete('selected')));
          }}
        />
      </div>
    </>
  );
};

export default ProfilesTable;
