import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import CredentialsActionCreators from '../../../provisioning/ActionCreators';
import RedshiftCredentials from '../../../provisioning/react/components/RedshiftCredentials';
import RedshiftSandboxCredentialsStore from '../../../provisioning/stores/RedshiftSandboxCredentialsStore';

const RedshiftCredentialsContainer = createReactClass({
  mixins: [createStoreMixin(RedshiftSandboxCredentialsStore)],

  propTypes: {
    isAutoLoad: PropTypes.bool.isRequired
  },

  componentDidMount() {
    if (!this.state.credentials.get('id') && this.props.isAutoLoad) {
      CredentialsActionCreators.createRedshiftSandboxCredentials();
    }
  },

  getStateFromStores() {
    return {
      credentials: RedshiftSandboxCredentialsStore.getCredentials(),
      pendingActions: RedshiftSandboxCredentialsStore.getPendingActions()
    };
  },

  render() {
    return (
      <RedshiftCredentials
        credentials={this.state.credentials}
        isCreating={this.state.pendingActions.get('create')}
      />
    );
  }
});

export default RedshiftCredentialsContainer;
