import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import Loader from './Loader';
import RollbackVersionModal from './RollbackVersionModal';
import Tooltip from './Tooltip';

const RollbackVersionButton = createReactClass({
  propTypes: {
    version: PropTypes.object.isRequired,
    onRollback: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    isPending: PropTypes.bool,
    isDisabled: PropTypes.bool
  },

  getInitialState() {
    return {
      showModal: false
    };
  },

  closeModal() {
    this.setState({ showModal: false });
  },

  openModal() {
    this.setState({ showModal: true });
  },

  onRollback() {
    this.props.onRollback();
    this.closeModal();
  },

  render() {
    return (
      <>
        <Tooltip tooltip="Restore this version" placement="top">
          <Button
            bsStyle="link"
            className="text-muted"
            onClick={this.openModal}
            disabled={this.props.isDisabled || this.props.isPending}
          >
            {this.props.isPending ? (
              <Loader />
            ) : (
              <FontAwesomeIcon icon="arrow-rotate-left" fixedWidth />
            )}
          </Button>
        </Tooltip>
        <RollbackVersionModal
          version={this.props.version}
          show={this.state.showModal}
          onClose={this.closeModal}
          onRollback={this.onRollback}
          componentId={this.props.componentId}
        />
      </>
    );
  }
});

export default RollbackVersionButton;
