import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/r/r';
import 'codemirror/mode/python/python';
import 'codemirror/mode/julia/julia';
import '@keboola/codemirror-mode-sfsql';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/matchesonscrollbar';
import 'codemirror/addon/selection/mark-selection';
import './addon/dialog';
import './addon/search';
import './addon/transfomation-hints';
import './addon/json-lint';
import './addon/dbt';

import { defaults as CodeMirror } from 'codemirror';

CodeMirror.theme = 'solarized';
CodeMirror.mode = 'application/json';
CodeMirror.tabSize = 2;
CodeMirror.viewportMargin = 50;
CodeMirror.matchBrackets = true;
CodeMirror.autoCloseBrackets = true;
CodeMirror.lineNumbers = true;
CodeMirror.lineWrapping = true;
CodeMirror.styleSelectedText = true;
CodeMirror.gutters = ['CodeMirror-lint-markers'];
