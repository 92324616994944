import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  ControlLabel,
  FormGroup,
  HelpBlock,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ExternalLink from '../../../react/common/ExternalLink';
import { endpointTypes } from '../constants';

const availableEndpoints = [
  {
    label: 'Reporting API',
    value: endpointTypes.ENDPOINT_REPORT
  },
  {
    label: 'Multi-Channel Funnels API',
    value: endpointTypes.ENDPOINT_MCF
  }
];

const EndpointSelect = createReactClass({
  propTypes: {
    selectedValue: PropTypes.string.isRequired,
    onSelectValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    hasNewProfiles: PropTypes.bool.isRequired
  },

  render() {
    return (
      <FormGroup>
        <ControlLabel className="col-md-2">{this.props.name}</ControlLabel>
        <Col md={10}>
          <ToggleButtonGroup
            name="endpoint"
            value={this.props.selectedValue}
            onChange={this.props.onSelectValue}
          >
            {this.props.hasNewProfiles && (
              <ToggleButton key="data-api" value="data-api" disabled={this.props.disabled}>
                GA4 - Data API
              </ToggleButton>
            )}
            {availableEndpoints.map((item) => {
              return (
                <ToggleButton
                  key={`endpoint-${item.value}`}
                  value={item.value}
                  disabled={this.props.disabled}
                >
                  {item.label}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          <HelpBlock>
            Switch between{' '}
            {this.props.hasNewProfiles && (
              <>
                <ExternalLink href="https://developers.google.com/analytics/devguides/reporting/data/v1">
                  GA4 - Data API
                </ExternalLink>
                ,{' '}
              </>
            )}
            core{' '}
            <ExternalLink href="https://developers.google.com/analytics/devguides/reporting/core/v4/">
              Reporting API
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink href="https://developers.google.com/analytics/devguides/reporting/mcf/v3/">
              Multi-Channel Funnels API
            </ExternalLink>
          </HelpBlock>
        </Col>
      </FormGroup>
    );
  }
});

export default EndpointSelect;
