import type React from 'react';

export default function multiRef<T extends Element>(
  ...refs: (React.RefCallback<T> | React.MutableRefObject<T | null> | null)[]
) {
  return (instance: T | null) => {
    for (let i = 0; i < refs.length; ++i) {
      const ref = refs[i];
      if (!ref) continue;
      if (typeof ref === 'function') ref(instance);
      else ref.current = instance;
    }
  };
}
