import { OperationalError } from 'bluebird';

import { REQUEST_ABORTED_ERROR, REQUEST_TIMEOUT_ERROR } from '../../constants/superagent';
import Error from './Error';
import HttpError from './HttpError';
import SimpleError from './SimpleError';

export const HTTP_STATUS_CODE_BAD_REQUEST = 400;
export const HTTP_STATUS_CODE_UNAUTHORIZED = 401;
export const HTTP_STATUS_CODE_FORBIDDEN = 403;
export const HTTP_STATUS_CODE_NOT_FOUND = 404;
export const HTTP_STATUS_CODE_CONFLICT = 409;
export const HTTP_STATUS_CODE_INTERNAL_SERVER_ERROR = 500;
export const HTTP_STATUS_CODE_BAD_GATEWAY = 502;
export const HTTP_STATUS_CODE_SERVICE_UNAVAILABLE = 503;
export const HTTP_STATUS_CODE_GATEWAY_TIMEOUT = 504;

const shouldBeMuted = (error) => {
  if (!error.isUserError || document.hasFocus()) {
    return false;
  }

  return ['connectTimeout', 'connectTimeoutRequestAborted', 'couldNotConnect'].includes(error.id);
};

const createPresentationalError = (exception) => {
  let error = exception;

  if (error instanceof HttpError) {
    return createFromXhrError(error);
  } else if (error.code === REQUEST_TIMEOUT_ERROR) {
    error = new Error('Request timeout', error.message);
    error.isUserError = true;
    error.id = 'connectTimeout';
    return error;
  } else if (error.code === REQUEST_ABORTED_ERROR) {
    error = new Error('Request timed out', 'Please try again later.');
    error.isUserError = true;
    error.id = 'connectTimeoutRequestAborted';
    return error;
  } else if (error.crossDomain) {
    error = new Error(
      'There was an error performing your request',
      'Please try again later. This may also occur when adblock plugin is used, make sure you do not use any for this site. We have no ads here anyway.'
    );
    error.id = 'couldNotConnect';
    error.isUserError = true;
    return error;
  } else if (error instanceof OperationalError || error.isOperational) {
    // error from bluebird
    return new Error('Connection error', error.message);
  } else if (error instanceof SimpleError) {
    error = new Error(error.title, error.message);
    error.isUserError = true;
    return error;
  } else if (typeof error === 'string' || typeof error?.message === 'string') {
    error = new Error('Error', error?.message || error);
    error.isUserError = true;
    return error;
  }

  return new Error('Application error', 'Please try reload the browser.');
};

var createFromXhrError = (httpError) => {
  let title = 'Error';
  let text = '';
  let isMaintenance = false;

  if (httpError.response.body?.error) {
    text = httpError.response.body.error?.message || httpError.response.body.error;
  }

  if (
    httpError.response.body?.message ||
    httpError.response.error?.message ||
    httpError.response.body?.errorMessage
  ) {
    title = text;
    text =
      httpError.response.body?.message ||
      httpError.response.error?.message ||
      httpError.response.body?.errorMessage;
  }

  if (
    httpError.response.status === HTTP_STATUS_CODE_SERVICE_UNAVAILABLE &&
    httpError.response.body?.status === 'maintenance'
  ) {
    isMaintenance = true;
    title = 'Maintenance';
    text =
      httpError.response.body.reason ||
      'Service is currently down for scheduled maintenance. We expect to be back soon. Thanks for your patience.';
  }

  if (!text) {
    text = 'Application error. Please reload the browser.';
  }

  const error = new Error(
    typeof title === 'string' ? title : 'Error',
    typeof text === 'string' ? text : '',
    httpError,
    httpError.response.body?.exceptionId
  );

  if (
    [
      HTTP_STATUS_CODE_BAD_REQUEST,
      HTTP_STATUS_CODE_UNAUTHORIZED,
      HTTP_STATUS_CODE_FORBIDDEN,
      HTTP_STATUS_CODE_NOT_FOUND,
      HTTP_STATUS_CODE_CONFLICT
    ].includes(httpError.response.status)
  ) {
    error.isUserError = true;
  } else if (isMaintenance) {
    error.isMaintenanceError = true;
  }

  return error;
};

export { shouldBeMuted, createPresentationalError };
