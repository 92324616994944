import { List, Map } from 'immutable';
import _ from 'underscore';

import { APIFY_APIFY as COMPONENT_ID } from '../../constants/componentIds';
import getDefaultBucket from '../../utils/getDefaultBucket';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';

export default function (configId) {
  const localState = () => InstalledComponentsStore.getLocalState(COMPONENT_ID, configId) || Map();
  const config = InstalledComponentsStore.getConfig(COMPONENT_ID, configId);
  const configData = InstalledComponentsStore.getConfigData(COMPONENT_ID, configId);
  const parameters = configData.get('parameters', Map());
  const inputTables = configData.getIn(['storage', 'input', 'tables'], List());
  const defaultOutputBucket = getDefaultBucket('in', COMPONENT_ID, configId);

  return {
    inputTable: inputTables.first(),
    parameters: parameters,
    config: config,
    configData: configData,
    outputBucket: defaultOutputBucket,
    // local state stuff
    getLocalState(path) {
      if (_.isEmpty(path)) {
        return localState() || Map();
      }
      return localState().getIn([].concat(path), Map());
    }
  };
}
