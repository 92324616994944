import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DEMO_PROJECT_URL } from '../../../../constants/KbcConstants';
import ExternalLink from '../../../../react/common/ExternalLink';
import { VideoWidget } from '../../../../react/common/VideoWidget';

type Props = {
  onDismiss: () => void;
};

const WelcomeWidget = ({ onDismiss }: Props) => {
  return (
    <div className="box box-panel dashboard-welcome-widget text-center">
      <Button bsStyle="link" className="ml-auto close" onClick={onDismiss}>
        <FontAwesomeIcon icon="xmark" />
      </Button>
      <div className="flex-container flex-column stretch gap-24 plp-3 prp-3">
        <h2 className="mtp-2 mb-0 f-24 line-height-32">New to Keboola?</h2>
        <div>
          <h3>Try out our interactive walk-through in the demo project.</h3>
          <ExternalLink href={DEMO_PROJECT_URL} className="btn btn-info-outline btn-block">
            Demo Project
          </ExternalLink>
        </div>
        <hr className="split" />
        <div>
          <h3>Watch the walk-through video.</h3>
          <VideoWidget type="keboola_walkthrough" />
        </div>
      </div>
    </div>
  );
};

export default WelcomeWidget;
