import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import ConfirmButtons from '../../../../../react/common/ConfirmButtons';
import ModalIcon from '../../../../../react/common/ModalIcon';
import Tooltip from '../../../../../react/common/Tooltip';
import { DBT_COMPONENTS } from '../../../../transformations-v2/constants';
import { ioType } from '../../../Constants';
import Editor from './TableInputMappingEditor';

const MODE_CREATE = 'create',
  MODE_EDIT = 'edit';

const TableInputMappingModal = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    mode: PropTypes.oneOf([MODE_CREATE, MODE_EDIT]).isRequired,
    mapping: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    otherMappings: PropTypes.object.isRequired,
    destinationType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    savedMapping: PropTypes.object,
    replacementComponentId: PropTypes.string,
    hasSourceTable: PropTypes.bool,
    onEditStart: PropTypes.func,
    generateSources: PropTypes.bool
  },

  isValid() {
    return (
      (List.isList(this.props.mapping.get('source'))
        ? !this.props.mapping.get('source').isEmpty()
        : !!this.props.mapping.get('source')) &&
      (this.props.mapping.has('tables') || !!this.props.mapping.get('destination')) &&
      (this.props.mapping.has('tables') || !this.isDestinationDuplicate())
    );
  },

  getInitialState() {
    return {
      isSaving: false,
      showModal: false
    };
  },

  open() {
    this.setState({
      showModal: true
    });
  },

  close() {
    this.setState({
      showModal: false
    });
  },

  isDestinationDuplicate() {
    const otherDestinations = this.props.otherMappings.map((mapping) =>
      mapping.get('destination', '').toLowerCase()
    );

    if (this.props.mapping.has('tables')) {
      return this.props.mapping.get('tables').some((table) => {
        if (
          this.props.mapping
            .get('tables')
            .some(
              (tableB) =>
                table.get('source') !== tableB.get('source') &&
                table.get('destination') === tableB.get('destination')
            )
        )
          return true;

        return otherDestinations.contains(table.get('destination', '').toLowerCase());
      });
    }

    return otherDestinations.contains(this.props.mapping.get('destination', '').toLowerCase());
  },

  render() {
    const isDbtComponent = DBT_COMPONENTS.includes(this.props.componentId);

    return (
      <span onClick={(e) => e.stopPropagation()}>
        {this.renderOpenButton(isDbtComponent)}
        <Modal bsSize="large" onHide={this.handleCancel} show={this.state.showModal}>
          <Modal.Header closeButton>
            <Modal.Title>{isDbtComponent ? 'Storage Source' : 'Input Mapping'}</Modal.Title>
            {this.props.mode === MODE_CREATE ? <ModalIcon.Plus /> : <ModalIcon.Edit />}
          </Modal.Header>
          <Modal.Body>{this.editor()}</Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={
                this.props.mode === MODE_CREATE
                  ? `Add ${isDbtComponent ? 'Source' : 'Input'}`
                  : 'Save Input'
              }
              isSaving={this.state.isSaving}
              onSave={this.handleSave}
              isDisabled={!this.isValid() || this.editingNonExistentTable()}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  },

  renderOpenButton(isDbtComponent) {
    if (this.props.mode === MODE_EDIT && isDbtComponent) {
      return null;
    }

    if (this.props.mode === MODE_EDIT) {
      const editingNonExistentTable = this.editingNonExistentTable();

      return (
        <Tooltip tooltip={editingNonExistentTable ? 'Open Input' : 'Edit Input'} placement="top">
          <Button bsStyle="link" className="text-muted" onClick={this.handleEditButtonClick}>
            {editingNonExistentTable ? (
              <FontAwesomeIcon icon="eye" fixedWidth />
            ) : (
              <FontAwesomeIcon icon="pen" fixedWidth />
            )}
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button
        bsStyle="link"
        className="header-inline-button color-success"
        onClick={this.open}
        disabled={isDbtComponent && !this.props.generateSources}
      >
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        {isDbtComponent ? 'Add New Source' : 'New Table Input'}
      </Button>
    );
  },

  handleEditButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.open();
    if (this.props.onEditStart) {
      this.props.onEditStart();
    }
  },

  editor() {
    return (
      <Editor
        componentId={this.props.componentId}
        replacementComponentId={this.props.replacementComponentId}
        mode={this.props.mode}
        value={this.props.mapping}
        savedValue={this.props.savedMapping}
        tables={this.props.tables}
        buckets={this.props.buckets}
        disabled={this.state.isSaving || this.editingNonExistentTable()}
        onChange={this.props.onChange}
        isDestinationDuplicate={this.isDestinationDuplicate()}
        editingNonExistentTable={this.editingNonExistentTable()}
        destinationType={this.props.destinationType}
        otherMappings={this.props.otherMappings}
      />
    );
  },

  editingNonExistentTable() {
    return this.props.mode === MODE_EDIT && !this.props.hasSourceTable;
  },

  handleCancel() {
    this.close();
    this.props.onCancel();
  },

  handleSave() {
    this.setState({
      isSaving: true
    });
    this.props
      .onSave()
      .then(() => {
        this.setState({
          isSaving: false
        });
        this.close();
      })
      .catch((e) => {
        this.setState({
          isSaving: false
        });
        throw e;
      });
  }
});

export default TableInputMappingModal;
