import React from 'react';
import classNames from 'classnames';
import _ from 'underscore';

import Tooltip, { MAX_ACTION_TOOLTIP_LENGTH } from './Tooltip';

type Props = {
  checked?: boolean;
  id?: string;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  indeterminate?: boolean;
  tooltip?: string;
  className?: string;
  children?: React.ReactNode;
};

class Checkbox extends React.PureComponent<Props> {
  checkboxId = _.uniqueId('checkbox_');

  render() {
    if (this.props.tooltip) {
      return (
        <Tooltip
          placement="top"
          tooltip={this.props.tooltip}
          type={this.props.tooltip.length > MAX_ACTION_TOOLTIP_LENGTH ? 'explanatory' : 'action'}
        >
          {this.renderCheckbox()}
        </Tooltip>
      );
    }

    return this.renderCheckbox();
  }

  renderCheckbox = () => {
    return (
      <div
        onClick={(event) => event.stopPropagation()}
        className={classNames('common-checkbox', this.props.className, {
          'disabled not-allowed': this.props.disabled
        })}
      >
        <input
          id={this.checkboxId}
          type="checkbox"
          disabled={!!this.props.disabled}
          checked={!!this.props.checked}
          onChange={() =>
            this.props.onChange?.(!!this.props.checked || !!this.props.indeterminate ? false : true)
          }
          ref={(input) => {
            if (input) {
              input.indeterminate = !!this.props.indeterminate;
            }
          }}
        />
        {this.props.children && <label htmlFor={this.checkboxId}>{this.props.children}</label>}
      </div>
    );
  };
}

export default Checkbox;
