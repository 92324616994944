import React from 'react';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { List, type Map } from 'immutable';

import CollapseButton from '../../../react/common/CollapseButton';
import CreatedDate from '../../../react/common/CreatedDate';
import Markdown from '../../../react/common/Markdown';
import { isMergeRequestCreated } from '../helpers';

const MergeRequestInfoPanel = (props: { mergeRequest: Map<string, any> }) => {
  const [expand, setExpand] = React.useState(true);
  const onToggle = () => setExpand(!expand);

  if (!isMergeRequestCreated(props.mergeRequest)) {
    return null;
  }

  const renderItem = (label: React.ReactNode, body: React.ReactNode) => (
    <div className="panel-info-item no-wrap">
      <span className="text-muted">{label}:</span>
      <span className="flex-container flex-start font-medium">{body}</span>
    </div>
  );

  const hasDescription = !!props.mergeRequest.get('description');

  return (
    <div className="box panel-info">
      <div className="box-content">
        <div
          onClick={onToggle}
          className={classNames('flex-container', { 'btn-collapse-area': hasDescription })}
        >
          <div className="flex-container align-top line-height-24">
            {renderItem(
              'Submited by',
              <>
                <FontAwesomeIcon icon="user" className="mrp-2 text-muted" />
                {props.mergeRequest.getIn(['creator', 'name'])}
              </>
            )}
            {renderItem(
              'Date',
              <>
                <FontAwesomeIcon icon="calendar" className="mrp-2 text-muted" />
                <CreatedDate createdTime={props.mergeRequest.get('createdAt')} />
              </>
            )}
            {renderItem(
              'Approved by',
              props.mergeRequest.get('approvals', List()).isEmpty() ? (
                <>
                  <FontAwesomeIcon icon={['far', 'empty-set']} className="mrp-2 text-muted" />
                  No one
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon="check-circle" className="mrp-2 color-success" />
                  {props.mergeRequest
                    .get('approvals')
                    .map((approve: Map<string, any>) => approve.get('approverName'))
                    .join(', ')}
                </>
              )
            )}
          </div>
          {hasDescription && (
            <CollapseButton entity="Description" isCollapsed={!expand} onToggle={onToggle} />
          )}
        </div>
        {hasDescription && (
          <Collapse in={expand}>
            <div className="text-muted">
              <div className="panel-info-content">
                <Markdown source={props.mergeRequest.get('description', '')} collapsible={false} />
              </div>
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
};

export default MergeRequestInfoPanel;
