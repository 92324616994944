import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import ExternalLink from '../../../react/common/ExternalLink';

const MarketplacePortalLink = (props: { href: string; className?: string }) => {
  return (
    <ExternalLink
      href={props.href}
      className={classNames('btn btn-success btn-block', props.className)}
    >
      Manage in Azure Portal
      <FontAwesomeIcon icon="arrow-up-right-from-square" className="f-14 icon-addon-left" />
    </ExternalLink>
  );
};

export default MarketplacePortalLink;
