import type { Map } from 'immutable';

import ApplicationStore from '../../stores/ApplicationStore';
import request from '../../utils/request';
import { configEnhancements } from './helpers';

const createRequest = (path: string, data: Record<string, any>) => {
  const sapiToken = ApplicationStore.getSapiToken();

  return request('POST', 'https://ai-service.keboola.com/api' + path)
    .set('X-StorageApi-Host', new URL(sapiToken.get('uri')).host)
    .set('X-StorageApi-Token', sapiToken.get('token'))
    .send(data)
    .promise()
    .then((response) => response.body)
    .catch((error) => {
      throw error?.response?.body?.errorMessage || error;
    });
};

const getConfigDescription = (component: Map<string, any>, configuration: Map<string, any>) => {
  return createRequest('/describe', {
    configuration: JSON.stringify(configEnhancements(component, configuration).toJS())
  });
};

export { getConfigDescription };
