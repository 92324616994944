import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { Map } from 'immutable';

import Tooltip from '../../../react/common/Tooltip';
import { BEHAVIOR_TYPES } from '../../queue/constants';

const PhaseActions = (props: {
  phase: Map<string, any>;
  behaviorOnError: string;
  newTaskPosition: null | string;
  handleShowAddTaskModal: (position: string | false) => void;
  toggleBehaviorOnErrorChange: (phase: Map<string, any>) => void;
  onDelete: (phase: Map<string, any>) => void;
}) => {
  const isActive = props.newTaskPosition === props.phase.get('id').toString();

  return (
    <span
      className={classNames('actions phase-actions', { active: isActive })}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Tooltip placement="top" tooltip="Delete Phase">
        <Button
          bsStyle="link"
          className="btn-link-inline circle-button text-muted"
          onClick={() => props.onDelete(props.phase)}
        >
          <FontAwesomeIcon icon="trash" />
        </Button>
      </Tooltip>
      {props.phase.get('tasks').count() > 1 && (
        <>
          <Tooltip
            placement="top"
            tooltip={
              props.behaviorOnError === BEHAVIOR_TYPES.WARNING
                ? 'All to Finish'
                : 'Continue on Failure'
            }
          >
            <Button
              bsStyle="link"
              className="btn-link-inline circle-button text-muted"
              onClick={() => props.toggleBehaviorOnErrorChange(props.phase)}
            >
              <FontAwesomeIcon
                icon={
                  props.behaviorOnError === BEHAVIOR_TYPES.WARNING
                    ? 'down-from-dotted-line'
                    : 'rotate-exclamation'
                }
              />
            </Button>
          </Tooltip>
        </>
      )}
      <Tooltip placement="top" tooltip={isActive ? 'Close' : 'Add Task to Phase'}>
        <span className="add-task-inline-container">
          <Button
            bsStyle="link"
            className="add-task-inline btn-link-inline circle-button"
            onClick={() => props.handleShowAddTaskModal(props.phase.get('id').toString())}
          >
            <FontAwesomeIcon fixedWidth className="f-14" icon={isActive ? 'xmark' : 'plus'} />
          </Button>
        </span>
      </Tooltip>
    </span>
  );
};

export default PhaseActions;
