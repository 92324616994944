import ApplicationStore from '../../stores/ApplicationStore';
import request from '../../utils/request';
import { getTelemetryUrl } from './helpers';

const createRequest = (method, path) => {
  return request(method, `${getTelemetryUrl()}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString()
  );
};

const TelemetryApi = {
  createCredentials(params = {}) {
    return createRequest('POST', 'provisioning/workspace')
      .send({ project_type: ApplicationStore.getCurrentProject().get('type'), ...params })
      .promise()
      .then((response) => response.body);
  }
};

export default TelemetryApi;
