import { fromJS } from 'immutable';

const createConfiguration = function (localState) {
  const source = localState.get('source', '');

  return fromJS({
    storage: {
      input: {
        tables: [{ source, destination: `${source}.csv` }]
      }
    }
  });
};

const parseConfiguration = function (configuration) {
  return fromJS({
    source: configuration.getIn(['storage', 'input', 'tables', 0, 'source'], '')
  });
};

const createEmptyConfiguration = function (tableId) {
  return createConfiguration(fromJS({ source: tableId }));
};

export default {
  createConfiguration: createConfiguration,
  parseConfiguration: parseConfiguration,
  createEmptyConfiguration: createEmptyConfiguration
};
