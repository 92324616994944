import { fromJS, List, Map } from 'immutable';

import Dispatcher from '../../Dispatcher';
import StoreUtils, { initStore } from '../../utils/StoreUtils';
import { ActionTypes as AdminActionTypes } from '../admin/constants';
import { ActionTypes as BillingActionTypes, CREDIT_PRICE } from './constants';

let _store = initStore(
  'BillingStore',
  Map({
    purchases: List(),
    configuration: Map(),
    billingInformation: Map(),
    credits: Map(),
    loadingCredits: false,
    topUpSetting: null,
    topUpSettingLoaded: false
  })
);

const BillingStore = StoreUtils.createStore({
  getPurchases() {
    return _store.get('purchases');
  },

  getBillingInformation() {
    return _store.get('billingInformation');
  },

  getTopUpSetting() {
    return _store.get('topUpSetting');
  },

  isTopUpSettingLoaded() {
    return _store.get('topUpSettingLoaded');
  },

  getMarketplaceSubscriptionLink() {
    return _store.getIn(['credits', 'marketplaceSubscription', 'billingLink'], '');
  },

  isLoadingCredits() {
    return _store.get('loadingCredits', false);
  },

  isMarketplaceProject() {
    return _store.hasIn(['credits', 'marketplaceSubscription', 'state']);
  },

  getCreditPrice() {
    return _store.getIn(['configuration', 'credit-price'], CREDIT_PRICE);
  },

  getRemainingCredits() {
    return _store.getIn(['credits', 'remaining'], 0);
  }
});

Dispatcher.register((payload) => {
  const action = payload.action;
  switch (action.type) {
    case AdminActionTypes.PAYG_BILLING_GET_PURCHASES_SUCCESS:
      _store = _store.set('purchases', fromJS(action.purchases || []));
      BillingStore.emitChange();
      break;

    case AdminActionTypes.PAYG_BILLING_GET_CONFIGURATION_SUCCESS:
      _store = _store.set('configuration', fromJS(action.configuration));
      BillingStore.emitChange();
      break;

    case BillingActionTypes.BILLING_CREDITS_LOAD_START:
      _store = _store.set('loadingCredits', true);
      BillingStore.emitChange();
      break;

    case BillingActionTypes.BILLING_CREDITS_LOAD_SUCCESS:
      _store = _store.set('credits', fromJS(action.credits));
      _store = _store.set('loadingCredits', false);
      BillingStore.emitChange();
      break;

    case BillingActionTypes.BILLING_CREDITS_LOAD_ERROR:
      _store = _store.set('loadingCredits', false);
      BillingStore.emitChange();
      break;

    case AdminActionTypes.PAYG_BILLING_GET_BILLING_INFORMATION_SUCCESS:
      _store = _store.set(
        'billingInformation',
        // empty response (204 no content) will cause `null`, so we have to use empty Map()
        action.billingInformation ? fromJS(action.billingInformation) : Map()
      );
      BillingStore.emitChange();
      break;

    case AdminActionTypes.PAYG_BILLING_GET_TOP_UP_SETTING_SUCCESS:
      _store = _store.set('topUpSetting', action.setting).set('topUpSettingLoaded', true);
      BillingStore.emitChange();
      break;

    default:
      break;
  }
});

export default BillingStore;
