import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import ConfirmModal from './ConfirmModal';
import Loader from './Loader';

/** @type {any} */
const SaveButtons = createReactClass({
  propTypes: {
    isSaving: PropTypes.bool.isRequired,
    isChanged: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    saveStyle: PropTypes.string,
    onReset: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    modalTitle: PropTypes.string,
    modalBody: PropTypes.any
  },

  getDefaultProps() {
    return {
      saveStyle: 'success',
      disabled: false,
      showModal: false,
      modalTitle: '',
      modalBody: null
    };
  },

  getInitialState() {
    return {
      modalOpen: false,
      isSaving: false
    };
  },

  render() {
    return (
      <>
        {this.props.isChanged && (
          <ButtonToolbar>
            <Button onClick={this.props.onReset} disabled={this.props.isSaving}>
              <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
              Reset
            </Button>
            <Button
              className="save-button"
              bsStyle={this.props.saveStyle}
              disabled={this.props.disabled || this.props.isSaving}
              onClick={this.onSaveButtonClick}
            >
              {this.props.isSaving ? <Loader /> : 'Save'}
            </Button>
          </ButtonToolbar>
        )}
        {this.props.showModal && this.modal()}
      </>
    );
  },

  modal() {
    return (
      <ConfirmModal
        closeAfterResolve
        buttonLabel="Save"
        text={this.props.modalBody}
        title={this.props.modalTitle}
        onConfirm={this.handleSave}
        onHide={() => this.setState({ modalOpen: false })}
        show={this.state.modalOpen}
        isLoading={this.state.isSaving}
      />
    );
  },

  onSaveButtonClick() {
    if (!this.props.showModal) {
      return this.props.onSave();
    } else {
      this.setState({ modalOpen: true });
    }
  },

  handleSave() {
    this.setState({ isSaving: true });
    return this.props.onSave().finally(() => this.setState({ isSaving: false }));
  }
});

export default SaveButtons;
