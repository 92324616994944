import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import Checkbox from '../../../../react/common/Checkbox';
import Select from '../../../../react/common/Select';

const LoadingOptions = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    actions: PropTypes.instanceOf(Map).isRequired,
    allValues: PropTypes.object.isRequired,
    value: PropTypes.shape({
      incremental: PropTypes.bool.isRequired,
      primaryKey: PropTypes.array.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Loading Options</h2>
        </div>
        <div className="box-content">
          <FormGroup>
            <Col componentClass={ControlLabel} sm={4}>
              Primary Key
            </Col>
            <Col sm={8}>
              <Select
                value={this.props.value.primaryKey}
                multi
                allowCreate={this.props.allValues.advancedMode}
                options={this.getPrimaryKeyOptions()}
                placeholder="Add a column"
                promptTextCreator={(columnName) => `Add a column "${columnName}"`}
                onChange={(value) => this.props.onChange({ primaryKey: value })}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                If primary key is set, updates can be done on table by selecting{' '}
                <strong>incremental loads</strong>. Primary key can consist of multiple columns.
                Primary key of an existing table cannot be changed.
              </HelpBlock>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col smOffset={4} sm={8}>
              <Checkbox
                checked={this.props.value.incremental}
                disabled={this.props.disabled}
                onChange={(checked) => this.props.onChange({ incremental: checked })}
              >
                Incremental Loading
              </Checkbox>
              <HelpBlock>
                If incremental load is turned on, table will be updated instead of rewritten. Tables
                with primary key will update rows, tables without primary key will append rows.
              </HelpBlock>
              {!!this.props.allValues.incrementalFetchingColumn &&
                !this.props.allValues.advancedMode &&
                !this.props.value.incremental && (
                  <Alert bsStyle="warning">
                    It is recommended to enable incremental loading if using incremental fetching.
                    If incremental loading is <strong>not</strong> enabled, the storage table will
                    always contain only the most recently fetched results.
                  </Alert>
                )}
            </Col>
          </FormGroup>
        </div>
      </div>
    );
  },

  getPrimaryKeyOptions() {
    if (this.props.allValues.advancedMode) {
      return [];
    }

    return this.props.actions
      .getIn(['getTables', 'data'], List())
      .find(
        (table) =>
          table.get('datasetId') === this.props.allValues.datasetId &&
          table.get('tableId') === this.props.allValues.tableId,
        null,
        Map()
      )
      .get('columns', this.props.actions.getIn(['getColumns', 'data'], List()))
      .map((column) => ({ label: column.get('name'), value: column.get('name') }))
      .toArray();
  }
});

export default LoadingOptions;
