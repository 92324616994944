import React from 'react';
import createReactClass from 'create-react-class';

import JobPartialRunLabel from '../../../react/common/JobPartialRunLabel';
import JobStatusLabel from '../../../react/common/JobStatusLabel';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import RoutesStore from '../../../stores/RoutesStore';
import { getCurrentBackendSize, getUserRunnedParentJob } from '../helpers';
import JobsStore from '../store';

const JobDetailStatus = createReactClass({
  mixins: [createStoreMixin(JobsStore)],

  getStateFromStores() {
    const jobId = RoutesStore.getCurrentRouteParam('jobId');

    return {
      job: JobsStore.get(jobId),
      allJobs: JobsStore.getAll()
    };
  },

  render() {
    if (!this.state.job) {
      return null;
    }

    return (
      <>
        <JobPartialRunLabel
          hasNewQueue
          job={getUserRunnedParentJob(this.state.job, this.state.allJobs)}
          className="mr-1"
        />
        <JobStatusLabel
          status={this.state.job.get('status')}
          backendType={getCurrentBackendSize(this.state.job)}
        />
      </>
    );
  }
});

export default JobDetailStatus;
