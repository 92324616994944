import { fromJS, List } from 'immutable';
import _ from 'underscore';

import {
  KDS_TEAM_WR_EXASOL,
  KDS_TEAM_WR_FIREBOLT,
  KDS_TEAM_WR_HIVE_CSAS,
  KDS_TEAM_WR_PAIRITY,
  KEBOOLA_WR_DB_HIVE,
  KEBOOLA_WR_DB_IMPALA,
  KEBOOLA_WR_DB_MSSQL_V_2,
  KEBOOLA_WR_DB_MYSQL,
  KEBOOLA_WR_DB_ORACLE,
  KEBOOLA_WR_DB_PGSQL,
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_DB_SYNAPSE,
  KEBOOLA_WR_LOOKER_V2,
  KEBOOLA_WR_REDSHIFT_V_2,
  KEBOOLA_WR_SISENSE,
  KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE,
  KEBOOLA_WR_THOUGHTSPOT
} from '../../../constants/componentIds';
import {
  findBaseType,
  findDefaultValue,
  findLength,
  findNullable
} from '../../components/utils/columnMetadataHelper';
import { isNullable } from '../../components/utils/datatypeHelpers';
import { getTableColumnMetadata } from '../../components/utils/tableMetadataHelper';
import { hasDisabledNullable } from '../helpers';
import * as mappings from '../mapping';
import DataTypes, { getDisabledColumnFields } from './dataTypes';

const definedMapping = fromJS({
  [KEBOOLA_WR_DB_IMPALA]: mappings.impala,
  [KEBOOLA_WR_DB_HIVE]: mappings.hive,
  [KEBOOLA_WR_DB_MSSQL_V_2]: mappings.mssql,
  [KEBOOLA_WR_DB_MYSQL]: mappings.mysql,
  [KEBOOLA_WR_DB_ORACLE]: mappings.oracle,
  [KEBOOLA_WR_DB_PGSQL]: mappings.pgsql,
  [KDS_TEAM_WR_PAIRITY]: mappings.pgsql,
  [KEBOOLA_WR_REDSHIFT_V_2]: mappings.redshift,
  [KEBOOLA_WR_DB_SNOWFLAKE]: mappings.snowflake,
  [KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE]: mappings.snowflake,
  [KEBOOLA_WR_THOUGHTSPOT]: mappings.thoughtspot,
  [KEBOOLA_WR_LOOKER_V2]: mappings.snowflake,
  [KEBOOLA_WR_DB_SYNAPSE]: mappings.synapse,
  [KEBOOLA_WR_SISENSE]: mappings.sisense,
  [KDS_TEAM_WR_FIREBOLT]: mappings.firebolt,
  [KDS_TEAM_WR_EXASOL]: mappings.exasol,
  [KDS_TEAM_WR_HIVE_CSAS]: mappings.hive
});

export function prepareColumnsTypes(componentId, table) {
  if (!DataTypes[componentId]) {
    return List();
  }

  const primaryKey = table.get('primaryKey', List());
  const defaultType = fromJS(DataTypes[componentId].default || {});
  const columnMetadata = getTableColumnMetadata(table);
  const omittedFields = getDisabledColumnFields(componentId);

  if (definedMapping.has(componentId) && columnMetadata.count()) {
    const metadata = getMetadataDataTypes(componentId, columnMetadata);

    return table.get('columns', List()).map((column) => {
      let preparedColumn = fromJS({
        name: column,
        dbName: column,
        type: metadata.getIn([column, 'type'], defaultType.get('type')),
        nullable: hasDisabledNullable(componentId, primaryKey, column)
          ? false
          : metadata.getIn([column, 'nullable'], false),
        default: metadata.getIn([column, 'defaultValue'], ''),
        size: metadata.getIn([column, 'size'], defaultType.get('size', ''))
      });

      omittedFields.forEach((field) => {
        preparedColumn = preparedColumn.delete(field);
      });

      return preparedColumn;
    });
  }

  return table.get('columns', List()).map((column) => {
    let preparedColumn = fromJS({
      name: column,
      dbName: column,
      type: defaultType.get('type', ''),
      nullable: hasDisabledNullable(componentId, primaryKey, column)
        ? false
        : defaultType.get('nullable', false),
      default: defaultType.get('default', ''),
      size: defaultType.get('size', '')
    });

    omittedFields.forEach((field) => {
      preparedColumn = preparedColumn.delete(field);
    });

    return preparedColumn;
  });
}

export function prepareColumnsTypesAsMap(componentId, table) {
  return prepareColumnsTypes(componentId, table)
    .toMap()
    .mapKeys((key, column) => column.get('name'));
}

export function getMetadataDataTypes(componentId, columnMetadata) {
  const matchesCaseWithDataTypesDef = [
    KEBOOLA_WR_DB_MYSQL,
    KEBOOLA_WR_REDSHIFT_V_2,
    KDS_TEAM_WR_EXASOL,
    KDS_TEAM_WR_FIREBOLT
  ].includes(componentId);

  return columnMetadata
    .map((data, column) => {
      const baseType = findBaseType(data);

      if (!baseType) {
        return null;
      }

      const datatype = definedMapping
        .get(componentId)
        .find((type) => type.get('basetype') === baseType.get('value'));

      if (!datatype) {
        return null;
      }

      const byProvider = data.filter((entry) => entry.get('provider') === baseType.get('provider'));
      const nullable = findNullable(byProvider);
      const defaultValue = findDefaultValue(byProvider);
      let size = findLength(byProvider).get('value', '');

      if (size && datatype.get('size') && datatype.get('maxLength')) {
        const maxLength = datatype.get('maxLength');

        if (_.isNumber(maxLength)) {
          size = parseInt(size) > maxLength ? `${maxLength}` : `${size}`;
        } else if (_.isString(maxLength)) {
          const [maxSize, maxPrecision] = maxLength.split(',').map(Number);
          const [definedSize, definedPrecision] = size.split(',').map(Number);
          size = `${Number.isInteger(definedSize) ? Math.min(definedSize, maxSize) : maxSize},${
            Number.isInteger(definedPrecision) ? Math.min(definedPrecision, maxPrecision) : 0
          }`;
        }
      } else {
        size = '';
      }

      return fromJS({
        column,
        size,
        type: matchesCaseWithDataTypesDef
          ? datatype.get('name')
          : datatype.get('name').toLowerCase(),
        defaultValue: defaultValue.get('value', ''),
        nullable: isNullable(nullable.get('value', 0))
      });
    })
    .filter(Boolean);
}
