import { Map, Set } from 'immutable';
import { startsWith, strRight } from 'underscore.string';

import {
  PREFIX_COMPONENT_ID,
  PREFIX_CONFIGURATION_ID,
  PREFIX_CONFIGURATION_ROW_ID
} from './constants';

const getTagsForComponentConfiguration = (componentId, configurationId) => {
  return [
    {
      name: `${PREFIX_COMPONENT_ID}${componentId}`
    },
    {
      name: `${PREFIX_CONFIGURATION_ID}${configurationId}`
    }
  ];
};

const getTagsForComponentConfigurationRow = (componentId, configurationId, configurationRowId) => {
  return [
    {
      name: `${PREFIX_COMPONENT_ID}${componentId}`
    },
    {
      name: `${PREFIX_CONFIGURATION_ID}${configurationId}`
    },
    {
      name: `${PREFIX_CONFIGURATION_ROW_ID}${configurationRowId}`
    }
  ];
};

const parseComponentConfigurationAndRowFromSourceTags = (tags) => {
  const idPrefixesMap = [
    { prefix: PREFIX_COMPONENT_ID, value: 'componentId' },
    { prefix: PREFIX_CONFIGURATION_ID, value: 'configurationId' },
    { prefix: PREFIX_CONFIGURATION_ROW_ID, value: 'configurationRowId' }
  ];

  return tags.reduce((parsedTags, tag) => {
    const { prefix, value } =
      idPrefixesMap.find((id) => startsWith(tag.get('name'), id.prefix)) || {};

    if (prefix && !parsedTags.has(value)) {
      return parsedTags.set(value, strRight(tag.get('name'), prefix));
    }

    if (!prefix && !parsedTags.has('fileTag')) {
      return parsedTags.set('fileTag', tag.get('name'));
    }

    return parsedTags;
  }, Map());
};

const getAllSelectedRows = (rows) => {
  return rows.reduce((all, row) => {
    all = all.concat(row);
    if (row.subRows.length > 0) {
      all = all.concat(getAllSelectedRows(row.subRows));
    }
    return [...new Set(all)];
  }, []);
};

const setParameterToAllMapings = (mappings, value) => {
  return mappings.map((mapping) => {
    if (startsWith(value, 'limit')) {
      return mapping.set('limit', parseInt(strRight(value, 'limit:'), 10)).delete('changed_since');
    }

    if (startsWith(value, 'changed_since')) {
      return mapping.set('changed_since', strRight(value, 'changed_since:')).delete('limit');
    }

    return mapping.delete('limit').delete('changed_since');
  });
};

const getCommonParameterForAllMapings = (mappings) => {
  if (mappings.some((mapping) => mapping.has('limit'))) {
    const limit = mappings.find((mapping) => mapping.has('limit')).get('limit');

    return `limit:${limit}`;
  }

  if (mappings.some((mapping) => mapping.has('changed_since'))) {
    const changed_since = mappings
      .find((mapping) => mapping.has('changed_since'))
      .get('changed_since');

    return `changed_since:${changed_since}`;
  }

  return '';
};

export {
  getTagsForComponentConfiguration,
  getTagsForComponentConfigurationRow,
  parseComponentConfigurationAndRowFromSourceTags,
  getAllSelectedRows,
  setParameterToAllMapings,
  getCommonParameterForAllMapings
};
