import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import type { Promise } from 'bluebird';
import type { Map } from 'immutable';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';
import DescriptionControl from './DescriptionControl';
import NameControl from './NameControl';

type ModalProps = {
  show: boolean;
  onSubmit: (id: string, params: { name: string; description: string }) => Promise<void>;
  onHide: () => void;
  devBranch: Map<string, any>;
  devBranches: Map<string, any>;
};

class UpdateDevBranchModal extends React.Component<ModalProps> {
  state = {
    name: '',
    description: '',
    isSaving: false,
    warning: '',
    error: ''
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() =>
          this.setState({
            name: this.props.devBranch.get('name'),
            description: this.props.devBranch.get('description')
          })
        }
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit development branch</Modal.Title>
            <ModalIcon icon={['far', 'code-branch']} color="orange" bold />
          </Modal.Header>
          <Modal.Body>
            <NameControl
              value={this.state.name}
              onChange={this.handleNameChange}
              error={this.state.error}
              warning={this.state.warning}
            />
            <DescriptionControl
              value={this.state.description}
              onChange={(description) => this.setState({ description })}
            />
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={this.state.isSaving ? 'Saving changes...' : 'Save changes'}
              isSaving={this.state.isSaving}
              isDisabled={
                !this.state.name.trim().length ||
                !!this.state.warning ||
                (this.props.devBranch.get('name') === this.state.name.trim() &&
                  this.props.devBranch.get('description') === this.state.description.trim())
              }
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  handleNameChange = (value: string) => {
    let warning = '';

    if (
      this.props.devBranches
        .filter((branch) => branch.get('id') !== this.props.devBranch.get('id'))
        .find((branch) => branch.get('name').toLowerCase() === value.toLowerCase().trim())
    ) {
      warning = `There already is a branch with name "${value.trim()}"`;
    }

    this.setState({ name: value, warning });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ isSaving: true });

    return this.props
      .onSubmit(this.props.devBranch.get('id'), {
        name: this.state.name.trim(),
        description: this.state.description.trim()
      })
      .then(this.props.onHide)
      .catch((error) => this.setState({ ...(error && { error: error.message }) }))
      .finally(() => this.setState({ isSaving: false }));
  };
}

export default UpdateDevBranchModal;
