import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { fromJS, Map } from 'immutable';
import _ from 'underscore';

import { isValidJsonConfig } from '../../../../utils/validation';
import WizardButtons from '../../../components/react/components/WizardButtons';
import TabbedWizard, { AUTH_KEY, CRAWLER_KEY, OPTIONS_KEY } from './TabbedWizard';

const SetupModal = createReactClass({
  propTypes: {
    onHideFn: PropTypes.func,
    show: PropTypes.bool.isRequired,
    localState: PropTypes.object.isRequired,
    parameters: PropTypes.object.isRequired,
    inputTableId: PropTypes.string,
    updateLocalState: PropTypes.func.isRequired,
    prepareLocalState: PropTypes.func.isRequired,
    loadActors: PropTypes.func.isRequired,
    loadTasks: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    buckets: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired
  },

  render() {
    const step = this.step();
    return (
      <Modal bsSize="large" show={this.props.show} onHide={this.props.onHideFn}>
        <Modal.Header closeButton>
          <Modal.Title>Setup Component</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderWizard()}</Modal.Body>
        <Modal.Footer>
          <WizardButtons
            onNext={() => this.cycleTab(1)}
            onPrevious={() => this.cycleTab(-1)}
            onSave={this.handleSave}
            onCancel={this.props.onHideFn}
            isSaving={this.props.isSaving}
            isSaveDisabled={!this.canSave()}
            isNextDisabled={!this.canNext()}
            isPreviousDisabled={step === 1}
            showNext={step < 3}
            showSave
            savingMessage=""
          />
        </Modal.Footer>
      </Modal>
    );
  },

  getInputTableId() {
    return this.localState('inputTableId', this.props.inputTableId);
  },

  handleSave() {
    let inputSetting = JSON.parse(this.getSettings());
    inputSetting = _.isEmpty(inputSetting) ? null : fromJS(inputSetting);
    const parameters = this.parameters();
    const action = this.getAction();
    let paramsToSave = Map();

    if (action === 'dataset') {
      paramsToSave = Map({
        actionType: 'getDatasetItems',
        datasetId: parameters.get('datasetId')
      });
    } else if (action === 'actor') {
      paramsToSave = Map({
        actionType: 'runActor',
        memory: parameters.get('memory') || '2048',
        build: parameters.get('build') || 'latest',
        input: inputSetting || {},
        actId: parameters.get('actId')
      });
    } else if (action === 'actor-last-run') {
      paramsToSave = Map({
        actionType: 'getActorLastRunDatasetItems',
        actId: parameters.get('actId')
      });
    } else if (action === '') {
      paramsToSave = Map({
        actionType: 'runActor',
        memory: parameters.get('memory') || '2048',
        build: parameters.get('build') || 'latest',
        input: inputSetting || {},
        actId: parameters.get('actId')
      });
    } else if (action === 'task') {
      paramsToSave = Map({
        actionType: 'runTask',
        memory: parameters.get('memory') || '2048',
        build: parameters.get('build') || 'latest',
        input: inputSetting || {},
        actorTaskId: parameters.get('actorTaskId')
      });
    } else if (action === 'task-last-run') {
      paramsToSave = Map({
        actionType: 'getTaskLastRunDatasetItems',
        actorTaskId: parameters.get('actorTaskId')
      });
    }

    if (parameters.get('fields')) {
      paramsToSave = paramsToSave.set('fields', parameters.get('fields'));
    }

    this.props.onSave(paramsToSave.set('#token', parameters.get('#token')), this.getInputTableId());
  },

  canNext() {
    const step = this.step();
    if (step === AUTH_KEY) return this.hasAuth();
    return true;
  },

  getSettings() {
    const settingsKey = ['actor', 'task'].includes(this.getAction()) ? 'input' : 'crawlerSettings';
    let defaultValue = this.props.parameters.get(settingsKey, Map()) || Map();
    defaultValue = JSON.stringify(defaultValue, null, '  ');
    return this.localState('settings', defaultValue);
  },

  hasAuth() {
    return !!this.parameters().get('#token');
  },

  getAction() {
    const params = this.parameters();
    return params.get('action', this.getDefaultAction(params));
  },

  getDefaultAction(params) {
    switch (params.get('actionType')) {
      case 'getDatasetItems':
        return 'dataset';

      case 'runTask':
        return 'task';

      case 'getTaskLastRunDatasetItems':
        return 'task-last-run';

      case 'runActor':
        return 'actor';

      case 'getActorLastRunDatasetItems':
        return 'actor-last-run';

      default:
        return 'actor';
    }
  },

  canSave() {
    if (!this.hasAuth()) {
      return false;
    }

    if (this.getAction() === 'dataset') {
      return !!this.parameters().get('datasetId');
    }

    if (
      this.localState(['tasks', 'loading'], false) ||
      this.localState(['actors', 'loading'], false)
    ) {
      return false;
    }

    if (this.getAction() === 'task') {
      return this.parameters().get('actorTaskId') && isValidJsonConfig(this.getSettings());
    }

    if (this.getAction() === 'task-last-run') {
      return !!this.parameters().get('actorTaskId');
    }

    if (this.getAction() === 'actor-last-run') {
      return !!this.parameters().get('actId');
    }

    return this.parameters().get('actId') && isValidJsonConfig(this.getSettings());
  },

  cycleTab(delta) {
    let step = this.step();

    switch (step) {
      case CRAWLER_KEY:
        step = AUTH_KEY;
        break;
      case AUTH_KEY:
        if (delta === 1) {
          step = OPTIONS_KEY;
        } else {
          step = CRAWLER_KEY;
        }
        break;
      case OPTIONS_KEY:
        step = AUTH_KEY;
        break;
    }

    if (step === OPTIONS_KEY && this.getAction() === 'actor') this.onLoadActors();
    if (step === OPTIONS_KEY && this.getAction() === 'task') this.onLoadTasks();
    this.updateLocalState('step', step);
  },

  renderWizard() {
    return (
      <TabbedWizard
        loadActors={this.onLoadActorsForce}
        loadTasks={this.onLoadTasksForce}
        actors={this.localState('actors', Map())}
        tasks={this.localState('tasks', Map())}
        step={this.step()}
        action={this.getAction()}
        selectTab={(s) => this.updateLocalState('step', s)}
        localState={this.props.localState}
        inputTableId={this.getInputTableId()}
        updateInputTableId={(tableId) => this.updateLocalState('inputTableId', tableId)}
        parameters={this.parameters()}
        updateParameters={(parameters) => this.updateLocalState('parameters', parameters)}
        settings={this.getSettings()}
        updateSettings={(val) => this.updateLocalState('settings', val)}
        buckets={this.props.buckets}
        tables={this.props.tables}
      />
    );
  },

  onLoadActors() {
    if (this.localState('actors')) return;
    this.onLoadActorsForce();
  },

  onLoadActorsForce() {
    this.updateLocalState(['actors'], Map({ loading: true, error: null }));
    this.props
      .loadActors(this.parameters())
      .then(
        (data) => {
          const isError = data.status === 'error' || data.timeout;
          const actors = {
            data: !isError ? data : null,
            loading: false,
            error: isError ? 'Error: ' + data.message : null
          };
          return this.updateLocalState('actors', fromJS(actors));
        },
        (err) => this.updateLocalState('actors', fromJS({ data: null, error: err, loading: false }))
      )
      .catch(() =>
        this.updateLocalState(
          'actors',
          fromJS({ loading: false, data: null, error: 'Error Loading Actors' })
        )
      );
  },

  onLoadTasks() {
    if (this.localState('tasks')) return;
    this.onLoadTasksForce();
  },

  onLoadTasksForce() {
    this.updateLocalState(['tasks'], Map({ loading: true, error: null }));
    this.props
      .loadTasks(this.parameters())
      .then(
        (data) => {
          const isError = data.status === 'error' || data.timeout;
          const tasks = {
            data: !isError ? data : null,
            loading: false,
            error: isError ? 'Error: ' + data.message : null
          };
          return this.updateLocalState('tasks', fromJS(tasks));
        },
        (err) => this.updateLocalState('tasks', fromJS({ data: null, error: err, loading: false }))
      )
      .catch(() =>
        this.updateLocalState(
          'tasks',
          fromJS({ loading: false, data: null, error: 'Error Loading Tasks' })
        )
      );
  },

  localState(key, defaultValue) {
    return this.props.localState.getIn([].concat(key), defaultValue);
  },

  parameters() {
    return this.localState('parameters', this.props.parameters);
  },

  step() {
    return this.localState('step', CRAWLER_KEY);
  },

  updateLocalState(path, value) {
    this.props.updateLocalState(path, value);
  }
});

export default SetupModal;
