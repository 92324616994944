import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import useStores from '../../../react/hooks/useStores';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import ComponentsStore from '../../components/stores/ComponentsStore';
import ComponentBoxModal from './ComponentBoxModal';

const ComponentHeaderButton = () => {
  const [showModal, setShowModal] = useState(false);
  const { allComponents, component, readOnly } = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();

      return {
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        component: ComponentsStore.getComponent(componentId) as Map<string, any>,
        readOnly: ApplicationStore.isReadOnly()
      };
    },
    [],
    [ApplicationStore, RoutesStore, ComponentsStore]
  );

  return (
    <>
      <Button onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon="circle-info" className="icon-addon-right" />
        Component Info
      </Button>
      <ComponentBoxModal
        showButtons={false}
        show={showModal}
        readOnly={readOnly}
        allComponents={allComponents}
        component={component}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default ComponentHeaderButton;
