import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { NEW_ENTITY_BUTTON } from '../../../../constants/external';
import ConfirmButtons from '../../../../react/common/ConfirmButtons';
import ModalIcon from '../../../../react/common/ModalIcon';
import Select from '../../../../react/common/Select';
import ApplicationStore from '../../../../stores/ApplicationStore';
import ActionCreators from '../../ActionCreators';
import { backends, transformationType } from '../../Constants';

function prepareDataForCreate(data) {
  let newData = Map({
    name: data.get('name').trim(),
    description: data.get('description')
  });

  switch (data.get('backend')) {
    case backends.REDSHIFT:
      newData = newData
        .set('backend', 'redshift')
        .set('type', 'simple')
        .set('queries', [
          `-- This is a sample query.
-- Adjust accordingly to your input mapping, output mapping
-- and desired functionality.
-- CREATE TABLE "out_table" AS SELECT * FROM "in_table";`
        ]);
      break;

    case backends.SNOWFLAKE:
      newData = newData
        .set('backend', 'snowflake')
        .set('type', 'simple')
        .set('queries', [
          `-- This is a sample query.
-- Adjust accordingly to your input mapping, output mapping
-- and desired functionality.
-- CREATE TABLE "out_table" AS SELECT * FROM "in_table";`
        ]);
      break;

    case transformationType.R:
      newData = newData
        .set('backend', 'docker')
        .set('type', 'r')
        .set('queries', [
          `# This is a sample script.
# Adjust accordingly to your input mapping, output mapping
# and desired functionality.
# input_data <- read.csv(file = "in/tables/input.csv");
# result <- input_data
# write.csv(result, file = "out/tables/output.csv", row.names = FALSE)`
        ]);
      break;

    case transformationType.PYTHON:
      newData = newData
        .set('backend', 'docker')
        .set('type', 'python')
        .set('queries', [
          `# This is a sample script.
# Adjust accordingly to your input mapping, output mapping
# and desired functionality.

'''
import csv

with open('in/tables/input.csv', mode='rt', encoding='utf-8') as in_file, open('out/tables/output.csv', mode='wt', encoding='utf-8') as out_file:
    lazy_lines = (line.replace('\\0', '') for line in in_file)
    reader = csv.DictReader(lazy_lines, lineterminator='\\n')
    writer = csv.DictWriter(out_file, fieldnames=reader.fieldnames, lineterminator='\\n')
    writer.writeheader()

    for row in reader
        # do something and write row
        writer.writerow(row)
'''`
        ]);
      break;

    case transformationType.OPENREFINE:
      newData = newData.set('backend', 'docker').set('type', 'openrefine');
      break;

    case transformationType.JULIA:
      newData = newData
        .set('backend', 'docker')
        .set('type', 'julia')
        .set('queries', [
          `# This is a sample script.
# Adjust accordingly to your input mapping, output mapping
# and desired functionality.

#=
using CSV
using DataFrames

println(readdir("/data/in/tables"))
infile = "/data/in/tables/source.csv"
outfile = "/data/out/tables/destination.csv"
CSV.write(outfile, DataFrame(column1 = String[], column2 = String[]), writeheader = true)
for row in CSV.File(infile)
    # do something with row.column and write a new row
    row = DataFrame(column1 = "value1", column2 = "value2")
    CSV.write(outfile, row, writeheader = false, append = true)
end
=#`
        ]);
      break;

    default:
      console.error('Unknown backend ' + data.get('backend'));
  }

  return newData;
}

const NewTransformation = createReactClass({
  propTypes: {
    bucket: PropTypes.object.isRequired
  },

  getInitialState() {
    return {
      data: Map({
        isSaving: false,
        name: '',
        description: '',
        backend: ApplicationStore.getCurrentProject().get('defaultBackend')
      }),
      showModal: false
    };
  },

  open() {
    this.setState({
      showModal: true
    });
  },

  close() {
    this.setState({
      showModal: false
    });
  },

  renderModal() {
    return (
      <Modal onHide={this.close} show={this.state.showModal}>
        <Form horizontal onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>New Transformation</Modal.Title>
            <ModalIcon.Plus />
          </Modal.Header>
          <Modal.Body>
            <HelpBlock>
              Create new transformation in bucket <strong>{this.props.bucket.get('name')}</strong>
            </HelpBlock>
            <FormGroup>
              <Col sm={3} componentClass={ControlLabel}>
                Name
              </Col>
              <Col sm={9}>
                <FormControl
                  autoFocus
                  type="text"
                  value={this.state.data.get('name')}
                  onChange={this.handleChange.bind(this, 'name')}
                  placeholder="Name"
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3} componentClass={ControlLabel}>
                Description
              </Col>
              <Col sm={9}>
                <FormControl
                  rows={3}
                  componentClass="textarea"
                  value={this.state.data.get('description')}
                  onChange={this.handleChange.bind(this, 'description')}
                  placeholder="Description"
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3} componentClass={ControlLabel}>
                Backend
              </Col>
              <Col sm={9}>
                <Select
                  clearable={false}
                  value={this.state.data.get('backend')}
                  onChange={(value) => {
                    this.setState({ data: this.state.data.set('backend', value) });
                  }}
                  options={this.backendOptions()}
                />
              </Col>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel="Create Transformation"
              isSaving={this.state.data.get('isSaving')}
              isDisabled={!this.isValid()}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  render() {
    return (
      <>
        <Button
          onClick={this.handleOpenButtonClick}
          bsStyle="success"
          className={NEW_ENTITY_BUTTON}
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          New Transformation
        </Button>
        {this.renderModal()}
      </>
    );
  },

  handleOpenButtonClick(e) {
    e.preventDefault();
    this.open();
  },

  backendOptions() {
    const options = [
      { value: 'snowflake', label: 'Snowflake' },
      { value: 'r', label: 'R' },
      { value: 'python', label: 'Python' },
      { value: 'openrefine', label: 'OpenRefine (beta)' },
      { value: 'julia', label: 'Julia' }
    ];

    if (ApplicationStore.getSapiToken().getIn(['owner', 'hasRedshift'], false)) {
      options.splice(1, 0, { value: 'redshift', label: 'Redshift' });
    }

    return options;
  },

  isValid() {
    return this.state.data.get('name').trim().length > 0;
  },

  handleChange(field, e) {
    this.setState({
      data: this.state.data.set(field, e.target.value)
    });
  },

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ data: this.state.data.set('isSaving', true) });
    const bucketId = this.props.bucket.get('id');
    ActionCreators.createTransformation(bucketId, prepareDataForCreate(this.state.data))
      .then(this.close)
      .catch((error) => {
        this.setState({ data: this.state.data.set('isSaving', false) });
        throw error;
      });
  }
});

export default NewTransformation;
