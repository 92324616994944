import { fromJS } from 'immutable';

export default fromJS({
  DATE: {
    name: 'DATE',
    basetype: 'DATE',
    size: false
  },
  NUMBER: {
    name: 'NUMBER',
    basetype: 'NUMERIC',
    size: true,
    maxLength: '38,127'
  },
  TIMESTAMP: {
    name: 'TIMESTAMP',
    basetype: 'TIMESTAMP',
    size: false
  },
  VARCHAR2: {
    name: 'VARCHAR2',
    basetype: 'STRING',
    size: true,
    maxLength: 32767
  }
});
