import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { Map } from 'immutable';

import Tooltip from '../../../react/common/Tooltip';

const PhaseInput = (props: {
  phase: Map<string, any>;
  previousPhase: Map<string, any>;
  newTaskPosition: null | string;
  handleShowAddTaskModal: (position: string | false) => void;
  readOnly: boolean;
  isLast: boolean;
}) => {
  if (props.readOnly) {
    return null;
  }

  const inlineButtonId = `${props.previousPhase.get('id')}:${props.phase.get('id')}`;

  return (
    <>
      {!props.isLast && (
        <div className="between-phases-action">
          <Tooltip
            placement="top"
            tooltip={props.newTaskPosition === inlineButtonId ? 'Close' : 'Add Task'}
          >
            <span className="add-task-inline-container">
              <Button
                bsStyle="link"
                className={classNames('add-task-inline btn-link-inline circle-button', {
                  active: props.newTaskPosition === inlineButtonId
                })}
                onClick={() => props.handleShowAddTaskModal(inlineButtonId)}
              >
                <FontAwesomeIcon
                  fixedWidth
                  className="f-14"
                  icon={props.newTaskPosition === inlineButtonId ? 'xmark' : 'plus'}
                />
              </Button>
            </span>
          </Tooltip>
        </div>
      )}
      <FontAwesomeIcon icon="caret-down" className="f-16" />
    </>
  );
};

export default PhaseInput;
