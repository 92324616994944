import React, { useEffect, useState } from 'react';

import RouterLink from '../../react/common/RouterLink';
import { generateSessionToken } from '../settings/actions';
import { getQueriesToRun } from './actions';
import { routeNames } from './constants';

const UpgradeBar = () => {
  const [currentStep, setCurrentStep] = useState<number>();

  useEffect(() => {
    generateSessionToken()
      .then(() => getQueriesToRun())
      .then((queries) => setCurrentStep(queries.length ? 1 : 2));
  }, []);

  if (!currentStep) return null;

  return (
    <div className="top-sticky-bar">
      {currentStep === 1
        ? 'Ready to tap into the full potential of Keboola?'
        : 'You have successfully granted us privileges.'}
      <RouterLink to={routeNames.UPGRADE_PAGE} className="btn btn-success">
        {currentStep === 1 ? 'Enable all features' : 'Confirm'}
      </RouterLink>
    </div>
  );
};

export default UpgradeBar;
