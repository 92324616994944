import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Table } from 'react-bootstrap';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS } from 'immutable';

import ColumnDataPreview from '../../../components/react/components/ColumnDataPreview';
import { dataPreview } from '../../../storage/actions';

const StorageTableColumnsEditor = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      tableExist: PropTypes.bool,
      matchColumnKey: PropTypes.string,
      tableId: PropTypes.string,
      columns: PropTypes.any,
      columnsMappings: PropTypes.any,
      context: PropTypes.any,
      getInitialShowAdvanced: PropTypes.func,
      isColumnValidFn: PropTypes.func
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    isDevModeActive: PropTypes.bool.isRequired
  },

  getInitialState() {
    return {
      loadingPreview: false,
      dataPreview: null,
      showAdvanced: this.props.value.getInitialShowAdvanced(this.props.value.columns)
    };
  },

  fetchData() {
    if (!this.props.value.tableExist || !this.props.value.tableId || this.state.loadingPreview) {
      return;
    }

    this.setState({ loadingPreview: true });
    dataPreview(this.props.value.tableId, { limit: 100 })
      .then((json) => this.setState({ dataPreview: fromJS(json) }))
      .finally(() => this.setState({ loadingPreview: false }));
  },

  renderHeaderCell(Element) {
    return (
      <Element
        showAdvanced={this.state.showAdvanced}
        onChangeShowAdvanced={(newValue) => this.setState({ showAdvanced: newValue })}
      />
    );
  },

  render() {
    let headers = this.props.value.columnsMappings.map((mapping) => mapping.title);

    return (
      <>
        {!this.props.value.tableExist && !this.props.isDevModeActive && (
          <Alert bsStyle="warning" className="box-separator">
            <h4>Table Missing</h4>
            <p>
              Table <code>{this.props.value.tableId}</code> used in this configuration is missing in
              Storage. Running this configuration will fail.
            </p>
          </Alert>
        )}
        {this.props.value.columns.length > 0 && (
          <>
            <div className="box">
              <div className="box-content pt-1 pb-0">
                <h2>Columns</h2>
              </div>
              <Table>
                <thead>
                  <tr>
                    <th className="w-150">Column Name</th>
                    {headers.map((title, index) => (
                      <th key={index}>
                        {typeof title === 'string' ? title : this.renderHeaderCell(title)}
                      </th>
                    ))}
                    <th />
                  </tr>
                </thead>
                {this.renderBody()}
              </Table>
            </div>
          </>
        )}
      </>
    );
  },

  onChangeColumn(newValue) {
    const { matchColumnKey } = this.props.value;
    const newColumns = this.props.value.columns.map((column) =>
      column[matchColumnKey] === newValue[matchColumnKey] ? newValue : column
    );
    this.props.onChange({ columns: newColumns });
  },

  renderBody() {
    const { matchColumnKey } = this.props.value;
    return (
      <tbody>
        {this.props.value.columns.map((column, index) => (
          <tr
            key={index}
            className={classnames({ danger: !this.props.value.isColumnValidFn(column) })}
          >
            <td>{column[matchColumnKey]}</td>
            {this.props.value.columnsMappings.map((Mapping, mappingIndex) => (
              <td key={mappingIndex}>
                <Mapping.render
                  context={this.props.value.context}
                  disabled={this.props.disabled}
                  showAdvanced={this.state.showAdvanced}
                  column={column}
                  onChange={this.onChangeColumn}
                />
              </td>
            ))}
            <td onMouseEnter={this.fetchData} onFocus={this.fetchData}>
              <ColumnDataPreview
                columnName={column[matchColumnKey]}
                tableData={this.state.dataPreview}
                loading={this.state.loadingPreview}
              />
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
});

export default StorageTableColumnsEditor;
