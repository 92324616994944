import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import dayjs from '../../../../date';
import Confirm from '../../../../react/common/Confirm';
import { READ_ONLY_TOOLTIP_MESSAGE } from '../../../../react/common/ReadOnlyTooltip';
import Select from '../../../../react/common/Select';
import timeOptionCreator from '../../../../react/common/timeOptionCreator';
import InstalledComponentsActionCreators from '../../InstalledComponentsActionCreators';

const DEFAULT_TIMEOUT_OPTIONS = [
  { label: '15 minutes', value: 900 },
  { label: '30 minutes', value: 1800 },
  { label: '45 minutes', value: 2700 },
  { label: '1 hour', value: 3600 },
  { label: '1 hour 30 minutes', value: 5400 },
  { label: '2 hours', value: 7200 }
];
const DEFAULT_TIMEOUT = 7200;
const MAX_TIMEOUT = 86400;
const VALID_TIMEOUT_DIMENSIONS = ['hour', 'minute'];

class QueryTimeoutModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newTimeout: this.getCurrentTimeoutValue(),
      isLoading: false
    };

    this.createTimeOption = this.createTimeOption.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isOptionValid = this.isOptionValid.bind(this);
  }

  render() {
    return (
      <>
        <Confirm
          closeAfterResolve
          icon="gear"
          title="Set query timeout"
          onConfirm={this.handleSubmit}
          onHide={() => this.setState({ newTimeout: this.getCurrentTimeoutValue() })}
          isLoading={this.state.isLoading}
          text={this.renderBody()}
          buttonType="primary"
          buttonLabel="Set query timeout"
          childrenRootElement="button"
          childrenRootElementClass="btn btn-link btn-block"
          isDisabledConfirmButton={this.isDisabledConfirmButton()}
          isDisabled={this.props.readOnly}
          {...(this.props.readOnly && { disabledReason: READ_ONLY_TOOLTIP_MESSAGE })}
        >
          <FontAwesomeIcon icon="gear" fixedWidth />
          Query Timeout:{' '}
          <span className="tw-font-medium">
            {this.getTimeOptionFromSeconds(this.getCurrentTimeoutValue())?.label}
          </span>
        </Confirm>
      </>
    );
  }

  renderBody() {
    return (
      <FormGroup>
        <ControlLabel>Query timeout</ControlLabel>
        <Select
          autoFocus
          allowCreate
          value={this.state.newTimeout}
          options={this.getOptions()}
          noResultsText="Invalid value"
          onChange={(newTimeout) => this.setState({ newTimeout })}
          isValidNewOption={this.isOptionValid}
          newOptionCreator={this.createTimeOption}
          promptTextCreator={(label) => this.createTimeOptionLabel(label) || 'Invalid value'}
        />
        <HelpBlock>
          Type in any range, e.g., <code>2 hours</code>. We support <code>minutes</code> and{' '}
          <code>hours</code> dimensions. Maximum limit is 24 hours.
        </HelpBlock>
      </FormGroup>
    );
  }

  handleSubmit() {
    this.setState({ isLoading: true });

    return InstalledComponentsActionCreators.updateComponentConfiguration(
      this.props.componentId,
      this.props.config.get('id'),
      {
        configuration: JSON.stringify(
          this.props.config
            .get('configuration')
            .setIn(['parameters', 'query_timeout'], this.state.newTimeout)
            .toJS()
        )
      },
      'Change query timeout'
    ).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  createTimeOptionLabel(timeout) {
    return timeOptionCreator(timeout, VALID_TIMEOUT_DIMENSIONS);
  }

  createTimeOption(value) {
    const newLabel = this.createTimeOptionLabel(value);

    if (!newLabel) {
      return false;
    }

    const [timeValue, timeKey] = newLabel.split(' ');
    const newValue = dayjs.duration(parseInt(timeValue, 10), timeKey).asSeconds();

    return {
      label: newLabel,
      value: newValue
    };
  }

  isOptionValid(inputValue) {
    if (!inputValue) {
      return false;
    }

    const { label, value } = this.createTimeOption(inputValue);

    return !!label && value <= MAX_TIMEOUT;
  }

  isExistingOption(options, timeoutInSeconds) {
    return options.some(({ value }) => value === timeoutInSeconds);
  }

  getOptions() {
    let options = DEFAULT_TIMEOUT_OPTIONS;

    if (!this.isExistingOption(options, this.getCurrentTimeoutValue())) {
      options = options.concat(this.getTimeOptionFromSeconds(this.getCurrentTimeoutValue()));
    }

    if (!this.isExistingOption(options, this.state.newTimeout)) {
      options = options.concat(this.getTimeOptionFromSeconds(this.state.newTimeout));
    }

    return options.sort((first, second) => first.value - second.value).filter(Boolean);
  }

  getCurrentTimeoutValue() {
    return (
      this.props.config.getIn(['configuration', 'parameters', 'query_timeout']) ||
      this.props.defaultTimeout ||
      DEFAULT_TIMEOUT
    );
  }

  getTimeOptionFromSeconds(timeoutInSeconds) {
    return this.createTimeOption(`${dayjs.duration(timeoutInSeconds, 'second').humanize()}`);
  }

  isDisabledConfirmButton() {
    return (
      !this.state.newTimeout ||
      this.getCurrentTimeoutValue() === this.state.newTimeout ||
      !!this.createTimeOptionLabel(this.state.newTimeout)
    );
  }
}

QueryTimeoutModal.propTypes = {
  config: PropTypes.instanceOf(Map).isRequired,
  componentId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  defaultTimeout: PropTypes.number
};

export default QueryTimeoutModal;
