import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Map } from 'immutable';

import { canManageSharedBucket } from '../../admin/privileges';
import EditSharingModal from './EditSharingModal';

class EditSharingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  render() {
    if (!canManageSharedBucket(this.props.sapiToken)) {
      return null;
    }

    return (
      <>
        <Button
          bsSize="sm"
          onClick={() => {
            this.setState({
              showModal: true
            });
          }}
        >
          Edit
        </Button>
        <EditSharingModal
          bucket={this.props.bucket}
          sapiToken={this.props.sapiToken}
          availableUsersOptions={this.props.availableUsersOptions}
          availableProjectsOptions={this.props.availableProjectsOptions}
          show={this.state.showModal}
          closeModalFn={() => {
            this.setState({ showModal: false });
          }}
        />
      </>
    );
  }
}

EditSharingButton.propTypes = {
  bucket: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  availableUsersOptions: PropTypes.array.isRequired,
  availableProjectsOptions: PropTypes.array.isRequired
};

export default EditSharingButton;
