import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import { event as d3Event, select, selectAll } from 'd3-selection';
import { fromJS, List, Map } from 'immutable';
import { capitalize } from 'underscore.string';

import { formatAbsolute } from '../../../react/common/CreatedDate';
import LoadingBlock from '../../../react/common/LoadingBlock';
import RoutesStore from '../../../stores/RoutesStore';
import dimple from '../../../utils/dimple';
import { durationInWords } from '../../../utils/duration';
import nextTick from '../../../utils/nextTick';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../../utils/windowOpen';
import { defaultTransformationBackendSize } from '../../components/Constants';
import JobsApi from '../api';
import { JOBS_LIMIT_FOR_GRAPH, JOBS_STATUS, routeNames } from '../constants';
import { prepareGraphData } from '../helpers';

const COLORS_MAP = {
  [JOBS_STATUS.SUCCESS]: '#51e051',
  [JOBS_STATUS.WARNING]: '#b88d00',
  [JOBS_STATUS.TERMINATED]: '#7C8594',
  [JOBS_STATUS.ERROR]: '#EC001D'
};

class JobsGraphWithPaging extends React.Component {
  graphRef = null;

  constructor(props) {
    super(props);

    this.state = {
      graphData: null,
      allJobRunsPaged: Map(),
      currentJobRunsPage: -1,
      loadingJobs: false
    };

    this._refreshGraph = this._refreshGraph.bind(this);
  }

  componentDidMount() {
    this.loadInitialJobsAndInitGraph();
  }

  componentDidUpdate(prevProps) {
    if (!this.state.graphData && !prevProps.job && this.props.job) {
      this.loadInitialJobsAndInitGraph();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._refreshGraph);
    window.removeEventListener('focus', this._refreshGraph);
  }

  restyleGraph() {
    if (!this.chart) return;

    const jobs = this.getJobs();

    selectAll('.dimple-axis-x text.dimple-custom-axis-label').each((index, value, shapes) => {
      if (!shapes[index]) return;

      const job = jobs.find((job) => job.index === index);
      let text = '';

      if (job?.duration) {
        const date = formatAbsolute(job.date);
        const includesYear = date.includes(',');

        if (includesYear) {
          shapes[index].classList.add('with-year');
        } else {
          shapes[index].classList.remove('with-year');
        }

        const [month, day, time, suffix = ''] = formatAbsolute(job.date).split(' ');

        if (month && day && time) {
          text = `<tspan x="32">${month} ${day}</tspan><tspan x="32" dy="16px">${time} ${suffix}</tspan>`;
        }
      }

      select(shapes[index]).html(text);
    });
  }

  showTooltip(data, shape, chart, barSeries) {
    dimple._showBarTooltip(data, shape, chart, barSeries);
    nextTick(() => select('.dimple-tooltip').classed(data.aggField[5], true));
  }

  initializeGraph() {
    if (!this.state.graphData) {
      return;
    }

    const svg = dimple.newSvg(this.graphRef, '100%', 240);
    const chart = new dimple.chart(svg, this.getJobs());

    const xAxis = chart.addCategoryAxis('x', 'index');
    xAxis.title = '';

    const yAxis = chart.addMeasureAxis('y', 'duration');
    yAxis.title = '';
    yAxis.ticks = 3;

    yAxis._getFormat = () => (value) => {
      const referenceJob = this.getJobs().find((job) => !!job.status);
      return referenceJob ? durationInWords(value, referenceJob.unit) : '';
    };

    const barSeries = chart.addSeries(
      ['unit', 'date', 'backendType', 'jobId', 'isCurrentJob', 'status'],
      dimple.plot.bar
    );
    barSeries.getTooltipText = (e) =>
      [
        `${capitalize(e.aggField[5])}${e.aggField[5] === JOBS_STATUS.SUCCESS ? '!' : ''}`,
        `Created: ${formatAbsolute(e.aggField[1])}`,
        `Duration: ${durationInWords(e.yValueList[0], e.aggField[0])}`,
        this.props.showBackendSize &&
          defaultTransformationBackendSize &&
          `Backend size: ${capitalize(e.aggField[2] || defaultTransformationBackendSize, true)}`
      ].filter(Boolean);

    chart.assignColor(JOBS_STATUS.ERROR, COLORS_MAP[JOBS_STATUS.ERROR]);
    chart.assignColor(JOBS_STATUS.SUCCESS, COLORS_MAP[JOBS_STATUS.SUCCESS]);
    chart.assignColor(JOBS_STATUS.WARNING, COLORS_MAP[JOBS_STATUS.WARNING]);
    chart.assignColor(JOBS_STATUS.TERMINATED, COLORS_MAP[JOBS_STATUS.TERMINATED]);
    chart.setMargins(74, 30, 20, 50);
    chart.draw(200);

    barSeries.afterDraw = (shape, data) => {
      shape.classList.add('clickable');

      const status = data.aggField[5];

      if (
        ![
          JOBS_STATUS.ERROR,
          JOBS_STATUS.SUCCESS,
          JOBS_STATUS.WARNING,
          JOBS_STATUS.TERMINATED
        ].includes(status)
      )
        return;

      const rect = select(shape)
        .on('mousedown', () => simulateClickIfMiddleMouseIsUsed.mousedown(d3Event))
        .on('mouseup', () => simulateClickIfMiddleMouseIsUsed.mouseup(d3Event))
        .on('mouseover', () => this.showTooltip(data, shape, chart, barSeries))
        .on('click', () => this.goToJob(data.aggField[3]));

      select(shape.parentNode)
        .append('circle')
        .attr('class', `clickable dimple-status-circle`)
        .attr('cx', parseFloat(rect.attr('x')) + 6)
        .attr('cy', parseFloat(rect.attr('y')) - 12)
        .attr('r', 4)
        .attr('fill', COLORS_MAP[status])
        .on('mouseover', () => this.showTooltip(data, shape, chart, barSeries))
        .on('mouseleave', () => dimple._removeTooltip(data, shape, chart, barSeries))
        .on('mousedown', () => simulateClickIfMiddleMouseIsUsed.mousedown(d3Event))
        .on('mouseup', () => simulateClickIfMiddleMouseIsUsed.mouseup(d3Event))
        .on('click', () => this.goToJob(data.aggField[3]));
    };

    this.chart = chart;

    this.restyleGraph();

    window.addEventListener('resize', this._refreshGraph);
    window.addEventListener('focus', this._refreshGraph);
  }

  _refreshGraph(e) {
    if (!this.state.graphData || !this.chart) {
      return;
    }

    // clean custom icons before redraw
    this.chart.svg.selectAll('.dimple-status-circle').remove();

    if (!e) {
      this.chart.data = this.getJobs();
    }

    this.chart.draw(0, !!e);

    this.restyleGraph();
  }

  prepareGraphData(pageNumber, allJobRunsPaged = this.state.allJobRunsPaged) {
    return prepareGraphData(
      allJobRunsPaged.get(`${pageNumber}`, List()),
      { onlyJobs: true },
      this.props.job.get('id')
    );
  }

  hasMorePreviousJobsAvailable() {
    return !this.state.allJobRunsPaged
      .get(`${this.state.currentJobRunsPage - 1}`, List())
      .isEmpty();
  }

  hasMoreNextJobsAvailable() {
    return !this.state.allJobRunsPaged
      .get(`${this.state.currentJobRunsPage + 1}`, List())
      .isEmpty();
  }

  getJobs() {
    let jobs = this.state.graphData.get('jobs');
    const jobsCount = jobs.count();

    if (JOBS_LIMIT_FOR_GRAPH && jobsCount < JOBS_LIMIT_FOR_GRAPH) {
      const emptyEntries = fromJS(
        new Array(JOBS_LIMIT_FOR_GRAPH - jobsCount).fill({ duration: 0 })
      );

      jobs =
        this.state.currentJobRunsPage > -2 && this.hasMorePreviousJobsAvailable()
          ? jobs.concat(emptyEntries)
          : emptyEntries.concat(jobs);
      jobs = jobs.map((job, index) => job.set('index', index));
    }

    return jobs.toJS();
  }

  goToJob(jobId) {
    if (shouldUseNewWindow(d3Event)) {
      return windowOpen(RoutesStore.getRouter().createHref(routeNames.JOB_DETAIL, { jobId }));
    }

    return RoutesStore.getRouter().transitionTo(routeNames.JOB_DETAIL, { jobId });
  }

  render() {
    if (!this.state.graphData || this.state.graphData.get('jobs', List()).isEmpty()) {
      if (this.state.loadingJobs) {
        return (
          <LoadingBlock
            style={{ height: '68px', background: 'transparent', margin: '50px 0 28px' }}
          />
        );
      }

      return null;
    }

    return (
      <div className="jobs-graph flex-container">
        {this.hasMorePreviousJobsAvailable() && this.renderPageButton('previous')}
        <div className="dimple-box fill-space" ref={(node) => (this.graphRef = node)} />
        {this.hasMoreNextJobsAvailable() && this.renderPageButton('next')}
      </div>
    );
  }

  renderPageButton(type) {
    return (
      <Button
        className="position-absolute circle-button larger bg-color-white with-shadow"
        onClick={() =>
          this.setState(
            (state) => {
              const currentJobRunsPage = state.currentJobRunsPage + (type === 'previous' ? -1 : 1);

              return {
                currentJobRunsPage,
                graphData: this.prepareGraphData(currentJobRunsPage)
              };
            },
            () => {
              this._refreshGraph();
              this.loadMoreJobs();
            }
          )
        }
        disabled={this.state.loadingJobs}
      >
        <FontAwesomeIcon icon={type === 'previous' ? 'chevron-left' : 'chevron-right'} />
      </Button>
    );
  }

  loadJobs(options) {
    return JobsApi.getSiblingJobs(this.props.job, { ...this.props.jobsFilters, ...options });
  }

  loadInitialJobsAndInitGraph() {
    if (!this.props.job) {
      return;
    }

    this.setState({ loadingJobs: true });
    return Promise.props({
      previousJobs: this.loadJobs({ limit: JOBS_LIMIT_FOR_GRAPH * 3, previous: true }),
      nextJobs: this.loadJobs({ limit: JOBS_LIMIT_FOR_GRAPH * 2 })
    })
      .then(({ previousJobs: prev, nextJobs: next }) => {
        let previousJobs = prev;
        let nextJobs = next;

        if (previousJobs.length <= JOBS_LIMIT_FOR_GRAPH) {
          const availableSpaceInPreviousJobs = JOBS_LIMIT_FOR_GRAPH - previousJobs.length;
          const chunkToFitInPreviousJobs = nextJobs.splice(0, availableSpaceInPreviousJobs);

          previousJobs = [...previousJobs, ...chunkToFitInPreviousJobs];
        }

        return new Promise((resolve) => {
          const allJobRunsPaged = fromJS({
            ...(previousJobs?.length > 0 && {
              [-3]: previousJobs.slice(JOBS_LIMIT_FOR_GRAPH * 2, -1),
              [-2]: previousJobs.slice(JOBS_LIMIT_FOR_GRAPH, JOBS_LIMIT_FOR_GRAPH * 2),
              [-1]: previousJobs.slice(0, JOBS_LIMIT_FOR_GRAPH)
            }),
            ...(nextJobs?.length > 1 && {
              [0]: nextJobs.slice(0, JOBS_LIMIT_FOR_GRAPH),
              [1]: nextJobs.slice(JOBS_LIMIT_FOR_GRAPH, -1)
            })
          });
          const currentJobRunsPage = previousJobs?.length > 0 ? -1 : 0;

          const graphData = this.prepareGraphData(currentJobRunsPage, allJobRunsPaged);

          this.props.onInitialGraphDataLoad?.(graphData ?? Map());
          this.setState({ allJobRunsPaged, graphData, currentJobRunsPage }, () => resolve());
        });
      })
      .then(() => this.initializeGraph())
      .finally(() => this.setState({ loadingJobs: false }));
  }

  loadMoreJobs() {
    const shouldLoadPreviousJobs = this.state.currentJobRunsPage < 0;
    const newPageNumber = this.state.currentJobRunsPage + (shouldLoadPreviousJobs ? -2 : 2);
    const offset =
      (shouldLoadPreviousJobs ? Math.abs(newPageNumber) - 1 : newPageNumber) * JOBS_LIMIT_FOR_GRAPH;

    if (
      this.state.allJobRunsPaged.get(`${newPageNumber}`) ||
      this.state.allJobRunsPaged
        .get(`${newPageNumber + (shouldLoadPreviousJobs ? 1 : -1)}`, List())
        .isEmpty()
    )
      return;

    this.setState({ loadingJobs: true });

    return this.loadJobs({ offset, previous: shouldLoadPreviousJobs })
      .then((jobs) =>
        this.setState((state) => ({
          allJobRunsPaged: state.allJobRunsPaged.set(`${newPageNumber}`, fromJS(jobs))
        }))
      )
      .finally(() => this.setState({ loadingJobs: false }));
  }
}

JobsGraphWithPaging.propTypes = {
  job: PropTypes.instanceOf(Map),
  showBackendSize: PropTypes.bool,
  jobsFilters: PropTypes.object,
  onInitialGraphDataLoad: PropTypes.func
};

JobsGraphWithPaging.defaultProps = {
  showBackendSize: false,
  jobsFilters: {}
};

export default JobsGraphWithPaging;
