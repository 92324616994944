import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import FileSize from '../../../react/common/FileSize';
import Tooltip from '../../../react/common/Tooltip';
import ApplicationStore from '../../../stores/ApplicationStore';
import { canDownloadSlicedFile } from '../../admin/privileges';
import ModalHandler from '../sliced-files-downloader/ModalHandler';

const FileLink = createReactClass({
  propTypes: {
    file: PropTypes.object,
    showFilesize: PropTypes.bool,
    linkClass: PropTypes.string,
    children: PropTypes.any
  },

  getDefaultProps() {
    return {
      showFilesize: true
    };
  },

  render() {
    if (this.props.file.get('isExpired')) {
      return this.renderDisabledLink();
    }

    if (this.props.file.get('isSliced')) {
      return this.renderSlicedFileDownloadModalTrigger();
    }

    return this.renderSimpleDownloadLink();
  },

  renderSlicedFileDownloadModalTrigger() {
    const body = (
      <>
        {this.renderBody()} {this.renderFilesize()}
      </>
    );

    if (canDownloadSlicedFile(ApplicationStore.getSapiToken())) {
      return (
        <ModalHandler file={this.props.file} linkClass={this.props.linkClass}>
          {body}
        </ModalHandler>
      );
    }

    return body;
  },

  renderSimpleDownloadLink() {
    return (
      <a href={this.props.file.get('url')} className={this.props.linkClass} download>
        {this.renderBody()} {this.renderFilesize()}
      </a>
    );
  },

  renderDisabledLink() {
    return (
      <Tooltip placement="top" tooltip="File expired">
        <span className="text-muted">{this.renderBody()}</span>
      </Tooltip>
    );
  },

  renderBody() {
    return this.props.children || this.props.file.get('name');
  },

  renderFilesize() {
    if (!this.props.showFilesize) {
      return null;
    }

    return <FileSize size={this.props.file.get('sizeBytes')} />;
  }
});

export default FileLink;
