import React from 'react';
import { Panel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const CollapsiblePanel = (props: {
  title: React.ReactNode;
  children: React.ReactNode;
  isDisabled?: boolean;
  defaultExpanded?: boolean;
  className?: string;
  bodyClassName?: string;
}) => {
  const [expanded, setExpanded] = React.useState(!!props.defaultExpanded);

  return (
    <Panel
      expanded={!props.isDisabled && expanded}
      onToggle={() => !props.isDisabled && setExpanded(!expanded)}
      className={classnames('collapsible-panel', { disabled: props.isDisabled }, props.className)}
      onClick={(event: React.MouseEvent) => event.stopPropagation()}
      onKeyUp={(event: React.KeyboardEvent) => event.stopPropagation()}
    >
      <Panel.Heading>
        <Panel.Title toggle>
          <div className="flex-container">
            <div className="flex-container flex-start">{props.title}</div>
            <FontAwesomeIcon
              fixedWidth
              className="f-16 text-muted ml-2"
              icon={['far', !props.isDisabled && expanded ? 'angle-up' : 'angle-down']}
            />
          </div>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible className={props.bodyClassName}>
        {props.children}
      </Panel.Body>
    </Panel>
  );
};

export default CollapsiblePanel;
