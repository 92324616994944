import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Immutable from 'immutable';

import InstalledComponentsActionCreators from '../../modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '../../modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '../../modules/components/stores/StorageTablesStore';
import ConfigurationRowActionCreators from '../../modules/configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '../../modules/configurations/ConfigurationRowsStore';
import { constants } from '../../modules/configurations/utils/configurationState';
import RoutesStore from '../../stores/RoutesStore';
import createStoreMixin from '../mixins/createStoreMixin';
import CreatedDate from './CreatedDate';
import ResetAutomaticLoadTypeButton from './ResetAutomaticLoadTypeButton';

const AutomaticLoadTypeLastUpdated = createReactClass({
  mixins: [
    createStoreMixin(
      RoutesStore,
      InstalledComponentsStore,
      ConfigurationRowsStore,
      StorageTablesStore
    )
  ],

  propTypes: {
    tableId: PropTypes.string.isRequired
  },

  getStateFromStores() {
    const componentId = RoutesStore.getCurrentRouteComponentId();
    const configId = RoutesStore.getConfigId();
    const rowId = RoutesStore.getRowId();

    let configuration = Immutable.Map();
    if (rowId) {
      configuration = ConfigurationRowsStore.get(componentId, configId, rowId);
    } else {
      configuration = InstalledComponentsStore.getConfig(componentId, configId);
    }

    const configurationState = configuration.get('state', Immutable.Map());
    const tableState = configurationState
      .getIn(
        [constants.STORAGE_NAMESPACE, constants.INPUT_NAMESPACE, constants.TABLES_NAMESPACE],
        Immutable.Map()
      )
      .find((item) => item.get('source') === this.props.tableId);

    return {
      componentId,
      configId,
      rowId,
      table: StorageTablesStore.getTable(this.props.tableId),
      tableState: tableState
    };
  },

  resetState() {
    if (this.state.rowId) {
      return ConfigurationRowActionCreators.clearInputMappingState(
        this.state.componentId,
        this.state.configId,
        this.state.rowId,
        this.props.tableId
      );
    }

    return InstalledComponentsActionCreators.clearInputMappingState(
      this.state.componentId,
      this.state.configId,
      this.props.tableId
    );
  },

  render() {
    if (!this.state.table) {
      return null;
    }

    if (!this.state.tableState || !this.state.tableState.get(constants.LAST_IMPORT_DATE_PROPERTY)) {
      return <div className="help-block">Table has not been loaded yet.</div>;
    }

    if (
      this.state.tableState.get(constants.LAST_IMPORT_DATE_PROPERTY) ===
      this.state.table.get(constants.LAST_IMPORT_DATE_PROPERTY)
    ) {
      return (
        <div className="help-block">
          Table contains no new data. <ResetAutomaticLoadTypeButton onClick={this.resetState} />
        </div>
      );
    }

    return (
      <div className="help-block">
        Source table updated since the previous update{' '}
        <CreatedDate createdTime={this.state.tableState.get(constants.LAST_IMPORT_DATE_PROPERTY)} />
        . <ResetAutomaticLoadTypeButton onClick={this.resetState} />
      </div>
    );
  }
});

export default AutomaticLoadTypeLastUpdated;
