import React from 'react';
import createReactClass from 'create-react-class';

import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import { canManageTokens } from '../../../admin/privileges';
import OrchestrationsStore from '../../stores/OrchestrationsStore';
import NewOrchestrationButton from '../modals/NewOrchestration';

const NewOrchestrationHeaderButton = createReactClass({
  mixins: [createStoreMixin(OrchestrationsStore, ApplicationStore)],

  getStateFromStores() {
    return {
      hasOrchestrations: OrchestrationsStore.getAll().count(),
      sapiTokenCanManageTokens: canManageTokens(ApplicationStore.getSapiToken())
    };
  },

  render() {
    if (!this.state.hasOrchestrations || !this.state.sapiTokenCanManageTokens) {
      return null;
    }

    return <NewOrchestrationButton />;
  }
});

export default NewOrchestrationHeaderButton;
