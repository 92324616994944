import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, type Map, OrderedSet } from 'immutable';

import type { Template } from '../../api/routes/templatesService';
import CircleIcon from '../../react/common/CircleIcon';
import Directory from '../../react/common/Directory';
import Loader from '../../react/common/Loader';
import RouterLink from '../../react/common/RouterLink';
import { SORT } from '../../react/common/SortSelect';
import Truncated from '../../react/common/Truncated';
import useStores from '../../react/hooks/useStores';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import string from '../../utils/string';
import ComponentsStore from '../components/stores/ComponentsStore';
import TemplateIcons from './components/TemplateIcons';
import { routeNames } from './constants';
import { getFilteredTemplates, sortTemplates } from './helpers';
import store, { type Store } from './store';

const TemplatesDirectory = () => {
  const { templates, templatesCategories, instances, allComponents, readOnly } = useStores(
    () => {
      const templatesStore = store.getStore();
      const allComponents = ComponentsStore.getAll() as Map<string, any>;

      return {
        ...templatesStore,
        allComponents,
        readOnly: ApplicationStore.isReadOnly() as boolean
      };
    },
    [],
    [store, ComponentsStore, ApplicationStore]
  );

  return (
    <Directory
      entity="Template"
      getItems={(filters: Map<string, any>) => {
        return {
          items: fromJS(
            sortTemplates(
              getFilteredTemplates(templates, filters.toJS(), allComponents),
              filters.get('sortBy')
            )
          ),
          categories: fromJS(
            getFilteredTemplates(templates, { q: filters.get('q') }, allComponents)
          )
        };
      }}
      renderItem={(item: Map<string, any>) => {
        const template = item.toJS() as Template;

        return (
          <TemplateBox
            key={template.id}
            allComponents={allComponents}
            instances={instances}
            readOnly={readOnly}
            {...template}
          />
        );
      }}
      categories={OrderedSet(templatesCategories)}
      supportedSortOptions={[SORT.A_Z, SORT.Z_A]}
    />
  );
};

const TemplateBox = ({
  id,
  name,
  description,
  components,
  allComponents,
  instances,
  readOnly
}: Store['templates'][number] & {
  allComponents: Map<string, any>;
  instances: Store['instances'];
  readOnly: boolean;
}) => {
  const [isPending, setIsPending] = useState(false);
  const numberOfInstances = instances?.filter((instance) => instance.templateId === id).length;

  return (
    <div className="box box-panel template-preview-box">
      <div className="box-header">
        {components.length > 0 ? (
          <TemplateIcons allComponents={allComponents} componentsIds={components} />
        ) : (
          <CircleIcon icon="book-open" bigger bold />
        )}
      </div>
      <div className="box-panel-content overflow-break-anywhere">
        <h3 className="mt-0">
          <Truncated text={name} twoLines />
        </h3>
        <p className="text-muted">
          <Truncated text={description} twoLines />
        </p>
        <div className="flex-container flex-start flex-wrap mt-auto gap-16 mt-1">
          {!readOnly && (
            <RouterLink
              to={routeNames.TEMPLATE_DETAIL}
              params={{ templateId: id }}
              query={{
                flowId: RoutesStore.getRouterState().getIn(['location', 'query', 'flowId'])
              }}
              onClick={() => setIsPending(true)}
              disabled={isPending}
              className="btn btn-success btn-sm"
              onlyActiveOnIndex
            >
              {isPending ? (
                <Loader className="icon-addon-right" />
              ) : (
                <FontAwesomeIcon icon="plus" className="icon-addon-right" />
              )}
              Use Template
            </RouterLink>
          )}
          {!!numberOfInstances && (
            <RouterLink to={routeNames.ROOT} query={{ q: id }} className="text-muted no-wrap">
              <FontAwesomeIcon icon="circle-check" className="color-success btn-icon" />
              {numberOfInstances} {string.pluralize(numberOfInstances, 'template')} added
            </RouterLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplatesDirectory;
