import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import Tooltip from './Tooltip';

const InfoTooltip = ({
  tooltip,
  allowHoverTooltip = false,
  className
}: {
  tooltip: string | React.ReactElement;
  allowHoverTooltip?: boolean;
  className?: string;
}) => {
  return (
    <Tooltip
      placement="top"
      tooltip={tooltip}
      type="explanatory"
      allowHoverTooltip={allowHoverTooltip}
    >
      <FontAwesomeIcon
        icon="circle-info"
        className={classnames('tw-text-neutral-400 tw-text-base tw-ml-1.5', className)}
      />
    </Tooltip>
  );
};

export default InfoTooltip;
