import React from 'react';
import PropTypes from 'prop-types';

import InlineEditTextInput from '../../../../../react/common/InlineEditTextInput';

class TableNameEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isEditing: false,
      editingValue: ''
    };
  }

  render() {
    if (this.props.readOnly) {
      return this.props.tableName;
    }

    return (
      <InlineEditTextInput
        isValid={this.state.editingValue.length > 0}
        isChanged={this.state.editingValue !== this.props.tableName}
        editTooltip="Edit Database Table name"
        placeholder="Table Name"
        isSaving={this.state.isSaving}
        isEditing={this.state.isEditing}
        text={this.state.isEditing ? this.state.editingValue : this.props.tableName}
        onEditStart={() => this.setState({ isEditing: true, editingValue: this.props.tableName })}
        onEditCancel={() => this.setState({ isEditing: false, editingValue: '' })}
        onEditChange={(value) => this.setState({ editingValue: value })}
        onEditSubmit={() => {
          this.setState({ isSaving: true });
          this.props.onSubmit(this.state.editingValue).then(() => {
            this.setState({ isSaving: false, isEditing: false, editingValue: '' });
          });
        }}
      />
    );
  }
}

TableNameEdit.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  tableName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default TableNameEdit;
