import Promise from 'bluebird';

import MetadataActionCreators from '../components/MetadataActionCreators';
import { MetadataKeys, ObjectTypes } from '../components/MetadataConstants';
import DevBranchesStore from '../dev-branches/DevBranchesStore';

const saveMetadataDescription = (description) => {
  if (!description) {
    const descriptionMetadata = DevBranchesStore.getAllMetadata().find(
      (row) => row.get('key') === MetadataKeys.PROJECT_DESCRIPTION
    );

    if (!descriptionMetadata) {
      return Promise.resolve();
    }

    return MetadataActionCreators.deleteMetadata(
      ObjectTypes.BRANCH,
      DevBranchesStore.getDefaultBranchId(),
      descriptionMetadata.get('id')
    );
  }

  return MetadataActionCreators.saveBranchesMetadata(
    DevBranchesStore.getDefaultBranchId(),
    MetadataKeys.PROJECT_DESCRIPTION,
    description
  );
};

export { saveMetadataDescription };
