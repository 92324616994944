import Promise from 'bluebird';
import qs from 'qs';

import { KEBOOLA_ORCHESTRATOR } from '../../constants/componentIds';
import { SERVICE_QUEUE } from '../../constants/serviceIds';
import dayjs from '../../date';
import ApplicationStore from '../../stores/ApplicationStore';
import request from '../../utils/request';
import DevBranchesStore from '../dev-branches/DevBranchesStore';
import ServicesStore from '../services/Store';
import {
  JOBS_LIMIT_FOR_GRAPH,
  JOBS_LIMIT_FOR_RUN_RESULTS,
  JOBS_STATUS,
  JOBS_TYPES,
  MAX_JOBS_LIMIT
} from './constants';
import { isContainerJob } from './helpers';

const createRequest = (method, path) => {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_QUEUE)}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString()
  );
};

export default {
  createJob(component, params, mode = 'run') {
    return createRequest('POST', 'jobs')
      .send(
        DevBranchesStore.isDevModeActive()
          ? { mode, component, branchId: DevBranchesStore.getCurrentId(), ...params }
          : { mode, component, ...params }
      )
      .promise()
      .then((response) => response.body);
  },

  createProductionJob(component, params, mode = 'run') {
    return createRequest('POST', 'jobs')
      .send({ mode, component, ...params })
      .promise()
      .then((response) => response.body);
  },

  getJobs(params = {}) {
    if (params.componentType) {
      params = { ...params, componentProps: { type: params.componentType } };
      delete params.componentType;
    }

    return createRequest('GET', 'jobs')
      .query(
        qs.stringify(
          {
            branchId: DevBranchesStore.isDevModeActive() ? DevBranchesStore.getCurrentId() : 'null',
            ...params
          },
          { arrayFormat: 'indices', encode: false }
        )
      )
      .promise()
      .then((response) => response.body);
  },

  getSiblingJobs(job, options) {
    return this.getJobs({
      offset: options?.offset || 0,
      limit: options?.limit || JOBS_LIMIT_FOR_GRAPH,
      ...(!!job.get('branchId') && { branchId: job.get('branchId') }),
      config: job.get('config'),
      component: job.get('component'),
      type: job.get('type'),
      status: options.status || [
        JOBS_STATUS.SUCCESS,
        JOBS_STATUS.ERROR,
        JOBS_STATUS.TERMINATED,
        JOBS_STATUS.WARNING
      ],
      startTimeFrom: '2000-01-01',
      endTimeFrom: '2000-01-01',
      ...(options?.previous
        ? { createdTimeTo: dayjs(job.get('createdTime')).toString() }
        : {
            createdTimeFrom: dayjs(job.get('createdTime')).add(1000, 'milliseconds').toString(),
            sortOrder: 'asc'
          })
    });
  },

  getChildJobs(job) {
    if (!isContainerJob(job)) {
      return Promise.resolve([]);
    }

    let allChildJobs = [];

    const loadAll = (params) => {
      return this.getJobs(params).then((jobs) => {
        allChildJobs = allChildJobs.concat(jobs);

        if (jobs.length === params.limit) {
          return loadAll({ ...params, offset: params.offset + params.limit });
        }

        return allChildJobs;
      });
    };

    return loadAll({
      parentRunId: job.get('runId'),
      ...(!!job.get('branchId') && { branchId: job.get('branchId') }),
      limit: MAX_JOBS_LIMIT,
      offset: 0
    });
  },

  getJobDetail(id) {
    return createRequest('GET', `jobs/${id}`)
      .promise()
      .then((response) => response.body);
  },

  terminateJob(id) {
    return createRequest('POST', `jobs/${id}/kill`)
      .promise()
      .then((response) => response.body);
  },

  getConfigurationsJobs(componentId, configIds) {
    return createRequest('GET', `configurations-jobs`)
      .query(
        qs.stringify(
          {
            branchId: DevBranchesStore.isDevModeActive() ? DevBranchesStore.getCurrentId() : 'null',
            configId: configIds,
            componentId: componentId,
            limit: MAX_JOBS_LIMIT,
            jobsPerConfiguration: JOBS_LIMIT_FOR_RUN_RESULTS,
            ...(componentId === KEBOOLA_ORCHESTRATOR && {
              type: JOBS_TYPES.ORCHESTRATION_CONTAINER
            })
          },
          { arrayFormat: 'indices', encode: false }
        )
      )
      .promise()
      .then((response) => response.body);
  },

  getLatestJobForAllConfigurations() {
    return createRequest('GET', 'latest-configurations-jobs')
      .query({
        limit: 200,
        ...(DevBranchesStore.isDevModeActive() && { branchId: DevBranchesStore.getCurrentId() })
      })
      .promise()
      .then((response) => response.body);
  },

  getOrchestrationRerunPlan(id) {
    return createRequest('GET', `orchestration-rerun-plan/${id}`)
      .promise()
      .then((response) => response.body);
  }
};
