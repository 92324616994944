import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { fromJS, List } from 'immutable';

import FileSize from '../../../../../react/common/FileSize';
import Loader from '../../../../../react/common/Loader';
import ModalIcon from '../../../../../react/common/ModalIcon';
import RouterLink from '../../../../../react/common/RouterLink';
import { routeNames as storageRoutes } from '../../../../storage/constants';
import StorageApi from '../../../StorageApi';

class MatchedFilesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: List(),
      isLoading: false
    };

    this.loadFiles = this.loadFiles.bind(this);
  }

  render() {
    return (
      <Modal show={this.props.show} onEnter={this.loadFiles} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Matched files</Modal.Title>
          <ModalIcon icon="file" color="blue" bold />
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
      </Modal>
    );
  }

  renderBody() {
    if (this.state.isLoading) {
      return (
        <>
          <Loader className="icon-addon-right" />
          Loading files...
        </>
      );
    }

    if (this.state.files.isEmpty()) {
      return <p>There are no files matching this tag.</p>;
    }

    return this.state.files
      .map((file) => (
        <RouterLink
          key={file.get('id')}
          to={storageRoutes.FILES}
          query={{ q: `id:${file.get('id')}` }}
          className="flex-container mt-1"
        >
          <div>{file.get('name')}</div>
          <div className="text-muted">
            <FileSize size={file.get('sizeBytes')} />
          </div>
        </RouterLink>
      ))
      .toArray();
  }

  loadFiles() {
    if (this.props.tags.isEmpty()) {
      return;
    }

    this.setState({ isLoading: true });

    return StorageApi.getFiles({
      q: this.props.tags.map((tag) => `tags: "${tag.get('name')}"`).join(' && ')
    }).then((files) => {
      this.setState({ files: fromJS(files), isLoading: false });
    });
  }
}

MatchedFilesModal.propTypes = {
  tags: PropTypes.instanceOf(List).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default MatchedFilesModal;
