import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import Select from '../../../../react/common/Select';
import string from '../../../../utils/string';
import { nameWarning } from '../../../storage/constants';

const SaveSettings = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      tableName: PropTypes.string.isRequired,
      primaryKey: PropTypes.array.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Save Settings</h2>
        </div>
        <div className="box-content">
          <FormGroup>
            <Col componentClass={ControlLabel} xs={4}>
              Table Name
            </Col>
            <Col xs={8}>
              <FormControl
                type="text"
                value={this.props.value.tableName}
                onChange={({ target }) => {
                  this.props.onChange({ tableName: string.sanitizeKbcTableIdString(target.value) });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Data will be saved to this table in the output bucket (customers and campaigns are
                reserved names and cannot be used as table names). {nameWarning}
              </HelpBlock>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={4}>
              Primary Key Columns
            </Col>
            <Col sm={8}>
              <Select
                name="primaryKey"
                value={this.props.value.primaryKey}
                multi
                allowCreate
                placeholder="Add a column to the primary key"
                onChange={(value) => {
                  this.props.onChange({ primaryKey: value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>List of columns to be used as primary key</HelpBlock>
            </Col>
          </FormGroup>
        </div>
      </div>
    );
  }
});

export default SaveSettings;
