import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { capitalize } from 'underscore.string';

import DescriptionBox from '../../../react/common/DescriptionBox';
import MetadataActionCreators from '../../components/MetadataActionCreators';
import { ObjectTypes } from '../../components/MetadataConstants';
import { getDescriptionValue } from '../helpers';

const StorageDescription = createReactClass({
  propTypes: {
    objectType: PropTypes.oneOf(Object.values(ObjectTypes)).isRequired,
    objectId: PropTypes.string.isRequired,
    metadata: PropTypes.object.isRequired,
    metadataKey: PropTypes.string.isRequired,
    fallbackMetadataKey: PropTypes.string,
    readOnly: PropTypes.bool.isRequired
  },

  getInitialState() {
    let editing = getDescriptionValue(this.props.metadata, this.props.metadataKey);

    if (!editing && this.props.fallbackMetadataKey) {
      editing = getDescriptionValue(this.props.metadata, this.props.fallbackMetadataKey);
    }

    return {
      editing,
      description: editing,
      isEditing: false,
      isSaving: false
    };
  },

  render() {
    return (
      <DescriptionBox
        editing={this.state.editing}
        description={this.state.description}
        onChange={this.handleEditChange}
        handleSave={this.handleEditSubmit}
        handleCancel={this.handleEditCancel}
        readOnly={this.props.readOnly}
        isSaving={this.state.isSaving}
        isEditing={this.state.isEditing}
        placeholder={`${capitalize(this.props.objectType)} Description`}
      />
    );
  },

  handleEditCancel() {
    this.setState({ isEditing: false, editing: this.state.description });
  },

  handleEditChange(description) {
    this.setState({ isEditing: true, editing: description });
  },

  handleEditSubmit() {
    this.setState({ isSaving: true });
    MetadataActionCreators.saveMetadata(
      this.props.objectType,
      this.props.objectId,
      this.props.metadataKey,
      this.state.editing.trim()
    )
      .then(() => this.setState({ description: this.state.editing }))
      .finally(() => this.setState({ isSaving: false, isEditing: false }));
  }
});

export default StorageDescription;
