import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import RouterLink from '../../../react/common/RouterLink';

class LinkMenuItem extends React.Component {
  render() {
    return (
      <li role="presentation" className={classnames({ active: this.props.active })}>
        <RouterLink
          role="menuitem"
          tabIndex={-1}
          to={this.props.to}
          params={this.props.params}
          onKeyDown={this.props.onKeyDown}
          onClick={this.handleClick}
          openInNewTab={this.props.openInNewTab}
          forceProduction={this.props.forceProduction}
        >
          {this.props.children}
        </RouterLink>
      </li>
    );
  }

  handleClick = (event) => {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.to, event);
    }
  };
}

LinkMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  params: PropTypes.object,
  onKeyDown: PropTypes.func,
  onSelect: PropTypes.func,
  openInNewTab: PropTypes.bool,
  forceProduction: PropTypes.bool,
  active: PropTypes.bool
};

export default LinkMenuItem;
