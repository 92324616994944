import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { List, Map } from 'immutable';

import SearchBar from '../../common/SearchBar';
import { findProjectOrganization } from './helpers';
import ProjectList from './ProjectList';

const DROPDOWN_ID = 'select-project-dropdown-button';

const ProjectSelector = (props: {
  isCollapsed: boolean;
  isDemoPreview: boolean;
  organizations: List<any>;
  currentProject: Map<string, any>;
  urlTemplates: Map<string, any>;
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');

  const currentOrganization = findProjectOrganization(props.organizations, props.currentProject);

  return (
    <Dropdown
      dropup
      id={DROPDOWN_ID}
      rootCloseEvent="mousedown"
      className="project-select"
      open={isOpen}
      onToggle={(isOpen: boolean, event: any, options: any) => {
        if (
          document.body.classList.contains('modal-open') ||
          (options?.source === 'rootClose' &&
            event?.composedPath?.().some((el: Element) => el?.id === DROPDOWN_ID))
        ) {
          return;
        }

        setOpen(isOpen);
      }}
      disabled={props.isDemoPreview}
    >
      <Dropdown.Toggle
        noCaret
        bsStyle="link"
        className={classNames(
          'nav-link flex-container',
          props.isCollapsed ? 'justify-center' : 'flex-start'
        )}
      >
        {props.isCollapsed ? (
          <FontAwesomeIcon icon={['far', 'globe']} className="m-0" />
        ) : (
          <>
            <div className="mr-1 project-box">
              <div className="truncated-box f-12 line-height-16 organization-name">
                {currentOrganization.get('name')}
              </div>
              <div className="truncated-box text-left reset-transform">
                {props.currentProject.get('name')}
              </div>
            </div>
          </>
        )}
        {!props.isCollapsed && <FontAwesomeIcon icon="angle-right" />}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <SearchBar
          className="with-border"
          onChange={setQuery}
          query={query}
          placeholder="Search projects"
        />
        <ProjectList
          query={query}
          organizations={props.organizations}
          currentProject={props.currentProject}
          urlTemplates={props.urlTemplates}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProjectSelector;
