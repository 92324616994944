import Immutable from 'immutable';

import { DefaultDatasetLocation } from '../constants';

export default {
  createConfiguration: function (localState) {
    return Immutable.fromJS({
      parameters: {
        google: {
          storage: localState.get('storage', ''),
          location: localState.get('location', DefaultDatasetLocation)
        }
      }
    });
  },

  parseConfiguration: function (configuration) {
    return Immutable.fromJS({
      storage: configuration.getIn(['parameters', 'google', 'storage'], ''),
      location: configuration.getIn(['parameters', 'google', 'location'], DefaultDatasetLocation)
    });
  },

  isComplete: function (configuration) {
    return configuration.getIn(['parameters', 'google', 'storage'], '') !== '';
  }
};
