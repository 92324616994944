import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { componentTypes } from '../../constants/componentTypes';
import { getNewComponentTypeLabel } from '../../modules/components/helpers';
import ComponentIcon from './ComponentIcon';
import ComponentName from './ComponentName';
import Truncated from './Truncated';

const ComponentWithIconAndType = ({ component, nameSuffix = '', isPhase = false }) => {
  return (
    <div className="flex-container flex-start">
      <ComponentIcon
        component={component}
        isPhase={isPhase}
        size="28"
        className="icon-addon-right"
      />
      <div>
        <div className="component-name">
          <ComponentName component={component}>
            {(name) => <Truncated className="component-name" text={`${name}${nameSuffix}`} />}
          </ComponentName>
        </div>
        {Object.values(componentTypes).includes(component.get('type')) && (
          <div className="f-11 line-height-1 text-muted">
            {getNewComponentTypeLabel(component.get('type'))}
          </div>
        )}
      </div>
    </div>
  );
};

ComponentWithIconAndType.propTypes = {
  component: PropTypes.instanceOf(Map).isRequired,
  nameSuffix: PropTypes.string,
  isPhase: PropTypes.bool
};

export default ComponentWithIconAndType;
