import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import ConfirmButtons from '../../../../react/common/ConfirmButtons';
import Loader from '../../../../react/common/Loader';
import SearchBar from '../../../../react/common/SearchBar';
import EmptyState from '../../../components/react/components/ComponentEmptyState';

const AccountsManagerModal = createReactClass({
  propTypes: {
    accounts: PropTypes.object.isRequired,
    syncAccounts: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onHideFn: PropTypes.func,
    authorizedDescription: PropTypes.string,
    localState: PropTypes.object.isRequired,
    updateLocalState: PropTypes.func.isRequired,
    prepareLocalState: PropTypes.func.isRequired,
    onSaveAccounts: PropTypes.func.isRequired,
    accountDescFn: PropTypes.func.isRequired
  },

  render() {
    const getDesc = this.props.accountDescFn;
    return (
      <Modal show={this.props.show} onHide={this.props.onHideFn}>
        <Modal.Header closeButton>
          <Modal.Title>Set Up {getDesc('Pages')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row tw-max-h-[32rem] tw-overflow-y-auto">
            <div className="col-xs-6">
              <div>
                <h4 className="text-center">
                  All {getDesc('Pages')} of {this.props.authorizedDescription}
                </h4>
                <SearchBar
                  bordered
                  query={this.localState(['filter'])}
                  onChange={(newVal) => this.updateLocalState(['filter'], newVal)}
                />
                {this.renderAllAccounts()}
              </div>
            </div>
            <div className="col-xs-6">
              <div>
                <h4 className="text-center">Selected {getDesc('Pages')}</h4>
                {this.renderConfigAccounts()}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={this.props.isSaving}
            onSave={this.handleSave}
            saveLabel="Save Changes"
            isDisabled={this.isSavingDisabled()}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  isSavingDisabled() {
    const hasChanged = !this.props.accounts.equals(this.localState(['selected']));
    return !hasChanged;
  },

  renderConfigAccounts() {
    if (this.localState(['selected'], Map()).isEmpty()) {
      return <EmptyState>No {this.props.accountDescFn('Pages')} Selected</EmptyState>;
    }

    return (
      <table className="table-condensed">
        <tbody>
          {this.localState(['selected'])
            .map((account) => {
              return (
                <tr key={account.get('id')}>
                  <td>
                    {account.get('name') || 'Unknown'}
                    <small>
                      <div>{account.get('id')}</div>
                    </small>
                  </td>
                  <td className="w-50">
                    <Button
                      bsStyle="link"
                      onClick={this.deselectAccount.bind(this, account.get('id'))}
                    >
                      <FontAwesomeIcon icon="trash" fixedWidth className="clickable" />
                    </Button>
                  </td>
                </tr>
              );
            })
            .toArray()}
        </tbody>
      </table>
    );
  },

  renderError(error, code) {
    let message = 'Unexpected error';
    const errorBody = error.get('error');
    try {
      const jsError = JSON.parse(errorBody).error;
      message = jsError.message || jsError;
    } catch (e) {
      message = error.get('message') || error;
    }

    return (
      <Alert bsStyle="danger">
        <p>Error: {message}</p>
        {code >= 500 ? <div>{error.get('exceptionId')}</div> : null}
      </Alert>
    );
  },

  renderAllAccounts() {
    if (this.props.syncAccounts.get('isLoading')) {
      return (
        <>
          <Loader fixedWidth={false} className="icon-addon-right" />
          Fetching {this.props.accountDescFn('pages')} from Facebook...
        </>
      );
    }

    let data = this.props.syncAccounts.get('data', List()) || List();
    data = data.filter((a) => !this.localState(['selected'], Map()).has(a.get('id')));
    let allCaption = '';
    if (!!this.localState(['filter'], '')) {
      allCaption = 'Filtered';
      data = data.filter((a) => {
        return (
          a
            .get('name')
            .toLowerCase()
            .includes(this.localState(['filter']).toLowerCase()) ||
          a
            .get('id')
            .toLowerCase()
            .includes(this.localState(['filter']).toLowerCase())
        );
      });
    }

    const isError = this.props.syncAccounts.get('isError');
    const error = this.props.syncAccounts.get('error');
    const code = this.props.syncAccounts.get('code');

    if (isError) return this.renderError(error, code);

    if (data.isEmpty()) {
      return <EmptyState>No {this.props.accountDescFn('Pages')}</EmptyState>;
    }

    return (
      <table className="table-hover table-condensed">
        <tbody>
          <tr className="clickable" onClick={this.selectAllAccounts.bind(this, data)}>
            <td>--Select All {allCaption}--</td>
            <td className="w-50">
              <FontAwesomeIcon icon="angle-right" pull="right" fixedWidth />
            </td>
          </tr>
          {data
            .map((d) => {
              return (
                <tr
                  key={d.get('id')}
                  className="clickable"
                  onClick={this.selectAccount.bind(this, d)}
                >
                  <td>
                    {d.get('name')}
                    <small>
                      <div>{d.get('id')}</div>
                    </small>
                  </td>
                  <td className="w-50">
                    <FontAwesomeIcon icon="angle-right" pull="right" fixedWidth />
                  </td>
                </tr>
              );
            })
            .toArray()}
        </tbody>
      </table>
    );
  },

  deselectAccount(accountId) {
    const accounts = this.localState(['selected'], Map()).delete(accountId);
    this.updateLocalState(['selected'], accounts.count() > 0 ? accounts : Map());
  },

  selectAllAccounts(accountsList) {
    const selected = this.localState(['selected'], Map());
    const accounts = accountsList.reduce((memo, a) => memo.set(a.get('id'), a), selected);
    this.updateLocalState(['selected'], accounts);
  },

  selectAccount(account) {
    let accounts = this.localState(['selected'], Map());
    // make sure we have an empty map if no accounts selected
    accounts = accounts.count() > 0 ? accounts : Map();
    this.updateLocalState(['selected'], accounts.set(account.get('id'), account));
  },

  localState(path, defaultVal) {
    return this.props.localState.getIn(path, defaultVal);
  },

  updateLocalState(path, newValue) {
    return this.props.updateLocalState([].concat(path), newValue);
  },

  handleSave() {
    this.props
      .onSaveAccounts(this.localState(['selected'], Map()))
      .then(() => this.props.onHideFn());
  }
});

export default AccountsManagerModal;
