import { KEBOOLA_EX_GOOGLE_ANALYTICS_V_4 as componentId } from '../../constants/componentIds';
import { configPoll, rowPoll } from '../../utils/genericPolls';
import { configRequiredData } from '../../utils/genericRequiredData';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '../configurations/ConfigurationRowsStore';
import RowsVersionsActionCreators from '../configurations/RowVersionsActionCreators';
import SimplifiedUiHeaderButtons from '../simplified-ui/HeaderButtons';
import Index from './Index';
import Row from './Row';

export default {
  name: componentId,
  path: `${componentId}/:config`,
  defaultRouteHandler: Index,
  headerButtonsHandler: SimplifiedUiHeaderButtons,
  title: (routerState) => {
    const configId = routerState.getIn(['params', 'config']);
    return InstalledComponentsStore.getConfig(componentId, configId).get('name');
  },
  requireData: [(params) => configRequiredData(componentId, params.config)],
  poll: configPoll(componentId),
  childRoutes: [
    {
      name: `${componentId}-row`,
      path: 'rows/:row',
      title: (routerState) => {
        const configId = routerState.getIn(['params', 'config']);
        const rowId = routerState.getIn(['params', 'row']);
        return ConfigurationRowsStore.get(componentId, configId, rowId).get('name', 'Untitled');
      },
      poll: rowPoll(componentId),
      requireData: [
        (params) => RowsVersionsActionCreators.loadVersions(componentId, params.config, params.row)
      ],
      defaultRouteHandler: Row
    }
  ]
};
