import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import ConfirmButtons from '../../../../react/common/ConfirmButtons';
import ModalIcon from '../../../../react/common/ModalIcon';
import Tooltip from '../../../../react/common/Tooltip';
import InputMappingRowDockerEditor from '../components/mapping/InputMappingRowDockerEditor';
import InputMappingRowRedshiftEditor from '../components/mapping/InputMappingRowRedshiftEditor';
import InputMappingRowSnowflakeEditor from '../components/mapping/InputMappingRowSnowflakeEditor';
import resolveInputShowDetails from './resolveInputShowDetails';

const MODE_CREATE = 'create',
  MODE_EDIT = 'edit';

const InputMapping = createReactClass({
  propTypes: {
    mode: PropTypes.oneOf([MODE_CREATE, MODE_EDIT]).isRequired,
    mapping: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    backend: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    otherDestinations: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    definition: PropTypes.object,
    disabled: PropTypes.bool
  },

  getDefaultProps() {
    return {
      definition: Map(),
      disabled: false
    };
  },

  isValid() {
    return (
      !!this.props.mapping.get('source') &&
      (this.props.mapping.has('tables') || !this.isDestinationDuplicate())
    );
  },

  getInitialState() {
    return {
      isSaving: false,
      showModal: false
    };
  },

  open() {
    this.setState({
      showModal: true
    });
  },

  close() {
    this.setState({
      showModal: false
    });
  },

  isDestinationDuplicate() {
    if (this.props.mapping.has('tables')) {
      return this.props.mapping.get('tables').some((table) => {
        return this.props.otherDestinations.contains(table.get('destination', '').toLowerCase());
      });
    }

    return this.props.otherDestinations.contains(
      this.props.mapping.get('destination', '').toLowerCase()
    );
  },

  render() {
    let title = 'Input Mapping';
    if (this.props.definition.get('label')) {
      title = this.props.definition.get('label');
    }
    return (
      <span onClick={(e) => e.stopPropagation()}>
        {this.renderOpenButton()}
        <Modal onHide={this.handleCancel} show={this.state.showModal} bsSize="large">
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            {this.props.mode === MODE_CREATE ? <ModalIcon.Plus /> : <ModalIcon.Edit />}
          </Modal.Header>
          <Modal.Body>
            {this.editingNonExistentTable() && !this.props.mapping.has('source_search') && (
              <Alert bsStyle="warning">Source table does not exist.</Alert>
            )}
            {this.editor()}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={this.props.mode === MODE_CREATE ? 'Add Input' : 'Save Input'}
              isSaving={this.state.isSaving}
              onSave={this.handleSave}
              isDisabled={!this.isValid() || this.editingNonExistentTable()}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  },

  renderOpenButton() {
    if (this.props.mode === MODE_EDIT) {
      const editingNonExistentTable = this.editingNonExistentTable();

      return (
        <Tooltip tooltip={editingNonExistentTable ? 'Open Input' : 'Edit Input'} placement="top">
          <Button
            bsStyle="link"
            className="text-muted"
            onClick={this.handleOpenButtonLink}
            disabled={this.props.disabled}
          >
            {editingNonExistentTable ? (
              <FontAwesomeIcon icon="eye" />
            ) : (
              <FontAwesomeIcon icon="pen" />
            )}
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button bsStyle="link" className="header-inline-button color-success" onClick={this.open}>
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        New Input
      </Button>
    );
  },

  editingNonExistentTable() {
    if (this.props.mapping.has('source_search')) {
      return true;
    }

    const source = this.props.mapping.get('source');
    const tableNotFound = this.props.tables.get(source, Map()).count() === 0;

    return this.props.mode === MODE_EDIT && source && tableNotFound;
  },

  handleOpenButtonLink(e) {
    e.preventDefault();
    e.stopPropagation();
    this.open();
  },

  editor() {
    const props = {
      mode: this.props.mode,
      value: this.props.mapping,
      tables: this.props.tables,
      buckets: this.props.buckets,
      disabled: this.editingNonExistentTable() || this.state.isSaving,
      onChange: this.props.onChange,
      initialShowDetails: resolveInputShowDetails(
        this.props.backend,
        this.props.type,
        this.props.mapping,
        this.props.tables
      ),
      isDestinationDuplicate: this.isDestinationDuplicate(),
      definition: this.props.definition,
      type: this.props.type
    };
    if (this.props.backend === 'redshift' && this.props.type === 'simple') {
      return <InputMappingRowRedshiftEditor {...props} />;
    } else if (this.props.backend === 'snowflake' && this.props.type === 'simple') {
      return <InputMappingRowSnowflakeEditor {...props} />;
    } else if (this.props.backend === 'docker') {
      return <InputMappingRowDockerEditor {...props} />;
    }
    return null;
  },

  handleCancel() {
    this.props.onCancel();
    this.close();
  },

  handleSave() {
    this.setState({
      isSaving: true
    });
    this.props
      .onSave()
      .then(() => {
        this.setState({
          isSaving: false
        });
        this.close();
      })
      .catch((e) => {
        this.setState({
          isSaving: false
        });
        throw e;
      });
  }
});

export default InputMapping;
