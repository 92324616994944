import { List, Map } from 'immutable';

import { transformationType } from '../../../Constants';

const prepareSourceMapping = (selected, currentMapping, tables, type) => {
  const table = tables.get(selected);

  if (table) {
    const destination = type === transformationType.OPENREFINE ? 'data' : table.get('displayName');
    return currentMapping.withMutations((mapping) =>
      mapping
        .set('source', table.get('id'))
        .set('destination', `${destination}.csv`)
        .delete('tables')
        .delete('changedSince')
        .set('whereColumn', '')
        .set('whereValues', List())
        .set('whereOperator', 'eq')
        .set('columns', List())
    );
  }

  return currentMapping.withMutations((mapping) =>
    mapping
      .set('source', selected)
      .set(
        'tables',
        tables
          .filter((table) => table.getIn(['bucket', 'id']) === selected)
          .map((table) =>
            Map({
              source: table.get('id'),
              destination: `${table.get('displayName')}.csv`
            })
          )
      )
      .delete('changedSince')
      .set('whereColumn', '')
      .set('whereValues', List())
      .set('whereOperator', 'eq')
      .set('columns', List())
  );
};

export { prepareSourceMapping };
