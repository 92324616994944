import { fromJS, List } from 'immutable';

import { findBaseType, findLength } from '../../components/utils/columnMetadataHelper';
import { getTableColumnMetadata } from '../../components/utils/tableMetadataHelper';
import { Types } from '../constants';

const basetypeMapping = fromJS({
  STRING: {
    type: Types.ATTRIBUTE,
    dataType: 'VARCHAR',
    maxLength: 10000,
    defaultSize: 255
  },
  INTEGER: {
    type: Types.FACT,
    dataType: 'INT'
  },
  NUMERIC: {
    type: Types.FACT,
    dataType: 'DECIMAL',
    maxLength: '15,6',
    defaultSize: '12,2'
  },
  FLOAT: {
    type: Types.FACT,
    dataType: 'DECIMAL',
    maxLength: '15,6',
    defaultSize: '12,2'
  },
  BOOLEAN: {
    type: Types.FACT
  },
  DATE: {
    type: Types.DATE
  },
  TIMESTAMP: {
    type: Types.DATE
  }
});

export function prepareColumnsTypes(table) {
  const columnMetadata = getTableColumnMetadata(table);
  const primaryKeys = table.get('primaryKey', List());
  const metadata = getMetadata(columnMetadata, primaryKeys);

  return table
    .get('columns')
    .filter((column) => metadata.get(column))
    .map((column) =>
      fromJS({
        title: column,
        type: metadata.getIn([column, 'type']).toUpperCase(),
        dataType: metadata.getIn([column, 'dataType']),
        dataTypeSize: metadata.getIn([column, 'size'])
      }).filter(Boolean)
    )
    .toMap()
    .mapKeys((key, column) => column.get('title'));
}

function getMetadata(columnMetadata, primaryKeys) {
  return columnMetadata.map((data, column) => {
    const isPrimaryKey = primaryKeys.includes(column);

    if (isPrimaryKey && primaryKeys.count() > 1) {
      return null;
    }

    const baseType = findBaseType(data);

    if (!baseType) {
      return null;
    }

    const mapping = basetypeMapping.get(baseType.get('value'));

    if (!mapping) {
      return null;
    }

    const byProvider = data.filter((entry) => entry.get('provider') === baseType.get('provider'));
    const maxLength = mapping.get('maxLength');
    let size = findLength(byProvider).get('value');

    if (mapping.get('dataType') === 'VARCHAR') {
      size = !size ? mapping.get('defaultSize') : size;
      size = parseInt(size) > maxLength ? `${maxLength}` : `${size}`;
    } else if (mapping.get('dataType') === 'DECIMAL') {
      size = !size ? mapping.get('defaultSize') : size;
      const [maxM, maxD] = maxLength.split(',').map(Number);
      const [M, D] = size.split(',').map(Number);
      size = `${Math.min(M, maxM)},${Math.min(D, maxD)}`;
    } else {
      size = '';
    }

    return fromJS({
      type: isPrimaryKey ? Types.CONNECTION_POINT : mapping.get('type'),
      dataType: mapping.get('dataType'),
      size
    });
  });
}
