import React, { useCallback, useEffect, useState } from 'react';
import Promise from 'bluebird';
import type { Map } from 'immutable';

import ApplicationActionCreators from '../../../../actions/ApplicationActionCreators';
import { KEBOOLA_EX_SAMPLE_DATA } from '../../../../constants/componentIds';
import { COLLAPSED_TRASH_CONFIGURATIONS } from '../../../../constants/localStorageKeys';
import InstalledComponentsActionCreators from '../../../../modules/components/InstalledComponentsActionCreators';
import Checkbox from '../../../../react/common/Checkbox';
import CollapseButton from '../../../../react/common/CollapseButton';
import ComponentDetailLink from '../../../../react/common/ComponentDetailLink';
import ComponentIcon from '../../../../react/common/ComponentIcon';
import ComponentName from '../../../../react/common/ComponentName';
import ComponentType from '../../../../react/common/ComponentType';
import useLocalStorage from '../../../../react/hooks/useLocalStorage';
import string from '../../../../utils/string';
import { hasDisabledRestore } from '../../utils';
import DeletedConfigurationRow from './DeletedConfigurationRow';
import RestoreConfigurationButton from './RestoreConfigurationButton';

type Props = {
  component: Map<string, any>;
  allComponents: Map<string, any>;
  configurations: Map<string, any>;
  deletingConfigurations: Map<string, any>;
  restoringConfigurations: Map<string, any>;
  admins: Map<string, any>;
  hasFlows: boolean;
  readOnly: boolean;
  isDeleteEnabled: boolean;
};

const DeletedComponentRow = ({
  component,
  allComponents,
  configurations,
  deletingConfigurations,
  restoringConfigurations,
  readOnly,
  hasFlows,
  admins,
  isDeleteEnabled
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    `${COLLAPSED_TRASH_CONFIGURATIONS}-${component?.get('id')}`,
    false
  );

  const [selectedConfigurations, setSelectedConfigurations] = useState<Set<string>>(new Set());
  const [isRestoring, setIsRestoring] = useState(false);

  const selectedSize = selectedConfigurations.size;
  const componentType = component.get('type');
  const isAllSelected = configurations.size === selectedSize;
  const isSomeSelected = selectedSize > 0;
  const isRestoreEnabled = !hasDisabledRestore(component.get('id'));

  const handleSelectConfiguration = useCallback(
    (configId: string) => {
      if (selectedConfigurations.has(configId)) {
        setSelectedConfigurations((prev) => {
          prev.delete(configId);
          return new Set(prev);
        });
      } else {
        setSelectedConfigurations((prev) => {
          return new Set(prev.add(configId));
        });
      }
    },
    [selectedConfigurations]
  );

  useEffect(() => {
    selectedConfigurations.forEach((id) => {
      // Clean up -> when we delete or restore config in a different way
      // we want to remove it from selected configs
      if (!configurations.get(id)) {
        handleSelectConfiguration(id);
      }
    });
  }, [selectedConfigurations, configurations, handleSelectConfiguration]);

  const handleMultipleSelection = () => {
    // Deselect
    if (selectedSize > 0) {
      setSelectedConfigurations(new Set());
    } else {
      // Select all
      const configIds = configurations.map((config) => config.get('id')).toArray();

      setSelectedConfigurations(new Set(configIds));
    }
  };

  const handleRestore = () => {
    setIsRestoring(true);

    const configsToRestore = [...selectedConfigurations]
      .map((configId) => configurations.get(configId))
      .filter(Boolean);

    return Promise.map(
      configsToRestore,
      (config) => {
        return InstalledComponentsActionCreators.restoreConfiguration(component, config, {
          skipNotification: selectedSize !== 1
        });
      },
      { concurrency: 3 }
    )
      .then(() => {
        if (selectedSize !== 1) {
          return ApplicationActionCreators.sendNotification({
            type: 'success',
            message: () => (
              <>
                <b>{selectedSize}</b> {string.pluralize(selectedSize, 'configuration')} have been
                restored.
              </>
            )
          });
        }
      })
      .finally(() => {
        setIsRestoring(false);
        handleMultipleSelection();
      });
  };

  const renderConfiguration = (configuration: Map<string, any>) => {
    const realComponentId = configuration.get('isSample')
      ? KEBOOLA_EX_SAMPLE_DATA
      : component.get('id');
    const realComponent = allComponents.get(realComponentId);

    const configId = configuration.get('id');

    return (
      <DeletedConfigurationRow
        key={configId}
        config={configuration}
        component={realComponent}
        readOnly={readOnly}
        admins={admins}
        isDeleteEnabled={isDeleteEnabled}
        isDeleting={deletingConfigurations.hasIn([realComponentId, configId])}
        isRestoring={restoringConfigurations.hasIn([realComponentId, configId])}
        isSelected={selectedConfigurations.has(configId)}
        isRestoreEnabled={isRestoreEnabled}
        onSelect={() => handleSelectConfiguration(configId)}
      />
    );
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getRestoreConfigurationModalText = () => {
    if (selectedSize === 1) {
      const [configId] = selectedConfigurations;
      const configName = configurations.getIn([configId, 'name']) || '';

      return (
        <>
          Are you sure you want to restore the configuration <b>{configName}</b>?
        </>
      );
    }

    return (
      <>
        Are you sure you want to restore <b>{selectedSize}</b>{' '}
        {string.pluralize(selectedSize, 'configuration')}?
      </>
    );
  };

  return (
    <div className="box">
      <div
        onClick={toggleCollapse}
        className="flex-container component-name-above-table btn-collapse-area"
      >
        <span className="flex-container flex-start">
          <ComponentIcon component={component} size="48" className="mrp-4" />
          <div className="flex-container flex-column align-top">
            <ComponentDetailLink componentId={component.get('id')} hasFlows={hasFlows}>
              <ComponentName component={component} />
            </ComponentDetailLink>
            {componentType !== 'other' && (
              <ComponentType
                noIcon
                type={componentType}
                className="f-14 font-medium line-height-20"
              />
            )}
          </div>
        </span>

        <CollapseButton isCollapsed={isCollapsed} onToggle={toggleCollapse} />
      </div>
      {isCollapsed ? (
        <div className="collapsed-configurations clickable" onClick={toggleCollapse}>
          <span className="font-bold">{configurations.size} </span>
          <span className="text-muted">
            {string.pluralize(configurations.size, 'Configuration')}
          </span>
        </div>
      ) : (
        <div className="table table-hover fixed">
          <div className="thead">
            <div className="tr">
              <div className="th">
                <div className="tw-flex tw-gap-3 tw-items-center tw-h-5">
                  {!readOnly && isRestoreEnabled && (
                    <Checkbox
                      checked={isAllSelected}
                      tooltip={isAllSelected || isSomeSelected ? 'Deselect all' : 'Select all'}
                      indeterminate={isSomeSelected && !isAllSelected}
                      className="tw-mb-0.5"
                      onChange={handleMultipleSelection}
                    />
                  )}
                  {isSomeSelected ? (
                    <strong className="tw-flex tw-items-center">
                      <span>
                        {selectedSize} {string.pluralize(selectedSize, 'configuration')} selected
                      </span>

                      <div>
                        <RestoreConfigurationButton
                          title={`Restore ${string.pluralize(selectedSize, 'Configuration')}`}
                          text={getRestoreConfigurationModalText()}
                          onConfirm={handleRestore}
                          isPending={isRestoring}
                        />
                      </div>
                    </strong>
                  ) : (
                    'Name'
                  )}
                </div>
              </div>
              <span className="th w-300 text-right">Deleted</span>
            </div>
          </div>
          <div className="tbody">{configurations.map(renderConfiguration).toArray()}</div>
        </div>
      )}
    </div>
  );
};

export default DeletedComponentRow;
