import React from 'react';
import { Label } from 'react-bootstrap';
import classnames from 'classnames';

const NativeTypesLabel = (props: { isTyped: boolean; className?: string }) => {
  if (!props.isTyped) {
    return null;
  }

  return (
    <Label className={classnames('label-purple label-rounded uppercase', props.className)}>
      Native Types
    </Label>
  );
};

export default NativeTypesLabel;
