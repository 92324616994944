import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_PROCESSOR_MOVE_FILES } from '../../constants/componentIds';
import columnTypes from '../configurations/utils/columnTypeConstants';
import createRoute from '../configurations/utils/createRoute';
import { CollapsibleSection } from '../configurations/utils/renderHelpers';
import conform from './adapters/conform';
import {
  conform as conformCredentials,
  createConfiguration as credentialsCreateConfiguration,
  isComplete as credentialsIsComplete,
  parseConfiguration as credentialsParseConfiguration
} from './adapters/credentials';
import {
  createConfiguration as rowCreateConfiguration,
  createEmptyConfiguration as rowCreateEmptyConfiguration,
  parseConfiguration as rowParseConfiguration
} from './adapters/row';
import ConfigurationForm from './Configuration';
import CredentialsForm from './Credentials';

const routeSettings = {
  componentId: 'keboola.ex-aws-s3',
  index: {
    actions: [
      {
        name: 'getExternalId',
        cache: true,
        autoload: true,
        getPayload: () => fromJS({ configData: {} })
      }
    ],
    onConform: conformCredentials,
    sections: [
      {
        render: CollapsibleSection({
          title: 'AWS Login',
          contentComponent: CredentialsForm,
          options: { includeSaveButtons: true }
        }),
        onSave: credentialsCreateConfiguration,
        onLoad: credentialsParseConfiguration,
        isComplete: credentialsIsComplete
      }
    ]
  },
  row: {
    onConform: conform,
    hasState: true,
    sections: [
      {
        render: ConfigurationForm,
        onSave: rowCreateConfiguration,
        onCreate: rowCreateEmptyConfiguration,
        onLoad: rowParseConfiguration
      }
    ],
    columns: [
      {
        name: 'Storage',
        type: columnTypes.TABLE_LINK_DEFAULT_BUCKET,
        value: function (row) {
          const processorMoveFiles = row.getIn(['processors', 'after'], List()).find(
            function (processor) {
              return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_MOVE_FILES;
            },
            null,
            Map()
          );
          return processorMoveFiles.getIn(['parameters', 'folder']);
        }
      }
    ]
  }
};

export default createRoute(routeSettings);
