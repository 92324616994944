import { KDS_EX_AZURE_DATA_LAKE_GEN2 } from '../../constants/componentIds';
import columnTypes from '../configurations/utils/columnTypeConstants';
import createRoute from '../configurations/utils/createRoute';
import { CollapsibleSection } from '../configurations/utils/renderHelpers';
import ConfigurationAdapter from '../ex-azure-blob/adapters/Configuration';
import ConfigurationForm from '../ex-azure-blob/react/Configuration';
import ConnectionAdapter from './adapters/Connection';
import ConnectionSection from './Connection';

export default createRoute({
  componentId: KDS_EX_AZURE_DATA_LAKE_GEN2,
  index: {
    sections: [
      {
        render: CollapsibleSection({
          title: 'Credentials',
          contentComponent: ConnectionSection,
          options: { includeSaveButtons: true }
        }),
        onLoad: ConnectionAdapter.parseConfiguration,
        onSave: ConnectionAdapter.createConfiguration,
        isComplete: ConnectionAdapter.isComplete
      }
    ]
  },
  row: {
    hasState: true,
    sections: [
      {
        render: ConfigurationForm,
        onSave: ConfigurationAdapter.createConfiguration,
        onLoad: ConfigurationAdapter.parseConfiguration,
        onCreate: ConfigurationAdapter.createEmptyConfiguration
      }
    ],
    columns: [
      {
        name: 'Storage',
        type: columnTypes.TABLE_LINK_DEFAULT_BUCKET,
        value: (row) => row.getIn(['parameters', 'file', 'storage'])
      }
    ]
  }
});
