import React from 'react';
import Promise from 'bluebird';

import { TRANSFORMATION } from '../../constants/componentIds';
import createVersionsPageRoute from '../../modules/components/utils/createVersionsPageRoute';
import ApplicationsStore from '../../stores/ApplicationStore';
import { configPoll, rowPoll } from '../../utils/genericPolls';
import ComponentNameEdit from '../components/react/components/ComponentName';
import StorageActionCreators from '../components/StorageActionCreators';
import VersionsActionCreators from '../components/VersionsActionCreators';
import RowVersions from '../configurations/react/pages/Versions';
import rowVersionsActions from '../configurations/RowVersionsActionCreators';
import ProvisioningActionCreators from '../provisioning/ActionCreators';
import InstalledComponentsActionCreators from './../components/InstalledComponentsActionCreators';
import TransformationBucketButtons from './react/components/TransformationBucketButtons';
import TransformationNameEdit from './react/components/TransformationNameEditField';
import Sandbox from './react/pages/sandbox/Sandbox';
import TransformationBucket from './react/pages/transformation-bucket/TransformationBucket';
import TransformationBucketHeaderButton from './react/pages/transformation-bucket/TransformationBucketHeaderButton';
import TransformationDetail from './react/pages/transformation-detail/TransformationDetail';
import TransformationGraph from './react/pages/transformation-graph/TransformationGraph';
import TransformationsIndex from './react/pages/transformations-index/TransformationsIndex';
import TransformationBucketsStore from './stores/TransformationBucketsStore';
import TransformationsStore from './stores/TransformationsStore';

const routes = {
  name: 'transformations',
  title: 'Transformations',
  defaultRouteHandler: TransformationsIndex,
  headerButtonsHandler: TransformationBucketButtons,
  requireData: [() => InstalledComponentsActionCreators.loadComponentConfigsData(TRANSFORMATION)],
  childRoutes: [
    {
      name: 'transformationBucket',
      path: 'bucket/:config',
      title(routerState) {
        const configId = routerState.getIn(['params', 'config']);
        return TransformationBucketsStore.get(configId).get('name');
      },
      nameEdit(params) {
        if (parseInt(params.config, 10) > 0) {
          return (
            <ComponentNameEdit
              key={`transformation-${params.config}`}
              componentId="transformation"
              configId={params.config}
            />
          );
        } else {
          return TransformationBucketsStore.get(params.config).get('name');
        }
      },
      defaultRouteHandler: TransformationBucket,
      headerButtonsHandler: TransformationBucketHeaderButton,
      requireData: [(params) => VersionsActionCreators.loadVersions(TRANSFORMATION, params.config)],
      poll: configPoll(TRANSFORMATION),
      childRoutes: [
        createVersionsPageRoute(TRANSFORMATION, 'config', 'transformation-bucket-versions'),
        {
          name: 'transformationDetail',
          path: 'transformation/:row',
          title(routerState) {
            const configId = routerState.getIn(['params', 'config']);
            const transformationId = routerState.getIn(['params', 'row']);
            return TransformationsStore.getTransformation(configId, transformationId).get('name');
          },
          nameEdit(params) {
            return (
              <TransformationNameEdit
                key={`${params.config}-${params.row}`}
                configId={params.config}
                rowId={params.row}
              />
            );
          },
          defaultRouteHandler: TransformationDetail,
          requireData: [
            () => StorageActionCreators.loadBucketsAndTables(),
            ({ config, row }) => rowVersionsActions.loadVersions(TRANSFORMATION, config, row)
          ],
          poll: rowPoll(TRANSFORMATION),
          childRoutes: [
            {
              name: 'transformation-bucket-row-versions',
              settings: { componentId: TRANSFORMATION },
              path: 'versions',
              title: 'Versions',
              defaultRouteHandler: RowVersions
            },
            {
              name: 'transformationDetailGraph',
              path: 'graph',
              title() {
                return 'Overview';
              },
              defaultRouteHandler: TransformationGraph
            }
          ]
        }
      ]
    },
    {
      name: 'sandbox',
      title: 'Sandbox',
      defaultRouteHandler: Sandbox,
      requireData: [
        () => StorageActionCreators.loadBucketsAndTables(),
        () => {
          if (ApplicationsStore.getSapiToken().getIn(['owner', 'hasSnowflake'], false)) {
            ProvisioningActionCreators.loadSnowflakeSandboxCredentials();
          }
          if (ApplicationsStore.getSapiToken().getIn(['owner', 'hasRedshift'], false)) {
            ProvisioningActionCreators.loadRedshiftSandboxCredentials();
          }
          ProvisioningActionCreators.loadRStudioSandboxCredentials();
          ProvisioningActionCreators.loadJulipyterSandboxCredentials();
          ProvisioningActionCreators.loadJupyterSandboxCredentials();
          return Promise.resolve();
        }
      ]
    }
  ]
};

export default routes;
