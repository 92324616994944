const KEBOOLA_US_EAST_1 = 'kbc-us-east-1';
const KEBOOLA_EU_CENTRAL_1 = 'kbc-eu-central-1';
const KEBOOLA_AZURE_NORTH_EUROPE = 'com-keboola-azure-north-europe';
const KEBOOLA_COULD_INNOGY_HUB = 'cloud-keboola-innogy-hub';
const KEBOOLA_CLOUD_HCI = 'cloud-keboola-hci';
const KEBOOLA_CLOUD_CSAS = 'cloud-keboola-csas';
const KEBOOLA_CLOUD_CSAS_TEST = 'cloud-keboola-csas-test';
const KEBOOLA_CONTROL_PLACE = 'com-keboola-control-plane';

export {
  KEBOOLA_US_EAST_1,
  KEBOOLA_EU_CENTRAL_1,
  KEBOOLA_AZURE_NORTH_EUROPE,
  KEBOOLA_COULD_INNOGY_HUB,
  KEBOOLA_CLOUD_HCI,
  KEBOOLA_CLOUD_CSAS,
  KEBOOLA_CLOUD_CSAS_TEST,
  KEBOOLA_CONTROL_PLACE
};
