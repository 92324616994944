import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormGroup, Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '../../../constants/componentIds';
import Checkbox from '../../../react/common/Checkbox';
import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';
import Select from '../../../react/common/Select';
import { folderLabel } from '../../../react/common/selectLabels';
import RoutesStore from '../../../stores/RoutesStore';
import { findCreatedFromMetadata, getFolderFromMetadata } from '../../components/helpers';
import InstalledComponentsActionCreators from '../../components/InstalledComponentsActionCreators';
import { routeNames as transformationRoutes } from '../../transformations-v2/constants';
import { mergeConfigurations } from '../helpers';

const INITIAL_STATE = {
  tempData: Map(),
  isLoading: false
};

class UpdateTransformationModal extends React.Component {
  static propTypes = {
    config: PropTypes.instanceOf(Map).isRequired,
    metadata: PropTypes.instanceOf(Map).isRequired,
    transformationComponent: PropTypes.instanceOf(Map).isRequired,
    existingTransformations: PropTypes.instanceOf(Map).isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
  };

  state = INITIAL_STATE;

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => {
          const data = findCreatedFromMetadata(
            KEBOOLA_SANDBOXES,
            this.props.config.get('id'),
            this.props.metadata
          );
          const optionExists = this.props.existingTransformations.has(data?.configurationId);

          this.setState({
            ...INITIAL_STATE,
            tempData: this.state.tempData.set(
              'transformation',
              optionExists ? data.configurationId : ''
            )
          });
        }}
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Select existing transformation</Modal.Title>
            <ModalIcon icon="gear" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Transformation</ControlLabel>
              <Select
                options={this.prepareOptions()}
                value={this.state.tempData.get('transformation', '')}
                onChange={(value) => {
                  this.setState({ tempData: this.state.tempData.set('transformation', value) });
                }}
                placeholder={
                  !this.props.existingTransformations.isEmpty()
                    ? 'Select transformation'
                    : 'No transformation created yet'
                }
                disabled={this.props.existingTransformations.isEmpty()}
              />
            </FormGroup>
            <FormGroup>
              <Checkbox
                checked={this.state.tempData.get('preserve', true)}
                onChange={(checked) => {
                  this.setState({ tempData: this.state.tempData.set('preserve', checked) });
                }}
                disabled={this.props.existingTransformations.isEmpty()}
              >
                Merge Input and Output mappings from workspace to transformation
              </Checkbox>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={
                this.state.isLoading ? 'Updating Transformation...' : 'Use Selected Transformation'
              }
              isSaving={this.state.isLoading}
              onSave={this.handleSubmit}
              isDisabled={!this.state.tempData.get('transformation') || this.state.isLoading}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  prepareOptions() {
    return this.props.existingTransformations
      .map((transformation) => {
        const folder = getFolderFromMetadata(
          this.props.metadata.getIn([
            this.props.transformationComponent.get('id'),
            transformation.get('id')
          ])
        );

        return {
          value: transformation.get('id'),
          label: folderLabel(transformation.get('name'), folder),
          name: `${folder} ${transformation.get('name')}`,
          hasFolder: !!folder
        };
      })
      .sort((rowA, rowB) => {
        if (rowA.hasFolder && !rowB.hasFolder) return -1;
        if (!rowA.hasFolder && rowB.hasFolder) return 1;

        return rowA.name.localeCompare(rowB.name);
      })
      .toArray();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const transformation = this.props.existingTransformations.get(
      this.state.tempData.get('transformation')
    );
    const data = this.state.tempData.get('preserve', true)
      ? mergeConfigurations(
          transformation.get('configuration', Map()),
          this.props.config.get('configuration', Map()),
          { skipPackages: true }
        )
      : transformation
          .get('configuration', Map())
          .set('storage', this.props.config.getIn(['configuration', 'storage'], Map()));

    this.setState({ isLoading: true });
    return InstalledComponentsActionCreators.updateComponentConfiguration(
      this.props.transformationComponent.get('id'),
      transformation.get('id'),
      {
        configuration: JSON.stringify(data)
      },
      `Use mapping from "${this.props.config.get('name')}" workspace`
    )
      .then(() => {
        RoutesStore.getRouter().transitionTo(transformationRoutes.GENERIC_TRANSFORMATION_CONFIG, {
          component: this.props.transformationComponent.get('id'),
          config: transformation.get('id')
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        throw error;
      });
  };
}

export default UpdateTransformationModal;
