import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Modal, Well } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import CollapsiblePanel from '../../../react/common/CollapsiblePanel';
import CreatedDate from '../../../react/common/CreatedDate';
import ExternalLink from '../../../react/common/ExternalLink';
import ModalIcon from '../../../react/common/ModalIcon';
import Tree from '../../../react/common/Tree';
import UrlsToLinks from '../../../react/common/UrlsToLinks';
import FileLink from '../../sapi-events/react/FileLink';

const EventDetailModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    event: PropTypes.instanceOf(Map)
  },

  getDefaultProps() {
    return {
      event: Map()
    };
  },

  render() {
    if (this.props.event.isEmpty()) {
      return null;
    }

    return (
      <Modal bsSize="large" show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Event detail</Modal.Title>
          <ModalIcon icon="magnifying-glass" color="blue" />
        </Modal.Header>
        <Modal.Body>
          {this.renderDeprecatedAuthorization()}
          <div>
            <p>
              <UrlsToLinks text={this.props.event.get('message')} />
            </p>
            {this.props.event.get('description') && (
              <p>
                <UrlsToLinks text={this.props.event.get('description')} />
              </p>
            )}
          </div>
          <Well>
            <p className="flex-container">
              <strong>ID</strong>
              <span className="text-muted">{this.props.event.get('id')}</span>
            </p>
            <p className="flex-container">
              <strong>Created</strong>
              <CreatedDate
                absolute
                withSeconds
                className="text-muted"
                createdTime={this.props.event.get('created')}
              />
            </p>
            <p className="flex-container">
              <strong>Component</strong>
              <span className="text-muted">{this.props.event.get('component')}</span>
            </p>
            <p className="flex-container">
              <strong>Run ID</strong>
              <span className="text-muted">{this.props.event.get('runId')}</span>
            </p>
          </Well>
          {this.renderAttachments()}
          {this.renderTreeData('Params', 'params')}
          {this.renderTreeData('Performance', 'performance')}
          {this.renderTreeData('Results', 'results')}
          {this.renderTreeData('Context', 'context')}
        </Modal.Body>
      </Modal>
    );
  },

  renderDeprecatedAuthorization() {
    if (this.props.event.getIn(['context', 'authorization']) !== 'deprecated') {
      return null;
    }

    return (
      <Alert bsStyle="danger">
        <p>
          Used authorization method is deprecated and will be disabled soon.
          <br />
          Please move your tokens from query string parameters to &quot;X-StorageApi-Token&quot;
          http header. See more in{' '}
          <ExternalLink href="http://docs.keboola.apiary.io/">API documentation</ExternalLink>
        </p>
      </Alert>
    );
  },

  renderAttachments() {
    const attachments = this.props.event.get('attachments', List());

    if (attachments.isEmpty()) {
      return null;
    }

    return (
      <CollapsiblePanel title="Attachments">
        <ul>
          {attachments
            .map((attachment) => (
              <li key={attachment.get('id')}>
                <FileLink file={attachment} />
              </li>
            ))
            .toArray()}
        </ul>
      </CollapsiblePanel>
    );
  },

  renderTreeData(header, parameter) {
    const data = this.props.event.get(parameter, Map());

    if (data.isEmpty()) {
      return null;
    }

    return (
      <CollapsiblePanel title={header}>
        <Tree data={data} />
      </CollapsiblePanel>
    );
  }
});

export default EventDetailModal;
