import React from 'react';

import Link from '../../react/common/RouterLink';
import ApplicationStore from '../../stores/ApplicationStore';
import BillingCredits from './react/Credits';
import BillingOverview from './react/Overview';
import {
  getBillingConfiguration,
  getBillingInformation,
  getCredits,
  getPurchases,
  getTopUpSetting
} from './actions';
import BillingConsumptionInfo from './BillingConsumptionInfo';
import OrganizationUsageInfo from './OrganizationUsageInfo';

const loadCommonData = () => {
  return Promise.all([
    getPurchases(),
    getBillingConfiguration(),
    getBillingInformation(),
    getTopUpSetting()
  ]);
};

const BackLink = () => {
  if (!ApplicationStore.hasPayAsYouGo()) return null;

  return (
    <Link className="dark muted" to="billing-overview">
      Billing
    </Link>
  );
};

const routes = [
  {
    name: 'billing-overview',
    title: 'Overview',
    subtitle: 'Billing',
    defaultRouteHandler: BillingOverview,
    requireData: [() => loadCommonData(), () => getCredits()]
  },
  {
    name: 'billing-consumption',
    title: 'Project Consumption',
    breadcrumbHandler: BackLink,
    defaultRouteHandler: BillingConsumptionInfo
  },
  {
    name: 'organization-usage',
    title: 'Organization Usage',
    breadcrumbHandler: BackLink,
    defaultRouteHandler: OrganizationUsageInfo
  },
  {
    name: 'billing-minutes',
    title: 'Add more minutes',
    breadcrumbHandler: BackLink,
    defaultRouteHandler: BillingCredits,
    requireData: [loadCommonData]
  }
];

export default routes;
