import React from 'react';
import PropTypes from 'prop-types';

import Loader from './Loader';

const DEFAULT_STYLE = {
  background: 'white',
  height: '28px'
};

const LoadingBlock = ({ label, style }) => (
  <div
    className="text-muted flex-container justify-center"
    style={{
      ...DEFAULT_STYLE,
      ...style
    }}
  >
    <Loader className="icon-addon-right" />
    {label || 'Loading...'}
  </div>
);

LoadingBlock.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object
};

export default LoadingBlock;
