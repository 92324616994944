import React from 'react';
import { Map } from 'immutable';

import useStores from '../../../react/hooks/useStores';
import RoutesStore from '../../../stores/RoutesStore';
import BucketsStore from '../../components/stores/StorageBucketsStore';
import ExternalBucketLabel from './ExternalBucketLabel';

const BucketLabels = () => {
  const { bucket } = useStores(
    () => {
      return {
        bucket: BucketsStore.getBucket(
          RoutesStore.getRouterState().getIn(['params', 'bucketId']),
          Map()
        )
      };
    },
    [],
    [RoutesStore, BucketsStore]
  );

  if (!bucket || bucket.isEmpty()) {
    return null;
  }

  return (
    <ExternalBucketLabel
      className="in-title"
      hasExternalSchema={bucket.get('hasExternalSchema', false)}
    />
  );
};

export default BucketLabels;
