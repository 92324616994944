import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { USER_DOCUMENTATION_URL } from '../../constants/KbcConstants';
import ExternalLink from '../../react/common/ExternalLink';

const DocumentationButton = () => {
  return (
    <ExternalLink className="btn btn-default" href={`${USER_DOCUMENTATION_URL}/templates/`}>
      <FontAwesomeIcon icon="book-blank" className="icon-addon-right" />
      Documentation
    </ExternalLink>
  );
};

export default DocumentationButton;
