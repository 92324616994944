import React from 'react';
import Promise from 'bluebird';

import { PROVISIONING, TRANSFORMATION } from '../../constants/componentIds';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import StorageActionCreators from '../components/StorageActionCreators';
import JobDetailButtons from './react/components/JobDetailButtons';
import JobDetailStatus from './react/components/JobDetailStatus';
import JobDetail from './react/pages/job-detail/JobDetail';
import JobsIndex from './react/pages/jobs-index/JobsIndex';
import JobsStore from './stores/JobsStore';
import JobsActionCreators from './ActionCreators';
import { getJobComponentId } from './JobComponentResolver';

const routes = {
  name: 'jobs',
  title: 'Jobs',
  defaultRouteHandler: JobsIndex,
  persistQueryParams: ['q'],
  poll: {
    skipFirst: true,
    interval: 10,
    skip: () => JobsStore.loadJobsErrorCount() >= 10,
    action: () => JobsActionCreators.reloadJobs()
  },
  requireData: [
    () => {
      return InstalledComponentsActionCreators.reloadInstalledComponents({
        include: 'configuration'
      });
    },
    (params, query) => {
      if (params.jobId) {
        // job detail
        return Promise.resolve();
      } else if ((query.q || query.q === '') && query.q !== JobsStore.getQuery()) {
        JobsActionCreators.setQuery(query.q || '');
        JobsActionCreators.loadJobsForce(0, true, false);
        // No return here to prevent "route change" error
      } else {
        return JobsActionCreators.loadJobs();
      }
    }
  ],
  childRoutes: [
    {
      name: 'jobDetail',
      path: ':jobId',
      persistQueryParams: ['q'],
      title(routerState) {
        const jobId = routerState.getIn(['params', 'jobId']);
        return `Job ${jobId}`;
      },
      labelHandler: JobDetailStatus,
      isRunning(routerState) {
        const jobId = routerState.getIn(['params', 'jobId']);
        const job = JobsStore.get(parseInt(jobId, 10));
        return job && !job.get('isFinished');
      },
      defaultRouteHandler: ({ params }) => <JobDetail key={params.jobId} />,
      headerButtonsHandler: JobDetailButtons,
      poll: {
        skipFirst: true,
        interval: 5,
        skip(params) {
          const job = JobsStore.get(parseInt(params.jobId, 10));
          return !job || !['waiting', 'processing', 'terminating'].includes(job.get('status'));
        },
        action(params) {
          return JobsActionCreators.loadJobDetailForce(parseInt(params.jobId, 10));
        }
      },
      requireData: [
        () => StorageActionCreators.loadBucketsAndTables(),
        (params) =>
          JobsActionCreators.loadJobDetail(parseInt(params.jobId, 10)).then(() => {
            const job = JobsStore.get(parseInt(params.jobId, 10));
            if (
              (job.get('component') === TRANSFORMATION &&
                job.hasIn(['params', 'transformations', 0])) ||
              (job.get('component') === PROVISIONING && job.hasIn(['params', 'transformation']))
            ) {
              return InstalledComponentsActionCreators.loadComponentConfigsData(TRANSFORMATION);
            }
            if (
              job.get('component') !== 'transformation' &&
              job.hasIn(['params', 'config']) &&
              job.hasIn(['params', 'row'])
            ) {
              return InstalledComponentsActionCreators.loadComponentConfigsData(
                getJobComponentId(job)
              );
            }
          })
      ]
    }
  ]
};

export default routes;
