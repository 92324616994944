import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import Loader from '../../../react/common/Loader';
import Select from '../../../react/common/Select';
import Tooltip from '../../../react/common/Tooltip';
import matchByWords from '../../../utils/matchByWords';

const GaMultiSelect = createReactClass({
  propTypes: {
    selectedValues: PropTypes.instanceOf(List).isRequired,
    onSelectValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isLoadingMetadata: PropTypes.bool.isRequired,
    metadata: PropTypes.array.isRequired,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    preferedOrderIds: PropTypes.array
  },

  getDefaultProps() {
    return {
      preferedOrderIds: [],
      labelClassName: 'col-md-2 control-label',
      wrapperClassName: 'col-md-10'
    };
  },

  render() {
    if (!this.props.isEditing) {
      return this.renderStatic();
    }

    return (
      <div className="form-group">
        <label className={this.props.labelClassName}>{this.props.name}</label>
        <div className={this.props.wrapperClassName}>
          <Select
            multi
            allowCreate
            isLoading={this.props.isLoadingMetadata}
            value={this.props.selectedValues}
            filterOption={this.filterOption}
            options={this.prepareOptionsData(this.props.metadata)}
            onChange={this.props.onSelectValue}
            newOptionCreator={this.createNewOption}
          />
        </div>
      </div>
    );
  },

  renderStatic() {
    return (
      <div className="form-group">
        <label className={'control-label ' + this.props.labelClassName}>
          {this.props.name}
          {this.props.isLoadingMetadata && <Loader className="icon-addon-left" />}
        </label>
        <div className={this.props.wrapperClassName}>
          <p className="form-control-static">{this.renderSelected()}</p>
        </div>
      </div>
    );
  },

  renderSelected() {
    if (!this.props.selectedValues.count()) {
      return 'N/A';
    }

    return this.props.selectedValues
      .map((val, idx) => this.renderStaticOption(val, idx))
      .reduce((prev, curr) => [prev, ', ', curr]);
  },

  renderStaticOption(optionId, idx) {
    const option = this.props.metadata.find((op) => op.id === optionId);
    const name = option?.attributes?.uiName || 'N/A';
    const description = option?.attributes?.description;

    if (!description) {
      return (
        <span key={idx}>
          {optionId}({name})
        </span>
      );
    }

    return (
      <Tooltip key={idx} tooltip={description} placement="top" type="explanatory">
        <span>
          {optionId}({name})
        </span>
      </Tooltip>
    );
  },

  filterOption(option, filter) {
    return matchByWords(
      [option.value, option.attributes?.uiName, option.attributes?.description],
      filter
    );
  },

  createNewOption(inputValue) {
    const found = this.props.metadata.find((m) => m.id === inputValue);

    if (found) {
      return found;
    }

    return { value: inputValue, label: inputValue };
  },

  prepareOptionsData(data) {
    const alldata = data.map((option) => ({
      value: option.id,
      label: option.attributes ? (
        <>
          <div>
            {option.id} ({option.attributes.uiName || 'N/A'})
          </div>
          {option.attributes.description && <small>{option.attributes.description}</small>}
        </>
      ) : (
        option.id
      ),
      selectedLabel: option.id,
      attributes: option.attributes
    }));

    const orderedData = this.props.preferedOrderIds
      .filter((i) => alldata.find((pi) => pi.id === i))
      .map((i) => alldata.find((pi) => pi.id === i));

    const restData = alldata
      .filter((d) => !this.props.preferedOrderIds.includes(d.id))
      .sort((a) => a.values);

    return orderedData.concat(restData);
  }
});

export default GaMultiSelect;
