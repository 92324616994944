import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import InfiniteScroll from 'react-infinite-scroller';
import createReactClass from 'create-react-class';
import { Map, Seq } from 'immutable';

import BlockButton from '../../../react/common/BlockButton';
import RefreshIcon from '../../../react/common/RefreshIcon';
import SearchPanel from '../../../react/common/SearchPanel';
import Tooltip from '../../../react/common/Tooltip';
import { factory as defaultEventsFactory } from '../../sapi-events/EventsService';
import EventsTable from './EventsTable';

const predefinedSearches = [
  {
    name: 'Omit tables fetches',
    query: 'NOT event:storage.tableDataPreview OR NOT event:storage.tableDetail'
  },
  {
    name: 'Omit tables exports',
    query: 'NOT event:storage.tableExported'
  },
  {
    name: 'Import/Exports only',
    query: ['ImportStarted', 'ImportDone', 'ImportError', 'Exported']
      .map((type) => `event:storage.table${type}`)
      .join(' OR ')
  }
];

/** @type {any} */
const Events = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    admins: PropTypes.instanceOf(Map).isRequired,
    eventsFactory: PropTypes.object
  },

  getDefaultProps() {
    return {
      eventsFactory: defaultEventsFactory()
    };
  },

  getInitialState() {
    return {
      events: Seq(),
      isLoadingMore: false,
      isRefreshing: false,
      isLoading: false,
      hasMore: true,
      searchQuery: ''
    };
  },

  componentDidMount() {
    this._events = this.props.eventsFactory;
    this._events.addChangeListener(this.handleChange);
    this._events.load();
  },

  componentWillUnmount() {
    this._events.removeChangeListener(this.handleChange);
    this._events.reset();
  },

  render() {
    return (
      <>
        <SearchPanel
          searchFieldPlaceholder="Search events"
          query={this.state.searchQuery}
          onSearch={this.handleQueryChange}
          onSubmit={this.handleSearchSubmit}
          predefinedSearches={predefinedSearches}
          additionalActions={this.renderRefreshButton()}
        />
        <div className="box">
          <InfiniteScroll
            initialLoad={false}
            hasMore={this.state.hasMore}
            loadMore={this.handleLoadMore}
          >
            <EventsTable
              events={this.state.events}
              admins={this.props.admins}
              isSearching={this.isSearching()}
            />
          </InfiniteScroll>
          {this.renderMoreButton()}
        </div>
      </>
    );
  },

  renderRefreshButton() {
    return (
      <Tooltip tooltip="Refresh events" placement="top">
        <Button onClick={this.handleRefresh} bsSize="sm">
          <RefreshIcon isLoading={this.state.isRefreshing} />
        </Button>
      </Tooltip>
    );
  },

  renderMoreButton() {
    if (this.state.events.isEmpty() || !this.state.hasMore || this.isSearching()) {
      return null;
    }

    return <BlockButton onClick={this.handleLoadMore} isLoading={this.state.isLoadingMore} />;
  },

  handleChange() {
    const isLoading = this._events.getIsLoading();

    this.setState({
      searchQuery: this._events.getQuery(),
      events: this._events.getEvents(),
      isRefreshing: isLoading && this.state.isRefreshing,
      isLoading,
      isLoadingMore: this._events.getIsLoadingOlder(),
      hasMore: this._events.getHasMore(),
      errorMessage: this._events.getErrorMessage()
    });
  },

  handleRefresh() {
    this.setState({ isRefreshing: true }, () => {
      this._events.load();
    });
  },

  handleLoadMore() {
    if (this.state.isLoadingMore) return;

    this._events.loadMoreMax();
  },

  handleQueryChange(query) {
    this.setState({ searchQuery: query });
  },

  handleSearchSubmit(searchQuery) {
    this._events.setQuery(searchQuery);
    this._events.load();
  },

  isSearching() {
    return this.state.isLoading && !this.state.isRefreshing;
  }
});

export default Events;
