import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, HelpBlock, Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import dayjs from '../../../date';
import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';
import Select from '../../../react/common/Select';

class UpdateProjectModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tempData: Map(),
      isSaving: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() =>
          this.setState({
            tempData: Map({
              name: this.props.project.get('name'),
              type: this.props.project.get('type'),
              expirationDays: this.props.project.get('expires')
                ? Math.max(
                    0,
                    Math.round(dayjs(this.props.project.get('expires')).diff(dayjs(), 'days', true))
                  )
                : '0',
              billedMonthlyPrice: this.props.project.get('billedMonthlyPrice')
            })
          })
        }
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Update Project Settings</Modal.Title>
            <ModalIcon icon="pen" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Project name</ControlLabel>
              <FormControl
                type="text"
                value={this.state.tempData.get('name') || ''}
                onChange={(e) =>
                  this.setState({ tempData: this.state.tempData.set('name', e.target.value) })
                }
              />
            </FormGroup>
            {this.props.isSuperAdmin && (
              <>
                <FormGroup>
                  <ControlLabel>Type</ControlLabel>
                  <Select
                    clearable={false}
                    value={this.state.tempData.get('type') || ''}
                    onChange={(selected) =>
                      this.setState({ tempData: this.state.tempData.set('type', selected) })
                    }
                    options={this.props.projectTemplates
                      .map((template) => ({
                        value: template.get('id'),
                        label: template.get('description')
                      }))
                      .toArray()}
                  />
                  <HelpBlock>
                    The change does not affect limits settings. Just the project type is changed.
                  </HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Expires in</ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.tempData.get('expirationDays') || ''}
                    onChange={(e) =>
                      this.setState({
                        tempData: this.state.tempData.set('expirationDays', e.target.value)
                      })
                    }
                  />
                  <HelpBlock>
                    Set to <code>0</code> to disable expiration.
                  </HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Monthly Fee $</ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.tempData.get('billedMonthlyPrice') || ''}
                    onChange={(e) =>
                      this.setState({
                        tempData: this.state.tempData.set('billedMonthlyPrice', e.target.value)
                      })
                    }
                  />
                </FormGroup>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={this.state.isSaving ? 'Saving changes...' : 'Save changes'}
              isSaving={this.state.isSaving}
              isDisabled={this.isDisabled()}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  isDisabled() {
    return (
      this.state.tempData.get('name') === this.props.project.get('name') &&
      this.state.tempData.get('type') === this.props.project.get('type') &&
      this.state.tempData.get('expirationDays') === this.props.project.get('expirationDays') &&
      this.state.tempData.get('billedMonthlyPrice') === this.props.project.get('billedMonthlyPrice')
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isSaving: true });
    this.props
      .onSubmit(
        this.props.isSuperAdmin
          ? this.state.tempData.toJS()
          : { name: this.state.tempData.get('name') }
      )
      .then(this.props.onHide)
      .finally(() => this.setState({ isSaving: false }));
  }
}

UpdateProjectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
  projectTemplates: PropTypes.instanceOf(List).isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default UpdateProjectModal;
