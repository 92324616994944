import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'underscore';

import FastFade from './FastFade';
import Loader from './Loader';

const RowActionDropdown = (props: {
  children: React.ReactNode;
  toggleClassName?: string;
  showLoading?: boolean;
  inModal?: boolean;
}) => {
  const [dropup, setDropup] = React.useState(false);
  const dropdownId = React.useRef(_.uniqueId('row-action-'));

  if (!props.children) {
    return null;
  }

  return (
    <span
      onClick={(event) => {
        // We have to catch this event and decide what to do with it, because
        // sometimes RowActionDropdown component is used within Anchor (e.g. for
        // configurations in generic Docker components).
        //
        // Note that all events will be caught, also clicks on menu items in
        // Dropdown.Menu.
        //
        // To make Anchors work, we have to check if event.target has href attr and
        // decide whether to "prevent default" or not, so Anchors will work.
        if (props.inModal || 'href' in event.target) {
          return;
        }

        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <Dropdown
        id={dropdownId.current}
        className="row-dropdown"
        dropup={dropup}
        onToggle={(isOpen: boolean, event: any) => {
          if (!isOpen && dropup) {
            return setDropup(false);
          }

          const rect = event?.target?.getBoundingClientRect();

          if (rect && rect.bottom > 400 && window.innerHeight * 0.6 < rect.bottom) {
            return setDropup(true);
          }
        }}
        pullRight
      >
        <Dropdown.Toggle noCaret bsStyle="link" className={props.toggleClassName}>
          {props.showLoading ? <Loader /> : <FontAwesomeIcon icon={['far', 'ellipsis']} />}
        </Dropdown.Toggle>
        <LazyDropdownMenu bsRole="menu">{props.children}</LazyDropdownMenu>
      </Dropdown>
    </span>
  );
};

const LazyDropdownMenu = React.forwardRef(({ children, ...restProps }: any, ref) => {
  return (
    <FastFade appear in={!!restProps.open} mountOnEnter>
      <Dropdown.Menu {...restProps} ref={ref}>
        {children}
      </Dropdown.Menu>
    </FastFade>
  );
});

export default RowActionDropdown;
