import React from 'react';
import {
  Alert,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  InputGroup
} from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import { componentTypes } from '../../../constants/componentTypes';
import ClipboardButton from '../../../react/common/Clipboard';
import ConfigurationInfoPanel from '../../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../../react/common/ConfigurationTabs';
import Loader from '../../../react/common/Loader';
import Sidebar from '../../../react/layout/Sidebar';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import getDefaultBucket from '../../../utils/getDefaultBucket';
import ComponentDescription from '../../components/react/components/ComponentDescription';
import Processors from '../../components/react/components/Processors';
import SapiTableLinkEx from '../../components/react/components/StorageApiTableLinkEx';
import ComponentsStore from '../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../components/stores/InstalledComponentsStore';
import StorageTablesStore from '../../components/stores/StorageTablesStore';
import { prepareTablesMetadataMap } from '../../storage/helpers';
import actionsProvisioning from '../actionsProvisioning';
import storeProvisioning, { storeMixins } from '../storeProvisioning';
import ConfigurationForm from './ConfigurationForm';

const COMPONENT_ID = 'keboola.ex-email-attachments';

const Index = createReactClass({
  mixins: [createStoreMixin(...storeMixins, ApplicationStore, ComponentsStore, StorageTablesStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const store = storeProvisioning(configId);
    const actions = actionsProvisioning(configId);

    return {
      configId: configId,
      store: store,
      actions: actions,
      localState: store.getLocalState(),
      settings: store.settings,
      processors: store.processors,
      allTables: StorageTablesStore.getAll(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      component: ComponentsStore.getComponent(COMPONENT_ID),
      readOnly: ApplicationStore.isReadOnly(),
      hasFlows: ApplicationStore.hasFlows()
    };
  },

  componentDidMount() {
    this.state.actions.requestEmailAndInitConfig();
  },

  getDefaultBucketName() {
    return getDefaultBucket('in', COMPONENT_ID, this.state.configId) + '.data';
  },

  renderImportedResult() {
    return (
      <FormGroup>
        <Col componentClass={ControlLabel} sm={4}>
          Output Table
        </Col>
        <Col sm={8}>
          <InputGroup>
            <SapiTableLinkEx tableId={this.getDefaultBucketName()} />
          </InputGroup>
          <HelpBlock>
            Table in Storage, where the .csv attachments will be imported. If the table or bucket
            does not exist, it will be created.
          </HelpBlock>
        </Col>
      </FormGroup>
    );
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={COMPONENT_ID} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.store.config}
          hasFlows={this.state.hasFlows}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={COMPONENT_ID}
              configId={this.state.configId}
              placeholderEntity={componentTypes.EXTRACTOR}
            />
            {this.state.store.requestedEmail ? this.renderConfig() : this.renderInitConfig()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={COMPONENT_ID}
              configId={this.state.configId}
              run={{
                disabled: this.invalidToRun(),
                text: 'You are about to run an extraction.'
              }}
            />
          </div>
        </div>
      </>
    );
  },

  renderInitConfig() {
    if (this.state.store.error) {
      return this.renderError();
    }

    return (
      <div className="box">
        <div className="box-content">
          <p>
            <Loader /> Generating email address, please wait.
          </p>
        </div>
      </div>
    );
  },

  renderConfig() {
    return (
      <>
        <div className="box">
          <div className="box-content">
            <div className="form-horizontal">
              <FormGroup>
                <Col componentClass={ControlLabel} sm={4}>
                  Email address
                </Col>
                <Col sm={8}>
                  <InputGroup>
                    <FormControl
                      type="email"
                      placeholder="Creating email, please wait"
                      readOnly
                      value={this.state.store.requestedEmail}
                      title="Email address generated by Email Attachments"
                    />
                    <InputGroup.Button>
                      <ClipboardButton
                        text={this.state.store.requestedEmail}
                        label="Copy email"
                        inline={false}
                      />
                    </InputGroup.Button>
                  </InputGroup>
                  <HelpBlock>
                    Please send emails with .csv attachment to this email address. Received
                    attachments will be imported after configuration execution. Only new emails are
                    imported on subsequent executions.
                  </HelpBlock>
                </Col>
              </FormGroup>
              {this.renderImportedResult()}
            </div>
          </div>
        </div>
        <ConfigurationForm
          readOnly={this.state.readOnly}
          incremental={this.state.settings.get('incremental')}
          delimiter={this.state.settings.get('delimiter')}
          enclosure={this.state.settings.get('enclosure')}
          primaryKey={this.state.settings.get('primaryKey')}
          onChange={this.state.actions.editChange}
          requestedEmail={this.state.store.requestedEmail}
          actions={this.state.actions}
          localState={this.state.localState}
        />
        {this.renderProcessors()}
      </>
    );
  },

  renderError() {
    const error = this.state.store.error;
    const code = error.code;
    let message = 'Unexpected error';
    try {
      const jsError = JSON.parse(error).error;
      message = jsError.message || jsError;
    } catch (e) {
      message = error.message || error;
    }

    return (
      <Alert bsStyle="danger">
        <p>Error: {message}</p>
        {code >= 500 ? <div>{error.get('exceptionId')}</div> : null}
      </Alert>
    );
  },

  invalidToRun() {
    if (
      !this.state.settings.get('enclosure') ||
      !this.state.settings.get('delimiter') ||
      !this.state.settings.get('email')
    ) {
      return 'Configuration has missing values';
    }
    if (this.state.localState.get('isChanged', false)) {
      return 'Configuration is not saved';
    }

    return '';
  },

  renderProcessors() {
    if (this.state.processors === '{}') {
      return null;
    }

    return (
      <Processors
        readOnly={this.state.readOnly}
        value={this.state.processors}
        onSubmit={this.state.actions.editProcessorsSave}
      />
    );
  }
});

export default Index;
