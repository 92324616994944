import React, { useEffect } from 'react';

import CollapsibleAlert from '../../../react/common/CollapsibleAlert';
import detectAdblock from '../../../utils/detectAdblock';

const AdblockDetector = () => {
  const [detected, setDetected] = React.useState(false);

  useEffect(() => {
    detectAdblock().then(setDetected);
  }, []);

  if (!detected) {
    return null;
  }

  return (
    <CollapsibleAlert title="Adblock detected" id="adblock-detector">
      <p>
        We have detected that your browser is blocking advertisements. This can interfere with our
        application and lead to seemingly missing things.
      </p>
      <p>
        We strongly recommend to whitelist the current site ({window.location.hostname}). We have no
        ads here anyway.
      </p>
    </CollapsibleAlert>
  );
};

export default AdblockDetector;
