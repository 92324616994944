import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import _ from 'underscore';

import BlockButton from '../../../react/common/BlockButton';
import fromJSOrdered from '../../../utils/fromJSOrdered';
import ParametersResultsModal from './ParametersResultsModal';

class JobsParametersResultsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalShown: false
    };
  }

  render() {
    const params = this.getParams();

    return (
      <>
        <div className="box-panel-content">
          <h4 className="mt-0 font-mono font-bold">Parameters</h4>
          <table className="params-table">
            <tbody>
              {params
                .filter((value) => _.isString(value))
                .map((value, key) => (
                  <tr key={key}>
                    <th>{key}</th>
                    <td>{value}</td>
                  </tr>
                ))
                .toArray()}
            </tbody>
          </table>
        </div>
        <BlockButton label="Show All" onClick={() => this.setState({ isModalShown: true })} />
        <ParametersResultsModal
          job={this.props.job}
          params={params}
          show={this.state.isModalShown}
          onHide={() => this.setState({ isModalShown: false })}
        />
      </>
    );
  }

  getParams() {
    const configData = this.props.job.get('configData', Map());

    return fromJSOrdered({
      mode: this.props.job.get('mode'),
      branchId: this.props.job.get('branchId'),
      component: this.props.job.get('component'),
      config: this.props.job.get('config'),
      row: this.props.job.get('configRowIds', List()).join(', '),
      tag: this.props.job.get('tag'),
      configData: configData.isEmpty() ? false : configData,
      variableValuesId: this.props.job.get('variableValuesId'),
      variableValuesData: this.props.job.get('variableValuesData'),
      onlyOrchestrationTaskIds: this.props.job.get('onlyOrchestrationTaskIds'),
      previousJobId: this.props.job.get('previousJobId'),
      parallelism: this.props.job.get('parallelism')
    }).filter(Boolean);
  }
}

JobsParametersResultsPanel.propTypes = {
  job: PropTypes.instanceOf(Map).isRequired
};

export default JobsParametersResultsPanel;
