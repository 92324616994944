const STACK_HOST_US_EAST_1_AWS = 'connection.keboola.com';
const STACK_HOST_EU_CENTRAL_1_AWS = 'connection.eu-central-1.keboola.com';
const STACK_HOST_NORTH_EUROPE_AZURE = 'connection.north-europe.azure.keboola.com';
const STACK_HOST_EUROPE_WEST3_GCP = 'connection.europe-west3.gcp.keboola.com';
const STACK_HOST_CSAS_PROD = 'connection.csas.keboola.cloud'; // private, single-tenant
const STACK_HOST_CSAS_TEST = 'connection.csas-test.keboola.cloud'; // private, single-tenant
const STACK_HOST_INNOGY = 'connection.innogy-hub.keboola.cloud'; // private, single-tenant

export {
  STACK_HOST_US_EAST_1_AWS,
  STACK_HOST_EU_CENTRAL_1_AWS,
  STACK_HOST_NORTH_EUROPE_AZURE,
  STACK_HOST_EUROPE_WEST3_GCP,
  STACK_HOST_CSAS_PROD,
  STACK_HOST_CSAS_TEST,
  STACK_HOST_INNOGY
};
