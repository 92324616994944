import React from 'react';

import ValidUntil from '../../../../react/common/ValidUntil';

const sandboxExtendedNotification = (validUntil) => () => {
  return (
    <>
      Sandbox has been extended. It will expire <ValidUntil validUntil={validUntil} />.
    </>
  );
};

export default sandboxExtendedNotification;
