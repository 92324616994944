import {
  STACK_HOST_EU_CENTRAL_1_AWS,
  STACK_HOST_EUROPE_WEST3_GCP,
  STACK_HOST_NORTH_EUROPE_AZURE,
  STACK_HOST_US_EAST_1_AWS
} from '../../constants/stackHosts';

const getProjectStackOptions = (isSingleTenant) => {
  if (isSingleTenant) {
    return [{ value: `https://${window.location.host}/`, label: window.location.host }];
  }

  let defaultOptions = [
    {
      value: `https://${STACK_HOST_EU_CENTRAL_1_AWS}/`,
      label: `AWS EU (${STACK_HOST_EU_CENTRAL_1_AWS})`
    },
    {
      value: `https://${STACK_HOST_US_EAST_1_AWS}/`,
      label: `AWS US (${STACK_HOST_US_EAST_1_AWS})`
    },
    {
      value: `https://${STACK_HOST_NORTH_EUROPE_AZURE}/`,
      label: `Azure EU (${STACK_HOST_NORTH_EUROPE_AZURE})`
    },
    {
      value: `https://${STACK_HOST_EUROPE_WEST3_GCP}/`,
      label: `GCP EU (${STACK_HOST_EUROPE_WEST3_GCP})`
    }
  ];

  if (
    ![
      STACK_HOST_EU_CENTRAL_1_AWS,
      STACK_HOST_US_EAST_1_AWS,
      STACK_HOST_NORTH_EUROPE_AZURE,
      STACK_HOST_EUROPE_WEST3_GCP
    ].includes(window.location.host)
  ) {
    defaultOptions.push({ value: `https://${window.location.host}/`, label: window.location.host });
  }

  return defaultOptions;
};

export { getProjectStackOptions };
