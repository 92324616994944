import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { FEATURE_ALTERNAT, FEATURE_ALTERNAT_WIZARD } from '../../../../constants/features';
import { PLATFORM_CHANGELOG_URL } from '../../../../constants/KbcConstants';
import CollapsibleAlert from '../../../../react/common/CollapsibleAlert';
import ComponentDetailLink from '../../../../react/common/ComponentDetailLink';
import ComponentName from '../../../../react/common/ComponentName';
import ExternalLink from '../../../../react/common/ExternalLink';
import Loader from '../../../../react/common/Loader';
import Truncated from '../../../../react/common/Truncated';
import ApplicationStore from '../../../../stores/ApplicationStore';
import { getNewComponentTypeLabel } from '../../../components/helpers';
import { addProjectFeature, removeProjectFeature } from '../../../settings/actions';

export const ANNOUNCEMENT_URL = `${PLATFORM_CHANGELOG_URL}/new-outbound-ip-addresses-for-keboola-connection-action-required/`;

const AlterNatNotification = (props: { readOnly: boolean; components: Map<string, any> }) => {
  const [isLoading, setLoading] = React.useState(false);

  const handleEnable = () => {
    setLoading(true);
    addProjectFeature(FEATURE_ALTERNAT).finally(() => setLoading(false));
  };

  const handleDisable = () => {
    setLoading(true);
    removeProjectFeature(FEATURE_ALTERNAT).finally(() => setLoading(false));
  };

  if (!ApplicationStore.hasCurrentProjectFeature(FEATURE_ALTERNAT_WIZARD)) {
    return null;
  }

  if (ApplicationStore.hasCurrentProjectFeature(FEATURE_ALTERNAT)) {
    return (
      <CollapsibleAlert
        bsStyle="info"
        title="New Outbound IP Addresses"
        id="alter-nat-notification"
      >
        <>
          <p>
            We are adding the new outbound IP addresses listed in this{' '}
            <ExternalLink href={ANNOUNCEMENT_URL}>announcement</ExternalLink>.
          </p>
          <h3>What must I do?</h3>
          <p>
            The project has already been switched to the new IPs. We encourage all users to switch
            to the new IP addresses, but the option to revert temporarily to the old IP addresses in
            the event of problems is available until further notice, or until the final transition
            takes place on June 30, 2023.
          </p>
          <Button
            onClick={handleDisable}
            disabled={props.readOnly || isLoading}
            className="color-main"
          >
            {isLoading ? (
              <Loader className="icon-addon-right" />
            ) : (
              <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
            )}
            Switch Project to old IPs
          </Button>
        </>
      </CollapsibleAlert>
    );
  }

  const affectedComponents = props.components
    .filter((component) => {
      return (
        component.getIn(['data', 'synchronous_actions'], List()).includes('testConnection') &&
        component.get('configurations', Map()).some((config: Map<string, any>) => {
          return config.hasIn(['configuration', 'parameters', 'db']);
        })
      );
    })
    .groupBy((component) => component.get('type'))
    .reverse();

  return (
    <CollapsibleAlert title="New Outbound IP Addresses" id="alter-nat-notification">
      <>
        <p>
          We are adding the new outbound IP addresses listed in this{' '}
          <ExternalLink href={ANNOUNCEMENT_URL}>announcement</ExternalLink>.
        </p>
        <h3>What must I do?</h3>
        <p>
          Please ensure that you have added all of these IP addresses to your firewall&apos;s
          whitelist, to enable Keboola Connection to connect to your system.
        </p>
        <p>
          It is essential that you review and configure your firewalls before{' '}
          <strong>June 30, 2023</strong>. Failure to do so may result in technical problems when
          switching to the new IPs. Once you have completed this task, you will be able to switch to
          the new IPs directly from your project. However, in the event of any problems, you can
          temporarily revert to your original IP address and contact our support team for
          assistance.
        </p>
        {!affectedComponents.isEmpty() && (
          <>
            <h3>Potentially affected components</h3>
            <p>
              You can check the connectivity before the switch for the following components, using
              Test Credentials:
            </p>
            <div className="flex-container flex-start align-top">
              {affectedComponents
                .map((components, type) => {
                  return (
                    <div
                      key={type}
                      className="flex-container flex-column align-top flex-inline w-300 mlp-4 mr-2 mb-1"
                    >
                      <h3 className="f-14">{getNewComponentTypeLabel(type)}s</h3>
                      <ul className="mlp-2">
                        {components
                          ?.map((component) => {
                            return (
                              <li key={component.get('id')} className="color-primary">
                                <ComponentDetailLink
                                  componentId={component.get('id')}
                                  className="line-height-24 f-14 color-primary"
                                >
                                  <ComponentName component={component}>
                                    {(componentName) => <Truncated text={componentName} />}
                                  </ComponentName>
                                </ComponentDetailLink>
                              </li>
                            );
                          })
                          .toArray()}
                      </ul>
                    </div>
                  );
                })
                .toArray()}
            </div>
          </>
        )}
        <Button bsStyle="success" disabled={props.readOnly || isLoading} onClick={handleEnable}>
          {isLoading && <Loader className="icon-addon-right" />}
          Switch Project to new IPs
        </Button>
      </>
    </CollapsibleAlert>
  );
};

export default AlterNatNotification;
