import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'underscore';

import Tooltip from './Tooltip';

const OFFSET_TOLERANCE = 2;

const Truncated = ({ text, title = text, className, twoLines = false, noTooltip = false }) => {
  const tooltip = _.isString(title) ? title : '';
  const [forceHide, setForceHide] = React.useState(false);

  return (
    <Tooltip
      placement="top"
      tooltip={tooltip}
      type="explanatory"
      forceHide={forceHide || noTooltip || !tooltip}
    >
      <span
        className={classNames(className, 'truncated', { 'truncated-2-lines': twoLines })}
        onMouseOver={(e) => {
          setForceHide(
            e.currentTarget.scrollHeight - OFFSET_TOLERANCE <= e.currentTarget.clientHeight
          );
        }}
      >
        {text}
      </span>
    </Tooltip>
  );
};

Truncated.propTypes = {
  text: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  twoLines: PropTypes.bool,
  noTooltip: PropTypes.bool
};

export default Truncated;
