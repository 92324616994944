import React from 'react';
import { Panel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

type Props = {
  children: React.ReactNode;
  defaultExpanded?: boolean;
  labelCollapse?: React.ReactNode;
  labelOpen?: React.ReactNode;
  placement?: 'bottom' | 'top';
};

type State = {
  expanded: boolean;
  panelHeaderTitle: React.ReactNode;
};

class PanelWithDetails extends React.Component<Props, State> {
  state = {
    expanded: !!this.props.defaultExpanded,
    panelHeaderTitle: this.props.defaultExpanded ? this.props.labelCollapse : this.props.labelOpen
  };

  static defaultProps = {
    defaultExpanded: false,
    labelCollapse: 'Hide details',
    labelOpen: 'Show details',
    placement: 'top'
  };

  render() {
    return (
      <Panel
        onToggle={this.toggleExpanded}
        expanded={this.state.expanded}
        className={classnames('panel-show-details', `panel-show-details-${this.props.placement}`)}
      >
        <Panel.Heading>
          <Panel.Title toggle>
            <FontAwesomeIcon
              fixedWidth
              icon={
                this.state.expanded
                  ? this.props.placement === 'bottom'
                    ? 'angle-up'
                    : 'angle-down'
                  : 'angle-right'
              }
              className="icon-addon-right"
            />
            {this.state.panelHeaderTitle}
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>{this.props.children}</Panel.Body>
      </Panel>
    );
  }

  toggleExpanded = (expanded: boolean, event: React.MouseEvent) => {
    event.stopPropagation();

    this.setState({
      expanded,
      panelHeaderTitle: expanded ? this.props.labelCollapse : this.props.labelOpen
    });
  };
}

export default PanelWithDetails;
