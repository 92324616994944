import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Map } from 'immutable';

import { KEBOOLA_DATA_APPS } from '../../../constants/componentIds';
import { componentTypes } from '../../../constants/componentTypes';
import ComponentIcon from '../../../react/common/ComponentIcon';
import Tooltip from '../../../react/common/Tooltip';

const TemplateIcons = ({
  allComponents,
  componentsIds,
  className
}: {
  allComponents: Map<string, any>;
  componentsIds: string[];
  className?: string;
}) => {
  const uniqueComponentsIds = [...new Set(componentsIds)];

  const sourcesIcons = uniqueComponentsIds.filter((componentId) => {
    return allComponents.get(componentId, Map()).get('type') === componentTypes.EXTRACTOR;
  });
  const dataAppsIcons = uniqueComponentsIds.filter((componentId) => {
    return componentId === KEBOOLA_DATA_APPS;
  });
  const destinationIcons = uniqueComponentsIds.filter((componentId) => {
    return !sourcesIcons.includes(componentId) && !dataAppsIcons.includes(componentId);
  });

  const renderIcons = (icons: string[], number: number) => {
    return icons
      .slice(0, number)
      .map((componentId) => (
        <ComponentIcon size="48" key={componentId} component={allComponents.get(componentId)} />
      ));
  };

  const renderTooltip = (icons: string[], number: number) => {
    const items = icons.slice(number);

    if (!items.length) {
      return null;
    }

    return (
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={items
          .map((componentId: string) => allComponents.getIn([componentId, 'name']))
          .reduce((componentA: string, componentB: string) => [
            componentA,
            <br key={`${componentA}-${componentB}`} />,
            componentB
          ])}
      >
        <span className="f-16 font-medium text-muted dashed-underline cursor-help">
          +{icons.length - number}
        </span>
      </Tooltip>
    );
  };

  const renderIconGroup = (icons: string[], limit: number, hasNext?: boolean) => {
    if (!icons.length) {
      return null;
    }

    return (
      <>
        {renderIcons(icons, limit)}
        {renderTooltip(icons, limit)}
        {hasNext && (
          <FontAwesomeIcon icon="square-chevron-right" className="f-16 text-muted-light" />
        )}
      </>
    );
  };

  return (
    <div className={classnames('flex-container icon-sets', className)}>
      {renderIconGroup(sourcesIcons, 2, !!dataAppsIcons.length || !!destinationIcons.length)}
      {renderIconGroup(dataAppsIcons, 1, !!destinationIcons.length)}
      {renderIconGroup(destinationIcons, 2)}
    </div>
  );
};

export default TemplateIcons;
