import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { capitalize } from 'underscore.string';

import { BACKEND_SIZES } from '../../modules/components/Constants';
import { JOBS_STATUS } from '../../modules/queue/constants';
import Tooltip from './Tooltip';

const CLASS_MAP = {
  [JOBS_STATUS.CREATED]: 'color-primary',
  [JOBS_STATUS.WAITING]: 'text-muted',
  [JOBS_STATUS.PROCESSING]: 'color-primary',
  [JOBS_STATUS.SUCCESS]: 'text-success',
  [JOBS_STATUS.ERROR]: 'text-danger',
  warn: 'color-warning',
  [JOBS_STATUS.WARNING]: 'color-warning',
  canceled: 'color-dark-muted',
  [JOBS_STATUS.CANCELLED]: 'color-dark-muted',
  [JOBS_STATUS.TERMINATED]: 'color-dark',
  [JOBS_STATUS.TERMINATING]: 'color-dark'
};

const JobStatusLabel = ({ status, backendType }) => {
  if (!CLASS_MAP[status]) {
    return null;
  }

  return (
    <span className={classNames('job-status-label', CLASS_MAP[status])}>
      {capitalize(status)}
      {JOBS_STATUS.CREATED === status &&
        [BACKEND_SIZES.MEDIUM, BACKEND_SIZES.LARGE].includes(backendType) && (
          <Tooltip
            placement="top"
            type="explanatory"
            tooltip="It might take a bit longer to process jobs with larger backend sizes."
          >
            <FontAwesomeIcon icon="circle-info" className="status-icon" />
          </Tooltip>
        )}
    </span>
  );
};

JobStatusLabel.propTypes = {
  status: PropTypes.oneOf(Object.keys(CLASS_MAP)).isRequired,
  backendType: PropTypes.oneOf(Object.values(BACKEND_SIZES))
};

export default JobStatusLabel;
