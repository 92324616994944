import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, ControlLabel, FormGroup, HelpBlock, ProgressBar } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { DEVELOPERS_DOCUMENTATION_URL } from '../../../../constants/KbcConstants';
import ExternalLink from '../../../../react/common/ExternalLink';
import FilesDropZone from '../../../../react/common/FilesDropZone';

const Upload = createReactClass({
  propTypes: {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    isFileBig: PropTypes.bool.isRequired,
    isUploading: PropTypes.bool.isRequired,
    uploadingMessage: PropTypes.string.isRequired,
    uploadingProgress: PropTypes.number.isRequired,
    resultMessage: PropTypes.string.isRequired,
    resultState: PropTypes.string.isRequired,
    dismissResult: PropTypes.func.isRequired,
    file: PropTypes.object
  },

  onChange(files) {
    this.props.onChange(files[0]);
  },

  uploadStatus() {
    if (this.props.resultMessage) {
      let alertStyle = 'info';

      if (this.props.resultState === 'error') {
        alertStyle = 'danger';
      } else if (this.props.resultState === 'success') {
        alertStyle = 'success';
      }

      return (
        <FormGroup>
          <Col xs={9} xsOffset={3}>
            <Alert bsStyle={alertStyle}>
              <button type="button" className="close" onClick={this.props.dismissResult}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
              {this.props.resultMessage}
            </Alert>
          </Col>
        </FormGroup>
      );
    }

    if (!this.props.isUploading) {
      return null;
    }

    return (
      <FormGroup>
        <Col xs={9} xsOffset={3}>
          {this.props.uploadingMessage}
          <ProgressBar active now={this.props.uploadingProgress} />
        </Col>
      </FormGroup>
    );
  },

  renderManualImportInfo() {
    return (
      <>
        Please refer to{' '}
        <ExternalLink
          className="color-inherit"
          href={`${DEVELOPERS_DOCUMENTATION_URL}/integrate/storage/api/importer/`}
        >
          documentation
        </ExternalLink>{' '}
        to perform a manual import using command line or other tools.
      </>
    );
  },

  render() {
    return (
      <div className="form-horizontal">
        <FormGroup>
          <Col xs={3} componentClass={ControlLabel}>
            Select file
          </Col>
          <Col xs={9}>
            <FilesDropZone
              onDrop={this.onChange}
              files={this.props.file}
              isDisabled={this.props.disabled || this.props.isUploading}
              helpText={
                <>
                  Maximum file size 2GB (recommended 100MB)
                  <br />
                  Only <span className="text-danger">.csv</span>,{' '}
                  <span className="text-danger">.tsv</span> and{' '}
                  <span className="text-danger">.gz</span> (gzipped CSV or TSV) files are supported.
                  <br />
                  The maximum transfer time is 45 minutes.
                </>
              }
            />
            {this.props.isFileBig && (
              <HelpBlock className="text-danger">
                The CSV file is larger than 100MB, your upload may not be successful.{' '}
                {this.renderManualImportInfo()}
              </HelpBlock>
            )}
          </Col>
        </FormGroup>
        {this.uploadStatus()}
      </div>
    );
  }
});

export default Upload;
