import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import type { List, Map } from 'immutable';
import { titleize } from 'underscore.string';

import type { Instance, InstanceDetail, Template } from '../../../api/routes/templatesService';
import { COLLAPSED_TEMPLATES } from '../../../constants/localStorageKeys';
import BlockButton from '../../../react/common/BlockButton';
import CircleIcon from '../../../react/common/CircleIcon';
import CollapseButton from '../../../react/common/CollapseButton';
import ConfirmModal from '../../../react/common/ConfirmModal';
import Loader from '../../../react/common/Loader';
import MultiActionsHeader from '../../../react/common/MultiActionsHeader';
import MultiActionsSelectCheckbox from '../../../react/common/MultiActionsSelectCheckbox';
import RouterLink from '../../../react/common/RouterLink';
import RowActionDropdown from '../../../react/common/RowActionDropdown';
import RowActionMenuItem from '../../../react/common/RowActionMenuItem';
import SortIcon from '../../../react/common/SortIcon';
import TimeAndUser from '../../../react/common/TimeAndUser';
import Tooltip from '../../../react/common/Tooltip';
import Truncated from '../../../react/common/Truncated';
import useLocalStorage from '../../../react/hooks/useLocalStorage';
import RoutesStore from '../../../stores/RoutesStore';
import string from '../../../utils/string';
import actions from '../actions';
import { routeNames } from '../constants';
import NewInstanceModal from './NewInstanceModal';
import TemplateIcons from './TemplateIcons';

type CommonProps = {
  instances: Instance[];
  admins: Map<string, any>;
  tokens: List<any>;
  readOnly: boolean;
};

type SortableColumnId = 'name' | 'version' | 'date';

const InstancesTableWithTemplateBox = ({
  template,
  instances,
  allComponents,
  admins,
  tokens,
  forceShowAll,
  readOnly = false
}: CommonProps & {
  template: Template;
  allComponents: Map<string, any>;
  forceShowAll: boolean;
}) => {
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    `${COLLAPSED_TEMPLATES}-${template.id}`,
    false
  );

  const toggleCollapse = () => {
    if (forceShowAll) {
      return;
    }

    setIsCollapsed(!isCollapsed);
    (document.activeElement as HTMLElement | null)?.blur();
  };

  if (!template) return null;

  return (
    <div className="box">
      <div className="box-content p-0">
        <div
          onClick={toggleCollapse}
          className={classnames('flex-container component-name-above-table', {
            'btn-collapse-area': !forceShowAll
          })}
        >
          <div className="flex-container align-top flex-column">
            <div className="flex-container">
              {template.components.length > 0 ? (
                <TemplateIcons allComponents={allComponents} componentsIds={template.components} />
              ) : (
                <CircleIcon icon="book-open" bigger bold />
              )}
            </div>
            <RouterLink to={routeNames.TEMPLATE_DETAIL} params={{ templateId: template.id }}>
              <Truncated
                text={template.name}
                className="ptp-4 f-16 font-medium letter-spacing-narrower"
              />
            </RouterLink>
          </div>
          {!forceShowAll && (
            <CollapseButton
              entity="template instances"
              isCollapsed={isCollapsed}
              onToggle={toggleCollapse}
            />
          )}
        </div>
        {isCollapsed && !forceShowAll ? (
          <div className="collapsed-configurations clickable" onClick={toggleCollapse}>
            <span className="font-bold">{instances.length} </span>
            <span className="text-muted">
              Template {string.pluralize(instances.length, 'Instance')}
            </span>
          </div>
        ) : (
          <InstancesTable
            template={template}
            instances={instances}
            admins={admins}
            tokens={tokens}
            readOnly={readOnly}
          />
        )}
      </div>
    </div>
  );
};

export const InstancesTable = ({
  template,
  instances,
  admins,
  tokens,
  readOnly = false
}: CommonProps & {
  template: Template | InstanceDetail['templateDetail'];
}) => {
  const [showNewInstanceModal, setShowNewInstanceModal] = useState(false);
  const [sort, setSort] = useState<{
    id: SortableColumnId;
    direction: number;
  }>({
    id: 'date',
    direction: 1
  });
  const [selectedInstances, setSelectedInstances] = useState<Record<string, boolean>>({});
  const [instancesToDelete, setInstancesToDelete] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const sortValueGetter = useCallback(
    (instance: Instance) => {
      if (sort.id === 'date') {
        return new Date(instance.updated.date ?? instance.created.date).valueOf();
      }

      return instance[sort.id];
    },
    [sort.id]
  );

  if (!template) return null;

  return (
    <>
      <div className="table table-hover overflow-break-anywhere">
        <div className="thead">
          <div className="tr">
            <span className="th">
              <MultiActionsHeader
                hide={readOnly}
                disabled={isDeleting}
                totalCount={instances.length}
                selectedCount={Object.values(selectedInstances).length}
                onToggleAll={(checked) =>
                  setSelectedInstances(
                    !checked
                      ? {}
                      : instances.reduce(
                          (selected, { instanceId }) => ({
                            ...selected,
                            [instanceId]: true
                          }),
                          {}
                        )
                  )
                }
                placeholder={
                  <SortableColumnTitle
                    id="name"
                    direction={sort.direction}
                    isActive={sort.id === 'name'}
                    onClickHandler={setSort}
                  />
                }
                entity="template"
              >
                <div className="table-action-buttons">
                  <Tooltip placement="top" tooltip="Delete Selected">
                    <Button
                      bsStyle="link"
                      className="text-muted"
                      onClick={() => setInstancesToDelete(Object.keys(selectedInstances))}
                      disabled={isDeleting}
                    >
                      {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
                    </Button>
                  </Tooltip>
                </div>
              </MultiActionsHeader>
            </span>
            <div className="th w-175 text-right">
              <SortableColumnTitle
                id="version"
                label="template version"
                direction={sort.direction}
                isActive={sort.id === 'version'}
                onClickHandler={setSort}
              />
            </div>
            <div className="th w-280 text-right">
              <SortableColumnTitle
                id="date"
                label="last change"
                direction={sort.direction}
                isActive={sort.id === 'date'}
                onClickHandler={setSort}
              />
            </div>
          </div>
        </div>
      </div>
      {!readOnly && (
        <BlockButton
          style="primary"
          className="add-button"
          label={
            <>
              <FontAwesomeIcon icon="plus" fixedWidth />
              Use Template
            </>
          }
          onClick={() => setShowNewInstanceModal(true)}
        />
      )}
      <div className="table table-hover overflow-break-anywhere">
        <div className="tbody">
          {instances
            .sort((a, b) => {
              const valueA = sortValueGetter(a);
              const valueB = sortValueGetter(b);
              if (valueA < valueB) return 1 * sort.direction;
              if (valueA > valueB) return -1 * sort.direction;
              return 0;
            })
            .map((instance) => {
              const token = tokens.find((token) => token.get('id') === instance.updated.tokenId);
              const admin = token ? admins.get(token.get('description')) : null;

              return (
                <RouterLink
                  key={instance.instanceId}
                  to={routeNames.INSTANCE_DETAIL}
                  params={{ instanceId: instance.instanceId }}
                  className="tr hoverable-actions-with-replacement color-inherit"
                >
                  <div className="td">
                    {!readOnly && (
                      <MultiActionsSelectCheckbox
                        isChecked={!!selectedInstances[instance.instanceId]}
                        isDisabled={readOnly || isDeleting}
                        onToggle={(checked) => {
                          const newSelectedInstances = { ...selectedInstances };

                          if (checked) {
                            newSelectedInstances[instance.instanceId] = true;
                          } else {
                            delete newSelectedInstances[instance.instanceId];
                          }

                          setSelectedInstances(newSelectedInstances);
                        }}
                        entity="template"
                      />
                    )}
                    <span className={classnames({ 'ml-1': !readOnly })}>{instance.name}</span>
                  </div>
                  <div className="td w-175 text-right text-muted">{instance.version}</div>
                  <div className="td w-280 text-right no-wrap">
                    {readOnly ? (
                      <TimeAndUser admin={admin} time={instance.updated.date} />
                    ) : (
                      <div className="actions-container">
                        <div className="not-actions">
                          <TimeAndUser admin={admin} time={instance.updated.date} />
                        </div>
                        <div className="actions">
                          <RowActionDropdown>
                            <RowActionMenuItem
                              disabled={isDeleting}
                              onSelect={() => setInstancesToDelete([instance.instanceId])}
                            >
                              <FontAwesomeIcon icon="trash" fixedWidth />
                              Delete Template Instance
                            </RowActionMenuItem>
                          </RowActionDropdown>
                        </div>
                      </div>
                    )}
                  </div>
                </RouterLink>
              );
            })}
        </div>
      </div>
      <NewInstanceModal
        showModal={showNewInstanceModal}
        onHide={() => setShowNewInstanceModal(false)}
        templateDetail={template}
      />
      <ConfirmModal
        closeAfterResolve
        show={!!instancesToDelete.length}
        icon="trash"
        title="Delete Selected"
        text={`Are you sure you want to delete ${
          instancesToDelete.length > 1 ? 'selected templates' : 'template'
        }?`}
        buttonLabel="Delete"
        buttonType="danger"
        onConfirm={() => {
          setIsDeleting(true);

          return actions
            .deleteInstances(instancesToDelete)
            .then((remainingInstances) => {
              if (!remainingInstances?.length) {
                RoutesStore.getRouter().transitionTo(routeNames.TEMPLATES);
                return null;
              }

              setIsDeleting(false);
              setSelectedInstances({});
            })
            .catch((error) => {
              setIsDeleting(false);

              throw error;
            });
        }}
        onHide={() => setInstancesToDelete([])}
        isLoading={isDeleting}
      />
    </>
  );
};

const SortableColumnTitle = ({
  id,
  label = id,
  direction,
  isActive,
  onClickHandler
}: {
  id: SortableColumnId;
  label?: string;
  direction: number;
  isActive: boolean;
  onClickHandler: (sort: { id: SortableColumnId; direction: number }) => void;
}) => {
  return (
    <span
      className="clickable"
      title={`Sort by ${label}`}
      onClick={() => onClickHandler({ id, direction: isActive ? direction * -1 : 1 })}
    >
      <SortIcon isSorted={isActive} isSortedDesc={direction === -1} />
      {titleize(label)}
    </span>
  );
};

export default InstancesTableWithTemplateBox;
