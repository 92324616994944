import { fromJS, Map } from 'immutable';

import Dispatcher from '../../../Dispatcher';
import StoreUtils, { initStore } from '../../../utils/StoreUtils';
import { filesLimit } from '../../storage/constants';
import * as constants from '../Constants';

let _store = initStore(
  'StorageFilesStore',
  Map({
    files: Map(),
    params: Map(),
    hasMore: true,
    isLoaded: false,
    isLoading: false,
    uploadingProgress: Map(),
    isLoadingMore: false,
    isDeleting: Map()
  })
);

const filesByIds = (jobs) => {
  return fromJS(jobs)
    .toMap()
    .mapKeys((key, val) => val.get('id'));
};

const StorageFilesStore = StoreUtils.createStore({
  getAll() {
    return _store.get('files');
  },

  hasMore() {
    return _store.get('hasMore');
  },

  getIsLoading() {
    return _store.get('isLoading');
  },

  getIsDeleting() {
    return _store.get('isDeleting', Map());
  },

  getIsLoadingMore() {
    return _store.get('isLoadingMore');
  },

  getIsLoaded() {
    return _store.get('isLoaded');
  },

  getAllUploadingProgress() {
    return _store.get('uploadingProgress', Map());
  },

  getUploadingProgress(id) {
    return _store.getIn(['uploadingProgress', id], 0);
  }
});

Dispatcher.register(function (payload) {
  const { action } = payload;

  switch (action.type) {
    case constants.ActionTypes.STORAGE_FILES_LOAD:
      _store = _store.set('isLoading', true);
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILES_LOAD_SUCCESS:
      _store = _store.withMutations((store) =>
        store
          .set(
            'files',
            Map(action.params).equals(_store.get('params'))
              ? _store.get('files').merge(filesByIds(action.files))
              : filesByIds(action.files)
          )
          .set('params', Map(action.params))
          .set('hasMore', action.files.length === filesLimit)
          .set('isLoading', false)
          .set('isLoaded', true)
      );
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILES_LOAD_ERROR:
      _store = _store.set('isLoading', false);
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILE_UPLOAD:
      _store = _store.setIn(['uploadingProgress', action.id], action.progress);
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILE_UPLOAD_SUCCESS:
    case constants.ActionTypes.STORAGE_FILE_UPLOAD_ERROR:
      _store = _store.deleteIn(['uploadingProgress', action.id]);
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILES_LOAD_MORE:
      _store = _store.set('isLoadingMore', true);
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILES_LOAD_MORE_SUCCESS:
      _store = _store.withMutations((store) =>
        store
          .set('files', _store.get('files').merge(filesByIds(action.files)))
          .set('hasMore', action.files.length === filesLimit)
          .set('isLoadingMore', false)
      );
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILES_LOAD_MORE_ERROR:
      _store = _store.set('isLoadingMore', false);
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILE_DELETE:
      _store = _store.setIn(['isDeleting', action.fileId], true);
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILE_DELETE_SUCCESS:
      _store = _store.withMutations((store) =>
        store
          .set(
            'files',
            _store.get('files').filter((file) => file.get('id') !== action.fileId)
          )
          .deleteIn(['isDeleting', action.fileId])
      );
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILE_DELETE_ERROR:
      _store = _store.deleteIn(['isDeleting', action.fileId]);
      return StorageFilesStore.emitChange();

    case constants.ActionTypes.STORAGE_FILES_RESET:
      _store = _store.set('files', Map());
      return StorageFilesStore.emitChange();

    default:
  }
});

export default StorageFilesStore;
