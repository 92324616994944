import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'boring-avatars';
import classNames from 'classnames';
import { Map } from 'immutable';

import { useSHA256 } from '../../utils/crypto';
import randomNumber from '../../utils/randomNumber';

const styles = {
  wrapper: {
    position: 'relative',
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  gravatar: {
    position: 'absolute',
    left: 0
  }
};

const Gravatar = ({ user, fallback, size, className }) => {
  const userName = user?.get('name') || user?.get('email');
  const hash = useSHA256(user?.get('email').trim().toLowerCase() || fallback || '');

  return (
    <span style={styles.wrapper} className={classNames('circle overflow-hidden', className)}>
      <Avatar
        name={userName || fallback || randomNumber()}
        variant="beam"
        colors={['#1F8FFF', '#004787', '#28D8FF', '#925cff', '#A8C2E6']}
        size={size}
      />
      {hash && (
        <img
          src={`https://secure.gravatar.com/avatar/${hash}?s=${size * 3}&d=blank`}
          width={size}
          height={size}
          alt={userName || fallback || 'User avatar'}
          referrerPolicy="no-referrer"
          loading="lazy"
          style={styles.gravatar}
        />
      )}
    </span>
  );
};

Gravatar.propTypes = {
  user: PropTypes.instanceOf(Map),
  fallback: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number
};

Gravatar.defaultProps = {
  size: 36
};

export default Gravatar;
