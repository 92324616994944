import { KEBOOLA_EX_GOOGLE_DRIVE as COMPONENT_ID } from '../../constants/componentIds';
import { configPoll } from '../../utils/genericPolls';
import { configRequiredData } from '../../utils/genericRequiredData';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import SimplifiedUiHeaderButtons from '../simplified-ui/HeaderButtons';
import Index from './react/Index/Index';

export default {
  name: COMPONENT_ID,
  path: `${COMPONENT_ID}/:config`,
  defaultRouteHandler: Index,
  headerButtonsHandler: SimplifiedUiHeaderButtons,
  title: (routerState) => {
    const configId = routerState.getIn(['params', 'config']);
    return InstalledComponentsStore.getConfig(COMPONENT_ID, configId).get('name');
  },
  requireData: [(params) => configRequiredData(COMPONENT_ID, params.config)],
  poll: configPoll(COMPONENT_ID)
};
