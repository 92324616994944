import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from './Loader';
import VersionsDiffModal from './VersionsDiffModal';

class DiffLatestVersionButton extends React.Component {
  state = {
    showModal: false
  };

  render() {
    return (
      <>
        <Button
          bsStyle="link"
          className="btn-link-inline compare-latest dark"
          disabled={this.props.isDisabled || this.props.isPending}
          onClick={() => {
            this.props.onLoadVersionConfig().then(() => this.setState({ showModal: true }));
          }}
        >
          {this.props.isPending ? (
            <Loader className="f-14 btn-icon" />
          ) : (
            <FontAwesomeIcon icon="right-left" fixedWidth className="f-14 btn-icon" />
          )}
          See Latest Changes
        </Button>
        <VersionsDiffModal
          show={this.state.showModal}
          admins={this.props.admins}
          onClose={() => this.setState({ showModal: false })}
          component={this.props.component}
          config={this.props.config}
          row={this.props.row}
          referentialVersion={this.props.versionConfig}
          compareVersion={this.props.previousVersionConfig}
          sharedCodes={this.props.sharedCodes}
          hideRollback={this.props.hideRollback}
        />
      </>
    );
  }
}

DiffLatestVersionButton.propTypes = {
  component: PropTypes.object.isRequired,
  versionConfig: PropTypes.object.isRequired,
  previousVersionConfig: PropTypes.object.isRequired,
  onLoadVersionConfig: PropTypes.func.isRequired,
  sharedCodes: PropTypes.object.isRequired,
  admins: PropTypes.object.isRequired,
  config: PropTypes.object,
  row: PropTypes.object,
  hideRollback: PropTypes.bool,
  isPending: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default DiffLatestVersionButton;
