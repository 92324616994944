import StorageActionCreators from '../components/StorageActionCreators';
import StorageBucketsStore from '../components/stores/StorageBucketsStore';
import HeaderButtons from './react/HeaderButtons';
import MySharedBucketDetail from './react/MySharedBucketDetail';
import MySharedBucketHeaderButton from './react/MySharedBucketHeaderButton';
import SharedFromThisProject from './react/SharedFromThisProject';

export default {
  name: 'data-catalog-shared-from-this-project',
  title: 'Shared from this project',
  subtitle: 'Data Catalog',
  defaultRouteHandler: SharedFromThisProject,
  headerButtonsHandler: HeaderButtons,
  requireData: [() => StorageActionCreators.loadBucketsAndTables()],
  childRoutes: [
    {
      name: 'shared-from-this-project-detail',
      path: ':bucketId',
      title(routerState) {
        const sharedBucket = StorageBucketsStore.getBucket(
          routerState.getIn(['params', 'bucketId'])
        );
        return `Bucket ${sharedBucket.get(
          'displayName',
          routerState.getIn(['params', 'bucketId'])
        )}`;
      },
      defaultRouteHandler: MySharedBucketDetail,
      headerButtonsHandler: MySharedBucketHeaderButton
    }
  ]
};
