import { fromJS } from 'immutable';

export const listAccounts = function (configData) {
  if (!configData.has('authorization')) {
    return false;
  }

  return fromJS({
    configData: { authorization: configData.get('authorization') }
  });
};
