import React, { useState } from 'react';
import {
  Alert,
  Button,
  ButtonToolbar,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { List } from 'immutable';
import { Map } from 'immutable';
import _ from 'underscore';

import type { Variable, VariableWithHash } from '../../api/routes/vaultService';
import { ADMIN_ROLES } from '../../constants/KbcConstants';
import ActivateDeactivateSwitch from '../../react/common/ActivateDeactivateSwitch';
import ConfigurationWithComponent from '../../react/common/ConfigurationWithComponent';
import Confirm from '../../react/common/Confirm';
import ConfirmButtons from '../../react/common/ConfirmButtons';
import ExternalLink from '../../react/common/ExternalLink';
import ModalIcon from '../../react/common/ModalIcon';
import OptionalFormLabel from '../../react/common/OptionalFormLabel';
import Tooltip from '../../react/common/Tooltip';
import Truncated from '../../react/common/Truncated';
import useStores from '../../react/hooks/useStores';
import ApplicationStore from '../../stores/ApplicationStore';
import nextTick from '../../utils/nextTick';
import { canApproveDevBranchReview } from '../admin/privileges';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import OAuthActions from '../oauth-v2/ActionCreators';
import { parseCredentialsId } from '../oauth-v2/OauthUtils';
import Authorization from '../oauth-v2/react/Authorization';
import OAuthStore from '../oauth-v2/Store';
import {
  filterCurrentBranchVariables,
  filterProductionVariables,
  filterProjectWideVariables,
  findExistingVariable,
  isVariableEncrypted,
  isVariableOauth,
  separateOauthVariables
} from '../vault/helpers';
import VariablesStore from '../vault/store';
import ChangelogDownloadButton from './components/ChangelogDownloadButton';
import {
  approveDevBranchChanges,
  mergeDevBranchChanges,
  rejectDevBranchChanges,
  sendDevBranchToReview
} from './actions';
import DevBranchesStore from './DevBranchesStore';
import {
  filterSelectedVariables,
  filterUnselectedUpdatedComponents,
  filterUnselectedVariables,
  findUpdatedConfigurations,
  findUpdatedMetadata,
  isMergeRequestApproved,
  isMergeRequestApprovedByCurrentAdmin,
  isMergeRequestInReview,
  isMergeRequestSentByCurrentAdmin
} from './helpers';

const RejectButton = (props: {
  mergeRequest: Map<string, any>;
  currentAdmin: Map<string, any>;
}) => {
  if (!isMergeRequestInReview(props.mergeRequest) && !isMergeRequestApproved(props.mergeRequest)) {
    return null;
  }

  const label = isMergeRequestSentByCurrentAdmin(props.mergeRequest, props.currentAdmin)
    ? 'Cancel'
    : 'Reject';

  return (
    <Confirm
      closeAfterResolve
      icon="triangle-exclamation"
      title={`${label} request`}
      buttonLabel={`${label} request`}
      text={`Are you sure you want to ${label.toLowerCase()} request? All existing approvals will be cancelled.`}
      onConfirm={() => rejectDevBranchChanges(props.mergeRequest.get('id'))}
      childrenRootElement="button"
      childrenRootElementClass="btn btn-default"
    >
      <FontAwesomeIcon icon="xmark" className="icon-addon-right" />
      {label} request
    </Confirm>
  );
};

const DeveloperAndReviewerButtons = (props: {
  sapiToken: Map<string, any>;
  mergeRequest: Map<string, any>;
  currentAdmin: Map<string, any>;
  updatedInstalledComponents: Map<string, any>;
  selectedConfigurationChanges: Map<string, any>;
  variables: VariableWithHash[];
  selectedVariables: List<VariableWithHash['hash']>;
}) => {
  const [formData, setFormData] = React.useState(props.mergeRequest);
  const [isApproving, setApproving] = React.useState(false);
  const [isSubmiting, setSubmiting] = React.useState(false);

  const unselectedConfigurations = filterUnselectedUpdatedComponents(
    props.updatedInstalledComponents,
    props.selectedConfigurationChanges
  );

  const unselectedVariables = filterUnselectedVariables(props.variables, props.selectedVariables);
  const alreadyApproved = isMergeRequestApprovedByCurrentAdmin(
    props.mergeRequest,
    props.currentAdmin
  );

  if (isMergeRequestInReview(props.mergeRequest) || isMergeRequestApproved(props.mergeRequest)) {
    return (
      <ButtonToolbar>
        <ChangelogDownloadButton mergeRequest={props.mergeRequest} />
        <RejectButton mergeRequest={props.mergeRequest} currentAdmin={props.currentAdmin} />
        {canApproveDevBranchReview(props.sapiToken, props.currentAdmin, props.mergeRequest) && (
          <Confirm
            closeAfterResolve
            icon="thumbs-up"
            title="Approve Merge"
            buttonLabel="Approve Merge"
            text={'Are you sure you want to approve the changes?'}
            onConfirm={() => {
              setApproving(true);
              return approveDevBranchChanges(props.mergeRequest.get('id')).finally(() =>
                setApproving(false)
              );
            }}
            buttonType="success"
            childrenRootElement="button"
            childrenRootElementClass="btn btn-success"
            isLoading={isApproving}
            isDisabled={!isMergeRequestInReview(props.mergeRequest) || alreadyApproved}
            disabledReason={alreadyApproved ? 'You already approved this merge.' : ''}
          >
            <FontAwesomeIcon icon="thumbs-up" className="icon-addon-right" />
            {alreadyApproved ? 'Approved' : 'Approve Merge'}
          </Confirm>
        )}
      </ButtonToolbar>
    );
  }

  return (
    <Confirm
      closeAfterResolve
      icon="arrow-right"
      title="Send for Review"
      buttonLabel="Send for Review"
      text={
        <>
          {(unselectedConfigurations.count() > 0 || unselectedVariables.length > 0) && (
            <Alert bsStyle="warning">
              All unselected variables and configurations will be removed.
            </Alert>
          )}
          <FormGroup>
            <ControlLabel>Title</ControlLabel>
            <FormControl
              type="text"
              placeholder="Name your request"
              value={formData.get('title', '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormData(formData.set('title', event.target.value));
              }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              Description <OptionalFormLabel />
            </ControlLabel>
            <FormControl
              rows={3}
              componentClass="textarea"
              placeholder="Describe your request"
              value={formData.get('description', '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormData(formData.set('description', event.target.value));
              }}
            />
            <HelpBlock>
              <ExternalLink href="https://www.markdownguide.org/cheat-sheet">Markdown</ExternalLink>{' '}
              is supported
            </HelpBlock>
          </FormGroup>
        </>
      }
      isLoading={isSubmiting}
      onConfirm={() => {
        setSubmiting(true);
        return sendDevBranchToReview(
          formData.get('title'),
          formData.get('description', ''),
          unselectedConfigurations,
          unselectedVariables
        )
          .then(() => setFormData(Map()))
          .finally(() => setSubmiting(false));
      }}
      isDisabled={props.selectedConfigurationChanges.isEmpty() && props.selectedVariables.isEmpty()}
      isDisabledConfirmButton={!formData.get('title', '').trim()}
      buttonType="success"
      childrenRootElement="button"
      childrenRootElementClass="btn btn-success"
    >
      <FontAwesomeIcon icon="paper-plane-top" className="icon-addon-right" />
      Send for review
    </Confirm>
  );
};

const ProductionManagerButtons = (props: {
  mergeRequest: Map<string, any>;
  currentAdmin: Map<string, any>;
  selectedConfigurationChanges: Map<string, any>;
  variables: VariableWithHash[];
  selectedVariables: List<VariableWithHash['hash']>;
  productionVariables: VariableWithHash[];
  projectWideVariables: VariableWithHash[];
  allComponents: Map<string, any>;
  allConfigurations: Map<string, any>;
  allOauthCredentials: Map<string, any>;
}) => {
  const [modalStep, setModalStep] = useState<'variables' | 'oauth' | 'confirm' | null>(null);
  const [authorizeModalData, setAuthorizeModalData] = useState<{
    variable: Variable & { isExistingVariable?: boolean; originalValue?: boolean };
    authorizedFor: string;
  } | null>(null);
  const [isMerging, setIsMerging] = useState(false);
  const [isMerged, setIsMerged] = useState(false);
  const [mergeError, setMergeError] = useState('');

  const [allVariablesToCreate, setVariablesToCreate] = useState<
    (Variable & { isExistingVariable?: boolean; originalValue?: boolean })[]
  >(
    filterSelectedVariables(props.variables, props.selectedVariables.toArray()).map(
      (variable: VariableWithHash) => {
        const existingVariableWithSameKey = findExistingVariable(
          variable,
          variable.attributes?.branchId ? props.productionVariables : props.projectWideVariables
        );

        return {
          ...variable,
          ...((isVariableEncrypted(variable) || isVariableOauth(variable)) && { value: '' }),
          ...(!!existingVariableWithSameKey && {
            isExistingVariable: true,
            hash: existingVariableWithSameKey.hash
          }),
          attributes: {
            ...variable.attributes,
            branchId: DevBranchesStore.getDefaultBranchId()?.toString()
          },
          originalValue: variable.value
        };
      }
    )
  );
  const updateVariable = (
    variableKey: string | undefined,
    key: string,
    value: string | boolean
  ) => {
    if (!variableKey) return;

    setVariablesToCreate(
      (variables) =>
        variables &&
        variables.map((variable) => {
          if (variable.key !== variableKey) return variable;

          if (key === 'productionOnly') {
            delete variable.attributes?.branchId;
            delete variable.isExistingVariable;

            const updatedVariable = {
              ...variable,
              attributes: {
                ...variable.attributes,
                ...(value && {
                  branchId: DevBranchesStore.getDefaultBranchId()?.toString()
                })
              }
            };
            const existingVariableWithSameKey = findExistingVariable(
              updatedVariable,
              value ? props.productionVariables : props.projectWideVariables
            );

            return {
              ...updatedVariable,
              ...(!!existingVariableWithSameKey && {
                isExistingVariable: true,
                hash: existingVariableWithSameKey.hash
              })
            };
          }

          return { ...variable, [key]: value };
        })
    );
  };

  const { variables: variablesToCreate, oauthVariables: oauthVariablesToCreate } =
    separateOauthVariables(allVariablesToCreate);
  const hasOauthVariablesToCreate = !!oauthVariablesToCreate?.length;
  const hasVariablesToCreate = !!variablesToCreate?.length;

  const renderButtonLabel = () => {
    if (isMerged) {
      return 'Redirecting to Production...';
    }

    if (isMerging) {
      return 'Merging to Production...';
    }

    return 'Merge to Production';
  };

  const renderModalTitle = () => {
    if (isMerged) {
      return 'Merge Successful';
    }

    if (modalStep === 'variables') {
      return 'Set Values for Production';
    }

    if (modalStep === 'oauth') {
      return 'Authorize for Production';
    }

    return 'Merge to Production';
  };

  const renderBody = () => {
    if (isMerged) {
      return (
        <div className="p-1 text-center">
          <FontAwesomeIcon icon="circle-check" className="text-success" size="3x" />
          <h2>Merge Successful</h2>
          <p>Development branch was successfully merged into the production.</p>
        </div>
      );
    }

    if (modalStep === 'variables') {
      return (
        <>
          <p>
            The branch creates new variables. You can specify their production values below. If you
            do not specify a value, the variable will not be created.
          </p>
          <div className="mbp-4 flex-container flex-column stretch gap-16">
            {variablesToCreate.map((variable) => (
              <FormGroup
                className="mb-0 fill-space"
                key={`${variable.attributes?.branchId}-${variable.key}`}
              >
                <ControlLabel className="flex-container flex-start">
                  {variable.group && (
                    <>
                      <FontAwesomeIcon icon="folder" className="text-muted f-16 icon-addon-right" />
                      <Truncated text={variable.group} />
                      <span className="plp-1 prp-1">/</span>
                    </>
                  )}
                  <Truncated text={variable.key} />
                </ControlLabel>
                <div className="flex-container align-bottom gap-16">
                  <FormControl
                    type="text"
                    placeholder="Variable Value"
                    value={variable.value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      updateVariable(variable.key, 'value', event.target.value)
                    }
                    className="flex-container align-bottom gap-16"
                  />
                  <Tooltip
                    tooltip="Encryption option is controlled by the developer. You can however change it in production vault after the merge."
                    placement="top"
                    type="explanatory"
                  >
                    <div>
                      <ActivateDeactivateSwitch
                        withLabel
                        noTooltips
                        buttonDisabled
                        customLabel="Encrypted"
                        isActive={isVariableEncrypted(variable)}
                        onChange={_.noop}
                        className="display-block mbp-1"
                      />
                    </div>
                  </Tooltip>
                </div>
                {variable.isExistingVariable && (
                  <HelpBlock className="text-warning mb-0">
                    This variable already exists in production. Its current value will be
                    overwritten if you provide a new value.
                  </HelpBlock>
                )}
              </FormGroup>
            ))}
          </div>
        </>
      );
    }

    if (modalStep === 'oauth') {
      const autorizedInfo = (credentials: Map<string, any>) => {
        const authorizedFor = credentials.get('authorizedFor');
        const creator = credentials.getIn(['creator', 'description']);

        return (
          <>
            <Truncated className="tw-font-medium" text={authorizedFor} />
            {authorizedFor !== creator && <Truncated text={creator} />}
          </>
        );
      };

      return (
        <>
          <p>
            The branch adds new authorizations. You should reauthorize them for production below. If
            you don&apos;t, you can authorize them in the vault later.
          </p>
          <div className="mbp-4 flex-container flex-column stretch gap-16">
            {oauthVariablesToCreate.map((variable) => {
              const branchCredentials = props.allOauthCredentials.get(
                parseCredentialsId(variable.originalValue),
                Map()
              );
              const productionCredentials = props.allOauthCredentials.get(variable.value, Map());

              return (
                <div
                  key={variable.key}
                  className="tw-flex tw-flex-col tw-items-stretch tw-rounded tw-border tw-border-solid tw-border-neutral-200 tw-bg-neutral-50"
                >
                  <div className="tw-p-4 tw-border-solid tw-border-0 tw-border-b tw-border-b-neutral-200">
                    <ConfigurationWithComponent
                      withLink
                      openInNewTab
                      component={props.allComponents.get(variable.attributes?.componentId)}
                      configuration={props.allConfigurations.getIn([
                        variable.attributes?.componentId,
                        'configurations',
                        variable.attributes?.configId
                      ])}
                    />
                    <div className="tw-grid tw-grid-cols-2 tw-text-sm tw-mt-4 tw-gap-4">
                      <div className="tw-flex tw-flex-col tw-items-start">
                        <span className="tw-text-neutral-400">Branch Authorization</span>
                        {autorizedInfo(branchCredentials)}
                      </div>
                      {!productionCredentials.isEmpty() && (
                        <div className="tw-flex tw-flex-col tw-items-start">
                          <span className="tw-text-neutral-400">Production Authorization</span>
                          {autorizedInfo(productionCredentials)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tw-p-4 tw-flex tw-gap-1">
                    {productionCredentials.isEmpty() ? (
                      <>
                        <Button
                          bsStyle="success"
                          onClick={() =>
                            setAuthorizeModalData({
                              variable,
                              authorizedFor: branchCredentials.get('authorizedFor')
                            })
                          }
                        >
                          <FontAwesomeIcon icon="user" className="icon-addon-right" />
                          Authorize as a Manager
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={() => {
                          OAuthActions.deleteCredentials(
                            variable.attributes?.componentId,
                            variable.value
                          );

                          updateVariable(variable.key, 'value', '');
                        }}
                      >
                        <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
                        Reset
                      </Button>
                    )}
                    <ActivateDeactivateSwitch
                      withLabel
                      noTooltips
                      buttonDisabled={!productionCredentials.isEmpty()}
                      customLabel="Production Only"
                      isActive={
                        variable.attributes?.branchId ===
                        DevBranchesStore.getDefaultBranchId()?.toString()
                      }
                      onChange={(isProductionOnly: boolean) =>
                        updateVariable(variable.key, 'productionOnly', isProductionOnly)
                      }
                      className="display-block mbp-1"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <Authorization
            skipSave
            showModal={!!authorizeModalData}
            onModalHideFn={() => nextTick(() => setAuthorizeModalData(null))}
            onCompleteFn={({ id }: { id: string }) =>
              updateVariable(authorizeModalData?.variable.key, 'value', id)
            }
            branchId={authorizeModalData?.variable.attributes?.branchId ?? null}
            componentId={authorizeModalData?.variable.attributes?.componentId || ''}
            configId={authorizeModalData?.variable.attributes?.configId}
            initialAuthorizedFor={authorizeModalData?.authorizedFor}
          />
        </>
      );
    }

    return (
      <>
        {mergeError && <Alert bsStyle="danger">{mergeError}</Alert>}
        <p>
          Are you sure you want to merge the branch into production? All approved changes will be
          merged.
        </p>
      </>
    );
  };

  const isDisabled = isMerging || !isMergeRequestApproved(props.mergeRequest);

  return (
    <ButtonToolbar>
      <ChangelogDownloadButton mergeRequest={props.mergeRequest} />
      <RejectButton mergeRequest={props.mergeRequest} currentAdmin={props.currentAdmin} />
      <Tooltip
        tooltip="This branch does not have enough approvals to be merged into production."
        placement="top"
        type="explanatory"
        forceHide={isMergeRequestApproved(props.mergeRequest)}
      >
        <Button
          bsStyle="success"
          tabIndex="0"
          onClick={() => {
            if (isDisabled) {
              return;
            }

            setModalStep(
              hasVariablesToCreate ? 'variables' : hasOauthVariablesToCreate ? 'oauth' : 'confirm'
            );
          }}
          className={classNames({ disabled: isDisabled })}
        >
          <FontAwesomeIcon icon="check-circle" className="icon-addon-right" />
          Merge to Production
        </Button>
      </Tooltip>
      <Modal show={!!modalStep} onHide={() => setModalStep(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{renderModalTitle()}</Modal.Title>
          <ModalIcon icon="code-merge" color="green" bold />
        </Modal.Header>
        <Modal.Body>{renderBody()}</Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="block">
            {((modalStep === 'oauth' && hasVariablesToCreate) ||
              (modalStep === 'confirm' && (hasVariablesToCreate || hasOauthVariablesToCreate))) && (
              <Button
                onClick={() =>
                  setModalStep(
                    modalStep === 'oauth' || !hasOauthVariablesToCreate ? 'variables' : 'oauth'
                  )
                }
              >
                <FontAwesomeIcon icon="arrow-left" fixedWidth />
              </Button>
            )}
            {modalStep === 'confirm' ? (
              <ConfirmButtons
                block
                saveStyle="success"
                saveLabel={renderButtonLabel()}
                onSave={() => {
                  setIsMerging(true);
                  return mergeDevBranchChanges(
                    props.mergeRequest.get('id'),
                    props.selectedConfigurationChanges,
                    variablesToCreate.concat(oauthVariablesToCreate),
                    () => setIsMerged(true)
                  )
                    .catch((error) => {
                      setMergeError(error?.message || error);
                      throw error;
                    })
                    .finally(() => {
                      setIsMerging(false);
                      setModalStep(null);
                    });
                }}
                isSaving={isMerging}
                isDisabled={isMerging || !isMergeRequestApproved(props.mergeRequest)}
              />
            ) : (
              <Button
                block
                bsStyle="success"
                className="btn-confirm"
                onClick={() =>
                  setModalStep(
                    modalStep === 'variables' && hasOauthVariablesToCreate ? 'oauth' : 'confirm'
                  )
                }
              >
                Next Step
              </Button>
            )}
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </ButtonToolbar>
  );
};

const MergeHeaderButtons = () => {
  const store = useStores(
    () => {
      const allConfigurations = InstalledComponentsStore.getAll();
      const updatedMetadata = findUpdatedMetadata(
        InstalledComponentsStore.getAllMetadata(),
        DevBranchesStore.getProductionComponentsMetadata()
      );

      return {
        allConfigurations,
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        sapiToken: ApplicationStore.getSapiToken(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
        mergeRequest: DevBranchesStore.getCurrentDevBranchMergeRequest(),
        updatedInstalledComponents: findUpdatedConfigurations(
          allConfigurations,
          DevBranchesStore.getProductionComponents() as Map<string, any>,
          InstalledComponentsStore.getAllDeleted(),
          DevBranchesStore.getProductionDeletedComponents() as Map<string, any>,
          updatedMetadata
        ),
        devBranchVariables: filterCurrentBranchVariables(VariablesStore.getStore().variables),
        productionVariables: filterProductionVariables(VariablesStore.getStore().variables),
        projectWideVariables: filterProjectWideVariables(VariablesStore.getStore().variables),
        selectedConfigurationChanges: DevBranchesStore.getSelectedConfigurationChanges() as Map<
          string,
          any
        >,
        selectedVariables: DevBranchesStore.getSelectedVariables() as List<
          VariableWithHash['hash']
        >,
        allOauthCredentials: OAuthStore.getAllCredentials() as Map<string, any>
      };
    },
    [],
    [ApplicationStore, DevBranchesStore, VariablesStore, InstalledComponentsStore, OAuthStore]
  );

  switch (store.sapiToken.getIn(['admin', 'role'])) {
    case ADMIN_ROLES.DEVELOPER:
    case ADMIN_ROLES.REVIEWER:
      return (
        <DeveloperAndReviewerButtons
          sapiToken={store.sapiToken}
          mergeRequest={store.mergeRequest}
          currentAdmin={store.currentAdmin}
          updatedInstalledComponents={store.updatedInstalledComponents}
          selectedConfigurationChanges={store.selectedConfigurationChanges}
          variables={store.devBranchVariables}
          selectedVariables={store.selectedVariables}
        />
      );

    case ADMIN_ROLES.PRODUCTION_MANAGER:
      return (
        <ProductionManagerButtons
          mergeRequest={store.mergeRequest}
          currentAdmin={store.currentAdmin}
          selectedConfigurationChanges={store.selectedConfigurationChanges}
          variables={store.devBranchVariables}
          selectedVariables={store.selectedVariables}
          productionVariables={store.productionVariables}
          projectWideVariables={store.projectWideVariables}
          allComponents={store.allComponents}
          allConfigurations={store.allConfigurations}
          allOauthCredentials={store.allOauthCredentials}
        />
      );

    default:
      return null;
  }
};

export default MergeHeaderButtons;
