import React from 'react';
import { Fade } from 'react-bootstrap';
import createReactClass from 'create-react-class';

const FastFade = createReactClass({
  render() {
    return <Fade {...this.props} className="fast-fade" timeout={100} />;
  }
});

export default FastFade;
