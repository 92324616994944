import { Map } from 'immutable';

import { MetadataKeys } from '../MetadataConstants';

const findByKey = (data, key) => data.find((entry) => entry.get('key') === key, null, Map());

export const findBaseType = (data) => {
  const userBaseType = data.find((entry) => {
    return entry.get('key') === MetadataKeys.BASE_TYPE && entry.get('provider') === 'user';
  });

  if (userBaseType) {
    return userBaseType;
  }

  return data
    .filter((entry) => entry.get('provider') !== 'user')
    .sortBy((row) => -1 * new Date(row.get('timestamp')).getTime())
    .find((entry) => entry.get('key') === MetadataKeys.BASE_TYPE, null, null);
};

export const findNullable = (data) => findByKey(data, MetadataKeys.NULLABLE);
export const findType = (data) => findByKey(data, MetadataKeys.TYPE);
export const findDefaultValue = (data) => findByKey(data, MetadataKeys.DEFAULT);
export const findLength = (data) => findByKey(data, MetadataKeys.LENGTH);
