import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import { List, Map } from 'immutable';

import nextTick from '../../../utils/nextTick';
import { getDefaultBucketName, getDefaultTableName } from '../../components/helpers';
import InstalledComponentsActionCreators from '../../components/InstalledComponentsActionCreators';
import TableOutputMappingModal from '../../components/react/components/generic/TableOutputMappingModal';
import { runComponent } from '../../simplified-ui/actions';

class SimplifiedTableOutputMapping extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editingMapping:
        props.configData.getIn(['storage', 'output', 'tables'], List()).first() || Map()
    };
  }

  static propTypes = {
    allBuckets: PropTypes.instanceOf(Map).isRequired,
    allTables: PropTypes.instanceOf(Map).isRequired,
    configData: PropTypes.instanceOf(Map),
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    configName: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
    commonState: PropTypes.object.isRequired,
    isHeaderButton: PropTypes.bool
  };

  render() {
    const configuredTables = this.props.configData.getIn(['storage', 'output', 'tables'], List());

    return (
      <div className="simplified-table-output-mapping flex-container align-top">
        <div className="flex-container fill-space">
          {!this.props.readOnly && (
            <TableOutputMappingModal
              simple
              componentId={this.props.componentId}
              mode={configuredTables.isEmpty() ? 'create' : 'edit'}
              mapping={
                this.state.editingMapping.get('source')
                  ? this.state.editingMapping
                  : this.state.editingMapping.set('source', 'model_last')
              }
              buckets={this.props.allBuckets}
              tables={this.props.allTables}
              onChange={(newMapping) => this.setState({ editingMapping: newMapping })}
              onCancel={() => this.handleMappingDeselect()}
              onSave={() =>
                this.handleMappingAdd(this.state.editingMapping).then(() => {
                  if (!configuredTables.isEmpty()) return Promise.resolve();

                  return runComponent(
                    this.props.componentId,
                    this.props.configId,
                    this.props.hasNewQueue
                  );
                })
              }
              sourceType="table"
              defaultBucketName={getDefaultBucketName(this.props.configName)}
              defaultTableName={getDefaultTableName(this.props.configName)}
              renderCustomOpenButton={(onClickHandler) =>
                configuredTables.isEmpty() ? (
                  <Button block bsStyle="success" onClick={onClickHandler}>
                    <FontAwesomeIcon icon="warehouse" className="icon-addon-right" />
                    Save To Storage
                  </Button>
                ) : (
                  <Button bsSize="sm" className="ml-1" onClick={onClickHandler}>
                    <FontAwesomeIcon icon="pen" className="icon-addon-right" />
                    Edit Result
                  </Button>
                )
              }
            />
          )}
        </div>
      </div>
    );
  }

  handleMappingDeselect = () => {
    this.setState({
      editingMapping:
        this.props.configData.getIn(['storage', 'output', 'tables'], List()).first() || Map()
    });
  };

  handleMappingAdd = (mapping) => {
    return this.handleChange(
      this.props.configData.setIn(['storage', 'output', 'tables'], List([mapping])),
      'Change output table'
    );
  };

  handleChange = (configData, changeDescription) => {
    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.props.componentId,
      this.props.configId,
      configData,
      changeDescription
    ).finally(() => nextTick(this.handleMappingDeselect));
  };
}

export default SimplifiedTableOutputMapping;
