import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '../../../../constants/componentIds';
import { componentTypes } from '../../../../constants/componentTypes';
import { FEATURE_DEPRECATED_GOOGLE_DRIVE_TRANSPOSE } from '../../../../constants/features';
// CONSTS
import ConfigurationInfoPanel from '../../../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../../../react/common/ConfigurationTabs';
import Sidebar from '../../../../react/layout/Sidebar';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import ComponentDescription from '../../../components/react/components/ComponentDescription';
import EmptyState from '../../../components/react/components/ComponentEmptyState';
import ComponentsStore from '../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';
import StorageTablesStore from '../../../components/stores/StorageTablesStore';
import { deleteCredentialsAndConfigAuth } from '../../../oauth-v2/OauthUtils';
import AuthorizationRow from '../../../oauth-v2/react/AuthorizationRow';
import { shouldShowSimplifiedUi } from '../../../simplified-ui/helpers';
import SimplifiedUi from '../../../simplified-ui/Index';
import { prepareTablesMetadataMap } from '../../../storage/helpers';
import actionsProvisioning from '../../actionsProvisioning';
import storeProvisioning, { storeMixins } from '../../storeProvisioning';
import OutputTableModal from './OutputTableModal';
import SheetsManagerModal from './SheetsManagerModal';
import SheetsTable from './SheetsTable';

const COMPONENT_ID = 'keboola.ex-google-drive';

const Index = createReactClass({
  mixins: [createStoreMixin(...storeMixins, ApplicationStore, ComponentsStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const store = storeProvisioning(configId);
    const actions = actionsProvisioning(configId);

    return {
      store: store,
      actions: actions,
      configId: configId,
      allTables: StorageTablesStore.getAll(),
      component: ComponentsStore.getComponent(COMPONENT_ID),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      authorizedEmail: store.oauthCredentials.get('authorizedFor', ''),
      oauthCredentials: store.oauthCredentials,
      localState: store.getLocalState(),
      hasFlows: ApplicationStore.hasFlows(),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
      enableDeprecatedGoogleDriveTranspose: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_DEPRECATED_GOOGLE_DRIVE_TRANSPOSE
      )
    };
  },

  render() {
    if (shouldShowSimplifiedUi(this.state.component, this.state.store.configData)) {
      return <SimplifiedUi />;
    }

    return (
      <>
        <ConfigurationTabs componentId={COMPONENT_ID} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.store.config}
          hasFlows={this.state.hasFlows}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          {this.renderSheetsManagerModal()}
          {this.renderEditTableModal()}
          <div className="col-sm-9">
            <ComponentDescription
              componentId={COMPONENT_ID}
              configId={this.state.configId}
              placeholderEntity={componentTypes.EXTRACTOR}
            />
            {this.renderAuthorizedInfo()}
            {this.renderTables()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={COMPONENT_ID}
              configId={this.state.configId}
              run={{
                disabled: this.invalidToRun(),
                text: 'You are about to run an extraction of all configured sheets.'
              }}
            />
          </div>
        </div>
      </>
    );
  },

  isAuthorized() {
    return this.state.store.isAuthorized();
  },

  hasSheets() {
    return this.state.store.sheets && this.state.store.sheets.count() > 0;
  },

  invalidToRun() {
    if (!this.isAuthorized()) {
      return 'No Google Drive account authorized';
    }

    if (!this.hasSheets()) {
      return 'No sheets configured';
    }

    return '';
  },

  renderAuthorizedInfo() {
    return (
      <AuthorizationRow
        readOnly={this.state.readOnly}
        configId={this.state.configId}
        componentId={COMPONENT_ID}
        credentials={this.state.oauthCredentials}
        onResetCredentials={this.deleteCredentials}
        admins={this.state.admins}
      />
    );
  },

  renderTables() {
    if (!this.hasSheets()) {
      return this.renderEmptySheets();
    }

    return (
      <div className="box-separator">
        {!this.state.readOnly && this.isAuthorized() && (
          <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
            <h2 className="tw-text-base tw-m-0">Sheets</h2>
            {this.renderAddSheetLink()}
          </div>
        )}
        {this.renderSheetsTable()}
      </div>
    );
  },

  renderSheetsTable() {
    return (
      <div className="box">
        <SheetsTable
          readOnly={this.state.readOnly}
          outputBucket={this.state.store.outputBucket}
          deleteSheetFn={this.state.actions.deleteSheet}
          toggleSheetEnabledFn={this.state.actions.toggleSheetEnabled}
          getRunSingleSheetDataFn={this.state.store.getRunSingleSheetData}
          isPendingFn={this.state.store.isPending}
          sheets={this.state.store.sheets}
          allProfiles={this.state.store.profiles}
          configId={this.state.configId}
          onStartEdit={this.showEditTableModal}
          {...this.state.actions.prepareLocalState('SheetsTable')}
        />
      </div>
    );
  },

  renderAddSheetLink() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <Button bsStyle="success" bsSize="sm" onClick={this.showSheetsManagerModal}>
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        New Sheet
      </Button>
    );
  },

  renderEmptySheets() {
    if (!this.isAuthorized()) {
      return null;
    }

    return (
      <div className="box-separator">
        <h2 className="tw-text-base tw-m-0 tw-mb-4">Sheets</h2>
        <div className="box">
          <div className="box-content">
            <EmptyState>
              <p>No sheets configured yet.</p>
              {this.renderAddSheetLink()}
            </EmptyState>
          </div>
        </div>
      </div>
    );
  },

  deleteCredentials() {
    return deleteCredentialsAndConfigAuth(COMPONENT_ID, this.state.configId);
  },

  showSheetsManagerModal() {
    this.state.actions.updateLocalState(['SheetsManagerModal', 'show'], true);
  },

  renderSheetsManagerModal() {
    return (
      <SheetsManagerModal
        show={this.state.localState.getIn(['SheetsManagerModal', 'show'], false)}
        files={this.state.localState.getIn(['SheetsManagerModal', 'files'])}
        isSaving={this.state.store.isSaving('newSheets')}
        authorizedEmail={this.state.authorizedEmail}
        savedSheets={this.state.store.sheets}
        onSaveSheets={this.state.actions.saveNewSheets}
        onFilesChange={(files) => {
          this.state.actions.updateLocalState(['SheetsManagerModal', 'files'], files);
        }}
        onHide={() => this.state.actions.updateLocalState(['SheetsManagerModal', 'show'], false)}
      />
    );
  },

  showEditTableModal(sheet) {
    const filename = `${sheet.get('fileId')}_${sheet.get('sheetId')}.csv`;
    const filteredProcessors = this.state.store.processors.filter(
      (p) => p.getIn(['parameters', 'filename']) === filename
    );
    const processor = filteredProcessors.isEmpty() ? Map() : filteredProcessors.first();

    this.state.actions.updateLocalState(['TableModal', 'processor'], processor);
    this.state.actions.updateLocalState(['TableModal', 'sheet'], sheet);
  },

  renderEditTableModal() {
    const sheet = this.state.localState.getIn(['TableModal', 'sheet'], Map());

    return (
      <OutputTableModal
        show={!sheet.isEmpty()}
        onHideFn={() => this.state.actions.updateLocalState('TableModal', Map())}
        isSaving={this.state.store.isSaving('updatingSheets')}
        savedSheets={this.state.store.sheets}
        onSaveSheetFn={this.state.actions.saveEditingSheet}
        enableDeprecatedGoogleDriveTranspose={this.state.enableDeprecatedGoogleDriveTranspose}
        {...this.state.actions.prepareLocalState('TableModal')}
      />
    );
  }
});

export default Index;
