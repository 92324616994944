import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import _ from 'underscore';

import CatchUnsavedChanges from '../../../../react/common/CatchUnsavedChanges';
import SaveButtons from '../../../../react/common/SaveButtons';
import SwitchButton from '../../../../react/common/SwitchButton';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import { isValidJsonConfig } from '../../../../utils/validation';
import InstalledComponentsActionCreators from '../../InstalledComponentsActionCreators';
import ComponentsStore from '../../stores/ComponentsStore';
import InstalledComponentsStore from '../../stores/InstalledComponentsStore';
import TemplatesStore from '../../stores/TemplatesStore';
import TemplatedConfigurationEdit from './TemplatedConfigurationEdit';

const TemplatedConfiguration = createReactClass({
  mixins: [
    createStoreMixin(ApplicationStore, InstalledComponentsStore, ComponentsStore, TemplatesStore)
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config'),
      componentId = RoutesStore.getCurrentRouteParam('component'),
      component = ComponentsStore.getComponent(componentId);

    const isTemplate =
      TemplatesStore.isConfigTemplate(
        componentId,
        InstalledComponentsStore.getTemplatedConfigValueConfig(componentId, configId)
      ) ||
      InstalledComponentsStore.getTemplatedConfigValueWithoutUserParams(
        componentId,
        configId
      ).isEmpty();

    return {
      configId,
      componentId,
      isTemplate,
      component: ComponentsStore.getComponent(componentId),
      config: InstalledComponentsStore.getTemplatedConfigValueConfig(componentId, configId),
      configSchema: component.getIn(['configurationSchema', 'properties', 'config'], Map()),
      configTemplates: TemplatesStore.getConfigTemplates(componentId),
      readOnly: ApplicationStore.isReadOnly(),
      isChanged: InstalledComponentsStore.isChangedTemplatedConfig(componentId, configId),
      isSaving: InstalledComponentsStore.isSavingConfigData(componentId, configId),
      isEditingString: InstalledComponentsStore.isTemplatedConfigEditingString(
        componentId,
        configId
      ),
      editingParams: InstalledComponentsStore.getTemplatedConfigEditingValueParams(
        componentId,
        configId
      ),
      editingTemplate: InstalledComponentsStore.getTemplatedConfigEditingValueTemplate(
        componentId,
        configId
      ),
      editingString: InstalledComponentsStore.getTemplatedConfigEditingValueString(
        componentId,
        configId
      )
    };
  },

  getInitialState() {
    return {
      showJsonEditor: null,
      jsonSchemEditorResetKey: _.uniqueId('json_schema_editor_')
    };
  },

  render() {
    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Configuration Template</h2>
            <ButtonToolbar>
              {this.renderSwitchButton()}
              {!this.state.readOnly && (
                <SaveButtons
                  isSaving={this.state.isSaving}
                  isChanged={this.state.isChanged}
                  onSave={this.onEditSubmit}
                  disabled={!this.isValid()}
                  onReset={this.onEditCancel}
                />
              )}
            </ButtonToolbar>
          </div>
          <div className="box-content">{this.renderEditor()}</div>
        </div>
      </>
    );
  },

  renderSwitchButton() {
    if (this.showJsonEditor()) {
      if (!this.state.isTemplate || this.hasNoTemplatesContent()) {
        return (
          <SwitchButton
            isDisabled
            tooltip={
              !this.state.isTemplate
                ? "Can't close code editor, configuration is not compatible. Revert your changes to allow switching back to the visual editor."
                : 'No templates content.'
            }
            label="Visual editor"
          />
        );
      }

      return <SwitchButton onClick={this.switchToTemplateEditor} label="Visual editor" />;
    }

    return <SwitchButton onClick={this.switchToJsonEditor} label="Code editor" />;
  },

  renderEditor() {
    return (
      <CatchUnsavedChanges
        isDirty={this.state.isChanged}
        onSave={this.onEditSubmit}
        isSaveDisabled={!this.isValid()}
        onDirtyLeave={this.onEditCancel}
      >
        <TemplatedConfigurationEdit
          readOnly={this.state.readOnly}
          component={this.state.component}
          showJsonEditor={this.showJsonEditor()}
          editingTemplate={this.state.editingTemplate}
          editingParams={this.state.editingParams}
          editingString={this.state.editingString}
          templates={this.state.configTemplates}
          paramsSchema={this.state.configSchema}
          isSaving={this.state.isSaving}
          onChangeTemplate={this.onEditChangeTemplate}
          onChangeString={this.onEditChangeString}
          onChangeParams={this.onEditChangeParams}
          jsonSchemEditorResetKey={this.state.jsonSchemEditorResetKey}
        />
      </CatchUnsavedChanges>
    );
  },

  onEditCancel() {
    InstalledComponentsActionCreators.cancelEditTemplatedComponentConfigData(
      this.state.componentId,
      this.state.configId
    );

    this.resetJsonSchemaEditor();
  },

  onEditSubmit() {
    return InstalledComponentsActionCreators.saveEditTemplatedComponentConfigData(
      this.state.componentId,
      this.state.configId
    ).then(this.resetJsonSchemaEditor);
  },

  resetJsonSchemaEditor() {
    if (!this.showJsonEditor()) {
      this.setState({ jsonSchemEditorResetKey: _.uniqueId('json_schema_editor_') });
    }
  },

  onEditChangeTemplate(value) {
    InstalledComponentsActionCreators.updateEditTemplatedComponentConfigDataTemplate(
      this.state.componentId,
      this.state.configId,
      value
    );
  },

  onEditChangeString(value) {
    InstalledComponentsActionCreators.updateEditTemplatedComponentConfigDataString(
      this.state.componentId,
      this.state.configId,
      value
    );
  },

  onEditChangeParams(value) {
    InstalledComponentsActionCreators.updateEditTemplatedComponentConfigDataParams(
      this.state.componentId,
      this.state.configId,
      value
    );
  },

  onEditChangeEditingMode(isStringEditingMode) {
    this.onEditCancel();
    InstalledComponentsActionCreators.toggleEditTemplatedComponentConfigDataString(
      this.state.componentId,
      this.state.configId,
      isStringEditingMode
    );
  },

  isValid() {
    if (this.state.editingString) {
      return isValidJsonConfig(this.state.editingString);
    }
    return true;
  },

  showJsonEditor() {
    if (this.hasNoTemplatesContent()) {
      return true;
    }

    if (!_.isBoolean(this.state.showJsonEditor)) {
      return this.state.isEditingString || !this.state.isTemplate;
    }

    return this.state.showJsonEditor;
  },

  hasNoTemplatesContent() {
    return (
      this.state.configSchema.get('properties', Map()).isEmpty() &&
      this.state.configTemplates.isEmpty()
    );
  },

  switchToJsonEditor() {
    this.setState({ showJsonEditor: true });
    this.onEditChangeEditingMode(true);
  },

  switchToTemplateEditor() {
    this.setState({ showJsonEditor: false });
    this.onEditChangeEditingMode(false);
  }
});

export default TemplatedConfiguration;
