import Promise from 'bluebird';
import _ from 'underscore';

const loadAsync = (props, initCallback = _.noop) => {
  return new Promise((resolve) => {
    const element = document.getElementById(props.id);

    if (element?.classList.contains('loaded')) {
      return resolve();
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;

    for (const [name, value] of Object.entries(props)) {
      script.setAttribute(name, value);
    }

    script.onload = () => {
      window.document.dispatchEvent(
        new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true
        })
      );
      document.getElementById(props.id)?.classList.add('loaded');
      initCallback();
      resolve();
    };

    document.body.appendChild(script);
  });
};

export { loadAsync };
