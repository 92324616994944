import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { List } from 'immutable';

import FilterPanel from '../../../../react/common/FilterPanel';
import LazyList from '../../../../react/common/LazyList';
import NoResultsFound from '../../../../react/common/NoResultsFound';

class VersionsTable extends React.Component {
  render() {
    return (
      <>
        <FilterPanel
          query={this.props.query}
          onChange={this.props.onSearchChange}
          placeholder="Search"
        />
        {this.renderTable()}
      </>
    );
  }

  renderTable() {
    if (this.props.filteredVersions.count() === 0 && this.props.versions.count() > 0) {
      return <NoResultsFound entityName="versions" />;
    }

    return (
      <div className="box">
        <Table hover>
          <thead>
            <tr>
              <th className="w-50" />
              <th>#</th>
              <th className="w-280 text-right">Created</th>
              <th className="w-200"></th>
            </tr>
          </thead>
          <LazyList
            rootElement="tbody"
            items={this.props.filteredVersions}
            render={this.props.renderRows}
          />
        </Table>
      </div>
    );
  }
}

VersionsTable.propTypes = {
  versions: PropTypes.instanceOf(List).isRequired,
  filteredVersions: PropTypes.instanceOf(List).isRequired,
  renderRows: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired
};

export default VersionsTable;
