import { List, Map } from 'immutable';

import dispatcher from '../../Dispatcher';
import StoreUtils, { initStore } from '../../utils/StoreUtils';
import {
  actionTypes,
  CHANNEL_EMAIL,
  FIELD_JOB_COMPONENT_ID,
  FIELD_JOB_CONFIGURATION_ID
} from './constants';
import { filterCurrentBranchNotifications } from './helpers';

let _store = initStore('NotificationsStore', Map({ notifications: List() }));

const NotificationsStore = StoreUtils.createStore({
  getAll() {
    return _store.get('notifications', List());
  },

  getNotifications(componentId, configId) {
    return _store.get('notifications', List()).filter((notification) => {
      const filters = notification.get('filters', List());

      return (
        notification.getIn(['recipient', 'channel']) === CHANNEL_EMAIL &&
        filters.some((filter) => {
          return (
            filter.get('field') === FIELD_JOB_COMPONENT_ID && filter.get('value') === componentId
          );
        }) &&
        filters.some((filter) => {
          return (
            filter.get('field') === FIELD_JOB_CONFIGURATION_ID && filter.get('value') === configId
          );
        })
      );
    });
  }
});

dispatcher.register(({ action }) => {
  switch (action.type) {
    case actionTypes.LOAD_NOTIFICATIONS_SUCCESS: {
      _store = _store.set('notifications', filterCurrentBranchNotifications(action.notifications));
      return NotificationsStore.emitChange();
    }

    default:
      break;
  }
});

export default NotificationsStore;
