import React from 'react';
import { Button } from 'react-bootstrap';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import type {
  ColumnInstance,
  UseExpandedInstanceProps,
  UseExpandedRowProps,
  UseTableCellProps,
  UseTableRowProps
} from 'react-table';
import { useExpanded, useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import type { Map } from 'immutable';

import ComponentIcon from '../../../react/common/ComponentIcon';
import ComponentName from '../../../react/common/ComponentName';
import { onTableRowKeyDown } from '../../../react/common/ConfigurationsTable/helpers';
import CreatedDate from '../../../react/common/CreatedDate';
import JobDuration from '../../../react/common/JobDuration';
import JobStatusLabel from '../../../react/common/JobStatusLabel';
import Loader from '../../../react/common/Loader';
import RouterLink from '../../../react/common/RouterLink';
import TableCollapseButton from '../../../react/common/TableCollapseButton';
import Truncated from '../../../react/common/Truncated';
import RoutesStore from '../../../stores/RoutesStore';
import hasSelections from '../../../utils/hasSelections';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../../utils/windowOpen';
import JobsActionCreators from '../actions';
import { BEHAVIOR_TYPES, JOBS_STATUS, JOBS_TYPES, routeNames } from '../constants';
import { isContainerJob, isPhaseJob, prepareSortedChildJobs } from '../helpers';
import {
  getComponentByJob,
  getConfigurationName,
  getConfigurationRowName,
  getOrchestratorPhaseName
} from '../jobResolver';
import ContinueOnFailureIcon from './ContinueOnFailureIcon';

type Column<D extends Record<string, unknown> = Record<string, unknown>> = ColumnInstance<D> &
  UseExpandedInstanceProps<D>;

type TableRow<D extends Record<string, unknown> = Record<string, unknown>> = UseTableRowProps<D> &
  UseExpandedRowProps<D>;

type TableCell<D extends Record<string, unknown> = Record<string, unknown>> = UseTableCellProps<D>;

const ChildJobs = (props: { job: Map<string, any>; childJobs: Map<string, any> }) => {
  const renderComponentDetail = (component: Map<string, any>, job: Map<string, any>) => {
    return (
      <>
        <ComponentIcon component={component} size="28" />
        <div className="ml-1">
          <div className="line-height-18">
            <Truncated text={getConfigurationName(job)} />
          </div>
          <div className="f-11 line-height-1 text-muted">
            <ComponentName component={component} capitalize showType>
              {(nameAndType: string) => nameAndType}
            </ComponentName>
          </div>
        </div>
      </>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (instance: Column) => {
          if (props.job.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER) {
            return (
              <div className="flex-container flex-start">
                Phases &amp; Tasks
                <Button
                  bsStyle="link"
                  onClick={() => instance.toggleAllRowsExpanded()}
                  className="ml-1 btn-link-inline font-normal"
                >
                  <FontAwesomeIcon
                    fixedWidth
                    className="f-14"
                    icon={instance.isAllRowsExpanded ? 'angle-down' : 'angle-right'}
                  />
                  {instance.isAllRowsExpanded ? 'Collapse' : 'Expand'} All
                </Button>
              </div>
            );
          }

          if (isPhaseJob(props.job)) {
            return 'Tasks';
          }

          return 'Rows';
        },
        Cell: ({ row }: { row: TableRow }) => {
          if (props.job.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER) {
            const component = getComponentByJob(row.values.job);

            return (
              <span
                className={classnames('with-expand flex-container flex-start', {
                  'level-2': row.depth === 1
                })}
              >
                {row.depth === 0 && (
                  <>
                    {row.canExpand && (
                      <TableCollapseButton entity="job" isCollapsed={!row.isExpanded} />
                    )}
                    <ComponentIcon isPhase component={component} size="28" className="mr-1" />
                  </>
                )}
                <RouterLink
                  to={routeNames.JOB_DETAIL}
                  params={{ jobId: row.values.job.get('id') }}
                  className={classnames('mr-1', {
                    'link-inherit': row.depth === 0,
                    'color-inherit no-underline': row.depth === 1
                  })}
                >
                  <div className="flex-container flex-start">
                    {row.depth === 0 ? (
                      <Truncated text={getOrchestratorPhaseName(row.values.job)} />
                    ) : (
                      renderComponentDetail(component, row.values.job)
                    )}
                  </div>
                </RouterLink>
                {row.values.job.getIn(['behavior', 'onError']) === BEHAVIOR_TYPES.WARNING && (
                  <ContinueOnFailureIcon />
                )}
              </span>
            );
          }

          if (isPhaseJob(props.job)) {
            const component = getComponentByJob(row.values.job);

            return (
              <div className="flex-container flex-start">
                <div className="mr-1 flex-container flex-start">
                  {renderComponentDetail(component, row.values.job)}
                </div>
                {row.values.job.getIn(['behavior', 'onError']) === BEHAVIOR_TYPES.WARNING && (
                  <ContinueOnFailureIcon />
                )}
              </div>
            );
          }

          return (
            <div className="flex-container flex-start">
              <Truncated text={getConfigurationRowName(row.values.job)} />
            </div>
          );
        },
        accessor: 'job'
      },
      {
        Header: 'Duration',
        Cell: ({ row }: { row: TableRow }) => {
          return (
            <JobDuration
              status={row.values.job.get('status')}
              startTime={row.values.job.get('startTime')}
              endTime={row.values.job.get('endTime')}
            />
          );
        },
        accessor: 'duration'
      },
      {
        Header: 'Created',
        Cell: ({ row }: { row: TableRow }) => {
          return <CreatedDate createdTime={row.values.job.get('createdTime')} />;
        },
        accessor: 'created'
      },
      {
        Header: 'Status',
        Cell: ({ row }: { row: TableRow }) => {
          return <JobStatusLabel status={row.values.job.get('status')} />;
        },
        accessor: 'status'
      }
    ],
    [props.job]
  );

  const data = React.useMemo(() => {
    return prepareSortedChildJobs(props.childJobs, props.job)
      .map((job: Map<string, any>) => {
        return {
          job,
          subRows: prepareSortedChildJobs(props.childJobs, job)
            .map((childJob: Map<string, any>) => {
              return { job: childJob };
            })
            .toArray()
        };
      })
      .toArray();
  }, [props.childJobs, props.job]);

  const [isLoading, setIsLoading] = React.useState(false);
  const tableInstance = useTable({ columns, data, autoResetExpanded: false } as any, useExpanded);
  const isStartingJob = [JOBS_STATUS.CREATED, JOBS_STATUS.WAITING].includes(
    props.job.get('status')
  );
  const showLoading = !tableInstance.rows.length && (isLoading || isStartingJob);

  React.useEffect(() => {
    setIsLoading(true);
    JobsActionCreators.loadChildJobsForce(props.job).finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.job?.get('id')]); // We want to reload child jobs only when parent job changes

  if (!isContainerJob(props.job) || (!tableInstance.rows.length && !isLoading && !isStartingJob)) {
    return null;
  }

  return (
    <div className="box mb-2">
      <div {...tableInstance.getTableProps({ className: 'table table-hover react-table' })}>
        <div className="thead">
          {tableInstance.headerGroups.map((headerGroup) => {
            const { key, ...headerProps } = headerGroup.getHeaderGroupProps({ className: 'tr' });

            return (
              <div key={key} {...headerProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...columnProps } = column.getHeaderProps({
                    className: classnames('th', {
                      'color-gray': column.id !== 'job' && showLoading,
                      'w-150 text-right': column.id === 'duration',
                      'w-250 text-right': column.id === 'created',
                      'w-125': column.id === 'status'
                    })
                  });

                  return (
                    <div key={key} {...columnProps}>
                      {column.render('Header')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div {...tableInstance.getTableBodyProps({ className: 'tbody' })}>
          {showLoading
            ? tableInstance.headerGroups.map((headerGroup, index) => (
                <div key={index} className="tr no-hover">
                  {headerGroup.headers.map((column, index) => (
                    <div key={index} className={classnames('td', { 'text-right': index > 0 })}>
                      {index === 0 ? (
                        <div className="flex-container flex-start color-base">
                          <Loader
                            className={classnames('icon-addon-right', {
                              'color-orange': !isStartingJob
                            })}
                          />
                          {isStartingJob &&
                            `${
                              props.job.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER
                                ? 'Phases'
                                : isPhaseJob(props.job)
                                ? 'Tasks'
                                : 'Rows'
                            } will be created when job starts processing.`}
                        </div>
                      ) : (
                        <div
                          className="bg-color-gray"
                          style={{ borderRadius: '4px', height: '8px' }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))
            : tableInstance.rows
                .map((row: any) => {
                  tableInstance.prepareRow(row);

                  return row;
                })
                .map((row: TableRow) => {
                  const rowAction = (e?: React.MouseEvent | React.KeyboardEvent) => {
                    if (hasSelections()) {
                      return;
                    }

                    if (shouldUseNewWindow(e)) {
                      const href = RoutesStore.getRouter().createHref(routeNames.JOB_DETAIL, {
                        jobId: row.values.job.get('id')
                      });

                      return windowOpen(href);
                    }

                    if (
                      row.depth === 0 &&
                      props.job.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER
                    ) {
                      return row.toggleRowExpanded(row.canExpand && !row.isExpanded);
                    }

                    return RoutesStore.getRouter().transitionTo(routeNames.JOB_DETAIL, {
                      jobId: row.values.job.get('id')
                    });
                  };

                  const userRowProps = {
                    tabIndex: '0',
                    role: 'button',
                    className: classnames('tr', { clickable: row.canExpand || row.depth === 1 }),
                    onMouseDown: simulateClickIfMiddleMouseIsUsed.mousedown,
                    onMouseUp: simulateClickIfMiddleMouseIsUsed.mouseup,
                    onClick: rowAction,
                    onKeyDown: onTableRowKeyDown(rowAction)
                  };

                  const { key, ...rowProps } = row.getRowProps(userRowProps);

                  return (
                    <div key={key} {...rowProps}>
                      {row.cells.map((cell: TableCell) => {
                        const { key, ...cellProps } = cell.getCellProps({
                          className: classnames('td', {
                            'text-right': ['duration', 'created'].includes(cell.column.id)
                          })
                        });

                        return (
                          <div key={key} {...cellProps}>
                            {cell.render('Cell')}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
        </div>
      </div>
    </div>
  );
};

const MemoizedChildJobs = React.memo(ChildJobs, shallowEqualImmutable);

export default MemoizedChildJobs;
