import React from 'react';

import { KEBOOLA_DATA_APPS } from '../../constants/componentIds';
import { configPoll } from '../../utils/genericPolls';
import InstalledComponentsActionsCreators from '../components/InstalledComponentsActionCreators';
import ComponentNameEdit from '../components/react/components/ComponentName';
import StorageActionCreators from '../components/StorageActionCreators';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import createVersionsPageRoute from '../components/utils/createVersionsPageRoute';
import VersionsActionCreators from '../components/VersionsActionCreators';
import SandboxesActions from '../sandboxes/Actions';
import BillingAlert from './components/BillingAlert';
import { routeNames } from './constants';
import Detail from './Detail';
import Header from './Header';
import Index from './Index';

const routes = {
  name: routeNames.ROOT,
  title: 'Data Apps',
  defaultRouteHandler: Index,
  infoAlertHandler: BillingAlert,
  headerButtonsHandler: Header,
  requireData: [
    (params) => {
      if (params.config) {
        return Promise.resolve();
      }

      return Promise.all([
        SandboxesActions.loadSandboxes(),
        InstalledComponentsActionsCreators.loadComponentConfigsData(KEBOOLA_DATA_APPS)
      ]);
    }
  ],
  poll: {
    skipFirst: true,
    interval: 15,
    action: () => {
      return Promise.all([
        SandboxesActions.loadSandboxesForce(),
        InstalledComponentsActionsCreators.loadComponentConfigsDataForce(KEBOOLA_DATA_APPS)
      ]);
    }
  },
  childRoutes: [
    {
      name: routeNames.DATA_APP_DETAIL,
      path: ':config',
      title: (routerState) => {
        return InstalledComponentsStore.getConfig(
          KEBOOLA_DATA_APPS,
          routerState.getIn(['params', 'config'])
        ).get('name', 'Data App');
      },
      nameEdit(params) {
        return (
          <ComponentNameEdit
            key={`${KEBOOLA_DATA_APPS}-${params.config}`}
            componentId={KEBOOLA_DATA_APPS}
            configId={params.config}
          />
        );
      },
      defaultRouteHandler: Detail,
      poll: configPoll(KEBOOLA_DATA_APPS),
      requireData: [
        () => StorageActionCreators.loadBucketsAndTables(),
        (params) => {
          return InstalledComponentsActionsCreators.loadComponentConfigData(
            KEBOOLA_DATA_APPS,
            params.config
          ).then(() => {
            const sandboxId = InstalledComponentsStore.getConfigData(
              KEBOOLA_DATA_APPS,
              params.config
            ).getIn(['parameters', 'id']);

            if (sandboxId) {
              SandboxesActions.loadSandbox(sandboxId);
            }
          });
        },
        (params) => VersionsActionCreators.loadVersions(KEBOOLA_DATA_APPS, params.config)
      ],
      childRoutes: [
        {
          ...createVersionsPageRoute(KEBOOLA_DATA_APPS, 'config', routeNames.DATA_APP_VERSIONS)
        }
      ]
    }
  ]
};

export default routes;
