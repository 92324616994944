import React from 'react';
import { IndexRedirect, IndexRoute, Redirect, Route } from 'react-router';
import Promise, { CancellationError } from 'bluebird';
import { rtrim, strRightBack } from 'underscore.string';

import ApplicationActionCreators from '../actions/ApplicationActionCreators';
import RouterActionCreators from '../actions/RouterActionCreators';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '../constants/features';
import { APP_ROUTE } from '../constants/routeNames';
import { routeNames as componentsRoutes } from '../modules/components-directory/constants';
import DevBranchesStore from '../modules/dev-branches/DevBranchesStore';
import { switchedAnyBranch } from '../modules/dev-branches/helpers';
import { hasLegacyOrchestrationInvalidToken } from '../modules/orchestrations/helpers';
import { routeNames as settingsRoutes } from '../modules/settings/constants';
import { routeNames as snowflakePartnerConnectRouteNames } from '../modules/snowflake-partner-connect/constants';
import { routeNames as storageRoutes } from '../modules/storage/constants';
import {
  routesDisabledInBranch,
  routesDisabledInDemoPreview,
  routesDisabledInProduction,
  routesDisabledInProtectedDefaultBranch,
  routesDisabledInSPCTrial
} from '../routes';
import ApplicationStore from '../stores/ApplicationStore';
import RoutesStore from '../stores/RoutesStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from './errors/helpers';
import Timer from './Timer';

let pendingPoller = null;
let pendingPromise = null;

const registerPollers = (state) => {
  const poller = RoutesStore.getPollerForLastRoute(state.routes);

  if (poller) {
    const cb = () => {
      if (poller.has('skip') && poller.get('skip')(state.params, state.location.query)) {
        return Promise.resolve();
      }

      ApplicationActionCreators.showPollLoading();
      return poller
        .get('action')(state.params, state.location.query)
        .finally(() => ApplicationActionCreators.hidePollLoading());
    };

    Timer.poll(cb, { interval: poller.get('interval'), skipFirst: poller.get('skipFirst') });
    pendingPoller = cb;
  }
};

const stopRegisteredPollers = () => {
  if (pendingPoller) {
    Timer.stop(pendingPoller);
    pendingPoller = null;
  }
};

const cancelPendingPromises = () => {
  if (pendingPromise) {
    pendingPromise.cancel();
  }
};

const loadRequiredData = (state, initialLoading = false) => {
  cancelPendingPromises();
  stopRegisteredPollers();

  RouterActionCreators.routeChangeStart(state);

  const promises = RoutesStore.getRequireDataFunctionsForRouterState(state.routes)
    .map((requireData) => Promise.resolve(requireData(state.params, state.location.query, state)))
    .toArray();

  if (initialLoading && promises.length > 0) {
    ApplicationActionCreators.showInitialLoading();
  }

  pendingPromise = Promise.all(promises);

  return pendingPromise
    .then(() => {
      registerPollers(state);
      RouterActionCreators.routeChangeSuccess(state);
      return null;
    })
    .catch((e) => {
      // bluebird error, calling calcel on already cancelled  promise
      if (e instanceof CancellationError) {
        return;
      }

      if (
        e?.response?.statusCode === HTTP_STATUS_CODE_NOT_FOUND ||
        hasLegacyOrchestrationInvalidToken(e)
      ) {
        if (switchedAnyBranch() && !state.location.state?.force) {
          return RoutesStore.getRouter().transitionToForce(APP_ROUTE);
        }

        return RouterActionCreators.routeChangeError(e, state);
      }

      throw e;
    })
    .finally(() => {
      if (initialLoading && promises.length > 0) {
        ApplicationActionCreators.hideInitialLoading();
      }
    });
};

const onEnter = (nextState, replace, callback) => {
  loadRequiredData(nextState, true).finally(callback);
};

const onChange = (prevState, nextState, replace, callback) => {
  loadRequiredData(nextState).finally(callback);
};

const getDisabledRoutes = () => {
  let disabledRoutes = DevBranchesStore.isDevModeActive()
    ? routesDisabledInBranch
    : routesDisabledInProduction;

  if (ApplicationStore.isDemoPreview()) {
    disabledRoutes = disabledRoutes.concat(routesDisabledInDemoPreview);
  }

  if (ApplicationStore.hasProtectedDefaultBranch() && !DevBranchesStore.isDevModeActive()) {
    disabledRoutes = disabledRoutes.concat(routesDisabledInProtectedDefaultBranch);
  }

  return [...new Set(disabledRoutes)];
};

const createReactRouterRoutes = (rootRoute, baseUrl) => {
  let _key = 0;

  const composeRoutes = (route) => {
    if (!route.childRoutes || route.childRoutes.length === 0) {
      return (
        <Route
          key={_key++}
          name={route.name}
          path={route.path || route.name}
          component={route.defaultRouteHandler}
          onLeave={route.onLeave}
          onEnter={route.onEnter}
        />
      );
    }

    return (
      <Route
        key={_key++}
        name={route.name}
        path={route.path || route.name}
        onLeave={route.onLeave}
        onEnter={route.onEnter}
      >
        <IndexRoute component={route.defaultRouteHandler} />
        {route.childRoutes.map(composeRoutes)}
      </Route>
    );
  };

  return (
    <Route
      path={baseUrl}
      onEnter={onEnter}
      onChange={onChange}
      component={rootRoute.handler}
      key={_key++}
    >
      <IndexRedirect to={rootRoute.path} />
      <Route
        path={rootRoute.path}
        name={rootRoute.name}
        component={rootRoute.defaultRouteHandler}
        onEnter={rootRoute.onEnter}
      />

      {getDisabledRoutes().map((routeName) => (
        <React.Fragment key={routeName}>
          <Redirect from={routeName} to={rootRoute.path} />
          <Redirect from={`${routeName}/*`} to={rootRoute.path} />
        </React.Fragment>
      ))}

      <Redirect from="use-cases" to="templates" />
      <Redirect from="use-cases/*" to="templates/*" />

      <Redirect
        from="transformations-v2/workspaces/:config/raw"
        to="components/keboola.sandboxes/:config/raw"
      />
      <Redirect
        from="transformations-v2/:component/:config/raw"
        to="components/:component/:config/raw"
      />
      <Redirect from="data-apps/:config/raw" to="components/keboola.data-apps/:config/raw" />
      <Redirect from="flows/:config/raw" to="components/keboola.orchestrator/:config/raw" />
      <Redirect
        from="orchestrations-v2/:config/raw"
        to="components/keboola.orchestrator/:config/raw"
      />
      <Redirect
        from="components/:component/:config/query/:row/raw"
        to="components/:component/:config/rows/:row/raw"
      />
      <Redirect
        from="transformations-v2/shared-codes/:config/:row/raw"
        to="components/keboola.shared-code/:config/rows/:row/raw"
      />

      <Redirect from="transformations-v2/workspaces" to="workspaces" />
      <Redirect from="transformations-v2/workspaces/*" to="workspaces/*" />

      {ApplicationStore.hasCurrentProjectFeature(FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED) &&
        routesDisabledInSPCTrial.map((routeName) => (
          <React.Fragment key={routeName}>
            <Redirect
              from={routeName}
              to={snowflakePartnerConnectRouteNames.UPGRADE_PAGE}
              query={{ d: routeName }}
            />
            <Redirect
              from={`${routeName}/*`}
              to={snowflakePartnerConnectRouteNames.UPGRADE_PAGE}
              query={{ d: routeName }}
            />
          </React.Fragment>
        ))}

      {rootRoute.childRoutes.map(composeRoutes)}

      <Route
        name="fallback-type"
        path="(applications)(extractors)(writers)(components)(/:component)"
        onEnter={({ location, params }) => {
          if (params.component) {
            return RoutesStore.getRouter().replaceTo(componentsRoutes.COMPONENT, {
              component: params.component
            });
          }

          return RoutesStore.getRouter().replaceTo(
            componentsRoutes.ROOT,
            null,
            null,
            `#${rtrim(strRightBack(location.pathname, '/'), 's')}`
          );
        }}
      />
      <Redirect from="(applications)(extractors)(writers)/*" to={`${componentsRoutes.ROOT}/*`} />
      <Redirect from="storage-explorer" to={storageRoutes.ROOT} />
      <Redirect from="storage-explorer/*" to={`${storageRoutes.ROOT}/*`} />
      <Redirect from="tokens" to={settingsRoutes.TOKENS} />
      <Redirect from="tokens/*" to={`${settingsRoutes.TOKENS}/*`} />
      <Redirect from="settings" to={settingsRoutes.PROJECT} />
      <Redirect from="settings-users" to={settingsRoutes.USERS} />

      <Route component={rootRoute.notFoundRouteHandler} path="*" name="notFound" />
    </Route>
  );
};

export default createReactRouterRoutes;
