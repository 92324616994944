import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { List, Map } from 'immutable';
import { truncate } from 'underscore.string';

import ProductionIcon from '../../common/ProductionIcon';
import SearchBar from '../../common/SearchBar';
import BranchList from './BranchList';

const DROPDOWN_ID = 'select-branch-dropdown-button';

const BranchSelector = (props: {
  xsrf: string;
  readOnly: boolean;
  isCollapsed: boolean;
  isDevModeActive: boolean;
  isDemoPreview: boolean;
  projectBaseUrl: string;
  organizations: List<any>;
  currentProject: Map<string, any>;
  devBranches: Map<string, any>;
  sapiToken: Map<string, any>;
  currentDevBranchId?: number | null;
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');

  return (
    <Dropdown
      dropup
      id={DROPDOWN_ID}
      rootCloseEvent="mousedown"
      className="branch-select"
      open={isOpen}
      onToggle={(isOpen: boolean, event: any, options: any) => {
        if (
          document.body.classList.contains('modal-open') ||
          (options?.source === 'rootClose' &&
            event?.composedPath?.().some((el: Element) => el?.id === DROPDOWN_ID))
        ) {
          return;
        }

        setOpen(isOpen);
      }}
      disabled={props.isDemoPreview}
    >
      <Dropdown.Toggle noCaret bsStyle="link" className="nav-link">
        {props.isDevModeActive ? (
          <>
            <FontAwesomeIcon
              fixedWidth
              icon={['far', 'code-branch']}
              className="icon-addon-right"
            />
            <span>{truncate(props.devBranches.getIn([props.currentDevBranchId, 'name']), 30)}</span>
          </>
        ) : (
          <>
            <ProductionIcon />
            <span>Production</span>
          </>
        )}
        {!props.isCollapsed && <FontAwesomeIcon icon="angle-right" />}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <SearchBar
          className="with-border"
          onChange={setQuery}
          query={query}
          placeholder="Search branches"
        />
        <BranchList
          query={query}
          organizations={props.organizations}
          currentProject={props.currentProject}
          xsrf={props.xsrf}
          devBranches={props.devBranches}
          currentDevBranchId={props.currentDevBranchId}
          isDevModeActive={props.isDevModeActive}
          sapiToken={props.sapiToken}
          readOnly={props.readOnly}
          projectBaseUrl={props.projectBaseUrl}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BranchSelector;
