import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from './componentIds';

const EXTERNAL_CLASS_PREFIX = 'extCls';
const HEADER_BUTTONS = `${EXTERNAL_CLASS_PREFIX}-header-buttons`;
const NEW_ENTITY_BUTTON = `${EXTERNAL_CLASS_PREFIX}-new-entity-button`;
const SIDEBAR = `${EXTERNAL_CLASS_PREFIX}-sidebar`;
const ORCHESTRATION_SETUP = `${EXTERNAL_CLASS_PREFIX}-orchestration-setup`;
const RUN_COMPONENT_BUTTON = `${EXTERNAL_CLASS_PREFIX}-run-component-button`;
const RUN_COMPONENT_MENU_ITEM = `${EXTERNAL_CLASS_PREFIX}-run-component-menu-item`;
const CONNECT_TO_MY_DATA_BUTTON = `${EXTERNAL_CLASS_PREFIX}-connect-to-my-data`;
const DEMO_DATA_BUTTON = `${EXTERNAL_CLASS_PREFIX}-use-with-demo-data`;

const getType = (component, isFlows = false) => {
  switch (component.get('id')) {
    case KEBOOLA_ORCHESTRATOR:
      return isFlows ? 'flow' : 'orchestration';

    case KEBOOLA_DATA_APPS:
      return 'data-app';

    default:
      return component.get('type');
  }
};

// HELPER FUNCTIONS TO GET COMPONENT TYPE FOR HEAP TRACKING
const useThisComponentType = (type) => `${EXTERNAL_CLASS_PREFIX}-use-this-${type}`;
const connectToMyDataComponentType = (type) => `${CONNECT_TO_MY_DATA_BUTTON}-${type}`;
const useDemoDataComponentType = (type) => `${DEMO_DATA_BUTTON}-${type}`;

const automateComponentType = (component) => {
  return `${EXTERNAL_CLASS_PREFIX}-automate-${getType(component)}`;
};

const runComponentType = (component, isFlows) => {
  return `${EXTERNAL_CLASS_PREFIX}-run-${getType(component, isFlows)}`;
};

export {
  useDemoDataComponentType,
  connectToMyDataComponentType,
  automateComponentType,
  runComponentType,
  useThisComponentType,
  EXTERNAL_CLASS_PREFIX,
  HEADER_BUTTONS,
  NEW_ENTITY_BUTTON,
  SIDEBAR,
  ORCHESTRATION_SETUP,
  RUN_COMPONENT_BUTTON,
  RUN_COMPONENT_MENU_ITEM
};
