import React from 'react';
import createReactClass from 'create-react-class';

import InfoTooltip from '../../../../react/common/InfoTooltip';

const DataTypeSizeHint = createReactClass({
  render() {
    return (
      <InfoTooltip
        tooltip={
          <>
            <p className="tooltip-title">Maximum possible values</p>
            <ul>
              <li>VARCHAR – 10000</li>
              <li>DECIMAL - 15,6</li>
            </ul>
          </>
        }
      />
    );
  }
});

export default DataTypeSizeHint;
