import React from 'react';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import ConfigurationInfoPanel from '../../../../../react/common/ConfigurationInfoPanel';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import ComponentsStore from '../../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../../components/stores/InstalledComponentsStore';
import StorageBucketsStore from '../../../../components/stores/StorageBucketsStore';
import StorageTablesStore from '../../../../components/stores/StorageTablesStore';
import ConfigurationRowsStore from '../../../../configurations/ConfigurationRowsStore';
import { loadSourceTables, reloadSourceTables } from '../../../actionsProvisioning';
import { supportConfigRows, supportSimpleSetup, supportSplitLoading } from '../../../helpers';
import {
  INCREMENTAL_CANDIDATES_PATH,
  LOADING_COLUMNS_PATH,
  LOADING_SOURCE_TABLES_PATH,
  SOURCE_TABLES_ERROR_PATH,
  SOURCE_TABLES_PATH
} from '../../../storeProvisioning';
import QueryEditor from '../../components/QueryEditor';
import QueryNav from './QueryNav';

const QueryDetail = function (componentId, actionsProvisioning, storeProvisioning) {
  const ExDbActionCreators = actionsProvisioning.createActions(componentId);

  return createReactClass({
    mixins: [
      createStoreMixin(
        ApplicationStore,
        InstalledComponentsStore,
        ConfigurationRowsStore,
        StorageTablesStore,
        ComponentsStore
      )
    ],

    componentDidMount() {
      // fetch sourceTable info if not done already
      if (!this.state.readOnly && !this.state.sourceTables) {
        loadSourceTables(componentId, this.state.configId).then(() => {
          if (
            supportSplitLoading(componentId) &&
            this.state.editingQuery.get('table') &&
            this.state.queryId
          ) {
            reloadSourceTables(componentId, this.state.configId, this.state.queryId);
          }
        });
      }
    },

    UNSAFE_componentWillReceiveProps() {
      const newState = this.getStateFromStores();

      this.setState(newState);

      if (!this.state.readOnly && supportSplitLoading(componentId)) {
        if (!newState.sourceTables && !newState.editingQuery.get('table')) {
          reloadSourceTables(componentId, newState.configId);
        } else if (newState.editingQuery.get('table')) {
          reloadSourceTables(componentId, newState.configId, newState.queryId);
        }
      }
    },

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const queryId = RoutesStore.getCurrentRouteParam('query');
      const ExDbStore = storeProvisioning.createStore(componentId, configId);
      const editingQuery = ExDbStore.getConfigQuery(queryId);
      const credentials = ExDbStore.getCredentials(componentId, configId);
      const isRowConfiguration = ExDbStore.isRowConfiguration();
      const migrateOnly = supportConfigRows(componentId) && !isRowConfiguration;

      return {
        configId: configId,
        queryId: queryId,
        editingQuery: editingQuery,
        component: ComponentsStore.getComponent(componentId),
        configuration: InstalledComponentsStore.getConfig(componentId, configId),
        processors: ExDbStore.getRowProcessors(queryId),
        editingQueries: ExDbStore.getEditingQueries(),
        newQueries: ExDbStore.getNewQueries(),
        newQueriesIdsList: ExDbStore.getNewQueriesIdsList(),
        isSaving: ExDbStore.isSavingQuery(queryId),
        tables: StorageTablesStore.getAll(),
        buckets: StorageBucketsStore.getAll(),
        sourceTables: ExDbStore.getSourceTables(),
        queriesFilter: ExDbStore.getQueriesFilter(),
        queriesFiltered: ExDbStore.getQueriesFiltered(),
        componentSupportsSimpleSetup: supportSimpleSetup(componentId),
        queryNameExists: ExDbStore.queryNameExists(editingQuery),
        localState: ExDbStore.getLocalState(),
        credentialsHasDatabase: !!credentials.get('database'),
        credentialsHasSchema: !!credentials.get('schema'),
        isConfigRow: isRowConfiguration,
        incrementalCandidates: ExDbStore.getIncrementalCandidates(),
        readOnly: ApplicationStore.isReadOnly() || migrateOnly
      };
    },

    handleQueryChange(newQuery) {
      return ExDbActionCreators.changeQueryEdit(this.state.configId, newQuery);
    },

    handleProcessorsChange(processors) {
      return ExDbActionCreators.changeProcessorsEdit(
        this.state.configId,
        this.state.queryId,
        processors
      );
    },

    handleRefreshSourceTables(queryId) {
      return reloadSourceTables(componentId, this.state.configId, queryId);
    },

    handleResetState() {
      return ExDbActionCreators.clearState(this.state.configId, this.state.queryId);
    },

    getDefaultOutputTableId(name) {
      return ExDbActionCreators.getDefaultOutputTableId(this.state.configId, name);
    },

    getQueryElement() {
      return (
        <QueryEditor
          query={this.state.editingQuery || Map()}
          tables={this.state.tables}
          buckets={this.state.buckets}
          onChange={this.handleQueryChange}
          onProcessorsChange={this.handleProcessorsChange}
          disabled={this.state.readOnly || this.state.isSaving}
          showSimple={this.state.componentSupportsSimpleSetup}
          configId={this.state.configId}
          componentId={componentId}
          component={this.state.component}
          processors={this.state.processors}
          getDefaultOutputTable={this.getDefaultOutputTableId}
          isLoadingSourceTables={this.state.localState.getIn(LOADING_SOURCE_TABLES_PATH, false)}
          isLoadingColumns={this.state.localState.getIn(LOADING_COLUMNS_PATH, false)}
          sourceTables={this.state.localState.getIn(SOURCE_TABLES_PATH) || List()}
          sourceTablesError={this.state.localState.getIn(SOURCE_TABLES_ERROR_PATH)}
          destinationEditing={this.state.localState.getIn(
            ['isDestinationEditing', this.state.queryId],
            false
          )}
          onDestinationEdit={ExDbActionCreators.destinationEdit}
          getPKColumns={ExDbActionCreators.getPKColumnsFromSourceTable}
          queryNameExists={this.state.queryNameExists}
          credentialsHasDatabase={this.state.credentialsHasDatabase}
          credentialsHasSchema={this.state.credentialsHasSchema}
          refreshMethod={this.handleRefreshSourceTables}
          onResetState={this.handleResetState}
          isConfigRow={this.state.isConfigRow}
          incrementalCandidates={this.state.localState.getIn(INCREMENTAL_CANDIDATES_PATH) || List()}
          readOnly={this.state.readOnly}
        />
      );
    },

    render() {
      return (
        <>
          {this.state.isConfigRow && (
            <ConfigurationInfoPanel
              component={this.state.component}
              config={this.state.configuration}
              className="tw-mt-0"
            />
          )}
          <div className="row">
            <div className="col-xs-3">
              <QueryNav
                readOnly={this.state.readOnly}
                queries={this.state.queriesFiltered}
                navQuery={this.state.editingQuery || Map()}
                editingQueries={this.state.editingQueries || List()}
                newQueries={this.state.newQueries || List()}
                newQueriesIdsList={this.state.newQueriesIdsList}
                configurationId={this.state.configId}
                filter={this.state.queriesFilter}
                componentId={componentId}
                actionsProvisioning={actionsProvisioning}
              />
            </div>
            <div className="col-xs-9">{this.getQueryElement()}</div>
          </div>
        </>
      );
    }
  });
};

export default QueryDetail;
