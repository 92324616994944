import React from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from './ConfirmModal';
import RowActionMenuItem from './RowActionMenuItem';

class ConfirmMenuItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  render() {
    return (
      <>
        <RowActionMenuItem
          disabled={this.props.isDisabled}
          onSelect={() => this.setState({ showModal: true })}
          onKeyDown={this.props.onKeyDown}
        >
          {this.props.children}
        </RowActionMenuItem>
        <ConfirmModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          icon={this.props.icon}
          title={this.props.title}
          text={this.props.text}
          isLoading={this.props.isLoading}
          closeAfterResolve={this.props.closeAfterResolve}
          onConfirm={this.props.onConfirm}
          buttonLabel={this.props.buttonLabel}
          buttonType={this.props.buttonType}
        />
      </>
    );
  }
}

ConfirmMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  closeAfterResolve: PropTypes.bool,
  icon: PropTypes.string,
  buttonType: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  children: PropTypes.any,
  onKeyDown: PropTypes.func
};

ConfirmMenuItem.defaultProps = {
  buttonType: 'danger'
};

export default ConfirmMenuItem;
