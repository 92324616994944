import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import Select from '../../../../../react/common/Select';

const ThoughtSpotTypeInput = createReactClass({
  propTypes: {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  },

  getDefaultProps() {
    return {
      value: 'standard'
    };
  },

  render() {
    return (
      <div className="form-group">
        <label className="control-label col-sm-3">Table Type</label>
        <div className="col-sm-3">
          <Select
            name="tableType"
            value={this.props.value}
            disabled={this.props.disabled}
            clearable={false}
            options={[
              { label: 'STANDARD', value: 'standard' },
              { label: 'FACT', value: 'fact' },
              { label: 'DIMENSION', value: 'dimension' }
            ]}
            onChange={(selected) => this.props.onChange(selected)}
          />
        </div>
      </div>
    );
  }
});

export default ThoughtSpotTypeInput;
