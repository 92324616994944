import { fromJS, Map } from 'immutable';

export const preparePayload = (configData, row) => {
  return fromJS({
    configData: {
      parameters: {
        service_account: configData.getIn(['parameters', 'service_account'], Map()),
        google: {
          location: configData.getIn(['parameters', 'google', 'location'], ''),
          datasetId: row.getIn(['parameters', 'query', 'datasetId']),
          tableId: row.getIn(['parameters', 'query', 'tableId'])
        }
      }
    }
  });
};

export default {
  name: 'getColumns',
  cache: true,
  autoload: false,
  getPayload: (configData, row) => {
    if (
      configData.getIn(['parameters', 'service_account'], Map()).isEmpty() ||
      !configData.getIn(['parameters', 'google', 'location']) ||
      !row.getIn(['parameters', 'query', 'datasetId']) ||
      !row.getIn(['parameters', 'query', 'tableId'])
    ) {
      return false;
    }

    return preparePayload(configData, row);
  }
};
