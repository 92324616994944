import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router, useRouterHistory } from 'react-router';
import { NamedURLResolver, resolve } from 'react-router-named-routes';
import Promise from 'bluebird';
import { createHistory, useBeforeUnload } from 'history';
import qs from 'qs';
import _ from 'underscore';
import { rtrim } from 'underscore.string';

import ApplicationActionCreators from './actions/ApplicationActionCreators';
import RouterActionCreators from './actions/RouterActionCreators';
import ComponentsActionCreators from './modules/components/ComponentsActionCreators';
import InstalledComponentsActionCreators from './modules/components/InstalledComponentsActionCreators';
import StorageActionCreators from './modules/components/StorageActionCreators';
import StorageApi from './modules/components/StorageApi';
import { loadMergeRequests, setCurrentDevBranchId } from './modules/dev-branches/actions';
import DevBranchesStore from './modules/dev-branches/DevBranchesStore';
import { redirectToProductionIfBranchNotFound } from './modules/dev-branches/helpers';
import { loadRuntimes } from './modules/runtimes/actions';
import ServicesActionCreators from './modules/services/ActionCreators';
import StackFeaturesActionCreators from './modules/stack-features/ActionCreators';
import { loadVariables } from './modules/vault/actions';
import LoadingBlock from './react/common/LoadingBlock';
import ApplicationStore from './stores/ApplicationStore';
import createReactRouterRoutes from './utils/createReactRouterRoutes';
import { init as initApis } from './api';
import appRoutes from './routes';

// eslint-disable-next-line react-hooks/rules-of-hooks
const history = useRouterHistory(useBeforeUnload(createHistory))({
  parseQueryString: qs.parse,
  stringifyQuery: qs.stringify
});

class Root extends Component {
  state = {
    routes: null
  };

  componentDidMount() {
    this.checkSafariBrowser();
    this.loadRequiredData().then(this.createRoutes);
  }

  render() {
    if (!this.state.routes) {
      return <LoadingBlock style={{ height: '100vh' }} />;
    }

    return <Router history={history} routes={this.state.routes} />;
  }

  loadRequiredData = () => {
    const { sapi, organizations, projectTemplates, kbc, tokenStats } = this.props.data;

    ApplicationActionCreators.receiveApplicationData({
      isDemoPreview: this.props.isDemoPreview,
      sapiUrl: sapi.url,
      sapiToken: sapi.token,
      organizations,
      projectTemplates,
      kbc,
      tokenStats
    });
    RouterActionCreators.routesConfigurationReceive(appRoutes);
    setCurrentDevBranchId();

    return Promise.all([
      StorageApi.loadStackInfo().then((stackInfo) => {
        ServicesActionCreators.receive(stackInfo.services);
        StackFeaturesActionCreators.receive(stackInfo.features);
        ApplicationActionCreators.receiveStack(stackInfo.stack);
        ComponentsActionCreators.receiveAllComponents(stackInfo.components);
        initApis(stackInfo.services, sapi.token.token);
      }),
      StorageActionCreators.loadDevBranches()
    ]).tap(() => {
      if (ApplicationStore.hasNewQueue()) {
        loadRuntimes();
      }

      if (ApplicationStore.hasProtectedDefaultBranch()) {
        loadMergeRequests();
        loadVariables();
      }

      return Promise.all([
        // force to load buckets and tables to prevent next load to be blocking
        StorageActionCreators.loadBucketsAndTablesForce({ include: 'columns' }),
        InstalledComponentsActionCreators.loadInstalledComponentsForce({
          include: 'configuration'
        }).catch((error) => {
          if (redirectToProductionIfBranchNotFound(error)) {
            return;
          }

          throw error;
        })
      ]);
    });
  };

  createRoutes = () => {
    const routerBaseUrl = DevBranchesStore.isDevModeActive()
      ? `${rtrim(
          this.props.data.kbc.projectBaseUrl,
          '/'
        )}/branch/${DevBranchesStore.getCurrentId()}`
      : this.props.data.kbc.projectBaseUrl;

    RouterActionCreators.historyCreated({
      createHref: (name, params, query) =>
        history.createHref({ pathname: resolve(name, params), query }),
      transitionTo: (name, params, query, hash, state) =>
        history.push({ pathname: resolve(name, params), query, hash, state }),
      transitionToForce: (name, params, query) =>
        history.push({ pathname: resolve(name, params), query, state: { force: true } }),
      replaceTo: (name, params, query, hash) =>
        history.replace({ pathname: resolve(name, params), query, hash }),
      updateQuery: (query) => {
        window.history.replaceState(
          {},
          document.title,
          history.createHref({
            pathname: window.location.pathname,
            query: _.pick(
              { ...qs.parse(new URLSearchParams(window.location.search).toString()), ...query },
              Boolean
            ),
            hash: window.location.hash
          })
        );
      }
    });

    const routes = createReactRouterRoutes(appRoutes, routerBaseUrl);
    NamedURLResolver.mergeRouteTree(routes, routerBaseUrl);
    this.setState({ routes });
  };

  checkSafariBrowser = () => {
    if (navigator.userAgent?.includes('Safari') && !navigator.userAgent?.includes('Chrome')) {
      document.body.classList.add('safari-browser');
    }
  };
}

Root.propTypes = {
  data: PropTypes.shape({
    kbc: PropTypes.object.isRequired,
    sapi: PropTypes.object.isRequired,
    tokenStats: PropTypes.object.isRequired,
    organizations: PropTypes.array.isRequired,
    projectTemplates: PropTypes.array.isRequired
  }).isRequired
};

export default Root;
