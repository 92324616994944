export default {
  ActionTypes: {
    CONFIGURATIONS_UPDATE_CONFIGURATION: 'CONFIGURATIONS_UPDATE_CONFIGURATION',
    CONFIGURATIONS_RESET_CONFIGURATION: 'CONFIGURATIONS_RESET_CONFIGURATION',

    CONFIGURATIONS_SAVE_CONFIGURATION_START: 'CONFIGURATIONS_SAVE_CONFIGURATION_START',
    CONFIGURATIONS_SAVE_CONFIGURATION_SUCCESS: 'CONFIGURATIONS_SAVE_CONFIGURATION_SUCCESS',
    CONFIGURATIONS_SAVE_CONFIGURATION_ERROR: 'CONFIGURATIONS_SAVE_CONFIGURATION_ERROR',

    CONFIGURATIONS_UPDATE_JSON_CONFIGURATION: 'CONFIGURATIONS_UPDATE_JSON_CONFIGURATION',
    CONFIGURATIONS_RESET_JSON_CONFIGURATION: 'CONFIGURATIONS_RESET_JSON_CONFIGURATION',

    CONFIGURATIONS_SAVE_JSON_CONFIGURATION_START: 'CONFIGURATIONS_SAVE_JSON_CONFIGURATION_START',
    CONFIGURATIONS_SAVE_JSON_CONFIGURATION_SUCCESS:
      'CONFIGURATIONS_SAVE_JSON_CONFIGURATION_SUCCESS',
    CONFIGURATIONS_SAVE_JSON_CONFIGURATION_ERROR: 'CONFIGURATIONS_SAVE_JSON_CONFIGURATION_ERROR',

    CONFIGURATIONS_JSON_EDITOR_OPEN: 'CONFIGURATIONS_JSON_EDITOR_OPEN',
    CONFIGURATIONS_JSON_EDITOR_CLOSE: 'CONFIGURATIONS_JSON_EDITOR_CLOSE',

    CONFIGURATIONS_ORDER_ROWS_START: 'CONFIGURATIONS_ORDER_ROWS_START',
    CONFIGURATIONS_ORDER_ROWS_SUCCESS: 'CONFIGURATIONS_ORDER_ROWS_SUCCESS',
    CONFIGURATIONS_ORDER_ROWS_ERROR: 'CONFIGURATIONS_ORDER_ROWS_ERROR'
  }
};
