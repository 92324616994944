import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import { ltrim } from 'underscore.string';

import keyCodes from '../../../constants/keyCodes';
import BucketStageLabel from '../../../react/common/BucketStageLabel';
import RouterLink from '../../../react/common/RouterLink';
import Truncated from '../../../react/common/Truncated';
import RoutesStore from '../../../stores/RoutesStore';
import { isCreatedInDevBranch } from '../../dev-branches/helpers';
import { routeNames, tableTabs } from '../constants';
import { getFilteredData, sortByDisplayName, sortByExactMatch } from '../helpers';

const prepareBuckets = memoizeOne((buckets, tables) => {
  return buckets.map((bucket) => {
    const bucketTables = tables.filter((table) => {
      return table.getIn(['bucket', 'id']) === bucket.get('id');
    });

    return bucket.set('bucketTables', bucketTables);
  });
});

class SearchContextDropdownMenu extends React.Component {
  constructor(props) {
    super(props);

    this.currentTargetRef = React.createRef();

    this.state = {
      openedBuckets: this.props.activeBucket ? Map({ [this.props.activeBucket]: true }) : Map()
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen && this.currentTargetRef.current) {
      const activeElement = this.currentTargetRef.current;
      activeElement.offsetParent.scrollTop = activeElement.offsetTop - 40;
    }
  }

  render() {
    const data = prepareBuckets(this.props.buckets, this.props.tables);

    if (
      this.props.contextFilter &&
      (this.props.searchQuery || !this.props.searchFilters.isEmpty())
    ) {
      return this.renderStaticResults(data);
    }

    return data
      .sortBy(sortByDisplayName)
      .map((bucket) => {
        const isActive = this.props.activeBucket === bucket.get('id');
        const noTables = bucket.get('bucketTables', Map()).isEmpty();
        const isOpen = this.state.openedBuckets.has(bucket.get('id')) && !noTables;

        return (
          <React.Fragment key={bucket.get('id')}>
            <li
              ref={isActive && !this.props.activeTable ? this.currentTargetRef : null}
              className={classnames('flex-container flex-start', {
                'dev-bucket': isCreatedInDevBranch(bucket),
                clickable: !noTables
              })}
              onClick={() => !noTables && this.toggleBucket(bucket.get('id'))}
              onKeyDown={(e) => {
                if (e.key === keyCodes.ENTER && !noTables) {
                  this.toggleBucket(bucket.get('id'));
                }
              }}
            >
              <div className="flex-container flex-start flex-inline">
                <FontAwesomeIcon
                  fixedWidth
                  className="f-16 text-muted btn-icon"
                  icon={isOpen ? 'angle-down' : 'angle-right'}
                />
                <FontAwesomeIcon
                  icon={noTables ? ['far', 'folder'] : isOpen ? 'folder-open' : 'folder'}
                  className={classnames('f-16 icon-addon-right', {
                    'color-primary': isActive && !this.props.activeTable
                  })}
                />
              </div>
              {this.renderBucketLink(bucket)}
              <BucketStageLabel stage={bucket.get('stage')} />
            </li>
            {isOpen &&
              bucket
                .get('bucketTables', Map())
                .sortBy(sortByDisplayName)
                .map((table) => {
                  const isActive = this.props.activeTable === table.get('id');

                  return (
                    <li
                      ref={isActive ? this.currentTargetRef : null}
                      className="flex-container flex-start pl-2 clickable"
                      key={table.get('id')}
                      onClick={() => this.goToTable(table)}
                      onKeyDown={(e) => {
                        if (e.key === keyCodes.ENTER) {
                          this.goToTable(table);
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon="table"
                        className={classnames('f-16 icon-addon-right text-muted ml-2', {
                          'color-primary': isActive
                        })}
                      />
                      {this.renderTableLink(bucket, table)}
                    </li>
                  );
                })
                .toArray()}
          </React.Fragment>
        );
      })
      .toArray();
  }

  renderStaticResults(data) {
    return getFilteredData(data, this.props.searchQuery, this.props.searchFilters.toJS())
      .sortBy(sortByDisplayName)
      .sortBy(sortByExactMatch)
      .map((bucket) => {
        const isActive = this.props.activeBucket === bucket.get('id');
        const tables = bucket.get('bucketTables', List()).sortBy(sortByDisplayName);

        return (
          <React.Fragment key={bucket.get('id')}>
            {(bucket.get('matches') || !tables.isEmpty()) && (
              <li
                ref={isActive ? this.currentTargetRef : null}
                key={bucket.get('id')}
                className={classnames('flex-container flex-start clickable', {
                  'dev-bucket': isCreatedInDevBranch(bucket)
                })}
                onClick={() => this.goToBucket(bucket.get('id'))}
                onKeyDown={(e) => {
                  if (e.key === keyCodes.ENTER) {
                    this.goToBucket(bucket.get('id'));
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={isActive ? 'folder-open' : 'folder'}
                  className={classnames('f-16 icon-addon-right text-muted', {
                    'color-primary': isActive
                  })}
                  title={bucket.get('displayName')}
                />
                {this.renderBucketLink(bucket)}
                <BucketStageLabel stage={bucket.get('stage')} />
              </li>
            )}
            {tables
              .sortBy(sortByDisplayName)
              .sortBy(sortByExactMatch)
              .map((table) => {
                const isActive = this.props.activeTable === table.get('id');

                return (
                  <li
                    key={table.get('id')}
                    className="flex-container flex-start pl-2 clickable"
                    onClick={() => this.goToTable(table)}
                    onKeyDown={(e) => {
                      if (e.key === keyCodes.ENTER) {
                        this.goToTable(table);
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon="table"
                      className={classnames('f-16 icon-addon-right text-muted', {
                        'color-primary': isActive
                      })}
                      title={table.get('displayName')}
                    />
                    {this.renderTableLink(bucket, table)}
                  </li>
                );
              })
              .toArray()}
          </React.Fragment>
        );
      })
      .toArray();
  }

  renderBucketLink(bucket) {
    return (
      <RouterLink
        tabIndex={-1}
        role="menuitem"
        className="p-0"
        to={routeNames.BUCKET}
        params={{ bucketId: bucket.get('id') }}
        hash={this.getBucketHash()}
        onClick={() => this.setState({ isOpen: false })}
        title={bucket.get('displayName')}
        onKeyDown={this.props.onKeyDown}
      >
        <Truncated text={bucket.get('displayName')} />
      </RouterLink>
    );
  }

  renderTableLink(bucket, table) {
    return (
      <RouterLink
        tabIndex={-1}
        role="menuitem"
        to={routeNames.TABLE}
        params={{ bucketId: bucket.get('id'), tableName: table.get('name') }}
        hash={this.getTableHash(table.get('isAlias'))}
        onClick={() =>
          this.setState({ isOpen: false, openedBuckets: Map({ [bucket.get('id')]: true }) })
        }
        title={table.get('displayName')}
        className={classnames('p-0', { 'dotted-underline': !!table.get('isAlias') })}
        onKeyDown={this.props.onKeyDown}
      >
        <Truncated text={table.get('displayName')} />
      </RouterLink>
    );
  }

  toggleBucket(bucketId) {
    this.setState({
      openedBuckets: this.state.openedBuckets.has(bucketId)
        ? this.state.openedBuckets.delete(bucketId)
        : this.state.openedBuckets.set(bucketId, true)
    });
  }

  getBucketHash() {
    if (this.props.activeTable) {
      return '';
    }

    return window.location.hash;
  }

  getTableHash(isAlias) {
    if (
      !this.props.activeTable ||
      (isAlias && ltrim(window.location.hash, '#') === tableTabs.SNAPSHOT_AND_RESTORE)
    ) {
      return '';
    }

    return window.location.hash;
  }

  goToBucket(bucketId) {
    return RoutesStore.getRouter().transitionTo(routeNames.BUCKET, { bucketId });
  }

  goToTable(table) {
    return RoutesStore.getRouter().transitionTo(routeNames.TABLE, {
      bucketId: table.getIn(['bucket', 'id']),
      tableName: table.get('name')
    });
  }
}

SearchContextDropdownMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  searchFilters: PropTypes.instanceOf(Map).isRequired,
  contextFilter: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string.isRequired,
  activeBucket: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func,
  activeTable: PropTypes.string
};

export default SearchContextDropdownMenu;
