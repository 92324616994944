import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { JSONEditor } from '@json-editor/json-editor';

import callDockerAction from '../../../modules/components/DockerActionsApi';
import Loader from '../../../react/common/Loader';
import RoutesStore from '../../../stores/RoutesStore';
import { prepareConfigData } from '../helpers';

const TestConnectionApp = () => {
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState(null);

  return (
    <>
      <Button
        bsStyle="primary"
        disabled={loading}
        onClick={() => {
          const componentId = RoutesStore.getCurrentRouteComponentId();
          const configId = RoutesStore.getCurrentRouteParam('configId');
          const rowId = RoutesStore.getCurrentRouteParam('row');

          setLoading(true);
          setResult(null);
          return callDockerAction(componentId, 'testConnection', {
            configData: prepareConfigData(componentId, configId, rowId)
          })
            .then((response) => {
              return setResult({ error: response?.status === 'error', message: response?.message });
            })
            .finally(() => setLoading(false));
        }}
      >
        {loading ? (
          <>
            <Loader className="icon-addon-right" />
            Testing Connection
          </>
        ) : (
          'Test Connection'
        )}
      </Button>
      {result && (
        <Alert bsStyle={result.error ? 'danger' : 'success'} className="alert-no-icon mt-1">
          <span className="alert-title">{result.error ? 'Failed to connect!' : 'Connected!'}</span>
          {result.message && <p className="mt-1">{result.message}</p>}
        </Alert>
      )}
    </>
  );
};

export default class TestConnectionEditor extends JSONEditor.AbstractEditor {
  build() {
    const buttonRoot = window.document.createElement('span');

    this.container.appendChild(buttonRoot);

    this.root = createRoot(buttonRoot);

    this.root.render(<TestConnectionApp />);
  }

  destroy() {
    setTimeout(() => this.root?.unmount());

    super.destroy();
  }
}
