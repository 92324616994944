import React from 'react';

import MultiSortTooltip from '../MultiSortTooltip';
import SortIcon from '../SortIcon';
import type { TableColumn } from './Table';

type Props = {
  column: TableColumn;
  canSort: boolean;
};

const ColumnHeader = (props: Props) => {
  if (!props.canSort) {
    return props.column.render('Header');
  }

  const renderSortIcon = (classNames: string) => {
    return (
      <SortIcon
        className={classNames}
        isSorted={props.column.isSorted}
        isSortedDesc={!!props.column.isSortedDesc}
      />
    );
  };

  if (props.column.id === 'data') {
    return (
      <>
        {props.column.render('Header')}
        <MultiSortTooltip active={!props.column.isSorted}>
          {renderSortIcon('icon-addon-left')}
        </MultiSortTooltip>
      </>
    );
  }

  return (
    <>
      <MultiSortTooltip active={!props.column.isSorted}>
        {renderSortIcon('icon-addon-right')}
      </MultiSortTooltip>
      {props.column.render('Header')}
    </>
  );
};

export default ColumnHeader;
