import adapter from '../adapters/oauth';
import OauthSection from '../react/components/OauthSection';

export default function () {
  return {
    render: OauthSection,
    onLoad: adapter.parseConfiguration,
    onSave: adapter.createConfiguration,
    isComplete: adapter.isComplete,
    isAuthSection: true
  };
}
