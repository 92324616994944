import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
  ProgressBar
} from 'react-bootstrap';
import createReactClass from 'create-react-class';

import Checkbox from '../../../react/common/Checkbox';
import ConfirmButtons from '../../../react/common/ConfirmButtons';
import FilesDropZone from '../../../react/common/FilesDropZone';
import Loader from '../../../react/common/Loader';
import ModalIcon from '../../../react/common/ModalIcon';

const INITIAL_STATE = {
  file: null,
  incremental: false,
  delimiter: ',',
  enclosure: '"',
  error: null
};

const LoadTableFromCsvModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    table: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    progress: PropTypes.number.isRequired
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Import CSV into table {this.props.table.get('displayName')}</Modal.Title>
            <ModalIcon color="green" icon="arrow-up" bold />
          </Modal.Header>
          <Modal.Body>
            {this.state.error ? (
              this.renderError()
            ) : (
              <div>{this.isSaving() ? this.renderProgress() : this.renderForm()}</div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.error ? (
              <ConfirmButtons
                block
                saveStyle="primary"
                saveLabel="Try again"
                onSave={this.resetState}
              />
            ) : (
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveLabel="Import"
                isDisabled={this.isDisabled()}
              />
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  renderForm() {
    return (
      <>
        <FilesDropZone label="CSV file" onDrop={this.handleFile} files={this.state.file} />
        <FormGroup>
          <ControlLabel>Delimiter</ControlLabel>
          <FormControl type="text" value={this.state.delimiter} onChange={this.handleDelimiter} />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Enclosure</ControlLabel>
          <FormControl type="text" value={this.state.enclosure} onChange={this.handleEnclosure} />
        </FormGroup>
        <FormGroup>
          <Checkbox checked={this.state.incremental} onChange={this.handleIncremental}>
            Incremental
          </Checkbox>
          <HelpBlock>Data from the CSV file will be appended to the table.</HelpBlock>
        </FormGroup>
      </>
    );
  },

  renderProgress() {
    const progress = this.props.isLoading ? 100 : this.props.progress;

    return (
      <div>
        <p>{progress < 100 ? 'Uploading file...' : 'File was successfully uploaded.'}</p>
        <ProgressBar striped bsStyle="success" now={progress} active={progress < 100} />
        {this.props.isLoading && (
          <p>
            <Loader /> Importing data...
          </p>
        )}
      </div>
    );
  },

  renderError() {
    return <Alert bsStyle="danger">{this.state.error}</Alert>;
  },

  handleFile(files) {
    this.setState({ file: files[0] });
  },

  handleIncremental() {
    this.setState({ incremental: !this.state.incremental });
  },

  handleDelimiter(event) {
    this.setState({
      delimiter: event.target.value
    });
  },

  handleEnclosure(event) {
    this.setState({
      enclosure: event.target.value
    });
  },

  onHide() {
    this.props.onHide();
    this.resetState();
  },

  onSubmit(event) {
    event.preventDefault();
    const params = {
      delimiter: this.state.delimiter,
      enclosure: this.state.enclosure,
      incremental: this.state.incremental ? 1 : 0
    };

    return this.props.onSubmit(this.state.file, params).then(this.onHide, this.handleError);
  },

  handleError(message) {
    this.setState({ error: message });
  },

  resetState() {
    this.setState(INITIAL_STATE);
  },

  isSaving() {
    return this.props.isLoading || this.props.progress > 0;
  },

  isDisabled() {
    if (this.isSaving() || this.state.error) {
      return true;
    }

    if (!this.state.file || !this.state.delimiter || !this.state.enclosure) {
      return true;
    }

    return false;
  }
});

export default LoadTableFromCsvModal;
