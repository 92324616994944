import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, SplitButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import { rtrim } from 'underscore.string';

import ApplicationActionCreators from '../../../actions/ApplicationActionCreators';
import { HTTP_STATUS_CODE_INTERNAL_SERVER_ERROR } from '../../../utils/errors/helpers';
import loadOnedriveFilePicker from '../../../utils/external/onedriveFilePicker';
import microsoftGraphApi from '../microsoftGraphApi';

const CLIENT_ID = '49f0d631-a959-418b-ba4e-e0d4c95c5b94';
const SCOPES = ['User.Read', 'Files.Read.All'];

const OPEN_TYPE = {
  FORCE_POPUP: 'FORCE_POPUP'
};

class FilePicker extends React.Component {
  constructor(props) {
    super(props);

    this.openEditor = this.openEditor.bind(this);

    this.state = {
      loadingScript: false
    };
  }

  componentDidMount() {
    this.setState({ loadingScript: true });
    Promise.props({
      filePicker: !window.OneDrive ? loadOnedriveFilePicker() : Promise.resolve(),
      msal: import(/* webpackChunkName: "msal" */ '@azure/msal-browser')
    }).then(({ msal }) => {
      this.msal = new msal.PublicClientApplication({
        auth: {
          clientId: CLIENT_ID,
          redirectUri: window.location.origin
        }
      });

      this.setState({ loadingScript: false });
    });
  }

  render() {
    return (
      <SplitButton
        id="file-picker-button"
        bsStyle="success"
        title={
          <>
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            Use File Picker
          </>
        }
        onClick={this.openEditor}
        disabled={this.props.disabled || this.state.loadingScript}
      >
        <MenuItem
          onSelect={() => this.openEditor(OPEN_TYPE.FORCE_POPUP)}
          disabled={this.props.disabled || this.state.loadingScript}
        >
          Force Account Selection
        </MenuItem>
      </SplitButton>
    );
  }

  handleFileSelect(accessToken, files) {
    const driveId = files.value[0].parentReference.driveId;
    const fileId = files.value[0].id;

    return microsoftGraphApi
      .getFile(accessToken, driveId, fileId)
      .then((result) => {
        this.props.onSelect({ name: result.name, driveId, fileId });
        return null;
      })
      .catch((error) => {
        this.props.onSelect({ name: '', driveId, fileId });
        throw error;
      });
  }

  handlePickerError(error) {
    if (error.message === 'popup window is disconnected') {
      return ApplicationActionCreators.sendNotification({
        type: 'error',
        message: 'File Picker popup was blocked by browser.'
      });
    }
  }

  openEditor(type) {
    return Promise.resolve(this.msal.initialize())
      .then(() => {
        if (type === OPEN_TYPE.FORCE_POPUP) {
          return this.msal.acquireTokenPopup({ scopes: SCOPES, prompt: 'select_account' });
        }

        return this.msal
          .acquireTokenSilent({ scopes: SCOPES, account: this.msal.getAllAccounts()[0] })
          .catch(() => this.msal.acquireTokenPopup({ scopes: SCOPES, prompt: 'select_account' }));
      })
      .then(({ accessToken }) => {
        return microsoftGraphApi
          .getMySite(accessToken)
          .then((result) => {
            this.launchEditor(
              accessToken,
              result.mySite ? `${rtrim(result.mySite, '/')}/_layouts/15/onedrive.aspx` : null
            );
            return null;
          })
          .catch((error) => {
            // it is expected status 500 if user have personal account
            if (error?.response?.statusCode === HTTP_STATUS_CODE_INTERNAL_SERVER_ERROR) {
              return this.launchEditor(accessToken);
            }

            throw error?.errorMessage || error?.message || error;
          });
      })
      .catch((error) => {
        // user cancelled login process
        if (['consent_required', 'user_cancelled'].includes(error.errorCode)) {
          return;
        }

        throw error?.errorMessage || error?.message || error;
      });
  }

  launchEditor(accessToken, endpointHint) {
    window.OneDrive.open({
      clientId: CLIENT_ID,
      action: 'query',
      viewType: 'files',
      multiSelect: false,
      accountSwitchEnabled: false,
      advanced: {
        accessToken,
        endpointHint: endpointHint || 'api.onedrive.com',
        filter: '.xls,.xlsx'
      },
      success: (files) => this.handleFileSelect(accessToken, files),
      error: (errors) => this.handlePickerError(errors)
    });

    return null;
  }
}

FilePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default FilePicker;
