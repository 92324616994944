import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import RefreshIcon from '../../../../react/common/RefreshIcon';
import Select from '../../../../react/common/Select';

const ApifyObjectSelector = createReactClass({
  propTypes: {
    objectName: PropTypes.string.isRequired,
    objectLabelKey: PropTypes.string.isRequired,
    object: PropTypes.object.isRequired,
    selectedValue: PropTypes.string,
    objectIdKey: PropTypes.string,
    onLoadObjectsList: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired
  },

  getDefaultProps() {
    return {
      objectIdKey: 'id'
    };
  },

  render() {
    const isLoading = this.props.object.get('loading', false);
    const error = this.props.object.get('error');

    return (
      <div className={error ? 'form-group has-error' : 'form-group'}>
        <label className="col-xs-2 control-label">
          {this.capitalizeFirstLetter(this.props.objectName)}
        </label>
        <div className="col-xs-10">
          {isLoading || error ? (
            <FormControl.Static>
              {error} {this.renderRefresh()}
            </FormControl.Static>
          ) : (
            this.renderSelectControl()
          )}
        </div>
      </div>
    );
  },

  renderRefresh() {
    const isLoading = this.props.object.get('loading', false);
    return (
      <span>
        {isLoading ? 'Loading list of ' + this.props.objectName + 's... ' : null}
        <RefreshIcon isLoading={isLoading} onClick={this.props.onLoadObjectsList} />
      </span>
    );
  },

  renderSelectControl() {
    const value = this.props.selectedValue;
    return (
      <InputGroup>
        <Select
          placeholder={'Select ' + this.props.objectName}
          name="ids"
          key="ids"
          clearable={false}
          options={this.getOptions()}
          value={value}
          onChange={(selectedId) => this.props.onSelect(selectedId)}
        />
        <InputGroup.Addon>{this.renderRefresh()}</InputGroup.Addon>
      </InputGroup>
    );
  },

  getOptions() {
    const objectsList = this.props.object.get('data', List()) || List();
    return objectsList
      .sortBy((c) => c.get(this.props.objectLabelKey).toLowerCase())
      .map((c) => {
        return { value: c.get(this.props.objectIdKey), label: c.get(this.props.objectLabelKey) };
      })
      .toArray();
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
});

export default ApifyObjectSelector;
