import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import Check from '../../../../../react/common/Check';
import CreatedDate from '../../../../../react/common/CreatedDate';
import Link from '../../../../../react/common/RouterLink';
import {
  canManageBuckets,
  canManageTokenRestrictions,
  canManageTokens,
  canPurgeTrash
} from '../../../../admin/privileges';
import { routeNames as projectSettingsRouteNames } from '../../../../settings/constants';
import BucketsSelector from './BucketsSelector.jsx';
import ComponentsSelector from './ComponentsSelector';
import ExpiresInEdit from './ExpiresInEdit';

const TokenEditor = createReactClass({
  propTypes: {
    disabled: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
    token: PropTypes.object.isRequired,
    sapiToken: PropTypes.object.isRequired,
    allBuckets: PropTypes.object.isRequired,
    allComponents: PropTypes.object.isRequired,
    updateToken: PropTypes.func.isRequired
  },

  render() {
    const canSetupRestrictions = canManageTokenRestrictions(this.props.sapiToken);
    const isCustomAccess = canSetupRestrictions && !canManageBuckets(this.props.token);
    const isAdminToken = this.props.token.has('admin');

    return (
      <div>
        {this.renderFormGroup(
          'Description',
          <div className="col-sm-9">{this.renderDescriptionInput()}</div>
        )}
        {this.renderFormGroup(
          'Expires In',
          isAdminToken ? (
            <div className="col-sm-9">
              <p className="form-control-static">
                This is a user admin token that is valid as long as the user exists.
              </p>
            </div>
          ) : (
            this.renderCustomExpires()
          )
        )}
        {this.props.isEditing &&
          this.renderFormGroup(
            'Created',
            <div className="col-sm-9">
              <p className="form-control-static">
                <CreatedDate createdTime={this.props.token.get('created')} />
                {this.renderCreatorTokenLink()}
              </p>
            </div>
          )}
        {canSetupRestrictions && this.renderFormGroup('Files', this.renderFileUploadsAccessInput())}
        {canSetupRestrictions &&
          this.renderFormGroup(
            'Components & Buckets',
            this.renderBucketsAndComponentsAccessInput()
          )}
        {isCustomAccess &&
          this.renderFormGroup(
            '',
            <div className="col-sm-offset-3 col-sm-9">
              <ComponentsSelector
                disabled={this.props.disabled}
                onChange={this.updateComponentAccess}
                selectedComponents={this.props.token.get('componentAccess', List())}
                allComponents={this.props.allComponents}
                hasNewQueue={this.props.hasNewQueue}
              />
              <span className="help-block">Token can run selected components</span>
            </div>
          )}
        {isCustomAccess &&
          this.renderFormGroup(
            '',
            <BucketsSelector
              disabled={this.props.disabled}
              bucketPermissions={this.props.token.get('bucketPermissions', Map()).toMap()}
              onChange={(permissions) => this.props.updateToken('bucketPermissions', permissions)}
              allBuckets={this.props.allBuckets}
              permission="read"
              wrapperClassName="cols-sm-offset-3 col-sm-9"
            />
          )}
        {isCustomAccess &&
          this.renderFormGroup(
            '',
            <BucketsSelector
              disabled={this.props.disabled}
              bucketPermissions={this.props.token.get('bucketPermissions', Map()).toMap()}
              onChange={(permissions) => this.props.updateToken('bucketPermissions', permissions)}
              allBuckets={this.props.allBuckets}
              permission="write"
              wrapperClassName="cols-sm-offset-3 col-sm-9"
            />
          )}
        {canSetupRestrictions && this.renderFormGroup('Trash', this.renderTrashAccessInput())}
        {canSetupRestrictions &&
          this.props.isEditing &&
          this.renderFormGroup(
            'Manage Tokens',
            <div className="col-sm-9">
              <p className="form-control-static">
                <Check isChecked={canManageTokens(this.props.token)} />
              </p>
              <p className="help-block">
                Token {canManageTokens(this.props.token) ? 'has' : "hasn't"} permission to manage
                (e.g. create) other tokens.
              </p>
            </div>
          )}
      </div>
    );
  },

  renderCustomExpires() {
    return this.props.isEditing ? (
      <div className="col-sm-9">
        <p className="form-control-static">
          <CreatedDate createdTime={this.props.token.get('expires')} fallback="Never" />
        </p>
      </div>
    ) : (
      <ExpiresInEdit
        disabled={this.props.disabled}
        value={this.props.token.get('expiresIn', null)}
        onChange={(value) => this.props.updateToken('expiresIn', value)}
      />
    );
  },

  renderCreatorTokenLink() {
    const creatorToken = this.props.token.get('creatorToken', null);
    return (
      creatorToken && (
        <span>
          {' '}
          by{' '}
          <Link
            to={projectSettingsRouteNames.TOKEN_DETAIL}
            params={{ tokenId: creatorToken.get('id') }}
          >
            {creatorToken.get('description')}
          </Link>
        </span>
      )
    );
  },

  renderDescriptionInput() {
    return (
      <input
        autoFocus={!this.props.isEditing}
        placeholder="Describe token..."
        disabled={this.props.disabled}
        className="form-control"
        type="text"
        value={this.props.token.get('description') || ''}
        onChange={(e) => this.props.updateToken('description', e.target.value)}
      />
    );
  },

  renderFormGroup(labelComponent, controlComponent) {
    return (
      <div className="form-group">
        <label className="control-label col-sm-3">{labelComponent}</label>
        {controlComponent}
      </div>
    );
  },

  renderFileUploadsAccessInput() {
    const isAdminToken = this.props.token.has('admin');
    const isFullAccess = this.props.token.get('canReadAllFileUploads', false);

    if (isAdminToken) {
      return (
        <div className="col-sm-9">
          <div className="radio">
            <label>
              <span>Full Access</span>
            </label>
            <span className="help-block">Allow access to all files</span>
          </div>
        </div>
      );
    }
    return (
      <div className="col-sm-9">
        <div className="radio">
          <label>
            <input
              disabled={this.props.disabled}
              type="radio"
              checked={isFullAccess}
              onChange={() => this.props.updateToken('canReadAllFileUploads', true)}
            />
            <span>Full Access</span>
          </label>
        </div>
        <span className="help-block">Allow access to all files</span>
        <div className="radio">
          <label>
            <input
              disabled={this.props.disabled}
              type="radio"
              checked={!isFullAccess}
              onChange={() => this.props.updateToken('canReadAllFileUploads', false)}
            />
            <span>Restricted Access</span>
          </label>
        </div>
        <span className="help-block">Only files uploaded by the token are accessible</span>
      </div>
    );
  },

  renderTrashAccessInput() {
    return (
      <div className="col-sm-9">
        <div className="radio">
          <label>
            <input
              disabled={this.props.disabled}
              type="radio"
              checked={canPurgeTrash(this.props.token)}
              onChange={() => this.props.updateToken('canPurgeTrash', true)}
            />
            <span>Full Access</span>
          </label>
        </div>
        <span className="help-block">
          Token can list, restore and permanently delete configurations in trash.
        </span>
        <div className="radio">
          <label>
            <input
              disabled={this.props.disabled}
              type="radio"
              checked={!canPurgeTrash(this.props.token)}
              onChange={() => this.props.updateToken('canPurgeTrash', false)}
            />
            <span>Restricted Access</span>
          </label>
        </div>
        <span className="help-block">Token can only list and restore configurations in trash.</span>
      </div>
    );
  },

  renderBucketsAndComponentsAccessInput() {
    const { isEditing } = this.props;
    const radioLabelStyle = isEditing ? { paddingLeft: '0px', cursor: 'default' } : {};
    const showFull = !isEditing || (isEditing && canManageBuckets(this.props.token));
    const showCustom = !isEditing || (isEditing && !canManageBuckets(this.props.token));
    return (
      <div className="col-sm-9">
        {showFull && (
          <div className="radio">
            <label style={radioLabelStyle}>
              {!isEditing && (
                <input
                  type="radio"
                  disabled={this.props.disabled}
                  checked={this.props.token.get('canManageBuckets')}
                  onChange={() => this.props.updateToken('canManageBuckets', true)}
                />
              )}
              <span>Full Access</span>
            </label>
            <span className="help-block">
              Allow full access to all buckets and components including buckets created in the
              future
            </span>
          </div>
        )}
        {showCustom && (
          <div className="radio">
            <label style={radioLabelStyle}>
              {!isEditing && (
                <input
                  disabled={this.props.disabled}
                  type="radio"
                  checked={!this.props.token.get('canManageBuckets')}
                  onChange={() => this.props.updateToken('canManageBuckets', false)}
                />
              )}
              <span>Custom Access</span>
            </label>
            <span className="help-block">
              Only specified components and buckets will be accessible.
            </span>
          </div>
        )}
      </div>
    );
  },

  updateComponentAccess(components) {
    this.props.updateToken('componentAccess', components);
  }
});

export default TokenEditor;
