import React from 'react';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';
import _ from 'underscore';

import CreatedDate from '../../../react/common/CreatedDate';
import ExternalLink from '../../../react/common/ExternalLink';
import FileSize from '../../../react/common/FileSize';
import RowsCount from '../../../react/common/RowsCount';
import User from '../../../react/common/User';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ErrorContent from '../../../react/pages/ErrorContent';
import ApplicationStore from '../../../stores/ApplicationStore';
import RoutesStore from '../../../stores/RoutesStore';
import { canLinkBucket } from '../../admin/privileges';
import { MetadataKeys } from '../../components/MetadataConstants';
import StorageApiBucketLinkEx from '../../components/react/components/StorageApiBucketLinkEx';
import BucketsStore from '../../components/stores/StorageBucketsStore';
import TablesStore from '../../components/stores/StorageTablesStore';
import StorageDescription from '../../storage/components/StorageDescription';
import { SHARED_TYPES } from '../constants';
import BackendRow from './BackendRow';
import OwnerRow from './OwnerRow';
import SharedBucketTables from './SharedBucketTables';
import UsedBy from './UsedBy';

const MySharedBucketDetail = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, TablesStore, BucketsStore)],

  getStateFromStores() {
    const sharedBucketId = RoutesStore.getCurrentRouteParam('bucketId');
    const sharedBucket = BucketsStore.getBucket(sharedBucketId);

    return {
      admins: ApplicationStore.getAdmins(),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      currentProject: ApplicationStore.getCurrentProject(),
      tables: TablesStore.getAll().filter(
        (table) => table.getIn(['bucket', 'id']) === sharedBucketId
      ),
      sharedBucket,
      sapiToken: ApplicationStore.getSapiToken()
    };
  },

  render() {
    if (!this.state.sharedBucket) {
      return <ErrorContent headerText="Bucket not found" />;
    }

    return (
      <>
        {this.renderOverview()}
        {this.renderDescription()}
        {this.renderTables()}
      </>
    );
  },

  renderOverview() {
    return (
      <div className="box info-row">
        <div className="info-row-section horizontal">
          <BackendRow backend={this.state.sharedBucket.get('backend')} />
          <OwnerRow
            owner={this.state.sharedBucket.get('sharedBy', Map())}
            admins={this.state.admins}
          />
          <div className="first-line font-medium flex-container">
            <span>Shared to</span>
            {this.renderSharedToBox()}
          </div>
          <div className="first-line font-medium flex-container">
            <span>Used by</span>
            <UsedBy
              urlTemplates={this.state.urlTemplates}
              links={this.state.sharedBucket.get('linkedBy', Map())}
            />
          </div>
        </div>
        <div className="info-row-section horizontal">
          <p className="first-line f-14 font-medium flex-container">
            <span>Bucket</span>
            <span className="font-normal">
              <StorageApiBucketLinkEx bucketId={this.state.sharedBucket.get('id')} />
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Stage</span>
            <span className="font-normal text-muted">
              {this.state.sharedBucket.get('stage').toUpperCase()}
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Created at</span>
            <CreatedDate
              className="font-normal text-muted"
              createdTime={this.state.sharedBucket.get('created')}
            />
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Last change</span>
            <CreatedDate
              className="font-normal text-muted"
              createdTime={this.state.sharedBucket.get('lastChangeDate')}
            />
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Row count</span>
            <span className="font-normal text-muted">
              <RowsCount count={this.state.sharedBucket.get('rowsCount')} />
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Data size</span>
            <span className="font-normal text-muted">
              <FileSize size={this.state.sharedBucket.get('dataSizeBytes')} />
            </span>
          </p>
        </div>
      </div>
    );
  },

  renderDescription() {
    return (
      <StorageDescription
        objectType="bucket"
        objectId={this.state.sharedBucket.get('id')}
        metadata={this.state.sharedBucket.get('metadata', List())}
        metadataKey={MetadataKeys.SHARED_DESCRIPTION}
        fallbackMetadataKey={MetadataKeys.DESCRIPTION}
        readOnly={!canLinkBucket(this.state.sapiToken)}
      />
    );
  },

  renderSharedToBox() {
    if (this.state.sharedBucket.get('sharing') === SHARED_TYPES.ORGANIZATION_MEMBER) {
      return <span className="font-normal text-muted">Organization Members</span>;
    }

    if (this.state.sharedBucket.get('sharing') === SHARED_TYPES.PROJECT_MEMBERS) {
      return <span className="font-normal text-muted">Project Members</span>;
    }

    if (this.state.sharedBucket.get('sharing') === SHARED_TYPES.SELECTED_PROJECT) {
      return (
        <span className="font-normal text-muted">
          {this.state.sharedBucket
            .getIn(['sharingParameters', 'projects'])
            .map((project) => {
              return (
                <ExternalLink
                  key={project.get('id')}
                  href={_.template(this.state.urlTemplates.get('project'))({
                    projectId: project.get('id')
                  })}
                >
                  {project.get('name')}
                </ExternalLink>
              );
            })
            .reduce((prev, curr) => [prev, ', ', curr])}
        </span>
      );
    }

    if (this.state.sharedBucket.get('sharing') === SHARED_TYPES.SELECTED_PEOPLE) {
      return (
        <span className="font-normal text-muted text-right">
          {this.state.sharedBucket
            .getIn(['sharingParameters', 'users'])
            .map((user) => {
              return <User key={user.get('id')} user={user} className="mr-2" />;
            })
            .reduce((prev, curr) => [prev, ', ', curr])}
        </span>
      );
    }

    return null;
  },

  renderTables() {
    return <SharedBucketTables tables={this.state.tables} />;
  }
});

export default MySharedBucketDetail;
