import React from 'react';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import _ from 'underscore';

import {
  computeLineInformation,
  type DiffInformation,
  DiffType,
  type LineInformation
} from './helpers';

type Props = {
  oldValue: string;
  newValue: string;
  leftTitle: React.ReactNode;
  rightTitle: React.ReactNode;
};

type State = {
  expandedBlocks: number[];
};

class SplitDiffRenderer extends React.PureComponent<Props> {
  state: State = {
    expandedBlocks: []
  };

  render() {
    return (
      <table className="diff-table">
        <thead>
          <tr>
            <th />
            <th className="diff-content-header">{this.props.leftTitle}</th>
            <th />
            <th className="diff-content-header">{this.props.rightTitle}</th>
          </tr>
        </thead>
        <tbody>{this.renderDiff()}</tbody>
      </table>
    );
  }

  renderDiff() {
    const { lineInformation, diffLines } = computeLineInformation(
      this.props.oldValue,
      this.props.newValue
    );
    const extraLines = 3;
    let skippedLines: number[] = [];

    return lineInformation.map((line: LineInformation, index: number) => {
      const diffBlockStart = diffLines[0];
      const currentPosition = diffBlockStart - index;

      if (currentPosition === -extraLines) {
        skippedLines = [];
        diffLines.shift();
      }
      if (
        line.left.type === DiffType.DEFAULT &&
        (currentPosition > extraLines || _.isUndefined(diffBlockStart)) &&
        !this.state.expandedBlocks.includes(diffBlockStart)
      ) {
        skippedLines.push(index + 1);
        // show skipped line indicator only if there is more than one line to hide
        if (index === lineInformation.length - 1 && skippedLines.length > 1) {
          return this.renderSkippedLineIndicator(
            skippedLines.length,
            diffBlockStart,
            line.left.lineNumber,
            line.right.lineNumber
          );
          // if we are trying to hide the last line, just show it
        } else if (index < lineInformation.length - 1) {
          return null;
        }
      }

      const diffNodes = this.renderDiffLine(line, index);

      if (currentPosition === extraLines && skippedLines.length > 0) {
        const { length } = skippedLines;
        skippedLines = [];
        return (
          <React.Fragment key={index}>
            {this.renderSkippedLineIndicator(
              length,
              diffBlockStart,
              line.left.lineNumber,
              line.right.lineNumber
            )}
            {diffNodes}
          </React.Fragment>
        );
      }

      return diffNodes;
    });
  }

  renderDiffLine({ left, right }: LineInformation, index: number) {
    return (
      <tr key={index} className="diff-line">
        {this.renderLine(left.lineNumber, left.type, left.value)}
        {this.renderLine(right.lineNumber, right.type, right.value)}
      </tr>
    );
  }

  renderLine(
    lineNumber: number,
    type: (typeof DiffType)[keyof typeof DiffType],
    value: string | DiffInformation[]
  ) {
    const content = Array.isArray(value) ? this.renderWordDiff(value) : value;

    return (
      <>
        <td
          className={classnames('diff-gutter', {
            'diff-empty-gutter': !lineNumber,
            'diff-added': type === DiffType.ADDED,
            'diff-removed': type === DiffType.REMOVED,
            'diff-changed': type === DiffType.CHANGED
          })}
        >
          <pre className="diff-line-number">{lineNumber}</pre>
        </td>
        <td
          className={classnames('diff-content', {
            'diff-empty-line': !content,
            'diff-added': type === DiffType.ADDED,
            'diff-removed': type === DiffType.REMOVED,
            'diff-changed': type === DiffType.CHANGED
          })}
        >
          <pre className="diff-content-text">{content}</pre>
        </td>
      </>
    );
  }

  renderSkippedLineIndicator(
    number: number,
    blockNumber: number,
    leftBlockLineNumber: number,
    rightBlockLineNumber: number
  ) {
    return (
      <tr key={`${leftBlockLineNumber}-${rightBlockLineNumber}`} className="diff-code-fold">
        <td />
        <td>
          <Button
            bsStyle="link"
            className="btn-block btn-link-inline"
            onClick={() => {
              this.setState({ expandedBlocks: [...this.state.expandedBlocks, blockNumber] });
            }}
          >
            <pre className="diff-code-fold-content">Expand {number} lines ...</pre>
          </Button>
        </td>
        <td />
        <td />
      </tr>
    );
  }

  renderWordDiff(diffArray: DiffInformation[]) {
    return diffArray.map((wordDiff, index) => {
      if (!_.isString(wordDiff.value)) {
        return null;
      }

      return (
        <span
          key={index}
          className={classnames('diff-word-diff', {
            'diff-word-added': wordDiff.type === DiffType.ADDED,
            'diff-word-removed': wordDiff.type === DiffType.REMOVED
          })}
        >
          {wordDiff.value}
        </span>
      );
    });
  }
}

export default SplitDiffRenderer;
