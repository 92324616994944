import React from 'react';

import RoutesStore from '../../stores/RoutesStore';
import type { WorkspaceData } from './BillingConsumptionInfo';
import { LoadingDashboard, LoadingData } from './BillingConsumptionInfo';
import TelemetryApi from './TelemetryApi';

const GoodDataDashboard = React.lazy(
  () => import(/* webpackChunkName: "gooddata-dashboard" */ './GoodDataDashboard')
);

const OrganizationUsageInfo = () => {
  const [credentials, setCredentials] = React.useState<WorkspaceData | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAvailable, setIsAvailable] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    TelemetryApi.createCredentials({
      project_type: 'organization-usage',
      crmId: RoutesStore.getRouterState().getIn(['location', 'query', 'crmid'])
    })
      .then(setCredentials)
      .catch((e) => {
        if (e?.message?.includes('Organization Usage')) {
          return setIsAvailable(false);
        }
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (!credentials) {
    return <LoadingData isLoading={isLoading} usageBoardIsNotAvailable={!isAvailable} />;
  }

  return (
    <React.Suspense fallback={<LoadingDashboard />}>
      <GoodDataDashboard
        userToken={credentials.userToken}
        workspaceId={credentials.workspaceId}
        dashboardId={credentials.dashboardId}
      />
    </React.Suspense>
  );
};

export default OrganizationUsageInfo;
