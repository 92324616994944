import React from 'react';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import ActivateDeactivateSwitch from '../../../../react/common/ActivateDeactivateSwitch';
import CatchUnsavedChanges from '../../../../react/common/CatchUnsavedChanges';
import SaveButtons from '../../../../react/common/SaveButtons';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import RunComponentButton from '../../../components/react/components/RunComponentButton';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '../../../configurations/ConfigurationRowsStore';
import { supportConfigRows } from '../../helpers';
import { LOADING_COLUMNS_PATH } from '../../storeProvisioning';
import QueryDeleteButton from './QueryDeleteButton';

const QueryActionButtons = function (componentId, actionsProvisioning, storeProvisioning) {
  const actionCreators = actionsProvisioning.createActions(componentId);

  return createReactClass({
    mixins: [
      createStoreMixin(
        RoutesStore,
        ApplicationStore,
        InstalledComponentsStore,
        ConfigurationRowsStore
      )
    ],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const ExDbStore = storeProvisioning.createStore(componentId, configId);
      const queryId = RoutesStore.getCurrentRouteParam('query');
      const query = ExDbStore.getConfigQuery(queryId);
      const migrateOnly = supportConfigRows(componentId) && !ExDbStore.isRowConfiguration();
      const savedOrNewQuery = ExDbStore.getSavedOrNewQuery(queryId);
      const isQueryChanged = !query.get('advancedMode')
        ? !savedOrNewQuery.equals(query.delete('advancedMode'))
        : !savedOrNewQuery.equals(query);

      return {
        configId,
        queryId,
        query,
        isQueryChanged,
        pendingActions: ExDbStore.getQueriesPendingActions().get(queryId, Map()),
        isEditingQuery: ExDbStore.isEditingQuery(queryId),
        isSaving: ExDbStore.isSavingQuery(queryId),
        isValid: ExDbStore.isEditingQueryValid(queryId) && !ExDbStore.queryNameExists(query),
        localState: ExDbStore.getLocalState(),
        readOnly: ApplicationStore.isReadOnly() || migrateOnly,
        isProcessorsChanged: !ExDbStore.getSavedRowProcessors(queryId).equals(
          ExDbStore.getRowProcessors(queryId)
        )
      };
    },

    render() {
      return (
        <>
          {!this.state.readOnly && (
            <>
              <ActivateDeactivateSwitch
                activateTooltip="Enable Query"
                deactivateTooltip="Disable Query"
                isActive={this.state.query.get('enabled')}
                isPending={this.state.pendingActions.get('enabled')}
                onChange={this.handleActiveChange}
                tooltipPlacement="bottom"
                buttonDisabled={this.state.isEditingQuery}
              />
              <QueryDeleteButton
                componentId={componentId}
                query={this.state.query}
                configurationId={this.state.configId}
                isPending={!!this.state.pendingActions.get('deleteQuery')}
                tooltipPlacement="bottom"
                actionsProvisioning={actionsProvisioning}
              />
              <CatchUnsavedChanges
                isDirty={this.state.isQueryChanged || this.state.isProcessorsChanged}
                onSave={this.handleSave}
                isSaveDisabled={!this.state.isValid}
                onDirtyLeave={this.handleReset}
              >
                <SaveButtons
                  isSaving={this.state.localState.getIn(['isSaving', this.state.queryId], false)}
                  isChanged={this.state.isQueryChanged || this.state.isProcessorsChanged}
                  onReset={this.handleReset}
                  onSave={this.handleSave}
                  disabled={
                    this.state.localState.getIn(['isSaving', this.state.queryId], false) ||
                    this.state.localState.getIn(LOADING_COLUMNS_PATH, false) ||
                    !this.state.isValid
                  }
                />
              </CatchUnsavedChanges>
            </>
          )}
          <RunComponentButton
            label="Run"
            buttonBsStyle="success"
            tooltipPlacement="bottom"
            component={componentId}
            runParams={this.runParams}
            config={this.state.configId}
            disabled={this.state.isEditingQuery}
          >
            You are about to run an extraction.
          </RunComponentButton>
        </>
      );
    },

    runParams() {
      return actionCreators.prepareSingleQueryRunData(
        this.state.configId,
        this.state.query,
        'detail'
      );
    },

    handleActiveChange(newValue) {
      actionCreators.changeQueryEnabledState(this.state.configId, this.state.queryId, newValue);
    },

    handleReset() {
      return actionCreators.resetQueryEdit(this.state.configId, this.state.queryId);
    },

    handleSave() {
      return actionCreators.saveQueryEdit(this.state.configId, this.state.queryId);
    }
  });
};

export default QueryActionButtons;
