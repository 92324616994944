import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, MenuItem, SplitButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import ChangeDescriptionModal from './ChangeDescriptionModal';
import Loader from './Loader';
import Tooltip from './Tooltip';

const SaveButtonWithDescription = createReactClass({
  propTypes: {
    isSaving: PropTypes.bool.isRequired,
    isChanged: PropTypes.bool.isRequired,
    onReset: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    onDescriptionChange: PropTypes.func.isRequired,
    changeDescription: PropTypes.string,
    disabledReason: PropTypes.string
  },

  getInitialState() {
    return {
      openModal: false
    };
  },

  render() {
    return (
      <>
        {this.props.isChanged && (
          <ButtonToolbar>
            <Button onClick={this.props.onReset} disabled={this.props.isSaving}>
              <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
              Reset
            </Button>
            {this.renderSaveButton()}
          </ButtonToolbar>
        )}
        <ChangeDescriptionModal
          show={this.state.openModal}
          value={this.props.changeDescription}
          isSaving={this.props.isSaving}
          onChange={this.props.onDescriptionChange}
          onSubmit={this.handleSubmit}
          onHide={this.closeModal}
        />
      </>
    );
  },

  renderSaveButton() {
    if (this.props.disabled && this.props.disabledReason) {
      return (
        <Tooltip placement="bottom" tooltip={this.props.disabledReason} type="explanatory">
          <Button bsStyle="success" className="save-button btn-disabled">
            {this.props.isSaving ? <Loader /> : 'Save'}
          </Button>
        </Tooltip>
      );
    }

    return (
      <SplitButton
        id="save-button-with-description"
        className="save-button"
        bsStyle="success"
        disabled={this.props.disabled || this.props.isSaving}
        title={this.props.isSaving ? <Loader /> : 'Save'}
        onClick={this.props.onSave}
        pullRight
      >
        <MenuItem onSelect={this.openModal}>Save with description</MenuItem>
      </SplitButton>
    );
  },

  handleSubmit(e) {
    e.preventDefault();
    this.closeModal();
    this.props.onSave();
  },

  openModal() {
    this.setState({ openModal: true });
  },

  closeModal() {
    this.setState({ openModal: false });
  }
});

export default SaveButtonWithDescription;
