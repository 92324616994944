import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, FormGroup, Modal, Nav, NavItem, Tab } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import Switch from 'rc-switch';

import { EXCLUDE_FROM_NEW_LIST, EXCLUDE_RUN } from '../../../constants/componentFlags';
import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import { componentTypes } from '../../../constants/componentTypes';
import keyCodes from '../../../constants/keyCodes';
import FastFade from '../../../react/common/FastFade';
import SearchBar from '../../../react/common/SearchBar';
import Select from '../../../react/common/Select';
import { getNewComponentTypeLabel } from '../../components/helpers';
import { getComponentsFiltered, sortComponents } from '../../components-directory/helpers';
import { componentsUnavailableInTrial } from '../../snowflake-partner-connect/constants';
import AddTaskComponentRow from './AddTaskComponentRow';

const INITIAL_STATE = {
  query: '',
  componentType: '',
  showExistingFirst: true,
  showAll: false,
  selectedTab: 'components',
  openStyles: null
};

const MAXIMUM_INITIAL_COMPONENTS = 5;

const VALID_TYPES = Object.values(componentTypes);

const COMPONENT_TYPES_IN_FILTER = [
  componentTypes.EXTRACTOR,
  componentTypes.WRITER,
  componentTypes.APPLICATION
];

const getComponents = memoizeOne(
  (components, configurations, query, componentType, hasSnowflakePartnerConnectLimited) => {
    const allowedComponents = components.filter((component, componentId) => {
      const flags = component.get('flags', List());

      if (!VALID_TYPES.includes(component.get('type')) || flags.includes(EXCLUDE_RUN)) {
        return false;
      }

      if (configurations.has(componentId)) {
        return true;
      }

      return !flags.includes(EXCLUDE_FROM_NEW_LIST);
    });

    let filteredComponents = getComponentsFiltered(
      allowedComponents,
      query,
      null,
      componentType ? List([componentType]) : List(COMPONENT_TYPES_IN_FILTER),
      ['name']
    );

    if (hasSnowflakePartnerConnectLimited) {
      filteredComponents = filteredComponents.filter(
        (component) => !componentsUnavailableInTrial.includes(component.get('id'))
      );
    }

    return filteredComponents;
  }
);

class AddTaskModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    components: PropTypes.instanceOf(Map).isRequired,
    configurations: PropTypes.instanceOf(Map).isRequired,
    hasSnowflakePartnerConnectLimited: PropTypes.bool.isRequired,
    hasDataApps: PropTypes.bool.isRequired,
    position: PropTypes.string
  };

  state = INITIAL_STATE;
  tooltipTimeouts = Map();

  componentDidUpdate(prevProps) {
    if (prevProps.show && !this.props.show) {
      this.setState(INITIAL_STATE);
    }
  }

  checkPressEscape = (e) => {
    if (this.props.show && e.key === keyCodes.ESCAPE) {
      this.props.onHide();
    }
  };

  componentDidMount() {
    window.addEventListener('keyup', this.checkPressEscape);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.checkPressEscape);
  }

  render() {
    if (!this.props.show || !this.props.position) {
      return null;
    }

    return ReactDOM.createPortal(this.renderAddNewTaskDialog(), this.getRootElement());
  }

  renderAddNewTaskDialog() {
    return (
      <FastFade in appear onEntering={this.calculateOpenStyles}>
        <Modal.Dialog bsClass="add-new-task" style={this.state.openStyles}>
          <Modal.Header className="no-border">
            <Modal.Title>Select component</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            {this.renderSearch()}
            <Tab.Container
              id="add-task-modal-tabs"
              activeKey={this.state.selectedTab}
              onSelect={(tab) => this.setState({ selectedTab: tab, showAll: false })}
            >
              <>
                <Nav bsStyle="tabs" role="navigation">
                  <NavItem eventKey="components">Components</NavItem>
                  {!this.props.hasSnowflakePartnerConnectLimited && (
                    <NavItem eventKey="transformations">Transformations</NavItem>
                  )}
                  <NavItem eventKey="flows">Flows</NavItem>
                  {this.props.hasDataApps && <NavItem eventKey="data-apps">Data Apps</NavItem>}
                </Nav>
                <Tab.Content animation={false}>
                  <Tab.Pane eventKey="components">
                    {this.renderCategorySelect()}
                    {this.renderFilterSwitch()}
                    {this.renderComponents()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="transformations">{this.renderComponents()}</Tab.Pane>
                  <Tab.Pane eventKey="flows">
                    {this.renderComponents(KEBOOLA_ORCHESTRATOR)}
                  </Tab.Pane>
                  {this.props.hasDataApps && (
                    <Tab.Pane eventKey="data-apps">
                      {this.renderComponents(KEBOOLA_DATA_APPS)}
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </>
            </Tab.Container>
          </Modal.Body>
        </Modal.Dialog>
      </FastFade>
    );
  }

  renderSearch = () => {
    return (
      <FormGroup>
        <SearchBar
          bordered
          placeholder="Search"
          query={this.state.query}
          onChange={(query) => this.setState({ query })}
        />
      </FormGroup>
    );
  };

  renderCategorySelect = () => {
    return (
      <FormGroup>
        <Select
          clearable={false}
          value={this.state.componentType}
          onChange={(type) => this.setState({ componentType: type })}
          options={[
            { label: 'All components', value: '' },
            ...COMPONENT_TYPES_IN_FILTER.map((type) => ({
              value: type,
              label: getNewComponentTypeLabel(type)
            }))
          ]}
        />
      </FormGroup>
    );
  };

  renderFilterSwitch = () => {
    return (
      <FormGroup className="flex-container flex-start">
        <Switch
          prefixCls="switch"
          className="icon-addon-right"
          checked={this.state.showExistingFirst}
          onChange={(showExistingFirst) => this.setState({ showExistingFirst })}
        />
        <ControlLabel
          className="clickable text-muted f-13 mb-0"
          onClick={() => this.setState({ showExistingFirst: !this.state.showExistingFirst })}
        >
          Show with existing configurations first
        </ControlLabel>
      </FormGroup>
    );
  };

  renderComponents = (forceComponent) => {
    const componentType =
      this.state.selectedTab === 'transformations'
        ? componentTypes.TRANSFORMATION
        : this.state.componentType;
    const components = forceComponent
      ? getComponentsFiltered(
          Map({ [forceComponent]: this.props.components.get(forceComponent) }),
          this.state.query,
          null,
          null,
          ['name']
        )
      : getComponents(
          this.props.components,
          this.props.configurations,
          this.state.query,
          componentType,
          this.props.hasSnowflakePartnerConnectLimited
        );
    const showShowAllButton =
      !this.state.showAll && components.count() > MAXIMUM_INITIAL_COMPONENTS;

    return (
      <div className="component-list">
        {this.renderComponentsRows(components)}
        {showShowAllButton && (
          <Button block bsStyle="link" onClick={() => this.setState({ showAll: true })}>
            Show all components
          </Button>
        )}
      </div>
    );
  };

  renderComponentsRows = (components) => {
    if (components.isEmpty()) {
      return 'No components found';
    }

    let sortedComponents = sortComponents(components);

    if (this.state.showExistingFirst) {
      sortedComponents = sortedComponents.sort((componentA, componentB) => {
        const hasAconfigs = this.props.configurations.has(componentA.get('id'));
        const hasBconfigs = this.props.configurations.has(componentB.get('id'));

        if (hasAconfigs && !hasBconfigs) {
          return -1;
        }

        if (!hasAconfigs && hasBconfigs) {
          return 1;
        }

        return 0;
      });
    }

    if (!this.state.showAll) {
      sortedComponents = sortedComponents.slice(0, MAXIMUM_INITIAL_COMPONENTS);
    }

    return sortedComponents
      .map((component) => {
        const configurations = this.props.configurations
          .getIn([component.get('id'), 'configurations'], Map())
          .count();

        return (
          <AddTaskComponentRow
            key={component.get('id')}
            component={component}
            configurations={configurations}
            onSelect={this.props.onSelect}
            query={this.state.query}
          />
        );
      })
      .toArray();
  };

  calculateOpenStyles = (el) => {
    if (!this.props.position) {
      return;
    }

    const builder = document.querySelector('.flow-builder');

    let styles = {
      top: '0px',
      left: '110%'
    };

    const modalRect = el.getBoundingClientRect();

    if (modalRect.left + modalRect.width > builder.clientWidth) {
      styles = {
        ...styles,
        transformOrigin: 'top right',
        left: 'initial',
        right: '110%'
      };
    }

    const containerRect = document.querySelector('.flow-graph-container').getBoundingClientRect();
    const bottomOffset = modalRect.bottom - containerRect.bottom;
    const topOffset = modalRect.height - (modalRect.top - containerRect.top);

    if (bottomOffset > 0 && bottomOffset > topOffset) {
      styles = {
        ...styles,
        top: 'initial',
        bottom: '-20px',
        transformOrigin: `bottom ${styles?.right ? 'right' : 'left'}`
      };
    }

    this.setState({ openStyles: styles });
  };

  getRootElement = () => {
    if (this.props.position === '[[end]]') {
      return document.querySelector('.flow-builder--group.is-last .add-task-inline-container');
    }

    if (this.props.position.includes(':')) {
      const phaseId = this.props.position.split(':')[1];

      return document.querySelector(
        `.flow-builder--group[data-name^="${phaseId}-"] .between-phases-action .add-task-inline`
      ).parentElement;
    }

    return document.querySelector(
      `.flow-builder--group[data-name^="${this.props.position}-"] .phase-actions .add-task-inline`
    ).parentElement;
  };
}

export default AddTaskModal;
