import ApplicationStore from '../stores/ApplicationStore';

export default function getDefaultBucket(stage, componentId, configId) {
  const bucketName = `${componentId.replace(/[^a-zA-Z0-9-]/gi, '-')}-${configId}`;

  if (!ApplicationStore.hasDisableLegacyBucketPrefix()) {
    return `${stage}.c-${bucketName}`;
  }

  return `${stage}.${bucketName}`;
}
