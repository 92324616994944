import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '../../constants/componentIds';
import InstalledComponentsActionCreators from '../../modules/components/InstalledComponentsActionCreators';
import { deleteFlow } from '../../modules/flows/actions';
import { isObsoleteComponent } from '../../modules/trash/utils';
import { HTTP_STATUS_CODE_NOT_FOUND } from '../../utils/errors/helpers';
import { getRealComponentId } from './ConfigurationsTable/helpers';
import Confirm from './Confirm';
import ConfirmMenuItem from './ConfirmMenuItem';
import FlowsValidationBox, {
  disableTasksWithDeletedConfigurations,
  isUsedInFlow
} from './FlowsValidationBox';
import Loader from './Loader';

export const usedFlows = (
  flows: Map<string, any>,
  componentId: string,
  config: Map<string, any>
) => {
  return flows
    .filter((flow: Map<string, any>) => isUsedInFlow(flow, componentId, config.get('id')))
    .toArray();
};

export const updateFlowTasks = (
  flow: Map<string, any>,
  componentId: string,
  config: Map<string, any>
) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_ORCHESTRATOR,
    flow.get('id'),
    {
      configuration: JSON.stringify(
        disableTasksWithDeletedConfigurations(flow, componentId, config.get('id')).toJS()
      )
    },
    `Disabled tasks of deleted configuration`
  ).catch((error) => {
    if (error.response?.status !== HTTP_STATUS_CODE_NOT_FOUND) {
      throw error;
    }
  });
};

const DeleteConfigurationButton = (props: {
  componentId: string;
  config: Map<string, any>;
  flows: Map<string, any>;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  mode?: 'navigation' | 'menuitem' | 'sidebar';
  forceTransition?: boolean;
}) => {
  const [isDeleting, setDeleting] = React.useState(false);
  const componentId = getRealComponentId(props.config, Map({ id: props.componentId }));
  const isFlow = componentId === KEBOOLA_ORCHESTRATOR;

  const handleConfirm = () => {
    const options = {
      notification: true,
      transition: props.forceTransition || props.mode !== 'menuitem'
    };

    setDeleting(true);
    return Promise.resolve()
      .then(() => {
        if (isFlow) {
          return deleteFlow(props.config, options);
        }

        return Promise.each(
          usedFlows(props.flows, componentId, props.config),
          (flow: Map<string, any>) => {
            return updateFlowTasks(flow, componentId, props.config);
          }
        ).then(() => {
          return InstalledComponentsActionCreators.deleteConfiguration(
            componentId,
            props.config.get('id'),
            options
          );
        });
      })
      .finally(() => setDeleting(false));
  };

  const label = isFlow ? 'Delete Flow' : 'Delete Configuration';

  const renderBody = () => (
    <>
      <p>Are you sure you want to delete the {isFlow ? 'flow' : 'configuration'}?</p>
      {isObsoleteComponent(componentId) && (
        <p>
          <FontAwesomeIcon icon="triangle-exclamation" className="icon-addon-right" />
          This configuration can&apos;t be restored.
        </p>
      )}
      <FlowsValidationBox
        flows={props.flows}
        configurations={[{ componentId, config: props.config }]}
      />
    </>
  );

  const renderIcon = () => {
    if (isDeleting) {
      return <Loader />;
    }

    return <FontAwesomeIcon icon="trash" fixedWidth />;
  };

  if (props.mode === 'menuitem') {
    return (
      <ConfirmMenuItem
        icon="trash"
        buttonLabel="Delete"
        title={label}
        text={renderBody()}
        onConfirm={handleConfirm}
        isDisabled={isDeleting}
        onKeyDown={props.onKeyDown}
        closeAfterResolve
      >
        {renderIcon()}
        {label}
      </ConfirmMenuItem>
    );
  }

  return (
    <Confirm
      icon="trash"
      buttonLabel="Delete"
      title={label}
      text={renderBody()}
      onConfirm={handleConfirm}
      isDisabled={isDeleting}
    >
      {props.mode === 'navigation' ? (
        <Button bsStyle="link" className="text-muted p-1">
          {renderIcon()}
        </Button>
      ) : (
        <Button bsStyle="link" className="btn-block">
          {renderIcon()}
          {label}
        </Button>
      )}
    </Confirm>
  );
};

export default DeleteConfigurationButton;
