import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import { NEW_ENTITY_BUTTON } from '../../../constants/external';
import createStoreMixin from '../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../stores/ApplicationStore';
import FilesStore from '../../components/stores/StorageFilesStore';
import { loadFiles } from '../actions';
import FilesLocalStore from '../FilesLocalStore';
import UploadModal from './UploadModal';

const UploadFileButton = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, FilesStore, FilesLocalStore)],

  getStateFromStores() {
    return {
      readOnly: ApplicationStore.isReadOnly(),
      uploadingProgress: FilesStore.getAllUploadingProgress(),
      searchQuery: FilesLocalStore.getSearchQuery()
    };
  },

  getInitialState() {
    return {
      openUploadModal: false
    };
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <>
        <Button
          bsStyle="success"
          onClick={() => this.setState({ openUploadModal: true })}
          className={NEW_ENTITY_BUTTON}
        >
          <FontAwesomeIcon icon="circle-arrow-up" className="icon-addon-right" />
          Upload Files
        </Button>
        <UploadModal
          show={this.state.openUploadModal}
          uploadingProgress={this.state.uploadingProgress}
          onUpload={this.reloadFiles}
          onHide={() => this.setState({ openUploadModal: false })}
        />
      </>
    );
  },

  reloadFiles() {
    return loadFiles({ offset: 0, ...(this.state.searchQuery && { q: this.state.searchQuery }) });
  }
});

export default UploadFileButton;
