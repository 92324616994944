import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import { capitalize, strRight, trim } from 'underscore.string';

import dayjs from '../../date';
import { getFakeComponentId } from '../../react/common/ConfigurationsTable/helpers';
import ApplicationStore from '../../stores/ApplicationStore';
import fromJSOrdered from '../../utils/fromJSOrdered';
import matchByWords from '../../utils/matchByWords';
import tableIdParser from '../../utils/tableIdParser';
import { ioType } from '../components/Constants';
import { getDestinationTypeFromStagingStorage } from '../components/helpers';
import { MetadataKeys } from '../components/MetadataConstants';
import { findLength, findNullable } from '../components/utils/columnMetadataHelper';
import {
  getLastActiveDatatypeProvider,
  getTableColumnMetadata
} from '../components/utils/tableMetadataHelper';
import { resolveComponentIdFromSandboxType } from '../sandboxes/helpers';
import {
  backends,
  dataPreviewDataType,
  FILTERS,
  FILTERS_GROUP,
  nameMaxLength,
  nameWarning,
  STAGE
} from './constants';

const getFilteredData = memoizeOne((data, query, searchFilters, exclude) => {
  const stageFilter = searchFilters?.[FILTERS_GROUP.STAGE];

  if (!exclude && searchFilters) {
    exclude =
      searchFilters[FILTERS_GROUP.ENTITY] === FILTERS.BUCKETS
        ? ['table', 'column']
        : searchFilters[FILTERS_GROUP.ENTITY] === FILTERS.TABLES
        ? ['bucket', 'column']
        : ['column'];
  }

  return data
    .filter((bucket) => {
      if (stageFilter && Object.values(STAGE).includes(stageFilter)) {
        return stageFilter === bucket.get('stage');
      }

      return true;
    })
    .reduce((results, bucket) => {
      if (
        !exclude.includes('bucket') &&
        (matchByWords(bucket.get('displayName'), query) ||
          bucket.get('id').toLowerCase() === query.trim().toLowerCase())
      ) {
        bucket = bucket.set('matches', true).set('exactMatch', bucket.get('displayName') === query);
      }

      bucket = bucket.set(
        'bucketTables',
        bucket.get('bucketTables').reduce((tables, table) => {
          if (
            !exclude.includes('table') &&
            (matchByWords(table.get('displayName'), query) ||
              table.get('id').toLowerCase() === query.trim().toLowerCase())
          ) {
            table = table
              .set('matches', true)
              .set('exactMatch', table.get('displayName') === query);
          }

          if (exclude.includes('column') && !table.has('matches')) {
            return tables;
          }

          table = table.update('columns', List(), (columns) =>
            columns.filter((columnName) => matchByWords(columnName, query))
          );

          if (table.get('matches') || !table.get('columns').isEmpty()) {
            return tables.push(table);
          }

          return tables;
        }, List())
      );

      if (bucket.get('matches') || !bucket.get('bucketTables').isEmpty()) {
        return results.push(bucket);
      }

      return results;
    }, List());
});

const isBucketNameAlreadyUsed = (
  buckets,
  name,
  stage,
  currentDevBranchId = null,
  options = { strict: true }
) => {
  const prefix = ApplicationStore.hasDisableLegacyBucketPrefix() ? '' : 'c-';
  const bucketName = ApplicationStore.hasProtectedDefaultBranch()
    ? name
    : prefixBucketNameWithCurrentBranchId(name, currentDevBranchId);

  return buckets.some((bucket) => {
    if (bucket.get('stage') !== stage) {
      return false;
    }

    return (
      bucket.get('displayName', '').toLowerCase() === bucketName.toLowerCase() ||
      (options.strict &&
        bucket.get('name', '').toLowerCase() === `${prefix}${bucketName.toLowerCase()}`)
    );
  });
};

const prefixBucketNameWithCurrentBranchId = (name, branchId = null) => {
  if (branchId) {
    const branchIdPrefix = `${branchId}-`;

    return `${branchIdPrefix}${strRight(name, branchIdPrefix)}`;
  }

  return name;
};

const fileExpirationStatus = (file) => {
  const maxAgeDays = file.get('maxAgeDays', null);

  if (maxAgeDays === null) {
    return { permanent: true, expired: false, text: 'Permanent' };
  }

  const now = dayjs();
  const expiresOn = dayjs(file.get('created')).add(maxAgeDays, 'days');
  const diffDays = expiresOn.diff(now, 'days');

  if (diffDays > 0) {
    return { permanent: false, expired: false, text: `Expires in ${diffDays} days` };
  }

  const diffMinutes = expiresOn.diff(now, 'minutes');

  if (diffMinutes > 0) {
    return { permanent: false, expired: false, text: `Expires in ${diffMinutes} minutes` };
  }

  return { permanent: false, expired: true, text: 'Expired' };
};

const isTableNameAlreadyUsed = (tables, name, options = { strict: true }) => {
  return tables.some((table) => {
    return (
      table.get('displayName', '').toLowerCase() === name.toLowerCase() ||
      (options.strict && table.get('name', '').toLowerCase() === name.toLowerCase())
    );
  });
};

const validateTableName = (name, tables = Map(), options) => {
  if (!name || name.length === 0) {
    return `Empty string is not allowed`;
  }

  if (name.length > nameMaxLength) {
    return `The maximum allowed table name length is ${nameMaxLength} characters.`;
  }

  if (name.indexOf('_') === 0) {
    return 'Table name cannot start with an underscore.';
  }

  if (!/^[a-zA-Z0-9_-]*$/.test(name)) {
    return nameWarning;
  }

  if (isTableNameAlreadyUsed(tables, name, options)) {
    return `The table "${name}" already exists.`;
  }

  return null;
};

const validateBucketName = (name, stage, allOtherBuckets, currentDevBranchId = null, options) => {
  if (!name || name.length === 0) {
    return `Empty string is not allowed`;
  }

  if (name.length > nameMaxLength) {
    return `The maximum allowed bucket name length is ${nameMaxLength} characters.`;
  }

  if (name.indexOf('_') === 0) {
    return 'Bucket name cannot start with an underscore.';
  }

  if (!/^[a-zA-Z0-9_-]*$/.test(name)) {
    return nameWarning;
  }

  if (isBucketNameAlreadyUsed(allOtherBuckets, name, stage, currentDevBranchId, options)) {
    return `The bucket "${name}" already used in the ${stage.toUpperCase()} stage.`;
  }

  return null;
};

const bucketDisplayNameWithStage = (bucket) => {
  return `${bucket.get('stage')} ${bucket.get('displayName')}`;
};

const tableName = (table) => {
  return `${table.getIn(['bucket', 'displayName'])} / ${table.get('displayName')}`;
};

const tableDisplayNameWithBucketAndStage = (table) => {
  return [
    table.getIn(['bucket', 'stage']),
    table.getIn(['bucket', 'displayName']),
    table.get('displayName')
  ].join(' ');
};

const getBucketDisplayNameFromName = (bucketName) => {
  return ApplicationStore.hasDisableLegacyBucketPrefix() ? bucketName : strRight(bucketName, 'c-');
};

const tableNameParsed = (tableId, storageBucket = Map()) => {
  const { stage, bucket, table } = tableIdParser.parse(tableId).parts;
  const bucketName = storageBucket.get('displayName', getBucketDisplayNameFromName(bucket));

  if (stage && bucketName && table) {
    return `${bucketName} / ${table}`;
  }

  return tableId;
};

const getTableAliases = (currentTable, tables, sapiToken) => {
  return tables
    .filter(
      (table) =>
        !table.getIn(['bucket', 'sourceBucket']) &&
        table.get('isAlias') &&
        table.getIn(['sourceTable', 'id']) === currentTable.get('id') &&
        sapiToken.getIn(['owner', 'id']) === table.getIn(['sourceTable', 'project', 'id'])
    )
    .toArray();
};

const getTableLinks = (table, bucket) => {
  if (table.get('isAlias') || !bucket.get('linkedBy')) {
    return [];
  }

  return bucket
    .get('linkedBy', List())
    .map((linkedByBucket) => linkedByBucket.merge({ table }))
    .toArray();
};

const prepareValues = (filter, operator) => {
  return strRight(filter.trim(), operator)
    .split(',')
    .map((value) => value.trim())
    .filter(Boolean);
};

const prepareWhereFilters = (filters, backend) => {
  const dataType = dataPreviewDataType[backend];

  return filters
    .map((filter, column) => {
      if (filter.trim().startsWith('"') && filter.trim().endsWith('"')) {
        return { column, values: [trim(filter, ' "')], operator: 'eq' };
      }

      let operator = filter.trim().substring(0, 2);
      let values = prepareValues(filter, operator);
      let allNumeric = values.every((value) => !isNaN(parseFloat(value)) && !isNaN(value - 0));

      switch (operator) {
        case '>=':
          return { column, values, operator: 'ge', ...(allNumeric && { dataType }) };

        case '=>':
          return { column, values, operator: 'ge', ...(allNumeric && { dataType }) };

        case '<=':
          return { column, values, operator: 'le', ...(allNumeric && { dataType }) };

        case '=<':
          return { column, values, operator: 'le', ...(allNumeric && { dataType }) };

        case '!=':
          return { column, values, operator: 'ne' };

        default:
          break;
      }

      operator = filter.trim().substring(0, 1);
      values = prepareValues(filter, operator);
      allNumeric = values.every((value) => !isNaN(parseFloat(value)) && !isNaN(value - 0));

      switch (operator) {
        case '>':
          return { column, values, operator: 'gt', ...(allNumeric && { dataType }) };

        case '<':
          return { column, values, operator: 'lt', ...(allNumeric && { dataType }) };

        case '=':
          return { column, values, operator: 'eq' };

        default:
          return { column, values: prepareValues(filter), operator: 'eq' };
      }
    })
    .toList()
    .toJS();
};

const prepareOrderBy = (sortedColumns, columnOrder, table, backend) => {
  const metadata = getTableColumnMetadata(table);

  return sortedColumns
    .sortBy((orderBy, column) => columnOrder.indexOf(column))
    .map((orderBy, column) => {
      return {
        column,
        order: orderBy.toUpperCase(),
        ...(!table.get('isTyped') &&
          hasNumberLikeBasetype(findBasetypeDatatype(metadata.get(column, List()))) && {
            dataType: dataPreviewDataType[backend]
          })
      };
    })
    .toList()
    .toJS();
};

const hasNumberLikeBasetype = (metadata) => {
  return (
    metadata && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(metadata.get('value', '').toUpperCase())
  );
};

const isColumnUsedInAliasColumns = (column, tables, tableId, sapiToken) => {
  return tables.some((table) => {
    return (
      table.get('isAlias') &&
      !table.get('aliasColumnsAutoSync') &&
      table.getIn(['sourceTable', 'id']) === tableId &&
      table.get('columns').some((columnName) => columnName === column) &&
      sapiToken.getIn(['owner', 'id']) === table.getIn(['sourceTable', 'project', 'id'])
    );
  });
};

const isColumnUsedInAliasFilter = (column, tables, tableId, sapiToken) => {
  return tables.some((table) => {
    return (
      table.get('isAlias') &&
      table.getIn(['sourceTable', 'id']) === tableId &&
      table.getIn(['aliasFilter', 'column']) === column &&
      sapiToken.getIn(['owner', 'id']) === table.getIn(['sourceTable', 'project', 'id'])
    );
  });
};

const findStorageTypeDatatype = (metadata) => {
  return metadata.find(
    (item) => item.get('key') === MetadataKeys.TYPE && item.get('provider') === 'storage'
  );
};

const findBasetypeDatatype = (metadata) => {
  return metadata.find(
    (item) => item.get('key') === MetadataKeys.BASE_TYPE && item.get('provider') === 'user',
    null,
    metadata
      .filter((item) => {
        return (
          item.get('key') === MetadataKeys.BASE_TYPE &&
          item.get('provider') === getLastActiveDatatypeProvider(metadata, { exclude: ['user'] })
        );
      })
      .sortBy((item) => -1 * new Date(item.get('timestamp')).getTime())
      .first()
  );
};

const findLengthDatatypeValue = (metadata, provider) => {
  return findLength(metadata.filter((item) => item.get('provider') === provider)).get('value');
};

const findNullableDatatypeValue = (metadata, provider) => {
  return findNullable(metadata.filter((item) => item.get('provider') === provider)).get('value');
};

const prepareTablesMetadataMap = memoizeOne(
  (
    tables,
    includeConfigurationId = true,
    evaluateSampleDataComponent = false,
    allConfigurations
  ) => {
    return tables.groupBy((table) => {
      const componentId = table
        .get('metadata', List())
        .find((row) => row.get('key') === MetadataKeys.LAST_UPDATED_BY_COMPONENT_ID, null, Map())
        .get('value');

      if (!includeConfigurationId && !evaluateSampleDataComponent) return componentId;

      const configId = table
        .get('metadata', List())
        .find(
          (row) => row.get('key') === MetadataKeys.LAST_UPDATED_BY_CONFIGURATION_ID,
          null,
          Map()
        )
        .get('value');

      if (!includeConfigurationId && evaluateSampleDataComponent) {
        return (
          getFakeComponentId(
            allConfigurations.getIn(
              [componentId, 'configurations', configId, 'configuration'],
              Map()
            )
          ) || componentId
        );
      }

      return `${componentId}:${configId}`;
    });
  }
);

const prepareMappingFromSelectedBucketsAndTables = (
  selected,
  allTables,
  components,
  sandboxType
) => {
  const componentId = resolveComponentIdFromSandboxType(sandboxType);
  const destinationType = getDestinationTypeFromStagingStorage(
    components.getIn([componentId, 'data', 'staging_storage', 'input'])
  );

  const prepareStorageTable = (table) => {
    return fromJSOrdered({
      source: table.get('id'),
      destination: `${table.get('displayName', '')}${
        destinationType === ioType.FILE ? '.csv' : ''
      }`,
      where_column: '',
      where_values: [],
      where_operator: 'eq',
      columns: []
    });
  };

  const tables = Map()
    .withMutations((tables) => {
      selected.forEach((row) => {
        if (row.has('stage')) {
          allTables
            .filter((table) => table.getIn(['bucket', 'id']) === row.get('id'))
            .forEach((table) => tables.set(table.get('id'), prepareStorageTable(table)));
        } else {
          tables.set(row.get('id'), prepareStorageTable(row));
        }
      });
    })
    .toList();

  return Map().setIn(['input', 'tables'], tables);
};

/*
  This helper relies on fact that we always loading "columnMetadata"
  when we need working with tables, so we know that if we do not have
  "columnMetadata" in table we are in loading phase
*/
const isLoadingColumnMetadata = (tables) => {
  if (tables.isEmpty()) {
    return false;
  }

  return !tables.some((table) => table.has('columnMetadata'));
};

const getDescriptionValue = (metadata = List(), key = MetadataKeys.DESCRIPTION) => {
  const descriptionMetadata = metadata.filter((row) => row.get('key') === key);

  const userComment = descriptionMetadata.find((row) => row.get('provider') === 'user');
  const componentComment = descriptionMetadata
    .filter((row) => row.get('provider') !== 'user')
    .sortBy((row) => -1 * new Date(row.get('timestamp')).getTime())
    .first();

  // if have only component comment, use it
  if (!userComment && componentComment) {
    return componentComment.get('value', '');
  }

  // if have both, always prefer user comment if it is not empty or is newer then component comment
  if (userComment?.has('value') && componentComment?.has('value')) {
    return !!userComment.get('value') ||
      userComment.get('timestamp') > componentComment.get('timestamp')
      ? userComment.get('value', '')
      : componentComment.get('value', '');
  }

  // otherwise use user comment
  return userComment?.get('value') || '';
};

const sortByDisplayName = (entity) => entity.get('displayName').toLowerCase();

const sortByExactMatch = (entity) => {
  return entity.get('exactMatch') ||
    entity.get('bucketTables', List()).some((table) => table.get('exactMatch'))
    ? -1
    : 0;
};

const parseBigQueryDatasetUrl = (url) => {
  const re = /projects\/([^/]+)\/locations\/([^/]+)\/dataExchanges\/([^/]+)\/listings\/([^/?#]+)/;
  const [, projectId, location, exchangerName, listingName] = url.match(re) || [];
  return [projectId, location, exchangerName, listingName].filter(Boolean);
};

const prepareBackendLabel = (backend) => {
  switch (backend) {
    case backends.BIGQUERY:
      return 'BigQuery';

    default:
      return capitalize(backend);
  }
};

export {
  getFilteredData,
  sortByDisplayName,
  sortByExactMatch,
  isBucketNameAlreadyUsed,
  prefixBucketNameWithCurrentBranchId,
  fileExpirationStatus,
  isTableNameAlreadyUsed,
  validateTableName,
  validateBucketName,
  tableName,
  tableNameParsed,
  getBucketDisplayNameFromName,
  bucketDisplayNameWithStage,
  tableDisplayNameWithBucketAndStage,
  getTableAliases,
  getTableLinks,
  prepareWhereFilters,
  prepareOrderBy,
  isColumnUsedInAliasColumns,
  isColumnUsedInAliasFilter,
  prepareTablesMetadataMap,
  findBasetypeDatatype,
  findStorageTypeDatatype,
  findLengthDatatypeValue,
  findNullableDatatypeValue,
  prepareMappingFromSelectedBucketsAndTables,
  isLoadingColumnMetadata,
  getDescriptionValue,
  parseBigQueryDatasetUrl,
  prepareBackendLabel
};
