import React from 'react';

import RoutePendingStore from '../../stores/RoutePendingStore';
import Loader from '../common/Loader';
import useStores from '../hooks/useStores';

const RoutePendingIndicator = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { isLoading } = useStores(
    () => {
      return {
        isLoading: RoutePendingStore.isPending() || RoutePendingStore.isPolling()
      };
    },
    [],
    [RoutePendingStore]
  );

  React.useEffect(() => {
    let timeout;

    if (isLoading) {
      timeout = setTimeout(() => setIsShown(true), 300);
    }

    return () => {
      clearTimeout(timeout);
      setIsShown(false);
    };
  }, [isLoading]);

  if (!isLoading || !isShown) {
    return null;
  }

  return <Loader className="icon-addon-left text-muted" />;
};

export default RoutePendingIndicator;
