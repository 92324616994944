import { diffLines as prepareDiffLines, diffWords as prepareDiffWords } from 'diff';
import type { Map } from 'immutable';

import { prepareDiffObject } from '../VersionsDiffModalHelpers';

export const DiffType = {
  DEFAULT: 0,
  ADDED: 1,
  REMOVED: 2,
  CHANGED: 3
} as const;

export type DiffInformation = {
  value: string | DiffInformation[];
  lineNumber: number;
  type: (typeof DiffType)[keyof typeof DiffType];
};

export type LineInformation = {
  left: DiffInformation;
  right: DiffInformation;
};

type ComputedLineInformation = {
  lineInformation: LineInformation[];
  diffLines: number[];
};

type ComputedDiffInformation = {
  left: DiffInformation[];
  right: DiffInformation[];
};

const constructLines = (value: string): string[] => {
  if (value === '') return [];

  const lines = value.replace(/\n$/, '').split('\n');

  return lines;
};

const computeDiff = (oldValue: string, newValue: string): ComputedDiffInformation => {
  const diffArray = prepareDiffWords(oldValue, newValue);
  const computedDiff: ComputedDiffInformation = {
    left: [],
    right: []
  };
  diffArray.forEach(({ added, removed, value }): DiffInformation => {
    const diffInformation = {} as DiffInformation;
    if (added) {
      diffInformation.type = DiffType.ADDED;
      diffInformation.value = value;
      computedDiff.right.push(diffInformation);
    }
    if (removed) {
      diffInformation.type = DiffType.REMOVED;
      diffInformation.value = value;
      computedDiff.left.push(diffInformation);
    }
    if (!removed && !added) {
      diffInformation.type = DiffType.DEFAULT;
      diffInformation.value = value;
      computedDiff.right.push(diffInformation);
      computedDiff.left.push(diffInformation);
    }
    return diffInformation;
  });
  return computedDiff;
};

const computeLineInformation = (oldString: string, newString: string): ComputedLineInformation => {
  const diffArray = prepareDiffLines(oldString.trimEnd(), newString.trimEnd(), {
    newlineIsToken: false,
    ignoreWhitespace: false,
    ignoreCase: false
  });
  let rightLineNumber = 0;
  let leftLineNumber = 0;
  let lineInformation: LineInformation[] = [];
  let counter = 0;
  const diffLines: number[] = [];
  const ignoreDiffIndexes: string[] = [];
  const getLineInformation = (
    value: string,
    diffIndex: number,
    added?: boolean,
    removed?: boolean,
    evaluateOnlyFirstLine?: boolean
  ): LineInformation[] => {
    return constructLines(value)
      .map((line: string, lineIndex): LineInformation => {
        const left = {} as DiffInformation;
        const right = {} as DiffInformation;
        if (
          ignoreDiffIndexes.includes(`${diffIndex}-${lineIndex}`) ||
          (evaluateOnlyFirstLine && lineIndex !== 0)
        ) {
          return null!;
        }
        if (added || removed) {
          let countAsChange = true;
          if (removed) {
            leftLineNumber += 1;
            left.lineNumber = leftLineNumber;
            left.type = DiffType.REMOVED;
            left.value = line || ' ';

            // When the current line is of type REMOVED, check the next item in
            // the diff array whether it is of type ADDED. If true, the current
            // diff will be marked as both REMOVED and ADDED. Meaning, the
            // current line is a modification.
            const nextDiff = diffArray[diffIndex + 1];
            if (nextDiff && nextDiff.added) {
              const nextDiffLines = constructLines(nextDiff.value)[lineIndex];
              if (nextDiffLines) {
                const nextDiffLineInfo = getLineInformation(
                  nextDiffLines,
                  diffIndex,
                  true,
                  false,
                  true
                );

                const { value: rightValue, lineNumber, type } = nextDiffLineInfo[0].right;

                // When identified as modification, push the next diff to ignore
                // list as the next value will be added in this line computation as
                // right and left values.
                ignoreDiffIndexes.push(`${diffIndex + 1}-${lineIndex}`);

                right.lineNumber = lineNumber;
                if (left.value === rightValue) {
                  // The new value is exactly the same as the old
                  countAsChange = false;
                  right.type = 0;
                  left.type = 0;
                  right.value = rightValue;
                } else {
                  right.type = type;
                  const computedDiff = computeDiff(line, rightValue as string);
                  right.value = computedDiff.right;
                  left.value = computedDiff.left;
                }
              }
            }
          } else {
            rightLineNumber += 1;
            right.lineNumber = rightLineNumber;
            right.type = DiffType.ADDED;
            right.value = line;
          }
          if (countAsChange && !evaluateOnlyFirstLine) {
            if (!diffLines.includes(counter)) {
              diffLines.push(counter);
            }
          }
        } else {
          leftLineNumber += 1;
          rightLineNumber += 1;

          left.lineNumber = leftLineNumber;
          left.type = DiffType.DEFAULT;
          left.value = line;
          right.lineNumber = rightLineNumber;
          right.type = DiffType.DEFAULT;
          right.value = line;
        }

        if (!evaluateOnlyFirstLine) {
          counter += 1;
        }
        return { right, left };
      })
      .filter(Boolean);
  };

  diffArray.forEach(({ added, removed, value }, index: number): void => {
    lineInformation = [...lineInformation, ...getLineInformation(value, index, added, removed)];
  });

  return {
    lineInformation,
    diffLines
  };
};

const prepareConfigData = (config?: Map<string, any>) => {
  if (!config || config.isEmpty()) {
    return '';
  }

  return JSON.stringify(prepareDiffObject(config), null, 2);
};

export { computeLineInformation, prepareConfigData };
