import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { useSHA256 } from '../../utils/crypto';

const backgroundColors = {
  0: '#38CE8F',
  1: '#FBBC05',
  2: '#925cff',
  3: '#FF6A3C',
  4: '#4CCBFF',
  5: '#07BE07',
  6: '#FF5B50',
  7: '#3B5998',
  8: '#38CE8F',
  9: '#FBBC05',
  a: '#925cff',
  b: '#FF6A3C',
  c: '#4CCBFF',
  d: '#07BE07',
  e: '#FF5B50',
  f: '#3B5998'
};

const CircleIcon = ({
  icon,
  iconUrl,
  className,
  color,
  generatedBackground,
  bold,
  bigger,
  smaller
}) => {
  let styles = {};
  const hash = useSHA256(generatedBackground);

  if (generatedBackground) {
    const firstCharacter = hash.substring(0, 1).toLowerCase();

    styles = {
      color: '#fff',
      background: backgroundColors[firstCharacter]
    };
  }

  return (
    <span
      className={classnames(
        'circle-icon',
        className,
        { [color]: !iconUrl },
        { bold },
        { 'bigger-icon': bigger },
        { 'smaller-icon': smaller }
      )}
      style={styles}
    >
      {iconUrl ? (
        <img src={iconUrl} width="100%" height="100%" loading="lazy" />
      ) : (
        <FontAwesomeIcon icon={icon} />
      )}
    </span>
  );
};

CircleIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  iconUrl: PropTypes.string,
  color: PropTypes.oneOf([
    'blue',
    'green',
    'light-green',
    'red',
    'violet',
    'yellow',
    'gray',
    'orange'
  ]),
  generatedBackground: PropTypes.string,
  className: PropTypes.string,
  bold: PropTypes.bool,
  bigger: PropTypes.bool,
  smaller: PropTypes.bool
};

CircleIcon.defaultProps = {
  color: 'blue',
  bold: false,
  bigger: false,
  smaller: false
};

export default CircleIcon;
