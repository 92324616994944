import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type List, Map } from 'immutable';

import { ADMIN_ROLES } from '../../../constants/KbcConstants';
import StorageActionCreators from '../../../modules/components/StorageActionCreators';
import CreateDevBranchModal from '../../../modules/dev-branches/components/CreateDevBranchModal';
import DeleteDevBranchModal from '../../../modules/dev-branches/components/DeleteDevBranchModal';
import UpdateDevBranchModal from '../../../modules/dev-branches/components/UpdateDevBranchModal';
import { prepareBranchHref, prepareProductionHref } from '../../../modules/dev-branches/helpers';
import matchByWords from '../../../utils/matchByWords';
import MarkedText from '../../common/MarkedText';
import ProductionIcon from '../../common/ProductionIcon';
import Tooltip from '../../common/Tooltip';
import Truncated from '../../common/Truncated';
import { findProjectOrganization } from './helpers';

type Props = {
  query: string;
  readOnly: boolean;
  organizations: List<any>;
  currentProject: Map<string, any>;
  xsrf: string;
  projectBaseUrl: string;
  devBranches: Map<string, any>;
  isDevModeActive: boolean;
  sapiToken: Map<string, any>;
  currentDevBranchId?: number | null;
};

class BranchList extends React.Component<Props> {
  state = {
    createDevBranch: false,
    editDevBranch: false,
    deleteDevBranch: false,
    devBranchId: null
  };

  render() {
    return (
      <>
        <h3 className="flex-container">
          Branches
          {!this.props.readOnly && this.props.devBranches.count() > 1 && (
            <Button
              bsStyle="link"
              className="btn-link-inline f-12 line-height-16"
              onClick={(event: React.SyntheticEvent) => {
                event.stopPropagation();

                this.setState({ createDevBranch: true });
              }}
            >
              <FontAwesomeIcon icon="plus" className="color-primary mrp-2 f-14" />
              Create Branch
            </Button>
          )}
        </h3>
        {this.props.devBranches.count() > 1 ? (
          <ul className="list-unstyled">{this.renderBranchesList()}</ul>
        ) : (
          <Button
            block
            bsStyle="link"
            className="subnav-link"
            onClick={(event: React.SyntheticEvent) => {
              event.stopPropagation();

              this.setState({ createDevBranch: true });
            }}
          >
            <FontAwesomeIcon icon="plus" className="text-success mrp-3 f-14" />
            Create Branch
          </Button>
        )}
        <CreateDevBranchModal
          show={this.state.createDevBranch}
          onSubmit={this.handleCreateProject}
          onHide={() => this.setState({ createDevBranch: false })}
          devBranches={this.props.devBranches}
        />
        <UpdateDevBranchModal
          show={this.state.editDevBranch}
          onSubmit={StorageActionCreators.updateDevBranch}
          onHide={() => this.setState({ editDevBranch: false })}
          devBranch={this.props.devBranches.getIn([this.state.devBranchId], Map())}
          devBranches={this.props.devBranches}
        />
        <DeleteDevBranchModal
          show={this.state.deleteDevBranch}
          name={this.props.devBranches.getIn([this.state.devBranchId, 'name'], '')}
          onSubmit={() => StorageActionCreators.deleteDevBranch(this.state.devBranchId)}
          onHide={() => this.setState({ deleteDevBranch: false })}
        />
      </>
    );
  }

  renderBranchesList() {
    const devBranches = this.getFilteredDevBranches();

    if (devBranches.isEmpty()) {
      return <li className="text-muted">No branch found</li>;
    }

    return devBranches
      .sortBy((branch) => branch.get('name'))
      .sortBy((branch) => !branch.get('isDefault'))
      .filter((branch) => !branch.get('isDefault') || this.props.isDevModeActive)
      .map((branch) => {
        const branchLinkContent = branch.get('isDefault') ? (
          <>
            <ProductionIcon />
            <span className="icon-addon-left font-medium">
              <MarkedText source="Production" mark={this.props.query} />
            </span>
          </>
        ) : (
          <>
            <FontAwesomeIcon
              icon={['far', 'code-branch']}
              className="icon-addon-right text-muted"
              fixedWidth
            />
            <Truncated text={<MarkedText source={branch.get('name')} mark={this.props.query} />} />
            {this.canManageDevBranch() && (
              <span className="hoverable-buttons">
                <Tooltip placement="top" tooltip="Edit">
                  <Button
                    className="btn-link btn-link-inline text-muted"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      event.stopPropagation();

                      this.setState({ editDevBranch: true, devBranchId: branch.get('id') });
                    }}
                  >
                    <FontAwesomeIcon icon="pen" fixedWidth />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" tooltip="Delete">
                  <Button
                    className="btn-link btn-link-inline text-muted"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      event.stopPropagation();

                      this.setState({ deleteDevBranch: true, devBranchId: branch.get('id') });
                    }}
                  >
                    <FontAwesomeIcon icon="trash" fixedWidth />
                  </Button>
                </Tooltip>
              </span>
            )}
          </>
        );

        return (
          <li key={branch.get('id')}>
            {this.props.currentDevBranchId === branch.get('id') ? (
              <span className="subnav-link active">{branchLinkContent}</span>
            ) : (
              <a
                className="subnav-link"
                href={
                  branch.get('isDefault')
                    ? prepareProductionHref(
                        this.props.projectBaseUrl,
                        location.pathname,
                        this.props.currentDevBranchId
                      )
                    : prepareBranchHref(
                        this.props.projectBaseUrl,
                        location.pathname,
                        branch.get('id'),
                        this.props.currentDevBranchId
                      )
                }
              >
                {branchLinkContent}
              </a>
            )}
          </li>
        );
      })
      .toArray();
  }

  getCurrentOrganization() {
    return findProjectOrganization(this.props.organizations, this.props.currentProject);
  }

  getFilteredDevBranches() {
    if (!this.props.query) {
      return this.props.devBranches;
    }

    return this.props.devBranches.filter((branch) => {
      return matchByWords(
        branch.get('isDefault') ? 'Production' : branch.get('name'),
        this.props.query
      );
    });
  }

  canManageDevBranch() {
    return (
      this.getCurrentOrganization().get('isAdmin', false) ||
      [ADMIN_ROLES.ADMIN, ADMIN_ROLES.SHARE].includes(this.props.sapiToken.getIn(['admin', 'role']))
    );
  }

  handleCreateProject = (name: string, description: string) => {
    return StorageActionCreators.createDevBranch(name, description).then((branchId) => {
      window.location.href = prepareBranchHref(
        this.props.projectBaseUrl,
        location.pathname,
        branchId,
        this.props.currentDevBranchId
      );
    });
  };
}

export default BranchList;
