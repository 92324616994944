import React from 'react';
import PropTypes from 'prop-types';

import ApplicationStore from '../../stores/ApplicationStore';
import SwitchStateLink from './ActionControls/SwitchStateLink';
import SwitchStateLabel from './SwitchStateLabel';

class ActivateDeactivateButton extends React.Component {
  render() {
    return (
      <SwitchStateLink
        label={<SwitchStateLabel isActive={this.props.isActive} />}
        onChange={() => this.props.onChange(!this.props.isActive)}
        isActive={this.props.isActive}
        isPending={this.props.isPending}
        disabled={this.props.buttonDisabled}
        readOnly={this.props.readOnly}
      />
    );
  }
}

ActivateDeactivateButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  isPending: PropTypes.bool,
  buttonDisabled: PropTypes.bool
};

ActivateDeactivateButton.defaultProps = {
  isPending: false,
  buttonDisabled: false,
  readOnly: ApplicationStore.isReadOnly()
};

export default ActivateDeactivateButton;
