import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Map } from 'immutable';

import CredentialsBox from '../../../react/common/CredentialsBox';
import ExternalLink from '../../../react/common/ExternalLink';
import Loader from '../../../react/common/Loader';
import ModalIcon from '../../../react/common/ModalIcon';
import SandboxesActions from '../Actions';
import { NEED_CLIENT_TO_CONNECT, SANDBOX_TYPE } from '../Constants';
import { prepareCredentialsData, prepareSandboxUrl } from '../helpers';
import ClientInfo from './ClientInfo';

class CredentialsModal extends React.Component {
  state = {
    isLoading: false
  };

  render() {
    const isDataApp = this.props.sandbox.get('type') === SANDBOX_TYPE.STREAMLIT;

    return (
      <Modal show={this.props.show} onHide={this.props.onHide} onEnter={this.checkData}>
        <Modal.Header closeButton>
          <Modal.Title>{isDataApp ? 'Data App' : 'Workspace'} Credentials</Modal.Title>
          <ModalIcon icon="user" color="green" bold />
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        {!NEED_CLIENT_TO_CONNECT.includes(this.props.sandbox.get('type')) &&
          !!this.props.sandbox.get('url') && (
            <Modal.Footer>
              <ExternalLink
                href={prepareSandboxUrl(this.props.sandbox)}
                className={classnames('btn btn-block btn-success', {
                  disabled: this.state.isLoading
                })}
              >
                <FontAwesomeIcon icon="circle-play" className="icon-addon-right" fixedWidth />
                {isDataApp ? 'Open Data App' : 'Connect'}
              </ExternalLink>
            </Modal.Footer>
          )}
      </Modal>
    );
  }

  renderBody() {
    if (this.state.isLoading) {
      return (
        <>
          <Loader /> Loading data...
        </>
      );
    }

    return (
      <>
        <CredentialsBox rows={prepareCredentialsData(this.props.sandbox)} noBorder />
        {NEED_CLIENT_TO_CONNECT.includes(this.props.sandbox.get('type')) && (
          <>
            <hr />
            <ClientInfo />
          </>
        )}
      </>
    );
  }

  checkData = () => {
    if (!this.props.sandbox.get('password')) {
      this.setState({ isLoading: true });
      SandboxesActions.loadSandboxForce(this.props.sandbox.get('id')).finally(() =>
        this.setState({ isLoading: false })
      );
    }
  };
}

CredentialsModal.propTypes = {
  sandbox: PropTypes.instanceOf(Map).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default CredentialsModal;
