import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import keyCodes from '../../constants/keyCodes';
import ConfirmModal from './ConfirmModal';
import Tooltip from './Tooltip';

/** @type {any} */
const Confirm = createReactClass({
  propTypes: {
    title: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired,
    onConfirm: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    onHide: PropTypes.func,
    onEnter: PropTypes.func,
    icon: PropTypes.string,
    buttonType: PropTypes.string,
    buttonClass: PropTypes.string,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    disabledReason: PropTypes.string,
    isDisabledConfirmButton: PropTypes.bool,
    closeAfterResolve: PropTypes.bool,
    handleErrorInModal: PropTypes.bool,
    children: PropTypes.any,
    childrenRootElement: PropTypes.any,
    childrenRootElementClass: PropTypes.string,
    modalSize: PropTypes.oneOf(['lg', 'large', 'sm', 'small'])
  },

  getDefaultProps() {
    return {
      buttonType: 'danger',
      childrenRootElement: 'span',
      isDisabled: false,
      isDisabledConfirmButton: false
    };
  },

  getInitialState() {
    return {
      showModal: false
    };
  },

  render() {
    return (
      <>
        {this.renderButton()}
        <ConfirmModal
          show={this.state.showModal}
          onHide={this.closeModal}
          icon={this.props.icon}
          title={this.props.title}
          text={this.props.text}
          isLoading={this.props.isLoading}
          isDisabled={this.props.isDisabledConfirmButton}
          closeAfterResolve={this.props.closeAfterResolve}
          handleErrorInModal={this.props.handleErrorInModal}
          onConfirm={this.props.onConfirm}
          buttonLabel={this.props.buttonLabel}
          buttonType={this.props.buttonType}
          buttonClass={this.props.buttonClass}
          onEnter={this.props.onEnter}
          size={this.props.modalSize}
        />
      </>
    );
  },

  renderButton() {
    if (!this.props.children) return null;

    if (this.props.isDisabled && this.props.disabledReason) {
      return (
        <Tooltip tooltip={this.props.disabledReason} placement="top" type="explanatory">
          {this.renderBody()}
        </Tooltip>
      );
    }

    return this.renderBody();
  },

  renderBody() {
    const Wrapper = this.props.childrenRootElement;

    return (
      <Wrapper
        tabIndex="0"
        role="button"
        onKeyDown={this.onKeyDown}
        onClick={this.onButtonClick}
        className={classnames(this.props.childrenRootElementClass, {
          disabled: this.props.isDisabled
        })}
      >
        {this.props.children}
      </Wrapper>
    );
  },

  closeModal() {
    this.setState({ showModal: false });
    this.props.onHide?.();
  },

  onButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.props.isDisabled) {
      this.setState({ showModal: true });
    }
  },

  onKeyDown(e) {
    if (e.key === keyCodes.ENTER) {
      this.onButtonClick(e);
    }
  }
});

export default Confirm;
