import {
  KDS_TEAM_WR_EXASOL,
  KDS_TEAM_WR_FIREBOLT,
  KDS_TEAM_WR_HIVE_CSAS,
  KDS_TEAM_WR_PAIRITY,
  KEBOOLA_WR_DB_HIVE,
  KEBOOLA_WR_DB_IMPALA,
  KEBOOLA_WR_DB_MSSQL_V_2,
  KEBOOLA_WR_DB_MYSQL,
  KEBOOLA_WR_DB_ORACLE,
  KEBOOLA_WR_DB_PGSQL,
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_DB_SYNAPSE,
  KEBOOLA_WR_LOOKER_V2,
  KEBOOLA_WR_REDSHIFT_V_2,
  KEBOOLA_WR_SISENSE,
  KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE,
  KEBOOLA_WR_THOUGHTSPOT
} from '../../constants/componentIds';

export const WR_DB_COMPONENTS = [
  KEBOOLA_WR_LOOKER_V2,
  KEBOOLA_WR_DB_MSSQL_V_2,
  KEBOOLA_WR_REDSHIFT_V_2,
  KEBOOLA_WR_DB_IMPALA,
  KEBOOLA_WR_DB_HIVE,
  KEBOOLA_WR_DB_SYNAPSE,
  KEBOOLA_WR_DB_MYSQL,
  KEBOOLA_WR_DB_ORACLE,
  KEBOOLA_WR_DB_PGSQL,
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE,
  KDS_TEAM_WR_PAIRITY,
  KDS_TEAM_WR_FIREBOLT,
  KDS_TEAM_WR_HIVE_CSAS,
  KEBOOLA_WR_THOUGHTSPOT,
  KEBOOLA_WR_SISENSE,
  KDS_TEAM_WR_EXASOL
];

export default {
  ActionTypes: {
    WR_DB_API_ERROR: 'WR_DB_API_ERROR',
    WR_DB_GET_CONFIGURATION_SUCCESS: 'WR_DB_GET_CONFIGURATION_SUCCESS',

    WR_DB_SET_TABLE_START: 'WR_DB_SET_TABLE_START',
    WR_DB_SET_TABLE_SUCCESS: 'WR_DB_SET_TABLE_SUCCESS',
    WR_DB_GET_TABLE_SUCCESS: 'WR_DB_GET_TABLE_SUCCESS',

    WR_DB_SAVE_CREDENTIALS_START: 'WR_DB_SAVE_CREDENTIALS_START',
    WR_DB_SAVE_CREDENTIALS_SUCCESS: 'WR_DB_SAVE_CREDENTIALS_SUCCESS',

    WR_DB_SET_EDITING: 'WR_DB_SET_EDITING',

    WR_DB_SAVE_COLUMNS_SUCCESS: 'WR_DB_SAVE_COLUMNS_SUCCESS',
    WR_DB_SAVE_COLUMNS_START: 'WR_DB_SAVE_COLUMNS_START',

    WR_DB_LOAD_PROVISIONING_START: 'WR_DB_LOAD_PROVISIONING_START',
    WR_DB_LOAD_PROVISIONING_SUCCESS: 'WR_DB_LOAD_PROVISIONING_SUCCESS',
    WR_DB_LOAD_PROVISIONING_NO_CREDENTIALS: 'WR_DB_LOAD_PROVISIONING_NO_CREDENTIALS',

    WR_DB_DELETE_TABLE_START: 'WR_DB_DELETE_TABLE_START',
    WR_DB_DELETE_TABLE_SUCCESS: 'WR_DB_DELETE_TABLE_SUCCESS',

    WR_DB_ADD_TABLE_START: 'WR_DB_ADD_TABLE_START',
    WR_DB_ADD_TABLE_SUCCESS: 'WR_DB_ADD_TABLE_SUCCESS',

    LOOKER_WRITER_REGISTER_TO_LOOKER: 'LOOKER_WRITER_REGISTER_TO_LOOKER',
    LOOKER_WRITER_REGISTER_TO_LOOKER_SUCCESS: 'LOOKER_WRITER_REGISTER_TO_LOOKER_SUCCESS',
    LOOKER_WRITER_REGISTER_TO_LOOKER_ERROR: 'LOOKER_WRITER_REGISTER_TO_LOOKER_ERROR',

    LOOKER_WRITER_SAVE_CREDENTIALS: 'LOOKER_WRITER_SAVE_CREDENTIALS',
    LOOKER_WRITER_SAVE_CREDENTIALS_SUCCESS: 'LOOKER_WRITER_SAVE_CREDENTIALS_SUCCESS',
    LOOKER_WRITER_SAVE_CREDENTIALS_ERROR: 'LOOKER_WRITER_SAVE_CREDENTIALS_ERROR'
  }
};

export const DATA_TYPES_FROM_STORAGE = 'DATA TYPES FROM STORAGE';
export const WR_ORACLE_DEFAULT_BULK_SIZE = 1000;
