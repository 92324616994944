import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import dayjs from '../../../date';
import Events from '../../sapi-events/react/Events';
import { JOBS_STATUS, routeNames } from '../constants';

class JobLogPanel extends React.Component {
  render() {
    return (
      <Events
        link={{ to: routeNames.JOB_DETAIL, params: { jobId: this.props.job.get('id') } }}
        params={{
          runId: this.props.job.get('useLegacyRunId')
            ? this.props.job.get('runId')
            : this.props.job.get('id')
        }}
        autoReload={this.shouldAutoReload()}
      />
    );
  }

  shouldAutoReload() {
    if (this.props.job.get('status') === JOBS_STATUS.CANCELLED) {
      return false;
    }

    if (!this.props.job.get('isFinished')) {
      return true;
    }

    return dayjs().diff(this.props.job.get('endTime'), 'minutes') < 5;
  }
}

JobLogPanel.propTypes = {
  job: PropTypes.instanceOf(Map).isRequired
};

export default JobLogPanel;
