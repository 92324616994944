import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RouterLink from '../../../react/common/RouterLink';
import Tooltip from '../../../react/common/Tooltip';
import useStores from '../../../react/hooks/useStores';
import RoutesStore from '../../../stores/RoutesStore';
import { routeNames as flowsRouteNames } from '../../flows/constants';
import { routeNames } from '../constants';
import DocumentationButton from '../DocumentationButton';
import TemplatesStore from '../store';

const CloseTemplatesDirectoryHeader = () => {
  const hasInstances = useStores(
    () => !!TemplatesStore.getStore().instances?.length,
    [],
    [TemplatesStore]
  );
  const flowOrigin = RoutesStore.getRouterState().getIn(['location', 'query', 'flowId']);

  return (
    <ButtonToolbar>
      <DocumentationButton />
      {hasInstances && !flowOrigin && (
        <Tooltip tooltip="Back to templates" placement="top">
          <span>
            <RouterLink onlyActiveOnIndex to={routeNames.ROOT} className="btn btn-default">
              <FontAwesomeIcon icon="xmark" />
            </RouterLink>
          </span>
        </Tooltip>
      )}
      {!!flowOrigin && (
        <Tooltip tooltip="Back to flows" placement="top">
          <span>
            <RouterLink
              onlyActiveOnIndex
              className="btn btn-default"
              {...(flowOrigin === 'new'
                ? { to: flowsRouteNames.ROOT }
                : { to: flowsRouteNames.DETAIL, params: { config: flowOrigin } })}
            >
              <FontAwesomeIcon icon="xmark" />
            </RouterLink>
          </span>
        </Tooltip>
      )}
    </ButtonToolbar>
  );
};

export default CloseTemplatesDirectoryHeader;
