import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

import string from '../../../../utils/string';
import { nameWarning } from '../../../storage/constants';

class TableOutput extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Save Settings</h2>
        </div>
        <div className="box-content">
          <FormGroup>
            <Col componentClass={ControlLabel} sm={4}>
              Storage Table Name
            </Col>
            <Col sm={8}>
              <FormControl
                type="text"
                value={this.props.value.tableName}
                onChange={(e) => {
                  this.props.onChange({
                    tableName: string.sanitizeKbcTableIdString(e.target.value)
                  });
                }}
                placeholder="mytable"
                disabled={this.props.disabled}
              />
              <HelpBlock>Name of the table stored in Storage. {nameWarning}</HelpBlock>
            </Col>
          </FormGroup>
        </div>
      </div>
    );
  }
}

TableOutput.propTypes = {
  value: PropTypes.shape({
    tableName: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default TableOutput;
