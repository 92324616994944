import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Map } from 'immutable';

import CircleIcon from '../../../react/common/CircleIcon';
import { updateBillingInformation } from '../actions';
import BillingAddress from './BillingAddress';
import BillingEditModal from './BillingEditModal';

class BillingInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false
    };
  }

  render() {
    const hasBillingInformation = !this.props.billingInformation.isEmpty();

    return (
      <>
        <div className="box box-panel box-panel-small">
          <div className="box-header">
            <h2 className="box-title">Billing Details</h2>
            <CircleIcon icon="file-invoice" color="blue" bold />
          </div>
          {hasBillingInformation ? (
            <>
              <div className="box-panel-content mb-2">
                <BillingAddress billingInformation={this.props.billingInformation} />
              </div>
              <div className="box-panel-content padding-bottom mt-auto">
                <Button block onClick={() => this.setState({ showEditModal: true })}>
                  Edit billing details
                </Button>
              </div>
            </>
          ) : (
            <div className="box-panel-content text-center mt-1 mb-2">
              <h3 className="f-14 line-height-20 mt-0">No Billing Details</h3>
              <p className="text-muted line-height-20 mb-1">
                Billing details will be accessible once completed.
              </p>
            </div>
          )}
        </div>
        {this.renderEditModal()}
      </>
    );
  }

  renderEditModal() {
    return (
      <BillingEditModal
        show={this.state.showEditModal}
        billing={this.props.billingInformation}
        onHide={() => this.setState({ showEditModal: false })}
        onSubmit={updateBillingInformation}
      />
    );
  }
}

BillingInformation.propTypes = {
  billingInformation: PropTypes.instanceOf(Map).isRequired
};

export default BillingInformation;
