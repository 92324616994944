import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, HelpBlock, Radio, Tab, Tabs } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import CodeEditor from '../../../../react/common/CodeEditor';
import ExternalLink from '../../../../react/common/ExternalLink';
import PasswordControl from '../../../../react/common/PasswordControl';
import SapiTableSelector from '../../../components/react/components/SapiTableSelector';
import ApifyObjectSelector from './ApifyObjectSelector';

export const CRAWLER_KEY = 1;
export const AUTH_KEY = 2;
export const OPTIONS_KEY = 3;

const TabbedWizard = createReactClass({
  propTypes: {
    localState: PropTypes.object.isRequired,
    settings: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    updateSettings: PropTypes.func.isRequired,
    actors: PropTypes.object.isRequired,
    tasks: PropTypes.object.isRequired,
    inputTableId: PropTypes.string,
    updateInputTableId: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    parameters: PropTypes.object.isRequired,
    loadActors: PropTypes.func.isRequired,
    loadTasks: PropTypes.func.isRequired,
    updateParameters: PropTypes.func.isRequired,
    selectTab: PropTypes.func.isRequired,
    buckets: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired
  },

  render() {
    return (
      <Tabs
        className="tabs-inside-modal"
        activeKey={this.props.step}
        animation={false}
        onSelect={this.props.selectTab}
        id="controlled-tab-wizard"
      >
        <Tab title="Action" eventKey={CRAWLER_KEY} disabled={this.isTabDisabled(CRAWLER_KEY)}>
          <div className="clearfix">
            {this.renderRadioOption(
              'actor',
              'Run actor',
              'Runs a specific Actor and retrieves its dataset items if it finishes successfully.'
            )}
            {this.renderRadioOption(
              'actor-last-run',
              'Retrieve dataset items from the last Actor run',
              'Retrieves items from the dataset of a specific Actor’s last run.'
            )}
            {this.renderRadioOption(
              'task',
              'Run task',
              'Runs a specific task and retrieves its dataset items if it finishes successfully.'
            )}
            {this.renderRadioOption(
              'task-last-run',
              'Retrieve items from the last task run',
              'Retrieves items from the dataset of a task’s last run.'
            )}
            {this.renderRadioOption(
              'dataset',
              'Retrieve items from a dataset',
              'Retrieves items from a dataset specified by its ID or name.'
            )}
          </div>
        </Tab>
        <Tab title="Authentication" eventKey={AUTH_KEY} disabled={this.isTabDisabled(AUTH_KEY)}>
          {this.renderTokenForm()}
        </Tab>
        <Tab
          title="Specification"
          eventKey={OPTIONS_KEY}
          disabled={this.isTabDisabled(OPTIONS_KEY)}
        >
          {this.props.step === OPTIONS_KEY && this.renderOptionsContent()}
        </Tab>
      </Tabs>
    );
  },

  renderRadioOption(param, label, help) {
    return (
      <FormGroup>
        <Radio
          value={param}
          checked={this.props.action === param}
          onChange={(event) => this.updateParameter('action', event.target.value)}
        >
          {label}
        </Radio>
        <HelpBlock>{help}</HelpBlock>
      </FormGroup>
    );
  },

  renderOptionsContent() {
    switch (this.props.action) {
      case 'dataset':
        return this.renderDatasetSettingsForm();
      case 'actor':
        return this.renderActorSettingsForm();
      case 'actor-last-run':
        return this.renderActorLastRunDatasetItemsSettingsForm();
      case 'task':
        return this.renderTaskSettingsForm();
      case 'task-last-run':
        return this.renderTaskLastRunDatasetItemsSettingsForm();
      default:
        return null;
    }
  },

  renderActorSettingsForm() {
    return (
      <div className="form-horizontal">
        {this.renderActorSelector()}
        {this.renderInputTableIdSelector('actor')}
        {this.renderOutputField()}
        {this.renderMemoryField()}
        {this.renderBuildField()}
        <div className="form-group">
          <label className="col-xs-2 control-label">Actor Input</label>
          <div className="col-xs-10">
            <CodeEditor
              value={this.props.settings}
              onChange={this.props.updateSettings}
              options={{
                lineNumbers: false,
                lint: true
              }}
            />
            <HelpBlock>(Optional) Contains input for the Actor in JSON format.</HelpBlock>
          </div>
        </div>
      </div>
    );
  },

  renderActorLastRunDatasetItemsSettingsForm() {
    return (
      <div className="form-horizontal">
        {this.renderActorSelector()}
        {this.renderOutputField()}
      </div>
    );
  },

  renderTaskSettingsForm() {
    return (
      <div className="form-horizontal">
        {this.renderTaskSelector()}
        {this.renderInputTableIdSelector('task')}
        {this.renderOutputField()}
        {this.renderMemoryField()}
        {this.renderBuildField()}
        <div className="form-group">
          <label className="col-xs-2 control-label">Input Overrides</label>
          <div className="col-xs-10">
            <CodeEditor
              value={this.props.settings}
              onChange={this.props.updateSettings}
              options={{
                lineNumbers: false,
                lint: true
              }}
            />
            <HelpBlock>
              (Optional) Here you can enter a JSON object to override the task input configuration.
              Only the provided fields will be overridden, the rest will be left unchanged.
            </HelpBlock>
          </div>
        </div>
      </div>
    );
  },

  renderTaskLastRunDatasetItemsSettingsForm() {
    return (
      <div className="form-horizontal">
        {this.renderTaskSelector()}
        {this.renderOutputField()}
      </div>
    );
  },

  renderDatasetSettingsForm() {
    return (
      <div className="form-horizontal">
        {this.renderInput(
          'Dataset',
          'datasetId',
          'ID or name of the Dataset to fetch the data from.',
          'Enter dataset id or dataset name'
        )}
        {this.renderOutputField()}
      </div>
    );
  },

  renderInputTableIdSelector(type) {
    return (
      <div className="form-group">
        <label className="col-xs-2 control-label">Input Table</label>
        <div className="col-xs-10">
          <SapiTableSelector
            clearable
            placeholder="Select table"
            tables={this.props.tables}
            buckets={this.props.buckets}
            value={this.props.inputTableId || ''}
            onSelectTableFn={this.props.updateInputTableId}
          />
          <HelpBlock>
            (Optional) Data from the input table will be pushed to {type}, where you can access them
            through the Key-value store. The ID of the Key-value store and key of record will be
            saved to the input of {type} in attribute inputTableRecord.
          </HelpBlock>
        </div>
      </div>
    );
  },

  renderOutputField() {
    return this.renderInput(
      'Output fields',
      'fields',
      '(Optional) A comma-separated list of fields which should be picked from the dataset items.',
      'Enter output fields'
    );
  },

  renderMemoryField() {
    return this.renderInput(
      'Memory',
      'memory',
      `(Optional) Specifies the amount of memory allocated for run.`,
      '2048'
    );
  },

  renderBuildField() {
    return this.renderInput(
      'Build',
      'build',
      `(Optional) Tag or number of build to run (e.g. latest or 1.2.34)`,
      'latest'
    );
  },

  isTabDisabled(tabKey) {
    return this.props.step !== tabKey;
  },

  renderTokenForm() {
    return (
      <div className="form-horizontal">
        {this.renderProtectedInput(
          'Token',
          '#token',
          <>
            API token from your{' '}
            <ExternalLink href="https://console.apify.com/account/integrations">
              account page
            </ExternalLink>
            .
          </>,
          'Enter token'
        )}
      </div>
    );
  },

  renderActorSelector() {
    return (
      <ApifyObjectSelector
        objectName="actor"
        objectLabelKey="name"
        object={this.props.actors}
        selectedValue={this.props.parameters.get('actId')}
        onLoadObjectsList={this.props.loadActors}
        onSelect={(actId) => this.updateParameter('actId', actId)}
      />
    );
  },

  renderTaskSelector() {
    return (
      <ApifyObjectSelector
        objectName="task"
        objectLabelKey="name"
        object={this.props.tasks}
        selectedValue={this.props.parameters.get('actorTaskId')}
        onLoadObjectsList={this.props.loadTasks}
        onSelect={(actorTaskId) => this.updateParameter('actorTaskId', actorTaskId)}
      />
    );
  },

  renderInput(label, propertyPath, helpText, placeholder) {
    return (
      <div className="form-group">
        <label className="col-xs-2 control-label">{label}</label>
        <div className="col-xs-10">
          <FormControl
            type="text"
            value={this.props.parameters.get(propertyPath, '')}
            placeholder={placeholder}
            onChange={(e) => this.updateParameter(propertyPath, e.target.value)}
          />
          <HelpBlock>{helpText}</HelpBlock>
        </div>
      </div>
    );
  },

  renderProtectedInput(label, propertyPath, helpText, placeholder) {
    return (
      <div className="form-group">
        <label className="col-xs-2 control-label">{label}</label>
        <div className="col-xs-10">
          <PasswordControl
            placeholder={placeholder}
            value={this.props.parameters.get(propertyPath, '')}
            onChange={(e) => this.updateParameter(propertyPath, e.target.value)}
          />
          <HelpBlock>{helpText}</HelpBlock>
        </div>
      </div>
    );
  },

  updateParameter(key, newValue) {
    this.props.updateParameters(this.props.parameters.set(key, newValue));
  }
});

export default TabbedWizard;
