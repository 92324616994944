import React from 'react';
import PropTypes from 'prop-types';
import { HelpBlock } from 'react-bootstrap';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import {
  KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2,
  KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION,
  KEBOOLA_PYTHON_TRANSFORMATION_V_2,
  KEBOOLA_R_TRANSFORMATION_V_2
} from '../../../../../constants/componentIds';
import { USER_DOCUMENTATION_URL } from '../../../../../constants/KbcConstants';
import ExternalLink from '../../../../../react/common/ExternalLink';
import Select from '../../../../../react/common/Select';
import { transformationType } from '../../../Constants';

const TRANSFORMATIONS_LINK_ROOT = `${USER_DOCUMENTATION_URL}/transformations`;

const Packages = createReactClass({
  propTypes: {
    packages: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onEditChange: PropTypes.func.isRequired,
    transformationType: PropTypes.string,
    componentId: PropTypes.string,
    disabled: PropTypes.bool,
    forGenericComponents: PropTypes.bool
  },

  getDefaultProps() {
    return {
      disabled: false,
      forGenericComponents: false
    };
  },

  render() {
    const isGeneric = this.props.forGenericComponents;

    return (
      <div className={classnames('box', { 'pbp-2': isGeneric })}>
        <div
          className={classnames(
            'box-header',
            isGeneric ? 'smaller ptp-2 prp-1 plp-6' : 'big-padding with-border'
          )}
        >
          <h2 className={classnames('box-title', { 'line-height-24': isGeneric })}>Packages</h2>
        </div>
        <div className={classnames('box-content', { 'ptp-2 prp-6 pbp-2 plp-6': isGeneric })}>
          <Select
            multi
            allowCreate
            trimMultiCreatedValues
            value={this.props.packages}
            placeholder="Add packages"
            onChange={this.props.onEditChange}
            promptTextCreator={(label) => `Add package "${label}"`}
            disabled={this.props.isSaving || this.props.disabled}
          />
          <HelpBlock>
            Learn more about package installation and usage, and about the list of pre-installed
            packages in our{' '}
            <ExternalLink
              href={this.props.componentId ? this.resolveLink() : this.resolveLegacyLink()}
            >
              documentation
            </ExternalLink>
            .
          </HelpBlock>
        </div>
      </div>
    );
  },

  resolveLink() {
    switch (this.props.componentId) {
      case KEBOOLA_R_TRANSFORMATION_V_2:
        return `${TRANSFORMATIONS_LINK_ROOT}/r-plain/#packages`;

      case KEBOOLA_PYTHON_TRANSFORMATION_V_2:
      case KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION:
      case KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2:
        return `${TRANSFORMATIONS_LINK_ROOT}/python-plain/#packages`;

      default:
        return TRANSFORMATIONS_LINK_ROOT;
    }
  },

  resolveLegacyLink() {
    switch (this.props.transformationType) {
      case transformationType.R:
        return `${TRANSFORMATIONS_LINK_ROOT}/r/#packages`;

      case transformationType.PYTHON:
        return `${TRANSFORMATIONS_LINK_ROOT}/python/#packages`;

      case transformationType.JULIA:
        return `${TRANSFORMATIONS_LINK_ROOT}/julia/#packages`;

      default:
        return TRANSFORMATIONS_LINK_ROOT;
    }
  }
});

export default Packages;
