import React from 'react';
import PropTypes from 'prop-types';

import InlineEditTextInput from '../../../../../react/common/InlineEditTextInput';

class BulkSizeEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isEditing: false,
      editingValue: ''
    };
  }

  render() {
    if (this.props.readOnly) {
      return this.props.bulkSize;
    }
    return (
      <InlineEditTextInput
        isSaving={this.state.isSaving}
        isValid={this.state.editingValue.length > 0}
        isChanged={this.state.editingValue !== this.props.bulkSize}
        editTooltip="Edit Bulk Size"
        placeholder="Change Value"
        isEditing={this.state.isEditing}
        text={this.state.isEditing ? this.state.editingValue : this.props.bulkSize}
        onEditStart={() => this.setState({ isEditing: true, editingValue: this.props.bulkSize })}
        onEditCancel={() => this.setState({ isEditing: false, editingValue: '' })}
        onEditChange={(value) => this.setState({ editingValue: value })}
        onEditSubmit={() => {
          this.setState({ isSaving: true });
          this.props.onChange(this.state.editingValue).then(() => {
            this.setState({ isSaving: false, isEditing: false, editingValue: '' });
          });
        }}
      />
    );
  }
}

BulkSizeEdit.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  bulkSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default BulkSizeEdit;
