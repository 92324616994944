import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import Confirm from '../../../../react/common/Confirm';
import ConfirmMenuItem from '../../../../react/common/ConfirmMenuItem';
import Loader from '../../../../react/common/Loader';
import RoutesStore from '../../../../stores/RoutesStore';
import OrchestrationActionCreators from '../../ActionCreators';

const OrchestrationDeleteButton = createReactClass({
  propTypes: {
    orchestration: PropTypes.object.isRequired,
    isPending: PropTypes.bool.isRequired,
    buttonBlock: PropTypes.bool,
    onKeyDown: PropTypes.func
  },

  render() {
    const name = this.props.orchestration.get('name');

    if (this.props.buttonBlock) {
      return (
        <Confirm
          icon="trash"
          title="Delete Configuration"
          text={
            <>
              <p>Are you sure you want to delete the configuration {name}?</p>
              <p>
                <FontAwesomeIcon icon="triangle-exclamation" className="icon-addon-right" />
                This configuration can&apos;t be restored.
              </p>
            </>
          }
          buttonLabel="Delete"
          onConfirm={this._deleteOrchestration}
        >
          <Button bsStyle="link" className="btn-block text-muted" disabled={this.props.isPending}>
            {this.props.isPending ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
            Delete
          </Button>
        </Confirm>
      );
    }

    return (
      <ConfirmMenuItem
        icon="trash"
        title="Delete Configuration"
        text={
          <>
            <p>Are you sure you want to delete the configuration {name}?</p>
            <p>
              <FontAwesomeIcon icon="triangle-exclamation" className="icon-addon-right" />
              This configuration can&apos;t be restored.
            </p>
          </>
        }
        buttonLabel="Delete"
        onConfirm={this._deleteOrchestration}
        isDisabled={this.props.isPending}
        onKeyDown={this.props.onKeyDown}
      >
        {this.props.isPending ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
        Delete
      </ConfirmMenuItem>
    );
  },

  _deleteOrchestration() {
    RoutesStore.getRouter().transitionTo('orchestrations');
    // if orchestration is deleted immediately view is rendered with missing orchestration because of store changed
    const id = this.props.orchestration.get('id');
    return setTimeout(() => OrchestrationActionCreators.deleteOrchestration(id));
  }
});

export default OrchestrationDeleteButton;
