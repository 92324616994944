import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '../../../../../constants/componentIds';
import Checkbox from '../../../../../react/common/Checkbox';
import MappingsMultiActionsHeader, {
  MappingsHeader
} from '../../../../../react/common/MappingsMultiActionsHeader';
import SortIcon from '../../../../../react/common/SortIcon';
import InstalledComponentsActionCreators from '../../../../configurations/ConfigurationRowsActionCreators';
import { DBT_COMPONENTS } from '../../../../transformations-v2/constants';
import { ioType } from '../../../Constants';
import InstalledComponentsActions from '../../../InstalledComponentsActionCreators';
import CollapsibleMapping from './CollapsibleMapping';
import { hasInputMappingTables } from './helpers';
import TableInputMappingModal from './TableInputMappingModal';
import TableInputMappingRow from './TableInputMappingRow';

const TableInputMapping = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    destinationType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    configId: PropTypes.string.isRequired,
    onDeleteMappings: PropTypes.func.isRequired,
    editingValue: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    pendingActions: PropTypes.object.isRequired,
    allComponents: PropTypes.instanceOf(Map),
    allowedComponents: PropTypes.instanceOf(Map),
    sandboxes: PropTypes.instanceOf(Map),
    hasPayAsYouGo: PropTypes.bool,
    availableDatabricksClusters: PropTypes.instanceOf(List),
    replacementComponentId: PropTypes.string,
    addBottomRadius: PropTypes.bool,
    rowId: PropTypes.string,
    generateSources: PropTypes.bool,
    onGenerateSourceChange: PropTypes.func,
    singleMapping: PropTypes.bool
  },

  getInitialState() {
    return {
      selectedMappings: Map(),
      sortBy: null,
      sortDesc: false
    };
  },

  render() {
    return (
      <CollapsibleMapping
        readOnly={!!this.props.singleMapping || this.props.readOnly}
        componentId={this.props.componentId}
        configId={this.props.configId}
        rowId={this.props.rowId}
        mappingKey="table-input"
      >
        {this.renderBody}
      </CollapsibleMapping>
    );
  },

  renderBody(collapse) {
    const isDbtComponent = DBT_COMPONENTS.includes(this.props.componentId);

    return (
      <div
        className={classnames('box', {
          'no-mapping': this.props.value.isEmpty(),
          'bottom-radius': this.props.addBottomRadius
        })}
      >
        <div
          className={classnames('box-header above-table-with-buttons tw-block', {
            'is-sticky bg-color-white': this.props.value.count() > 5 && !collapse.isCollapsed
          })}
        >
          <div className="tw-flex tw-justify-between tw-items-center">
            <MappingsHeader type="input" componentId={this.props.componentId} storage="tables" />
            <div className="flex-container">
              {!this.props.value.isEmpty() &&
                (!isDbtComponent || collapse.isCollapsed) &&
                collapse.renderToggleButton()}
              {!this.props.readOnly && !this.props.singleMapping && (
                <>
                  {isDbtComponent && (
                    <Checkbox
                      checked={this.props.generateSources}
                      onChange={this.props.onGenerateSourceChange}
                    >
                      Generate Sources
                    </Checkbox>
                  )}
                  <TableInputMappingModal
                    mode="create"
                    componentId={this.props.componentId}
                    replacementComponentId={this.props.replacementComponentId}
                    mapping={this.props.editingValue.get('new-mapping', Map())}
                    tables={this.props.tables}
                    buckets={this.props.buckets}
                    onChange={(newMapping) => this.onChangeMapping('new-mapping', newMapping)}
                    onCancel={() => this.onCancelEditMapping('new-mapping')}
                    onSave={() => {
                      const mapping = this.props.editingValue.get('new-mapping', Map());
                      const multipleTables = hasInputMappingTables(mapping);

                      return this.onSaveMapping(
                        'new-mapping',
                        `Add input table${multipleTables ? 's' : ` ${mapping.get('source')}`}`
                      );
                    }}
                    otherMappings={this.props.value}
                    destinationType={this.props.destinationType}
                    generateSources={this.props.generateSources}
                  />
                </>
              )}
            </div>
          </div>

          {!this.props.value.isEmpty() && !collapse.isCollapsed && (
            <div className="tw-flex tw-items-center tw-py-2">
              <MappingsMultiActionsHeader
                hide={!!this.props.singleMapping || this.props.readOnly}
                disabled={this.props.value.isEmpty()}
                componentId={this.props.componentId}
                configurationId={this.props.configId}
                rowId={this.props.rowId}
                type="input"
                storage="tables"
                allMappings={this.props.value}
                selectedMappings={this.state.selectedMappings}
                updateMappingsSelection={(selectedMappings) =>
                  this.setState(() => ({ selectedMappings }))
                }
                deleteMappings={this.props.onDeleteMappings}
                sandboxComponent={this.props.allComponents?.get(KEBOOLA_SANDBOXES)}
                allowedComponents={this.props.allowedComponents}
                sandboxes={this.props.sandboxes}
                hasPayAsYouGo={this.props.hasPayAsYouGo}
                availableDatabricksClusters={this.props.availableDatabricksClusters}
                isSorted={this.state.sortBy === 'source'}
                isSortedDesc={this.state.sortDesc}
                onClick={() => this.handleChangeSort('source')}
              />
              {isDbtComponent ? (
                <div className="tw-font-medium">Input Table</div>
              ) : (
                <div
                  className="tw-cursor-pointer tw-font-medium"
                  onClick={() => this.handleChangeSort('destination')}
                >
                  Input Table
                  <SortIcon
                    className="tw-ml-2"
                    isSorted={this.state.sortBy === 'destination'}
                    isSortedDesc={this.state.sortDesc}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {collapse.renderCollapseSection(this.renderContent(isDbtComponent))}
      </div>
    );
  },

  renderContent(isDbtComponent) {
    if (this.props.value.isEmpty()) {
      return null;
    }

    return (
      <div className="box-content p-0">
        <div className="table table-hover overflow-break-anywhere">
          <div className="tbody">
            {this.props.value
              .map((input, index) => [input, index])
              .sort(this.handleSort)
              .map(([input, index]) => this.renderRow(input, index, isDbtComponent))
              .toArray()}
          </div>
        </div>
      </div>
    );
  },

  renderRow(input, key, isDbtComponent) {
    return (
      <TableInputMappingRow
        key={key}
        value={input}
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        singleMapping={this.props.singleMapping}
        replacementComponentId={this.props.replacementComponentId}
        editingValue={this.props.editingValue.get(key, Map())}
        tables={this.props.tables}
        buckets={this.props.buckets}
        mappingIndex={key}
        pendingActions={this.props.pendingActions}
        otherMappings={this.props.value.filter((mapping, index) => index !== key)}
        onEditStart={() => this.onEditStart(key)}
        onChange={(value) => this.onChangeMapping(key, value)}
        onSave={() => this.onSaveMapping(key, `Update input table ${input.get('source')}`)}
        onCancel={() => this.onCancelEditMapping(key)}
        onDelete={() =>
          this.onDeleteMapping(key).then(() => this.setState({ selectedMappings: Map() }))
        }
        destinationType={this.props.destinationType}
        isSelected={!!this.state.selectedMappings.get(key)}
        toggleSelection={(checked) =>
          this.setState({ selectedMappings: this.state.selectedMappings.set(key, checked) })
        }
        isDisabled={isDbtComponent && !this.props.generateSources}
      />
    );
  },

  handleChangeSort(type) {
    if (type !== this.state.sortBy) {
      return this.setState({
        sortBy: type,
        sortDesc: false
      });
    }

    this.setState({
      sortDesc: !this.state.sortDesc
    });
  },

  handleSort([inputA], [inputB]) {
    if (!this.state.sortBy) {
      return 0;
    }

    const nameA = inputA.get(this.state.sortBy, '');
    const nameB = inputB.get(this.state.sortBy, '');

    const sort = this.state.sortDesc ? -1 : 1;

    return nameA.localeCompare(nameB) * sort;
  },

  onChangeMapping(key, value) {
    if (this.props.rowId) {
      return InstalledComponentsActionCreators.changeEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'input',
        'tables',
        key,
        value
      );
    }

    return InstalledComponentsActions.changeEditingMapping(
      this.props.componentId,
      this.props.configId,
      'input',
      'tables',
      key,
      value
    );
  },

  onEditStart(key) {
    if (this.props.rowId) {
      return InstalledComponentsActionCreators.startEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'input',
        'tables',
        key
      );
    }

    return InstalledComponentsActions.startEditingMapping(
      this.props.componentId,
      this.props.configId,
      'input',
      'tables',
      key
    );
  },

  onSaveMapping(key, changeDescription) {
    if (this.props.rowId) {
      return InstalledComponentsActionCreators.saveEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'input',
        'tables',
        key,
        changeDescription
      );
    }

    return InstalledComponentsActions.saveEditingMapping(
      this.props.componentId,
      this.props.configId,
      'input',
      'tables',
      key,
      changeDescription
    );
  },

  onCancelEditMapping(key) {
    if (this.props.rowId) {
      return InstalledComponentsActionCreators.cancelEditingMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'input',
        'tables',
        key
      );
    }

    return InstalledComponentsActions.cancelEditingMapping(
      this.props.componentId,
      this.props.configId,
      'input',
      'tables',
      key
    );
  },

  onDeleteMapping(key) {
    const updatingTableId = this.props.value.get(key).get('source');
    const changeDescription = `Delete input table mapping ${updatingTableId}`;

    if (this.props.rowId) {
      return InstalledComponentsActionCreators.deleteMapping(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        'input',
        'tables',
        key,
        changeDescription
      );
    }

    return InstalledComponentsActions.deleteMapping(
      this.props.componentId,
      this.props.configId,
      'input',
      'tables',
      key,
      changeDescription
    );
  }
});

export default TableInputMapping;
