import React from 'react';
import PropTypes from 'prop-types';
import { Well } from 'react-bootstrap';
import { Map } from 'immutable';

import ConfirmModal from '../../../react/common/ConfirmModal';
import { getTableAliases, getTableLinks, tableName } from '../helpers';

class DeleteBucketsAndTablesModal extends React.Component {
  render() {
    const buckets = this.props.selected.filter(
      (row) => row.has('stage') && !row.has('sourceBucket')
    );
    const linkedBuckets = this.props.selected.filter(
      (row) => row.has('stage') && row.has('sourceBucket')
    );
    const bucketKeys = buckets
      .map((bucket) => bucket.get('id'))
      .concat(linkedBuckets.map((bucket) => bucket.get('id')));
    const tables = this.props.selected.filter(
      (row) => !row.has('stage') && !bucketKeys.includes(row.getIn(['bucket', 'id']))
    );
    const tablesAliases = tables.reduce(
      (tableAliases, table) => [
        ...tableAliases,
        ...getTableAliases(table, this.props.allTables, this.props.sapiToken)
      ],
      []
    );
    const tablesLinks = tables.reduce(
      (tableLinks, table) => [
        ...tableLinks,
        ...getTableLinks(table, this.props.allBuckets.get(table.getIn(['bucket', 'id']), Map()))
      ],
      []
    );

    return (
      <ConfirmModal
        closeAfterResolve
        show={this.props.show}
        onHide={this.props.onHide}
        icon="trash"
        buttonType="danger"
        buttonLabel="Delete selected"
        title="Delete selected"
        text={
          <>
            <p>Are you sure you want to delete selected items?</p>
            <Well>
              {buckets.length > 0 && (
                <>
                  <p className="text-muted small">These buckets will be deleted:</p>
                  <ul className="list-unstyled">
                    {buckets.map((bucket) => (
                      <li key={bucket.get('id')}>{bucket.get('displayName')}</li>
                    ))}
                  </ul>
                </>
              )}
              {linkedBuckets.length > 0 && (
                <>
                  <p className="text-muted small">These buckets will be unlinked:</p>
                  <ul className="list-unstyled">
                    {linkedBuckets.map((bucket) => (
                      <li key={bucket.get('id')}>{bucket.get('displayName')}</li>
                    ))}
                  </ul>
                </>
              )}
              {tables.length > 0 && (
                <>
                  <p className="text-muted small">These tables will be deleted:</p>
                  <ul className="list-unstyled">
                    {tables.map((table) => (
                      <li key={table.get('id')}>{tableName(table)}</li>
                    ))}
                  </ul>
                </>
              )}
              {tablesAliases.length > 0 && (
                <>
                  <p className="text-muted small">These alias tables will be deleted:</p>
                  <ul className="list-unstyled">
                    {tablesAliases.map((table) => (
                      <li key={table.get('id')}>{tableName(table)}</li>
                    ))}
                  </ul>
                </>
              )}
              {tablesLinks.length > 0 && (
                <>
                  <p className="text-muted small">These tables links will be deleted:</p>
                  <ul className="list-unstyled">
                    {tablesLinks.map((table) => (
                      <li key={table.get('id')}>{tableName(table.get('table'))}</li>
                    ))}
                  </ul>
                </>
              )}
            </Well>
          </>
        }
        isLoading={this.props.isLoading}
        onConfirm={this.props.onSubmit}
      />
    );
  }
}

DeleteBucketsAndTablesModal.propTypes = {
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  allTables: PropTypes.instanceOf(Map).isRequired,
  allBuckets: PropTypes.instanceOf(Map).isRequired,
  show: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selected: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
};

export default DeleteBucketsAndTablesModal;
