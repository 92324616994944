import React from 'react';
import type { UseExpandedRowProps, UseTableCellProps, UseTableRowProps } from 'react-table';
import { useExpanded, useTable } from 'react-table';
import Promise from 'bluebird';
import classnames from 'classnames';
import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import dayjs from '../../../date';
import BlockButton from '../../../react/common/BlockButton';
import CircleIcon from '../../../react/common/CircleIcon';
import ComponentIcon from '../../../react/common/ComponentIcon';
import ComponentName from '../../../react/common/ComponentName';
import { onTableRowKeyDown } from '../../../react/common/ConfigurationsTable/helpers';
import CreatedDate from '../../../react/common/CreatedDate';
import Gravatar from '../../../react/common/Gravatar';
import JobDuration from '../../../react/common/JobDuration';
import JobStatusLabel from '../../../react/common/JobStatusLabel';
import JobTerminateButton from '../../../react/common/JobTerminateButton';
import Loader from '../../../react/common/Loader';
import RouterLink from '../../../react/common/RouterLink';
import TableCollapseButton from '../../../react/common/TableCollapseButton';
import Tooltip from '../../../react/common/Tooltip';
import Truncated from '../../../react/common/Truncated';
import User from '../../../react/common/User';
import RoutesStore from '../../../stores/RoutesStore';
import hasSelections from '../../../utils/hasSelections';
import Timer from '../../../utils/Timer';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../../utils/windowOpen';
import JobsActionCreators from '../../queue/actions';
import ContinueOnFailureIcon from '../../queue/components/ContinueOnFailureIcon';
import ScheduledJob from '../../queue/components/ScheduledJob';
import TriggeredJob from '../../queue/components/TriggeredJob';
import {
  BEHAVIOR_TYPES,
  JOB_RUNNING_STATUSES,
  JOBS_LIMIT_FOR_GRAPH,
  JOBS_STATUS,
  JOBS_TYPES,
  routeNames
} from '../../queue/constants';
import { isScheduledJob, isTriggeredJob, prepareSortedChildJobs } from '../../queue/helpers';
import {
  getComponentByJob,
  getConfigurationName,
  getOrchestratorPhaseName
} from '../../queue/jobResolver';

type TableRow<D extends Record<string, unknown> = Record<string, unknown>> = UseTableRowProps<D> &
  UseExpandedRowProps<D>;

type TableCell<D extends Record<string, unknown> = Record<string, unknown>> = UseTableCellProps<D>;

const JobsTable = (props: {
  configId: string;
  jobs: Map<string, any>;
  allJobs: Map<string, any>;
  admins: Map<string, any>;
  sapiToken: Map<string, any>;
  terminatingPendingActions: Map<string, boolean>;
  isLoading: boolean;
}) => {
  const [loadingChildJobs, setLoadingChildJobs] = React.useState(Map());

  const columns = React.useMemo(
    () => [
      {
        Header: 'All runs',
        Cell: ({ row }: { row: TableRow }) => {
          const component = getComponentByJob(row.values.job);

          if (row.depth === 0) {
            const tokenDescription = row.values.job.getIn(
              ['token', 'description'],
              row.values.job.getIn(['initiatorToken', 'description'])
            );
            const admin = props.admins.get(tokenDescription);
            const isLoading = loadingChildJobs.get(row.values.job.get('id'), false);
            const isRunnedByScheduler = isScheduledJob(row.values.job);

            return (
              <span className="with-expand flex-container flex-start">
                <>
                  {!!row.values.job.get('startTime') && (
                    <TableCollapseButton entity="job" isCollapsed={!row.isExpanded} />
                  )}
                  {isRunnedByScheduler ? (
                    <CircleIcon
                      bold
                      icon="clock"
                      color="blue"
                      className="job-icon icon-addon-right"
                    />
                  ) : (
                    <span className="icon-addon-right line-height-1">
                      <Gravatar user={admin} size={28} fallback={tokenDescription} />
                    </span>
                  )}
                  <RouterLink
                    to={routeNames.JOB_DETAIL}
                    params={{ jobId: row.values.job.get('id') }}
                    className="link-inherit"
                  >
                    <div className="flex-container flex-start">
                      {admin ? (
                        <User user={admin} avatar={false} />
                      ) : isRunnedByScheduler ? (
                        <ScheduledJob noIcon />
                      ) : isTriggeredJob(row.values.job) ? (
                        <TriggeredJob />
                      ) : (
                        tokenDescription
                      )}
                    </div>
                  </RouterLink>
                  {isLoading && (
                    <Tooltip tooltip="Loading child jobs" placement="top">
                      <Loader className="icon-addon-left text-muted-light" />
                    </Tooltip>
                  )}
                </>
              </span>
            );
          }

          if (row.depth === 1) {
            return (
              <span className="with-expand level-2 flex-container flex-start">
                {row.canExpand && (
                  <TableCollapseButton entity="job" isCollapsed={!row.isExpanded} />
                )}
                <ComponentIcon component={component} isPhase size="28" className="mr-1" />
                <RouterLink
                  to={routeNames.JOB_DETAIL}
                  params={{ jobId: row.values.job.get('id') }}
                  className="link-inherit mr-1"
                >
                  <div className="flex-container flex-start">
                    <Truncated text={getOrchestratorPhaseName(row.values.job)} />
                  </div>
                </RouterLink>
                {row.values.job.getIn(['behavior', 'onError']) === BEHAVIOR_TYPES.WARNING && (
                  <ContinueOnFailureIcon />
                )}
              </span>
            );
          }

          return (
            <div className="with-expand level-3 flex-container flex-start">
              <ComponentIcon component={component} size="28" />
              <div className="ml-1 mr-1">
                <div className="line-height-18">
                  <Truncated text={getConfigurationName(row.values.job)} />
                </div>
                <div className="f-11 line-height-1 text-muted">
                  <ComponentName component={component} capitalize showType>
                    {(nameAndType: string) => nameAndType}
                  </ComponentName>
                </div>
              </div>
              {row.values.job.getIn(['behavior', 'onError']) === BEHAVIOR_TYPES.WARNING && (
                <ContinueOnFailureIcon />
              )}
            </div>
          );
        },
        accessor: 'job'
      },
      {
        Header: 'Duration',
        Cell: ({ row }: { row: TableRow }) => {
          return (
            <JobDuration
              status={row.values.job.get('status')}
              startTime={row.values.job.get('startTime')}
              endTime={row.values.job.get('endTime')}
            />
          );
        },
        accessor: 'duration'
      },
      {
        Header: 'Created',
        Cell: ({ row }: { row: TableRow }) => {
          return <CreatedDate createdTime={row.values.job.get('createdTime')} />;
        },
        accessor: 'created'
      },
      {
        Header: 'Status',
        Cell: ({ row }: { row: TableRow }) => {
          const jobId = row.values.job.get('id');
          const jobStatus = row.values.job.get('status');

          if (props.terminatingPendingActions.get(jobId, false)) {
            return <JobStatusLabel status={JOBS_STATUS.TERMINATING} />;
          }

          if (!JOB_RUNNING_STATUSES.includes(jobStatus) || jobStatus === JOBS_STATUS.TERMINATING) {
            return <JobStatusLabel status={jobStatus} />;
          }

          return (
            <div className="actions-container with-inline-buttons">
              <div className="not-actions">
                <JobStatusLabel status={jobStatus} />
              </div>
              <div className="actions">
                <JobTerminateButton
                  simple
                  isFlow
                  isTerminating={false}
                  job={row.values.job}
                  sapiToken={props.sapiToken}
                  onTerminate={() => JobsActionCreators.terminateJob(jobId)}
                />
              </div>
            </div>
          );
        },
        accessor: 'status'
      }
    ],
    [props.admins, props.terminatingPendingActions, props.sapiToken, loadingChildJobs]
  );

  const data = React.useMemo(() => {
    return props.jobs
      .map((orchestratorJob: Map<string, any>) => {
        return {
          job: orchestratorJob,
          subRows: prepareSortedChildJobs(props.allJobs, orchestratorJob)
            .map((phaseJob: Map<string, any>) => {
              return {
                job: phaseJob,
                subRows: prepareSortedChildJobs(props.allJobs, phaseJob)
                  .map((taskJob: Map<string, any>) => ({ job: taskJob }))
                  .toArray()
              };
            })
            .toArray()
        };
      })
      .toArray();
  }, [props.allJobs, props.jobs]);

  const tableInstance = useTable({ columns, data, autoResetExpanded: false } as any, useExpanded);

  React.useEffect(() => {
    const checkRunningJobs = () => {
      const openedNotFinishedJobs = tableInstance.rows
        .map((row: any) => {
          tableInstance.prepareRow(row);
          return row;
        })
        .filter((row: TableRow) => {
          return (
            row.isExpanded &&
            row.values.job.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER &&
            (!row.values.job.get('isFinished') ||
              dayjs().diff(row.values.job.get('endTime'), 'minutes') < 1)
          );
        })
        .map((row) => row.values.job);

      return Promise.map(openedNotFinishedJobs, JobsActionCreators.loadChildJobsForce, {
        concurrency: 3
      }).then(() =>
        JobsActionCreators.loadComponentConfigurationLatestJobs(
          KEBOOLA_ORCHESTRATOR,
          props.configId
        )
      );
    };

    Timer.poll(checkRunningJobs, { interval: 10 });
    return () => {
      Timer.stop(checkRunningJobs);
    };
  }, [tableInstance, props.configId]);

  return (
    <div className="box mb-2">
      <div {...tableInstance.getTableProps({ className: 'table table-hover react-table' })}>
        <div className="thead">
          {tableInstance.headerGroups.map((headerGroup) => {
            const { key, ...headerProps } = headerGroup.getHeaderGroupProps({ className: 'tr' });

            return (
              <div key={key} {...headerProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...columnProps } = column.getHeaderProps({
                    className: classnames('th', {
                      'w-150 text-right': column.id === 'duration',
                      'w-250 text-right': column.id === 'created',
                      'w-125': column.id === 'status'
                    })
                  });

                  return (
                    <div key={key} {...columnProps}>
                      {column.render('Header')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div {...tableInstance.getTableBodyProps({ className: 'tbody' })}>
          {props.jobs.isEmpty() && (
            <div className="tr no-hover">
              {tableInstance.columns.map((column, index) => {
                if (index > 0) {
                  return <div key={index} className="td text-muted" />;
                }

                return (
                  <div key={index} className="td text-muted">
                    {props.isLoading ? (
                      <>
                        <Loader className="icon-addon-right" />
                        Loading jobs...
                      </>
                    ) : (
                      'No flow jobs found'
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {tableInstance.rows
            .map((row: any) => {
              tableInstance.prepareRow(row);
              return row;
            })
            .map((row: TableRow) => {
              const rowAction = (e?: React.MouseEvent | React.KeyboardEvent) => {
                if (hasSelections()) {
                  return;
                }

                if (shouldUseNewWindow(e)) {
                  const href = RoutesStore.getRouter().createHref(routeNames.JOB_DETAIL, {
                    jobId: row.values.job.get('id')
                  });

                  return windowOpen(href);
                }

                if (
                  row.depth < 2 &&
                  [JOBS_TYPES.ORCHESTRATION_CONTAINER, JOBS_TYPES.PHASE_CONTAINER].includes(
                    row.values.job.get('type')
                  )
                ) {
                  if (
                    !row.isExpanded &&
                    row.subRows.length === 0 &&
                    !!row.values.job.get('startTime')
                  ) {
                    setLoadingChildJobs(loadingChildJobs.set(row.values.job.get('id'), true));
                    return JobsActionCreators.loadChildJobsForce(row.values.job).then(() => {
                      setLoadingChildJobs(loadingChildJobs.delete(row.values.job.get('id')));
                      row.toggleRowExpanded(true);
                    });
                  }

                  return row.toggleRowExpanded(row.canExpand && !row.isExpanded);
                }

                return RoutesStore.getRouter().transitionTo(routeNames.JOB_DETAIL, {
                  jobId: row.values.job.get('id')
                });
              };

              const userRowProps = {
                tabIndex: '0',
                role: 'button',
                className: classnames('tr hoverable-actions-with-replacement', {
                  clickable: row.canExpand || row.depth === 2
                }),
                onMouseDown: simulateClickIfMiddleMouseIsUsed.mousedown,
                onMouseUp: simulateClickIfMiddleMouseIsUsed.mouseup,
                onClick: rowAction,
                onKeyDown: onTableRowKeyDown(rowAction)
              };

              const { key, ...rowProps } = row.getRowProps(userRowProps);

              return (
                <div key={key} {...rowProps}>
                  {row.cells.map((cell: TableCell) => {
                    const { key, ...cellProps } = cell.getCellProps({
                      className: classnames('td', {
                        'text-right': ['duration', 'created'].includes(cell.column.id)
                      })
                    });

                    return (
                      <div key={key} {...cellProps}>
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
      {props.jobs.count() >= JOBS_LIMIT_FOR_GRAPH && (
        <BlockButton
          label="Show All Jobs"
          onClick={() => {
            return RoutesStore.getRouter().transitionTo(
              routeNames.JOBS,
              {},
              { component: KEBOOLA_ORCHESTRATOR, config: props.configId }
            );
          }}
        />
      )}
    </div>
  );
};

export default JobsTable;
