/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Label } from 'react-bootstrap';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import { useExpanded, useRowSelect, useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { List, Map, OrderedMap } from 'immutable';

import { componentTypes } from '../../../../../constants/componentTypes';
import Checkbox from '../../../../../react/common/Checkbox';
import ComponentIcon from '../../../../../react/common/ComponentIcon';
import ComponentName from '../../../../../react/common/ComponentName';
import ConnectorIcon from '../../../../../react/common/ConnectorIcon';
import MarkedText from '../../../../../react/common/MarkedText';
import Tooltip from '../../../../../react/common/Tooltip';
import fromJSOrdered from '../../../../../utils/fromJSOrdered';
import onClickSelectionCell from '../../../../../utils/onClickSelectionCell';
import { getNewComponentTypeLabel } from '../../../helpers';
import {
  getAllSelectedRows,
  getTagsForComponentConfiguration,
  getTagsForComponentConfigurationRow
} from './helpers';

const getTableInitialState = (data, searchQuery) => {
  if (!searchQuery) {
    return;
  }

  const expanded = data.reduce((all, component, componentIndex) => {
    const configs = component.subRows.reduce((acc, config, configIndex) => {
      return { ...acc, [`${componentIndex}.${configIndex}`]: config.matchOnlyName !== true };
    }, {});

    return { ...all, [componentIndex]: component.matchOnlyName !== true, ...configs };
  }, {});

  return { expanded };
};

const ComponentRow = ({ row, searchQuery }) => {
  const componentTypeLabel = getNewComponentTypeLabel(row.values.item.get('type'));

  return (
    <div className="flex-container flex-start">
      <ComponentName component={row.values.item}>
        {(name) => <MarkedText source={name} mark={searchQuery} />}
      </ComponentName>
      <Label bsStyle="primary" className="label-rounded icon-addon-left">
        {componentTypeLabel.toUpperCase()}
      </Label>
    </div>
  );
};

const Config = ({ row, searchQuery, handleAdd }) => {
  return (
    <span className="flex-container flex-start">
      <FontAwesomeIcon
        fixedWidth
        icon={row.isExpanded || !row.canExpand ? 'folder-open' : 'folder'}
        className={classnames({
          'text-muted-light': !row.isExpanded,
          'text-muted': row.isExpanded
        })}
      />
      <MarkedText
        source={row.values.item.get('name')}
        mark={searchQuery}
        className="icon-addon-right"
      />
      <div className="labels-container">
        {row.values.item.get('fileTags').map((tag, index) => (
          <FileTag
            key={index}
            tag={tag}
            row={row}
            handleAdd={handleAdd}
            searchQuery={searchQuery}
          />
        ))}
      </div>
    </span>
  );
};

const ConfigRow = ({ row, searchQuery, handleAdd }) => {
  return (
    <span className="flex-container flex-start">
      <ConnectorIcon />
      <FontAwesomeIcon icon="cabinet-filing" className="text-muted-light" />
      <MarkedText
        source={row.values.item.get('name')}
        mark={searchQuery}
        className="icon-addon-right"
      />
      <div className="labels-container">
        {row.values.item.get('fileTags').map((tag, index) => (
          <FileTag
            key={index}
            tag={tag}
            row={row}
            handleAdd={handleAdd}
            searchQuery={searchQuery}
          />
        ))}
      </div>
    </span>
  );
};

const FileTag = ({ tag, row, searchQuery, handleAdd }) => {
  return (
    <Tooltip placement="top" tooltip="Row with tag will be used." type="explanatory">
      <Label
        bsStyle="success"
        className="label-rounded color-inverse clickable"
        onClick={(e) => {
          e.stopPropagation();

          handleAdd(
            fromJSOrdered([
              { source: { tags: row.values.item.get('tags').push(OrderedMap({ name: tag })) } }
            ])
          );
        }}
      >
        <MarkedText source={tag} mark={searchQuery} />
      </Label>
    </Tooltip>
  );
};

const Table = ({ columns, data, searchQuery }) => {
  const initialState = React.useMemo(() => {
    return getTableInitialState(data, searchQuery);
  }, [data, searchQuery]);

  const tableInstance = useTable(
    { columns, data, initialState },
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps, rows }) => {
            const isAllSelected = rows.every((row) => row.isSelected);
            const isSomeSelected = rows.some((row) => row.isSelected);

            return (
              <Checkbox
                {...getToggleAllRowsSelectedProps({
                  tooltip: 'Toggle all queries',
                  checked: isAllSelected,
                  indeterminate: !isAllSelected && isSomeSelected,
                  onChange: () => {
                    const toggleState = !(isAllSelected || isSomeSelected);
                    rows.forEach((row) => row.toggleRowSelected(toggleState));
                  }
                })}
              />
            );
          },
          Cell: ({ row }) => {
            switch (row.depth) {
              case 0:
                return <ComponentIcon component={row.values.item} size="32" />;

              case 2:
                return (
                  <Checkbox
                    {...row.getToggleRowSelectedProps({
                      tooltip: row.isSelected ? 'Deselect row' : 'Select row',
                      onChange: (checked) => row.toggleRowSelected(checked)
                    })}
                  />
                );

              default:
                return null;
            }
          }
        },
        ...columns
      ]);
    }
  );

  return (
    <div {...tableInstance.getTableProps({ className: 'table table-hover react-table' })}>
      <div className="thead">
        {tableInstance.headerGroups.map((headerGroup, index) => (
          <div key={index} {...headerGroup.getHeaderGroupProps({ className: 'tr' })}>
            {headerGroup.headers.map((column) => {
              const userCellProps = {
                className: classnames('th', {
                  'w-52': column.id === 'selection',
                  'pt-0 pb-0 pr-1': column.id === 'action'
                })
              };

              if (column.id === 'selection') {
                userCellProps.onClick = onClickSelectionCell;
              }

              return (
                <div key={column.id} {...column.getHeaderProps(userCellProps)}>
                  {column.render('Header')}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div {...tableInstance.getTableBodyProps({ className: 'tbody' })}>
        {tableInstance.rows.map((row) => {
          tableInstance.prepareRow(row);

          const expandProps = {};

          if (row.canExpand) {
            expandProps.onClick = (e) => {
              e.stopPropagation();
              row.toggleRowExpanded(!!!row.isExpanded);
            };
          }

          return (
            <div
              key={row.id}
              {...row.getRowProps({
                className: classnames('tr hoverable-actions', { clickable: row.canExpand }),
                ...expandProps
              })}
            >
              {row.cells.map((cell, index) => {
                let userCellProps = {
                  className: classnames('td', {
                    'w-52': cell.column.id === 'selection',
                    'pt-0 pb-0 pr-1': cell.column.id === 'action'
                  })
                };

                if (cell.column.id === 'selection') {
                  userCellProps.onClick = onClickSelectionCell;
                }

                return (
                  <div key={index} {...cell.getCellProps(userCellProps)}>
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const IndexTable = ({ allConfigurations, allComponents, searchQuery, handleAdd }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: ({ selectedFlatRows }) => {
          const selected = getAllSelectedRows(selectedFlatRows).filter(
            (row) => row.depth === 2
          ).length;

          if (selected === 0) {
            return <span className="text-muted">No queries selected</span>;
          }

          return <>{selected} queries selected</>;
        },
        Cell: ({ row }) => {
          switch (row.depth) {
            case 0:
              return <ComponentRow row={row} searchQuery={searchQuery} />;

            case 1:
              return <Config row={row} searchQuery={searchQuery} handleAdd={handleAdd} />;

            case 2:
              return <ConfigRow row={row} searchQuery={searchQuery} handleAdd={handleAdd} />;

            default:
              return null;
          }
        },
        accessor: 'item'
      },
      {
        Header: ({ selectedFlatRows, toggleAllRowsSelected }) => {
          const selected = getAllSelectedRows(selectedFlatRows)
            .filter((row) => row.depth === 2)
            .map((row) => ({ source: { tags: row.values.item.get('tags') } }));

          return (
            <Button
              bsSize="sm"
              bsStyle="success"
              disabled={selected.length === 0}
              onClick={() => {
                handleAdd(fromJSOrdered(selected));
                toggleAllRowsSelected(false);
              }}
            >
              <FontAwesomeIcon icon="plus" className="icon-addon-right" />
              Add selected
            </Button>
          );
        },
        Cell: ({ row }) => {
          switch (row.depth) {
            case 0:
              return null;

            case 1:
              return (
                <Tooltip
                  placement="top"
                  type="explanatory"
                  tooltip={
                    row.values.item.get('isTransformation')
                      ? 'Configuration will be used.'
                      : 'All rows from configuration will be used.'
                  }
                >
                  <Button
                    bsSize="sm"
                    bsStyle="success"
                    onClick={(e) => {
                      e.stopPropagation();

                      handleAdd(fromJSOrdered([{ source: { tags: row.values.item.get('tags') } }]));
                    }}
                  >
                    <FontAwesomeIcon icon="plus" style={{ fontSize: '13px' }} />
                  </Button>
                </Tooltip>
              );

            case 2:
              return (
                <Tooltip placement="top" tooltip="Row will be used." type="explanatory">
                  <Button
                    bsSize="sm"
                    bsStyle="success"
                    onClick={() => {
                      handleAdd(fromJSOrdered([{ source: { tags: row.values.item.get('tags') } }]));
                    }}
                  >
                    <FontAwesomeIcon icon="plus" style={{ fontSize: '13px' }} />
                  </Button>
                </Tooltip>
              );

            default:
              return null;
          }
        },
        accessor: 'action'
      }
    ],
    [searchQuery, handleAdd]
  );

  const memoizedRows = React.useMemo(
    () =>
      allConfigurations
        .map((component, componentId) => {
          return {
            item: allComponents.get(componentId),
            action: null,
            matchOnlyName: component.get('matchOnlyName', false),
            subRows: component
              .get('configurations')
              .sortBy((config) => config.get('name').toLowerCase())
              .map((config, configId) => {
                return {
                  item: fromJSOrdered({
                    tags: getTagsForComponentConfiguration(componentId, configId),
                    name: config.get('name'),
                    isTransformation: component.get('type') === componentTypes.TRANSFORMATION,
                    fileTags: config
                      .getIn(['configuration', 'storage', 'output', 'files'], List())
                      .reduce((tags, mapping) => tags.concat(mapping.get('tags')), List())
                      .filter(Boolean)
                  }),
                  action: null,
                  matchOnlyName: config.get('matchOnlyName', false),
                  subRows: config
                    .get('rows', List())
                    .sortBy((row) => row.get('name').toLowerCase())
                    .map((row) => {
                      return {
                        item: fromJSOrdered({
                          tags: getTagsForComponentConfigurationRow(
                            componentId,
                            configId,
                            row.get('id')
                          ),
                          name: row.get('name'),
                          fileTags: row
                            .getIn(
                              ['configuration', 'storage', 'output', 'table_files', 'tags'],
                              List()
                            )
                            .concat(
                              row
                                .getIn(['configuration', 'storage', 'output', 'files'], List())
                                .reduce((tags, mapping) => tags.concat(mapping.get('tags')), List())
                            )
                            .filter(Boolean)
                        }),
                        action: null
                      };
                    })
                    .toArray()
                };
              })
              .toArray()
          };
        })
        .toArray(),
    [allConfigurations, allComponents]
  );

  return (
    <div className="box">
      <Table columns={columns} data={memoizedRows} searchQuery={searchQuery} />
    </div>
  );
};

IndexTable.propTypes = {
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  allComponents: PropTypes.instanceOf(Map).isRequired,
  handleAdd: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired
};

const MemoizedTable = React.memo(IndexTable, shallowEqualImmutable);

export default MemoizedTable;
