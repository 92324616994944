import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../../../react/common/Loader';
import Tooltip from '../../../react/common/Tooltip';

const CreditsLoadingTooltip = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <Tooltip placement="top" tooltip="Loading credits balance...">
      <span style={{ fontSize: '0.5em', verticalAlign: 'middle' }}>
        <Loader className="icon-addon-left text-muted" />
      </span>
    </Tooltip>
  );
};

CreditsLoadingTooltip.propTypes = {
  show: PropTypes.bool.isRequired
};

export default CreditsLoadingTooltip;
