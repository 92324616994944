import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ActionCreators from '../../ActionCreators';
import RStudioSandboxCredentialsStore from '../../stores/RStudioSandboxCredentialsStore';
import ExtendCredentialsButton from './ExtendCredentialsButton';

const ExtendRStudioCredentials = createReactClass({
  mixins: [createStoreMixin(RStudioSandboxCredentialsStore)],

  propTypes: {
    canExtend: PropTypes.bool.isRequired,
    buttonStyle: PropTypes.string.isRequired
  },

  getStateFromStores() {
    return {
      isLoaded: RStudioSandboxCredentialsStore.getIsLoaded(),
      isExtending: RStudioSandboxCredentialsStore.getPendingActions().get('extend', false),
      isDisabled: RStudioSandboxCredentialsStore.getPendingActions().count() > 0
    };
  },

  render() {
    if (!this.state.isLoaded) {
      return null;
    }

    return (
      <ExtendCredentialsButton
        isExtending={this.state.isExtending}
        onExtend={ActionCreators.extendRStudioSandboxCredentials}
        disabled={this.state.isDisabled}
        canExtend={this.props.canExtend}
        buttonStyle={this.props.buttonStyle}
      />
    );
  }
});

export default ExtendRStudioCredentials;
