import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import Select from '../../../../react/common/Select';
import { DatasetLocations } from '../../constants';

const Unload = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      storage: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    const { value, onChange, disabled } = this.props;
    return (
      <Form horizontal>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Cloud Storage Bucket Name
          </Col>
          <Col sm={8}>
            <FormControl
              type="text"
              disabled={disabled}
              onChange={(e) => onChange({ storage: e.target.value.trim() })}
              value={value.storage}
              placeholder="my-cloud-storage-bucket"
            />
            <HelpBlock>
              Existing Google Cloud Storage bucket. There will be data temporarily exported, before
              load to KBC.
            </HelpBlock>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={4}>
            Dataset Location
          </Col>
          <Col sm={8}>
            <Select
              clearable={false}
              value={value.location}
              disabled={disabled}
              onChange={(value) => onChange({ location: value })}
              options={DatasetLocations}
            />
            <HelpBlock>The geographic location where dataset exists.</HelpBlock>
          </Col>
        </FormGroup>
      </Form>
    );
  }
});

export default Unload;
