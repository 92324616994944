import { KEBOOLA_SANDBOXES, TRANSFORMATION } from '../../constants/componentIds';
import { hasGenericDockerUI, hasGenericTemplatesUI, hasGenericUI } from '../components/helpers';
import ComponentsStore from '../components/stores/ComponentsStore';

const getDisabledRestoreReason = (componentId) => {
  if (componentId === KEBOOLA_SANDBOXES) {
    return 'Workpaces are shortlived interactive development environments. Once deleted they cannot be restored.';
  }

  if (isObsoleteComponent(componentId)) {
    return 'The configuration of an obsolete component cannot be restored.';
  }

  return '';
};

const hasDisabledRestore = (componentId) => !!getDisabledRestoreReason(componentId);

const isObsoleteComponent = (componentId) => {
  let component = ComponentsStore.getComponent(componentId);

  if (!component || [TRANSFORMATION, KEBOOLA_SANDBOXES].includes(componentId)) {
    return false;
  }

  return (
    component.get('uri') &&
    !hasGenericUI(component) &&
    !hasGenericDockerUI(component) &&
    !hasGenericTemplatesUI(component)
  );
};

export { getDisabledRestoreReason, hasDisabledRestore, isObsoleteComponent };
