import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import DeleteButton from '../../../../react/common/DeleteButton';
import Loader from '../../../../react/common/Loader';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import RunComponentButton from '../../../components/react/components/RunComponentButton';
import StorageBucketsStore from '../../../components/stores/StorageBucketsStore';
import StorageTablesStore from '../../../components/stores/StorageTablesStore';
import CredentialsActionCreators from '../../../provisioning/ActionCreators';
import SnowflakeCredentials from '../../../provisioning/react/components/SnowflakeCredentials';
import SnowflakeSandboxCredentialsStore from '../../../provisioning/stores/SnowflakeSandboxCredentialsStore';
import ConfigureSandbox from './ConfigureSandbox';

const SnowflakeSandbox = createReactClass({
  mixins: [
    createStoreMixin(SnowflakeSandboxCredentialsStore, StorageBucketsStore, StorageTablesStore)
  ],

  propTypes: {
    readOnly: PropTypes.bool.isRequired
  },

  getStateFromStores() {
    return {
      credentials: SnowflakeSandboxCredentialsStore.getCredentials(),
      pendingActions: SnowflakeSandboxCredentialsStore.getPendingActions(),
      isLoading: SnowflakeSandboxCredentialsStore.getIsLoading(),
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll()
    };
  },

  getInitialState() {
    return {
      sandboxConfiguration: Map()
    };
  },

  _renderCredentials() {
    return (
      <SnowflakeCredentials
        credentials={this.state.credentials}
        isCreating={this.state.pendingActions.get('create')}
      />
    );
  },

  _renderControlButtons() {
    if (this.props.readOnly) {
      return null;
    }

    const connectLink =
      'https://' +
      this.state.credentials.get('hostname') +
      '/console/login#/?returnUrl=sql/worksheet';
    if (this.state.credentials.get('id')) {
      return (
        <>
          <div>
            <RunComponentButton
              forceModal
              component="transformation"
              method="create-sandbox"
              title="Load tables into Snowflake sandbox"
              mode="button"
              label="Load data"
              runParams={this.getRunParams}
              onEnter={this.resetSandboxConfiguration}
              disabled={this.state.pendingActions.count() > 0}
              modalRunButtonDisabled={
                this.state.sandboxConfiguration.get('include', List()).count() === 0
              }
            >
              <ConfigureSandbox
                backend="snowflake"
                tables={this.state.tables}
                buckets={this.state.buckets}
                onChange={this.updateSandboxConfiguration}
              />
            </RunComponentButton>
          </div>
          <div>
            <form action={connectLink} target="_blank">
              <button
                type="submit"
                className="btn btn-link"
                disabled={this.state.pendingActions.count() > 0}
              >
                <FontAwesomeIcon icon="database" className="icon-addon-right" />
                Connect
              </button>
            </form>
            <div>
              <DeleteButton
                tooltip="Delete Snowflake Sandbox"
                isPending={this.state.pendingActions.get('drop')}
                pendingLabel="Deleting sandbox"
                isEnabled={this.state.pendingActions.count() === 0}
                label="Drop sandbox"
                confirm={{
                  title: 'Delete Snowflake Sandbox',
                  text: 'Are you sure you want to delete the Snowflake sandbox?',
                  onConfirm: this._dropCredentials
                }}
              />
            </div>
          </div>
        </>
      );
    } else if (!this.state.pendingActions.get('create')) {
      return (
        <button className="btn btn-link" onClick={this._createCredentials}>
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          New Sandbox
        </button>
      );
    }
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">SQL (Snowflake)</h2>
        </div>
        <div className="box-content">
          {this.state.isLoading ? (
            <>
              <Loader /> Loading...
            </>
          ) : (
            <div className="row">
              <div className="col-md-6">{this._renderCredentials()}</div>
              <div className="col-md-3 col-md-offset-3">{this._renderControlButtons()}</div>
            </div>
          )}
        </div>
      </div>
    );
  },

  _createCredentials() {
    return CredentialsActionCreators.createSnowflakeSandboxCredentials();
  },

  _dropCredentials() {
    return CredentialsActionCreators.dropSnowflakeSandboxCredentials();
  },

  getRunParams() {
    return this.state.sandboxConfiguration.toJS();
  },

  updateSandboxConfiguration(params) {
    this.setState({ sandboxConfiguration: fromJS(params) });
  },

  resetSandboxConfiguration() {
    this.setState({ sandboxConfiguration: Map() });
  }
});

export default SnowflakeSandbox;
