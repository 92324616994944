import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { calculateDuration, timeInWords } from '../../utils/duration';
import DurationUntillNow from './DurationUntillNow';

const Duration = (props: {
  startTime?: string;
  endTime?: string;
  duration?: number;
  showIcon?: boolean;
}) => {
  if (props.duration) {
    return (
      <span>
        {props.showIcon && <FontAwesomeIcon icon="clock" className="icon-addon-right" />}
        {timeInWords(Math.round(props.duration))}
      </span>
    );
  }

  if (props.startTime) {
    return (
      <span>
        {props.showIcon && <FontAwesomeIcon icon="clock" className="icon-addon-right" />}
        {props.endTime ? (
          timeInWords(calculateDuration(props.startTime, props.endTime), true)
        ) : (
          <DurationUntillNow startTime={props.startTime} />
        )}
      </span>
    );
  }

  return <span>N/A</span>;
};

export default Duration;
