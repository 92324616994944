import { Dispatcher } from 'flux';

import { PayloadSources } from './constants/KbcConstants';

class KbcDispatcher extends Dispatcher {
  handleViewAction(action) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(action); // eslint-disable-line
    }

    this.dispatch({
      source: PayloadSources.VIEW_ACTION,
      action
    });
  }
}

export default new KbcDispatcher();
