import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List } from 'immutable';
import { truncate } from 'underscore.string';

import Popover from '../../../../react/common/Popover';

class ColumnDataPreview extends React.PureComponent {
  render() {
    const columnIndex = this.props.tableData?.get('columns', List()).indexOf(this.props.columnName);

    return (
      <Popover
        title={`Preview - ${this.props.columnName}`}
        className="overflow-break-anywhere"
        popover={
          columnIndex > -1 ? (
            <ul className="container-fluid">
              {this.props.tableData
                .get('rows', List())
                .map((row) => row.getIn([columnIndex, 'value']))
                .toSet()
                .slice(0, 10)
                .map((value, index) => <li key={index}>{truncate(value, 100)}</li>)
                .toArray()}
            </ul>
          ) : this.props.loading ? (
            'Loading data...'
          ) : (
            'No data available'
          )
        }
      >
        <Button bsStyle="link" className="btn-link-inline btn-link-muted">
          <FontAwesomeIcon icon="eye" fixedWidth />
        </Button>
      </Popover>
    );
  }
}

ColumnDataPreview.propTypes = {
  columnName: PropTypes.string.isRequired,
  tableData: PropTypes.object,
  loading: PropTypes.bool
};

export default ColumnDataPreview;
