import React from 'react';

import dayjs, {
  DATE_FORMAT,
  DATE_FORMAT_WITHOUT_YEAR,
  TIME_FORMAT,
  TIME_FORMAT_WITH_SECONDS
} from '../../date';

type Props = {
  createdTime?: string;
  fallback?: React.ReactNode;
  className?: string;
  absolute?: boolean;
  relative?: boolean;
  withSeconds?: boolean;
};

export const formatAbsolute = (createdTime: string, withSeconds?: boolean) => {
  const date = dayjs(createdTime);
  const timeFormat = withSeconds ? TIME_FORMAT_WITH_SECONDS : TIME_FORMAT;

  if (date.year() === dayjs().year()) {
    return date.format(`${DATE_FORMAT_WITHOUT_YEAR} ${timeFormat}`);
  }

  return date.format(`${DATE_FORMAT} ${timeFormat}`);
};

const CreatedDate = ({
  createdTime,
  withSeconds,
  absolute,
  relative,
  fallback,
  className
}: Props) => {
  if (!createdTime) {
    return <span className={className}>{fallback || 'N/A'}</span>;
  }

  const renderRelative = () => {
    return (
      <span className={className} title={formatAbsolute(createdTime, withSeconds)}>
        {dayjs(createdTime).fromNow()}
      </span>
    );
  };

  const renderAbsolute = () => {
    return <span className={className}>{formatAbsolute(createdTime, withSeconds)}</span>;
  };

  if (relative) {
    return renderRelative();
  }

  return absolute || dayjs(createdTime).isBefore(dayjs().subtract(1, 'day'))
    ? renderAbsolute()
    : renderRelative();
};

export default CreatedDate;
