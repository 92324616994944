import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';
import qs from 'qs';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ExternalLink from '../../../react/common/ExternalLink';

const ANALYTICS_BASE_URLS = [
  'https://ga-dev-tools.appspot.com/query-explorer/?',
  'https://www.googleapis.com/analytics/v3/data/ga?'
];

const INITIAL_STATE = {
  url: '',
  parsed: Map(),
  errors: []
};

const UrlParserModal = createReactClass({
  propTypes: {
    onCancel: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onCancel}
        onEnter={() => this.setState(INITIAL_STATE)}
      >
        <Form onSubmit={this.setAndClose} horizontal>
          <Modal.Header closeButton>
            <Modal.Title>Parse Url and Set Query</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Create a query via{' '}
              <ExternalLink href="https://ga-dev-tools.appspot.com/query-explorer/">
                Google Analytics Query Explorer
              </ExternalLink>{' '}
              and paste the result url to reconstruct the query.
            </p>
            <FormGroup>
              <Col sm={3} componentClass={ControlLabel}>
                Url
              </Col>
              <Col sm={9}>
                <FormControl type="text" value={this.state.url} onChange={this.onUrlChange} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3} componentClass={ControlLabel}>
                Parsed Query
              </Col>
              <Col
                sm={9}
                className={classnames({ 'pre-scrollable': !this.state.parsed.isEmpty() })}
              >
                {!this.state.parsed.isEmpty() ? (
                  this.renderParsedQuery()
                ) : (
                  <FormControl.Static>N/A</FormControl.Static>
                )}
              </Col>
            </FormGroup>
            {this.renderErrors()}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel="Set"
              saveStyle="primary"
              saveButtonType="submit"
              isDisabled={this.isDisabled()}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  parseArray(name, item) {
    return (
      <li>
        <strong>{name}:</strong> {item ? item.join(', ') : 'N/A'}
      </li>
    );
  },

  renderParsedQuery() {
    const range = this.state.parsed.get('dateRanges').first() || Map();

    return (
      <div>
        <ul>
          {this.parseArray('Metrics', this.state.parsed.get('metrics'))}
          {this.parseArray('Dimensions', this.state.parsed.get('dimensions'))}
          {this.parseArray('Segments', this.state.parsed.get('segments'))}
          <li>
            <strong>Filter:</strong> {this.state.parsed.get('filtersExpression') || 'N/A'}
          </li>
          <li>
            <strong>Date Ranges:</strong>
            <ul>
              <li>Start Date: {range.get('startDate') || 'N/A'}</li>
              <li>End Date: {range.get('endDate') || 'N/A'}</li>
            </ul>
          </li>
        </ul>
      </div>
    );
  },

  renderErrors() {
    if (!this.state.errors.length) {
      return null;
    }

    return (
      <Alert bsStyle="danger">
        {this.state.errors.map((error, index) => (
          <p key={index}>{error}</p>
        ))}
      </Alert>
    );
  },

  onUrlChange(e) {
    this.setState({
      url: e.target.value,
      parsed: this.parseUrl(e.target.value.trim())
    });
  },

  parseUrl(url) {
    if (!ANALYTICS_BASE_URLS.some((baseUrl) => url.includes(baseUrl))) {
      this.setState({
        errors: url
          ? ['The URL is not a valid direct link to a report from Google Analytics Query Explorer.']
          : []
      });
      return Map();
    }

    const urlSearchParams = new URLSearchParams(new URL(url).search);
    return this.prepareQuery(qs.parse(urlSearchParams.toString()));
  },

  prepareQuery(parsedParams) {
    const errors = [];
    const metrics = this.safeSplit(parsedParams.metrics);
    const dimensions = this.safeSplit(parsedParams.dimensions);

    if (!metrics || !metrics.length) {
      errors.push('Missing required attribute "metrics".');
    }

    if (!dimensions || !dimensions.length) {
      errors.push('Missing required attribute "dimensions".');
    }

    this.setState({ errors });

    return fromJS({
      metrics,
      dimensions,
      segments: parsedParams.segment ? [parsedParams.segment] : [],
      filtersExpression: parsedParams.filters,
      dateRanges: [
        {
          startDate: parsedParams['start-date'],
          endDate: parsedParams['end-date']
        }
      ]
    });
  },

  safeSplit(value) {
    if (!value) return [];
    return value.split(',');
  },

  setAndClose(e) {
    e.preventDefault();

    this.props.onSave(
      this.state.parsed
        .update('metrics', List(), (metrics) => metrics.map((name) => fromJS({ expression: name })))
        .update('dimensions', List(), (dimensions) => dimensions.map((name) => fromJS({ name })))
        .update('segments', List(), (segments) =>
          segments.map((segmentId) => fromJS({ segmentId }))
        )
    );
    this.props.onCancel();
  },

  isDisabled() {
    if (this.state.parsed.isEmpty()) {
      return true;
    }

    return (
      this.state.parsed.get('metrics', List()).isEmpty() ||
      this.state.parsed.get('dimensions', List()).isEmpty()
    );
  }
});

export default UrlParserModal;
