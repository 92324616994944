import React from 'react';
import createReactClass from 'create-react-class';

import { KEBOOLA_PROJECT_BACKUP } from '../../constants/componentIds';
import { USER_DOCUMENTATION_URL } from '../../constants/KbcConstants';
import ExternalLink from '../../react/common/ExternalLink';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import actionCreators from '../components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import Form from './Form';

const Index = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore)],

  getStateFromStores() {
    return {
      readOnly: ApplicationStore.isReadOnly()
    };
  },

  getInitialState() {
    return {
      isSaving: false
    };
  },

  render() {
    return (
      <div className="box">
        <div className="box-content">
          <p>
            You can export this project to{' '}
            <ExternalLink href="https://aws.amazon.com/s3/">Amazon S3</ExternalLink> or{' '}
            <ExternalLink href="https://azure.microsoft.com/services/storage/blobs/">
              Azure Blob Storage
            </ExternalLink>
            .
          </p>
          <p>
            Export will contain all bucket and table metadata, all table data exported to gzipped
            CSV files, and all component configurations. Read more in{' '}
            <ExternalLink href={`${USER_DOCUMENTATION_URL}/management/project/export/`}>
              Data Takeout documentation
            </ExternalLink>
            .
          </p>
          <hr />
          <Form
            readOnly={this.state.readOnly}
            onSubmit={this.handleSubmit}
            isSaving={this.state.isSaving}
          />
        </div>
      </div>
    );
  },

  handleSubmit(parameters) {
    this.setState({ isSaving: true });
    actionCreators
      .runComponent({
        component: KEBOOLA_PROJECT_BACKUP,
        data: { configData: { parameters } }
      })
      .finally(() => this.setState({ isSaving: false }));
  }
});

export default Index;
