import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RouterLink from '../../../react/common/RouterLink';
import { routeNames } from '../constants';
import DocumentationButton from '../DocumentationButton';

const AddNewTemplateHeader = () => {
  return (
    <ButtonToolbar>
      <DocumentationButton />
      <RouterLink to={routeNames.TEMPLATES} className="btn btn-success">
        <FontAwesomeIcon icon="plus" className="icon-addon-right" /> Add Template
      </RouterLink>
    </ButtonToolbar>
  );
};

export default AddNewTemplateHeader;
