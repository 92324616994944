import React from 'react';
import PropTypes from 'prop-types';

import Loader from './Loader';

const BoxLoader = ({ entity }) => {
  return (
    <div className="box">
      <div className="box-content">
        <Loader /> Loading {entity}, please wait...
      </div>
    </div>
  );
};

BoxLoader.propTypes = {
  entity: PropTypes.string.isRequired
};

export default BoxLoader;
