import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import ConfirmModal from '../../../react/common/ConfirmModal';
import Loader from '../../../react/common/Loader';
import Tooltip from '../../../react/common/Tooltip';
import { removeAliasTableFilter, setAliasTableFilter } from '../actions';
import AliasFilterModal from './AliasFilterModal';

const TableAliasFilter = createReactClass({
  propTypes: {
    table: PropTypes.object.isRequired,
    canEdit: PropTypes.bool.isRequired,
    settingAliasFilter: PropTypes.bool.isRequired,
    removingAliasFilter: PropTypes.bool.isRequired
  },

  getInitialState() {
    return {
      showUpdateModal: false,
      showRemoveModal: false
    };
  },

  render() {
    const filter = this.props.table.get('aliasFilter', Map());

    return (
      <>
        <span>
          Alias filter
          {this.renderEditButton(filter)}
          {this.renderDeleteButton(filter)}
        </span>
        <span className="font-normal text-muted">{this.renderLabel(filter)}</span>
      </>
    );
  },

  renderLabel(filter) {
    if (!filter.count()) {
      return <span className="ml-auto">No filter set</span>;
    }

    return (
      <span className="ml-auto">
        Where <b>{filter.get('column')}</b> {this.whereOperator(filter)} {this.renderValues(filter)}
      </span>
    );
  },

  renderEditButton(filter) {
    if (!this.props.canEdit) {
      return null;
    }

    return (
      <span className="icon-addon-left">
        <Tooltip tooltip={filter.count() > 0 ? 'Edit filter' : 'Set filter'} placement="top">
          <Button
            bsStyle="link"
            className="btn-link-inline text-muted"
            onClick={this.openUpdateModal}
            disabled={this.props.settingAliasFilter || this.props.removingAliasFilter}
          >
            {this.props.settingAliasFilter ? <Loader /> : <FontAwesomeIcon icon="pen" />}
          </Button>
        </Tooltip>
        {this.renderAliasFilterModal()}
      </span>
    );
  },

  renderDeleteButton(filter) {
    if (!this.props.canEdit || !filter.count()) {
      return null;
    }

    return (
      <span className="icon-addon-left">
        <Tooltip tooltip="Remove filter" placement="top">
          <Button
            bsStyle="link"
            className="btn-link-inline text-muted"
            onClick={this.openRemoveModal}
            disabled={this.props.settingAliasFilter || this.props.removingAliasFilter}
          >
            {this.props.removingAliasFilter ? <Loader /> : <FontAwesomeIcon icon="trash" />}
          </Button>
        </Tooltip>
        {this.renderDeleteModal()}
      </span>
    );
  },

  renderAliasFilterModal() {
    return (
      <AliasFilterModal
        key={this.props.table.get('lastChangeDate')}
        show={this.state.showUpdateModal}
        onSubmit={this.handleSubmit}
        onHide={this.closeUpdateModal}
        isSaving={this.props.settingAliasFilter}
        table={this.props.table}
      />
    );
  },

  renderDeleteModal() {
    return (
      <ConfirmModal
        show={this.state.showRemoveModal}
        onHide={this.closeRemoveModal}
        icon="trash"
        title="Remove alias filter"
        text={<p>Are you sure you want to remove the filter?</p>}
        buttonLabel="Delete"
        buttonType="danger"
        onConfirm={this.handleRemoveFilter}
      />
    );
  },

  handleSubmit(params) {
    const tableId = this.props.table.get('id');
    return setAliasTableFilter(tableId, params);
  },

  handleSetFilter(filterOptions) {
    const tableId = this.props.table.get('id');
    const params = {
      filterOptions
    };
    return setAliasTableFilter(tableId, params);
  },

  handleRemoveFilter() {
    const tableId = this.props.table.get('id');
    return removeAliasTableFilter(tableId);
  },

  openUpdateModal() {
    this.setState({
      showUpdateModal: true
    });
  },

  closeUpdateModal() {
    this.setState({
      showUpdateModal: false
    });
  },

  openRemoveModal() {
    this.setState({
      showRemoveModal: true
    });
  },

  closeRemoveModal() {
    this.setState({
      showRemoveModal: false
    });
  },

  whereOperator(filter) {
    const operator = filter.get('operator');
    const valuesCount = filter.get('values').count();

    if (operator === 'eq' && valuesCount <= 1) {
      return 'is';
    }

    if (operator === 'eq' && valuesCount > 1) {
      return 'in';
    }

    if (operator === 'ne' && valuesCount <= 1) {
      return 'is not';
    }

    return 'not in';
  },

  renderValues(filter) {
    return filter
      .get('values')
      .map((value, index) => {
        if (value === '') {
          return <code key={index}>%_EMPTY_STRING_%</code>;
        }

        if (value === ' ') {
          return <code key={index}>%_SPACE_CHARACTER_%</code>;
        }

        return <React.Fragment key={index}>{value}</React.Fragment>;
      })
      .reduce((prev, curr) => [prev, ', ', curr]);
  }
});

export default TableAliasFilter;
