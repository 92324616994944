import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from './Checkbox';

const MultiActionsHeader = ({
  hide,
  disabled,
  totalCount,
  selectedCount,
  onToggleAll,
  children,
  placeholder,
  entity
}) => {
  if (hide) {
    return placeholder;
  }

  const isAllSelected = !!selectedCount && selectedCount === totalCount;
  const isSomeSelected = !!selectedCount && selectedCount !== totalCount;

  return (
    <div className="flex-container flex-start tw-whitespace-nowrap">
      <Checkbox
        className="mr-1"
        tooltip={`${isAllSelected || isSomeSelected ? 'Deselect' : 'Select'} all ${entity}s`}
        checked={isAllSelected}
        onChange={onToggleAll}
        indeterminate={!!selectedCount && !isAllSelected}
        disabled={disabled}
      />
      {selectedCount ? (
        <>
          <strong>
            {selectedCount} {entity}
            {selectedCount > 1 ? 's ' : ' '}
            selected
          </strong>
          {children}
        </>
      ) : (
        placeholder
      )}
    </div>
  );
};

MultiActionsHeader.propTypes = {
  hide: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  selectedCount: PropTypes.number.isRequired,
  onToggleAll: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.node.isRequired,
  entity: PropTypes.string.isRequired
};

export default MultiActionsHeader;
