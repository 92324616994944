import React from 'react';
import { ButtonToolbar, MenuItem } from 'react-bootstrap';
import Promise from 'bluebird';
import createReactClass from 'create-react-class';
import { fromJS, List, Map, OrderedMap } from 'immutable';
import Switch from 'rc-switch';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '../../constants/componentIds';
import CatchUnsavedChanges from '../../react/common/CatchUnsavedChanges';
import DescriptionButton from '../../react/common/DescriptionButton';
import DescriptionModal from '../../react/common/DescriptionModal';
import JobTerminateButton from '../../react/common/JobTerminateButton';
import SaveButtons from '../../react/common/SaveButtons';
import Tooltip from '../../react/common/Tooltip';
import createStoreMixin from '../../react/mixins/createStoreMixin';
import ApplicationStore from '../../stores/ApplicationStore';
import RoutesStore from '../../stores/RoutesStore';
import nextTick from '../../utils/nextTick';
import Timer from '../../utils/Timer';
import InstalledComponentsActionCreators from '../components/InstalledComponentsActionCreators';
import RunComponentButton from '../components/react/components/RunComponentButton';
import ComponentsStore from '../components/stores/ComponentsStore';
import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import BucketsStore from '../components/stores/StorageBucketsStore';
import TablesStore from '../components/stores/StorageTablesStore';
import OneTimeNotificationButton from '../notifications/components/OneTimeNotificationButton';
import NotificationsStore from '../notifications/store';
import { getConfigDescription } from '../openai/api';
import { prepareOrchestration } from '../orchestrations-v2/helpers';
import {
  clearLocalState,
  getLocalStateValue,
  updateLocalStateValue
} from '../orchestrations-v2/localState';
import JobsActionCreators from '../queue/actions';
import { JOB_FAILED_STATUSES, JOB_FINISHED_STATUSES } from '../queue/constants';
import JobsStore from '../queue/store';
import RunSelectedModal from './components/RunSelectedModal';
import TaskIcon from './components/TaskIcon';
import TaskName from './components/TaskName';
import { saveFlow } from './actions';
import {
  filterDisabledTasks,
  getRunningFlowStatus,
  jobVersionMatch,
  prepareSelectedTasks,
  prepareTask,
  prepareVisualizationPhases,
  shouldAllowRunFlow
} from './helpers';

const DetailHeader = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      TablesStore,
      BucketsStore,
      InstalledComponentsStore,
      ComponentsStore,
      JobsStore
    )
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const activeTab = RoutesStore.getCurrentRouteParam('tab');
    const config = InstalledComponentsStore.getConfig(KEBOOLA_ORCHESTRATOR, configId);
    const tasksFromConfig = config.getIn(['configuration', 'tasks'], List());
    const phasesFromConfig = config.getIn(['configuration', 'phases'], List());
    const tasks = getLocalStateValue(configId, ['tasks'], tasksFromConfig);
    const phases = getLocalStateValue(configId, ['phases'], phasesFromConfig);
    const phasesWithSomeTasks = phases.filter((phase) => {
      return tasks.some((task) => task.get('phase') === phase.get('id'));
    });
    const isChanged =
      !tasks.equals(tasksFromConfig) || !phasesWithSomeTasks.equals(phasesFromConfig);

    const allJobs = JobsStore.getAll();
    const latestJobs =
      JobsStore.getLatestJobs(KEBOOLA_ORCHESTRATOR, configId).get('jobs') ?? OrderedMap();
    const latestJob = latestJobs.first();
    const runningJob = !isChanged
      ? getLocalStateValue(
          configId,
          ['runningJob'],
          latestJob && jobVersionMatch(config, latestJob) ? latestJob : null
        )
      : null;
    const flowStatus = runningJob ? getRunningFlowStatus(allJobs, runningJob) : null;
    const flow = prepareOrchestration(
      config,
      InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SCHEDULER)
    );

    const allComponents = ComponentsStore.getAll();
    const allInstalledComponents = InstalledComponentsStore.getAll();
    const deletedComponents = InstalledComponentsStore.getAllDeleted();
    const visualizationPhases = prepareVisualizationPhases(
      phases,
      tasks,
      allComponents,
      allInstalledComponents,
      deletedComponents
    );

    return {
      activeTab,
      configId,
      config,
      flow,
      phases,
      tasks,
      visualizationPhases,
      components: allComponents,
      installedComponents: allInstalledComponents,
      deletedComponents,
      runningJob,
      flowStatus,
      isChanged,
      latestJob,
      tables: TablesStore.getAll(),
      buckets: BucketsStore.getAll(),
      shouldAllowRunFlow: shouldAllowRunFlow(tasks),
      notifications: NotificationsStore.getAll(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      sapiToken: ApplicationStore.getSapiToken(),
      readOnly: ApplicationStore.isReadOnly(),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      jobsTerminatingPendingActions: JobsStore.getPendingActions().get('terminating', Map())
    };
  },

  getInitialState() {
    return {
      isSaving: false,
      isTerminating: false,
      showRunModalAs: null, // 'empty' | 'retry'
      showDescriptionModal: false
    };
  },

  componentDidMount() {
    // if user enters flow that is running it should start polling
    if (this.state.flowStatus?.get('isRunning') && !Timer.isPolling(this.pollRunningJob)) {
      Timer.poll(this.pollRunningJob, { interval: 5, skipFirst: true });
    }
  },

  componentWillUnmount() {
    Timer.stop(this.pollRunningJob);
  },

  pollRunningJob() {
    if (!this.state.runningJob) {
      Timer.stop(this.pollRunningJob);
      return;
    }
    const runningJobId = this.state.runningJob.get('id');
    // reload the job
    return JobsActionCreators.loadJobForce(runningJobId).then(() => {
      const parentJob = JobsStore.get(runningJobId);
      // job is not done, reload its children + trigger update
      return JobsActionCreators.loadChildJobsForce(parentJob).then(() => {
        updateLocalStateValue(this.state.configId, ['runningJob'], parentJob);
        if (JOB_FINISHED_STATUSES.includes(parentJob.get('status'))) {
          // if job is done, stop polling
          Timer.stop(this.pollRunningJob);
          this.setState({ isTerminating: false });
        }
        return Promise.resolve();
      });
    });
  },

  onRunStart(job) {
    updateLocalStateValue(this.state.configId, ['runningJob'], fromJS(job));
    nextTick(() => Timer.poll(this.pollRunningJob, { interval: 5 }));
    this.setState({ isTerminating: false });
    return Promise.resolve();
  },

  render() {
    return (
      <>
        <ButtonToolbar>
          <DescriptionButton
            onClick={() => this.setState({ showDescriptionModal: true })}
            isFilled={!!this.state.flow.get('description')}
            readOnly={this.state.readOnly}
            showsInModalOnly
          />
          {this.renderSaveButton()}
          {this.renderRunButton()}
          {this.renderTerminateButton()}
          <OneTimeNotificationButton
            job={this.state.runningJob}
            notifications={this.state.notifications}
            admin={this.state.currentAdmin}
          />
        </ButtonToolbar>
        {this.renderRunSelectedModal()}
        {this.renderDescriptionModal()}
      </>
    );
  },

  renderSaveButton() {
    const hasBlankEnabledTasks = this.state.tasks.some((task) => {
      return !task.getIn(['task', 'configId']) && task.get('enabled', true);
    });

    return (
      <CatchUnsavedChanges
        key={this.state.activeTab}
        isDirty={this.state.isChanged}
        onSave={this.handleSave}
        onDirtyLeave={this.resetEditingTasks}
        text={<p>When saving, all tasks without selected configuration will be disabled.</p>}
      >
        <SaveButtons
          onReset={this.resetEditingTasks}
          isSaving={this.state.isSaving}
          onSave={this.handleSave}
          isChanged={this.state.isChanged}
          showModal={hasBlankEnabledTasks}
          modalTitle="Save Flow"
          modalBody={
            <>
              <p>You are about to save the flow.</p>
              <p>All tasks without selected configuration will be disabled.</p>
            </>
          }
        />
      </CatchUnsavedChanges>
    );
  },

  renderRunButton() {
    const disabledOrNonConfiguredTasks = this.state.config
      .getIn(['configuration', 'tasks'], List())
      .filter((savedTask) => {
        return (
          this.state.tasks.some((task) => task.get('id') === savedTask.get('id')) &&
          (!savedTask.get('enabled', true) || !savedTask.getIn(['task', 'configId']))
        );
      });

    return (
      <RunComponentButton
        component={KEBOOLA_ORCHESTRATOR}
        runParams={() => ({ config: this.state.configId })}
        disabled={this.state.isChanged || !this.state.shouldAllowRunFlow}
        disabledReason={
          this.state.isChanged
            ? 'You need to save or reset changes before running the flow.'
            : 'Flow cannot run without any configured and enabled tasks.'
        }
        label="Run flow"
        buttonIcon="circle-play"
        buttonBsStyle="success"
        forceModal={!disabledOrNonConfiguredTasks.isEmpty()}
        title={!disabledOrNonConfiguredTasks.isEmpty() ? 'Are you sure?' : void 0}
        onAfterRun={this.onRunStart}
        additionalActions={
          <>
            <MenuItem onSelect={() => this.setState({ showRunModalAs: 'empty' })}>
              Run selected tasks
            </MenuItem>
            {JOB_FAILED_STATUSES.includes(this.state.runningJob?.get('status')) && (
              <MenuItem onSelect={() => this.setState({ showRunModalAs: 'retry' })}>
                Re-run failed tasks
              </MenuItem>
            )}
          </>
        }
      >
        {!disabledOrNonConfiguredTasks.isEmpty() ? (
          <>
            <p className="mb-2">
              Following components are disabled or without a configuration, therefore they will not
              perform any action within the Flow.
            </p>
            {disabledOrNonConfiguredTasks
              .sortBy((task) => (!!task.getIn(['task', 'configId']) ? -1 : 0))
              .map(this.renderTaskRow)
              .toArray()}
          </>
        ) : (
          'You are about to run the flow.'
        )}
      </RunComponentButton>
    );
  },

  renderTerminateButton() {
    return (
      <JobTerminateButton
        isFlow
        job={this.state.runningJob}
        sapiToken={this.state.sapiToken}
        isTerminating={
          this.state.isTerminating ||
          (this.state.runningJob &&
            this.state.jobsTerminatingPendingActions.get(this.state.runningJob.get('id'), false))
        }
        onTerminate={() => {
          this.setState({ isTerminating: true });
          JobsActionCreators.terminateJob(this.state.runningJob.get('id'));
        }}
      />
    );
  },

  renderRunSelectedModal() {
    const isRetry = this.state.showRunModalAs === 'retry';

    return (
      <RunSelectedModal
        allConfigurations={this.state.installedComponents}
        hasProtectedDefaultBranch={this.state.hasProtectedDefaultBranch}
        phases={
          isRetry
            ? filterDisabledTasks(this.state.visualizationPhases)
            : this.state.visualizationPhases
        }
        status={this.state.flowStatus}
        show={!!this.state.showRunModalAs}
        isRetry={isRetry}
        onHide={() => this.setState({ showRunModalAs: null })}
        onRun={(selected, variablesOverride) => {
          return InstalledComponentsActionCreators.runComponent({
            component: KEBOOLA_ORCHESTRATOR,
            data: {
              config: this.state.configId,
              ...this.prepareRunSelectedTasksData(selected, variablesOverride)
            }
          }).then(this.onRunStart);
        }}
      />
    );
  },

  renderDescriptionModal() {
    return (
      <DescriptionModal
        entity="flow"
        readOnly={this.state.readOnly}
        show={this.state.showDescriptionModal}
        onHide={() => this.setState({ showDescriptionModal: false })}
        description={this.state.flow.get('description')}
        onSave={this.handleUpdateDescription}
        handleGenerate={() => {
          return getConfigDescription(
            this.state.components.get(KEBOOLA_ORCHESTRATOR),
            this.state.config
          );
        }}
      />
    );
  },

  renderTaskRow(savedTask) {
    const editedTask = prepareTask(
      this.state.tasks.find((task) => task.get('id') === savedTask.get('id')),
      this.state.components,
      this.state.installedComponents,
      this.state.deletedComponents
    );

    return (
      <div key={editedTask.get('id')} className="flex-container mb-1">
        <div className="flex-container flex-start">
          <TaskIcon src={editedTask.get('iconUrl')} size={48} />
          <TaskName
            isDragged={false}
            isBlank={!editedTask.get('configId')}
            isDeleted={editedTask.get('hasDeletedConfiguration')}
            name={editedTask.get('name')}
            componentName={editedTask.get('component')}
            componentType={editedTask.get('type')}
          />
        </div>
        {!!editedTask.get('configId') && (
          <Tooltip placement="top" tooltip={editedTask.get('enabled', true) ? 'Disable' : 'Enable'}>
            <Switch
              className="wider"
              prefixCls="switch"
              checked={editedTask.get('enabled', true)}
              onChange={(checked) => {
                const updatedTasks = this.state.tasks.map((task) => {
                  return task.get('id') === editedTask.get('id')
                    ? task.set('enabled', checked)
                    : task;
                });

                updateLocalStateValue(this.state.configId, 'tasks', updatedTasks);
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  },

  prepareRunSelectedTasksData(selected, variablesOverride) {
    if (this.state.hasProtectedDefaultBranch) {
      return {
        ...(this.state.showRunModalAs === 'retry' &&
          !!this.state.latestJob && {
            previousJobId: this.state.latestJob.get('id')
          }),
        onlyOrchestrationTaskIds: Object.entries(selected)
          .filter(([, value]) => value)
          .map(([key]) => key)
      };
    }

    return {
      configData: prepareSelectedTasks(
        this.state.config.get('configuration'),
        selected,
        this.state.installedComponents,
        variablesOverride
      )
    };
  },

  handleSave() {
    this.setState({ isSaving: true });
    return saveFlow(this.state.config, this.state.tasks, this.state.phases).finally(() =>
      this.setState({ isSaving: false })
    );
  },

  handleUpdateDescription(description) {
    return InstalledComponentsActionCreators.updateComponentConfiguration(
      KEBOOLA_ORCHESTRATOR,
      this.state.configId,
      { description },
      `Update description`,
      'description'
    );
  },

  resetEditingTasks() {
    clearLocalState(this.state.configId);
  }
});

export default DetailHeader;
