import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';
import OptionalFormLabel from '../../../react/common/OptionalFormLabel';

const INITIAL_STATE = {
  description: ''
};

const CreateSnapshotModal = createReactClass({
  propTypes: {
    table: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create snapshot</Modal.Title>
            <ModalIcon icon="table" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <p>
              Table settings, attributes and content will be saved in its current state. Any time
              later, you can create a perfect copy of the table elsewhere.
            </p>
            <FormGroup>
              <ControlLabel>
                Description <OptionalFormLabel />
              </ControlLabel>
              <FormControl
                autoFocus
                placeholder="Describe your snapshot"
                componentClass="textarea"
                value={this.state.description}
                onChange={this.handleDescription}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons block saveLabel="Create snapshot" saveButtonType="submit" />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  handleDescription(event) {
    this.setState({ description: event.target.value });
  },

  handleSubmit(event) {
    event.preventDefault();
    this.props.onConfirm(this.state.description);
    this.onHide();
  },

  onHide() {
    this.setState(INITIAL_STATE);
    this.props.onHide();
  }
});

export default CreateSnapshotModal;
