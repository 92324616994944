import React from 'react';
import PropTypes from 'prop-types';
import { event as d3Event, select } from 'd3-selection';
import { Map } from 'immutable';
import _ from 'underscore';
import { capitalize } from 'underscore.string';

import { formatAbsolute } from '../../../react/common/CreatedDate';
import RoutesStore from '../../../stores/RoutesStore';
import dimple from '../../../utils/dimple';
import { durationInWords } from '../../../utils/duration';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen
} from '../../../utils/windowOpen';
import { JOBS_STATUS } from '../constants';

const COLORS_MAP = {
  [JOBS_STATUS.SUCCESS]: '#07BE07',
  [JOBS_STATUS.WARNING]: '#b88d00',
  [JOBS_STATUS.TERMINATED]: '#7C8594',
  [JOBS_STATUS.ERROR]: '#EC001D'
};

class JobsGraph extends React.Component {
  graphRef = null;

  constructor(props) {
    super(props);

    this._refreshGraph = this._refreshGraph.bind(this);
  }

  componentDidMount() {
    if (!this.props.data) {
      return;
    }

    const svg = dimple.newSvg(this.graphRef, '100%', 240);
    const chart = new dimple.chart(svg, this.getJobs());

    const xAxis = chart.addCategoryAxis('x', 'index');
    xAxis.title = 'Previous runs';
    xAxis.showGridlines = false;

    const yAxis = chart.addMeasureAxis('y', 'duration');
    yAxis.hidden = true;

    const barSeries = chart.addSeries(
      ['unit', 'date', 'backendType', 'jobId', 'status'],
      dimple.plot.bar
    );
    barSeries.getTooltipText = (e) =>
      [
        `${capitalize(e.aggField[4])}${e.aggField[4] === JOBS_STATUS.SUCCESS ? '!' : ''}`,
        `Created: ${formatAbsolute(e.aggField[1])}`,
        `Duration: ${durationInWords(e.yValueList[0], e.aggField[0])}`
      ].filter(Boolean);

    if (this.props.data.get('trendData').count() > 1) {
      const trendSeries = chart.addSeries(
        ['trend-line', this.props.data.get('trend')],
        dimple.plot.line
      );
      trendSeries.data = this.props.data.get('trendData').toJS();
      trendSeries.addEventHandler('mouseover', () => null);
      trendSeries.addEventHandler('mouseleave', () => null);
    }

    chart.assignColor(JOBS_STATUS.ERROR, COLORS_MAP[JOBS_STATUS.ERROR]);
    chart.assignColor(JOBS_STATUS.SUCCESS, COLORS_MAP[JOBS_STATUS.SUCCESS]);
    chart.assignColor(JOBS_STATUS.WARNING, COLORS_MAP[JOBS_STATUS.WARNING]);
    chart.assignColor(JOBS_STATUS.TERMINATED, COLORS_MAP[JOBS_STATUS.TERMINATED]);
    chart.setMargins(10, 30, 20, 50);
    chart.draw(200);

    xAxis.shapes
      .selectAll('text.dimple-custom-axis-label')
      .style('fill-opacity', 0)
      .style('user-select', 'none');
    xAxis.shapes.selectAll('line.dimple-custom-axis-line').style('stroke-opacity', 0);
    select('.dimple-axis-x .domain').attr('d', `M0,1H${chart._widthPixels()}`);

    barSeries.afterDraw = (shape, data) => {
      shape.classList.add('clickable');

      const status = data.aggField[4];

      if (
        ![
          JOBS_STATUS.ERROR,
          JOBS_STATUS.SUCCESS,
          JOBS_STATUS.WARNING,
          JOBS_STATUS.TERMINATED
        ].includes(status)
      )
        return;

      const rect = select(shape)
        .on('mousedown', () => simulateClickIfMiddleMouseIsUsed.mousedown(d3Event))
        .on('mouseup', () => simulateClickIfMiddleMouseIsUsed.mouseup(d3Event))
        .on('click', () => this.goToJob(data.aggField[3]));

      select(shape.parentNode)
        .append('circle')
        .attr('class', `clickable dimple-status-circle`)
        .attr('cx', parseFloat(rect.attr('x')) + 6)
        .attr('cy', parseFloat(rect.attr('y')) - 12)
        .attr('r', 4)
        .attr('fill', COLORS_MAP[status])
        .on('mouseover', () => dimple._showBarTooltip(data, shape, chart, barSeries))
        .on('mouseleave', () => dimple._removeTooltip(data, shape, chart, barSeries))
        .on('mousedown', () => simulateClickIfMiddleMouseIsUsed.mousedown(d3Event))
        .on('mouseup', () => simulateClickIfMiddleMouseIsUsed.mouseup(d3Event))
        .on('click', () => this.goToJob(data.aggField[3]));
    };

    this.chart = chart;

    window.addEventListener('resize', this._refreshGraph);
    window.addEventListener('focus', this._refreshGraph);
  }

  componentDidUpdate(prevProps) {
    if (this.chart && this.props.data && !this.props.data.equals(prevProps.data)) {
      this._refreshGraph();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._refreshGraph);
    window.removeEventListener('focus', this._refreshGraph);
  }

  _refreshGraph(e) {
    if (!this.props.data || !this.chart) {
      return;
    }

    // clean custom icons before redraw
    this.chart.svg.selectAll('.dimple-status-circle').remove();

    if (!e) {
      this.chart.data = this.getJobs();
    }

    this.chart.draw(0, !!e);

    select('.dimple-axis-x .domain').attr('d', `M0,1H${this.chart._widthPixels()}`);
  }

  getJobs() {
    let jobs = this.props.data.get('jobs');
    const jobsCount = jobs.count();

    if (this.props.minEntries && jobsCount < this.props.minEntries) {
      _.times(this.props.minEntries - jobsCount, (index) => {
        jobs = jobs.push(Map({ duration: 0, index: index + jobsCount }));
      });
    }

    return jobs.toJS();
  }

  goToJob(jobId) {
    if (shouldUseNewWindow(d3Event)) {
      return windowOpen(RoutesStore.getRouter().createHref('jobDetail', { jobId }));
    }

    return RoutesStore.getRouter().transitionTo('jobDetail', { jobId });
  }

  render() {
    return <div className="dimple-box" ref={(node) => (this.graphRef = node)} />;
  }
}

JobsGraph.propTypes = {
  data: PropTypes.instanceOf(Map),
  minEntries: PropTypes.number.isRequired
};

export default JobsGraph;
