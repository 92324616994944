import React from 'react';
import PropTypes from 'prop-types';
import Sortable from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import { defaultOptions } from '../../../../../../constants/sortable';
import BlockButton from '../../../../../../react/common/BlockButton';
import CatchUnsavedChangesModal from '../../../../../../react/common/CatchUnsavedChangesModal';
import Loader from '../../../../../../react/common/Loader';
import string from '../../../../../../utils/string';
import CreateSharedCodeFromSourceModal from '../../../../../shared-codes/components/CreateSharedCodeFromSourceModal';
import { prepareCodeString } from '../../../../../shared-codes/helpers';
import {
  addNewScript,
  addNewScriptAsSharedCode,
  convertCodeBlockToSharedCode,
  convertSharedCodeToCodeBlock,
  copyCode,
  createBlock,
  deleteBlock,
  deleteCode,
  reorder,
  updateBlockName,
  updateCode,
  updateCodeName
} from './actions';
import Block from './Block';
import CodeBlocksEditor from './CodeBlocksEditor';
import {
  getNewCode,
  getSharedCode,
  prepareBlocks,
  prepareScriptsBeforeSave,
  reorderBlocks,
  reorderCodes
} from './helpers';

class CodeBlocksConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreatingBlock: false,
      codeDetail: null,
      blocks: prepareBlocks(props.configData.getIn(['parameters', 'blocks'], List())),
      createSharedCodeDetail: Map(),
      showCreateSharedCodeModal: false,
      showConfirm: null
    };

    this.handleReorderCodes = _.throttle(this.handleReorderCodes, 200, { leading: false });
  }

  componentDidUpdate(prevProps) {
    const currentBlocks = this.props.configData.getIn(['parameters', 'blocks'], List());

    if (!prevProps.configData.getIn(['parameters', 'blocks'], List()).equals(currentBlocks)) {
      this.setState({ blocks: prepareBlocks(currentBlocks) });
    }
  }

  render() {
    return (
      <>
        <Sortable
          options={{
            ...defaultOptions,
            handle: '.block-drag-handle',
            disabled: this.props.readOnly
          }}
          className="box-separator"
          onChange={this.handleReorderBlocks}
        >
          {this.state.blocks.map(this.renderBlock)}
        </Sortable>
        {this.renderNewBlockButton()}
        {this.renderCodeEditor()}
        <CreateSharedCodeFromSourceModal
          show={this.state.showCreateSharedCodeModal}
          onSubmit={(componentId, name, code, variables) =>
            convertCodeBlockToSharedCode(
              componentId,
              this.props.configId,
              this.props.configData,
              this.state.createSharedCodeDetail.get('blockIndex'),
              this.state.createSharedCodeDetail.get('codeIndex'),
              name,
              code,
              variables
            )
          }
          onHide={() =>
            this.setState({
              showCreateSharedCodeModal: false,
              createSharedCodeDetail: Map()
            })
          }
          sourceCode={this.state.createSharedCodeDetail}
        />
        <CatchUnsavedChangesModal
          show={!!this.state.showConfirm}
          onHide={() => this.setState({ showConfirm: null })}
          onLeave={this.handleCloseConfirmAndSwitchCode}
          onSave={() => {
            return prepareScriptsBeforeSave(
              this.props.component.get('id'),
              this.state.codeDetail.get('scripts')
            )
              .then((normalizedScripts) => {
                return this.handleSaveCodeScripts(
                  this.state.codeDetail.get('blockIndex'),
                  this.state.codeDetail.get('codeIndex'),
                  this.state.codeDetail.get('originalName'),
                  normalizedScripts
                );
              })
              .then(this.handleCloseConfirmAndSwitchCode);
          }}
          text="You have unsaved changes! If you open another editor, your unsaved changes will be discarded and your work will be lost."
          leaveLabel="Switch without saving"
        />
      </>
    );
  }

  renderBlock = (block, blockIndex) => {
    return (
      <Block
        key={`${blockIndex}-${string.webalize(block.get('name'))}`}
        readOnly={this.props.readOnly}
        block={block}
        blockIndex={blockIndex}
        isCodeOpened={!!this.state.codeDetail}
        componentId={this.props.component.get('id')}
        hasMoreBlock={this.state.blocks.count() > 1}
        onChangeName={(newName) => this.handleSaveBlockName(blockIndex, newName)}
        onChangeCodeName={(codeIndex = 0, newName) => {
          return this.handleSaveCodeName(blockIndex, codeIndex, newName);
        }}
        onOpenCode={(codeIndex = 0) => {
          if (
            this.state.codeDetail &&
            (this.state.codeDetail.get('name') !== this.state.codeDetail.get('originalName') ||
              this.state.codeDetail.get('scripts') !== this.state.codeDetail.get('originalScripts'))
          ) {
            return this.setState({ showConfirm: { blockIndex, codeIndex } });
          }

          return this.handleOpenCode(blockIndex, codeIndex);
        }}
        onDeleteBlock={() => this.handleDeleteBlock(blockIndex)}
        onDeleteCode={(codeIndex = 0) => this.handleDeleteCode(blockIndex, codeIndex)}
        onCopyCode={(codeIndex = 0) => this.handleCopyCode(blockIndex, codeIndex)}
        onReorderCodes={(order, sortable, event) => this.handleReorderCodes(order, event)}
        onAddSharedCodeInline={(name, code) => {
          return this.callAction(
            addNewScript,
            blockIndex,
            block.get('codes', List()).count(),
            name,
            code
          );
        }}
        onAddSharedCode={(sharedConfigurationRowId) => {
          return this.callAction(
            addNewScriptAsSharedCode,
            blockIndex,
            block.get('codes', List()).count(),
            this.props.componentSharedCodes.get('id'),
            sharedConfigurationRowId
          );
        }}
        sharedCodes={this.props.sharedCodes}
        onCreateSharedCodeFromCode={(codeIndex = 0) => {
          return this.handleCreateSharedCodeFromCode(blockIndex, codeIndex);
        }}
        onCreateCodeFromSharedCode={(codeIndex = 0) => {
          return this.callAction(
            convertSharedCodeToCodeBlock,
            this.props.sharedCodes,
            blockIndex,
            codeIndex
          );
        }}
      />
    );
  };

  renderNewBlockButton() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <BlockButton
        style="default"
        className="box-separator new-block-button"
        disabled={this.state.isCreatingBlock}
        label={
          <>
            {this.state.isCreatingBlock ? (
              <Loader className="icon-addon-right" />
            ) : (
              <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            )}
            New Code Block
          </>
        }
        onClick={this.handleAddNewBlock}
      />
    );
  }

  renderCodeEditor() {
    if (!this.state.codeDetail) {
      return null;
    }

    return (
      <CodeBlocksEditor
        codeDetail={this.state.codeDetail}
        component={this.props.component}
        onSaveName={this.handleSaveCodeName}
        onResetName={this.handleResetCodeName}
        onSaveCode={this.handleSaveCodeScripts}
        onResetCode={this.handleResetCodeScripts}
        onChangeScript={(scripts) => {
          this.setState({ codeDetail: this.state.codeDetail.set('scripts', scripts) });
        }}
        onChangeName={(newName) => {
          this.setState({ codeDetail: this.state.codeDetail.set('name', newName) });
        }}
        onClose={() => this.setState({ codeDetail: null })}
        configData={this.props.configData}
        variables={this.props.variables}
        tables={this.props.tables}
      />
    );
  }

  handleAddNewBlock = () => {
    this.setState({ isCreatingBlock: true });
    this.callAction(createBlock).finally(() => {
      this.setState({ isCreatingBlock: false });
    });
  };

  handleReorderBlocks = (order, sortable, event) => {
    const blocks = reorderBlocks(this.state.blocks, event);

    this.setState({ blocks });
    this.callAction(reorder, blocks, 'Reorder Blocks');
  };

  handleReorderCodes(order, event) {
    const blocks = reorderCodes(this.state.blocks, order, event);

    if (!blocks.equals(this.state.blocks)) {
      this.setState({ blocks });
      this.callAction(reorder, blocks, 'Reorder Codes');
    }
  }

  handleSaveBlockName = (blockIndex, newName) => {
    return this.callAction(updateBlockName, blockIndex, newName);
  };

  handleSaveCodeName = (blockIndex, codeIndex, newName) => {
    return this.callAction(updateCodeName, blockIndex, codeIndex, newName).then(() => {
      if (this.state.codeDetail) {
        this.setState({
          codeDetail: this.state.codeDetail
            .set('name', newName)
            .set('originalName', newName)
            .remove('isNewCode')
        });
      }
    });
  };

  handleDeleteBlock = (blockIndex) => {
    return this.callAction(deleteBlock, blockIndex, this.props.sharedCodes);
  };

  handleDeleteCode = (blockIndex, codeIndex) => {
    return this.callAction(deleteCode, blockIndex, codeIndex, this.props.sharedCodes);
  };

  handleCopyCode = (blockIndex, codeIndex) => {
    this.callAction(copyCode, blockIndex, codeIndex, this.props.sharedCodes);
  };

  handleCreateSharedCodeFromCode = (blockIndex, codeIndex) => {
    this.setState({
      showCreateSharedCodeModal: true,
      createSharedCodeDetail: Map({
        type: this.props.component.get('id'),
        name: this.state.blocks.getIn([blockIndex, 'codes', codeIndex, 'name']),
        code: this.state.blocks.getIn([blockIndex, 'codes', codeIndex, 'script'], List()),
        blockIndex,
        codeIndex
      })
    });
  };

  handleOpenCode = (blockIndex, codeIndex) => {
    if (
      this.state.codeDetail &&
      this.state.codeDetail.get('blockIndex') === blockIndex &&
      this.state.codeDetail.get('codeIndex') === codeIndex
    ) {
      return;
    }

    const block = this.state.blocks.get(blockIndex, Map());
    const isNewCode = !block.hasIn(['codes', codeIndex]);
    let code = block.getIn(['codes', codeIndex], getNewCode());
    const sharedCode = getSharedCode(code, this.props.sharedCodes);

    if (sharedCode) {
      code = code
        .set('name', sharedCode.get('name'))
        .setIn(
          ['script', 0],
          prepareCodeString(sharedCode.getIn(['configuration', 'code_content'], ''))
        );
    }

    const name = isNewCode ? '' : code.get('name');
    const scripts = code.get('script', List()).join('\n\n');

    this.setState({
      codeDetail: fromJS({
        isNewCode,
        blockIndex,
        codeIndex,
        block,
        code,
        name,
        originalName: name,
        scripts,
        originalScripts: scripts,
        sharedCode:
          sharedCode?.set('configurationId', this.props.componentSharedCodes.get('id')) || Map(),
        readOnly: this.props.readOnly
      })
    });
  };

  handleSaveCodeScripts = (blockIndex, codeIndex, name, scripts, changeDescription) => {
    return this.callAction(
      updateCode,
      blockIndex,
      codeIndex,
      name,
      scripts,
      changeDescription
    ).then(() => {
      if (!this.state.codeDetail) {
        return;
      }

      const newScripts = scripts.join('\n\n');

      this.setState({
        codeDetail: this.state.codeDetail
          .set('scripts', newScripts)
          .set('originalScripts', newScripts)
      });
    });
  };

  handleResetCodeName = () => {
    if (this.state.codeDetail) {
      this.setState({
        codeDetail: this.state.codeDetail.set('name', this.state.codeDetail.get('originalName'))
      });
    }
  };

  handleResetCodeScripts = () => {
    if (this.state.codeDetail) {
      this.setState({
        codeDetail: this.state.codeDetail.set(
          'scripts',
          this.state.codeDetail.get('originalScripts')
        )
      });
    }
  };

  handleCloseConfirmAndSwitchCode = () => {
    const { blockIndex, codeIndex } = this.state.showConfirm;
    this.handleOpenCode(blockIndex, codeIndex);
    this.setState({ showConfirm: false });
  };

  callAction = (actionFn, ...parameters) => {
    return actionFn(
      this.props.component.get('id'),
      this.props.configId,
      this.props.configData,
      ...parameters
    );
  };
}

CodeBlocksConfiguration.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  configId: PropTypes.string.isRequired,
  component: PropTypes.instanceOf(Map).isRequired,
  configData: PropTypes.instanceOf(Map).isRequired,
  sharedCodes: PropTypes.instanceOf(List).isRequired,
  componentSharedCodes: PropTypes.instanceOf(Map).isRequired,
  variables: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired
};

export default CodeBlocksConfiguration;
