import React from 'react';
import type { Map } from 'immutable';
import { truncate } from 'underscore.string';

import { extractErrorDetails } from '../../modules/queue/helpers';

const JobError = ({ job }: { job: Map<string, any> }) => {
  if (
    job.get('status') !== 'error' ||
    (job.get('error') !== 'application' && !job.getIn(['result', 'message']))
  ) {
    return null;
  }

  return (
    <span className="job-status-label text-danger">
      {' - '}
      {job.get('error') === 'application'
        ? 'Internal Error'
        : truncate(extractErrorDetails(job.getIn(['result', 'message'])), 40)}
    </span>
  );
};

export default JobError;
