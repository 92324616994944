import React from 'react';
import PropTypes from 'prop-types';

import keyCodes from '../../constants/keyCodes';

class RowActionMenuItem extends React.Component {
  constructor(props) {
    super(props);

    this.linkRef = React.createRef();

    this.onSelect = this.onSelect.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  render() {
    if (this.props.divider) {
      return (
        <li role="separator">
          <hr />
        </li>
      );
    }

    return <li role="presentation">{this.props.href ? this.renderLink() : this.renderButton()}</li>;
  }

  renderLink() {
    return (
      <a
        tabIndex="-1"
        role="menuitem"
        target="_blank"
        rel="noopener noreferrer"
        ref={this.linkRef}
        href={this.props.href}
        className={this.props.className}
        onKeyDown={this.onKeyDown}
        onClick={(event) => event.stopPropagation()}
      >
        {this.props.children}
      </a>
    );
  }

  renderButton() {
    if (this.props.disabled) {
      return (
        <span tabIndex="-1" role="menuitem" className="disabled" onKeyDown={this.props.onKeyDown}>
          {this.props.children}
        </span>
      );
    }

    return (
      <span
        tabIndex="-1"
        role="menuitem"
        className={this.props.className}
        onKeyDown={this.onKeyDown}
        onClick={this.onSelect}
      >
        {this.props.children}
      </span>
    );
  }

  onSelect(event) {
    event.preventDefault();
    event.stopPropagation();

    this.props.onSelect(event, this.props.eventKey);
  }

  onKeyDown(event) {
    if (event.key === keyCodes.ENTER) {
      event.preventDefault();
      event.stopPropagation();

      this.props.href
        ? this.linkRef.current.click()
        : this.props.onSelect(event, this.props.eventKey);
      return;
    }

    return this.props.onKeyDown(event);
  }
}

RowActionMenuItem.propTypes = {
  children: PropTypes.any,
  onSelect: PropTypes.func,
  eventKey: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  divider: PropTypes.bool
};

RowActionMenuItem.defaultProps = {
  onSelect: () => null,
  onKeyDown: () => null
};

export default RowActionMenuItem;
