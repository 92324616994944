import React from 'react';

import {
  KDS_TEAM_EX_NETSUITE,
  KEBOOLA_EX_DB_FIREBIRD,
  KEBOOLA_EX_DB_INFORMIX,
  KEBOOLA_EX_DB_MYSQL,
  KEBOOLA_EX_DB_NETSUITE,
  KEBOOLA_EX_DB_ORACLE,
  KEBOOLA_EX_DB_SNOWFLAKE,
  KEBOOLA_EX_TERADATA,
  KEBOOLA_ORACLE_TRANSFORMATION
} from '../../../constants/componentIds';
import { USER_DOCUMENTATION_URL } from '../../../constants/KbcConstants';
import ExternalLink from '../../../react/common/ExternalLink';
import oracleTransformationCredentialsFields from '../../oracle-transformation/credentials-fields';
import { HostnameHelp } from '../../simplified-ui/credentials';

const defaultFields = [
  {
    label: 'Hostname',
    name: 'host',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Port',
    name: 'port',
    type: 'number',
    protected: false,
    required: true
  },
  {
    label: 'Username',
    name: 'user',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Password',
    name: '#password',
    type: 'password',
    protected: true,
    required: true
  },
  {
    label: 'Database',
    name: 'database',
    type: 'text',
    protected: false,
    required: true
  }
];

/*
# impala fields:
# host: impala
# port: 21050
# database: default
# user: impala
# password:
# auth_mech: 0
*/

const firebirdFields = [
  {
    label: 'Database',
    name: 'dbname',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Username',
    name: 'user',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Password',
    name: '#password',
    type: 'password',
    protected: true,
    required: true
  }
];

const oracleFields = [
  {
    label: 'Hostname',
    name: 'host',
    type: 'text',
    protected: false,
    required: false
  },
  {
    label: 'Port',
    name: 'port',
    type: 'number',
    protected: false,
    required: false
  },
  {
    label: 'Service Name/SID',
    name: 'database',
    type: 'text',
    protected: false,
    required: false
  },
  {
    label: 'Tnsnames',
    name: 'tnsnames',
    type: 'textarea',
    protected: false,
    required: false,
    help: (
      <>
        Fill <code>Tnsnames</code> only if you want to connect without specifying the{' '}
        <code>Hostname</code>, <code>Port</code> and <code>Service Name/SID</code> parameters. Read
        more in the{' '}
        <ExternalLink
          href={`${USER_DOCUMENTATION_URL}/components/extractors/database/sqldb/#oracle-tnsnamesora-file`}
        >
          documentation
        </ExternalLink>
        .
      </>
    )
  },
  {
    label: 'Username',
    name: 'user',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Password',
    name: '#password',
    type: 'password',
    protected: true,
    required: true
  },
  {
    label: 'Enable Connect Through',
    name: 'connectThrough',
    type: 'checkbox',
    help: (
      <>
        Sets the <code>OracleConnection.PROXY_USER_NAME</code> from the user who runs the
        configuration.
      </>
    ),
    protected: false,
    required: false,
    onlySingleTenant: true
  }
];

const snowflakeFields = [
  {
    label: 'Hostname',
    name: 'host',
    type: 'text',
    protected: false,
    required: true,
    help: (
      <HostnameHelp
        url="xx1234.west-europe.azure.snowflakecomputing.com"
        componentId={KEBOOLA_EX_DB_SNOWFLAKE}
      />
    )
  },
  {
    label: 'Port',
    name: 'port',
    type: 'number',
    protected: false,
    required: true
  },
  {
    label: 'Username',
    name: 'user',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Password',
    name: '#password',
    type: 'password',
    protected: true,
    required: true
  },
  {
    label: 'Database',
    name: 'database',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Schema',
    name: 'schema',
    type: 'text',
    protected: false,
    required: false
  },
  {
    label: 'Warehouse',
    name: 'warehouse',
    type: 'text',
    protected: false,
    required: false
  }
];

// same as default, but database is optional
const mysqlFields = [
  {
    label: 'Hostname',
    name: 'host',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Port',
    name: 'port',
    type: 'number',
    protected: false,
    required: true
  },
  {
    label: 'Username',
    name: 'user',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Password',
    name: '#password',
    type: 'password',
    protected: true,
    required: true
  },
  {
    label: 'Database',
    name: 'database',
    type: 'text',
    protected: false,
    required: false
  },
  {
    label: 'Transaction Isolation Level',
    name: 'transactionIsolationLevel',
    type: 'select',
    required: false,
    protected: false,
    options: [
      { value: 'REPEATABLE READ', label: 'Repeatable read' },
      { value: 'READ COMMITTED', label: 'Read committed' },
      { value: 'READ UNCOMMITTED', label: 'Read uncommitted' },
      { value: 'SERIALIZABLE', label: 'Serializable' }
    ]
  },
  {
    label: 'Network Compression',
    name: 'networkCompression',
    type: 'checkbox',
    protected: false,
    required: false
  }
];

const netsuiteFields = [
  {
    label: 'Account ID',
    name: 'accountId',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Role ID',
    name: 'roleId',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'User',
    name: 'user',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Password',
    name: '#password',
    type: 'password',
    protected: true,
    required: true
  }
];

const kdsNetsuiteFields = [
  {
    label: 'Host',
    name: 'host',
    type: 'text',
    placeholder: 'xxxxx.connect.api.netsuite.com',
    protected: false,
    required: true
  },
  {
    label: 'Port',
    name: 'port',
    type: 'number',
    protected: false,
    required: true
  },
  {
    label: 'Server Data Source',
    name: 'server_data_source',
    type: 'select',
    help: (
      <>
        Note that As of November 8, 2021, new Connect users can access the Connect Service using the
        NetSuite2.com data source only. If you gained access to the Connect Service before this
        date, you can still access the NetSuite.com data source to ensure a smooth transition to
        NetSuite2.com.{' '}
        <ExternalLink href="https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/chapter_N752122.html">
          Learn more
        </ExternalLink>
      </>
    ),
    protected: false,
    required: true,
    defaultValue: 'NetSuite2.com',
    options: [
      { value: 'NetSuite.com', label: 'NetSuite.com' },
      { value: 'NetSuite2.co', label: 'NetSuite2.co' }
    ]
  },
  {
    label: 'Account ID',
    name: 'account_id',
    type: 'string',
    help: (
      <>
        The values are available on the SuiteAnalytics Connect Driver Download page under Your
        Configuration. You can access the SuiteAnalytics Connect Driver Download page using the Set
        Up Analytics Connect link in the Settings portlet when you are logged in to NetSuite. For
        more information, see{' '}
        <ExternalLink href="https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/bridgehead_1493644302.html">
          Finding Your Settings Portlet
        </ExternalLink>
      </>
    ),
    protected: false,
    required: true
  },
  {
    label: 'Role ID',
    name: 'role_id',
    type: 'string',
    help: (
      <>
        The values are available on the SuiteAnalytics Connect Driver Download page under Your
        Configuration. You can access the SuiteAnalytics Connect Driver Download page using the Set
        Up Analytics Connect link in the Settings portlet when you are logged in to NetSuite. For
        more information, see{' '}
        <ExternalLink href="https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/bridgehead_1493644302.html">
          Finding Your Settings Portlet
        </ExternalLink>
      </>
    ),
    placeholder: '1030',
    protected: false,
    required: true
  },
  {
    label: 'User',
    name: 'user',
    type: 'string',
    protected: false,
    required: true
  },
  {
    label: 'Password',
    name: '#password',
    type: 'password',
    protected: true,
    required: true
  }
];

// same as default without port - should be always 1025
const teradataFields = [
  {
    label: 'Hostname',
    name: 'host',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Port',
    name: 'port',
    type: 'number',
    protected: false,
    required: true
  },
  {
    label: 'Username',
    name: 'user',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Password',
    name: '#password',
    type: 'password',
    protected: true,
    required: true
  },
  {
    label: 'Database',
    name: 'database',
    type: 'text',
    protected: false,
    required: false
  }
];

const informixFields = [
  {
    label: 'Host',
    name: 'host',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Server Name',
    name: 'serverName',
    type: 'text',
    protected: false,
    required: true,
    help: 'Informix server name or alias.'
  },
  {
    label: 'Protocol',
    name: 'protocol',
    type: 'select',
    required: true,
    protected: false,
    defaultValue: 'onsoctcp',
    options: [
      { value: 'onsoctcp', label: 'onsoctcp' },
      { value: 'onsocssl', label: 'onsocssl' }
    ]
  },
  {
    label: 'DB Locale',
    name: 'dbLocale',
    type: 'text',
    protected: false,
    required: false,
    defaultValue: 'en_US.utf8',
    help: 'Locale of the Informix database. Typical values:  "en_US.utf8" or "en_us.8859-1".'
  },
  {
    label: 'Port',
    name: 'port',
    type: 'number',
    protected: false,
    required: true
  },
  {
    label: 'User',
    name: 'user',
    type: 'text',
    protected: false,
    required: true
  },
  {
    label: 'Password',
    name: '#password',
    type: 'password',
    protected: true,
    required: true
  },
  {
    label: 'Database',
    name: 'database',
    type: 'text',
    protected: false,
    required: true
  }
];

const COMPONENTS_FIELDS = {
  [KEBOOLA_EX_DB_MYSQL]: mysqlFields,
  [KEBOOLA_EX_DB_FIREBIRD]: firebirdFields,
  [KEBOOLA_EX_DB_ORACLE]: oracleFields,
  [KEBOOLA_ORACLE_TRANSFORMATION]: oracleTransformationCredentialsFields,
  [KEBOOLA_EX_DB_SNOWFLAKE]: snowflakeFields,
  [KEBOOLA_EX_DB_NETSUITE]: netsuiteFields,
  [KDS_TEAM_EX_NETSUITE]: kdsNetsuiteFields,
  [KEBOOLA_EX_TERADATA]: teradataFields,
  [KEBOOLA_EX_DB_INFORMIX]: informixFields
};

export function getFields(componentId) {
  if (COMPONENTS_FIELDS[componentId]) {
    return COMPONENTS_FIELDS[componentId];
  } else {
    return defaultFields;
  }
}

// returns @array of properties that needs to be hashed
// should return only password property in most cases
export function getProtectedProperties(componentId) {
  let result = [];
  getFields(componentId).forEach(function (f) {
    if (f.protected) {
      result.push(f.name);
    }
  });
  return result;
}
