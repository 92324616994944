import ApplicationStore from '../../stores/ApplicationStore';
import { loadAll, loadAllAndInitialize } from './actions';
import DevBranchTitle from './DevBranchTitle';
import HeaderButton from './HeaderButton';
import MergeIndex from './MergeIndex';

export default {
  name: 'development-overview',
  titleHandler: DevBranchTitle,
  defaultRouteHandler: MergeIndex,
  headerButtonsHandler: HeaderButton,
  requireData: [loadAllAndInitialize],
  ...(ApplicationStore.hasProtectedDefaultBranch() && {
    poll: {
      skipFirst: true,
      interval: 30,
      action: loadAll
    }
  })
};
