import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { capitalize } from 'underscore.string';

import CredentialsBox from '../../../react/common/CredentialsBox';
import FileSize from '../../../react/common/FileSize';
import RowsCount from '../../../react/common/RowsCount';
import { backends } from '../../storage/constants';
import { prepareBackendLabel } from '../../storage/helpers';
import UpdateStorageButton from './UpdateStorageButton';

class StorageBackend extends React.Component {
  render() {
    const sizeBytes = this.props.project.getIn(['metrics', 'storage.dataSizeBytes', 'value'], 0);
    const rowsCount = this.props.project.getIn(['metrics', 'storage.rowsCount', 'value'], 0);

    let rows = Map({
      Usage: {
        noCopy: true,
        component: (
          <>
            <FileSize size={sizeBytes} /> (<RowsCount count={rowsCount} /> Rows)
          </>
        )
      },
      'Assigned Backends': {
        noCopy: true,
        component: Object.values(backends)
          .filter((backend) => this.props.project.get(`has${capitalize(backend)}`, false))
          .map((backend) => prepareBackendLabel(backend))
          .join(', ')
      },
      'Default Backend': {
        noCopy: true,
        text: prepareBackendLabel(this.props.project.get('defaultBackend'))
      }
    });

    if (this.props.project.get('hasSnowflake', false)) {
      rows = rows.set('Data Retention', {
        noCopy: true,
        text: `${this.props.project.get('dataRetentionTimeInDays')} days`
      });
    }

    return (
      <div className="well pp-6">
        <h3 className="tw-mt-0 tw-mb-6">
          Storage
          {this.props.user.get('isSuperAdmin', false) && (
            <UpdateStorageButton
              project={this.props.project}
              onSubmit={this.props.handleUpdateStorage}
            />
          )}
        </h3>
        <CredentialsBox rows={rows} noBorder />
      </div>
    );
  }
}

StorageBackend.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
  handleUpdateStorage: PropTypes.func.isRequired
};

export default StorageBackend;
