import React from 'react';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import _ from 'underscore';

import { KDS_TEAM_WR_HIVE_CSAS, KEBOOLA_WR_LOOKER_V2 } from '../../../../../constants/componentIds';
import { FEATURE_ARTIFACTS } from '../../../../../constants/features';
import HiveCredentials from '../../../../../react/common/HiveCredentials';
import SaveButtons from '../../../../../react/common/SaveButtons';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import InstalledComponentsActions from '../../../../components/InstalledComponentsActionCreators';
import ComponentsStore from '../../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../../components/stores/InstalledComponentsStore';
import WorkspacesActions from '../../../../workspaces/WorkspacesActions';
import WorkspacesStore from '../../../../workspaces/WorkspacesStore';
import WrDbActions from '../../../actionCreators';
import { isProvisioningCredentials } from '../../../helpers';
import WrDbStore from '../../../store';
import hasValidCredentials from '../../../templates/hasValidCredentials';
import V2Actions from '../../../v2-actions';
import { prepareCredentialsAfterEditStart } from '../../components/credentialsHelper';
import BigQueryServiceAccountForm from './BigQueryServiceAccountForm';
import CredentialsForm from './CredentialsForm';
import { States } from './StateConstants';

const Credentials = (componentId, driver, componentSupportsProvisionedCredentials) => {
  return createReactClass({
    mixins: [
      createStoreMixin(
        ApplicationStore,
        InstalledComponentsStore,
        ComponentsStore,
        WrDbStore,
        WorkspacesStore
      )
    ],

    getStateFromStores() {
      const component = ComponentsStore.getComponent(componentId);
      const configId = RoutesStore.getCurrentRouteParam('config');
      const config = InstalledComponentsStore.getConfigData(componentId, configId);

      let workspaceCredentials = Map();
      if (config.hasIn(['parameters', 'db', 'user'])) {
        workspaceCredentials = WorkspacesStore.getConfigurationWorkspaceByConnectionUser(
          componentId,
          configId,
          config.getIn(['parameters', 'db', 'user'])
        );
      }
      const credentials =
        workspaceCredentials.count() !== 0
          ? workspaceCredentials.get('connection', Map())
          : WrDbStore.getCredentials(componentId, configId);

      return {
        configId,
        credentials,
        workspaceCredentials,
        credentialsType: RoutesStore.getRouterState()
          .getIn(['location', 'query', 'type'], '')
          .toLowerCase(),
        localState: InstalledComponentsStore.getLocalState(componentId, configId),
        approvedHostnames: component.getIn(['data', 'image_parameters', 'approvedHostnames']),
        globalDbConfig: component.getIn(['data', 'image_parameters', 'global_config', 'db'], Map()),
        isWorkspaceCredentials: workspaceCredentials.count() !== 0,
        editingCredentials: WrDbStore.getEditingByPath(componentId, configId, 'creds') || Map(),
        v2Actions: V2Actions(configId, componentId),
        readOnly: ApplicationStore.isReadOnly(),
        hasArtifacts: ApplicationStore.hasCurrentProjectFeature(FEATURE_ARTIFACTS)
      };
    },

    componentDidMount() {
      if (componentSupportsProvisionedCredentials) {
        if (
          isProvisioningCredentials(this.state.credentials) ||
          this.state.isWorkspaceCredentials
        ) {
          this._updateLocalState('credentialsState', States.SHOW_STORED_CREDS);
        } else {
          this._startEdit();
        }
      } else {
        this._startEdit();
      }
    },

    render() {
      const credentialsState = this.state.localState.get('credentialsState');
      const editable = [States.SAVING_NEW_CREDS, States.CREATE_NEW_CREDS, States.INIT].includes(
        credentialsState
      );
      const credentials = editable ? this.state.editingCredentials : this.state.credentials;
      const credentialsWithGlobals = this.state.globalDbConfig.mergeDeep(credentials);

      if (this.isLookerBigQueryCredentials()) {
        return (
          <BigQueryServiceAccountForm
            onChangeFn={this.setCredentials}
            cancelEditingFn={this._startEdit}
            saveCredentialsFn={this.handleSave}
            isSaving={credentialsState === States.SAVING_NEW_CREDS}
            savedCredentials={this.state.credentials}
            credentials={credentials}
            readOnly={this.state.readOnly}
          />
        );
      }

      if (KDS_TEAM_WR_HIVE_CSAS === componentId) {
        return (
          <div className="form-horizontal">
            <div className="box">
              <div className="box-content">
                {!this.state.readOnly && (
                  <div className="save-buttons">
                    <SaveButtons
                      isSaving={credentialsState === States.SAVING_NEW_CREDS}
                      isChanged={!this.state.credentials.equals(this.state.editingCredentials)}
                      disabled={
                        this.state.isSaving ||
                        !hasValidCredentials(componentId, credentialsWithGlobals)
                      }
                      onReset={this._startEdit}
                      onSave={this.handleSave}
                    />
                  </div>
                )}
                <HiveCredentials
                  credentials={credentialsWithGlobals}
                  editingCredentials={this.state.credentials.mergeDeep(credentials)}
                  onChange={this.setCredentials}
                  componentId={componentId}
                  configId={this.state.configId}
                  actionCreators={{
                    testCredentials: (configId, credentials, forceAlterNat) =>
                      this.state.v2Actions.testCredentials(credentials, forceAlterNat)
                  }}
                  readOnly={this.state.readOnly}
                  isChanged={!this.state.credentials.equals(this.state.editingCredentials)}
                  isSaving={credentialsState === States.SAVING_NEW_CREDS}
                  hasArtifacts={this.state.hasArtifacts}
                  hasValidCredentials={() =>
                    hasValidCredentials(componentId, credentialsWithGlobals)
                  }
                />
              </div>
            </div>
          </div>
        );
      }

      return (
        <CredentialsForm
          readOnly={this.state.readOnly}
          isEditing={editable}
          credentials={credentialsWithGlobals}
          globalCredentails={this.state.globalDbConfig}
          savedCredentials={this.state.credentials}
          approvedHostnames={this.state.approvedHostnames}
          onChangeFn={this._handleChange}
          changeCredentialsFn={this.setCredentials}
          isSaving={credentialsState === States.SAVING_NEW_CREDS}
          isProvisioningCredentials={isProvisioningCredentials(this.state.credentials)}
          componentId={componentId}
          configId={this.state.configId}
          testCredentialsFn={this.state.v2Actions.testCredentials}
          cancelEditingFn={this._startEdit}
          saveCredentialsFn={this.handleSave}
          componentSupportsProvisionedCredentials={componentSupportsProvisionedCredentials}
          isWorkspaceCredentials={this.state.isWorkspaceCredentials}
          resetWorkspacePasswordFn={
            this.state.isWorkspaceCredentials
              ? () =>
                  WrDbActions.resetWorkspacePassword(
                    componentId,
                    this.state.configId,
                    this.state.credentials,
                    this.state.workspaceCredentials.get('id')
                  )
              : () => null
          }
          forgetPasswordFn={() =>
            WorkspacesActions.hideWorkspacePassword(
              componentId,
              this.state.configId,
              this.state.workspaceCredentials.get('id')
            )
          }
          credentialsType={this.state.credentialsType}
        />
      );
    },

    handleSave() {
      this._updateLocalState('credentialsState', States.SAVING_NEW_CREDS);
      return WrDbActions.saveCredentials(
        componentId,
        this.state.configId,
        this.state.editingCredentials.filter((value) => _.isBoolean(value) || !!value)
      ).then(() => {
        this._updateLocalState('credentialsState', States.SHOW_STORED_CREDS);
        return RoutesStore.getRouter().transitionTo(componentId, {
          config: this.state.configId
        });
      });
    },

    _handleChange(propName, value) {
      return this.setCredentials(this.state.editingCredentials.set(propName, value));
    },

    setCredentials(creds) {
      return WrDbActions.setEditingData(componentId, this.state.configId, 'creds', creds);
    },

    _updateLocalState(pathname, data) {
      const path = _.isString(pathname) ? [pathname] : pathname;
      const newLocalState = this.state.localState.setIn(path, data);
      return InstalledComponentsActions.updateLocalState(
        componentId,
        this.state.configId,
        newLocalState,
        path
      );
    },

    _startEdit() {
      let credentials = prepareCredentialsAfterEditStart(
        componentId,
        driver,
        this.state.credentials,
        this.state.globalDbConfig
      );

      if (this.isLookerBigQueryCredentials()) {
        credentials = credentials.set('driver', 'bigquery').delete('port');
      }

      WrDbActions.setEditingData(componentId, this.state.configId, 'creds', credentials);
      return this._updateLocalState('credentialsState', States.CREATE_NEW_CREDS);
    },

    isLookerBigQueryCredentials() {
      return (
        componentId === KEBOOLA_WR_LOOKER_V2 &&
        (this.state.credentialsType === 'bigquery' ||
          this.state.credentials.get('driver') === 'bigquery')
      );
    }
  });
};

export default Credentials;
