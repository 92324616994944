import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup, HelpBlock, Modal } from 'react-bootstrap';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_WR_DB_SNOWFLAKE } from '../../../constants/componentIds';
import Checkbox from '../../../react/common/Checkbox';
import ConfirmButtons from '../../../react/common/ConfirmButtons';
import InputValidation, { isValidName } from '../../../react/common/InputValidation';
import ModalIcon from '../../../react/common/ModalIcon';
import Select from '../../../react/common/Select';
import { getComponentDataTypes } from '../../wr-db/templates/dataTypes';
import { backends, nameWarning } from '../constants';

const INITIAL_STATE = {
  name: '',
  definition: Map(),
  warning: null
};

const CreateColumnModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    table: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    isTypedTable: PropTypes.bool.isRequired,
    addingColumn: PropTypes.bool.isRequired
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create column</Modal.Title>
            <ModalIcon.Plus />
          </Modal.Header>
          <Modal.Body>
            <InputValidation predefined="columnName" value={this.state.name}>
              {(inputState) => (
                <FormGroup validationState={this.state.warning ? 'error' : inputState}>
                  <ControlLabel>Name</ControlLabel>
                  {this.props.table.get('sourceTable') ? (
                    <Select
                      clearable={false}
                      placeholder="Column name"
                      value={this.state.name}
                      onChange={this.handleSelectedName}
                      options={this.columnsOptions()}
                    />
                  ) : (
                    <>
                      <FormControl
                        autoFocus
                        type="text"
                        value={this.state.name}
                        onChange={this.handleName}
                        placeholder="Column name"
                      />
                      <HelpBlock
                        className={classnames({
                          'text-danger': !!this.state.warning || inputState === 'error'
                        })}
                      >
                        {this.state.warning || nameWarning}
                      </HelpBlock>
                    </>
                  )}
                </FormGroup>
              )}
            </InputValidation>
            {this.renderTypeDefinition()}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isDisabled={this.isDisabled()}
              isSaving={this.props.addingColumn}
              saveLabel={this.props.addingColumn ? 'Creating column...' : 'Create column'}
              saveButtonType="submit"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  },

  renderTypeDefinition() {
    if (!this.shouldDefineType()) {
      return null;
    }

    const type = this.state.definition.get('type', '');
    const snowflakeTypes = fromJS(getComponentDataTypes(KEBOOLA_WR_DB_SNOWFLAKE))
      .map((type) => {
        if (typeof type === 'string') {
          return Map({ name: type.toUpperCase() });
        }

        const name = type.keySeq().first();
        return type.get(name).set('name', name.toUpperCase());
      })
      .toMap()
      .mapKeys((key, type) => type.get('name'));

    return (
      <>
        <FormGroup>
          <ControlLabel>Type</ControlLabel>
          <Select
            clearable={false}
            value={type}
            onChange={(type) => {
              let definition = this.state.definition.set('type', type);

              if (snowflakeTypes.hasIn([type, 'defaultSize'])) {
                definition = definition.set('length', snowflakeTypes.getIn([type, 'defaultSize']));
              } else {
                definition = definition.delete('length');
              }

              this.setState({ definition });
            }}
            options={snowflakeTypes
              .sortBy((type) => type.get('name'))
              .map((type, name) => ({ label: name, value: name }))
              .toArray()}
          />
        </FormGroup>
        {snowflakeTypes.hasIn([type, 'defaultSize']) && (
          <FormGroup>
            <ControlLabel>Length</ControlLabel>
            <FormControl
              type="text"
              value={this.state.definition.get('length', '')}
              onChange={(e) => {
                this.setState({
                  definition: e.target.value
                    ? this.state.definition.set('length', e.target.value)
                    : this.state.definition.delete('length')
                });
              }}
              placeholder={type === 'STRING' ? 'eg. 255' : 'eg. 38,0'}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Checkbox
            disabled={!type}
            checked={this.state.definition.get('nullable', true)}
            onChange={(checked) => {
              this.setState({ definition: this.state.definition.set('nullable', checked) });
            }}
          >
            Nullable
          </Checkbox>
        </FormGroup>
        <FormGroup>
          <ControlLabel>Default value</ControlLabel>
          <FormControl
            type="text"
            value={this.state.definition.get('default', '')}
            onChange={(e) => {
              this.setState({
                definition: e.target.value
                  ? this.state.definition.set('default', e.target.value)
                  : this.state.definition.delete('default')
              });
            }}
            disabled={!type}
          />
        </FormGroup>
      </>
    );
  },

  shouldDefineType() {
    return (
      this.props.isTypedTable &&
      this.props.table.getIn(['bucket', 'backend']) === backends.SNOWFLAKE
    );
  },

  handleSelectedName(column) {
    this.setState({ name: column });
  },

  handleName(event) {
    this.setState({ name: event.target.value }, this.validateName);
  },

  columnsOptions() {
    const sourceTable = this.props.tables.get(this.props.table.getIn(['sourceTable', 'id']));

    if (!sourceTable) {
      return null;
    }

    return sourceTable
      .get('columns')
      .filter((column) => !this.props.table.get('columns').includes(column))
      .map((column) => ({
        label: column,
        value: column
      }))
      .toArray();
  },

  onHide() {
    this.props.onHide();
    this.resetState();
  },

  onSubmit(event) {
    event.preventDefault();

    this.props
      .onSubmit({
        name: this.state.name,
        ...(!this.state.definition.isEmpty() && {
          definition: this.state.definition.toJS()
        })
      })
      .then(this.onHide);
  },

  resetState() {
    this.setState(INITIAL_STATE);
  },

  validateName() {
    if (this.props.table.get('columns', List()).includes(this.state.name)) {
      this.setState({
        warning: `The column "${this.state.name}" already exists.`
      });
    } else {
      this.setState({ warning: null });
    }
  },

  isDisabled() {
    return (
      !this.state.name ||
      !isValidName(this.state.name) ||
      !!this.state.warning ||
      (this.shouldDefineType() && !this.state.definition.get('type'))
    );
  }
});

export default CreateColumnModal;
