import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from './Tooltip';

class InlineTooltipWarningIcon extends React.Component {
  render() {
    return (
      <Tooltip tooltip={this.props.message} placement="top" type="explanatory">
        <FontAwesomeIcon
          icon="triangle-exclamation"
          className="icon-addon-left inline text-warning"
        />
      </Tooltip>
    );
  }
}

InlineTooltipWarningIcon.propTypes = {
  message: PropTypes.string.isRequired
};

export default InlineTooltipWarningIcon;
