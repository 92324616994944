import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from '../../../react/common/Tooltip';

const ContinueOnFailureIcon = () => {
  return (
    <Tooltip placement="bottom" tooltip="Continue on Failure">
      <FontAwesomeIcon icon="rotate-exclamation" className="color-danger simple-icon f-16" />
    </Tooltip>
  );
};

export default ContinueOnFailureIcon;
