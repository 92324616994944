import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Sortable from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { defaultOptions } from '../../../../../../constants/sortable';
import InlineEditTextInput from '../../../../../../react/common/InlineEditTextInput';
import Loader from '../../../../../../react/common/Loader';
import RowActionDropdown from '../../../../../../react/common/RowActionDropdown';
import RowActionMenuItem from '../../../../../../react/common/RowActionMenuItem';
import Tooltip from '../../../../../../react/common/Tooltip';
import string from '../../../../../../utils/string';
import SharedCodePickerModal from '../../../../../shared-codes/components/PickerModal';
import Code from './Code';
import { getNewCode, getSharedCode } from './helpers';

class Block extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBlockSaving: false,
      isBlockEditing: false,
      editingBlockValue: '',
      isBlockDeleting: false,
      isCodeMoving: false,
      showSharedCodePickerModal: false
    };

    this.renderCode = this.renderCode.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleRename = this.handleRename.bind(this);
    this.handleEditNameStart = this.handleEditNameStart.bind(this);
    this.handleEditNameCancel = this.handleEditNameCancel.bind(this);
    this.handleEditNameChange = this.handleEditNameChange.bind(this);
  }

  render() {
    if (!this.props.block.get('codes', List()).count() && this.props.readOnly) {
      return null;
    }

    return (
      <div className="box box-compact with-blocks" data-id={this.props.blockIndex}>
        <div className="box-header">
          {this.renderMoveIcon()}
          <h2 className="overflow-hidden">
            <FontAwesomeIcon icon="clock" className="f-13 icon-addon-right" />
            {this.renderBlockName()}
          </h2>
          {!this.props.readOnly && (
            <div className="no-wrap block-actions">
              {this.renderAddSharedCodeButton()}
              {this.renderAddCodeButton()}
              {this.props.hasMoreBlock && (
                <RowActionDropdown showLoading={this.state.isBlockDeleting}>
                  <RowActionMenuItem
                    disabled={this.state.isBlockDeleting || this.props.isCodeOpened}
                    onSelect={this.handleDelete}
                  >
                    {this.state.isBlockDeleting ? (
                      <Loader />
                    ) : (
                      <FontAwesomeIcon icon="trash" fixedWidth />
                    )}
                    Delete Block
                  </RowActionMenuItem>
                </RowActionDropdown>
              )}
            </div>
          )}
        </div>
        <Sortable
          options={{
            ...defaultOptions,
            handle: '.code-drag-handle',
            group: 'blocks',
            disabled: this.props.readOnly,
            onStart: () => this.setState({ isCodeMoving: true }),
            onEnd: () => this.setState({ isCodeMoving: false })
          }}
          onChange={this.props.onReorderCodes}
          className="box-content"
        >
          {this.renderCodes()}
        </Sortable>
        {this.renderSharedCodePickerModal()}
      </div>
    );
  }

  renderMoveIcon() {
    if (
      !this.props.hasMoreBlock ||
      this.props.readOnly ||
      this.state.isBlockEditing ||
      this.props.isCodeOpened
    ) {
      return null;
    }

    return (
      <Tooltip placement="top" tooltip="Move Block">
        <FontAwesomeIcon className="text-muted-light dragable block-drag-handle" icon="bars" />
      </Tooltip>
    );
  }

  renderBlockName() {
    if (this.props.readOnly || this.props.isCodeOpened) {
      return this.props.block.get('name');
    }

    return (
      <InlineEditTextInput
        showEditIcon={false}
        placeholder="Block name"
        tooltipPlacement="bottom"
        editTooltip="Edit block name"
        isSaving={this.state.isBlockSaving}
        isEditing={this.state.isBlockEditing}
        isValid={this.state.editingBlockValue.trim().length > 0}
        isChanged={this.state.editingBlockValue !== this.props.block.get('name')}
        text={
          this.state.isBlockEditing ? this.state.editingBlockValue : this.props.block.get('name')
        }
        onEditStart={this.handleEditNameStart}
        onEditCancel={this.handleEditNameCancel}
        onEditChange={this.handleEditNameChange}
        onEditSubmit={this.handleRename}
      />
    );
  }

  renderCodes() {
    if (!this.props.block.get('codes', List()).count()) {
      return (
        <Code
          newCodeButton
          isSharedCode={false}
          code={getNewCode()}
          readOnly={this.props.readOnly}
          key={`${this.props.blockIndex}-0`}
          sortableKey={`${this.props.blockIndex}-0`}
          onClickCode={this.props.onOpenCode}
        />
      );
    }

    return this.props.block.get('codes', List()).map(this.renderCode);
  }

  renderCode(code, codeIndex) {
    const sharedCode = getSharedCode(code, this.props.sharedCodes);
    const renamedCode = sharedCode ? code.set('name', sharedCode.get('name')) : code;

    return (
      <Code
        key={`${this.props.blockIndex}-${codeIndex}-${string.webalize(renamedCode.get('name'))}`}
        isSharedCode={!!sharedCode}
        code={renamedCode}
        readOnly={this.props.readOnly}
        sortableKey={`${this.props.blockIndex}-${codeIndex}`}
        onClickCode={() => !this.state.isCodeMoving && this.props.onOpenCode(codeIndex)}
        onDeleteCode={() => this.props.onDeleteCode(codeIndex)}
        onCopyCode={() => this.props.onCopyCode(codeIndex)}
        onCreateSharedCodeFromCode={() => this.props.onCreateSharedCodeFromCode(codeIndex)}
        onCreateCodeFromSharedCode={() => this.props.onCreateCodeFromSharedCode(codeIndex)}
        onChangeCodeName={(newName) => this.props.onChangeCodeName(codeIndex, newName)}
        isCodeOpened={this.props.isCodeOpened}
      />
    );
  }

  renderAddCodeButton() {
    return (
      <Button
        bsStyle="link"
        className="header-inline-button color-success"
        onClick={() => this.props.onOpenCode(this.props.block.get('codes', List()).count())}
      >
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        New Code
      </Button>
    );
  }

  renderAddSharedCodeButton() {
    if (this.props.sharedCodes.count() === 0) {
      return (
        <Tooltip tooltip="No shared code available" placement="top">
          <Button bsStyle="link" className="disabled header-inline-button color-primary">
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            Select Shared Code
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button
        bsStyle="link"
        className="header-inline-button color-primary"
        onClick={() => this.setState({ showSharedCodePickerModal: true })}
      >
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        Select Shared Code
      </Button>
    );
  }

  renderSharedCodePickerModal() {
    return (
      <SharedCodePickerModal
        sharedCodes={this.props.sharedCodes}
        componentId={this.props.componentId}
        show={this.state.showSharedCodePickerModal}
        onSubmitAsInline={this.props.onAddSharedCodeInline}
        onSubmitAsSharedCode={this.props.onAddSharedCode}
        onHide={() => this.setState({ showSharedCodePickerModal: false })}
      />
    );
  }

  handleEditNameStart() {
    this.setState({ isBlockEditing: true, editingBlockValue: this.props.block.get('name') });
  }

  handleEditNameCancel() {
    this.setState({ isBlockEditing: false, editingBlockValue: '' });
  }

  handleEditNameChange(value) {
    this.setState({ editingBlockValue: value });
  }

  handleRename() {
    this.setState({ isBlockSaving: true });
    this.props.onChangeName(this.state.editingBlockValue.trim()).then(() => {
      this.setState({ isBlockEditing: false, isBlockSaving: false, editingBlockValue: '' });
    });
  }

  handleDelete() {
    this.setState({ isBlockDeleting: true });
    this.props.onDeleteBlock().finally(() => {
      this.setState({ isBlockDeleting: false });
    });
  }
}

Block.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  componentId: PropTypes.string.isRequired,
  block: PropTypes.instanceOf(Map).isRequired,
  sharedCodes: PropTypes.instanceOf(List).isRequired,
  blockIndex: PropTypes.number.isRequired,
  hasMoreBlock: PropTypes.bool.isRequired,
  isCodeOpened: PropTypes.bool.isRequired,
  onAddSharedCodeInline: PropTypes.func.isRequired,
  onAddSharedCode: PropTypes.func.isRequired,
  onReorderCodes: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeCodeName: PropTypes.func.isRequired,
  onOpenCode: PropTypes.func.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  onDeleteCode: PropTypes.func.isRequired,
  onCreateSharedCodeFromCode: PropTypes.func.isRequired,
  onCreateCodeFromSharedCode: PropTypes.func.isRequired
};

export default Block;
