import { List, Map } from 'immutable';
import _ from 'underscore';

import InstalledComponentsStore from '../components/stores/InstalledComponentsStore';
import OauthStore from '../oauth-v2/Store';

export const storeMixins = [InstalledComponentsStore, OauthStore];

export const DEFAULT_API_VERSION = 'v18.0';

export default function (COMPONENT_ID, configId) {
  const localState = () => InstalledComponentsStore.getLocalState(COMPONENT_ID, configId) || Map();
  const config = InstalledComponentsStore.getConfig(COMPONENT_ID, configId);
  const configData = InstalledComponentsStore.getConfigData(COMPONENT_ID, configId);
  const parameters = configData.get('parameters', Map());
  const queries = parameters.get('queries', List());

  const tempPath = ['_'];
  const syncAccountsPath = tempPath.concat('SyncAccounts');
  const accountsSavingPath = tempPath.concat('savingaccounts');
  const savingQueriesPath = tempPath.concat('savingQueries');
  const pendingPath = tempPath.concat('pending');

  const editPath = tempPath.concat('editing');
  const editData = localState().getIn(editPath, Map());

  function findQuery(queryId) {
    return queries.findLast((q) => q.get('id').toString() === queryId.toString());
  }
  const oauthCredentials = OauthStore.getCredentials(COMPONENT_ID, configData) || Map();
  // ----ACTUAL STATE OBJECT------

  return {
    configData,
    parameters,
    config,
    queries,
    oauthCredentials,
    findQuery,
    syncAccountsPath,
    editData,
    // local state stuff
    getLocalState(path) {
      if (_.isEmpty(path)) {
        return localState() || Map();
      }
      return localState().getIn([].concat(path), Map());
    },
    syncAccounts: localState().getIn(syncAccountsPath, Map()),
    hasQueries: queries.count() > 0,
    version: parameters.get('api-version', DEFAULT_API_VERSION),
    accounts: parameters.get('accounts', Map()),
    hasAccounts: parameters.get('accounts', Map()).count() > 0,
    getEditPath: (what) => (what ? editPath.concat(what) : editPath),
    isEditing: (what) => editData.hasIn([].concat(what)),
    isSavingAccounts: () => localState().getIn(accountsSavingPath),
    isSavingQuery: (qid) => localState().getIn(savingQueriesPath.concat(qid), false),
    getSavingQueryPath: (qid) => savingQueriesPath.concat(qid),

    getPendingPath(what) {
      return pendingPath.concat(what);
    },

    isPending(what) {
      return localState().getIn(pendingPath.concat(what), null);
    },

    getRunSingleQueryData(qid) {
      const query = findQuery(qid).set('disabled', false);
      return configData.setIn(['parameters', 'queries'], List().push(query)).toJS();
    },

    accountsSavingPath,
    isAuthorized() {
      const creds = this.oauthCredentials;
      return creds && creds.has('id');
    }
  };
}
