const routeNames = {
  ROOT: 'storage',
  JOBS: 'storage-jobs',
  EVENTS: 'storage-events',
  FILES: 'storage-files',
  BUCKET: 'storage-bucket',
  TABLE: 'storage-table'
};

const actionTypes = {
  TOGGLE_CONTEXT_FILTER: 'STORAGE_TOGGLE_CONTEXT_FILTER',
  TOGGLE_SHOW_ALL_BUCKETS: 'TOGGLE_SHOW_ALL_BUCKETS',
  TOGGLE_EXPAND_BUCKET: 'TOGGLE_EXPAND_BUCKET',
  RESET_EXPANDED_BUCKETS: 'RESET_EXPANDED_BUCKETS',
  UPDATE_SEARCH_QUERY: 'STORAGE_UPDATE_SEARCH_QUERY',
  UPDATE_SEARCH_FILTERS: 'STORAGE_UPDATE_SEARCH_FILTERS',
  UPDATE_FILES_SEARCH_QUERY: 'STORAGE_UPDATE_FILES_SEARCH_QUERY',
  UPDATE_BUCKETS_SORT: 'UPDATE_BUCKETS_SORT'
};

const STAGE = {
  IN: 'in',
  OUT: 'out'
};

const backends = {
  SNOWFLAKE: 'snowflake',
  REDSHIFT: 'redshift',
  SYNAPSE: 'synapse',
  EXASOL: 'exasol',
  TERADATA: 'teradata',
  BIGQUERY: 'bigquery'
};

const dataPreviewDataType = {
  [backends.SNOWFLAKE]: 'DOUBLE',
  [backends.SYNAPSE]: 'REAL',
  [backends.EXASOL]: 'DECIMAL',
  [backends.TERADATA]: 'DECIMAL',
  [backends.BIGQUERY]: 'DECIMAL'
};

const jobsLimit = 20;
const filesLimit = 50;
const storageInitialLimit = 50;
const nameMaxLength = 96;
const ALIAS_SUFFIX = '_alias';

const eventsPredefinedSearches = [
  {
    name: 'Omit tables fetches',
    query: 'NOT event:storage.tableDataPreview OR NOT event:storage.tableDetail'
  },
  {
    name: 'Omit tables exports',
    query: 'NOT event:storage.tableExported'
  },
  {
    name: 'Import/Exports only',
    query:
      'event:storage.tableImportStarted OR event:storage.tableImportDone OR event:storage.tableImportError OR event:storage.tableExported'
  }
];

const eventsTemplates = {
  'storage.tableImportStarted': {
    message: 'Import started'
  },

  'storage.tableImportDone': {
    message: 'Successfully imported',
    className: 'bg-success'
  },

  'storage.tableImportError': {
    message: 'Error on table import',
    className: 'bg-danger'
  },

  'storage.tableExported': {
    message: 'Exported to a csv file',
    className: 'bg-info'
  }
};

const bucketTabs = {
  OVERVIEW: 'overview',
  EVENTS: 'events'
};

const tableTabs = {
  OVERVIEW: 'overview',
  EVENTS: 'events',
  SCHEMA: 'schema',
  DATA_SAMPLE: 'data-sample',
  SNAPSHOT_AND_RESTORE: 'snapshot-and-restore',
  GRAPH: 'graph',
  USAGE: 'usage'
};

const tableModalTabs = {
  EXPORT: 'export',
  LOAD: 'load',
  RESTORE: 'restore',
  SNAPSHOT: 'snapshot',
  ALIAS: 'alias',
  TRUNCATE: 'truncate',
  DELETE: 'delete'
};

const sortEntities = { LAST_CHANGE: 'LAST_CHANGE', NAME: 'NAME' };

const FILTERS_GROUP = {
  STAGE: 'stage',
  ENTITY: 'entity'
};

const FILTERS = {
  ALL: '',
  IN: 'in',
  OUT: 'out',
  TABLES: 'tables',
  BUCKETS: 'buckets',
  DEV: 'dev'
};

const EXPORT_TYPES = {
  GZIP: 'GZIP',
  CSV: 'CSV',
  XLSX: 'XLSX'
};

const nameWarning = 'Only alphanumeric characters, dash, and underscores are allowed.';
const tableIdWarning = 'Only alphanumeric characters, dots, dash and underscore are allowed.';

export {
  routeNames,
  STAGE,
  actionTypes,
  backends,
  dataPreviewDataType,
  jobsLimit,
  filesLimit,
  storageInitialLimit,
  nameMaxLength,
  eventsPredefinedSearches,
  eventsTemplates,
  ALIAS_SUFFIX,
  bucketTabs,
  tableTabs,
  tableModalTabs,
  sortEntities,
  FILTERS_GROUP,
  FILTERS,
  EXPORT_TYPES,
  nameWarning,
  tableIdWarning
};
