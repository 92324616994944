import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import {
  KEBOOLA_ORCHESTRATOR,
  TDE_EXPORTER as componentId
} from '../../../../../constants/componentIds';
import { componentTypes } from '../../../../../constants/componentTypes';
import dayjs from '../../../../../date';
import ConfigurationInfoPanel from '../../../../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../../../../react/common/ConfigurationTabs';
import FilterPanel from '../../../../../react/common/FilterPanel';
import Link from '../../../../../react/common/RouterLink';
import Sidebar from '../../../../../react/layout/Sidebar';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import InstalledComponentsActions from '../../../../components/InstalledComponentsActionCreators';
import ComponentDescription from '../../../../components/react/components/ComponentDescription';
import TablesByBucketsPanel from '../../../../components/react/components/TablesByBucketsPanel';
import ComponentsStore from '../../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../../components/stores/InstalledComponentsStore';
import StorageBucketsStore from '../../../../components/stores/StorageBucketsStore';
import StorageFilesStore from '../../../../components/stores/StorageFilesStore';
import StorageTablesStore from '../../../../components/stores/StorageTablesStore';
import DevBranchesStore from '../../../../dev-branches/DevBranchesStore';
import { removeDevBranchReferenceFromTable } from '../../../../dev-branches/helpers';
import { prepareTablesMetadataMap } from '../../../../storage/helpers';
import { getStorageInputTablesWithSourceSearch } from '../../../../wr-db/helpers';
import * as tdeCommon from '../../../tdeCommon';
import { webalizeTdeFileName } from '../../../tdeCommon';
import AddNewTableModal from './AddNewTableModal';
import TableRow from './TableRow';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
      StorageFilesStore
    )
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(componentId, configId);
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);

    return {
      configId,
      config,
      configData,
      componentId,
      files: StorageFilesStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      isLoadingFiles: StorageFilesStore.getIsLoading(),
      localState: InstalledComponentsStore.getLocalState(componentId, configId),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      typedefs: configData.getIn(['parameters', 'typedefs'], Map()),
      isSaving: InstalledComponentsStore.getSavingConfigData(componentId, configId),
      tablesWithSourceSearchInputMapping: getStorageInputTablesWithSourceSearch(configData),
      allTables: StorageTablesStore.getAll(),
      allBuckets: StorageBucketsStore.getAll(),
      readOnly: ApplicationStore.isReadOnly(),
      hasFlows: ApplicationStore.hasFlows()
    };
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={this.state.componentId} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.config}
          hasFlows={this.state.hasFlows}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={componentId}
              configId={this.state.configId}
              placeholderEntity={componentTypes.WRITER}
            />
            {this._renderMainContent()}
          </div>
          {this._renderSideBar()}
        </div>
      </>
    );
  },

  _renderMainContent() {
    if (this._isEmptyConfig()) {
      return (
        <div className="box-separator">
          <h2 className="tw-text-base tw-m-0 tw-mb-4">Tables</h2>
          <div className="box">
            <div className="box-content text-center">
              <p>No tables configured yet.</p>
              {this.renderNewTableButton()}
              {this.renderNewTableModal()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="box-separator">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
          <h2 className="tw-text-base tw-m-0">Tables</h2>
          {this.renderNewTableButton()}
        </div>
        <FilterPanel
          query={this.state.localState.get('searchQuery') || ''}
          onChange={(newQuery) => this._updateLocalState(['searchQuery'], newQuery)}
        />
        <div className="box tables-by-buckets">
          <div className="box-content">{this.renderTables()}</div>
        </div>
        {this.renderNewTableModal()}
      </div>
    );
  },

  renderTables() {
    return (
      <TablesByBucketsPanel
        tables={this.state.allTables}
        buckets={this.state.allBuckets}
        renderTableRowFn={(table) => this._renderTableRow(table, true)}
        renderDeletedTableRowFn={(table) => this._renderTableRow(table, false)}
        isExportedFn={(tableId) => this.state.typedefs.has(tableId)}
        isShownFn={(tableId) => this.state.typedefs.has(tableId)}
        onToggleBucketFn={this._handleToggleBucket}
        isBucketToggledFn={this._isBucketToggled}
        configuredTables={this.state.typedefs.keySeq().toJS()}
        tablesWithSourceSearchInputMapping={this.state.tablesWithSourceSearchInputMapping}
        searchQuery={this.state.localState.get('searchQuery')}
      />
    );
  },

  _renderSideBar() {
    return (
      <div className="col-sm-3">
        <Sidebar
          componentId={componentId}
          configId={this.state.configId}
          run={{
            disabled: this._disabledToRun(),
            text: 'You are about to run an export of all configured tables to TDE.'
          }}
          additionalButtons={this.renderAdditionalButtons()}
        />
      </div>
    );
  },

  _renderTableRow(table, tableExists) {
    const tableId = table.get('id');
    const tdeFileName = tdeCommon.getTdeFileName(this.state.configData, tableId);

    return (
      <TableRow
        key={tableId}
        table={table}
        tableExists={tableExists}
        readOnly={this.state.readOnly}
        configId={this.state.configId}
        tdeFile={this._getLastTdeFile(tdeFileName)}
        tdeFileName={tdeFileName}
        prepareRunDataFn={() => this._prepareRunTableData(tableId)}
        deleteRowFn={() => this._deleteTable(tableId)}
        configData={this.state.configData}
        uploadComponentId={this.state.localState.get('uploadComponentId')}
        uploadComponentIdSetFn={(uploadComponentId) => {
          this._updateLocalState(['uploadComponentId'], uploadComponentId);
        }}
      />
    );
  },

  renderNewTableModal() {
    const show = !!(this.state.localState && this.state.localState.getIn(['newTable', 'show']));

    return (
      <AddNewTableModal
        show={show}
        buckets={this.state.allBuckets}
        tables={this.state.allTables}
        selectedTableId={this.state.localState.getIn(['newTable', 'id'], '')}
        configuredTables={this.state.configData.getIn(['parameters', 'typedefs'])}
        configId={this.state.configId}
        onHideFn={() => {
          this._updateLocalState(['newTable'], Map());
        }}
        onSetTableIdFn={(value) => {
          this._updateLocalState(['newTable', 'id'], value);
        }}
        onSaveFn={(selectedTableId) => {
          this.addNewTableToConfig(selectedTableId).then(() => {
            return RoutesStore.getRouter().transitionTo('tde-exporter-table', {
              config: this.state.configId,
              tableId: removeDevBranchReferenceFromTable(
                selectedTableId,
                DevBranchesStore.getCurrentId()
              )
            });
          });
        }}
      />
    );
  },

  addNewTableToConfig(selectedTableId) {
    const table = this.state.allTables.get(selectedTableId);
    const columns = table.get('columns');
    const configData = this.state.configData;

    const storageInputTables = configData
      .getIn(['storage', 'input', 'tables'], List())
      .filter((currentInputTable) => {
        return currentInputTable !== selectedTableId;
      })
      .push(
        fromJS({
          source: selectedTableId,
          columns
        })
      );

    const newConfigData = configData
      .setIn(['storage', 'input', 'tables'], storageInputTables)
      .setIn(
        ['parameters', 'typedefs', selectedTableId],
        columns
          .toMap()
          .mapEntries(([, column]) => [column, fromJS({ type: 'string', format: null })])
      )
      .setIn(
        ['parameters', 'tables', selectedTableId],
        fromJS({
          tdename: `${webalizeTdeFileName(
            removeDevBranchReferenceFromTable(selectedTableId, DevBranchesStore.getCurrentId())
          )}.tde`
        })
      );

    return InstalledComponentsActions.saveComponentConfigData(
      componentId,
      this.state.configId,
      newConfigData
    );
  },

  renderNewTableButton() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <Button
        bsSize="sm"
        bsStyle="success"
        onClick={() => {
          this._updateLocalState(['newTable', 'show'], true);
        }}
      >
        <FontAwesomeIcon icon="plus" className="icon-addon-right" />
        Add Table
      </Button>
    );
  },

  renderAdditionalButtons() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <Link
        to="tde-exporter-destination"
        className="btn btn-link"
        params={{ config: this.state.configId }}
      >
        <FontAwesomeIcon icon="gear" fixedWidth />
        Set Up Upload
      </Link>
    );
  },

  _getLastTdeFile(tdeFileName) {
    const filename = tdeFileName.replace(/-/g, '_').toLowerCase();
    const files = this.state.files.filter((file) => {
      return file.get('name').toLowerCase() === filename;
    });

    return files.max((a, b) => {
      const adate = dayjs(a.get('created'));
      const bdate = dayjs(b.get('created'));
      if (adate === bdate) {
        return 0;
      }
      return adate > bdate ? 1 : -1;
    });
  },

  _deleteTable(tableId) {
    let { configData } = this.state;
    let intables = configData.getIn(['storage', 'input', 'tables']);
    if (intables) {
      intables = intables.filter((intable) => intable.get('source') !== tableId);
      configData = configData.setIn(['storage', 'input', 'tables'], intables);
    }
    configData = configData.deleteIn(['parameters', 'typedefs', tableId]);
    configData = configData.deleteIn(['parameters', 'tables', tableId]);

    InstalledComponentsActions.saveComponentConfigData(
      componentId,
      this.state.configId,
      configData
    );
  },

  _prepareRunTableData(tableId) {
    let { configData } = this.state;
    let intables = configData.getIn(['storage', 'input', 'tables']);
    intables = intables.filter((intable) => intable.get('source') === tableId);
    configData = configData.setIn(['storage', 'input', 'tables'], intables);
    const typedefs = configData.getIn(['parameters', 'typedefs', tableId]);
    configData = configData.setIn(['parameters', 'typedefs'], Map());
    configData = configData.setIn(['parameters', 'typedefs', tableId], typedefs);
    const tags = [`config-${this.state.configId}`];
    configData = configData.setIn(['parameters', 'tags'], fromJS(tags));
    const data = {
      configData: configData.toJS(),
      config: this.state.configId
    };
    return data;
  },

  _disabledToRun() {
    if (this._isEmptyConfig()) {
      return 'No tables configured';
    }
    return '';
  },

  _isEmptyConfig() {
    const tables = this.state.configData.getIn(['storage', 'input', 'tables']);
    return !(tables && tables.count() > 0);
  },

  _handleToggleBucket(bucketId) {
    const newValue = !this._isBucketToggled(bucketId);
    const bucketToggles = this.state.localState.get('bucketToggles', Map());
    const newToggles = bucketToggles.set(bucketId, newValue);
    this._updateLocalState(['bucketToggles'], newToggles);
  },

  _isBucketToggled(bucketId) {
    const bucketToggles = this.state.localState.get('bucketToggles', Map());
    return !!bucketToggles.get(bucketId);
  },

  _updateLocalState(path, data) {
    const newLocalState = this.state.localState.setIn(path, data);
    InstalledComponentsActions.updateLocalState(
      componentId,
      this.state.configId,
      newLocalState,
      path
    );
  }
});

export default Index;
