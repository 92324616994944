import React from 'react';
import { Modal } from 'react-bootstrap';

export const GOOGLE_CALENDAR_APPOINTMENT_IDS = {
  engineering: 'AcZssZ3cr_Ke_rxR1z2a9Hqz1Rz_-SHlfmikRA_CCEhaZZSSyolpfbLp8gU5clc1xGCvtzeYYUItuwI0',
  sales: 'AcZssZ07F-tVeTuTUTovU8d3rgfmTjF2xADdq2AqyC8tF236gG9mWUtphcFowt5ahwQ-P9f0WlhY6vRy'
} as const;

export const MeetingWidget = ({ type }: { type: keyof typeof GOOGLE_CALENDAR_APPOINTMENT_IDS }) => {
  const appointmentId = GOOGLE_CALENDAR_APPOINTMENT_IDS[type];

  return (
    <div id="meeting-scheduler-wrapper">
      <iframe
        title="Google Calendar Appointment scheduler"
        src={`https://calendar.google.com/calendar/appointments/schedules/${appointmentId}?gv=true`}
        style={{ border: '0' }}
        width="100%"
        height="840"
      ></iframe>
    </div>
  );
};

type Props = {
  type: keyof typeof GOOGLE_CALENDAR_APPOINTMENT_IDS | null;
  onHide: (show: boolean) => void;
};

export const MeetingWidgetModal = ({ type, onHide }: Props) => {
  return (
    <Modal show={!!type} onHide={onHide} bsSize="large">
      <Modal.Header closeButton>
        <Modal.Title>Schedule a call</Modal.Title>
      </Modal.Header>
      <Modal.Body>{type && <MeetingWidget type={type} />}</Modal.Body>
    </Modal>
  );
};
