import { fromJS } from 'immutable';

export default {
  createConfiguration: function (localState) {
    return fromJS({
      parameters: {
        host: localState.get('host', ''),
        port: parseInt(localState.get('port', 21), 10),
        connectionType: localState.get('connectionType', ''),
        username: localState.get('username', ''),
        '#password': localState.get('password', ''),
        '#privateKey': localState.get('privateKey', ''),
        ignorePassiveAddress: localState.get('ignorePassiveAddress', false),
        ssh: localState.get('ssh', { enabled: false })
      }
    });
  },

  parseConfiguration: function (configuration) {
    return fromJS({
      host: configuration.getIn(['parameters', 'host'], ''),
      port: configuration.getIn(['parameters', 'port'], 21),
      connectionType: configuration.getIn(['parameters', 'connectionType'], ''),
      username: configuration.getIn(['parameters', 'username'], ''),
      password: configuration.getIn(['parameters', '#password'], ''),
      privateKey: configuration.getIn(['parameters', '#privateKey'], ''),
      ignorePassiveAddress: configuration.getIn(['parameters', 'ignorePassiveAddress'], false),
      ssh: configuration.getIn(['parameters', 'ssh'], { enabled: false })
    });
  },

  isComplete: function (configuration) {
    return (
      configuration.getIn(['parameters', 'host'], '') !== '' &&
      configuration.getIn(['parameters', 'port'], '') !== '' &&
      configuration.getIn(['parameters', 'connectionType'], '') !== '' &&
      configuration.getIn(['parameters', 'username'], '') !== '' &&
      configuration.getIn(['parameters', '#password'], '') !== '' &&
      configuration.hasIn(['parameters', 'ignorePassiveAddress'])
    );
  },

  createEmptyConfiguration: function () {
    return this.createConfiguration(fromJS({ port: 21 }));
  }
};
