import React, { type ReactNode, useState } from 'react';
import { Modal } from 'react-bootstrap';
import type Promise from 'bluebird';
import { capitalize } from 'underscore.string';

import keyCodes from '../../constants/keyCodes';
import ApplicationStore from '../../stores/ApplicationStore';
import DescriptionBox from './DescriptionBox';
import ModalIcon from './ModalIcon';

const DescriptionModal = (props: {
  show: boolean;
  onHide: () => void;
  onSave: (description: string) => Promise<void>;
  description: string;
  entity: string;
  readOnly?: boolean;
  additionalTitleControl?: ReactNode;
  handleGenerate?: () => Promise<any>;
}) => {
  const [editing, setEditing] = useState<string | null>(null);
  const [isGenerating, setGenerating] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleSubmit = () => {
    const newDescription = editing || '';

    setSaving(true);
    return props
      .onSave(newDescription.trim())
      .then(props.onHide)
      .finally(() => setSaving(false));
  };

  return (
    <span
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
    >
      <Modal
        bsSize="large"
        show={props.show}
        onHide={props.onHide}
        onEnter={() => setEditing(props.description ? null : '')}
      >
        <Modal.Header closeButton>
          <Modal.Title className="flex-container">
            {props.entity.toLowerCase() === 'other'
              ? 'Description'
              : `${capitalize(props.entity)} Description`}
            {props.additionalTitleControl}
          </Modal.Title>
          <ModalIcon icon="note-sticky" color="blue" bold />
        </Modal.Header>
        <DescriptionBox
          inModal
          readOnly={!!props.readOnly}
          editing={editing}
          description={props.description}
          onChange={setEditing}
          handleSave={handleSubmit}
          handleCancel={() => setEditing(null)}
          isSaving={isSaving}
          isEditing={editing !== null}
          {...(props.handleGenerate && {
            isGenerating,
            hasAllowedAi: ApplicationStore.hasAllowedDescriptionAi(),
            handleGenerate: () => {
              if (!props.handleGenerate) {
                return;
              }

              setGenerating(true);
              props
                .handleGenerate()
                .then((data) => setEditing(data.description.trim()))
                .finally(() => setGenerating(false));
            }
          })}
        />
      </Modal>
    </span>
  );
};

export default DescriptionModal;
