import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

import { APP_ROUTE } from '../../../constants/routeNames';
import { canLoadSharedBuckets } from '../../../modules/admin/privileges';
import { routeNames as componentRoutes } from '../../../modules/components-directory/constants';
import { routeNames as flowRoutes } from '../../../modules/flows/constants';
import { routeNames as orchestrationRoutes } from '../../../modules/orchestrations-v2/constants';
import { routeNames as queueRoutes } from '../../../modules/queue/constants';
import { routeNames as workspacesRouteNames } from '../../../modules/sandboxes/Constants';
import { routeNames as storageRoutes } from '../../../modules/storage/constants';
import { routeNames as templateRoutes } from '../../../modules/templates/constants';
import RoutesStore from '../../../stores/RoutesStore';
import Link from '../../common/RouterLink';
import Tooltip from '../../common/Tooltip';

const Navigation = createReactClass({
  propTypes: {
    xsrf: PropTypes.string.isRequired,
    sapiToken: PropTypes.object.isRequired,
    showNewTransformations: PropTypes.bool.isRequired,
    hideOldTransformations: PropTypes.bool.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    hasPayAsYouGo: PropTypes.bool.isRequired,
    isDevModeActive: PropTypes.bool.isRequired,
    hasConfigurations: PropTypes.bool.isRequired,
    hasFlowsOnly: PropTypes.bool.isRequired,
    hasFlows: PropTypes.bool.isRequired,
    hasTemplates: PropTypes.bool.isRequired,
    hasSnowflakePartnerConnect: PropTypes.bool.isRequired,
    isDemoPreview: PropTypes.bool.isRequired,
    isCollapsed: PropTypes.bool.isRequired
  },

  render() {
    const router = RoutesStore.getRouter();

    return (
      <ul className="nav nav-pills nav-stacked">
        <Tooltip
          tooltip="Dashboard"
          placement="right"
          forceHide={!this.props.isCollapsed}
          delay="fast"
        >
          <li>
            <Link to={APP_ROUTE} className="nav-link" onlyActiveOnIndex>
              <FontAwesomeIcon
                fixedWidth
                icon={router.isActive(APP_ROUTE) ? 'gauge' : ['far', 'gauge']}
              />
              <span>Dashboard</span>
            </Link>
          </li>
        </Tooltip>
        {this.renderFlows(router)}
        {this.renderOrchestrations(router)}
        {this.renderUseCases(router)}
        {this.renderComponents(router)}
        {this.renderTransformationsMenu(router)}
        {this.renderWorkspaces(router)}
        <Tooltip
          tooltip="Storage"
          placement="right"
          forceHide={!this.props.isCollapsed}
          delay="fast"
        >
          <li>
            <Link to={storageRoutes.ROOT} className="nav-link">
              <FontAwesomeIcon
                fixedWidth
                icon={router.isActive(storageRoutes.ROOT) ? 'warehouse' : ['far', 'warehouse']}
              />
              <span>Storage</span>
            </Link>
          </li>
        </Tooltip>
        {this.renderDataCatalog(router)}
        {this.renderJobs(router)}
      </ul>
    );
  },

  renderComponents(router) {
    const route = this.props.hasConfigurations
      ? componentRoutes.CONFIGURATIONS
      : componentRoutes.ROOT;

    return (
      <Tooltip
        tooltip="Components"
        placement="right"
        forceHide={!this.props.isCollapsed}
        delay="fast"
      >
        <li>
          <Link
            to={route}
            className={classnames('nav-link', { active: router.isActive(componentRoutes.ROOT) })}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={router.isActive(route) ? 'layer-group' : ['far', 'layer-group']}
            />
            <span>Components</span>
          </Link>
        </li>
      </Tooltip>
    );
  },

  renderFlows(router) {
    if (!this.props.hasFlows && !this.props.hasFlowsOnly) {
      return null;
    }

    return (
      <Tooltip tooltip="Flows" placement="right" forceHide={!this.props.isCollapsed} delay="fast">
        <li>
          <Link to={flowRoutes.ROOT} className="nav-link">
            <FontAwesomeIcon
              className="icon-addon-right"
              fixedWidth
              icon={router.isActive(flowRoutes.ROOT) ? 'bars-staggered' : ['far', 'bars-staggered']}
            />
            <span>Flows</span>
          </Link>
        </li>
      </Tooltip>
    );
  },

  renderUseCases(router) {
    if (!this.props.hasTemplates || this.props.isDevModeActive) {
      return null;
    }

    return (
      <Tooltip
        tooltip="Templates"
        placement="right"
        forceHide={!this.props.isCollapsed}
        delay="fast"
      >
        <li>
          <Link
            to={templateRoutes.ROOT}
            className={classnames('nav-link', {
              active: router.isActive(templateRoutes.ROOT)
            })}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={router.isActive(templateRoutes.ROOT) ? 'book-open' : ['far', 'book-open']}
            />
            <span>Templates</span>
          </Link>
        </li>
      </Tooltip>
    );
  },

  renderDataCatalog(router) {
    if (
      this.props.sapiToken.getIn(['owner', 'hasExasol'], false) ||
      this.props.sapiToken.getIn(['owner', 'hasTeradata'], false) ||
      this.props.hasPayAsYouGo ||
      this.props.isDevModeActive ||
      this.props.isDemoPreview
    ) {
      return null;
    }

    const route = !canLoadSharedBuckets(this.props.sapiToken)
      ? 'data-catalog-shared-from-this-project'
      : 'data-catalog-shared-with-you';

    return (
      <Tooltip
        tooltip="Data Catalog"
        placement="right"
        forceHide={!this.props.isCollapsed}
        delay="fast"
      >
        <li>
          <Link
            to={route}
            className={classnames('nav-link', {
              active:
                router.isActive('data-catalog-shared-with-you') ||
                router.isActive('data-catalog-shared-from-this-project')
            })}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={router.isActive(route) ? 'book-blank' : ['far', 'book-blank']}
            />
            <span>Data Catalog</span>
          </Link>
        </li>
      </Tooltip>
    );
  },

  renderOrchestrations(router) {
    if (
      this.props.hasFlows ||
      this.props.hasFlowsOnly ||
      (this.props.isDevModeActive && !this.props.hasNewQueue)
    ) {
      return null;
    }

    const route = this.props.hasNewQueue ? orchestrationRoutes.ROOT : 'orchestrations';

    return (
      <Tooltip
        tooltip="Orchestrations"
        placement="right"
        forceHide={!this.props.isCollapsed}
        delay="fast"
      >
        <li>
          <Link to={route} className="nav-link">
            <FontAwesomeIcon
              fixedWidth
              icon={router.isActive(route) ? 'bars-staggered' : ['far', 'bars-staggered']}
            />
            <span>Orchestrations</span>
          </Link>
        </li>
      </Tooltip>
    );
  },

  renderTransformationsMenu(router) {
    if (this.props.hasSnowflakePartnerConnect) {
      return null;
    }

    const route = this.props.showNewTransformations ? 'transformations-v2' : 'transformations';

    return (
      <Tooltip
        tooltip="Transformations"
        placement="right"
        forceHide={!this.props.isCollapsed}
        delay="fast"
      >
        <li>
          <Link to={route} className="nav-link">
            <FontAwesomeIcon
              fixedWidth
              icon={router.isActive(route) ? 'window-restore' : ['far', 'window-restore']}
            />
            <span>Transformations</span>
          </Link>
        </li>
      </Tooltip>
    );
  },

  renderWorkspaces(router) {
    if (
      !this.props.showNewTransformations ||
      this.props.readOnly ||
      this.props.hasSnowflakePartnerConnect
    ) {
      return null;
    }

    return (
      <Tooltip
        tooltip="Workspaces"
        placement="right"
        forceHide={!this.props.isCollapsed}
        delay="fast"
      >
        <li>
          <Link to={workspacesRouteNames.WORKSPACES} className="nav-link">
            <FontAwesomeIcon
              fixedWidth
              icon={router.isActive(workspacesRouteNames.WORKSPACES) ? 'box' : ['far', 'box']}
            />
            <span>Workspaces</span>
          </Link>
        </li>
      </Tooltip>
    );
  },

  renderJobs(router) {
    const route = this.props.hasNewQueue ? queueRoutes.JOBS : 'jobs';

    return (
      <Tooltip tooltip="Jobs" placement="right" forceHide={!this.props.isCollapsed} delay="fast">
        <li>
          <Link to={route} className="nav-link">
            <FontAwesomeIcon
              fixedWidth
              icon={router.isActive(route) ? 'circle-play' : ['far', 'circle-play']}
            />
            <span>Jobs</span>
          </Link>
        </li>
      </Tooltip>
    );
  }
});

export default Navigation;
