import Promise from 'bluebird';

import Metadata from '../components/MetadataActionCreators';
import { MetadataKeys } from '../components/MetadataConstants';
import Storage from '../components/StorageActionCreators';

const saveSharedDescription = (bucketId, description) => {
  if (!description) {
    return Promise.resolve();
  }

  return Metadata.saveMetadata('bucket', bucketId, MetadataKeys.SHARED_DESCRIPTION, description);
};

export default {
  shareBucketSimple(bucketId, sharedType, params, description) {
    return Storage.shareBucket(sharedType, bucketId, params).then(() =>
      saveSharedDescription(bucketId, description)
    );
  },

  shareBucket({ name, displayName, description, stage, tables, sharedType, ...params }, sapiToken) {
    const backend = sapiToken.getIn(['owner', 'defaultBackend']);
    return Storage.createBucket({ name, displayName, stage, backend }).then((bucket) => {
      const aliasTables = tables
        .map((tableName, tableId) => ({ name: tableName, sourceTable: tableId }))
        .toArray();

      return Promise.each(aliasTables, (table) => Storage.createAliasTable(bucket.id, table))
        .then(() => Storage.shareBucket(sharedType, bucket.id, params))
        .then(() => saveSharedDescription(bucket.id, description))
        .catch((error) => {
          Storage.deleteBucket(bucket.id, { forceDelete: true, skipNotification: true });
          throw error;
        });
    });
  }
};
