import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { ASSETS_URL, PLATFORM_CHANGELOG_URL } from '../../../../constants/KbcConstants';
import { PLATFORM_NEWS_LAST_SEEN } from '../../../../constants/localStorageKeys';
import dayjs from '../../../../date';
import { request } from '../../../../helpers';
import CustomLoader from '../../../../react/common/CustomLoader';
import ExternalLink from '../../../../react/common/ExternalLink';
import Tooltip from '../../../../react/common/Tooltip';
import Truncated from '../../../../react/common/Truncated';
import { getItem, setItem } from '../../../../utils/localStorage';

type Post = {
  excerpt: string;
  published_at: string;
  slug: string;
  title: string;
  unread?: boolean;
};

const UTM_QUERY_PARAMS = 'utm_campaign=news-dropdown&utm_source=platform';

const PlatformNews = (props: { isCollapsed: boolean }) => {
  const [open, setOpen] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);
  const [unreadPostsCount, setUnreadPostsCount] = useState(0);

  useEffect(() => {
    request('GET', `${ASSETS_URL}/platform-changelog/published-posts.json`).then(
      ({ body }: { body: Post[] }) => {
        let unreadCount = 0;
        const lastSeen = getItem(PLATFORM_NEWS_LAST_SEEN);
        const sortedPosts = body
          .sort(
            (postA, postB) =>
              new Date(postB.published_at).valueOf() - new Date(postA.published_at).valueOf()
          )
          .slice(0, 4)
          .map((post) => {
            if (!lastSeen || dayjs(post.published_at).diff(lastSeen) > 0) {
              unreadCount++;

              return { ...post, unread: true };
            }

            return post;
          });

        setPosts(sortedPosts);
        setUnreadPostsCount(unreadCount);
      }
    );
  }, []);

  return (
    <Dropdown
      dropup
      id="platform-news"
      rootCloseEvent="mousedown"
      open={open}
      onToggle={(isOpen: boolean, event: any, data: { source: string }) => {
        if (
          data.source === 'rootClose' &&
          event.composedPath?.().some((el: Element) => el.id === 'platform-news')
        ) {
          return;
        }

        setOpen(isOpen);
        if (isOpen) {
          setUnreadPostsCount(0);
          setItem(PLATFORM_NEWS_LAST_SEEN, new Date().toString());
        }
      }}
      pullRight
    >
      <Dropdown.Toggle noCaret bsStyle="link">
        {props.isCollapsed ? (
          <Tooltip tooltip="What's New" placement="right">
            <FontAwesomeIcon icon={['far', 'gift']} fixedWidth />
          </Tooltip>
        ) : (
          <>
            What&apos;s New
            {!!unreadPostsCount && <span className="unread-icon" />}
          </>
        )}
        {!props.isCollapsed && <FontAwesomeIcon icon="angle-right" />}
      </Dropdown.Toggle>
      <Dropdown.Menu className="tw-min-w-[240px] tw-mt-1 tw-p-2">
        {!posts.length ? (
          <div className="text-center">
            <CustomLoader
              text="Loading news..."
              styles={{ minHeight: 'none', paddingTop: '5px' }}
              show
            />
            <hr />
          </div>
        ) : (
          posts.map(({ excerpt, slug, title, unread }) => (
            <React.Fragment key={slug}>
              <ExternalLink
                href={`${PLATFORM_CHANGELOG_URL}/${slug}/?${UTM_QUERY_PARAMS}`}
                onClick={() => {
                  setPosts(
                    posts.map((post) => ({
                      ...post,
                      ...(post.slug === slug && { unread: false })
                    }))
                  );
                }}
                className={classnames('display-block color-inherit no-underline', { unread })}
              >
                <Truncated text={title} className="font-medium" />
                <Truncated
                  twoLines
                  text={excerpt}
                  noTooltip
                  className="text-muted f-12 font-medium line-height-22"
                />
              </ExternalLink>
              <hr className="mt-1 mb-1" />
            </React.Fragment>
          ))
        )}
        <div className="text-center">
          <ExternalLink href={`${PLATFORM_CHANGELOG_URL}/?${UTM_QUERY_PARAMS}`}>
            See all changes &rarr;
          </ExternalLink>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PlatformNews;
