import React from 'react';
import PropTypes from 'prop-types';
import { Button, Label } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import Loader from '../../../../../react/common/Loader';
import MultiActionsSelectCheckbox from '../../../../../react/common/MultiActionsSelectCheckbox';
import Tooltip from '../../../../../react/common/Tooltip';
import FileInputMappingModal from './FileInputMappingModal';

const FileInputMappingRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    editingValue: PropTypes.object.isRequired,
    mappingIndex: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    pendingActions: PropTypes.object.isRequired,
    onEditStart: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelection: PropTypes.func.isRequired
  },

  render() {
    return (
      <span className="tr hoverable-actions">
        {this.renderColumns()}
        {this.renderActionButtons()}
      </span>
    );
  },

  renderColumns() {
    const isDeleting = this.props.pendingActions.getIn(
      ['input', 'tables', this.props.mappingIndex, 'delete'],
      false
    );

    return (
      <>
        {!this.props.readOnly && (
          <span className="td pr-0">
            <MultiActionsSelectCheckbox
              isChecked={this.props.isSelected}
              isDisabled={isDeleting}
              onToggle={this.props.toggleSelection}
              entity="mapping"
            />
          </span>
        )}
        <span className="td col-xs-5">
          {this.renderTags()}
          {this.props.value.get('query', '') !== '' && (
            <>
              {' '}
              <code>{this.props.value.get('query')}</code>
            </>
          )}
        </span>
        <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
        <span className="td col-xs-5">{'in/files/*'}</span>
      </>
    );
  },

  renderActionButtons() {
    if (this.props.readOnly) {
      return <span className="td pl-0 pr-1 no-wrap" />;
    }

    const isDeleting = this.props.pendingActions.getIn(
      ['input', 'files', this.props.mappingIndex, 'delete'],
      false
    );

    return (
      <span className="td pl-0 pr-1 no-wrap">
        <FileInputMappingModal
          mode="edit"
          mapping={this.props.editingValue}
          onChange={this.props.onChange}
          onCancel={this.props.onCancel}
          onSave={this.props.onSave}
          onEditStart={this.props.onEditStart}
        />
        <Tooltip placement="top" tooltip="Delete Input">
          <Button
            bsStyle="link"
            className="text-muted"
            onClick={this.props.onDelete}
            disabled={isDeleting}
          >
            {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
          </Button>
        </Tooltip>
      </span>
    );
  },

  renderTags() {
    const tags = !this.props.value.get('tags', List()).isEmpty()
      ? this.props.value.get('tags', List())
      : this.props.value.getIn(['source', 'tags'], List()).map((tag) => tag.get('name'));

    if (tags.isEmpty()) {
      return null;
    }

    return (
      <div className="labels-container">
        {tags.map((tag) => <Label key={tag}>{tag}</Label>).toArray()}
      </div>
    );
  },

  renderRightArrow() {
    const withProcessedTags = this.props.value.get('processed_tags', List()).count() > 0;

    if (!withProcessedTags) {
      return <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />;
    }

    return (
      <Tooltip placement="top" type="explanatory" tooltip="Processed Tags">
        <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />
      </Tooltip>
    );
  }
});

export default FileInputMappingRow;
