import { HTTP_STATUS_CODE_NOT_FOUND } from '../../utils/errors/helpers';
import { createRequest } from '../sandboxes/SandboxesApi';

const ModelsApi = {
  loadModels() {
    return createRequest('GET', 'ml/models')
      .promise()
      .then((response) => response.body)
      .catch((error) => {
        if (error.response && error.response.status === HTTP_STATUS_CODE_NOT_FOUND) {
          return [];
        }
        throw error;
      });
  },

  testModel(deploymentId, testJson) {
    if (!testJson) {
      return createRequest('POST', `ml/deployments/${deploymentId}/test`)
        .promise()
        .then((response) => response.body);
    }
    return createRequest('POST', `ml/deployments/${deploymentId}/test`)
      .type('json')
      .send(testJson)
      .promise()
      .then((response) => response.body);
  },

  updateModel(name, version, params) {
    return createRequest('PATCH', 'ml/models/' + name + '/versions/' + version)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  linkConfiguration(deploymentId, params) {
    return createRequest('POST', `ml/deployments/${deploymentId}/linkedConfigurations`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  unlinkConfiguration(deploymentId, componentId, configurationId) {
    return createRequest(
      'DELETE',
      `ml/deployments/${deploymentId}/linkedConfigurations/${componentId}/${configurationId}`
    )
      .promise()
      .then((response) => response.body);
  }
};

export default ModelsApi;
