import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import Checkbox from '../../../../react/common/Checkbox';
import PasswordControl from '../../../../react/common/PasswordControl';
import Select from '../../../../react/common/Select';
import { bucketLabel } from '../../../../react/common/selectLabels';
import { bucketDisplayNameWithStage } from '../../../storage/helpers';

const NewProjectForm = createReactClass({
  propTypes: {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    buckets: PropTypes.instanceOf(Map).isRequired
  },

  handleChange(newDiff) {
    this.props.onChange({ ...this.props.value, ...newDiff });
  },

  allBucketsOptions() {
    return this.props.buckets
      .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
      .map((bucket) => ({
        value: bucket.get('id'),
        label: bucketLabel(bucket),
        name: bucket.get('displayName')
      }))
      .toArray();
  },

  render() {
    return (
      <div>
        {this.renderInputControlGroup(
          'Project ID',
          'pid',
          'Type project ID',
          'ID of GoodData project/workspace'
        )}
        {this.renderInputControlGroup(
          'Username',
          'login',
          'Type username',
          'Username of GoodData user'
        )}
        {this.renderInputControlGroup(
          'Password',
          'password',
          'Type password',
          'Password of GoodData user'
        )}
        {this.renderInputControlGroup(
          'Backend URL (optional)',
          'backendUrl',
          'Type URL',
          'If empty, the default (https://secure.gooddata.com) will be used.'
        )}
        <FormGroup>
          <Col sm={9} smOffset={3}>
            <Checkbox
              checked={this.props.value.readLDM}
              onChange={(checked) => this.handleChange({ readLDM: checked })}
              disabled={this.props.disabled}
            >
              Read LDM model
            </Checkbox>
          </Col>
        </FormGroup>
        {this.props.value.readLDM && (
          <FormGroup>
            <Col sm={3} componentClass={ControlLabel}>
              Target bucket
            </Col>
            <Col sm={9}>
              <Select
                options={this.allBucketsOptions()}
                value={this.props.value.targetBucket}
                onChange={(selected) => this.handleChange({ targetBucket: selected })}
              />
            </Col>
          </FormGroup>
        )}
      </div>
    );
  },

  renderInputControlGroup(label, fieldName, placeholder, help) {
    const { disabled, value } = this.props;

    return (
      <FormGroup key={fieldName}>
        <Col sm={3} componentClass={ControlLabel}>
          {label}
        </Col>
        <Col sm={9}>
          {fieldName === 'password' ? (
            <PasswordControl
              placeholder={placeholder}
              disabled={disabled}
              onChange={(e) => this.handleChange({ [fieldName]: e.target.value })}
              value={value[fieldName] || ''}
            />
          ) : (
            <FormControl
              placeholder={placeholder}
              type="text"
              disabled={disabled}
              onChange={(e) => this.handleChange({ [fieldName]: e.target.value })}
              value={value[fieldName] || ''}
            />
          )}
          {help && <HelpBlock>{help}</HelpBlock>}
        </Col>
      </FormGroup>
    );
  }
});

export default NewProjectForm;
