import React from 'react';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { HelpBlock, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { capitalize } from 'underscore.string';

import ConfirmButtons from '../../../../react/common/ConfirmButtons';
import ModalIcon from '../../../../react/common/ModalIcon';
import Select from '../../../../react/common/Select';

const MergePhasesModal = createReactClass({
  mixins: [PureRenderMixin],
  propTypes: {
    tasks: PropTypes.object.isRequired,
    phases: PropTypes.object.isRequired,
    onMergePhases: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
  },

  getInitialState() {
    return {
      value: null,
      isSaving: false
    };
  },

  isValid() {
    return !!this.state.value;
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Merge Selected Phases</Modal.Title>
          <ModalIcon icon="code-merge" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <Select
            allowCreate
            clearable={false}
            value={this.state.value}
            promptTextCreator={(label) => `Create phase "${label}"`}
            placeholder="Select a phase or type a new..."
            onChange={(input) => this.setState({ value: input })}
            onInputChange={(inputValue) => capitalize(inputValue)}
            options={this.getPhasesOptions()}
          />
          <HelpBlock>Select an existing phase name or type a new phase name.</HelpBlock>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel="Merge"
            isDisabled={!this.isValid()}
            onSave={this.handleSave}
            isSaving={this.state.isSaving}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  getPhasesOptions() {
    const result = this.props.phases.map((key) => {
      return {
        label: key,
        value: key
      };
    });

    const phases =
      this.state.value !== null
        ? result.concat({ label: this.state.value, value: this.state.value })
        : result;

    return phases.toList().toJS();
  },

  closeModal() {
    this.setState({
      value: null
    });
    this.props.onHide();
  },

  handleSave() {
    this.setState({
      isSaving: true
    });
    this.props.onMergePhases(this.state.value);
    this.setState({
      value: null,
      isSaving: false
    });
  }
});

export default MergePhasesModal;
