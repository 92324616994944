import React from 'react';
import { Button, ControlLabel, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { Map } from 'immutable';
import Switch from 'rc-switch';
import { ltrim, trim } from 'underscore.string';

const KBC_TOKEN = 'kbc_token';
const KBC_URL = 'kbc_url';
const RESERVED_KEYWORDS = [KBC_TOKEN, KBC_URL];

const getDefaultName = (name?: string) => ltrim(name || '', '#');

type Props = {
  name?: string;
  value?: string;
  secrets: Map<string, string>;
  onSave: (name: string, value: string) => Promise<void>;
  onReset: () => void;
  isSaving: boolean;
  readOnly: boolean;
};

class SecretForm extends React.Component<Props> {
  state = {
    name: getDefaultName(this.props.name),
    value: this.props.value || '',
    isEncrypted: false
  };

  render() {
    return (
      <div className="variable-editing">
        <form
          className="!tw-block"
          onSubmit={(event) => {
            event.preventDefault();
            if (this.isDisabled()) {
              return;
            }

            const encrypted = this.state.isEncrypted || this.editingEncryptedSecret();

            this.props
              .onSave(`${encrypted ? '#' : ''}${this.state.name}`, this.state.value)
              .then(this.props.onReset);
          }}
        >
          <div className="tw-flex tw-flex-col tw-gap-4 md-legacy:tw-flex-row md-legacy:tw-justify-between md-legacy:tw-items-center">
            <div className="variable-form-inner">
              <FontAwesomeIcon icon="code" fixedWidth className="text-muted icon-addon-right" />
              <div className="variable-name">
                <input
                  autoFocus
                  type="text"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={(event) => this.setState({ name: trim(event.target.value) })}
                  className={classNames({
                    'has-error': !this.props.isSaving && this.hasError()
                  })}
                />
              </div>
              <div className="variable-value">
                <input
                  type={this.editingEncryptedSecret() ? 'password' : 'text'}
                  placeholder="Value"
                  value={this.state.value}
                  onChange={(event) => this.setState({ value: event.target.value })}
                />
              </div>
              {!this.editingEncryptedSecret() && (
                <FormGroup className="font-sans flex-container flex-start inline-flex clickable mb-0 ml-1">
                  <Switch
                    prefixCls="switch"
                    className="btn-icon wider"
                    checked={this.state.isEncrypted}
                    onChange={(isEncrypted) => this.setState({ isEncrypted })}
                  />
                  <ControlLabel
                    className="clickable text-muted f-13 mb-0"
                    onClick={() => this.setState({ isEncrypted: !this.state.isEncrypted })}
                  >
                    Encrypted
                  </ControlLabel>
                </FormGroup>
              )}
            </div>
            <div className="tw-flex tw-gap-3">
              <Button type="submit" bsStyle="success" bsSize="small" disabled={this.isDisabled()}>
                Save
              </Button>
              <Button type="reset" bsSize="small" onClick={this.handleReset}>
                Cancel
              </Button>
            </div>
          </div>

          {this.hasError() && (
            <div className="color-danger tw-text-xs tw-mt-2">{this.getErrorMessage()}</div>
          )}
        </form>
      </div>
    );
  }

  isNameDuplicate() {
    if (this.props.name === this.state.name) {
      return false;
    }

    return this.props.secrets.some((value, name) => {
      return name !== this.props.name && ltrim(name || '', '#') === this.state.name;
    });
  }

  editingEncryptedSecret() {
    return this.props.name?.startsWith('#');
  }

  includesReservedKeywords() {
    return RESERVED_KEYWORDS.includes(this.state.name.toLowerCase());
  }

  isDisabled() {
    const encrypted = this.state.isEncrypted || this.editingEncryptedSecret();
    const fullName = `${encrypted ? '#' : ''}${this.state.name}`;

    return (
      !trim(this.state.name) ||
      (this.props.name === fullName && this.props.value === this.state.value) ||
      this.props.isSaving ||
      this.isNameDuplicate() ||
      this.includesReservedKeywords()
    );
  }

  hasError() {
    return this.isNameDuplicate() || this.includesReservedKeywords();
  }

  getErrorMessage() {
    if (this.isNameDuplicate()) {
      return `The name ${this.state.name} is already used.`;
    }

    if (this.includesReservedKeywords()) {
      switch (this.state.name.toLowerCase()) {
        case KBC_TOKEN:
          return (
            <div className="tw-w-max tw-max-w-md">
              Secret with this name is automatically generated upon deployment, but it is not
              available for use in your application.
            </div>
          );

        case KBC_URL:
          return (
            <div className="tw-w-max tw-max-w-lg">
              Secret with this name is automatically generated upon deployment, and you can use it
              in your application. It represents your project location, such as
              connection.keboola.com.
            </div>
          );

        default:
          return 'Secret with this name is created automatically upon deploy.';
      }
    }

    return 'Error';
  }

  handleReset = () => {
    this.setState(
      { name: getDefaultName(this.props.name), value: this.props.value || '' },
      this.props.onReset
    );
  };
}

export default SecretForm;
