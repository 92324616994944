import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Map } from 'immutable';

import useStores from '../../../react/hooks/useStores';
import RoutesStore from '../../../stores/RoutesStore';
import BucketsStore from '../../components/stores/StorageBucketsStore';
import { isCreatedInDevBranch } from '../../dev-branches/helpers';

const BucketTitle = () => {
  const { bucket, bucketId } = useStores(
    () => {
      const bucketId = RoutesStore.getRouterState().getIn(['params', 'bucketId']);

      return {
        bucketId,
        bucket: BucketsStore.getBucket(bucketId, Map())
      };
    },
    [],
    [RoutesStore, BucketsStore]
  );

  return (
    <>
      <FontAwesomeIcon
        icon="folder"
        style={{ fontSize: '0.9em' }}
        className={classNames('text-muted', 'icon-addon-right', {
          'dev-bucket': isCreatedInDevBranch(bucket)
        })}
      />
      {bucket.get('displayName', bucketId)}
    </>
  );
};

export default BucketTitle;
