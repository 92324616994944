import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';

import Loader from './Loader';

const BlockButton = ({ onClick, label, isLoading, disabled, style, className }) => (
  <div className={classnames('block-button', className)}>
    <Button block bsStyle={style} onClick={onClick} disabled={disabled || isLoading}>
      {isLoading ? <Loader /> : label}
    </Button>
  </div>
);

BlockButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.oneOf(['default', 'primary', 'success']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

BlockButton.defaultProps = {
  style: 'primary',
  label: 'Load More',
  isLoading: false,
  disabled: false
};

export default BlockButton;
