import React from 'react';
import { type RouteProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import { KEBOOLA_SANDBOXES, KEBOOLA_SHARED_CODE } from '../../constants/componentIds';
import { resolveRouterLinkParams } from '../../modules/components/helpers';
import { routeNames } from '../../modules/components-directory/constants';
import {
  DBT_COMPONENTS,
  routeNames as transformationRouteNames
} from '../../modules/transformations-v2/constants';
import ApplicationStore from '../../stores/ApplicationStore';
import { getRealComponentId } from './ConfigurationsTable/helpers';
import DeleteConfigurationButton from './DeleteConfigurationButton';
import TabLink from './TabLink';
import TabNav from './TabNav';

const TABS = {
  CONFIG: 'config',
  NOTIFICATIONS: 'notifications',
  VERSIONS: 'versions',
  DISCOVERY: 'discovery'
};

type Props = {
  componentId: string;
  configId: string;
  rowId?: string;
  versionsLinkTo?: string;
  notificationsLinkTo?: string;
  routes: RouteProps[];
} & (
  | {
      showDeleteButton?: never;
      config?: never;
      flows?: never;
    }
  | {
      showDeleteButton: boolean;
      config: Map<string, any>;
      flows: Map<string, any>;
    }
);

const ConfigurationTabs = (props: Props) => {
  const routePath = props.routes[props.routes.length - 1]?.path;
  const active = routePath && Object.values(TABS).includes(routePath) ? routePath : TABS.CONFIG;
  const linkParams = resolveRouterLinkParams(props.componentId, props.configId, props.rowId);

  return (
    <div id="configuration-nav" className="tabs-with-border-wrapper flex-container">
      <TabNav>
        {linkParams && (
          <TabLink active={active === TABS.CONFIG} to={linkParams.to} params={linkParams.params}>
            <FontAwesomeIcon icon="circle-info" className="icon-addon-right" />
            Information &amp; Settings
          </TabLink>
        )}
        {ApplicationStore.hasNewQueue() &&
          !props.rowId &&
          ![KEBOOLA_SANDBOXES, KEBOOLA_SHARED_CODE].includes(props.componentId) && (
            <TabLink
              active={active === TABS.NOTIFICATIONS}
              to={props.notificationsLinkTo || routeNames.GENERIC_CONFIG_NOTIFICATIONS}
              params={{ component: props.componentId, config: props.configId }}
            >
              <FontAwesomeIcon icon="bell-on" className="icon-addon-right" />
              Notifications
            </TabLink>
          )}
        {DBT_COMPONENTS.includes(props.componentId) && (
          <TabLink
            active={active === TABS.DISCOVERY}
            to={transformationRouteNames.GENERIC_TRANSFORMATION_CONFIG_DISCOVERY}
            params={{ component: props.componentId, config: props.configId }}
          >
            <FontAwesomeIcon icon="magnifying-glass" className="icon-addon-right" />
            Discovery
          </TabLink>
        )}
        <TabLink
          active={active === TABS.VERSIONS}
          to={
            props.versionsLinkTo
              ? props.versionsLinkTo
              : props.rowId
              ? routeNames.GENERIC_CONFIG_ROW_VERSIONS
              : routeNames.GENERIC_CONFIG_VERSIONS
          }
          params={{ component: props.componentId, config: props.configId, row: props.rowId }}
        >
          <FontAwesomeIcon icon="clone" className="icon-addon-right" />
          Versions
        </TabLink>
      </TabNav>
      {props.showDeleteButton && (
        <span className="ml-auto">
          <DeleteConfigurationButton
            mode="navigation"
            flows={props.flows}
            componentId={getRealComponentId(props.config, Map({ id: props.componentId }))}
            config={props.config}
          />
        </span>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default withRouter(ConfigurationTabs);
