import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
  Well
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, List } from 'immutable';

import ConfirmButtons from '../../../react/common/ConfirmButtons';
import ModalIcon from '../../../react/common/ModalIcon';
import SyncActionError from '../../../utils/errors/SyncActionError';
import { searchFile } from '../action';

const INITIAL_STATE = {
  showModal: false,
  isSearching: false,
  error: null,
  warning: null,
  files: List(),
  search: ''
};

class FileSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Button bsStyle="success" onClick={this.openModal} disabled={this.props.disabled}>
          <FontAwesomeIcon icon="magnifying-glass" className="icon-addon-right" />
          Search file
        </Button>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Form horizontal onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Search Excel Files</Modal.Title>
              <ModalIcon icon="magnifying-glass" color="green" bold />
            </Modal.Header>
            <Modal.Body>
              {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
              {this.state.warning && <Alert bsStyle="warning">{this.state.warning}</Alert>}
              <FormGroup>
                <Col sm={3} componentClass={ControlLabel}>
                  Search
                </Col>
                <Col sm={9}>
                  <FormControl
                    autoFocus
                    type="text"
                    disabled={this.state.isSearching}
                    value={this.state.search}
                    onChange={(e) => this.setState({ search: e.target.value })}
                  />
                  <HelpBlock>
                    Allowed formats are: <br />
                    <code>{'/path/to/file.xlsx'}</code> - the file is searched on a personal
                    OneDrive, or
                    <br />
                    <code>{'https://{siteName}.sharepoint.com/sites/path/to/file.xlsx'}</code>,{' '}
                    <code>{'site://{siteName}/path/to/file.xlsx'}</code> - the file is searched on
                    SharePoint site
                  </HelpBlock>
                </Col>
              </FormGroup>
              {this.state.files.count() > 0 && (
                <Well className="m-0 overflow-auto">
                  <p className="text-muted f-14">Select file</p>
                  {this.state.files
                    .map((file) => (
                      <Button
                        block
                        bsStyle="link"
                        className="btn-link-inline"
                        key={file.get('fileId')}
                        onClick={() => {
                          this.props.onSelect({
                            name: file.get('name'),
                            driveId: file.get('driveId'),
                            fileId: file.get('fileId')
                          });
                          this.closeModal();
                        }}
                      >
                        <FontAwesomeIcon
                          icon="file-excel"
                          className="icon-addon-right text-muted"
                        />
                        {file.get('path')} - {file.get('name')}
                      </Button>
                    ))
                    .toArray()}
                </Well>
              )}
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveLabel="Search"
                saveButtonType="submit"
                isSaving={this.state.isSearching}
                isDisabled={!this.state.search.trim()}
              />
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isSearching: true, warning: null, error: null });
    return searchFile(this.props.configId, this.state.search)
      .then((response) => {
        const files = fromJS(response.files);

        if (files.count() > 0) {
          this.setState({ files });
        } else {
          this.setState({ warning: 'No files was found for given search.' });
        }
      })
      .catch(SyncActionError, (error) => {
        this.setState({ error: error.message });
      })
      .finally(() => {
        this.setState({ isSearching: false });
      });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState(INITIAL_STATE);
  }
}

FileSearch.propTypes = {
  disabled: PropTypes.bool.isRequired,
  configId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default FileSearch;
