import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import InputOutputTypeIcon from '../../../../../react/common/InputOutputTypeIcon';
import Tooltip from '../../../../../react/common/Tooltip';
import { ioType } from '../../../../components/Constants';
import StorageApiTableLinkEx from '../../../../components/react/components/StorageApiTableLinkEx';
import actionCreators from '../../../ActionCreators';
import OutputMappingModal from '../../modals/OutputMapping';

const OutputMappingRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    outputMapping: PropTypes.object.isRequired,
    mappingIndex: PropTypes.number.isRequired,
    editingOutputMapping: PropTypes.object.isRequired,
    editingId: PropTypes.string.isRequired,
    transformation: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    bucket: PropTypes.object.isRequired,
    pendingActions: PropTypes.object.isRequired,
    definition: PropTypes.object,
    otherOutputMappings: PropTypes.object,
    disabled: PropTypes.bool
  },

  getDefaultProps() {
    return {
      definition: Map(),
      disabled: false
    };
  },

  render() {
    return (
      <span className="tr hoverable-actions">
        {this.renderColumns()}
        {this.renderActionButtons()}
      </span>
    );
  },

  renderColumns() {
    if (!this.props.definition.has('label')) {
      return (
        <>
          <span className="td col-xs-5">
            <InputOutputTypeIcon
              type={
                this.props.transformation.get('backend') === 'docker' ? ioType.FILE : ioType.TABLE
              }
            />
            {this.props.transformation.get('backend') === 'docker'
              ? `out/tables/${this.props.outputMapping.get('source')}`
              : this.props.outputMapping.get('source')}
          </span>
          <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
          <span className="td col-xs-5">{this.renderDestination()}</span>
        </>
      );
    }

    return (
      <>
        <span className="td col-xs-5">{this.props.definition.get('label')}</span>
        <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
        <span className="td col-xs-5">{this.renderDestination()}</span>
      </>
    );
  },

  renderRightArrow() {
    if (this.props.outputMapping.get('incremental')) {
      return (
        <Tooltip placement="top" tooltip="Incremental">
          <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />
        </Tooltip>
      );
    }

    return <FontAwesomeIcon icon={['far', 'chevron-right']} className="text-muted" />;
  },

  renderActionButtons() {
    if (this.props.readOnly) {
      return <span className="td pl-0 pr-1 no-wrap" />;
    }

    return (
      <span className="td pl-0 pr-1 no-wrap">
        <OutputMappingModal
          mode="edit"
          transformationBucket={this.props.bucket}
          tables={this.props.tables}
          buckets={this.props.buckets}
          backend={this.props.transformation.get('backend')}
          type={this.props.transformation.get('type')}
          mapping={this.props.editingOutputMapping}
          onChange={this._handleChange}
          onCancel={this._handleCancel}
          onSave={this._handleSave}
          definition={this.props.definition}
          otherOutputMappings={this.props.otherOutputMappings}
          disabled={this.props.disabled}
        />
        {this.props.outputMapping.get('destination') !== '' && (
          <Tooltip tooltip="Delete Output" placement="top">
            <Button
              bsStyle="link"
              className="text-muted"
              onClick={this._handleDelete}
              disabled={
                this.props.disabled ||
                this.props.pendingActions.get(`delete-output-${this.props.mappingIndex}`)
              }
            >
              <FontAwesomeIcon icon="trash" fixedWidth />
            </Button>
          </Tooltip>
        )}
      </span>
    );
  },

  renderDestination() {
    if (!this.props.outputMapping.get('destination')) {
      return 'Not set';
    }

    return <StorageApiTableLinkEx tableId={this.props.outputMapping.get('destination')} />;
  },

  _handleChange(newMapping) {
    return actionCreators.updateTransformationEditingField(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      this.props.editingId,
      newMapping
    );
  },

  _handleCancel() {
    return actionCreators.cancelTransformationEditingField(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      this.props.editingId
    );
  },

  _handleSave() {
    return actionCreators.saveTransformationMapping(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      'output',
      this.props.editingId,
      this.props.mappingIndex
    );
  },

  _handleDelete() {
    return actionCreators.deleteTransformationMapping(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      'output',
      this.props.mappingIndex
    );
  }
});

export default OutputMappingRow;
