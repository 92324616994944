import React from 'react';
import confetti from 'canvas-confetti';

const fireConfetti = () => {
  const commonOptions = {
    particleCount: 200,
    spread: 55,
    colors: ['1F8FFF', 'FFFFFF', 'C5CBD6']
  };

  confetti({
    ...commonOptions,
    origin: { x: 1 },
    angle: 120
  });

  confetti({
    ...commonOptions,
    origin: { x: 0 },
    angle: 60
  });
};

type Props = { router: Record<string, any>; location: Record<string, any> };

class Confetti extends React.PureComponent<Props> {
  componentDidMount() {
    fireConfetti();

    const query = { ...this.props.location.query };
    delete query.celebrate;
    this.props.router.replace({ ...this.props.location, query });
  }

  render() {
    return null;
  }
}

export default Confetti;
