import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import {
  ACADEMY_URL,
  COMMUNITY_URL,
  USER_DOCUMENTATION_URL
} from '../../../constants/KbcConstants';
import { routeNames as settingsRouteNames } from '../../../modules/settings/constants';
import contactSupport from '../../../utils/contactSupport';
import Gravatar from '../../common/Gravatar';
import Truncated from '../../common/Truncated';
import LinkMenuItem from './LinkMenuItem';
import UserFeaturesModal from './UserFeaturesModal';

/** @type {any} */
const CurrentUser = createReactClass({
  propTypes: {
    user: PropTypes.object.isRequired,
    urlTemplates: PropTypes.object.isRequired,
    canManageApps: PropTypes.bool.isRequired,
    hasPayAsYouGo: PropTypes.bool.isRequired,
    isSingleTenant: PropTypes.bool.isRequired,
    isMarketplaceProject: PropTypes.bool.isRequired,
    availableFeatures: PropTypes.instanceOf(List).isRequired,
    activatedFeatures: PropTypes.instanceOf(List).isRequired,
    isCollapsed: PropTypes.bool.isRequired,
    isDevModeActive: PropTypes.bool
  },

  getDefaultProps() {
    return {
      isDevModeActive: false
    };
  },

  getInitialState() {
    return {
      isOpen: false,
      showFeaturesModal: false
    };
  },

  render() {
    return (
      <>
        <div className="user">
          <Dropdown
            dropup
            id="current-user-dropdown"
            rootCloseEvent="mousedown"
            open={this.state.isOpen}
            onToggle={(isOpen, event, data) => {
              if (
                data.source === 'rootClose' &&
                event.composedPath?.().some((el) => el.id === 'current-user-dropdown')
              ) {
                return;
              }

              this.setState({ isOpen });
            }}
            pullRight
          >
            <Dropdown.Toggle noCaret bsStyle="link" className="avatar">
              {this.props.isCollapsed ? (
                <Gravatar size={32} user={this.props.user} className="user-avatar" />
              ) : (
                this.props.user.get('name') || this.props.user.get('email')
              )}
              {!this.props.isCollapsed && <FontAwesomeIcon icon="angle-right" />}
            </Dropdown.Toggle>
            <Dropdown.Menu className="tw-min-w-[240px] tw-mt-1 tw-p-2">
              <div className="tw-p-2">
                {!!this.props.user.get('name') && (
                  <Truncated
                    className="tw-text-base tw-font-medium"
                    text={this.props.user.get('name')}
                  />
                )}
                <Truncated
                  className="tw-text-xs tw-text-neutral-400"
                  text={this.props.user.get('email')}
                />
              </div>
              {this.props.canManageApps && (
                <MenuItem
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.props.urlTemplates.get('manageApps')}
                >
                  Manage Applications
                </MenuItem>
              )}
              <MenuItem
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.urlTemplates.get('changePassword')}
              >
                My Account &amp; Organizations
              </MenuItem>
              <MenuItem onClick={() => this.setState({ showFeaturesModal: true })}>
                User Features
              </MenuItem>
              {this.renderDivider()}
              <LinkMenuItem to={settingsRouteNames.PROJECT} forceProduction>
                Project Settings
              </LinkMenuItem>
              {!this.props.isSingleTenant && (
                <LinkMenuItem to="billing-consumption">Project Consumption</LinkMenuItem>
              )}
              {!this.props.hasPayAsYouGo && !this.props.isSingleTenant && (
                <LinkMenuItem to="organization-usage">Organization Usage</LinkMenuItem>
              )}
              {this.renderBillingLink()}
              {this.renderDivider()}
              <MenuItem onClick={this.openSupportModal}>Support</MenuItem>
              <MenuItem href={USER_DOCUMENTATION_URL} target="_blank" rel="noopener noreferrer">
                Help
              </MenuItem>
              <MenuItem href={COMMUNITY_URL} target="_blank" rel="noopener noreferrer">
                Community Portal
              </MenuItem>
              <MenuItem href={ACADEMY_URL} target="_blank" rel="noopener noreferrer">
                Academy
              </MenuItem>
              {this.renderDivider()}
              <MenuItem href={this.props.urlTemplates.get('logout')}>Logout</MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <UserFeaturesModal
          show={this.state.showFeaturesModal}
          onHide={() => this.setState({ showFeaturesModal: false })}
          availableFeatures={this.props.availableFeatures}
          activatedFeatures={this.props.activatedFeatures}
        />
      </>
    );
  },

  renderBillingLink() {
    if (!this.props.hasPayAsYouGo || this.props.isMarketplaceProject) {
      return null;
    }

    return <LinkMenuItem to="billing-overview">Billing</LinkMenuItem>;
  },

  renderDivider() {
    return <MenuItem divider className="!tw-m-2 !tw-bg-neutral-200" />;
  },

  openSupportModal(e) {
    e.preventDefault();

    contactSupport();
  }
});

export default CurrentUser;
