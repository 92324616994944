import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import { useExpanded, useRowSelect, useSortBy, useTable } from 'react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import classnames from 'classnames';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import {
  COLLAPSED_CONFIGURATIONS,
  CONFIGURATIONS_SORT_BY
} from '../../../constants/localStorageKeys';
import dayjs from '../../../date';
import { getFolderFromMetadata } from '../../../modules/components/helpers';
import useMatchMedia from '../../../react/hooks/useMatchMedia';
import { getItem } from '../../../utils/localStorage';
import onClickSelectionCell from '../../../utils/onClickSelectionCell';
import string from '../../../utils/string';
import useLocalStorage from '../../hooks/useLocalStorage';
import CollapseButton from '../CollapseButton';
import ComponentBadges from '../ComponentBadges';
import ComponentDetailLink from '../ComponentDetailLink';
import ComponentIcon from '../ComponentIcon';
import ComponentName from '../ComponentName';
import ComponentType from '../ComponentType';
import DeleteConfigurationButton from '../DeleteConfigurationButton';
import StorageData from '../StorageData';
import ActionButtons from './ActionButtons';
import AddNewConfigButton from './AddNewConfigButton';
import CheckboxCell from './CheckboxCell';
import ConfigurationActions from './ConfigurationActions';
import ConfigurationNameWithDescription from './ConfigurationNameWithDescription';
import ConfigurationRow from './ConfigurationRow';
import ConfigurationsFolderName from './ConfigurationsFolderName';
import { DEFAULT_FOLDER_NAME, MULTI_ACTIONS } from './constants';
import DeleteConfigurationsModal from './DeleteConfigurationsModal';
import EmptyRow from './EmptyRow';
import FolderRow from './FolderRow';
import { getRealComponentId } from './helpers';
import LastChange from './LastChange';
import LastChangeActionCell from './LastChangeActionCell';
import MoveConfigurationsModal from './MoveConfigurationsModal';
import PaddingRow from './PaddingRow';
import LastUseActionCell from './RenderLastUseActionCell';
import TableHead from './TableHead';
import UsedInCell from './UsedInCell';

const VIRTUALIZATION_LIMIT = 50;
const HIDDEN_COLUMNS_WIDTH_LIMIT = 1281;

const getTableInitialState = (
  data,
  forceShowAll,
  supportFolders,
  expandedFolders,
  sortByKey,
  columnsHiddenForSmallerScreens
) => {
  let expanded = {};

  if (forceShowAll) {
    expanded = data.reduce((all, row, index) => ({ ...all, [index]: true }), {});
  } else if (supportFolders) {
    const uncategorizedFolder = data.findIndex((row) => row.data === DEFAULT_FOLDER_NAME);

    expanded = data.reduce(
      (all, row, index) => {
        return { [index]: expandedFolders.get(row.data, false), ...all };
      },
      { [uncategorizedFolder]: true }
    );
  }

  const [id, order] = getItem(`${CONFIGURATIONS_SORT_BY}-${sortByKey}`, 'last_change:asc').split(
    ':'
  );

  return {
    sortBy: [{ id, desc: order === 'desc' }],
    expanded,
    ...(window.innerWidth < HIDDEN_COLUMNS_WIDTH_LIMIT && {
      hiddenColumns: columnsHiddenForSmallerScreens
    })
  };
};

const Table = ({
  columns,
  data,
  component,
  allComponents,
  readOnly,
  hasNewQueue,
  hasFlows,
  showMigrations,
  allowCreateConfig,
  customClasses,
  forceComponentType,
  forceShowAll,
  configurations,
  expandedFolders,
  supportFolders,
  columnsHiddenForSmallerScreens,
  isLoading
}) => {
  const sortByKey = component?.get('id') || forceComponentType;
  const initialState = React.useMemo(
    () =>
      getTableInitialState(
        data,
        forceShowAll,
        supportFolders,
        expandedFolders,
        sortByKey,
        columnsHiddenForSmallerScreens
      ),
    [data, forceShowAll, supportFolders, expandedFolders, sortByKey, columnsHiddenForSmallerScreens]
  );

  const fakeFolderId = data.findIndex((row) => row.data === DEFAULT_FOLDER_NAME);
  const isInFolder = (row) => row.depth === 1 && !row.id.startsWith(`${fakeFolderId}.`);

  const tableInstance = useTable(
    { columns, data, initialState, disableSortRemove: true },
    useSortBy,
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (configurations.isEmpty()) {
          return columns;
        }

        if (readOnly) {
          return columns.map((column) => {
            if (column.id !== 'data') {
              return column;
            }

            return {
              ...column,
              Cell: (props) => {
                if (!isInFolder(props.row)) {
                  return column.Cell(props);
                }

                return <div className="tw-pl-6">{column.Cell(props)}</div>;
              }
            };
          });
        }

        const renderCheckboxHeader = ({ rows, rowsById }) => {
          const isAllSelected = rows.every((row) => row.isSelected);
          const isSomeSelected = rows.some((row) => row.isSelected);

          return (
            <div
              onClick={onClickSelectionCell}
              className="tw-p-1 tw-pr-3 tw-pl-6 -tw-ml-6 tw-inline-flex tw-items-center"
            >
              <CheckboxCell
                tooltip={isAllSelected || isSomeSelected ? 'Deselect all' : 'Select all'}
                checked={isAllSelected}
                indeterminate={!isAllSelected && isSomeSelected}
                onChange={() => {
                  const toggleState = !(isAllSelected || isSomeSelected);
                  Object.values(rowsById)
                    .filter(
                      (row) =>
                        !row.values.data.config?.get('isUnselectable') &&
                        (!supportFolders || row.depth > 0)
                    )
                    .forEach((row) => {
                      if (!row.toggleRowSelected) {
                        tableInstance.prepareRow(row);
                      }
                      row.toggleRowSelected(toggleState);
                    });
                }}
              />
            </div>
          );
        };

        const renderCheckboxCell = ({ row }) => {
          return (
            <div
              onClick={onClickSelectionCell}
              className={classnames(
                'tw-p-3 tw-inline-flex tw-items-center',
                isInFolder(row) ? 'tw-pl-12 -tw-ml-12' : 'tw-pl-6 -tw-ml-6'
              )}
            >
              <CheckboxCell
                tooltip={
                  supportFolders && row.depth === 0
                    ? `${row.isSelected || row.isSomeSelected ? 'Deselect' : 'Select'} folder`
                    : `${row.isSelected ? 'Deselect' : 'Select'} ${
                        forceComponentType || 'configuration'
                      }`
                }
                checked={row.isSelected}
                indeterminate={!row.isSelected && row.isSomeSelected}
                onChange={(checked) => {
                  if (supportFolders && row.depth === 0) {
                    row.subRows.forEach((subRow) => {
                      if (!subRow.toggleRowSelected) {
                        tableInstance.prepareRow(subRow);
                      }
                      subRow.toggleRowSelected(!(row.isSelected || row.isSomeSelected));
                    });
                  } else {
                    row.toggleRowSelected(checked);
                  }
                }}
                disabled={row.values.data.config?.get('isUnselectable') ?? false}
              />
            </div>
          );
        };

        return columns.map((column) => {
          if (column.id !== 'data') {
            return column;
          }

          return {
            ...column,
            Header: (props) => {
              return (
                <div className="flex-container inline-flex flex-start">
                  {renderCheckboxHeader(props)}
                  {column.Header(props)}
                </div>
              );
            },
            Cell: (props) => {
              return (
                <div
                  className={classnames('flex-container inline-flex flex-start', {
                    'tw-pl-7': isInFolder(props.row)
                  })}
                >
                  {renderCheckboxCell(props)}
                  {column.Cell(props)}
                </div>
              );
            }
          };
        });
      });
    }
  );

  const matchMediaHandler = React.useCallback(
    ({ matches }) => {
      return tableInstance.columns
        .filter((column) => columnsHiddenForSmallerScreens.includes(column.id))
        .forEach((column) => column.toggleHidden(!matches));
    },
    [columnsHiddenForSmallerScreens, tableInstance.columns]
  );

  useMatchMedia(`(min-width: ${HIDDEN_COLUMNS_WIDTH_LIMIT}px)`, matchMediaHandler);

  const renderHeader = () => {
    return (
      <>
        <TableHead
          tableInstance={tableInstance}
          sortByKey={sortByKey}
          allowCreateConfig={allowCreateConfig}
          customClasses={customClasses}
          readOnly={readOnly}
        />
        {allowCreateConfig && (
          <AddNewConfigButton
            allComponents={allComponents}
            component={component}
            readOnly={readOnly}
            showMigrations={showMigrations}
            hasConfigurations={!configurations.isEmpty()}
            hasNewQueue={hasNewQueue}
            hasFlows={hasFlows}
          />
        )}
      </>
    );
  };

  const renderRow = (row, index) => {
    if (!row.getRowProps) {
      tableInstance.prepareRow(row);
    }

    if (supportFolders && row.depth === 0) {
      return (
        <FolderRow
          key={row.values.data}
          row={row}
          isFirst={index === 0}
          forceComponentType={forceComponentType}
          customClasses={customClasses}
        />
      );
    }

    return (
      <ConfigurationRow
        key={row.values.data.id}
        row={row}
        customClasses={customClasses}
        readOnly={readOnly}
        hasFlows={hasFlows}
      />
    );
  };

  if (tableInstance.rows.length > VIRTUALIZATION_LIMIT) {
    return (
      <VirtualizedTable
        renderHeader={renderHeader}
        renderRow={renderRow}
        tableInstance={tableInstance}
      />
    );
  }

  return (
    <>
      {renderHeader()}
      <div {...tableInstance.getTableProps({ className: 'table table-hover react-table' })}>
        <div {...tableInstance.getTableBodyProps({ className: 'tbody' })}>
          {tableInstance.rows.length === 0 ? (
            <EmptyRow isLoading={isLoading} forceComponentType={forceComponentType} />
          ) : (
            tableInstance.rows.map(renderRow)
          )}
        </div>
      </div>
    </>
  );
};

const VirtualizedTable = ({ renderHeader, renderRow, tableInstance }) => {
  const tableContainerRef = React.useRef();
  const rowVirtualizer = useVirtualizer({
    count: tableInstance.rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 58,
    overscan: 5
  });
  const virtualizedRows = rowVirtualizer.getVirtualItems();
  const [paddingBefore, paddingAfter] = [
    virtualizedRows[0]?.start || 0,
    rowVirtualizer.getTotalSize() - virtualizedRows[virtualizedRows.length - 1]?.end || 0
  ];

  return (
    <div
      ref={tableContainerRef}
      className="tw-min-h-[48rem] tw-h-[72vh] tw-overflow-y-auto [overflow-anchor:none] sticky-position-zero"
    >
      {renderHeader()}
      <div {...tableInstance.getTableProps({ className: 'table table-hover react-table' })}>
        <div {...tableInstance.getTableBodyProps({ className: 'tbody' })}>
          <PaddingRow height={paddingBefore} />
          {virtualizedRows.map((virtualRow) => {
            return renderRow(tableInstance.rows[virtualRow.index], virtualRow.index);
          })}
          <PaddingRow height={paddingAfter} />
        </div>
      </div>
    </div>
  );
};

/** @type {any} */
const ConfigurationsTable = ({
  allConfigurations,
  configurations,
  availableConfigurations,
  notifications,
  component,
  currentAdmin,
  admins,
  readOnly,
  renderAdditionalActions,
  additionalColumns,
  allowCreateConfig,
  hasFlows,
  hasNewQueue,
  isDevModeActive,
  showComponentIcon,
  showComponentDetailLink,
  componentsMetadata,
  expandedFolders,
  showMigrations,
  showData,
  showUsedIn,
  hideCommonActions,
  allComponents,
  tablesMetadataMap,
  latestJobs,
  forceShowAll,
  supportFolders,
  forceComponentType,
  customClasses,
  renderCustomConfigurationIcon,
  renderNameLabel,
  renderCustomLabel,
  renderAdditionalMultiActions,
  columnsHiddenForSmallerScreens,
  isLoading,
  className,
  getMultiActionDeleteDisabledReason
}) => {
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    `${COLLAPSED_CONFIGURATIONS}-${component?.get('id')}`,
    false
  );
  const [multiAction, setMultiAction] = React.useState('');
  const [selectedRows, setSelectedRows] = React.useState([]);
  const flows = allConfigurations.getIn([KEBOOLA_ORCHESTRATOR, 'configurations'], Map());

  const sortByName = React.useCallback(
    (rowA, rowB, columnId, desc) => {
      if (
        rowA.values.data.config?.get('isCreating') ||
        rowB.values.data.config?.get('isCreating')
      ) {
        return (
          rowB.values.data.config?.get('isCreating') &&
          dayjs(rowA.values.data.config?.getIn(['currentVersion', 'created'])).isBefore(
            dayjs(rowB.values.data.config?.getIn(['currentVersion', 'created']))
          )
        );
      }

      if (rowA.values.data === DEFAULT_FOLDER_NAME && rowB.values.data !== DEFAULT_FOLDER_NAME) {
        return desc ? -1 : 1;
      }

      if (rowA.values.data !== DEFAULT_FOLDER_NAME && rowB.values.data === DEFAULT_FOLDER_NAME) {
        return desc ? 1 : -1;
      }

      if (supportFolders && rowA.depth === 0 && rowB.depth === 0) {
        return rowA.values.data.trim().localeCompare(rowB.values.data.trim());
      }

      return rowA.values.data.config
        .get('name')
        .trim()
        .localeCompare(rowB.values.data.config.get('name').trim());
    },
    [supportFolders]
  );

  const sortByLastChange = React.useCallback(
    (rowA, rowB, columnId, desc) => {
      if (supportFolders && rowA.depth === 0 && rowB.depth === 0) {
        return sortByName(rowA, rowB, columnId, desc);
      }

      const valueA = new Date(
        rowA.values.data.config.getIn(['currentVersion', 'created'])
      ).valueOf();
      const valueB = new Date(
        rowB.values.data.config.getIn(['currentVersion', 'created'])
      ).valueOf();
      if (valueA < valueB) return 1;
      if (valueA > valueB) return -1;
      return 0;
    },
    [supportFolders, sortByName]
  );

  const sortByLastUse = React.useCallback(
    (rowA, rowB, columnId, desc) => {
      if (supportFolders && rowA.depth === 0 && rowB.depth === 0) {
        return sortByName(rowA, rowB, columnId, desc);
      }

      const valueA = new Date(rowA.values.job.get('createdTime')).valueOf();
      const valueB = new Date(rowB.values.job.get('createdTime')).valueOf();
      if (valueA < valueB || (_.isNaN(valueA) && !_.isNaN(valueB))) return 1;
      if (valueA > valueB || (_.isNaN(valueB) && !_.isNaN(valueA))) return -1;
      return 0;
    },
    [supportFolders, sortByName]
  );

  const columns = React.useMemo(() => {
    const customLastColumn = additionalColumns.find((column) => column.isLastColumn);

    const renderActions = (row, body) => {
      if (row.values.data.config?.get('isDummyItem')) {
        return (
          <div className="actions-container">
            <div className="not-actions">{body}</div>
            <div className="actions -tw-mr-1">
              <DeleteConfigurationButton
                mode="navigation"
                flows={flows}
                componentId={row.values.data.component.get('id')}
                config={row.values.data.config}
              />
            </div>
          </div>
        );
      }

      return (
        <ConfigurationActions
          configuration={row.values.data.config}
          component={row.values.data.component}
          flows={flows}
          renderAdditionalActions={renderAdditionalActions}
          hasFlows={hasFlows}
          readOnly={readOnly}
          hasNewQueue={hasNewQueue}
          hideCommonActions={hideCommonActions}
          notifications={notifications}
          currentAdmin={currentAdmin}
          latestJob={row.values.job}
          {...(supportFolders && {
            onMove: () => {
              setMultiAction(MULTI_ACTIONS.MOVE);
              setSelectedRows([row.values.data]);
            }
          })}
        >
          {body}
        </ConfigurationActions>
      );
    };

    return [
      {
        sortType: sortByName,
        Header: ({ state, rowsById }) => {
          const selected = Object.keys(state.selectedRowIds);
          const selectedConfigurations = Object.values(rowsById)
            .filter((row) => selected.includes(row.id))
            .map((row) => row.values.data)
            .filter((row) => !!row?.config);

          return (
            <ActionButtons
              supportFolders={supportFolders}
              openDeleteModal={() => {
                setMultiAction(MULTI_ACTIONS.DELETE);
                setSelectedRows(selectedConfigurations);
              }}
              openMoveModal={() => {
                setMultiAction(MULTI_ACTIONS.MOVE);
                setSelectedRows(selectedConfigurations);
              }}
              renderAdditionalMultiActions={renderAdditionalMultiActions}
              selectedConfigurations={selectedConfigurations}
              forceComponentType={forceComponentType}
              disableDeleteReason={getMultiActionDeleteDisabledReason?.(selectedConfigurations)}
            />
          );
        },
        Cell: ({ row }) => {
          if (supportFolders && row.depth === 0) {
            return (
              <ConfigurationsFolderName
                name={row.values.data}
                isExpanded={row.isExpanded}
                readOnly={readOnly}
                rows={row.subRows}
              />
            );
          }

          return (
            <ConfigurationNameWithDescription
              allConfigurations={allConfigurations}
              configuration={row.values.data.config}
              component={row.values.data.component}
              readOnly={readOnly}
              showComponentIcon={showComponentIcon}
              customConfigurationIcon={renderCustomConfigurationIcon?.(row)}
              nameLabel={renderNameLabel?.(row)}
              customLabel={renderCustomLabel?.(row)}
              hasFlows={hasFlows}
              componentsMetadata={componentsMetadata}
              isDevModeActive={isDevModeActive}
            />
          );
        },
        accessor: 'data'
      },
      ...additionalColumns.filter(
        (column) => !column.isLastColumn && column.accessor !== 'run_results'
      ),
      showData &&
        tablesMetadataMap && {
          disableSortBy: true,
          Header: 'Data',
          Cell: ({ row }) => {
            if (supportFolders && row.depth === 0) {
              return null;
            }

            return (
              <span onClick={(e) => e.stopPropagation()}>
                <StorageData
                  tableMode
                  tablesMetadataMap={tablesMetadataMap}
                  component={row.values.data.component}
                  config={row.values.data.config}
                />
              </span>
            );
          },
          accessor: 'storage_data'
        },
      showUsedIn &&
        hasFlows && {
          disableSortBy: true,
          accessor: 'used_in',
          Header: 'Used In',
          Cell: ({ row }) => {
            const { config, component } = row.values.data;

            if (!component || !config) {
              return null;
            }

            return (
              <UsedInCell
                component={component}
                config={config}
                componentsMetadata={componentsMetadata}
                flows={flows}
              />
            );
          }
        },
      {
        sortType: sortByLastChange,
        Header: 'Last Change',
        Cell: ({ row }) => {
          if (supportFolders && row.depth === 0) {
            return null;
          }

          if ((hasNewQueue && latestJobs) || customLastColumn) {
            return <LastChange configuration={row.values.data.config} admins={admins} />;
          }

          return <LastChangeActionCell admins={admins} row={row} renderAction={renderActions} />;
        },
        accessor: 'last_change'
      },
      ...additionalColumns.filter((column) => column.accessor === 'run_results'),
      hasNewQueue &&
        latestJobs && {
          sortType: sortByLastUse,
          Header: 'Last Use',
          Cell: ({ row }) => {
            if (supportFolders && row.depth === 0) {
              return null;
            }

            return <LastUseActionCell admins={admins} row={row} renderAction={renderActions} />;
          },
          accessor: 'job'
        },
      customLastColumn && {
        ...customLastColumn,
        Cell: (cellProps) => {
          if (
            cellProps.row.values.data.config?.get('isCreating') ||
            cellProps.row.values.data.config?.get('isDummyItem')
          ) {
            return customLastColumn.Cell(cellProps);
          }

          return renderActions(cellProps.row, customLastColumn.Cell(cellProps));
        }
      }
    ].filter((column, index) => !!column && (!configurations.isEmpty() || index === 0));
  }, [
    additionalColumns,
    sortByName,
    showData,
    showUsedIn,
    tablesMetadataMap,
    sortByLastChange,
    hasNewQueue,
    latestJobs,
    sortByLastUse,
    renderAdditionalActions,
    hasFlows,
    readOnly,
    isDevModeActive,
    hideCommonActions,
    componentsMetadata,
    supportFolders,
    configurations,
    notifications,
    flows,
    getMultiActionDeleteDisabledReason,
    renderAdditionalMultiActions,
    forceComponentType,
    allConfigurations,
    showComponentIcon,
    renderCustomConfigurationIcon,
    renderNameLabel,
    renderCustomLabel,
    currentAdmin,
    admins
  ]);

  const memoizedRows = React.useMemo(() => {
    const restructureConfigurations = (configurations) =>
      configurations
        .map((configuration, configurationId) => {
          const componentData = configuration.get('component', component);
          const realComponentId = getRealComponentId(configuration, componentData);

          return {
            data: {
              config: configuration,
              component: componentData,
              id: `${realComponentId}-${configurationId}`
            },
            job: latestJobs?.getIn([realComponentId, configurationId, 0], Map())
          };
        })
        .toArray();

    if (supportFolders) {
      return configurations
        .map((configuration) => {
          const folder = getFolderFromMetadata(
            componentsMetadata.getIn([
              configuration.get('component', component).get('id'),
              configuration.get('id')
            ]),
            DEFAULT_FOLDER_NAME
          );

          return configuration.set('folder', folder);
        })
        .groupBy((configuration) => configuration.get('folder'))
        .map((configurations, folder) => {
          return {
            data: folder,
            subRows: restructureConfigurations(configurations)
          };
        })
        .toArray();
    }

    return restructureConfigurations(configurations);
  }, [component, componentsMetadata, configurations, latestJobs, supportFolders]);

  const toggleCollapse = () => {
    if (forceShowAll) {
      return;
    }

    setIsCollapsed(!isCollapsed);
    document.activeElement?.blur();
  };

  return (
    <>
      <div className={classnames('box mbp-6', className)}>
        {showComponentDetailLink && (
          <div
            onClick={toggleCollapse}
            className={classnames('flex-container component-name-above-table', {
              'btn-collapse-area': !forceShowAll
            })}
          >
            <span className="flex-container flex-start">
              <ComponentIcon component={component} size="48" className="mrp-4" />
              <div className="flex-container flex-column align-top">
                <div className="tw-flex tw-items-center">
                  <ComponentDetailLink componentId={component.get('id')}>
                    <ComponentName className="line-height-24" component={component} />
                  </ComponentDetailLink>
                  <ComponentBadges flags={component.get('flags')} className="tw-ml-2" />
                </div>
                <ComponentType
                  noIcon
                  type={component.get('type')}
                  className="f-14 font-medium line-height-20 tw-mt-0.5"
                />
              </div>
            </span>
            {!forceShowAll && (
              <CollapseButton
                entity="configurations"
                isCollapsed={isCollapsed}
                onToggle={toggleCollapse}
              />
            )}
          </div>
        )}
        {!forceShowAll && isCollapsed ? (
          <div className="collapsed-configurations clickable" onClick={toggleCollapse}>
            <span className="font-bold">{memoizedRows.length} </span>
            <span className="text-muted">
              {string.pluralize(memoizedRows.length, 'Configuration')}
            </span>
          </div>
        ) : (
          <Table
            columns={columns}
            data={memoizedRows}
            component={component}
            readOnly={readOnly}
            hasNewQueue={hasNewQueue}
            hasFlows={hasFlows}
            showMigrations={showMigrations}
            allComponents={allComponents}
            allowCreateConfig={allowCreateConfig}
            forceComponentType={forceComponentType}
            customClasses={customClasses}
            configurations={configurations}
            forceShowAll={forceShowAll}
            supportFolders={supportFolders}
            expandedFolders={expandedFolders}
            columnsHiddenForSmallerScreens={columnsHiddenForSmallerScreens}
            isLoading={isLoading}
          />
        )}
      </div>
      <DeleteConfigurationsModal
        show={multiAction === MULTI_ACTIONS.DELETE}
        onHide={() => setMultiAction('')}
        rows={selectedRows}
        flows={flows}
        forceComponentType={forceComponentType}
      />
      <MoveConfigurationsModal
        show={multiAction === MULTI_ACTIONS.MOVE}
        onHide={() => setMultiAction('')}
        rows={selectedRows}
        component={component}
        availableConfigurations={availableConfigurations}
        componentsMetadata={componentsMetadata}
        isDevModeActive={isDevModeActive}
      />
    </>
  );
};

ConfigurationsTable.propTypes = {
  currentAdmin: PropTypes.instanceOf(Map).isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  notifications: PropTypes.instanceOf(List).isRequired,
  availableConfigurations: PropTypes.instanceOf(Map),
  readOnly: PropTypes.bool.isRequired,
  hasNewQueue: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  component: PropTypes.instanceOf(Map),
  allComponents: PropTypes.instanceOf(Map),
  tablesMetadataMap: PropTypes.instanceOf(Map),
  customClasses: PropTypes.instanceOf(Map),
  latestJobs: PropTypes.instanceOf(Map),
  componentsMetadata: PropTypes.instanceOf(Map),
  expandedFolders: PropTypes.instanceOf(Map),
  renderAdditionalActions: PropTypes.func,
  renderAdditionalMultiActions: PropTypes.func,
  renderCustomConfigurationIcon: PropTypes.func,
  renderNameLabel: PropTypes.func,
  renderCustomLabel: PropTypes.func,
  additionalColumns: PropTypes.array,
  columnsHiddenForSmallerScreens: PropTypes.array,
  isLoading: PropTypes.bool,
  allowCreateConfig: PropTypes.bool,
  showComponentDetailLink: PropTypes.bool,
  showMigrations: PropTypes.bool,
  showComponentIcon: PropTypes.bool,
  showData: PropTypes.bool,
  showUsedIn: PropTypes.bool,
  hideCommonActions: PropTypes.bool,
  forceShowAll: PropTypes.bool,
  supportFolders: PropTypes.bool,
  isDevModeActive: PropTypes.bool,
  forceComponentType: PropTypes.string,
  className: PropTypes.string
};

ConfigurationsTable.defaultProps = {
  renderAdditionalActions: _.noop,
  additionalColumns: [],
  columnsHiddenForSmallerScreens: [],
  customClasses: Map(),
  latestJobs: null,
  availableConfigurations: Map(),
  componentsMetadata: Map(),
  expandedFolders: Map(),
  isLoading: false,
  showComponentDetailLink: false,
  showData: false,
  showUsedIn: false,
  showMigrations: false,
  showComponentIcon: false,
  hideCommonActions: false,
  forceShowAll: false,
  allowCreateConfig: false,
  supportFolders: false,
  isDevModeActive: false
};

/** @type {any} */
const MemoizedTable = React.memo(ConfigurationsTable, shallowEqualImmutable);

export default MemoizedTable;
