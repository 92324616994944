import React from 'react';
import immutableMixin from 'react-immutable-render-mixin';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import dayjs from '../../../../date';
import FilterPanel from '../../../../react/common/FilterPanel';
import LazyList from '../../../../react/common/LazyList';
import createStoreMixin from '../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../stores/ApplicationStore';
import RoutesStore from '../../../../stores/RoutesStore';
import matchByWords from '../../../../utils/matchByWords';
import { canPurgeTrash } from '../../../admin/privileges';
import ComponentsStore from '../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../components/stores/InstalledComponentsStore';
import { mergeSampleDataToConfigurations } from '../../../components-directory/helpers';
import DevBranchesStore from '../../../dev-branches/DevBranchesStore';
import DeletedComponentRow from '../components/DeletedComponentRow';

const FILTERS = {
  ALL: '',
  COMPONENTS: 'components'
};

const TrashIndex = createReactClass({
  mixins: [
    immutableMixin,
    createStoreMixin(ApplicationStore, DevBranchesStore, InstalledComponentsStore, ComponentsStore)
  ],

  getStateFromStores() {
    const allComponents = ComponentsStore.getAll();

    return {
      allComponents,
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      deletedComponents: mergeSampleDataToConfigurations(
        InstalledComponentsStore.getAllDeleted(),
        allComponents
      ),
      deletingConfigurations: InstalledComponentsStore.getDeletingConfigurations(),
      restoringConfigurations: InstalledComponentsStore.getRestoringConfigurations(),
      sapiToken: ApplicationStore.getSapiToken(),
      readOnly: ApplicationStore.isReadOnly(),
      hasFlows: ApplicationStore.hasFlows(),
      adminEmail: ApplicationStore.getCurrentAdmin().get('email'),
      admins: ApplicationStore.getAdmins()
    };
  },

  getInitialState() {
    return {
      searchQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
      filter: FILTERS.ALL
    };
  },

  render() {
    return (
      <>
        <FilterPanel
          placeholder="Search by name, description or id"
          query={this.state.searchQuery}
          onChange={this.handleSearchQuery}
          additionalActions={this.additionalActions()}
        />
        <LazyList limit={5} items={this.getFilteredComponents()} render={this.renderRows} />
      </>
    );
  },

  renderRows(components) {
    if (!components.count()) {
      return <p>{this.renderLabel()}</p>;
    }

    return components
      .map((component) => {
        return (
          <DeletedComponentRow
            key={component.get('id')}
            readOnly={this.state.readOnly}
            component={component}
            allComponents={this.state.allComponents}
            configurations={this.getFilteredConfigurations(component)}
            deletingConfigurations={this.state.deletingConfigurations}
            restoringConfigurations={this.state.restoringConfigurations}
            isDeleteEnabled={canPurgeTrash(this.state.sapiToken) && !this.state.isDevModeActive}
            hasFlows={this.state.hasFlows}
            admins={this.state.admins}
          />
        );
      })
      .toArray();
  },

  additionalActions() {
    return (
      <div className="predefined-search-list">
        <button
          type="button"
          className={classnames('btn predefined-search-link', {
            active: this.state.filter === FILTERS.ALL
          })}
          onClick={() => this.toggleMyComponentsFilter(FILTERS.ALL)}
        >
          All
        </button>
        <button
          type="button"
          className={classnames('btn predefined-search-link', {
            active: this.state.filter === FILTERS.COMPONENTS
          })}
          onClick={() => this.toggleMyComponentsFilter(FILTERS.COMPONENTS)}
        >
          My Components
        </button>
      </div>
    );
  },

  renderLabel() {
    if (this.state.searchQuery || this.state.filter === FILTERS.COMPONENTS) {
      return 'No removed configurations found';
    }

    return 'Trash is empty';
  },

  getFilteredConfigurations(component) {
    let configurations = component.get('configurations', Map());

    if (this.state.searchQuery) {
      const query = this.state.searchQuery.toLowerCase();
      configurations = configurations.filter((configuration) => {
        return matchByWords(
          [configuration.get('id'), configuration.get('name'), configuration.get('description')],
          query
        );
      });
    }

    if (!configurations.count()) {
      configurations = component.get('configurations');
    }

    return configurations.sortBy((configuration) => {
      return -dayjs(configuration.getIn(['currentVersion', 'created'])).unix();
    });
  },

  getFilteredComponents() {
    let components = this.state.deletedComponents;

    if (this.state.filter === FILTERS.COMPONENTS) {
      components = components
        .map((component) =>
          component.update('configurations', Map(), (configs) =>
            configs.filter(
              (config) => config.getIn(['creatorToken', 'description']) === this.state.adminEmail
            )
          )
        )
        .filter((component) => component.get('configurations').count() > 0);
    }

    if (this.state.searchQuery) {
      const query = this.state.searchQuery.toLowerCase();
      components = components.filter((component) => {
        if (matchByWords([component.get('id'), component.get('name')], query)) {
          return true;
        }

        return !!component.get('configurations', Map()).find((configuration) => {
          return matchByWords(
            [configuration.get('id'), configuration.get('name'), configuration.get('description')],
            query
          );
        });
      });
    }

    return components.sortBy((component) => component.get('name').toLowerCase());
  },

  handleSearchQuery(query) {
    return this.setState({ searchQuery: query });
  },

  toggleMyComponentsFilter(filter) {
    return this.setState({ filter });
  }
});

export default TrashIndex;
