import React from 'react';
import { Button, ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import string from '../../utils/string';
import { byteConverter } from './FileSize';
import Select from './Select';

type FileRejection = {
  errors: { code: string; message: string }[];
  file: File;
};

const MAX_FILE_SIZE = 2000 * 1000 * 1000; // 2GB,
const ALLOWED_TYPES_FOR_TABLE = {
  'text/csv': ['.csv'],
  'application/gzip': ['.gz'],
  'text/tab-separated-values': ['.tsv']
};

const FilesDropZone = (props: {
  onDrop: (files: File[], options?: { force: boolean }) => void;
  files?: File | File[] | null;
  multiple?: boolean;
  allowAllFiles?: boolean;
  isDisabled?: boolean;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
}) => {
  const [dragEnter, setDragEnter] = React.useState(false);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    multiple: !!props.multiple,
    disabled: !!props.isDisabled,
    onDropAccepted: props.onDrop,
    onDrop: () => setDragEnter(false),
    onDragEnter: () => setDragEnter(true),
    onDragLeave: () => setDragEnter(false),
    maxSize: MAX_FILE_SIZE,
    ...(!props.allowAllFiles && { accept: ALLOWED_TYPES_FOR_TABLE })
  });
  const files = props.files instanceof File ? [props.files] : props.files || [];
  const showOnlySelectedFile = !props.multiple && files.length > 0;

  const tooLargeFiles: FileRejection[] = fileRejections.filter(({ errors }: FileRejection) => {
    return errors.some(({ code }) => code === 'file-too-large');
  });
  const invalidTypeFiles: FileRejection[] = fileRejections.filter(({ errors }: FileRejection) => {
    return errors.some(({ code }) => code === 'file-invalid-type');
  });

  return (
    <>
      {props.label && !showOnlySelectedFile && <ControlLabel>{props.label}</ControlLabel>}
      <div className="files-dropzone">
        {!showOnlySelectedFile && (
          <div
            {...getRootProps({
              className: classnames(
                'dropzone-container flex-container flex-column justify-center',
                {
                  disabled: !!props.isDisabled,
                  'drag-enter': dragEnter,
                  'mbp-4': files.length > 0
                }
              )
            })}
          >
            <input {...getInputProps()} />
            <FontAwesomeIcon icon={['fad', 'upload']} className="f-32 color-primary mbp-6" />
            <p className="f-14 font-medium line-height-20 mbp-2">
              Drag &amp; Drop here or{' '}
              <Button bsStyle="link" className="btn-link-inline">
                Select {string.pluralize(props.multiple ? 2 : 1, 'File')}
              </Button>
            </p>
            <p className="f-12 line-height-16 text-muted m-0 text-center">
              {props.helpText || `Maximum file size ${byteConverter(MAX_FILE_SIZE)}`}
            </p>
          </div>
        )}
        {files.length > 0 && (
          <FormGroup className="m-0">
            <ControlLabel>Selected {string.pluralize(files.length, 'File')}</ControlLabel>
            <Select
              multi
              noDropdown
              clearable={false}
              value={files.map((file) => file.name)}
              onChange={(newFiles) => {
                props.onDrop(
                  files.filter((file) => newFiles.includes(file.name)),
                  { force: true }
                );
              }}
            />
          </FormGroup>
        )}
        {tooLargeFiles.length > 0 && (
          <HelpBlock className="text-danger">
            The {string.pluralize(tooLargeFiles.length, 'file')}{' '}
            {string.list(tooLargeFiles.map(({ file }) => file.name))} cannot be uploaded because the
            file size exceeds the limit.
          </HelpBlock>
        )}
        {invalidTypeFiles.length > 0 && (
          <HelpBlock className="text-danger">
            The {string.pluralize(invalidTypeFiles.length, 'file')}{' '}
            {string.list(invalidTypeFiles.map(({ file }) => file.name))} cannot be uploaded because
            the file type is not allowed.
          </HelpBlock>
        )}
      </div>
    </>
  );
};

export default FilesDropZone;
