import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { List } from 'immutable';
import _ from 'underscore';

import matchByWords from '../../../utils/matchByWords';
import MarkedText from '../../common/MarkedText';
import Tooltip from '../../common/Tooltip';
import Truncated from '../../common/Truncated';
import { findProjectOrganization } from './helpers';

class ProjectList extends React.Component {
  static propTypes = {
    query: PropTypes.string.isRequired,
    organizations: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    urlTemplates: PropTypes.object.isRequired
  };

  render() {
    return (
      <>
        <h3>Projects</h3>
        <ul className="list-unstyled">{this.renderProjectsList()}</ul>
      </>
    );
  }

  renderProjectsList() {
    const organizations = this.getFilteredProjects();

    if (organizations.isEmpty()) {
      return <li className="text-muted mb-0">No projects or organizations found</li>;
    }

    return (
      <>
        {organizations
          .sortBy(
            (organization) => organization.get('id') !== this.getCurrentOrganization().get('id')
          )
          .map((organization) => (
            <li key={`proj-${organization.get('id')}`}>
              {organization.get('hasAccess') ? (
                <a
                  className="subnav-link group-header"
                  href={this.getOrganizationUrlFromTemplate(organization.get('id'))}
                >
                  <Truncated
                    text={<MarkedText source={organization.get('name')} mark={this.props.query} />}
                  />
                </a>
              ) : (
                <Tooltip placement="top" tooltip="No access to organization">
                  <span className="subnav-link group-header disabled">
                    <Truncated
                      text={
                        <MarkedText source={organization.get('name')} mark={this.props.query} />
                      }
                    />
                  </span>
                </Tooltip>
              )}
              <ul className="list-unstyled p-0">
                {organization
                  .get('projects')
                  .map((project) => {
                    const currentProject =
                      this.props.currentProject.get('id') === project.get('id');

                    return (
                      <li key={`proj-${project.get('id')}`}>
                        {project.get('isDisabled') ? (
                          <Tooltip
                            placement="top"
                            tooltip={`Project is disabled. ${project.get('disabledReason', '')}`}
                          >
                            <span className="subnav-link disabled">
                              <Truncated
                                text={
                                  <MarkedText
                                    source={project.get('name')}
                                    mark={this.props.query}
                                  />
                                }
                              />
                            </span>
                          </Tooltip>
                        ) : (
                          <a
                            href={this.getProjectUrlFromTemplate(project.get('id'))}
                            className={classnames('subnav-link', { active: currentProject })}
                          >
                            <Truncated
                              text={
                                <MarkedText
                                  source={
                                    currentProject
                                      ? this.props.currentProject.get('name')
                                      : project.get('name')
                                  }
                                  mark={this.props.query}
                                />
                              }
                            />
                          </a>
                        )}
                      </li>
                    );
                  })
                  .toArray()}
              </ul>
            </li>
          ))
          .toArray()}
      </>
    );
  }

  getFilteredProjects() {
    const organizations = this.props.organizations
      .sortBy((organization) => organization.get('name'))
      .update('projects', List(), (projects) => projects.sortBy((project) => project.get('name')));

    if (!this.props.query) {
      return organizations;
    }

    return organizations.reduce((filteredOrganizations, organization) => {
      if (matchByWords(organization.get('name'), this.props.query)) {
        return filteredOrganizations.push(organization);
      }

      const matchedProjects = organization
        .get('projects', List())
        .filter((project) => matchByWords(project.get('name'), this.props.query));

      if (!matchedProjects.isEmpty()) {
        return filteredOrganizations.push(organization.set('projects', matchedProjects));
      }

      return filteredOrganizations;
    }, List());
  }

  getCurrentOrganization() {
    return findProjectOrganization(this.props.organizations, this.props.currentProject);
  }

  getOrganizationUrlFromTemplate(organizationId) {
    return _.template(this.props.urlTemplates.get('organization'))({ organizationId });
  }

  getProjectUrlFromTemplate(projectId) {
    return _.template(this.props.urlTemplates.get('project'))({ projectId });
  }
}

export default ProjectList;
