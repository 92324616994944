import React from 'react';
import Promise from 'bluebird';
import type { Map } from 'immutable';

import ApplicationActionCreators from '../../../actions/ApplicationActionCreators';
import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SANDBOXES } from '../../../constants/componentIds';
import InstalledComponentsActionCreators from '../../../modules/components/InstalledComponentsActionCreators';
import { deleteFlow } from '../../../modules/flows/actions';
import SandboxesActions from '../../../modules/sandboxes/Actions';
import ConfirmModal from '../ConfirmModal';
import { updateFlowTasks, usedFlows } from '../DeleteConfigurationButton';
import FlowsValidationBox from '../FlowsValidationBox';
import { getRealComponentId } from './helpers';
import type { SelectedRows } from './Table';

const multiDeleteNotification = (rows: SelectedRows) => {
  return ApplicationActionCreators.sendNotification({
    type: 'info',
    message: 'Selected configurations have been moved to Trash.',
    button: {
      label: 'Restore',
      action: () => {
        return Promise.each(rows, (row) => {
          const { component, config } = row;

          return InstalledComponentsActionCreators.restoreConfiguration(component, config, {
            skipNotification: true
          });
        }).then(() => {
          ApplicationActionCreators.sendNotification({
            type: 'success',
            message: 'Selected configurations have been restored.'
          });
        });
      }
    }
  });
};

type Props = {
  show: boolean;
  onHide: () => void;
  rows: SelectedRows;
  flows: Map<string, any>;
  forceComponentType?: string;
};

const DeleteConfigurationsModal = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <ConfirmModal
      closeAfterResolve
      show={props.show}
      onHide={props.onHide}
      icon="trash"
      buttonType="danger"
      buttonLabel="Delete selected"
      title="Delete selected"
      text={
        <>
          <p>
            Are you sure you want to delete selected {props.forceComponentType || 'configuration'}
            s?
          </p>
          <FlowsValidationBox
            flows={props.flows}
            configurations={props.rows.map((row) => {
              return {
                componentId: getRealComponentId(row.config, row.component),
                config: row.config
              };
            })}
          />
        </>
      }
      isLoading={isLoading}
      onConfirm={() => {
        setIsLoading(true);
        const singleRow = props.rows.length === 1;

        return Promise.each(props.rows, (row) => {
          return Promise.each(
            usedFlows(props.flows, row.component.get('id'), row.config),
            (flow: Map<string, any>) => {
              return updateFlowTasks(flow, row.component.get('id'), row.config);
            }
          ).then(() => {
            if (row.component.get('id') === KEBOOLA_ORCHESTRATOR) {
              return deleteFlow(row.config, { notification: singleRow });
            }

            if (
              row.component.get('id') === KEBOOLA_SANDBOXES &&
              row.config.getIn(['configuration', 'parameters', 'id'])
            ) {
              return SandboxesActions.deleteSandbox(
                row.config.getIn(['configuration', 'parameters', 'id']),
                row.config.get('id'),
                KEBOOLA_SANDBOXES,
                { notification: singleRow }
              );
            }

            return InstalledComponentsActionCreators.deleteConfiguration(
              getRealComponentId(row.config, row.component),
              row.config.get('id'),
              { transition: false, notification: singleRow }
            );
          });
        })
          .then(() => {
            props.onHide();

            if (!singleRow) {
              multiDeleteNotification(props.rows);
            }
          })
          .finally(() => setIsLoading(false));
      }}
    />
  );
};

export default DeleteConfigurationsModal;
