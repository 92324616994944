import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { KEBOOLA_SANDBOXES } from '../../../../../constants/componentIds';
import Loader from '../../../../../react/common/Loader';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import { routeNames as workspaceRoutes } from '../../../../sandboxes/Constants';
import { routeNames as transformationRoutes } from '../../../../transformations-v2/constants';
import InstalledActions from '../../../InstalledComponentsActionCreators';
import InstalledStore from '../../../stores/InstalledComponentsStore';

const Index = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const componentId = RoutesStore.getCurrentRouteParam('component') || KEBOOLA_SANDBOXES;
    const configData = InstalledStore.getConfigData(componentId, configId);
    const editingConfigData = InstalledStore.getEditingConfigDataObject(componentId, configId);
    const savedMapping = configData.getIn(['storage', 'input', 'files'], List());
    const editingMapping = editingConfigData.getIn(
      ['storage', 'input', 'files', 'complete-mapping'],
      savedMapping
    );

    return {
      configId,
      componentId,
      savedMapping,
      editingMapping,
      isChanged: !savedMapping.equals(editingMapping)
    };
  },

  getInitialState() {
    return {
      isSaving: false
    };
  },

  render() {
    const hasEmptyChangedSince = this.state.editingMapping.some(
      (mapping) => mapping.get('changed_since') === ''
    );

    if (!this.state.isChanged) {
      return (
        <Button disabled bsStyle="success">
          <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
          Save &amp; Close
        </Button>
      );
    }

    return (
      <ButtonToolbar>
        <Button
          onClick={() => this.updateMapping(this.state.savedMapping)}
          disabled={this.state.isSaving || !this.state.isChanged}
        >
          <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
          Reset
        </Button>
        <Button
          bsStyle="success"
          disabled={this.state.isSaving || !this.state.isChanged || hasEmptyChangedSince}
          onClick={() => this.saveMapping()}
        >
          {this.state.isSaving ? (
            <>
              <Loader className="icon-addon-right" />
              Saving...
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
              Save &amp; Close
            </>
          )}
        </Button>
      </ButtonToolbar>
    );
  },

  saveMapping() {
    const updateMapping = this.state.editingMapping;

    this.setState({ isSaving: true });
    InstalledActions.saveEditingMapping(
      this.state.componentId,
      this.state.configId,
      'input',
      'files',
      'complete-mapping',
      'Update input mapping'
    )
      .then(() => this.updateMapping(updateMapping))
      .catch((error) => {
        this.setState({ isSaving: false });
        throw error;
      })
      .then(() => {
        if (this.state.componentId === KEBOOLA_SANDBOXES) {
          RoutesStore.getRouter().transitionTo(workspaceRoutes.WORKSPACE, {
            config: this.state.configId
          });
        } else {
          RoutesStore.getRouter().transitionTo(transformationRoutes.GENERIC_TRANSFORMATION_CONFIG, {
            component: this.state.componentId,
            config: this.state.configId
          });
        }
        return null;
      });
  },

  updateMapping(mapping) {
    InstalledActions.changeEditingMapping(
      this.state.componentId,
      this.state.configId,
      'input',
      'files',
      'complete-mapping',
      mapping
    );
  }
});

export default Index;
