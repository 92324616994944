import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

import Checkbox from '../../../react/common/Checkbox';

class Source extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Source</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <Col xs={4} componentClass={ControlLabel}>
              File name
            </Col>
            <Col xs={8}>
              <FormControl
                type="text"
                value={this.props.value.file_name}
                onChange={(e) => {
                  this.props.onChange({ file_name: e.target.value });
                }}
                placeholder="folder/subfolder/file_*"
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Name of a source file with its extension or wildcard.
                <ul>
                  <li>
                    <code>{'folder/subfolder/test.csv'}</code> will download &quot;test&quot; CSV
                    file from &quot;folder/subfolder&quot; directory
                  </li>
                  <li>
                    <code>{'test_*'}</code> will download all CSV files with &quot;test_&quot;
                    prefix
                  </li>
                </ul>
              </HelpBlock>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col xs={8} xsOffset={4}>
              <Checkbox
                checked={this.props.value.new_files_only}
                onChange={(checked) => this.props.onChange({ new_files_only: checked })}
                disabled={this.props.disabled}
              >
                New Files Only
              </Checkbox>
              <HelpBlock>
                Every job stores the timestamp of the last downloaded file and a subsequent job can
                pick up from there.
              </HelpBlock>
            </Col>
          </FormGroup>
        </div>
      </div>
    );
  }
}

Source.propTypes = {
  value: PropTypes.shape({
    file_name: PropTypes.string.isRequired,
    new_files_only: PropTypes.bool.isRequired
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default Source;
