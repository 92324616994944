import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, ControlLabel, Form, FormGroup, HelpBlock, Radio } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import AutomaticLoadTypeLastUpdated from '../../../../react/common/AutomaticLoadTypeLastUpdated';
import changedSinceConstants from '../../../../react/common/changedSinceConstants';
import ChangedSinceInput from '../../../../react/common/ChangedSinceInput';
import ExternalLink from '../../../../react/common/ExternalLink';
import Select from '../../../../react/common/Select';

const LoadTypeSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      changedSince: PropTypes.string.isRequired,
      grainColumns: PropTypes.array.isRequired,
      hasConnectionPoint: PropTypes.bool.isRequired,
      tableId: PropTypes.string.isRequired,
      grain: PropTypes.array
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  },

  render() {
    const { value, onChange, disabled } = this.props;
    const isIncremental = !!value.changedSince;
    const isGrainInvalid = value.grain && value.grain.length === 1;
    return (
      <Form horizontal>
        <FormGroup>
          <Col sm={4} componentClass={ControlLabel}>
            Load type
          </Col>
          <Col sm={8}>
            <Radio
              type="radio"
              title="Full Load"
              disabled={disabled}
              onChange={() => onChange({ changedSince: '' })}
              checked={!isIncremental}
            >
              Full Load
            </Radio>
            <HelpBlock>
              All data in the GoodData dataset will be replaced by the current Storage table data.
            </HelpBlock>
            <Radio
              type="radio"
              title="Automatic Incremental Load"
              disabled={disabled}
              onChange={() => onChange({ changedSince: changedSinceConstants.ADAPTIVE_VALUE })}
              checked={
                isIncremental &&
                this.props.value.changedSince === changedSinceConstants.ADAPTIVE_VALUE
              }
            >
              Automatic Incremental Load
            </Radio>
            <HelpBlock>
              Only data changed since the last successful run will be appended to the dataset.
            </HelpBlock>
            {isIncremental &&
              this.props.value.changedSince === changedSinceConstants.ADAPTIVE_VALUE && (
                <AutomaticLoadTypeLastUpdated tableId={this.props.value.tableId} />
              )}
            <Radio
              type="radio"
              title="Manual Incremental Load"
              disabled={disabled}
              onChange={() => onChange({ changedSince: '-1 days' })}
              checked={
                isIncremental &&
                this.props.value.changedSince !== changedSinceConstants.ADAPTIVE_VALUE
              }
            >
              Manual Incremental Load
            </Radio>
            <HelpBlock>Selected data will be appended to the dataset.</HelpBlock>
          </Col>
        </FormGroup>
        {isIncremental &&
          this.props.value.changedSince !== changedSinceConstants.ADAPTIVE_VALUE && (
            <FormGroup>
              <Col componentClass={ControlLabel} sm={4}>
                Changed In Last
              </Col>
              <Col sm={8}>
                <ChangedSinceInput
                  value={value.changedSince}
                  onChange={(newValue) => this.props.onChange({ changedSince: newValue })}
                  disabled={disabled}
                  tableId={value.tableId}
                />
              </Col>
            </FormGroup>
          )}
        {isIncremental && (
          <FormGroup>
            <Col sm={4} componentClass={ControlLabel}>
              Fact Grain
            </Col>
            <Col sm={8}>
              <Select
                placeholder="Select at least 2 columns"
                multi
                disabled={disabled || value.hasConnectionPoint}
                options={value.grainColumns.map((column) => ({ value: column, label: column }))}
                value={value.grain}
                onChange={(newColumns) => onChange({ grain: newColumns })}
              />
              <HelpBlock>
                {isGrainInvalid && (
                  <Alert bsStyle="danger">
                    <FontAwesomeIcon icon="exclamation" /> Please specify a second attribute,
                    reference, or date type columns.
                  </Alert>
                )}
                <ExternalLink href="https://developer.gooddata.com/article/set-fact-table-grain">
                  Fact grain
                </ExternalLink>{' '}
                columns help to avoid duplicate records in the GoodData dataset without a connection
                point. <strong> Specify at least 2 </strong> attributes, reference or date type
                columns.
              </HelpBlock>
            </Col>
          </FormGroup>
        )}
      </Form>
    );
  }
});

export default LoadTypeSection;
