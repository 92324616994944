import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { STAGE } from '../../modules/storage/constants';

const BucketStageLabel = ({ stage, round, className }) => {
  if (!stage) {
    return null;
  }

  return (
    <span
      className={classnames('stage-label label text-center', className, {
        'label-rounded': round,
        'label-primary': stage === STAGE.IN,
        'label-teal': stage === STAGE.OUT
      })}
    >
      {stage.toUpperCase()}
    </span>
  );
};

BucketStageLabel.propTypes = {
  stage: PropTypes.string,
  round: PropTypes.bool,
  className: PropTypes.string
};

export default BucketStageLabel;
