import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import dayjs from '../../../../../date';
import Events from '../../../../sapi-events/react/Events';

class JobLogPanel extends React.Component {
  render() {
    return (
      <Events
        link={{
          to: 'jobDetail',
          params: {
            jobId: this.props.job.get('id')
          },
          query: {
            q: this.props.query
          }
        }}
        params={{
          runId: this.props.job.get('runId')
        }}
        autoReload={this.shouldAutoReload()}
      />
    );
  }

  shouldAutoReload() {
    if (['canceled', 'cancelled'].includes(this.props.job.get('status'))) {
      return false;
    }

    if (this.props.job.get('isFinished') === false) {
      return true;
    }

    return dayjs().diff(this.props.job.get('endTime'), 'minutes') < 5;
  }
}

JobLogPanel.propTypes = {
  job: PropTypes.instanceOf(Map).isRequired,
  query: PropTypes.string
};

export default JobLogPanel;
