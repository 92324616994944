import columnTypes from '../configurations/utils/columnTypeConstants';
import createRoute from '../configurations/utils/createRoute';
import { CollapsibleSection } from '../configurations/utils/renderHelpers';
import actions from './adapters/actions';
import saveSettingsAdapter from './adapters/saveSettings';
import sourceProjectAdapter from './adapters/sourceProject';
import sourceTableAdapter from './adapters/sourceTable';
import SaveSettingsSection from './react/components/SaveSettings';
import SourceProjectSection from './react/components/SourceProject';
import SourceTableSection from './react/components/SourceTable';

const routeSettings = {
  componentId: 'keboola.ex-storage',
  index: {
    actions: [
      {
        name: 'sourceInfo',
        cache: true,
        autoload: true,
        getPayload: actions.sourceInfo
      }
    ],
    sections: [
      {
        render: CollapsibleSection({
          title: 'Source Project',
          contentComponent: SourceProjectSection,
          options: { includeSaveButtons: true }
        }),
        onSave: sourceProjectAdapter.createConfiguration,
        onLoad: sourceProjectAdapter.parseConfiguration,
        isComplete: sourceProjectAdapter.isComplete
      }
    ]
  },
  row: {
    hasState: false,
    sections: [
      {
        render: SourceTableSection,
        onSave: sourceTableAdapter.createConfiguration,
        onLoad: sourceTableAdapter.parseConfiguration,
        onCreate: sourceTableAdapter.createEmptyConfiguration
      },
      {
        render: SaveSettingsSection,
        onSave: saveSettingsAdapter.createConfiguration,
        onLoad: saveSettingsAdapter.parseConfiguration,
        onCreate: saveSettingsAdapter.createEmptyConfiguration
      }
    ],
    columns: [
      {
        name: 'Destination',
        type: columnTypes.TABLE_LINK_DEFAULT_BUCKET,
        value: function (row) {
          return row.getIn(['parameters', 'tableName']);
        }
      }
    ]
  }
};

const result = createRoute(routeSettings);

export default result;
