import React, { useState } from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import dayjs from '../../../date';
import CollapsibleAlert from '../../../react/common/CollapsibleAlert';
import { MeetingWidget } from '../../../react/common/MeetingWidget';
import ModalIcon from '../../../react/common/ModalIcon';
import contactSupport from '../../../utils/contactSupport';

const days = (expires: string) => {
  const days = Math.max(0, Math.round(dayjs(expires).diff(dayjs(), 'days', true)));
  switch (days) {
    case 0:
      return 'less than a day';
    case 1:
      return '1 day';
    default:
      return `${days} days`;
  }
};

const Expiration = ({
  expires,
  showInStickyPanel
}: {
  expires?: string;
  showInStickyPanel?: boolean;
}) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  if (
    !expires ||
    parseInt(days(expires), 10) > 30 ||
    (showInStickyPanel && parseInt(days(expires), 10) > 7)
  ) {
    return null;
  }

  if (showInStickyPanel) {
    return (
      <>
        <div className="top-sticky-bar warning bg-color-orange">
          <FontAwesomeIcon icon="triangle-exclamation" className="color-warning icon-addon-right" />
          Your trial will expire in {days(expires)}.
          <Button bsStyle="success" onClick={() => setShowUpgradeModal(true)}>
            Upgrade Account
          </Button>
        </div>
        <UpgradeModal show={showUpgradeModal} onHide={() => setShowUpgradeModal(false)} />
      </>
    );
  }

  return (
    <>
      <CollapsibleAlert
        title={`Your project will expire in ${days(expires)}`}
        id="project-expiration"
      >
        <p>
          To keep your project active and continue enjoying the benefits of Keboola, please{' '}
          <Button
            bsStyle="link"
            className="btn-link-inline"
            onClick={() => setShowUpgradeModal(true)}
          >
            upgrade your account
          </Button>
          .
        </p>
      </CollapsibleAlert>
      <UpgradeModal show={showUpgradeModal} onHide={() => setShowUpgradeModal(false)} />
    </>
  );
};

const UpgradeModal = (props: { show: boolean; onHide: () => void }) => {
  const [showMeetingWidget, setShowMeetingWidget] = useState(false);

  return (
    <Modal bsSize="large" show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <ModalIcon icon="arrow-up" color="green" bold />
        <Modal.Title>Upgrade Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showMeetingWidget ? (
          <MeetingWidget type="sales" />
        ) : (
          <>
            <h4 className="mt-0 mb-0 font-medium line-height-20">End of trial</h4>
            <p className="text-muted">
              Your trial period is ending soon. If you don&apos;t upgrade to a paid plan, your
              project will expire and you&apos;ll lose any work that you&apos;ve completed so far.
              Upgrade now to keep your project active and continue using Keboola.
            </p>
            <h4 className="mtp-6 mb-0 font-medium line-height-20">Contact our support team</h4>
            <p className="text-muted">
              Schedule a call with our friendly sales team to learn more about our paid plans. Our
              team will help you understand Keboola&apos;s full range of features and benefits, then
              tailor a plan that meets your specific needs. Don&apos;t miss out on the power of
              Keboola - upgrade today!
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {showMeetingWidget ? (
          <Button onClick={() => setShowMeetingWidget(false)} block>
            <FontAwesomeIcon icon="chevron-left" className="icon-addon-right" />
            Back
          </Button>
        ) : (
          <ButtonToolbar className="block same-width">
            <Button onClick={() => contactSupport()}>Contact Support</Button>
            <Button onClick={() => setShowMeetingWidget(true)}>Schedule Call</Button>
          </ButtonToolbar>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Expiration;
