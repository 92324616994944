import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import BlankImage from '../../../../../../images/illustrations/blank.png';
import { KEBOOLA_ORCHESTRATOR } from '../../../../../constants/componentIds';
import { componentTypes } from '../../../../../constants/componentTypes';
import ConfigurationInfoPanel from '../../../../../react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '../../../../../react/common/ConfigurationTabs';
import FilterPanel from '../../../../../react/common/FilterPanel';
import NoResultsFound from '../../../../../react/common/NoResultsFound';
import Link from '../../../../../react/common/RouterLink';
import Sidebar from '../../../../../react/layout/Sidebar';
import createStoreMixin from '../../../../../react/mixins/createStoreMixin';
import ApplicationStore from '../../../../../stores/ApplicationStore';
import RoutesStore from '../../../../../stores/RoutesStore';
import { fileUrl } from '../../../../../utils/fileHelpers';
import ComponentDescription from '../../../../components/react/components/ComponentDescription';
import ComponentsStore from '../../../../components/stores/ComponentsStore';
import InstalledComponentsStore from '../../../../components/stores/InstalledComponentsStore';
import StorageTablesStore from '../../../../components/stores/StorageTablesStore';
import VersionsStore from '../../../../components/stores/VersionsStore';
import ConfigurationRowsStore from '../../../../configurations/ConfigurationRowsStore';
import { shouldShowSimplifiedUi } from '../../../../simplified-ui/helpers';
import SimplifiedUi from '../../../../simplified-ui/Index';
import { prepareTablesMetadataMap } from '../../../../storage/helpers';
import * as actionsProvisioning from '../../../actionsProvisioning';
import { supportConfigRows, supportSimpleSetup } from '../../../helpers';
import * as storeProvisioning from '../../../storeProvisioning';
import AsynchActionError from '../../components/AsynchActionError';
import CreateQueryElement from '../../components/CreateQueryElement';
import MigrateToRowsButton from '../../components/MigrateToRowsButton';
import Quickstart from '../../components/Quickstart';
import QueryTable from './QueryTable';

const Index = function (componentId) {
  const actionsCreators = actionsProvisioning.createActions(componentId);
  return createReactClass({
    mixins: [
      createStoreMixin(
        ApplicationStore,
        VersionsStore,
        ComponentsStore,
        StorageTablesStore,
        InstalledComponentsStore,
        ConfigurationRowsStore
      )
    ],

    UNSAFE_componentWillReceiveProps() {
      return this.setState(this.getStateFromStores());
    },

    componentDidMount() {
      if (
        !this.state.readOnly &&
        this.state.hasCredentials &&
        this.state.queries.isEmpty() &&
        !this.state.sourceTables
      ) {
        actionsProvisioning.loadSourceTables(componentId, this.state.configId);
      }
    },

    getStateFromStores() {
      const configId = RoutesStore.getRouterState().getIn(['params', 'config']);
      const config = InstalledComponentsStore.getConfig(componentId, configId);
      const ExDbStore = storeProvisioning.createStore(componentId, configId);
      const queries = ExDbStore.getQueries();
      const credentials = ExDbStore.getCredentials();
      const enabledQueries = queries.filter(function (query) {
        return query.get('enabled');
      });
      const isRowConfiguration = ExDbStore.isRowConfiguration();
      const queriesFiltered = ExDbStore.getQueriesFiltered().map((query) => {
        const configRow =
          config.get('rows').find((row) => row.get('id') === query.get('id')) || Map();

        return query
          .set('configuration', configRow.getIn(['configuration', 'parameters'], Map()))
          .merge(configRow.delete('configuration'));
      });

      return {
        isRowConfiguration,
        configId,
        config,
        queries,
        allTables: StorageTablesStore.getAll(),
        queriesFiltered,
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        configData: InstalledComponentsStore.getConfigData(componentId, configId),
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        versions: VersionsStore.getVersions(componentId, configId),
        pendingActions: ExDbStore.getQueriesPendingActions(),
        hasCredentials: !credentials.isEmpty(),
        newCredentials: ExDbStore.getNewCredentials(),
        sourceTables: ExDbStore.getSourceTables(),
        queriesFilter: ExDbStore.getQueriesFilter(),
        hasEnabledQueries: enabledQueries.count() > 0,
        localState: ExDbStore.getLocalState(),
        component: ComponentsStore.getComponent(componentId),
        readOnly: ApplicationStore.isReadOnly(),
        hasFlows: ApplicationStore.hasFlows(),
        migrateOnly: supportConfigRows(componentId) && !isRowConfiguration
      };
    },

    handleCredentialsSetup() {
      actionsCreators.updateEditingCredentials(this.state.configId, this.state.newCredentials);
      RoutesStore.getRouter().transitionTo('ex-db-generic-' + componentId + '-credentials', {
        config: this.state.configId
      });
    },

    handleFilterChange(query) {
      return actionsCreators.setQueriesFilter(this.state.configId, query);
    },

    handleRefreshSourceTables() {
      return actionsProvisioning.reloadSourceTables(componentId, this.state.configId);
    },

    handleDismissMigrationAlert() {
      return actionsCreators.dismissMigrationAlert(this.state.configId);
    },

    renderNewQueryLink() {
      if (this.state.readOnly || this.state.migrateOnly) {
        return null;
      }

      return (
        <CreateQueryElement
          isNav={false}
          configurationId={this.state.configId}
          componentId={componentId}
          actionsProvisioning={actionsProvisioning}
        />
      );
    },

    renderCredentialsSetup() {
      if (this.state.hasCredentials) {
        return null;
      }

      return (
        <div className="box pt-2 pb-1">
          <div className="blank-page">
            <img
              src={fileUrl(BlankImage)}
              className="intro-image intro-image-small"
              alt="Connect database"
            />
            <h2>Connect database</h2>
            {this.state.readOnly ? (
              <p className="intro-text">No credentials provided for this component</p>
            ) : (
              <>
                <p className="intro-text">Please set up database credentials for this component</p>
                <div className="intro-action">
                  <Button bsStyle="success" onClick={this.handleCredentialsSetup}>
                    <FontAwesomeIcon icon="user" className="icon-addon-right" />
                    Set Up Credentials
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    },

    renderQueriesMain() {
      if (this.state.queries.isEmpty() || this.state.localState.get('quickstartSaving', false)) {
        if (!this.state.hasCredentials) {
          return null;
        }

        if (
          !this.state.readOnly &&
          !this.state.migrateOnly &&
          supportSimpleSetup(componentId) &&
          !this.state.localState.getIn(storeProvisioning.SOURCE_TABLES_ERROR_PATH)
        ) {
          return (
            <Quickstart
              isSaving={this.state.localState.get('quickstartSaving', false)}
              configId={this.state.configId}
              isLoadingSourceTables={
                this.state.localState.getIn(storeProvisioning.LOADING_SOURCE_TABLES_PATH) || false
              }
              sourceTables={this.state.localState.getIn(storeProvisioning.SOURCE_TABLES_PATH)}
              sourceTablesError={this.state.localState.getIn(
                storeProvisioning.SOURCE_TABLES_ERROR_PATH
              )}
              quickstart={this.state.localState.get('quickstart') || Map()}
              onChange={actionsCreators.quickstartSelected}
              onSubmit={actionsCreators.quickstart}
              refreshMethod={this.handleRefreshSourceTables}
            />
          );
        }

        return (
          <div className="box-separator">
            <h2 className="tw-text-base tw-m-0 tw-mb-4">Tables</h2>
            <div className="box">
              <div className="box-content text-center">
                <p>No tables created yet.</p>
                {this.renderNewQueryLink()}
              </div>
            </div>
          </div>
        );
      }

      if (!this.state.queriesFiltered.count()) {
        return <NoResultsFound entityName="tables" />;
      }

      return (
        <QueryTable
          component={this.state.component}
          configuration={this.state.config}
          queries={this.state.queriesFiltered}
          filter={this.state.queriesFilter}
          pendingActions={this.state.pendingActions}
          isRowConfiguration={this.state.isRowConfiguration}
          readOnly={this.state.readOnly}
        />
      );
    },

    renderAsynchError() {
      if (this.state.localState.getIn(storeProvisioning.SOURCE_TABLES_ERROR_PATH)) {
        return (
          <div className="box-separator">
            <AsynchActionError
              componentId={componentId}
              configId={this.state.configId}
              sourceTablesLoading={this.state.localState.getIn(
                storeProvisioning.LOADING_SOURCE_TABLES_PATH,
                false
              )}
              sourceTablesError={this.state.localState.getIn(
                storeProvisioning.SOURCE_TABLES_ERROR_PATH
              )}
            />
          </div>
        );
      }
    },

    renderMigrationToRowsButton() {
      if (supportConfigRows(componentId) && !this.state.isRowConfiguration) {
        return (
          <div className="box">
            <div className="box-content text-center">
              <p>
                Please migrate the configuration to the newest format to unlock the latest features.
              </p>
              <MigrateToRowsButton
                onMigrate={() => actionsCreators.migrateConfig(this.state.configId)}
              />
            </div>
          </div>
        );
      } else if (
        supportConfigRows(componentId) &&
        !!this.state.localState.getIn(['migration', 'completed'])
      ) {
        return (
          <div className="box-separator">
            <Alert bsStyle="success" onDismiss={this.handleDismissMigrationAlert}>
              The configuration has been successfully migrated.
            </Alert>
          </div>
        );
      }
    },

    render() {
      if (shouldShowSimplifiedUi(this.state.component, this.state.configData)) {
        return <SimplifiedUi />;
      }

      return (
        <>
          <ConfigurationTabs componentId={componentId} configId={this.state.configId} />
          <ConfigurationInfoPanel
            component={this.state.component}
            config={this.state.config}
            hasFlows={this.state.hasFlows}
            flows={this.state.flows}
            tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
            metadata={this.state.componentsMetadata}
          />
          <div className="row">
            <div className="col-sm-9">
              <ComponentDescription
                componentId={componentId}
                configId={this.state.configId}
                placeholderEntity={componentTypes.EXTRACTOR}
              />
              {this.renderAsynchError()}
              {this.renderCredentialsSetup()}
              {this.renderMigrationToRowsButton()}
              {this.state.queries.count() > 0 &&
                !this.state.localState.get('quickstartSaving', false) && (
                  <div className="box-separator">
                    <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
                      <h2 className="tw-text-base tw-m-0">Tables</h2>
                      {this.renderNewQueryLink()}
                    </div>
                    <FilterPanel
                      query={this.state.queriesFilter}
                      onChange={this.handleFilterChange}
                    />
                  </div>
                )}
              {this.renderQueriesMain()}
            </div>
            <div className="col-sm-3">
              <Sidebar
                componentId={componentId}
                configId={this.state.configId}
                run={{
                  disabled: !this.state.hasEnabledQueries ? 'There are no tables configured' : '',
                  text: 'You are about to run an extraction.'
                }}
                additionalButtons={
                  this.state.hasCredentials && (
                    <Link
                      to={'ex-db-generic-' + componentId + '-credentials'}
                      params={{ config: this.state.configId }}
                    >
                      <FontAwesomeIcon icon="user" fixedWidth />
                      Database Credentials
                    </Link>
                  )
                }
              />
            </div>
          </div>
        </>
      );
    }
  });
};

export default Index;
