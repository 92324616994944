import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import { Map } from 'immutable';

import Checkbox from '../../../react/common/Checkbox';
import ComponentIcon from '../../../react/common/ComponentIcon';
import ComponentName from '../../../react/common/ComponentName';
import Tooltip from '../../../react/common/Tooltip';
import BackendSizeSelect from '../../components/react/components/BackendSizeSelect';
import ComponentConfigurationLink from '../../components/react/components/ComponentConfigurationLink';

class TaskRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <tr className="bg-color-white hoverable-actions">
        <td />
        <td>
          <Checkbox
            checked={this.props.isSelected}
            onChange={this.props.onTaskToggleFn}
            disabled={this.props.readOnly}
          />
        </td>
        <td>
          {this.props.component && (
            <ComponentIcon
              component={this.props.component}
              className="icon-addon-right"
              size="32"
            />
          )}
          {this.props.component ? (
            <ComponentName component={this.props.component} showType />
          ) : (
            this.props.task.getIn(['task', 'componentId'])
          )}
        </td>
        <td>{this.renderConfigurationLink()}</td>
        <td>
          <Checkbox
            checked={this.props.task.get('enabled', true)}
            onChange={(checked) => this.props.onTaskUpdateFn(['enabled'], checked)}
            disabled={this.props.readOnly}
          />
        </td>
        <td>
          <Checkbox
            checked={this.props.task.get('continueOnFailure', false)}
            onChange={(checked) => this.props.onTaskUpdateFn(['continueOnFailure'], checked)}
            disabled={this.props.readOnly}
          />
        </td>
        {this.renderBackendSize()}
        <td className="pl-0 pr-1">
          {!this.props.readOnly && (
            <Tooltip tooltip="Remove task" placement="top">
              <Button bsStyle="link" className="text-muted" onClick={this.props.onDeleteFn}>
                <FontAwesomeIcon icon="trash" fixedWidth />
              </Button>
            </Tooltip>
          )}
        </td>
      </tr>
    );
  }

  renderBackendSize() {
    if (!this.props.showBackendSize) {
      return null;
    }

    return (
      <td className="pl-0">
        {this.props.hasBackendSize && (
          <BackendSizeSelect
            componentId={this.props.task.getIn(['task', 'componentId'])}
            currentSize={this.props.task.getIn(['task', 'backend', 'type'])}
            defaultSize={this.props.configuration.getIn([
              'configuration',
              'runtime',
              'backend',
              'type'
            ])}
            changeBackendSize={(backendSize) =>
              Promise.delay(300).then(() =>
                this.props.onTaskUpdateFn(['task', 'backend', 'type'], backendSize)
              )
            }
            readOnly={this.props.readOnly}
            hasSnowflakeDynamicBackendSize={this.props.hasSnowflakeDynamicBackendSize}
            hasJobsDynamicBackendSize={this.props.hasJobsDynamicBackendSize}
            isSingleTenant={this.props.isSingleTenant}
            simple
          />
        )}
      </td>
    );
  }

  renderConfigurationLink() {
    if (this.props.configuration.isEmpty()) {
      return this.props.task.getIn(['task', 'configId'], 'N/A');
    }
    return (
      <ComponentConfigurationLink
        componentId={this.props.task.getIn(['task', 'componentId'])}
        configId={this.props.task.getIn(['task', 'configId'])}
        hasFlows={this.props.hasFlows}
      >
        {this.props.configuration.get('name')}
      </ComponentConfigurationLink>
    );
  }
}

TaskRow.propTypes = {
  task: PropTypes.instanceOf(Map).isRequired,
  component: PropTypes.instanceOf(Map).isRequired,
  configuration: PropTypes.instanceOf(Map).isRequired,
  onDeleteFn: PropTypes.func.isRequired,
  onTaskUpdateFn: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onTaskToggleFn: PropTypes.func.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  hasSnowflakeDynamicBackendSize: PropTypes.bool.isRequired,
  hasJobsDynamicBackendSize: PropTypes.bool.isRequired,
  showBackendSize: PropTypes.bool.isRequired,
  hasBackendSize: PropTypes.bool.isRequired,
  isSingleTenant: PropTypes.bool.isRequired
};

export default TaskRow;
