import { JSONEditor } from '@json-editor/json-editor';

import ApplicationStore from '../../../stores/ApplicationStore';

export default class PasswordPlugin extends JSONEditor.defaults.editors.string {
  preBuild() {
    if (ApplicationStore.hasProtectedDefaultBranch()) {
      this.options.format = 'text';
    }
  }
}
