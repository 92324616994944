import Promise from 'bluebird';

import { JOBS_STATUS } from '../modules/queue/constants';
import ApplicationStore from '../stores/ApplicationStore';
import request from './request';

const POLL_STATUSES = [
  JOBS_STATUS.CREATED,
  JOBS_STATUS.WAITING,
  JOBS_STATUS.PROCESSING,
  JOBS_STATUS.TERMINATING
];

const jobPoller = {
  poll(url: string, interval = 5000) {
    return new Promise((resolve, reject) => {
      const runRequest = () => {
        return request('GET', url)
          .set('X-StorageApi-Token', ApplicationStore.getSapiTokenString())
          .promise()
          .then((response) => {
            if (POLL_STATUSES.includes(response.body.status)) {
              setTimeout(runRequest, interval);
              return;
            }

            if (response.body?.status === 'error') {
              // storage jobs
              if (!!response.body?.operationName) {
                return reject(response.body?.error);
              }

              // queue jobs
              return reject(response.body?.result);
            }

            return resolve(response.body);
          })
          .catch(reject);
      };

      return runRequest();
    });
  }
};

export default jobPoller;
