import React, { useState } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';

import ApplicationActionCreators from '../../../actions/ApplicationActionCreators';
import { KEBOOLA_ORCHESTRATOR } from '../../../constants/componentIds';
import CatchUnsavedChanges from '../../../react/common/CatchUnsavedChanges';
import Loader from '../../../react/common/Loader';
import RouterLink from '../../../react/common/RouterLink';
import useStores from '../../../react/hooks/useStores';
import RoutesStore from '../../../stores/RoutesStore';
import InstalledComponentsActionCreators from '../../components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '../../components/stores/InstalledComponentsStore';
import { routeNames as flowsRouteNames } from '../../flows/constants';
import actions from '../actions';
import { routeNames } from '../constants';
import { getPreconfiguredValues, hasAnyConfiguratorFormChanges } from '../helpers';
import TemplatesStore from '../store';
import VersionSelect from './VersionSelect';

const InstanceConfiguratorHeader = () => {
  const {
    templateVersionDetail,
    instanceDetail,
    instanceConfiguratorForm,
    instanceId,
    instanceName,
    instanceVersion,
    hasAnyRequiredInputs,
    hasAnyChanges
  } = useStores(
    () => {
      const routerState = RoutesStore.getRouterState();
      const { instanceConfiguratorForm, templateVersionDetail, instanceDetail } =
        TemplatesStore.getStore();

      if (!templateVersionDetail) return {};

      return {
        templateVersionDetail,
        instanceDetail,
        instanceConfiguratorForm,
        instanceId: routerState.getIn(['params', 'instanceId']) as string,
        instanceName: routerState.getIn(['location', 'query', 'n']) as string,
        instanceVersion:
          (routerState.getIn(['location', 'query', 'v']) as string) ||
          instanceDetail?.version ||
          templateVersionDetail.version,
        hasAnyRequiredInputs: instanceConfiguratorForm.inputs?.stepGroups?.some(
          (group) =>
            ['all', 'atLeastOne', 'exactlyOne'].includes(group.required) &&
            group.steps.some((step) => step.inputs.length > 0)
        ),
        hasAnyChanges: hasAnyConfiguratorFormChanges(
          instanceConfiguratorForm.inputs,
          instanceConfiguratorForm.userValues
        )
      };
    },
    [],
    [RoutesStore, TemplatesStore]
  );
  const [isSaving, setIsSaving] = useState(false);
  const [isReseting, setIsReseting] = useState(false);

  if (!templateVersionDetail) return null;

  const isSaveDisabled =
    !instanceConfiguratorForm.validation?.valid ||
    (hasAnyRequiredInputs &&
      !hasAnyChanges &&
      (!instanceId || instanceVersion === instanceDetail?.version)) ||
    isSaving;
  const onSaveHandler = () => {
    if (!instanceConfiguratorForm.userValues) return Promise.resolve();

    const flowId = RoutesStore.getRouterState().getIn(['location', 'query', 'flowId']);

    setIsSaving(true);
    return Promise.resolve(
      instanceId
        ? actions.upgradeInstance(instanceId, instanceConfiguratorForm.userValues, instanceVersion)
        : actions.createInstance(
            templateVersionDetail.repository.name,
            templateVersionDetail.template.id,
            instanceVersion,
            instanceName,
            instanceConfiguratorForm.userValues
          )
    )
      .then((instanceDetail) => {
        const generatedFlowId = instanceDetail?.mainConfig?.configId;

        if (!instanceDetail) return;

        if (!flowId || !generatedFlowId) {
          return RoutesStore.getRouter().transitionTo(routeNames.INSTANCE_DETAIL, {
            instanceId: instanceDetail.instanceId
          });
        }

        return Promise.resolve()
          .then(() => {
            if (flowId === 'new') return;

            InstalledComponentsActionCreators.deleteConfiguration(KEBOOLA_ORCHESTRATOR, flowId, {
              transition: false,
              notification: false
            });
          })
          .then(() => {
            const generatedFlowName = InstalledComponentsStore.getConfig(
              KEBOOLA_ORCHESTRATOR,
              generatedFlowId
            ).get('name');

            return InstalledComponentsActionCreators.updateComponentConfiguration(
              KEBOOLA_ORCHESTRATOR,
              generatedFlowId,
              { name: `${instanceDetail.name} - ${generatedFlowName}` },
              'Update name',
              'name'
            );
          })
          .then(() => {
            ApplicationActionCreators.sendNotification({
              type: 'success',
              message: 'The Flow was sucessfully created from template.'
            });

            return RoutesStore.getRouter().transitionTo(flowsRouteNames.DETAIL, {
              config: generatedFlowId
            });
          });
      })

      .finally(() => setIsSaving(false));
  };

  return (
    <CatchUnsavedChanges
      isDirty={hasAnyChanges && !isSaving}
      onSave={onSaveHandler}
      isSaveDisabled={isSaveDisabled}
      onDirtyLeave={actions.resetInstanceConfiguratorForm}
    >
      <ButtonToolbar>
        {!!instanceId && (
          <VersionSelect
            confirm
            versions={templateVersionDetail.template.versions}
            current={instanceVersion}
            disabled={isSaving}
          />
        )}
        {hasAnyChanges && (
          <Button
            onClick={() => {
              if (!instanceConfiguratorForm.inputs) return;

              setIsReseting(true);
              return actions
                .changeInstanceConfiguratorForm(
                  templateVersionDetail.repository.name,
                  templateVersionDetail.template.id,
                  instanceVersion,
                  getPreconfiguredValues(instanceConfiguratorForm.inputs)
                )
                .finally(() => setIsReseting(false));
            }}
            disabled={isReseting || isSaving}
          >
            {isReseting ? (
              <Loader className="icon-addon-right" />
            ) : (
              <FontAwesomeIcon icon="arrow-rotate-left" className="icon-addon-right" />
            )}
            Reset
          </Button>
        )}
        <Button bsStyle="success" onClick={onSaveHandler} disabled={isSaveDisabled}>
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
        {!!instanceId && (
          <RouterLink
            onlyActiveOnIndex
            to={routeNames.INSTANCE_DETAIL}
            params={{ instanceId }}
            disabled={hasAnyChanges || isSaving}
            className="btn btn-default"
          >
            <FontAwesomeIcon icon="xmark" className="icon-addon-right" />
            Cancel
          </RouterLink>
        )}
      </ButtonToolbar>
    </CatchUnsavedChanges>
  );
};

export default InstanceConfiguratorHeader;
