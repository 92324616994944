import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Select from '../../../react/common/Select';
import Tooltip from '../../../react/common/Tooltip';
import string from '../../../utils/string';
import { AUTO_SLEEP_OPTIONS, CONTAINER_BASED } from '../Constants';
import { getAutoSleepNote } from '../helpers';

const TIMEOUT_OPTIONS = AUTO_SLEEP_OPTIONS.map((timeout) => {
  return {
    value: timeout,
    label: `${timeout} ${string.pluralize(timeout, 'hour')}`
  };
});

const WorkspaceExpirationControl = (props: {
  type: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled: boolean;
}) => {
  if (!CONTAINER_BASED.includes(props.type)) {
    return null;
  }

  return (
    <FormGroup>
      <ControlLabel>
        Auto Sleep Timeout{' '}
        <Tooltip placement="top" tooltip={getAutoSleepNote()} type="explanatory">
          <FontAwesomeIcon icon="circle-info" className="text-muted" />
        </Tooltip>
      </ControlLabel>
      <Select
        clearable={false}
        options={TIMEOUT_OPTIONS}
        value={props.value}
        onChange={props.onChange}
        disabled={props.isDisabled}
      />
    </FormGroup>
  );
};

export default WorkspaceExpirationControl;
