import ApplicationStore from '../../stores/ApplicationStore';
import { loadAsync } from './loadAsync';
import {
  hideButton as hideProductFruitButton,
  showButton as showProductFruitButton
} from './productFruits';

let formState = {};

const props = {
  id: 'jsd-script',
  src: 'https://jsd-widget.atlassian.com/assets/embed.js',
  'data-base-url': 'https://jsd-widget.atlassian.com',
  'data-key': '17115df7-7878-4c24-8620-0321fe792b6e',
  'data-jsd-embedded': ''
};

const getNativeInputValueSetter = (input) => {
  const prototypeSource =
    input.tagName === 'INPUT' ? window.HTMLInputElement : window.HTMLTextAreaElement;

  return Object.getOwnPropertyDescriptor(prototypeSource.prototype, 'value').set;
};

const setInputValue = (input, value) => {
  getNativeInputValueSetter(input).call(input, value);

  input.dispatchEvent(new Event('input', { bubbles: true }));
};

const simulateClick = (input) => {
  ['mousedown', 'mouseup'].forEach((mouseEventType) => {
    input?.dispatchEvent(new MouseEvent(mouseEventType, { bubbles: true, buttons: 1 }));
  });
};

const initJiraWidget = () => {
  window.addEventListener('productfruits_button_ext_widget_init', () => openJiraWidget());

  // Add styles for Jira widget
  const iframeDoc = getJiraWidgetIframeDoc();
  const style = iframeDoc.createElement('style');
  style.appendChild(iframeDoc.createTextNode(''));
  iframeDoc.head.appendChild(style);
  style.sheet.insertRule('#react-root { padding: 15px; }');
  style.sheet.insertRule('#button-container { display: none; }');
  style.sheet.insertRule('* { font-size: 14px; font-weight: 400; line-height: 20px; }');
  style.sheet.insertRule('.help-form { max-height: none; height: auto; }');
  style.sheet.insertRule('.form-container footer { border-top: 1px solid #D9DEE6; }');
  style.sheet.insertRule('.form-container footer .powered-by { box-shadow: none; }');
  style.sheet.insertRule('.ak-field-group:first-child { padding-top: 0; }');
  style.sheet.insertRule(
    '.ak-field-group label, .ak-field-group legend { color: #222529 !important; }'
  );
  style.sheet.insertRule('.ak-field-group legend { margin-bottom: 0; }');
  style.sheet.insertRule(
    '.ak-field-group textarea { resize: vertical; min-height: 100px; max-height: 200px; }'
  );
  style.sheet.insertRule(
    '.ak-field-group input, .ak-field-group .Select-placeholder, .ak-field-group .Select-input { min-height: 40px; width: 100%; }'
  );
  style.sheet.insertRule(
    '.ak-field-group > input, .ak-field-group > input:hover:not(:focus), .ak-field-group > textarea, .ak-field-group > textarea:hover:not(:focus), .ak-field-group .Select > .Select-control, .ak-field-group .Select:hover > .Select-control, .ak-field-group .Select.is-focused > .Select-control, .ak-field-group .Select:hover:not(.is-focused) > .Select-control, .ak-field-group .Select > .Select-placeholder, .ak-field-group .Select:hover > .Select-placeholder, .ak-field-group .Select > .Select-input, .ak-field-group .Select:hover > .Select-input, .ak-field-group .Select > .Select-value, .ak-field-group .Select:hover > .Select-value { background-color: #edf0f5 !important; border-color: transparent !important; border-radius: 4px !important; }'
  );
  style.sheet.insertRule(
    '.ak-field-group > input:focus, .ak-field-group > textarea:focus, .ak-field-group div.Select.is-focused > .Select-control { border: 1px solid #1f8fff !important; box-shadow: 0 0 0 3px rgb(34 141 255 / 25%) !important; }'
  );
  style.sheet.insertRule('.Select-placeholder { padding-top: 5px; color: #7c8594; }');
  style.sheet.insertRule('.Select-input input { padding: 0px; }');
  style.sheet.insertRule('.Select-value { top: 10px !important; }');
  style.sheet.insertRule(
    '.Select-input input, .Select .Select-value .Select-value-label { color: #222529 !important; }'
  );
  style.sheet.insertRule('.Select .Select-control .Select-clear-zone:hover { color: #1f8fff; }');
  style.sheet.insertRule(
    '.ak-field-group > .Select.is-focused > .Select-control { padding: 1px !important; }'
  );
  style.sheet.insertRule('.ak-field-group > textarea { padding: 9px 7px !important; }');
  style.sheet.insertRule('span.optional { color: #7C8594; }');
  style.sheet.insertRule('.ak-button__appearance-primary { border-radius: 4px }');
  style.sheet.insertRule(
    '.ak-button__appearance-link { color: #1f8fff !important; text-decoration: underline; }'
  );
  style.sheet.insertRule(
    '.form-container { border-radius: 6px; box-shadow: 0px 0px 11px 3px rgba(34, 37, 41, 0.12) }'
  );
  style.sheet.insertRule(
    '.ak-field-group legend + .ak-button__appearance-link { height: auto; line-height: normal; padding: 4px; margin-left: -4px; }'
  );
  style.sheet.insertRule('#submit-button { margin-top: 10px }');
};

export default () => {
  return loadAsync(props, () => {
    const iframe = document.querySelector('iframe#jsd-widget');

    if (getJiraWidgetIframeDoc(iframe).readyState === 'complete') {
      initJiraWidget();
    } else {
      iframe.onload = initJiraWidget;
    }
  });
};

export const openJiraWidget = (context) => {
  const iframeDoc = getJiraWidgetIframeDoc();

  if (!iframeDoc || iframeDoc.querySelector('.help-form')) return;

  hideProductFruitButton();
  iframeDoc.querySelector('#help-button').click();

  for (const [inputName, value] of Object.entries(formState)) {
    const inputElement = iframeDoc.getElementsByName(inputName)[0];

    if (['text', 'textarea'].includes(inputElement.type)) {
      setInputValue(inputElement, value);
    }
  }

  iframeDoc.querySelector('#description').setAttribute('rows', '4');
  iframeDoc.querySelector('#customfield_10330').parentNode.style['display'] = 'none';
  iframeDoc.querySelector('#customfield_10325').parentNode.style['display'] = 'none';
  iframeDoc.querySelector('#email').parentNode.style['display'] = 'none';
  setInputValue(
    iframeDoc.querySelector('#customfield_10330'),
    JSON.stringify({
      url: window.location.href,
      project_type: ApplicationStore.getCurrentProject().get('type'),
      ...context
    })
  );
  setInputValue(iframeDoc.querySelector('#customfield_10325'), window.location.href);
  setInputValue(iframeDoc.querySelector('#email'), ApplicationStore.getCurrentAdmin().get('email'));

  if (context?.requesting_new) {
    const inquiryInput = iframeDoc.querySelector('label:has([title="Inquiry"]) + .Select input');

    if (inquiryInput) {
      // simulate search in input
      setInputValue(inquiryInput, 'feature requests');
      // simulate click on first searched option
      simulateClick(iframeDoc.querySelector('.select-option.is-focused'));
      // focus next element
      iframeDoc.querySelector('#summary')?.focus();
    }
  }

  iframeDoc.querySelector('form').addEventListener('change', (event) => {
    if (event.target.name) {
      formState[event.target.name] = event.target.value;
    }
  });

  iframeDoc.querySelector('form').addEventListener('submit', () => {
    setTimeout(() => {
      if (iframeDoc.querySelector('#confirmation-container')) {
        formState = {};
      }
    }, 1000);
  });

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node.id === 'button-container') showProductFruitButton();
      });
    });
  });

  observer.observe(iframeDoc, { childList: true, subtree: true });
};

export const getJiraWidgetIframeDoc = (iframe = document.querySelector('iframe#jsd-widget')) => {
  return iframe?.contentDocument || iframe?.contentWindow?.document;
};
