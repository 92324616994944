import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { Map } from 'immutable';

import VersionsStore from '../../../modules/components/stores/VersionsStore';
import VersionsActionCreators from '../../../modules/components/VersionsActionCreators';
import RowVersionsActionCreators from '../../../modules/configurations/RowVersionsActionCreators';
import RowVersionsStore from '../../../modules/configurations/RowVersionsStore';
import rowRollback from '../../../modules/configurations/utils/createRowVersionOnRollback';
import rollback from '../../../utils/createVersionOnRollback';
import ComponentIcon from '../ComponentIcon';
import CreatedDate from '../CreatedDate';
import Loader from '../Loader';
import { prepareConfigData } from './helpers';
import SplitDiffRenderer from './SplitDiffRenderer';

const SIDE = {
  LEFT: 'left',
  RIGHT: 'right'
} as const;

type SIDE = (typeof SIDE)[keyof typeof SIDE];

const SplitDiff = ({
  admins,
  componentId,
  config,
  row,
  current,
  previous,
  branchDiff,
  hideRollback,
  noChangesPlaceholder,
  prepareData = prepareConfigData
}: {
  admins: Map<string, any>;
  componentId: string;
  config?: Map<string, any>;
  row?: Map<string, any>;
  current?: Map<string, any>;
  previous?: Map<string, any>;
  branchDiff?: boolean;
  hideRollback?: boolean;
  noChangesPlaceholder?: React.ReactNode;
  prepareData?: (config?: Map<string, any>) => string;
}) => {
  const [leftVersion, setLeftVersion] = React.useState(previous);
  const [rightVersion, setRightVersion] = React.useState(current);
  const actualVersion = !!row ? row.get('version') : config?.get('version') ?? 1;

  const handleChangeVersion = React.useCallback(
    (version: number, side: SIDE) => {
      if (!config || !version) {
        return Promise.resolve();
      }

      return Promise.resolve()
        .then(() => {
          if (!!row) {
            RowVersionsActionCreators.loadComponentConfigByVersion(
              componentId,
              config.get('id'),
              row.get('id'),
              version
            ).then(() => {
              return RowVersionsStore.getConfigByVersion(
                componentId,
                config.get('id'),
                row.get('id'),
                version
              );
            });
          }

          return VersionsActionCreators.loadComponentConfigByVersion(
            componentId,
            config.get('id'),
            version
          ).then(() => {
            return VersionsStore.getConfigByVersion(componentId, config.get('id'), version);
          });
        })
        .then(side === SIDE.LEFT ? setLeftVersion : setRightVersion);
    },
    [componentId, config, row]
  );

  const prepareTitle = (side: SIDE) => {
    const version = side === SIDE.LEFT ? leftVersion : rightVersion;

    if (!version || version.isEmpty()) {
      return 'No data';
    }

    const versionId = version.get('version');
    const creator = version.getIn(
      ['currentVersion', 'creatorToken', 'description'],
      version.getIn(['creatorToken', 'description'], 'unknown')
    );
    const adminName = admins.getIn([creator, 'name'], creator);
    const created = version.getIn(['currentVersion', 'created'], version.get('created'));
    const entity = branchDiff
      ? `${side === SIDE.RIGHT ? 'Branch' : 'Production'} version`
      : 'Version';

    const renderLeftSide = () => {
      if (!config) {
        return null;
      }

      return (
        <ButtonToolbar className="tw-mr-4 no-wrap">
          <LoadVersionButton
            side={SIDE.LEFT}
            diffSide={side}
            leftVersion={leftVersion?.get('version') ?? 1}
            rightVersion={rightVersion?.get('version') ?? 1}
            actualVersion={actualVersion}
            onClick={handleChangeVersion}
          />
          <LoadVersionButton
            side={SIDE.RIGHT}
            diffSide={side}
            leftVersion={leftVersion?.get('version') ?? 1}
            rightVersion={rightVersion?.get('version') ?? 1}
            actualVersion={actualVersion}
            onClick={handleChangeVersion}
          />
        </ButtonToolbar>
      );
    };

    const renderRightSide = () => {
      if (actualVersion === versionId) {
        return <span className="tw-ml-auto tw-text-secondary-600">Current Version</span>;
      }

      if (hideRollback || !config) {
        return null;
      }

      return (
        <VersionRestoreButton
          componentId={componentId}
          configId={config.get('id')}
          versionId={versionId}
          rowId={row?.get('id')}
        />
      );
    };

    return (
      <div className="tw-flex tw-justify-start tw-items-center">
        {renderLeftSide()}
        <header>
          <h3 className="tw-font-normal tw-text-sm tw-m-0">
            {entity} <b className="tw-font-medium">#{version.get('version')}</b>
          </h3>
          <div className="tw-text-xs tw-text-neutral-400">
            Created <CreatedDate createdTime={created} /> by {adminName}
          </div>
        </header>
        {renderRightSide()}
      </div>
    );
  };

  const oldData = prepareData(leftVersion);
  const newData = prepareData(rightVersion);

  if (noChangesPlaceholder && oldData === newData) {
    return <div>{noChangesPlaceholder}</div>;
  }

  return (
    <div className="split-diff">
      <SplitDiffRenderer
        oldValue={oldData}
        newValue={newData}
        leftTitle={prepareTitle(SIDE.LEFT)}
        rightTitle={prepareTitle(SIDE.RIGHT)}
      />
    </div>
  );
};

export const SplitDiffTitle = ({
  name,
  component,
  helpText
}: {
  name: string;
  component: Map<string, any>;
  helpText?: React.ReactNode;
}) => {
  return (
    <>
      <ComponentIcon component={component} size="40" className="tw-mr-4" />
      <div className="flex-container flex-column align-top">
        {name} - configuration changes
        {helpText && <div className="f-12 line-height-16 text-muted">{helpText}</div>}
      </div>
    </>
  );
};

const LoadVersionButton = ({
  side,
  diffSide,
  leftVersion,
  rightVersion,
  actualVersion,
  onClick
}: {
  side: SIDE;
  diffSide: SIDE;
  leftVersion: number;
  rightVersion: number;
  actualVersion: number;
  onClick: (version: number, side: SIDE) => Promise<any>;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const isDisabled =
    isLoading ||
    (side === SIDE.LEFT && (diffSide === SIDE.LEFT ? leftVersion : rightVersion) === 1) ||
    (side === SIDE.RIGHT &&
      (diffSide === SIDE.LEFT ? leftVersion : rightVersion) === actualVersion);

  const version =
    (diffSide === SIDE.LEFT ? leftVersion : rightVersion) + (side === SIDE.LEFT ? -1 : 1);

  return (
    <Button
      bsSize="sm"
      className={classNames('only-icon', {
        '!tw-ml-1': side === SIDE.LEFT,
        '!tw-ml-2': side === SIDE.RIGHT
      })}
      disabled={isDisabled}
      onClick={() => {
        setIsLoading(true);
        onClick(version, diffSide).finally(() => setIsLoading(false));
      }}
    >
      <FontAwesomeIcon icon={side === SIDE.RIGHT ? 'angle-right' : 'angle-left'} />
    </Button>
  );
};

const VersionRestoreButton = ({
  componentId,
  configId,
  versionId,
  rowId
}: {
  componentId: string;
  configId: string;
  versionId: number;
  rowId?: string;
}) => {
  const [restoring, setRestoring] = React.useState(false);

  return (
    <Button
      bsSize="sm"
      className="tw-ml-auto"
      disabled={restoring}
      onClick={() => {
        const rollbackAction = !!rowId
          ? rowRollback(componentId, configId, rowId, versionId)
          : rollback(componentId, configId, versionId);

        setRestoring(true);
        rollbackAction().finally(() => setRestoring(false));
      }}
    >
      {restoring ? (
        <>
          <Loader className="text-muted icon-addon-right" />
          Restoring...
        </>
      ) : (
        'Restore version'
      )}
    </Button>
  );
};

export default SplitDiff;
