import React from 'react';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import CollapseButton from './CollapseButton';
import Tooltip from './Tooltip';

const CollapsibleBox = (props: {
  title: string;
  isDisabled?: boolean;
  entity?: string;
  defaultOpen?: boolean;
  mountOnEnter?: boolean;
  titleSuffix?: React.ReactNode;
  collapsePrefix?: React.ReactNode;
  additionalActions?: (isOpen: boolean, toggleOpen: () => void) => React.ReactNode;
  headerClassName?: string;
  children?: React.ReactNode;
  hint?: React.ReactNode;
}) => {
  const [open, setOpen] = React.useState(!!props.defaultOpen);
  const handleCollapse = () => {
    if (!props.isDisabled) {
      setOpen(!open);
    }
  };

  return (
    <div className={classNames('box box-collapsible', { disabled: props.isDisabled })}>
      <div
        className={classNames('box-header big-padding btn-collapse-area', props.headerClassName)}
        onClick={handleCollapse}
      >
        <h2 className="box-title flex-container flex-start">
          <span className="color-main">{props.title}</span>
          {props.hint && (
            <Tooltip tooltip={props.hint} placement="top" type="explanatory">
              <FontAwesomeIcon icon="circle-info" className="title-hint" />
            </Tooltip>
          )}
          {props.titleSuffix}
        </h2>
        <div className="flex-container">
          {props.collapsePrefix}
          {!props.isDisabled && !!props.additionalActions && (
            <span className="addons" onClick={(event) => event.stopPropagation()}>
              {props.additionalActions(open, handleCollapse)}
            </span>
          )}
          <CollapseButton
            entity={props.entity || 'setting'}
            isCollapsed={props.isDisabled || !open}
            onToggle={handleCollapse}
            isDisabled={props.isDisabled}
          />
        </div>
      </div>
      <Collapse in={open && !props.isDisabled} mountOnEnter={props.mountOnEnter}>
        <div>
          <div className="box-content pt-0">{props.children}</div>
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsibleBox;
