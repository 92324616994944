import React from 'react';

import ExternalLink from '../../../react/common/ExternalLink';
import Link from '../../../react/common/RouterLink';

class DataTakeout extends React.Component {
  render() {
    return (
      <div className="well pp-6">
        <h3 className="tw-mt-0 tw-mb-6">Data Takeout</h3>
        <div className="value-list m-0">
          <p className="tw-mb-6">
            Export this project to{' '}
            <ExternalLink href="https://aws.amazon.com/s3/">Amazon S3</ExternalLink> or{' '}
            <ExternalLink href="https://azure.microsoft.com/services/storage/blobs/">
              Azure Blob Storage
            </ExternalLink>
            .
          </p>
          <Link to="data-takeout" className="btn btn-sm btn-primary">
            Data Takeout
          </Link>
        </div>
      </div>
    );
  }
}

export default DataTakeout;
