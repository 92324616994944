import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import Checkbox from '../../../../react/common/Checkbox';
import CopyPrimaryKeyButton from '../../../../react/common/CopyPrimaryKeyButton';
import Select from '../../../../react/common/Select';
import TableSelectorForm from '../../../../react/common/TableSelectorForm';

const Settings = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    settings: PropTypes.object.isRequired,
    defaultTable: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    destinationEditing: PropTypes.bool.isRequired,
    onDestinationEdit: PropTypes.func.isRequired
  },

  getDefaultProps() {
    return {
      disabled: false
    };
  },

  onChangeDestination(value) {
    var settings = this.props.settings.set('destination', value);

    // set primary key if table exists
    if (this.props.tables.has(value)) {
      settings = settings.set('primaryKey', this.props.tables.getIn([value, 'primaryKey']));
    } else {
      settings = settings.set('primaryKey', List());
    }
    this.props.onChange(settings);
  },

  onDestinationEdit() {
    const settings = this.props.settings;
    this.props.onChange(settings);
    this.props.onDestinationEdit();
  },

  isExistingTable() {
    const destinationTable = this.props.settings.get('destination');
    if (!destinationTable || destinationTable === '') {
      return false;
    }
    return this.props.tables.has(destinationTable);
  },

  onChangeIncremental() {
    var settings = this.props.settings.set(
      'incremental',
      !this.props.settings.get('incremental', false)
    );
    this.props.onChange(settings);
  },

  onChangePrimaryKey(value) {
    var settings = this.props.settings.set('primaryKey', value);
    this.props.onChange(settings);
  },

  onChangeDelimiter(e) {
    var settings = this.props.settings.set('delimiter', e.target.value);
    this.props.onChange(settings);
  },

  onChangeEnclosure(e) {
    var settings = this.props.settings.set('enclosure', e.target.value);
    this.props.onChange(settings);
  },

  primaryKeyPlaceholder() {
    if (this.isExistingTable()) {
      return 'Cannot add a column';
    }
    return 'Add a column';
  },

  render() {
    return (
      <div className="form-horizontal">
        <FormGroup>
          <Col xs={3} componentClass={ControlLabel}>
            Destination
          </Col>
          <Col xs={9}>
            <TableSelectorForm
              buckets={this.props.buckets}
              tables={this.props.tables}
              value={this.props.settings.get('destination', '')}
              onChange={this.onChangeDestination}
              disabled={this.props.disabled}
              onEdit={this.onDestinationEdit}
              editing={this.props.destinationEditing}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col xs={9} xsOffset={3}>
            <Checkbox
              checked={this.props.settings.get('incremental')}
              onChange={this.onChangeIncremental}
              disabled={this.props.disabled}
            >
              Incremental Load
            </Checkbox>
            <HelpBlock>
              If incremental load is turned on, the table will be updated instead of rewritten.{' '}
              Tables with a primary key will have rows updated, tables without a primary key will
              have rows appended.
            </HelpBlock>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col xs={3} componentClass={ControlLabel}>
            Primary Key
          </Col>
          <Col xs={9}>
            <Select
              name="primaryKey"
              value={this.props.settings.get('primaryKey')}
              multi
              allowCreate
              placeholder={this.primaryKeyPlaceholder()}
              onChange={this.onChangePrimaryKey}
              disabled={this.props.disabled}
            />
            <HelpBlock>{this.renderPrimaryKeyHelpText()}</HelpBlock>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col xs={3} componentClass={ControlLabel}>
            Delimiter
          </Col>
          <Col xs={9}>
            <FormControl
              type="text"
              value={this.props.settings.get('delimiter')}
              onChange={this.onChangeDelimiter}
              disabled={this.props.disabled}
            />
            <HelpBlock>
              Field delimiter used in the CSV file. The default value is <code>,</code>. Use{' '}
              <code>\t</code> for tabulator.
            </HelpBlock>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col xs={3} componentClass={ControlLabel}>
            Enclosure
          </Col>
          <Col xs={9}>
            <FormControl
              type="text"
              value={this.props.settings.get('enclosure')}
              onChange={this.onChangeEnclosure}
              disabled={this.props.disabled}
            />
            <HelpBlock>Field enclosure used in CSV file.</HelpBlock>
          </Col>
        </FormGroup>
      </div>
    );
  },

  renderPrimaryKeyHelpText() {
    const sourcePrimaryKey = this.props.tables.getIn(
      [this.props.settings.get('destination'), 'primaryKey'],
      List()
    );

    return (
      <>
        If a primary key is set, updates can be done on the table by selecting{' '}
        <strong>incremental loads</strong>. The primary key can consist of multiple columns.{' '}
        {!sourcePrimaryKey.equals(this.props.settings.get('primaryKey', List())) && (
          <>
            The primary key must match the primary key in the existing table.{' '}
            <CopyPrimaryKeyButton
              sourcePrimaryKey={sourcePrimaryKey}
              onCopy={this.onChangePrimaryKey}
            />
          </>
        )}
      </>
    );
  }
});

export default Settings;
