import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { getDefaultBucketName } from '../../modules/components/helpers';
import { filterProductionAndCurrentDevBranchBuckets } from '../../modules/dev-branches/helpers';
import { STAGE } from '../../modules/storage/constants';
import { getBucketDisplayNameFromName } from '../../modules/storage/helpers';
import Select from '../../react/common/Select';
import ApplicationStore from '../../stores/ApplicationStore';
import string from '../../utils/string';
import { bucketLabel } from './selectLabels';

const DestinationTableSelector = createReactClass({
  propTypes: {
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    parts: PropTypes.object.isRequired,
    updatePart: PropTypes.func.isRequired,
    currentSource: PropTypes.string,
    defaultBucketName: PropTypes.string,
    defaultTableName: PropTypes.string,
    disabled: PropTypes.bool
  },

  getDefaultProps() {
    return {
      currentSource: '',
      disabled: false
    };
  },

  render() {
    return (
      <div className="destination-table-selector">
        {this.renderStageSelect()}
        {this.renderBucketSelect()}
        {this.renderTableSelect()}
      </div>
    );
  },

  renderStageSelect() {
    return (
      <Select
        clearable={false}
        searchable={false}
        value={this.props.parts.stage}
        disabled={this.props.disabled}
        onChange={this.selectStage}
        options={Object.values(STAGE).map((stage) => ({
          value: stage,
          label: stage.toUpperCase()
        }))}
      />
    );
  },

  renderBucketSelect() {
    return (
      <Select
        allowCreate
        promptTextCreator={(label) => label}
        disabled={this.props.disabled}
        placeholder="Select a bucket"
        value={this.props.parts.bucket}
        onChange={this.selectBucket}
        options={this.prepareBucketsOptions().toJS()}
        newOptionCreator={this.selectBucketOptionCreator}
        isValidNewOption={this.isBucketUnique}
      />
    );
  },

  renderTableSelect() {
    return (
      <Select
        allowCreate
        promptTextCreator={(label) => label}
        disabled={this.props.disabled}
        placeholder="Select a table"
        value={this.props.parts.table}
        onChange={this.selectTable}
        options={this.prepareTablesOptions().toJS()}
        newOptionCreator={this.selectTableOptionCreator}
        isValidNewOption={this.isTableUnique}
      />
    );
  },

  prepareBucketsOptions() {
    const bucket = this.props.parts.bucket;
    const buckets = filterProductionAndCurrentDevBranchBuckets(this.props.buckets)
      .filter((bucket) => {
        return (
          bucket.get('stage') === this.props.parts.stage &&
          !bucket.has('sourceBucket') &&
          !bucket.get('hasExternalSchema')
        );
      })
      .map((bucket) => ({
        label: bucketLabel(bucket, { showStage: false }),
        name: bucket.get('displayName'),
        value: bucket.get('name')
      }))
      .toList();

    if (!!bucket && !buckets.find((b) => b.value === bucket)) {
      return buckets.push({
        label: getBucketDisplayNameFromName(bucket),
        name: bucket,
        value: bucket
      });
    }

    return buckets;
  },

  prepareTablesOptions() {
    const bucketId = this.props.parts.stage + '.' + this.props.parts.bucket;
    const webalizedSource = string.webalize(this.props.currentSource, { caseSensitive: true });
    const defaultTableName = this.props.defaultTableName || webalizedSource;

    let tables = this.props.tables
      .filter((table) => table.getIn(['bucket', 'id']) === bucketId)
      .map((table) => ({
        label: `${table.get('displayName')}${table.get('isAlias', false) ? ' (alias)' : ''}`,
        value: table.get('name'),
        name: table.get('displayName'),
        isDisabled: table.get('isAlias', false)
      }))
      .toList();

    if (!!defaultTableName && !tables.find((table) => table.value === defaultTableName)) {
      tables = tables.insert(0, {
        label: `New table ${defaultTableName}`,
        value: defaultTableName,
        name: defaultTableName
      });
    }

    return tables;
  },

  selectStage(stage) {
    this.updateValue('stage', stage);
  },

  selectBucket(bucket) {
    if (
      !!bucket &&
      !bucket.startsWith('c-') &&
      !ApplicationStore.hasDisableLegacyBucketPrefix() &&
      !this.prepareBucketsOptions().find((b) => b.value === bucket)
    ) {
      this.updateValue('bucket', 'c-' + bucket);
    } else if (!bucket && this.props.defaultBucketName) {
      this.updateValue('bucket', this.props.defaultBucketName);
    } else {
      this.updateValue('bucket', bucket);
    }
  },

  selectTable(value) {
    this.updateValue('table', value);
  },

  updateValue(partNameToUpdate, value) {
    this.props.updatePart(partNameToUpdate, value);
  },

  isBucketUnique(inputValue) {
    const buckets = this.props.buckets.filter((bucket) => {
      return bucket.get('stage') === this.props.parts.stage;
    });

    return (
      !buckets.has(`${this.props.parts.stage}.${getDefaultBucketName(inputValue)}`) &&
      !buckets.some((bucket) => bucket.get('displayName') === inputValue)
    );
  },

  isTableUnique(inputValue) {
    const bucketId = this.props.parts.stage + '.' + this.props.parts.bucket;
    const tables = this.props.tables.filter((table) => {
      return table.getIn(['bucket', 'id']) === bucketId;
    });

    return (
      !tables.has(inputValue) && !tables.some((table) => table.get('displayName') === inputValue)
    );
  },

  selectBucketOptionCreator(inputValue) {
    const option = string.webalize(inputValue, { caseSensitive: true });

    return {
      label: `Create new bucket "${option}"`,
      value: option
    };
  },

  selectTableOptionCreator(inputValue) {
    const option = string.webalize(inputValue, { caseSensitive: true });

    return {
      label: `Create new table "${option}"`,
      value: option
    };
  }
});

export default DestinationTableSelector;
