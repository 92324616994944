import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import { USER_DOCUMENTATION_URL } from '../../../../constants/KbcConstants';
import ConfirmButtons from '../../../../react/common/ConfirmButtons';
import ExternalLink from '../../../../react/common/ExternalLink';
import actionCreators from '../../ActionCreators';

const Phase = createReactClass({
  propTypes: {
    transformation: PropTypes.object.isRequired,
    bucketId: PropTypes.string.isRequired
  },

  getInitialState() {
    return {
      isSaving: false,
      phase: this.props.transformation.get('phase'),
      showModal: false
    };
  },

  render() {
    return (
      <>
        <Button bsStyle="link" onClick={this.open} block>
          <FontAwesomeIcon icon="list-radio" fixedWidth />
          Phase: <span className="tw-font-medium">{this.props.transformation.get('phase')}</span>
        </Button>
        <Modal onHide={this.close} show={this.state.showModal}>
          <Form onSubmit={this.handleSubmit} inline>
            <Modal.Header closeButton>
              <Modal.Title>Transformation Phase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <ExternalLink href={`${USER_DOCUMENTATION_URL}/transformations/#phases`}>
                  A phase
                </ExternalLink>{' '}
                is a set of transformations.
              </p>
              <p>
                Phases may be used to divide transformations into logical blocks, transfer data
                between transformations, transformation engines, and remote transformations.
              </p>
              <FormGroup controlId="phaseNumber">
                <ControlLabel>Phase #</ControlLabel>{' '}
                <FormControl
                  type="number"
                  min={1}
                  autoFocus
                  value={parseInt(this.state.phase, 10)}
                  onChange={this.handlePhaseChange}
                  disabled={this.state.isSaving}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveButtonType="submit"
                isSaving={this.state.isSaving}
                isDisabled={!this.isValid()}
              />
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  },

  open() {
    this.setState({ showModal: true });
  },

  close() {
    this.setState({ isSaving: false, showModal: false });
  },

  handlePhaseChange(e) {
    if (e.target.value < 0) {
      return;
    }

    this.setState({ phase: e.target.value });
  },

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isSaving: true });
    actionCreators
      .changeTransformationProperty(
        this.props.bucketId,
        this.props.transformation.get('id'),
        'phase',
        this.state.phase
      )
      .then(() => this.close())
      .catch((error) => {
        this.setState({ isSaving: false });
        throw error;
      });
  },

  isValid() {
    return this.state.phase >= 1;
  }
});

export default Phase;
