import {
  KEBOOLA_AZURE_NORTH_EUROPE,
  KEBOOLA_CLOUD_CSAS,
  KEBOOLA_CLOUD_CSAS_TEST,
  KEBOOLA_COULD_INNOGY_HUB,
  KEBOOLA_EU_CENTRAL_1,
  KEBOOLA_US_EAST_1
} from '../../constants/stackIds';
import { getCampaignTarget, isValidCampaignTarget } from '../../modules/home/helpers';
import ApplicationStore from '../../stores/ApplicationStore';
import { getJiraWidgetIframeDoc } from './jiraWidget';

export const getProjectCode = () => {
  if (ApplicationStore.isDemoPreview()) {
    return 'dJwWgtEVmEraUlro';
  }
  if (ApplicationStore.hasPayAsYouGo()) {
    return 'V5x3GnLccUmxjejR';
  }

  switch (ApplicationStore.getCurrentStack()) {
    case KEBOOLA_US_EAST_1:
      return '0t8RZuvgvuyzXRPN';
    case KEBOOLA_EU_CENTRAL_1:
      return 'Tg0NCPVOAZZxlEWw';
    case KEBOOLA_AZURE_NORTH_EUROPE:
      return 'Nr5zikdYkUBDWHIX';
    case KEBOOLA_CLOUD_CSAS:
    case KEBOOLA_CLOUD_CSAS_TEST:
      return 'u5IhJPP2XvAJVL0N';
    case KEBOOLA_COULD_INNOGY_HUB:
      return 'W8ISIGrLISE7wyam';
    default:
      return 'Tg0NCPVOAZZxlEWw';
  }
};

export const getCustomData = (location) => {
  const project = ApplicationStore.getCurrentProject();

  return {
    projectId: project.get('id'),
    backend: project.get('defaultBackend'),
    ...(isValidCampaignTarget(location.query.target) && {
      landing_page: getCampaignTarget(location.query.target),
      campaign: location.query.campaign
    })
  };
};

export const showButton = () => {
  if (window.productFruits?.button.config.isActive) {
    window.productFruits.api?.button?.showButton();
  }
};

export const hideButton = () => {
  if (!window.productFruits) return;

  if (
    window.productFruits.button?.buttonEl?.classList.contains('productfruits--lifering-clicked')
  ) {
    window.productFruits.button.buttonEl.click();
  }

  window.productFruits.api?.button?.hideButton();
};

export const notifyProductFruitsWhenRouteChanges = () => {
  // This is to let ProductFruits know that the page has changed (since `disableLocationChangeDetection` is on), but not show the button if Jira support widget is opened.
  window.productFruits?.pageChanged?.();

  if (getJiraWidgetIframeDoc()?.querySelector('.form-container')) {
    hideButton();
  }
};
